/**
 * copy of CustomerCategoryConstants.java for frontend
 */
export const enum CustomerCategoryEnum {
    // Category 1
    TRIPLETEX_HERBALIFE_CUSTOMERS = 93,
    TRIPLETEX_CAMPAIGN_INVOICE_ACCOUNTING = 447,
    TRIPLETEX_CAMPAIGN_TAKSTNOKKELEN = 453,
    TRIPLETEX_CAMPAIGN_MARY_KAY = 454,
    TRIPLETEX_CAMPAIGN_TROLLWEB = 621,
    TRIPLETEX_CAMPAIGN_ALOE_VERA = 654,
    TRIPLETEX_WHOLESALER_ACCOUNT = 663,
    TRIPLETEX_CAMPAIGN_EB_TEAM = 1153,
    TRIPLETEX_CAMPAIGN_DISCOUNT_CODE = 4364,
    TRIPLETEX_ORDER_FROM_SITE = 1393,
    TRIPLETEX_ORDER_FROM_APPLICATION = 1394,
    ECONOMIC = 14377,

    // Category 2
    TRIPLETEX_CAT2_ACCOUNTANT = 6351,
    TRIPLETEX_AGRO_MIGRERT = 35500,
    TRIPLETEX_MAMUT_MIGRATED = 73091,
    TRIPLETEX_MAMUT_ENTERPRISE_MIGRATED = 73670,
    TRIPLETEX_EACCOUNTING_MIGRATED = 112676,

    // Category 3
    TRIPLETEX_TEST = 286,
    TRIPLETEX_FREE = 287,
    TRIPLETEX_PAYING = 288,
    TRIPLETEX_AGRO = 24551,
    TRIPLETEX_MAMUT = 26532,
}
