/**
 * Give general information about the browser.
 */
export const browserInfo = (function () {
    /**
     * The regular expression is taken from http://detectmobilebrowsers.com/
     * The expression was then last updated 1 August 2014.
     *
     * NB: Tablets not counted as mobile (iPad and Samsung Galaxy Tab confirmed)
     *
     *
     * @author bruce
     * @date 2013-09-30
     *
     */
    let isMobile: boolean | undefined = undefined;
    function isMobileReg(): boolean {
        if (typeof isMobile !== 'undefined') {
            return isMobile;
        }
        const a = navigator.userAgent || navigator.vendor;
        isMobile =
            /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
                a
            ) ||
            // eslint-disable-next-line no-useless-escape
            /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
                a.substr(0, 4)
            );
        return isMobile;
    }

    function isNarrowScreen() {
        return (
            isMobileReg() &&
            isTouchDevice() &&
            (window.screen.width < 768 || window.screen.height < 768)
        );
    }

    function isTouchDevice() {
        return 'ontouchstart' in window || 'onmsgesturechange' in window; // ie10 fix
    }

    /**
     * NB! USED FOR SEGMENTATION, NOT FEATURES!
     *
     * Our current heuristic to detect the three major different devices that contains a browser.
     *
     * Notes of concern:
     *
     * * 'mobile' will fail if regex from 2014 doesn't detect the device, or if the device as wide as
     *   768px or wider.
     *
     * * 'desktop' and 'tablet' will fail if regex with unkown origin fails.
     *
     *
     * @returns {string}
     */
    function device() {
        if (isNarrowScreen()) {
            return 'mobile';
        }

        if (isTouchDevice() && !isDesktopBrowser()) {
            return 'tablet';
        }

        return 'desktop';
    }

    /**
     * Try to figure out if we're on desktop. Probably not bullet proof.
     */
    function isDesktopBrowser(): boolean {
        // A computer mouse is NOT in use AND there is some pointer device in use on this device!
        if (
            !matchMedia('(any-pointer:fine)').matches &&
            !matchMedia('(any-pointer:none)').matches
        ) {
            return false;
        }
        return !/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(
            navigator.userAgent
        );
    }

    function isFirefox(): boolean {
        return navigator.userAgent.indexOf('Firefox') != -1;
    }

    function isChrome(): boolean {
        return (
            navigator.userAgent.indexOf('Chrome') != -1 ||
            navigator.userAgent.indexOf('CriOS') !== -1
        ); // CriOS = Chrome on iOs.
    }

    function isSafari(): boolean {
        return navigator.userAgent.indexOf('Safari') != -1 && !isChrome();
    }

    function isIphone(): boolean {
        return navigator.userAgent.indexOf('iPhone') > -1;
    }

    function isIpad(): boolean {
        return navigator.userAgent.match(/iPad/i) != null;
    }

    function isIOS(): boolean {
        return /iPad|iPhone|iPod/g.test(navigator.userAgent);
    }

    function isEdge(): boolean {
        return navigator.userAgent.indexOf('Edge') > -1;
    }

    const supportsFileUploadMemo: boolean | undefined = undefined;
    function supportsFileUpload(): boolean {
        'Taken from Viljami Salminen, http://viljamis.com/blog/2012/file-upload-support-on-mobile/';
        if (typeof supportsFileUploadMemo !== 'undefined') {
            return supportsFileUploadMemo;
        }
        // Handle devices which falsely report support
        if (
            navigator.userAgent.match(
                /(Android (1.0|1.1|1.5|1.6|2.0|2.1))|(Windows Phone (OS 7|8.0))|(XBLWP)|(ZuneWP)|(w(eb)?OSBrowser)|(webOS)|(Kindle\/(1.0|2.0|2.5|3.0))/
            )
        ) {
            return false;
        }
        // Create test element
        const el = document.createElement('input');
        el.type = 'file';
        return !el.disabled;
    }

    /**
     * This only works on webkit based browsers, but is only used for a bug fix in chrome as of now.
     * @returns {boolean}
     */
    function isRetina(): boolean {
        return window.devicePixelRatio >= 2;
    }

    let doesSupportNotification = false;
    function supportsNotification(): boolean {
        if (window.Notification) {
            if (!Notification.requestPermission) {
                doesSupportNotification = false;
            }
            if (Notification.permission === 'granted') {
                doesSupportNotification = true;
            } else {
                try {
                    new Notification('');
                    doesSupportNotification = true;
                } catch (e) {
                    doesSupportNotification = false;
                }
            }
        }

        // Instead of performing all of the above for each time:
        browserInfo.supportsNotification = function () {
            return doesSupportNotification;
        };
        return doesSupportNotification;
    }

    function isInIframe(): boolean {
        try {
            return window.self !== window.top;
        } catch (e) {
            return true;
        }
    }

    return {
        isMobileReg: isMobileReg,
        isDesktopBrowser: isDesktopBrowser,
        isFirefox: isFirefox,
        isChrome: isChrome,
        isSafari: isSafari,
        isIphone: isIphone,
        isNarrowScreen: isNarrowScreen,
        isTouchDevice: isTouchDevice,
        supportsFileUpload: supportsFileUpload,
        isIOS: isIOS,
        isIpad: isIpad,
        isEdge: isEdge,
        isRetina: isRetina,
        supportsNotification: supportsNotification,
        isInIframe: isInIframe,
        device: device,
    };
})();
