import * as React from 'react';
import { createPortal } from 'react-dom';
import './FullScreen.scss';

type PropsWithChildren = {
    children: React.ReactNode;
    ref?: React.RefObject<HTMLDivElement>;
    handleScroll?: (event: any) => void;
};

type FullscreenDialogProps = {
    open?: boolean;
} & PropsWithChildren;

export const FullscreenHeader = (props: PropsWithChildren) => (
    <div className="react-modal-fullscreen__header">{props.children}</div>
);

export const FullScreenHeaderTitle = (props: PropsWithChildren) => (
    <h6 className="react-modal-fullscreen__header__title">{props.children}</h6>
);

export const FullscreenBody = (props: PropsWithChildren) => (
    <div
        className="react-modal-fullscreen__body"
        ref={props.ref}
        onScroll={props.handleScroll}
    >
        {props.children}
    </div>
);

export const FullscreenDialog = (props: FullscreenDialogProps) => {
    if (!props.open && props.open !== undefined) {
        return null;
    }
    return createPortal(
        <div className="react-modal-fullscreen">{props.children}</div>,
        document.body
    );
};
