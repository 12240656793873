import React, { AriaAttributes, AriaRole, ReactNode, useState } from 'react';
import './DataTree.css';
import {
    AddIcon,
    Button,
    ChevronDownIcon,
    ChevronRightIcon,
    DeleteIcon,
    EditIcon,
    Input,
    TestableProps,
} from '@tlx/atlas';
import classNames from 'classnames';
import { DataTreeNode } from '@Page/LogisticsV2/components/DataTree/types';
import { ConfirmationModal } from '@Page/LogisticsV2/components/Modals/ConfirmationModal';

type DataTreeProps = {
    children?: ReactNode;
    isRoot: boolean;
    isOpen: boolean;
    role?: AriaRole;
    className?: string;
} & AriaAttributes;

export function DataTree({
    children,
    isRoot,
    isOpen,
    className,
    ...props
}: DataTreeProps) {
    return (
        <div
            className={classNames('tlx-product-group-root', className, {
                'atl-pl-0': isRoot,
                'atl-pl-16': !isRoot,
                'tlx-product-group-expanded-root': isOpen && isRoot,
            })}
            {...props}
        >
            {children}
        </div>
    );
}

export function DataTreeContent({
    isOpen,
    children,
    ...props
}: {
    role?: AriaRole;
    isOpen: boolean;
    children?: ReactNode;
} & AriaAttributes) {
    return (
        <div hidden={!isOpen} {...props}>
            {children}
        </div>
    );
}

export function DataTreeButtons({
    children,
    className,
    ...props
}: {
    children?: ReactNode;
    className?: string;
    role?: AriaRole;
} & AriaAttributes) {
    return (
        <div className={className} {...props}>
            {children}
        </div>
    );
}

export function DataTreeHeader({
    children,
    className,
    ...props
}: {
    children?: ReactNode;
    className?: string;
    role?: AriaRole;
} & AriaAttributes) {
    return (
        <div
            className={classNames(
                'atl-flex atl-items-center atl-w-full',
                className
            )}
            {...props}
        >
            {children}
        </div>
    );
}

export function DataTreeTitle({
    isSelected,
    children,
    onChange,
    className,
}: {
    isSelected: boolean;
} & {
    children?: ReactNode;
    onChange?: () => void;
    className?: string;
}) {
    return (
        <Button
            className={classNames(
                'atl-text-base tlx-product-group-title',
                className,
                { 'tlx-product-group-title-selected': isSelected }
            )}
            data-testid={'product-group-selected'}
            onClick={onChange}
            variant="tertiary"
        >
            {children}
        </Button>
    );
}

export function DataTreeDeletableTitle({
    children,
    onDelete,
}: {
    children?: ReactNode;
    onDelete?: () => void;
}) {
    return (
        <div className={'atl-text-base tlx-product-group-title'}>
            {children}
        </div>
    );
}

export function DataTreeToggleButton({
    isOpen,
    onClick,
    'data-testid': dataTestid,
    ...props
}: {
    isOpen: boolean;
    onClick?: React.MouseEventHandler;
} & TestableProps &
    AriaAttributes) {
    return (
        <Button
            onClick={onClick}
            data-testid={dataTestid}
            variant="icon"
            aria-label={getMessage(isOpen ? 'text_collapse' : 'text_expand')}
            className={'goldshark-editable-tree-expand-button'}
            {...props}
        >
            {isOpen ? <ChevronDownIcon /> : <ChevronRightIcon />}
        </Button>
    );
}

export function DataTreeEditTitle({
    title,
    onChange,
    onCancel,
}: {
    title: string;
    onChange: (title: string) => void;
    onCancel: () => void;
}) {
    const [internalTitle, setInternalTitle] = useState(title);
    return (
        <div className={'atl-flex atl-gap-16 atl-my-8'}>
            <Input
                type="text"
                value={internalTitle}
                data-testid={'rename-title-input'}
                onChange={(event) => setInternalTitle(event.target.value)}
                onKeyDown={(event) => {
                    switch (event.key) {
                        case 'Enter':
                            onChange(internalTitle);
                            break;
                        case 'Escape':
                            onCancel();
                            break;
                    }
                }}
                className={'atl-mr-8 tlx-product-group-title atl-text-base'}
                autoFocus={true}
                autoComplete={'off'}
            />
            <Button
                data-testid={'rename-title-save-button'}
                variant={'primary'}
                onClick={() => onChange(internalTitle)}
                aria-label={'save'}
                disabled={internalTitle.trim() === ''}
            >
                {getMessage('text_confirm')}
            </Button>
            <Button
                data-testid={'rename-title-cancel-button'}
                variant={'secondary'}
                onClick={() => {
                    onCancel();
                }}
                aria-label={'cancel'}
            >
                {getMessage('text_cancel_reply')}
            </Button>
        </div>
    );
}

export function DataTreeItem({
    node,
    isRoot,
    isEditMode,
    addNode,
    onUpdate,
    onDelete,
    onCancel,
    onEditing,
}: {
    node: DataTreeNode;
    isRoot: boolean;
    isEditMode: boolean;
    addNode: (parentId: number | null) => void;
    onUpdate: (node: DataTreeNode) => void;
    onDelete: (id: number) => void;
    onCancel: (id: number) => void;
    onEditing: (value: boolean) => void;
}) {
    const isNew = node.id < 1;
    const [isOpen, setIsOpen] = useState(true);
    const [isEditing, setIsEditing] = useState(isNew);
    const [showWarning, setShowWarning] = useState(false);

    return (
        <DataTree
            isRoot={isRoot}
            isOpen={isOpen}
            role={node.children.length > 0 ? 'group' : ''}
        >
            <DataTreeHeader
                role={'treeitem'}
                className={'goldshark-editable-tree-title'}
                aria-expanded={node.children.length > 0 ? isOpen : undefined}
            >
                {node.children.length > 0 && (
                    <DataTreeToggleButton
                        isOpen={isOpen}
                        onClick={() => setIsOpen(!isOpen)}
                        data-testid={'toggle-second'}
                        aria-label={
                            isOpen
                                ? 'toggle button expanded'
                                : 'toggle button collapsed'
                        }
                    />
                )}
                {isEditing ? (
                    <DataTreeEditTitle
                        title={node.name}
                        onChange={(title) => {
                            setIsEditing(false);
                            onEditing(false);
                            node.name = title;
                            onUpdate(node);
                        }}
                        onCancel={() => {
                            setIsEditing(false);
                            onEditing(false);
                            onCancel(node.id);
                        }}
                    />
                ) : (
                    <div
                        className={
                            'atl-flex atl-w-full goldshark-tree-row atl-items-center'
                        }
                    >
                        <div
                            tabIndex={0}
                            style={{ height: '40px' }}
                            className={
                                'atl-items-center atl-flex atl-text-base tlx-product-group-title'
                            }
                        >
                            {node.name}
                        </div>
                        {!isEditMode && (
                            <DataTreeButtons
                                className={'goldshark-hidden atl-ml-8'}
                            >
                                <Button
                                    data-testid={'rename-button'}
                                    variant="icon"
                                    onClick={() => {
                                        setIsEditing(true);
                                        onEditing(true);
                                    }}
                                    aria-label={getMessage('rename')}
                                >
                                    <EditIcon />
                                </Button>
                                <Button
                                    data-testid={'add-button'}
                                    variant="icon"
                                    onClick={() => {
                                        addNode(node.id);
                                    }}
                                    aria-label={'add child'}
                                >
                                    <AddIcon />
                                </Button>
                                {node.isDeletable && (
                                    <Button
                                        data-testid={'delete-button'}
                                        variant="icon"
                                        onClick={() => {
                                            if (node.children.length > 0) {
                                                setShowWarning(true);
                                            } else {
                                                onDelete(node.id);
                                            }
                                        }}
                                        aria-label={'delete'}
                                    >
                                        <DeleteIcon />
                                    </Button>
                                )}
                            </DataTreeButtons>
                        )}
                        {showWarning && (
                            <ConfirmationModal
                                text={getMessage(
                                    'button_delete_group_with_subgroup'
                                )}
                                onConfirm={() => {
                                    onDelete(node.id);
                                    setShowWarning(false);
                                }}
                                onCancel={() => setShowWarning(false)}
                            />
                        )}
                    </div>
                )}
            </DataTreeHeader>
            <DataTreeContent
                isOpen={isOpen}
                role={node.children.length > 0 ? 'group' : ''}
            >
                {node.children.map((node) => (
                    <DataTreeItem
                        key={node.id}
                        node={node}
                        isRoot={false}
                        isEditMode={isEditMode}
                        addNode={addNode}
                        onUpdate={onUpdate}
                        onDelete={onDelete}
                        onCancel={onCancel}
                        onEditing={onEditing}
                    />
                ))}
            </DataTreeContent>
        </DataTree>
    );
}
