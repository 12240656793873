import React, { ReactNode, useState } from 'react';
import './ProductSelector.css';
import { ToggleSwitch } from '@Page/LogisticsV2/components/ToggleSwitch/ToggleSwitch';
import { Button, Icon } from '@tlx/atlas';
import classNames from 'classnames';

type ProductSelector2LayoutProps = {
    hasProductGroups: boolean;
    children: ReactNode;
};

export function Container(props: ProductSelector2LayoutProps) {
    return (
        <div
            className={
                props.hasProductGroups
                    ? 'goldshark-productselector__layout'
                    : 'goldshark-productselector__layout goldshark-productselector__layout--no-groups'
            }
        >
            {props.children}
        </div>
    );
}

export function GroupsCollapsibleArrow({
    onClick,
    visibleGroups,
}: {
    onClick: () => void;
    visibleGroups: boolean;
}) {
    return (
        <div
            className={classNames(
                'goldshark-productselector__toggle-groups atl-flex',
                {
                    'goldshark-productselector__toggle-groups--closed':
                        !visibleGroups,
                }
            )}
        >
            <Button
                data-testid={'toggle-product-groups'}
                variant="icon"
                aria-label={getMessage(
                    visibleGroups ? 'text_collapse' : 'text_expand'
                )}
                className={'atl-rounded-full goldshark-toggle-arrow'}
                onClick={onClick}
            >
                <Icon>
                    {visibleGroups
                        ? 'keyboard_double_arrow_left'
                        : 'keyboard_double_arrow_right'}
                </Icon>
            </Button>
        </div>
    );
}

export function GroupsSection(props: { children: ReactNode }) {
    return (
        <div className="goldshark-productselector__groups atl-bg-blue-5">
            {props.children}
        </div>
    );
}

export function GroupsTitleSection(props: { children?: ReactNode }) {
    return (
        <div className="atl-flex atl-justify-left atl-items-center atl-text-xl goldshark-productselector__groups-title atl-bg-blue-5 atl-pl-16 atl-pt-8">
            {props.children}
        </div>
    );
}

export function ProductTitleSection(props: { children?: ReactNode }) {
    return (
        <div className="atl-flex atl-items-center atl-text-xl goldshark-productselector__producttitle">
            {props.children}
        </div>
    );
}

export function ProductsHeaderSection(props: { children: ReactNode }) {
    return (
        <div className="goldshark-productselector__search atl-flex atl-gap-8">
            {props.children}
        </div>
    );
}

export function NewProductSection(props: {
    children: (isResaleProduct: boolean) => ReactNode;
}) {
    const [hasResaleProduct, setHasResaleProduct] = useState(false);
    return (
        <div className="goldshark-productselector__new-product atl-flex atl-flex-col">
            <ToggleSwitch
                id={'product-selector-resale-product-toggle-switch'}
                label={getMessage('text_resale_product_toggle')}
                onCheckedChange={() => setHasResaleProduct(!hasResaleProduct)}
            />
            {props.children(hasResaleProduct)}
        </div>
    );
}

export function ProductsTableSection(props: { children: ReactNode }) {
    return (
        <div className="goldshark-productselector2-overflow-y-scroll goldshark-productselector__table">
            {props.children}
        </div>
    );
}

export function ButtonsSection(props: { children: ReactNode }) {
    return (
        <div className="goldshark-productselector__toolbar">
            {props.children}
        </div>
    );
}
