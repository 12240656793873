import jQuery from 'jquery';
const $ = jQuery;

/**
 * This widget maintains a abosolute positioned header|content layout where 'content' is a scrollable container.
 * The widget is needed because the header might change height due to wrapping when window is resized.
 * All structural knowledge about frameless should be exposed here.
 *
 * @author Ole Jørgen / Lars Erik
 */
$.widget('tlx.frameless', {
    // default options
    options: {
        head: '#head',
        // 'content' was in conflict with the contentframe frame name
        // (causes conficts because both named and id'ed elements are exposed as global variables - and some frame checks
        // relies on this and is confused if content exist, but don't point to the frame)
        content: '#framelessContent',
        scrollContainer: '#scrollContainer',
        //		footer: '#footer'
    },
    getScrollContainer: function () {
        return this.$scrollContainer;
    },

    /**
     * SUP-586. Sometimes we want to NOT scroll to "correct" position when doing history.back().
     * This code is intimately connected to frameless.js code for remembering scroll position
     * and scrolling to that position.
     *
     * OBS! When used as "getter" this will return to is default state (false)!
     *
     * $('body').frameless('ignoreScroll', true); // Sets value to true
     *
     *$('body').frameless('ignoreScroll') // Returns true if and only if it last time was invoked with true as second argument
     *
     */
    ignoreScroll: function (ignoreScrollPosition) {
        var oldValue = this.ignoreScrollPosition;
        this.ignoreScrollPosition = ignoreScrollPosition;
        return !!oldValue;
    },

    /**
     * Scroll (with animation) an element in scrollcontainer into view (if neccessary).
     * Add virtual margins to the element if you have fixed elements on top or bottom of the screen.
     *
     * 'opts' are passed through to animate.
     *
     * Use opt.complete if you need a callback. The callback is always run. (ie. not just when a scroll happened)
     *
     * Possible future considerations: What if the element is inside a scrollable
     * dialog?
     *
     * @authors Ole Jørgen og Lars-Erik
     */
    scrollIntoView: function (element, opts) {
        opts = $.extend(
            {
                offsetTop: 0,
                offsetBottom: 0,
            },
            opts
        );

        var $sc = this.$scrollContainer;
        var $e = $(element);

        // All coordinates relative to scroll container
        var viewportTop = $sc.scrollTop();
        // This formula was found with empirical experimentation
        // (element offset is relative to the document)
        var elementTop = viewportTop + $e.offset().top - $sc.offset().top;
        var viewportBottom = viewportTop + $sc.height();
        var elementBottom = elementTop + $e.height();

        // Adding offsets as
        elementTop = elementTop - opts.offsetTop;
        elementBottom = elementBottom + opts.offsetBottom;
        var scrollTo;

        if (elementTop < viewportTop) {
            scrollTo = elementTop;
        } else if (elementBottom > viewportBottom) {
            scrollTo = elementBottom - $sc.height();
        } else {
            if (opts.complete) {
                opts.complete.apply($sc.get(0)); // (this is how the callback is called by jquery)
            }
            return;
        }

        $sc.stop().animate(
            {
                scrollTop: scrollTo,
            },
            opts
        );
    },
    _create: function () {
        var that = this;

        function recalculate() {
            that.$content.css('top', that.$head.height());
        }

        that.$head = $(that.options.head);
        that.$content = $(that.options.content);
        that.$scrollContainer = that.$content.find(
            that.options.scrollContainer
        );

        // Forward scroll events to document. Needed since some code listens for scroll on document and the scroll event doesn't bubble? (Not sure if this is needed anymore)
        that.$scrollContainer.bind(
            'scroll',
            $.throttle(function () {
                $(document).trigger('scroll');
            }, 150)
        );

        if (!window.narrowScreen) {
            $(window).bind(
                'resize.frameless tlx-toggleTabsVisibility',
                recalculate
            );

            // Chrome (37) sometimes gave the wrong height (slightly less) when recalculate was run directly.
            // Could not pinpoint the underlying problem, but a timeout seems to work
            setTimeout(recalculate);
        }
    },
    _destroy: function () {
        $(window).unbind('.frameless');
    },
});
