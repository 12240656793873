import { Form } from '@Page/LogisticsV2/components/Form/Form';
import { getSvgIcon } from '@Page/LogisticsV2/utils/svgIcons';
import {
    Modal,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalTitle,
} from '@tlx/atlas';
import { useState } from 'react';
import { ModalBatchActionButtons, ModalWarning } from '../components';
import {
    ChangeProductDetailsRackbeatContent,
    ChangeProductDetailsRadioGroup,
    ChangeProductDetailsUnitsDropdown,
} from './ChangeProductDetailsRackbeat';

export function ChangeProductDetailsRackbeatModal({
    lines,
    onClose,
    onSubmit,
}: {
    lines: number;
    onClose: () => void;
    onSubmit: (
        formElement: HTMLFormElement,
        values: { [k: string]: FormDataEntryValue }
    ) => void;
}) {
    const [isNextDisabled, setIsNextDisabled] = useState(true);
    const [showEditProductDetailsWarning, setShowEditProductDetailsWarning] =
        useState(false);

    const handleOnSubmit = (
        formElement: HTMLFormElement,
        values: { [k: string]: FormDataEntryValue }
    ) => {
        onSubmit(formElement, values);
        onClose();
    };

    const handleChangeProductDetails = (
        formElement: HTMLFormElement,
        values: { [k: string]: FormDataEntryValue }
    ) => {
        if (
            values['editDetailsType'] === 'unit' &&
            (values['unitValue'] === undefined ||
                values['unitValue'] === 'undefined')
        ) {
            setIsNextDisabled(true);
        } else {
            setIsNextDisabled(false);
        }
    };

    return (
        <Modal
            open={true}
            onClose={() => {
                onClose();
                setShowEditProductDetailsWarning(false);
            }}
        >
            <ModalContent>
                {getSvgIcon('edit')}
                <ModalTitle>
                    {getMessage('text_product_change_info')}
                </ModalTitle>
                <ModalCloseButton />
                <Form
                    formId="change-product-details-rackbeat-form"
                    onSubmit={handleOnSubmit}
                    onChangeForm={handleChangeProductDetails}
                >
                    <ChangeProductDetailsRackbeatContent>
                        <ChangeProductDetailsRadioGroup>
                            <ChangeProductDetailsUnitsDropdown />
                        </ChangeProductDetailsRadioGroup>
                        {showEditProductDetailsWarning && (
                            <ModalWarning
                                text={getMessage(
                                    'text_change_product_information_warning',
                                    lines
                                )}
                            />
                        )}
                    </ChangeProductDetailsRackbeatContent>
                </Form>
            </ModalContent>
            <ModalFooter>
                <ModalBatchActionButtons
                    formId="change-product-details-rackbeat-form"
                    isDisabled={isNextDisabled}
                    showButton={showEditProductDetailsWarning}
                    onNext={() => setShowEditProductDetailsWarning(true)}
                    onBack={() => setShowEditProductDetailsWarning(false)}
                    onCancel={() => {
                        onClose();
                        setShowEditProductDetailsWarning(false);
                    }}
                />
            </ModalFooter>
        </Modal>
    );
}
