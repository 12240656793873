import { useFetchSingle } from '@Page/LogisticsV2/hooks/api';
import { StocktakingDTO } from '@Page/LogisticsV2/types/dtoTypes';
import { ApiUrl } from '@Page/LogisticsV2/utils/enums';
import { mapObjectToURLSearchParams } from '@Page/LogisticsV2/utils/mappers';
import { getIdFromUrl } from '@Page/LogisticsV2/utils/functions';

export function useStocktakingDetails() {
    const stocktakingId = getIdFromUrl('inventoryId');
    const { data, mutate } = useFetchSingle<StocktakingDTO>(
        stocktakingId !== null
            ? ApiUrl.INVENTORY_STOCKTAKING + '/' + stocktakingId
            : undefined,
        mapObjectToURLSearchParams({ fields: '*,inventory(id,name)' }),
        { revalidateOnFocus: false, shouldRetryOnError: false }
    );
    return {
        data,
        mutate,
    };
}
