import './PaymentWidgetInputAmountField.css';
import React, { FC } from 'react';
import { Group, Icon, Label } from '@tlx/atlas';
import type { TestableProps } from '@tlx/atlas';
import { FormattedNumberField } from '@General/PBC/Components/FormattedNumberField';
import classNames from 'classnames';

interface PaymentWidgetInputAmountFieldProps extends TestableProps {
    readonly id: string;
    readonly amount: number;
    readonly setAmount: (amount: number) => void;
    readonly atlasDesignSystem: boolean;
    readonly name?: string;
    readonly validationMessage?: string;
    readonly resetValidationMessage?: () => void;
    readonly disabled?: boolean;
    readonly children?: React.ReactNode;
}

export const PaymentWidgetInputAmountField: FC<PaymentWidgetInputAmountFieldProps> =
    ({
        id,
        amount,
        setAmount,
        atlasDesignSystem,
        name,
        children,
        validationMessage,
        resetValidationMessage,
        disabled,
        ...props
    }) => {
        const updateAmount = (rawValue: number) => {
            if (!isNaN(rawValue)) {
                setAmount(rawValue);
            }
            resetValidationMessage?.();
        };

        return (
            <Group
                className={classNames('inputItem ', {
                    'pbc-amount--atlas': atlasDesignSystem,
                    'pbc-amount--classic': !atlasDesignSystem,
                    'pbc-text-validation-error': !!validationMessage,
                })}
            >
                <Label htmlFor={id}>{children} *</Label>

                <div
                    className={classNames(
                        'atl-flex atl-items-center atl-justify-center atl-gap-8',
                        {
                            'pbc-amount--classic-margin': !validationMessage,
                        }
                    )}
                >
                    <FormattedNumberField
                        {...props}
                        id={id}
                        name={name}
                        className={[
                            'atl-w-full',
                            validationMessage
                                ? 'pbc-payment__section-validation-error'
                                : '',
                        ].join(' ')}
                        required={true}
                        step="0.01"
                        placeholder="0.00"
                        value={amount.toString()}
                        onBlur={(_, rawValue) =>
                            updateAmount(parseFloat(rawValue))
                        }
                        disabled={disabled}
                    />
                    {atlasDesignSystem && validationMessage && (
                        <Icon className="material-icons-outlined atl-icon atl-text-red-100 pbc-payment__section-icon">
                            error_outline
                        </Icon>
                    )}
                </div>
                {validationMessage && (
                    <div className="tlx-formgroup__validation atl-text-red-100 pbc-payment__section-validation">
                        {validationMessage}
                    </div>
                )}
            </Group>
        );
    };
