import $ from 'jquery';
import { CSRF_HEADER_NAME, getCSRFToken } from './csrf';

export const localAPI = (function () {
    'use strict';

    function extractData(rawData) {
        if (!rawData) {
            return null;
        }
        if (typeof rawData.value !== 'undefined') {
            return rawData.value;
        }
        if (typeof rawData.values !== 'undefined') {
            return rawData.values;
        }

        return rawData;
    }

    function execAjaxCall(method, actionPath, data) {
        const headers = {
            'x-tlx-context-id': window.contextId,
            'content-type': 'application/json; charset=utf-8',
            accept: 'application/json; charset=utf-8',
            [CSRF_HEADER_NAME]: getCSRFToken(),
        };

        const promise = $.ajax({
            url: '/v2/' + actionPath,
            headers: headers,
            method: method,
            data: data,
            beforeSend: function (jqXHR, settings) {
                jqXHR.url = settings.url; // Store URL for error handler. See frameless.js
            },
        });

        return promise.then(extractData).fail(function fail(e) {
            console.error('API error', e, {
                method: method,
                actionPath: actionPath,
                data: data,
            });
            return e;
        });
    }
    function POST(actionPath, data) {
        return execAjaxCall('POST', actionPath, data);
    }

    function GET(actionPath, data) {
        return execAjaxCall('GET', actionPath, data);
    }

    function PUT(actionPath, data) {
        return execAjaxCall('PUT', actionPath, data);
    }

    function DELETE(actionPath, data) {
        return execAjaxCall('DELETE', actionPath, data);
    }

    return {
        POST: POST,
        GET: GET,
        PUT: PUT,
        DELETE: DELETE,
    };
})();
