import { OrderOrProject } from '@Page/ProjectInvoicingDialog/types/InvoicingDialog.type';
import { ValidationError } from '@Page/ProjectInvoicingDialog/component/ValidationError';
import { Button } from '@tlx/atlas';
import * as React from 'react';
import { useContext } from 'react';
import { InvoicingModalContext } from '@Page/ProjectInvoicingDialog/component/InvoicingModal';

export function SendingError({
    orderOrProject,
}: {
    orderOrProject: OrderOrProject;
}) {
    const { trackingIdContext } = useContext(InvoicingModalContext);
    return (
        <div className="atl-p-32">
            <h3>{getMessage('text_sending_failed')}</h3>
            <ValidationError
                error={{
                    type: 'manual',
                    message: getMessage('text_sending_failed_details'),
                }}
            />
            <div className="atl-mt-16 atl-mb-8 tlx-invoicingDialog__mb_auto">
                <Button
                    variant="primary"
                    onClick={() => {
                        window.location.href = addContextId(
                            '/execute/invoiceMenu?invoiceId=' +
                                orderOrProject.preliminaryInvoice?.id ?? 0
                        );
                    }}
                    data-testid="close-invoicing-dialog-button"
                    data-trackingid={
                        !window.location.href.includes('story')
                            ? getLocaleMessage('en_GB', 'text_sending_failed') +
                              ', ' +
                              getLocaleMessage(
                                  'en_GB',
                                  'button_go_to_invoice_details'
                              ).toLowerCase() +
                              ' (single invoicing) (invoicing React modal)' +
                              trackingIdContext
                            : ''
                    }
                >
                    {getMessage('button_go_to_invoice_details')}
                </Button>
            </div>
        </div>
    );
}
