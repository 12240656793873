import * as React from 'react';

import { renderComponent } from '@General/renderComponent';
import { PageLoader } from '@Component/PageLoader';

const Page = () => (
    <PageLoader<{}>
        loader={async () => import('./SecureAuthorization').then((m) => m.page)}
    />
);

export function renderSecureAuthorization(domId: string) {
    renderComponent(Page, domId);
}
