import * as React from 'react';

import './Link.scss';
import { nav } from '../../../../js/modules/navigation';

type PropsChildren = {
    children: React.ReactNode;
};

type PropsLink = {
    href: string;
    external?: boolean;
    target?: string;
    className?: string;
    toolTip?: string;
    onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
    trackingId?: string;
} & PropsChildren;

const navigate = (
    event: React.MouseEvent<HTMLAnchorElement>,
    url: string,
    target?: string
) => {
    if (event.defaultPrevented) {
        return;
    }
    if (event.metaKey || event.ctrlKey) {
        return;
    }
    nav.nav(url, {
        target,
    });
    event.preventDefault();
};

// TODO A complete rewrite is needed as this is just over-engineering with no benefits.
class Link extends React.PureComponent<PropsLink> {
    a?: HTMLAnchorElement | null;

    constructor(props: PropsLink) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(e: React.MouseEvent<HTMLAnchorElement>) {
        if (this.props.onClick) {
            this.props.onClick(e);
        }
        navigate(e, this.props.href, this.props.target);
    }

    render() {
        const {
            className,
            href,
            external,
            toolTip,
            target,
            trackingId,
            children,
        } = this.props;
        const thisClassName = `${className || 'txr-link'}`;
        return (
            <a
                href={href}
                onClick={!external ? this.handleClick : undefined}
                className={thisClassName}
                aria-label={toolTip}
                data-tooltip={toolTip}
                target={target}
                rel={external ? 'noopener noreferrer' : ''}
                ref={(node) => {
                    this.a = node;
                }}
                data-trackingid={trackingId}
            >
                {children}
            </a>
        );
    }
}

export default Link;
