export function getColumnLabel(column: string): string {
    switch (column) {
        case 'unit':
            return getMessage('text_product_unit');
        case 'deviation':
            return getMessage('text_deviation');
        case 'costPrice':
            return getMessage('text_cost_price');
        case 'value':
            return getMessage('text_value');
        case 'location':
            return getMessage('text_location');
        case 'comment':
            return getMessage('text_comment');
        default:
            throw new Error(`Unknown column: ${column}`);
    }
}

export const getAvailableColumnsInStocktakingDetails = () => {
    return [
        'unit',
        'deviation',
        'costPrice',
        'value',
        ...(isLocationEnabled ? ['location'] : []),
        'comment',
    ];
};

export const getDefaultColumnsInStocktakingDetails = () => {
    return [
        'unit',
        'deviation',
        'costPrice',
        'value',
        ...(isLocationEnabled ? ['location'] : []),
        'comment',
    ];
};
