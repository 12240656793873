import { useState } from 'react';
import { Group, InfoIcon, Input, Label, RadioGroup } from '@tlx/atlas';

export function ChangePricePurchasePriceSection({
    showPriceChangeWarning,
    isSupplierProduct,
}: {
    showPriceChangeWarning: boolean;
    isSupplierProduct: boolean;
}) {
    const [contentValue, setContentValue] = useState({
        type: 'percent',
        percentValue: '',
        value: '',
    });

    return (
        <div className="atl-flex atl-gap-32 atl-items-center atl-flex-col">
            <div className="atl-flex atl-gap-16 atl-w-full">
                <Group className="atl-w-full">
                    <Label>
                        <RadioGroup>
                            <Input
                                id="percent"
                                type="radio"
                                data-testid="change-price-purchase-percent-radio"
                                checked={contentValue.type === 'percent'}
                                onChange={() => {
                                    setContentValue({
                                        type: 'percent',
                                        percentValue: '',
                                        value: '',
                                    });
                                }}
                                disabled={showPriceChangeWarning}
                                name="changePricePurchasePercentRadio"
                            />
                            <Label htmlFor="percent">
                                {getMessage('text_percent')}
                            </Label>
                        </RadioGroup>
                    </Label>
                    <Input
                        type="number"
                        name="changePricePurchasePercentInput"
                        data-testid="change-price-purchase-percent-input"
                        disabled={
                            contentValue.type !== 'percent' ||
                            showPriceChangeWarning
                        }
                        value={contentValue.percentValue}
                        onChange={(e) =>
                            setContentValue({
                                ...contentValue,
                                percentValue: e.target.value,
                            })
                        }
                    />
                </Group>
                <Group className="atl-w-full">
                    <Label>
                        <RadioGroup>
                            <Input
                                id="value"
                                type="radio"
                                data-testid="change-price-purchase-value-radio"
                                checked={contentValue.type === 'value'}
                                onChange={() => {
                                    setContentValue({
                                        type: 'value',
                                        percentValue: '',
                                        value: '',
                                    });
                                }}
                                name="changePricePurchaseValueRadio"
                                disabled={showPriceChangeWarning}
                            />
                            <Label htmlFor="value">
                                {getMessage('text_value')} (NOK)
                            </Label>
                        </RadioGroup>
                    </Label>
                    <Input
                        type="money"
                        name="changePricePurchaseValueInput"
                        data-testid="change-price-purchase-value-input"
                        disabled={
                            contentValue.type !== 'value' ||
                            showPriceChangeWarning
                        }
                        value={contentValue.value}
                        onChange={(e) =>
                            setContentValue({
                                ...contentValue,
                                value: e.target.value,
                            })
                        }
                    />
                </Group>
            </div>
            {!isSupplierProduct && moduleLogistics && (
                <Group className="atl-w-full">
                    <Label>
                        <RadioGroup>
                            <Input
                                id="mainSupplier"
                                type="radio"
                                data-testid="change-price-purchase-supplier-radio"
                                checked={contentValue.type === 'mainSupplier'}
                                onChange={() => {
                                    setContentValue({
                                        type: 'mainSupplier',
                                        percentValue: '',
                                        value: '',
                                    });
                                }}
                                name="changePricePurchaseSupplierRadio"
                                disabled={showPriceChangeWarning}
                            />
                            <Label htmlFor="mainSupplier">
                                {getMessage(
                                    'text_update_price_from_main_supplier'
                                )}
                            </Label>
                        </RadioGroup>
                    </Label>
                    <Label>
                        <RadioGroup>
                            <div>
                                <InfoIcon
                                    className="atl-rounded-full atl-bg-turquoise-20"
                                    size="small"
                                />
                            </div>
                            <Label>
                                {getMessage(
                                    'text_update_price_from_main_supplier_info'
                                )}
                            </Label>
                        </RadioGroup>
                    </Label>
                </Group>
            )}
        </div>
    );
}
