import { FC } from 'react';
import { PageLoader } from '@Component/PageLoader';
import { RouteData } from '@General/Router';
import { Team } from '@General/Team';
import { renderComponent } from '@General/renderComponent';

const Page: FC = (props) => (
    <PageLoader<{}>
        loader={async () =>
            import('./ProjectApprovedInvoiceExistsWarning').then((m) => m.page)
        }
        props={props}
    />
);

export const route: RouteData = {
    component: Page,
    path: 'execute/projectApprovedInvoiceExistsWarning',
    team: Team.SHEET_STORM,
    auth: () => true,
};

export function renderProjectApprovedInvoiceExistsWarning(domId: string) {
    renderComponent(Page, domId);
}
