import React, { FC, useEffect, useState } from 'react';
import { PaymentWidgetDropdown } from '@Page/PaymentWidget/component/PaymentWidgetDropdown/PaymentWidgetDropdown';
import {
    PaymentWidgetAdvancedData,
    RegulatoryReportingCode,
} from '@Page/PaymentWidget/types/paymentWidgetTypes';
import { TextField } from '@Page/PaymentWidget/component/TextField/TextField';
import tlxFetch from '../../../api/tlxFetch';
import {
    BankFieldsEnum,
    getName,
} from '@Page/PaymentWidget/component/AdvancedPaymentWidgetTemplate';

/**
 * Wrapper over the payment widget dropdown component for fetching the regulatory reporting codes from backend.
 *
 * @author Daniel Bratosin
 * @date 31. Jan 2022
 */
export type RegulatoryReportingCodePair = Pick<
    PaymentWidgetAdvancedData,
    'regulatoryReportingCode' | 'regulatoryReportingInfo'
>;

type PaymentWidgetRegulatoryReportingCodeDropdownWrapperProps = {
    id: string;
    dataTestId: string;
    atlasDesignSystem: boolean;
    name: string;
    regulatoryReportingCodePair?: RegulatoryReportingCodePair;
    setRegulatoryReportingCodePair: (
        value: RegulatoryReportingCodePair
    ) => void;
    regulatoryReportingInfoValidationMessage?: string;
    resetRegulatoryReportingInfoValidationMessage?: () => void;
};

export const PaymentWidgetRegulatoryReportingCodeDropdownWrapper: FC<PaymentWidgetRegulatoryReportingCodeDropdownWrapperProps> =
    ({
        id,
        dataTestId,
        atlasDesignSystem,
        name,
        regulatoryReportingCodePair,
        setRegulatoryReportingCodePair,
        regulatoryReportingInfoValidationMessage,
        resetRegulatoryReportingInfoValidationMessage,
    }) => {
        const [regulatoryReportingCodes, setRegulatoryReportingCodes] =
            useState<RegulatoryReportingCode[]>([]);
        const codeValueId =
            regulatoryReportingCodePair?.regulatoryReportingCode || '';
        const codeValueInfo =
            regulatoryReportingCodePair?.regulatoryReportingInfo || '';

        function matchComment(id: number) {
            let comment = '';

            regulatoryReportingCodes.forEach((regulatoryReportingCode) => {
                if (regulatoryReportingCode.id !== id) {
                    return;
                }
                comment = regulatoryReportingCode.name.replace(/[0-9]/g, '');
            });

            return comment.replace(/^\s+/g, '');
        }

        const fetchRegulatoryReportingCodesProvider = async () => {
            const { response } = await tlxFetch({
                url: `/v2/bank/paymentWidget/regulatoryReportingCodes`,
            });

            if (response) {
                setRegulatoryReportingCodes(response);
            }
        };

        useEffect(() => {
            fetchRegulatoryReportingCodesProvider();
        }, []);

        const setDropdownOption = (regulatoryReportingCodeType: string) => {
            const selectedRegulatoryReportingCode =
                regulatoryReportingCodes.find(
                    ({ id }) => id === parseInt(regulatoryReportingCodeType)
                );
            if (selectedRegulatoryReportingCode) {
                const comment = matchComment(
                    selectedRegulatoryReportingCode.id
                );
                setRegulatoryReportingCodePair({
                    regulatoryReportingCode:
                        selectedRegulatoryReportingCode.id.toString(),
                    regulatoryReportingInfo: comment,
                });
            }
        };

        return (
            <div>
                <PaymentWidgetDropdown
                    name={name}
                    id={id}
                    options={regulatoryReportingCodes.map(
                        (regulatoryReportingCode) => ({
                            value: regulatoryReportingCode.id.toString(),
                            displayName: regulatoryReportingCode.name,
                        })
                    )}
                    atlasDesignSystem={atlasDesignSystem}
                    dropdownOption={codeValueId}
                    setDropdownOption={(regulatoryReportingCodeType) => {
                        setDropdownOption(regulatoryReportingCodeType);
                    }}
                    data-testid={dataTestId}
                >
                    {getMessage('text_autopay_regulatory_reporting_code')}
                </PaymentWidgetDropdown>

                <TextField
                    name={getName(BankFieldsEnum.RegulatoryReportingInfo)}
                    id="comments"
                    label="text_comments"
                    atlasDesignSystem={atlasDesignSystem}
                    value={codeValueInfo}
                    setValue={(comments: string) => {
                        setRegulatoryReportingCodePair({
                            regulatoryReportingCode: codeValueId,
                            regulatoryReportingInfo: comments,
                        });
                    }}
                    validationMessage={regulatoryReportingInfoValidationMessage}
                    resetValidationMessage={
                        resetRegulatoryReportingInfoValidationMessage
                    }
                />
            </div>
        );
    };
