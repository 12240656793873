import { RouteData } from '@General/Router';
import { Team } from '@General/Team';

import { CompanyOverviewDashboard } from './CompanyOverviewDashboard.page';

export const route: RouteData = {
    component: CompanyOverviewDashboard,
    path: '/execute/dashboard',
    team: Team.BEEHIVE,
    documentationComponent: 257,
    title: () => getMessage('text_dashboard'),
    auth: (context) => {
        return true;
    },
};
