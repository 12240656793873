import React from 'react';
import { AdvancedPaymentWidget } from '@Page/PaymentWidget/component/AdvancedPaymentWidget';
import { useAdvancedPaymentWidget } from '@Page/PaymentWidget/hooks/useAdvancedPaymentWidget';
import { Scenario } from '@Page/PaymentWidget/types/paymentWidgetTypes';

export type AdvancedPaymentWidgetWrapperProps = {
    scenario: Scenario;
    label: string;
    vendorId?: number;
    invoiceId?: number;
    hasKid?: boolean;
    isOTPValidated?: boolean;
    voucherId?: number;
};

export function AdvancedPaymentWidgetWrapper({
    scenario,
    label,
    vendorId,
    invoiceId,
    hasKid,
    isOTPValidated,
    voucherId,
}: AdvancedPaymentWidgetWrapperProps) {
    return (
        <AdvancedPaymentWidget
            {...useAdvancedPaymentWidget(
                scenario,
                vendorId,
                invoiceId,
                hasKid,
                isOTPValidated,
                voucherId
            )}
            atlasDesignSystem={false}
            label={label}
        />
    );
}
