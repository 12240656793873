/**
 * Populate legacy select options in a native select.
 *
 * @param select the select DOM Element
 * @param listOfOptions an array of options [], where each option is [value, displayName]
 */
function addOptions(
    select: HTMLSelectElement,
    listOfOptions: [[number, string]]
) {
    for (let i = 0; i < listOfOptions.length; i++) {
        const [value, displayName] = listOfOptions[i];

        // Only update displayName if value exists
        const option: HTMLOptionElement =
            select.querySelector(`option[value="${value}"]`) ||
            document.createElement('option');
        option.value = value + '';
        option.innerText = displayName;
        select.append(option);
    }
}

/**
 * Remove the <option> tag inside the given <select> with according value (if it exists).
 *
 * @param select the select DOM Element
 * @param value the value of the option which should be removed.
 */
function removeOption(select: HTMLSelectElement, value: string) {
    const option = select.querySelector(`[value="${value}"]`);
    if (option !== null) {
        option.remove();
    }
}

/**
 * Replaces all existing options in a select with a set of new ones.
 *
 * @param select the select DOM Element
 * @param listOfOptions an array of options [], where each option is [value, displayName]
 */
function setOptions(
    select: HTMLSelectElement,
    listOfOptions: [[number, string]]
) {
    const currentValue = select.value;
    select.innerHTML = '';
    addOptions(select, listOfOptions);

    // Make sure when setting options, that if the new set with options contain
    // the same value, keep this value.
    if (select.querySelector(`[value="${currentValue}"]`) !== null) {
        // I had to wait a tick for this update to actually work (chrome 90)
        window.setTimeout(function () {
            select.value = currentValue;
        });
    }
}

export const selectUtil = { setOptions, addOptions, removeOption };
