import { getLink } from '@Page/LogisticsV2/pages/PurchaseOrder/utils';
import { PurchaseOrderDTO } from '@Page/LogisticsV2/pages/PurchaseOrder/overview/types';
import React, { useState } from 'react';
import { dateUtil } from '../../../../../../../js/modules/date';
import {
    mapStatusColor,
    mapTextToStatus,
} from '@Page/LogisticsV2/utils/mappers';
import { NumberFormat } from '@Page/Salary5/Components/NumberFormat/NumberFormat';
import { MultipleValuesWithPopup } from '@Page/LogisticsV2/components/Popup/MultipleValuesPopup';
import { Status } from '@tlx/atlas';

export function PurchaseOrderOverviewTableCell({
    column,
    purchaseOrder,
}: {
    column: string;
    purchaseOrder: PurchaseOrderDTO;
}): JSX.Element {
    switch (column) {
        case 'status':
            return <StatusRow purchaseOrder={purchaseOrder} />;
        case 'supplier':
            return <SupplierRow purchaseOrder={purchaseOrder} />;
        case 'supplierInvoice':
            return <SupplierInvoiceRow purchaseOrder={purchaseOrder} />;
        case 'projectOrder':
            return <ProjectOrderRow purchaseOrder={purchaseOrder} />;
        case 'sentDate':
            return <SentDateRow purchaseOrder={purchaseOrder} />;
        case 'deliveryDate':
            return <DeliveryDateRow purchaseOrder={purchaseOrder} />;
        case 'contact':
            return <ContactRow purchaseOrder={purchaseOrder} />;
        case 'totalAmount':
            return <TotalAmountRow purchaseOrder={purchaseOrder} />;
        case 'currency':
            return <CurrencyRow purchaseOrder={purchaseOrder} />;
        case 'internalComment':
            return <InternalCommentRow purchaseOrder={purchaseOrder} />;
        case 'orderLines':
            return <></>;
        default:
            throw new Error(`Unknown setting: ${column}`);
    }
}

function StatusRow({ purchaseOrder }: { purchaseOrder: PurchaseOrderDTO }) {
    const status = mapTextToStatus(purchaseOrder.status);
    return (
        <td className="atl-td atl-font-normal atl-align-middle" key="statusRow">
            <a href={getLink(purchaseOrder)} className="atl-text-grey-100">
                <div className="atl-flex atl-whitespace-pre-line">
                    <Status
                        variant={
                            mapStatusColor.get(purchaseOrder.status) ?? 'error'
                        }
                    >
                        {status}
                    </Status>
                </div>
            </a>
        </td>
    );
}

function SupplierRow({ purchaseOrder }: { purchaseOrder: PurchaseOrderDTO }) {
    return (
        <td
            className="atl-td atl-font-normal atl-align-middle"
            key="supplierRow"
        >
            <a
                href={getLink(purchaseOrder)}
                className="atl-text-grey-100 atl-flex"
                style={{ width: '200px' }}
            >
                {purchaseOrder.supplier !== null ? (
                    <div>
                        {purchaseOrder.supplier.name}
                        <div className="atl-text-grey-80">
                            ({purchaseOrder.supplier.supplierNumber})
                        </div>
                    </div>
                ) : (
                    <div>
                        {getMessage('text_goods_receipt_direct_no_supplier')}
                    </div>
                )}
            </a>
        </td>
    );
}

function SupplierInvoiceRow({
    purchaseOrder,
}: {
    purchaseOrder: PurchaseOrderDTO;
}) {
    const [showMultipleInvoices, setShowMultipleInvoices] = useState(false);
    return (
        <td
            className="atl-td atl-font-normal atl-align-middle"
            key="invoiceRow"
        >
            <a
                href={getLink(purchaseOrder)}
                className="atl-text-grey-100 atl-flex"
                style={{ width: '200px' }}
            >
                {purchaseOrder.supplierInvoice?.length === 0 && <div>-</div>}
                {purchaseOrder.supplierInvoice?.length === 1 &&
                    purchaseOrder.supplierInvoice.map((voucher) =>
                        voucher.number === 0 ? (
                            <div key={voucher.tempNumber}>
                                {getMessage('text_voucher')}
                                {' <'}
                                {getMessage('text_bookkeeping_not_posted')}{' '}
                                {voucher.tempNumber}
                                {'>'}
                            </div>
                        ) : (
                            <div key={voucher.number}>
                                {getMessage('text_voucher')} {voucher.number}
                                {' - '}
                                {voucher.year}
                            </div>
                        )
                    )}
                {purchaseOrder.supplierInvoice &&
                    purchaseOrder.supplierInvoice?.length > 1 && (
                        <MultipleValuesWithPopup
                            open={showMultipleInvoices}
                            setOpen={setShowMultipleInvoices}
                            text={getMessage(
                                'text_voucher_connected',
                                purchaseOrder.supplierInvoice.length
                            )}
                        >
                            {purchaseOrder.supplierInvoice.map((voucher) =>
                                voucher.number === 0 ? (
                                    <div key={voucher.tempNumber}>
                                        {getMessage('text_voucher')}
                                        {' <'}
                                        {getMessage(
                                            'text_bookkeeping_not_posted'
                                        )}{' '}
                                        {voucher.tempNumber}
                                        {'>'}
                                    </div>
                                ) : (
                                    <div key={voucher.number}>
                                        {getMessage('text_voucher')}{' '}
                                        {voucher.number}
                                        {' - '}
                                        {voucher.year}
                                    </div>
                                )
                            )}
                        </MultipleValuesWithPopup>
                    )}
            </a>
        </td>
    );
}

function ProjectOrderRow({
    purchaseOrder,
}: {
    purchaseOrder: PurchaseOrderDTO;
}) {
    return (
        <td
            className="atl-td atl-font-normal atl-align-middle"
            key="projectOrderRow"
        >
            <a href={getLink(purchaseOrder)} className="atl-text-grey-100">
                {purchaseOrder.project !== null ? (
                    <div>{purchaseOrder.project.displayName}</div>
                ) : (
                    '-'
                )}
            </a>
        </td>
    );
}

function SentDateRow({ purchaseOrder }: { purchaseOrder: PurchaseOrderDTO }) {
    return (
        <td
            className="atl-td atl-font-normal atl-align-middle"
            key="sentDateRow"
        >
            <a
                href={getLink(purchaseOrder)}
                className="atl-text-grey-100 atl-whitespace-nowrap"
            >
                {purchaseOrder.lastSentTimestamp !== null
                    ? dateUtil.formatDate(
                          new Date(purchaseOrder.lastSentTimestamp),
                          'yyyy-MM-dd'
                      )
                    : '-'}
            </a>
        </td>
    );
}

function DeliveryDateRow({
    purchaseOrder,
}: {
    purchaseOrder: PurchaseOrderDTO;
}) {
    return (
        <td
            className="atl-td atl-font-normal atl-align-middle"
            key="deliveryDateRow"
        >
            <a href={getLink(purchaseOrder)} className="atl-text-grey-100">
                {purchaseOrder.deliveryDate}
            </a>
        </td>
    );
}

function ContactRow({ purchaseOrder }: { purchaseOrder: PurchaseOrderDTO }) {
    return (
        <td
            className="atl-td atl-font-normal atl-align-middle atl-whitespace-pre-line"
            key="contactRow"
        >
            <a
                href={getLink(purchaseOrder)}
                className="atl-text-grey-100 atl-flex atl-flex-col"
                style={{ width: '200px' }}
            >
                {purchaseOrder.ourContact !== null && (
                    <div>{purchaseOrder.ourContact.displayName}</div>
                )}
                {purchaseOrder.supplierContact !== null && (
                    <div className="atl-text-grey-60 goldshark-purchase-order_contact">
                        {purchaseOrder.supplierContact.displayName}
                    </div>
                )}
                {purchaseOrder.attention !== null && (
                    <div className="atl-text-grey-60 goldshark-purchase-order_contact">
                        {purchaseOrder.attention.displayName}
                    </div>
                )}
            </a>
        </td>
    );
}

function TotalAmountRow({
    purchaseOrder,
}: {
    purchaseOrder: PurchaseOrderDTO;
}) {
    return (
        <td
            className="atl-td atl-font-normal atl-align-middle atl-text-right"
            key="totalPriceRow"
        >
            <a href={getLink(purchaseOrder)} className="atl-text-grey-100">
                <NumberFormat
                    value={purchaseOrder.totalAmount ?? 0}
                    options={{
                        style: 'decimal',
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                    }}
                />
            </a>
        </td>
    );
}

function CurrencyRow({ purchaseOrder }: { purchaseOrder: PurchaseOrderDTO }) {
    return (
        <td
            className="atl-td atl-font-normal atl-align-middle"
            key="currencyRow"
        >
            <a href={getLink(purchaseOrder)} className="atl-text-grey-100">
                {purchaseOrder.currency.code}
            </a>
        </td>
    );
}

function InternalCommentRow({
    purchaseOrder,
}: {
    purchaseOrder: PurchaseOrderDTO;
}) {
    return (
        <td
            className="atl-td atl-font-normal atl-align-middle"
            key="internalCommentRow"
        >
            <a href={getLink(purchaseOrder)} className="atl-text-grey-100">
                {purchaseOrder.internalComment}
            </a>
        </td>
    );
}
