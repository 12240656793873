import * as React from 'react';

import { PageLoader } from '@Component/PageLoader';

import type { ViewerProps } from './component';

export const ReportViewerPage: React.FC<ViewerProps> = (props) => (
    <PageLoader<ViewerProps>
        loader={async () => import('./ReportViewer.module').then((m) => m.page)}
        adjustWrapperDiv
        props={props}
    />
);
