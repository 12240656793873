import { useEffect, useState } from 'react';
import { dateUtil } from '../../../../js/modules/date';

/**
 * Calculate number of days until the due date given that the invoicing date is today
 *
 * Directly translated from `Invoice.java#calculateTermOfPayment(Project order)`
 *
 * @param invoiceDate The date of invoicing
 * @param invoiceDueIn Number of days/months/day in month
 * @param invoicesDueInType Type of `invoiceDueIn`
 * @author Alexander Sagen
 * @date 2022-04-20
 */
export function calculateDueDaysFromToday(
    invoiceDate: Date,
    invoiceDueIn: number,
    invoicesDueInType: 'DAYS' | 'MONTHS' | 'RECURRING_DAY_OF_MONTH'
): number | undefined {
    if (invoiceDueIn === undefined || !invoicesDueInType) {
        return undefined;
    }

    let dueInDate = undefined;

    const today: Date = getDateWithoutTime();

    if (invoicesDueInType === 'DAYS') {
        dueInDate = dateUtil.addDays(invoiceDate, invoiceDueIn);
    } else if (invoicesDueInType === 'MONTHS') {
        dueInDate = dateUtil.addMonths(invoiceDate, invoiceDueIn);
    } else if (invoicesDueInType === 'RECURRING_DAY_OF_MONTH') {
        // 31 is a special value representing the last day of the month.
        if (invoiceDueIn === 31) {
            const lastDayOfMonth = dateUtil.getLastDayOfMonth(invoiceDate);
            if (lastDayOfMonth.getDate() === today.getDate()) {
                dueInDate = dateUtil.getLastDayOfMonth(
                    dateUtil.addDay(invoiceDate)
                );
            } else {
                dueInDate = dateUtil.getLastDayOfMonth(invoiceDate);
            }
        } else {
            // termOfPayment should be set to a specific (recurring) day of the month, after the invoice date.
            let current: Date = today;
            for (let x = 0; x < 31; x++) {
                current = dateUtil.addDay(current);
                if (current.getDate() === invoiceDueIn) {
                    dueInDate = current;
                    break; // dueDate found...
                }
            }
        }
    }

    return dueInDate ? dateUtil.getDaysBetween(today, dueInDate) : undefined;
}

/**
 * Get the current date today with hours, minutes, seconds and millis set to 0.
 * @author Alexander Sagen
 * @date 2022-04-20
 */
function getDateWithoutTime() {
    const today: Date = new Date();
    today.setHours(0, 0, 0, 0);
    return today;
}
