import { ProductLineDTO } from '@Page/LogisticsV2/pages/Stocktaking/utils';
import { KeyedMutator } from 'swr';
import { ListResponse } from '@tlx/astro-shared';
import { StocktakingDTO } from '@Page/LogisticsV2/types/dtoTypes';

export type ProductLinesAPIModel = {
    data: ProductLineDTO[];
    error: any;
    isLoading: boolean;
    hasMore: boolean;
    loadMore: () => void;
    refresh: KeyedMutator<ListResponse<ProductLineDTO>[]>;
};

export type StocktakingAPIModel = {
    data: StocktakingDTO;
    mutate: KeyedMutator<StocktakingDTO>;
};

export const DEFAULT_COLUMNS_STOCKTAKING_DETAILS = 5;
