import { PageData, PageLoader } from '@Component/PageLoader';
import { RouteData } from '@General/Router';
import * as React from 'react';
import { Team } from '@General/Team';
import { PurchaseOrderOverviewPage } from './PurchaseOrderOverviewPage';
import { PageUrl } from '@Page/LogisticsV2/utils/enums';
import { renderComponent } from '@General/renderComponent';

const loader: React.FC = () => (
    <PageLoader<{}> loader={async () => page} adjustWrapperDiv />
);

export const route: RouteData = {
    component: loader,
    path: PageUrl.PURCHASE_ORDER_NEW,
    documentationComponent: 213,
    supportsXLS: true,
    team: Team.GOLDSHARK,
    auth: () => moduleLogistics || moduleLogisticsLight,
};

const PAGE_ID = 'PurchaseOrderOverviewPage';
const page: PageData<any> = {
    page: PurchaseOrderOverviewPage,
    modules: [
        {
            id: PAGE_ID,
        },
    ],
};

export function renderPurchaseOrderOverview(domElementId: string) {
    renderComponent(loader, domElementId);
}
