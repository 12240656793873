/**
 * Modulo function used to verify KID number, reflects {@link NumberUtil.java#modulo10()}
 *
 * @param number kid
 * @returns {number}
 */
export function modulo10(number: string): number {
    let sum = 0;
    let weighting = 2;

    for (let i = number.length - 1; i >= 0; i--) {
        const digit = parseInt(number.charAt(i), 10);
        const product = weighting * digit;

        if (product >= 10) {
            sum += 1 + (product - 10);
        } else {
            sum += product;
        }

        if (weighting === 2) {
            weighting = 1;
        } else {
            weighting = 2;
        }
    }
    return (10 - (sum % 10)) % 10;
}

/**
 * Modulo 11 used to verify KID number, reflects {@link NumberUtil.java#modulo11()}
 *
 * @param number kid
 * @returns {number}
 */
export function modulo11(number: string): number {
    let sum = 0;
    let weighting = 2;

    for (let i = number.length - 1; i >= 0; i--) {
        const digit = parseInt(number.charAt(i), 10);
        const product = weighting * digit;
        sum += product;
        if (weighting === 7) {
            weighting = 2;
        } else {
            weighting++;
        }
    }

    const rest = sum % 11;

    if (rest === 0) {
        return 0;
    } else {
        return 11 - rest;
    }
}

/**
 * Validates control digit in kid
 *
 * @param number
 * @returns {boolean}
 */
export function validateModulo10(number: string): boolean {
    const digit = modulo10(number.substring(0, number.length - 1));
    return digit == parseInt(number.charAt(number.length - 1), 10);
}

/**
 * Validates control digit in kid
 *
 * @param number
 * @returns {boolean}
 */
export function validateModulo11(number: string): boolean {
    const digit = modulo11(number.substring(0, number.length - 1));
    const lastChar = number.charAt(number.length - 1);
    const controlDigit = lastChar == '-' ? 10 : parseInt(lastChar, 10);
    return digit == controlDigit;
}
