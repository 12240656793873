import jQuery from 'jquery';

import { browserInfo } from './d-browserInfo';
import { extraFrame } from './extraFrame';
import { defaultAjaxAction } from '../o-common';

/*
 *
 */
export const sendEmail = (function ($) {
    let $dialog = $();

    function resizeHandler() {
        removeOverlay();
        addOverlay();
    }

    /**
     * Creates overlay above menu + header + main screen.
     */
    function addOverlayDesktop() {
        const $head = $('#head');
        const $scroll = $('#scrollContainer');
        const $extraFrame = $('.tmdl-layout__inset');

        $('<div class="ui-widget-overlay ui-front js-mail-overlay"></div>')
            .appendTo($head)
            .css({
                width: $head.width(),
                height: $head.height(),
            });

        $('<div class="ui-widget-overlay ui-front js-mail-overlay"></div>')
            .appendTo($scroll)
            .css({
                width: $scroll.width(),
                'margin-top': $head.height(),
                height: $scroll.height(),
                left: $extraFrame.outerWidth(),
            });

        $('<div class="ui-widget-overlay ui-front js-mail-overlay"></div>')
            .appendTo($extraFrame)
            .css({
                width: $extraFrame.outerWidth(),
                'margin-top': $head.height(),
                height: $extraFrame.height(),
            });

        $(window).on('resize', resizeHandler);
    }

    function addOverlay() {
        if (!window.narrowScreen) {
            //Mobile has overlay by default
            addOverlayDesktop();
        }
    }

    function removeOverlay() {
        $(window).off('resize', resizeHandler);
        $('.js-mail-overlay').remove();
    }

    return {
        /**
         * Used by updateProjectFinance, updateProjectPlan and invoicing.js for displaying pdf and sending email
         */
        createDialog: function createDialog(
            $this,
            $template,
            dialogTitle,
            pdfUrl,
            closeButtonTrackingID = '',
            mainButtonTrackingID = ''
        ) {
            // Make it impossible to have more than one "send preview per e-mail" dialog up at once.
            sendEmailDialogDestroyer();

            if (!window.narrowScreen) {
                extraFrame.viewerPdf(pdfUrl);
            }

            setTimeout(function () {
                const closeButton = $template
                    .data()
                    .uiDialog.uiButtonSet.children()
                    .eq(1);
                $(closeButton).attr('data-trackingid', closeButtonTrackingID);
                const mainButton = $template
                    .data()
                    .uiDialog.uiButtonSet.children()
                    .eq(0);
                $(mainButton).attr('data-trackingid', mainButtonTrackingID);
            }, 0);

            const buttons = {};
            buttons[getMessage('text_send')] = function () {
                if (!$(this).find('input').validate($(this))) {
                    return;
                }

                defaultAjaxAction($(this), 'sendEmail', null, {
                    callback: function () {
                        extraFrame.collapse();
                        sendEmailDialogDestroyer();
                    },
                });
            };
            buttons[getMessage('button_cancel')] = function () {
                extraFrame.collapse();
                sendEmailDialogDestroyer();
            };

            function sendEmailDialogDestroyer() {
                if ($dialog.dialog('instance') !== undefined) {
                    $dialog.dialog('close');
                    $dialog.dialog('destroy');
                    $dialog = $();
                    removeOverlay();
                }
                $(window).off(
                    'tlxNavigateAjax tlxtabsload',
                    sendEmailDialogDestroyer
                );
            }

            $dialog = $template.dialog({
                buttons: buttons,
                modal:
                    !!window.narrowScreen &&
                    !(browserInfo.isChrome() && browserInfo.isIphone()),
                minWidth: 490,
                title: dialogTitle,
                position: {
                    my: 'center',
                    at: 'center',
                    of: '#scrollContainer',
                },
                create: function () {
                    $(this)
                        .closest('.ui-dialog')
                        .find('.ui-dialog-buttonpane')
                        .find('.ui-button:first')
                        .addClass('tlx-green');
                },
                open: function () {
                    $(window).one(
                        'tlxNavigateAjax tlxtabsload',
                        sendEmailDialogDestroyer
                    );
                },
                close: function () {
                    sendEmailDialogDestroyer();
                    extraFrame.collapse();
                },
            });

            addOverlay();
        },
        hiddenInputsHtml: function hiddenInputsHtml(hiddenInputs) {
            return hiddenInputs
                .map(function (values) {
                    return (
                        '<input type="hidden" name="' +
                        values.name +
                        '" value="' +
                        values.value +
                        '" />'
                    );
                })
                .join('');
        },
        createDialogWithoutPreview: function createDialogWithoutPreview(
            $this,
            $template,
            dialogTitle,
            noSelection,
            missingEmail
        ) {
            // Make it impossible to have more than one dialog up at once.
            sendEmailDialogDestroyer();

            const canSend = !noSelection && missingEmail.length === 0;

            const buttons = {};
            if (canSend) {
                buttons[getMessage('text_send')] = function () {
                    if (!$(this).find('input').validate($(this))) {
                        return;
                    }

                    defaultAjaxAction($(this), 'sendEmail', null, {
                        callback: function () {
                            sendEmailDialogDestroyer();
                        },
                    });
                };
            }

            buttons[getMessage('button_cancel')] = function () {
                sendEmailDialogDestroyer();
            };

            function sendEmailDialogDestroyer() {
                if ($dialog.dialog('instance') !== undefined) {
                    $dialog.dialog('close');
                    $dialog.dialog('destroy');
                    $dialog = $();
                    removeOverlay();
                }
                $(window).off(
                    'tlxNavigateAjax tlxtabsload',
                    sendEmailDialogDestroyer
                );
            }

            extraFrame.collapse();

            $dialog = $template.dialog({
                buttons: buttons,
                modal:
                    !!window.narrowScreen &&
                    !(browserInfo.isChrome() && browserInfo.isIphone()),
                minWidth: 490,
                title: dialogTitle,
                position: {
                    my: 'center',
                    at: 'center',
                    of: '#scrollContainer',
                },
                create: function () {
                    $(this)
                        .closest('.ui-dialog')
                        .find('.ui-dialog-buttonpane')
                        .find('.ui-button:first')
                        .addClass('tlx-green');
                },
                open: function () {
                    $(window).one(
                        'tlxNavigateAjax tlxtabsload',
                        sendEmailDialogDestroyer
                    );
                },
                close: function () {
                    sendEmailDialogDestroyer();
                },
            });

            addOverlay();

            if (!canSend) {
                const $buttonPane = $dialog
                    .dialog('widget')
                    .find('.ui-dialog-buttonpane');
                const message = noSelection
                    ? getMessage('validation_no_element_selected')
                    : getMessage(
                          'validation_employee_without_email',
                          missingEmail.join(', ')
                      );
                $("<span class='warningMessage tlx-popup-extrainfo'></span>")
                    .prependTo($buttonPane)
                    .text(message)
                    .show();
            }
        },
    };
})(jQuery);
