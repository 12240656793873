import { Input, SortableTableHeader } from '@tlx/atlas';
import {
    getSortDirection,
    getSorting,
} from '@Page/LogisticsV2/utils/functions';
import { SortingType } from '@Page/LogisticsV2/utils/enums';
import useSessionStorage from '@Page/LogisticsV2/hooks/useSessionStorage';
import { ReactNode, useEffect, useRef } from 'react';

export function ProductDiscountTableHeaders({
    columns,
    children,
}: {
    columns: string[];
    children?: ReactNode;
}) {
    const [sorting, setSorting] = useSessionStorage<SortingType>(
        'goldshark_discount_overview_sorting',
        SortingType.NUMBER_DESCENDING
    );
    return (
        <thead className="atl-thead atl-thead--sticky">
            <tr className="atl-tr">
                {children}
                <NumberHeader sorting={sorting} onSortingChange={setSorting} />
                <NameHeader sorting={sorting} onSortingChange={setSorting} />
                {columns.map((column) => (
                    <ProductDiscountTableHeaderCell
                        key={column}
                        column={column}
                        sorting={sorting}
                        onSortingChange={setSorting}
                    />
                ))}
                <ActionHeader />
            </tr>
        </thead>
    );
}

function ProductDiscountTableHeaderCell({
    column,
    sorting,
    onSortingChange,
}: {
    column: string;
    sorting: SortingType;
    onSortingChange: (sorting: SortingType) => void;
}): JSX.Element {
    switch (column) {
        case 'priceExcludingVatCurrency':
            return (
                <PriceExcludingVatCurrencyHeader
                    sorting={sorting}
                    onSortingChange={onSortingChange}
                />
            );
        case 'discount':
            return <DiscountHeader />;
        case 'priceWithDiscount':
            return (
                <PriceWithDiscountHeader
                    sorting={sorting}
                    onSortingChange={onSortingChange}
                />
            );
        case 'currency':
            return <CurrencyHeader />;
        default:
            throw new Error(`Unknown column: ${column}`);
    }
}

export function CheckboxHeader({
    checked,
    indeterminate,
    onChange,
}: {
    checked: boolean;
    indeterminate: boolean;
    onChange: () => void;
}) {
    const checkboxRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (checkboxRef.current) {
            checkboxRef.current.indeterminate = indeterminate;
        }
    }, [indeterminate]);
    return (
        <th className="atl-th atl-th--checkbox">
            <Input
                ref={checkboxRef}
                type={'checkbox'}
                data-testid={'select-all-product-discounts'}
                checked={checked}
                onChange={onChange}
            />
        </th>
    );
}

function NumberHeader({
    sorting,
    onSortingChange,
}: {
    sorting: SortingType;
    onSortingChange: (sorting: SortingType) => void;
}) {
    return (
        <SortableTableHeader
            data-testid="product-discount-overview-number-header"
            className="atl-th"
            sortDirection={getSortDirection(
                sorting,
                SortingType.NUMBER_ASCENDING,
                SortingType.NUMBER_DESCENDING
            )}
            onClick={() =>
                onSortingChange(
                    getSorting(
                        sorting,
                        SortingType.NUMBER_ASCENDING,
                        SortingType.NUMBER_DESCENDING
                    )
                )
            }
        >
            {getMessage('text_number')}
        </SortableTableHeader>
    );
}

function NameHeader({
    sorting,
    onSortingChange,
}: {
    sorting: SortingType;
    onSortingChange: (sorting: SortingType) => void;
}) {
    return (
        <SortableTableHeader
            data-testid={'product-discount-overview-name-header'}
            className="atl-th"
            sortDirection={getSortDirection(
                sorting,
                SortingType.NAME_ASCENDING,
                SortingType.NAME_DESCENDING
            )}
            onClick={() =>
                onSortingChange(
                    getSorting(
                        sorting,
                        SortingType.NAME_ASCENDING,
                        SortingType.NAME_DESCENDING
                    )
                )
            }
        >
            {getMessage('text_product_name')}
        </SortableTableHeader>
    );
}

function DiscountHeader() {
    return (
        <th className="atl-th atl-text-right">{getMessage('text_discount')}</th>
    );
}

function PriceExcludingVatCurrencyHeader({
    sorting,
    onSortingChange,
}: {
    sorting: SortingType;
    onSortingChange: (sorting: SortingType) => void;
}) {
    return (
        <SortableTableHeader
            data-testid={'product-discount-overview-price-excl-vat-header'}
            className="atl-th atl-text-right"
            sortDirection={getSortDirection(
                sorting,
                SortingType.SELLING_PRICE_ASCENDING,
                SortingType.SELLING_PRICE_DESCENDING
            )}
            onClick={() =>
                onSortingChange(
                    getSorting(
                        sorting,
                        SortingType.SELLING_PRICE_ASCENDING,
                        SortingType.SELLING_PRICE_DESCENDING
                    )
                )
            }
        >
            {getMessage('text_sales_price_excl_vat')}
        </SortableTableHeader>
    );
}

function PriceWithDiscountHeader({
    sorting,
    onSortingChange,
}: {
    sorting: SortingType;
    onSortingChange: (sorting: SortingType) => void;
}) {
    return (
        <SortableTableHeader
            data-testid={'product-discount-overview-price-with-discount-header'}
            className="atl-th atl-text-right"
            sortDirection={getSortDirection(
                sorting,
                SortingType.SELLING_PRICE_WITH_DISCOUNT_ASCENDING,
                SortingType.SELLING_PRICE_WITH_DISCOUNT_DESCENDING
            )}
            onClick={() =>
                onSortingChange(
                    getSorting(
                        sorting,
                        SortingType.SELLING_PRICE_WITH_DISCOUNT_ASCENDING,
                        SortingType.SELLING_PRICE_WITH_DISCOUNT_DESCENDING
                    )
                )
            }
        >
            {getMessage('text_sales_price_excl_vat_with_discount')}
        </SortableTableHeader>
    );
}

function CurrencyHeader() {
    return <th className="atl-th">{getMessage('text_currency')}</th>;
}

function ActionHeader() {
    return <th className="atl-th" />;
}
