import { postNotificationMessage } from '@General/LegacyNotification';
import { invokeAPI, useFetchAll } from '@Page/LogisticsV2/hooks/api';
import { ApiUrl, MethodType } from '@Page/LogisticsV2/utils/enums';
import {
    getURL,
    getValidationMessagesFromResponse,
} from '@Page/LogisticsV2/utils/functions';
import { mapObjectToURLSearchParams } from '@Page/LogisticsV2/utils/mappers';
import { Dispatch, SetStateAction, useState } from 'react';
import { ProductImportType, step } from './types';

export function useFileUpload(onStepChange: Dispatch<SetStateAction<string>>) {
    const [responseFileUpload, setResponseFileUpload] =
        useState<ProductImportType>();
    const [progressFileId, setProgressFileId] = useState<number | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleUploadFile = async (file: File) => {
        setIsLoading(true);
        onStepChange(step.MAPPING);
        const formData = new FormData();
        formData.append('file', file);
        const { data, error } = await invokeAPI(
            getURL(
                ApiUrl.PRODUCT_IMPORT,
                mapObjectToURLSearchParams({
                    fields: '*,productImportHeaders(*)',
                })
            ) ?? '',
            MethodType.POST,
            formData,
            {}
        );
        if (error !== undefined) {
            getValidationMessagesFromResponse(
                error,
                getMessage('validation_could_not_upload_file')
            );
            onStepChange(step.FILE);
        } else {
            const dataJson = await data?.json();
            setResponseFileUpload(dataJson.value);
        }
        setIsLoading(false);
    };

    const { data: incompleteImports, mutate: refresh } = useFetchAll<
        ProductImportType[]
    >(
        getURL(
            `${ApiUrl.PRODUCT_IMPORT}`,
            mapObjectToURLSearchParams({
                fields: '*,importFile(id,url,fileName),employee(id,displayName, pictureId),productImportHeaders(*)',
                inProgressOnly: true,
            })
        ) ?? ''
    );

    const handleDeleteFile = async (id: number | undefined) => {
        if (id === undefined) {
            return;
        }
        const { error } = await invokeAPI(
            `${ApiUrl.PRODUCT_IMPORT}/${id}`,
            MethodType.DELETE
        );
        if (error === undefined) {
            postNotificationMessage(
                getMessage('text_import'),
                [
                    `${getMessage('text_action_completed')} ${getMessage(
                        'text_delete'
                    )}`,
                ],
                new Date()
            );
            await refresh();
        } else {
            getValidationMessagesFromResponse(error);
        }
        setProgressFileId(null);
    };

    const fileToReturn =
        progressFileId !== null
            ? incompleteImports?.find((item) => item.id === progressFileId)
            : responseFileUpload;

    return {
        incompleteImports:
            incompleteImports?.filter((item) => item.id > 0) ?? [],
        isLoading,
        uploadedFile: fileToReturn,
        onUploadFile: handleUploadFile,
        onDeleteFile: handleDeleteFile,
        onChangeImportId: setProgressFileId,
    };
}
