import jQuery from 'jquery';
import { addContextId, tlxUrl } from '../../js/modules/url';
const $ = jQuery;

/**
 * Tabs are initialized from the global function initTabs, which again is called from the page specific scripts
 * (todo: is it really necessary? perhaps do it automatically if tabs exists inside scope).
 */

$.widget('tlx.tlxTabs', $.ui.tabs, {
    options: {
        preferredNumberOfTabs: 6,
        moreTabsButtonTrackingId: '',
    },
    /**
     * Invalidates the content cache of all non-active tabs so they will be reloaded on the next activation.
     * <p>
     * Also disables all elementes in the non-active tabs. (so the user won't be able to interact with stale content)
     */
    clearCachedTabs: function () {
        var $tabs = this.element;
        var activeTab = this.options.active;

        $tabs
            .children('.ui-tabs-nav')
            .find('li')
            .each(function (i) {
                var $tabLi = $(this);
                var scopeId = $tabLi.attr('aria-controls');
                if (i === parseInt(activeTab, 10)) {
                    return true; // continue
                }
                if ($tabLi.data('cached')) {
                    var $scope = $('#' + scopeId);
                    $scope
                        .find('input, textarea, button')
                        .css('cursor', 'wait')
                        .tlxSetDisabled(true); // FRAMEWORK: performance drag... (ROA-160)
                    $scope.css('cursor', 'wait');
                    $tabLi.removeData('cached');
                }
            });
    },

    /**
     * Use the name of the tab (given with the aria-controls attribute on the <li> element in the *Menu.jsp page) to open
     * this specific tab.
     *
     * @param name
     */
    openTab: function (name) {
        var $li = $('[aria-controls=' + name + ']', this.element);

        var jquuid = $li.attr('aria-labelledby');

        $('[data-jquiid="' + jquuid + '"]').trigger('click');

        return this;
    },

    getTabIndex: function (name) {
        return $('[aria-controls=' + name + ']', this.element).index();
    },

    /**
     * Updates the tab url. The new url is saved so that it's used the next time this tab is loaded.
     *
     * NB: Does not reload the tab. Use load to trigger a reload.
     *
     * @param dontClearTabCache: If true, don't mark this tab for reload by a subsequent load
     */
    updateTabUrl: function (tabIndex, url, dontClearTabCache) {
        if (tabIndex === 'activeTab') tabIndex = this.options.active;

        var $tab = $(this.element)
            .children('.ui-tabs-nav')
            .find('li')
            .eq(tabIndex);

        // Find the url that originally was used to load this tab. This url act as a key for the saved filter state.
        var origUrl = $tab.data('originalUrl');
        if (!origUrl) {
            // Should normally be set already, but check to be safe.. (?) CLEANUP..
            origUrl = $tab.find('a').attr('href');
            $tab.data('originalUrl', origUrl);
        }
        // Remember new filter state
        $.sessionStorage.setItem(this._normalizeUrl(origUrl), url);

        if (!dontClearTabCache) {
            $tab.removeData('cached');
        }

        $tab.find('a').attr('href', url);
    },

    getUpdatedTabUrl: function (origUrl) {
        return $.sessionStorage.getItem(this._normalizeUrl(origUrl)) || origUrl;
    },

    // helper for updated url stuff.. see confluence:Navigation for more info
    _normalizeUrl: function (url) {
        return addContextId(tlxUrl.removeUrlParameter(url, 'contextId'));
    },

    _setupSticky: function () {
        const hasStickyTop = this._setupStickyTop();

        this.$sticky = $('.ui-tabs-nav');
        this.$sticky.addClass('isSticky');
        if (hasStickyTop) {
            this.$sticky.addClass('ui-tabs-nav--hasStickyTop');
        }

        /**
         * We toggle the style class "ui-tabs-nav--is-pinned" on "ui-tabs-nav" when tabs is a pixel or two
         * outside the screen. This is because we wanted a unique styling of these tabs when they are pinned/
         * sticky. Unfortunately there are no other known ways of checking if the "position: sticky" rule
         * applies on an element.
         *
         * The unique styling in question: Border bottom + showing the header inside .sticky-head.
         */
        const observer = (this.observer = new IntersectionObserver(
            ([e]) => {
                $('.sticky-head, .ui-tabs-nav').toggleClass(
                    'ui-tabs-nav--is-pinned',
                    e.intersectionRatio < 1
                );
            },
            {
                threshold: [1],
                /** Giving lots of room the the left and right, is because we don't care about horizontal scroll. **/
                rootMargin: '-52px 800px 0px 800px',
                root: document.getElementById('scrollContainer'),
            }
        ));

        observer.observe(document.querySelector('.ui-tabs-nav'));
    },

    _setupStickyTop: function () {
        const titleCategory = $('#menuHeader .titleCategory')
            .remove()
            .text()
            .trim();
        const $buttons = $('.tlx-menu-header__dropdown-buttons-container');

        if (titleCategory.length === 0 && $buttons.length === 0) {
            return false;
        }
        const clip = $('#menuHeader .clip').text();

        let $stickyHead = $('.sticky-head');

        if ($('.sticky-head').length === 0) {
            $stickyHead = $(
                `<div class="sticky-head isSticky">
<span class="sticky-head__titleCategory"></span>
<span class="sticky-head__slash">/</span>
<span class="sticky-head__header"></span>
<span class="sticky-head__buttons"></span>
</div>`
            ).insertBefore('#menuHeader');
        }

        $stickyHead.find('.sticky-head__titleCategory').text(titleCategory);
        $stickyHead.find('.sticky-head__header').text(clip);

        // Some Menu.jsp pages lacks title category. For instance WageTransaction without a voucher.
        if (titleCategory.length === 0) {
            $('.sticky-head__titleCategory, .sticky-head__slash').hide();
        }

        $(
            '#newMenuDropdown, #actionMenuDropdown, #kebabDropdown, .tlx-menu-header__dropdown-buttons-container'
        ).appendTo('.sticky-head__buttons');

        const $scrollContainer = $('#scrollContainer');

        /**
         * Ensure that the buttons dont gets stuck behind the PDF viewer.
         */
        $(window).on(
            'tlxPossibleWidthChange.tabs resize.tabs',
            $.debounce(function () {
                $stickyHead.css({
                    maxWidth: Math.min($scrollContainer.width() - 48, 984),
                });
            }, 50)
        );

        return true;
    },

    _create: function () {
        this._super();

        const numberOfTabs = this.tabs.filter(':not(.ui-helper-hidden)').length;
        if (numberOfTabs > 1) {
            this._setupSticky();
        }

        this._setupDropdownButton(
            '.js-dropdown-create-button',
            '#newMenuDropdown',
            'create-menu-button'
        );
        this._setupDropdownButton(
            '.js-dropdown-action-button',
            '#actionMenuDropdown',
            'action-menu-button'
        );
        this._setupDropdownButton(
            '.js-dropdown-kebab-button',
            '.tlx-menu',
            'kebab-menu-button'
        );

        if (numberOfTabs < 2) {
            this.tablist.hide();
            return;
        }

        this.NUMBER_OF_TABS_DISPLAYED =
            window.innerWidth < 600 ? 0 : this.options.preferredNumberOfTabs;

        // If only one tab hides behind more button, just show it
        if (numberOfTabs - 1 === this.NUMBER_OF_TABS_DISPLAYED) {
            this.NUMBER_OF_TABS_DISPLAYED++;
        }

        this._addTestIds();

        this.$moreText = $(); // dummy element so updates of this is silently ignored when not relevant
        this.$more = $();

        if (numberOfTabs > this.NUMBER_OF_TABS_DISPLAYED) {
            this.renderedMoreButton = true;
            this._createMoreButton();
        }

        $(window).on(
            'tlxPossibleWidthChange.tabs resize.tabs',
            this._adjustMenuHeaderWidth
        );

        this._adjustMenuHeaderWidth();
    },

    _setupDropdownButton: function (buttonSelector, menuSelector, testId) {
        const $button = $(buttonSelector).attr('data-testid', testId);
        const $menu = $(menuSelector);
        let menuOpen = false;

        $button.on('click', () => {
            if (menuOpen) {
                $menu.hide();
            } else {
                $menu.show();
                $menu.position({
                    of: $button,
                    at: 'left bottom',
                    my: 'left top+4px',
                    collision: 'flip fit',
                    within: '#scrollContainer',
                });
            }
            menuOpen = !menuOpen;
        });

        $(window).on('click.tabs', (e) => {
            if ($(e.target).closest(buttonSelector).length > 0) {
                return;
            }
            if (menuOpen) {
                $menu.hide();
                menuOpen = false;
            }
        });
    },

    _createMoreButton: function () {
        this.menuOpen = false;
        const $menu = (this.$menu = $(
            '<ul class="ui-tab__more-menu" style="display: none;" data-testid="tabs-more-menu"></ul>'
        ));
        const $more = $(
            '<button class="ui-tab__more-button" data-testid="tabs-more-button" data-trackingid="' +
                this.options.moreTabsButtonTrackingId +
                '"></button>'
        ).on('click', () => {
            this.menuOpen = !this.menuOpen;
            $menu.toggle(this.menuOpen);
            if (this.menuOpen) {
                // A bit hackish, make sure more menu actually is above content when opened.
                if (document.body.classList.contains('jq-dialog-is-open')) {
                    window.Sentry.captureException(
                        new Error(
                            'jQ UI Dialog reported as open, but menu button clicked!'
                        )
                    );
                    document.body.classList.remove('jq-dialog-is-open');
                }
                $menu.position({
                    of: $more,
                    at: 'left bottom',
                    my: 'left top+4px',

                    /**
                     * The default collision is "flip" which when invoked on a menu containing very many items on a narrow screen
                     * causes parts of the menu to become inaccessible.
                     *
                     * By setting this to "none" we assume the page will be longer than the menu.
                     *
                     * @see https://jira.visma.com/browse/TRIP-27196
                     */
                    collision: 'none',
                });
            }
        });

        this._documentClickHandler = this._documentClickHandler.bind(this);
        document.addEventListener('click', this._documentClickHandler, true);

        const activeIndex = this.tabs.index(this.active);
        const moreIsActive = activeIndex >= this.NUMBER_OF_TABS_DISPLAYED;
        const moreText = moreIsActive
            ? this.active.text()
            : getMessage('text_more');

        if (moreIsActive) {
            $more.addClass('ui-tab__more-button--active');
        }

        this.$more = $more;
        this.$moreText = $(`<span class="js-more-text">${moreText}</span>`);

        $more
            .append(this.$moreText)
            .append(
                '<span class="material-icons ui-tab__more-menu-icon">arrow_drop_down</span>'
            );

        for (let i = this.NUMBER_OF_TABS_DISPLAYED; i < this.tabs.length; i++) {
            const $tab = $(this.tabs[i]);
            const $menuItem = $('<a class="ui-tab__more-menu-item"></a>');
            const testId = $tab.attr('data-testid');
            const tabLinkTrackingId = $tab
                .children()
                .first()
                .attr('data-trackingid');
            $tab.hide().removeAttr('data-testid');
            $menuItem.text($tab.text());
            $menuItem.attr('href', $tab.find('a').attr('href'));
            $menuItem.attr('data-testid', testId);
            $menuItem.attr('data-trackingid', tabLinkTrackingId);
            $menu.append($menuItem);
            $menuItem.on('click', function (e) {
                $tab.find('a')[0].click();
                e.preventDefault();
            });
            /**
             * We want to prevent context menu and middle click on these links, because the links will not work. They
             * lack contextId (easy to fix) and they will not take you to the Menu page, but the specific tab (more
             * difficult to fix).
             */
            $menuItem
                .on('contextmenu', function (e) {
                    e.preventDefault();
                })
                .on('auxclick', function (e) {
                    e.preventDefault();
                })
                // Safari doesn't support the auxclick event
                .on('mousedown', function (e) {
                    // e.which is deprecated, but also kinda not: https://github.com/jquery/jquery/issues/4755#issuecomment-664501730
                    if (e.which === 2) {
                        e.preventDefault();
                    }
                });
        }

        this.tablist.append($more).append($menu);
    },

    /**
     * Adjust the widht of the menu header, according to how much actual space there are within the #scrollContainer.
     * This is needed to prevent the PDF viewer in extraFrame to hide/overlap the menuHeader and the buttons in the
     * menuHeader.
     * @private
     */
    _adjustMenuHeaderWidth() {
        $('#menuHeader').css({ maxWidth: $('#scrollContainer').width() - 20 });
    },

    _addTestIds: function () {
        this.tabs.attr('data-testid', function () {
            const id = $(this).attr('aria-controls');

            return `tab-${id}`;
        });
    },

    _documentClickHandler: function () {
        if (this.menuOpen) {
            this.menuOpen = false;
            this.$menu.toggle(this.menuOpen);
        }
    },

    /**
     * This is run whenever GUI switches from one tab to another, thus handy to replace the "More" menu text.
     * OBS: Is not run when tabs is initialized, so if active tab is in the More menu, correct txt will not
     * be set by this _toggle method.
     */
    _toggle: function (event, eventData) {
        this._super(event, eventData);

        const $newTab = eventData.newTab;
        if (
            this.tabs.index(eventData.newTab[0]) >=
            this.NUMBER_OF_TABS_DISPLAYED
        ) {
            this.$moreText.text($newTab.text());
            this.$more.addClass('ui-tab__more-button--active');
        } else {
            this.$moreText.text(getMessage('text_more'));
            this.$more.removeClass('ui-tab__more-button--active');
        }
    },

    /**
     * Overrides jQuery UI tab load function. This is always run when loading the tab,
     * AND when showing the tab with preloaded content.
     *
     * @param i the index of the tab loaded
     */
    load: function (i) {
        /**
         * This makes sure the JSPDropdowns are unmounted when the content of the tab is reloaded.
         * Happens on save, for instance.
         *
         * HOTFIX: react code in tabs isn't mounted again, on pre-loaded tabs so this doesn't work!
         *
        $(this.panels[i]).trigger('tlxRemoveUpgradedMdlComponents');
         */
        this._updateUrlWithTab(i);
        this._super(i);
    },

    _updateUrlWithTab: function (index) {
        const tab = this.tabs[index];
        const name = tab.getAttribute('aria-controls');
        const tabUrl = new URL(location.href);
        tabUrl.hash = '';

        /**
         * If the <li> tab element havent been given aria-controls, jQuery UI automatically generates one instead.
         * This generated ui-id will change every time you open the browser, and from user to user, so this is not
         * usable as part of URL. Therefore we demand that all tab LIs has an aria-controls.
         */
        if (name.startsWith('ui-id')) {
            window.Sentry.captureException(
                new Error('All tabs MUST be given an aria-controls!')
            );
        } else if (index > 0) {
            // Only tabs after the first tab should add a hash to the URL
            tabUrl.hash = name;
        }

        if (
            tabUrl.pathname !== window.location.pathname ||
            tabUrl.search !== window.location.search ||
            tabUrl.hash !== window.location.hash
        ) {
            window.history.replaceState(null, '', tabUrl.toString());
            $(window).trigger('tlxUrlChanged');
        }
    },

    _destroy: function () {
        this._super();

        this.$sticky.removeClass('isSticky');

        if (this.observer) {
            this.observer.disconnect();
        }

        document.removeEventListener('click', this._documentClickHandler, true);

        $(window).off('.tabs');
    },
});
