import * as React from 'react';
import { clsx } from 'clsx';

import { Spinner } from '@tlx/atlas';

import './LoadingSpinner.scss';

export type LoadingSpinnerProps = React.HTMLAttributes<HTMLDivElement>;

/**
 * @deprecated Use `<Spinner>` from `@tlx/atlas` instead.
 */
export const LoadingSpinner: React.FunctionComponent<LoadingSpinnerProps> = ({
    className,
    ...divProps
}) => (
    <div className={clsx('txr-spinner', className)} {...divProps}>
        <Spinner />
    </div>
);
