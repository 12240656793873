import { call, put, takeEvery } from 'redux-saga/effects';

import tlxFetch, {
    isValidApiResponse,
    StandardResponse,
} from '../../../../../api/tlxFetch';

import { FetchData, StoreData } from '../action/LogisticsSettingsData.action';
import { LogisticsSettingsModel } from '../type/LogisticsSettingsData.type';

async function doFetchData(): Promise<
    StandardResponse<LogisticsSettingsModel>
> {
    return tlxFetch({
        method: 'GET',
        url: '/v2/product/logisticsSettings',
    });
}

export function* sagaFetchData() {
    const {
        response: logisticsSettings,
        err,
    }: StandardResponse<LogisticsSettingsModel> = yield call(doFetchData);

    if (!err && logisticsSettings && isValidApiResponse(logisticsSettings)) {
        yield put(StoreData({ logisticsSettings }));
    }
}

export const logisticsSettingsDataSaga = [
    takeEvery(FetchData.type, sagaFetchData),
];
