import { ActionReducer } from '../types/Reducer';
import { DeveloperState } from '@General/types/Developer.type';
import { SetDeveloper } from '@General/action/Developer.action';

export const initialState: DeveloperState = {
    data: false,
};

const reducerSetDeveloper: ActionReducer<DeveloperState, SetDeveloper> = (
    state,
    action
) => ({
    ...state,
    data: action.developer,
});

type SupportedActions = SetDeveloper;

export function reduceDeveloper(
    state: DeveloperState = initialState,
    action: SupportedActions
): DeveloperState {
    switch (action.type) {
        case SetDeveloper.type:
            return reducerSetDeveloper(state, action as SetDeveloper);
        default:
            return state;
    }
}
