import * as React from 'react';

import { ButtonIcon, ButtonIconProps } from '@Component/ButtonIcon';

import './SectionToggle.scss';

export interface SectionToggleProps {
    onClick: ButtonIconProps['onClick'];
    isSectionOpen: boolean;
}

export const SectionToggle: React.FunctionComponent<SectionToggleProps> = (
    props
) => (
    <ButtonIcon
        className="tlx-section-toggle"
        icon="keyboard_arrow_up"
        onClick={props.onClick}
        toolTip={
            props.isSectionOpen
                ? getMessage('text_hide_details')
                : getMessage('text_view_details')
        }
    />
);
