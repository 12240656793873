import { JSPDropdownFetcher } from '@Component/DropDown/JSPDropdown/JSPDropdownFetcher';
import { ApiResultMapper } from '@General/Provider';
import { ModelAPIV2 } from '@General/types/Api';
import { MappedResponse } from '@General/Provider/Fetcher';
import tlxFetch from '../../../../api/tlxFetch';

interface DynamicControlFormTemplateObject extends ModelAPIV2 {
    readonly type: string;
    readonly description: string;
}

interface DynamicControlFormTemplate {
    type: string;
    description: string;
}

const dynamicControlFormTemplateMapper: ApiResultMapper<DynamicControlFormTemplateObject> =
    (item) => {
        return {
            value: item.id?.toString() ?? '0',
            displayName: item.type,
            secondary: item.description,
            item,
        };
    };

class DynamicControlFormTemplateFetcher extends JSPDropdownFetcher<DynamicControlFormTemplateObject> {
    asyncJsonrpcGetter: Function;

    constructor(scope: HTMLDivElement, queryAttributes: Record<string, any>) {
        super(scope, queryAttributes, dynamicControlFormTemplateMapper);

        this.asyncJsonrpcGetter = asyncrpc.DynamicControlFormTemplate.get;
    }

    getMarshallSpec() {
        return window.marshallSpec('id', 'type', 'description');
    }

    getParams(): ObjectIndex {
        return {};
    }

    async fetchList(
        params: ObjectIndex
    ): Promise<MappedResponse<DynamicControlFormTemplateObject>> {
        return new Promise((resolve, reject) => {
            try {
                tlxFetch({
                    url: this.getQueryURL(params),
                    method: 'GET',
                }).then((value) => {
                    resolve(value.response.map(this.getMapper(params)));
                });
            } catch (e) {
                defaultJsonRpcExceptionHandler(e);
                reject(e);
            }
        });
    }

    getQueryURL(params: ObjectIndex) {
        const url = new URL(
            '/v2/project/dynamicControlForm/template',
            window.location.origin
        );
        const searchParams = url.searchParams;
        searchParams.set('isArchived', 'false');
        searchParams.set('type', params.name);
        return url.toString();
    }
}

export const exports = {
    fetcher: DynamicControlFormTemplateFetcher,
    type: 'DynamicControlFormTemplate',
};
