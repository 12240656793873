import { PaymentWidgetDropdown } from '@Page/PaymentWidget/component/PaymentWidgetDropdown/PaymentWidgetDropdown';
import React from 'react';
import { PaymentType } from '@Page/PaymentWidget/types/paymentWidgetTypes';

type PaymentTypeDropdownProps = {
    paymentTypes: PaymentType[];
    atlasDesignSystem: boolean;
    validationMessage: string;
    resetValidationMessage: () => void;
    setDropdownOption: (paymentType: PaymentType) => void;
    id: string;
    selectedPaymentType: PaymentType;
};

export const PaymentTypeDropdown = ({
    id,
    paymentTypes,
    atlasDesignSystem,
    validationMessage,
    resetValidationMessage,
    setDropdownOption,
    selectedPaymentType,
}: PaymentTypeDropdownProps) => {
    const setPaymentType = (paymentTypeId: string) => {
        const selectedPaymentType = paymentTypes.find(
            (pt) => pt.paymentTypeId === parseInt(paymentTypeId)
        );
        if (selectedPaymentType) {
            setDropdownOption(selectedPaymentType);
        }
    };

    const options = paymentTypes.map((paymentType) => ({
        displayName:
            paymentType.paymentTypeValue.toString() ||
            paymentType.paymentTypeId.toString(),
        value: [paymentType.paymentTypeId].toString(),
    }));

    return (
        <PaymentWidgetDropdown
            name="paymentWidgetWrapper.paymentType"
            id={id}
            options={options}
            atlasDesignSystem={atlasDesignSystem}
            dropdownOption={selectedPaymentType.paymentTypeId.toString()}
            setDropdownOption={setPaymentType}
            data-testid="payment-widget-payment-type-dropdown"
            validationMessage={validationMessage}
            resetValidationMessage={resetValidationMessage}
        >
            {getMessage('text_payment_type')}
        </PaymentWidgetDropdown>
    );
};
