import {
    Button,
    Modal,
    ModalContent,
    ModalFooter,
    ModalLayout,
    ModalTitle,
} from '@tlx/atlas';
import './SupportReadAccessRequestDialog.css';

export type SupportReadAccessRequestDialogProps = {
    isOpen?: boolean;
    onAccept: () => void;
    onReject: () => void;
};

export function SupportReadAccessRequestDialog({
    isOpen,
    onAccept,
    onReject,
}: SupportReadAccessRequestDialogProps) {
    if (!isOpen) {
        return null;
    }

    return (
        <Modal open className="tlx-support-access-read-request-dialog">
            <ModalLayout>
                <ModalTitle className="atl-pb-0">
                    <img
                        src="/resources/imgs/support_access_illustration.svg"
                        alt={getMessage('text_access_control')}
                        className="atl-mb-16"
                    />
                    <div>{getMessage('text_access_control')}</div>
                </ModalTitle>
                <ModalContent>
                    <div className="atl-text-sm atl-font-bold atl-mb-4">
                        {getMessage(
                            'text_support_access_account_request_dialog_title'
                        )}
                    </div>
                    <div className="atl-text-sm atl-flex-1 atl-text-left">
                        {getMessage(
                            'text_support_access_account_request_dialog_message_text1'
                        )}
                    </div>
                    <div className="atl-text-sm atl-flex-1 atl-text-left">
                        {getMessage(
                            'text_support_access_account_request_dialog_message_text2'
                        )}
                    </div>
                </ModalContent>
                <ModalFooter>
                    <div className="atl-flex atl-gap-8">
                        <Button
                            onClick={onAccept}
                            variant="primary"
                            data-testid="support-read-access-request-dialog-approve-button"
                        >
                            {getMessage('text_approve')}
                        </Button>
                        <Button
                            onClick={() => {
                                onReject();
                                window.location.reload();
                            }}
                            variant="secondary"
                            data-testid="support-read-access-request-dialog-reject-button"
                        >
                            {getMessage('text_reject')}
                        </Button>
                    </div>
                </ModalFooter>
            </ModalLayout>
        </Modal>
    );
}
