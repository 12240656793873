import * as React from 'react';
import { Button } from '@Component/Button';
import './Footer.scss';
import { createPortal } from 'react-dom';

export type FooterProps = {
    action: () => void;
    testId?: string;
    textButton?: string;
};

export class Footer extends React.Component<FooterProps> {
    constructor(props: FooterProps) {
        super(props);
    }

    render() {
        return createPortal(
            <div className="tlx-footer mdl-shadow--8dp">
                <Button
                    raised
                    onClick={() => this.props.action()}
                    testId={this.props.testId}
                >
                    {this.props.textButton ?? getMessage('button_save')}
                </Button>
            </div>,
            document.querySelector('body') as HTMLBodyElement
        );
    }
}
