import { ActionCreator } from '@General/types/Action';
import { DeveloperMetaData } from '@General/types/Developer.type';

export const namespace = 'Developer';

const SetDeveloperType = `${namespace}Set`;
export const SetDeveloper = ActionCreator<
    typeof SetDeveloperType,
    {
        readonly developer: DeveloperMetaData | false;
    }
>(SetDeveloperType);
export type SetDeveloper = ReturnType<typeof SetDeveloper>;
