import React, { useState } from 'react';
import { ProductLineDTO } from '@Page/LogisticsV2/pages/Stocktaking/utils';
import {
    Button,
    KebabIcon,
    PopupMenu,
    PopupMenuItemButton,
    Textarea,
    usePopupMenu,
} from '@tlx/atlas';
import { LogisticsModal } from '@Page/LogisticsV2/components/Modals/Modal';
import {
    getDisplayNumber,
    getValidationMessagesFromResponse,
} from '@Page/LogisticsV2/utils/functions';
import { useStocktakingDetails } from '@Page/LogisticsV2/pages/Stocktaking/details2/useStocktakingDetails';
import { AutoSaveStatusType } from '@Page/LogisticsV2/utils/enums';
import {
    doUnCountLine,
    doUpdateLine,
} from '@Page/LogisticsV2/pages/Stocktaking/details2/StocktakingDetailsActions';

export function StocktakingDetailsMobileHeader({
    line,
    onChange,
    onEditLocation,
}: {
    line: ProductLineDTO;
    onChange: (
        status: AutoSaveStatusType,
        lineId?: number,
        shouldAnimate?: boolean
    ) => void;
    onEditLocation: (value: boolean) => void;
}) {
    const stocktaking = useStocktakingDetails();
    const handleChangeLine = async (value: string) => {
        onChange(AutoSaveStatusType.PENDING);
        const { error } = await doUpdateLine(line.id, undefined, value);
        if (error === undefined) {
            onChange(AutoSaveStatusType.SAVED);
        } else {
            onChange(AutoSaveStatusType.ERROR);
            getValidationMessagesFromResponse(
                error,
                getMessage('validation_cannot_save_count')
            );
        }
    };

    const handleUnCountLine = async () => {
        onChange(AutoSaveStatusType.PENDING);
        const { error } = await doUnCountLine(line.id);
        if (error === undefined) {
            onChange(AutoSaveStatusType.SAVED);
        } else {
            onChange(AutoSaveStatusType.ERROR);
            getValidationMessagesFromResponse(
                error,
                getMessage('validation_cannot_uncount_line')
            );
        }
    };

    if (stocktaking.data === undefined) {
        return null;
    }
    return (
        <thead className="atl-thead">
            <tr className="atl-tr">
                <MobileNumberHeader number={getDisplayNumber(line.product)} />
                <MobileNameHeader
                    name={line.product.name}
                    productId={line.product.id}
                />
                <MobileActionsHeader
                    line={line}
                    isStocktakingCompleted={false}
                    onEditLocation={() => onEditLocation(true)}
                    onUnCountLine={handleUnCountLine}
                    onUpdateComment={(value) => handleChangeLine(value)}
                />
            </tr>
        </thead>
    );
}

function MobileNumberHeader({ number }: { number?: string }) {
    return (
        <th className="atl-th atl-font-normal" style={{ maxWidth: '100px' }}>
            <div>{getMessage('text_number')}</div>
            <div
                className="atl-text-grey-40"
                style={{ overflowWrap: 'anywhere' }}
            >
                {number}
            </div>
        </th>
    );
}

function MobileNameHeader({
    name,
    productId,
}: {
    name?: string;
    productId: number;
}) {
    return (
        <th className="atl-th atl-font-normal" colSpan={2}>
            <div>{getMessage('text_name')}</div>
            <div className="atl-text-grey-40">
                <a
                    href={addContextId(
                        `/execute/productMenu?productId=${productId}`
                    )}
                    target="_blank"
                >
                    {name}
                </a>
            </div>
        </th>
    );
}

function MobileActionsHeader({
    line,
    isStocktakingCompleted,
    onEditLocation,
    onUnCountLine,
    onUpdateComment,
}: {
    line: ProductLineDTO;
    isStocktakingCompleted: boolean;
    onEditLocation: () => void;
    onUnCountLine: () => void;
    onUpdateComment: (value: string) => void;
}) {
    const [showComment, setShowComment] = useState(false);
    const [internalComment, setInternalComment] = useState(line.comment ?? '');
    const { openerButtonProps, popupMenuProps } = usePopupMenu();
    return (
        <th className="atl-th atl-pt-16 atl-align-middle atl-td--button atl-flex atl-justify-end atl-mr-16">
            <div>
                <Button
                    {...openerButtonProps}
                    data-testid="open-button"
                    variant="icon"
                    aria-label="stocktaking-row-action-button"
                >
                    <KebabIcon />
                </Button>
                <PopupMenu {...popupMenuProps} className={'atl-bg-white'}>
                    <PopupMenuItemButton
                        data-trackingid={'text_comment'}
                        data-testid={'stocktaking-comment'}
                        onClick={() => setShowComment(true)}
                    >
                        {getMessage('text_comment')}
                    </PopupMenuItemButton>
                    {!line.counted &&
                        isLocationEnabled &&
                        !isStocktakingCompleted && (
                            <PopupMenuItemButton
                                data-trackingid={'text_edit_location'}
                                data-testid={'stocktaking-edit-location'}
                                onClick={onEditLocation}
                            >
                                {getMessage('text_edit_location')}
                            </PopupMenuItemButton>
                        )}
                    {line.counted && !isStocktakingCompleted && (
                        <PopupMenuItemButton
                            data-testid={'stocktaking-uncount-line'}
                            data-trackingid={'text_inventory_uncount_line'}
                            onClick={onUnCountLine}
                        >
                            {getMessage('text_inventory_uncount_line')}
                        </PopupMenuItemButton>
                    )}
                </PopupMenu>
            </div>
            {showComment && (
                <LogisticsModal
                    title={getMessage('text_comment')}
                    svgIcon="comment"
                    onCancel={() => setShowComment(false)}
                    onConfirm={() => {
                        setShowComment(false);
                        onUpdateComment(internalComment);
                    }}
                >
                    <Textarea
                        data-testid="modal-comment-textarea"
                        className={
                            'atl-flex atl-gap-8 atl-mb-16 goldshark-comment-modal-textarea'
                        }
                        placeholder={getMessage('text_add_comment')}
                        value={internalComment}
                        onChange={(e) => setInternalComment(e.target.value)}
                    />
                </LogisticsModal>
            )}
        </th>
    );
}
