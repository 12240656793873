import jQuery from 'jquery';
const $ = jQuery;

/**
 * Created by Ine on 12/02/18.
 *
 * Belong to the nav-mainMenu-simple.jsp
 */

$(function () {
    // The nav-mainMenu-simple.jsp is only used when Tripletex is used as an integration (e.g. agro). It's therefore no
    // need to run this javascript if that's not the case
    if (!window.integration) {
        return;
    }

    const $menuContainer = $('.menu-container');
    const $mainMenuSimple = $('#main-menu--simple');

    _removeUnnecessarySubMenus();

    //************************ EVENT LISTENERS *************************/

    $(window).on('tlxNavigateAjax', function () {
        $('.menu-entry:not(.sub-menu).open').removeClass('open');
    });

    $('.menu-entry').click(function (e) {
        const $this = $(e.currentTarget);

        if ($this.is('.sub-menu')) {
            e.stopPropagation(); // This is causing the whole page to refresh, why?
        }

        _makeMenuEntryActive(e);
        _openMenuEntry(e);
    });

    $('.menu-title > span', $menuContainer).each(function (_, menuEntry) {
        $(menuEntry).attr('data-text', $(menuEntry).text());
    });

    $(window).on('tlxtabsload', function () {
        $('.mdl-layout__tab').each(function (_, tab) {
            $(tab).attr('data-text', $(tab).text());
        });
    });

    //************************ PRIVATE METHODS *************************/

    function _removeUnnecessarySubMenus() {
        $mainMenuSimple.find('.menu-items').each(function (_, menu) {
            const $menu = $(menu);
            const numberOfItems = $menu.find('> li').length;

            if (numberOfItems === 1) {
                const $menuItems = $menu.find('> li');
                const $parentMenu = $menu.parent();

                if (!$parentMenu.is('.sub-menu')) {
                    $menuItems.addClass('menu-entry');
                    const $linkItems = $menuItems.find('> a');

                    $linkItems.each(function (_, item) {
                        $(item).wrap(
                            '<div><span data-text="' +
                                $(item).text() +
                                '"></span></div>'
                        );
                    });
                }

                $parentMenu.replaceWith($menuItems.removeClass('sub-menu'));
            }
        });
    }

    function _makeMenuEntryActive(e) {
        const $this = $(e.currentTarget);

        $('.menu-entry')
            .not($this.parent().closest('.menu-entry'))
            .not($this)
            .removeClass('active');

        $this.not('.sub-menu').addClass('active');
    }

    function _openMenuEntry(e) {
        const $this = $(e.currentTarget);
        const $menuEntryItems = $this.find('.menu-items');

        //we only want one menu category to be open at the time
        $('.menu-entry')
            .not($this.parent().closest('.menu-entry'))
            .not($this)
            .removeClass('open');

        $this.toggleClass('open', !$menuEntryItems.is(':visible'));
    }
});
