import { DataTreeNode } from '@Page/LogisticsV2/components/DataTree/types';
import {
    Currency,
    InventoryLocationDTO,
    ResaleProductDTO,
} from '@Page/LogisticsV2/types/dtoTypes';
import { SortingType } from '@Page/LogisticsV2/utils/enums';

export type ProductDTO = {
    id: number;
    name: string;
    number: number;
    displayNumber: string;
    displayName: string;
    costExcludingVatCurrency: number;
    priceExcludingVatCurrency: number;
    priceIncludingVatCurrency: number;
    currency: Currency;
    stockOfGoods: number;
    isStockItem: boolean;
    isDeletable: boolean;
    account: { id: number; displayName: string };
    productUnit: {
        id: number;
        nameShort: string;
        nameShortEN: string;
        nameEN: string;
    };
    vatType: { id: number; displayName: string };
    inventories?: StockDTO[];
    ean: string;
    availableStock: number;
    incomingStock: number;
    outgoingStock: number;
    costCurrency: number;
    description: string;
    orderLineDescription: string;
};

export type StockDTO = {
    inventoryId?: number;
    inventory: string;
    inventoryLocation?: InventoryLocationDTO;
    closingStock: number;
};

export type InventoriesDTO = {
    product: ProductDTO;
    stock: StockDTO[];
};

export type InventoryDTO = {
    id: number;
    name: string;
    isMainInventory: boolean;
    displayName: string;
};

export type ProductInventoryLocationDTO = {
    id: number;
    product: ResaleProductDTO;
    inventory: InventoryDTO;
    inventoryLocation?: InventoryLocationDTO;
    isMainLocation: boolean;
    stockOfGoods: number;
};

export type ProductOverviewModel = {
    totalLines: ProductDTO[];
    data: ProductDTO[];
    columns: string[];
    error: any;
    filters: ProductOverviewFilterModel;
    hasMore: boolean;
    isExporting: boolean;
    isLoading: boolean;
    isTotalLinesLoading: boolean;
    loadMore: () => void;
    notDeletableIds: Array<{ id: number }>;
    selectedIds: Array<{ id: number }>;
    sorting: SortingType;
    getNotDeletableProducts: () => void;
    onChange: () => void;
    onChangePrice: (
        formElement: HTMLFormElement,
        values: { [k: string]: FormDataEntryValue }
    ) => void;
    onChangeSelectedIds: React.Dispatch<
        React.SetStateAction<
            {
                id: number;
            }[]
        >
    >;
    onChangeSorting: (sorting: SortingType) => void;
    onDelete: (id: number) => void;
    onExport: () => void;
    onNext: (type: string, isPurchasePrice: boolean) => Promise<number>;
    onSelectAll: () => void;
};

export type ProductOverviewFilterModel = {
    incomeAccountFilter: string;
    inventoryFilter: string;
    productGroupsFilter: string[];
    productUnitFilter: string;
    query: string;
    statusFilter: string[];
    supplierFilter: string[];
    withoutMainSupplier: string;
    stockItemFilter: string;
};

export type ProductOverviewBulkActionsModel = {
    nodes: DataTreeNode[];
    selectedNodes: number[];
    loadingBulkGroups: boolean;
    onAddProductGroups: () => void;
    onBulkDelete: () => void;
    onBulkInactivate: () => void;
    onChangeSelectedNodes: (nodeId: number) => void;
    onChangeProductDetails: (
        formElement: HTMLFormElement,
        values: { [k: string]: FormDataEntryValue }
    ) => void;
    onChangeProductDetailsRackbeat: (
        formElement: HTMLFormElement,
        values: { [k: string]: FormDataEntryValue }
    ) => void;
    onRemoveProductGroups: () => void;
    onResetSelectedNodes: (selectedNodes: number[]) => void;
};

export const PRODUCT_OVERVIEW_NON_LOGISTICS_FIELDS =
    'id,number,displayNumber,name,isStockItem,isDeletable,';
