import React, { useEffect, useState } from 'react';
import {
    PaymentType,
    PaymentTypeEnum,
    PaymentWidgetAdvancedData,
} from '@Page/PaymentWidget/types/paymentWidgetTypes';
import _ from 'lodash';
import { CreditNotesDetailsProps } from '@Page/PaymentWidget/component/AdvancedPaymentWidgetTemplate';

/**
 * Events are only used in following files.
 * - incomingInvoiceJS.jsp
 * - incomingInvoiceOrderLineModeJS.jsp
 */
export const usePaymentWidgetEventHandlers = (
    paymentWidgetData: PaymentWidgetAdvancedData,
    setPaymentWidgetData: (data: Partial<PaymentWidgetAdvancedData>) => void
) => {
    const [creditNotesDetails, setCreditNotesDetails] = useState<
        CreditNotesDetailsProps | undefined
    >();

    const changeKid = React.useCallback(
        (event: AdvancedPaymentWidgetKidChangedEvent) => {
            setPaymentWidgetData({
                kid: event.detail.kid,
            });
        },
        []
    );

    const handleCreditNotes = (
        customEvent: any,
        newData: Partial<PaymentWidgetAdvancedData>,
        notPaidPaymentType: any
    ) => {
        let cnDetails = undefined;

        if (customEvent.detail.amountSubstracted) {
            cnDetails = {
                amountSubstracted: customEvent.detail.amountSubstracted,
                totalRemaining: customEvent.detail.amount,
            };
        }

        setCreditNotesDetails(cnDetails);

        if (
            customEvent.detail.amountSubstracted > 0 &&
            customEvent.detail.amount === 0
        ) {
            newData = {
                ...newData,
                selectedPaymentType: notPaidPaymentType,
            };
        } else if (
            _.isEqual(
                paymentWidgetData.selectedPaymentType,
                notPaidPaymentType
            ) &&
            !notPaidPaymentType?.isDefault
        ) {
            const defaultPaymentType = paymentWidgetData.paymentTypes.find(
                (paymentType: PaymentType) => paymentType.isDefault === true
            );
            newData = {
                ...newData,
                ...(!!defaultPaymentType && {
                    selectedPaymentType: defaultPaymentType,
                }),
            };
        }

        return newData;
    };

    const changeAmount = React.useCallback(
        (event: AdvancedPaymentWidgetAmountChangedEvent) => {
            let newData: Partial<PaymentWidgetAdvancedData> = {};
            const notPaidPaymentType = paymentWidgetData.paymentTypes.find(
                (paymentType: PaymentType) =>
                    paymentType.paymentIntegration === PaymentTypeEnum.NOT_PAID
            );

            if (event.detail.amount < 0) {
                if (notPaidPaymentType) {
                    newData = {
                        ...newData,
                        selectedPaymentType: notPaidPaymentType,
                        amount: event.detail.amount,
                    };
                }
            } else {
                newData = {
                    ...newData,
                    amount: event.detail.amount,
                    currencyId: event.detail.currencyId,
                    currencyCode: event.detail.currencyCode,
                };
                newData = handleCreditNotes(event, newData, notPaidPaymentType);
            }
            setPaymentWidgetData(newData);
        },
        [paymentWidgetData]
    );

    const changeCurrency = React.useCallback(
        (event: AdvancedPaymentWidgetCurrencyChangedEvent) => {
            setPaymentWidgetData({
                currencyId: event.detail.currencyId,
                currencyCode: event.detail.currencyCode,
            });
        },
        []
    );

    const changeDueDate = React.useCallback(
        (event: AdvancedPaymentWidgetDueDateChangedEvent) => {
            setPaymentWidgetData({
                date: event.detail.date,
            });
        },
        []
    );

    useEffect(() => {
        document.addEventListener(
            'advancedPaymentWidget:kid:changed',
            changeKid
        );

        return () => {
            document.removeEventListener(
                'advancedPaymentWidget:kid:changed',
                changeKid
            );
        };
    }, [changeKid]);

    useEffect(() => {
        document.addEventListener(
            'advancedPaymentWidget:amount:changed',
            changeAmount
        );

        return () => {
            document.removeEventListener(
                'advancedPaymentWidget:amount:changed',
                changeAmount
            );
        };
    }, [changeAmount]);

    useEffect(() => {
        document.addEventListener(
            'advancedPaymentWidget:currency:changed',
            changeCurrency
        );

        return () => {
            document.removeEventListener(
                'advancedPaymentWidget:currency:changed',
                changeCurrency
            );
        };
    }, [changeCurrency]);

    useEffect(() => {
        document.addEventListener(
            'advancedPaymentWidget:dueDate:changed',
            changeDueDate
        );

        return () => {
            document.removeEventListener(
                'advancedPaymentWidget:dueDate:changed',
                changeDueDate
            );
        };
    }, [changeDueDate]);

    return {
        creditNotesDetails,
    };
};
