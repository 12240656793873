import { ModelAPIV1 } from '@General/types/Api';
import { Option } from '../../types';
import { JSPDropdownFetcher } from '../JSPDropdownFetcher';
import { ApiResultMapperNoUndefinedResult } from '@General/Provider';
import { notUndefined } from '@General/Helpers';

interface TripletexSalesModuleTypeEntry extends ModelAPIV1 {
    readonly displayName: string;
}

const tripletexSalesModuleTypeMapper: ApiResultMapperNoUndefinedResult<TripletexSalesModuleTypeEntry> =
    (item) => ({
        value: item.id + '',
        displayName: item.displayName,
        item,
    });

class TripletexSalesModuleTypeFetcher extends JSPDropdownFetcher<TripletexSalesModuleTypeEntry> {
    asyncJsonrpcGetter: Function;

    constructor(scope: HTMLDivElement, queryAttributes: Record<string, any>) {
        super(scope, queryAttributes, tripletexSalesModuleTypeMapper);
        this.asyncJsonrpcGetter = asyncrpc.TripletexSalesModuleType.get;
    }

    getParams(): ObjectIndex {
        return {
            baseModule: this.getQueryAttribute('baseModule', 42),
        };
    }

    async fetchList(
        params: ObjectIndex
    ): Promise<Option<TripletexSalesModuleTypeEntry>[]> {
        return new Promise((resolve, reject) => {
            try {
                jsonrpc.TripletexSalesModuleType.searchAll(
                    (result: TripletexSalesModuleTypeEntry[] | null) => {
                        const mapped = (result ?? [])
                            .filter(notUndefined)
                            .map(this.getMapper(params))
                            .filter(notUndefined);
                        resolve(mapped);
                    },
                    this.getMarshallSpec(),
                    params.baseModule,
                    params.name || ''
                );
            } catch (e) {
                defaultJsonRpcExceptionHandler(e);
                reject(e);
            }
        });
    }
}

export const exports = {
    fetcher: TripletexSalesModuleTypeFetcher,
    type: 'TripletexSalesModuleType',
};
