import { Store } from 'redux';

import { wrapSagaList } from '@General/Saga.util';

import { generalSaga } from './general.saga';
import { HistorySagas } from './HistoryShim.saga';
import { betaProgramSaga } from './BetaProgram.saga';
import { developerSaga } from './Developer.saga';

import { FetchEntitlement } from '../action/General.action';
import { FetchAll } from '../action/BetaProgram.action';

export const generalSagas = [wrapSagaList(generalSaga)];

export const historySagas = [wrapSagaList(HistorySagas)];

export const betaProgramSagas = [wrapSagaList(betaProgramSaga)];

export const developerSagas = [wrapSagaList(developerSaga)];

export function runGlobalInitializers(store: Store) {
    const initializationActions = [
        FetchEntitlement({ employeeId: window.loginEmployeeId }),
        FetchAll(),
    ];

    initializationActions.forEach(store.dispatch);
}
