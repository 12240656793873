import {
    BatchActions,
    BatchActionsButton,
    BatchActionsTitle,
} from '@tlx/astro-shared';
import { DeleteIcon } from '@tlx/atlas';
import React, { useState } from 'react';
import { LogisticsModal } from '@Page/LogisticsV2/components/Modals/Modal';
import { doDeleteDiscounts } from '@Page/LogisticsV2/pages/Product discount/overview/ProductDiscountAction';
import { postValidationMessages } from '@General/LegacyValidationPopup';

export function ProductDiscountBatchActions({
    selectedRows,
    onChange,
}: {
    selectedRows: number[];
    onChange: () => void;
}) {
    const [showDeleteWarning, setShowDeleteWarning] = useState(false);

    async function handleBulkDelete() {
        const { error } = await doDeleteDiscounts(selectedRows.join(','));
        if (error) {
            postValidationMessages(error);
        } else {
            onChange();
        }
    }

    return (
        <BatchActions>
            <BatchActionsTitle>
                {selectedRows.length} {getMessage('text_selected')}
            </BatchActionsTitle>
            <BatchActionsButton
                variant="secondary"
                data-testid="discount-batch-delete-button"
                onClick={() => {
                    setShowDeleteWarning(true);
                }}
            >
                {getMessage('text_delete')}
                <DeleteIcon />
            </BatchActionsButton>
            {showDeleteWarning && (
                <LogisticsModal
                    title={getMessage('text_delete_discounts')}
                    svgIcon="delete"
                    onConfirm={async () => {
                        setShowDeleteWarning(false);
                        await handleBulkDelete();
                    }}
                    onCancel={() => setShowDeleteWarning(false)}
                >
                    {getMessage('text_delete_discounts_warning')}
                </LogisticsModal>
            )}
        </BatchActions>
    );
}
