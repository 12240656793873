import * as React from 'react';
import { debounce, DebouncedFunc } from 'lodash';

import { AsyncProvider, Provider } from '@General/Provider';

import {
    Props as DropdownProps,
    ResponsiveDropDown,
    TlxListItem,
} from '../DropDown/DropDown';
import { CreateButtonComponent, Option } from '../DropDown/types';
import { JSPDropdownProvider } from '@Component/DropDown/JSPDropdown/JSPDropdownProvider';

export type ProviderDropDownProps<T> = {
    readOnly?: boolean;
    name?: string;
    label?: NonNullable<React.ReactNode> | string;
    labelText?: string;
    hideSearch?: boolean;
    dense?: boolean;
    className?: string;
    selected: Option<T>['value'];
    removeSelectedText?: string | null;
    onSearch?: DropdownProps<T>['onSearch'];
    onOpen?: DropdownProps<T>['onOpen'];
    onChange: DropdownProps<T>['onChange'];
    onFocus?: DropdownProps<T>['onFocus'];
    autoFocus?: boolean;
    onBlur?: DropdownProps<T>['onBlur'];
    errorMessage?: string;
    selectOnMobile?: boolean;
    disabled?: boolean;
    required?: boolean;
    provider: Provider<T> | AsyncProvider<T>;
    desktopListItem?: TlxListItem<T>;
    mobileListItem?: TlxListItem<T>;
    headers?: React.ComponentType;
    createButton?: CreateButtonComponent;
    offsetTop?: number;
    isCombo?: boolean;
    maxLength?: number;
    resetBy?: string;
    dataTestId?: string;
};
type ProviderDropdownState = {
    query: string;
    /**
     * This is used to show the ajax wheel when the user starts to type.
     * We need this to fill in the time gap before the debounced function executes
     */
    willFetch: boolean;
    allFetchedOptions: Option<any>[];
    pageIndex: number;
    doneFetching: boolean;
    resetDropdown: boolean;
};

export class ProviderDropdown<T> extends React.Component<
    ProviderDropDownProps<T>,
    ProviderDropdownState
> {
    private readonly doFetchDebounced: DebouncedFunc<
        (clear: boolean) => Promise<void>
    >;

    constructor(props: ProviderDropDownProps<T>) {
        super(props);

        this.onOpen = this.onOpen.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.doFetchDebounced = debounce(this.doFetch, 300);
        this.flushSearch = this.flushSearch.bind(this);
        this.onSearchCallback = this.onSearchCallback.bind(this);
        this.updateComponent = this.updateComponent.bind(this);
        this.doPrefetch = this.doPrefetch.bind(this);

        this.state = {
            query: '',
            willFetch: false,
            allFetchedOptions: [],
            pageIndex: 0,
            doneFetching: false,
            resetDropdown: false,
        };
    }

    componentDidMount(): void {
        if (this.props.resetBy && this.props.resetBy !== '') {
            const resetByList = this.props.resetBy.split(',');
            // eslint-disable-next-line @typescript-eslint/no-this-alias
            const providerDropdownClass = this;
            $('body').on('change', function (e) {
                for (const elementName of resetByList) {
                    if ($(e.target).attr('name') === elementName) {
                        providerDropdownClass.setState(() => ({
                            resetDropdown: true,
                            allFetchedOptions: [],
                            doneFetching: false,
                        }));
                    }
                }
            });
        }
        this.props.provider.registerListener(this.updateComponent);
        this.doPrefetch();
    }

    componentWillUnmount(): void {
        this.props.provider.unregisterListener(this.updateComponent);
    }

    componentDidUpdate(
        prevProps: Readonly<ProviderDropDownProps<T>>,
        prevState: Readonly<ProviderDropdownState>
    ): void {
        if (this.props.provider !== prevProps.provider) {
            prevProps.provider.unregisterListener(this.updateComponent);
            this.props.provider.registerListener(this.updateComponent);
            this.doPrefetch();
        }
    }

    updateComponent() {
        this.setState(() => ({ willFetch: false }));
    }

    onSearch(query: string) {
        this.setState(
            (state, props) => ({
                query,
                willFetch: props.provider.willFetch(query),
                allFetchedOptions: [],
                pageIndex: 0,
                doneFetching: false,
            }),
            this.onSearchCallback
        );
    }

    /**
     * At least used when user hits "Enter" key, means they want the search to be quickly done.
     */
    flushSearch() {
        this.doFetchDebounced.flush();
    }

    onSearchCallback() {
        if (this.state.willFetch) {
            this.doFetchDebounced(true);
        } else {
            void this.doFetch(true);
        }
        if (this.props.onSearch) {
            this.props.onSearch(this.state.query);
        }
    }

    onOpen() {
        void this.doFetch(this.state.resetDropdown);
        if (this.props.onOpen) {
            this.props.onOpen();
        }
    }

    async doFetch(clearPrevious = false) {
        if (
            this.props.provider instanceof AsyncProvider &&
            (!this.state.doneFetching || clearPrevious)
        ) {
            await this.props.provider.fetchOptions(
                this.state.query,
                this.state.pageIndex
            );
            const newFetchedOptions = this.props.provider.getOptionsNoDefaults(
                this.state.query
            );
            // The dropdown does not have infinite scroll if the options
            // on page 2 are the same as the options on page 1
            const dropdownDoesNotHaveInfiniteScroll =
                newFetchedOptions.length > 0 &&
                this.state.allFetchedOptions.length > 0 &&
                newFetchedOptions[0].value ===
                    this.state.allFetchedOptions[0].value;
            if (
                newFetchedOptions.length == 0 ||
                dropdownDoesNotHaveInfiniteScroll
            ) {
                this.setState(() => ({
                    doneFetching: true,
                }));
            } else {
                this.setState((state) => ({
                    allFetchedOptions: [
                        ...(clearPrevious ? [] : state.allFetchedOptions),
                        ...newFetchedOptions,
                    ],
                }));
            }
        }
        this.setState(() => ({
            resetDropdown: false,
        }));
    }

    doFetchMore() {
        if (!this.state.doneFetching && !this.state.willFetch) {
            this.setState((state) => ({
                pageIndex: state.pageIndex + 1,
            }));
            void this.doFetch();
        }
    }

    doPrefetch() {
        if (this.props.provider instanceof AsyncProvider) {
            void this.props.provider.prefetch(this.state.query);
        }
    }

    render() {
        const options = this.props.provider.getDefaults(this.state.query);
        options.push(...this.state.allFetchedOptions);
        let selected = null;
        if (this.props.selected !== undefined && this.props.selected !== null) {
            selected = this.props.provider.getSingle(this.props.selected);
        }
        return (
            <ResponsiveDropDown
                onChange={this.props.onChange}
                onFocus={this.props.onFocus}
                autoFocus={this.props.autoFocus}
                onBlur={this.props.onBlur}
                onOpen={this.onOpen}
                onSearch={this.onSearch}
                doFetchMore={this.doFetchMore.bind(this)}
                flushSearch={this.flushSearch}
                searchLabel={getMessage('button_search_short')}
                search={!this.props.hideSearch}
                dense={this.props.dense}
                name={this.props.name}
                label={this.props.label}
                labelText={this.props.labelText}
                className={this.props.className || ''}
                removeSelectedText={this.props.removeSelectedText}
                selected={selected}
                options={options}
                error={this.props.errorMessage}
                selectOnMobile={this.props.selectOnMobile}
                disabled={this.props.disabled}
                readOnly={this.props.readOnly}
                required={this.props.required}
                createButton={this.props.createButton}
                apiSearch
                fetching={
                    this.state.willFetch ||
                    this.props.provider.getIsLoadingData()
                }
                desktopListItem={this.props.desktopListItem}
                mobileListItem={this.props.mobileListItem}
                headers={this.props.headers}
                offsetTop={this.props.offsetTop}
                isCombo={this.props.isCombo}
                maxLength={this.props.maxLength}
                dataTestId={this.props.dataTestId}
            />
        );
    }
}
