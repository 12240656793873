import product from '@Page/LogisticsV2/pages/Product/overview/Banner/product.svg';
import {
    Banner,
    BannerActions,
    BannerCloseButton,
    BannerContent,
    BannerDescription,
    BannerHeader,
    BannerImage,
    Button,
} from '@tlx/atlas';
import * as React from 'react';
import { Tour } from '@tlx/tourme-core';
import ProductOverviewNewFeaturesNonLogistics from '@Page/LogisticsV2/pages/Product/overview/TourMe/GOLDSHARK_ProductOverview_NewFeatures_NonLogistics_TOUR.json';
import { runTour } from '../../../../../../../../js/modules/tourme';
import ProductOverviewNewFeaturesLogistics from '@Page/LogisticsV2/pages/Product/overview/TourMe/GOLDSHARK_ProductOverview_NewFeatures_Logistics_TOUR.json';
import newProductOverviewAtlasLogistics from '@Page/LogisticsV2/pages/Product/overview/TourMe/GOLDSHARK_AtlasProductOverviewLogistics_TOUR.json';
import newProductOverviewAtlas from '@Page/LogisticsV2/pages/Product/overview/TourMe/GOLDSHARK_AtlasProductOverview_TOUR.json';

export function ProductOverviewBanner({
    onSetIsBannerClosed,
}: {
    onSetIsBannerClosed: (value: boolean) => void;
}) {
    return (
        <Banner size={'medium'} variant={'news'}>
            <BannerCloseButton onClick={() => onSetIsBannerClosed(true)} />
            <BannerImage src={product} alt="product" />
            <BannerContent>
                <BannerHeader>
                    {getMessage('text_product_overview_banner_title')}
                </BannerHeader>
                <BannerDescription>
                    {getMessage('text_product_overview_banner_description')}
                </BannerDescription>
                <BannerActions>
                    <Button
                        variant="primary"
                        onClick={() =>
                            moduleLogistics
                                ? runTour(
                                      ProductOverviewNewFeaturesLogistics as Tour
                                  )
                                : runTour(
                                      ProductOverviewNewFeaturesNonLogistics as Tour
                                  )
                        }
                        data-testid="product-overview-banner-what-is-new-button"
                        aria-label="product-overview-banner-what-is-new-button"
                    >
                        {getMessage('text_what_is_new')}
                    </Button>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            const tour = moduleLogistics
                                ? newProductOverviewAtlasLogistics
                                : newProductOverviewAtlas;
                            runTour(tour as Tour);
                        }}
                        data-testid="product-overview-banner-tutorial-button"
                        aria-label="product-overview-banner-tutorial-button"
                    >
                        {getMessage('automation_starting_guide')}
                    </Button>
                </BannerActions>
            </BannerContent>
        </Banner>
    );
}
