import React, { useState } from 'react';
import './ProductGroupCart.css';
import { useProductGroupCart } from '@Page/LogisticsV2/widgets/ProductGroupCart/useProductGroupCart';
import {
    ProductGroupCartHeader,
    ProductGroupCartRelationRow,
    ProductGroupCartSelectionModal,
} from '@Page/LogisticsV2/widgets/ProductGroupCart/ProductGroupCartComponents';
import { Button } from '@tlx/atlas';

export function ProductGroupCart() {
    const [isExpanded, setIsExpanded] = useState(true);
    return (
        <div
            style={{ maxWidth: '982px' }}
            className={'atl-bg-white atl-border atl-border-grey-20 atl-mb-16'}
        >
            <ProductGroupCartHeader
                onClick={() => setIsExpanded(!isExpanded)}
                isExpanded={isExpanded}
            />
            {isExpanded && <ProductGroupCartContent />}
        </div>
    );
}

const ProductGroupCartContent = () => {
    const {
        selectedNodes,
        groupsNodes,
        dataTree,
        onConfirm,
        onDelete,
        onChange,
    } = useProductGroupCart();
    const [showModal, setShowModal] = useState(false);
    return (
        <div className={'atl-bg-white goldshark-border-bottom-grey-20'}>
            {dataTree.map((data) => (
                <ProductGroupCartRelationRow
                    key={data.id}
                    data={data}
                    handleDelete={onDelete}
                />
            ))}
            {!moduleRackbeat && (
                <Button
                    data-testid={'add-productgroup'}
                    onClick={() => setShowModal(true)}
                    variant="tertiary"
                >
                    {getMessage('text_link_with_product_group')}
                </Button>
            )}
            {showModal && (
                <ProductGroupCartSelectionModal
                    treeNodes={groupsNodes}
                    selectedNodes={selectedNodes}
                    onConfirm={() => {
                        onConfirm(selectedNodes);
                        setShowModal(false);
                    }}
                    onCancel={() => setShowModal(false)}
                    onChange={onChange}
                />
            )}
        </div>
    );
};
