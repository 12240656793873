import * as React from 'react';

import { renderComponent } from '@General/renderComponent';
import {
    InvoicingModeSwitcher,
    InvoicingModeSwitcherProps,
} from '@Page/InvoicingModeSwitcher/InvoicingModeSwitcher';

export function renderInvoicingModeSwitcher(
    domId: string,
    saveAndChangeMode: () => void,
    changeMode: () => void
) {
    renderComponent<InvoicingModeSwitcherProps>(InvoicingModeSwitcher, domId, {
        saveAndChangeMode: saveAndChangeMode,
        changeMode,
    });
}
