import * as React from 'react';
import { useContext, useState } from 'react';
import {
    InvoiceSettings,
    InvoicingBankAccount,
} from '@Page/ProjectInvoicingDialog/types/InvoicingDialog.type';
import { LoadingSpinner } from '@Component/Loading';
import {
    SetupFormFieldValues,
    useSetupForm,
} from '@Page/ProjectInvoicingDialog/component/SetupForm/SetupFormHook';
import { Alert, AlertContent, Button, Group, Input, Label } from '@tlx/atlas';
import { ValidationError } from '@Page/ProjectInvoicingDialog/component/ValidationError';
import './Setup.css';
import { FieldErrors } from 'react-hook-form';
import { InvoicingModalContext } from '@Page/ProjectInvoicingDialog/component/InvoicingModal';

export interface SetupProps {
    closeModal: () => void;
    invoiceSettings: InvoiceSettings;
    companyOrgNr?: string;
    isCompanyAdmin: boolean;
    isAuthAccountingSettings: boolean;
    invoicingBankAccount?: InvoicingBankAccount;
    submitSetup: (
        formValues: SetupFormFieldValues,
        invoiceSettings: InvoiceSettings,
        invoicingBankAccount?: InvoicingBankAccount
    ) => Promise<void>;
    refresh: () => void;
}

export function Setup({
    closeModal,
    invoiceSettings,
    companyOrgNr,
    isAuthAccountingSettings,
    isCompanyAdmin,
    invoicingBankAccount,
    submitSetup,
    refresh,
}: SetupProps) {
    const formProps = useSetupForm(
        invoiceSettings,
        invoicingBankAccount,
        companyOrgNr ?? ''
    );
    const { fields, errors, isSubmitting, handleSubmit, watch } = formProps;
    const [hasSubmitError, setHasSubmitError] = useState(false);

    const isBankAccountReady = invoiceSettings.bankAccountReady;

    const invoiceAbroad = watch('invoiceAbroad');

    const { trackingIdContext } = useContext(InvoicingModalContext);

    async function onSubmit(formValues: SetupFormFieldValues) {
        setHasSubmitError(false);
        await submitSetup(formValues, invoiceSettings, invoicingBankAccount)
            .then(() => refresh())
            .catch((e) => {
                console.log('error during submit', e);
                setHasSubmitError(true);
            });
    }

    function onError(errors: FieldErrors<SetupFormFieldValues>) {
        // Sometimes validation errors will not show in GUI because the field is missing, so we need to log it so that it is possible to figure out what is wrong when the submit button does not work
        console.log('Validation errors: ', errors);
    }

    if (
        (!errors && isSubmitting) ||
        isCompanyAdmin === undefined ||
        isAuthAccountingSettings === undefined
    ) {
        return <LoadingSpinner />;
    }

    const header = (
        <div className="atl-rounded-t atl-bg-blue-20 atl-text-blue-100 atl-p-32">
            <div className="atl-text-2xl atl-font-bold">
                {getMessage('text_invoicing_onboarding1')}
            </div>
            <div className="atl-text-base atl-mt-16">
                {getMessage('text_invoicing_onboarding2')}
            </div>
        </div>
    );

    if (!invoicingBankAccount && !isBankAccountReady) {
        return (
            <div className="kb-setup">
                {header}
                <div className="atl-pl-32 atl-pr-32 atl-pb-32 atl-pt-16">
                    <Alert variant="info" className="atl-mb-16">
                        <AlertContent>
                            {getMessage(
                                'validation_invoice_can_not_be_created_bank_account_number_missing'
                            )}
                            <div>
                                <a
                                    href={addContextId(
                                        '/execute/updateCompany'
                                    )}
                                    className="linkFunction navigate"
                                    data-trackingid={
                                        !window.location.href.includes('story')
                                            ? getLocaleMessage(
                                                  'en_GB',
                                                  'link_register_bank_account_number_here'
                                              ) +
                                              ' (' +
                                              getLocaleMessage(
                                                  'en_GB',
                                                  'text_invoicing_onboarding1'
                                              ).toLowerCase() +
                                              ') (single invoicing) (invoicing React modal)' +
                                              trackingIdContext
                                            : ''
                                    }
                                >
                                    {getMessage(
                                        'link_register_bank_account_number_here'
                                    )}
                                </a>
                            </div>
                        </AlertContent>
                    </Alert>
                    <Button
                        onClick={closeModal}
                        data-testid="setup-cancel-button"
                        data-trackingid={
                            !window.location.href.includes('story')
                                ? getLocaleMessage('en_GB', 'button_close') +
                                  ' (' +
                                  getLocaleMessage(
                                      'en_GB',
                                      'text_invoicing_onboarding1'
                                  ).toLowerCase() +
                                  ') (single invoicing) (invoicing React modal)' +
                                  trackingIdContext
                                : ''
                        }
                    >
                        {getMessage('button_close')}
                    </Button>
                </div>
            </div>
        );
    }

    const isAuthBankAccount = isCompanyAdmin || isAuthAccountingSettings;
    if (
        (!companyOrgNr && !isCompanyAdmin) ||
        (!isBankAccountReady && !isAuthBankAccount)
    ) {
        return (
            <div className="kb-setup">
                {header}
                <div className="atl-pl-32 atl-pr-32 atl-pb-32 atl-pt-16">
                    <div className="atl-pb-16 atl-text-base">
                        {getMessage('text_invoice_setup_missing_access')}
                    </div>
                    <Button
                        onClick={closeModal}
                        data-testid="setup-cancel-button"
                        data-trackingid={
                            !window.location.href.includes('story')
                                ? getLocaleMessage('en_GB', 'button_close') +
                                  ' (' +
                                  getLocaleMessage(
                                      'en_GB',
                                      'text_invoicing_onboarding1'
                                  ).toLowerCase() +
                                  ') (single invoicing) (invoicing React modal)' +
                                  trackingIdContext
                                : ''
                        }
                    >
                        {getMessage('button_close')}
                    </Button>
                </div>
            </div>
        );
    }

    return (
        <div className="kb-setup">
            {header}
            <div className="atl-pl-32 atl-pr-32 atl-pb-32 atl-pt-16">
                <form
                    onSubmit={handleSubmit(onSubmit, onError)}
                    autoComplete="off"
                >
                    {!invoiceSettings.hasFirstInvoiceNumber && (
                        <Group className="atl-mb-16">
                            <Label>
                                {getMessage('text_first_invoice_number')}
                            </Label>
                            <Input
                                className="atl-w-full"
                                placeholder={getMessage(
                                    'text_first_invoice_number_recommendation'
                                )}
                                data-testid="setup-first-invoice-number-button"
                                data-trackingid={
                                    !window.location.href.includes('story')
                                        ? getLocaleMessage(
                                              'en_GB',
                                              'text_first_invoice_number'
                                          ) +
                                          ' (' +
                                          getLocaleMessage(
                                              'en_GB',
                                              'text_invoicing_onboarding1'
                                          ).toLowerCase() +
                                          ') (single invoicing) (invoicing React modal)' +
                                          trackingIdContext
                                        : ''
                                }
                                type="text"
                                {...fields.firstInvoiceNumber}
                            />
                            <ValidationError
                                error={errors.firstInvoiceNumber}
                            />
                        </Group>
                    )}
                    {!companyOrgNr && (
                        <Group className="atl-mb-16">
                            <Label>{getMessage('text_org_number')}</Label>
                            <Input
                                className="atl-w-full"
                                placeholder={getMessage(
                                    'text_your_organization_number'
                                )}
                                type="text"
                                data-testid="setup-organization-number-input"
                                data-trackingid={
                                    !window.location.href.includes('story')
                                        ? getLocaleMessage(
                                              'en_GB',
                                              'text_org_number'
                                          ) +
                                          ' (' +
                                          getLocaleMessage(
                                              'en_GB',
                                              'text_invoicing_onboarding1'
                                          ).toLowerCase() +
                                          ') (single invoicing) (invoicing React modal)' +
                                          trackingIdContext
                                        : ''
                                }
                                {...fields.organizationNumber}
                            />
                            <ValidationError
                                error={errors.organizationNumber}
                            />
                        </Group>
                    )}
                    {!isBankAccountReady && invoicingBankAccount && (
                        <>
                            <Group className="atl-mb-16">
                                <Label>
                                    {getMessage('text_your_bank_account')}
                                </Label>
                                <Input
                                    className="atl-w-full"
                                    type="text"
                                    placeholder={getMessage(
                                        'text_what_bank_account'
                                    )}
                                    data-testid="setup-bank-account-input"
                                    data-trackingid={
                                        !window.location.href.includes('story')
                                            ? getLocaleMessage(
                                                  'en_GB',
                                                  'text_your_bank_account'
                                              ) +
                                              ' (' +
                                              getLocaleMessage(
                                                  'en_GB',
                                                  'text_invoicing_onboarding1'
                                              ).toLowerCase() +
                                              ') (single invoicing) (invoicing React modal)' +
                                              trackingIdContext
                                            : ''
                                    }
                                    {...fields.invoicingBankAccountNumber}
                                />
                                <ValidationError
                                    error={errors.invoicingBankAccountNumber}
                                />
                            </Group>
                            <Group
                                className={`atl-pt-16 atl-pb-4 atl-flex atl-rounded atl-pr-16 atl-bg-blue-10 kb-setup__fit-content ${
                                    invoiceAbroad
                                        ? 'atl-border atl-border-blue-100'
                                        : ''
                                }`}
                            >
                                <Input
                                    className="atl-ml-16"
                                    type="checkbox"
                                    {...fields.invoiceAbroad}
                                    data-testid="will-invoice-abroad-checkbox"
                                    data-trackingid={
                                        !window.location.href.includes('story')
                                            ? getLocaleMessage(
                                                  'en_GB',
                                                  'text_will_invoice_abroad'
                                              ) +
                                              ' (' +
                                              getLocaleMessage(
                                                  'en_GB',
                                                  'text_invoicing_onboarding1'
                                              ).toLowerCase() +
                                              ') (single invoicing) (invoicing React modal)' +
                                              trackingIdContext
                                            : ''
                                    }
                                />
                                <Label className="atl-ml-16">
                                    {getMessage('text_will_invoice_abroad')}
                                </Label>
                            </Group>
                            {invoiceAbroad && (
                                <>
                                    <div className="atl-font-medium atl-text-base atl-mt-16">
                                        {getMessage('text_need_more_info')}
                                    </div>
                                    <Group className="atl-mt-16">
                                        <Label>
                                            {getMessage('text_iban_number')}
                                        </Label>
                                        <Input
                                            className="atl-w-full"
                                            type="text"
                                            data-testid="setup-iban-number-input"
                                            data-trackingid={
                                                !window.location.href.includes(
                                                    'story'
                                                )
                                                    ? getLocaleMessage(
                                                          'en_GB',
                                                          'text_iban_number'
                                                      ) +
                                                      ' (' +
                                                      getLocaleMessage(
                                                          'en_GB',
                                                          'text_invoicing_onboarding1'
                                                      ).toLowerCase() +
                                                      ') (single invoicing) (invoicing React modal)' +
                                                      trackingIdContext
                                                    : ''
                                            }
                                            {...fields.iban}
                                        />
                                        <ValidationError error={errors.iban} />
                                    </Group>
                                    <Group className="atl-mt-16">
                                        <Label>
                                            {getMessage('text_swift_code')}
                                        </Label>

                                        <Input
                                            className="atl-w-full"
                                            type="text"
                                            data-testid="setup-swift-input"
                                            data-trackingid={
                                                !window.location.href.includes(
                                                    'story'
                                                )
                                                    ? getLocaleMessage(
                                                          'en_GB',
                                                          'text_swift_code'
                                                      ) +
                                                      ' (' +
                                                      getLocaleMessage(
                                                          'en_GB',
                                                          'text_invoicing_onboarding1'
                                                      ).toLowerCase() +
                                                      ') (single invoicing) (invoicing React modal)' +
                                                      trackingIdContext
                                                    : ''
                                            }
                                            {...fields.swift}
                                        />
                                        <ValidationError error={errors.swift} />
                                    </Group>
                                </>
                            )}
                        </>
                    )}
                    {hasSubmitError && (
                        <Alert className="atl-mt-16" variant="error">
                            <AlertContent>
                                {getMessage('validation_unexpected_error')}
                            </AlertContent>
                        </Alert>
                    )}
                    <div className="atl-mt-16">
                        <Button
                            type="submit"
                            className="atl-mr-16"
                            data-testid="setup-submit-button"
                            data-trackingid={
                                !window.location.href.includes('story')
                                    ? getLocaleMessage('en_GB', 'button_save') +
                                      ' (' +
                                      getLocaleMessage(
                                          'en_GB',
                                          'text_invoicing_onboarding1'
                                      ).toLowerCase() +
                                      ') (single invoicing) (invoicing React modal)' +
                                      trackingIdContext
                                    : ''
                            }
                        >
                            {getMessage('button_save')}
                        </Button>
                        <Button
                            variant="tertiary"
                            onClick={closeModal}
                            data-testid="setup-cancel-button"
                            data-trackingid={
                                !window.location.href.includes('story')
                                    ? getLocaleMessage(
                                          'en_GB',
                                          'button_cancel'
                                      ) +
                                      ' (' +
                                      getLocaleMessage(
                                          'en_GB',
                                          'text_invoicing_onboarding1'
                                      ).toLowerCase() +
                                      ') (single invoicing) (invoicing React modal)' +
                                      trackingIdContext
                                    : ''
                            }
                        >
                            {getMessage('button_cancel')}
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    );
}
