import { CommandResponderRegistry } from '@General/Command';

export const SectionCommandId = 'SectionCommand';

const SectionCommand = {
    id: SectionCommandId,
    payloadProvider: hasClosedSections,
};

const SectionCombination = {
    key: 'd',
};

function hasClosedSections() {
    // The :visible selector is part of JQuery and not browser native.
    return (
        document.querySelectorAll(
            '.tlx-section--closed, .tlx-subsection--closed, .tlx-report-landing__report-category--closed'
        ).length +
            $('.section.collapsed:visible').length >
        0
    );
}

function JSPSectionHandler() {
    const $section = $('.section').has(':visible');
    if ($section.length > 0) {
        if (hasClosedSections()) {
            $section
                .has('.collapsed')
                .find('.tlxSection-head')
                .trigger('click');
        } else {
            $section
                .not('.collapsed')
                .find('.tlxSection-head')
                .trigger('click');
        }
    }
}

export function registerSection(registry: CommandResponderRegistry) {
    registry.registerCommand(SectionCommand);
    registry.registerHandler(SectionCommandId, JSPSectionHandler);
    registry.registerCombination(SectionCommandId, SectionCombination);
}
