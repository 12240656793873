import { SagaIterator } from 'redux-saga';
import { all, Effect } from 'redux-saga/effects';

/**
 * Wraps Saga Effects in a SagaIterator so the Effect list can be used with the dynamic module loading system.
 */
export function wrapSagaList(sagas: Array<Effect>) {
    return function* wrappedSagas(): SagaIterator {
        yield all(sagas);
    };
}
