import { PageData, PageLoader } from '@Component/PageLoader';
import { RouteData } from '@General/Router';
import * as React from 'react';
import { Team } from '@General/Team';
import { PageUrl } from '@Page/LogisticsV2/utils/enums';
import { ProductOverview } from '@Page/LogisticsV2/pages/Product/overview/ProductOverview';
import { renderComponent } from '@General/renderComponent';

const loader: React.FC = () => (
    <PageLoader<{}> loader={async () => page} adjustWrapperDiv />
);

export function renderProductOverviewPage(domId: string) {
    renderComponent(loader, domId);
}

export const route: RouteData = {
    component: loader,
    path: PageUrl.PRODUCT_OVERVIEW,
    documentationComponent: 169,
    supportsXLS: true,
    team: Team.GOLDSHARK,
    auth: () => hasProductAuthAdminRole,
};

const PAGE_ID = 'ProductOverviewPage';
const page: PageData<any> = {
    page: ProductOverview,
    modules: [
        {
            id: PAGE_ID,
        },
    ],
};
