/**
 * We have several global functions that would fit in here.
 *
 * encodeHTML, decodeHTML, encodeJavaScript. See also the duplicated
 * code in chat.js, forum.js and forum3.js for cookie handling etc.
 *
 */
window.httpUtils = (function () {
    function convertLinks(text) {
        if (!text) {
            return '';
        }
        const re =
            // eslint-disable-next-line no-useless-escape
            /(https?|ftps?):\/\/([-\w\.]+)+(:\d+)?(\/([\w\/_\.\-#]*(\?\S+)?)?)?/g;
        return text.replace(
            re,
            '<a href="$&" target="_blank" rel="noopener">$&</a>'
        );
    }

    return {
        convertLinks: convertLinks,
    };
})();
