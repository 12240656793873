import { Tab, Tabs, TabsContainer } from '@tlx/atlas';
import { ProductLineStatus } from '@Page/LogisticsV2/pages/Stocktaking/utils';
import React from 'react';
import useSessionStorage from '@Page/LogisticsV2/hooks/useSessionStorage';
import { EmptyState } from '@Page/LogisticsV2/components/EmptyState/EmptyState';

export function StocktakingDetailsTabs() {
    const [statusFilter, setStatusFilter] = useSessionStorage(
        `stocktakingDetailsProductLineStatus-${window.loginEmployeeId}`,
        ProductLineStatus.ALL_PRODUCTS
    );
    return (
        <TabsContainer
            value={statusFilter}
            onChange={(value: string) =>
                setStatusFilter(value as ProductLineStatus)
            }
        >
            <Tabs>
                <Tab
                    value={ProductLineStatus.ALL_PRODUCTS}
                    data-testid="all-tab"
                >
                    {getMessage('text_all')}
                </Tab>
                <Tab
                    value={ProductLineStatus.NOT_COUNTED_PRODUCTS}
                    data-testid="not-counted-tab"
                >
                    {getMessage('text_inventory_not_counted')}
                </Tab>
                <Tab
                    value={ProductLineStatus.COUNTED_PRODUCTS}
                    data-testid="counted-tab"
                >
                    {getMessage('text_inventory_counted')}
                </Tab>
            </Tabs>
        </TabsContainer>
    );
}

export function TabEmptyState({ children }: { children: React.ReactNode }) {
    const [statusFilter] = useSessionStorage(
        `stocktakingDetailsProductLineStatus-${window.loginEmployeeId}`,
        ProductLineStatus.ALL_PRODUCTS
    );
    switch (statusFilter) {
        case ProductLineStatus.ALL_PRODUCTS:
            return (
                <EmptyState
                    title={getMessage('text_inventory_all_empty_title')}
                    description={getMessage(
                        'text_inventory_all_empty_description'
                    )}
                    svgIcon={'generalEmptyState'}
                    className={'atl-text-xl'}
                >
                    {children}
                </EmptyState>
            );

        case ProductLineStatus.COUNTED_PRODUCTS:
            return (
                <EmptyState
                    title={getMessage('text_inventory_counted_empty_title')}
                    description={getMessage(
                        'text_inventory_counted_empty_description'
                    )}
                    svgIcon={'stocktakingCounted'}
                    className={'atl-text-xl'}
                />
            );

        case ProductLineStatus.NOT_COUNTED_PRODUCTS:
            return (
                <EmptyState
                    title={getMessage('text_inventory_not_counted_empty_title')}
                    description={getMessage(
                        'text_inventory_not_counted_empty_description'
                    )}
                    svgIcon={'stocktakingNotCounted'}
                    className={'atl-text-xl'}
                />
            );

        default:
            throw new Error('Invalid tab');
    }
}
