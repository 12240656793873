// Returns a list containing lists of orders, grouped by the following logic:
// 1 - The order should be invoiced the standard way - alone - and using the regular API for creation.
// 2 - The customer of a series of orders has the setting "singleCustomerInvoice" = true AND all the orders of the grouping -
//     has the same customer, currency, due date, receiver email and attn., and invoice and invoiceSMSNotificationNumber -
//     and should be "merge invoiced" using the /order/:invoiceMultipleOrders end-point.
// Example: [[order], [order], [singleCustomerInvoiceOrder, singleCustomerInvoiceOrder]]
import { OrderOrProject } from '@Page/ProjectInvoicingDialog/types/InvoicingDialog.type';

function compareLength(
    invoiceOrdersAndProjectsA: OrderOrProject[],
    invoiceOrdersAndProjectsB: OrderOrProject[]
): number {
    if (invoiceOrdersAndProjectsA.length > invoiceOrdersAndProjectsB.length) {
        return -1;
    }
    if (invoiceOrdersAndProjectsA.length < invoiceOrdersAndProjectsB.length) {
        return 1;
    }
    return 0;
}

export const groupOrdersAndProjectsByInvoice = (
    ordersAndProjects: OrderOrProject[]
): Array<OrderOrProject[]> => {
    const invoiceDictionary: { [index: string]: OrderOrProject[] } = {};

    ordersAndProjects.forEach((orderOrProject) => {
        if (!orderOrProject.customer.singleCustomerInvoice) {
            invoiceDictionary[orderOrProject.id] = [orderOrProject];
            return;
        }

        const key =
            orderOrProject.customer.id.toString() +
            orderOrProject.currency.code +
            orderOrProject.invoicesDueIn.toString() +
            orderOrProject.invoicesDueInType +
            orderOrProject.receiverEmail +
            orderOrProject.attn?.id +
            getInvoiceSMSNotificationNumberKey(orderOrProject); // phone number is only considered if invoiceSendSMSNotification is true on both customer and order

        if (invoiceDictionary[key] !== undefined) {
            invoiceDictionary[key].push(orderOrProject);
        } else {
            invoiceDictionary[key] = [orderOrProject];
        }
    });

    const ordersAndProjectsGroupedByInvoice = Object.values(invoiceDictionary);
    ordersAndProjectsGroupedByInvoice.sort(compareLength); // Single customer invoices comes first in list

    return ordersAndProjectsGroupedByInvoice;
};

function getInvoiceSMSNotificationNumberKey(
    orderOrProject: OrderOrProject
): string | undefined {
    const isCustomerSmsNotificationOnForInvoicing =
        orderOrProject.customer.invoiceSendSMSNotification;
    const isOrderOrProjectSmsNotificationOnForInvoicing =
        orderOrProject.invoiceSendSMSNotification;

    if (
        isCustomerSmsNotificationOnForInvoicing &&
        isOrderOrProjectSmsNotificationOnForInvoicing
    ) {
        return orderOrProject.invoiceSMSNotificationNumber;
    }
    return undefined;
}
