import './TextField.css';
import React from 'react';
import { Group, Icon, Input, Label } from '@tlx/atlas';
import classNames from 'classnames';

type TextFieldProps = {
    id: string;
    placeholder?: string;
    value?: string;
    setValue: (value: string) => void;
    atlasDesignSystem: boolean;
    name?: string;
    readOnly?: boolean;
    validationMessage?: string;
    resetValidationMessage?: () => void;
    required?: boolean;
    label: string;
    maxLength?: number;
    className?: string;
};

export const TextField: React.FC<TextFieldProps> = ({
    id,
    placeholder,
    value,
    setValue,
    atlasDesignSystem,
    name,
    readOnly = false,
    validationMessage,
    resetValidationMessage,
    required = false,
    label,
    maxLength,
    className,
}) => {
    return (
        <Group
            className={classNames('atl-w-full inputItem', {
                'pbc-text--atlas': atlasDesignSystem,
                'pbc-text--classic': !atlasDesignSystem,
                'pbc-text-validation-error': !!validationMessage,
            })}
        >
            <Label htmlFor={id}>{getMessage(label)}</Label>

            <div
                className={classNames(
                    'atl-flex atl-items-center atl-justify-center atl-gap-8 ',
                    { 'atl-mb-24': !validationMessage }
                )}
            >
                <Input
                    data-testid={id}
                    type="text"
                    id={id}
                    name={name}
                    className={classNames('atl-w-full', className)}
                    required={required}
                    placeholder={placeholder ? getMessage(placeholder) : ''}
                    value={value}
                    readOnly={readOnly}
                    onChange={(e) => {
                        setValue(e.currentTarget.value);
                        resetValidationMessage?.();
                    }}
                    maxLength={maxLength}
                />
                {atlasDesignSystem && validationMessage && (
                    <Icon className="material-icons-outlined atl-icon atl-text-red-100 pbc-text-field__section-icon">
                        error_outline
                    </Icon>
                )}
            </div>

            {validationMessage && (
                <div className="tlx-formgroup__validation atl-text-red-100 pbc-payment__section-validation">
                    {validationMessage}
                </div>
            )}
        </Group>
    );
};
