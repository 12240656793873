import { ModelAPIV1 } from '@General/types/Api';
import { ApiResultMapperNoUndefinedResult } from '@General/Provider';
import { Option } from '../../types';
import { JSPDropdownFetcher } from '../JSPDropdownFetcher';

interface MailAccountEntry extends ModelAPIV1 {
    readonly displayName: string;
}

const MailAccountMapper: ApiResultMapperNoUndefinedResult<MailAccountEntry> = (
    item
) => ({
    value: `${item.id}`,
    displayName: item.displayName,
    item,
});

class MailAccountFetcher extends JSPDropdownFetcher<MailAccountEntry> {
    asyncJsonrpcGetter: Function;
    constructor(scope: HTMLDivElement, queryAttributes: Record<string, any>) {
        super(scope, queryAttributes, MailAccountMapper);
        this.asyncJsonrpcGetter = asyncrpc.MailAccount.get;
    }

    getParams(): ObjectIndex {
        return {
            showPublicMailAccounts: this.getQueryAttribute(
                'showPublicMailAccounts',
                false
            ),
            direction: this.getQueryAttribute('mailAccountType', 1),
        };
    }

    async fetchList(params: ObjectIndex): Promise<Option<MailAccountEntry>[]> {
        return new Promise((resolve, reject) => {
            try {
                return jsonrpc.MailAccount.searchForMailAccounts(
                    (result: MailAccountEntry[] | null) =>
                        resolve((result ?? []).map(MailAccountMapper)),
                    this.getMarshallSpec(),
                    params.showPublicMailAccounts,
                    params.direction,
                    params.name || ''
                );
            } catch (e) {
                defaultJsonRpcExceptionHandler(e);
                reject(e);
            }
        });
    }
}

export const exports = {
    fetcher: MailAccountFetcher,
    type: 'MailAccount',
};
