import * as React from 'react';
import clsx from 'clsx';

import { renderComponent } from '@General/renderComponent';

import {
    BannerButtons,
    BannerDescription,
    BannerImage,
    BannerPersistence,
    BannerTitle,
    GetStartedLabel,
    MinimizableBanner,
    useGetStartedBanner,
} from '@tlx/beehive-shared';

import rocket from '@Page/Beehive/resource/rocket.svg';

type GetStartedBannerInfoProps = {
    bannerId: string;
    bannerTitle: string;
    bannerDescription: string;
    bannerUrl?: string;
    className?: string;
};
export const GetStartedBannerInfo = ({
    bannerId,
    bannerTitle,
    bannerDescription,
    bannerUrl,
    className,
}: GetStartedBannerInfoProps) => {
    const bannerInfo = useGetStartedBanner(
        bannerId,
        BannerPersistence.Dismissible
    );

    if (!bannerInfo) {
        return null;
    }

    return (
        <MinimizableBanner use={bannerInfo} className={clsx(className)}>
            <BannerImage src={rocket} alt="rocket" />
            <GetStartedLabel />
            <BannerTitle>{bannerTitle}</BannerTitle>
            <BannerDescription>{bannerDescription}</BannerDescription>
            {bannerUrl && (
                <BannerButtons>
                    <a
                        data-testid={`get-started-banner-link__${bannerId}`}
                        data-trackingid={`get-started-banner-link__${bannerId}`}
                        className="atl-button atl-button--primary"
                        href={bannerUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {getMessage('text_read_more')}
                    </a>
                </BannerButtons>
            )}
        </MinimizableBanner>
    );
};

export function renderGetStartedBanner(
    props: GetStartedBannerInfoProps,
    domId: string
) {
    renderComponent(GetStartedBannerInfo, domId, props);
}
