import jQuery from 'jquery';

import { nav } from './navigation';
import { sendEmail } from './sendEmail';

window.invoicing = (function ($) {
    return {
        /**
         * Used in updateInvoices and createInvoices for sending invoice as preview.
         */
        onSendInvoicePreviewClick: function onSendInvoicePreviewClick() {
            const $this = $(this);
            const previewids = $this.data('previewids');
            const projectId = $this.data('projectid');
            const title = $this.data('dialogtitle');
            const vals = $($this).data('dialog-data');

            const pdfUrl =
                '/execute/invoiceUtil?act=preview&amp;ids=' + previewids;

            vals.sendPreview = getMessage('text_send_invoice_preview');

            vals.recipientTrackingId =
                window.getLocaleMessage('en_GB', 'text_receiver') +
                ' (' +
                window
                    .getLocaleMessage(
                        'en_GB',
                        'text_send_preview_invoice_email'
                    )
                    .toLowerCase() +
                ')';
            vals.copyTrackingId =
                window.getLocaleMessage('en_GB', 'text_email_cc') +
                ' (' +
                window
                    .getLocaleMessage(
                        'en_GB',
                        'text_send_preview_invoice_email'
                    )
                    .toLowerCase() +
                ')';
            vals.titleTrackingId = '';
            vals.messageTrackingId =
                window.getLocaleMessage('en_GB', 'text_message') +
                ' (' +
                window
                    .getLocaleMessage(
                        'en_GB',
                        'text_send_preview_invoice_email'
                    )
                    .toLowerCase() +
                ')';

            vals.sendAsEmailDescription = getMessage(
                'text_send_order_offer_as_email_description'
            );

            vals.hiddenInputsHtml = sendEmail.hiddenInputsHtml(
                vals.hiddenInputs.concat(
                    {
                        name: 'previewIds',
                        value: previewids,
                    },
                    {
                        name: 'projectId',
                        value: projectId,
                    }
                )
            );

            const $template = $('script#orderConfirmationDialogTemplate').templ(
                vals
            );
            sendEmail.createDialog(
                $this,
                $template,
                title,
                pdfUrl,
                window.getLocaleMessage(
                    'en_GB',
                    'text_send_preview_invoice_email'
                ) +
                    ' (' +
                    window
                        .getLocaleMessage('en_GB', 'button_cancel')
                        .toLowerCase() +
                    ')',
                window.getLocaleMessage(
                    'en_GB',
                    'text_send_preview_invoice_email'
                ) +
                    ' (' +
                    window
                        .getLocaleMessage('en_GB', 'text_confirm')
                        .toLowerCase() +
                    ')'
            );
        },

        /**
         * Used in updateInvoices and createInvoices for printing checked and approved invoices.
         * Should perhaps give a warning if non-approved invoices are selected?
         */
        printInvoices: function printInvoices(scope) {
            // Find checked  and approved invoices
            const ids = $('tr.invoiceRow', scope)
                .filter(function (a, b) {
                    return (
                        $(b).find('input.selected').is(':checked') &&
                        $(b).find('input.isapprovedHidden').val() === 'true'
                    );
                })
                .get()

                // Turn invoices into a comma-separated string of IDs
                .reduce(function (a, b) {
                    return a + $(b).find('.voucherId').val() + ',';
                }, '');

            if (ids.length > 0) {
                nav.popup('/execute/invoiceUtil?act=preview&ids=' + ids);
            }
        },
    };
})(jQuery);
