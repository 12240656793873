import * as React from 'react';

import { PageLoader } from '@Component/PageLoader';

import type { SupportDashboardProps } from './component';

export const SupportDashboardPage: React.FC<SupportDashboardProps> = (
    props
) => (
    <PageLoader<SupportDashboardProps>
        loader={async () =>
            import('./SupportDashboard.module').then((m) => m.page)
        }
        adjustWrapperDiv
        props={props}
    />
);
