import jQuery from 'jquery';
import * as React from 'react';
import { Provider } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { createDndContext, DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';

import { store } from './createStore';
import { resultMapMemorizer } from './Helpers';
import { mount, unmount } from '../util/React18mount';

const $ = jQuery;

// Translate Tripletex locale to ECMAScript Internationalization API locale
let locale = 'nb';
if (window.locale === 'en_GB') {
    locale = 'en';
}

const getDNDContext = resultMapMemorizer((touch) =>
    createDndContext(touch ? TouchBackend : HTML5Backend)
);

export function renderComponent<P extends object>(
    Component: React.ComponentType<P>,
    renderTarget: string | HTMLElement,
    props?: P,
    persistAcrossNavigate = false
): void {
    let element: HTMLElement | null;
    if (typeof renderTarget === 'string') {
        element = document.getElementById(renderTarget);
    } else {
        element = renderTarget;
    }
    if (element === null) {
        return;
    }

    const isTouchScreen = window.matchMedia('(pointer: coarse)').matches;
    const manager = getDNDContext(isTouchScreen).dragDropManager;

    mount(
        element,
        <IntlProvider locale={locale} defaultLocale="nb">
            <Provider store={store}>
                {manager ? (
                    // @ts-expect-error - our version of `react-dnd` is not typed correctly for React 18
                    <DndProvider manager={manager}>
                        <Component {...(props ?? ({} as P))} />
                    </DndProvider>
                ) : (
                    <Component {...(props ?? ({} as P))} />
                )}
            </Provider>
        </IntlProvider>
    );

    element.dataset.tlxReactMounted = '';

    if (persistAcrossNavigate) {
        element.dataset.persistAcrossNavigate = '';
    }
}

$(window).on(
    'dialogclose tlxRemoveUpgradedMdlComponents',
    (event: JQuery.TriggeredEvent) => {
        const target = event.target as HTMLElement;

        // Ensure that drpodowns are mounted again if they re-appear
        target
            .querySelectorAll('.tlx-dropdown__react-container--observing')
            .forEach(function (dropdown) {
                dropdown.classList.remove(
                    'tlx-dropdown__react-container--observing'
                );
            });

        const elements = target.querySelectorAll<HTMLElement>(
            '[data-tlx-react-mounted]:not([data-persist-across-navigate])'
        );

        elements.forEach((element) => {
            unmount(element);
            delete element.dataset.tlxReactMounted;
        });
    }
);
