import {
    DISCOUNT_TYPE,
    DiscountPolicyApiModel,
} from '@Page/LogisticsV2/pages/Product discount/overview/types';
import React, { useEffect, useState } from 'react';
import {
    Dropdown,
    DropdownDrawer,
    DropdownOpener,
    DropdownScrollContainer,
    DropdownSearch,
    Group,
    Input,
    Label,
    Modal,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalIllustration,
    ModalLayout,
    ModalTitle,
    MoneyInput,
    PercentageInput,
    RadioGroup,
} from '@tlx/atlas';
import { AsyncDropdownOptions } from '@tlx/astro-shared';
import { ApiUrl } from '@Page/LogisticsV2/utils/enums';
import {
    ProductDiscountModalActions,
    ProductDiscountModalSummary,
} from '@Page/LogisticsV2/pages/Product discount/modals/ProductDiscountModalsComponents';
import { getPriceAfterDiscount } from '@Page/LogisticsV2/pages/Product discount/overview/utils';
import { doEditDiscount } from '@Page/LogisticsV2/pages/Product discount/overview/ProductDiscountAction';
import { postValidationMessages } from '@General/LegacyValidationPopup';
import { getSvgIcon } from '@Page/LogisticsV2/utils/svgIcons';
import { Form } from '@Page/LogisticsV2/components/Form/Form';

export function EditProductDiscountModal({
    discountPolicy,
    onClose,
    onChange,
}: {
    discountPolicy: DiscountPolicyApiModel;
    onClose: () => void;
    onChange: () => void;
}) {
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [errorMessage, setErrorMessage] = useState({
        percentError: '',
        fixedAmountError: '',
    });
    const handleOnSubmit = async (
        formElement: HTMLFormElement,
        values: { [k: string]: FormDataEntryValue }
    ) => {
        const { error } = await doEditDiscount(
            discountPolicy?.id ?? 0,
            discountPolicy.product.priceExcludingVatCurrency,
            formElement,
            values
        );

        if (error) {
            postValidationMessages(error);
        } else {
            onChange?.();
        }
        onClose();
    };

    useEffect(() => {
        if (errorMessage.percentError || errorMessage.fixedAmountError) {
            setIsButtonDisabled(true);
        } else {
            setIsButtonDisabled(false);
        }
    }, [errorMessage]);

    return (
        <Modal open={true} onClose={onClose}>
            <ModalLayout>
                <ModalCloseButton />
                <ModalIllustration>
                    {getSvgIcon('editDiscount')}
                </ModalIllustration>
                <ModalTitle>{getMessage('text_edit_discount')}</ModalTitle>
                <ModalContent>
                    <Form formId={'editDiscountForm'} onSubmit={handleOnSubmit}>
                        <EditProductDiscountModalContent
                            discountPolicy={discountPolicy}
                            errorMessage={errorMessage}
                            onChangeErrorMessage={setErrorMessage}
                        />
                    </Form>
                </ModalContent>
                <ModalFooter>
                    <ProductDiscountModalActions
                        formId={'editDiscountForm'}
                        onCancel={onClose}
                        isEditMode={true}
                        isButtonDisabled={isButtonDisabled}
                    />
                </ModalFooter>
            </ModalLayout>
        </Modal>
    );
}

export function EditProductDiscountModalContent({
    discountPolicy,
    errorMessage,
    onChangeErrorMessage,
}: {
    discountPolicy: DiscountPolicyApiModel;
    errorMessage: { percentError: string; fixedAmountError: string };
    onChangeErrorMessage: (errorMessage: {
        percentError: string;
        fixedAmountError: string;
    }) => void;
}) {
    const [contentValue, setContentValue] = useState({
        type: discountPolicy.isPercentage
            ? DISCOUNT_TYPE.PERCENT
            : DISCOUNT_TYPE.FIXED_AMOUNT,
        percentValue: discountPolicy.percentage.toString(),
        value: !discountPolicy.isPercentage
            ? discountPolicy.salesPriceWithDiscount.toString()
            : '',
    });

    function handleOnChangeDiscount(value: string, discountType: string) {
        setContentValue({
            ...contentValue,
            [discountType]: value,
        });
    }

    function handleOnChangeDiscountType(discountType: string) {
        setContentValue({
            ...contentValue,
            type: discountType,
        });
    }

    function clearErrorMessages() {
        onChangeErrorMessage({
            percentError: '',
            fixedAmountError: '',
        });
    }

    return (
        <>
            <div className="atl-flex atl-flex-col atl-gap-16">
                <div className="atl-flex atl-gap-16">
                    <Dropdown
                        data-testid="edit-discount-product-dropdown"
                        defaultValue={discountPolicy.product.name}
                        defaultDisplayName={discountPolicy.product.name}
                        disabled
                    >
                        <DropdownOpener className="atl-w-full" />
                        <DropdownDrawer>
                            <DropdownSearch />
                            <DropdownScrollContainer>
                                <AsyncDropdownOptions
                                    url={`${ApiUrl.PRODUCT_QUERY}?isSupplierProduct=false`}
                                ></AsyncDropdownOptions>
                            </DropdownScrollContainer>
                        </DropdownDrawer>
                    </Dropdown>
                </div>
                <div className="atl-flex atl-gap-16">
                    <Group className="atl-w-full">
                        <Label>
                            <RadioGroup>
                                <Input
                                    id="percent"
                                    type="radio"
                                    data-testid="edit-discount-percent-radio"
                                    name="editDiscountPercent"
                                    checked={
                                        contentValue.type ===
                                        DISCOUNT_TYPE.PERCENT
                                    }
                                    onChange={() => {
                                        handleOnChangeDiscountType(
                                            DISCOUNT_TYPE.PERCENT
                                        );
                                        clearErrorMessages();
                                    }}
                                />
                                <Label htmlFor="percent">
                                    {getMessage('text_percent')}
                                </Label>
                            </RadioGroup>
                        </Label>
                        <PercentageInput
                            className={'atl-w-full'}
                            name="editDiscountPercentInput"
                            data-testid="edit-discount-percent-input"
                            disabled={
                                contentValue.type === DISCOUNT_TYPE.FIXED_AMOUNT
                            }
                            value={contentValue.percentValue}
                            onChange={(value) => {
                                let error = '';
                                if (
                                    Number(value) > 100 ||
                                    Number(value) === 0
                                ) {
                                    error = getMessage(
                                        'validation_invalid_percentage',
                                        1
                                    );
                                } else if (Number(value) < 0) {
                                    error = getMessage('validation_negative');
                                } else if (!Number(value)) {
                                    error = getMessage(
                                        'text_only_numbers_allowed'
                                    );
                                }

                                if (error) {
                                    onChangeErrorMessage({
                                        ...errorMessage,
                                        percentError: error,
                                    });
                                    handleOnChangeDiscount(
                                        value,
                                        'percentValue'
                                    );
                                } else {
                                    handleOnChangeDiscount(
                                        value,
                                        'percentValue'
                                    );
                                    clearErrorMessages();
                                }
                            }}
                        />
                        {errorMessage.percentError && (
                            <div className="atl-text-red-100 atl-mt-4">
                                {errorMessage.percentError}
                            </div>
                        )}
                    </Group>
                    <Group className="atl-w-full">
                        <Label>
                            <RadioGroup>
                                <Input
                                    id="value"
                                    type="radio"
                                    data-testid="edit-discount-value-radio"
                                    name="editDiscountValue"
                                    checked={
                                        contentValue.type ===
                                        DISCOUNT_TYPE.FIXED_AMOUNT
                                    }
                                    onChange={() => {
                                        handleOnChangeDiscountType(
                                            DISCOUNT_TYPE.FIXED_AMOUNT
                                        );
                                        clearErrorMessages();
                                    }}
                                />
                                <Label htmlFor="value">
                                    {getMessage(
                                        'project_contract_type_fixed_price'
                                    )}
                                </Label>
                            </RadioGroup>
                        </Label>
                        <MoneyInput
                            className={'atl-w-full'}
                            currency={discountPolicy.product.currency.code}
                            name="editDiscountValueInput"
                            data-testid="edit-discount-value-input"
                            disabled={
                                contentValue.type === DISCOUNT_TYPE.PERCENT
                            }
                            value={contentValue.value}
                            onChange={(value) => {
                                const price =
                                    discountPolicy?.product
                                        ?.priceExcludingVatCurrency ?? 0;
                                let error = '';
                                if (Number(value) === 0) {
                                    error = getMessage(
                                        'validation_amount_not_zero'
                                    );
                                } else if (!Number(value)) {
                                    error = getMessage(
                                        'text_only_numbers_allowed'
                                    );
                                } else if (price - Number(value) < 0) {
                                    error = getMessage(
                                        'validation_discount_exceeds_current_price'
                                    );
                                } else if (Number(value) < 0) {
                                    error = getMessage('validation_negative');
                                }
                                if (error) {
                                    onChangeErrorMessage({
                                        ...errorMessage,
                                        fixedAmountError: error,
                                    });
                                    handleOnChangeDiscount(value, 'value');
                                } else {
                                    handleOnChangeDiscount(value, 'value');
                                    clearErrorMessages();
                                }
                            }}
                        />
                        {errorMessage.fixedAmountError && (
                            <div className="atl-text-red-100 atl-mt-4">
                                {errorMessage.fixedAmountError}
                            </div>
                        )}
                    </Group>
                </div>
            </div>
            <ProductDiscountModalSummary
                priceBeforeDiscount={
                    discountPolicy.product.priceExcludingVatCurrency
                }
                priceAfterDiscount={getPriceAfterDiscount(
                    discountPolicy.product.priceExcludingVatCurrency,
                    contentValue.type === DISCOUNT_TYPE.PERCENT
                        ? contentValue.percentValue
                        : contentValue.value,
                    contentValue.type
                )}
            />
        </>
    );
}
