import { LoadableDropdownProps } from '@Component/Dropdowns/types';
import { Department } from '../../../../hooks/fetch/department/types';
import {
    Dropdown,
    DropdownDrawer,
    DropdownError,
    DropdownLoadMoreTarget,
    DropdownScrollContainer,
    DropdownSearchOpener,
} from '@tlx/atlas';
import { DropdownOptions } from '@Component/Dropdowns/DropdownOptions';
import React from 'react';

export type DepartmentDropdownProps = LoadableDropdownProps<Department> & {
    disabled?: boolean;
};

export const DepartmentDropdown = ({
    items,
    defaultDisplayName,
    setQuery,
    onChange,
    children,
    disabled,
    ...props
}: DepartmentDropdownProps) => {
    return (
        <Dropdown
            {...props}
            onChange={(event) => onChange?.(event.target.value)}
            defaultDisplayName={defaultDisplayName.data}
        >
            <DropdownSearchOpener
                onChange={
                    setQuery
                        ? (event) => setQuery(event.target.value)
                        : undefined
                }
            />
            {!disabled && (
                <DropdownDrawer>
                    <DropdownScrollContainer>
                        {children}
                        <DropdownOptions items={items} />
                        <DropdownLoadMoreTarget
                            hasMore={items.hasMore}
                            onLoadMore={items.loadMore}
                        />
                        <DropdownError error={items.error} />
                    </DropdownScrollContainer>
                </DropdownDrawer>
            )}
        </Dropdown>
    );
};
