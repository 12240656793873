import $ from 'jquery';
import { getElement, tlxGetScope } from '../c-common';
import { changed } from '../modules/change';

/// Task reordering stuff:

//instead of updating the order input-element each time we could do it for all rows on sumbit time. (value is equal to the the html row element index)
//see updateAllSortIndices

function getSortIndexNode(taskRow) {
    return $(taskRow).find('input').filter("[name^='orderMapping']")[0];
}

// for drag and drop
function updateAllSortIndices(tbody) {
    const rows = tbody.children;
    const sortIndices = []; // can't preallocate capacity?
    for (let i = 0; i < rows.length; i++) {
        sortIndices.push(parseInt(getSortIndexNode(rows[i]).value));
    }
    // ad-hoc
    const scope = tlxGetScope(tbody);
    let ascendingSwitch = -1; // -1 = descending
    const acendingInputElement = getElement('ascending', scope);
    if (acendingInputElement) {
        ascendingSwitch = acendingInputElement.value == 'true' ? 1 : -1;
    }
    sortIndices.sort(function (a, b) {
        return ascendingSwitch * (a - b);
    });
    for (let j = 0; j < rows.length; j++) {
        getSortIndexNode(rows[j]).value = sortIndices[j].toString();
    }
    changed();
}

function swapSortIndex(rowA, rowB) {
    const indexNodeA = getSortIndexNode(rowA);
    const indexNodeB = getSortIndexNode(rowB);
    changed(indexNodeA);
    changed(indexNodeB);
    const temp = indexNodeA.value;
    indexNodeA.value = indexNodeB.value;
    indexNodeB.value = temp;
}

function setDisabledOuterRows(disabled, tbody) {
    const first = $(tbody.firstElementChild).find('.moveup, .movetop');
    const last = $(tbody.lastElementChild).find('.movedown, .movebottom');
    //	var disabled = (disabled ? "disable" : "enable");
    first.tlxSetDisabled(disabled);
    last.tlxSetDisabled(disabled);
}

function moveWithEffect(row, moveFn) {
    // TODO: adjust color and timing
    $(row).children().effect(
        'highlight',
        {
            color: '#C3D6C6',
        },
        600
    );
    const tbody = $(row).closest('tbody').get(0);
    setTimeout(function () {
        setDisabledOuterRows(false, tbody);
        moveFn(row);
        setDisabledOuterRows(true, tbody);
        // buttons don't recieve "hover end" events when the element are moved away from the mouse
        $(row)
            .find('.moveup, .movedown, .movetop, .movebottom')
            .removeClass('ui-state-hover')
            .removeClass('ui-state-focus');
    }, 100);
}

function moveUp(row) {
    moveWithEffect(row, moveUpNoHL);
}
function moveUpNoHL(row) {
    const body = $(row).parent().get(0);
    const otherRow = $(row).prev().get(0);
    body.insertBefore(row, otherRow);
    swapSortIndex(row, otherRow);
    return true;
}

function moveDown(row) {
    moveWithEffect(row, moveDownNoHL);
}
function moveDownNoHL(row) {
    // same as moving the next row up
    const otherRow = $(row).next().get(0);
    return moveUpNoHL(otherRow);
}

// TODO: better to do this in one go, as actually moving the row multiple times could cause UI artifacts(?)
function moveTop(row) {
    moveWithEffect(row, function (row) {
        moveRow(row, 0);
    });
}
function moveBottom(row) {
    moveWithEffect(row, function (row) {
        moveRow(row, -1);
    });
}

function moveRow(row, index) {
    const tbody = $(row).parent().get(0);
    if (index < 0) {
        index = tbody.children.length + index + 1;
    }
    let refElement = null; // last element
    if (index < tbody.children.length) {
        refElement = tbody.children[index];
    }
    tbody.insertBefore(row, refElement);
    updateAllSortIndices(tbody);
}

export function initTaskTableDragAndDrop(tbodyId) {
    // do lazy dragn'drop initialization
    $('#' + tbodyId).on(
        'mouseenter.lazytaskdrag',
        '.draghandle',
        function (event) {
            const tbody = $(event.delegateTarget);
            tbody.off('mouseenter.lazytaskdrag', '.draghandle');
            doInit(tbody);
        }
    );
    function doInit(tbody) {
        /// drag and drop
        const tableRowHelper = function (e, tr) {
            const $originals = tr.children();
            const $helper = tr.clone();
            // prevents the helper from growing too big
            $helper.css('max-width', tr.parent().parent().width());
            $helper.children().each(function (index) {
                // prevents the cells in the dragged row (the helper) from changing size
                $(this).width($originals.eq(index).width());
            });

            return $helper;
        };
        tbody.sortable({
            helper: tableRowHelper,
            axis: 'y',
            forcePlaceHolderSize: true,
            handle: '.draghandle',
        });
        tbody
            .bind('sortupdate', function () {
                if (window.location.href.includes('updateHourlist')) {
                    tlxGetScope(tbody).autosaveTasksTableReorder();
                }
                updateAllSortIndices(tbody.get(0));
            })
            .bind('sortstart', function (ev, ui) {
                const first = tbody
                    .children(':first')
                    .find('.moveup, .movetop');
                // the helper is inserted last in the table before 'sortstart' is run. (that's why we don't use setDisabledOuterRows too)
                const last = tbody
                    .children(':last')
                    .prev()
                    .find('.movedown, .movebottom');
                first.tlxSetDisabled(false);
                last.tlxSetDisabled(false);
                ui.item
                    .find('.movedown, .moveup, .movetop, .movebottom')
                    .tlxSetDisabled(false); // not sure why this is needed really...
                if (window.location.href.includes('updateHourlist')) {
                    tlxGetScope(tbody).setOriginalTaskOrder();
                }
            })
            .bind('sortstop', function () {
                setDisabledOuterRows(true, tbody.get(0));
            });
    }
}

export function initTaskTableReorderButtons(tbodyId) {
    const tbodySelector = '#' + tbodyId;
    /// re-order buttons
    const $tbody = $(tbodySelector);
    if ($tbody.length == 0) {
        return;
    }
    $tbody.on(
        'click',
        '.moveup, .movedown, .movetop, .movebottom',
        function () {
            const dispatch = [
                'moveup',
                moveUp,
                'movedown',
                moveDown,
                'movetop',
                moveTop,
                'movebottom',
                moveBottom,
            ];

            dispatchByElementClass($(this), dispatch);

            function dispatchByElementClass($elem, dispatch) {
                for (let i = 0; i < dispatch.length; i += 2) {
                    if ($elem.hasClass(dispatch[i])) {
                        dispatch[i + 1]($elem.closest('tr.task_row')[0]);
                        break;
                    }
                }
            }
        }
    );

    setDisabledOuterRows(true, $tbody.get(0));
}
