import React, { useState } from 'react';
import { NewSupplierProduct } from '@Page/LogisticsV2/components/NewSupplierProduct/NewSupplierProduct';
import {
    createProductAndSupplierConnection,
    createSupplierConnection,
} from '@Page/LogisticsV2/components/Modals/ProductSelector/useProducts';
import {
    ResaleProductDTO,
    SupplierConnectionDTO,
} from '@Page/LogisticsV2/types/dtoTypes';
import { Button } from '@tlx/atlas';
import { ApiError } from '@Page/LogisticsV2/utils/constants';
import { unmount } from '../../../../../util/React18mount';

export function NewSupplierProductModal() {
    const supplierId = $(':prop(creditPosting.vendorId)').val() as string;
    const [error, setError] = useState<ApiError | undefined>(undefined);

    const handleCreateSupplierConnection = async (
        supplierConnection: Partial<SupplierConnectionDTO>
    ) => {
        setError(undefined);
        if (
            supplierConnection?.resaleProduct?.id === undefined ||
            supplierConnection.resaleProduct.id < 1
        ) {
            const validationMessages = error?.validationMessages;
            validationMessages?.push({
                field: 'resaleProduct',
                message: getMessage('text_select_resale_product'),
            });
            setError({
                message: '',
                validationMessages: validationMessages ?? [
                    {
                        field: 'resaleProduct',
                        message: getMessage('text_select_resale_product'),
                    },
                ],
            });
        } else {
            const { data, error } = await createSupplierConnection(
                supplierConnection
            );
            if (data && !error) {
                onCreateSuccess(data);
            } else {
                setError(error);
            }
        }
    };
    const handleCreateProductAndSupplierConnection = async (
        resaleProduct: Partial<ResaleProductDTO>,
        productGroupId: number | undefined,
        supplierConnection: Partial<SupplierConnectionDTO>
    ) => {
        setError(undefined);
        const { data, error } = await createProductAndSupplierConnection(
            resaleProduct,
            productGroupId,
            supplierConnection
        );
        if (data && !error) {
            onCreateSuccess(data);
        } else {
            setError(error);
        }
    };
    const onCreateSuccess = (data: any) => {
        const newProductId = data.value.id;
        const dropdownIndex = window.reactVars.indexProductDropdown ?? 0;
        const isFromOrderLines = window.reactVars.isFromOrderLines;
        const productDropdown = document.getElementsByName(
            isFromOrderLines
                ? `orderLines[${dropdownIndex}].guiProductId`
                : `debitPostings[${dropdownIndex}].productId`
        )[0];
        if (productDropdown !== null) {
            productDropdown.setAttribute('value', newProductId);
            //Trigger onChange because in JSP we show other fields when this dropdown is changed.
            const event = new Event('change');
            productDropdown.dispatchEvent(event);
        }
        const container = document.getElementsByClassName(
            'txr-page-dialog__backdrop--product'
        )[0] as HTMLElement;
        unmount(container);
        container.remove();
    };

    return (
        <>
            <NewSupplierProduct
                onCreateSupplierConnection={handleCreateSupplierConnection}
                supplierId={supplierId ?? 0}
                onCreateProduct={handleCreateProductAndSupplierConnection}
                error={error}
            />
            <Button
                data-testid={'new-product-create-button'}
                variant="primary"
                type={'submit'}
                form={'create-product-form'}
            >
                {getMessage('button_create')}
            </Button>
        </>
    );
}
