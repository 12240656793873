import { WarehouseOverviewPage } from '@Page/LogisticsV2/pages/Warehouse/overview/WarehouseOverviewPage';
import { PageData, PageLoader } from '@Component/PageLoader';
import { Team } from '@General/Team';
import { PageUrl } from '@Page/LogisticsV2/utils/enums';
import { RouteData } from '@General/Router';

const loader: React.FC = () => (
    <PageLoader<{}> loader={async () => page} adjustWrapperDiv />
);

export const route: RouteData = {
    component: loader,
    path: PageUrl.WAREHOUSE,
    team: Team.GOLDSHARK,
    documentationComponent: 214,
    auth: () => hasProductAuthAdminRole && moduleWarehouse && !moduleRackbeat,
};

const PAGE_ID = 'WarehouseOverview';
const page: PageData<any> = {
    page: WarehouseOverviewPage,
    modules: [
        {
            id: PAGE_ID,
        },
    ],
};
