import * as React from 'react';
import classNames from 'classnames';

import { FormGroup } from './FormGroup';
import './TextField.scss';
import { SyntheticEvent } from 'react';
import { ButtonIcon } from '@Component/ButtonIcon';

export type TextFieldSimpleProps = {
    name?: string;
    value?: string;
    label?: NonNullable<React.ReactNode> | string;
    placeholder?: string;
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
    onBlur?: React.FocusEventHandler<HTMLInputElement>;
    onFocus?: React.FocusEventHandler<HTMLInputElement>;
    onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
    inputRef?: React.RefObject<HTMLInputElement>;
    type?: string;
    shape?: string;
    outlined?: boolean;
    shaped?: boolean;
    className?: string;
    inputClassName?: string;
    icon?: any;
    dense?: boolean;
    disabled?: boolean;
    autoFocus?: boolean;
    readOnly?: boolean;
    required?: boolean;
    onPaste?: React.ClipboardEventHandler<HTMLInputElement>;
    testId?: string;
    formatKey?: string;
};

export type TextFieldProps = Omit<TextFieldSimpleProps, 'className'> & {
    invalid?: boolean;
    validationMessage?: string;
    helpText?: string;
    textFieldClassName?: string;
    inputClassName?: string;
    onFocus?: React.FocusEventHandler<HTMLInputElement>;
    formGroupClassName?: string;
    icon?: string;
    iconOnClick?: (e: any) => any;
    required?: boolean;
    autoFocus?: boolean;
};

export type TextFieldIconProps = {
    onClick?: (e: SyntheticEvent) => void;
    icon: string;
    disabled?: boolean;
};

export const TextFieldIcon = ({
    onClick,
    icon,
    disabled,
}: TextFieldIconProps) => {
    if (!onClick) {
        return <i className="material-icons tlx-textfield__icon">{icon}</i>;
    }

    return (
        <ButtonIcon
            onClick={onClick}
            disabled={disabled}
            icon={icon}
            className="tlx-textfield__icon"
        />
    );
};

/**
 * This is the TextField without any FormGroup around it. Per default, all form components should be inside
 * a FormGroup (which should handle validation messages and such).
 * @constructor
 */
export const TextFieldSimple = ({
    name,
    value,
    type = 'text',
    label,
    placeholder,
    onChange,
    onBlur,
    onFocus,
    onPaste,
    onKeyDown,
    outlined = false,
    shaped = false,
    className,
    inputClassName,
    icon,
    dense,
    inputRef,
    disabled,
    readOnly,
    required,
    autoFocus,
    testId,
    formatKey,
}: TextFieldSimpleProps) => {
    return (
        <div
            className={classNames('tlx-textfield', className, {
                'tlx-textfield--outlined': outlined,
                'tlx-textfield--shaped': shaped,
                'tlx-textfield--icon-right': icon,
                'tlx-textfield--dense': dense,
            })}
        >
            <input
                ref={inputRef}
                className={classNames('tlx-textfield__input', inputClassName, {
                    'tlx-textfield__input--outlined': outlined,
                    'tlx-textfield__input--shaped': shaped,
                    'tlx-textfield__input--icon-right': icon,
                    'tlx-textfield__input--dense': dense,
                })}
                placeholder={placeholder ? placeholder : ' '}
                type={type}
                name={name}
                value={value}
                onChange={onChange}
                onPaste={onPaste}
                onBlur={onBlur}
                onFocus={onFocus}
                onKeyDown={onKeyDown}
                disabled={disabled}
                readOnly={readOnly}
                autoFocus={autoFocus}
                tabIndex={disabled || readOnly ? -1 : undefined}
                size={1}
                data-testid={testId}
                data-formatkey={formatKey}
            />
            <label
                className={classNames('tlx-textfield__label', {
                    'tlx-textfield__label--dense': dense,
                    'tlx-textfield__label--outlined': outlined,
                    'tlx-textfield__label--required': required,
                })}
            >
                {label}
            </label>
            {!outlined && <div className="tlx-textfield__border" />}
            {icon}
        </div>
    );
};

export const TextField = ({
    name,
    value,
    type = 'text',
    label,
    placeholder,
    onChange,
    onPaste,
    onBlur,
    onFocus,
    onKeyDown,
    outlined = false,
    shaped = false,
    icon,
    iconOnClick,
    textFieldClassName,
    formGroupClassName,
    inputClassName,
    inputRef,
    readOnly,
    invalid = false,
    helpText,
    validationMessage,
    dense,
    disabled,
    required,
    autoFocus,
    testId,
    formatKey,
}: TextFieldProps) => {
    return (
        <FormGroup
            invalid={invalid}
            helpText={helpText}
            validationMessage={validationMessage}
            className={formGroupClassName}
            dense={dense}
        >
            <TextFieldSimple
                name={name}
                value={value}
                label={label}
                onChange={onChange}
                onPaste={onPaste}
                onBlur={onBlur}
                onFocus={onFocus}
                autoFocus={autoFocus}
                onKeyDown={onKeyDown}
                type={type}
                className={textFieldClassName}
                inputClassName={inputClassName}
                placeholder={placeholder}
                icon={
                    icon && (
                        <TextFieldIcon
                            onClick={iconOnClick}
                            icon={icon}
                            disabled={disabled}
                        />
                    )
                }
                outlined={outlined}
                shaped={shaped}
                dense={dense}
                inputRef={inputRef}
                disabled={disabled}
                readOnly={readOnly}
                required={required}
                testId={testId}
                formatKey={formatKey}
            />
        </FormGroup>
    );
};
