import { ModelAPIV1 } from '@General/types/Api';
import { notUndefined } from '@General/Helpers';
import { ApiResultMapper } from '@General/Provider';
import { JSPDropdownFetcher } from '../JSPDropdownFetcher';
import { Option } from '../../types';

interface WarehouseLocationEntry extends ModelAPIV1 {
    readonly displayName: string;
}

const warehouseLocationMapper: ApiResultMapper<WarehouseLocationEntry> = (
    item
) => ({
    value: `${item.id}`,
    displayName: `${item.displayName}`,
    item,
});

/**
 * OBS! This is not the same as WarehouseAndLocation, which is modelled by a relation
 * between warehouses and locations.
 */
class WarehouseLocationFetcher extends JSPDropdownFetcher<WarehouseLocationEntry> {
    asyncJsonrpcGetter: Function;

    constructor(scope: HTMLDivElement, queryAttributes: Record<string, any>) {
        super(scope, queryAttributes, warehouseLocationMapper);

        this.asyncJsonrpcGetter = asyncrpc.WarehouseLocation.get;
    }

    getParams(): ObjectIndex {
        return {
            warehouseId: this.getQueryAttribute('warehouseElementId', -1),
            productId: this.getQueryAttribute('productElementId', -1),
        };
    }

    async fetchList(
        params: ObjectIndex
    ): Promise<Option<WarehouseLocationEntry>[]> {
        return new Promise((resolve, reject) => {
            const callback = (result: WarehouseLocationEntry[] | null) => {
                const mapped = (result ?? [])
                    .filter(notUndefined)
                    .map(this.getMapper(params))
                    .filter(notUndefined);
                resolve(mapped);
            };
            try {
                if (
                    params.productId > 0 &&
                    jsonrpc.Product.hasWarehouseLocations(params.productId)
                ) {
                    jsonrpc.WarehouseLocation.searchForProductLocationsTlxSelect(
                        callback,
                        this.getMarshallSpec(),
                        window.loginCompanyId,
                        params.productId,
                        params.warehouseId,
                        params.name || ''
                    );
                } else {
                    jsonrpc.WarehouseLocation.searchForLocationsTlxSelect(
                        callback,
                        this.getMarshallSpec(),
                        window.loginCompanyId,
                        params.warehouseId,
                        params.name || ''
                    );
                }
            } catch (e) {
                defaultJsonRpcExceptionHandler(e);
                reject(e);
            }
        });
    }
}

export const exports = {
    fetcher: WarehouseLocationFetcher,
    type: 'WarehouseLocation',
};
