import { postValidationMessages } from '@General/LegacyValidationPopup';
import { invokeAPI, useFetchAll } from '@Page/LogisticsV2/hooks/api';
import { ApiUrl, MethodType } from '@Page/LogisticsV2/utils/enums';
import { getURL } from '@Page/LogisticsV2/utils/functions';
import { mapObjectToURLSearchParams } from '@Page/LogisticsV2/utils/mappers';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
    NameToMappingType,
    ProductImportHeaderFieldsRelationType,
    ProductImportSuggestedMappingType,
    ProductImportType,
    step,
} from './types';

export function useFieldMapping(
    uploadedFile: ProductImportType | undefined,
    onStepChange: Dispatch<SetStateAction<string>>
) {
    const [nameToMappingId, setNameToMappingId] = useState<NameToMappingType>(
        {}
    );
    const filteredHeaders = uploadedFile?.productImportHeaders.filter(
        (field) =>
            field.name !== '' && field.name !== undefined && field.name !== null
    );
    const [headers, setHeaders] = useState<ProductImportSuggestedMappingType[]>(
        []
    );

    const [status, setStatus] = useState({
        countCreated: 0,
        countUpdated: 0,
        countIgnored: 0,
    });

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const { data: tripletexFields } = useFetchAll<
        ProductImportSuggestedMappingType[]
    >('/v2/productImportFields');

    const requiredFields = tripletexFields?.filter((item) => item.isMandatory);

    useEffect(() => {
        const newNameToMappingId = filteredHeaders?.reduce((map, header) => {
            map[header.name] = {
                headerId: header.id,
                id: header.suggestedColumnField?.id ?? -1,
                mandatory: header.suggestedColumnField?.isMandatory ?? false,
            };
            return map;
        }, {} as NameToMappingType);
        setNameToMappingId(newNameToMappingId ?? {});
    }, [uploadedFile]);

    const onChangeMapping = (id: number, name: string) => {
        setNameToMappingId((currentMap) => {
            const newMap = { ...currentMap };
            newMap[name] = {
                ...newMap[name],
                id: id,
            };
            return newMap;
        });
    };

    const onSubmitMapping = async () => {
        setIsLoading(true);
        onStepChange(step.VALIDATE);
        const payload: {
            productImport: { id: number };
            header: { id: number };
            field: { id: number };
        }[] = [];
        Object.values(nameToMappingId)
            .filter((field) => field.id > 0)
            .forEach((field) => {
                payload.push({
                    productImport: { id: uploadedFile?.id ?? 0 },
                    header: { id: field.headerId },
                    field: { id: field.id },
                });
            });

        const { data, error } = await invokeAPI(
            `${ApiUrl.PRODUCT_IMPORT_FIELDS_RELATION_LIST}?fields=*,field(*),productImport(countCreated, countUpdated, countIgnored)`,
            MethodType.POST,
            JSON.stringify(payload)
        );
        if (error) {
            if (error.validationMessages?.length > 0) {
                postValidationMessages(error.validationMessages[0]?.message);
            } else {
                postValidationMessages(getMessage('text_import_mapping_error'));
            }
            onStepChange(step.MAPPING);
        } else {
            const dataJson = await data?.json();
            const responseData = dataJson.values.map(
                (value: ProductImportHeaderFieldsRelationType) => {
                    return value.field;
                }
            );
            setHeaders(responseData);
            setStatus(dataJson.values[0].productImport);
        }
        setIsLoading(false);
    };

    const getMappedHeaders = async (id: number) => {
        const { data, error } = await invokeAPI(
            getURL(
                '/v2/productImportFieldsRelation',
                mapObjectToURLSearchParams({
                    productImportId: id,
                    fields: '*,field(*),productImport(countCreated, countUpdated, countIgnored)',
                })
            ) ?? '',
            MethodType.GET
        );
        if (!error) {
            const dataJson = await data?.json();
            const responseData = dataJson.values.map(
                (value: ProductImportHeaderFieldsRelationType) => {
                    return value.field;
                }
            );
            setHeaders(responseData);
            setStatus(dataJson.values[0].productImport);
        }
    };

    return {
        fileFields: filteredHeaders,
        isLoading,
        headers,
        mappedFields: nameToMappingId,
        requiredFields,
        status,
        onChangeMapping,
        onContinueMapping: getMappedHeaders,
        onSubmitMapping,
    };
}
