import { CreateButtonComponent, Option } from '../../types';
import { notUndefined } from '@General/Helpers';
import { ModelAPIV1 } from '@General/types/Api';
import { JSPDropdownFetcher } from '../JSPDropdownFetcher';
import { ApiResultMapper } from '@General/Provider';
import * as React from 'react';
import { createCreateNewButton } from '@Component/DropDown/CreateNewButton/CreateNewButton';
import { TlxListItem } from '@Component/DropDown/DropDown';

interface AmeldingWageCodeEntry extends ModelAPIV1 {
    readonly displayName: string;
    readonly payStatementCodeCode: string;
    readonly name: string;
    readonly advantageAmeldingTypeDisplayName: string;
    readonly salaryDescriptionAmeldingTypeDisplayName: string;
    readonly isPayrollTaxBasis: boolean;
    readonly isTaxBasis: boolean;
}

const AmeldingWageCodeMapper: ApiResultMapper<AmeldingWageCodeEntry> = (
    item
) => {
    return {
        value: item.id + '',
        displayName: item.displayName,
        item,
    };
};

const DesktopListItem: TlxListItem<AmeldingWageCodeEntry> = (props) => {
    if (!props.option.item) {
        return (
            <div className="txr-dropdown__row txr-dropdown__list-item">
                <div className="txr-dropdown__item-cell txr-dropdown__item-cell--default">
                    {props.option.displayName}
                </div>
            </div>
        );
    }
    return (
        <div className="txr-dropdown__row txr-dropdown__list-item">
            <div
                className="txr-dropdown__item-cell txr-dropdown__item-cell--code"
                title={props.option.item.payStatementCodeCode}
            >
                {props.option.item.payStatementCodeCode}
            </div>
            <div
                className="txr-dropdown__item-cell txr-dropdown__item-cell--description"
                title={props.option.item.name}
            >
                {props.option.item.name}
            </div>
            <div
                className="txr-dropdown__item-cell txr-dropdown__item-cell--name"
                title={props.option.item.advantageAmeldingTypeDisplayName}
            >
                {props.option.item.advantageAmeldingTypeDisplayName}
            </div>
            <div
                className="txr-dropdown__item-cell txr-dropdown__item-cell--description"
                title={
                    props.option.item.salaryDescriptionAmeldingTypeDisplayName
                }
            >
                {props.option.item.salaryDescriptionAmeldingTypeDisplayName}
            </div>
            <div className="txr-dropdown__item-cell txr-dropdown__item-cell--checkbox">
                {props.option.item.isPayrollTaxBasis && (
                    <span className="checkboxOn">done</span>
                )}
            </div>
            <div className="txr-dropdown__item-cell txr-dropdown__item-cell--checkbox">
                {props.option.item.isTaxBasis && (
                    <span className="checkboxOn">done</span>
                )}
            </div>
        </div>
    );
};

const Headers: React.FC = () => {
    return (
        <div className="txr-dropdown__row">
            <div className="txr-dropdown__item-cell txr-dropdown__item-cell--header  txr-dropdown__item-cell--code">
                {getMessage('text_lonn_trekk_short')}
            </div>
            <div className="txr-dropdown__item-cell txr-dropdown__item-cell--header txr-dropdown__item-cell--description">
                {getMessage('text_name')}
            </div>
            <div className="txr-dropdown__item-cell txr-dropdown__item-cell--header txr-dropdown__item-cell--name">
                {getMessage('text_advantage')}
            </div>
            <div className="txr-dropdown__item-cell txr-dropdown__item-cell--header txr-dropdown__item-cell--description">
                {getMessage('text_wage_description')}
            </div>
            <div
                className="txr-dropdown__item-cell txr-dropdown__item-cell--header txr-dropdown__item-cell--checkbox txr-dropdown__item-cell--allow-wrap"
                title={getMessage('text_payroll_tax_basis_short')}
            >
                {getMessage('text_payroll_tax_basis_short')}
            </div>
            <div
                className="txr-dropdown__item-cell txr-dropdown__item-cell--header txr-dropdown__item-cell--checkbox txr-dropdown__item-cell--allow-wrap"
                title={getMessage('text_tax_basis')}
            >
                {getMessage('text_tax_basis')}
            </div>
        </div>
    );
};

class AmeldingWageCodeFetcher extends JSPDropdownFetcher<AmeldingWageCodeEntry> {
    asyncJsonrpcGetter: Function;

    constructor(scope: HTMLDivElement, queryAttributes: Record<string, any>) {
        super(scope, queryAttributes, AmeldingWageCodeMapper);

        this.asyncJsonrpcGetter = asyncrpc.AmeldingWageCode.get;
    }

    getDesktopListItem(): TlxListItem<AmeldingWageCodeEntry> {
        return DesktopListItem;
    }

    getMarshallSpec(): { marshallSpec: string[] } {
        return window.marshallSpec(
            'id',
            'displayName',
            'payStatementCodeCode',
            'name',
            'advantageAmeldingTypeDisplayName',
            'salaryDescriptionAmeldingTypeDisplayName',
            'isPayrollTaxBasis',
            'isTaxBasis'
        );
    }

    getParams(): ObjectIndex {
        return {
            queryId: this.getQueryAttribute('queryId', -1),
            payStatementElementCodeId: this.getQueryAttribute(
                'payStatementCodeElementId',
                -1
            ),
        };
    }

    async fetchList(
        params: ObjectIndex
    ): Promise<Option<AmeldingWageCodeEntry>[]> {
        return new Promise((resolve, reject) => {
            try {
                jsonrpc.AmeldingWageCode.search(
                    (result: AmeldingWageCodeEntry[] | null) => {
                        const mapped = (result ?? [])
                            .filter(notUndefined)
                            .map(this.getMapper(params))
                            .filter(notUndefined);
                        resolve(mapped);
                    },
                    this.getMarshallSpec(),
                    params.queryId,
                    params.payStatementElementCodeId,
                    params.name
                );
            } catch (e) {
                defaultJsonRpcExceptionHandler(e);
                reject(e);
            }
        });
    }

    createCreateNewButton(
        setSelected: (id: number) => void
    ): CreateButtonComponent | undefined {
        if (!isAuth.createAmeldingWageCode) {
            return undefined;
        }
        return createCreateNewButton(
            setSelected,
            'text_new_ameldingWageCode',
            'createAmeldingWageCode'
        );
    }

    getHeaders() {
        return Headers;
    }
}

function link(id: number, contextId: number): string | null {
    if (!loginCompanyModules.ameldingWageCode || !isAuth.ameldingWageCodeMenu) {
        return null;
    }
    return `/execute/ameldingWageCodeMenu?ameldingWageCodeId=${id}&contextId=${contextId}`;
}

export const exports = {
    fetcher: AmeldingWageCodeFetcher,
    type: 'AmeldingWageCode',
    link: link,
};
