import * as React from 'react';
import { StrictOmit } from 'ts-essentials';
import classNames from 'classnames';

import { getScrollParent } from '@General/DOMUtil';
import { clamp } from '@General/NumberUtil';

import {
    FloatingSurface,
    FloatingSurfaceProps,
} from '@Component/FloatingSurface';

import { AnchorProps, DefaultAnchor } from './DefaultAnchor';

import './AnchoredSurface.scss';

const DEFAULT_EDGE_MARGIN = 8;

interface Rectangle {
    width: number;
    height: number;
    x: number;
    y: number;
}

export type PreferredVertical = 'bottom' | 'center' | 'top';
export type PreferredHorizontal = 'left' | 'center' | 'right';

export interface AnchoredSurfaceProps
    extends StrictOmit<
        FloatingSurfaceProps,
        'top' | 'bottom' | 'left' | 'right' | 'target' | 'ref'
    > {
    /**
     * Origin position inside the anchor. This is the point the surface aligns along.
     * Is automatically calculated if not specified.
     */
    origin?: {
        vertical?: PreferredVertical;
        horizontal?: PreferredHorizontal;
    };

    /**
     * The direction in which the content of the surface should be positioned
     */
    direction: {
        /**
         * Alignment along the vertical axis for the surface.
         */
        vertical: PreferredVertical;

        /**
         * Alignment along the horizontal axis for the surface.
         */
        horizontal: PreferredHorizontal;
    };

    /**
     * Content of the anchor.
     */
    anchorContent?: React.ReactNode;

    /**
     * Content of the surface.
     */
    children: NonNullable<React.ReactNode>;

    /**
     * Sets the visible state.
     * NOTE: setting this to false unmounts the surface and content from the DOM
     */
    visible?: boolean;

    /**
     * Overrides what container to constrain the surface inside.
     */
    container?: HTMLElement;

    /**
     * Do additional positional constrains based on viewport.
     */
    constrainedByViewport?: boolean;

    /**
     * Overrides the default anchor renderer. See DefaultAnchor for the default implementation.
     */
    anchor?: React.ComponentType<AnchorProps>;
}

type AnchoredSurfaceState = Pick<
    React.CSSProperties,
    'top' | 'bottom' | 'left' | 'right'
>;

/**
 * Container for floating content around this, with this acting as an anchor.
 *
 * @author tellef
 * @date 2020-08-07
 */
export class AnchoredSurface extends React.Component<
    AnchoredSurfaceProps,
    AnchoredSurfaceState
> {
    private readonly self: React.RefObject<HTMLElement>;
    private readonly surface: React.RefObject<HTMLDivElement>;

    private container: HTMLElement | null = null;

    private unmounting = false;

    constructor(props: AnchoredSurfaceProps) {
        super(props);

        this.self = React.createRef();
        this.surface = React.createRef();
        this.state = this.computeNewPosition();

        this.updatePosition = this.updatePosition.bind(this);
        this.requestUpdate = this.requestUpdate.bind(this);
    }

    componentDidMount(): void {
        if (this.self.current) {
            this.container =
                this.props.container ??
                getScrollParent(this.self.current) ??
                document.getElementById('scrollContainer');
            this.updatePosition();
        }
    }

    componentWillUnmount(): void {
        this.unmounting = true;
    }

    /**
     * Do an update of the surface position and then request a new update.
     */
    updatePosition(): void {
        // requestAnimationFrame callbacks can happen after unmounting.
        if (this.unmounting) {
            return;
        }

        this.setState(this.computeNewPosition(), this.requestUpdate);
    }

    /**
     * Requests the browser to trigger a surface position update before next animation frame.
     */
    requestUpdate(): void {
        if (
            this.props.visible === false ||
            this.props.inline ||
            this.unmounting
        ) {
            return;
        }

        window.requestAnimationFrame(this.updatePosition);
    }

    /**
     * Get the bounds of the container that the surface should be constrained inside.
     */
    getContainerDimensions(): Rectangle {
        if (this.container) {
            const clientRect = this.container.getBoundingClientRect();
            return {
                x: clientRect.left,
                y: clientRect.top,
                width: this.container.scrollWidth,
                height: this.container.scrollHeight,
            };
        } else {
            return {
                width: document.body.scrollWidth,
                height: document.body.scrollHeight,
                x: 0,
                y: 0,
            };
        }
    }

    /**
     * Get the size of the surface and calculate what the offsets should be based on alignment props.
     */
    getSurfaceOffsetAndSize(): {
        offsetX: number;
        offsetY: number;
        width: number;
        height: number;
    } {
        const horizontal =
            this.props.origin?.horizontal ?? this.props.direction.horizontal;
        const vertical =
            this.props.origin?.vertical ?? this.props.direction.vertical;

        if (this.self.current && this.surface.current) {
            const self = this.self.current;
            const surface = this.surface.current;

            let x = 0;
            switch (horizontal) {
                case 'left':
                    x = 0;
                    break;
                case 'center':
                    x = self.clientWidth / 2;
                    break;
                case 'right':
                    x = self.clientWidth;
                    break;
                default:
                    // Do nothing
                    break;
            }

            let y = 0;
            switch (vertical) {
                case 'top':
                    y = 0;
                    break;
                case 'center':
                    y = self.clientHeight / 2;
                    break;
                case 'bottom':
                    y = self.clientHeight;
                    break;
                default:
                    // Do nothing
                    break;
            }

            return {
                offsetX: x,
                offsetY: y,
                width: surface.clientWidth,
                height: surface.clientHeight,
            };
        } else {
            return {
                offsetX: 0,
                offsetY: 0,
                width: 0,
                height: 0,
            };
        }
    }

    /**
     * x / y difference between scroll container and self.
     */
    getContainerOffset(): { x: number; y: number } {
        if (this.self.current && this.container) {
            const targetRect = this.self.current.getBoundingClientRect();
            const containerRect = this.container.getBoundingClientRect();

            const offsetX =
                targetRect.left -
                containerRect.left +
                this.container.scrollLeft;
            const offsetY =
                targetRect.top - containerRect.top + this.container.scrollTop;

            return {
                x: offsetX,
                y: offsetY,
            };
        } else {
            return {
                x: 0,
                y: 0,
            };
        }
    }

    /**
     * Compute what top, bottom, left and right should be based on the current state in the browser.
     */
    computeNewPosition(): AnchoredSurfaceState {
        let top: number | null = null;
        let left: number | null = null;

        if (!this.self.current || !this.container) {
            return {
                top: 'unset',
                bottom: 'unset',
                left: 'unset',
                right: 'unset',
            };
        }

        const {
            direction: { vertical, horizontal },
            inline,
        } = this.props;

        const container = this.getContainerDimensions();
        const self = this.getContainerOffset();
        const surface = this.getSurfaceOffsetAndSize();

        const minX = DEFAULT_EDGE_MARGIN;
        const maxX = Math.max(
            container.width - DEFAULT_EDGE_MARGIN - surface.width,
            DEFAULT_EDGE_MARGIN + surface.width
        );
        const minY = DEFAULT_EDGE_MARGIN;
        const maxY = Math.max(
            container.height - DEFAULT_EDGE_MARGIN - surface.height,
            DEFAULT_EDGE_MARGIN + surface.height
        );

        const containerOffsetX = inline
            ? -self.x
            : container.x - this.container.scrollLeft;
        const containerOffsetY = inline
            ? -self.y
            : container.y - this.container.scrollTop;

        switch (vertical) {
            case 'bottom':
                top =
                    clamp(minY, self.y + surface.offsetY, maxY) +
                    containerOffsetY;
                break;
            case 'center':
                top =
                    clamp(
                        minY,
                        self.y - surface.height / 2 + surface.offsetY,
                        maxY
                    ) + containerOffsetY;
                break;
            case 'top':
                top =
                    clamp(
                        minY,
                        self.y - surface.height + surface.offsetY,
                        maxY
                    ) + containerOffsetY;
                break;
            default:
                // Do nothing
                break;
        }

        switch (horizontal) {
            case 'left':
                left =
                    clamp(
                        minX,
                        self.x - surface.width + surface.offsetX,
                        maxX
                    ) + containerOffsetX;
                break;
            case 'center':
                left =
                    clamp(
                        minX,
                        self.x - surface.width / 2 + surface.offsetX,
                        maxX
                    ) + containerOffsetX;
                break;
            case 'right':
                left =
                    clamp(minX, self.x + surface.offsetX, maxX) +
                    containerOffsetX;
                break;
            default:
                // Do nothing
                break;
        }

        if (this.props.constrainedByViewport) {
            if (this.props.inline !== true) {
                if (top !== null) {
                    top = clamp(
                        DEFAULT_EDGE_MARGIN,
                        top,
                        window.innerHeight -
                            DEFAULT_EDGE_MARGIN -
                            surface.height
                    );
                }

                if (left !== null) {
                    left = clamp(
                        DEFAULT_EDGE_MARGIN,
                        left,
                        window.innerWidth - DEFAULT_EDGE_MARGIN - surface.width
                    );
                }
            } else {
                if (top !== null) {
                    top =
                        clamp(
                            DEFAULT_EDGE_MARGIN,
                            top - containerOffsetY,
                            window.innerHeight -
                                DEFAULT_EDGE_MARGIN -
                                surface.height
                        ) + containerOffsetY;
                }

                if (left !== null) {
                    left =
                        clamp(
                            DEFAULT_EDGE_MARGIN,
                            left - containerOffsetX,
                            window.innerWidth -
                                DEFAULT_EDGE_MARGIN -
                                surface.width
                        ) + containerOffsetX;
                }
            }
        }

        return {
            top: top !== null ? `${top}px` : 'unset',
            bottom: 'unset',
            left: left !== null ? `${left}px` : 'unset',
            right: 'unset',
        };
    }

    componentDidUpdate(prevProps: Readonly<AnchoredSurfaceProps>): void {
        let recalculate = false;
        if (
            this.props.inline &&
            (prevProps.direction.horizontal !==
                this.props.direction.horizontal ||
                prevProps.direction.vertical !==
                    this.props.direction.vertical ||
                prevProps.origin !== this.props.origin ||
                prevProps.origin?.horizontal !==
                    this.props.origin?.horizontal ||
                prevProps.origin?.vertical !== this.props.origin?.vertical)
        ) {
            recalculate = true;
        }

        if (prevProps.container !== this.props.container) {
            this.container =
                this.props.container ??
                getScrollParent(this.self.current) ??
                document.getElementById('scrollContainer');
            recalculate = true;
        }

        if (
            prevProps.visible !== this.props.visible &&
            this.props.visible !== false
        ) {
            recalculate = true;
        }

        if (recalculate) {
            this.updatePosition();
        }
    }

    render(): JSX.Element {
        const {
            // Eslint has no way to understand why this is done.
            // This is done to keep floatingSurfaceProps clean of properties intended only for AnchoredSurface.
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            direction,
            origin,
            constrainedByViewport,
            container,
            children,
            anchor: Anchor = DefaultAnchor,
            anchorContent,
            visible,
            className: surfaceClass,
            ...floatingSurfaceProps
        } = this.props;

        const surface =
            visible || this.props.inline ? (
                <FloatingSurface
                    {...floatingSurfaceProps}
                    {...this.state}
                    className={classNames(
                        surfaceClass,
                        'tlx-anchored-surface__surface',
                        {
                            'tlx-anchored-surface__surface__hidden':
                                visible === false,
                            'tlx-anchored-surface__surface__shown':
                                visible ?? true,
                        }
                    )}
                    ref={this.surface}
                >
                    {children}
                </FloatingSurface>
            ) : null;

        return (
            <Anchor
                className={classNames('tlx-anchored-surface')}
                anchorRef={this.self}
            >
                {anchorContent}
                {surface}
            </Anchor>
        );
    }
}
