import { DropdownEmpty, Option, SkeletonOption } from '@tlx/atlas';
import { FetchPaginatedState } from '../../hooks/fetch/types';
import { LoadableDropdownOption } from './types';

type DropdownOptionsProps<
    T extends LoadableDropdownOption = LoadableDropdownOption
> = {
    items: FetchPaginatedState<Pick<T, 'id' | 'displayName'>>;
    required?: boolean;
};

export function DropdownOptions({ items }: DropdownOptionsProps) {
    const { data, error, isLoading } = items;
    const empty = data.length === 0;

    if (empty && error === undefined && !isLoading) {
        return <DropdownEmpty>{getMessage('text_no_match')}</DropdownEmpty>;
    }

    if (empty && isLoading) {
        return (
            <>
                <SkeletonOption />
                <SkeletonOption />
                <SkeletonOption />
            </>
        );
    }

    return (
        <>
            {data.map((item) => (
                <Option key={item.id} value={item.id.toString()}>
                    {item.displayName}
                </Option>
            ))}
        </>
    );
}
