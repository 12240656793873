export function getBrowserInfo() {
    let browserInfo = '';
    if (navigator) {
        browserInfo += 'NAVIGATOR\r\n';
        browserInfo += 'appName:     ' + navigator.appName + '\r\n';
        browserInfo += 'appVersion:  ' + navigator.appVersion + '\r\n';
        browserInfo += 'appCodeName: ' + navigator.appCodeName + '\r\n';
        browserInfo += 'platform:    ' + navigator.platform + '\r\n';
        browserInfo += 'language:    ' + navigator.language + '\r\n';
        browserInfo += 'userAgent:   ' + navigator.userAgent + '\r\n';
        browserInfo += '\r\n';
    }

    if (screen) {
        browserInfo += 'SCREEN\r\n';
        browserInfo += 'width:       ' + screen.width + '\r\n';
        browserInfo += 'height:      ' + screen.height + '\r\n';
        browserInfo += 'pixelDepth:  ' + screen.pixelDepth + '\r\n';
        browserInfo += 'availWidth:  ' + screen.availWidth + '\r\n';
        browserInfo += 'availHeight: ' + screen.availHeight + '\r\n';
        browserInfo += '\r\n';
    }

    if (navigator) {
        if (navigator.plugins) {
            const numPlugins = navigator.plugins.length;
            for (let i = 0; i < numPlugins; i++) {
                const plugin = navigator.plugins[i];
                if (plugin) {
                    browserInfo += 'PLUGIN\r\n';
                    browserInfo += 'name:        ' + plugin.name + '\r\n';
                    browserInfo += 'filename:    ' + plugin.filename + '\r\n';
                    browserInfo +=
                        'description: ' + plugin.description + '\r\n';
                    browserInfo += 'length:      ' + plugin.length + '\r\n';
                    browserInfo += '\r\n';
                }
            }
        }
    }
    browserInfo += 'Referrer: ' + document.referrer;
    return browserInfo;
}
