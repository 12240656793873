import * as React from 'react';
import { PageLoader } from '@Component/PageLoader';
import { RouteData } from '@General/Router';
import { Team } from '@General/Team';
import { renderComponent } from '@General/renderComponent';
import { getMessage } from '../../../../js/modules/getMessage';

/**
 * The outside part of the bundle shear line.
 * The bundle split happens because of the <code>import('./SomePage')</code> call.
 * The PageLoader both sets up an error boundary (meaning a crash in the React code inside won't crash the page)
 * and handles showing a loader.
 *
 * Remember to add the text keys being used on the page to <code>textKeys</code> to prevent stuttering and unresponsive UI.
 *
 * @see PageLoader
 * @see renderComponent
 */
export const Page: React.FC = (props) => (
    <PageLoader<{}>
        loader={async () =>
            import('./DistributionKeys.module').then((m) => m.page)
        }
        props={props}
    />
);

/**
 * Describes URL -> React page mapping
 *
 * Name of this file must end with `.route.ts` or `.route.tsx` for the mapping to be registered automatically.
 */
export const route: RouteData = {
    component: Page,
    path: '/execute/distributionKeys',
    title: () => getMessage('text_distribution_keys'),
    team: Team.ANTS,
    // To fully connect this page to the Tripletex framework, the page needs a documentationComponent id so that we can
    // link help articles to the page
    // documentationComponent: ,

    // We should limit who has access to this page.
    auth: () => true,
};

export const renderDistributionKeys = (domId: string) => {
    renderComponent(Page, domId);
};
