import {
    Department,
    FetchDepartmentsParams,
} from '../../../hooks/fetch/department/types';
import {
    useDropdown,
    UseDropdownProps,
} from '@Component/Dropdowns/DropdownHookBuilder';
import { UseLoadableDropdownReturn } from '@Component/Dropdowns/types';

export function useDepartmentDropdown(
    props?: UseDropdownProps<FetchDepartmentsParams>
): UseLoadableDropdownReturn<Department> {
    return useDropdown<FetchDepartmentsParams, Department>(
        '/v2/department',
        '/v2/department/query',
        props
    );
}
