import * as React from 'react';

import { PageLoader } from '@Component/PageLoader';

import type { DatabaseOverviewRootProps } from './component';

export const DatabaseOverviewPage: React.FC<DatabaseOverviewRootProps> = (
    props
) => (
    <PageLoader<DatabaseOverviewRootProps>
        loader={async () =>
            import('./DatabaseOverview.module').then((m) => m.page)
        }
        adjustWrapperDiv
        props={props}
    />
);
