import * as React from 'react';
import { clsx } from 'clsx';

import './LoadingDots.scss';

export type LoadingDotsProps = React.HTMLAttributes<HTMLSpanElement>;

/**
 * LoadingDots
 */
export const LoadingDots: React.FC<LoadingDotsProps> = ({
    className,
    ...spanProps
}) => (
    <span className={clsx('txr-loading-dots', className)} {...spanProps}>
        <span>.</span>
        <span>.</span>
        <span>.</span>
    </span>
);
