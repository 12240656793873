import { useEffect, useRef } from 'react';

export function useTrackUnmatchedSearchQuery(
    query: string,
    isEmpty: boolean,
    isLoading: boolean
) {
    const previousUnmatchedQueryRef = useRef(query);

    useEffect(() => {
        if (
            !isLoading &&
            isEmpty &&
            query !== '' &&
            previousUnmatchedQueryRef.current != query
        ) {
            window.dispatchEvent(
                new CustomEvent('tlx:account-help:search', {
                    detail: { query: query },
                })
            );
            previousUnmatchedQueryRef.current = query;
        }
    }, [isEmpty, isLoading, query]);
}
