import React from 'react';
import { PageLoader } from '@Component/PageLoader';
import { RouteData } from '@General/Router';
import { Team } from '@General/Team';

const Page = () => (
    <PageLoader<{}>
        loader={async () => import('./AddonsCustomer').then((m) => m.page)}
        adjustWrapperDiv
    />
);

export const route: RouteData = {
    component: Page,
    path: '/execute/addonCustomer',
    title: () => getMessage('text_tripletex_account_integration_details'),
    team: Team.BEEHIVE,
    documentationComponent: 340,
    auth: () => addonsCustomerForm,
};
