import { DistributionKeysTable } from '@Page/DistributionKeys/components/table/DistributionKeysTable';
import { DistributionKey } from '@Page/DistributionKeys/models/DistributionKeys';
import { ApiAction } from '@Page/DistributionKeys/models/Actions';
import { Markdown } from '@Component/Markdown';

type DistributionKeysViewProps = {
    distributionKeys?: DistributionKey[];
    dispatch: (apiAction: ApiAction, distributionKey: DistributionKey) => void;
};

export const DistributionKeysView = ({
    distributionKeys,
    dispatch,
}: DistributionKeysViewProps) => {
    return (
        <>
            <div className="atl-p-32 atl-font-normal">
                <Markdown
                    textKey="text_distribution_key_help_message"
                    linksTargetBlank={true}
                />
            </div>
            <DistributionKeysTable
                distributionKeys={distributionKeys}
                dispatch={dispatch}
            />
        </>
    );
};
