import { InitialsIcon } from '@tlx/atlas';
import React from 'react';

export type SignerDetailsProps = {
    name: string;
    description: string;
};

export function SignerDetails({ name, description }: SignerDetailsProps) {
    return (
        <div className="atl-flex atl-items-center atl-h-full">
            <div>
                <InitialsIcon className="atl-rounded-full">{name}</InitialsIcon>
            </div>
            <div style={{ maxWidth: 300 }}>
                <div className="atl-text-lg atl-ml-16 atl-truncate">{name}</div>
                <div className=" atl-text-base atl-ml-16 atl-text-grey-80 atl-truncate">
                    {description}
                </div>
            </div>
        </div>
    );
}
