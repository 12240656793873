/// see task 2126
import jQuery from 'jquery';
const $ = jQuery;

import { toJSON } from './jsonrpc';

const loadTimestamp = Date.now();
let disabled = false;

const ignoredErrors = [
    'Kan ikke flytte fokus til kontrollen fordi den er usynlig, deaktivert eller av en type som ikke kan ha fokus.',
    "Can't move focus to the control because it is invisible, not enabled, or of a type that does not accept the focus.",
    'Highcharts error #16: www.highcharts.com/errors/16',
];
function ignoreError(errorMessage, file) {
    // Some errors originate from plugins/extensions. Ignore these.
    if (window.productionMode && file && file.indexOf('tripletex.no') < 0) {
        return true;
    }

    for (let i = 0; i < ignoredErrors.length; i++) {
        if (errorMessage && errorMessage.indexOf(ignoredErrors[i]) >= 0) {
            return true;
        }
    }
    return false;
}

// This method should _never_ throw
// values should be a object if specified
function logError(desc, error, values) {
    try {
        if (disabled) {
            return;
        }
        values = values || {};
        $.extend(values, buildClientInfo());

        // eslint-disable-next-line no-console
        console.error(desc + ' ' + error + ', ' + toJSON(values));
        window.jsonrpc.Sync.remoteLogException(
            handleServerResponse,
            desc,
            error,
            toJSON(values)
        );
    } catch (_) {
        // Do nothing
    }
}
window.logError = logError;

/**
 * @deprecated use `captureException` from `@sentry/browser` (preferred) or `window.Sentry.captureException`
 */
export function logException(desc, e, values) {
    try {
        if (ignoreError(e.message, e.fileName)) {
            return;
        }
        values = values || {};
        logError(
            desc,
            e.message + '\n' + printStackTrace({ e: e }).join('\n'),
            $.extend(values, { exception: e })
        );
    } catch (_) {
        logError('Failed logging exception...! (' + desc + ')'); // in case printStackTrace is broken in some configurations
    }
}

function handleServerResponse(res, e) {
    if (e) {
        disabled = true; // bail
    }
}

function buildClientInfo() {
    const info = {};
    const activeTab = $('.ui-tabs-active > a');
    // include loaded tabs too?
    if (activeTab.length > 0) {
        info.location = activeTab.attr('href');
        info.parentLocation = location.href;
    } else {
        info.location = location.href;
    }

    info.tripletexVersion = window.tripletexVersion;
    info.userAgent = navigator.userAgent;
    info.windowSize = $(window).width() + ' x ' + $(window).height();
    //		info.screen_size = window.screen.availWidth+' x '+window.screen.availHeight;

    const ae = document.activeElement;
    if (ae != document.body) {
        // include eventhandlers?
        info.activeElement = { name: ae.name, id: ae.id, tagName: ae.tagName };
    }
    // doesn't really work as expected with tab pages
    info.timeAfterPageLoad = (Date.now() - loadTimestamp) / 1000;

    return info;
}

//// Cross browser stack traces:

// Domain Public by Eric Wendelin http://eriwen.com/ (2008)
//                  Luke Smith http://lucassmith.name/ (2008)
//                  Loic Dachary <loic@dachary.org> (2008)
//                  Johan Euphrosine <proppy@aminche.com> (2008)
//                  Oyvind Sean Kinsey http://kinsey.no/blog (2010)
//                  Victor Homyakov <victor-homyakov@users.sourceforge.net> (2010)
/**
 * Main function giving a function stack trace with a forced or passed in Error
 *
 * @cfg {Error} e The error to create a stacktrace from (optional)
 * @cfg {Boolean} guess If we should try to resolve the names of anonymous functions
 * @return {Array} of Strings with functions, lines, files, and arguments where possible
 */
function printStackTrace(options) {
    options = options || { guess: true };
    const ex = options.e || null;
    const guess = !!options.guess;
    const p = new printStackTrace.implementation();
    const result = p.run(ex);
    return guess ? p.guessAnonymousFunctions(result) : result;
}

printStackTrace.implementation = function () {
    /* noop */
};

printStackTrace.implementation.prototype = {
    /**
     * @param {Error} ex The error to create a stacktrace from (optional)
     * @param {String} mode Forced mode (optional, mostly for unit tests)
     */
    run: function (ex, mode) {
        ex = ex || this.createException();
        // examine exception properties w/o debugger
        //for (var prop in ex) {alert("Ex['" + prop + "']=" + ex[prop]);}
        mode = mode || this.mode(ex);
        if (mode === 'other') {
            return this.other(arguments.callee);
        }

        return this[mode](ex);
    },

    createException: function () {
        try {
            this.undef();
        } catch (e) {
            return e;
        }
    },

    /**
     * Mode could differ for different exception, e.g.
     * exceptions in Chrome may or may not have arguments or stack.
     *
     * @return {String} mode of operation for the exception
     */
    mode: function (e) {
        if (e['arguments'] && e.stack) {
            return 'chrome';
        } else if (e.stack && e.sourceURL) {
            return 'safari';
        } else if (e.stack && e.number) {
            return 'ie';
        } else if (e.stack && e.fileName) {
            return 'firefox';
        } else if (e.message && e['opera#sourceloc']) {
            // e.message.indexOf("Backtrace:") > -1 -> opera9
            // 'opera#sourceloc' in e -> opera9, opera10a
            // !e.stacktrace -> opera9
            if (!e.stacktrace) {
                return 'opera9'; // use e.message
            }
            if (
                e.message.indexOf('\n') > -1 &&
                e.message.split('\n').length > e.stacktrace.split('\n').length
            ) {
                // e.message may have more stack entries than e.stacktrace
                return 'opera9'; // use e.message
            }
            return 'opera10a'; // use e.stacktrace
        } else if (e.message && e.stack && e.stacktrace) {
            // e.stacktrace && e.stack -> opera10b
            if (e.stacktrace.indexOf('called from line') < 0) {
                return 'opera10b'; // use e.stacktrace, format differs from 'opera10a'
            }
            // e.stacktrace && e.stack -> opera11
            return 'opera11'; // use e.stacktrace, format differs from 'opera10a', 'opera10b'
        } else if (e.stack && !e.fileName) {
            // Chrome 27 does not have e.arguments as earlier versions,
            // but still does not have e.fileName as Firefox
            return 'chrome';
        }
        return 'other';
    },

    /**
     * Given a context, function name, and callback function, overwrite it so that it calls
     * printStackTrace() first with a callback and then runs the rest of the body.
     *
     * @param {Object} context of execution (e.g. window)
     * @param {String} functionName to instrument
     * @param {Function} callback function to call with a stack trace on invocation
     */
    instrumentFunction: function (context, functionName, callback) {
        context = context || window;
        const original = context[functionName];
        context[functionName] = function instrumented() {
            callback.call(this, printStackTrace().slice(4));
            // eslint-disable-next-line prefer-rest-params
            return context[functionName]._instrumented.apply(this, arguments);
        };
        context[functionName]._instrumented = original;
    },

    /**
     * Given a context and function name of a function that has been
     * instrumented, revert the function to it's original (non-instrumented)
     * state.
     *
     * @param {Object} context of execution (e.g. window)
     * @param {String} functionName to de-instrument
     */
    deinstrumentFunction: function (context, functionName) {
        if (
            context[functionName].constructor === Function &&
            context[functionName]._instrumented &&
            context[functionName]._instrumented.constructor === Function
        ) {
            context[functionName] = context[functionName]._instrumented;
        }
    },

    /**
     * Given an Error object, return a formatted Array based on Chrome's stack string.
     *
     * @param e - Error object to inspect
     * @return Array<String> of function calls, files and line numbers
     */
    chrome: function (e) {
        return (
            (e.stack + '\n')
                .replace(/^\s+(at eval )?at\s+/gm, '') // remove 'at' and indentation

                // eslint-disable-next-line no-useless-escape
                .replace(/^([^\(]+?)([\n$])/gm, '{anonymous}() ($1)$2')
                .replace(
                    // eslint-disable-next-line no-useless-escape
                    /^Object.<anonymous>\s*\(([^\)]+)\)/gm,
                    '{anonymous}() ($1)'
                )
                .replace(/^(.+) \((.+)\)$/gm, '$1@$2')
                .split('\n')
                .slice(1, -1)
        );
    },

    /**
     * Given an Error object, return a formatted Array based on Safari's stack string.
     *
     * @param e - Error object to inspect
     * @return Array<String> of function calls, files and line numbers
     */
    safari: function (e) {
        return (
            e.stack
                .replace(/\[native code\]\n/m, '')

                // eslint-disable-next-line no-useless-escape
                .replace(/^(?=\w+Error\:).*$\n/m, '')
                .replace(/^@/gm, '{anonymous}()@')
                .split('\n')
        );
    },

    /**
     * Given an Error object, return a formatted Array based on IE's stack string.
     *
     * @param e - Error object to inspect
     * @return Array<String> of function calls, files and line numbers
     */
    ie: function (e) {
        return e.stack
            .replace(/^\s*at\s+(.*)$/gm, '$1')
            .replace(/^Anonymous function\s+/gm, '{anonymous}() ')
            .replace(/^(.+)\s+\((.+)\)$/gm, '$1@$2')
            .split('\n')
            .slice(1);
    },

    /**
     * Given an Error object, return a formatted Array based on Firefox's stack string.
     *
     * @param e - Error object to inspect
     * @return Array<String> of function calls, files and line numbers
     */
    firefox: function (e) {
        return e.stack
            .replace(/(?:\n@:0)?\s+$/m, '')
            .replace(/^(?:\((\S*)\))?@/gm, '{anonymous}($1)@')
            .split('\n');
    },

    opera11: function (e) {
        const ANON = '{anonymous}';
        const lineRE = /^.*line (\d+), column (\d+)(?: in (.+))? in (\S+):$/;
        const lines = e.stacktrace.split('\n');
        const result = [];

        for (let i = 0, len = lines.length; i < len; i += 2) {
            const match = lineRE.exec(lines[i]);
            if (match) {
                const location = match[4] + ':' + match[1] + ':' + match[2];
                let fnName = match[3] || 'global code';
                fnName = fnName
                    .replace(/<anonymous function: (\S+)>/, '$1')
                    .replace(/<anonymous function>/, ANON);
                result.push(
                    fnName +
                        '@' +
                        location +
                        ' -- ' +
                        lines[i + 1].replace(/^\s+/, '')
                );
            }
        }

        return result;
    },

    opera10b: function (e) {
        // "<anonymous function: run>([arguments not available])@file://localhost/G:/js/stacktrace.js:27\n" +
        // "printStackTrace([arguments not available])@file://localhost/G:/js/stacktrace.js:18\n" +
        // "@file://localhost/G:/js/test/functional/testcase1.html:15"
        const lineRE = /^(.*)@(.+):(\d+)$/;
        const lines = e.stacktrace.split('\n');
        const result = [];

        for (let i = 0, len = lines.length; i < len; i++) {
            const match = lineRE.exec(lines[i]);
            if (match) {
                const fnName = match[1] ? match[1] + '()' : 'global code';
                result.push(fnName + '@' + match[2] + ':' + match[3]);
            }
        }

        return result;
    },

    /**
     * Given an Error object, return a formatted Array based on Opera 10's stacktrace string.
     *
     * @param e - Error object to inspect
     * @return Array<String> of function calls, files and line numbers
     */
    opera10a: function (e) {
        // "  Line 27 of linked script file://localhost/G:/js/stacktrace.js\n"
        // "  Line 11 of inline#1 script in file://localhost/G:/js/test/functional/testcase1.html: In function foo\n"
        const ANON = '{anonymous}';
        const lineRE =
            /Line (\d+).*script (?:in )?(\S+)(?:: In function (\S+))?$/i;
        const lines = e.stacktrace.split('\n');
        const result = [];

        for (let i = 0, len = lines.length; i < len; i += 2) {
            const match = lineRE.exec(lines[i]);
            if (match) {
                const fnName = match[3] || ANON;
                result.push(
                    fnName +
                        '()@' +
                        match[2] +
                        ':' +
                        match[1] +
                        ' -- ' +
                        lines[i + 1].replace(/^\s+/, '')
                );
            }
        }

        return result;
    },

    // Opera 7.x-9.2x only!
    opera9: function (e) {
        // "  Line 43 of linked script file://localhost/G:/js/stacktrace.js\n"
        // "  Line 7 of inline#1 script in file://localhost/G:/js/test/functional/testcase1.html\n"
        const ANON = '{anonymous}';
        const lineRE = /Line (\d+).*script (?:in )?(\S+)/i;
        const lines = e.message.split('\n');
        const result = [];

        for (let i = 2, len = lines.length; i < len; i += 2) {
            const match = lineRE.exec(lines[i]);
            if (match) {
                result.push(
                    ANON +
                        '()@' +
                        match[2] +
                        ':' +
                        match[1] +
                        ' -- ' +
                        lines[i + 1].replace(/^\s+/, '')
                );
            }
        }

        return result;
    },

    // Safari 5-, IE 9-, and others
    other: function (curr) {
        const ANON = '{anonymous}';
        const fnRE = /function\s*([\w\-$]+)?\s*\(/i;
        const stack = [];
        let fn;
        let args;
        const maxStackSize = 10;
        while (curr && curr['arguments'] && stack.length < maxStackSize) {
            fn = fnRE.test(curr.toString()) ? RegExp.$1 || ANON : ANON;
            args = Array.prototype.slice.call(curr['arguments'] || []);
            stack[stack.length] =
                fn + '(' + this.stringifyArguments(args) + ')';
            curr = curr.caller;
        }
        return stack;
    },

    /**
     * Given arguments array as a String, substituting type names for non-string types.
     *
     * @param {Arguments,Array} args
     * @return {String} stringified arguments
     */
    stringifyArguments: function (args) {
        const result = [];
        const slice = Array.prototype.slice;
        for (let i = 0; i < args.length; ++i) {
            const arg = args[i];
            if (arg === undefined) {
                result[i] = 'undefined';
            } else if (arg === null) {
                result[i] = 'null';
            } else if (arg.constructor) {
                if (arg.constructor === Array) {
                    if (arg.length < 3) {
                        result[i] = '[' + this.stringifyArguments(arg) + ']';
                    } else {
                        result[i] =
                            '[' +
                            this.stringifyArguments(slice.call(arg, 0, 1)) +
                            '...' +
                            this.stringifyArguments(slice.call(arg, -1)) +
                            ']';
                    }
                } else if (arg.constructor === Object) {
                    result[i] = '#object';
                } else if (arg.constructor === Function) {
                    result[i] = '#function';
                } else if (arg.constructor === String) {
                    result[i] = '"' + arg + '"';
                } else if (arg.constructor === Number) {
                    result[i] = arg;
                }
            }
        }
        return result.join(',');
    },

    sourceCache: {},

    /**
     * @return the text from a given URL
     */
    ajax: function (url) {
        const req = this.createXMLHTTPObject();
        if (req) {
            try {
                req.open('GET', url, false);
                //req.overrideMimeType('text/plain');
                //req.overrideMimeType('text/javascript');
                req.send(null);
                //return req.status == 200 ? req.responseText : '';
                return req.responseText;
            } catch (e) {
                // Do nothing
            }
        }
        return '';
    },

    /**
     * Try XHR methods in order and store XHR factory.
     *
     * @return <Function> XHR function or equivalent
     */
    createXMLHTTPObject: function () {
        return new XMLHttpRequest();
    },

    /**
     * Given a URL, check if it is in the same domain (so we can get the source
     * via Ajax).
     *
     * @param url <String> source url
     * @return <Boolean> False if we need a cross-domain request
     */
    isSameDomain: function (url) {
        return (
            typeof location !== 'undefined' &&
            url.indexOf(location.hostname) !== -1
        ); // location may not be defined, e.g. when running from nodejs.
    },

    /**
     * Get source code from given URL if in the same domain.
     *
     * @param url <String> JS source URL
     * @return <Array> Array of source code lines
     */
    getSource: function (url) {
        // TODO reuse source from script tags?
        if (!(url in this.sourceCache)) {
            this.sourceCache[url] = this.ajax(url).split('\n');
        }
        return this.sourceCache[url];
    },

    guessAnonymousFunctions: function (stack) {
        for (let i = 0; i < stack.length; ++i) {
            const reStack = /\{anonymous\}\(.*\)@(.*)/;
            const reRef = /^(.*?)(?::(\d+))(?::(\d+))?(?: -- .+)?$/;
            const frame = stack[i];
            const ref = reStack.exec(frame);

            if (ref) {
                const m = reRef.exec(ref[1]);
                if (m) {
                    // If falsey, we did not get any file/line information
                    const file = m[1];
                    const lineno = m[2];
                    const charno = m[3] || 0;
                    if (file && this.isSameDomain(file) && lineno) {
                        const functionName = this.guessAnonymousFunction(
                            file,
                            lineno,
                            charno
                        );
                        stack[i] = frame.replace('{anonymous}', functionName);
                    }
                }
            }
        }
        return stack;
    },

    guessAnonymousFunction: function (url, lineNo) {
        let ret;
        try {
            ret = this.findFunctionName(this.getSource(url), lineNo);
        } catch (e) {
            ret =
                'getSource failed with url: ' +
                url +
                ', exception: ' +
                e.toString();
        }
        return ret;
    },

    findFunctionName: function (source, lineNo) {
        // FIXME findFunctionName fails for compressed source
        // (more than one function on the same line)
        // function {name}({args}) m[1]=name m[2]=args
        const reFunctionDeclaration = /function\s+([^(]*?)\s*\(([^)]*)\)/;
        // {name} = function ({args}) TODO args capture
        // /['"]?([0-9A-Za-z_]+)['"]?\s*[:=]\s*function(?:[^(]*)/
        const reFunctionExpression =
            /['"]?([$_A-Za-z][$_A-Za-z0-9]*)['"]?\s*[:=]\s*function\b/;
        // {name} = eval()
        const reFunctionEvaluation =
            /['"]?([$_A-Za-z][$_A-Za-z0-9]*)['"]?\s*[:=]\s*(?:eval|new Function)\b/;
        // Walk backwards in the source lines until we find
        // the line which matches one of the patterns above
        let code = '';
        let line;
        const maxLines = Math.min(lineNo, 20);
        let m;
        let commentPos;
        for (let i = 0; i < maxLines; ++i) {
            // lineNo is 1-based, source[] is 0-based
            line = source[lineNo - i - 1];
            commentPos = line.indexOf('//');
            if (commentPos >= 0) {
                line = line.substr(0, commentPos);
            }
            // TODO check other types of comments? Commented code may lead to false positive
            if (line) {
                code = line + code;
                m = reFunctionExpression.exec(code);
                if (m && m[1]) {
                    return m[1];
                }
                m = reFunctionDeclaration.exec(code);
                if (m && m[1]) {
                    //return m[1] + "(" + (m[2] || "") + ")";
                    return m[1];
                }
                m = reFunctionEvaluation.exec(code);
                if (m && m[1]) {
                    return m[1];
                }
            }
        }
        return '(?)';
    },
};
