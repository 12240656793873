import { getSvgIcon } from '@Page/LogisticsV2/utils/svgIcons';
import { Button, Modal, ModalCloseButton, ModalTitle } from '@tlx/atlas';
import classNames from 'classnames';
import { ReactNode } from 'react';
import './modals.css';

export function LogisticsModal({
    children,
    className,
    svgIcon,
    iconSize,
    title,
    onConfirm,
    onCancel,
    onClose,
}: {
    children?: ReactNode;
    className?: string;
    svgIcon?: string;
    iconSize?: number;
    title?: string;
    onConfirm?: () => void;
    onCancel?: () => void;
    onClose?: () => void;
}) {
    return (
        <Modal
            className={classNames(
                'goldshark-confirmation-modal__container',
                className
            )}
            open={true}
            onClose={onCancel || onClose}
        >
            {svgIcon && getSvgIcon(svgIcon, iconSize)}
            <ModalTitle className="atl-my-16">{title}</ModalTitle>
            <ModalCloseButton />
            <div
                className={'atl-flex atl-gap-8 atl-mb-16 goldshark-modal-body'}
            >
                {children}
            </div>
            <div className={'atl-flex atl-gap-8'}>
                {onConfirm && (
                    <Button
                        onClick={onConfirm}
                        data-testid={'confirm-warning-modal'}
                    >
                        {getMessage('text_confirm')}
                    </Button>
                )}
                {onCancel && (
                    <Button
                        data-testid={'cancel-warning-modal'}
                        onClick={onCancel}
                        variant={'secondary'}
                    >
                        {getMessage('button_cancel')}
                    </Button>
                )}
                {onClose && (
                    <Button
                        data-testid={'close-warning-modal'}
                        onClick={onClose}
                        variant={'primary'}
                    >
                        {getMessage('button_close')}
                    </Button>
                )}
            </div>
        </Modal>
    );
}
