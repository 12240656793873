import { ISagaModule } from 'redux-dynamic-modules-saga';
import { reducer as formReducer } from 'redux-form';

import {
    generalReducers,
    historyReducers,
    componentReducers,
    betaProgramReducers,
    developerReducers,
} from './reducer';

import {
    generalSagas,
    historySagas,
    betaProgramSagas,
    developerSagas,
} from './saga';

export const globalModules: Array<ISagaModule<unknown>> = [
    {
        id: 'General',
        reducerMap: generalReducers,
        sagas: generalSagas,
    },
    {
        id: 'History',
        reducerMap: historyReducers,
        sagas: historySagas,
    },
    {
        id: 'Components',
        reducerMap: componentReducers,
    },
    {
        id: 'ReduxForm',
        reducerMap: {
            form: formReducer,
        },
    },
    {
        id: 'betaProgramLoader',
        reducerMap: betaProgramReducers,
        sagas: betaProgramSagas,
    },
    {
        id: 'developerLoader',
        reducerMap: developerReducers,
        sagas: developerSagas,
    },
];
