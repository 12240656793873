export enum MESSAGE_TYPE {
    INFO = 'info',
    WARNING = 'warning',
    ERROR = 'error',
    CONFIRMATION = 'confirmation',
}

export enum MESSAGE_POSITION {
    SNACKBAR = 'SNACKBAR',
    PAGE = 'PAGE',
    INLINE = 'INLINE',
}

export type MessageAction = {
    text: string;
    raised?: boolean;
    onClick?: Function;
    dispatchOnClick?: Record<string, any>;
    closeOnClick?: boolean;
    trackingId?: string;
};

export type MessageObject = {
    position: MESSAGE_POSITION;
    pagePath?: string; // if position is PAGE, providing this will restrain the message to pages with given path
    text: string;
    type?: MESSAGE_TYPE;
    timeout?: number;
    action?: MessageAction;
    isCloseable?: boolean;
    isMarkdown?: boolean;
};

export type MessagesState = {
    messages: MessageObject[];
};

export type GlobalState = {
    readonly messages: MessagesState;
};
