import * as React from 'react';
import { getOverflowAncestors, useFloating } from '@floating-ui/react-dom';

export interface FloatingSurfaceProps
    extends React.HTMLAttributes<HTMLDivElement> {
    readonly 'data-testid'?: string;

    readonly openerRef: React.MutableRefObject<HTMLElement | null>;
    readonly middleware?: (data: ReturnType<typeof useFloating>) => void;
    readonly options: Parameters<typeof useFloating>[0];
}

export const FloatingSurface: React.FC<FloatingSurfaceProps> = ({
    openerRef,
    middleware,
    options,
    children,
    style,
    ...divProps
}) => {
    const { hidden } = divProps;

    const floatingData = useFloating(options);
    const { x, y, strategy, update, refs } = floatingData;

    if (middleware) {
        middleware(floatingData);
    }

    // Apply external anchor reference
    React.useLayoutEffect(() => {
        floatingData.refs.setReference(openerRef.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [floatingData.refs.setReference, openerRef.current]);

    // Update on scroll and resize for all relevant nodes
    React.useEffect(() => {
        if (!refs.floating.current || !refs.reference.current) {
            return;
        }

        const parents = [
            ...getOverflowAncestors(refs.floating.current),
            ...(refs.reference.current instanceof HTMLElement
                ? getOverflowAncestors(refs.reference.current)
                : []),
        ];

        parents.forEach((parent) => {
            parent.addEventListener('scroll', update, { passive: true });
            parent.addEventListener('resize', update, { passive: true });
        });

        return () => {
            parents.forEach((parent) => {
                parent.removeEventListener('scroll', update);
                parent.removeEventListener('resize', update);
            });
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refs.floating.current, refs.reference.current, update]);

    React.useLayoutEffect(() => {
        if (!hidden) {
            update();
        }
    }, [update, hidden]);

    return (
        <div
            ref={refs.setFloating}
            {...divProps}
            style={{
                ...style,
                position: strategy,
                top: y ?? '',
                left: x ?? '',
            }}
        >
            {hidden ? null : children}
        </div>
    );
};
