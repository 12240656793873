import classNames from 'classnames';
import React from 'react';
import './ToggleSwitch.css';

type ToggleSwitchProps = {
    id?: string;
    checked?: boolean;
    disabled?: boolean;
    className?: string;
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
    onCheckedChange?: (value: boolean) => void;
    label?: string;
};

export function ToggleSwitch({
    id,
    checked,
    disabled,
    className,
    onChange,
    onCheckedChange,
    label,
}: ToggleSwitchProps) {
    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        onChange?.(event);
        onCheckedChange?.(event.target.checked);
    }
    return (
        <div className={'atl-flex atl-items-center'}>
            <label className={classNames('goldshark-toggle-switch', className)}>
                <input
                    id={id}
                    type="checkbox"
                    checked={checked}
                    onChange={handleChange}
                    disabled={disabled}
                />
                <span className="switch" />
            </label>
            <div>{label}</div>
        </div>
    );
}
