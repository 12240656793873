import { ALL_PRODUCT_GROUPS } from '@Page/LogisticsV2/pages/Product/overview/utils';
import { useListSearchParam, useSearchParam } from '@tlx/astro-shared';

export function useProductOverviewFilter() {
    const statusFilter = useListSearchParam('status', ['active']);
    const query = useSearchParam('query');
    const supplierFilter = useListSearchParam('supplier');
    const inventoryFilter = useSearchParam('inventory');
    const productGroupsFilter = useListSearchParam('productGroups', [
        ALL_PRODUCT_GROUPS,
    ]);
    const withoutMainSupplier = useSearchParam('withoutMainSupplier');
    const stockItemFilter = useSearchParam('stockItem');
    const incomeAccountFilter = useSearchParam('incomeAccount');
    const vatTypeFilter = useSearchParam('vatType');
    const productUnitFilter = useSearchParam('productUnit');

    return {
        productGroupsFilter,
        productUnitFilter,
        query,
        supplierFilter,
        statusFilter,
        incomeAccountFilter,
        inventoryFilter,
        vatTypeFilter,
        withoutMainSupplier,
        stockItemFilter,
    };
}
