import { useEffect, useRef } from 'react';

/**
 *  Get previous value of a prop
 */
export function usePrevious<T>(value: T) {
    const ref = useRef<T>();

    useEffect(() => {
        ref.current = value;
    }, [value]);

    return ref.current;
}
