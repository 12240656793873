import { invokeAPI } from '@Page/LogisticsV2/hooks/api';
import { ApiUrl, MethodType } from '@Page/LogisticsV2/utils/enums';

export function doDeleteDiscount(id: number) {
    return invokeAPI(`${ApiUrl.DISCOUNT_POLICY}/${id}`, MethodType.DELETE);
}

export function doEditDiscount(
    id: number,
    currentPrice: number,
    formElement: HTMLFormElement,
    values: { [k: string]: FormDataEntryValue }
) {
    const fixedAmount = Number(values['editDiscountValueInput']);
    const payload = {
        ...(values['editDiscountValue'] !== undefined
            ? { fixedAmount: currentPrice - fixedAmount }
            : {
                  fixedAmount: '0',
              }),
        ...(values['editDiscountPercent'] !== undefined
            ? { percentage: values['editDiscountPercentInput'] }
            : { percentage: '0' }),
    };
    return invokeAPI(
        `${ApiUrl.DISCOUNT_POLICY}/${id}`,
        MethodType.PUT,
        JSON.stringify(payload)
    );
}

export function doCreateDiscount(
    formElement: HTMLFormElement,
    values: { [k: string]: FormDataEntryValue },
    currentPrice: number
) {
    const fixedAmount = Number(values['discountValueInput']);
    const customerId = document.querySelector<HTMLInputElement>(
        '#customerIdForProductOverview'
    )?.value;
    const payload = {
        customer: {
            id: customerId,
        },
        product: { id: values['discountProduct'] },
        ...(values['discountProductGroup'] !== undefined
            ? { productGroup: { id: values['discountProductGroup'] } }
            : {}),
        ...(values['discountValue'] !== undefined
            ? {
                  fixedAmount: currentPrice - fixedAmount,
              }
            : {
                  fixedAmount: 0,
              }),
        ...(values['discountPercent'] !== undefined
            ? { percentage: values['discountPercentInput'] }
            : { percentage: 0 }),
    };

    const keepExistingDiscount = values['keepExistingDiscount'] === 'on';

    return invokeAPI(
        `${ApiUrl.DISCOUNT_POLICY}?keepExistingDiscount=${keepExistingDiscount}`,
        MethodType.POST,
        JSON.stringify(payload)
    );
}

export function doDeleteDiscounts(ids: string) {
    return invokeAPI(
        `${ApiUrl.DISCOUNT_POLICY}/list?ids=${ids}`,
        MethodType.DELETE
    );
}
