import * as React from 'react';

import { MaterialIcon, MaterialIconProps } from '@Component/MaterialIcon';

export type FormIconProps = Omit<MaterialIconProps, 'children'> & {
    icon: string;
};

export const Icon: React.FunctionComponent<FormIconProps> = (props) => {
    const { icon, ...rest } = props;

    return <MaterialIcon {...rest}>{icon}</MaterialIcon>;
};
