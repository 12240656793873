import jQuery from 'jquery';
const $ = jQuery;

/**
 *
 * <zelect> plugin for making a nice looking <select>.
 *
 *
 * @author bruce
 * @date november 2014
 *
 **/
$.fn.zelect = function (options) {
    // Deal with (default) options
    var popupevent = (options && options.popupevent) || 'mouseover touchstart', // Which event should show the list of options
        closeOnLeave = (options && options.closeOnLeave) || true, // Should options close when mouse pointer leave the options?
        $scrollContainer = (options && options.$scrollContainer) || $(window), // What window should the list of options contain itself within?
        // Other variables
        $originalSelect = this,
        $options = $originalSelect.find('option'),
        $selected = this.find('option:selected'),
        $zelected = $(),
        $zelectWrapper = $('<div class="zelectWrapper" />'),
        $zelect = $('<div class="zelect noselect"/>')
            .append('<span class="text">' + $selected.text() + '</span>')
            .append('<span class="icon-select"/>'),
        $optionz = $('<div class="optionz noselect"/>').hide();

    $zelect.addClass($originalSelect.attr('class'));

    // Only bind events etc if there is something to choose from.
    if ($options.length > 1) {
        $options.each(function () {
            var $this = $(this),
                val = $this.val(),
                text = $this.text(),
                $opt = $(
                    '<div class="option" data-value="' +
                        val +
                        '">' +
                        text +
                        '</div>'
                );
            $optionz.append($opt);
            if ($this.is(':selected')) {
                $zelected = $opt;
                $opt.addClass('selected');
            }

            $opt.click(function () {
                var $that = $(this),
                    oldVal = $originalSelect.val().toString(),
                    value = $that.data('value').toString();
                if (oldVal !== value) {
                    $zelected.removeClass('selected');
                    $zelected = $that;
                    $zelected.addClass('selected');
                    $originalSelect.val(value);
                    $zelect.find('.text').text($that.text());
                    $originalSelect.trigger('change');
                }
            });
        });

        $zelect.on(popupevent, function () {
            // Reset drawing of $optionz boks.
            $optionz.css('max-height', $scrollContainer.height());
            $optionz.show();
            $optionz.css({ top: 0 });
            $optionz.get(0).scrollTop = 0;

            // Place the selected option right above the select.
            // We have to move the $options box upwards until the
            // selected div ($zelected) is vertically aligned with
            // the .text inside the $zelect element.
            var textOffset = $zelect.find('.text').offset();
            var zelectedOffset = $zelected.offset();
            var optionzOffset = $optionz.offset();

            var optionzOffsetTopInitial =
                textOffset.top -
                zelectedOffset.top -
                (textOffset.top - optionzOffset.top);

            $optionz.css(
                'left',
                textOffset.left - $zelectWrapper.offset().left
            );
            $optionz.css('top', optionzOffsetTopInitial);

            // Place the options inside window if some parts of it are outside
            var pushdown =
                $scrollContainer.offset().top - $optionz.offset().top;
            var pushup =
                $optionz.offset().top + $optionz.height() - $(window).height();
            if (pushup > 0) {
                $optionz.css('top', optionzOffsetTopInitial - pushup);
            } else if (pushdown > 0) {
                $optionz.css('top', optionzOffsetTopInitial + pushdown);
            }

            function closeOptions() {
                $optionz.hide();
            }

            if (closeOnLeave) {
                $optionz.one('mouseleave', closeOptions);
            }

            $('body').one('click', closeOptions);

            return false;
        });
    } else {
        // When we dont have something to choose from
        $zelect.css('cursor', 'default').find('.icon-select').remove();
    }

    this.hide().after($zelectWrapper.append($zelect).append($optionz));

    return $zelect;
};
