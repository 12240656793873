import { TextField } from '@Page/PaymentWidget/component/TextField/TextField';

type KidNumberProps = {
    value?: string;
    setValue: (value: string) => void;
    atlasDesignSystem: boolean;
    name?: string;
    validationMessage?: string;
    resetValidationMessage?: () => void;
    className?: string;
};

/**
 * ClassicTextField: for KID validation on the classic component we utilise the global validation coming from "tlxUtil.js"
 * This needs the inputClassName="kidNumber" being set to work properly
 */
export const KidNumberOrReceiverReference = ({
    value,
    setValue,
    atlasDesignSystem,
    name,
    validationMessage,
    resetValidationMessage,
    className,
}: KidNumberProps) => {
    const isKid = className?.includes('kidNumber');
    const placeholder = atlasDesignSystem
        ? isKid
            ? 'text_kid_or_receiver_reference'
            : 'text_message_25_characters'
        : undefined;
    return (
        <TextField
            id="kid"
            placeholder={placeholder}
            label={getMessage(
                isKid
                    ? 'text_kid_or_receiver_reference'
                    : 'text_message_25_characters'
            )}
            className={className}
            atlasDesignSystem={atlasDesignSystem}
            value={value}
            setValue={setValue}
            name={name}
            validationMessage={validationMessage}
            resetValidationMessage={resetValidationMessage}
            required={!!value}
            maxLength={35}
        />
    );
};
