import * as React from 'react';

import { renderComponent } from '@General/renderComponent';
import {
    InvoicingModal,
    InvoicingModalProps,
} from '@Page/InvoicingDialog/component/InvoicingModal';

export function renderInvoicingFormModal(
    domId: string,
    isOpenByDefault: boolean,
    forwardURL: undefined | string,
    orderIds: number[]
) {
    renderComponent<InvoicingModalProps>(InvoicingModal, domId, {
        isOpenByDefault,
        forwardURL,
        initialOrderIds: orderIds,
    });
}
