import { AutoSaveStatusType } from '@Page/LogisticsV2/utils/enums';
import { SavedIcon, SaveFailedIcon, SavingIcon } from '@tlx/atlas';

export function AutoSaveStatus({ status }: { status: AutoSaveStatusType }) {
    return (
        <>
            {status === AutoSaveStatusType.SAVED && <SavedStatus />}
            {status === AutoSaveStatusType.PENDING && <PendingStatus />}
            {status === AutoSaveStatusType.ERROR && <ErrorStatus />}
        </>
    );
}

const SavedStatus = () => {
    return (
        <div className={'atl-flex atl-gap-4 atl-items-center'}>
            <SavedIcon className="atl-text-grey-40" />
            <div className="atl-text-grey-40 ">
                {getMessage('text_autosave_status_saved')}
            </div>
        </div>
    );
};

const PendingStatus = () => {
    return (
        <div className={'atl-flex atl-gap-4 atl-items-center'}>
            <SavingIcon className="atl-text-grey-40" />
            <div className="atl-text-grey-40">
                {getMessage('text_autosave_status_saving')}
            </div>
        </div>
    );
};

const ErrorStatus = () => {
    return (
        <div className={'atl-flex atl-gap-4 atl-items-center'}>
            <SaveFailedIcon className="atl-text-grey-40" />
            <div className="atl-text-grey-40">
                {getMessage('text_autosave_status_failed')}
            </div>
        </div>
    );
};
