import jQuery from 'jquery';
const $ = jQuery;

import { browserInfo } from '../modules/d-browserInfo';

/**
 * This code purpose is to optimise the scrolling on iOS devices. It does this by dynamically setting the height
 * on every possible size change. For every other devices (including desktop) this is done in scss using the css
 * property vh. This cannot be done on iOS devices since the vh property behaves a bit different. On iOS devices
 * the vh property is the viewport height PLUS the url bar height. Since the url bar height is different on every
 * iOS device this has do be done in js using innerHeight.
 */

//We wait for document ready to be sure the browserInfo has been loaded
$(function () {
    if (browserInfo.isIOS && !browserInfo.isIOS()) {
        return;
    }

    $(window).on(
        'resize orientationchange tlxPossibleWidthChange tlxToggleActionBar tlx-toggleTabsVisibility tlxLazyLoadDone tlxInitializeStateDone',
        function () {
            const headerHeight = $('.mdl-layout__header-row').outerHeight();
            const insetHeaderHeight = $('.tmld-drawer-header').outerHeight();
            const innerHeight = window.innerHeight - headerHeight;
            const insetInnerHeight = innerHeight - insetHeaderHeight;

            const tmdlLayoutActionBarOpen = '.tmdl-layout__action-bar-open';
            const tmdlLayoutTabBarOpen = '.tmdl-layout__tab_bar-open';

            $('.tmdl-layout--fixed-header')
                .find('.mdl-navigation')
                .height(innerHeight);

            _setHeight('body', innerHeight, insetInnerHeight - 10);
            _setHeight(tmdlLayoutActionBarOpen, innerHeight, insetInnerHeight);
            _setHeight(tmdlLayoutTabBarOpen, innerHeight, insetInnerHeight);
            _setHeight(
                tmdlLayoutActionBarOpen + tmdlLayoutTabBarOpen,
                innerHeight,
                insetInnerHeight
            );

            if (browserInfo.isIphone()) {
                _hackFixedElement($('.beta-feedback'));
                _hackFixedElement($('.tmdl-layout__action-bar-row'));
                _hackFixedElement($('.mdl-layout__header'));
            }
        }
    );

    $(window).on('tlxDatepickerVisible', function (e) {
        const landscapeMode = window.innerWidth > window.innerHeight;

        if (!landscapeMode || window.innerHeight > $(e.target).outerHeight()) {
            return;
        }

        const $materialHeaderHeight = $(
            '.ui-datepicker-tmdl-header',
            e.target
        ).outerHeight();
        const $headerHeight = $(
            '.ui-datepicker-header',
            e.target
        ).outerHeight();
        const $buttonpaneHeight = $(
            '.ui-datepicker-buttonpane',
            e.target
        ).outerHeight();
        const $calendarHeight =
            window.innerHeight -
            $materialHeaderHeight -
            $headerHeight -
            $buttonpaneHeight;

        $('.ui-datepicker-calendar', e.target).height($calendarHeight);
    });

    function _setHeight(className, contentHeight, menuHeight) {
        if (!browserInfo.isIphone()) {
            $(className).find('#framelessContent').height(contentHeight);
        }
        $(className)
            .find('.tmdl-layout__inset')
            .height(contentHeight)
            .find('.mdl-navigation')
            .height(menuHeight);
    }

    //This hack prevents fixed elements from bouncing around on the page on input focus
    //It simply hides the fixed element while it is jumping...
    function _hackFixedElement($fixedElement) {
        $('input').on('focus blur', function (event) {
            if (event.handled !== true) {
                if ($('input').is(':focus')) {
                    $fixedElement.hide();

                    setTimeout(function () {
                        $fixedElement.show();
                    }, 100);
                }
            }
            return false;
        });
    }
});
