import * as React from 'react';

import { RouteData, RouteProps } from '@General/Router';
import { Team } from '@General/Team';

import { PageLoader } from '@Component/PageLoader';

const component: React.FC<RouteProps> = () => (
    <PageLoader<RouteProps>
        loader={async () => import('./ReportLanding').then((m) => m.page)}
        adjustWrapperDiv
    />
);

export const route: RouteData = {
    component,
    path: '/execute/report/landing',
    team: Team.DATA_STREAM,
    documentationComponent: 343, // REPORT_OVERVIEW TODO convert to enum
    title: () => '', // Needed otherwise the fallback 401 page title will show up.
    // Always authorized as the page content is adjusted automatically
    auth: () => true,
};
