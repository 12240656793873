// Returns true if the hash has changed, and a reload was triggered.
export function handlePossibleHashChange(newClientHash: string): boolean {
    if (!window.oldClientHash) {
        window.oldClientHash = newClientHash;
    }

    if (newClientHash !== window.oldClientHash) {
        window.reloadingDueToHashChange = true;
        location.reload();
        // This code might not run (JavaScript could halt execution after location.reload())
        // but at least it makes TypeScript happy.
        return true;
    } else {
        // The hash has not changed
        return false;
    }
}
