import {
    Settings,
    TableColumnSetting,
    TableColumnsSetting,
} from '@tlx/astro-shared';
import {
    availableColumnsInProductOverviewWithSupplier,
    defaultColumnsForProductOverviewWithSupplier,
    getAvailableColumnsInProductOverview,
    getColumnLabel,
    getDefaultColumnsInProductOverview,
} from './utils';
import { getIdFromUrl } from '@Page/LogisticsV2/utils/functions';

export function ProductOverviewDisplayOptions() {
    return (
        <Settings>
            <TableColumnsSetting
                name={getProductOverviewDisplayOptionsName()}
                label={getMessage('text_columns')}
                aria-label="Columns-for-product-overview"
                defaultColumns={getDefaultColumnsInProductOverview()}
            >
                {getAvailableColumnsInProductOverview().map((column) => (
                    <TableColumnSetting key={column} value={column}>
                        {getColumnLabel(column)}
                    </TableColumnSetting>
                ))}
            </TableColumnsSetting>
        </Settings>
    );
}

export function ProductOverviewDisplayOptionsWithSupplier() {
    const supplierFilter = getIdFromUrl('supplier');
    const customerId =
        document.querySelector<HTMLInputElement>(
            '#customerIdForProductOverview'
        )?.value ?? null;
    return (
        <Settings>
            <TableColumnsSetting
                name={`columns-product-overview-2-with-supplier-${window.loginEmployeeId}`}
                label={getMessage('text_columns')}
                aria-label="Columns-for-product-overview-with-supplier"
                defaultColumns={defaultColumnsForProductOverviewWithSupplier}
            >
                {availableColumnsInProductOverviewWithSupplier().map(
                    (column) => {
                        let label = getColumnLabel(column);
                        if (
                            (supplierFilter || customerId) &&
                            column === 'costExcludingVatCurrency'
                        ) {
                            label += ' (NOK)';
                        }
                        return (
                            <TableColumnSetting key={column} value={column}>
                                {label}
                            </TableColumnSetting>
                        );
                    }
                )}
            </TableColumnsSetting>
        </Settings>
    );
}

export function getProductOverviewDisplayOptionsName() {
    let name = `columns-product-overview-2-${window.loginEmployeeId}`;
    if (moduleRackbeat) {
        name = name + '-rackbeat';
    }
    if (moduleWarehouse) {
        name = name + '-warehouse';
    }
    if (isVatOn) {
        name = name + '-vat';
    }
    if (window.moduleCurrency) {
        name = name + '-currency';
    }
    if (hasAvailableStock) {
        name = name + '-availableStock';
    }

    return name;
}
