@{%
import { createLexer } from './Expression.lexer';
const lexer = createLexer();
%}
@lexer lexer

@{%
function extractToken(value) {
    const token = id(value);
    return {
        ...token
    };
}

function extractAndFlattenToken(value) {
    console.log("extractAndFlattenToken before", JSON.stringify(value));
    const token = id(value);

    if (Array.isArray(token)) {

        const result = token.flat();

        console.log("extractAndFlattenToken after", JSON.stringify(result));
        return result;
    } else {
        const result = {
            ...token
        };

        console.log("extractAndFlattenToken after", JSON.stringify(result));
        return result;
    }

}

function extractInfixOperator() {

}

function extractInfixOperation([[nodes]]) {
    if (nodes.length === 1) {
        return nodes[0];
    }

    const [[leftOperand], [[operator]], [rightOperand]] = nodes;

    return {
        type: "binaryOperation",
        leftOperand,
        operator,
        rightOperand,
        offset: leftOperand.offset,
        lineBreaks: leftOperand.lineBreaks,
        line: leftOperand.line,
        col: leftOperand.col
    };
}

function extractPrefixOperation([[nodes]]) {
    if (nodes.length === 1) {
        return nodes[0];
    }

    const [[[operator]], [operand]] = nodes;

    return {
        type: "unaryOperation",
        operator,
        operand,
        offset: operator.offset,
        lineBreaks: operator.lineBreaks,
        line: operator.line,
        col: operator.col
    };
}

%}

root -> sum {% id %}

# General foruma
# orn = Operator rule name
# ot = Operator token
# next = Next precedence operator
# Left associativity:
#   orn -> orn ot next | next
# Right Associativity:
#   orn -> next ot orn | next

leftInfix[orn, ot, next] ->
      ($orn $ot $next)
    | $next

rightInfix[orn, ot, next] ->
      ($next $ot $orn)
    | $next

prefix[ot, next] ->
    ($ot $next)
    | $next

postfix[ot, next] ->
    ($next $ot)
    | $next

sum -> leftInfix[sum, (%plus | %minus), product] {% extractInfixOperation %}
product -> leftInfix[product, (%multiplier | %divider), exponent] {% extractInfixOperation %}
exponent -> rightInfix[exponent, (%power | %modulo), unary] {% extractInfixOperation %}
unary -> prefix[(%plus | %minus), value] {% extractPrefixOperation %}

value ->
    %number {% extractToken %}
    | roundBracketGroup {% extractToken %}

roundBracketGroup -> %leftRoundBracket sum %rightRoundBracket {%
    function([lrb, content]) {
        return {
            type: "roundBracketGroup",
            offset: lrb.offset,
            lineBreaks: lrb.lineBreaks,
            line: lrb.line,
            col: lrb.col,
            content
        }
    }
%}
