import { useInfiniteScrollData } from '@Page/LogisticsV2/hooks/api';
import {
    DISCOUNT_POLICY_FIELDS,
    DiscountPolicyApiModel,
} from '@Page/LogisticsV2/pages/Product discount/overview/types';
import { ApiUrl, SortingType } from '@Page/LogisticsV2/utils/enums';
import { useSearchParam } from '@tlx/astro-shared';
import useSessionStorage from '@Page/LogisticsV2/hooks/useSessionStorage';
import { useDebouncedQuery } from '@Page/LogisticsV2/hooks/useDebouncedQuery';

export function useDiscountPolicyData() {
    const customerId = document.querySelector<HTMLInputElement>(
        '#customerIdForProductOverview'
    )?.value;
    const query = useSearchParam('query');
    const productGroup = useSearchParam('productGroup');
    const [sorting] = useSessionStorage<SortingType>(
        'goldshark_discount_overview_sorting',
        SortingType.NUMBER_DESCENDING
    );
    const debouncedQuery = useDebouncedQuery(query ?? '', 600);

    const { data, isLoading, hasMore, loadMore, refresh } =
        useInfiniteScrollData<DiscountPolicyApiModel>(ApiUrl.DISCOUNT_POLICY, {
            fields: DISCOUNT_POLICY_FIELDS,
            customerId: customerId,
            query: debouncedQuery,
            sorting: sorting,
            productGroupId: productGroup !== '-1' ? productGroup : undefined,
        });
    return { data, isLoading, hasMore, loadMore, refresh };
}
