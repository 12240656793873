import { PageLoader } from '@Component/PageLoader';
import { renderComponent } from '@General/renderComponent';
import { ZtlPaymentsLookupTableProps } from '@General/PBC/SupportTools/ZtlPaymentsLookupTable/ZtlPaymentsLookupTable';
import { FC } from 'react';

const Page: FC<ZtlPaymentsLookupTableProps> = (props) => (
    <PageLoader<ZtlPaymentsLookupTableProps>
        loader={async () =>
            import('./ZtlPaymentsLookupTable.page').then((m) => m.page)
        }
        props={props}
    />
);

export function renderZtlPaymentsLookupTable(
    domId: string,
    props: ZtlPaymentsLookupTableProps
) {
    renderComponent(Page, domId, props);
}
