import * as React from 'react';

import { renderComponent } from '@General/renderComponent';
import { PageLoader } from '@Component/PageLoader';

interface PageProps {
    onDoneLoading: () => void;
}

const Page: React.FC<PageProps> = (props) => (
    <PageLoader<PageProps>
        loader={async () => import('./IncomingInvoice').then((m) => m.page)}
        props={props}
    />
);

export function renderIncomingInvoiceOrderLines(
    domId: string,
    onDoneLoading: () => void
) {
    renderComponent<PageProps>(Page, domId, { onDoneLoading });
}
