import { EntitlementLookup } from '@General/constants';
import { RouteData } from '@General/Router';
import { Team } from '@General/Team';

import { ReportSystemAdmin } from './ReportSystemAdmin.page';

export const route: RouteData = {
    component: ReportSystemAdmin,
    path: '/execute/report/system/admin',
    team: Team.DATA_STREAM,
    auth: (context) =>
        context.entitlementMap[
            EntitlementLookup.GLOBAL_REPORT_ADMINISTRATOR
        ] !== undefined,
};
