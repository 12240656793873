/**
 * Types of data objects (i.e. objects from API).
 */
export enum BankOnboardingStepState {
    COMPLETED = 'COMPLETED',
    INCOMPLETE = 'INCOMPLETE',
    PROCESSING = 'PROCESSING',
}

export type BankOnboardingStepStatus = {
    readonly id: number;
    state: BankOnboardingStepState;
    readonly accessible: boolean;
};

export type BankOnboardingStatus = {
    readonly steps: BankOnboardingStepStatus[];
    readonly accessRequest?: AccessRequestObject; // @Deprecated
    readonly hasFullAccess?: boolean;
    readonly companyOrgnr?: string;
    readonly autoPayTwoFAEnabled?: boolean;
    readonly lockedCurrenciesMap: Record<number, boolean>;
    readonly userIsAutoPayProvisioned: boolean;
};

export type ElectronicAgreementSupport = {
    readonly bankId: number;
    readonly bankName: string;
    readonly type: ElectronicSupportType;
    readonly bankUrl: string;
};

export enum ElectronicSupportType {
    PARTIAL = 'PARTIAL',
    COMPLETE = 'COMPLETE',
}

export enum ElectronicAgreementBanks {
    DNB = 'DNB',
}

export type AccessRequestObject = {
    readonly id: number;
    readonly requesteeEmployeeId: number;
    readonly requesteeName: string;
    readonly roleId: number;
};

/**
 * Authorization methods used to initiate BankId login, with either mobile or code generator.
 * MUst match with ID's of the ENUMS in VismaConnectAuthMethod.java
 */
export enum AuthorizationMethod {
    BankId = 2,
    OneTimePassword = 4,
}

export enum ErrorCode {
    CompanyNotFound = 'CompanyNotFound',
    ConsentRequestNotFound = 'ConsentRequestNotFound',
    MismatchingOrganizationNumber = 'MismatchingOrganizationNumber',
    AgreementsImportFailure = 'AgreementsImportFailure',
    BankApiException = 'BankAPIException',
    Unknown = 'UnknownError',
    NoCallback = 'NoCallback',
    InvalidElectronicAgreementType = 'InvalidElectronicAgreementType',
    InvalidState = 'InvalidState',
    ResignConsentFailed = 'ResignConsentFailed',
}

export enum PaymentAccessType {
    FULL = 'FULL',
    LIMITED = 'LIMITED',
    NONE = 'NONE',
}
