import * as React from 'react';
import './Button.scss';
import classNames from 'classnames';

export type ButtonProps = {
    disabled?: boolean;
    raised?: boolean;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    onMouseOver?: React.MouseEventHandler<HTMLButtonElement>;
    onFocus?: React.FocusEventHandler<HTMLButtonElement>;
    onKeyDown?: React.HTMLAttributes<HTMLButtonElement>['onKeyDown'];
    className?: string;
    type?: 'button' | 'submit' | 'reset';
    toolTip?: string;
    children?: React.ReactNode;
    render?: boolean;
    name?: string;
    testId?: string;
    trackingId?: string;
};

/**
 * Simple button component
 *
 * @author Håkon Wågbø
 * @date 16. Apr 2018
 */
export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
    (props, ref) => {
        if (props.render === false) {
            return null;
        }

        return (
            <button
                onMouseOver={props.onMouseOver}
                onFocus={props.onFocus}
                onKeyDown={props.onKeyDown}
                className={classNames('txr-button', props.className, {
                    'txr-button--inline': !props.raised,
                    'txr-button--raised': props.raised,
                    'txr-button--disabled': props.disabled,
                    'txr-button--raised--disabled':
                        props.disabled && props.raised,
                    'txr-button--inline--disabled':
                        props.disabled && !props.raised,
                })}
                data-testid={props.testId}
                data-trackingid={props.trackingId}
                data-tooltip={props.toolTip}
                onClick={props.onClick}
                disabled={props.disabled}
                type={props.type || 'button'}
                name={props.name}
                ref={ref}
            >
                {props.children}
            </button>
        );
    }
);
