import { Option } from '../../types';
import { ModelAPIV1 } from '@General/types/Api';
import { JSPDropdownFetcher } from '../JSPDropdownFetcher';
import { ApiResultMapperNoUndefinedResult } from '@General/Provider';

interface ProfessionEntry extends ModelAPIV1 {
    readonly displayName: string;
}

const ProfessionMapper: ApiResultMapperNoUndefinedResult<ProfessionEntry> = (
    item
) => {
    return {
        value: item.id + '',
        displayName: item.displayName,
        item,
    };
};

class ProfessionFetcher extends JSPDropdownFetcher<ProfessionEntry> {
    asyncJsonrpcGetter: Function;
    constructor(scope: HTMLDivElement, queryAttributes: Record<string, any>) {
        super(scope, queryAttributes, ProfessionMapper);
        this.asyncJsonrpcGetter = asyncrpc.Profession.get;
    }

    getParams(): ObjectIndex {
        return {};
    }

    async fetchList(params: ObjectIndex): Promise<Option<ProfessionEntry>[]> {
        return new Promise((resolve, reject) => {
            try {
                jsonrpc.Profession.search(
                    (result: ProfessionEntry[] | null) =>
                        resolve((result ?? []).map(ProfessionMapper)),
                    this.getMarshallSpec(),
                    params.name || ''
                );
            } catch (e) {
                defaultJsonRpcExceptionHandler(e);
                reject(e);
            }
        });
    }
}

export const exports = {
    fetcher: ProfessionFetcher,
    type: 'Profession',
};
