import { Action } from '../types/Reducer';
import { HistoryState } from '../types/HistoryShim.type';

const namespace = 'Global';

const HistoryUpdateType = `${namespace}HistoryUpdate`;
export type HistoryUpdate = Action<
    typeof HistoryUpdateType,
    {
        data: History['state'];
        title: string;
        url: MaybeString;
    }
>;
export const HistoryUpdate = (
    data: History['state'],
    title: string,
    url: MaybeString
): HistoryUpdate => ({
    type: HistoryUpdateType,
    data,
    title,
    url,
});
HistoryUpdate.type = HistoryUpdateType;

const HistoryChangedType = `${namespace}HistoryChanged`;
export type HistoryChanged = Action<
    typeof HistoryChangedType,
    {
        before: HistoryState;
        after: HistoryState;
    }
>;
export const HistoryChanged = (
    before: HistoryState,
    after: HistoryState
): HistoryChanged => ({
    type: HistoryChangedType,
    before,
    after,
});
HistoryChanged.type = HistoryChangedType;
