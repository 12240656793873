import { InventoryCorrectionTableWithFilters } from '@Page/LogisticsV2/pages/InventoryCorrection/overview/table/InventoryCorrectionTableWithFilters';
import {
    InventoryCorrectionOverviewHeader,
    InventoryCorrectionOverviewLayout,
} from '@Page/LogisticsV2/pages/InventoryCorrection/overview/InventoryCorrectionComponents';

export function InventoryCorrectionPage() {
    return (
        <InventoryCorrectionOverviewLayout>
            <InventoryCorrectionOverviewHeader />
            <InventoryCorrectionTableWithFilters />
        </InventoryCorrectionOverviewLayout>
    );
}
