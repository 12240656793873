import React, { useCallback, useEffect, useState } from 'react';
import { createAPIRequest, getContextId } from '@tlx/astro-shared';
import { InfoLabel } from '@tlx/beehive-shared';
import { GetStartedBannerSalaryOverview } from '@Page/Salary5/Components/SalaryOverview/Banner/GetStartedBannerSalaryOverview';

export function OptInSalaryBanner() {
    const [pilotAlreadyActive, setShowBanner] = useState(false);

    useEffect(() => {
        salaryOverviewPilotActive().then(setShowBanner);
    }, []);

    const onClickAction = useCallback(async () => {
        const request = createAPIRequest(
            '/v2/pilotFeature/activateSalaryOverview',
            { method: 'PUT' }
        );

        try {
            await fetch(request);

            window.location.replace(
                `/page/salary/overview?contextId=${getContextId()}`
            );
        } catch (error) {
            console.error(error);
        }
    }, []);

    if (pilotAlreadyActive) {
        return null;
    }

    return (
        <GetStartedBannerSalaryOverview
            bannerId="SalaryOverviewBanner"
            bannerTitle={getMessage('new_salary_overview_banner_title')}
            bannerDescription={getMessage(
                'new_salary_overview_banner_description'
            )}
            bannerLabel={<InfoLabel />}
            buttonText={getMessage('new_salary_overview_banner_button')}
            className="atl-mb-24"
            onClick={onClickAction}
        />
    );
}

async function salaryOverviewPilotActive(): Promise<boolean> {
    const request = createAPIRequest(
        '/v2/pilotFeature?pilotFeatures=SalaryOverview',
        { method: 'GET' }
    );

    try {
        const response = await fetch(request);
        const data = await response.json();

        return data.value['SalaryOverview'];
    } catch (error) {
        return false;
    }
}
