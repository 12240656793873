import './Container.css';
import classNames from 'classnames';
import React from 'react';

type ContainerPros = {
    children: React.ReactNode;
    className?: string | undefined;
    isEditMode?: boolean;
    setIsEditMode?: (isEditMode: boolean) => void;
};

export function Container({
    children,
    className,
    isEditMode,
    setIsEditMode,
}: ContainerPros) {
    function handleClick() {
        if (setIsEditMode && !isEditMode) {
            setIsEditMode(true);
        }
    }

    return (
        <div
            tabIndex={0}
            onClick={handleClick}
            onKeyDown={(event) => {
                if (event.key === 'Enter') {
                    handleClick();
                }
            }}
            className={classNames(
                className,
                'atl-bg-white atl atl-pt-8 atl-pl-16 atl-pr-16 atl-mb-8 kb-container',
                {
                    'atl-pb-16': isEditMode,
                    'atl-pb-8': !isEditMode,
                }
            )}
        >
            {children}
        </div>
    );
}
