import React from 'react';
import {
    Button,
    SkeletonRow,
    SkeletonText,
    TableLayout,
    TableLayoutContent,
    TableLayoutTopbar,
    useModal,
} from '@tlx/atlas';
import { StocktakingDetailsTableFilters } from '@Page/LogisticsV2/pages/Stocktaking/details2/table/StocktakingDetailsTableFilters';
import { DEFAULT_COLUMNS_STOCKTAKING_DETAILS } from '@Page/LogisticsV2/pages/Stocktaking/details2/types';
import { AutoSaveStatusType, PageUrl } from '@Page/LogisticsV2/utils/enums';
import { StocktakingDTO } from '@Page/LogisticsV2/types/dtoTypes';
import { StocktakingDetailsTableHeader } from '@Page/LogisticsV2/pages/Stocktaking/details2/table/StocktakingDetailsTableHeaderComponents';
import {
    Settings,
    TableColumnSetting,
    TableColumnsSetting,
    useTableColumnsSetting,
} from '@tlx/astro-shared';
import { getStocktakingDetailsDisplayOptionsName } from '@Page/LogisticsV2/pages/Stocktaking/details2/StocktakingDetailsDisplayOptions';
import {
    getAvailableColumnsInStocktakingDetails,
    getColumnLabel,
    getDefaultColumnsInStocktakingDetails,
} from '@Page/LogisticsV2/pages/Stocktaking/details2/utils';
import { useLoadMoreTarget } from '@Page/LogisticsV2/hooks/fetcher';
import {
    StocktakingDetailsAddProductButton,
    StocktakingDetailsExportButton,
} from '@Page/LogisticsV2/pages/Stocktaking/details2/StocktakingDetailsComponents';
import { TabEmptyState } from '@Page/LogisticsV2/pages/Stocktaking/details2/table/StocktakingDetailsTableComponents';
import {
    doAnimateRow,
    getIsFiltered,
} from '@Page/LogisticsV2/pages/Stocktaking/details2/StocktakingDetailsActions';
import { NoResultsEmptyState } from '@Page/LogisticsV2/components/EmptyState/EmptyState';
import { StocktakingDetailsTableRow } from '@Page/LogisticsV2/pages/Stocktaking/details2/table/StocktakingDetailsTableRowComponents';
import { useStocktakingDetailsTableData } from '@Page/LogisticsV2/pages/Stocktaking/details2/useStocktakingDetailsTableData';

export function StocktakingDetailsTableWithFilters({
    stocktaking,
    onChange,
}: {
    stocktaking: StocktakingDTO;
    onChange: (status: AutoSaveStatusType) => void;
}) {
    const enabledColumnsForStocktakingDetails = useTableColumnsSetting(
        getStocktakingDetailsDisplayOptionsName(),
        getDefaultColumnsInStocktakingDetails()
    );
    const productlines = useStocktakingDetailsTableData();
    const loadMoreRef = useLoadMoreTarget<HTMLDivElement>(
        productlines.loadMore
    );

    async function handleOnChange(
        status: AutoSaveStatusType,
        productlineId?: number,
        shouldAnimate?: boolean
    ) {
        onChange(status);
        if (shouldAnimate) {
            await doAnimateRow(productlines.refresh, productlineId);
        } else {
            await productlines.refresh();
        }
    }

    const { modalProps, openerProps } = useModal();

    return (
        <TableLayout>
            <TableLayoutTopbar className="atl-justify-between atl-m-16">
                <StocktakingDetailsTableFilters
                    warehouseId={stocktaking.inventory.id}
                />
                <div className="atl-flex atl-items-center atl-gap-8 atl-ml-8">
                    {!stocktaking.isCompleted && (
                        <StocktakingDetailsAddProductButton
                            stocktaking={stocktaking}
                            modalProps={modalProps}
                            onClick={openerProps.onClick}
                            onChange={onChange}
                        />
                    )}
                    <StocktakingDetailsExportButton
                        stocktaking={stocktaking}
                        columns={enabledColumnsForStocktakingDetails}
                    />
                    <Settings>
                        <TableColumnsSetting
                            name={getStocktakingDetailsDisplayOptionsName()}
                            label={getMessage('text_columns')}
                            aria-label="columns-for-stocktaking-details"
                            defaultColumns={getDefaultColumnsInStocktakingDetails()}
                        >
                            {getAvailableColumnsInStocktakingDetails().map(
                                (column) => (
                                    <TableColumnSetting
                                        key={column}
                                        value={column}
                                    >
                                        {getColumnLabel(column)}
                                    </TableColumnSetting>
                                )
                            )}
                        </TableColumnsSetting>
                    </Settings>
                </div>
            </TableLayoutTopbar>
            <TableLayoutContent
                style={{
                    overflowY: 'auto',
                    maxHeight: stocktaking.isCompleted
                        ? 'calc(100vh - 400px)'
                        : 'calc(100vh - 500px)',
                }}
            >
                <table className="atl-table">
                    <StocktakingDetailsTableHeader
                        columns={enabledColumnsForStocktakingDetails}
                    />
                    <tbody className="atl-tbody">
                        {productlines.data.length > 0 &&
                            productlines.data.map((line) => (
                                <StocktakingDetailsTableRow
                                    key={line.id}
                                    columns={
                                        enabledColumnsForStocktakingDetails
                                    }
                                    line={line}
                                    onChange={handleOnChange}
                                />
                            ))}
                        {productlines.data.length === 0 &&
                            !productlines.isLoading &&
                            getIsFiltered() && (
                                <NoResultsEmptyState
                                    colSpan={
                                        enabledColumnsForStocktakingDetails.length +
                                        DEFAULT_COLUMNS_STOCKTAKING_DETAILS
                                    }
                                    onClick={() =>
                                        window.history.pushState(
                                            {},
                                            '',
                                            addContextId(
                                                `${PageUrl.STOCKTAKING_DETAILS}?inventoryId=${stocktaking.id}`
                                            )
                                        )
                                    }
                                />
                            )}
                        {productlines.data.length === 0 &&
                            !productlines.isLoading &&
                            !getIsFiltered() && (
                                <tr className="atl-tr">
                                    <td
                                        className="atl-td"
                                        colSpan={
                                            enabledColumnsForStocktakingDetails.length +
                                            DEFAULT_COLUMNS_STOCKTAKING_DETAILS
                                        }
                                    >
                                        <TabEmptyState>
                                            {!stocktaking.isCompleted && (
                                                <div>
                                                    <Button
                                                        data-testid="add-product"
                                                        variant="secondary"
                                                        className="goldshark-stocktaking-details__add-button"
                                                        onClick={
                                                            openerProps.onClick
                                                        }
                                                    >
                                                        {getMessage(
                                                            'text_add_product'
                                                        )}
                                                    </Button>
                                                </div>
                                            )}
                                        </TabEmptyState>
                                    </td>
                                </tr>
                            )}
                        {productlines.isLoading && (
                            <SkeletonRow className="atl-tr">
                                {[
                                    ...Array.from(
                                        Array(
                                            enabledColumnsForStocktakingDetails.length +
                                                DEFAULT_COLUMNS_STOCKTAKING_DETAILS
                                        ).keys()
                                    ),
                                ].map((index) => (
                                    <td className="atl-td" key={index}>
                                        <SkeletonText className="atl-w-full" />
                                    </td>
                                ))}
                            </SkeletonRow>
                        )}
                        {productlines.hasMore && (
                            <tr>
                                <td>
                                    <div ref={loadMoreRef} />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </TableLayoutContent>
        </TableLayout>
    );
}
