import * as React from 'react';

import { RouteData } from '@General/Router';
import { PageLoader } from '@Component/PageLoader';
import { Team } from '@General/Team';
import { PageUrl } from '@Page/LogisticsV2/utils/enums';

const Page = () => (
    <PageLoader<{}>
        loader={async () =>
            import('./PurchaseOrderDeviations').then((m) => m.page)
        }
    />
);

export const route: RouteData = {
    component: Page,
    path: PageUrl.DEVIATION,
    title: () => `${getMessage('text_deviation_processing')}`,
    documentationComponent: 315,
    team: Team.GOLDSHARK,
    auth: (context) => loginCompanyModules.logistics,
};
