import { CommandResponderRegistry } from '@General/Command';

import { registerSection } from './Section';
import { registerMenuDrawer } from './MenuDrawer';
import { registerDocumentDrawer } from './DocumentDrawer';
import { registerEscape } from './Escape';
import { registerSave } from './Save';

export function registerAllGlobalCommands(registry: CommandResponderRegistry) {
    registerSection(registry);
    registerMenuDrawer(registry);
    registerDocumentDrawer(registry);
    registerEscape(registry);
    registerSave(registry);
}
