import './PaymentWidgetDropdown.scss';
import React, { useState } from 'react';
import { useRef, useEffect } from 'react';
import {
    Group,
    Label,
    Option,
    Dropdown,
    DropdownDrawer,
    DropdownScrollContainer,
    DropdownSearchOpener,
    WarningIcon,
} from '@tlx/atlas';
import type { TestableProps } from '@tlx/atlas';
import classNames from 'classnames';

/**
 * In order to support several object types, this component includes special handling as follows:
 * - dropdownOption => a string that must contain the id and the displayName of an option, in this format: "id,displayName"
 * - e.g. of a paymentType dropdownOption: "1342,testkonto"
 * - e.g. of a country dropdownOption: "37,Canada"
 *
 * - options => an array of such dropdownOptions, which will be mapped accordingly
 *
 * @author Melisa Damian
 * @date 24. Dec 2021
 */

interface PaymentWidgetDropdownProps extends TestableProps {
    readonly id: string;
    readonly dropdownOption: string;
    readonly setDropdownOption: (value: string) => void;
    readonly options: {
        value: string;
        displayName: string;
    }[];
    readonly atlasDesignSystem: boolean;
    readonly name?: string;
    readonly validationMessage?: string;
    readonly className?: string;
    readonly resetValidationMessage?: () => void;
    readonly children?: React.ReactNode;
}

export const PaymentWidgetDropdown: React.FC<PaymentWidgetDropdownProps> = ({
    id,
    dropdownOption,
    setDropdownOption,
    options = [],
    atlasDesignSystem,
    name,
    children,
    validationMessage,
    resetValidationMessage,
    className,
    'data-testid': dataTestId,
}) => {
    const [searchTerm, setSearchTerm] = useState<string>('');
    const hiddenInput = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        if (dropdownOption && hiddenInput?.current) {
            const event = new Event('change', { bubbles: false });
            hiddenInput.current.dispatchEvent(event);
            setSearchTerm('');
        }
    }, [dropdownOption]);

    const dropdownOptions = options
        .filter(({ displayName }) =>
            displayName.toLowerCase().includes(searchTerm.toLowerCase().trim())
        )
        .map(({ value, displayName }) => (
            <Option key={value} value={value}>
                {displayName}
            </Option>
        ));

    return (
        <div>
            <input
                ref={hiddenInput}
                type="hidden"
                name={name}
                value={dropdownOption}
            />
            <Group
                className={classNames('payment-widget-dropdown', {
                    'payment-widget-dropdown--classic': !atlasDesignSystem,
                })}
            >
                <Label id={id}>{children}</Label>
                <div
                    className={classNames(
                        'atl-flex atl-items-center atl-justify-center atl-gap-4 payment-widget-dropdown__input-field',
                        {
                            'payment-widget-dropdown__input--validation-error':
                                validationMessage,
                        },
                        className
                    )}
                >
                    <Dropdown
                        data-testid={dataTestId}
                        value={dropdownOption}
                        onChange={(event) => {
                            setDropdownOption(event.target.value);
                            resetValidationMessage?.();
                        }}
                    >
                        <DropdownSearchOpener
                            onChange={(e): void =>
                                setSearchTerm(e.target.value)
                            }
                        />
                        <DropdownDrawer>
                            <DropdownScrollContainer>
                                {dropdownOptions.length ? (
                                    dropdownOptions
                                ) : (
                                    <option key="0" value="" disabled>
                                        {getMessage('text_no_match')}
                                    </option>
                                )}
                            </DropdownScrollContainer>
                        </DropdownDrawer>
                    </Dropdown>

                    {validationMessage && atlasDesignSystem && (
                        <span className="atl-text-red-100">
                            <WarningIcon />
                        </span>
                    )}
                </div>
                {validationMessage && (
                    <div className="atl-text-red-100 pbc-payment__section-validation">
                        {validationMessage}
                    </div>
                )}
            </Group>
        </div>
    );
};
