import { Input, Label, RadioGroup } from '@tlx/atlas';
import '../style.css';
import React from 'react';

export function ChangeProductGroupsContent({
    action,
    onChangeAction,
    children,
}: {
    action: string;
    onChangeAction: (action: string) => void;
    children: React.ReactNode;
}) {
    return (
        <div className="atl-flex atl-gap-16 atl-flex-col goldshark-product-groups-modal">
            <div className="atl-flex atl-gap-8 atl-flex-col">
                <RadioGroup>
                    <Input
                        data-testid="add-product-groups"
                        type="radio"
                        value={'add'}
                        onChange={(e) => onChangeAction(e.target.value)}
                        checked={action === 'add'}
                    />
                    <Label>{getMessage('text_add_products')}</Label>
                </RadioGroup>
                <RadioGroup>
                    <Input
                        data-testid="remove-product-groups"
                        type="radio"
                        value="remove"
                        checked={action === 'remove'}
                        onChange={(e) => onChangeAction(e.target.value)}
                    />
                    <Label>{getMessage('text_remove_products')}</Label>
                </RadioGroup>
            </div>
            {children}
        </div>
    );
}
