import * as React from 'react';

export interface AnchorProps {
    readonly className: string;
    readonly anchorRef: React.RefObject<HTMLElement>;
    readonly children?: React.ReactNode;
}

/**
 * DefaultAnchor
 */
export const DefaultAnchor: React.FC<AnchorProps> = ({
    anchorRef,
    className,
    children,
}) => (
    <div
        className={className}
        ref={
            /* type narrowing was happening in the wrong direction here */
            anchorRef as React.Ref<HTMLDivElement>
        }
    >
        {children}
    </div>
);
