import React, { ReactNode } from 'react';
import { ResultIcon, ThreeIcon } from '@Page/LogisticsV2/pages/Import v2/icons';
import { ApprovedIcon, Button, FailIcon, WarningIcon } from '@tlx/atlas';
import { PageUrl } from '@Page/LogisticsV2/utils/enums';
import { ProductImportType } from '../types';

export function ResultStep({ children }: { children: ReactNode }) {
    window.app.runCES('logistics_product_import');
    return <div className="atl-flex atl-w-full">{children}</div>;
}

export function IconSection() {
    return (
        <div className="atl-w-full atl-flex atl-flex-col atl-p-32 atl-gap-32 atl-border atl-border-grey-20 atl-justify-center atl-items-center atl-rounded-tl atl-rounded-bl">
            <div className="atl-text-2xl atl-font-medium atl-text-grey-120">
                {getMessage('text_success')}!
            </div>
            <ResultIcon />
        </div>
    );
}

export function ResultSection({
    data,
}: {
    data: ProductImportType | undefined;
}) {
    return (
        <div className="atl-flex atl-flex-col atl-w-full atl-bg-white atl-p-24 atl-gap-24 atl-border atl-border-grey-20 atl-rounded-tr atl-rounded-br">
            <ResultSectionTitle />
            <ResultSectionContent>
                <ResultSectionList data={data} />
                <ResultSectionButtons />
            </ResultSectionContent>
        </div>
    );
}

function ResultSectionTitle() {
    return (
        <div className="atl-flex atl-gap-16 atl-items-center">
            <ThreeIcon />
            <div className="atl-flex atl-flex-col atl-gap-4">
                <div className="atl-text-lg atl-font-medium">
                    {getMessage('text_result')}
                </div>
                <div>{getMessage('text_import_result')}</div>
            </div>
        </div>
    );
}

function ResultSectionContent({ children }: { children: ReactNode }) {
    return <div className="atl-flex atl-flex-col atl-gap-32">{children}</div>;
}

function ResultSectionList({ data }: { data: ProductImportType | undefined }) {
    return (
        <>
            <ResultSectionRow
                text={getMessage('text_created_products')}
                number={data?.countCreated ?? 0}
            >
                <ApprovedIcon className="atl-p-4 atl-rounded-full atl-bg-turquoise-20" />
            </ResultSectionRow>
            <ResultSectionRow
                text={getMessage('text_updated_products')}
                number={data?.countUpdated ?? 0}
            >
                <WarningIcon className="atl-p-4 atl-rounded-full atl-bg-turquoise-20" />
            </ResultSectionRow>
            <ResultSectionRow
                text={getMessage('text_ignored_products')}
                number={data?.countIgnored ?? 0}
            >
                <FailIcon className="atl-p-4 atl-rounded-full atl-bg-turquoise-20" />
            </ResultSectionRow>
        </>
    );
}

function ResultSectionRow({
    children,
    text,
    number,
}: {
    children: ReactNode;
    text: string;
    number: number;
}) {
    return (
        <div className="atl-flex atl-gap-16 atl-items-center atl-border atl-border-grey-20 atl-rounded atl-p-16 atl-justify-between">
            <div className="atl-flex atl-gap-8 atl-items-center">
                {children}
                <div>{text}</div>
            </div>
            <div className="atl-text-xl atl-font-medium">{number}</div>
        </div>
    );
}

function ResultSectionButtons() {
    return (
        <div className="atl-flex atl-gap-8">
            <Button
                variant={'primary'}
                data-testid={'import-go-to-products-button'}
            >
                <a
                    href={addContextId(PageUrl.PRODUCT_OVERVIEW)}
                    className="atl-text-white"
                >
                    {getMessage('text_go_to_products')}
                </a>
            </Button>
            <Button
                variant={'secondary'}
                data-testid={'import-another-file-button'}
            >
                <a
                    className="atl-text-blue-100"
                    href={addContextId(PageUrl.PRODUCT_IMPORT)}
                >
                    {getMessage('text_import_another_file')}
                </a>
            </Button>
        </div>
    );
}
