import classNames from 'classnames';
import { ReactNode, useRef } from 'react';

export function Form({
    children,
    className,
    formId,
    onChangeInput,
    onChangeForm,
    onSubmit,
}: {
    children: ReactNode;
    className?: string;
    formId: string;
    onChangeInput?: (input: HTMLInputElement | HTMLSelectElement) => void;
    onChangeForm?: (
        form: HTMLFormElement,
        values: { [k: string]: FormDataEntryValue }
    ) => void;
    onSubmit?: (
        form: HTMLFormElement,
        values: { [k: string]: FormDataEntryValue }
    ) => void;
}) {
    const formRef = useRef<HTMLFormElement | null>(null);

    return (
        <form
            id={formId}
            autoComplete="off"
            className={classNames(
                'atl-flex atl-gap-24 atl-flex-col',
                className
            )}
            ref={formRef}
            onSubmit={() => {
                if (formRef.current && formRef.current?.checkValidity()) {
                    onSubmit?.(
                        formRef.current!,
                        Object.fromEntries(
                            new FormData(formRef.current!).entries()
                        )
                    );
                } else {
                    formRef.current?.reportValidity(); // Trigger browser's default form validation messages
                }
            }}
            onChange={(e) => {
                onChangeInput?.(
                    e.target as HTMLInputElement | HTMLSelectElement
                );
                onChangeForm?.(
                    formRef.current!,
                    Object.fromEntries(new FormData(formRef.current!).entries())
                );
            }}
        >
            {children}
        </form>
    );
}
