import { SendSigningRequestForm } from '@Page/CompanyVerificationFlow/SendSigningRequestForm/SendSigningRequestForm';
import React, { useEffect, useState } from 'react';
import { SendSigningRequestError } from '@Page/CompanyVerificationFlow/SendSigningRequestError';
import {
    requestSignaturesViaEmail,
    useCurrentUserSignatureStatus,
    useFetchSignatureCombination,
} from '@Page/CompanyVerificationFlow/companyVerificationAPI';
import { SignatureStatus, Signer } from '@Page/CompanyVerificationFlow/Types';
import { CompanyVerificationSteps } from '@Page/CompanyVerificationFlow/CompanyVerificationFlow';
import { SendSigningRequestFormSkeleton } from '@Page/CompanyVerificationFlow/SendSigningRequestForm/SendSigningRequestFormSkeleton';

export function ConnectedSendSigningRequestForm({
    handleNextStep,
    userSignatureStatus,
}: {
    handleNextStep: (value: CompanyVerificationSteps) => void;
    userSignatureStatus: SignatureStatus;
}) {
    const [serverError, setServerError] = useState<string | undefined>(
        undefined
    );

    const [validationMessages, setValidationMessages] = useState<
        string[] | undefined
    >(undefined);

    const { mutateUserSignatureStatus } = useCurrentUserSignatureStatus();

    // re-fetches userSignatureStatus so if they are thisIsMe the submit button will still work after going back.
    useEffect(() => {
        mutateUserSignatureStatus();
    }, [mutateUserSignatureStatus]);

    const signerCombinations = useFetchSignatureCombination(
        setServerError,
        setValidationMessages
    );

    if (signerCombinations === undefined && serverError !== undefined) {
        return (
            <SendSigningRequestError
                serverError={serverError}
                validationMessages={validationMessages}
            />
        );
    }

    if (signerCombinations === undefined) {
        return <SendSigningRequestFormSkeleton />;
    }

    return (
        <SendSigningRequestForm
            userSignatureStatus={userSignatureStatus}
            signerCombinations={signerCombinations}
            handleSignatureRequest={handleSignatureRequest}
            handleNextStep={handleNextStep}
        />
    );
}

async function handleSignatureRequest(
    signers: Signer[],
    thisIsMeSigner: Signer | undefined
) {
    // TODO: I guess this returns OK/true or soemthing?
    await requestSignaturesViaEmail(signers, thisIsMeSigner);
}
