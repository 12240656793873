import $ from 'jquery';
/**
 * All functions that deals with dynamic rows should be placed in here. For instance
 *
 * - copyRow
 * - getGroupInfo
 * - addNewRowWithoutFocusing
 * - addNewRow
 * - addRow2
 * - showNextRow
 * - subtractRowsInto
 */
export const dtable = (function () {
    return {
        /**
         * Given an item within a row, or the row itself, returns true or false if the row is deleted.
         * This function was made for the dynamic rows in order.jsp. Sophistication may be needed if
         * used on other tables than this.
         *
         * If no indication are found (i.e. no hidden ".deleted" field), this method returns false.
         *
         */
        isRowDeleted: function ($row) {
            $row = $($row).closest('tr');

            return (
                $row.find('input[type=hidden][name$=".deleted"]').val() ===
                'true'
            );
        },
    };
})();
