import { format } from './format';
import { ChartConfiguration } from 'chart.js';

// These types all come from the Java backend, see ChartTag.java, ChartOptions.java etc.
type HighchartPoint = {
    y: number;
};

// PlotOptions.java
type HighchartSeries = {
    data: HighchartPoint[];
    name: string;
    color: string;
    stacking: string;
};

// Axis.java
type HighchartAxis = {
    categories: string[];
};

// PlotOptions.java
type HighchartPlotOptions = {
    column: HighchartSeries;
};

// ChartOptions.java
type HighchartOptions = {
    plotOptions: HighchartPlotOptions;
    series: HighchartSeries[];
    xAxis: HighchartAxis[];
};

export function mapHighchartToChartJS(
    highchartOptions: HighchartOptions
): ChartConfiguration<'bar'> {
    const stacked = highchartOptions.plotOptions.column.stacking === 'normal';

    function formatAxis(value: number | string): string {
        const val = parseFloat(value);
        const absValue = Math.abs(val);

        if (absValue < 1e3) {
            return String(val);
        }
        if (absValue >= 1e3 && absValue < 1e6) {
            return +(val / 1e3).toFixed(1) + 'K';
        }
        if (absValue >= 1e6 && absValue < 1e9) {
            return +(val / 1e6).toFixed(1) + 'M';
        }
        if (absValue >= 1e9 && absValue < 1e12) {
            return +(val / 1e9).toFixed(1) + 'G';
        }
        if (absValue >= 1e12) {
            return +(val / 1e12).toFixed(1) + 'T';
        }
        return '?';
    }

    return {
        type: 'bar',
        data: {
            labels: highchartOptions.xAxis[0].categories,
            datasets: highchartOptions.series.map((series) => ({
                label: series.name,
                data: series.data.map((data) => data.y),
                backgroundColor: series.color,
            })),
        },
        options: {
            scales: {
                xAxes: {
                    stacked: stacked,
                },
                yAxes: {
                    beginAtZero: true,
                    ticks: {
                        callback: formatAxis,
                    },
                    stacked: stacked,
                },
            },
            plugins: {
                legend: {
                    position: 'bottom',
                },
                tooltip: {
                    callbacks: {
                        label: function formatLabel(tooltipItem) {
                            const label =
                                tooltipItem.dataset.data[tooltipItem.dataIndex];
                            return format.amount(label);
                        },
                    },
                },
            },
        },
    };
}
