/**
 * Merges all given objects in to 1.
 * Properties from later entries have precedence over those from earlier entries.
 *
 * @param t1 The first entry to merge in.
 *
 * @return The new merged entry.
 *
 * @author tellef
 * @date 2019-04-25
 */
export function mergeAll<T1>(t1: T1): T1;

/**
 * Merges all given objects in to 1.
 * Properties from later entries have precedence over those from earlier entries.
 *
 * @param t1 The first entry to merge in.
 * @param t2 The second entry to merge in.
 *
 * @return The new merged entry.
 *
 * @author tellef
 * @date 2019-04-25
 */
export function mergeAll<T1, T2>(t1: T1, t2: T2): T1 & T2;

/**
 * Merges all given objects in to 1.
 * Properties from later entries have precedence over those from earlier entries.
 *
 * @param t1 The first entry to merge in.
 * @param t2 The second entry to merge in.
 * @param t3 The third entry to merge in.
 *
 * @return The new merged entry.
 *
 * @author tellef
 * @date 2019-04-25
 */
export function mergeAll<T1, T2, T3>(t1: T1, t2: T2, t3: T3): T1 & T2 & T3;

/**
 * Merges all given objects in to 1.
 * Properties from later entries have precedence over those from earlier entries.
 *
 * @param t1 The first entry to merge in.
 * @param t2 The second entry to merge in.
 * @param t3 The third entry to merge in.
 * @param t4 The fourth entry to merge in.
 *
 * @return The new merged entry.
 *
 * @author tellef
 * @date 2019-04-25
 */
export function mergeAll<T1, T2, T3, T4>(
    t1: T1,
    t2: T2,
    t3: T3,
    t4: T4
): T1 & T2 & T3 & T4;

/**
 * Merges all given objects in to 1.
 * Properties from later entries have precedence over those from earlier entries.
 *
 * @param t1 The first entry to merge in.
 * @param t2 The second entry to merge in.
 * @param t3 The third entry to merge in.
 * @param t4 The fourth entry to merge in.
 * @param t5 The fifth entry to merge in.
 *
 * @return The new merged entry.
 *
 * @author tellef
 * @date 2019-04-25
 */
export function mergeAll<T1, T2, T3, T4, T5>(
    t1: T1,
    t2: T2,
    t3: T3,
    t4: T4,
    t5: T5
): T1 & T2 & T3 & T4 & T5;

/**
 * Merges all given objects in to 1.
 * Properties from later entries have precedence over those from earlier entries.
 *
 * @param obj All the entries to merge together.
 *
 * @return The new merged entry.
 *
 * @author tellef
 * @date 2019-04-25
 */
export function mergeAll(...obj: ObjectIndex[]): ObjectIndex {
    return obj.reduce(_merge);
}

/**
 * Merges all entries in the given list to 1.
 * Properties from later entries have precedence over those from earlier entries.
 * NOTE: this exists to make typing easier.
 *
 * @param list A list of the entries to merge together.
 *
 * @return The new merged entry.
 *
 * @author tellef
 * @date 2019-10-02
 */
export function mergeList<T extends ObjectIndex>(list: T[]): T {
    return list.reduce(_merge);
}

/**
 * Merges the 2 given objects, giving precedence to values in `newValues`.
 *
 * @param existing  The entry to merge values in to.
 * @param newValues The entry with values to merge in.
 *
 * @return The new merged entry.
 *
 * @author tellef
 * @date 2019-04-25
 */
function _merge<T1 extends object, T2 extends object>(
    existing: T1,
    newValues: T2
): T1 & T2 {
    if (isArray<T1>(existing) && isArray<T2>(newValues)) {
        // Either cast or have infinite type recursion
        return _mergeArray(existing, newValues) as unknown as T1 & T2;
    } else if (
        isObject<T1>(existing) &&
        isObject<T2>(newValues) &&
        !isFunction(existing) &&
        !isFunction(newValues)
    ) {
        return _mergeObject(existing, newValues);
    } else {
        return _mergeProperty(existing, newValues);
    }
}

function _mergeArray<T1 extends object, T2 extends object>(
    existing: Array<T1>,
    newValues: Array<T2>
): Array<T1 & T2> {
    const result: Array<T1 & T2> = [];
    const length = Math.max(existing.length, newValues.length);

    for (let i = 0; i < length; i++) {
        result[i] = _merge(existing[i], newValues[i]);
    }

    return result;
}

function _mergeObject<T1 extends object, T2 extends object>(
    existing: T1,
    newValues: T2
): T1 & T2 {
    const result = {
        ...existing,
    };

    Object.entries(newValues).forEach(([key, value]) => {
        // @ts-expect-error values can be undefined
        result[key] = _merge(result[key], value);
    });

    return result as T1 & T2;
}

function _mergeProperty<T1, T2>(existing: T1, newValues: T2): T1 & T2 {
    return newValues as T1 & T2;
}

export function isArray<T>(arg: any): arg is Array<T> {
    return Array.isArray(arg);
}

export function isObject<T>(arg: any): arg is T {
    return arg === Object(arg);
}

export function isFunction<T extends Function>(arg: any): arg is T {
    return typeof arg === 'function';
}
