import * as React from 'react';
import { clsx } from 'clsx';

import './Avatar.css';

export type AvatarProps = React.HTMLAttributes<HTMLDivElement>;

/**
 * Avatar
 */
export const Avatar: React.FC<AvatarProps> = ({
    children,
    className,
    ...props
}) => (
    <div className={clsx('tlx-ds-avatar', className)} aria-hidden {...props}>
        {children}
    </div>
);
