import { ModelAPIV1 } from '@General/types/Api';
import { Option } from '../../types';
import { notUndefined } from '@General/Helpers';
import { JSPDropdownFetcher } from '../JSPDropdownFetcher';
import { ApiResultMapper } from '@General/Provider';
import { SearchFilterGenerator } from '@Component/DropDown/types';

interface CustomerCategoryEntry extends ModelAPIV1 {
    readonly displayName: string;
}

const customerCategoryMapper: ApiResultMapper<CustomerCategoryEntry> = (
    item
) => ({
    value: item.id + '',
    displayName: item.displayName,
    item,
});

const searchFilterGenerator: SearchFilterGenerator<unknown> = (query) => {
    return (option) =>
        option.displayName.toLowerCase().indexOf(query.toLowerCase()) > -1;
};

class CustomerCategoryFetcher extends JSPDropdownFetcher<CustomerCategoryEntry> {
    asyncJsonrpcGetter: Function;

    constructor(scope: HTMLDivElement, queryAttributes: Record<string, any>) {
        super(scope, queryAttributes, customerCategoryMapper);
        this.asyncJsonrpcGetter = asyncrpc.CustomerCategory.get;
    }

    getParams(): ObjectIndex {
        return {
            categoryType: this.getQueryAttribute('categoryType', -1),
        };
    }

    async fetchList(
        params: ObjectIndex
    ): Promise<Option<CustomerCategoryEntry>[]> {
        const windowUrl = window.location.search;
        const windowUrlParams = new URLSearchParams(windowUrl);
        const customerId = windowUrlParams.get('customerId') ?? -1;

        return new Promise((resolve, reject) => {
            try {
                jsonrpc.CustomerCategory.searchForCustomerCategories(
                    (result: CustomerCategoryEntry[] | null) => {
                        const mapped = (result ?? [])
                            .filter(notUndefined)
                            .map(this.getMapper(params))
                            .filter(notUndefined);
                        resolve(mapped);
                    },
                    this.getMarshallSpec(),
                    window.loginCompanyId,
                    params.categoryType,
                    params.name,
                    customerId
                );
            } catch (e) {
                defaultJsonRpcExceptionHandler(e);
                reject(e);
            }
        });
    }
}

export const exports = {
    fetcher: CustomerCategoryFetcher,
    type: 'CustomerCategory',
    searchFilterGenerator: searchFilterGenerator,
};
