import {
    CheckboxGroupFilter,
    FilterItem,
    SearchFilter,
} from '@tlx/astro-shared';
import React from 'react';

export function WarehouseOverviewTableFilterComponents() {
    return (
        <form autoComplete={'off'} className={'atl-flex atl-gap-16'}>
            <SearchFilter
                data-testid={'warehouse-overview-search'}
                label={getMessage('text_search')}
                name={'query'}
                placeholder={getMessage('text_search')}
                quick
            />
            <CheckboxGroupFilter
                name="status"
                label=""
                quick
                defaultValues={['active']}
            >
                <FilterItem value="active">
                    {getMessage('text_active')}
                </FilterItem>
                <FilterItem value="inactive">
                    {getMessage('text_inactive')}
                </FilterItem>
            </CheckboxGroupFilter>
        </form>
    );
}
