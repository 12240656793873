/*
 * ## Changed/dirty tracking (also see 'onchange' in frameless.js)
 *
 *
 * Changes [ojb - 22. jan. 2015]: (in response to needs in crmWorkbench)
 *   Updated so it's possible clear the changed status of individual elements and actually affect the global changed status.
 *   I.e. if all elements are cleared, the global state is also cleared.
 *
 *   Not fully consolidated with all other functionality though - eg. tabs.
 *
 *   Possibly that it would make more sense to use "name" as id and have some concept of parent forms/scope.. ?
 *   Not sure how this works with radio buttons for instance.. ?
 */

import $ from 'jquery';
import { tlxConfirm } from './confirm';
import { stringUtils } from './stringUtils';
import { formChanged } from '@General/formChanged';
import { clearAllValidations } from './notification';

// Key used for changes without an identified element. (eg. setChanged(true))
export const sourceLessChangedKey = '__uniqueSymbol986x3t';

// Maps changed elements to true, if it is changed
let changedMap: Record<string, boolean> = {};
let tabChanged: boolean[] = [];

/**
 * Creates a key identifying the element. Atm. this must be the id attribute of the element
 * so elements without id can not be tracked.
 *
 * Cases where the id attribute is changed dynamically is not handled
 */
function elementKey(idOrElement: string | HTMLInputElement): string {
    if (stringUtils.isString(idOrElement)) {
        return idOrElement;
    } else {
        return idOrElement.id || idOrElement.name;
    }
}

/**
 * Clear the changed status of idOrElement if given. Otherwise clears global changed status
 * IMPROVEMENT: Take an optional 'expectedValue' - if the current value doesn't match this,
 *              don't clear.
 */
export function clearChanged(idOrElement?: string | HTMLInputElement) {
    if (!idOrElement) {
        changedMap = {};
        return;
    }
    delete changedMap[elementKey(idOrElement)];
}

export function isChanged(idOrElement?: string | HTMLInputElement) {
    if (!idOrElement) {
        return !$.isEmptyObject(changedMap);
    } else {
        return changedMap[elementKey(idOrElement)] != undefined;
    }
}

function setTabChanged(tabIndex: number) {
    tabChanged[tabIndex] = true;
}

function isTabChanged(tabIndex: number) {
    return tabChanged[tabIndex];
}

export function isOtherTabChanged(tabIndex: number) {
    for (let i = 0; i < tabChanged.length; i++) {
        if (i === tabIndex) {
            continue;
        }
        if (tabChanged[i]) {
            return true;
        }
    }
    return false;
}

export function clearOtherTabsChanged(tabIndex: number) {
    $('.ui-tabs-nav li')
        .not(':eq(' + tabIndex + ')')
        .find('a')
        .removeClass('changed error');
    for (let i = 0; i < tabChanged.length; i++) {
        if (i != tabIndex) {
            tabChanged[i] = false;
        }
    }
}

export function clearTabChanged(tabIndex?: number) {
    const $tabs = $('.ui-tabs');
    if ($tabs.length == 1) {
        if (tabIndex !== null && tabIndex !== undefined) {
            tabChanged[tabIndex] = false;
        } else {
            tabChanged = [];
        }

        if (typeof tabIndex == 'number') {
            if (!isOtherTabChanged(tabIndex)) {
                clearChanged();
            }

            const t = $('.ui-tabs-nav li').eq(tabIndex).find('a');
            if (t.hasClass('error')) {
                clearGeneralValidation();
            }
            t.removeClass('changed error');
        } else {
            $('.ui-tabs-nav a').removeClass('changed error');
            clearChanged();
            clearGeneralValidation();
        }
        $('.ui-tabs-panel:visible')
            .find(':tlx-mainToolbar')
            // @ts-expect-error no proper jQuery UI support in TypeScript yet
            .mainToolbar('option', 'lockButtons', false)
            .end()
            .find(':tlx-tableToolbar')
            .tableToolbar('option', 'lockButtons', false);
    }
}

export function changeTest(
    callbackFunction: () => void,
    form?: HTMLFormElement,
    tabIndex?: number
) {
    //NB! Sending in tabIndex will ignore
    if (form && form.method.toLowerCase() == 'post') {
        clearChanged(); // To avoid warning from onbeforeunload
        callbackFunction();
        return;
    }
    if (tabIndex === parseInt(tabIndex, 10)) {
        if (isTabChanged(tabIndex)) {
            tlxConfirm(
                callbackFunction,
                getMessage('validation_content_changed')
            );
            return;
        }
    } else if (isChanged() || formChanged()) {
        tlxConfirm(callbackFunction, getMessage('validation_content_changed'));
        return;
    }

    if (callbackFunction) {
        callbackFunction();
    }
}

export function changed(el?: string | HTMLInputElement) {
    //Do not set to changed if element is in a dialog.
    if (el instanceof HTMLElement && $(el).closest('.ui-dialog').length > 0) {
        return;
    }

    const $uiTabs = $('.ui-tabs');
    if ($uiTabs.length == 1) {
        const activeTab = $uiTabs.tlxTabs('option', 'active');
        //Only need to setChange on tab once
        // @ts-expect-error it is always a boolean. Could improve typing of tlxTabs with keyof typeof something
        if (!isTabChanged(activeTab)) {
            // @ts-expect-error it is always a boolean. Could improve typing of tlxTabs with keyof typeof something
            setTabChanged(activeTab);
            $('.ui-tabs-active a').addClass('changed');
            $('.ui-tabs-panel:visible')
                .find(':tlx-mainToolbar')
                // @ts-expect-error Lacking TypeScript support for jQuery UI
                .mainToolbar('option', 'lockButtons', true)
                .end()
                .find(':tlx-tableToolbar')
                .tableToolbar('option', 'lockButtons', true);
        }
    } else {
        // @ts-expect-error Lacking TypeScript support for jQuery UI
        $(':tlx-tableToolbar').tableToolbar('option', 'lockButtons', true);
        // @ts-expect-error Lacking TypeScript support for jQuery UI
        $(':tlx-mainToolbar').mainToolbar('option', 'lockButtons', true);
    }
    if (el) {
        const key = elementKey(el);
        changedMap[key] = true;
    } else {
        changedMap[sourceLessChangedKey] = true;
    }
}

export function clearGeneralValidation() {
    clearAllValidations();
}

function onchange(e: Event) {
    const target = e.target;

    if (!(target instanceof HTMLInputElement)) {
        return;
    }

    if (
        target.form &&
        target.form.method.toLowerCase() === 'post' &&
        !$(target.form).is('.ignoreChanges')
    ) {
        if ($(target).is("input:checkbox[name$='\\.selected']")) {
            return;
        }
        if ($(target).attr('name') === undefined) {
            return;
        }
        changed(target);
    }
}

window.addEventListener('change', onchange);
