import * as React from 'react';

function isString(value: any) {
    return typeof value === 'string';
}

function getLocale() {
    return normalizeLanguage(window.locale);
}

function normalizeLanguage(language: string): string {
    if (isString(language) && language.toLocaleLowerCase().includes('en')) {
        return 'en-GB';
    }

    return 'nb-NO';
}

export function NumberFormat({
    value,
    options,
}: {
    value: number;
    options: Intl.NumberFormatOptions;
}) {
    return <>{Intl.NumberFormat(getLocale(), options).format(value)}</>;
}
