import { AsyncDropdownOptions } from '@tlx/astro-shared';
import {
    Dropdown,
    DropdownDrawer,
    DropdownOpener,
    DropdownScrollContainer,
    DropdownSearch,
    Input,
    Label,
    Option,
    RadioGroup,
} from '@tlx/atlas';
import { ReactNode } from 'react';

export function ChangeProductDetailsRackbeatContent({
    children,
}: {
    children: ReactNode;
}) {
    return (
        <div
            className="atl-flex atl-gap-32 atl-flex-col atl-mt-32"
            style={{ width: '400px' }}
        >
            {children}
        </div>
    );
}
export function ChangeProductDetailsUnitsDropdown() {
    return (
        <Dropdown
            data-testid={'change-product-details-rackbeat-dropdown'}
            id="change-product-details-rackbeat"
            name="unitValue"
            defaultValue={'undefined'}
        >
            <DropdownOpener className="atl-w-full" />
            <DropdownDrawer>
                <DropdownSearch />
                <DropdownScrollContainer>
                    <Option value={'undefined'}>
                        {getMessage('option_not_chosen')}
                    </Option>
                    <AsyncDropdownOptions url={'/v2/product/unit/query'} />
                </DropdownScrollContainer>
            </DropdownDrawer>
        </Dropdown>
    );
}

export function ChangeProductDetailsRadioGroup({
    children,
}: {
    children: ReactNode;
}) {
    return (
        <>
            <RadioGroup>
                <Input
                    data-testid="rackbeat-product-unit"
                    type="radio"
                    id="change-product-details-rackbeat-product-unit"
                    name="editDetailsType"
                    defaultChecked
                    value={'unit'}
                />
                <Label htmlFor="change-product-details-rackbeat-product-unit">
                    {getMessage('text_product_unit')}
                </Label>
            </RadioGroup>
            {children}
        </>
    );
}
