import $ from 'jquery';
import { bindEventsInsideScope } from '../o-common';
import { addContextId } from '../modules/url';

/**
 * Initialize all comments links by creating an popup that opens when the link is clicked (popupOpener).
 *
 * @author Haakon
 * @date 30. aug 2016
 */
$(window).on('tlxLoadPageContentDone tlxLazyLoadDone', function () {
    $('a.comments-link').each(function () {
        window.comments.createCommentsPopup(this);
    });
});

window.comments = (function () {
    /**
     * Create an popup showing comments, that is opened by clicking the given element (using popupOpener).
     *
     * @param element must have attributes data-comments-parent-id and data-comments-parent-type
     *
     * @author Haakon
     * @date 15. sep 2016
     */
    function createCommentsPopup(element) {
        if (!element) {
            return;
        }
        const $commentsLink = $(element);

        const parentId = $commentsLink.attr('data-comments-parent-id');
        const parentType = $commentsLink.attr('data-comments-parent-type');

        if (!parentId || !parentType) {
            throw Error(
                "Given element is missing attributes 'data-comments-parent-id' or/and 'data-comments-parent-type'"
            );
        }

        if (
            $commentsLink.hasClass('mdl-badge') &&
            $commentsLink.siblings('.mdl-tooltip').length > 0
        ) {
            // ensure that the tooltip is properly initialized
            const tooltip = $commentsLink.siblings('.mdl-tooltip').get(0);
            componentHandler.upgradeElement(tooltip);
        }

        $commentsLink.popupOpener({
            url: addContextId(
                '/execute/forumPopup?parentId=' +
                    parentId +
                    '&parentType=' +
                    parentType +
                    '&isComments=true'
            ),
            appendDialogToScope: false,
            load: function (event, $ui) {
                bindEventsInsideScope($ui);

                $ui.dialog('option', { draggable: false }); // TODO this is here because of BUG-219, remove this when done!

                $ui.bind('dialogbeforeclose', function () {
                    const numOfComments =
                        window.jsonrpc.ForumPopupForm.getNumberOfComments(
                            parentType,
                            parentId
                        );
                    if (numOfComments != 0) {
                        const lastComment =
                            window.jsonrpc.ForumPopupForm.getLastComment(
                                parentType,
                                parentId
                            );
                        if ($commentsLink.hasClass('mdl-badge')) {
                            // Comments has badge, so the bade icon already exists
                            $commentsLink.attr(
                                'data-badge',
                                '' + numOfComments
                            );
                            $commentsLink
                                .siblings('.mdl-tooltip')
                                .text(lastComment);
                        } else {
                            // Comments does not have any badge, because no comments existed before
                            $commentsLink
                                .addClass('mdl-badge')
                                .addClass('mdl-badge--overlap')
                                .attr('data-badge', '' + numOfComments)
                                .siblings('.mdl-tooltip')
                                .text(
                                    window.jsonrpc.ForumPopupForm.getLinkText(
                                        parentType,
                                        parentId
                                    )
                                );
                        }
                    }
                });
                try {
                    window.tlxInitializeState($ui.get(0));
                } catch (err) {
                    if (
                        err.type != 'not_defined' ||
                        err.arguments[0] != 'tlxInitializeState'
                    ) {
                        throw err;
                    }
                }
            },
            buttonText: getMessage('button_close'),
            click: function () {
                $(this).dialog('close'); // add a primary "close" button
            },
            closeText: '', // hide the "ordinary" close button
            title: getMessage('text_comments'),
        });
    }

    /**
     * Get a comments link element (the link to open the comments popup).
     *
     * NB! Changes in this function should be reflected in CommentsTag.getRoot()
     *
     * @param parentType
     * @param parentId
     *
     * @author Haakon
     * @date 15. sep 2016
     */
    function getCommentsLinkElement(parentType, parentId) {
        const $root = $('<span class="comments-link--root"></span>');
        const $link = $('<a></a>');
        const $tooltip = $('<span></span>');

        const numberOfComments =
            window.jsonrpc.ForumPopupForm.getNumberOfComments(
                parentType,
                parentId
            );
        const linkText = window.jsonrpc.ForumPopupForm.getLinkText(
            parentType,
            parentId
        );

        $link
            .addClass('comments-link')
            .attr('data-comments-parent-id', parentId)
            .attr('data-comments-parent-type', parentType);

        $link
            .attr('id', 'comments-link' + parentType + '-' + parentId)
            .addClass('material-icons')
            .text('message');

        if (numberOfComments !== 0) {
            $link
                .addClass('mdl-badge')
                .addClass('mdl-badge--overlap')
                .attr('data-badge', '' + numberOfComments);
        }

        $tooltip
            .addClass('mdl-tooltip')
            .attr('for', $link.attr('id'))
            .text(linkText);

        $root.append($link).append($tooltip);

        return $root.get(0);
    }

    return {
        createCommentsPopup: createCommentsPopup,
        getCommentsLinkElement: getCommentsLinkElement,
    };
})();
