import * as React from 'react';
import { PageData, PageLoader } from '@Component/PageLoader';
import { RouteData } from '@General/Router';
import { Team } from '@General/Team';

const ALLOWED_ROLES = [150];

const Page: React.FC = () => {
    return (
        <PageLoader
            loader={async (): Promise<PageData> =>
                import('./ZtlSettings').then((m) => m.page)
            }
            adjustWrapperDiv
        />
    );
};

export const route: RouteData = {
    component: Page,
    path: '/execute/ztlSettings',
    title: () => getMessage('text_bank_settings'),
    team: Team.PBC,
    // To fully connect this page to the Tripletex framework, the page needs a documentationComponent id so that we can
    // link help articles to the page

    // We should limit who has access to this page.
    auth: (context) =>
        context.entitlements.some((e) => ALLOWED_ROLES.includes(e.id)),
};
