import { useCallback, useState } from 'react';
import { createAPIRequest } from '../../../../hooks/fetch/createAPIRequest';
import { createAPIResponse } from '../../../../hooks/fetch/createAPIResponse';
import { ResponseWrapper } from '../../../../hooks/fetch/types';
import { UserFeedbackDTO } from '@Page/Automation2022/models/UserFeedback';
import { browserInfo } from '../../../../../../js/modules/d-browserInfo';

export function useSendUserFeedback() {
    const [error, setError] = useState<any>(undefined);

    const sendToBackend = useCallback(
        async ({
            message,
            team,
            pageTitle,
            contactRequest,
        }: UserFeedbackDTO) => {
            setError(undefined);
            try {
                await internalSendUserFeedback({
                    message,
                    team,
                    pageTitle,
                    contactRequest,
                });
            } catch (error) {
                setError(error);
            }
        },
        []
    );

    return {
        sendToBackend,
        sendToBackendError: error,
    };
}

async function internalSendUserFeedback({
    message,
    team,
    pageTitle,
    contactRequest,
}: UserFeedbackDTO) {
    const request = createAPIRequest(`/v2/feedback`, {
        method: 'POST',
        body: JSON.stringify({
            message: message,
            browser: getBrowserInfo(),
            pageTitle: pageTitle,
            team: team,
            contactRequest: contactRequest,
        }),
        headers: {
            'content-type': 'application/json',
        },
    });

    const response = await fetch(request);
    return await createAPIResponse<ResponseWrapper<any>>(request, response);
}

function getBrowserInfo(): string {
    let browser = 'Other'; //Other browsers that consist of less than 2 % of the users (i.e. Samsung Internet, Opera, Android Webview, Safari, YaBrowser, Internet Explorer)
    if (browserInfo.isChrome()) {
        browser = 'Chrome';
    } else if (browserInfo.isEdge()) {
        browser = 'Edge';
    } else if (browserInfo.isFirefox()) {
        browser = 'Firefox';
    } else if (browserInfo.isSafari()) {
        browser = 'Safari';
    }
    return browser;
}
