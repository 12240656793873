import { GeneralGlobalState } from '@General/types/General';
import { ModelAPIV1, ModelAPIV1Long, ModelAPIV2 } from '@General/types/Api';
import { DeepPartial } from 'ts-essentials';

export type DebitPosting = ModelAPIV1Long & {
    accountId?: number;
    accountDepartmentId?: number;
    accountIsVatLocked?: boolean;
    account?: AccountObject;
    departmentId?: number;
    employeeId?: number;
    projectId?: number;
    project?: ProjectObject;
    productId?: number;

    quantityAmount1?: number;
    quantityProductUnitId1?: number;
    quantityAmount2?: number;
    quantityProductUnitId2?: number;

    vatType: number | undefined;
    vatEntry: VatEntry | undefined;

    amountBasisCurrency: number;
    amountVat: number;

    amortizationVisible: boolean;
    amortizationAccountId?: number;
    guiAmortizationMonth?: number;
    guiAmortizationYear?: number;
    guiAmortizationMonthCount?: number;
    guiAssetId?: number;
    taxTransactionType?: number;
};

export type InvoiceRead = {
    readonly invoicenumber: number;
};

export type Currency = {
    id: number;
    code: string;
};

export type OrderLineIncomeObject = ModelAPIV1 & {
    readonly invoiceId?: number;
    readonly invoice?: InvoiceRead;

    projectId?: number;
    project?: ProjectObject;
    projectSubContractId?: number;
    isChargeable: boolean;
    unitPriceCurrency: number;
    currency?: Currency;
    markup: number;
    discountPercentage: number;
    amountExVatCurrency: number;

    vatType: number | undefined;
    vatEntry: VatEntry | undefined;
};

export type OrderLineCostObject = ModelAPIV1 & {
    readonly expectedDeliveryDate?: Date;
    guiProductId?: number;
    warehouseId?: number;
    isStockItem?: boolean;
    stockChangeWarehouseId?: number;
    description: string;
    countNegative: number;
    unitPriceInput: number; // As written on invoice. Not saved to backend.
    unitPriceCurrency: number;
    unitPriceIncVatCurrency: number;
    amountExVatCurrencyNegative: number;
    amountIncVatCurrencyNegative: number;
    guiOrderLine: OrderLineIncomeObject;
    taxTransactionType: number;
    isTaxTransactionAccount: boolean;
    costAccountSuggestion: boolean;
    projectSuggestion: boolean;
    descriptionSuggestion: boolean;
    countNegativeSuggestion: boolean;
    unitPriceCurrencySuggestion: boolean;
    unitPriceIncVatCurrencySuggestion: boolean;
    amountExVatCurrencyNegativeSuggestion: boolean;
    amountIncVatCurrencyNegativeSuggestion: boolean;
};

export type OrderLineObject = {
    readonly orderLine: OrderLineCostObject;
    readonly debitPosting: DebitPosting;
    readonly selected: boolean;
    readonly collapsed: boolean;
    readonly id: number;

    readonly showCostCarriers: boolean;
    readonly showAmortization: boolean;
    readonly showLogistic: boolean;

    readonly lockProjectsTogether: boolean;
};

export type ViewOptionsState = {
    readonly vendorId: number;
    readonly dialogVisible: boolean;
    readonly cost: {
        readonly product: boolean;
        readonly description: boolean;
        readonly account: boolean;
        readonly count: boolean;
        readonly unitPrice: boolean;
        readonly vat: boolean;
        readonly amountExVat: boolean;
        readonly amountIncVat: boolean;
    };
    readonly costCarrier: {
        readonly project: boolean;
        readonly product: boolean;
        readonly department: boolean;
        readonly employee: boolean;
        readonly asset: boolean;
    };
    readonly income: {
        readonly project: boolean;
        readonly projectSubContract: boolean;
        readonly unitPrice: boolean;
        readonly vat: boolean;
        readonly markup: boolean;
        readonly discount: boolean;
        readonly amountExVat: boolean;
    };
    readonly logistics: {
        readonly warehouse: boolean;
    };
    readonly amortization: boolean;
};

export type MassEditDialogState = {
    readonly massEditDialogVisible: boolean;
    readonly template: OrderLineContentTemplate;
};

// TODO switch to map of id = key and value = line.
export type OrderLinesState = Array<OrderLineObject>;

export type IncomingInvoiceState = {
    readonly isNew: boolean;
    readonly ehf: boolean;
    readonly readOnly: boolean;
    readonly modules: ModulesState;
    readonly currencyName: string;
    supplier: string;
};

export type ModulesState = {
    readonly invoice: boolean;
    readonly project: boolean;
    readonly projectEconomy: boolean;
    readonly product: boolean;
    readonly productAccounting: boolean;
    readonly department: boolean;
    readonly employee: boolean;
    readonly amortization: boolean;
    readonly logistics: boolean;
    readonly allowMultipleProjectInvoiceVat: boolean;
    readonly agro: boolean;
    readonly asset: boolean;
    readonly yearEndReport: boolean;
};

export type IncomingInvoiceGlobalState = IncomingInvoiceState & {
    readonly orderLines: OrderLinesState;
    readonly viewOptions: ViewOptionsState;
    readonly massEditDialog: MassEditDialogState;
};

export type GlobalState = GeneralGlobalState & {
    readonly incomingInvoice: IncomingInvoiceGlobalState;
};

export type OrderLineTemplate = DeepPartial<OrderLineObject>;

// GURU make a version of DeepPartial that don't apply to the objects itself, only primitives.
export type OrderLineContentTemplate = DeepPartial<OrderLineObject> & {
    readonly orderLine: DeepPartial<OrderLineCostObject> & {
        readonly guiOrderLine: DeepPartial<OrderLineIncomeObject>;
    };
    readonly debitPosting: DeepPartial<DebitPosting>;
};

export type OrderLinePatch = OrderLineTemplate & {
    id: number;
};

export type SmartScanPredictionOrderLineObject = {
    readonly code: number;
    readonly description: string;
    readonly pageRef: number;
    readonly quantity: number;
    readonly total: number;
    readonly totalExclVat: number;
    readonly unit: string;
    readonly unitPrice: number;
    readonly unitPriceExclVat: number;
};

// These values are the same as in the ENUM used in InvoiceService#getPredictionsForIncomingInvoice(Voucher voucher).
export type SmartScanPredictionObject = {
    readonly AMOUNT_INC_VAT: string;
    readonly CURRENCY: string;
    readonly KID_OR_RECEIVER_REFERENCE: string;
    readonly INVOICE_NUMBER: string;
    readonly PAYMENT_DUE_DATE: string;
    readonly VOUCHER_DATE: string;
    readonly SUPPLIER: string;
    readonly BANK_ACCOUNT_NUMBER: string;
    readonly BANK_ACCOUNT_IBAN: string;
    readonly BANK_ACCOUNT_SWIFT: string;
};

export interface ProjectSubContractObject extends ModelAPIV1 {
    readonly projectId: number;
    readonly customerId: number;
    readonly budgetFeeCurrency: number;
    readonly budgetExpensesCurrency: number;
    readonly budgetIncomeCurrency: number;
    readonly budgetNetAmountCurrency: number;
    readonly budgetExpensesNegativeCurrency: number;
    readonly name: string;
    readonly displayName: string;
    readonly description: string;
}

export enum LedgerType {
    ASSET = 'ASSET',
    GENERAL = 'GENERAL',
    EMPLOYEE = 'EMPLOYEE',
    CUSTOMER = 'CUSTOMER',
    VENDOR = 'VENDOR',
}

export interface AccountObject extends ModelAPIV2 {
    readonly version: number;
    readonly number: number;
    readonly name: string;
    readonly description: string;
    readonly type: string;
    readonly vatType: ModelAPIV2 | null; // Incomplete type
    readonly vatLocked: boolean;
    readonly currency: ModelAPIV2 | null; // Incomplete type
    readonly ledgerType: LedgerType;
    readonly isCloseable: boolean;
    readonly isApplicableForSupplierInvoice: boolean;
    readonly requireReconciliation: boolean;
    readonly isInactive: boolean;
    readonly isBankAccount: boolean;
    readonly isInvoiceAccount: boolean;
    readonly bankAccountNumber: string;
    readonly bankAccountCountry: ModelAPIV2 | null; // Incomplete type
    readonly bankName: string;
    readonly bankAccountIBAN: string;
    readonly bankAccountSWIFT: string;
    readonly department: ModelAPIV2 | null;
    readonly quantityType1: ModelAPIV2 | null;
    readonly quantityType2: ModelAPIV2 | null;
}

export interface ProjectObject extends ModelAPIV2 {
    readonly version: number;
    readonly name: string;
    readonly number: string;
    readonly displayName: string;
    readonly description: string;
    readonly projectManager: ModelAPIV2 | null; // Incomplete type
    readonly projectManagerNameAndNumber: string;
    readonly hierarchyLevel: number;
    readonly hierarchyNameAndNumber?: string;
    readonly customerName: string;
    readonly department?: ModelAPIV2; // Incomplete type
    readonly mainProject: ModelAPIV2 | null; // Incomplete type
    readonly startDate: string;
    readonly endDate: string;
    readonly customer: (ModelAPIV2 & { name: string }) | null; // Incomplete type
    readonly isClosed: boolean;
    readonly isReadyForInvoicing: boolean;
    readonly isInternal: boolean;
    readonly isOffer: boolean;
    readonly projectCategory: ModelAPIV2 | null;
    readonly deliveryAddress: ModelAPIV2 | null;
    readonly displayNameFormat: string;
    readonly externalAccountNumber: string;
    readonly discountPercentage: number;
    readonly markUpOrderLines: number;
    readonly vatType: ModelAPIV2 | null; // Incomplete type
    readonly orderlines: Array<ModelAPIV2> | null; // Incomplete type
    readonly projectHourlyRates: Array<ModelAPIV2> | null; // Incomplete type
    readonly participants: Array<ModelAPIV2> | null; // Incomplete type
    readonly currency: { id: number; code: string }; // Incomplete type
}

export interface VatEntry extends ModelAPIV1 {
    readonly name: string;
    readonly number: string;
    readonly vatCode: number;
    readonly shortName: string;
    readonly accountId: number;
    readonly basisPercentage: number;
    readonly percentage: number;
    readonly percentageAsString: string;
    readonly isReverseInVatCode: boolean;
    readonly displayName: string;
}

export interface TaxTransactionTypeEntry extends ModelAPIV1 {
    readonly displayName: string;
}
