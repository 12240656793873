import * as React from 'react';

function isString(value: any) {
    return typeof value === 'string';
}

function getLocale() {
    return normalizeLanguage(window.locale);
}

function normalizeLanguage(language: string): string {
    if (isString(language) && language.toLocaleLowerCase().includes('en')) {
        return 'en-GB';
    }
    return 'nb-NO';
}

export function NumberFormat({
    className,
    value,
    options,
    id,
}: {
    className?: string;
    value: number;
    options: Intl.NumberFormatOptions;
    id?: string;
}) {
    return (
        <div className={className} id={id}>
            {Intl.NumberFormat(getLocale(), options).format(value)}
        </div>
    );
}
