import {
    getSortDirection,
    getSorting,
} from '@Page/LogisticsV2/utils/functions';
import { SortingType } from '@Page/LogisticsV2/utils/enums';
import { SortableTableHeader } from '@tlx/atlas';
import React from 'react';
import useSessionStorage from '@Page/LogisticsV2/hooks/useSessionStorage';

export function StocktakingDetailsTableHeader({
    columns,
}: {
    columns: string[];
}): JSX.Element {
    const [sorting, setSorting] = useSessionStorage(
        `stocktakingDetailsSortingBy-${window.loginEmployeeId}`,
        SortingType.NAME_ASCENDING
    );
    return (
        <thead className="atl-thead atl-thead--sticky">
            <tr className="atl-tr">
                <NumberHeader value={sorting} onChange={setSorting} />
                <NameHeader value={sorting} onChange={setSorting} />
                <CountHeader />
                {columns.map((column) => (
                    <StocktakingDetailsTableHeaderCell
                        key={column}
                        column={column}
                        sorting={sorting}
                        onChange={setSorting}
                    />
                ))}
                <ConfirmedHeader />
                <th className="atl-th" />
            </tr>
        </thead>
    );
}

export function StocktakingDetailsTableHeaderCell({
    column,
    sorting,
    onChange,
}: {
    sorting: SortingType;
    onChange: (value: SortingType) => void;
    column: string;
}): JSX.Element {
    switch (column) {
        case 'unit':
            return <UnitHeader />;
        case 'deviation':
            return <DeviationHeader />;
        case 'costPrice':
            return <CostPriceHeader />;
        case 'value':
            return <ValueHeader />;
        case 'location':
            return <LocationHeader value={sorting} onChange={onChange} />;
        case 'comment':
            return <CommentHeader />;
        default:
            throw new Error(`Unknown column: ${column}`);
    }
}

export function NumberHeader({
    value,
    onChange,
}: {
    value: SortingType;
    onChange: (value: SortingType) => void;
}) {
    return (
        <SortableTableHeader
            data-testid={'stocktaking-number-header'}
            className="atl-th atl-align-middle"
            sortDirection={getSortDirection(
                value,
                SortingType.NUMBER_ASCENDING,
                SortingType.NUMBER_DESCENDING
            )}
            onClick={() =>
                onChange(
                    getSorting(
                        value,
                        SortingType.NUMBER_ASCENDING,
                        SortingType.NUMBER_DESCENDING
                    )
                )
            }
        >
            {getMessage('text_number')}
        </SortableTableHeader>
    );
}

export function NameHeader({
    value,
    onChange,
}: {
    value: SortingType;
    onChange: (value: SortingType) => void;
}) {
    return (
        <SortableTableHeader
            data-testid={'stocktaking-name-header'}
            className="atl-th atl-align-middle"
            style={{ width: '250px' }}
            sortDirection={getSortDirection(
                value,
                SortingType.NAME_ASCENDING,
                SortingType.NAME_DESCENDING
            )}
            onClick={() =>
                onChange(
                    getSorting(
                        value,
                        SortingType.NAME_ASCENDING,
                        SortingType.NAME_DESCENDING
                    )
                )
            }
        >
            {getMessage('text_name')}
        </SortableTableHeader>
    );
}

export function CountHeader() {
    return (
        <th className="atl-th atl-align-middle atl-ml-8">
            {getMessage('text_count')}
        </th>
    );
}

function UnitHeader() {
    return (
        <th className="atl-th atl-align-middle atl-whitespace-nowrap">
            {getMessage('text_product_unit')}
        </th>
    );
}

function DeviationHeader() {
    return (
        <th className="atl-th atl-align-middle atl-text-right">
            {getMessage('text_deviation')}
        </th>
    );
}

function CostPriceHeader() {
    return (
        <th className="atl-th atl-align-middle atl-text-right atl-whitespace-nowrap">
            {getMessage('text_cost_price')}
        </th>
    );
}

function ValueHeader() {
    return (
        <th className="atl-th atl-align-middle atl-text-right">
            {getMessage('text_value')}
        </th>
    );
}

function LocationHeader({
    value,
    onChange,
}: {
    value: SortingType;
    onChange: (value: SortingType) => void;
}) {
    return (
        <SortableTableHeader
            data-testid={'stocktaking-location-header'}
            className="atl-align-middle atl-th"
            sortDirection={getSortDirection(
                value,
                SortingType.LOCATION_ASCENDING,
                SortingType.LOCATION_DESCENDING
            )}
            onClick={() =>
                onChange(
                    getSorting(
                        value,
                        SortingType.LOCATION_ASCENDING,
                        SortingType.LOCATION_DESCENDING
                    )
                )
            }
        >
            {getMessage('text_location')}
        </SortableTableHeader>
    );
}

function CommentHeader() {
    return (
        <th className="atl-th atl-align-middle">
            {getMessage('text_comment')}
        </th>
    );
}

export function ConfirmedHeader() {
    return (
        <th className="atl-th atl-align-middle">
            {getMessage('text_confirmed_noarg')}
        </th>
    );
}
