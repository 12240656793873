import React from 'react';
import { Group, ModalContent, ModalSubtitle, ModalTitle } from '@tlx/atlas';

export type SendSigningRequestErrorProps = {
    serverError?: string;
    validationMessages?: string[];
};

export function SendSigningRequestError({
    serverError,
    validationMessages,
}: SendSigningRequestErrorProps) {
    if (serverError === '422') {
        return <BrregError />;
    }

    return <ServerError validationMessages={validationMessages} />;
}

export function ServerError({
    validationMessages,
}: SendSigningRequestErrorProps) {
    const showPossibleReasons = validationMessages?.length === 0;

    return (
        <>
            <ModalTitle>{getMessage('text_service_unavailable')}</ModalTitle>
            <ModalSubtitle>{getMessage('text_brreg_issue')}</ModalSubtitle>
            <ModalContent>
                <Group
                    className={'atl-flex atl-flex-col atl-pb-16 atl-text-base'}
                >
                    <div>{getMessage('text_brreg_issue_explanation')}</div>
                    <ul>
                        {!showPossibleReasons && (
                            <li>
                                {getMessage('text_brreg_issue_reason_else')}
                            </li>
                        )}
                        {showPossibleReasons && (
                            <>
                                <li>
                                    {getMessage('text_brreg_issue_reason_1')}
                                </li>
                                <li>
                                    {getMessage('text_brreg_issue_reason_2')}
                                </li>
                            </>
                        )}
                    </ul>
                </Group>
            </ModalContent>
        </>
    );
}

export function BrregError() {
    return (
        <>
            <ModalTitle>
                {getMessage('text_unverifiable_company_brreg_short')}
            </ModalTitle>
            <ModalSubtitle>
                {getMessage('text_unverifiable_company_brreg_long')}
            </ModalSubtitle>
            <ModalContent>
                <Group className={'atl-flex atl-flex-col atl-pb-8'}>
                    <div>
                        {getMessage('text_suggested_solution_contact_support')}
                    </div>
                </Group>
                <div className="atl-flex atl-justify-center atl-mt-8">
                    <a
                        //TODO: replace with resource instead of hardcoding?
                        href="https://hjelp.tripletex.no/hc/no/p/kontakt_oss"
                        className="atl-button atl-button--primary"
                    >
                        {getMessage('text_shortcut_to_tripletex_support')}
                    </a>
                </div>
            </ModalContent>
        </>
    );
}
