import React, { ReactNode, useState } from 'react';
import { StocktakingDTO } from '@Page/LogisticsV2/types/dtoTypes';
import {
    doCompleteStocktaking,
    doDeleteStocktaking,
    doEmptyWarehouse,
} from '@Page/LogisticsV2/pages/Stocktaking/details2/StocktakingDetailsActions';
import { getValidationMessagesFromResponse } from '@Page/LogisticsV2/utils/functions';
import { nav } from '../../../../../../../../js/modules/navigation';
import { AutoSaveStatusType, PageUrl } from '@Page/LogisticsV2/utils/enums';
import {
    Breadcrumbs,
    BreadcrumbsLinkItem,
} from '@Page/Logistics/Component/Breadcrumbs/Breadcrumbs';
import { StatusIcon } from '@Page/LogisticsV2/utils/generalComponents';
import { getStatus } from '@Page/LogisticsV2/pages/Stocktaking/components';
import {
    Button,
    KebabIcon,
    PopupMenu,
    PopupMenuItemButton,
    usePopupMenu,
} from '@tlx/atlas';
import { LogisticsModal } from '@Page/LogisticsV2/components/Modals/Modal';
import { OptionalComponent } from '@Page/LogisticsV2/pages/Stocktaking/details2/StocktakingDetailsComponents';
import { useDeviceType } from '@Component/Responsive/useDeviceType';
import { getSvgIcon } from '@Page/LogisticsV2/utils/svgIcons';
import useLocalStorage from '../../../../../../util/storage';
import useSessionStorage from '@Page/LogisticsV2/hooks/useSessionStorage';
import { ProductLineStatus } from '@Page/LogisticsV2/pages/Stocktaking/utils';

export function StocktakingDetailsHeader({
    children,
    stocktaking,
    onChange,
}: {
    children?: ReactNode;
    stocktaking: StocktakingDTO;
    onChange: (status: AutoSaveStatusType) => void;
}) {
    const device = useDeviceType();
    const [isBarcodeMode] = useLocalStorage(
        `stocktakingBarcodeMode-${window.loginEmployeeId}`,
        false
    );
    const [, setStatusFilter] = useSessionStorage(
        `stocktakingDetailsProductLineStatus-${window.loginEmployeeId}`,
        ProductLineStatus.ALL_PRODUCTS
    );
    async function handleOnComplete() {
        onChange(AutoSaveStatusType.PENDING);
        const { error } = await doCompleteStocktaking(stocktaking?.id);
        if (error === undefined) {
            onChange(AutoSaveStatusType.SAVED);
            setStatusFilter(ProductLineStatus.ALL_PRODUCTS);
        } else {
            onChange(AutoSaveStatusType.ERROR);
            getValidationMessagesFromResponse(
                error,
                getMessage('validation_cannot_complete_stocktaking')
            );
        }
    }

    async function handleOnEmptyWarehouse() {
        onChange(AutoSaveStatusType.PENDING);
        const { error } = await doEmptyWarehouse(stocktaking?.id);
        if (error !== undefined) {
            onChange(AutoSaveStatusType.ERROR);
            getValidationMessagesFromResponse(
                error,
                getMessage('validation_cannot_empty_warehouse')
            );
        } else {
            onChange(AutoSaveStatusType.SAVED);
        }
    }

    async function handleOnDelete() {
        onChange(AutoSaveStatusType.PENDING);
        const { error } = await doDeleteStocktaking(stocktaking?.id);
        if (error === undefined) {
            nav.nav(addContextId(PageUrl.STOCKTAKING));
        } else {
            onChange(AutoSaveStatusType.ERROR);
            getValidationMessagesFromResponse(
                error,
                getMessage('validation_cannot_delete_stocktaking')
            );
        }
    }

    return (
        <>
            <OptionalComponent id={'desktop'} deviceType={device}>
                <div className="atl-flex atl-flex-col atl-gap-8">
                    <Breadcrumbs>
                        <BreadcrumbsLinkItem
                            href={addContextId(PageUrl.LOGISTICS_DASHBOARD)}
                        >
                            {getMessage('menu_product')}
                        </BreadcrumbsLinkItem>
                        <BreadcrumbsLinkItem
                            href={addContextId(PageUrl.STOCKTAKING)}
                        >
                            {getMessage('text_inventories')}
                        </BreadcrumbsLinkItem>
                    </Breadcrumbs>
                    <div className="atl-flex atl-justify-between atl-items-center">
                        <div
                            id="menuHeader"
                            data-testid="header-title"
                            className="useAtlasHeader atl-flex atl-gap-16 atl-mb-0"
                        >
                            {!stocktaking?.isCompleted && isBarcodeMode && (
                                <div className="atl-flex atl-items-center">
                                    {getSvgIcon('barcode')}
                                </div>
                            )}
                            <h1 className="clip">
                                {`${getMessage('text_inventory')} ${getMessage(
                                    'th_number_short'
                                ).toLowerCase()} ${stocktaking?.number}`}
                            </h1>
                            <div className="atl-flex atl-items-center atl-gap-8 atl-text-lg">
                                <StatusIcon
                                    status={
                                        stocktaking?.isCompleted
                                            ? 'STATUS_CONFIRMED'
                                            : 'STATUS_OPEN'
                                    }
                                />
                                {getStatus(stocktaking?.isCompleted ?? false)}
                            </div>
                            {children}
                        </div>
                        {!stocktaking?.isCompleted && (
                            <StocktakingDetailsHeaderActions
                                onComplete={handleOnComplete}
                                onEmptyWarehouse={handleOnEmptyWarehouse}
                                onDelete={handleOnDelete}
                            />
                        )}
                    </div>
                </div>
            </OptionalComponent>
            <OptionalComponent id={'mobile'} deviceType={device}>
                <div className="atl-flex atl-flex-col atl-gap-16">
                    <div
                        id="menuHeader"
                        data-testid="header-title"
                        className="useAtlasHeader atl-flex atl-gap-16 atl-items-center atl-m-0"
                    >
                        {!stocktaking?.isCompleted && isBarcodeMode && (
                            <div className="atl-flex atl-items-center">
                                {getSvgIcon('barcode')}
                            </div>
                        )}
                        <h2
                            className="clip atl-text-xl atl-font-bold"
                            style={{ left: 0 }}
                        >
                            {`${getMessage('text_inventory')} ${getMessage(
                                'th_number_short'
                            ).toLowerCase()} ${stocktaking?.number}`}
                        </h2>
                        <div className="atl-flex atl-items-center atl-gap-8 atl-text-lg">
                            <StatusIcon
                                status={
                                    stocktaking?.isCompleted
                                        ? 'STATUS_CONFIRMED'
                                        : 'STATUS_OPEN'
                                }
                            />
                            {getStatus(stocktaking?.isCompleted ?? false)}
                        </div>
                    </div>
                    {!stocktaking?.isCompleted && (
                        <div className="atl-flex atl-justify-between atl-items-center">
                            {children}
                            <StocktakingDetailsHeaderActions
                                onComplete={handleOnComplete}
                                onEmptyWarehouse={handleOnEmptyWarehouse}
                                onDelete={handleOnDelete}
                            />
                        </div>
                    )}
                </div>
            </OptionalComponent>
        </>
    );
}

export function StocktakingDetailsHeaderActions({
    onComplete,
    onDelete,
    onEmptyWarehouse,
}: {
    onComplete?: () => void;
    onDelete?: () => void;
    onEmptyWarehouse?: () => void;
}) {
    const { openerButtonProps, popupMenuProps } = usePopupMenu();
    const [showEmptyWarehouseModal, setShowEmptyWarehouseModal] =
        useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showCompleteStocktakingModal, setShowCompleteStocktakingModal] =
        useState(false);
    return (
        <div className="atl-flex atl-gap-8 atl-items-center">
            <Button
                data-testid="stocktaking-complete-button"
                className={'atl-font-normal'}
                onClick={() => setShowCompleteStocktakingModal(true)}
            >
                {getMessage('text_inventory_confirm')}
            </Button>
            <div>
                <Button
                    {...openerButtonProps}
                    data-testid="open-button"
                    variant="icon"
                    aria-label="stocktaking-top-actions"
                >
                    <KebabIcon />
                </Button>

                <PopupMenu
                    {...popupMenuProps}
                    id="stocktaking-kebab-menu-button"
                >
                    <PopupMenuItemButton
                        onClick={() => setShowEmptyWarehouseModal(true)}
                        data-testid={'stocktaking-empty-warehouse'}
                    >
                        {getMessage('text_empty_warehouse')}
                    </PopupMenuItemButton>

                    <PopupMenuItemButton
                        data-testid={'stocktaking-delete-button'}
                        onClick={() => setShowDeleteModal(true)}
                    >
                        {getMessage('text_inventory_delete')}
                    </PopupMenuItemButton>
                </PopupMenu>
            </div>
            {showCompleteStocktakingModal && (
                <LogisticsModal
                    title={getMessage('text_inventory_set_completed')}
                    svgIcon="completeStocktaking"
                    onCancel={() => setShowCompleteStocktakingModal(false)}
                    onConfirm={() => {
                        onComplete?.();
                        setShowCompleteStocktakingModal(false);
                    }}
                >
                    <div className="atl-text-base atl-mb-24 goldshark-stocktaking-modal__complete-text">
                        <div className="atl-font-medium">
                            {getMessage('text_inventory_completed_warning')}
                        </div>
                        {getMessage('text_inventory_warning_complete')}
                    </div>
                </LogisticsModal>
            )}
            {showEmptyWarehouseModal && (
                <LogisticsModal
                    title={getMessage('text_warning')}
                    svgIcon="warning"
                    onConfirm={() => {
                        onEmptyWarehouse?.();
                        setShowEmptyWarehouseModal(false);
                    }}
                    onCancel={() => setShowEmptyWarehouseModal(false)}
                >
                    <div
                        style={{
                            maxWidth: '460px',
                            lineHeight: '40px',
                        }}
                    >
                        {getMessage('text_empty_warehouse_warning')}
                    </div>
                </LogisticsModal>
            )}
            {showDeleteModal && (
                <LogisticsModal
                    title={getMessage('text_warning')}
                    svgIcon="warning"
                    onConfirm={() => onDelete?.()}
                    onCancel={() => setShowDeleteModal(false)}
                >
                    {getMessage('text_sure_not_undo')}
                </LogisticsModal>
            )}
        </div>
    );
}
