import {
    DataTreeDTO,
    DataTreeNode,
} from '@Page/LogisticsV2/components/DataTree/types';

export function buildTree(
    nodes: DataTreeDTO[],
    parentId: number | null = null,
    parentNode: DataTreeNode | null = null,
    level = 0
): DataTreeNode[] {
    return nodes
        .filter((child) => child.parentId === parentId)
        .map((dto) => {
            const node: DataTreeNode = {
                id: dto.id,
                name: dto.name,
                level: level,
                children: [],
                parent: parentNode,
                prefix: '',
                isDeletable: dto.isDeletable,
            };
            node.children = buildTree(nodes, dto.id, node, level + 1);
            return node;
        });
}

function flattenTree(nodes: DataTreeNode[]) {
    for (const node of nodes) {
        if (node.children.length > 0) {
            flattenTree(node.children);
        }
        if (node.level > 0 && node.children.length > 0) {
            const parent = node.parent;
            if (parent != null) {
                const index = parent.children.findIndex(
                    (n) => n.id === node.id
                );
                for (const child of node.children.reverse()) {
                    child.prefix = node.name + ' / ' + child.prefix;
                    parent.children.splice(index + 1, 0, child);
                }
            }
            node.children = [];
        } else {
            const index = nodes.findIndex((n) => n.id === node.id);
            for (const child of node.children.reverse()) {
                child.prefix = node.name + ' / ' + child.prefix;
                nodes.splice(index + 1, 0, child);
            }
        }
    }
    return nodes;
}

export function getTree(nodes: DataTreeDTO[]) {
    return buildTree(nodes);
}

export function getFlattenedTree(nodes: DataTreeDTO[]) {
    const tree = buildTree(nodes);
    return flattenTree(tree);
}

export function deleteTreeNode(
    nodes: DataTreeDTO[],
    id: number
): DataTreeDTO[] {
    return nodes.filter((node) => node.parentId !== id && node.id !== id);
}

export function addTreeNode(
    nodes: DataTreeDTO[],
    parentId: number | null,
    id: number
): DataTreeDTO[] {
    return [{ id: id, name: '', parentId, isDeletable: true }, ...nodes];
}

export function updateTreeNode(
    nodes: DataTreeDTO[],
    id: number,
    name: string
): DataTreeDTO[] {
    return nodes.map((node) => (node.id === id ? { ...node, name } : node));
}
