import {
    Button,
    DownloadIcon,
    Group,
    Input,
    Label,
    RefreshIcon,
    ToggleGroup,
} from '@tlx/atlas';
import classNames from 'classnames';
import React, { ChangeEvent, ReactNode, useEffect, useState } from 'react';
import './style.css';
import {
    Barcode,
    getDiscrepancySum,
    getReportURL,
    getWarehouseValue,
    ProductLineDTO,
    Stocktaking,
} from './utils';
import { validateDate } from '@Page/LogisticsV2/utils/functions';
import { StocktakingDTO } from '@Page/LogisticsV2/types/dtoTypes';
import { KeyedMutator } from 'swr';
import { ListResponse } from '@tlx/astro-shared';
import { nav } from '../../../../../../js/modules/navigation';
import { NumberFormat } from '@Page/LogisticsV2/components/NumberFormat/NumberFormat';

export const StocktakingContainer = ({ children }: { children: ReactNode }) => {
    return <div className="goldshark-stocktaking__container">{children}</div>;
};

export const getStatus = (isCompleted: boolean) => {
    return (
        <div className="atl-mt-2">
            {isCompleted
                ? getMessage('text_completed')
                : getMessage('activity_status_started')}
        </div>
    );
};

export function StocktakingDetailsContainer({
    stocktaking,
    productLines,
    barcode,
    onRefresh,
}: {
    stocktaking: Stocktaking;
    productLines: ProductLineDTO[];
    barcode: Barcode;
    onRefresh: KeyedMutator<ListResponse<ProductLineDTO>[]>;
}) {
    return (
        <div className="atl-border atl-py-16 atl-px-24 atl-border-grey-20 goldshark-stocktaking-details__status atl-flex-col atl-flex atl-gap-16">
            <StocktakingDetailsOverview
                stocktaking={stocktaking.data}
                barcode={barcode}
                productLines={productLines}
                onRefresh={onRefresh}
                onChangeDate={stocktaking.onChangeDate}
            />
            {stocktaking.data?.comment && stocktaking.data?.comment !== '' && (
                <div className="atl-flex atl-p-8 atl-bg-blue-5 goldshark-stocktaking-details__comment atl-rounded">
                    {stocktaking.data.comment}
                </div>
            )}
        </div>
    );
}

function StocktakingDetailsOverview({
    stocktaking,
    barcode,
    productLines,
    onChangeDate,
    onRefresh,
}: {
    stocktaking: StocktakingDTO | undefined;
    barcode: Barcode;
    productLines: ProductLineDTO[];
    onChangeDate: (date: string) => void;
    onRefresh: KeyedMutator<ListResponse<ProductLineDTO>[]>;
}) {
    return (
        <div
            className={classNames('atl-flex atl-gap-16 atl-flex-wrap', {
                'goldshark-stocktaking-details__overview__completed':
                    stocktaking?.isCompleted,
                'goldshark-stocktaking-details__overview':
                    !stocktaking?.isCompleted,
            })}
        >
            {stocktaking?.isCompleted && (
                <StocktakingDetailsReadOnlyInputs
                    stocktaking={stocktaking}
                    productLines={productLines}
                />
            )}
            {!stocktaking?.isCompleted && (
                <StocktakingDetailsInputs
                    stocktaking={stocktaking}
                    barcode={barcode}
                    productLines={productLines ?? []}
                    onRefresh={onRefresh}
                    onChangeDate={onChangeDate}
                />
            )}
        </div>
    );
}

const StocktakingDetailsReadOnlyInputs = ({
    stocktaking,
    productLines,
}: {
    stocktaking: StocktakingDTO | undefined;
    productLines: ProductLineDTO[];
}) => {
    const discrepancySum = getDiscrepancySum(productLines);
    const warehouseValue = getWarehouseValue(productLines);
    return (
        <div className="atl-flex atl-flex-wrap atl-gap-32">
            <div className="goldshark-new-stocktaking__status__warehouse atl-mr-32 atl-flex atl-flex-col atl-gap-8">
                <div className="atl-text-base atl-font-medium">
                    {getMessage('text_warehouse')}
                </div>
                <div
                    className="atl-text-lg atl-flex atl-items-center"
                    style={{ height: '24px' }}
                >
                    {stocktaking?.inventory.name}
                </div>
            </div>
            <div className="goldshark-new-stocktaking__status__date atl-mr-32 atl-flex atl-flex-col atl-gap-8">
                <div className="atl-text-base atl-font-medium">
                    {getMessage('text_date')}
                </div>
                <div
                    className="atl-text-lg atl-flex atl-items-center"
                    style={{ height: '24px' }}
                >
                    {stocktaking?.date}
                </div>
            </div>
            <div
                className={'atl-flex atl-flex-col atl-mr-32 atl-gap-8'}
                style={{ height: '24px' }}
            >
                <div className="atl-text-base atl-font-medium">
                    {getMessage('text_discrepancy_sum')}
                </div>
                <div className={'atl-text-lg'}>{discrepancySum}</div>
            </div>
            <div
                className={'atl-flex atl-flex-col atl-mr-32 atl-gap-8'}
                style={{ height: '24px' }}
            >
                <div className="atl-text-base atl-font-medium">
                    {getMessage('text_warehouse_value')}
                </div>
                <NumberFormat
                    className="atl-text-lg"
                    value={warehouseValue}
                    options={{
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    }}
                />
            </div>
            <div className="goldshark-stocktaking-warehouse-report-status atl-flex atl-flex-col atl-gap-8">
                <div className="atl-text-base atl-font-medium">
                    {getMessage('text_stock')}
                </div>
                <div
                    className="atl-text-blue-80 atl-text-lg goldshark-stocktaking-details__link atl-flex atl-items-center"
                    style={{ height: '24px' }}
                    onClick={() =>
                        nav.popup(
                            getReportURL(
                                stocktaking?.inventory.id.toString() ?? '',
                                stocktaking?.date ?? ''
                            )
                        )
                    }
                >
                    {getMessage('text_see_stock_value')}
                </div>
            </div>
        </div>
    );
};

const StocktakingDetailsInputs = ({
    stocktaking,
    productLines,
    barcode,
    onChangeDate,
    onRefresh,
}: {
    stocktaking: StocktakingDTO | undefined;
    barcode: Barcode;
    productLines: ProductLineDTO[];
    onChangeDate: (date: string) => void;
    onRefresh: KeyedMutator<ListResponse<ProductLineDTO>[]>;
}) => {
    const [hasDateError, setHasDateError] = useState(false);
    const [internalDate, setInternalDate] = useState(stocktaking?.date ?? '');
    const [discrepancySum, setDiscrepancySum] = useState(0);

    const handleDateChange = (event: ChangeEvent<HTMLInputElement>) => {
        const inputDate = event.target.value;
        const isValidDate = validateDate(inputDate);
        setInternalDate(inputDate);
        if (isValidDate) {
            onChangeDate(inputDate);
            setHasDateError(false);
        } else {
            setHasDateError(true);
        }
    };

    useEffect(() => {
        if (barcode.isBarcodeMode) {
            setDiscrepancySum(getDiscrepancySum(barcode.scannedProductLines));
        } else {
            setDiscrepancySum(getDiscrepancySum(productLines));
        }
    }, [barcode.isBarcodeMode, productLines, barcode.scannedProductLines]);

    return (
        <form autoComplete="off" className="atl-flex atl-gap-16 atl-flex-wrap">
            <Group>
                <Label>{getMessage('text_warehouse')}</Label>
                <Input
                    type="text"
                    data-testid="warehouse-name-in-stocktaking-details"
                    value={stocktaking?.inventory.name}
                    disabled
                />
            </Group>
            <Group>
                <Label>{getMessage('text_date')}</Label>
                <div className="atl-flex atl-flex-col atl-gap-8">
                    <Input
                        type="date"
                        data-testid="date-in-stocktaking-details"
                        value={internalDate}
                        onChange={handleDateChange}
                    />
                    {hasDateError && (
                        <span className="atl-text-red-100">
                            {getMessage('validation_invalid_date')}
                        </span>
                    )}
                </div>
            </Group>
            <div
                className={
                    'atl-mb-4 atl-bg-grey-5 atl-rounded atl-flex atl-items-center atl-px-16 atl-gap-8'
                }
                style={{ marginTop: '28px' }}
            >
                {getMessage('text_discrepancy_sum')}
                <div className={'atl-font-medium'}>{discrepancySum}</div>
            </div>
            <ToggleGroup className="atl-mt-24 goldshark-toggle-wrapper atl-items-center">
                <Input
                    data-testid="barcode-mode-stocktaking"
                    type="toggle"
                    id="barcode-mode-stocktaking"
                    onChange={async () => {
                        localStorage.setItem(
                            'isBarcodeMode',
                            String(!barcode.isBarcodeMode)
                        );
                        barcode.onToggleBarcodeMode(!barcode.isBarcodeMode);
                        await onRefresh();
                    }}
                    checked={barcode.isBarcodeMode}
                />
                <Label htmlFor="barcode-mode-stocktaking">
                    {getMessage('text_use_barcode_scanner')}
                </Label>
            </ToggleGroup>
        </form>
    );
};

export function ExportMenu({
    isCompleted,
    isExporting,
    children,
    onClick,
}: {
    isCompleted: boolean;
    isExporting: boolean;
    children?: ReactNode;
    onClick: () => void;
}) {
    return (
        <div
            className={classNames(
                'atl-flex atl-items-center goldshark-stocktaking-details__file-download',
                {
                    'goldshark-stocktaking-details__completed': isCompleted,
                }
            )}
        >
            {isExporting ? (
                <Button
                    data-testid={'stocktaking-loading-export-button'}
                    variant="icon"
                    aria-label="stocktaking-loading-export-button"
                >
                    <RefreshIcon
                        className={
                            'goldshark-stocktaking-details__export-loading-icon'
                        }
                    />
                </Button>
            ) : (
                <Button
                    data-testid={'stocktaking-export-button'}
                    variant="icon"
                    title={getMessage('text_export')}
                    className={'atl-ml-4'}
                    onClick={onClick}
                    aria-label="stocktaking-export-button"
                >
                    <DownloadIcon className="atl-items goldshark-stocktaking-details-export" />
                </Button>
            )}
            {children}
        </div>
    );
}
