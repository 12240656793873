import { RouteData } from '@General/Router';
import { Team } from '@General/Team';
import * as React from 'react';
import { PageLoader } from '@Component/PageLoader';
import {
    PBC_TRIPLETEX_TEAM,
    PBC_TRIPLETEX_TEAM_ACCESS_GROUP,
    TRIPLETEX_ID,
} from '@General/PBC/util';
import { isProduction } from '@General/PBC/bank.globals';

const Page: React.FC = (props) => {
    return (
        <PageLoader<Record<string, never>>
            loader={async () => import('./page').then((m) => m.page)}
            adjustWrapperDiv
            props={props}
        />
    );
};

export const route: RouteData = {
    component: Page,
    path: '/execute/bankAdmin',
    title: () => 'Piggy Bank Crew Admin Page',
    team: Team.PBC,
    auth: (context) => {
        if (context.loginCompanyId !== TRIPLETEX_ID) {
            return false;
        }

        if (isProduction) {
            const canAccessSpecificToolInProd: number[] = [];
            for (const accessGroups in PBC_TRIPLETEX_TEAM_ACCESS_GROUP) {
                canAccessSpecificToolInProd.push(
                    ...PBC_TRIPLETEX_TEAM[accessGroups]
                );
            }

            return (
                PBC_TRIPLETEX_TEAM.ADMIN.includes(context.loginEmployeeId) ||
                canAccessSpecificToolInProd.includes(context.loginEmployeeId)
            );
        }

        return [
            ...PBC_TRIPLETEX_TEAM.LIGHT,
            ...PBC_TRIPLETEX_TEAM.ADMIN,
        ].includes(context.loginEmployeeId);
    },
};
