import { Action } from '../types/Reducer';
import { BetaProgramObject } from '../types/BetaProgram.type';

export const namespace = 'BetaProgram';

const FetchAllType = `${namespace}FetchAll`;
export type FetchAll = Action<typeof FetchAllType, {}>;
export const FetchAll = (): FetchAll => ({
    type: FetchAllType,
});
FetchAll.type = FetchAllType;

const StoreAllType = `${namespace}StoreAll`;
export type StoreAll = Action<
    typeof StoreAllType,
    {
        programs: Array<BetaProgramObject>;
    }
>;
export const StoreAll = (programs: Array<BetaProgramObject>): StoreAll => ({
    type: StoreAllType,
    programs,
});
StoreAll.type = StoreAllType;
