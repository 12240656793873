import * as React from 'react';
import { useContext, useEffect, useState } from 'react';

import './BatchInvoicing.css';
import {
    InvoiceSettings,
    OrderOrProject,
} from '@Page/ProjectInvoicingDialog/types/InvoicingDialog.type';
import { Icon } from '@Component/Icon';
import { Text } from '@Component/Text';
import { Button, Group, Input, Label, ModalCloseButton } from '@tlx/atlas';
import { dateUtil } from '../../../../../../js/modules/date';
import { InvoicingList } from '@Page/ProjectInvoicingDialog/component/BatchInvoicing/InvoicingList';
import {
    BatchInvoiceOrderOrProjectState,
    InvoicingListItem,
    InvoicingListItemSkeleton,
    OrderOrProjectInvoicingStateMessage,
} from '@Page/ProjectInvoicingDialog/component/BatchInvoicing/InvoicingListItem';
import { Company } from '@Page/ProjectInvoicingDialog/component/InvoicingModalDataFetching';
import { groupOrdersAndProjectsByInvoice } from '@Page/ProjectInvoicingDialog/utils/groupOrdersAndProjectsByInvoice';
import { SkeletonRectangle } from '@Page/ProjectInvoicingDialog/component/SkeletonRectangle/SkeletonRectangle';
import { InvoiceTotal } from '@Page/ProjectInvoicingDialog/component/InvoicingForm/InvoicingForm';
import { Tooltip } from '@Component/AntsComponents/ToolTip/components/Tooltip';
import { InvoicingModalContext } from '@Page/ProjectInvoicingDialog/component/InvoicingModal';

async function handleInvoicing(
    onSubmit: (
        invoicingDate: string,
        deliveryDate: string,
        ordersAndProjects: OrderOrProject[][],
        onError: (
            batchInvoicingGroupIndex: number,
            errorMessage: OrderOrProjectInvoicingStateMessage
        ) => void,
        onSuccess: (
            batchInvoicingGroupIndex: number,
            successMessage: OrderOrProjectInvoicingStateMessage
        ) => void,
        onInfo: (
            batchInvoicingGroupIndex: number,
            infoMessage: OrderOrProjectInvoicingStateMessage
        ) => void,
        createBackOrders: boolean,
        isProjectInvoicingModal: boolean,
        periodEndDate?: string,
        readyForBilling?: string
    ) => Promise<URL | undefined>,
    invoicingDate: string,
    deliveryDate: string,
    batchInvoicingGroupStates: BatchInvoice[],
    setOrderOrProjectStates: React.Dispatch<
        React.SetStateAction<BatchInvoice[]>
    >,
    createBackOrders: boolean,
    isProjectInvoicingModal: boolean,
    periodEndDate?: string,
    readyForBilling?: string
): Promise<URL | undefined> {
    const setOrdersAndProjectsToSending = () => {
        const orderOrProjectStatesCopy = batchInvoicingGroupStates.map(
            (orderOrProjectState) => {
                orderOrProjectState.invoicingState = {
                    status: InvoicingStatus.SENDING,
                    messages: [],
                };
                return orderOrProjectState;
            }
        );
        setOrderOrProjectStates(orderOrProjectStatesCopy);
    };

    const setOrderOrProjectErrorState = (
        orderOrProjectStateIndex: number,
        errorMessage: OrderOrProjectInvoicingStateMessage
    ) => {
        const orderOrProjectStatesCopy = batchInvoicingGroupStates.slice();
        orderOrProjectStatesCopy[orderOrProjectStateIndex].invoicingState = {
            status: InvoicingStatus.SENT_ERROR,
            messages: [errorMessage],
        };
        setOrderOrProjectStates(orderOrProjectStatesCopy);
    };

    const setOrderOrProjectSuccessState = (
        orderOrProjectStateIndex: number,
        successMessage: OrderOrProjectInvoicingStateMessage
    ) => {
        const orderOrProjectStatesCopy = batchInvoicingGroupStates.slice();
        orderOrProjectStatesCopy[orderOrProjectStateIndex].invoicingState = {
            status: InvoicingStatus.SENT_SUCCESS,
            messages: [successMessage],
        };
        setOrderOrProjectStates(orderOrProjectStatesCopy);
    };

    const setOrderOrProjectInfoState = (
        orderOrProjectStateIndex: number,
        infoMessage: OrderOrProjectInvoicingStateMessage
    ) => {
        const orderOrProjectStatesCopy = batchInvoicingGroupStates.slice();
        orderOrProjectStatesCopy[orderOrProjectStateIndex].invoicingState = {
            status: InvoicingStatus.SENT_INFO,
            messages: [infoMessage],
        };
        setOrderOrProjectStates(orderOrProjectStatesCopy);
    };

    setOrdersAndProjectsToSending();

    return onSubmit(
        invoicingDate,
        deliveryDate,
        batchInvoicingGroupStates.map((batchInvoicingGroupState) =>
            batchInvoicingGroupState.orderAndProjectStates.map(
                (orderOrProjectState) => orderOrProjectState.orderOrProject
            )
        ),
        setOrderOrProjectErrorState,
        setOrderOrProjectSuccessState,
        setOrderOrProjectInfoState,
        createBackOrders,
        isProjectInvoicingModal,
        periodEndDate,
        readyForBilling
    );
}

export interface BatchInvoicingProps {
    isLoading?: boolean;
    ordersAndProjectsLoadingCount?: number;
    className?: string;
    company: Company | undefined;
    orders: OrderOrProject[];
    projects: OrderOrProject[];
    onSubmit: (
        invoicingDate: string,
        deliveryDate: string,
        ordersAndProjects: OrderOrProject[][],
        onError: (
            batchInvoicingGroupIndex: number,
            errorMessage: OrderOrProjectInvoicingStateMessage
        ) => void,
        onSuccess: (
            batchInvoicingGroupIndex: number,
            successMessage: OrderOrProjectInvoicingStateMessage
        ) => void,
        onInfo: (
            batchInvoicingGroupIndex: number,
            infoMessage: OrderOrProjectInvoicingStateMessage
        ) => void,
        createBackOrder: boolean,
        isProjectInvoicingModal: boolean,
        periodEndDate?: string,
        readyForBilling?: string
    ) => Promise<URL | undefined>;
    invoiceSettings: InvoiceSettings;
    forwardURL: undefined | string;
    isProjectInvoicingModal: boolean;
    periodEndDate?: string;
    readyForBilling?: string;
}

export const getInvoicingValidationErrors = (
    orderOrProject: OrderOrProject
): string[] => {
    const messages = [];
    if (orderOrProject.isClosed) {
        messages.push(
            getMessage(
                orderOrProject.isProject
                    ? 'validation_the_project_is_closed'
                    : 'validation_the_order_is_closed'
            )
        );
    }

    if (
        orderOrProject.isSubscription &&
        !orderOrProject.preliminaryInvoice?.isApproved
    ) {
        messages.push(
            getMessage(
                'validation_subscription_order_must_be_approved_before_invoicing'
            )
        );
    }

    if (
        !orderOrProject.isProject &&
        orderOrProject.orderLines.length === 0 &&
        (orderOrProject.preliminaryInvoice?.travelReports.length === 0 ||
            !orderOrProject.preliminaryInvoice)
    ) {
        messages.push(getMessage('validation_missing_orderline'));
    }
    return messages;
};

enum BatchInvoicingStatus {
    PRE_SEND,
    SENDING,
    SENT,
}

export enum InvoicingStatus {
    PRE_SEND,
    VALIDATION_ERROR,
    SENDING,
    SENT_SUCCESS,
    SENT_INFO,
    SENT_ERROR,
}

export interface BatchInvoice {
    orderAndProjectStates: BatchInvoiceOrderOrProjectState[];
    invoicingState: {
        status: InvoicingStatus;
        messages: Array<string | React.JSX.Element>;
    };
}

const compareInvoicingStatus = (
    batchInvoicingGroupStateA: BatchInvoice,
    batchInvoicingGroupStateB: BatchInvoice
) => {
    if (
        batchInvoicingGroupStateA.invoicingState.status ===
            InvoicingStatus.VALIDATION_ERROR &&
        batchInvoicingGroupStateB.invoicingState.status !==
            InvoicingStatus.VALIDATION_ERROR
    ) {
        return -1;
    } else if (
        batchInvoicingGroupStateB.invoicingState.status ===
            InvoicingStatus.VALIDATION_ERROR &&
        batchInvoicingGroupStateA.invoicingState.status !==
            InvoicingStatus.VALIDATION_ERROR
    ) {
        return 1;
    }
    return 0;
};

// Collect metrics for all sent invoices and send to JSON-RPC endpoint
function collectMetricsForSentInvoices(batchInvoices: BatchInvoice[]) {
    try {
        const batchInvoicesSentSuccessfully = batchInvoices.filter(
            (batchInvoice) =>
                batchInvoice.invoicingState.status ==
                InvoicingStatus.SENT_SUCCESS
        ).length;

        const batchInvoicesSentWithInfo = batchInvoices.filter(
            (batchInvoice) =>
                batchInvoice.invoicingState.status == InvoicingStatus.SENT_INFO
        ).length;

        const batchInvoicesSentWithError = batchInvoices.filter(
            (batchInvoice) =>
                batchInvoice.invoicingState.status == InvoicingStatus.SENT_ERROR
        ).length;

        window.jsonrpc.KillBillMetrics.observeCountBulkInvoicingCompletedWithStatus(
            batchInvoicesSentSuccessfully,
            batchInvoicesSentWithInfo,
            batchInvoicesSentWithError
        );
        // eslint-disable-next-line no-empty
    } catch (ignore) {}
}

export function BatchInvoicing({
    isLoading = false,
    ordersAndProjectsLoadingCount = 0, // Used as an estimate to generate loading skeleton elements (we can't know if they will belong to single customer invoice)
    className,
    orders,
    projects,
    company,
    onSubmit,
    invoiceSettings,
    forwardURL,
    isProjectInvoicingModal,
    periodEndDate,
    readyForBilling,
}: BatchInvoicingProps) {
    const [batchInvoices, setBatchInvoices] = useState<BatchInvoice[]>([]);
    const [isBatchInvoicesLoading, setIsBatchInvoicesLoading] = useState(true);
    const [createBackOrders, setCreateBackOrders] = useState(false);
    const [isBackOrderAvailable, setIsBackOrderAvailable] = useState(false);

    const { trackingIdContext } = useContext(InvoicingModalContext);

    useEffect(() => {
        setTimeout(() => {
            if (!window.location.href.includes('story')) {
                setDateFieldTrackingId(trackingIdContext);
            }
        }, 0);
    }, [trackingIdContext]);

    useEffect(() => {
        const ordersAndProjects = orders.concat(projects);
        setIsBackOrderAvailable(
            !isProjectInvoicingModal &&
                ordersAndProjects.some(
                    (orderOrProject) => orderOrProject.canCreateBackorder
                )
        );
        const ordersAndProjectsGroupedByInvoice =
            groupOrdersAndProjectsByInvoice(ordersAndProjects);

        const mappedBatchInvoices: Array<BatchInvoice> =
            ordersAndProjectsGroupedByInvoice.map(
                (ordersAndProjectsInGroup): BatchInvoice => {
                    let isValidationError = false;

                    const mappedOrderAndProjectStates =
                        ordersAndProjectsInGroup.map((orderOrProject) => {
                            const validationErrorsForOrderOrProject =
                                getInvoicingValidationErrors(orderOrProject);

                            if (validationErrorsForOrderOrProject.length > 0) {
                                isValidationError = true;
                            }

                            return {
                                orderOrProject,
                                messages: validationErrorsForOrderOrProject,
                            };
                        });

                    return {
                        orderAndProjectStates: mappedOrderAndProjectStates,
                        invoicingState: {
                            status: isValidationError
                                ? InvoicingStatus.VALIDATION_ERROR
                                : InvoicingStatus.PRE_SEND,
                            messages: [],
                        },
                    };
                }
            );

        mappedBatchInvoices.sort(compareInvoicingStatus);
        setBatchInvoices(mappedBatchInvoices);
    }, [orders, projects, isProjectInvoicingModal]);

    useEffect(() => {
        setIsBatchInvoicesLoading(batchInvoices.length === 0 || isLoading); // mapping of data has to be finished before we truly are done loading
    }, [batchInvoices.length, isLoading]);

    const [batchInvoicingState, setBatchInvoicingState] =
        useState<BatchInvoicingStatus>(BatchInvoicingStatus.PRE_SEND); // State of the dialog (should it fx. be disabled)
    const [
        isOrderOrProjectValidationError,
        setIsOrderOrProjectValidationError,
    ] = useState(false);
    const [invoicingDate, setInvoicingDate] = useState(
        dateUtil.formatDate(dateUtil.today(), 'yyyy-MM-dd')
    );
    const [invoicingDateValidationError, setInvoicingDateValidationError] =
        useState<string | undefined>(undefined);
    const [deliveryDate, setDeliveryDate] = useState(
        dateUtil.formatDate(new Date(), 'yyyy-MM-dd')
    );
    const [deliveryDateValidationError, setDeliveryDateValidationError] =
        useState<string | undefined>(undefined);
    const [deliveryDateEdited, setDeliveryDateEdited] = useState(false);
    const [pdfURLForManualInvoices, setPDFURLForManualInvoices] = useState<
        undefined | URL
    >(undefined);

    const [startInvoiceNumberRange, setStartInvoiceNumberRange] = useState(0);
    const [endInvoiceNumberRange, setEndInvoiceNumberRange] = useState(0);

    const companyCurrencyCode = company?.currency.code;

    let ordersAndProjectsTotalAmount = 0;
    let ordersAndProjectsTotalAmountExcludingVAT = 0;
    batchInvoices.forEach((batchInvoicingGroupState) =>
        batchInvoicingGroupState.orderAndProjectStates.forEach(
            (orderOrProjectState) => {
                ordersAndProjectsTotalAmount +=
                    orderOrProjectState.orderOrProject.preliminaryInvoice
                        ?.amount ?? 0;
                ordersAndProjectsTotalAmountExcludingVAT +=
                    orderOrProjectState.orderOrProject.preliminaryInvoice
                        ?.amountExcludingVat ?? 0;
            }
        )
    );

    useEffect(() => {
        if (
            batchInvoicingState == BatchInvoicingStatus.SENT &&
            !isBatchInvoicesLoading
        ) {
            collectMetricsForSentInvoices(batchInvoices);
        }
    }, [batchInvoicingState, batchInvoices, isBatchInvoicesLoading]);

    useEffect(() => {
        let isAnInvoiceSending = false;
        let isAllInvoicesSent = true;
        let newIsOrderOrProjectValidationError = false;

        batchInvoices.forEach((batchInvoiceGroupState) => {
            if (
                batchInvoiceGroupState.invoicingState.status ===
                InvoicingStatus.PRE_SEND
            ) {
                isAllInvoicesSent = false;
            }

            if (
                batchInvoiceGroupState.invoicingState.status ===
                InvoicingStatus.SENDING
            ) {
                isAnInvoiceSending = true;
                isAllInvoicesSent = false;
            }

            if (
                batchInvoiceGroupState.invoicingState.status ===
                InvoicingStatus.VALIDATION_ERROR
            ) {
                newIsOrderOrProjectValidationError = true;
            }
        });

        setIsOrderOrProjectValidationError(newIsOrderOrProjectValidationError);

        if (isAnInvoiceSending) {
            setBatchInvoicingState(BatchInvoicingStatus.SENDING);
        } else if (isAllInvoicesSent) {
            setBatchInvoicingState(BatchInvoicingStatus.SENT);
        } else {
            setBatchInvoicingState(BatchInvoicingStatus.PRE_SEND);
        }
    }, [batchInvoices]);

    useEffect(() => {
        const newStartInvoiceNumberRange = invoiceSettings.nextInvoiceNumber;
        const newEndInvoiceNumberRange =
            newStartInvoiceNumberRange + batchInvoices.length - 1;

        setStartInvoiceNumberRange(newStartInvoiceNumberRange);
        setEndInvoiceNumberRange(newEndInvoiceNumberRange);
    }, [batchInvoices, invoiceSettings.nextInvoiceNumber]);

    const handleDateChange = (
        userInput: string,
        setDateValidationError: (
            value:
                | ((prevState: string | undefined) => string | undefined)
                | string
                | undefined
        ) => void
    ) => {
        const isValidDate = dateUtil.isDate(userInput, 'yyyy-MM-dd');
        const parsedDate = dateUtil.parseDate(userInput);
        if (!isValidDate || !parsedDate) {
            setDateValidationError(getMessage('validation_invalid_date'));
            return;
        }
        const maxValidYear = dateUtil.getYear(dateUtil.today()) + 1;
        if (parsedDate.getFullYear() > maxValidYear) {
            setDateValidationError(
                getMessage('validation_invoicing_date_too_late')
            );
            return;
        }
        setDateValidationError('');
    };

    const handleInvoicingDateChange = (value: string) => {
        handleDateChange(value, setInvoicingDateValidationError);
        setInvoicingDate(value);
        if (!deliveryDateEdited) {
            handleDeliveryDateChange(value, true);
        }
    };

    const handleDeliveryDateChange = (
        value: string,
        updatedByInvoiceDateField: boolean
    ) => {
        handleDateChange(value, setDeliveryDateValidationError);
        setDeliveryDate(value);
        if (!updatedByInvoiceDateField) {
            setDeliveryDateEdited(true);
        }
    };

    let invoicingListItems;
    if (isBatchInvoicesLoading) {
        const items = [];
        for (let i = 0; i < ordersAndProjectsLoadingCount; i++) {
            items.push(
                <InvoicingListItemSkeleton
                    className="kb-invoicing-list__item"
                    key={i}
                />
            );
        }
        invoicingListItems = items;
    } else {
        invoicingListItems = batchInvoices.map((batchInvoice, index) => {
            let invoiceListItemColor: undefined | 'error' | 'info' | 'success';
            if (
                batchInvoice.invoicingState.status ===
                    InvoicingStatus.VALIDATION_ERROR ||
                batchInvoice.invoicingState.status ===
                    InvoicingStatus.SENT_ERROR
            ) {
                invoiceListItemColor = 'error';
            } else if (
                batchInvoice.invoicingState.status === InvoicingStatus.SENT_INFO
            ) {
                invoiceListItemColor = 'info';
            } else if (
                batchInvoice.invoicingState.status ===
                InvoicingStatus.SENT_SUCCESS
            ) {
                invoiceListItemColor = 'success';
            }

            return (
                <InvoicingListItem
                    className="kb-invoicing-list__item"
                    variant={invoiceListItemColor}
                    ordersAndProjectsWithMessages={
                        batchInvoice.orderAndProjectStates
                    }
                    status={batchInvoice.invoicingState.status}
                    statusMessages={batchInvoice.invoicingState.messages}
                    onRemoveItem={() => {
                        const ordersAndProjectsCopy = [...batchInvoices];
                        ordersAndProjectsCopy.splice(index, 1);
                        setBatchInvoices(ordersAndProjectsCopy);
                    }}
                    key={
                        batchInvoice.orderAndProjectStates[0].orderOrProject.id
                    }
                />
            );
        });
    }

    const sendInvoicesText =
        batchInvoices.length === 1 ? (
            getMessage('text_send_invoice')
        ) : (
            <Text
                textKey="text_send_n_invoices"
                textKeyArgs={[batchInvoices.length]}
            />
        );

    const dateErrorExists =
        invoicingDateValidationError || deliveryDateValidationError;

    return (
        <form
            data-trackingid="react-batch-invoicing-form"
            onSubmit={async (e) => {
                e.preventDefault();

                const pdfURLForManualInvoicing = await handleInvoicing(
                    onSubmit,
                    invoicingDate,
                    deliveryDate,
                    batchInvoices,
                    setBatchInvoices,
                    createBackOrders,
                    isProjectInvoicingModal,
                    periodEndDate,
                    readyForBilling
                );

                if (pdfURLForManualInvoicing) {
                    window.open(pdfURLForManualInvoicing, '_blank')?.focus();
                    setPDFURLForManualInvoices(pdfURLForManualInvoicing);
                }
            }}
            className={className}
        >
            <header className="atl-p-32 atl-flex kb-batch-invoice-border-b-grey-20 atl-items-end atl-justify-between">
                <ModalCloseButton />

                <div className="atl-flex atl-flex-col">
                    <div className="atl-flex atl-mb-4">
                        <h2 className="atl-text-xl atl-m-0">
                            {getMessage('text_send_invoices')}
                        </h2>

                        <div className="atl-bg-turquoise-10 atl-text-turquoise-120 atl-text-s atl-px-8 atl-py-4 atl-rounded atl-ml-16">
                            {batchInvoices.length} {getMessage('text_piece_2')}
                        </div>
                    </div>

                    <div className="atl-flex atl-text-base">
                        <span className="atl-text-grey-60 atl-mr-4">
                            {getMessage('text_invoice_number')}:
                        </span>
                        <InvoiceNumberRange
                            isLoading={isBatchInvoicesLoading}
                            start={startInvoiceNumberRange}
                            end={endInvoiceNumberRange}
                        />
                    </div>

                    {isProjectInvoicingModal && (
                        <div className="atl-flex atl-text-base">
                            {getMessage(
                                'text_only_approved_projects_will_be_invoiced'
                            )}
                        </div>
                    )}
                </div>
            </header>

            <div className="atl-p-32 atl-flex atl-items-end atl-justify-between kb-batch-invoice-border-b-grey-20">
                <Group className="atl-flex atl-items-end atl-w-full">
                    <div className="atl-flex-1">
                        <Label htmlFor="invoicingDate">
                            {getMessage('text_invoice_date')}
                        </Label>
                        <Input
                            autoFocus
                            data-testid="batch-invoice-date-picker"
                            type="date"
                            id="invoicingDate"
                            value={invoicingDate}
                            onChange={(e) =>
                                handleInvoicingDateChange(e.target.value)
                            }
                            disabled={isOrderOrProjectValidationError}
                            data-trackingid={
                                !window.location.href.includes('story')
                                    ? getLocaleMessage(
                                          'en_GB',
                                          'text_invoice_date'
                                      ) +
                                      ' (batch invoicing) (invoicing React modal)' +
                                      trackingIdContext
                                    : ''
                            }
                        />
                        {dateErrorExists && (
                            <div
                                className="atl-text-red-100"
                                style={{ height: '20px' }}
                            >
                                {invoicingDateValidationError ?? ''}
                            </div>
                        )}
                    </div>
                    {company && company.invoiceShowDeliveryDate && (
                        <div className="atl-flex-1">
                            <Label htmlFor="deliveryDate">
                                {getMessage('text_delivery_date')}
                            </Label>
                            <Input
                                data-testid="batch-delivery-date-picker"
                                type="date"
                                id="deliveryDate"
                                value={deliveryDate}
                                onChange={(e) =>
                                    handleDeliveryDateChange(
                                        e.target.value,
                                        false
                                    )
                                }
                                disabled={isOrderOrProjectValidationError}
                                data-trackingid={
                                    !window.location.href.includes('story')
                                        ? getLocaleMessage(
                                              'en_GB',
                                              'text_delivery_date'
                                          ) +
                                          ' (batch invoicing) (invoicing React modal)' +
                                          trackingIdContext
                                        : ''
                                }
                            />
                            {dateErrorExists && (
                                <div
                                    className="atl-text-red-100"
                                    style={{ height: '20px' }}
                                >
                                    {deliveryDateValidationError ?? ''}
                                </div>
                            )}
                        </div>
                    )}
                    {isBackOrderAvailable && (
                        <div className="atl-flex atl-gap-8 atl-items-center atl-flex-1 atl-mb-8">
                            <Input
                                type="checkbox"
                                checked={createBackOrders}
                                onChange={() =>
                                    setCreateBackOrders(!createBackOrders)
                                }
                                name="createBackOrders"
                                data-testid="batch-invoicing-create-back-order-button"
                                data-trackingid={
                                    !window.location.href.includes('story')
                                        ? getLocaleMessage(
                                              'en_GB',
                                              'text_restorder'
                                          ) +
                                          ' checkbox (batch invoicing) (invoicing React modal)' +
                                          trackingIdContext
                                        : ''
                                }
                            />
                            <Label
                                className="atl-m-0"
                                htmlFor="createBackOrders"
                            >
                                {getMessage('text_restorder')}
                            </Label>

                            <Tooltip
                                className="atl-flex"
                                content={getMessage(
                                    'text_create_back_orders_for_orders'
                                )}
                                direction="right"
                            >
                                <Icon
                                    className="atl-text-blue-80 atl-mr-4"
                                    icon="error_outline"
                                />
                            </Tooltip>
                        </div>
                    )}
                </Group>
            </div>

            <div className="atl-pt-32">
                <InvoicingList>{invoicingListItems}</InvoicingList>
            </div>

            {pdfURLForManualInvoices && (
                <div className="atl-px-32 atl-py-8 atl-flex atl-justify-end">
                    <Button
                        variant="secondary"
                        data-testid={'batch-invoice-open-pdf-button'}
                        onClick={() =>
                            window
                                .open(pdfURLForManualInvoices, '_blank')
                                ?.focus()
                        }
                        data-trackingid={
                            !window.location.href.includes('story')
                                ? getLocaleMessage(
                                      'en_GB',
                                      'text_open_compiled_pdf'
                                  ) +
                                  ' (batch invoicing) (invoicing React modal)' +
                                  trackingIdContext
                                : ''
                        }
                    >
                        {getMessage('text_open_compiled_pdf')}
                        <Icon icon="open_in_new" />
                    </Button>
                </div>
            )}

            <div className="atl-p-32 atl-flex atl-justify-between kb-batch-invoice-border-t-grey-20">
                <div className="atl-flex atl-items-end">
                    {batchInvoicingState === BatchInvoicingStatus.SENT &&
                    !isBatchInvoicesLoading ? (
                        <Button
                            data-testid="done-batch-invoice-button"
                            onClick={() => {
                                if (forwardURL) {
                                    window.location.href = forwardURL;
                                }
                            }}
                            data-trackingid={
                                !window.location.href.includes('story')
                                    ? getLocaleMessage(
                                          'en_GB',
                                          'button_finish_it'
                                      ) +
                                      ' button (batch invoicing) (invoicing React modal)' +
                                      trackingIdContext
                                    : ''
                            }
                        >
                            {getMessage('button_finish_it')}
                        </Button>
                    ) : (
                        <>
                            <Button
                                type="submit"
                                data-testid="batch-invoice-submit-button"
                                disabled={
                                    isBatchInvoicesLoading ||
                                    isOrderOrProjectValidationError ||
                                    !!invoicingDateValidationError ||
                                    !!deliveryDateValidationError ||
                                    batchInvoicingState ===
                                        BatchInvoicingStatus.SENDING
                                }
                                data-trackingid={
                                    !window.location.href.includes('story')
                                        ? getLocaleMessage(
                                              'en_GB',
                                              'text_send_invoices'
                                          ) +
                                          ' (batch invoicing) (invoicing React modal)' +
                                          trackingIdContext
                                        : ''
                                }
                            >
                                {sendInvoicesText}
                            </Button>
                        </>
                    )}
                </div>

                <InvoiceTotal
                    isLoading={isLoading}
                    total={ordersAndProjectsTotalAmount}
                    totalExcludingVAT={ordersAndProjectsTotalAmountExcludingVAT}
                    currencyCode={companyCurrencyCode}
                />
            </div>
        </form>
    );
}

function InvoiceNumberRange({
    isLoading,
    start,
    end,
}: {
    isLoading?: boolean;
    start?: number;
    end?: number;
}) {
    return (
        <div className="atl-flex">
            <span className="atl-flex">
                #
                {isLoading ? (
                    <SkeletonRectangle width={40} height={20} />
                ) : (
                    start
                )}
            </span>
            {start !== end && (
                <>
                    <Icon
                        className="kb-invoice-number-range__arrow"
                        icon="east"
                    />
                    <span className="atl-flex">
                        #
                        {isLoading ? (
                            <SkeletonRectangle width={40} height={20} />
                        ) : (
                            end
                        )}
                    </span>
                </>
            )}
        </div>
    );
}

function setDateFieldTrackingId(trackingIdContext: string) {
    const invoiceDateButton = $(
        'button[data-testid=batch-invoice-date-picker-button]'
    );
    invoiceDateButton.attr(
        'data-trackingid',
        'Date picker opener (' +
            getLocaleMessage('en_GB', 'text_invoice_date').toLowerCase() +
            ') (batch invoicing) (invoicing React modal)' +
            trackingIdContext
    );
    invoiceDateButton.on('click', () => {
        setTimeout(() => {
            $(
                '[data-testid=batch-invoice-date-picker-month-selector-next-month]'
            ).attr(
                'data-trackingid',
                'Date picker next month (' +
                    getLocaleMessage(
                        'en_GB',
                        'text_invoice_date'
                    ).toLowerCase() +
                    ') (batch invoicing) (invoicing React modal)' +
                    trackingIdContext
            );
            $(
                '[data-testid=batch-invoice-date-picker-month-selector-previous-month]'
            ).attr(
                'data-trackingid',
                'Date picker previous month (' +
                    getLocaleMessage(
                        'en_GB',
                        'text_invoice_date'
                    ).toLowerCase() +
                    ') (batch invoicing) (invoicing React modal)' +
                    trackingIdContext
            );
            $(
                '[data-testid=batch-invoice-date-picker-calendar-day-button]'
            ).attr(
                'data-trackingid',
                'Date picker day (' +
                    getLocaleMessage(
                        'en_GB',
                        'text_invoice_date'
                    ).toLowerCase() +
                    ') (batch invoicing) (invoicing React modal)' +
                    trackingIdContext
            );
            $(
                '[data-testid=batch-invoice-date-picker-calendar-day-button-next-or-previous-month]'
            ).attr(
                'data-trackingid',
                'Date picker day (' +
                    getLocaleMessage(
                        'en_GB',
                        'text_invoice_date'
                    ).toLowerCase() +
                    ') (batch invoicing) (invoicing React modal)' +
                    trackingIdContext
            );
        }, 0);
    });
}
