import {
    FieldErrors,
    SubmitHandler,
    useForm,
    UseFormGetValues,
    UseFormRegister,
    UseFormSetValue,
    UseFormWatch,
} from 'react-hook-form';
import { invokeAPI, useFetchSingle } from '@Page/LogisticsV2/hooks/api';
import { ApiUrl, MethodType } from '@Page/LogisticsV2/utils/enums';
import { postNotificationMessage } from '@General/LegacyNotification';
import { getValidationMessagesFromResponse } from '@Page/LogisticsV2/utils/functions';
import { BringCredentialsDTO } from '@Page/Logistics/TransportIntegration/types';
import { useEffect } from 'react';

export type BringCredentialsModel = {
    register: UseFormRegister<BringCredentialsDTO>;
    watch: UseFormWatch<BringCredentialsDTO>;
    getValues: UseFormGetValues<BringCredentialsDTO>;
    setValue: UseFormSetValue<BringCredentialsDTO>;
    errors: FieldErrors<BringCredentialsDTO>;
};

export function useBringCredentials() {
    const { data: bringCredentialsAPI, mutate } =
        useFetchSingle<BringCredentialsDTO>(ApiUrl.BRING_INTEGRATION);
    const {
        register,
        handleSubmit,
        watch,
        getValues,
        setValue,
        reset,
        formState: { errors },
    } = useForm<BringCredentialsDTO>({ mode: 'onChange' });
    useEffect(() => {
        reset(bringCredentialsAPI);
    }, [bringCredentialsAPI]);

    const onSubmit: SubmitHandler<BringCredentialsDTO> = (data) =>
        handleSaveCredentials(data);

    const handleSaveCredentials = async (data: BringCredentialsDTO) => {
        const isNew = data.id === undefined || data.id < 1;
        const { error } = await invokeAPI(
            isNew
                ? ApiUrl.BRING_INTEGRATION
                : `${ApiUrl.BRING_INTEGRATION}/${data.id}`,
            isNew ? MethodType.POST : MethodType.PUT,
            JSON.stringify(data)
        );
        if (error === undefined) {
            postNotificationMessage(getMessage('text_transport_integration'), [
                getMessage('text_autosave_status_saved'),
            ]);
            await mutate();
        } else {
            getValidationMessagesFromResponse(error);
        }
    };
    return {
        errors,
        register,
        watch,
        getValues,
        setValue,
        onSave: handleSubmit(onSubmit),
    };
}
