import jQuery from 'jquery';
import { format } from '../../js/modules/format.ts';
import { utils } from '../../js/modules/utils';
import { tlxForms } from '../../js/modules/forms';
import { addContextId } from '../../js/modules/url';

const $ = jQuery;
/**
 * Widget for Aprila invoice factoring support in most invoicing dialogs.
 */
(function () {
    var displayState = {
        // GURU needed?
        EMPTY: 1,
        FETCHING: 2,
        DISPLAY_ERROR: 3,
        DISPLAY_REJECTED: 4,
        DISPLAY_VALIDATION: 5,
        DISPLAY_TIMEOUT: 6,
        DISPLAY_OFFER: 7,
        DISPLAY_MESSAGE: 8,
        DISPLAY_REASON: 9,
    };

    let initiateInvoiceCounter = 0;

    window.addEventListener('tlx:notification:custom', (event) => {
        if (event.detail.type === 'UPDATE_FACTORING_APRILA_OFFER') {
            $('.UPDATE_FACTORING_APRILA_OFFER').trigger(
                'UPDATE_FACTORING_APRILA_OFFER',
                [event.detail.body]
            );
        }
    });

    $.widget('tlx.aprilaFactoring', {
        options: {
            /**
             * Required
             *
             * Used by this widget to load the results. @See no.tripletex.tcp.web.aprila.FactoringSupport.Offer for structure.
             */
            load: function () {
                throw 'Not implemented';
            },
            /**
             * Required
             *
             * Used by this widget to save the results. @See no.tripletex.tcp.web.aprila.FactoringSupport.Offer for structure.
             */
            save: function () {
                throw 'Not implemented';
            },
            /**
             * Required
             *
             * Used by this widget to get the invoice invoicing date to fetch offers for.
             */
            invoiceDateProvider: function () {
                throw 'Not implemented';
            },
            /**
             * Optional
             *
             * The initial timeout value.
             */
            timeoutInitial: 10000,
            /**
             * Optional
             *
             * The timeout increment value.
             * Added to the timeout celling for each repeated attempt at fetching the offer.
             */
            timeoutIncrement: 5000,
            // Optional
            callbackClass: 'UPDATE_FACTORING_APRILA_OFFER',
            // Optional
            callbackEvent: 'UPDATE_FACTORING_APRILA_OFFER',
        },
        _create: function () {
            // Internal value defaults.
            this._state = displayState.EMPTY;
            this._ignoreTimeout = false;
            this._offer = this.options.load();
            this._offerTimestamp = 0;
            this._internalId =
                'tlx.aprilaFactoring-' + utils.getRandomInt(0, 99999999);
            this._timeout = this.options.timeoutInitial;
            this._timeoutHandler = this._onTimeout.bind(this);
            this._callbackHandler = this._onCallback.bind(this);
            this._clickCheckboxHandler = this._onClickCheckbox.bind(this);
            this._clickRetryHandler = this._onClickRetry.bind(this);
            this._$container = $(this.element);
            this._cashCreditUrl = addContextId('/execute/CashCredit');

            // Construct DOM
            var $widget = $('<div>', {
                class: 'interactive-factoring ' + this.options.callbackClass,
            });

            $widget
                .append(
                    $('<span>', {
                        text: getMessage('text_factoring_system_aprila'),
                        class: 'interactive-factoring__title',
                    })
                )
                .append(
                    $('<div>', {
                        class: 'interactive-factoring__status',
                    }).append(
                        $('<div>', {
                            class: 'inputItem checkbox',
                        })
                            .hide()
                            .append(
                                $('<label>', {
                                    for: this._internalId + '-sell',
                                    class: 'tlx-checkbox',
                                }).append(
                                    $('<input>', {
                                        type: 'checkbox',
                                        id: this._internalId + '-sell',
                                        class: 'interactive-factoring__checkbox tlx-checkbox__input',
                                    }),
                                    $('<span>', {
                                        class: 'interactive-factoring__summary tlx-checkbox__label',
                                        text: getMessage(
                                            'text_factoring_sell_invoice'
                                        ),
                                    })
                                )
                            )
                    ),
                    $('<div>', {
                        class: 'interactive-factoring__breakdown',
                    }),
                    $('<div>', {
                        class: 'interactive-factoring__spinner',
                    }),
                    $('<div>', {
                        class: 'interactive-factoring__footer tmdl-textfield__helper-text',
                        html: getMessage('text_factoring_sell_info'),
                    }),
                    $('<div>', {
                        class: 'interactive-factoring__warning',
                    }).append(
                        $('<div>', {
                            class: 'warningicon',
                            html: '<i class="icon material-icons tmdl-date-icon">info</i>',
                        }),
                        $('<div>', {
                            class: 'tmdl-textfield__helper-text',
                            html: getMessage(
                                'text_factoring_sell_EOL_info',
                                this._cashCreditUrl
                            ),
                        })
                    )
                );

            // Fill container with generated widget.
            this._$container.append($widget);

            // Set up handlers
            this._$container.on(
                this.options.callbackEvent,
                '.interactive-factoring',
                this._callbackHandler
            );
            this._$container.on(
                'change',
                '.interactive-factoring__checkbox',
                this._clickCheckboxHandler
            );

            // Initalize spinner so that it is ready when we need it.
            $('.interactive-factoring__spinner', this._$container).tlxLoader({
                fader: true,
            });

            // Initialize checkbox
            tlxForms.disable(
                $('.interactive-factoring__checkbox', this._$container),
                true
            );

            this._$container.hide();
        },
        _destroy: function () {
            this._state(displayState.EMPTY);
            this._clearTimeout();

            this._$container.off(
                this.options.callbackEvent,
                'interactive-factoring',
                this._callbackHandler
            );
            this._$container.off(
                'change',
                '.interactive-factoring__checkbox',
                this._clickCheckboxHandler
            );
            this._$container.empty();
            this._$container = null;
        },
        _displayMessages: function ($target1, $targetN, messages) {
            let startIndex = 0;
            if ($target1) {
                $target1.html(
                    "<h6 class='interactive-factoring__breakdown__title'>" +
                        messages[0] +
                        '</h6>'
                );
                startIndex = 1;
            }

            if (messages.length > startIndex) {
                var entries = 0;
                var $list = $('<ul class="validation">');

                if (
                    messages[startIndex].list &&
                    messages[startIndex].list.length
                ) {
                    for (let i = 0; i < messages[1].list.length; i++) {
                        if (
                            !messages[1] ||
                            messages[1].list[i].trim().length === 0
                        ) {
                            continue;
                        }

                        $list.append(
                            $('<li>', {
                                html: messages[1].list[i],
                            })
                        );

                        entries++;
                    }
                } else {
                    for (let i = startIndex; i < messages.length; i++) {
                        if (!messages[i] || messages[i].trim().length === 0) {
                            continue;
                        }

                        $list.append(
                            $('<li>', {
                                html: messages[i],
                            })
                        );

                        entries++;
                    }
                }

                if (entries > 0) {
                    $targetN.append($list);
                }
            }
        },
        _displayBreakdown: function ($target, aprilaInvoiceOffer) {
            // Only NOK is supported by Aprila for now.
            // GURU make server send value, JavaMoney?
            var currency = 'NOK';

            $target.empty().append(
                $('<div>', {
                    class: 'interactive-factoring__breakdown-line',
                }).append(
                    $('<span>', {
                        class: 'interactive-factoring__breakdown-key',
                        text: getMessage(
                            'text_factoring_sell_invoice_sum_before'
                        ),
                    }),
                    $('<span>', {
                        class: 'interactive-factoring__breakdown-value',
                        text:
                            format.amount2(aprilaInvoiceOffer.invoiceAmount) +
                            ' ' +
                            currency,
                    })
                ),
                $('<div>', {
                    class: 'interactive-factoring__breakdown-line',
                }).append(
                    $('<span>', {
                        class: 'interactive-factoring__breakdown-key',
                        text: getMessage('text_factoring_sell_invoice_cost'),
                    }),
                    $('<span>', {
                        class: 'interactive-factoring__breakdown-value',
                        text:
                            format.amount2(aprilaInvoiceOffer.amountCost) +
                            ' ' +
                            currency,
                    })
                ),
                $('<div>', {
                    class: 'interactive-factoring__breakdown-line interactive-factoring__breakdown-line--summary',
                }).append(
                    $('<span>', {
                        class: 'interactive-factoring__breakdown-key',
                        text: getMessage(
                            'text_factoring_sell_invoice_sum_after'
                        ),
                    }),
                    $('<span>', {
                        class: 'interactive-factoring__breakdown-value',
                        text:
                            format.amount2(aprilaInvoiceOffer.amountReceived) +
                            ' ' +
                            currency,
                    })
                ),
                $('<div>', {
                    class: 'interactive-factoring__breakdown-line interactive-factoring__breakdown-line--footer',
                    text: getMessage('text_factoring_sell_footer'),
                })
            );
        },
        _displayRetryButton: function ($target) {
            $target.append(
                $('<button>', {
                    class: 'interactive-factoring__retry mdl-button mdl-js-button mdl-button--colored',
                    text: getMessage('button_refresh'),
                })
            );
            $('.interactive-factoring__retry', $target).one(
                'click',
                this._clickRetryHandler
            );
        },
        _removeRetryButton: function ($target) {
            $('.interactive-factoring__retry', $target).remove();
        },
        _updateState: function (newState, data) {
            if (this._state === newState) {
                return;
            }
            var oldState = this._state;
            this._state = newState;

            var $sellCheckbox = $(
                '.interactive-factoring__checkbox',
                this._$container
            );
            var $summary = $(
                '.interactive-factoring__summary',
                this._$container
            );
            var $spinner = $(
                '.interactive-factoring__spinner',
                this._$container
            );
            var $breakdown = $(
                '.interactive-factoring__breakdown',
                this._$container
            );
            let $checkboxDiv = $('.interactive-factoring__status .inputItem');

            $summary.empty();
            $breakdown.empty();
            tlxForms.check($sellCheckbox, false);

            // Cleanup
            switch (oldState) {
                case displayState.EMPTY:
                    this._$container.show();
                    break;

                case displayState.FETCHING:
                    this._clearTimeout();
                    this._$container.removeClass(
                        'interactive-factoring--fetching'
                    );
                    $spinner.hide();
                    if ($spinner.tlxLoader('instance')) {
                        $spinner.tlxLoader('stop');
                    }
                    break;

                case displayState.DISPLAY_OFFER:
                    $checkboxDiv.hide();
                    tlxForms.disable($sellCheckbox, true);
                    break;

                case displayState.DISPLAY_ERROR:
                    this._removeRetryButton($spinner);
                    $spinner.hide();
                    break;

                case displayState.DISPLAY_REJECTED:
                    // Already corrected
                    break;

                case displayState.DISPLAY_VALIDATION:
                    // Already corrected
                    break;

                case displayState.DISPLAY_TIMEOUT:
                    this._removeRetryButton($spinner);
                    $spinner.hide();
                    break;

                case displayState.DISPLAY_MESSAGE:
                    // Already corrected
                    break;
                case displayState.DISPLAY_REASON:
                    // Already corrected
                    break;
            }

            // Setup
            switch (newState) {
                case displayState.EMPTY:
                    this._$container.hide();
                    break;

                case displayState.FETCHING:
                    this._setTimeout();
                    this._$container.addClass(
                        'interactive-factoring--fetching'
                    );
                    $spinner.show().tlxLoader('start');
                    this._displayMessages($breakdown, $breakdown, [
                        getMessage('text_factoring_sell_invoice_processing'),
                    ]);
                    tlxForms.disable($sellCheckbox, true);
                    break;

                case displayState.DISPLAY_ERROR:
                    this._displayMessages(
                        $breakdown,
                        $breakdown,
                        [getMessage('text_communication_error')].concat(
                            data.messages
                        )
                    );
                    this._displayRetryButton($spinner);
                    $spinner.show();
                    break;

                case displayState.DISPLAY_REJECTED:
                    this._displayMessages(
                        $breakdown,
                        $breakdown,
                        data.messages
                    );
                    break;

                case displayState.DISPLAY_VALIDATION:
                    this._displayMessages(
                        $breakdown,
                        $breakdown,
                        [getMessage('validation_failed')].concat(data.messages)
                    );
                    break;

                case displayState.DISPLAY_TIMEOUT:
                    this._displayMessages($breakdown, $breakdown, [
                        getMessage('text_factoring_offer_timeout'),
                    ]);
                    this._displayRetryButton($spinner);
                    $spinner.show();
                    break;

                case displayState.DISPLAY_OFFER:
                    if (initiateInvoiceCounter === 0) {
                        this._displayMessages($summary, $summary, [
                            getMessage('text_factoring_sell_invoice'),
                        ]);
                        this._displayBreakdown(
                            $breakdown,
                            data.aprilaInvoiceOffer
                        );
                        tlxForms.disable($sellCheckbox, false);
                        this._offer.ready = true;
                        $checkboxDiv.show();
                    } else {
                        this._offer.ready = false;
                        this._save();
                    }
                    break;

                case displayState.DISPLAY_MESSAGE:
                    this._displayMessages(
                        $breakdown,
                        $breakdown,
                        data.messages
                    );
                    break;
                case displayState.DISPLAY_REASON:
                    this._displayMessages(
                        $breakdown,
                        $breakdown,
                        data.messages
                    );
                    break;
            }
        },
        _initiateOfferRequest: function () {
            // Do UI updates first to avoid race condition.
            if (this._offer.ready === true) {
                this._offer.ready = false;
                this._save();
            }
            initiateInvoiceCounter++;
            this._updateState(displayState.FETCHING);

            this._load();
            this._save();

            if (!(this._offer.invoiceId && this._offer.invoiceRevision)) {
                // Nothing to get and display offer for.
                this._updateState(displayState.EMPTY);
                return;
            }

            var self = this;
            jsonrpc.Aprila.validateInvoiceForFactoring(
                function (result, error) {
                    if (error) {
                        initiateInvoiceCounter--;
                        self._updateState(displayState.DISPLAY_ERROR, {
                            messages: [
                                getMessage('text_factoring_offer_failed'),
                                error.msg,
                            ],
                        });
                    } else if (result) {
                        if (result.length > 0) {
                            initiateInvoiceCounter--;
                            self._updateState(displayState.DISPLAY_VALIDATION, {
                                messages: result,
                            });
                            return;
                        }

                        const date = self.options.invoiceDateProvider();
                        if (!date) {
                            self._updateState(displayState.DISPLAY_VALIDATION, {
                                messages: [
                                    getMessage('text_validation_invalid_date'),
                                ],
                            });
                            return;
                        }

                        jsonrpc.Aprila.initiateInvoicePurchase(
                            function (result2, error2) {
                                if (error2) {
                                    initiateInvoiceCounter--;
                                    self._updateState(
                                        displayState.DISPLAY_ERROR,
                                        {
                                            messages: [
                                                getMessage(
                                                    'text_factoring_offer_failed'
                                                ),
                                                error2.msg,
                                            ],
                                        }
                                    );
                                } else if (result2) {
                                    initiateInvoiceCounter--;
                                    self._handleOfferData(result2, false);
                                } else {
                                    initiateInvoiceCounter--;
                                    throw 'Invalid JSON-RPC response from server!';
                                }
                            },
                            self._offer.invoiceId,
                            self._offer.invoiceRevision,
                            date
                        );
                    } else {
                        throw 'Invalid JSON-RPC response from server!';
                    }
                },
                this._offer.invoiceId,
                this._offer.invoiceRevision
            );
        },
        _setTimeout: function () {
            this._ignoreTimeout = false;
            setTimeout(this._timeoutHandler, this._timeout);
        },
        _clearTimeout: function () {
            this._ignoreTimeout = true;
            clearTimeout(this._timeoutHandler);
        },
        _onTimeout: function () {
            if (this._ignoreTimeout === true) {
                return;
            }

            this._timeout += this.options.timeoutIncrement;
            this._updateState(displayState.DISPLAY_TIMEOUT);

            if (!this._offer.offerId || this._offer.offerId.length === 0) {
                return;
            }

            var self = this;
            jsonrpc.Aprila.getInvoicePurchaseStatus(
                function (result, error) {
                    if (error) {
                        self._updateState(displayState.DISPLAY_TIMEOUT);
                    } else if (result) {
                        self._handleOfferData(result, false);
                    } else {
                        throw 'Invalid JSON-RPC response from server!';
                    }
                },
                this._offer.invoiceId,
                this._offer.invoiceRevision,
                this._offer.offerId
            );
        },
        _onCallback: function (event, data) {
            if (
                this._state !== displayState.FETCHING &&
                !(
                    this._state === displayState.DISPLAY_REJECTED &&
                    data.reasons !== null
                )
            ) {
                return;
            }

            this._handleOfferData(data, false);
        },
        _onClickRetry: function () {
            this._updateState(displayState.FETCHING);

            if (!this._offer.offerId || this._offer.offerId.length === 0) {
                this._updateState(displayState.DISPLAY_ERROR, {
                    messages: [getMessage('text_factoring_offer_failed')],
                });
            } else {
                var self = this;
                jsonrpc.Aprila.getInvoicePurchaseStatus(
                    function (result, error) {
                        if (error) {
                            self._initiateOfferRequest();
                        } else if (result) {
                            self._handleOfferData(result, false);
                        } else {
                            throw 'Invalid JSON-RPC response from server!';
                        }
                    },
                    this._offer.invoiceId,
                    this._offer.invoiceRevision,
                    this._offer.offerId
                );
            }
        },
        _onClickCheckbox: function (event) {
            if (this._state !== displayState.DISPLAY_OFFER) {
                return;
            }

            this._offer.acceptOffer = $(event.currentTarget).is(':checked');
            this._save();
        },
        _load: function () {
            this._offer = this.options.load();
        },
        _save: function () {
            this.options.save(this._offer);
        },
        _handleOfferData: function (offer, fromServer) {
            //All offers have a PENDING state and have a timestamp that is used to get the latest offer. On timeout
            //this timestamp is set to null and if the InvoicePurchaseOfferId is different it is an older offer and can be ignored.
            if (
                offer.timestamp === null &&
                this._offer.offerId !== offer.invoicePurchaseOfferId
            ) {
                return;
            }

            var currentOfferTimestamp = new Date(offer.timestamp).getTime();
            //All offers with timestamps older than the current one saved are ignored.
            if (
                offer.timestamp &&
                this._offerTimestamp > currentOfferTimestamp
            ) {
                return;
            }

            var status = offer.status.toUpperCase();
            this._offer.offerId = offer.invoicePurchaseOfferId;
            this._offerTimestamp = currentOfferTimestamp;

            this._save();

            if (status === 'PENDING') {
                // Handle the immediate response that holds no offer, collect the invoiceOfferPurchaseId to validate the later webhook callback
                return;
            }

            switch (status) {
                case 'APPROVED':
                    // Fetch offer from server if needed
                    var aprilaInvoiceOffer = fromServer
                        ? offer
                        : jsonrpc.Aprila.getInvoicePurchaseStatus(
                              this._offer.invoiceId,
                              this._offer.invoiceRevision,
                              this._offer.offerId
                          );
                    if (offer.status === aprilaInvoiceOffer.status) {
                        this._updateState(displayState.DISPLAY_OFFER, {
                            aprilaInvoiceOffer: aprilaInvoiceOffer,
                        });
                    } else {
                        this._updateState(displayState.DISPLAY_ERROR, {
                            messages: [
                                getMessage('text_factoring_offer_failed'),
                            ],
                        });
                    }
                    break;
                case 'OFFERED':
                    if (initiateInvoiceCounter === 0) {
                        this._updateState(displayState.DISPLAY_OFFER, {
                            aprilaInvoiceOffer: offer,
                        });
                    } else if (initiateInvoiceCounter > 0) {
                        return;
                    }
                    break;

                case 'REJECTED':
                case 'MANUAL':
                    if (offer.reasons !== null)
                        this._updateState(displayState.DISPLAY_REASON, {
                            messages: [
                                getMessage('text_factoring_offer_rejection'),
                            ].concat(offer.reasons),
                        });
                    else
                        this._updateState(displayState.DISPLAY_REJECTED, {
                            messages: [
                                getMessage('text_factoring_offer_rejection'),
                            ],
                        });
                    break;

                default:
                    this._updateState(displayState.DISPLAY_ERROR, {
                        messages: [getMessage('text_factoring_offer_failed')],
                    });
                    break;
            }
        },
        onOpenDialog: function () {
            if (this._state === displayState.FETCHING) {
                return;
            }
            this._initiateOfferRequest();
        },
        showMessages: function (messages) {
            this._updateState(displayState.DISPLAY_MESSAGE, {
                messages: messages,
            });
        },
        refreshOffer: $.debounce(function () {
            this._initiateOfferRequest();
        }, 500),
    });
})();
