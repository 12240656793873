import * as React from 'react';

import {
    Button,
    CheckboxGroup,
    Fieldset,
    Group,
    Input,
    Label,
    Modal,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalTitle,
    RadioGroup,
    Select,
    Option,
    ModalLayout,
} from '@tlx/atlas';

import {
    CSVExportConfiguration,
    downloadPageAsCSV,
    getDefaultCSVConfiguration,
} from '../ExportMenu.util';

import './ExportModal.css';

export interface ExportCsvProps {
    readonly onClose: () => void;
}

/**
 * ExportCsv
 */
export const ExportCsv: React.FC<ExportCsvProps> = ({ onClose }) => {
    const defaults = React.useMemo(getDefaultCSVConfiguration, []);

    const [csvHeader, setCsvHeader] = React.useState(defaults.csvHeader);
    const onChangeCsvHeader: React.ChangeEventHandler<HTMLInputElement> =
        React.useCallback(
            (event) => setCsvHeader(event.target.checked),
            [setCsvHeader]
        );

    const [csvExportFormat, setCsvExportFormat] = React.useState<
        CSVExportConfiguration['csvExportFormat']
    >(defaults.csvExportFormat);
    const isDefaultFormat = csvExportFormat === 'csvDefault';
    const isAdvancedFormat = csvExportFormat === 'csvAdvanced';
    const onChangeCsvFormatDefault = React.useCallback(
        () => setCsvExportFormat('csvDefault'),
        [setCsvExportFormat]
    );
    const onChangeCsvFormatAdvanced = React.useCallback(
        () => setCsvExportFormat('csvAdvanced'),
        [setCsvExportFormat]
    );

    const [csvEncoding, setCsvEncoding] = React.useState<
        CSVExportConfiguration['csvEncoding']
    >(defaults.csvEncoding);
    const onChangeCsvEncoding: React.ChangeEventHandler<HTMLSelectElement> =
        React.useCallback(
            (event) =>
                setCsvEncoding(
                    event.target.value as CSVExportConfiguration['csvEncoding']
                ),
            [setCsvEncoding]
        );

    const [csvSeparator, setCsvSeparator] = React.useState<
        CSVExportConfiguration['csvSeparator']
    >(defaults.csvSeparator);
    const onChangeCsvSeparator: React.ChangeEventHandler<HTMLSelectElement> =
        React.useCallback(
            (event) =>
                setCsvSeparator(
                    event.target.value as CSVExportConfiguration['csvSeparator']
                ),
            [setCsvSeparator]
        );

    const [csvDecimal, setCsvDecimal] = React.useState<
        CSVExportConfiguration['csvDecimal']
    >(defaults.csvDecimal);
    const onChangeCsvDecimal: React.ChangeEventHandler<HTMLSelectElement> =
        React.useCallback(
            (event) =>
                setCsvDecimal(
                    event.target.value as CSVExportConfiguration['csvDecimal']
                ),
            [setCsvDecimal]
        );

    const [csvQualifier, setCsvQualifier] = React.useState<
        CSVExportConfiguration['csvQualifier']
    >(defaults.csvQualifier);
    const onChangeCsvQualifier: React.ChangeEventHandler<HTMLSelectElement> =
        React.useCallback(
            (event) =>
                setCsvQualifier(
                    event.target.value as CSVExportConfiguration['csvQualifier']
                ),
            [setCsvQualifier]
        );

    const [csvLineBreak, setCsvLineBreak] = React.useState<
        CSVExportConfiguration['csvLineBreak']
    >(defaults.csvLineBreak);
    const onChangeCsvLineBreak: React.ChangeEventHandler<HTMLSelectElement> =
        React.useCallback(
            (event) =>
                setCsvLineBreak(
                    event.target.value as CSVExportConfiguration['csvLineBreak']
                ),
            [setCsvLineBreak]
        );

    const onAccept = React.useCallback(() => {
        downloadPageAsCSV({
            csvHeader,
            csvExportFormat,
            csvEncoding,
            csvSeparator,
            csvDecimal,
            csvQualifier,
            csvLineBreak,
        });
        onClose();
    }, [
        csvDecimal,
        csvEncoding,
        csvExportFormat,
        csvHeader,
        csvLineBreak,
        csvQualifier,
        csvSeparator,
        onClose,
    ]);

    return (
        <Modal open onClose={onClose}>
            <ModalLayout>
                <ModalCloseButton />
                <ModalTitle>{getMessage('text_csv_export')}</ModalTitle>
                <ModalContent className="tlx-export-modal">
                    <div className="atl-flex atl-flex-col atl-gap-24">
                        <Fieldset className="atl-flex atl-flex-col atl-gap-16">
                            <Label>
                                {getMessage('text_csv_export_format')}
                            </Label>
                            <RadioGroup>
                                <Input
                                    id="csvDefault"
                                    name="csvExportFormat"
                                    type="radio"
                                    data-testid="csv-export-modal__csv-format-default"
                                    checked={isDefaultFormat}
                                    onChange={onChangeCsvFormatDefault}
                                />
                                <Label htmlFor="csvDefault">
                                    {getMessage(
                                        'text_csv_export_format_default'
                                    )}
                                </Label>
                            </RadioGroup>
                            <RadioGroup>
                                <Input
                                    id="csvAdvanced"
                                    name="csvExportFormat"
                                    type="radio"
                                    data-testid="csv-export-modal__csv-format-advanced"
                                    checked={isAdvancedFormat}
                                    onChange={onChangeCsvFormatAdvanced}
                                />
                                <Label htmlFor="csvAdvanced">
                                    {getMessage(
                                        'text_csv_export_format_advanced'
                                    )}
                                </Label>
                            </RadioGroup>
                        </Fieldset>
                        {isAdvancedFormat ? (
                            <Fieldset className="atl-flex atl-flex-col atl-gap-16">
                                <div className="atl-flex atl-flex-row atl-gap-16 atl-flex-wrap atl-justify-between">
                                    <Group className="atl-flex-1">
                                        <Label htmlFor="csvEncoding">
                                            {getMessage('text_csv_encoding')}
                                        </Label>
                                        <Select
                                            id="csvEncoding"
                                            data-testid="csv-export-modal__csv-encoding"
                                            value={csvEncoding}
                                            onChange={onChangeCsvEncoding}
                                            className="atl-block"
                                        >
                                            <Option value="ISO-8859-1">
                                                ISO-8859-1
                                            </Option>
                                            <Option value="UTF-8">UTF-8</Option>
                                            <Option value="MacRoman">
                                                Mac Roman
                                            </Option>
                                        </Select>
                                    </Group>
                                    <Group className="atl-flex-1">
                                        <Label htmlFor="csvSeparator">
                                            {getMessage('text_csv_separator')}
                                        </Label>
                                        <Select
                                            id="csvSeparator"
                                            data-testid="csv-export-modal__csv-separator"
                                            value={csvSeparator}
                                            onChange={onChangeCsvSeparator}
                                            className="atl-block"
                                        >
                                            <Option value=";">
                                                {getMessage(
                                                    'text_csv_separator_semicolon'
                                                )}
                                            </Option>
                                            <Option value=",">
                                                {getMessage(
                                                    'text_csv_separator_comma'
                                                )}
                                            </Option>
                                            <Option value={'\t'}>
                                                {getMessage(
                                                    'text_csv_separator_tab'
                                                )}
                                            </Option>
                                        </Select>
                                    </Group>
                                </div>
                                <div className="atl-flex atl-flex-row atl-gap-16 atl-flex-wrap atl-justify-between">
                                    <Group className="atl-flex-1">
                                        <Label htmlFor="csvDecimal">
                                            {getMessage('text_csv_decimal')}
                                        </Label>
                                        <Select
                                            id="csvDecimal"
                                            data-testid="csv-export-modal__csv-decimal"
                                            value={csvDecimal}
                                            onChange={onChangeCsvDecimal}
                                            className="atl-block"
                                        >
                                            <Option value=".">
                                                {getMessage(
                                                    'text_csv_decimal_dot'
                                                )}
                                            </Option>
                                            <Option value=",">
                                                {getMessage(
                                                    'text_csv_decimal_comma'
                                                )}
                                            </Option>
                                        </Select>
                                    </Group>
                                    <Group className="atl-flex-1">
                                        <Label htmlFor="csvQualifier">
                                            {getMessage('text_csv_qualifier')}
                                        </Label>
                                        <Select
                                            id="csvQualifier"
                                            data-testid="csv-export-modal__csv-qualifier"
                                            value={csvQualifier}
                                            onChange={onChangeCsvQualifier}
                                            className="atl-block"
                                        >
                                            <Option value='"'>
                                                {getMessage(
                                                    'text_csv_qualifier_double'
                                                )}
                                            </Option>
                                            <Option value="'">
                                                {getMessage(
                                                    'text_csv_qualifier_single'
                                                )}
                                            </Option>
                                            <Option value="">
                                                {getMessage(
                                                    'text_csv_qualifier_none'
                                                )}
                                            </Option>
                                        </Select>
                                    </Group>
                                </div>
                                <div className="atl-flex atl-flex-row atl-gap-16 atl-flex-wrap atl-justify-between">
                                    <Group className="atl-flex-1">
                                        <Label htmlFor="csvLineBreak">
                                            {getMessage('text_csv_line_break')}
                                        </Label>
                                        <Select
                                            id="csvLineBreak"
                                            data-testid="csv-export-modal__csv-line-break"
                                            value={csvLineBreak}
                                            onChange={onChangeCsvLineBreak}
                                            className="atl-block"
                                        >
                                            <Option value={'\r\n'}>
                                                Windows
                                            </Option>
                                            <Option value={'\n'}>
                                                Mac/Linux
                                            </Option>
                                        </Select>
                                    </Group>
                                    <div className="atl-flex-1" />
                                </div>
                            </Fieldset>
                        ) : null}
                        <Fieldset className="atl-flex atl-flex-col atl-gap-16">
                            <CheckboxGroup className="atl-pb-4">
                                <Input
                                    id="csvHeader"
                                    type="checkbox"
                                    data-testid="csv-export-modal__csv-header"
                                    checked={csvHeader}
                                    onChange={onChangeCsvHeader}
                                />
                                <Label htmlFor="csvHeader">
                                    {getMessage('text_csv_header')}
                                </Label>
                            </CheckboxGroup>
                        </Fieldset>
                    </div>
                </ModalContent>
                <ModalFooter>
                    <div className="atl-flex atl-gap-8 atl-items-left">
                        <Button
                            data-testid="csv-export-modal__download-button"
                            variant="primary"
                            onClick={onAccept}
                        >
                            {getMessage('text_download_csv')}
                        </Button>
                        <Button
                            data-testid="csv-export-modal__cancel-button"
                            variant="secondary"
                            onClick={onClose}
                        >
                            {getMessage('button_cancel')}
                        </Button>
                    </div>
                </ModalFooter>
            </ModalLayout>
        </Modal>
    );
};
