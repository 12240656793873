import { Option } from '../../types';
import { notUndefined } from '@General/Helpers';
import { ModelAPIV1 } from '@General/types/Api';
import { JSPDropdownFetcher } from '../JSPDropdownFetcher';
import { ApiResultMapper } from '@General/Provider';
import * as React from 'react';

interface TravelReportRateCategoryEntry extends ModelAPIV1 {
    readonly displayName: string;
}

const TravelReportRateCategoryMapper: ApiResultMapper<TravelReportRateCategoryEntry> =
    (item) => {
        return {
            value: item.id + '',
            displayName: item.displayName,
            item,
        };
    };

class TravelReportRateCategoryFetcher extends JSPDropdownFetcher<TravelReportRateCategoryEntry> {
    asyncJsonrpcGetter: Function;

    constructor(scope: HTMLDivElement, queryAttributes: Record<string, any>) {
        super(scope, queryAttributes, TravelReportRateCategoryMapper);

        this.asyncJsonrpcGetter = asyncrpc.TravelReportRateCategory.get;
    }

    getParams(): ObjectIndex {
        return {
            groupId: this.getQueryAttribute('groupElementId', -1),
            rateType: this.getQueryAttribute('rateType', -1),
            isDayTrip: this.getQueryAttribute('isDayTripElementId', -1),
        };
    }

    async fetchList(
        params: ObjectIndex
    ): Promise<Option<TravelReportRateCategoryEntry>[]> {
        return new Promise((resolve, reject) => {
            try {
                jsonrpc.TravelReportRateCategory.searchForRates(
                    (result: TravelReportRateCategoryEntry[] | null) => {
                        const mapped = (result ?? [])
                            .filter(notUndefined)
                            .map(this.getMapper(params))
                            .filter(notUndefined);
                        resolve(mapped);
                    },
                    this.getMarshallSpec(),
                    params.groupId,
                    params.rateType,
                    params.isDayTrip,
                    params.name
                );
            } catch (e) {
                defaultJsonRpcExceptionHandler(e);
                reject(e);
            }
        });
    }
}

export const exports = {
    fetcher: TravelReportRateCategoryFetcher,
    type: 'TravelReportRateCategory',
};
