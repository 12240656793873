import jQuery from 'jquery';
const $ = jQuery;

/**
 * tlx.tlxLoader:
 * Element: Container that is "loaded".
 */

$.widget('tlx.tlxLoader', {
    options: {
        showOverlay: false,
        /* But constrained by container height (90px is good for tables with toolbar) */
        loaderPositionY: 90,
        styleClass: 'legacy',
        fader: false,
    },
    _create: function () {
        let spinnerHtml =
            '<div class="mdl-spinner mdl-js-spinner is-active lazyloader"></div>';
        if (window.location.href.includes('updateProjectStatus')) {
            // Making sure that the spinner appears in the middle of the main content
            // window (framelessContent), because the table on this page can get so
            // wide that the spinner would otherwise not be visible
            spinnerHtml =
                '<div style="position: absolute; width: 100%;">' +
                spinnerHtml +
                '</div>';
        }
        this.loader = { $loaderElement: $(spinnerHtml) };
        this.$container = $(this.element);
    },
    _destroy: function () {
        this.loader.$loaderElement.remove();
    },
    start: function () {
        componentHandler.upgradeElements(this.loader.$loaderElement[0]);
        this.$container.addClass('lazyLoad--inProgress');
        this.$container.removeClass('lazyLoad--done');

        this.loader.$loaderElement.insertBefore(this.$container);
    },
    stop: function () {
        this.$container.removeClass('lazyLoad--inProgress');
        this.$container.addClass('lazyLoad--done');
        this.loader.$loaderElement.remove();
    },
});
