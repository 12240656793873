import { Alert, AlertContent, AlertDescription, AlertHeader } from '@tlx/atlas';
import { formatNumber } from '../../../PurchaseOrder/utils';

export function ChangePriceWarningSection({
    affectedProducts,
    editPrice,
}: {
    affectedProducts: number;
    editPrice: { type: string; value: string };
}) {
    return (
        <Alert variant="warning">
            <AlertContent>
                <AlertHeader as={'h3'}>
                    {getMessage(
                        'text_purchase_order_change_status_warning_title'
                    )}
                </AlertHeader>
                <AlertDescription>
                    {getMessage(
                        'text_product_overview_change_price_warning',
                        editPrice.type === 'mainSupplier'
                            ? getMessage('text_update').toLowerCase()
                            : Number(editPrice.value) > 0
                            ? getMessage('text_increase').toLowerCase()
                            : getMessage('text_decrease').toLowerCase(),
                        affectedProducts,
                        editPrice.type === 'percent'
                            ? formatNumber(Number(editPrice.value))
                            : editPrice.type === 'mainSupplier'
                            ? `${getMessage(
                                  'text_standard'
                              ).toLowerCase()} ${getMessage(
                                  'text_supplier'
                              ).toLowerCase()}`
                            : editPrice.type === 'percent'
                            ? Math.abs(Number(editPrice.value))
                            : 'NOK',
                        editPrice.type === 'mainSupplier'
                            ? ''
                            : editPrice.type === 'percent'
                            ? '%'
                            : formatNumber(Number(editPrice.value))
                    )}
                </AlertDescription>
            </AlertContent>
        </Alert>
    );
}
