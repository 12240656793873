import { ModelAPIV1 } from '@General/types/Api';
import { Option } from '../../types';
import { JSPDropdownFetcher } from '../JSPDropdownFetcher';
import { ApiResultMapperNoUndefinedResult } from '@General/Provider';

interface OrderOutEntry extends ModelAPIV1 {
    readonly number: string;
}

const orderOutMapper: ApiResultMapperNoUndefinedResult<OrderOutEntry> = (
    item
) => ({
    value: item.id + '',
    displayName: item.number,
    item,
});

class OrderOutFetcher extends JSPDropdownFetcher<OrderOutEntry> {
    asyncJsonrpcGetter: Function;

    constructor(scope: HTMLDivElement, queryAttributes: Record<string, any>) {
        super(scope, queryAttributes, orderOutMapper);
        this.asyncJsonrpcGetter = asyncrpc.OrderOut.get;
    }

    getMarshallSpec() {
        return window.marshallSpec('id', 'number');
    }

    getParams(): ObjectIndex {
        return {
            vendorId: this.getQueryAttribute('vendorElementId', -1),
        };
    }

    async fetchList(params: ObjectIndex): Promise<Option<OrderOutEntry>[]> {
        return new Promise((resolve, reject) => {
            try {
                return jsonrpc.OrderOut.query(
                    (result: OrderOutEntry[] | null) =>
                        resolve((result ?? []).map(orderOutMapper)),
                    this.getMarshallSpec(),
                    params.name || '',
                    params.vendorId
                );
            } catch (e) {
                defaultJsonRpcExceptionHandler(e);
                reject(e);
            }
        });
    }
}

export const exports = {
    fetcher: OrderOutFetcher,
    type: 'OrderOut',
    link: (id: number, contextId: number) =>
        `/execute/purchaseOrderMenu?orderOutId=${id}&contextId=${contextId}`,
};
