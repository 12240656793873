import React from 'react';

import { PageLoader } from '@Component/PageLoader';
import { renderComponent } from '@General/renderComponent';

const Page: React.FC = (props) => (
    <PageLoader<{}>
        loader={async () =>
            import('./VatReturnsInternalFetcherPage').then((m) => m.page)
        }
        props={props}
    />
);

export function renderVatReturnsInternalXmlFetcher(domId: string) {
    renderComponent(Page, domId);
}
