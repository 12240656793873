import * as React from 'react';
import { MaterialIcon } from '@Component/MaterialIcon';

import './EmptyState.scss';

export enum EmptyStateIconSize {
    DEFAULT = 'default',
    SMALL = 'small',
    TINY = 'tiny',
}

type Props = {
    icon?: string;
    imageSource?: string;
    title?: React.ReactNode | string;
    text?: React.ReactNode | string;
    iconSize?: EmptyStateIconSize;
    isUseIconBackground?: boolean;
    children?: React.ReactNode;
};

const EmptyState = (props: Props) => {
    return (
        <div className="txr-empty-state">
            {props.imageSource ? (
                <img
                    className={`txr-empty-state-icon txr-empty-state-icon--${
                        props.iconSize ? props.iconSize : 'default'
                    }`}
                    src={props.imageSource}
                />
            ) : props.icon ? (
                <div
                    className={`txr-empty-state-icon txr-empty-state-icon--${
                        props.iconSize ? props.iconSize : 'default'
                    }${
                        props.isUseIconBackground !== false
                            ? ' txr-empty-state-icon--with-bg'
                            : ' txr-empty-state-icon--without-bg'
                    }`}
                >
                    <MaterialIcon>{props.icon}</MaterialIcon>
                </div>
            ) : null}
            <div
                className={`txr-empty-state__title txr-empty-state__title--${
                    props.iconSize ? props.iconSize : 'default'
                }`}
            >
                {props.title ? props.title : ''}
            </div>
            {props.text ? (
                <div className="txr-empty-state__text">{props.text}</div>
            ) : null}
            {props.children ? (
                <div className="txr-empty-state__action">{props.children}</div>
            ) : null}
        </div>
    );
};

export default EmptyState;
