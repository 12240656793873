import $ from 'jquery';

/**
 * Created by bruce on 29/06/16.
 */
window.controlSchema = (function () {
    return {
        jaNeiIkkeAkt: function jaNeiIkkeAkt(scope) {
            // Make jaNeiIkkeAkt un-selectable.
            $('input[type=radio][name$=jaNeiIkkeAkt]', scope).click(function (
                e
            ) {
                const $target = $(e.target);
                const name = $target.attr('name');

                if (!$target.data('checked')) {
                    $('input:prop(' + name + ')', scope).data('checked', false);
                    $target.data('checked', true);
                    return;
                }

                $('input:prop(' + name + ')', scope).data('checked', false);
                $('input[value=0]:prop(' + name + ')', scope).prop(
                    'checked',
                    true
                );
            });
        },

        onchangeIsAnleggsdel: function onchangeIsAnleggsdel(scope) {
            $('input[name$=isAnleggsdel]', scope).change(function () {
                const $anleggsdel = $('input[name$=\\.anleggsdel]', scope);
                if ($(this).val() === 'false') {
                    $anleggsdel.val('').prop('disabled', true);
                } else {
                    $anleggsdel.prop('disabled', false);
                }
            });
        },
    };
})();
