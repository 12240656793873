export function getColumnLabel(column: string): string {
    switch (column) {
        case 'status':
            return getMessage('text_status');
        case 'supplier':
            return getMessage('text_supplier');
        case 'supplierInvoice':
            return getMessage('text_supplier_invoice');
        case 'projectOrder':
            return moduleProject
                ? getMessage('text_project_or_order')
                : getMessage('text_order');
        case 'sentDate':
            return getMessage('text_sent_date');
        case 'deliveryDate':
            return getMessage('text_delivery_date');
        case 'contact':
            return getMessage('text_contact');
        case 'totalAmount':
            return getMessage('text_total_amount');
        case 'currency':
            return getMessage('text_currency');
        case 'internalComment':
            return getMessage('text_remark_description_label');
        case 'orderLines':
            return (
                getMessage('text_order_lines') +
                ' ' +
                getMessage('text_export_only')
            );
        default:
            throw new Error(`Unknown column: ${column}`);
    }
}

export const availableColumnsInPurchaseOrderOverview = [
    'status',
    'supplier',
    'supplierInvoice',
    'projectOrder',
    'sentDate',
    'deliveryDate',
    'contact',
    'totalAmount',
    'currency',
    'internalComment',
    'orderLines',
];

export const defaultColumnsInPurchaseOrderOverview = [
    'status',
    'supplier',
    'supplierInvoice',
    'projectOrder',
    'sentDate',
    'deliveryDate',
    'contact',
    'totalAmount',
    'currency',
];

export const columnToField: { [key: string]: string } = {
    supplier: 'supplier(id, supplierNumber, name)',
    projectOrder: 'project(id, displayName)',
    contact:
        'ourContact(id, displayName), supplierContact(id, displayName), attention(id, displayName)',
    deliveryDate: 'deliveryDate',
    currency: 'currency(id, code)',
    sentDate: 'lastSentTimestamp',
    internalComment: 'internalComment',
};
