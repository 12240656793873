import React from 'react';
import { Button, EditIcon, SendIcon } from '@tlx/atlas';

interface UserFeedbackSendCancelProps {
    userWantsToGiveFeedback: boolean;
    setUserWantsToGiveFeedback: React.Dispatch<React.SetStateAction<boolean>>;
    isSendButtonVisible: boolean;
    sendFeedback: (input: string) => void;
    resetFeedbackBox: () => void;
    closeModal: () => void;
    input: string;
}

export function KycFeedbackBoxActionButtons({
    userWantsToGiveFeedback,
    setUserWantsToGiveFeedback,
    isSendButtonVisible,
    sendFeedback,
    resetFeedbackBox,
    closeModal,
    input,
}: UserFeedbackSendCancelProps) {
    return (
        <div className="atl-flex">
            {isSendButtonVisible ? (
                <Button
                    data-testid="kyc-feedback"
                    data-trackingid="text_send_kyc_feedback"
                    onClick={() => {
                        resetFeedbackBox();
                        void sendFeedback(input);
                    }}
                >
                    {getMessage('text_send')}
                    <SendIcon />
                </Button>
            ) : (
                <Button
                    className="atl-mr-8"
                    data-testid="cancel"
                    data-trackingid="text_close_modal"
                    onClick={() => {
                        closeModal();
                    }}
                >
                    {getMessage('close_account_button_continue_done')}
                </Button>
            )}

            {!userWantsToGiveFeedback && (
                <Button
                    variant="secondary"
                    data-testid="kyc-give-feedback"
                    data-trackingid="text_give_kyc_feedback"
                    onClick={() => setUserWantsToGiveFeedback(true)}
                >
                    {getMessage('text_send_feedback')}
                    <EditIcon />
                </Button>
            )}
        </div>
    );
}
