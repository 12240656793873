import { ModelAPIV1 } from '@General/types/Api';
import { CreateButtonComponent, Option } from '../../types';
import { notUndefined } from '@General/Helpers';
import { JSPDropdownFetcher } from '../JSPDropdownFetcher';
import { ApiResultMapper } from '@General/Provider';
import { TlxListItem } from '@Component/DropDown/DropDown';
import * as React from 'react';
import { createCreateNewButton } from '@Component/DropDown/CreateNewButton/CreateNewButton';

interface EmployeeEntry extends ModelAPIV1 {
    readonly displayName: string;
    readonly nameAndNumber: string;
    readonly customerName: string;
    readonly isExternal: boolean;
}

type EmployeeItem = {
    isExternal: boolean;
    customerName: string;
};

const employeeMapper: ApiResultMapper<EmployeeEntry> = (item) => ({
    value: item.id + '',
    displayName: item.nameAndNumber,
    secondary: item.customerName, // Used by the default ListItem in DesktopPopup
    item: item,
});

const EmployeeDesktopListItemExternal: TlxListItem<EmployeeItem> = (props) => {
    return (
        <div className="txr-dropdown__list-item">
            <div className="txr-dropdown__list-item__text">
                {props.option.displayName}
                {props.option.item &&
                    props.option.item.isExternal &&
                    ' (' + getMessage('text_contact') + ')'}
            </div>
        </div>
    );
};

export class EmployeeFetcher extends JSPDropdownFetcher<EmployeeEntry> {
    asyncJsonrpcGetter: Function;

    constructor(scope: HTMLDivElement, queryAttributes: Record<string, any>) {
        super(scope, queryAttributes, employeeMapper);
        this.asyncJsonrpcGetter = asyncrpc.Employee.get;
    }

    getDesktopListItem(): TlxListItem<EmployeeItem> | void {
        const isInternal = this.getQueryAttribute('isExternal', -1);

        // We only need a special ListItem component for employee when we are showing if the person is external (a contact)
        if (parseInt(isInternal, 10) === -1) {
            return EmployeeDesktopListItemExternal;
        }
    }

    getMobileListItem(): TlxListItem<EmployeeItem> | void {
        return this.getDesktopListItem();
    }

    getMarshallSpec() {
        const isInternal = this.getQueryAttribute('isExternal', -1);

        if (parseInt(isInternal, 10) === -1) {
            return window.marshallSpec(
                'id',
                'number',
                'displayName',
                'nameAndNumber',
                'isExternal'
            );
        }
        const includeFreeContacts = this.getQueryAttribute(
            'includeFreeContactsVal',
            false
        );

        if (includeFreeContacts) {
            return window.marshallSpec(
                'id',
                'number',
                'displayName',
                'nameAndNumber',
                'customerName'
            );
        }

        return window.marshallSpec(
            'id',
            'number',
            'displayName',
            'nameAndNumber'
        );
    }

    getParams(): ObjectIndex {
        const { startDate, endDate } = this.getDates();

        return {
            departmentId: this.getQueryAttribute('departmentElementId', -1),
            customerId: this.getQueryAttribute('customerElementId', -1),
            employeeCategoryId: this.getQueryAttribute(
                'employeeCategoryElementId',
                -1
            ),
            isExternal: this.getQueryAttribute('isExternal', -1), // -1 means: We dont care if it is external or not
            isProxy: this.getQueryAttribute('isProxy', 0), // default is 0 ie. exclude proxy employees. [KK 2014-07-03]
            isRobot: this.getQueryAttribute('isRobot', 0), // default is 0 ie. exclude robot employees. [Kristoffer, 2016-08-16]
            wageReceiversOnly: this.getQueryAttribute(
                'wageReceiversOnly',
                false
            ),
            includeFreeContacts: this.getQueryAttribute(
                'includeFreeContacts',
                false
            ),
            allowLoginOnly: this.getQueryAttribute('allowLoginOnly', false),
            allowRegInfoOnly: this.getQueryAttribute('allowRegInfoOnly', true),
            startDate,
            endDate,
            withAuth: this.getQueryAttribute('withAuth', -1),
            pensionReportEmploymentsOnly: this.getQueryAttribute(
                'pensionReportEmploymentsOnly',
                false
            ),
            onlyProjectManagers: this.getQueryAttribute(
                'onlyProjectManagers',
                false
            ),
        };
    }

    async fetchList(params: ObjectIndex): Promise<Option<EmployeeEntry>[]> {
        return new Promise((resolve, reject) => {
            try {
                jsonrpc.Employee.searchForEmployees(
                    (result: EmployeeEntry[] | null) => {
                        const mapped = (result ?? [])
                            .filter(notUndefined)
                            .map(this.getMapper(params))
                            .filter(notUndefined);
                        resolve(mapped);
                    },
                    this.getMarshallSpec(),
                    window.loginCompanyId,
                    params.departmentId,
                    params.customerId,
                    params.employeeCategoryId,
                    params.isExternal,
                    params.isProxy,
                    params.isRobot,
                    params.wageReceiversOnly,
                    params.includeFreeContacts,
                    params.allowLoginOnly,
                    params.allowRegInfoOnly,
                    params.startDate,
                    params.endDate,
                    params.withAuth,
                    params.pensionReportEmploymentsOnly,
                    params.onlyProjectManagers,
                    params.name,
                    this.tlxSelectMaxRows,
                    0
                );
            } catch (e) {
                defaultJsonRpcExceptionHandler(e);
                reject(e);
            }
        });
    }

    createCreateNewButton(
        setSelected: (id: number) => void,
        dropdownTrackingId?: string
    ): CreateButtonComponent | undefined {
        const dontShowPlusButton = this.getQueryAttribute(
            'dontShowPlusButton',
            false
        );

        if (dontShowPlusButton === 'true') {
            return undefined;
        }

        const isExternal = this.getQueryAttribute('isExternal', -1) === 1;

        // Check if user is authorized to create employee or contact
        if (isExternal) {
            if (!isAuth.createContact) {
                return undefined;
            }
        } else {
            if (!isAuth.createEmployee) {
                return undefined;
            }
        }

        const isWageReceiver = this.getQueryAttribute(
            'wageReceiversOnly',
            false
        );
        const customerId = this.getQueryAttribute('customerElementId', -1);
        const departmentId = this.getQueryAttribute('departmentElementId', 0);

        let createNewUrl = `createEmployee?isExternal=${isExternal}&isWageReceiver=${isWageReceiver}`;

        if (customerId !== -1) {
            createNewUrl +=
                '&customerId=' + customerId + '&lockCustomerId=true';
        }

        if (departmentId > 0) {
            createNewUrl += '&departmentid=' + departmentId;
        }

        const label = isExternal ? 'text_new_contact' : 'submenu_new_employee';

        return createCreateNewButton(
            setSelected,
            label,
            createNewUrl,
            dropdownTrackingId
                ? getLocaleMessage('en_GB', label) + ' - ' + dropdownTrackingId
                : ''
        );
    }
}

export const exports = {
    fetcher: EmployeeFetcher,
    type: 'Employee',
    link: (id: number, contextId: number) =>
        `/execute/employeeMenu?employeeId=${id}&contextId=${contextId}`,
};
