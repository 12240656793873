import jQuery from 'jquery';

import { tlxForms } from '../../js/modules/forms';

const $ = jQuery;

let logError = window.logError || console.error;

/**
 * Base toolbar widget. Don't use this directly.
 * Basically creates buttons from .toolbarActions and creates a span with class 'toolbar'
 */
$.widget('tlx.toolbar', {
    // default options
    options: {
        buttons: [],
        lockedCallback: $.noop,
        lockButtons: false, // Locks buttons with data-attribute lockable
        onTabsActivate: false, // Creates mainToolbar only on tlxTabsActivate. Prevents a create->destroy->create. For performance.
        onlyUseOptionButtons: false, // If true, only the buttons in the options will be displayed
        deleteConfirmTrackingId: '',
        deleteCancelTrackingId: '',
        deletePermanentlyConfirmTrackingId: '',
        deletePermanentlyCancelTrackingId: '',
        saveBeforeOtherActionsOKButtonTrackingId: '',
    },
    _create: function () {
        if (this.options.onTabsActivate) return;
        this.element.addClass('tlx-toolbar-attached');
        this.toolbar = $('<span/>', {
            class: 'tlx-toolbar',
        });
        this._createButtons(this.options.buttons);
    },
    _createButtons: function (optionButtons) {
        const that = this;

        // make sure to clean up any potential leftovers from other toolbars
        that.toolbar.empty();
        $('.tlx-toolbar:not(".tlx-toolbar-table")').remove();

        // TODO: If onlyUseOptionButtons is true, the table will ignore any tlx:toolbarAction elements.
        //       This will be reverted in TRIP-39855
        if (!that.options.onlyUseOptionButtons) {
            $('.toolbarAction', this.element.closest('.tlxScope')).each(
                function () {
                    const $this = $(this);

                    let toolbarActionExistsInOptions = false;
                    for (const optionButton of optionButtons) {
                        if (
                            optionButton.dialogAction &&
                            optionButton.dialogAction === $this.data('action')
                        ) {
                            toolbarActionExistsInOptions = true;
                        }
                    }

                    if (
                        !toolbarActionExistsInOptions &&
                        (!$this.data('action') ||
                            !$this
                                .data('action')
                                .toLowerCase()
                                .includes('dynamic'))
                    ) {
                        const isDialog =
                            $this.children().length > 0 ||
                            $.trim($this.html()).length > 0;
                        that.isDialog = isDialog;
                        const action = $this.data('action');
                        // TODO TRIP-8061: implicit scope here? Would avoid jsAction="tlxGetScope(this)...." everywhere. (tlxGetScope(this)[$this.data("js-action")])
                        var actionCallback = eval($this.data('js-action'));
                        if (
                            logError &&
                            !actionCallback &&
                            $this.data('js-action')
                        ) {
                            logError(
                                'Function in js-action not found',
                                'Did you initialize the toolbar before defining the scoped function? (Also remember that jsAction specifies a function, not an arbitrary eval)',
                                $this.data()
                            );
                        }
                        if (!actionCallback && action) {
                            var actionOptions = {
                                infoMessage: $this.data('toolbar-button-text'),
                                method: $this.attr('method'),
                                clientsideValidation: $this.data(
                                    'clientside-validation'
                                ),
                                withWarning: $this.data('with-warning'),
                                warningKey: $this.attr('withWarningKey'),
                            };
                            let confirmDialogOKButtonTrackingId = '';
                            let confirmDialogCancelButtonTrackingId = '';
                            if (
                                action
                                    .toLowerCase()
                                    .includes('deletePermanently'.toLowerCase())
                            ) {
                                confirmDialogOKButtonTrackingId =
                                    that.options
                                        .deletePermanentlyConfirmTrackingId;
                                confirmDialogCancelButtonTrackingId =
                                    that.options
                                        .deletePermanentlyCancelTrackingId;
                            } else if (
                                action.toLowerCase().includes('delete')
                            ) {
                                confirmDialogOKButtonTrackingId =
                                    that.options.deleteConfirmTrackingId;
                                confirmDialogCancelButtonTrackingId =
                                    that.options.deleteCancelTrackingId;
                            }
                            actionCallback =
                                window.toolbarActionCallbackWithTrackingId(
                                    action,
                                    actionOptions,
                                    confirmDialogOKButtonTrackingId,
                                    confirmDialogCancelButtonTrackingId
                                );
                        }
                        if (
                            !actionCallback &&
                            !isDialog &&
                            !$this.data('toolbar-button-id') &&
                            !$this.data('toolbar-button-class') &&
                            logError &&
                            $this.attr('isDirectUpload') !== 'true'
                        ) {
                            // If toolbar button id or class is set, the button might be used in some special way. (eg. voucher2.jsp add customer)
                            logError('Empty toolbarAction! ', '', $this.data());
                            if (!window.productionMode)
                                alert(
                                    'Empty toolbarAction! (validation dialog without content?) ' +
                                        $this.data('toolbar-button-text')
                                );
                        }
                        that.actionCallback = actionCallback;

                        // TODO TRIP-8061
                        var validation = eval($this.attr('jsValidation'));
                        var buttonId = $this.data('toolbar-button-id');
                        var disableValidate = $this.data('disable-validate');

                        var buttonProps = {
                            text: $this.data('toolbar-button-text'),
                            title: $this.data('toolbar-button-title-text'),
                            disableValidate: isDialog ? true : disableValidate,
                            data: {
                                lockable: $this.data('lockable'),
                                testid: $this.data('testid'),
                                trackingid: $this.data('trackingid'),
                                action,
                            },
                            validation: isDialog ? undefined : validation,
                            click: isDialog ? undefined : actionCallback,
                            id: buttonId,
                            disabled: $this.attr('isDisabled') === 'true',
                            tooltip: $this.data('tooltip'),
                            class: $this.data('toolbar-button-class'),
                            exposed: $this.attr('exposed') === 'true',
                            requireSelectedElements: !disableValidate,
                        };
                        var $button = initButton(buttonProps);

                        // if the tag has content the action has a dialog
                        if (isDialog) {
                            $button.popupOpener({
                                buttonText: $this.data('dialog-button-text'),
                                hideClickButton: function () {
                                    return that._hideClickButtonFunc.call(
                                        that,
                                        disableValidate
                                    );
                                },
                                showExtraInfo: function () {
                                    return that._hideClickButtonFunc.call(
                                        that,
                                        disableValidate
                                    );
                                },
                                dialogContent: $this,
                                title: $this.data('title-text'),
                                closeText: $this.attr('closeKey')
                                    ? getMessage($this.attr('closeKey'))
                                    : $this.data('close-text'),
                                click: actionCallback,
                                clickValidation: validation,
                                clientsideValidation: $this.attr(
                                    'dialogClientsideValidation'
                                ),
                                mainButtonClasses: $this.attr(
                                    'dialogMainButtonClasses'
                                ),
                                disableMainButton: $this.attr(
                                    'dialogMainButtonDisabled'
                                ),
                                buttonTestId: $this.attr(
                                    'data-dialog-main-button-testid'
                                ),
                                buttonTrackingId: $this.attr(
                                    'data-dialog-main-button-trackingid'
                                ),
                                closeTestId: $this.attr(
                                    'data-dialog-close-button-testid'
                                ),
                                closeTrackingId: $this.attr(
                                    'data-dialog-close-button-trackingid'
                                ),
                                formTestId: $this.attr('data-dialog-testid'),
                                formTrackingId: $this.attr(
                                    'data-dialog-trackingid'
                                ),
                            });
                        }

                        var onopen = eval($this.attr('jsOnOpen')); // TODO: TRIP-8061
                        $this.bind('dialogopen', onopen);
                        if ($this.attr('isDirectUpload') === 'true') {
                            $button.directUpload({
                                form: that.element,
                            });
                        }

                        // Remove possibly conflicting attributes from buttons
                        $this.removeAttr(
                            'data-testid data-toolbar-action id name'
                        );
                    }
                }
            );
        }
        $.each(optionButtons, function (_, props) {
            // TODO: If the option button has a dialog action, it means that it will replace a tlx:toolbarAction button,
            //       so instead of initializing the tlx:toolbarAction button, it will instead create a new one.
            //       This will be reverted in TRIP-39855
            if (props.dialogAction) {
                const $toolbarActionElement = $(
                    '[data-action=' + props.dialogAction + ']'
                );
                const actionCallback = eval(
                    $toolbarActionElement.data('js-action')
                );
                that._createButton(
                    $toolbarActionElement,
                    true,
                    actionCallback,
                    props
                );
            } else {
                initButton(props);
            }
        });
        if ($.fn.button) {
            that.toolbar.buttonset();
        }

        function initButton(props) {
            var customValidation = props.validation;
            delete props.validation; // hackish.. avoid method to be called by button attr initialization below

            var $button = $('<button type="button"></button>')
                .attr(
                    'formId',
                    that.element.attr('id') ||
                        that.element.closest('form').attr('id')
                )
                .click(function (event) {
                    if (
                        that.options.lockButtons &&
                        $(event.currentTarget).data('lockable')
                    ) {
                        that.options.lockedCallback(
                            that.options
                                .saveBeforeOtherActionsOKButtonTrackingId
                        );
                        event.stopImmediatePropagation();
                        return;
                    }
                    // if this is a "dialog action" props.click will be empty, and a separate click handler will open the dialog if the validation is ok
                    if (that._buttonClickValidation(props, customValidation)) {
                        if (props.click) {
                            props.click.apply(that.element[0], arguments);
                        }
                    } else {
                        // Prevents other click handlers from being called. Typically popupOpener
                        event.stopImmediatePropagation();
                    }
                    // Was added (I think) to prevent the click event (bound on document) to close the possible opened infoPopup/validationPopup...
                    // That is now handled by the infoPopup/validationPopup widget, so it should be safe to remove this (2012-06-0
                    event.stopPropagation();
                })
                .appendTo(that.toolbar);

            // can't use .attr( props, true ) with jQuery 1.3.2.
            $.each(props, function (key, value) {
                if (value === undefined || value === null) {
                    return;
                } else if (key === 'click') {
                    return;
                } else if (key === 'text') {
                    $button[key](value);
                    return;
                } else if (key === 'tooltip') {
                    $button.attr('title', value);
                } else if (key === 'class') {
                    $button.addClass(value);
                } else if (key === 'data') {
                    $button.data(value);
                } else {
                    $button.attr(key, value);
                }
            });

            return $button;
        }
    },
    // TODO: Will be removed in TRIP-39855
    _createButton: function (
        $toolbarActionElement,
        isDialog,
        actionCallback,
        buttonProps
    ) {
        const that = this;
        const action = $toolbarActionElement.data('action');

        if (
            logError &&
            !actionCallback &&
            $toolbarActionElement.data('js-action')
        ) {
            logError(
                'Function in js-action not found',
                'Did you initialize the toolbar before defining the scoped function? (Also remember that jsAction specifies a function, not an arbitrary eval)',
                $toolbarActionElement.data()
            );
        }
        if (!actionCallback && action) {
            const actionOptions = {
                infoMessage: $toolbarActionElement.data('toolbar-button-text'),
                method: $toolbarActionElement.attr('method'),
                clientsideValidation: $toolbarActionElement.data(
                    'clientside-validation'
                ),
                withWarning: $toolbarActionElement.data('with-warning'),
                warningKey: $toolbarActionElement.attr('withWarningKey'),
            };
            let confirmDialogOKButtonTrackingId = '';
            let confirmDialogCancelButtonTrackingId = '';
            if (
                action.toLowerCase().includes('deletePermanently'.toLowerCase())
            ) {
                confirmDialogOKButtonTrackingId =
                    that.options.deletePermanentlyConfirmTrackingId;
                confirmDialogCancelButtonTrackingId =
                    that.options.deletePermanentlyCancelTrackingId;
            } else if (action.toLowerCase().includes('delete')) {
                confirmDialogOKButtonTrackingId =
                    that.options.deleteConfirmTrackingId;
                confirmDialogCancelButtonTrackingId =
                    that.options.deleteCancelTrackingId;
            }
            actionCallback = window.toolbarActionCallbackWithTrackingId(
                action,
                actionOptions,
                confirmDialogOKButtonTrackingId,
                confirmDialogCancelButtonTrackingId
            );
        }
        if (
            !actionCallback &&
            !isDialog &&
            !$toolbarActionElement.data('toolbar-button-id') &&
            !$toolbarActionElement.data('toolbar-button-class') &&
            logError &&
            $toolbarActionElement.attr('isDirectUpload') !== 'true'
        ) {
            // If toolbar button id or class is set, the button might be used in some special way. (e.g. voucher2.jsp add customer)
            logError('Empty toolbarAction! ', '', $toolbarActionElement.data());
            if (!window.productionMode)
                alert(
                    'Empty toolbarAction! (validation dialog without content?) ' +
                        $toolbarActionElement.data('toolbar-button-text')
                );
        }
        that.actionCallback = actionCallback;

        // TODO TRIP-8061
        const validation = eval($toolbarActionElement.attr('jsValidation'));
        const buttonId = $toolbarActionElement.data('toolbar-button-id');
        const disableValidate = $toolbarActionElement.data('disable-validate');

        if (!buttonProps) {
            buttonProps = {
                text: $toolbarActionElement.data('toolbar-button-text'),
                title: $toolbarActionElement.data('toolbar-button-title-text'),
                disableValidate: isDialog ? true : disableValidate,
                data: {
                    lockable: $toolbarActionElement.data('lockable'),
                    testid: $toolbarActionElement.data('testid'),
                    trackingid: $toolbarActionElement.data('trackingid'),
                    action,
                },
                validation: isDialog ? undefined : validation,
                click: isDialog ? undefined : actionCallback,
                id: buttonId,
                disabled: $toolbarActionElement.attr('isDisabled') === 'true',
                tooltip: $toolbarActionElement.data('tooltip'),
                class: $toolbarActionElement.data('toolbar-button-class'),
                exposed: $toolbarActionElement.attr('exposed') === 'true',
                requireSelectedElements: !disableValidate,
            };
        }

        const $button = that._initButton(buttonProps);

        // if the tag has content, the action has a dialog
        if (isDialog) {
            $button.popupOpener({
                buttonText: $toolbarActionElement.data('dialog-button-text'),
                hideClickButton: function () {
                    return that._hideClickButtonFunc.call(
                        that,
                        disableValidate
                    );
                },
                showExtraInfo: function () {
                    return that._hideClickButtonFunc.call(
                        that,
                        disableValidate
                    );
                },
                dialogContent: $toolbarActionElement,
                title: $toolbarActionElement.data('title-text'),
                closeText: $toolbarActionElement.attr('closeKey')
                    ? getMessage($toolbarActionElement.attr('closeKey'))
                    : $toolbarActionElement.data('close-text'),
                click: actionCallback,
                clickValidation: validation,
                clientsideValidation: $toolbarActionElement.attr(
                    'dialogClientsideValidation'
                ),
                mainButtonClasses: $toolbarActionElement.attr(
                    'dialogMainButtonClasses'
                ),
                disableMainButton: $toolbarActionElement.attr(
                    'dialogMainButtonDisabled'
                ),
                buttonTestId: $toolbarActionElement.attr(
                    'data-dialog-main-button-testid'
                ),
                buttonTrackingId: $toolbarActionElement.attr(
                    'data-dialog-main-button-trackingid'
                ),
                closeTestId: $toolbarActionElement.attr(
                    'data-dialog-close-button-testid'
                ),
                closeTrackingId: $toolbarActionElement.attr(
                    'data-dialog-close-button-trackingid'
                ),
                formTestId: $toolbarActionElement.attr('data-dialog-testid'),
                formTrackingId: $toolbarActionElement.attr(
                    'data-dialog-trackingid'
                ),
            });
        }

        const onopen = eval($toolbarActionElement.attr('jsOnOpen')); // TODO: TRIP-8061
        $toolbarActionElement.bind('dialogopen', onopen);
        if ($toolbarActionElement.attr('isDirectUpload') === 'true') {
            $button.directUpload({
                form: that.element,
            });
        }

        // Remove possibly conflicting attributes from buttons
        $toolbarActionElement.removeAttr(
            'data-testid data-toolbar-action id name'
        );
    },
    // TODO: Will be removed in TRIP-39855
    _initButton: function (props) {
        const that = this;

        const customValidation = props.validation;
        delete props.validation; // Hackish, avoid method to be called by button attr initialization below

        const $button = $('<button type="button"></button>')
            .attr(
                'formId',
                that.element.attr('id') ||
                    that.element.closest('form').attr('id')
            )
            .click(function (event) {
                if (
                    that.options.lockButtons &&
                    $(event.currentTarget).data('lockable')
                ) {
                    that.options.lockedCallback(
                        that.options.saveBeforeOtherActionsOKButtonTrackingId
                    );
                    event.stopImmediatePropagation();
                    return;
                }
                // if this is a "dialog action" props.click will be empty, and a separate click handler will open the dialog if the validation is ok
                if (that._buttonClickValidation(props, customValidation)) {
                    if (props.click) {
                        props.click.apply(that.element[0], arguments);
                    }
                } else {
                    // Prevents other click handlers from being called. Typically popupOpener
                    event.stopImmediatePropagation();
                }
                // Was added (I think) to prevent the click event (bound on document) to close the possible opened infoPopup/validationPopup...
                // That is now handled by the infoPopup/validationPopup widget, so it should be safe to remove this (2012-06-0
                event.stopPropagation();
            })
            .appendTo(that.toolbar);

        // can't use .attr( props, true ) with jQuery 1.3.2.
        $.each(props, function (key, value) {
            if (value === undefined || value === null) {
                return;
            } else if (key === 'click') {
                return;
            } else if (key === 'text') {
                $button[key](value);
                return;
            } else if (key === 'tooltip') {
                $button.attr('title', value);
            } else if (key === 'class') {
                $button.addClass(value);
            } else if (key === 'data') {
                $button.data(value);
            } else {
                $button.attr(key, value);
            }
        });

        return $button;
    },
    _buttonClickValidation: function (buttonProps, customValidationFn) {
        return !(customValidationFn && !customValidationFn.call(this));
    },
    _hideClickButtonFunc: function () {
        return false;
    },
    // events bound via _bind are removed automatically
    // revert other modifications here
    destroy: function () {
        // remove generated elements
        if (!this.options.onTabsActivate) {
            this.toolbar.remove();

            this.element.removeClass('tlx-toolbar-attached');
        }
        this._super();
    },
    // _setOptions is called with a hash of all options that are
    // changing
    // always refresh when changing options
    _setOptions: function () {
        this._superApply(arguments);
    },
    // _setOption is called for each individual option that is
    // changing
    _setOption: function (key, value) {
        this._super(key, value);
        if (key === 'buttons') {
            this._createButtons(this.options.buttons);
        }
    },
    _createMDLListElement: function ($element) {
        const $li = $(
            '<li class="mdl-menu__item tmdl_actionbar__link" tabindex="-1"></li>'
        )
            .text($element.text())
            .click(function () {
                const $a = $element.find('a');
                /*
                 According to MDN, the HTMLElement.click() method is not supported by Android browser, and has just a
                 question mark for support on other mobile browsers. I tested it in webviewer, chrome and firefox on
                 android, and it seems to work just fine.
                 The MDN document also claims it won't initiate navigation on the <a> element, but this looks also to be
                 false.

                 https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/click
                 */
                if ($a.length !== 0) {
                    $a[0].click();
                } else {
                    $element[0].click();
                }
            });

        if ($element.is('.ui-state-disabled, [disabled]')) {
            $li.attr('disabled', '');
        }

        return $($li);
    },
    _createMDLButton: function ($element) {
        const elementId = $element.attr('id');
        const elementClasses = $element.attr('class').split(' '); // string[]
        const $button = $(
            '<button class="mdl-button mdl-button--colored mdl-js-button">' +
                $element.text() +
                '</button>'
        ).click(function () {
            $element.trigger('click');
        });

        if (elementId) {
            $button.attr('id', 'toolbar-' + elementId);
        }
        // the button should have the same classes as the element it represents, except for the default ui-element classes
        elementClasses.forEach(function (elementClass) {
            if (
                !elementClass.includes('ui') &&
                elementClass !== 'tlx-popup-opener'
            ) {
                $button.addClass(elementClass);
            }
        });

        if ($element.is('.storeAction')) {
            $button.addClass('storeAction');
        }
        return $button;
    },
});

/**
 * https://tripletex.atlassian.net/wiki/display/DOC/toolbar
 *
 * 'element' the table that should have the toolbar attached.
 * Places one button outside the dropdown menu, if any toolbarActions are set to be exposed
 * those are placed outside instead. The rest is placed inside the dropdown menu.
 * The tableToolbar in its entirety is made and placed here based on the buttons and toolbar actions
 */
$.widget('tlx.tableToolbar', $.tlx.toolbar, {
    options: {
        attachTo: 'thead td:last',
        requireSelected: true, // actions need selected items (override with disableValidate on tlx:toolbarAction)
        noRowsSelectedCallback: $.noop,
        exposeButtonsOnSmallScreens: false,
        moreOptionsButtonTrackingId: '',
        noRowsSelectedOKButtonTrackingId: '',
    },
    _create: function () {
        this._super();
        if (this.options.onTabsActivate) return;
        this.toolbar.addClass('tlx-toolbar-table');

        this._setupButtons();

        if (this.options.attachTo === null) {
            throw 'tableToolbar: attachTo is missing!';
        } else {
            this.toolbar.appendTo(
                $(this.options.attachTo, this.element).addClass(
                    'ui-widget-header ui-helper-clearfix'
                )
            );
            this._placeTableButtons($(this.toolbar));
        }

        $(window).on(
            'tlxPossibleWidthChange.tableToolbar resize.tableToolbar',
            this._placeTableButtons.bind(window, this.toolbar)
        );

        // TRIP-19166 Long entries in form might require resizes
        this.element.on('change', () => this._placeOwnTableButtons());

        if (!window.narrowScreen) this._placeTableButtons($(this.toolbar));
    },
    _setupButtons: function () {
        const dropdownId =
            'dropdown' + (Math.random() * 9999 + '').split('.')[0];
        const $dropdownButton = $(
            '<button id="' +
                dropdownId +
                '" class="mdl-button mdl-button--colored mdl-button--raised tmdl-actionmenu-button mdl-js-button mdl-button--icon" ' +
                'data-testid="button-more-options" ' +
                'data-trackingid="' +
                this.options.moreOptionsButtonTrackingId +
                '" ' +
                'aria-label="' +
                getMessage('text_more_options') +
                '">' +
                '<i class="material-icons">&#xE5D4;</i>' +
                '</button>'
        );
        const $dropdownList = $(
            '<ul class="mdl-menu tmdl-actionmenu-menu mdl-menu--bottom-right mdl-js-menu" for="' +
                dropdownId +
                '"></ul>'
        );

        const exposeButtons =
            $(window).width() > 480 || this.options.exposeButtonsOnSmallScreens;

        let numberOfExposedButtons = 1;
        if (!exposeButtons) {
            numberOfExposedButtons = 0;
        }

        const $buttons = $(this.toolbar.children());
        const options = this.options;
        // If some buttons are explicitly exposed, don't expose first button!
        $buttons.each(function () {
            if ($(this).attr('exposed') === 'true') {
                numberOfExposedButtons = 0;
            }
            if (options.buttons) {
                for (const optionButton of options.buttons) {
                    if (optionButton.text === this.textContent) {
                        if (optionButton.testId) {
                            $(this).attr('data-testid', optionButton.testId);
                        }
                        if (optionButton.trackingId) {
                            $(this).attr(
                                'data-trackingid',
                                optionButton.trackingId
                            );
                        }
                    }
                }
            }
        });

        const that = this;
        $buttons.each(function () {
            const $this = $(this);
            // If not on mobile, expose button if we are skipping some or if some buttons are explicitly exposed
            if (
                exposeButtons &&
                (numberOfExposedButtons-- > 0 ||
                    $this.attr('exposed') === 'true')
            ) {
                $this.addClass('mdl-button mdl-button--colored mdl-js-button');
                if ($this.data('testid')) {
                    $this.attr('data-testid', $this.data('testid'));
                }
                if ($this.data('trackingid')) {
                    $this.attr('data-trackingid', $this.data('trackingid'));
                }
                if ($this.data('action')) {
                    $this.attr('data-toolbar-action', $this.data('action'));
                }
                if ($this.is('.storeAction')) {
                    $this.addClass('mdl-button--raised');
                }
            } else {
                $this.hide();
                const $li = $(
                    '<a class="mdl-menu__item table-toolbar__options-item" ' +
                        ($this.data('trackingid')
                            ? 'data-trackingid="' +
                              $this.data('trackingid') +
                              '"'
                            : '') +
                        '>' +
                        $(this).text() +
                        '</a>'
                )
                    .click(function () {
                        $this.trigger('click');
                    })
                    .appendTo($dropdownList);

                if ($this.is('.ui-state-disabled, [disabled]')) {
                    $li.attr('disabled', '');
                }

                if ($this.data('testid')) {
                    $li.attr('data-testid', $this.data('testid'));
                }

                // Needed to make ctrl+s work when store button in the menu
                if ($this.is('.storeAction')) {
                    $li.addClass('storeAction');
                }
            }
        });

        if ($dropdownList[0].childElementCount > 0) {
            that.toolbar.append($dropdownButton).append($dropdownList);
            componentHandler.upgradeElement($dropdownButton[0]);
        }
    },
    _hideClickButtonFunc: function (disableVal) {
        if (disableVal === true) return false;
        return (
            this.options.requireSelected &&
            $(this.element).find('.select input:checked').length === 0
        );
    },
    _placeOwnTableButtons: function () {
        this._placeTableButtons(this.toolbar);
    },
    _placeTableButtons: function ($toolbarTable) {
        // Prevent toolbar buttons to be placed outside of screen
        const margin =
            $toolbarTable.closest('table').width() -
            $('#scrollContainer').width();

        const $floatingHeader = $toolbarTable
            .closest('table')
            .find('.tlxFloatingHeader');

        if (!$floatingHeader.find('.tlx-toolbar').data('noAutoMargin')) {
            if (margin > 0) {
                $toolbarTable.css('margin-right', margin + 16); //16px padding

                if ($floatingHeader) {
                    $floatingHeader
                        .find('.tlx-toolbar-table')
                        .css('margin-right', margin + 16); //16px padding
                }
            } else {
                $toolbarTable.css('margin-right', '0');
                if ($floatingHeader) {
                    $floatingHeader
                        .find('.tlx-toolbar-table')
                        .css('margin-right', 0);
                }
            }
        }
    },
    _buttonClickValidation: function (buttonProps, customValidationFn) {
        if (this.options.requireSelected && !buttonProps.disableValidate) {
            if (!this._requireSelectedValidation()) return false;
        }
        return this._super(buttonProps, customValidationFn);
    },
    _requireSelectedValidation: function () {
        if ($(this.element).find('.select input:checked').length === 0) {
            this.options.noRowsSelectedCallback(
                this.options.noRowsSelectedOKButtonTrackingId
            );
            return false;
        }
        return true;
    },

    _destroy: function () {
        $(window).off('.tableToolbar');
    },
});

/**
 * https://tripletex.atlassian.net/wiki/display/DOC/toolbar
 *
 * 'element' the table that should have the toolbar attached.
 * Places one button outside the dropdown menu, if any toolbarActions are set to be exposed
 * those are placed outside instead. The rest is placed inside the dropdown menu.
 * The tableToolbar in its entirety is made and placed here based on the buttons and toolbar actions
 * DynamicTableToolbar introduces a dynamic header that changes if a table is selected or not.
 * Buttons displayed depend on if the button requires selected elements.
 * Exposed buttons automatically hide when the window width changes to below 480px (mobile)
 * rowTag specifies what tag is considered a row for highlighting.
 * If a button should be available both when items are selected and not selected, create two buttons.
 * We could maybe change this behaviour by using an attribute on toolbaraction in the future.
 */
$.widget('tlx.dynamicTableToolbar', $.tlx.toolbar, {
    options: {
        attachTo: 'thead td:last',
        requireSelected: true, // actions need selected items (override with disableValidate on tlx:toolbarAction)
        noRowsSelectedCallback: $.noop,
        rowTag: 'tr',
    },
    _create: function () {
        this._super();
        this.toolbarIsSelected = false;
        this.toolbar.addClass('tlx-toolbar-table tlx-toolbar-table-dynamic');

        if (this.options.attachTo === null) {
            throw 'tableToolbar: attachTo is missing!';
        } else {
            this.toolbar.appendTo(
                $(this.options.attachTo, this.element).addClass(
                    'ui-widget-header ui-helper-clearfix'
                )
            );
            const containerSelectedId =
                this.options.attachTo.substring(1) + 'ElementsSelected';
            const containerSelected = $(
                this.options.attachTo,
                this.element
            ).clone();
            //Text to be displayed when selecting one or more row.
            this.selectedText = $('<span/>', {
                class: 'tlx-title tlx-selected-text',
            });

            this.selectedTextArray = [
                0,
                getMessage('text_of_lc'),
                0,
                getMessage('text_selected'),
            ];

            //Toolbar for selected rows.
            this.toolbarSelected = $('<span/>', {
                class: 'tlx-toolbar tlx-toolbar-selected tlx-toolbar-table',
            });

            //The X, cancel button. Wrapped in a span with class cancel for styling purposes.
            const tooltipId =
                'tooltip' + (Math.random() * 99999 + '').split('.')[0];
            const $cancelButton = $(
                '<button id="' +
                    tooltipId +
                    '" class="tlx-toolbar-cancel-button-icon mdl-button mdl-js-button mdl-button--icon tlx-toolbar-table-dynamic-cancel" aria-label="' +
                    getMessage('button_cancel') +
                    '">' +
                    '<i class="material-icons">close</i>' +
                    '</button>'
            );
            this._on($cancelButton, {
                click: '_onClickCancel',
            });

            containerSelected
                .prop('id', containerSelectedId)
                .addClass('tlx-toolbar-table-dynamic-selected')
                .empty()
                .append($cancelButton)
                .append(this.selectedText)
                .append(this.toolbarSelected);

            const $containerRow = $('<tr/>');
            $containerRow.append(containerSelected).hide();

            $(this.options.attachTo, this.element)
                .closest('tr')
                .before($containerRow);
            $(this.options.attachTo, this.element).addClass(
                'tlx-toolbar-table-dynamic-not-selected'
            );

            //We separate the buttons that require a selection from those who don't

            const $requireSelectedButtons = $(this.toolbar.children()).filter(
                function () {
                    return (
                        $(this).attr('requireselectedelements') === 'true' ||
                        typeof $(this).attr('requireselectedelements') ===
                            'undefined'
                    );
                }
            );

            const that = this;
            $requireSelectedButtons.each(function () {
                $(this).detach().appendTo(that.toolbarSelected);
            });

            const $buttons = $(this.toolbar.children()).map(function () {
                if ($(this).attr('requireselectedelements') === 'false') {
                    return this;
                }
            });

            //Set up the buttons and stuff.
            this._setupButtons($requireSelectedButtons, this.toolbarSelected);
            this._setupButtons($buttons, this.toolbar);
            this._placeTableButtons();
        }

        //attach to checkboxes to show/hide dynamic table header:
        this.checkboxes = $('.tlx-toolbar-table-dynamic', this.element)
            .closest('table')
            .find("td input[type='checkbox']");
        this.checkboxesTotal = this.checkboxes.length;
        this._off(this.checkboxes, 'change'); //todo-tk: I'm not sure if this can cause several copies of the handler being added or not, so remove before add?
        this._on(this.checkboxes, {
            change: '_onCheckboxChange',
        });

        this.selectedTextArray[2] = this.checkboxesTotal;
        $(window).on(
            'tlxPossibleWidthChange.dynamicTableToolbar resize.dynamicTableToolbar',
            this._placeTableButtons
        );

        if (!window.narrowScreen) {
            this._placeTableButtons();
        }
    },
    _onClickCancel: function () {
        const $checkBoxes = $('.tlx-toolbar-table-dynamic', this.element)
            .closest('table')
            .find("input[type='checkbox']:enabled");
        tlxForms.check($checkBoxes, false);
        $checkBoxes.asyncTrigger('change', { aSync: true, checked: false });
        $('.tlxFloatingHeader')
            .find('.mdl-checkbox')
            .toggleClass('is-checked', false); //Special case for floating header...
    },
    /**
     *
     * Options if for when the change is triggered async, i.e. via the "select all" checkbox.
     * aSync and checked ensures that we always end up with the correct end state.
     *
     * aSync - Set this to true if triggered via asyncTrigger.
     * checked - if it checks or unchecks the checkboxes triggered async.
     */
    _onCheckboxChange: function (e, options) {
        const $target = $(e.target);
        $target
            .closest(this.options.rowTag)
            .toggleClass(
                'tlx-toolbar-table-dynamic-selected',
                $target.prop('checked')
            );

        let checkBoxesCheckedLength;

        if (typeof options === 'undefined' || !options.aSync) {
            checkBoxesCheckedLength = this.checkboxes.filter(':checked').length;
        } else {
            if (options.checked) {
                const checkboxesDisabledLength =
                    this.checkboxes.filter(':disabled').length;
                checkBoxesCheckedLength =
                    this.checkboxesTotal - checkboxesDisabledLength;
            } else {
                checkBoxesCheckedLength = 0;
            }
        }

        if (checkBoxesCheckedLength !== this.selectedTextArray[0]) {
            this.selectedTextArray[0] = checkBoxesCheckedLength;
            $('.tlx-selected-text', this.element).text(
                this.selectedTextArray.join(' ')
            );
        }

        if (!this.toolbarIsSelected && checkBoxesCheckedLength > 0) {
            this.toolbarIsSelected = true;
            $('thead td.tlx-toolbar-table-dynamic-not-selected', this.element)
                .closest('tr')
                .hide();
            $('thead td.tlx-toolbar-table-dynamic-selected', this.element)
                .closest('tr')
                .show();
            $('thead th', this.element).addClass(
                'tlx-toolbar-table-dynamic-selected-th'
            );
        } else if (this.toolbarIsSelected && checkBoxesCheckedLength === 0) {
            this.toolbarIsSelected = false;
            $('thead td.tlx-toolbar-table-dynamic-selected', this.element)
                .closest('tr')
                .hide();
            $('thead td.tlx-toolbar-table-dynamic-not-selected', this.element)
                .closest('tr')
                .show();
            $('thead th', this.element).removeClass(
                'tlx-toolbar-table-dynamic-selected-th'
            );
        }
    },
    _setupButtons: function ($buttons, $toolbar) {
        const dropdownId =
            'dropdown' + (Math.random() * 9999 + '').split('.')[0];
        const $dropdownButton = $(
            '<button id="' +
                dropdownId +
                '" class="mdl-button mdl-button--colored mdl-button--raised tmdl-actionmenu-button mdl-js-button mdl-button--icon tlx-dynamic-dropdown-button">' +
                '<i class="material-icons">&#xE5D4;</i>' +
                '</button>'
        );
        const $dropdownList = $(
            '<ul class="mdl-menu tmdl-actionmenu-menu mdl-menu--bottom-right mdl-js-menu" for="' +
                dropdownId +
                '"></ul>'
        );

        //We expose the first button only if no buttons are explicitly exposed.
        const hasExposed = $buttons.filter(function () {
            return $(this).attr('exposed') === 'true';
        }).length;

        if (hasExposed === 0) {
            $buttons.first().attr('exposed', 'true');
        }

        $buttons.each(function () {
            const $this = $(this);
            $this.hide();
            // If not on mobile, expose button if some buttons are explicitly exposed, or first button
            if ($(window).width() > 480 && $this.attr('exposed') === 'true') {
                $this.addClass('mdl-button mdl-button--colored mdl-js-button');
                if ($this.is('.storeAction'))
                    $this.addClass('mdl-button--raised');
                $this.show();
            }

            const $li = $(
                '<li class="mdl-menu__item">' + $(this).text() + '</li>'
            )
                .click(function () {
                    $this.click();
                })
                .appendTo($dropdownList);

            $li.attr('exposed', $this.attr('exposed'));
            if ($this.is('.ui-state-disabled, [disabled]')) {
                $li.attr('disabled', '');
            }

            if ($(window).width() > 480 && $li.attr('exposed') === 'true') {
                $li.hide();
            }

            // Needed to make ctrl+s work when store button in the menu
            if ($this.is('.storeAction')) {
                $li.addClass('storeAction');
            }
        });
        if ($dropdownList[0].childElementCount > 0) {
            $toolbar.append($dropdownButton).append($dropdownList);
            componentHandler.upgradeElement($dropdownButton[0]);
        }
    },
    _hideClickButtonFunc: function (disableVal) {
        if (disableVal === true) return false;
        return (
            this.options.requireSelected &&
            $(this.element).find('.select input:checked').length === 0
        );
    },
    _placeTableButtons: function () {
        const $toolbar = $('.tlx-toolbar');
        // Prevent toolbar buttons to be placed outside of screen
        const margin =
            $toolbar.closest('table').width() - $('#scrollContainer').width();
        if (margin > 0) {
            $toolbar.css('margin-right', margin + 16); //16px padding
        } else {
            $toolbar.css('margin-right', '0');
        }

        //Show or hide buttons when narrow screen.
        if ($(window).width() > 480) {
            $("button[exposed='true']", $toolbar).show();
            $("li[exposed='true']", $toolbar).hide();
        } else {
            $("button[exposed='true']", $toolbar).hide();
            $("li[exposed='true']", $toolbar).show();
        }

        $toolbar.each(function () {
            const $this = $(this);
            //If there are no list elements that shouldn't be exposed, the menu is empty in desktop mode, and we can hide it.
            const $li = $this.find("li[exposed='false']");

            if ($li.length === 0 && $(window).width() > 480) {
                $('.tlx-dynamic-dropdown-button', $this).hide();
            } else {
                $('.tlx-dynamic-dropdown-button', $this).show();
            }
        });
    },
    _buttonClickValidation: function (buttonProps, customValidationFn) {
        if (this.options.requireSelected && !buttonProps.disableValidate) {
            if (!this._requireSelectedValidation()) return false;
        }
        return this._super(buttonProps, customValidationFn);
    },
    _requireSelectedValidation: function () {
        if ($(this.element).find('.select input:checked').length === 0) {
            this.options.noRowsSelectedCallback();
            return false;
        }
        return true;
    },

    _destroy: function () {
        $(window).off('.dynamicTableToolbar');
    },
});

/**
 * https://tripletex.atlassian.net/wiki/display/DOC/toolbar
 *
 * 'element' is the toolbar container. (usually the tlx:form)
 * Takes global actions from menuHeader and puts them into the dropdown menu.
 * The first three toolbar actions are placed on the toolbar as buttons, if there are more than three
 * the rest will be placed inside the dropdown menu below the global ones.
 * If any of the local buttons are disabled they will are put inside the dropdown menu.
 * The dropdown menu is already defined and placed in index.jsp
 */
$.widget('tlx.mainToolbar', $.tlx.toolbar, {
    options: {
        globalOnly: false,
    },
    _create: function () {
        this._super();
        if (this.options.onTabsActivate) return;
        this.toolbar.prependTo(this.element).addClass('tlx-toolbar-standalone');

        this._clean();

        if (!this.options.globalOnly) this._setupLocalMenuItems();
        this._setupDropdownMenu();

        this.element.trigger('tlxMainToolbarCreated');
    },

    _setupLocalMenuItems: function () {
        const $buttons = $('.tlx-toolbar.tlx-toolbar-standalone').children();
        // We are initially hiding disabled buttons behind kebab menu, but if we have room to expose all buttons,
        // this boolean is used to make sure also disabled buttons are exposed.
        let exposeAll = false;

        if ($buttons.length === 0) return;

        $('body')
            .removeClass('tmdl-layout__action-bar-closed')
            .addClass('tmdl-layout__action-bar-open');

        $(window).trigger('tlxToggleActionBar');

        let numberOfExposedButtons = 3;
        if ($(window).width() < 550) {
            numberOfExposedButtons = 1;
        } else if ($(window).width() < 800) {
            numberOfExposedButtons = 2;
        }

        let hasExposed = 0;
        $buttons.each(function () {
            if ($(this).attr('exposed') === 'true') {
                hasExposed += 1;
            }
        });

        hasExposed = Math.min(numberOfExposedButtons, hasExposed);

        // If there is only one button inside the kebab menu, expose that as well, instead of using kebab menu
        // From now on (atlas tabs and headers), only local buttons are inside this kebab menu.
        if ($buttons.length === numberOfExposedButtons + 1) {
            numberOfExposedButtons++;
        }
        if ($buttons.length <= numberOfExposedButtons) {
            exposeAll = true;
        }

        const that = this;
        $buttons.each(function (index, element) {
            const $element = $(element);

            const isExplicitlyExposedButton =
                hasExposed > 0 && $element.attr('exposed') === 'true';
            const isDefaultExposedButton =
                hasExposed <= 0 &&
                index < numberOfExposedButtons &&
                !$element.is('.ui-state-disabled, [disabled]');

            if (
                exposeAll ||
                isExplicitlyExposedButton ||
                isDefaultExposedButton
            ) {
                let $button = that
                    ._createMDLButton($element)
                    .addClass('tmdl_actionbar__local');
                if (
                    index === 0 &&
                    !$element.is('.ui-state-disabled, [disabled]')
                ) {
                    $button.addClass('mdl-button--raised');
                }

                if ($element.is('.ui-state-disabled, [disabled]')) {
                    $button.attr('disabled', 'disabled');
                }

                $button.attr('data-testid', $element.data('testid'));
                $button.attr('data-trackingid', $element.data('trackingid'));
                $button.attr('data-toolbar-action', $element.data('action'));

                // NOTE: Attempting to modify $button after this conditional may cause
                // your modifications to be applied to the tooltip wrapper instead of the actual button.
                if ($element.attr('title')) {
                    const $tooltipWrapper = $('<div>', {
                        class: 'tooltip-wrapper',
                    });

                    $tooltipWrapper.attr('aria-label', $element.attr('title'));
                    $button = $tooltipWrapper.append($button);
                }

                $button.insertBefore(
                    index === 0
                        ? '#actionbar-layout-spacer'
                        : '#content-menu-top-right'
                );

                if (isExplicitlyExposedButton) hasExposed--;
            } else {
                const $li = that
                    ._createMDLListElement($element)
                    .addClass('tmdl_actionbar__local');

                if ($element.attr('title')) {
                    $li.attr('aria-label', $element.attr('title'));
                }
                $li.insertBefore('.tmdl_actionbar__local_placeholder');
            }

            if (
                $element.is('.ui-state-disabled, [disabled]') &&
                $element.attr('exposed') !== 'true'
            ) {
                if (index < numberOfExposedButtons) {
                    // If a button is disabled put it inside more menu (see first if statement).
                    // But we still want x number of buttons on the action bar.
                    numberOfExposedButtons++;
                }
            }
        });
    },
    _setupDropdownMenu: function () {
        const $menu = $('#actionbar_menu'),
            $moreButton = $('#content-menu-top-right');

        $moreButton.hide();

        // Make sure we don't add event listener several times
        $menu.off('click', onClickMenu).on('click', onClickMenu);

        // Higher than two because of the two placeholders.
        if ($menu.children().length > 2) {
            // Only show "..." menu if it has contents.
            $moreButton.show();

            // Only show divider if we have both local and global menu items
            const $lastGlobal = $menu.find('.tmdl_actionbar__global').last();

            if ($lastGlobal.next('.tmdl_actionbar__local').length > 0) {
                $lastGlobal.addClass('mdl-menu__item--full-bleed-divider');
            } else {
                $lastGlobal.removeClass('mdl-menu__item--full-bleed-divider');
            }
        }

        /**
         * Make sure menu is closed when item inside menu is clicked.
         * But wait until ripple effect is done.
         */
        function onClickMenu() {
            window.setTimeout(function () {
                $('.mdl-menu__container').removeClass('is-visible');
            }, 200);
        }
    },
    _clean: function () {
        $('.tmdl-layout__action-bar .tmdl_actionbar__local').remove();
        $('.tmdl-layout__action-bar .tooltip-wrapper').remove();
        this._removeToolbarButtons();

        $('body')
            .addClass('tmdl-layout__action-bar-closed')
            .removeClass('tmdl-layout__action-bar-open');
        $(window).trigger('tlxToggleActionBar');
    },
    _removeToolbarButtons: function () {
        $('.tmdl-layout__action-bar-row button').each(function () {
            if (this.id === 'content-menu-top-right') return;
            $(this).remove();
        });
    },
    destroy: function () {
        $(window).off('.tlxToolbar');

        if (!this.options.onTabsActivate) {
            // remove generated elements and bindings
            $('.tmdl-layout__action-bar .tmdl_actionbar__local').remove();
            $('.tmdl-layout__action-bar .tmdl_actionbar__global').remove();
            $('.tmdl-layout__action-bar .tooltip-wrapper').remove();
            this._removeToolbarButtons();

            $('.tlx-toolbar.tlx-toolbar-standalone').remove();

            $('body')
                .addClass('tmdl-layout__action-bar-closed')
                .removeClass('tmdl-layout__action-bar-open');
            $(window).trigger('tlxToggleActionBar');
        }
        this._super();
    },
});

/**
 * Refresh toolbar when loading tabs.
 * We could potentially initialize all mainToolbars in a way like this.
 * For most pages we destroy and recreate the toolbar here anyway,
 * as most pages we have a mainToolbar is located inside a tab and will trigger the
 * tlxTabsActivate event anyway.
 */
$(window).on('tlxTabsActivate', function (e) {
    // most pages should have their toolbar initialized on the form.
    // However, some pages do not have a form other than the filter form (Customers > Contacts tab).
    // adding e.target (which should be tlxScope) as a last fallback
    var form =
            $(e.target).find('form:not(".filterForm")')[0] ||
            $(e.target).find('form')[0] ||
            $(e.target)[0],
        $form = $(form),
        $mainToolbar = $form.data('tlx-mainToolbar');
    if ($mainToolbar) {
        const globalOnly = $mainToolbar.options.globalOnly;
        const saveBeforeOtherActionsOKButtonTrackingId =
            $mainToolbar.options.saveBeforeOtherActionsOKButtonTrackingId;
        $mainToolbar.destroy();
        if (globalOnly) {
            $form.mainToolbar({
                globalOnly: true,
                onTabsActivate: false,
                saveBeforeOtherActionsOKButtonTrackingId,
            });
        } else {
            $form.mainToolbar({
                onTabsActivate: false,
                saveBeforeOtherActionsOKButtonTrackingId,
            });
        }
    } else {
        $form.mainToolbar({
            globalOnly: true,
            onTabsActivate: false,
        });
    }
});

/**
 * Clean up and upgrade the dropdown menu for sticky headers in table toolbars
 * Need to do this because the sticky header simply clones the original header,
 * so we need to "re-initialize" the menu for the clone
 */
$(window).on('clonedTableHeader', function (e) {
    const $target = $(e.target);
    // Uncomment if prefixed data-testid for the floating header is needed.
    // $target.find('button').each((index, button) => {
    //     const $button = $(button);
    //     if ($button.data('testid')) {
    //         $button.attr(
    //             'data-testid',
    //             `floating-header-${$button.data('testid')}`
    //         );
    //     }
    // });

    $('.tmdl-actionmenu-button', $target).each(function () {
        const dropdownId =
            'dropdown' + (Math.random() * 9999 + '').split('.')[0];

        // clean up corresponding dropdown menu
        const $menu = $('.tmdl-actionmenu-menu[for=' + this.id + ']', $target);
        $menu
            .removeAttr('data-upgraded')
            .find('li')
            .each(function (i, el) {
                el.removeAttribute('data-upgraded');
            });

        // clean up button
        this.removeAttribute('data-upgraded');

        // set new id, rearrange button and menu in dom, and upgrade elements
        this.id = dropdownId;
        $menu[0].setAttribute('for', dropdownId);
        $menu.insertAfter(this).next('.mdl-menu__container').remove();
        componentHandler.upgradeElement(this);
        componentHandler.upgradeElement($menu[0]);
    });
});
