/**
 * Ensures that the given initializer runs after the page DOM is fully loaded.
 * NOTE: This doesn't mean it waits for all the pictures to load but just for the initial HTML parsing.
 *
 * @param {() => void} initializer The function to run.
 *
 * @author tellef
 * @date 2020-07-29
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Window/DOMContentLoaded_event
 */
export function ensureInitAfterContentLoaded(initializer: () => void) {
    if (document.readyState === 'loading') {
        window.addEventListener('DOMContentLoaded', initializer, {
            once: true,
        });
    } else {
        initializer();
    }
}
