import { Order } from '@Page/InvoicingDialog/types/InvoicingDialog.type';
import { format } from '../../../../../../js/modules/format';
import classNames from 'classnames';
import { LoadingSpinner } from '@Component/Loading';
import { Icon } from '@Component/Icon';
import { SendTypes } from '@Page/InvoicingDialog/component/InvoicingForm/SendMethod';
import { InvoicingStatus } from '@Page/InvoicingDialog/component/BatchInvoicing/BatchInvoicing';
import { getSendMethodName } from '@Page/InvoicingDialog/utils/getSendMethodName';
import { Button } from '@Component/Button';
import { Tooltip } from '@Component/AntsComponents/ToolTip/components/Tooltip';
import { SkeletonRectangle } from '@Page/InvoicingDialog/component/SkeletonRectangle/SkeletonRectangle';
import './InvoicingListItem.css';
import * as React from 'react';

export enum OrderInvoicingStatus {
    'SENDING',
    'INFO',
    'ERROR',
    'SUCCESS',
}

export type OrderInvoicingStateMessage = string | JSX.Element;

export interface BatchInvoiceOrderState {
    order: Order;
    messages: string[];
}

const getInvoiceSendMethod = (order: Order): string => {
    const customerInvoiceSendMethod = order.customer.invoiceSendMethod;

    if (customerInvoiceSendMethod === SendTypes.EMAIL && !order.receiverEmail) {
        return getMessage('text_send_type_later');
    }

    return getSendMethodName(customerInvoiceSendMethod);
};

function getSentSuccessIcon(order: Order) {
    const sendMethod = order.customer.invoiceSendMethod;
    const isInvoiceToBeHandledManually =
        sendMethod === 'MANUAL' ||
        (sendMethod === 'EMAIL' && !order.receiverEmail);
    if (isInvoiceToBeHandledManually) {
        return <Icon icon="text_snippet" className="atl-text-green-80" />;
    }

    return <Icon icon="check" className="atl-text-green-80" />;
}

export function InvoicingListItemSkeleton({
    className,
}: {
    className?: string;
}) {
    return (
        <li
            className={classNames('atl-px-32', 'atl-py-8', {
                [`${className}`]: className,
            })}
        >
            <SkeletonRectangle height="48px" />
        </li>
    );
}

function EmailList({ emails }: { emails: string }) {
    const listOfEmails = emails
        .split(/\s*[,;]\s*/)
        .filter((email) => email.length > 5);
    const emailsCount = listOfEmails.length;
    const formattedEmails = listOfEmails.join('\n');

    return (
        <Tooltip className="atl-flex" content={formattedEmails} direction="top">
            <span className="atl-truncate">{emails}</span>

            {emailsCount > 1 && (
                <div className="atl-bg-turquoise-10 atl-text-turquoise-120 atl-whitespace-nowrap atl-text-s atl-px-4 atl-py-1 atl-rounded atl-ml-2">
                    {emailsCount}
                </div>
            )}
        </Tooltip>
    );
}

export function InvoicingListItem({
    className,
    ordersWithMessages,
    variant,
    status,
    statusMessages,
    onRemoveItem,
}: {
    className?: string;
    ordersWithMessages: BatchInvoiceOrderState[];
    variant: undefined | 'success' | 'info' | 'error';
    status: InvoicingStatus;
    statusMessages: Array<string | JSX.Element>;
    onRemoveItem: () => void;
}) {
    const firstOrderInList = ordersWithMessages[0].order;

    const amountCurrencyExclVat = ordersWithMessages.reduce(
        (acc, orderWithMessages) => {
            const amountExcludingVatCurrency =
                orderWithMessages.order.preliminaryInvoice
                    ?.amountExcludingVatCurrency ?? 0;
            const amountRoundoffExcludingVatCurrency =
                orderWithMessages.order.preliminaryInvoice
                    ?.amountRoundoffCurrency ?? 0;
            return (
                acc +
                (amountExcludingVatCurrency +
                    amountRoundoffExcludingVatCurrency)
            );
        },
        0
    );

    let icon;
    if (status === InvoicingStatus.SENDING) {
        icon = <LoadingSpinner />;
    } else if (status === InvoicingStatus.SENT_INFO) {
        icon = <Icon icon="check" className="atl-text-blue-80" />;
    } else if (status === InvoicingStatus.SENT_SUCCESS) {
        icon = getSentSuccessIcon(ordersWithMessages[0].order);
    } else if (status === InvoicingStatus.SENT_ERROR) {
        icon = <Icon icon="error_outline" className="atl-text-red-100" />;
    } else {
        icon = (
            <Button
                onClick={onRemoveItem}
                className="kb-invoicing-list-item__clear-button"
                type="button"
                data-testid="kb-invoicing-list-item__clear-button"
            >
                <Icon
                    icon="clear"
                    className="kb-invoicing-list__clear-button__icon"
                />
            </Button>
        );
    }

    return (
        <li
            className={classNames(
                'kb-invoicing-list-item',
                'atl-pt-2',
                'atl-pb-8',
                {
                    [`${className}`]: className,
                    'kb-invoicing-list-item--error': variant === 'error',
                    'kb-invoicing-list-item--info': variant === 'info',
                }
            )}
        >
            <div className={classNames('kb-invoicing-list-item--status')}>
                <div className="kb-invoicing-list-item--grid kb-invoicing-list-item__header">
                    <span className="atl-text-base atl-font-normal atl-text-grey-120 atl-truncate">
                        {firstOrderInList.customer.name}
                    </span>

                    <span className="atl-whitespace-nowrap">
                        {format.amount2(amountCurrencyExclVat)}
                        {firstOrderInList.currency.code}
                    </span>

                    <span>{getInvoiceSendMethod(firstOrderInList)}</span>

                    {icon}
                </div>

                <ul className="atl-p-0 kb-invoicing-list-item__order-list">
                    {ordersWithMessages.map(({ order, messages }, index) => {
                        const orderAmountExclVat = format.amount2(
                            order.preliminaryInvoice
                                ?.amountExcludingVatCurrency ?? 0
                        );

                        const tooltipMessages = (
                            <div>
                                {messages.map((message, index) => (
                                    <div key={index}>{message}</div>
                                ))}
                            </div>
                        );

                        return (
                            <li
                                className="kb-invoicing-list-item__order-list__order"
                                key={order.id}
                            >
                                <div className="atl-flex">
                                    <span
                                        className={
                                            messages.length > 0
                                                ? 'kb-invoicing-list_item__order-ml-minus28'
                                                : ''
                                        }
                                    >
                                        {messages.length > 0 && (
                                            <Tooltip
                                                className="atl-flex"
                                                content={tooltipMessages}
                                                direction="right"
                                            >
                                                <Icon
                                                    className={classNames(
                                                        ' atl-mr-4',
                                                        {
                                                            'atl-text-red-80':
                                                                variant ===
                                                                'error',
                                                            'atl-text-blue-80':
                                                                variant ===
                                                                'info',
                                                        }
                                                    )}
                                                    icon="error_outline"
                                                />
                                            </Tooltip>
                                        )}
                                    </span>

                                    <div className="kb-invoicing-list-item--grid atl-text-grey-80">
                                        <span>
                                            {getMessage('text_order')}:{' '}
                                            <a
                                                className="atl-text-blue-100"
                                                href={addContextId(
                                                    `/execute/projectMenu?projectId=${order.id}`
                                                )}
                                                target="_blank"
                                            >
                                                {order.number}
                                            </a>
                                        </span>
                                        {ordersWithMessages.length > 1 && (
                                            <span>
                                                {orderAmountExclVat}{' '}
                                                {order.currency.code}
                                            </span>
                                        )}

                                        {ordersWithMessages.length === 1 && (
                                            <span /> // render empty span for grid to fit
                                        )}

                                        {index == 0 &&
                                            order.customer.invoiceSendMethod ==
                                                SendTypes.EMAIL &&
                                            order.receiverEmail && (
                                                <EmailList
                                                    emails={order.receiverEmail}
                                                />
                                            )}
                                    </div>
                                </div>
                            </li>
                        );
                    })}
                </ul>

                <div>
                    {!!statusMessages &&
                        statusMessages.map((message, index) => (
                            <span key={index}>{message}</span>
                        ))}
                </div>
            </div>
        </li>
    );
}
