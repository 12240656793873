import './Breadcrumbs.css';
import { Button, Icon } from '@tlx/atlas';
import * as React from 'react';
import { useLayoutEffect, useState } from 'react';
import Link from '@Component/Link/Link';
import { offset, shift, useFloating } from '@floating-ui/react-dom';

export function Breadcrumbs({ children }: { children?: React.ReactNode }) {
    return <nav className="atl-flex">{children}</nav>;
}

type BreadcrumbsLinkItemProps = {
    href: string;
    target?: string;
    className?: string;
    toolTip?: string;
    onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
    children?: React.ReactNode;
};

export function BreadcrumbsLinkItem({
    children,
    ...props
}: BreadcrumbsLinkItemProps) {
    return (
        <div className="tlx-breadcrumbs__item">
            <Link {...props}>{children}</Link>
        </div>
    );
}

export function BreadcrumbsItem({ children }: { children?: React.ReactNode }) {
    return (
        <div className="tlx-breadcrumbs__item atl-font-normal">{children}</div>
    );
}

export function BreadcrumbsCurrentItem({ children }: { children?: string }) {
    return (
        <div className="tlx-breadcrumbs__item tlx-breadcrumbs__current-item atl-font-medium">
            {children}
        </div>
    );
}

export function BreadcrumbsHiddenItems({
    children,
}: {
    children?: React.ReactNode;
}) {
    const [isOpen, setIsOpen] = useState(false);

    const floating = useFloating({
        placement: 'bottom-end',
        middleware: [shift({ padding: 8 }), offset(8)],
    });

    const popupPositioningStyle: React.CSSProperties = {
        position: floating.strategy,
        top: floating.y ?? '',
        left: floating.x ?? '',
    };

    useLayoutEffect(() => {
        if (isOpen) {
            floating.update();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    return (
        <div className="tlx-breadcrumbs__item">
            <span aria-hidden={true}>...</span>
            <Button
                className="tlx-breadcrumbs__collapse-button"
                ref={floating.refs.setReference}
                data-testid="open-button"
                variant="icon"
                aria-label={
                    isOpen
                        ? getMessage('text_collapse')
                        : getMessage('text_expand')
                }
                onClick={() => setIsOpen(!isOpen)}
            >
                <Icon>expand_more</Icon>
            </Button>
            {isOpen && (
                <div
                    style={popupPositioningStyle}
                    className="tlx-breadcrumbs__collapsed-popup"
                    ref={floating.refs.setFloating}
                >
                    {children}
                </div>
            )}
        </div>
    );
}
