import { useFetchAll } from '@Page/LogisticsV2/hooks/api';
import { mapObjectToURLSearchParams } from '@Page/LogisticsV2/utils/mappers';
import { ApiUrl } from '@Page/LogisticsV2/utils/enums';
import { getIdFromUrl } from '@Page/LogisticsV2/utils/functions';
import { InventoryDTO } from '@Page/LogisticsV2/types/dtoTypes';

export function useStocktakingCreate() {
    const { data: warehouseList } = useFetchAll<InventoryDTO[]>(
        ApiUrl.INVENTORY,
        mapObjectToURLSearchParams({ fields: 'id,name, isMainInventory' }),
        { revalidateOnFocus: false }
    );
    const defaultInventoryId = getDefaultInventoryId(warehouseList);
    return {
        defaultInventoryId,
        warehouseList,
    };
}

const getDefaultInventoryId = (data: InventoryDTO[] | undefined) => {
    const urlId = getIdFromUrl('warehouseId');
    if (urlId !== null) {
        return Number(urlId);
    }
    if (data === undefined) {
        return undefined;
    }
    const mainWarehouse = data?.find((warehouse) => warehouse.isMainInventory);
    return mainWarehouse ? mainWarehouse.id : data[0].id;
};
