import ReactMarkdown from 'react-markdown';
import * as React from 'react';
import { TipsIcon } from '@tlx/atlas';

const MAX_SHOW_TAGS = 3;

export type AccountExplanation = {
    title: string;
    userDescription: string;
    description: string;
    tags: string[];
};
function AccountHelpExplanationPanelContainer({
    children,
}: {
    children: React.ReactNode;
}) {
    return <div className="atl-flex atl-flex-col atl-m-12">{children}</div>;
}

function AccountHelpExplanationPanelTitle({
    children,
}: {
    children: React.ReactNode;
}) {
    return <div className={'atl-font-medium atl-mb-24'}>{children}</div>;
}

function AccountHelpExplanationPanelContent({
    children,
}: {
    children: React.ReactNode;
}) {
    return (
        <div className={'krr-account-help-explanation-panel__content'}>
            {children}
        </div>
    );
}

function AccountHelpEmptyExplanationPanel() {
    return (
        <AccountHelpExplanationPanelContainer>
            <AccountHelpExplanationPanelTitle>
                {getMessage('text_account_help_explanation_description')}
            </AccountHelpExplanationPanelTitle>
            <AccountHelpExplanationPanelContent>
                {/*<div className={'atl-p-12 atl-bg-blue-10 atl-rounded'}>*/}
                {/*    <div className={'atl-font-medium atl-mb-8'}>*/}
                {/*        <TipsIcon /> <span>Some cool tip</span>*/}
                {/*    </div>*/}
                {/*    <div>*/}
                {/*        Do eiusmod aliqua laborum sit amet ex deserunt*/}
                {/*        voluptate. Duis labore cupidatat nulla pariatur Lorem*/}
                {/*        sunt.*/}
                {/*    </div>*/}
                {/*</div>*/}
            </AccountHelpExplanationPanelContent>
        </AccountHelpExplanationPanelContainer>
    );
}

export function AccountHelpExplanationPanel({
    accountExplanation,
}: {
    accountExplanation: AccountExplanation;
}) {
    const isAccountExplanationEmpty =
        accountExplanation?.description === '' &&
        accountExplanation?.userDescription === '' &&
        accountExplanation?.tags.length === 0;

    if (isAccountExplanationEmpty) {
        return <AccountHelpEmptyExplanationPanel />;
    }

    const hasBothDescriptions =
        accountExplanation?.description && accountExplanation?.userDescription;

    return (
        <AccountHelpExplanationPanelContainer>
            <AccountHelpExplanationPanelTitle>
                {accountExplanation?.title}
            </AccountHelpExplanationPanelTitle>
            {/*<div>*/}
            {/*    {accountExplanation?.tags.map((tag, index) => {*/}
            {/*        if (index >= MAX_SHOW_TAGS) {*/}
            {/*            return null;*/}
            {/*        }*/}

            {/*        return (*/}
            {/*            <Chip className={'atl-mr-2 atl-mb-2'} key={tag}>*/}
            {/*                {tag}*/}
            {/*            </Chip>*/}
            {/*        );*/}
            {/*    })}*/}
            {/*</div>*/}
            <AccountHelpExplanationPanelContent>
                <ReactMarkdown>{accountExplanation?.description}</ReactMarkdown>
            </AccountHelpExplanationPanelContent>
            {hasBothDescriptions ? <hr /> : null}
            <div>{accountExplanation?.userDescription}</div>
        </AccountHelpExplanationPanelContainer>
    );
}
