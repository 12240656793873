import { useEffect, useState } from 'react';
import { DeviceType } from '../utils/constants';
import { getDeviceType } from '../utils/functions';

export function useDeviceType(): DeviceType {
    const [deviceType, setDeviceType] = useState<DeviceType>(
        getDeviceType(window.innerWidth)
    );

    useEffect(() => {
        const listener = () => {
            const newDeviceType = getDeviceType(window.innerWidth);
            if (newDeviceType !== deviceType) {
                setDeviceType(newDeviceType);
            }
        };
        window.addEventListener('resize', listener);
        return () => {
            window.removeEventListener('resize', listener);
        };
    }, [deviceType]);

    return deviceType;
}
