import { Input } from '@tlx/atlas';
import { ChangeEvent, FocusEvent, useEffect, useState } from 'react';
import { format } from '../../../../../../js/modules/format';

const formatDecimal = (value: string): string => {
    const number = value
        .replaceAll(/[^0-9.,]/g, '')
        .replaceAll(/[.,]/g, window.decimalSeparator);
    const split = number.split(/[,.]/g);
    if (split.length > 1) {
        return (
            format.withKey(split[0], '#,###.##') +
            window.decimalSeparator +
            split.slice(1).join('').substring(0, 2)
        );
    }
    return format.withKey(number, '#,###.##');
};

type FormattedNumberFieldProps = {
    value?: string;
    defaultValue?: string;
    placeholder?: string;
    className?: string;
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
    onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
    disabled?: boolean;
};

export const FormattedNumberField = ({
    value,
    defaultValue,
    placeholder,
    className,
    onChange,
    onFocus,
    onBlur,
    disabled,
}: FormattedNumberFieldProps) => {
    const [inputValue, setInputValue] = useState<string>('');

    const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        setInputValue(formatDecimal(event.target.value));
        if (onChange) {
            onChange(event);
        }
    };

    useEffect(() => {
        if (defaultValue) {
            setInputValue(formatDecimal(defaultValue));
        }

        if (value) {
            setInputValue(formatDecimal(value));
        }
    }, [value, defaultValue]);

    return (
        <form className={className}>
            <Input
                data-testid="formatted-number-field"
                type="text"
                placeholder={placeholder}
                value={inputValue}
                onChange={onChangeHandler}
                onFocus={onFocus}
                onBlur={onBlur}
                autoComplete={'off'}
                disabled={disabled}
            />
        </form>
    );
};
