import { OPTION_NOT_CHOSEN } from '@Page/LogisticsV2/utils/constants';
import { ApiUrl, PageUrl } from '@Page/LogisticsV2/utils/enums';
import {
    ApprovedIcon,
    AutomatedIcon,
    Button,
    Chip,
    Dropdown,
    DropdownDrawer,
    DropdownOpener,
    DropdownScrollContainer,
    Group,
    HelpIcon,
    InfoIcon,
    Modal,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalLayout,
    ModalTitle,
    Option,
} from '@tlx/atlas';
import classNames from 'classnames';
import { ReactNode } from 'react';
import '../style.css';
import { ProductImportHeaderType } from '../types';
import { AsyncDropdownOptions } from '@tlx/astro-shared';
import { checkEntitlements } from '@Page/LogisticsV2/utils/functions';
import { EntitlementLookup } from '@General/constants';

export function MappingStep({ children }: { children: ReactNode }) {
    return <div className="atl-flex atl-gap-24">{children}</div>;
}

export const MappingSection = ({ children }: { children: ReactNode }) => {
    return (
        <div className="atl-flex atl-flex-col atl-flex-1 atl-gap-24">
            {children}
        </div>
    );
};

export const MappingTable = ({ children }: { children: ReactNode }) => {
    return (
        <form className="atl-table-container" autoComplete="off">
            <table className="atl-table atl-w-full">
                <MappingTableHeaders />
                <tbody>{children}</tbody>
            </table>
        </form>
    );
};

export const MappingTableRow = ({
    item,
    selectedMapping,
    error,
    hideFormat,
    onChange,
}: {
    item: ProductImportHeaderType;
    selectedMapping: number;
    error: string | undefined;
    hideFormat: boolean;
    onChange: (id: number, name: string) => void;
}) => {
    return (
        <tr className="atl-tr">
            <td className="atl-td">
                <Group className="atl-py-16">
                    <div className="">{item.name}</div>
                    <div className="atl-text-grey-60">
                        {getMessage('text_example')}: {item.sampleValue}
                    </div>
                </Group>
            </td>
            <td
                className="atl-td"
                style={{
                    verticalAlign: 'middle',
                }}
            >
                <div
                    className={classNames(
                        'atl-flex atl-flex-col atl-gap-8 atl-relative',
                        item.suggestedColumnField &&
                            item.suggestedColumnField.id === selectedMapping
                            ? 'goldshark-overrides'
                            : ''
                    )}
                >
                    {item.suggestedColumnField &&
                        item.suggestedColumnField.id === selectedMapping && (
                            <AutomatedIcon className="atl-suggested-input__icon" />
                        )}
                    <Dropdown
                        data-testid={'tripletex-available-fields'}
                        value={selectedMapping}
                        onChange={(e) =>
                            onChange(Number(e.target.value), item.name)
                        }
                    >
                        <DropdownOpener
                            className={
                                item.suggestedColumnField &&
                                item.suggestedColumnField.id === selectedMapping
                                    ? 'atl-border atl-border-purple-100 goldshark-input-customise goldshark-border-radius-6'
                                    : ''
                            }
                        />
                        <DropdownDrawer>
                            <DropdownScrollContainer>
                                <Option value={OPTION_NOT_CHOSEN}>
                                    {getMessage('option_not_chosen')}
                                </Option>
                                <AsyncDropdownOptions
                                    url={ApiUrl.PRODUCT_IMPORT_FIELDS}
                                />
                            </DropdownScrollContainer>
                        </DropdownDrawer>
                    </Dropdown>
                    {error && <span className="atl-text-red-100">{error}</span>}
                </div>
            </td>
            <td className="atl-td atl-align-middle">
                {mappingDetails.get(selectedMapping) && !hideFormat && (
                    <FormatRow body={mappingDetails.get(selectedMapping)} />
                )}
            </td>
        </tr>
    );
};

const FormatRow = ({
    body,
}: {
    body: { message: string; url?: string; linkText?: string } | undefined;
}) => {
    if (!body) {
        return null;
    }
    return (
        <div>
            {getMessage(body.message)}
            {body.linkText && body.url && (
                <a
                    href={addContextId(body.url)}
                    className="atl-ml-4"
                    target="_blank"
                >
                    {getMessage(body.linkText)}
                </a>
            )}
        </div>
    );
};

export const MappingTableHeaders = () => {
    return (
        <thead className="atl-thead">
            <tr className="atl-tr">
                <th className="atl-th">
                    {getMessage('text_import_column_file')}
                </th>
                <th className="atl-th">
                    {getMessage('text_import_column_tripletex')}
                </th>
                <th className="atl-th" style={{ width: '400px' }}>
                    {getMessage('text_format')}
                    <InfoIcon className="atl-ml-4" />
                </th>
            </tr>
        </thead>
    );
};

export const RequiredFieldsComponent = ({
    mandatoryFields,
    duplicateFields,
    displayWarningModal,
    toggleWarningModal,
}: {
    mandatoryFields: {
        [name: string]: boolean;
    };
    duplicateFields: {
        [id: number]: string[];
    };
    displayWarningModal: boolean;
    toggleWarningModal: (value: boolean) => void;
}) => {
    const hasMappingError = Object.entries(mandatoryFields).find(
        ([key, value]) => !value
    );
    const duplicates: string[] = [];
    Object.entries(duplicateFields).forEach(([key, value]) =>
        value.forEach((item) => duplicates.push(item))
    );

    return (
        <div
            className="atl-flex atl-flex-col atl-p-16 atl-gap-16 atl-border atl-border-grey-20 atl-bg-white atl-rounded"
            style={{ height: 'fit-content' }}
        >
            <div className="atl-text-xl atl-font-medium">
                {getMessage('text_import_required_fields')}
            </div>
            <div>{getMessage('text_import_required_fields_description')}</div>
            {Object.entries(mandatoryFields).map(([key, value]) => (
                <div key={key}>
                    <Chip
                        className={`atl-px-8 ${
                            value
                                ? `atl-bg-turquoise-5 atl-border atl-border-turquoise-100`
                                : `atl-bg-yellow-10 atl-border atl-border-yellow-100`
                        }`}
                    >
                        {value ? (
                            <ApprovedIcon className="atl-text-turquoise-100" />
                        ) : (
                            <HelpIcon className="atl-text-yellow-100" />
                        )}
                        {key}
                    </Chip>
                </div>
            ))}
            <Modal
                open={displayWarningModal}
                onClose={() => toggleWarningModal(false)}
            >
                <ModalLayout>
                    <ModalCloseButton />
                    <ModalTitle>
                        {getMessage('text_import_required_fields_error')}
                    </ModalTitle>
                    <ModalContent>
                        <div className="atl-flex atl-gap-24 atl-flex-col">
                            <div className="atl-gap-16 atl-flex atl-flex-col">
                                {hasMappingError && (
                                    <div>
                                        {getMessage(
                                            'text_import_required_fields_error_description'
                                        )}
                                    </div>
                                )}
                                <div className="atl-flex atl-flex-row atl-gap-16">
                                    {Object.entries(mandatoryFields).map(
                                        ([key, value]) =>
                                            value ? (
                                                <div key={key}></div>
                                            ) : (
                                                <div key={key}>
                                                    <Chip
                                                        className={
                                                            'atl-px-8 atl-bg-blue-5 atl-border atl-border-grey-40'
                                                        }
                                                        key={key}
                                                    >
                                                        {key}
                                                    </Chip>
                                                </div>
                                            )
                                    )}
                                </div>
                            </div>
                            <div className=" atl-flex atl-gap-16 atl-flex-col">
                                {duplicates.length > 0 && (
                                    <div>
                                        {getMessage(
                                            'text_import_mapping_error_duplicate_description'
                                        )}
                                    </div>
                                )}
                                <div className="atl-flex atl-flex-row atl-gap-16">
                                    {duplicates.map((item) => {
                                        return (
                                            <div key={item}>
                                                <Chip
                                                    className={
                                                        'atl-px-8 atl-bg-blue-5 atl-border atl-border-grey-40'
                                                    }
                                                >
                                                    {item}
                                                </Chip>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </ModalContent>
                    <ModalFooter>
                        <div className="atl-flex atl-gap-8">
                            <Button
                                variant="secondary"
                                data-testid="close-mapping-warning-modal"
                                onClick={() => toggleWarningModal(false)}
                            >
                                {getMessage('button_close')}
                            </Button>
                        </div>
                    </ModalFooter>
                </ModalLayout>
            </Modal>
        </div>
    );
};

const hasAccessToAccount = checkEntitlements([
    EntitlementLookup.AUTH_ACCOUNTING_SETTINGS,
]);

const mappingDetails = new Map<
    number,
    { message: string; url?: string; linkText?: string }
>([
    [
        10,
        {
            message: 'text_vat_code_information',
            url: '/execute/accountingSettingsMenu#vatTypes',
            linkText: hasAccessToAccount ? 'text_accounting_settings' : '',
        },
    ],
    [
        4,
        {
            message: 'text_product_unit_information',
            url: `${PageUrl.PRODUCT_SETTINGS}#productUnits`,
            linkText: 'text_product_unit',
        },
    ],
    [
        16,
        {
            message: 'text_import_weight_information',
        },
    ],
    [
        17,
        {
            message: 'text_import_weight_unit_information',
        },
    ],
    [
        18,
        {
            message: 'text_import_volume_information',
        },
    ],
    [
        19,
        {
            message: 'text_import_volume_unit_information',
        },
    ],
]);
