export type Signer = {
    id: number;
    name: string;
    email: string;
    signatureStatus: SignatureStatus;
    description: string;
    roleInCompany: number;
    employeeId: number;
    canReceiveReminder: boolean;
};

export type SignatureStatus =
    | 'NOT_SIGNED'
    | 'PENDING_SIGNATURE'
    | 'SIGNED'
    | 'NOT_A_SIGNER';

export enum SendMessage {
    SIGN_NOW = 'text_company_verification_sign_now',
    SEND_MANY_REQUESTS_AND_SIGN_NOW = 'text_kyc_send_requests_and_sign_now',
    SEND_MANY_REQUESTS = 'text_kyc_send_requests',
    SEND_ONE_REQUEST_AND_SIGN_NOW = 'text_kyc_send_request_and_sign_now',
    SEND_ONE_REQUEST = 'text_kyc_send_one_request',
    NO_DETAILS_PROVIDED = 'text_no_details_provided',
}

export type SignerCombination = {
    companyRepresentativeDTOList: Signer[];
    signatureCombinationDescription: string;
    uniqueKey: string;
};

export type CompanyVerificationType =
    | 'UNVERIFIED'
    | 'VERIFIED_ACCOUNTANT'
    | 'VERIFIED_CLIENT_OF_ACCOUNTANT'
    | 'VERIFIED_CUSTOMER_BANKID'
    | 'VERIFIED_BY_ALTINN_MESSAGE'
    | 'VERIFIED_BY_TRIPLETEX_SUPPORT'
    | 'VERIFIED_BANK_AGREEMENT'
    | 'VERIFIED_TRIPLETEX';
