import { Order } from '@Page/InvoicingDialog/types/InvoicingDialog.type';
import { ValidationError } from '@Page/InvoicingDialog/component/ValidationError';
import { Button } from '@tlx/atlas';
import * as React from 'react';

export function SendingError({ order }: { order: Order }) {
    return (
        <div className="atl-p-32">
            <h3>{getMessage('text_sending_failed')}</h3>
            <ValidationError
                error={{
                    type: 'manual',
                    message: getMessage('text_sending_failed_details'),
                }}
            />
            <div className="atl-mt-16 atl-mb-8 tlx-invoicingDialog__mb_auto">
                <Button
                    variant="primary"
                    onClick={() => {
                        window.location.href = addContextId(
                            '/execute/invoiceMenu?invoiceId=' +
                                order.preliminaryInvoice.id
                        );
                    }}
                    data-testid="close-invoicing-dialog-button"
                >
                    {getMessage('button_go_to_invoice_details')}
                </Button>
            </div>
        </div>
    );
}
