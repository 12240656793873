import { ValidationStatus } from '@Page/LogisticsV2/pages/Import v2/ValidationStatus';
import { RowError } from '@Page/LogisticsV2/pages/Import v2/components';
import {
    ProductImportSuggestedMappingType,
    ProductPotentialType,
    rowStatus,
} from '@Page/LogisticsV2/pages/Import v2/types';
import {
    ApprovedIcon,
    FailIcon,
    InfoIcon,
    MultiContentSwitch,
    MultiContentSwitcher,
    SkeletonRow,
    SkeletonText,
} from '@tlx/atlas';
import { ReactNode } from 'react';
import { mapRowContent } from '../utils';
import { isNumberColumn } from '@Page/LogisticsV2/utils/functions';

export function ValidationStep({ children }: { children: ReactNode }) {
    return <div className="atl-flex atl-flex-col atl-gap-16">{children}</div>;
}

export function ValidationTable({
    children,
    headers,
}: {
    children: ReactNode;
    headers: ProductImportSuggestedMappingType[];
}) {
    return (
        <table className="atl-table">
            <ValidationTableHeader headers={headers} />
            <tbody>{children}</tbody>
        </table>
    );
}

function ValidationTableHeader({
    headers,
}: {
    headers: ProductImportSuggestedMappingType[];
}) {
    return (
        <thead className="atl-thead atl-thead--sticky">
            <tr className="atl-tr">
                <th className="atl-th" style={{ width: '10px' }} />
                <th
                    className="atl-th"
                    style={{
                        borderRight: '1px solid var(--atl-color-grey-20)',
                        width: '150px',
                    }}
                >
                    {getMessage('text_status')}
                </th>
                {headers.map((item) => (
                    <th
                        className={`atl-th ${
                            isNumberColumn(item.fieldName)
                                ? 'atl-text-right'
                                : ''
                        }`}
                        key={item.id}
                    >
                        {item.displayName}
                    </th>
                ))}
            </tr>
        </thead>
    );
}

export function ValidationRow({
    item,
    headers,
}: {
    item: ProductPotentialType;
    headers: ProductImportSuggestedMappingType[];
}) {
    const rowContent = mapRowContent(headers, item.rowContent);
    return (
        <tr className="atl-tr">
            <td className="atl-td atl-align-middle atl-text-grey-40">
                {item.rowContent.rowNumber}
            </td>
            <td
                className="atl-td atl-align-middle atl-td--button goldshark-override"
                style={{
                    borderRight: '1px solid var(--atl-color-grey-20)',
                }}
            >
                <ValidationStatus
                    status={item.rowStatus}
                    errorMessage={item.errorMessage}
                />
            </td>
            {Object.entries(rowContent).map(([key, value]) => {
                const error = item.errorMessage.find(
                    (error) => error.fieldName === key
                );

                return (
                    <td
                        className={`atl-td atl-align-middle atl-td--button `}
                        key={key}
                    >
                        <div
                            className={`atl-flex atl-items-center atl-gap-16 ${
                                isNumberColumn(key) ? 'atl-justify-end' : ''
                            }`}
                        >
                            {error && (
                                <RowError
                                    errorMessage={error.errorMessage ?? {}}
                                />
                            )}
                            {value}
                        </div>
                    </td>
                );
            })}
        </tr>
    );
}

export function ValidationFilterBar({
    status,
    onChangeStatus,
}: {
    status: string | null;
    onChangeStatus: (status: string) => void;
}) {
    return (
        <div className="atl-flex atl-p-16 atl-justify-between atl-w-full atl-items-center">
            <MultiContentSwitcher data-testid="goldshark-import-status-filter">
                <MultiContentSwitch
                    name={rowStatus.FAIL}
                    checked={status === rowStatus.FAIL}
                    data-testid="goldshark-import-error-status"
                    onChange={() => onChangeStatus(rowStatus.FAIL)}
                >
                    {getMessage('text_error_import_tab')}
                </MultiContentSwitch>
                <MultiContentSwitch
                    name={rowStatus.UPDATE}
                    checked={status === rowStatus.UPDATE}
                    data-testid="goldshark-import-update-status"
                    onChange={() => onChangeStatus(rowStatus.UPDATE)}
                >
                    {getMessage('text_updated')}
                </MultiContentSwitch>
                <MultiContentSwitch
                    name={rowStatus.NEW}
                    checked={status === rowStatus.NEW}
                    data-testid="goldshark-import-new-status"
                    onChange={() => onChangeStatus(rowStatus.NEW)}
                >
                    {getMessage('text_new')}
                </MultiContentSwitch>
            </MultiContentSwitcher>
        </div>
    );
}

export function ValidationSummary({
    status,
}: {
    status: {
        countCreated: number;
        countUpdated: number;
        countIgnored: number;
    };
}) {
    return (
        <div className="atl-flex atl-gap-16 atl-items-center atl-flex-wrap">
            <div className="atl-flex atl-gap-8 atl-items-center">
                <ApprovedIcon
                    className="atl-p-4 atl-rounded-full atl-bg-turquoise-20"
                    size={'small'}
                />
                <div>
                    {status?.countCreated}{' '}
                    {getMessage('text_created_products').toLowerCase()}
                </div>
            </div>
            <div className="atl-flex atl-gap-8 atl-items-center">
                <InfoIcon
                    className="atl-p-4 atl-rounded-full atl-bg-turquoise-20"
                    size={'small'}
                />
                <div>
                    {status?.countUpdated}{' '}
                    {getMessage('text_updated_products').toLowerCase()}
                </div>
            </div>
            <div className="atl-flex atl-gap-8 atl-items-center">
                <FailIcon
                    className="atl-p-4 atl-rounded-full atl-bg-turquoise-20"
                    size={'small'}
                />
                <div>
                    {status?.countIgnored}{' '}
                    {getMessage('text_ignored_products').toLowerCase()}
                </div>
            </div>
        </div>
    );
}

export function ValidationTableSkeleton({ length }: { length: number }) {
    return (
        <SkeletonRow className="atl-tr">
            {[...Array.from(Array(length).keys())].map((index) => (
                <td className="atl-td" key={index}>
                    <SkeletonText className="atl-w-full" />
                </td>
            ))}
        </SkeletonRow>
    );
}

export function ImportSummarySkeleton() {
    return (
        <div className="atl-flex atl-gap-32">
            <SkeletonText className="atl-w-full" />
            <SkeletonText className="atl-w-full" />
            <SkeletonText className="atl-w-full" />
        </div>
    );
}

export function ImportTableSkeleton({
    rows,
    columns,
}: {
    rows: number;
    columns?: number;
}) {
    return (
        <>
            <SkeletonRow className="atl-tr">
                {[...Array.from(Array(rows).keys())].map((index) => (
                    <td className="atl-td" key={index}>
                        <SkeletonText className="atl-w-full" />
                    </td>
                ))}
            </SkeletonRow>
            <SkeletonRow className="atl-tr">
                {[...Array.from(Array(rows).keys())].map((index) => (
                    <td className="atl-td" key={index}>
                        <SkeletonText className="atl-w-full" />
                    </td>
                ))}
            </SkeletonRow>
            <SkeletonRow className="atl-tr">
                {[...Array.from(Array(rows).keys())].map((index) => (
                    <td className="atl-td" key={index}>
                        <SkeletonText className="atl-w-full" />
                    </td>
                ))}
            </SkeletonRow>
            <SkeletonRow className="atl-tr">
                {[...Array.from(Array(rows).keys())].map((index) => (
                    <td className="atl-td" key={index}>
                        <SkeletonText className="atl-w-full" />
                    </td>
                ))}
            </SkeletonRow>
            <SkeletonRow className="atl-tr">
                {[...Array.from(Array(rows).keys())].map((index) => (
                    <td className="atl-td" key={index}>
                        <SkeletonText className="atl-w-full" />
                    </td>
                ))}
            </SkeletonRow>
        </>
    );
}
