import jQuery from 'jquery';
import { renderJSPDropdown } from '@Component/DropDown/JSPDropdown/JSPDropdown';
const $ = jQuery;

/**
 * This code is merely for bootstrapping/starting the react component rendering for
 * dropdowns. For the actual component, see JSPDropdown.
 */
(function () {
    function lazyInitializeDropdown(element) {
        observer.unobserve(element);
        const $scope = $(element).closest('.tlxScope');
        const $container = $(element).closest('.tlx-dropdown');
        const $input = $container.find('.tlx-dropdown__input');
        let filter;

        // Check if the input element representing this dropdown has focus, before turning it into a react component.
        // That way, we can give the react dropdown focus again, after initialization
        const $possibleFocusedElement = $container
            .find(':input:visible')
            .not("[type='button']")
            .first();
        const hasFocus =
            $possibleFocusedElement.get(0) === document.activeElement;

        // Search for filters attached to this dropdown, via its property (the property being the same as the name attribute
        // for the input-item tied to this dropdown).
        if ($scope[0] && $scope[0].tlxDropdownFilters) {
            Object.keys($scope[0].tlxDropdownFilters).forEach(function (key) {
                if ($input.is(':prop(' + key + ')')) {
                    filter = function (object) {
                        return $scope[0].tlxDropdownFilters[key](
                            $input.attr('name'),
                            object
                        );
                    };
                }
            });
        }

        const currentVal = $input.val();
        let currentDisplayName = $input.get(0).dataset.displayName; // Retrieve data-display-name directly from input element as jQuery's data() only reads from dataset *once*
        const initialValue = $input.get(0).dataset.initialValue;
        const resetBy = $input.get(0).dataset.resetby;
        const dataTrackingId = $input.get(0).dataset.trackingid;

        // If javascript changes the value of this input before we initialise it in react, the displayName value is
        // most likely wrong.
        if (currentVal !== initialValue) {
            currentDisplayName = '';
        }

        // No need having +-buttons in drop downs for filters.
        const dontShowPlusButton =
            $input.data('dontshowplusbutton') ||
            $container.closest('.filter').length > 0;

        const defaults = $.map(
            $container.find('.dropdown-option'),
            function (span) {
                return {
                    value: $(span).attr('value'),
                    displayName: $(span).text(),
                };
            }
        );

        renderJSPDropdown(element, {
            label: $input.data('label'),
            defaults: defaults,
            initial: {
                value: currentVal,
                displayName: currentDisplayName,
            },
            input: $input[0],
            type: $input.data('model'),
            scope: $container.closest('.tlxScope')[0],
            queryAttributes: $container.data(),
            required: $input.data('required'),
            dontShowPlusButton: dontShowPlusButton,
            disabled: $input.is('[disabled]'),
            readonly: $input.is('[readonly]'),
            isCombo: $input.data('iscombo'),
            maxLength: $input.data('maxlength'),
            clientFilter: filter,
            autofocus: $input.data('autofocus'),
            resetBy,
            dataTestId: $container
                .find('.tlx-textfield[data-testid]')
                .data('testid'),
            dataTrackingId,
        });
        // Embarrassing hack. When something is shown or hidden in dialogs
        // replace dialogs so they fit the screen again.
        $(element).closest('.ui-dialog').trigger('changeInDialog');

        // o-common:addNewRow() should give focus to first input in row.TRIP-17195
        // This is removed when creating react component so we need to add it back.TRIP-17195
        // WARNING: there might be a race condition here. There is no guarantee that focus will be set before dropdown is created,
        // and setting focus after creating dropdown will not work.
        if (hasFocus) {
            // It is one of the recommendations for what is essentially a callback after react renders.
            // I'm not 100% sure this will always work, but I'm also afraid that using the callback ref
            // example would potentially result in trying to focus the element more than once in some
            // cases. At least this will keep it to firing only once.
            // https://github.com/reactwg/react-18/discussions/5#discussioncomment-798304
            setTimeout(
                () => $container.find('div[tabindex]').trigger('focus'),
                0
            );
        }
    }

    const observerOptions = {
        root: null, // null means viewport, using anything else might break init in dialogs.
        rootMargin: '0px',
        treshold: 0,
    };
    let observer;

    // We had some issues with old browsers not finding "dropdown" in global scope, defined further down in this file.
    // When try/catching IntersectionObserver, things started to work again.
    try {
        observer = new IntersectionObserver(function (entries) {
            entries
                .filter((entry) => entry.isIntersecting)
                .map((entry) => {
                    return entry.target;
                })
                .forEach((element) => {
                    /**
                     * The reasons for this setTimeout is a particular bug in Firefox, which shows up with the peculiar
                     * error message «Should not already be working».
                     *
                     * The bug is really in Firefox: It looks like it cannot schedule it's javascript handling correct in
                     * certain circumstances. When there is an alert() statement, or a debugger in the console, or if some
                     * code is making a synchronous network call, while an IntersectionObserver is invoked at the same time.
                     *
                     * Several discussions on how Firefox handles Javascript scheduling differently, is taking place in
                     * this issue. See in particular the linked mentioned comment below[1]. No one has mentioned any issues
                     * with IntersectionObserver yet, but I have confirmed that this is a bug by making a minimal
                     * reproduction[2].
                     *
                     * 1. https://github.com/facebook/react/issues/17355#issuecomment-857730190
                     * 2. https://codesandbox.io/s/should-not-already-be-working-x1syi?file=/src/App.js
                     *
                     */
                    window.setTimeout(
                        lazyInitializeDropdown.bind(null, element),
                        0
                    );
                });
        }, observerOptions);
    } catch (e) {
        // We still have some users in Safari 10, 11, 12 which doesn't support the IntersectionObserver
        observer = {
            observe: lazyInitializeDropdown,
            unobserve: function noop() {
                /* noop */
            },
        };
    }

    $(window).on(
        'tlxInitializeStateDone tlxLazyLoadDone tlxPopupIsLoaded dialogopen crmCardLoaded tlxNewRowAdded',
        function (e) {
            const $dropdowns = $(
                '.tlx-dropdown__react-container:not(.tlx-dropdown__react-container--observing)',
                e.target
            );
            $dropdowns.each(function () {
                $(this).addClass('tlx-dropdown__react-container--observing');
                observer.observe(this);
            });
        }
    );
})();
