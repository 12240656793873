import jQuery from 'jquery';

const $ = jQuery;

import { addContextId, tlxUrl } from './modules/url';
import { JSONRpcClient } from './legacy/jsonrpc';
import { app } from './modules/framework';
import { dev } from './modules/dev-util';
import { format } from './modules/format';
import { dateUtil } from './modules/date';
import { tlxForms } from './modules/forms';
import { getRelevantUrl } from './modules/scope';
import { toolTip } from './component/tooltip/tooltip';
import {
    bindEventsInsideScope,
    defaultAjaxAction,
    defaultJsonRpcExceptionHandler,
    hideElement,
} from './o-common';
import { tlxConfirm, tlxConfirmWithTrackingId } from './modules/confirm';
import { stringUtils } from './modules/stringUtils';
import { changed, changeTest } from './modules/change';
import { CSRF_HEADER_NAME, getCSRFToken } from './modules/csrf';
import { encodeHTML } from './modules/encodeHTML';

const isString = stringUtils.isString;

// Locale
const decimalSeparator = window.decimalSeparator || '';
const groupingSeparator = window.groupingSeparator || '';

export function round2(num) {
    return Math.round(num * 100.0) / 100.0;
}

/**
 * Our own little query function.
 *
 * @param selector
 * @param context
 *
 * @author bruce
 * @date Feb 25, 2015
 *
 * @deprecated
 */
window.tlx = function tlx(selector, context) {
    /* Case 1: If we send in the scope object, make sure we use scope DOM node instead. */
    if (selector.scopeNode) {
        selector = selector.scopeNode;
    } else if (context && context.scopeNode) {
        context = context.scopeNode;
    }

    /* Case 2: If we send in the scope node as second argument, and id as first argument, prefix id. */
    if (selector.charAt(0) === '#' && context && context.id) {
        selector = '#' + context.id + selector.substring(1);

        // Escape meta-characters.
        selector = selector
            .replace('[', '\\[')
            .replace(']', '\\]')
            .replace('.', '\\.');
    }

    return $(selector, context);
};

let oldContextId;

export function tlxInitJsonrpc() {
    if (oldContextId === window.contextId) {
        return;
    }

    oldContextId = window.contextId;
    window.jsonrpc = new JSONRpcClient(addContextId('/JSON-RPC'));
    /**
     *  An asynchronous interface to jsonrpc that will return a Promise.
     *  This is to offer an alternative to using callbacks for asynchronous requests.
     *  It is just a proxy to the underlying jsonrpc implementation, so every method on jsonrpc is exposed.
     *  It is lazily initialized, so only the methods that are actually used is initialized with a proxy.
     */
    window.asyncrpc = (function () {
        const objectProxies = {};
        return new Proxy(window.jsonrpc, {
            get(target, property) {
                if (objectProxies[property] === undefined) {
                    objectProxies[property] = new Proxy(target[property], {
                        get(target, property) {
                            const method = target[property];
                            return function (...args) {
                                return new Promise(function (resolve, reject) {
                                    function callback(res, err) {
                                        if (err) {
                                            reject(err);
                                        } else {
                                            resolve(res);
                                        }
                                    }

                                    args = [callback, ...args];
                                    method(...args);
                                });
                            };
                        },
                    });
                }
                return objectProxies[property];
            },
        });
    })();
}

//Collection methods

export function toArray(collection) {
    const length = collection.length;
    const ret = new Array(length);
    for (let i = 0; i < length; i++) {
        ret[i] = collection[i];
    }
    return ret;
}

// String methods

String.prototype.cut = function (maxLength) {
    if (maxLength >= this.length) {
        return this;
    }
    return this.substr(0, maxLength) + '...';
};

String.prototype.truncate = function (maxLength) {
    if (maxLength >= this.length) {
        return this;
    }
    return this.substr(0, maxLength);
};

String.prototype.reverse = function () {
    const a = [];
    for (let i = 0; i < this.length; i++) {
        a.push(this.substr(i, 1));
    }
    return a.reverse().join('');
};

String.prototype.divide = function (separator) {
    const ret = [];
    let last = 0;
    for (
        let i = this.indexOf(separator);
        i >= 0;
        i = this.indexOf(separator, i)
    ) {
        ret[ret.length] = this.substring(last, i);
        i++;
        last = i;
    }
    if (this.length > 0) {
        ret[ret.length] = this.substring(last);
    }
    return ret;
};

window.defaultSearch = function defaultSearch(event) {
    defaultAjaxAction($(event.target || event.srcElement), 'search', null, {
        method: 'get',
    });
    event.preventDefault ? event.preventDefault() : (event.returnValue = false);
};

window.addEventListener('tlx:logout', logout);

// To prevent infinite loop when submitting the logout form
let doLogout = false;

/**
 * TODO add CSRF token, bake in to form?
 *
 * @param event {SubmitEvent}
 */
export function logout(event) {
    if (doLogout) {
        return;
    }

    event.preventDefault();

    const logoutFunction = function () {
        const csrfToken = getCSRFToken();
        document
            .querySelectorAll('.log-off-csrfToken')
            .forEach((element) => (element.value = csrfToken));
        doLogout = true;

        // TODO Probably more data that should be cleared here
        $.sessionStorage.removeItem(
            'tlx.infoPopup' +
                '_' +
                window.contextId +
                '_' +
                window.loginEmployeeId
        );

        if (typeof window.embedded_svc !== 'undefined') {
            window.embedded_svc.liveAgentAPI.endChat();
        }

        const evt = new CustomEvent('tlx:logout-confirmed', {
            detail: { site: event.detail.site },
            cancelable: true,
        });

        window.dispatchEvent(evt);
    };

    changeTest(logoutFunction);
}

window.ensureESMModulesAreLoaded().then(() => {
    document
        .querySelectorAll('.log-off')
        .forEach((element) => element.addEventListener('submit', logout));
});

window.sumRowsInto = function sumRowsInto(
    name,
    property,
    formatFunction,
    blank,
    id,
    scope
) {
    const sum = sumRows(name, property, scope);
    if (!formatFunction) {
        formatFunction = format.decimal2;
    }
    if (!id) {
        id = name + '.sum.' + property;
    }
    setElementValue(id, formatFunction(sum, blank), scope);
    return sum;
};

function sumRows(name, property, scope) {
    let sum = 0.0;
    let i = 0;
    let deletedInput;
    do {
        deletedInput = getElement(name + '[' + i + '].deleted', scope);
        if (deletedInput && deletedInput.value == 'false') {
            sum = round2(
                sum +
                    getElementFloat(
                        name + '[' + i + '].' + property,
                        0.0,
                        scope
                    )
            );
        }
        i++;
    } while (deletedInput);
    return sum;
}

window.sumRows = sumRows;

function parseFloat2(s) {
    if (s == null) {
        return 0;
    }
    s = s.trim();

    let decimalSeparatorCode =
        decimalSeparator == '' ? 44 : decimalSeparator.charCodeAt(0);
    const groupingSeparatorCode = groupingSeparator.charCodeAt(0);
    let temp = '0';
    if (s.length > 0 && s.charCodeAt(0) == 45) {
        temp = '-0';
    } // 45 = '-'

    for (let i = 0; i < s.length; i++) {
        const charCode = s.charCodeAt(i);
        if (charCode == groupingSeparatorCode) {
            // Do nothing
        } else if (
            decimalSeparatorCode > 0 &&
            (charCode == decimalSeparatorCode ||
                charCode == 44 ||
                charCode == 46)
        ) {
            // 44 = ','  46 = '.'
            temp += '.';
            decimalSeparatorCode = -1;
        } else if (charCode >= 48 && charCode < 58) {
            temp += s.charAt(i);
        }
    }

    return parseFloat(temp);
}

window.parseFloat2 = parseFloat2;

export function checkboxFix(form) {
    const elements = toArray(form.elements); // Prevents Opera from freaking out

    for (let i = 0; i < elements.length; i++) {
        const e = elements[i];
        if (e.type && e.type.toUpperCase() == 'CHECKBOX') {
            if (!e.checked) {
                $(form).append(
                    '<input type="hidden" name="' +
                        e.name +
                        '" value="false" />'
                );
                e.name = '';
            } else if (e.disabled) {
                $(form).append(
                    '<input type="hidden" name="' + e.name + '" value="true" />'
                );
                e.name = '';
            }
        } else if (e.disabled) {
            if (
                e.tagName &&
                e.tagName.toUpperCase() == 'SELECT' &&
                e.selectedIndex >= 0
            ) {
                $(form).append(
                    '<input type="hidden" name="' +
                        e.name +
                        '" value="' +
                        e.options[e.selectedIndex].value +
                        '" />'
                );
                e.name = '';
            } else if (e.type && e.type.toUpperCase() == 'TEXT') {
                $(form).append(
                    '<input type="hidden" name="' +
                        e.name +
                        '" value="' +
                        e.value +
                        '" />'
                );
                e.name = '';
            }
        }
    }
}

window.applyRows = function applyRows(name, scope, lambda, includeDeleted) {
    let i = 0;
    let deletedInput;
    do {
        deletedInput = getElement(name + '[' + i + '].deleted', scope);
        if (deletedInput && (deletedInput.value == 'false' || includeDeleted)) {
            if (lambda(name + '[' + i + ']')) {
                return;
            }
        }
        i++;
    } while (deletedInput);
};

export function getElementInt(idOrElement, defaultValue, scope) {
    const ret = getElementValue(idOrElement, defaultValue, scope);
    return ret === defaultValue ? defaultValue : parseInt(ret);
}

window.selectAllCheckboxes = function selectAllCheckboxes(
    checkbox,
    within,
    checkboxFilter
) {
    const checked = $(checkbox).prop('checked');
    let checkboxes;
    checkboxFilter = checkboxFilter || "[name$='\\]\\.selected']";
    within = within || 'table';

    const $within = $(checkbox).closest(within);

    // Only toggle checkbox in floating header if the whole table is the target.
    if (within === 'table') {
        $('.tlxFloatingHeader')
            .find('.tlx-checkbox')
            .toggleClass('is-checked', checked);
    }

    /**
     * We want to trigger the 'change' event, but only for those checkboxes that actually are changed.
     * Use async triggering, so js interpreter won't "spin" here to all events have climbed DOM and are handled.
     */
    if (checked) {
        checkboxes = $within
            .find('input:checkbox')
            .filter(checkboxFilter)
            .not(':disabled')
            .not(':checked');
    } else {
        checkboxes = $within
            .find('input:checkbox:checked')
            .filter(checkboxFilter)
            .not(':disabled');
    }
    tlxForms.check(checkboxes, checked);
    checkboxes.asyncTrigger('change', { aSync: true, checked: checked });
};

export function getElementFloat(idOrElement, defaultValue, scope) {
    const ret = getElementValue(idOrElement, defaultValue, scope);
    return ret === defaultValue ? defaultValue : parseFloat2(ret);
}

export function getElementCheckboxInt(idOrElement, defaultValue, scope) {
    const element = isString(idOrElement)
        ? getElement(idOrElement, scope)
        : idOrElement;
    if (element) {
        return element.checked ? 1 : 0;
    }
    return defaultValue;
}

window.getElementDate = function getElementDate(
    idOrElement,
    defaultValue,
    scope
) {
    const ret = getElementValue(idOrElement, defaultValue, scope);
    return ret === defaultValue ? defaultValue : dateUtil.parseDate(ret);
};

/**
 * Ensure "check all" checkbox is checked when all checkboxes are checked.
 *
 * @param selfCheckbox the checkbox being clicked
 * @param selectAllCheckbox the "select all" checkboxes which triggers the selfChecbox, asserted as a css selector.
 * @param selectGroup the parent element that should be used to find the selectAllCheckbox element. Can be either a tbody or the parent of a tbody.
 */
window.checkSelectAllCheckboxes = function checkSelectAllCheckboxes(
    selfCheckbox,
    selectAllCheckbox,
    selectGroup
) {
    const $selectGroup = $(selfCheckbox).closest(selectGroup || 'table');
    const $selectAllCheckbox = $selectGroup.find(
        selectAllCheckbox || 'th.select input[type=checkbox]'
    );

    const $checkboxes = $selectGroup.find(
        (selectGroup === 'tbody' ? '' : 'tbody ') + 'input[type=checkbox]'
    );

    $selectAllCheckbox.prop(
        'checked',
        $checkboxes.filter(':checked').length === $checkboxes.length
    );
};

//
//BUG: GetElementValue does not work as one would expect for radio buttons, in that it only fetches the value of the
//   first element and returns that, and not of the selected element.
//
export function getElementValue(idOrElement, defaultValue, scope) {
    if (!scope && defaultValue instanceof HTMLElement) {
        scope = defaultValue;
        defaultValue = undefined;
    }
    const element = isString(idOrElement)
        ? getElement(idOrElement, scope)
        : idOrElement;
    if (!element) {
        return defaultValue;
    } else if (
        element.tagName.toUpperCase() == 'INPUT' ||
        element.tagName.toUpperCase() == 'TEXTAREA'
    ) {
        if (element.type.toUpperCase() == 'CHECKBOX') {
            return element.checked;
        } else {
            return element.value;
        }
    } else if (element.tagName.toUpperCase() == 'SELECT') {
        if (element.options.length == 0) {
            return defaultValue;
        }

        return element.options[element.selectedIndex].value;
    } else {
        if (element.innerText) {
            return element.innerText; // IE
        } else if (element.textContent) {
            return element.textContent; // FF
        } else {
            return element.innerHTML; // Emergency
        }
    }
}

window.handleOneTimePassword = function handleOneTimePassword(element) {
    sendOneTimePassword();
    cleanUpOnetimePasswordElement(element);
};

function sendOneTimePassword() {
    try {
        window.jsonrpc.OneTimePassword.generate();
    } catch (exception) {
        defaultJsonRpcExceptionHandler(exception);
    }
}

window.sendOneTimePassword = sendOneTimePassword;

function cleanUpOnetimePasswordElement(element) {
    $(element).prop('disabled', true);
    $(element).text(getMessage('text_security_onetimepassword_sent_short'));
    toolTip.remove(element); // workaround for chrome: prevents dangling tooltips due to mouseleave not being triggered when element is disabled
    setTimeout(function () {
        $(element).prop('disabled', false);
        $(element).text(getMessage('text_send_new_otp'));
    }, 5000);
}

window.cleanUpOnetimePasswordElement = cleanUpOnetimePasswordElement;

window.textInputEnterListener = function textInputEnterListener(event) {
    if (event.key === 'Enter') {
        $('.dialogMainButton').trigger('click');
    }
};

//Dynamic rows: {{
/*
 * The jsp must generate this structure: (where GROUPNAME is some string)
 * Ideally we wouldn't need the id, but for now we do.
 * <XX class="GROUPNAME" data-row-index="INDEX" id="GROUPNAME[INDEX]">
 * ...
 * </XX>
 * Only id attributes with the form GROUPNAME[INDEX].* is updated.
 * Eventhandlers must be general. (ie. not have any captured state depending on the row index)
 */

// Do not use directly with jquery
export function indexedName(groupName, index, property) {
    return groupName + '[' + index + ']' + (property ? '.' + property : '');
}

window.getPropertyIndices = function getPropertyIndices(id) {
    const re = /\[(\d+)\]/g;
    const indices = [];
    let match;
    // eslint-disable-next-line no-constant-condition
    while (true) {
        match = re.exec(id);
        if (match === null) {
            break;
        }
        indices.push(match[1]);
    }
    return indices;
};

/**
 * Updates references to other DOM nodes within a dynamic row, where id and name attributes are indexed..
 *
 */
export function deepUpdateIndices($elem, groupName, newIndex, scope) {
    let prefix = '';
    if (
        scope &&
        (scope.nodeType == 1 ||
            (scope.nodeType == undefined && $(scope).get(0).nodeType == 1))
    ) {
        //nodeType1 = ELEMENT_NODE nodeType9 = DOCUMENT_NODE
        // || ($(scope).get(0) && $(scope).get(0).nodeType == 1)
        prefix = $(scope).get(0).tlxIdPrefix;
        if (!prefix || prefix == 'null') {
            prefix = '';
        }
    }

    function escapeRegExp(str) {
        // eslint-disable-next-line no-useless-escape
        return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
    }

    const re = new RegExp(
        '^' + prefix + escapeRegExp(groupName) + '\\[([0-9]+)\\](.*)'
    );
    const reName = new RegExp(
        '^' + escapeRegExp(groupName) + '\\[([0-9]+)\\](.*)'
    );

    // NB: not prefixed
    function getUpdatedId(id) {
        // Alternative non-regex implementation:
        //		var prefixedGroupName = prefix+groupName+"[";
        //		if(id.startsWith(prefixedGroupName)) {
        //			var endIndex = id.indexOf("]", prefixedGroupName.length);
        //			if(endIndex >= 0)
        //				return groupName+"["+newIndex+"]"+id.substring(endIndex+1);
        //		}
        //		return null; // not a valid group id
        const res = re.exec(id);
        if (res === null) {
            return null;
        }
        return groupName + '[' + newIndex + ']' + res[2];
    }

    function getUpdatedName(name) {
        const res = reName.exec(name);
        if (res === null) {
            return null;
        }
        return groupName + '[' + newIndex + ']' + res[2];
    }

    /**
     * A general and hopefully water- and bullet-proof version of updating indicies for attributes connected to a dropdown.
     *
     * Updates all data-properties with a name that ends with *prop, because this is the way in tlx:dropdown to declare that the attribute
     * value points to another DOM element. Also checks that the value of this attribute actually starts with groupname, so we know(?) that
     * 1) It is actually a iterated DOM item and 2) it actually belongs to the currently copied row.
     *
     * @param el
     */
    function updateTlxDropdownAttributes(el) {
        const isDataProp = /^data-.*prop$/;
        Array.from(el.attributes).forEach(function (element) {
            if (
                isDataProp.test(element.name) &&
                element.value.startsWith(groupName + '[')
            ) {
                element.value = getUpdatedName(element.value);
            }
        });
    }

    function updateLabelFor($el) {
        const forAttr = $el.attr('for');
        const id = getUpdatedId(forAttr);
        if (id) {
            $el.attr('for', prefix + id);
        }
    }

    function updateIndexesForElement(dummyI, el) {
        const $el = $(el);

        if ($el.is('.tlx-dropdown')) {
            updateTlxDropdownAttributes(el);
        }

        if ($el.is('.caves, .tlx-dropdown__input') && el.name) {
            el.name = getUpdatedName(el.name);
        }
        if (el.tagName.toUpperCase() == 'LABEL') {
            updateLabelFor($el);
        }
        if (el.id) {
            const id = getUpdatedId(el.id);
            if (!id) {
                return;
            }
            if (el.tagName.toUpperCase()) {
                el.id = prefix + id;
            }
            if (el.name) {
                el.name = id;
            }
        }
    }

    function jQuerySelectorEscape(expression) {
        return expression.replace(
            // eslint-disable-next-line no-useless-escape
            /[!"#$%&'()*+,.\/:;<=>?@\[\\\]^`{|}~]/g,
            '\\$&'
        );
    }

    // Need to keep (and update) data attribute to support nested tables (subcontent, eg. voucher2.jsp) inside a group. (could use other marker for the group row ofc.)
    $elem.data('rowIndex', newIndex).attr('data-row-index', newIndex);

    const idPrefixSelector = jQuerySelectorEscape(prefix + groupName);
    const $elementsToUpdate = $elem
        .find("[id^='" + idPrefixSelector + '\\[' + "']")
        .add($elem)
        .add($elem.find("label[for^='" + idPrefixSelector + "']"))
        .add($elem.find('.caves'))
        .add($elem.find('.tlx-dropdown')) // For the data-*prop attributes
        .add($elem.find('.tlx-dropdown__input'));
    $elementsToUpdate.each(updateIndexesForElement);

    return $elem;
}

export function updateDeleteButton($elem, groupName, newIndex, scope) {
    const $deleteButtons = $elem.find('[name="DeleteButton"]'); // non unique id

    if ($deleteButtons.length != 1) {
        return;
    }
    if ($deleteButtons.attr('onclick')) {
        if (
            $deleteButtons.attr('onclick').lastIndexOf('deleteRow2(', 0) !== 0
        ) {
            // startsWith equivalent
            return;
        }
        let clickAttr = "deleteRow2('" + groupName + "', " + newIndex;
        if (scope) {
            clickAttr += ', undefined, tlxGetScope(this)';
        }
        clickAttr += ')';
        $deleteButtons.attr('onclick', clickAttr);
    }
}

/**
 * The order of lines in DOM might not be the same as the indexes.
 * Therefore this function is converted to change all numbers in
 * the table, not just the given element.
 *
 * Some naïve assumptions:
 *
 * We are always in a table.contentTable.
 * [data-row-index] is the ordering element.
 *
 *
 * This is used (and presumably works) in order.jsp and updateExtraCosts.jsp
 *
 * @param $elem any element in the current table we are working on
 */
export function updateDigits($elem) {
    // Iterating all rows for getting the correct (GUI) index,
    // then iterating over possible elements to change index in.
    $elem
        .closest('table.contentTable')
        .find('tr[data-row-index]:visible')
        .each(function (index) {
            $('.table-dynamic--incrementNumber', this).each(function () {
                const $this = $(this);
                const text = $this.text();
                if ($this.is('span')) {
                    $this.text(index + 1);
                    return;
                }

                if ($this.is('button')) {
                    $this.text(text.replace(/[0-9]+/, index + 1));
                }
            });
        });
}

/**
 * returns templateProperty "aligned" to the sourceProperty. eg. templateProperty = employments[].guiSalary[0].date, sourceProperty = employments[3].startDate => employments[3].guiSalary[0].date
 */
function alignIndexedProperty(templateProperty, sourceProperty) {
    let aligned = '';
    let a = 0;
    let b = 0;
    let i = 0;
    // eslint-disable-next-line no-constant-condition
    while (true) {
        b = templateProperty.indexOf('[]', b + 1);
        if (b < 0) {
            break;
        }
        i = sourceProperty.indexOf('[', i + 1);
        aligned += templateProperty.substr(a, b - a);
        aligned += '[';
        aligned += sourceProperty.substr(
            i + 1,
            sourceProperty.indexOf(']', i) - (i + 1)
        );
        aligned += ']';

        a = b;
    }
    if (a == 0) {
        return templateProperty;
    } else {
        return aligned + templateProperty.substr(a + 2);
    }
}

window.alignIndexedProperty = alignIndexedProperty;

/**
 * onchange utility: updates the target's value to the value of sourceElement (using the sourceElement's scope).
 * targetIdOrElement can be a "index-template" property. (employments[].guiSalary[0].date). The unfilled indices are populated with the corresponding indices of sourceElement
 */
window.linkElement = function linkElement(sourceElement, targetIdOrElement) {
    if (isString(targetIdOrElement)) {
        targetIdOrElement = alignIndexedProperty(
            targetIdOrElement,
            sourceElement.name
        );
    }
    setElementValue(
        targetIdOrElement,
        getElementValue(sourceElement),
        tlxGetScope(sourceElement)
    );
};

/**
 * @deprecated use tlxForms.change
 * Used for text content, not HTML content
 */
export function setElementValue(idOrElement, value, scope) {
    if (idOrElement instanceof jQuery) {
        idOrElement = idOrElement[0];
    }
    value = isString(value) ? value : '' + value;
    const element = isString(idOrElement)
        ? getElement(idOrElement, scope)
        : idOrElement;
    if (!element || !element.tagName) {
        return;
    }
    if (
        element.tagName.toUpperCase() == 'INPUT' ||
        element.tagName.toUpperCase() == 'TEXTAREA'
    ) {
        if (element.type.toUpperCase() == 'CHECKBOX') {
            tlxForms.check(element, value == 'true' || value == true);
        } else if (element.type.toUpperCase() == 'HIDDEN') {
            element.value = value;
        } else {
            tlxForms.change(element, value);
        }
    } else if (element.tagName.toUpperCase() == 'SELECT') {
        const options = element.options;
        for (let i = 0; i < options.length; i++) {
            if (options[i].value == value) {
                element.selectedIndex = i;
                break;
            }
        }
    } else {
        element.innerText = value;
    }
}

//struts style with pre-generated hidden rows
window.addRow = function addRow(groupName, cancel, scope) {
    if (!cancel) {
        let last = null;
        let deleted = null;
        let size = getTableRowCount(groupName, scope);
        do {
            size--;
            last = deleted;
            deleted = getElement(groupName + '[' + size + '].deleted', scope);
        } while (
            deleted &&
            deleted.value == 'true' &&
            !deleted.getAttribute('deleteRow.dirty')
        );
        if (last) {
            last.value = 'false';
            if (scope && scope.fillRow) {
                scope.fillRow(size + 1, groupName);
            } else if (window.fillRow) {
                window.fillRow(size + 1, groupName);
            }
            const groupNameStr = groupName + '[' + (size + 1) + ']';
            const row = getElement(groupNameStr, scope);
            showElement(row, scope);
            $(row)
                .find(':input:visible')
                .not("[name='DeleteButton']")
                .first()
                .focus();
            changed();
        }
    }
};

export function getTableRowCount(groupName, scope) {
    if (scope && scope.getTableRowCount) {
        return scope.getTableRowCount(groupName);
    }
    let row = getElement(groupName + '[0]', scope);

    if (row) {
        const table = getParentTable(row);
        let attrName = groupName + '.getTableRowCount.count';
        attrName = attrName.replace(/\[/g, '');
        attrName = attrName.replace(/\]/g, '');
        let count = table.getAttribute(attrName);

        if (count != undefined && count != null) {
            return count;
        } else {
            for (count = table.childNodes.length; count > 0; count--) {
                row = getElement(groupName + '[' + (count - 1) + ']', scope);

                if (row) {
                    table.setAttribute(attrName, count);
                    return count;
                }
            }
        }
    } else {
        return 0;
    }
}

//TODO: is cancel in use?
window.deleteRow2 = function deleteRow2(groupName, index, cancel, scope) {
    if (!cancel) {
        const rowName = groupName + '[' + index + ']';
        const deleted = getElement(rowName + '.deleted', scope);
        deleted.setAttribute('deleteRow.dirty', true);
        deleted.value = 'true';
        const row = getElement(rowName, scope);
        hideElement(row, scope);
        // Removes validation errors inside row (duplicated in voucher2.jsp:deleteRow...)
        // TODO ojb: still possible to click next/prev in validationPopup to get to the hidden elements and cause a visual glitch)
        //           Solutions? "deleted" event?
        $('.ui-state-error', row).trigger('change');
        changed();
        if (scope && scope.refreshTable) {
            scope.refreshTable(groupName);
        } else if (window.refreshTable) {
            window.refreshTable(groupName);
        }
    }
};

function getParentTable(node) {
    const candidate = getParentNode(node, 'TBODY');
    return candidate ? candidate : getParentNode(node, 'TABLE');
}

window.getParentTable = getParentTable;

function getParentNode(node, type) {
    const parent = node.parentNode;
    if (!parent) {
        return null;
    }
    if (!type || parent.nodeName == type.toUpperCase()) {
        return parent;
    }
    return getParentNode(parent, type);
}

window.getParentNode = getParentNode;

//TODO: add error handler (currently no good way for calling code to do cleanup on error)
export function makeSafeCallback(callback) {
    const token = window.token;
    return function (result, exception) {
        let tok = null;
        try {
            tok = token;
        } catch (e) {
            return;
        }
        if (tok == token) {
            if (exception) {
                defaultJsonRpcExceptionHandler(exception);
            } else {
                callback(result);
            }
        }
    };
}

export function getScopeId(propertyId) {
    const $element = $(getElement(propertyId));
    let scopeId = $element.data('scopeId');
    if (!scopeId) {
        scopeId = $element.closest('.tlxScope').attr('id');
        if (!scopeId) {
            scopeId = '';
        }
        if (scopeId) {
            $element.data('scopeId', scopeId);
        }
    }
    return scopeId;
}

export function collapseExtra() {
    const main = window;
    if (main && main.extraFrame) {
        main.extraFrame.collapse();
    }
}

// From commons-jq

/**
 * If scope is in another frame than the callee, it must be set to the _document_ in that frame, not some other element
 * TODO: how to handle radio buttons?
 *
 * DEPRECATED! Use $(":prop('<property>')", scope) for getting elements, where scope is a DOM-Element or a selector.
 */
export function getElement(elementId, scope) {
    let prefix = '';
    if (!scope) {
        // Do nothing
    } else if (
        scope.nodeType === 1 ||
        (scope.nodeType == undefined && $(scope).get(0).nodeType === 1)
    ) {
        /**
         * Avoid looking at ID when picking up element.
         */
        const result =
            scope.querySelector &&
            scope.querySelector('[name="' + elementId + '"]');
        if (result) {
            return result;
        }

        prefix = $(scope).get(0).tlxIdPrefix;
        if (!prefix || prefix === 'null') {
            prefix = '';
        } else if (elementId.indexOf(prefix) === 0) {
            /**
             * This is a test to check if prefix has already been added to the elementId. But sometimes elementId can
             * actually randomly start with prefix, without it being added (for instance wageRuleswageRuless[0]).
             * Therefore, try to fetch it with prefix either way, and return if something is found.
             */
            const element = document.getElementById(prefix + elementId);
            if (element !== null) {
                return element;
            }
            //If prefix is already part of the elementID;
            prefix = '';
        }
    } else if (scope.nodeType === 9) {
        // scope is of type 'document'
        return scope.getElementById(elementId);
    }
    return document.getElementById(prefix + elementId);
}

/**
 * Gets the scope as html element of a parent of the input html element.
 * @param htmlElement find the scope of this element
 * @returns the scope
 */
export function tlxGetScope(htmlElement) {
    let scope = $(htmlElement).closest('.tlxScope').get(0);
    if (!scope) {
        dev.consoleLog(
            'No explicit scope found for element',
            htmlElement,
            'using document.body'
        );
        scope = document.body;
    }
    return scope;
}

window.initChangeLog = function initChangeLog(
    classId,
    id,
    changelogPopupOKButtonTrackingId = ''
) {
    if (id > 0) {
        $('#changeLogLink').popupOpener({
            url: addContextId(
                '/execute/changeLog?classId=' + classId + '&id=' + id
            ),
            closeText: 'OK',
            closeTrackingId: changelogPopupOKButtonTrackingId,
        });
    }
};

/**
 * Returns a function that either just calls the {@code actionName} with the given {@code options}, or a function that forces the user to confirm the action,
 * with a popup containing the warning key found in {@code options}.
 * @param actionName The function to be called by the returned function
 * @param options Contains the options. withWarning is a boolean or function returning a boolean which determines whether to give the user a warning.
 *          warningKey is the text key to be displayed in the dialog. This may contain additional options, which will be passed on to defaultToolbarAction.call().
 * @returns {Function}
 */
window.toolbarActionCallback = function toolbarActionCallback(
    actionName,
    options
) {
    return window.toolbarActionCallbackWithTrackingId(
        actionName,
        options,
        '',
        ''
    );
};

/**
 * Returns a function that either just calls the {@code actionName} with the given {@code options}, or a function that forces the user to confirm the action,
 * with a popup containing the warning key found in {@code options}.
 * @param actionName The function to be called by the returned function
 * @param options Contains the options. withWarning is a boolean or function returning a boolean which determines whether to give the user a warning.
 *          warningKey is the text key to be displayed in the dialog. This may contain additional options, which will be passed on to defaultToolbarAction.call().
 * @param confirmDialogOKButtonTrackingId The confirmation dialog OK button's tracking ID
 * @param confirmDialogCancelButtonTrackingId The confirmation dialog Cancel button's tracking ID
 * @returns {Function}
 */
window.toolbarActionCallbackWithTrackingId =
    function toolbarActionCallbackWithTrackingId(
        actionName,
        options,
        confirmDialogOKButtonTrackingId,
        confirmDialogCancelButtonTrackingId
    ) {
        if (options && options.withWarning) {
            return function () {
                // eslint-disable-next-line @typescript-eslint/no-this-alias
                const eventThis = this;
                if (!options.warningKey) {
                    options.warningKey = 'text_sure_not_undo';
                }
                if (
                    typeof options.withWarning === 'function' &&
                    !options.withWarning()
                ) {
                    defaultToolbarAction.call(this, actionName, options);
                } else {
                    tlxConfirmWithTrackingId(
                        function () {
                            defaultToolbarAction.call(
                                eventThis,
                                actionName,
                                options
                            );
                        },
                        getMessage(options.warningKey),
                        undefined,
                        confirmDialogOKButtonTrackingId,
                        confirmDialogCancelButtonTrackingId
                    );
                }
            };
        } else {
            return function () {
                defaultToolbarAction.call(this, actionName, options);
            };
        }
    };

window.clickIfChecked = function clickIfChecked($elem) {
    if ($elem.prop('checked')) {
        $elem.triggerHandler('click');
    }
};

window.doWithWarning = function doWithWarning(func) {
    tlxConfirm(func, getMessage('text_sure_not_undo'));
};

/** see defaultAjaxAction for documentation of options */
function defaultToolbarAction(methodName, options) {
    if ($(this).hasClass('ui-dialog-content')) {
        //In a dialog/pop-up "this" is the content of the dialog
        const $toolbarButton = $(this).data('popupOpener');
        const $closestForm = $toolbarButton.closest('form');
        const $form = $(this).add($closestForm);

        if (options && options.dialogClientsideValidation) {
            if (
                !$form
                    .findFormInput()
                    .not(':hidden')
                    .not(':disabled')
                    .validate($form.validationPopup())
            ) {
                return;
            }
        }

        // Trigger a submit event so that we may track form submissions
        $closestForm.trigger('submit');

        defaultAjaxAction($form, methodName, $(this), options);
    } else {
        //As a toolbar button, "this" is the table which the toolbar is attached
        const $form = $(this).closest('form');

        // Without further ado, let us perform some client side validation.
        if (options && options.clientsideValidation) {
            const isValid = $form
                .findFormInput()
                .not(':hidden')
                .not(':disabled')
                .validate($form.validationPopup());
            if (!isValid) {
                return;
            }
        }

        // Trigger a submit event so that we may track form submissions
        $form.trigger('submit');

        defaultAjaxAction($form, methodName, undefined, options);
    }
}

window.defaultToolbarAction = defaultToolbarAction;

export function showElement(idOrElement, scope) {
    let $element = isString(idOrElement)
        ? $(getElement(idOrElement, scope))
        : $(idOrElement);
    if ($element.length == 0) {
        return;
    }

    if ($element.is('.tlx-dropdown__input')) {
        $element = $element.closest('.tlx-dropdown');
    }

    $element.show();

    if ($element.is('.hasHeader')) {
        $element.prev().show();
    }
    $(scope || tlxGetScope($element[0])).trigger('tlxPossibleWidthChange');
}

////These functions is usually used in combination with addNewRow-type tables.
window.getRow = function getRow(elem) {
    return $(elem).closest('[data-row-index]');
};

window.getRowIndex = function getRowIndex(elem) {
    return $(elem).closest('[data-row-index]').data('rowIndex');
};

export function getScopedId(propertyId, scope) {
    if (scope && scope.tlxIdPrefix) {
        return scope.tlxIdPrefix + propertyId;
    }
    return propertyId;
}

/**
 * Create a callback suitable for stateless csv export. The callback adds the url query for the current page as a parameter to the form.
 * This can be used in the target form to return a download url for export.
 * See https://tripletex.atlassian.net/wiki/display/DOC/Struts+mode -> CSV export for more background
 */
window.csvExportActionCallback = function csvExportActionCallback(actionName) {
    return function () {
        const url = tlxUrl.removeUrlParameter(getRelevantUrl(), 'act');
        const query = url.substring(url.indexOf('?') + 1);
        defaultToolbarAction.call(this, actionName, {
            form: { filterQuery: query },
        });
    };
};

function createHiddenInputs(paramMap) {
    // FRAMEWORK: should be shared with bindEventsInScope but not sure where to put it
    const namesToSkip = {
        act: 1,
        contextId: 1,
        javaClass: 1,
        documentationComponent: 1,
    };
    let inputsHtml = '';
    $.each(paramMap, function (param, value) {
        if (param in namesToSkip) {
            return;
        } // continue
        inputsHtml +=
            "<input type='hidden' name='" +
            param +
            "' value='" +
            encodeHTML(value) +
            "'/> ";
    });
    return inputsHtml;
}

/**
 * A loader implementing logic for "lazy content loading"
 * - loads the "content" of a page given the filter url (ie. the regular url)
 * - cancels the last issued request if not completed by the time the next request is started.
 * - does not issue new request if the url is the same as the pending one.
 * - initializes the content when loaded and calls its tlxInitializeState with correct scope (if defined)
 * - ensures that the post form(s) contains the inputs used for retrieval
 * - handles loading animation
 * - dialogs (moved out of the content container when used) is cleared by the widget-destroy system
 * - does not touch the browser history
 * - self-destructs on event tlxNavigateAjax
 *
 * @param $contentContainer The container to insert (i.e. replace) the loaded content in. Should be attached in the correct scope.
 * @param additionalParams Additional parameters to be added to the URL of the lazy loaded content.
 *                         Example: 'isProjectMenuTab=true'
 */
export function LazyContentLoader($contentContainer, ...additionalParams) {
    let runningXhr;
    let runningUrl = '';
    let scope = tlxGetScope($contentContainer.get(0));
    const scopePrefix = scope.tlxIdPrefix || '';

    // Needed for loading animation - somewhat ad-hoc
    $contentContainer.css('position', 'relative').css('min-height', '30px');

    $(window).one('tlxNavigateAjax', function () {
        if (runningXhr) {
            runningXhr.abort();
        }
        // leak paranoia:
        $contentContainer = null;
        runningXhr = null;
        scope = null;
    });

    $contentContainer.tlxLoader();

    /**
     * @param onLoadBegin Called before loading starts (after changed check and duplicate request check is passed)
     * @param onLoadDone Called when a request completes. Arguments are the contentContainer, the url that completed, status ("abort" if this is a replaced request)
     */
    return function loadContentLazily(filterUrl, onLoadBegin, onLoadDone) {
        let contentUrl = tlxUrl.addUrlParameters(
            filterUrl,
            'act',
            'content',
            'scope',
            scopePrefix
        );
        contentUrl = addContextId(contentUrl);

        for (const param of additionalParams) {
            if (param.trim() !== '') {
                contentUrl += '&' + param;
            }
        }

        if (runningXhr) {
            if (runningUrl == filterUrl) {
                return false;
            } else {
                runningXhr.abort();
                runningXhr = null;
                runningUrl = '';
            }
        }

        if (onLoadBegin) {
            onLoadBegin();
        }

        const showOverlay = $contentContainer.children().length > 0;

        $contentContainer.tlxLoader('start', {
            showOverlay: showOverlay,
            loaderPositionY: showOverlay ? 90 : 0,
        });

        $contentContainer.trigger('tlxLoadContentLazily');
        window.dispatchEvent(
            new CustomEvent('pageLoad-loadContentLazily', {
                detail: { url: contentUrl },
            })
        );
        $contentContainer.trigger('allValidationsCleared'); // Refresh -> ignore possible changes and validation messages

        runningUrl = filterUrl;

        runningXhr = $.ajax(contentUrl, {
            headers: {
                [CSRF_HEADER_NAME]: getCSRFToken(),
            },
            complete: function (xhr, textStatus) {
                // Implementation choice:
                // We use ajax, instead of $.get because we handle error and success mostly the same. (both insert the received content, etc.)
                // The promise based api of $.get (jqXhr.always) has retarded parameter semantics. The parameters meaning change based on the success status
                // of the request so it's a pain to use. [Ole - 22. mai 2014]
                if (xhr.status === 401) {
                    return; // Don't do anything - this is taken care of by the global ajaxError handler
                }

                try {
                    if (textStatus != 'abort') {
                        if (
                            xhr.status === 0 &&
                            xhr.responseText === undefined
                        ) {
                            const event = jQuery.Event('tlxCommunicationError');
                            event.xhr = xhr;
                            $contentContainer.trigger(event);
                            return;
                        }

                        $contentContainer
                            .trigger('tlxRemoveUpgradedMdlComponents')
                            .html(xhr.responseText);

                        bindEventsInsideScope($contentContainer);

                        // Important that happens after bindEventsInsideScope because bindEventsInsideScope interacts with the copies
                        // Alternatively we could do more clearing of the copies
                        $contentContainer
                            .find('form[method="post"]')
                            .append(
                                createHiddenInputs(
                                    tlxUrl.getUrlParameters(filterUrl)
                                )
                            );

                        if (window.tlxInitializeState) {
                            /**
                             * This is NOT how we should load scripts for lazily loaded content.
                             * If you need to run javascript every time the content of a lazily
                             * loaded report table is done loading, we have an event listener for
                             * this (tlxLazyLoadDone)!
                             */
                            console.warn(
                                'Detected script tag in lazily loaded page, please remove this!'
                            );
                            console.warn(
                                'OBS! Script file for lazily loaded pages can _not_ be loaded asynchronously.'
                            );
                            const $scope =
                                $contentContainer.closest('.tlxScope');

                            app.loadPageScript($scope);
                        }
                    }
                } finally {
                    if (onLoadDone) {
                        onLoadDone($contentContainer, filterUrl, textStatus);
                    } // Wrap stuff in try catch to ensure that this always is called?
                    if ($contentContainer.tlxLoader('instance')) {
                        $contentContainer.tlxLoader('stop');
                    }

                    // Don't trigger lazy load done if lazy load is aborted.
                    if (textStatus != 'abort') {
                        $contentContainer.trigger('tlxLazyLoadDone', filterUrl);

                        window.dispatchEvent(
                            new CustomEvent('pageLoad-lazyLoadDone', {
                                detail: { url: contentUrl },
                            })
                        );
                    }
                    // Clear running state
                    runningXhr = null;
                    runningUrl = '';
                }
            },
        });
        return true;
    };
}
