import * as React from 'react';

import './FormGroup.scss';
import { forwardRef } from 'react';

type FormGroupProps = {
    children: any;
    invalid?: boolean;
    validationMessage?: string;
    helpText?: string;
    className?: string;
    dense?: boolean;
};

/**
 * A FormGroup is a potential complex form input element, with validation messages, help texts,
 * multiple input elements, and so forth.
 *
 * @param children
 * @param invalid
 * @param validationMessage
 * @param helpText
 * @constructor
 */
export const FormGroup = forwardRef<HTMLDivElement, FormGroupProps>(
    (
        {
            children,
            invalid = false,
            validationMessage,
            helpText,
            className,
            dense,
        },
        ref
    ) => {
        const classNames = ['tlx-formgroup'];
        className && classNames.push(className);
        dense && classNames.push('tlx-formgroup--dense');

        return (
            <div className={classNames.join(' ')} ref={ref}>
                {children}
                {validationMessage && (
                    <div
                        className="tlx-formgroup__validation"
                        // A proper solution / cleanup will be needed before this one can be removed
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{ __html: validationMessage }}
                    />
                )}
                {helpText && (
                    <div className="tlx-formgroup__helptext">{helpText}</div>
                )}
            </div>
        );
    }
);
