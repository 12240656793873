// Smooth scrolling within a DIV container
// Ideally you would use https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
// but Safari iOS don't support smooth scrolling...
window.smoothScroll = (function () {
    function step(context) {
        const time = Date.now();
        let elapsed = (time - context.startTime) / context.scrollTime;

        // avoid elapsed times higher than one
        elapsed = elapsed > 1 ? 1 : elapsed;

        // apply easing to elapsed time
        const value = 0.5 * (1 - Math.cos(Math.PI * elapsed));

        const currentX = context.startX + (context.x - context.startX) * value;
        const currentY = context.startY + (context.y - context.startY) * value;

        context.scrollable.scrollLeft = currentX;
        context.scrollable.scrollTop = currentY;

        // scroll more if we have not reached our destination
        if (currentX !== context.x || currentY !== context.y) {
            window.requestAnimationFrame(step.bind(window, context));
        }
    }

    function smoothScroll(el, x, y) {
        const startTime = Date.now();
        const distance = Math.max(
            Math.abs(x - el.scrollLeft),
            Math.abs(y - el.scrollTop)
        );
        if (distance === 0) {
            return;
        }

        // scroll looping over a frame
        step({
            scrollable: el,
            startTime: startTime,
            startX: el.scrollLeft,
            startY: el.scrollTop,
            x: x,
            y: y,
            scrollTime: distance * 2,
        });
    }

    return smoothScroll;
})();
