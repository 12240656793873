import { Key } from 'swr';
import { createAPIRequest } from './createAPIRequest';
import { createAPIResponse } from './createAPIResponse';

export async function defaultFetcher<T = unknown>(key: Key): Promise<T> {
    const url = getUrl(key);
    const request = createAPIRequest(url);
    const response = await window.fetch(request);

    return createAPIResponse(request, response);
}

function getUrl(key: Key): string {
    if (typeof key === 'string') {
        return key;
    }

    if (Array.isArray(key)) {
        return key[0];
    }

    throw new Error(`Key needs to be a string or an array, got ${key} instead`);
}
