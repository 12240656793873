import React from 'react';
import { Button, Group, Input, Label, RadioGroup } from '@tlx/atlas';
import Message from '@Component/Messages/component/Message/Message';
import { MESSAGE_POSITION, MESSAGE_TYPE } from '@Component/Messages/types';
import {
    FormProps,
    InvoicingFormProps,
} from '@Page/InvoicingDialog/component/InvoicingForm/InvoicingForm';
import { ValidationError } from '@Page/InvoicingDialog/component/ValidationError';
import { getSendTypeErrors } from '@Page/InvoicingDialog/ValidationUtil';
import { Markdown } from '@Component/Markdown';
import { ElectronicReceiveState } from '@Page/InvoicingDialog/component/InvoicingForm/Hooks';
import {
    Customer,
    InvoiceSettings,
    Order,
} from '@Page/InvoicingDialog/types/InvoicingDialog.type';
import { getSendMethodName } from '@Page/InvoicingDialog/utils/getSendMethodName';
import { CustomerCategoryEnum } from '@General/types/customerCategory.enum';

export const SendTypes = {
    EMAIL: 'EMAIL',
    EHF: 'EHF',
    PAPER: 'PAPER',
    EFAKTURA: 'EFAKTURA',
    AVTALEGIRO: 'AVTALEGIRO',
    MANUAL: 'MANUAL',
    VIPPS: 'VIPPS',
};

export function SendMethod(props: InvoicingFormProps & FormProps) {
    const {
        order,
        invoiceSettings,
        electronicReceiveState,
        fields,
        errors,
        watch,
        isUserAdmin,
        isCompanyAdmin,
    } = { ...props };

    const customer = order.customer;
    const isTestAccount =
        window.segmentationData.tripletexCustomerCategoryId3 ===
        CustomerCategoryEnum.TRIPLETEX_TEST;

    const selectedSendType = watch('invoiceSendMethod');

    const sendTypesToShow = getSendTypesToShow(
        order.customer,
        invoiceSettings.sendTypes
    );

    const hasVipps = invoiceSettings.sendTypes.includes(SendTypes.VIPPS);
    const hasEfaktura = invoiceSettings.sendTypes.includes(SendTypes.EFAKTURA);
    const canSendEFaktura = hasEfaktura || hasVipps;
    const canSendEHF = invoiceSettings.sendTypes.includes(SendTypes.EHF);

    const canSendElectronic = customer.isPrivateIndividual
        ? canSendEFaktura
        : canSendEHF;

    const sendMethodsRadioButtons = (
        <>
            {getMessage('text_send_type')}
            <div className="atl-mb-16">
                <div className={sendTypesToShow.length < 5 ? 'atl-flex' : ''}>
                    {sendTypesToShow.map((sendType) => {
                        return (
                            <RadioGroup
                                key={sendType}
                                className="atl-py-4 atl-mr-16"
                            >
                                <Input
                                    data-testid={'send-type-radio-' + sendType}
                                    id={sendType}
                                    type="radio"
                                    value={sendType}
                                    {...fields.invoiceSendMethod}
                                />
                                <Label htmlFor={sendType}>
                                    {getSendMethodName(sendType)}
                                </Label>
                            </RadioGroup>
                        );
                    })}
                </div>
                <ValidationError error={errors.invoiceSendMethod} />
            </div>
        </>
    );

    const sendTypeEmail = (
        <>
            <Group>
                <Label htmlFor={fields.customerEmail.name}>
                    {getMessage('text_email')}
                </Label>
                <Input
                    data-testid="customer-email-input"
                    type="text"
                    className="atl-w-full"
                    disabled={isTestAccount}
                    {...fields.customerEmail}
                />
                <ValidationError error={errors.customerEmail} />
            </Group>
        </>
    );

    const sendTypeErrors = getSendTypeErrors(customer, selectedSendType);
    const sendTypeEHF = (
        <>
            {!canSendEHF && (
                <ActivateEHFMessage
                    isUserAdmin={isUserAdmin}
                    isCompanyAdmin={isCompanyAdmin}
                />
            )}
            {sendTypeErrors === '' &&
                electronicReceiveState === ElectronicReceiveState.NO &&
                canSendEHF && (
                    <>
                        <SimpleMessage
                            text={getMessage(
                                'text_can_not_receive_ehf',
                                customer.name
                            )}
                        />
                    </>
                )}
            {canSendEHF && sendTypeErrors !== '' && (
                <SimpleMessage text={sendTypeErrors} />
            )}
            {sendTypeErrors === '' &&
                electronicReceiveState === ElectronicReceiveState.ERROR &&
                canSendEHF && (
                    <>
                        <SimpleMessage
                            text={getMessage('text_elma_lookup_failed_ehf')}
                        />
                    </>
                )}
        </>
    );

    return (
        <div className="atl-mb-16">
            {sendMethodsRadioButtons}
            <div>
                <SendTypeSuggestion
                    {...{
                        electronicReceiveState,
                        canSendElectronic,
                        selectedSendType,
                        order,
                        invoiceSettings,
                    }}
                />
                {selectedSendType === SendTypes.EMAIL && sendTypeEmail}
                {selectedSendType === SendTypes.EHF && sendTypeEHF}
                {selectedSendType === SendTypes.EFAKTURA && (
                    <SendTypeEfaktura {...props} />
                )}
            </div>
        </div>
    );
}

function SendTypeSuggestion({
    electronicReceiveState,
    canSendElectronic,
    selectedSendType,
    order,
    invoiceSettings,
}: {
    electronicReceiveState: ElectronicReceiveState;
    canSendElectronic: boolean;
    selectedSendType: string;
    order: Order;
    invoiceSettings: InvoiceSettings;
}) {
    const isReasonForSuggestingDifferentSendType =
        electronicReceiveState === ElectronicReceiveState.YES &&
        canSendElectronic &&
        ((order.customer.invoiceSendMethod &&
            order.customer.invoiceSendMethod !== SendTypes.AVTALEGIRO) ||
            selectedSendType === SendTypes.EMAIL ||
            selectedSendType === SendTypes.PAPER);

    const isSelectedSendTypeChangedFromInvoiceSendMethod =
        order.customer.invoiceSendMethod !== selectedSendType;

    const isSwitchedToEFakturaByTripleTex =
        selectedSendType === SendTypes.EFAKTURA &&
        invoiceSettings.defaultSendTypeB2C === SendTypes.EFAKTURA &&
        isSelectedSendTypeChangedFromInvoiceSendMethod;

    const isSwitchedToEhfByTripleTex =
        selectedSendType === SendTypes.EHF &&
        invoiceSettings.defaultSendTypeB2B === SendTypes.EHF &&
        isSelectedSendTypeChangedFromInvoiceSendMethod;

    const isPrivateCustomerAndSwitchedToEfaktura =
        isSwitchedToEFakturaByTripleTex && order.customer.isPrivateIndividual;

    const isBusinessCustomerAndSwitchedToEhf =
        isSwitchedToEhfByTripleTex && !order.customer.isPrivateIndividual;

    const isPrivateCustomerAndWantsEfaktura =
        isSwitchedToEFakturaByTripleTex && order.customer.isPrivateIndividual;

    const isBusinessCustomerAndWantsEhf =
        !order.customer.isPrivateIndividual &&
        selectedSendType !== SendTypes.EHF;

    if (!isReasonForSuggestingDifferentSendType) {
        return null;
    }

    if (
        isPrivateCustomerAndSwitchedToEfaktura ||
        isBusinessCustomerAndSwitchedToEhf
    ) {
        return (
            <p>
                <Markdown
                    textKey={
                        order.customer.isPrivateIndividual
                            ? 'text_customer_switched_to_efaktura'
                            : 'text_customer_switched_to_ehf'
                    }
                    textKeyArgs={[order.customer.name]}
                />
            </p>
        );
    }

    if (isPrivateCustomerAndWantsEfaktura || isBusinessCustomerAndWantsEhf) {
        return (
            <p>
                <Markdown
                    textKey={
                        order.customer.isPrivateIndividual
                            ? 'text_customer_wants_efaktura'
                            : 'text_customer_wants_ehf'
                    }
                    textKeyArgs={[order.customer.name]}
                />
            </p>
        );
    }
    return null;
}

function SendTypeEfaktura({
    order,
    isCompanyAdmin,
    isUserAdmin,
    fields,
    electronicReceiveState,
    updateCanReceiveElectronic,
    watch,
    invoiceSettings,
}: InvoicingFormProps & FormProps) {
    const customerPhoneNumber = watch('customerPhoneNumber');
    const customer = order.customer;

    const hasVipps = invoiceSettings.sendTypes.includes(SendTypes.VIPPS);
    const hasEfaktura = invoiceSettings.sendTypes.includes(SendTypes.EFAKTURA);
    const canSendEFaktura = hasEfaktura || hasVipps;

    return (
        <>
            {!canSendEFaktura && (
                <ActivateEfakturaMessage
                    isCompanyAdmin={isCompanyAdmin}
                    isUserAdmin={isUserAdmin}
                />
            )}
            {electronicReceiveState !== ElectronicReceiveState.YES &&
                canSendEFaktura &&
                !customer.phoneNumber &&
                !customer.phoneNumberMobile && (
                    <div>
                        <SimpleMessage
                            text={getMessage(
                                'text_add_phone_number_warning',
                                customer.name
                            )}
                        />
                        <div className="atl-grid tlx-invoicingDialog__gridCols2 atl-mb-16">
                            <Group className="atl-mr-16">
                                <Label
                                    htmlFor={fields.customerPhoneNumber.name}
                                >
                                    {getMessage('text_phone_number')}
                                </Label>
                                <Input
                                    data-testid="customer-phone-number-input"
                                    type="text"
                                    className="atl-w-full"
                                    {...fields.customerPhoneNumber}
                                />
                            </Group>
                            <Button
                                data-testid="update-customer-button"
                                onClick={() =>
                                    updateCanReceiveElectronic(
                                        customerPhoneNumber
                                    )
                                }
                                variant="tertiary"
                                className="atl-mt-auto"
                            >
                                {getMessage('button_search')}
                            </Button>
                        </div>
                    </div>
                )}
            {electronicReceiveState === ElectronicReceiveState.NO &&
                canSendEFaktura &&
                (customer.phoneNumber || customer.phoneNumberMobile) && (
                    <SimpleMessage
                        text={getMessage(
                            'text_can_not_receive_efaktura',
                            customer.name
                        )}
                    />
                )}
            {electronicReceiveState === ElectronicReceiveState.ERROR &&
                canSendEFaktura && (
                    <>
                        <SimpleMessage
                            text={getMessage(
                                'text_elma_lookup_failed_efaktura'
                            )}
                        />
                    </>
                )}
        </>
    );
}

function SimpleMessage({ text }: { text: string }) {
    return (
        <Message
            extraClassnames="atl-mb-16"
            message={{
                text: text,
                type: MESSAGE_TYPE.INFO,
                position: MESSAGE_POSITION.INLINE,
                isCloseable: false,
            }}
        />
    );
}

function ActivateEfakturaMessage({
    isCompanyAdmin,
    isUserAdmin,
}: {
    isCompanyAdmin: boolean | undefined;
    isUserAdmin: boolean | undefined;
}) {
    if (isUserAdmin || isCompanyAdmin) {
        return (
            <Message
                extraClassnames="atl-mb-16"
                message={{
                    text: getMessage('text_efaktura_get_monney_faster'),
                    type: MESSAGE_TYPE.INFO,
                    position: MESSAGE_POSITION.INLINE,
                    isCloseable: false,
                    action: {
                        text: getMessage('text_activate_efaktura'),
                        onClick: () =>
                            window.open(
                                addContextId('/execute/mySubscription')
                            ),
                    },
                }}
            />
        );
    } else {
        return (
            <Message
                extraClassnames="atl-mb-16"
                message={{
                    text: getMessage(
                        'text_efaktura_get_monney_faster_no_access'
                    ),
                    type: MESSAGE_TYPE.INFO,
                    position: MESSAGE_POSITION.INLINE,
                    isCloseable: false,
                }}
            />
        );
    }
}

function ActivateEHFMessage({
    isCompanyAdmin,
    isUserAdmin,
}: {
    isCompanyAdmin: boolean | undefined;
    isUserAdmin: boolean | undefined;
}) {
    if (isUserAdmin || isCompanyAdmin) {
        return (
            <Message
                extraClassnames="atl-mb-16"
                message={{
                    text: getMessage('text_ehf_get_money_faster'),
                    type: MESSAGE_TYPE.INFO,
                    position: MESSAGE_POSITION.INLINE,
                    isCloseable: false,
                    action: {
                        text: getMessage('text_activate_ehf'),
                        onClick: () =>
                            window.open(
                                addContextId('/execute/mySubscription')
                            ),
                    },
                }}
            />
        );
    } else {
        return (
            <Message
                extraClassnames="atl-mb-16"
                message={{
                    text: getMessage('text_ehf_get_money_faster_no_access'),
                    type: MESSAGE_TYPE.INFO,
                    position: MESSAGE_POSITION.INLINE,
                    isCloseable: false,
                }}
            />
        );
    }
}

export function getSendMethodNameForInvoicingButton(enumValue: string) {
    if (enumValue === SendTypes.AVTALEGIRO) {
        return getMessage('text_invoice_option_avtalegiro');
    } else if (enumValue === SendTypes.EFAKTURA) {
        return getMessage('text_invoice_option_efaktura');
    } else if (enumValue === SendTypes.EHF) {
        return getMessage('text_invoice_using_accesspoint_short');
    } else if (enumValue === SendTypes.EMAIL) {
        return getMessage('text_invoice').toLowerCase();
    } else if (enumValue === SendTypes.PAPER) {
        return getMessage('text_invoice_option_paper').toLowerCase();
    } else if (enumValue === SendTypes.MANUAL) {
        return getMessage('text_send_type_later');
    } else if (enumValue === SendTypes.VIPPS) {
        return getMessage('text_invoice_option_efaktura');
    }

    return '';
}

function getSendTypesToShow(customer: Customer, sendTypes: string[]) {
    const sendTypesToShow: string[] = [
        customer.isPrivateIndividual ? SendTypes.EFAKTURA : SendTypes.EHF,
        SendTypes.EMAIL,
        SendTypes.MANUAL,
    ];

    if (
        customer.isPrivateIndividual &&
        sendTypes.includes(SendTypes.AVTALEGIRO)
    ) {
        sendTypesToShow.unshift(SendTypes.AVTALEGIRO);
    }

    if (customer.invoiceSendMethod === SendTypes.PAPER) {
        sendTypesToShow.push(SendTypes.PAPER);
    }

    return sendTypesToShow;
}
