import jQuery from 'jquery';
const $ = jQuery;
import { dateUtil } from '../../js/modules/date';
import { getMessage } from '../../js/modules/getMessage';

if (window.narrowScreen) {
    /* eslint-disable no-inner-declarations */
    function initializePeriodSelecter() {
        function shorten(text) {
            if (text.length <= 3) return text.toLowerCase();
            return text.substring(0, 3).toLowerCase() + '.';
        }

        // Narrow screens break the scroller, fix by showing small month names.
        // Yes, we want > 480 and not >= 480. User may change orientation.
        var monthNames = [
            getMessage('month_first_upper_1'),
            getMessage('month_first_upper_2'),
            getMessage('month_first_upper_3'),
            getMessage('month_first_upper_4'),
            getMessage('month_first_upper_5'),
            getMessage('month_first_upper_6'),
            getMessage('month_first_upper_7'),
            getMessage('month_first_upper_8'),
            getMessage('month_first_upper_9'),
            getMessage('month_first_upper_10'),
            getMessage('month_first_upper_11'),
            getMessage('month_first_upper_12'),
        ];

        var monthNamesShort = monthNames.map(shorten);

        var narrowScreen = $(window).width() <= 480;

        var dayNames = [
            getMessage('text_sunday'),
            getMessage('text_monday'),
            getMessage('text_tuesday'),
            getMessage('text_wednesday'),
            getMessage('text_thursday'),
            getMessage('text_friday'),
            getMessage('text_saturday'),
        ];

        var dayNamesShort = dayNames.map(shorten);

        function setupDaySelecter(widget) {
            var $day = widget.element;
            var $startDate = $('#' + widget.element.data('start-date'));
            var $endDate = $('#' + widget.element.data('end-of-period-date'));

            var $picker = $(
                '<img class="ui-datepicker-trigger" src="/resources/images/tlx-datepicker.png">'
            );

            $day.attr('class', 'tlxDateField')
                .attr('data-vformat', 'yyyy-MM-dd')
                .val($startDate.val())
                .wrap('<div class="dateInputField" />')
                .after($picker)
                .on('input change', function () {
                    $startDate.val($day.val());
                    $endDate.val($day.val());
                });
        }

        $.widget('tlx.periodselecter', {
            // These options will be used as defaults
            options: {
                allPeriods: true,
                labelYear: '',
                labelPeriod: 'Periode',
                labelVatTerm: '',
                labelWageTerm: '',
                labelDay: '',
                labelMonth: '',
                labelWeek: '',
                labelFrom: 'Fra',
                labelTo: 'Til',
                buttonLabelAll: '',
                buttonLabelSoFarThisYear: '',
                buttonLabelOk: 'OK',
                buttonTrackingIdAll: '',
                buttonTrackingIdSoFarThisYear: '',
                buttonTrackingIdOk: '',
                narrowScreenPeriodTabTrackingId: '',
                narrowScreenWeekTabTrackingId: '',
                narrowScreenMonthTabTrackingId: '',
                narrowScreenYearTabTrackingId: '',
                narrowScreenWageTermTabTrackingId: '',
                narrowScreenVatTermTabTrackingId: '',
                maxDate: null,
                minDate: null,
                typeMonth: true,
                typeRangeSlider: true,
                typeVatTerm: false,
                typeWageTerm: false,
                typeWeek: true,
                typeYear: true,
                vatTermPrefix: '',
                vatTermSuffix: '',
                vatTermFullYearText: '',
                vatTerms: [],
                monthNames: monthNames, // Not in use, because we adjust names according to screen size.
            },

            periodTypes: {
                range: 0,
                year: 1,
                vatTerm: 2,
                wageTerm: 3,
                month: 4,
                week: 5,
            },

            $theElement: null,
            $textElement: null,
            $startDateInput: null,
            $endDateInput: null,
            $periodType: null,

            /* Taken from jQuery UI!
             * Set as calculateWeek to determine the week of the year based on the ISO 8601 definition.
             * @param  date  Date - the date to get the week for
             * @return  number - the number of the week within the year that contains this date
             */
            iso8601Week: function (date) {
                var time,
                    checkDate = new Date(date.getTime());

                // Find Thursday of this week starting on Monday
                checkDate.setDate(
                    checkDate.getDate() + 4 - (checkDate.getDay() || 7)
                );

                time = checkDate.getTime();
                checkDate.setMonth(0); // Compare with Jan 1
                checkDate.setDate(1);
                return (
                    Math.floor(Math.round((time - checkDate) / 86400000) / 7) +
                    1
                );
            },

            /* Copied from desktop version. */
            _getDaysBetween: function (startDate, endDate) {
                return Math.round(
                    (endDate.getTime() - startDate.getTime()) / 86400000
                );
            },

            /**
             * No arguments: returns [start, end] (as Date objects)
             * Arguments: Sets the start and end date
             */
            dates: function (startDate, endDate) {
                if (arguments.length) {
                    startDate = this._parseDate(startDate);
                    endDate = this._parseDate(endDate);
                    if (
                        startDate !== null &&
                        endDate !== null &&
                        (this._isOutsideDrawDates(startDate) ||
                            this._isOutsideDrawDates(endDate))
                    ) {
                        this._extendDrawDates(startDate, endDate);
                        //TODO use _regeneratePeriodsIfNeeded instead?
                    }
                    this._setDates(startDate, endDate);
                    this._setPersistent();
                    this.periodSelecterContent
                        .find('.ui-selected')
                        .removeClass('ui-selected');
                    return this;
                }

                if (arguments.length === 0) {
                    var $startDate = $(
                        '#' + $(this.element).attr('id') + 'StartDate'
                    );
                    var $endDate = $(
                        '#' + $(this.element).attr('id') + 'EndOfPeriodDate'
                    );

                    return [
                        dateUtil.parseDate($startDate.val()),
                        dateUtil.parseDate($endDate.val()),
                    ];
                }
            },

            previous: function () {
                var start = this._getDate(this.$startDateInput),
                    end = this._getDate(this.$endDateInput);
                switch (this.$periodType.val()) {
                    case '0':
                        // Probably "All periods", do nothing.
                        if (
                            this.$startDateInput.val() === '' ||
                            this.$endDateInput.val() === ''
                        ) {
                            return;
                        }
                        var b = this._getDaysBetween(start, end);
                        end = new Date(start);
                        end.setDate(end.getDate() - 1);
                        start = new Date(end);
                        start.setDate(start.getDate() - b);
                        break;
                    case '1':
                        start.setFullYear(start.getFullYear() - 1);
                        end.setFullYear(end.getFullYear() - 1);
                        break;
                    case '2':
                        var opt = this.$theElement
                            .find('.vatTerms [selected]')
                            .removeAttr('selected')
                            .prev()
                            .attr('selected', 'selected');
                        start = new Date(opt.val());
                        end = new Date(opt.data('todate'));
                        break;
                    case '3': // Wage term
                        // Roll back two months
                        start.setDate(-1);
                        start.setDate(1);
                        start.setDate(-1);
                        start.setDate(1);

                        end.setDate(0);
                        end.setDate(0);
                        break;
                    case '4':
                        start.setDate(-1);
                        start.setDate(1);
                        end.setDate(0);
                        break;
                    case '5': // WEEK
                        start.setDate(start.getDate() - 7);
                        // Wraps to next month gracefully
                        // Since the end.setDate is relative to the start.getDate()!
                        end = new Date(start.getTime());
                        end.setDate(start.getDate() + 6);
                        break;
                    case '6':
                        start.setDate(start.getDate() - 1);
                        end.setDate(end.getDate() - 1);
                        break;
                }

                this._setStartEndDate(start, end);

                this.setTheText(
                    start,
                    end,
                    this.$theElement
                        .find('.fromTo [selected]')
                        .data('presentation')
                );
            },

            /**
             * Set period selecter start and end date. Emmit change event
             * if something has changed ...
             *
             * @param start
             * @param end
             * @private
             */
            _setStartEndDate: function _setStartEndDate(start, end) {
                var changedStart = this.setInputDateValue(
                    this.$startDateInput,
                    start
                );
                var changedEnd = this.setInputDateValue(
                    this.$endDateInput,
                    end
                );

                if (changedStart || changedEnd) {
                    this.element.change();
                }
            },

            next: function () {
                var start = this._getDate(this.$startDateInput),
                    end = this._getDate(this.$endDateInput);

                switch (this.$periodType.val()) {
                    case '0':
                        // Probably "All periods", do nothing.
                        if (
                            this.$startDateInput.val() === '' ||
                            this.$endDateInput.val() === ''
                        ) {
                            return;
                        }
                        var b = this._getDaysBetween(start, end);
                        start = new Date(end);
                        start.setDate(start.getDate() + 1);
                        end = new Date(start);
                        end.setDate(end.getDate() + b);
                        break;
                    case '1':
                        start.setFullYear(start.getFullYear() + 1);
                        end.setFullYear(end.getFullYear() + 1);
                        break;
                    case '2':
                        var opt = this.$theElement
                            .find('.vatTerms [selected]')
                            .removeAttr('selected')
                            .next()
                            .attr('selected', 'selected');
                        start = new Date(opt.val());
                        end = new Date(opt.data('todate'));
                        break;
                    case '3': // Wage term
                        start.setDate(33);
                        start.setDate(1);
                        start.setDate(33);
                        start.setDate(1);

                        end.setDate(99);
                        end.setDate(0);
                        break;
                    case '4':
                        start.setDate(33);
                        start.setDate(1);
                        end.setDate(66);
                        end.setDate(0);
                        break;
                    case '5': // WEEK
                        start.setDate(start.getDate() + 7);
                        // Wraps to next month gracefully
                        // Since the end.setDate is relative to the start.getDate()!
                        end = new Date(start.getTime());
                        end.setDate(start.getDate() + 6);
                        break;
                    case '6':
                        start.setDate(start.getDate() + 1);
                        end.setDate(end.getDate() + 1);
                        break;
                }

                this._setStartEndDate(start, end);

                this.setTheText(
                    start,
                    end,
                    this.$theElement
                        .find('.fromTo')
                        .find('[selected]')
                        .data('presentation')
                );
            },

            _getDate: function ($dateInput) {
                if (
                    $dateInput !== null &&
                    dateUtil.isDate($dateInput.val(), 'yyyy-MM-dd')
                ) {
                    var t = $dateInput.val().split('-');
                    return new Date(
                        parseInt(t[0]),
                        parseInt(t[1]) - 1,
                        parseInt(t[2])
                    );
                } else {
                    return new Date();
                }
            },

            /* Set the text in the input box (visual) */
            setTheText: function (start, end, text) {
                if (text) {
                    this.element.val(text);
                    return;
                }
                var value;
                var months = monthNames;
                switch (this.$periodType.val()) {
                    case '5':
                        value =
                            this.options.labelWeek +
                            ' ' +
                            this.iso8601Week(start);
                        break;
                    case '0':
                        value =
                            this.makeDateString(start) +
                            ' - ' +
                            this.makeDateString(end);
                        this.element.val();
                        break;
                    case '4':
                        value =
                            months[start.getMonth()] +
                            ' ' +
                            start.getFullYear();
                        break;
                    case '1':
                        value = start.getFullYear();
                        break;
                    case '3':
                        value =
                            months[start.getMonth()] +
                            ' - ' +
                            months[end.getMonth()] +
                            ' ' +
                            end.getFullYear();
                        break;
                }
                this.element.val(value);
            },

            makeDateString: dateUtil.formatDate,

            _create: function () {
                this.$startDateInput = this.element
                    .closest('form')
                    .find('[name="period.startDate"]');
                this.$endDateInput = this.element
                    .closest('form')
                    .find('[name="period.endOfPeriodDate"]');
                this.$periodType = this.element
                    .closest('form')
                    .find('[name="period.periodType"]');

                if (this.$periodType.val() === '6') {
                    setupDaySelecter(this);
                    return;
                }

                this.element.addClass('hasPeriodselecter');

                // Delete previous period selectors, if any
                $('div.dialog.fullpage.periodSelector').remove();

                var that = this,
                    o = this.options,
                    //				width = ' style="width: ' + (100 / 4) + '%; height: 40px;"',
                    $dialog = $(
                        '<div class="dialog fullpage periodSelector" style="display: none"></div>'
                    ).appendTo('body'),
                    $fromTo = $('<div class="fromTo"></div>').appendTo($dialog);

                var periodTypes = [
                    {
                        value: 0,
                        label: o.labelPeriod,
                        show: o.typeRangeSlider,
                        trackingId: o.narrowScreenPeriodTabTrackingId,
                    },
                    {
                        value: 5,
                        label: o.labelWeek,
                        show: o.typeWeek,
                        trackingId: o.narrowScreenWeekTabTrackingId,
                    },
                    {
                        value: 4,
                        label: o.labelMonth,
                        show: o.typeMonth,
                        trackingId: o.narrowScreenMonthTabTrackingId,
                    },
                    {
                        value: 1,
                        label: o.labelYear,
                        show: o.typeYear,
                        trackingId: o.narrowScreenYearTabTrackingId,
                    },
                    {
                        value: 3,
                        label: o.labelWageTerm,
                        show: o.typeWageTerm,
                        trackingId: o.narrowScreenWageTermTabTrackingId,
                    },
                    {
                        value: 2,
                        label: o.labelVatTerm,
                        show: o.typeVatTerm,
                        trackingId: o.narrowScreenVatTermTabTrackingId,
                    },
                ];

                var buttons = periodTypes
                    .filter(function (option) {
                        return option.show;
                    })
                    .map(function (option) {
                        return (
                            '<a class="tab" data-value="' +
                            option.value +
                            '" data-trackingid="' +
                            option.trackingId +
                            '">' +
                            '<span class="tab-label">' +
                            option.label +
                            '</span>' +
                            '</a>'
                        );
                    })
                    .join('');

                var $selectPeriod = $('<div class="periodType"></div>')
                    .prependTo($dialog)
                    .append(
                        '<div><ul class="tlx-blue">' + buttons + '</ul></div>'
                    )
                    .on('click', function (e) {
                        var $clicked = $(e.target).closest('.tab');
                        if ($clicked.length < 1) return; // Return if the clicked is not a button
                        if ($clicked.is('.active')) return; // Return if the clicked is the current

                        // Switch who looks active
                        $clicked
                            .closest('ul')
                            .find('.active')
                            .removeClass('active');
                        $clicked.addClass('active');

                        // Set up new period type
                        that.$periodType.val($clicked.data('value'));
                        that.setUpFromTo($fromTo);
                    });

                // Find the active period and set style class accordingly
                $selectPeriod
                    .find('[data-value=' + this.$periodType.val() + ']')
                    .addClass('active');

                // Set value as text
                this.element.text(this.element.attr('value'));
                // Add an icon.
                var $temp = $('<div class="filter-button" />');
                this.element.replaceWith($temp).appendTo($temp);
                $(
                    '<span class="dateFieldIcon" style="float: right; top: 0px;"></span>'
                ).appendTo($temp);

                $temp.on('click', function () {
                    that.element.periodselecter('open');
                });

                this.setUpFromTo($fromTo);
                this.$theElement = $dialog;
            },

            generateVatTerms: function () {
                var now = this._getDate(this.$startDateInput);
                var minDate = now.getFullYear() - 3;
                var maxDate = now.getFullYear() + 1;
                var stringbuff = [];
                var months = narrowScreen ? monthNamesShort : monthNames;

                var vt = this.options.vatTerms.filter(function (date) {
                    return (
                        date.getFullYear() >= minDate &&
                        date.getFullYear() <= maxDate
                    );
                });

                stringbuff.push('<select class="vatTerms">');

                var vatTermThisYear = 1;
                for (var i = 0; i < vt.length - 1; i++) {
                    var startDate = vt[i];
                    var endDate = dateUtil.addDays(vt[i + 1], -1);

                    // reset the hours part of the date, as this will have an impact on the later date comparisons, resulting in no date being selected.
                    startDate.setHours(0, 0, 0, 0);
                    endDate.setHours(0, 0, 0, 0);

                    var isLastTermInYear =
                        vt[i + 1].getFullYear() > vt[i].getFullYear();

                    let displayText;
                    if (isLastTermInYear && vatTermThisYear === 1) {
                        displayText =
                            this.options.vatTermFullYearText +
                            ' ' +
                            startDate.getFullYear();
                    } else {
                        displayText =
                            vatTermThisYear + this.options.vatTermSuffix + ' ';
                        displayText += startDate.getFullYear() + ' (';
                        displayText += months[startDate.getMonth()] + '&#8211;';
                        displayText += months[endDate.getMonth()] + ')';
                    }

                    stringbuff.push('<option ');

                    if (now >= startDate && now <= endDate) {
                        stringbuff.push('selected="selected" ');
                    }
                    stringbuff.push(
                        'data-todate="' + this.makeDateString(endDate) + '"'
                    );
                    stringbuff.push('data-presentation="');
                    stringbuff.push(
                        this.options.vatTermPrefix +
                            vatTermThisYear +
                            this.options.vatTermSuffix
                    );
                    stringbuff.push(' ');
                    stringbuff.push(startDate.getFullYear());
                    stringbuff.push('"');
                    stringbuff.push('value="');
                    stringbuff.push(this.makeDateString(startDate));
                    stringbuff.push('">');
                    stringbuff.push(displayText);
                    stringbuff.push('</option>');
                    if (isLastTermInYear) {
                        vatTermThisYear = 1;
                    } else {
                        vatTermThisYear++;
                    }
                }

                stringbuff.push('</select>');

                return stringbuff.join('');
            },

            generateWageTerms: function () {
                var now = new Date();
                var start = now.getFullYear() - 3;
                var end = now.getFullYear() + 1;
                var that = this;
                var months = monthNames;

                function getWageTerms(startYear, endYear) {
                    var terms = [];
                    for (var year = startYear; year <= endYear; year++) {
                        for (var i = 0; i < 12; i += 2) {
                            // Notice that we skip every other month, i will never become 11,
                            // thus i+1 is never 12 (which is not a month).
                            var numberOfDays =
                                dateUtil.daysInMonth(year, i) +
                                dateUtil.daysInMonth(year, i + 1);
                            terms.push({
                                startDate: new Date(year, i, 1),
                                endDate: new Date(year, i, numberOfDays),
                            });
                        }
                    }
                    return terms;
                }

                var stringbuff = [];
                stringbuff.push('<select>');

                getWageTerms(start, end).forEach(function (term) {
                    var startDate = term.startDate;
                    var endDate = term.endDate;

                    stringbuff.push('<option ');
                    if (now >= startDate && now <= endDate) {
                        stringbuff.push('selected="selected" ');
                    }
                    stringbuff.push(
                        'data-todate="' + that.makeDateString(endDate) + '"'
                    );
                    stringbuff.push('value="');
                    stringbuff.push(that.makeDateString(startDate));
                    stringbuff.push('">');
                    stringbuff.push(months[startDate.getMonth()] + ' &#8211; ');
                    stringbuff.push(months[endDate.getMonth()] + ' ');
                    stringbuff.push(startDate.getFullYear());
                    stringbuff.push('</option>');
                });

                stringbuff.push('</select>');
                return stringbuff.join('');
            },

            generateWeeks: function () {
                var string = '<select>',
                    thisYear = new Date().getFullYear(),
                    startYear = thisYear - 3,
                    endYear = thisYear + 2,
                    that = this,
                    helper = findFirstDayInFirstWeek(startYear),
                    thisWeek = this.iso8601Week(new Date());

                // the first week of the year is the week with the 4th of January in it
                function findFirstDayInFirstWeek(year) {
                    var date = new Date(year, 0, 4, 0, 0, 0, 0);
                    while (that.iso8601Week(date) === 1) {
                        date.setDate(date.getDate() - 1);
                    }
                    date.setDate(date.getDate() + 1);
                    return date;
                }

                for (var year = startYear; year <= endYear; year++) {
                    var week = 1;
                    // eslint-disable-next-line no-constant-condition
                    while (true) {
                        var endDate = new Date(helper.toString());
                        endDate.setDate(helper.getDate() + 6);

                        string +=
                            '<option value="' +
                            that.makeDateString(helper) +
                            '"';
                        if (thisWeek === week && thisYear === year) {
                            string += ' selected="selected"';
                        }

                        string +=
                            '>' +
                            getMessage('text_week') +
                            ' ' +
                            week +
                            ', ' +
                            year +
                            ' (' +
                            helper.getDate() +
                            '.' +
                            (helper.getMonth() + 1) +
                            '&#8211;' +
                            endDate.getDate() +
                            '.' +
                            (endDate.getMonth() + 1) +
                            ')' +
                            '</option>';
                        helper = endDate;
                        helper.setDate(helper.getDate() + 1);
                        week = that.iso8601Week(helper);
                        if (week === 1) break;
                    }
                }

                string += '</select>';
                return string;
            },

            weeks: null,

            /**
             * Returns true if value actually changed
             * @param $f
             * @param d
             */
            setInputDateValue: function ($f, d) {
                var newVal = d
                    ? d.getFullYear() +
                      '-' +
                      (d.getMonth() + 1) +
                      '-' +
                      d.getDate()
                    : '';
                var oldVal = $f.val();

                $f.val(newVal);

                return oldVal !== newVal;
            },

            setUpFromTo: function ($fromTo) {
                var dateOrder = 'D dd MMyy',
                    dateHelper = new Date(),
                    that = this,
                    $fromInput = null,
                    $toInput = null,
                    pType = (function (types, $type) {
                        var obj = Object.create ? Object.create(null) : {},
                            val = parseInt($type.val());
                        for (var key in types) {
                            obj['is' + key] = types[key] === val;
                            obj[key] = types[key] + ''; // Convert to string because val returns a string.
                        }
                        obj.val = function (set) {
                            if (set) $type.val(set);
                            return $type.val();
                        };
                        return obj;
                    })(this.periodTypes, this.$periodType);

                $fromTo
                    .empty()
                    .append(
                        '<div>' +
                            (pType.isrange
                                ? '<h3>' +
                                  getMessage('text_email_from') +
                                  '</h3>'
                                : '') +
                            '<input style="display: none;" class="from"></div>' +
                            (pType.isrange
                                ? '<div><h3>' +
                                  getMessage('text_email_to') +
                                  '</h3><input style="display: none;" class="to"></div>'
                                : '')
                    );

                // For some reasons, we suddenly need to keep the <select> element updated ourself.
                // When mobiscroll selecter changes value, remove currently selected and select correct option.
                function updateSelectElement(val, ins) {
                    $fromTo
                        .find('[selected="selected"]')
                        .removeAttr('selected');
                    $fromTo
                        .find('[value="' + ins.getSelectVal() + '"]')
                        .attr('selected', 'selected');
                }

                switch (pType.val()) {
                    case pType.week:
                        this.weeks = this.weeks || this.generateWeeks();
                        $fromInput = $fromTo
                            .find('.from')
                            .after(this.weeks)
                            .next()
                            .hide()
                            .mobiscroll()
                            .select({
                                display: 'inline',
                                label: 'Week',
                                tap: false,
                                showLabel: false,
                            });
                        break;
                    case pType.wageTerm:
                        this.wageTerms =
                            this.wageTerms || this.generateWageTerms();
                        $fromInput = $fromTo
                            .find('.from')
                            .after(this.wageTerms)
                            .next()
                            .hide()
                            .mobiscroll()
                            .select({
                                display: 'inline',
                                label: 'Week',
                                tap: false,
                                showLabel: false,
                                onChange: updateSelectElement,
                            });
                        break;
                    case pType.vatTerm:
                        this.vatTerms =
                            this.vatTerms || this.generateVatTerms();
                        $fromInput = $fromTo
                            .find('.from')
                            .after(this.vatTerms)
                            .next()
                            .hide()
                            .mobiscroll()
                            .select({
                                display: 'inline',
                                label: 'Week',
                                tap: false,
                                showLabel: false,
                                onChange: updateSelectElement,
                            });
                        break;
                    case pType.month:
                        dateOrder = 'MMyy';
                        break;
                    case pType.year:
                        dateOrder = 'yy';
                        break;
                }

                if (!pType.isweek && !pType.iswageTerm && !pType.isvatTerm) {
                    var o = {};

                    o.display = 'inline';
                    o.dateOrder = dateOrder;
                    o.tap = false;
                    o.showLabel = false;

                    if (o.showLabel) {
                        o.dayText = that.options.labelDay;
                        o.monthText = that.options.labelMonth;
                        o.yearText = that.options.labelYear;
                    }

                    o.monthNames = narrowScreen ? monthNamesShort : monthNames;

                    o.dayNamesShort = dayNamesShort;
                    $fromInput = $fromTo
                        .find('.from')
                        .mobiscroll()
                        .date(o)
                        .mobiscroll('setDate', dateHelper, true);

                    if (pType.isrange) {
                        $toInput = $fromTo
                            .find('.to')
                            .mobiscroll()
                            .date(o)
                            .mobiscroll('setDate', dateHelper, true);
                    }
                }

                function getDate($fromOrTo) {
                    return pType.isweek || pType.iswageTerm || pType.isvatTerm
                        ? new Date(
                              $fromOrTo
                                  .mobiscroll('getValue')
                                  .replace(/-/g, '/')
                          )
                        : $fromOrTo.mobiscroll('getDate');
                }

                function setDate($fromOrTo, date) {
                    if (pType.isweek) {
                        $fromOrTo.mobiscroll('setValue', [
                            that.makeDateString(date),
                        ]);
                    } else {
                        $fromOrTo.mobiscroll('setDate', date);
                    }
                }

                if (pType.isrange) {
                    $fromInput.on('change', function () {
                        var from = getDate($fromInput),
                            to = getDate($toInput);
                        if (to.getTime() < from.getTime()) {
                            setDate($toInput, from);
                        }
                    });

                    $toInput.on('change', function () {
                        var from = getDate($fromInput),
                            to = getDate($toInput);
                        if (to.getTime() < from.getTime()) {
                            setDate($fromInput, to);
                        }
                    });
                }

                var $buttonContainer = $(
                    '<div class="period-selecter-buttonpane"></div>'
                ).appendTo($fromTo);

                if (
                    this.options.allPeriods === true &&
                    that.options.buttonLabelAll.length > 0
                ) {
                    $buttonContainer.append(
                        // "All periods"
                        $(
                            '<button class="mdl-button mdl-js-button" style="padding: 0 10px;" ' +
                                'data-trackingid="' +
                                this.options.buttonTrackingIdAll +
                                '">' +
                                that.options.buttonLabelAll +
                                '</button>'
                        ).on('click', function () {
                            that.$periodType.val('0');

                            that._setStartEndDate();

                            that.close();
                            that.element.val(that.options.buttonLabelAll);
                        })
                    );
                }

                if (this.options.typeRangeSlider) {
                    $buttonContainer.append(
                        // "So far this year"
                        $(
                            '<button class="mdl-button mdl-js-button" style="padding: 0 10px;" ' +
                                'data-trackingid="' +
                                this.options.buttonTrackingIdSoFarThisYear +
                                '">' +
                                that.options.buttonLabelSoFarThisYear +
                                '</button>'
                        ).on('click', function () {
                            var startDate = new Date();
                            var endDate = new Date();
                            that.$periodType.val('0');
                            startDate.setMonth(0);
                            startDate.setDate(1);

                            that._setStartEndDate(startDate, endDate);

                            that.close();
                            that.setTheText(startDate, endDate);
                        })
                    );
                }

                $buttonContainer.append(
                    // "OK"
                    $(
                        '<button class="mdl-button mdl-js-button mdl-button--raised mdl-button--colored" ' +
                            'data-trackingid="' +
                            this.options.buttonTrackingIdOk +
                            '">' +
                            this.options.buttonLabelOk +
                            '</button>'
                    ).on('click', function () {
                        var fromDate = getDate($fromInput);
                        var toDate = pType.isrange
                            ? getDate($toInput)
                            : new Date(fromDate);

                        switch (pType.val()) {
                            case pType.week:
                                // Make sure we get monday in to
                                toDate.setDate(toDate.getDate() + 6);
                                break;
                            case pType.wageTerm:
                            case pType.vatTerm:
                                var datestring = $(this)
                                    .closest('.fromTo')
                                    .find('[selected]')
                                    .data('todate');
                                toDate = new Date(datestring);
                                break;
                            case pType.month:
                                // Make sure we get 01 in from and last day of month in to
                                fromDate.setDate(1);
                                // First get next month, then get last day of previous/this month.
                                toDate.setDate(34);
                                toDate.setDate(0);
                                break;
                            case pType.year:
                                // Make sure we get 01.01 in from and 12.31 in to
                                fromDate.setMonth(0);
                                fromDate.setDate(1);
                                toDate.setMonth(11);
                                toDate.setDate(31);
                                break;
                        }

                        that._setStartEndDate(fromDate, toDate);

                        that.close();

                        that.setTheText(
                            fromDate,
                            toDate,
                            $(this)
                                .closest('.fromTo')
                                .find('[selected]')
                                .data('presentation')
                        );
                    })
                );
            },

            /**
             * http://api.jqueryui.com/jQuery.widget/#method-_getCreateOptions (used to let the element data attrs define widget options)
             */
            _getCreateOptions: function () {
                var options = {};

                $.each(this.element.data(), function (key, value) {
                    switch (key) {
                        case 'typeRange':
                            options['typeRangeSlider'] = value;
                            break;
                        case 'startDate':
                        case 'endOfPeriodDate':
                        case 'periodType':
                            options[key + 'Field'] = $('#' + value);
                            break;
                        case 'vatTerms':
                            var vatTerms = [];
                            if (value) {
                                for (var i = 0; i < value.length; i++) {
                                    var date = new Date(value[i].time);
                                    date.setMinutes(
                                        120 + date.getTimezoneOffset()
                                    );
                                    vatTerms.push(date);
                                }
                            }
                            options[key] = vatTerms;
                            break;
                        default:
                            options[key] = value;
                    }
                });

                return options;
            },

            destroy: function () {
                this.$theElement.remove();
            },

            isOpen: false,

            open: function () {
                if (
                    this.element.is('.ui-state-disabled') ||
                    this.element.is('[disabled]')
                )
                    return;
                var that = this;
                that.isOpen = true;

                that.$theElement.dialog({
                    title: getMessage('text_period'),
                });
            },

            close: function () {
                if (this.isOpen) {
                    this.isOpen = false;
                    const $theElement = this.$theElement;

                    if ($theElement.dialog('instance') !== undefined) {
                        $theElement.dialog('close');
                        $theElement.dialog('destroy');
                    }
                }
            },
        });
    }

    initializePeriodSelecter();
}
