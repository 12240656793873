import { Icon } from '@tlx/atlas';
import { getMessage } from '../../../../../../js/modules/getMessage';

export const DistributionKeysNoKeys = () => {
    return (
        <tr className="atl-tr">
            <td
                className="atl-td atl-text-center"
                colSpan={7}
                style={{ height: 'fit-content' }}
            >
                <div
                    className="atl-mx-auto atl-my-16"
                    style={{ width: 'fit-content' }}
                >
                    <Icon className="empty-page-icon">key</Icon>
                </div>
                <h1 className="empty-page-text">
                    {getMessage('text_distribution_key_found_none')}
                </h1>
            </td>
        </tr>
    );
};
