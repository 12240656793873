import { ModelAPIV1 } from '@General/types/Api';
import { Option } from '../../types';
import { notUndefined } from '@General/Helpers';
import { JSPDropdownFetcher } from '../JSPDropdownFetcher';
import { ApiResultMapper } from '@General/Provider';

interface ActivityCategoryEntry extends ModelAPIV1 {
    readonly displayName: string;
}

const activityCategoryMapper: ApiResultMapper<ActivityCategoryEntry> = (
    item
) => ({
    value: item.id + '',
    displayName: item.displayName,
    item,
});

class ActivityCategoryFetcher extends JSPDropdownFetcher<ActivityCategoryEntry> {
    asyncJsonrpcGetter: Function;

    constructor(scope: HTMLDivElement, queryAttributes: Record<string, any>) {
        super(scope, queryAttributes, activityCategoryMapper);
        this.asyncJsonrpcGetter = asyncrpc.ActivityCategory.get;
    }

    getParams(): ObjectIndex {
        return {};
    }

    async fetchList(
        params: ObjectIndex
    ): Promise<Option<ActivityCategoryEntry>[]> {
        return new Promise((resolve, reject) => {
            try {
                jsonrpc.ActivityCategory.searchForActivityCategories(
                    (result: ActivityCategoryEntry[] | null) => {
                        const mapped = (result ?? [])
                            .filter(notUndefined)
                            .map(this.getMapper(params))
                            .filter(notUndefined);
                        resolve(mapped);
                    },
                    this.getMarshallSpec(),
                    window.loginCompanyId,
                    params.name
                );
            } catch (e) {
                defaultJsonRpcExceptionHandler(e);
                reject(e);
            }
        });
    }
}

export const exports = {
    fetcher: ActivityCategoryFetcher,
    type: 'ActivityCategory',
};
