import jQuery from 'jquery';
const $ = jQuery;
import { setElementValue } from '../c-common';
import { compareValues } from './pt-reorder-compareValues';

/**
 * This file is (partially) copied from the peacock-table project
 * .
 * NB! This file should reflect the equivalent file in the Peacock Table repo!
 * In other words: Any changes that is not Tripletex specific should also be
 * added to the Peacock Table repo.
 *
 * @author Haakon
 * @date 21. sep 2016
 */

window.ptReorder = (function () {
    function sortupdate(tBody) {
        const rows = tBody.children;
        for (let i = 0; i < rows.length; i++) {
            $(rows[i]).trigger('change');
        }
    }

    function initRowDragAndDrop(tableElem) {
        // do lazy dragn'drop initialization
        $('tbody', tableElem).on(
            'mouseenter.lazytaskdrag',
            '.pt-draggable',
            function (event) {
                const tbody = $(event.delegateTarget);
                tbody.off('mouseenter.lazytaskdrag', '.pt-draggable');

                // drag and drop
                const tableRowHelper = function (e, tr) {
                    const $originals = tr.children();
                    const $helper = tr.clone();
                    // prevents the helper from growing too big
                    $helper.css('max-width', tr.parent().parent().width());
                    $helper.children().each(function (index) {
                        // prevents the cells in the dragged row (the helper) from changing size
                        $(this).width($originals.eq(index).width());
                    });
                    $helper.addClass('pt-dragging');

                    return $helper;
                };

                tbody.sortable({
                    helper: tableRowHelper,
                    axis: 'y',
                    forcePlaceHolderSize: true,
                    handle: '.pt-draggable',
                    placeholder: 'pt-placeholder',
                    sort: function (e, ui) {
                        // fix issue with jquery creating a placeholder that does not consider hidden cells
                        ui.placeholder.find('td').each(function (key) {
                            $(this).toggle(
                                ui.helper.find('td').eq(key).is(':visible')
                            );
                        });

                        // ensure that the placeholder is of the same height as the helper
                        ui.placeholder.height(ui.helper.height());
                    },
                });
                tbody.bind('sortupdate', function () {
                    sortupdate(this);
                });
            }
        );
    }

    /**
     * Tripletex specific code (can be modified here and not in the peacock table repo).
     *
     * Initialized drag and drop on all peacock tables with reorderable rows.
     *
     * @author Haakon
     * @date 23. sep 2016
     */
    $(window).on(
        'tlxLoadPageContentDone tlxtabsload tlxLazyLoadDone',
        function () {
            $('table.pt-reorderable-rows').each(function () {
                initRowDragAndDrop(this);
            });

            $(
                'table.pt-reorderable-rows td.pt-draggable span.pt-icon-draggable'
            ).replaceWith('<i class="material-icons">drag_handle</i>');
        }
    );

    /**
     * Tripletex specific code (can be modified here and not in the peacock table repo).
     *
     * Sort table according to given compare function.
     *
     * @param table Element or jquery object
     * @param compare Function for comparing two rows.
     *                The function must take two parameters, a and b, and return -1, 0 or 1.
     *
     * @author Haakon
     * @date 24. okt 2016
     */
    function sort(table, compare) {
        const $table = $(table);
        const $rows = $table.find('tbody tr');
        const $templateRow = $rows.last();

        $rows.sort(compare);

        $rows.appendTo($table);
        $templateRow.appendTo($table);
    }

    /**
     * Tripletex specific code (can be modified here and not in the peacock table repo).
     *
     * Sort rows in a given table in GUI, according to given sort order.
     *
     * NB! This sorting MUST reflect OrderLine.sort() in Java!!!
     *
     * @param table Table element to be sorted
     * @param sortOrder {number} Which sort order to use in sorting.
     *                      0 - sort by ID
     *                      1 - sort by expected delivery date
     *                      2 - sort by product name (alphabetically)
     *                      3 - sort by custom sort index
     *
     * @author Haakon
     * @date 29. sep 2016
     */
    function sortOrderLines(table, sortOrder) {
        const options = {
            // sortOrder    - correspond to the sortOrder parameter that is given to the function
            // selector     - used to find the elements with the values used in the sorting
            // nullValue    - value that will be considered to have no affect on sorting

            subscription: {
                selector: 'input[name$=".isSubscription"]',
            },
            id: {
                sortOrder: 0,
                selector: 'input[name$=".id"]',
                nullValue: 0,
            },
            date: {
                sortOrder: 1,
                selector: 'input[name$=".expectedDeliveryDate"]',
                nullValue: '',
            },
            product: {
                sortOrder: 2,
                selector: 'input[name$=".guiProductIdSelect"]',
                nullValue: getMessage('option_select_product'),
            },
            custom: {
                sortOrder: 3,
                selector: 'input[name$=".customSortIndex"]',
            },
        };

        // Internal compareBy functions (used for sorting)
        const compareByDataRowIndex = function (a, b) {
            // Fall back sorting on data-row-index (this also handles new rows)
            return compareValues(
                $(a).attr('data-row-index'),
                $(b).attr('data-row-index')
            );
        };

        const compareById = function (a, b) {
            return compareValues(
                $(a).find(options.id.selector).val(),
                $(b).find(options.id.selector).val(),
                options.id.nullValue
            );
        };

        const compareBySubscription = function (a, b) {
            return compareValues(
                $(a).find(options.subscription.selector).prop('checked'),
                $(b).find(options.subscription.selector).prop('checked'),
                undefined,
                false
            );
        };

        const compareByDate = function (a, b) {
            return compareValues(
                $(a).find(options.date.selector).val(),
                $(b).find(options.date.selector).val(),
                options.date.nullValue
            );
        };

        const compareByProduct = function (a, b) {
            return compareValues(
                $(a).find(options.product.selector).val(),
                $(b).find(options.product.selector).val(),
                options.product.nullValue
            );
        };

        const compareByCustom = function (a, b) {
            return compareValues(
                $(a).find(options.custom.selector).val(),
                $(b).find(options.custom.selector).val(),
                options.custom.nullValue
            );
        };

        if (sortOrder === options.id.sortOrder) {
            // SORT BY ID
            sort(table, function (a, b) {
                return (
                    compareBySubscription(a, b) ||
                    compareById(a, b) ||
                    compareByDataRowIndex(a, b)
                );
            });
        } else if (sortOrder === options.date.sortOrder) {
            // SORT BY DATE
            sort(table, function (a, b) {
                return (
                    compareBySubscription(a, b) ||
                    compareByDate(a, b) ||
                    compareById(a, b) ||
                    compareByDataRowIndex(a, b)
                );
            });
        } else if (sortOrder === options.product.sortOrder) {
            // SORT BY PRODUCT (ALPHABETIC)
            sort(table, function (a, b) {
                return (
                    compareBySubscription(a, b) ||
                    compareByProduct(a, b) ||
                    compareById(a, b) ||
                    compareByDataRowIndex(a, b)
                );
            });
        } else if (sortOrder === options.custom.sortOrder) {
            // SORT BY CUSTOM ORDERING
            sort(table, function (a, b) {
                return (
                    compareByCustom(a, b) ||
                    compareBySubscription(a, b) ||
                    compareById(a, b) ||
                    compareByDataRowIndex(a, b)
                );
            });
        }
    }

    return {
        sortOrderLines: sortOrderLines,
        sort: sort,
        compareValues: compareValues,
        sortupdate: sortupdate,
    };
})();

$(document).on('tlxLazyLoadDone tlxtabsload tlxtabsactivate', function () {
    if ($('table.pt-reorderable-rows').attr('data-sort-selector')) {
        //see OrderLine.java
        const ORDER_LINE_SORT_ORDER_ID = 0;
        const ORDER_LINE_SORT_ORDER_PRODUCT = 2;
        const ORDER_LINE_SORT_ORDER_CUSTOM = 3;

        const sortSelector = $('table.pt-reorderable-rows').attr(
            'data-sort-selector'
        );
        const $scope = $('table.pt-reorderable-rows').closest('form');

        /**
         * Sort rows in gui according to selected sort order.
         *
         * @author Haakon
         * @date 23. sep 2016
         */
        $(sortSelector, $scope).change(function (e) {
            const sortOrder = parseInt(this.value);

            const tableSelector = $(e.target)
                .closest('[data-for]')
                .attr('data-for');
            const $table = $scope.find(tableSelector);

            //NB! This sorting should reflect OrderLine.sort()!
            if (e.target.name.split('.')[1] === 'orderLinesSortOrder') {
                // sort order in GUI
                if (sortOrder === ORDER_LINE_SORT_ORDER_ID) {
                    window.ptReorder.sortOrderLines($table, 0);
                } else if (sortOrder === ORDER_LINE_SORT_ORDER_PRODUCT) {
                    window.ptReorder.sortOrderLines($table, 2);
                } else if (sortOrder === ORDER_LINE_SORT_ORDER_CUSTOM) {
                    window.ptReorder.sortOrderLines($table, 3);
                }
            }
        });

        /**
         * sets sortorder to Custom if the order of rows are changed by drag and drop.
         *
         * If SORT_ORDER_CUSTOM is not 3 in other classes this listener needs some rewriting, but as of now i don't know of any.
         */
        $($scope).on('sortupdate', '.ui-sortable', function () {
            setElementValue($(sortSelector)[0], ORDER_LINE_SORT_ORDER_CUSTOM);
        });
        /**
         * Set sort order to custom when adding order groups
         */
        $($scope).on('click', '.js-newOrderGroupButton', function () {
            setElementValue($(sortSelector)[0], ORDER_LINE_SORT_ORDER_CUSTOM);
        });
    }

    if ($('table.pt-reorderable-rows').length > 0) {
        /**
         * Ensure that changes in the row order is reflected
         * in the customSortIndex property.
         *
         * @author Haakon
         * @date 23. sep 2016
         */
        const $pageScope = $('.pt-reorderable-rows').closest('form');
        $pageScope.on(
            'change',
            'table.pt-reorderable-rows tr:visible',
            function (event) {
                let index;
                if ($(event.target).is('tr')) {
                    index = $(this).closest('tbody').find('tr').index(this);
                    $(this)
                        .find('input[name$=".customSortIndex"]')
                        .val(index)
                        .trigger('change');
                }
            }
        );

        //Set sort index after new row is added
        $pageScope.on('click', '.js-newRowButton', function () {
            const $tBody = $(this).closest('table').children('tbody').get(0);
            window.ptReorder.sortupdate($tBody);
        });
    }
});
