import { useState } from 'react';
import { FetchState } from '../../hooks/fetch/types';
import { useDebouncedQuery } from '../../hooks/useDebouncedQuery';
import { LoadableDropdownOption, UseLoadableDropdownReturn } from './types';
import {
    ParamsType,
    useFetchMany,
    useFetchOne,
} from '../../hooks/fetch/useFetch';

export type UseDropdownProps<Params extends ParamsType> = {
    defaultValue?: string;
    value?: string;
    params?: Params;
    onChange?: (value: string) => void;
};

export function useDropdown<
    Params extends ParamsType,
    Model extends LoadableDropdownOption
>(
    getSingleUrl: string,
    getManyUrl: string,
    { defaultValue, value, params, onChange }: UseDropdownProps<Params> = {},
    fields: string[] = ['id', 'displayName']
): UseLoadableDropdownReturn<Model> {
    const [query, setQuery] = useState('');
    const debouncedQuery = useDebouncedQuery(query, 250);
    const models = useFetchMany<Params, Model>(
        getManyUrl,
        params,
        debouncedQuery,
        fields
    );
    const defaultDisplayName = useModelDisplayName(
        getSingleUrl,
        value ?? defaultValue
    );

    return {
        items: models as any,
        defaultDisplayName,
        defaultValue: defaultValue,
        onChange,
        query,
        setQuery,
    };
}

function useModelDisplayName<Model>(
    getSingleUrl: string,
    value: string | undefined
): FetchState<string> {
    const id =
        value === '' || value === '-1' || value === '-2' ? undefined : value;
    const model = useFetchOne<Model>(getSingleUrl, id, ['id', 'displayName']);

    if (id === undefined) {
        return {
            data: undefined,
            error: undefined,
            isLoading: false,
        };
    }

    return asDisplayName(model as any);
}

function asDisplayName(
    state: FetchState<{ displayName: string }>
): FetchState<string> {
    return {
        data: state.data?.displayName,
        error: state.error,
        isLoading: state.isLoading,
    };
}
