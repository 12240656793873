import React from 'react';
import { Input } from '@tlx/atlas';

type DomesticOrForeignPaymentProps = {
    isForeignPayment: boolean | undefined;
    setForeignPayment: (direction: boolean) => void;
    name: string;
};

export const DomesticOrForeignPayment: React.FC<DomesticOrForeignPaymentProps> =
    ({ isForeignPayment, setForeignPayment, name }) => {
        return (
            <div className="atl-gap-8 atl-flex atl-flex-col">
                <div className="atl-group__label">
                    {getMessage('text_autopay_domestic_or_foreign_field')}
                </div>
                <label className="atl-flex atl-items-center atl-gap-8">
                    <Input
                        data-testid="paymentDirectionDomestic"
                        id="paymentDirectionDomestic"
                        type="radio"
                        name={name}
                        value="false"
                        checked={isForeignPayment === false}
                        onChange={() => setForeignPayment(false)}
                    />
                    <div className="atl-text-sm">
                        {getMessage('text_autopay_domestic_payment')}
                    </div>
                </label>

                <label className="atl-flex atl-items-center atl-gap-8">
                    <Input
                        data-testid="paymentDirectionAbroad"
                        id="paymentDirectionAbroad"
                        type="radio"
                        name={name}
                        value="true"
                        checked={isForeignPayment === true}
                        onChange={() => setForeignPayment(true)}
                    />
                    <div className="atl-text-sm">
                        {getMessage('text_autopay_foreign_payment')}
                    </div>
                </label>
            </div>
        );
    };
