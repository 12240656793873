import React, { createContext, useEffect, useMemo, useState } from 'react';
import { Modal } from '@tlx/atlas';
import {
    InvoiceSettings,
    PaymentType,
} from '@Page/ProjectInvoicingDialog/types/InvoicingDialog.type';
import { ConnectedInvoicingForm } from '@Page/ProjectInvoicingDialog/component/InvoicingForm/ConnectedInvoicingForm';
import { BatchInvoicing } from '@Page/ProjectInvoicingDialog/component/BatchInvoicing/BatchInvoicing';
import {
    doBatchInvoice,
    useFetchCompany,
    useFetchInvoices,
    useFetchOrdersOrProjects,
} from '@Page/ProjectInvoicingDialog/component/InvoicingModalDataFetching';
import { LoadingSpinner } from '@Component/Loading';
import { createAPIRequest } from '../../../hooks/fetch/createAPIRequest';
import { ConnectedSetup } from '@Page/ProjectInvoicingDialog/component/SetupForm/ConnectedSetup';

export type InvoicingModalContextType = {
    trackingIdContext: string;
};

export const InvoicingModalContext = createContext<InvoicingModalContextType>({
    trackingIdContext: '',
});

export interface InvoicingModalProps {
    forwardURL: string | undefined;
    isOpenByDefault: boolean;
    orderIds: number[];
    projectIds: number[];
    invoiceIds: number[];
    doBatchInvoicing: boolean;
    isProjectInvoicingModal: boolean;
    periodEndDate?: string;
    readyForBilling?: string;
    trackingIdContext?: string;
}

export function InvoicingModal({
    forwardURL,
    isOpenByDefault,
    orderIds,
    projectIds,
    invoiceIds,
    doBatchInvoicing,
    isProjectInvoicingModal,
    periodEndDate,
    readyForBilling,
    trackingIdContext,
}: InvoicingModalProps) {
    const [isOpen, setIsOpen] = useState(isOpenByDefault);

    // To allow invoice number to be 1
    const [haveChosenInvoiceNumberNow, setHaveChosenInvoiceNumberNow] =
        useState(false);

    const { data: company, refresh: refreshCompany } =
        useFetchCompany(contextId);
    const { data: invoiceSettings, refresh: refreshInvoiceSettings } =
        useFetchInvoiceSettings();

    function refresh() {
        void refreshCompany(contextId);
        void refreshInvoiceSettings();
        setHaveChosenInvoiceNumberNow(true);
    }

    const isLoading = invoiceSettings == undefined || company == undefined;

    const isReadyToInvoice =
        !isLoading &&
        invoiceSettings.bankAccountReady &&
        (invoiceSettings.hasFirstInvoiceNumber || haveChosenInvoiceNumberNow) &&
        company?.organizationNumber;

    function getContent() {
        if (isLoading) {
            return (
                <div className="kb-invoicing-modal__single-invoicing atl-flex atl-justify-center atl-items-center">
                    <LoadingSpinner />
                </div>
            );
        }
        if (!isReadyToInvoice) {
            return (
                <ConnectedSetup
                    closeModal={() => setIsOpen(false)}
                    invoiceSettings={invoiceSettings}
                    companyOrgNr={company?.organizationNumber}
                    refresh={refresh}
                />
            );
        }
        return (
            <InvoicingDialogContent
                orderIds={orderIds}
                projectIds={projectIds}
                invoiceIds={invoiceIds}
                invoiceSettings={invoiceSettings}
                closeModal={() => setIsOpen(false)}
                forwardURL={forwardURL}
                isProjectInvoicingModal={isProjectInvoicingModal}
                doBatchInvoicing={doBatchInvoicing}
                periodEndDate={periodEndDate}
                readyForBilling={readyForBilling}
            />
        );
    }

    const trackingIdContextMemo = useMemo<InvoicingModalContextType>(() => {
        return {
            trackingIdContext: trackingIdContext ?? '',
        } as InvoicingModalContextType;
    }, [trackingIdContext]);

    return (
        <InvoicingModalContext.Provider value={trackingIdContextMemo}>
            <button
                id="open-invoicing-dialog-trigger"
                data-testid={'open-invoicing-dialog-trigger'}
                onClick={() => setIsOpen(true)}
                className="atl-hidden"
            >
                Invoicing
            </button>
            {isOpen && (
                <Modal
                    className="atl-p-0"
                    open={isOpen}
                    onClose={() => {
                        setIsOpen(false);
                    }}
                >
                    <div>{getContent()}</div>
                </Modal>
            )}
        </InvoicingModalContext.Provider>
    );
}

type InvoicingDialogContentProps = {
    orderIds: number[];
    projectIds: number[];
    invoiceIds: number[];
    invoiceSettings: InvoiceSettings;
    closeModal: () => void;
    forwardURL: string | undefined;
    isProjectInvoicingModal: boolean;
    doBatchInvoicing?: boolean;
    periodEndDate?: string;
    readyForBilling?: string;
};

const InvoicingDialogContent = ({
    orderIds,
    projectIds,
    invoiceIds,
    invoiceSettings,
    closeModal,
    forwardURL,
    isProjectInvoicingModal,
    doBatchInvoicing,
    periodEndDate,
    readyForBilling,
}: InvoicingDialogContentProps) => {
    const paymentTypes = useFetchPaymentTypes();
    const { data: company } = useFetchCompany(contextId);

    const {
        ordersOrProjects: orders,
        isLoadingOrdersOrProjects: isLoadingOrders,
    } = useFetchOrdersOrProjects(isProjectInvoicingModal, false, orderIds);

    const {
        ordersOrProjects: projects,
        isLoadingOrdersOrProjects: isLoadingProjects,
    } = useFetchOrdersOrProjects(isProjectInvoicingModal, true, projectIds);

    const { invoices, isLoadingInvoices } = useFetchInvoices(invoiceIds);

    if (paymentTypes == undefined) {
        return null;
    }

    const isLoading = isLoadingOrders || isLoadingProjects || isLoadingInvoices;

    if (!isLoading) {
        for (const order of orders) {
            order.preliminaryInvoice = invoices.find((invoice) => {
                return invoice.projectInvoiceDetails.some(
                    (details) => details.project.id === order.id
                );
            });
        }
        for (const project of projects) {
            project.preliminaryInvoice = invoices.find((invoice) => {
                return invoice.projectInvoiceDetails.some(
                    (details) => details.project.id === project.id
                );
            });
        }
    }

    let invoicingView;
    if (orders.length + projects.length === 1 && !doBatchInvoicing) {
        if (isLoading) {
            // Loading could be moved to ConnectedInvoicingForm, -
            // but we agreed to keep it simple for now is loading is almost instant
            invoicingView = (
                <div className="kb-invoicing-modal__loading-single-invoicing">
                    <LoadingSpinner />
                </div>
            );
        } else {
            invoicingView = (
                <ConnectedInvoicingForm
                    className="kb-invoicing-modal__single-invoicing"
                    invoiceSettings={invoiceSettings}
                    paymentTypes={paymentTypes}
                    closeModal={closeModal}
                    orderOrProject={orders.length > 0 ? orders[0] : projects[0]}
                    forwardURL={forwardURL}
                    isProjectInvoicingModal={isProjectInvoicingModal}
                    company={company}
                    periodEndDate={periodEndDate}
                    readyForBilling={readyForBilling}
                />
            );
        }
    } else if (orders.length + projects.length > 1 || doBatchInvoicing) {
        invoicingView = (
            <BatchInvoicing
                isLoading={isLoading}
                ordersAndProjectsLoadingCount={orders.length + projects.length}
                className="kb-invoicing-modal__batch-invoicing"
                company={company}
                invoiceSettings={invoiceSettings}
                orders={orders}
                projects={projects}
                onSubmit={doBatchInvoice}
                forwardURL={forwardURL}
                isProjectInvoicingModal={isProjectInvoicingModal}
                periodEndDate={periodEndDate}
                readyForBilling={readyForBilling}
            />
        );
    }

    return <>{(orders || projects) && <div>{invoicingView}</div>}</>;
};

function useFetchInvoiceSettings() {
    const [data, setData] = useState<InvoiceSettings | undefined>();
    const [error, setError] = useState();
    const [isLoading, setIsLoading] = useState(false);

    async function refresh() {
        setError(undefined);
        setIsLoading(true);

        try {
            const response = await window.fetch(
                createAPIRequest('/v2/invoice/settings?fields=*')
            );
            const data = await response.json();
            setData(data.value);
        } catch (error) {
            setError(error);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        void refresh();
    }, []);

    return { data, error, isLoading, refresh };
}

function useFetchPaymentTypes() {
    const [data, setData] = useState<{ values: PaymentType[] | undefined }>();

    useEffect(() => {
        window
            .fetch(
                createAPIRequest(
                    '/v2/invoice/paymentType/query?showOrderInvoice=true&fields=id, description, debitAccount(currency(code))&sorting=sequence'
                )
            )
            .then((response) => response.json())
            .then((data) => setData(data));
    }, []);

    return data?.values;
}
