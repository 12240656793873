import { postValidationMessages } from '@General/LegacyValidationPopup';
import { CSRF_HEADER_NAME, getCSRFToken } from '../../../../../js/modules/csrf';
import { ResaleProductDTO } from '../types/dtoTypes';
import { DeviceType, TripletexValidationMessage } from './constants';
import { SortingType } from '@Page/LogisticsV2/utils/enums';
import { getEntitlements } from '@General/getEntitlements';
import { EntitlementLookup } from '@General/constants';

export function getDeviceType(windowWidth: number): DeviceType {
    if (windowWidth < 780) {
        return 'mobile';
    } else {
        return 'desktop';
    }
}

export function isNumberColumn(key: string) {
    return (
        key === 'sellingPriceInclVat' ||
        key === 'sellingPriceExclVat' ||
        key === 'purchasePrice' ||
        key === 'priceExcludingVatCurrency' ||
        key === 'priceIncludingVatCurrency' ||
        key === 'costExcludingVatCurrency' ||
        key === 'stockOfGoods' ||
        key === 'availableStock' ||
        key === 'incomingStock' ||
        key === 'outgoingStock' ||
        key === 'expenses' ||
        key === 'costCurrency'
    );
}

export function getDisplayNumber(product: Partial<ResaleProductDTO>) {
    if (product.elNumber && product.elNumber !== '') {
        return product.elNumber;
    }
    if (product.nrfNumber && product.nrfNumber !== '') {
        return product.nrfNumber;
    }
    return product.number;
}

export function getFieldErrorFromValidationMessages(
    validationMessages: TripletexValidationMessage[] | undefined,
    field: string
) {
    if (validationMessages === undefined) {
        return undefined;
    }
    return validationMessages.find((item) => item.field === field)?.message;
}

export function getHeaders(
    additionalHeaders: { [name: string]: string } = {
        'Content-Type': 'application/json; charset=utf-8',
    }
) {
    return new Headers({
        accept: 'application/json; charset=utf-8',
        'x-tlx-context-id': window.contextId.toString(),
        [CSRF_HEADER_NAME]: getCSRFToken(),
        ...additionalHeaders,
    });
}

export function getIdFromUrl(param: string) {
    const queryParams = new URLSearchParams(window.location.search);
    return queryParams.get(param);
}

export function getURL(
    url: string | null | undefined,
    queryParams?: URLSearchParams
) {
    if (url == null) {
        return null;
    }
    if (queryParams === undefined) {
        return url;
    }
    return `${url}?${queryParams}`;
}

export async function getValue(response: Response) {
    return (await response.json())?.value;
}

export async function getValues(response: Response) {
    return (await response.json())?.values;
}

export function getValidationMessagesFromResponse(
    response: {
        validationMessages: TripletexValidationMessage[];
    },
    defaultMessage?: string
) {
    if (response.validationMessages?.length) {
        response.validationMessages.forEach(
            (validationMessage: TripletexValidationMessage) => {
                postValidationMessages(
                    getFieldTextKey(validationMessage.field)
                        ? `${getFieldTextKey(validationMessage.field)}: ${
                              validationMessage.message
                          }`
                        : validationMessage.message
                );
            }
        );
    } else if (defaultMessage) {
        postValidationMessages(defaultMessage);
    }
}

function getFieldTextKey(field: string) {
    //Response error return the field in english, we have to handle manually every possible field
    switch (field) {
        case 'date':
            return getMessage('text_date');
        case 'count':
            return getMessage('text_count');
        default:
            return undefined;
    }
}

export const getCurrentDate = () => {
    const newDate = new Date();
    const day =
        newDate.getDate() < 10 ? `0${newDate.getDate()}` : newDate.getDate();
    const month =
        newDate.getMonth() + 1 < 10
            ? `0${newDate.getMonth() + 1}`
            : newDate.getMonth() + 1;
    return newDate.getFullYear() + '-' + month + '-' + day;
};

export const validateDate = (inputDate: string) => {
    const regex = /^\d{4}-\d{2}-\d{2}$/;

    if (regex.test(inputDate)) {
        const parts = inputDate.split('-');
        const year = parseInt(parts[0], 10);
        const month = parseInt(parts[1], 10) - 1; // Month is zero-based in JavaScript
        const day = parseInt(parts[2], 10);

        const date = new Date(year, month, day);

        if (
            date.getFullYear() === year &&
            date.getMonth() === month &&
            date.getDate() === day
        ) {
            // Date is valid
            return true;
        } else {
            // Date is invalid
            return false;
        }
    } else {
        // Date format is invalid
        return false;
    }
};

export function getSortDirection(
    sortBy: SortingType,
    sortAscending: SortingType,
    sortDescending: SortingType
) {
    if (sortBy === sortAscending) {
        return 'ascending';
    } else if (sortBy === sortDescending) {
        return 'descending';
    } else {
        return undefined;
    }
}

export function getSorting(
    currentSort: SortingType,
    ascending: SortingType,
    descending: SortingType
) {
    switch (currentSort) {
        case SortingType.NO_SORTING:
            return ascending;
        case ascending:
            return descending;
        case descending:
            return SortingType.NO_SORTING;
        default:
            return ascending;
    }
}

export const checkEntitlements = (
    entitlementLookup: EntitlementLookup[]
): boolean => {
    const entitlements = getEntitlements();

    if (
        entitlements === undefined ||
        entitlements.length === 0 // Just because it is not async just yet, Can be removed when entitlements is in 'window'
    ) {
        return false;
    }

    return entitlements.some((x: any) => {
        return entitlementLookup.includes(x.id);
    });
};
