import { ModelAPIV1 } from '@General/types/Api';
import { notUndefined } from '@General/Helpers';
import { ApiResultMapper } from '@General/Provider';
import { JSPDropdownFetcher } from '../JSPDropdownFetcher';
import { Option } from '../../types';

interface ProductUnitEntry extends ModelAPIV1 {
    readonly displayName: string;
}

const productUnitMapper: ApiResultMapper<ProductUnitEntry> = (item) => ({
    value: `${item.id}`,
    displayName: item.displayName,
    item,
});

class ProductUnitFetcher extends JSPDropdownFetcher<ProductUnitEntry> {
    asyncJsonrpcGetter: Function;

    constructor(scope: HTMLDivElement, queryAttributes: Record<string, any>) {
        super(scope, queryAttributes, productUnitMapper);
        this.asyncJsonrpcGetter = asyncrpc.Productunit.get;
    }

    getParams(): ObjectIndex {
        return {};
    }

    async fetchList(params: ObjectIndex): Promise<Option<ProductUnitEntry>[]> {
        return new Promise((resolve, reject) => {
            try {
                jsonrpc.Productunit.searchForProductunits(
                    (result: ProductUnitEntry[] | null) => {
                        const mapped = (result ?? [])
                            .filter(notUndefined)
                            .map(this.getMapper(params))
                            .filter(notUndefined);
                        resolve(mapped);
                    },
                    this.getMarshallSpec(),
                    window.loginCompanyId,
                    params.name || ''
                );
            } catch (e) {
                defaultJsonRpcExceptionHandler(e);
                reject(e);
            }
        });
    }
}

export const exports = {
    fetcher: ProductUnitFetcher,
    type: 'ProductUnit',
};
