import { PageData, PageLoader } from '@Component/PageLoader';
import { RouteData } from '@General/Router';
import { Team } from '@General/Team';
import { EntitlementLookup } from '@General/constants';

const ALLOWED_ROLES = [EntitlementLookup.AUTH_DIRECT_REMIT_ADMIN_ZTL];

const Page = (): JSX.Element => (
    <PageLoader
        adjustWrapperDiv
        loader={async (): Promise<PageData> =>
            import('./PaymentOverviewPage').then((m) => m.page)
        }
    />
);

export const route: RouteData = {
    component: Page,
    path: '/execute/paymentOverview',
    team: Team.PBC,
    supportsXLS: true,
    documentationComponent: 275,
    auth: (context) => {
        if (window.segmentationData?.modules?.moduleRemitZtl === true) {
            return context.entitlements.some((e) =>
                ALLOWED_ROLES.includes(e.id as EntitlementLookup)
            );
        }

        return (
            window.segmentationData?.modules?.moduleRemit === true &&
            window.segmentationData?.modules?.moduleRemitAutoPay === true &&
            context.entitlements.some((e) =>
                [
                    EntitlementLookup.AUTH_DIRECT_REMIT_ADMIN,
                    EntitlementLookup.AUTH_DIRECT_REMIT_LIGHT,
                ].includes(e.id as EntitlementLookup)
            )
        );
    },
};
