import React from 'react';
import { OpenNewIcon } from '@tlx/atlas';
import Link from '@Component/Link/Link';
import classNames from 'classnames';
import EmptyProductGroupsIcon from '@Page/LogisticsV2/components/Modals/ProductSelector/EmptyProductGroupsIcon';

export function ProductGroupsEmptyState({
    className,
}: {} & {
    className?: string;
}) {
    return (
        <div className={classNames(className)}>
            <div className={'atl-text-center atl-mt-16'}>
                <EmptyProductGroupsIcon width={180} height={200} />
            </div>
            <div
                className={
                    'atl-text-center atl-mt-32 atl-text-xl atl-font-bold'
                }
            >
                {getMessage('text_no_productgroups')}
            </div>
            <div className={'atl-text-center atl-mt-32 atl-text-base'}>
                {getMessage('text_start_using_product_groups')}
            </div>

            <Link
                href={`/execute/productSettingsMenu?contextId=${contextId}#productGroupSettings`}
                target="_blank"
                className={
                    'atl-text-center atl-mt-32 atl-text-base atl-justify-center atl-flex atl-items-center'
                }
            >
                <span className={'atl-mr-4'}>
                    {getMessage('text_go_to_product_groups')}
                </span>
                <OpenNewIcon
                    className={'goldshark-productselector__emptystate_icon'}
                />
            </Link>
        </div>
    );
}
