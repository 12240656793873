import $ from 'jquery';

import { format } from '../../js/modules/format.ts';

/**
 * Tabzum dynamically sums up columns in a table on specified events.
 * The first summation occurs when the widget is run on a table. The
 * plugin should be run on the sum-container, i.e. the DOM-node
 * which presents the computed sum. There should be no issue if this
 * node is whitin the table.
 *
 * @author Lars-Erik Bruce
 *
 */
$.widget('tlx.tabzum', {
    options: {
        /**
         * Given a row, find out if this column should be summed up.
         * Returns true if this is the case, othervise false.
         */
        criterium: function ($tr) {
            return $tr
                .find('td.select [type=checkbox][name$=\\.selected]')
                .is(':checked');
        },
        /**
         * The pattern used to see if the row is interesting i.e. if it contains what
         * we are going to sum up. We need to know this when counting the frequenzy
         * of summed rows.
         */
        locator: 'tr.tabzum',
        /**
         * The table to perform calculations on.
         */
        $table: null,
        /**
         * How to identify the column we are about to sum up.
         */
        columnSelector: 'td.sumNOK',
        /**
         * What event should we listen on for making updates to the sum.
         */
        event: 'change',
        labelText: 'Sum',
        /**
         * How should the sum be set.
         */
        setSum: function (element, sum, freq) {
            if (freq > 0 && Math.abs(sum) < 10) {
                element.addClass('ok');
            } else {
                element.removeClass('ok');
            }
            // Hide box if freq is 0, i.e. no boxes are selected.
            if (freq === 0) {
                element.hide();
            } else {
                element.show();
            }
            element.find('.numberBox').text(format.amount2(sum));
        },
        draggable: true,
    },
    _create: function () {
        var that = this,
            sum = 0,
            freq = 0,
            $table = that.options.$table,
            $element = this.element;
        $table.find(that.options.locator).each(function () {
            if (that.options.criterium($(this))) {
                sum += window.parseFloat2(
                    $(this)
                        .find(that.options.columnSelector)
                        .text()
                        .replace(/\s/g, '')
                );
                freq++;
            }
        });
        $element
            .append(that.options.labelText + '<div class="numberBox"></div>')
            .css('position', 'fixed')
            /**
             * Bugfix 04.09.2019:
             * We use right: rule in css for placement of widget when it first
             * appears, but as of jQuery UI 1.12 the right-property is not
             * animated any longer. Therefore we got a weird bug where the widget
             * just got longer short (with a fixed right position) on drag.
             *
             * This was the only way I found how to set a placement of the widget
             * when it opens, but at the same time that right placement isn't
             * static any more.
             *
             * @auth bruce
             */
            .on('dragstart', function () {
                $element.css('right', 'auto');
            });

        if (this.options.draggable) {
            $element.draggable();
        }

        that.options.setSum(that.element, sum, freq);
        that.sum = sum;
        that.freq = freq;

        $table.on(this.options.event, function (e) {
            var $row = $(e.target).closest(that.options.locator);
            if ($row.length === 0) return;

            var value = window.parseFloat2(
                $row.find(that.options.columnSelector).text().replace(/\s/g, '')
            );
            if (that.options.criterium($(e.target).closest('tr'))) {
                that.sum = that.sum + value;
                that.freq = that.freq + 1;
            } else {
                that.sum = that.sum - value;
                that.freq = that.freq - 1;
            }
            // Removing rounding errors when sum is close to 0 again
            if (Math.abs(that.sum) < 0.005) that.sum = 0;
            that.options.setSum($element, that.sum, that.freq);
        });
    },
    _destroy: function () {
        this.options.$table.off(this.options.event);
        this.element.off('dragstart');
    },
});
