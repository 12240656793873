export function getColumnLabel(column: string): string {
    switch (column) {
        case 'stockOfGoods':
            return getMessage('text_stock_of_goods');
        case 'warehouse':
            return getMessage('text_warehouse');
        case 'costExcludingVatCurrency':
            return getMessage('text_cost_price');
        case 'priceExcludingVatCurrency':
            return getMessage('text_sales_price_excl_vat');
        case 'currency(code)':
            return getMessage('text_currency');
        case 'account(displayName)':
            return getMessage('text_income_account');
        case 'productUnit':
            return getMessage('text_product_unit');
        case 'vatType(displayName)':
            return getMessage('text_vat_code');
        case 'priceIncludingVatCurrency':
            return getMessage('text_sales_price_incl_vat');
        case 'ean':
            return getMessage('text_EAN_GTIN');
        case 'availableStock':
            return getMessage('text_stock_of_goods_available');
        case 'incomingStock':
            return getMessage('text_incoming_stock');
        case 'outgoingStock':
            return getMessage('text_outgoing_stock');
        case 'costCurrency':
            return getMessage('text_cost_price');
        case 'description':
            return getMessage('text_product_description');
        case 'orderLineDescription':
            return getMessage('text_orderline_description');
        default:
            throw new Error(`Unknown column: ${column}`);
    }
}

export const getAvailableColumnsInProductOverview = () => {
    return [
        ...((moduleLogistics || moduleLogisticsLight) && !moduleRackbeat
            ? ['stockOfGoods']
            : []),
        ...(hasAvailableStock && !moduleRackbeat ? ['availableStock'] : []),
        ...(hasAvailableStock && !moduleRackbeat ? ['incomingStock'] : []),
        ...(hasAvailableStock && !moduleRackbeat ? ['outgoingStock'] : []),
        ...(moduleWarehouse &&
        (moduleLogistics || moduleLogisticsLight) &&
        !moduleRackbeat
            ? ['warehouse']
            : []),
        'costExcludingVatCurrency',
        'priceExcludingVatCurrency',
        ...(window.moduleCurrency ? ['currency(code)'] : []),
        'account(displayName)',
        'productUnit',
        'vatType(displayName)',
        ...(isVatOn ? ['priceIncludingVatCurrency'] : []),
        ...(moduleLogistics || moduleLogisticsLight || moduleRackbeat
            ? ['ean']
            : []),
        'description',
        ...(pilotOrderLineDescription ? ['orderLineDescription'] : []),
    ];
};

export const getDefaultColumnsInProductOverview = () => {
    return [
        ...((moduleLogistics || moduleLogisticsLight) && !moduleRackbeat
            ? ['stockOfGoods']
            : []),
        ...(moduleWarehouse &&
        (moduleLogistics || moduleLogisticsLight) &&
        !moduleRackbeat
            ? ['warehouse']
            : []),
        'costExcludingVatCurrency',
        'priceExcludingVatCurrency',
        ...(window.moduleCurrency ? ['currency(code)'] : []),
    ];
};

export const availableColumnsInProductOverviewWithSupplier = () => {
    return [
        'costExcludingVatCurrency',
        'costCurrency',
        ...(window.moduleCurrency ? ['currency(code)'] : []),
        'productUnit',
        ...(moduleLogistics || moduleLogisticsLight || moduleRackbeat
            ? ['ean']
            : []),
    ];
};

export const defaultColumnsForProductOverviewWithSupplier = [
    'costExcludingVatCurrency',
    'costCurrency',
    ...(window.moduleCurrency ? ['currency(code)'] : []),
];

export type InvoiceSettingsDTO = {
    availableInventory: boolean;
};
