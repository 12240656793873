import {
    Dropdown,
    DropdownDrawer,
    DropdownError,
    DropdownLoadMoreTarget,
    DropdownScrollContainer,
    DropdownSearchOpener,
    Group,
    Label,
    Option,
} from '@tlx/atlas';
import React from 'react';
import { DropdownSkeleton } from '@Page/LogisticsV2/components/Loading/Skeleton/DropdownSkeleton';
import {
    DropdownOption,
    UseApiPaginatedReturn,
} from '@Page/LogisticsV2/types/returnTypes';
import './DropdownInput.css';
/*
!this is a dropdown input with:
-- search functionality
-- infinite scroll
-- skeleton loading
*/

export function DropdownInput({
    data,
    label,
    defaultValue,
    className,
    error,
    value,
    onChange,
}: {
    data: UseApiPaginatedReturn<DropdownOption[]>;
    label?: string;
    value?: string;
    defaultValue?: string;
    className?: string;
    error?: string;
    onChange: (value: string) => void;
}) {
    return (
        <Group className={'atl-py-8 atl-flex atl-flex-col'}>
            <Label className={className}>{label}</Label>
            <Dropdown
                data-testid="goldshark-dropdown-input"
                name={getMessage('text_products')}
                onChange={
                    onChange
                        ? (event) => onChange(event.target.value)
                        : undefined
                }
                defaultValue={defaultValue}
                value={value}
            >
                <DropdownSearchOpener
                    onChange={(event) => data.search(event.target.value)}
                />
                <DropdownDrawer>
                    <DropdownScrollContainer>
                        <Option value={'-1'} key={'-1'}>
                            {getMessage('option_not_chosen')}
                        </Option>
                        {data.data.map((item) => (
                            <Option key={item.id} value={item.id.toString()}>
                                {item.name}
                            </Option>
                        ))}
                        <DropdownLoadMoreTarget
                            hasMore={data.hasMore}
                            onLoadMore={data.loadMore}
                        />
                        {data.isLoading && <DropdownSkeleton />}
                        <DropdownError error={data.error} />
                    </DropdownScrollContainer>
                </DropdownDrawer>
            </Dropdown>
            {error && (
                <span className={'goldshark-input__error atl-break-all'}>
                    {error}
                </span>
            )}
        </Group>
    );
}
