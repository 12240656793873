import { Button } from '@tlx/atlas';
import React from 'react';
import { NumberFormat } from '@Page/LogisticsV2/components/NumberFormat/NumberFormat';

export function ProductDiscountModalSummary({
    priceBeforeDiscount,
    priceAfterDiscount,
}: {
    priceBeforeDiscount: number;
    priceAfterDiscount: number;
}) {
    return (
        <div className="atl-flex atl-flex-col atl-bg-bg atl-p-16 atl-gap-8 atl-rounded">
            <div>{getMessage('text_sales_price_excl_vat')}</div>
            <div className="atl-flex atl-gap-8">
                <div className="atl-flex-1 atl-flex atl-gap-8">
                    {getMessage('text_before_discount')}:
                    <div className={'atl-font-bold'}>
                        <NumberFormat
                            value={priceBeforeDiscount}
                            options={{
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            }}
                        />
                    </div>
                </div>
                <div className="atl-flex-1 atl-flex atl-gap-8">
                    {getMessage('text_after_discount')}:
                    <div className={'atl-font-bold'}>
                        <NumberFormat
                            value={priceAfterDiscount}
                            options={{
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export function ProductDiscountModalActions({
    formId,
    isButtonDisabled,
    isEditMode,
    onCancel,
}: {
    formId: string;
    isButtonDisabled: boolean;
    isEditMode: boolean;
    onCancel: () => void;
}) {
    return (
        <div className="atl-flex atl-gap-8">
            <Button
                data-testid={
                    isEditMode
                        ? 'discount-modal-save-button'
                        : 'discount-modal-create-button'
                }
                form={formId}
                type="submit"
                variant="primary"
                disabled={isButtonDisabled}
                tabIndex={9999}
            >
                {isEditMode
                    ? getMessage('button_save')
                    : getMessage('button_create')}
            </Button>
            <Button
                data-testid={'discount-modal-cancel-button'}
                variant="secondary"
                onClick={onCancel}
                tabIndex={9999}
            >
                {getMessage('button_cancel')}
            </Button>
        </div>
    );
}
