import { Container } from '../Container/Container';
import { useState } from 'react';
import { OrderDateForJSP } from '@Page/OrderTopSection/OrderDate/OrderDate';
import { DueDateForJSP } from '@Page/OrderTopSection/DueDate/DueDate';
import { useViewOptions } from '@Page/InvoicingModeSwitcher/dataFetching';

export function OrderTopSection() {
    const [isOrderDateEditMode, setIsOrderDateEditMode] = useState(false);
    const [isDueDateEditMode, setIsDueDateEditMode] = useState(false);

    const viewOptions = useViewOptions();

    if (!viewOptions?.showAsSimple) {
        return null;
    }

    return (
        <div className="atl-flex atl-flex-wrap atl-gap-8 atl-flex-wrap">
            <Container
                isEditMode={isOrderDateEditMode}
                setIsEditMode={setIsOrderDateEditMode}
            >
                <OrderDateForJSP
                    isEditMode={isOrderDateEditMode}
                    setIsEditMode={setIsOrderDateEditMode}
                />
            </Container>
            <Container
                isEditMode={isDueDateEditMode}
                setIsEditMode={setIsDueDateEditMode}
            >
                <DueDateForJSP
                    isEditMode={isDueDateEditMode}
                    setIsEditMode={setIsDueDateEditMode}
                />
            </Container>
        </div>
    );
}
