import {
    InvoiceSettings,
    OrderOrProject,
    PaymentType,
} from '@Page/ProjectInvoicingDialog/types/InvoicingDialog.type';
import { useForm } from 'react-hook-form';
import { dateUtil } from '../../../../../../js/modules/date';
import { useEffect } from 'react';
import { format } from '../../../../../../js/modules/format';
import { DoInvoiceFieldValues } from '@Page/ProjectInvoicingDialog/component/InvoicingForm/ConnectedInvoicingForm';
import { SendTypes } from '@Page/ProjectInvoicingDialog/component/InvoicingForm/SendMethod';
import { ElectronicReceiveState } from '@Page/ProjectInvoicingDialog/component/InvoicingForm/Hooks';
import { calculateDueDaysFromToday } from '@Page/ProjectInvoicingDialog/DueDateUtil';
import { FormProps } from './InvoicingForm';
import { validateEmailList } from '../../../../util/validateEmail';

export function useDoInvoice(
    orderOrProject: OrderOrProject,
    paymentTypes: PaymentType[],
    onSubmit: (
        formValues: DoInvoiceFieldValues,
        orderOrProject: OrderOrProject,
        sendMethods: string[]
    ) => Promise<string[]>,
    canReceiveElectronicInvoices: ElectronicReceiveState,
    invoiceSettings: InvoiceSettings,
    isProjectInvoicingModal: boolean
): FormProps {
    const sendMethods = invoiceSettings.sendTypes;

    function getPreSelectedSendMethod(customerSendMethod: string) {
        if (
            customerSendMethod === SendTypes.EMAIL &&
            orderOrProject.receiverEmail === '' &&
            orderOrProject.customer.invoiceEmail !== ''
        ) {
            return SendTypes.MANUAL;
        }

        if (orderOrProject.customer.isPrivateIndividual) {
            if (invoiceSettings.defaultSendTypeB2C == SendTypes.EMAIL) {
                return customerSendMethod;
            }
        } else if (invoiceSettings.defaultSendTypeB2B == SendTypes.EMAIL) {
            return customerSendMethod;
        }

        const hasChangedEmail =
            orderOrProject.customer.invoiceEmail !==
            orderOrProject.receiverEmail;
        const isMultipleEmails =
            orderOrProject.receiverEmail.includes(';') ||
            orderOrProject.receiverEmail.includes(',');

        if (!isMultipleEmails && !hasChangedEmail && sendMethods) {
            const canSendElectronic = orderOrProject.customer
                .isPrivateIndividual
                ? sendMethods.includes(SendTypes.EFAKTURA) ||
                  sendMethods.includes(SendTypes.VIPPS)
                : sendMethods.includes(SendTypes.EHF);

            if (
                customerSendMethod == SendTypes.EMAIL &&
                canReceiveElectronicInvoices === ElectronicReceiveState.YES &&
                canSendElectronic
            ) {
                return orderOrProject.customer.isPrivateIndividual
                    ? SendTypes.EFAKTURA
                    : SendTypes.EHF;
            }
            if (orderOrProject.customer.invoiceSendMethod === SendTypes.VIPPS) {
                return SendTypes.EFAKTURA;
            }
        }

        return customerSendMethod;
    }

    const sendMethod = getPreSelectedSendMethod(
        orderOrProject.customer.invoiceSendMethod
    );

    const defaultValues = {
        invoiceAKontoWithVAT: orderOrProject.invoiceOnAccountVatHigh,
        aKontoComment: '',
        invoiceDate: dateUtil.formatDate(new Date()),
        deliveryDate: dateUtil.formatDate(new Date()),
        deliveryDateEdited: false,
        createAKonto: false,
        createBackorder: orderOrProject.canCreateBackorder,
        paidAmount: formatAmount(
            orderOrProject.preliminaryInvoice
                ? orderOrProject.preliminaryInvoice.amountCurrency.toString()
                : ''
        ),
        paymentTypeId: '0',
        restAmountPaymentTypeId: '0',
        invoiceSendMethod: sendMethod,
        customerEmail: orderOrProject.receiverEmail,
        customerOrganizationNumber: orderOrProject.customer.organizationNumber,
        customerPhoneNumber: orderOrProject.customer.phoneNumberMobile
            ? orderOrProject.customer.phoneNumberMobile
            : orderOrProject.customer.phoneNumber,
    };

    useEffect(() => {
        // This makes it easier to test in storybook
        reset({
            ...defaultValues,
        });
    }, [
        sendMethods,
        orderOrProject.customer.invoiceSendMethod,
        orderOrProject.customer.invoiceEmail,
        orderOrProject.receiverEmail,
    ]);

    const {
        register,
        control,
        handleSubmit,
        watch,
        getValues,
        setValue,
        reset,
        setError,
        trigger,
        formState: { errors },
    } = useForm<DoInvoiceFieldValues>({
        defaultValues: defaultValues,
    });

    useEffect(() => {
        // set send method after send type lookup is done
        setValue('invoiceSendMethod', defaultValues.invoiceSendMethod);
    }, [canReceiveElectronicInvoices]);

    return {
        fields: {
            invoiceAKontoWithVAT: register('invoiceAKontoWithVAT'),
            aKontoAmount: register('aKontoAmount', {
                validate: (value) => {
                    return (
                        !getValues('createAKonto') ||
                        format.unFormat(value) > 0 ||
                        getMessage('validation_missing')
                    );
                },
                onChange: (event) => {
                    setValue(
                        'paidAmount',
                        formatAmount(event.currentTarget.value)
                    );
                },
            }),
            aKontoComment: register('aKontoComment'),
            invoiceDate: register('invoiceDate', {
                validate: (value) => {
                    return (
                        dateUtil.isDate(value, 'yyyy-MM-dd') ||
                        getMessage('validation_date_format')
                    );
                },
                onChange: (event) => {
                    if (!getValues('deliveryDateEdited')) {
                        setValue('deliveryDate', event.target.value);
                    }
                },
            }),
            deliveryDate: register('deliveryDate', {
                validate: (value) => {
                    return (
                        dateUtil.isDate(value, 'yyyy-MM-dd') ||
                        getMessage('validation_date_format')
                    );
                },
                onChange: () => {
                    setValue('deliveryDateEdited', true);
                },
            }),
            deliveryDateEdited: register('deliveryDateEdited'),
            createAKonto: register('createAKonto'),
            createBackorder: register('createBackorder'),
            paidAmount: register('paidAmount', {
                validate: (value) => {
                    if (Number(getValues('paymentTypeId')) === 0) {
                        return true;
                    }
                    const invoiceAmount =
                        orderOrProject.preliminaryInvoice?.amountCurrency ?? 0;
                    const isCreditNote = invoiceAmount < 0;
                    const paidAmount = format.unFormat(value);
                    const isValidAmount =
                        (isCreditNote &&
                            paidAmount < 0 &&
                            paidAmount >= invoiceAmount) ||
                        (!isCreditNote &&
                            paidAmount > 0 &&
                            paidAmount <= invoiceAmount);

                    return (
                        isValidAmount || getMessage('validation_invalid_amount')
                    );
                },
            }),
            paidAmountAccountCurrency: register('paidAmountAccountCurrency', {
                validate: (value) => {
                    const amount = format.unFormat(value);

                    const paymentType = paymentTypes.find(
                        (type) => type.id === Number(getValues('paymentTypeId'))
                    );
                    const paymentTypeCurrencyCode =
                        paymentType && paymentType.debitAccount.currency
                            ? paymentType.debitAccount.currency.code
                            : undefined;

                    return (
                        Number(getValues('paymentTypeId')) === 0 ||
                        Number(amount) !== 0 ||
                        !paymentTypeCurrencyCode ||
                        paymentTypeCurrencyCode ===
                            orderOrProject.currency.code ||
                        getMessage('validation_missing')
                    );
                },
            }),
            paidAmountRestAccountCurrency: register(
                'paidAmountRestAccountCurrency',
                {
                    validate: (value) => {
                        const amount = format.unFormat(value);

                        const paymentType = paymentTypes.find(
                            (type) =>
                                type.id ===
                                Number(getValues('restAmountPaymentTypeId'))
                        );
                        const paymentTypeCurrencyCode =
                            paymentType && paymentType.debitAccount.currency
                                ? paymentType.debitAccount.currency.code
                                : undefined;

                        return (
                            Number(getValues('restAmountPaymentTypeId')) ===
                                0 ||
                            Number(amount) !== 0 ||
                            !paymentTypeCurrencyCode ||
                            paymentTypeCurrencyCode ===
                                orderOrProject.currency.code ||
                            getMessage('validation_missing')
                        );
                    },
                }
            ),
            paymentTypeId: register('paymentTypeId'),
            restAmountPaymentTypeId: register('restAmountPaymentTypeId'),
            invoiceSendMethod: register('invoiceSendMethod', {
                validate: (value) => {
                    const invoiceDate = dateUtil.parseDate(
                        getValues('invoiceDate')
                    );
                    if (invoiceDate === null) {
                        // invoice date validation should be handled by the invoice date field validation,
                        // and should never happen here. The return here is to avoid showing multiple validation errors for something that should only happen at one field.
                        return undefined;
                    }
                    return validateTermOfPayment(
                        invoiceDate,
                        orderOrProject,
                        value
                    );
                },
            }),
            customerEmail: register('customerEmail', {
                validate: (value) =>
                    getValues('invoiceSendMethod') !== SendTypes.EMAIL ||
                    validateEmailList(value, false),
            }),
            customerPhoneNumber: register('customerPhoneNumber'),
            customerOrganizationNumber: register('customerOrganizationNumber'),
            invoiceIdForCreditNote: register('invoiceIdForCreditNote', {
                validate: (value) => {
                    return (
                        isProjectInvoicingModal ||
                        (orderOrProject.preliminaryInvoice?.amountCurrency ??
                            0) >= 0 ||
                        getValues('invoiceSendMethod') !== SendTypes.EHF ||
                        value != undefined ||
                        getMessage('validation_ehf_credit_note_reference')
                    );
                },
            }),
        },
        errors,
        onSubmit: handleSubmit((formValues) =>
            onSubmit(formValues, orderOrProject, sendMethods).then(
                (errors1) => {
                    if (errors1.length > 0) {
                        setError('invoiceSendMethod', {
                            type: 'manual',
                            message: errors1[0],
                        });
                    } else {
                        prepareCesSurvey();
                    }
                }
            )
        ),
        control,
        watch,
        reset,
        trigger,
        setError,
        setValue,
    };
}

function prepareCesSurvey() {
    const isSubscriptionInvoice =
        sessionStorage.getItem('invoicing_ces_subscription_state') === 'true';

    const key = 'invoicing_ces_scenario';
    if (isSubscriptionInvoice) {
        sessionStorage.setItem(key, 'create_subscription_invoice_manual_ces');
    } else {
        sessionStorage.setItem(key, 'create_invoice_ces');
    }

    sessionStorage.removeItem('invoicing_ces_subscription_state');
}

function formatAmount(value: string) {
    return format.amount2(format.unFormat(value));
}

function validateTermOfPayment(
    invoiceDate: Date,
    orderOrProject: OrderOrProject,
    sendType: string
) {
    if (sendType === SendTypes.EFAKTURA || sendType === SendTypes.AVTALEGIRO) {
        const invoiceDateWithoutTime = new Date(invoiceDate.toDateString());
        const daysUntilDueDate =
            calculateDueDaysFromToday(
                invoiceDateWithoutTime,
                orderOrProject.invoicesDueIn,
                orderOrProject.invoicesDueInType
            ) ?? 0;

        if (daysUntilDueDate < 4) {
            return getMessage(
                'validation_invoice_efaktura_avtalegiro_require_min_days'
            );
        }
    }

    return true;
}
