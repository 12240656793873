import * as React from 'react';

import { RouteData, RouteProps } from '@General/Router';
import { Team } from '@General/Team';
import { EntitlementLookup } from '@General/constants';

import { PageLoader } from '@Component/PageLoader';

const component: React.FC<RouteProps> = (props) => (
    <PageLoader<RouteProps>
        adjustWrapperDiv
        loader={async () => import('./ReportEditor.module').then((m) => m.page)}
        props={props}
    />
);

export const route: RouteData = {
    component,
    path: '/execute/report/edit',
    // Page title is handled by the page itself
    title: () => '',
    team: Team.DATA_STREAM,
    documentationComponent: 311, // REPORT_EDITOR TODO convert to enum
    auth: (context) =>
        context.entitlementMap[EntitlementLookup.REPORT_AUTHOR] !== undefined,
};
