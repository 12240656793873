import {
    ModalContent,
    ModalSubtitle,
    SkeletonRow,
    SkeletonText,
} from '@tlx/atlas';
import React from 'react';
import { SignerTable } from '@Page/CompanyVerificationFlow/SendSigningRequestForm/SignerCombination/SignerList/SignerTable';

export function SendSigningRequestFormSkeleton() {
    return (
        <>
            <ModalSubtitle>
                {getMessage('text_kyc_need_emails_to_sign')}
            </ModalSubtitle>
            <ModalContent>
                <SignerTable hideEmailLabel={false} hideThisIsMeLabel={false}>
                    <SkeletonRow delayed className={'atl-tr'}>
                        <td className={'atl-td'}>
                            <SkeletonText />
                        </td>
                        <td className={'atl-td'}>
                            <SkeletonText />
                        </td>
                        <td className={'atl-td'}>
                            <SkeletonText placeholder={'email@email.com'} />
                        </td>
                        <td className={'atl-td atl-pr-24 atl-text-right'}>
                            <SkeletonText />
                        </td>
                    </SkeletonRow>
                </SignerTable>
            </ModalContent>
        </>
    );
}
