import { Alert, AlertContent, AlertDescription, AlertHeader } from '@tlx/atlas';

export function ChangeProductGroupsWarning({
    totalLines,
}: {
    totalLines: number;
}) {
    return (
        <Alert variant="warning" style={{ maxWidth: '500px' }}>
            <AlertContent>
                <AlertHeader as={'h3'}>
                    {getMessage(
                        'text_purchase_order_change_status_warning_title'
                    )}
                </AlertHeader>
                <AlertDescription>
                    {getMessage(
                        'text_change_product_groups_warning',
                        totalLines
                    )}
                </AlertDescription>
            </AlertContent>
        </Alert>
    );
}
