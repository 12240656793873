import classNames from 'classnames';
import { FormEvent, ReactNode, useRef } from 'react';

export function ChangePriceForm({
    children,
    className,
    onChange,
    onSubmit,
}: {
    children: ReactNode;
    className?: string;
    onChange?: (e: HTMLInputElement | HTMLSelectElement) => void;
    onSubmit?: (
        formElement: HTMLFormElement,
        values: { [k: string]: FormDataEntryValue }
    ) => void;
}) {
    const formRef = useRef<HTMLFormElement | null>(null);

    const handleFormSubmit = (e: FormEvent) => {
        e.preventDefault();
        e.stopPropagation();
        const formElement = formRef.current;
        if (formElement && formElement.checkValidity()) {
            const inputElements = formElement.querySelectorAll('input');
            const values: { [key: string]: string } = {};
            inputElements.forEach((inputElement) => {
                if (inputElement.name) {
                    values[inputElement.name] = inputElement.value;
                }
            });
            onSubmit?.(formElement, values);
        } else {
            formElement?.reportValidity(); // Trigger browser's default form validation messages
        }
    };

    return (
        <form
            id="change-price-form"
            autoComplete="off"
            className={classNames(
                'atl-flex atl-gap-24 atl-flex-col',
                className
            )}
            ref={formRef}
            onSubmit={handleFormSubmit}
            onChange={(e) => {
                const target = e.target as HTMLInputElement | HTMLSelectElement;
                onChange?.(target);
            }}
        >
            {children}
        </form>
    );
}
