import { InventoryCorrectionDTO } from '@Page/LogisticsV2/pages/InventoryCorrection/overview/types';
import { PageUrl } from '@Page/LogisticsV2/utils/enums';
import {
    getFromWarehousesDisplayNames,
    getToWarehousesDisplayNames,
} from '@Page/LogisticsV2/pages/InventoryCorrection/overview/utils';
import { MultipleValuesWithPopup } from '@Page/LogisticsV2/components/Popup/MultipleValuesPopup';
import { useState } from 'react';
import { nav } from '../../../../../../../../js/modules/navigation';

export function InventoryCorrectionTableRow({
    columns,
    inventoryCorrection,
}: {
    columns: string[];
    inventoryCorrection: InventoryCorrectionDTO;
}) {
    return (
        <tr
            className="atl-tr atl-tr--highlight"
            style={{ cursor: 'pointer' }}
            onClick={(e) =>
                nav.popup(
                    addContextId(
                        `${PageUrl.INVENTORY_MENU}?inventoryId=${inventoryCorrection.id}`
                    ),
                    e.metaKey ? '_blank' : '_self'
                )
            }
        >
            <td key="numberRow" className="atl-td">
                {inventoryCorrection.number}
            </td>
            {columns.map((column) => (
                <InventoryCorrectionTableRowCell
                    key={column}
                    column={column}
                    inventoryCorrection={inventoryCorrection}
                />
            ))}
        </tr>
    );
}

function InventoryCorrectionTableRowCell({
    column,
    inventoryCorrection,
}: {
    column: string;
    inventoryCorrection: InventoryCorrectionDTO;
}): JSX.Element {
    switch (column) {
        case 'date':
            return <DateRowCell date={inventoryCorrection.date} />;
        case 'fromWarehouse':
            return (
                <FromWarehouseRowCell
                    inventoryCorrection={inventoryCorrection}
                />
            );
        case 'toWarehouse':
            return (
                <ToWarehouseRowCell inventoryCorrection={inventoryCorrection} />
            );
        case 'comment':
            return <CommentRowCell comment={inventoryCorrection.comment} />;
        default:
            throw new Error(`Unknown setting: ${column}`);
    }
}

function DateRowCell({ date }: { date: string }) {
    return (
        <td key="dateRow" className="atl-td atl-whitespace-nowrap">
            {date}
        </td>
    );
}

function FromWarehouseRowCell({
    inventoryCorrection,
}: {
    inventoryCorrection: InventoryCorrectionDTO;
}) {
    const [showMultipleFromWarehouses, setShowMultipleFromWarehouses] =
        useState(false);
    const multipleFromWarehouses: string[] = isLocationEnabled
        ? getFromWarehousesDisplayNames(inventoryCorrection.stockChanges)
        : [];

    return (
        <td key="fromWarehouseRow" className="atl-td">
            {multipleFromWarehouses.length === 0 && (
                <>{inventoryCorrection.fromWarehouse?.displayName || '-'}</>
            )}
            {multipleFromWarehouses.length === 1 && (
                <div>{multipleFromWarehouses[0]}</div>
            )}
            {multipleFromWarehouses.length > 1 && (
                <MultipleValuesWithPopup
                    open={showMultipleFromWarehouses}
                    setOpen={setShowMultipleFromWarehouses}
                    text={getMessage(
                        'text_warehouses_number',
                        multipleFromWarehouses.length
                    )}
                >
                    {multipleFromWarehouses.map((fromWarehouse) => (
                        <div key={fromWarehouse}>{fromWarehouse}</div>
                    ))}
                </MultipleValuesWithPopup>
            )}
        </td>
    );
}

function ToWarehouseRowCell({
    inventoryCorrection,
}: {
    inventoryCorrection: InventoryCorrectionDTO;
}) {
    const [showMultipleToWarehouses, setShowMultipleToWarehouses] =
        useState(false);
    const multipleToWarehouses: string[] = isLocationEnabled
        ? getToWarehousesDisplayNames(inventoryCorrection.stockChanges)
        : [];

    return (
        <td key="toWarehouseRow" className="atl-td">
            {multipleToWarehouses.length === 0 && (
                <>{inventoryCorrection.warehouse?.displayName || '-'}</>
            )}
            {multipleToWarehouses.length === 1 && (
                <div>{multipleToWarehouses[0]}</div>
            )}
            {multipleToWarehouses.length > 1 && (
                <MultipleValuesWithPopup
                    open={showMultipleToWarehouses}
                    setOpen={setShowMultipleToWarehouses}
                    text={getMessage(
                        'text_warehouses_number',
                        multipleToWarehouses.length
                    )}
                >
                    {multipleToWarehouses.map((toWarehouse) => (
                        <div key={toWarehouse}>{toWarehouse}</div>
                    ))}
                </MultipleValuesWithPopup>
            )}
        </td>
    );
}

function CommentRowCell({ comment }: { comment: string }) {
    return (
        <td key="commentRow" className="atl-td">
            {comment || '-'}
        </td>
    );
}
