import { dateUtil } from '../../../../../js/modules/date';
import { DeliverySteps } from '../models/DeliverySteps';
import { AccountDTO } from '../models/PreValidateCreateDTO';
import {
    Sign,
    StructuredComment,
    VatReturnsCodeComment,
} from '../models/StructuredComment';
import { VatReportType, VatSpecificationLine } from '../models/VatReturns';
import { VatReturnsPeriod } from '../models/VatReturnsPeriod';
import { VatReturnsStatus } from '../models/VatReturnsStatus';

export function isEmpty(text?: string) {
    return text === null || text === undefined || text.length === 0;
}

export function isString(value: unknown): value is string {
    return typeof value === 'string';
}

export function formatVatAmount(
    amount: number | undefined,
    absoluteValue: boolean,
    useGrouping: boolean
) {
    if (amount != undefined && Number.isFinite(amount)) {
        const v = absoluteValue ? Math.abs(amount) : amount;
        return Intl.NumberFormat(getLocale(), {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
            useGrouping,
        }).format(v);
    }

    return '';
}

// User locale is save in format 'no_NO' with underscore, Intl want in format 'no-nb' with hypen. Fallback to be used in storeybook.
export function getLocale() {
    return normalizeLanguage(window.locale);
}

function normalizeLanguage(language: string): string {
    if (isString(language) && language.toLocaleLowerCase().includes('en')) {
        return 'en-GB';
    }

    return 'nb-NO';
}

export function isDeliveryStepIn(
    deliveryStep: DeliverySteps,
    ...required: DeliverySteps[]
) {
    return required.includes(deliveryStep);
}

export function isStatusIn(
    status: VatReturnsStatus | undefined,
    ...required: VatReturnsStatus[]
) {
    return status !== undefined && required.includes(status);
}

export function getVatPeriodLedgerLink(
    period: VatReturnsPeriod | undefined,
    account: AccountDTO | null,
    vatTypeId?: number
) {
    if (period === undefined || (account === null && vatTypeId === undefined)) {
        return undefined;
    }

    const startDate = period.startDate;
    const endDate = period.endDate;

    const urlParams = new URLSearchParams();
    urlParams.set('contextId', window.contextId + '');
    urlParams.set('period.startDate', startDate);
    urlParams.set('period.endDate', endDate);
    urlParams.set('period.periodType', '2');

    if (account !== null) {
        urlParams.set('accountId', account.id + '');
    }

    if (vatTypeId !== undefined) {
        urlParams.set('selectedVatId', vatTypeId + '');
        urlParams.set('viewMode', '27');
        urlParams.set('selectedVatReturnsId', '0');
    }

    return '/execute/ledger?' + urlParams;
}

export function getIncompleteVoucherLink(
    vatTerm: VatReturnsPeriod | undefined
) {
    if (vatTerm === undefined) {
        return undefined;
    }

    const startDate = vatTerm.startDate;
    const endDate = vatTerm.endDate;

    const urlParams = new URLSearchParams();
    urlParams.set('contextId', window.contextId + '');
    urlParams.set('period.startDate', startDate);
    urlParams.set('period.endDate', endDate);
    urlParams.set('period.periodType', '2');
    urlParams.set('onlyOpenPostings', 'false');
    urlParams.set('isExpandedFilter', 'false');
    urlParams.set('mode', '3');

    return '/execute/incompleteVouchers?' + urlParams;
}

export function getBankHarmonizationLink(
    period: VatReturnsPeriod | undefined,
    account: AccountDTO | null
) {
    if (period === undefined || account === null) {
        return undefined;
    }

    const endDate = dateUtil.addDays(new Date(period.endDate), -1);
    const startDate = dateUtil.getFirstDayOfMonth(endDate);

    const urlParams = new URLSearchParams();
    urlParams.set('contextId', window.contextId + '');
    urlParams.set('startDate', dateUtil.formatDate(startDate));
    urlParams.set('endDate', dateUtil.formatDate(endDate));
    urlParams.set('accountId', account.id + '');

    return '/execute/bankReconciliationAction?' + urlParams;
}

/**
 * Accepts a list of async function.
 * Return value is from first function returning a value !== undefined.
 */
export async function getFirstDefinedAsync<T>(
    ...getters: (() => Promise<T | undefined>)[]
): Promise<T | undefined> {
    for (const getter of getters) {
        const result = await getter();
        if (result !== undefined) {
            return result;
        }
    }

    return undefined;
}

export function getOperatorFromNumber(number: number): Sign {
    return number < 0 ? 'NEGATIVE' : 'POSITIVE';
}

export function getStructuredCommentsFromVatCode(
    vatReturnsCodeComments: VatReturnsCodeComment[],
    vatSpecificationLine: VatSpecificationLine
) {
    const code = vatReturnsCodeComments.find(
        (it) => parseInt(it.vatCode) === vatSpecificationLine.standardCode
    );

    const comments = code?.comments ?? [];

    return comments.filter((comment) =>
        isCommentForLine(comment, vatSpecificationLine)
    );
}

function isCommentForLine(
    comment: StructuredComment,
    line: VatSpecificationLine
) {
    return (
        comment.vatSpecificationType === line.specificationType &&
        comment.expectedSign === line.expectedSign &&
        comment.deliveredSign === getOperatorFromNumber(line.vatAmount)
    );
}

export function getComment(
    specification: VatSpecificationLine,
    structuredComments: StructuredComment[]
) {
    if (!isEmpty(specification.userComment)) {
        return specification.userComment;
    }

    const structuredComment = structuredComments.find(
        (c) => c.technicalName === specification.structuredComment
    );

    if (!isEmpty(structuredComment?.title)) {
        return structuredComment?.title;
    }

    return undefined;
}

export function isVatReportType(value: unknown): value is VatReportType {
    return (
        isString(value) &&
        (value === 'PRIMARY_INDUSTRY' || value === 'GENERAL_INDUSTRY')
    );
}
