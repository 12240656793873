import React, { useEffect, useState } from 'react';
import { Modal } from '@tlx/atlas';
import {
    InvoiceSettings,
    PaymentType,
} from '@Page/InvoicingDialog/types/InvoicingDialog.type';
import { ConnectedInvoicingForm } from '@Page/InvoicingDialog/component/InvoicingForm/ConnectedInvoicingForm';
import { BatchInvoicing } from '@Page/InvoicingDialog/component/BatchInvoicing/BatchInvoicing';
import {
    doBatchInvoice,
    useFetchCompany,
    useFetchOrders,
} from '@Page/InvoicingDialog/component/InvoicingModalDataFetching';
import { LoadingSpinner } from '@Component/Loading';
import { createAPIRequest } from '../../../hooks/fetch/createAPIRequest';
import { ConnectedSetup } from '@Page/InvoicingDialog/component/SetupForm/ConnectedSetup';

// Right now we don't have a reactified "order overview" or "new order/invoice" page.
// Therefore, we use this method where we've set the order ids as an attribute on the button before the dialog is opened -
// and then read and set them. In the future the order id's should come from the reactified pages that uses them.
const setOrderIdsAndOpenDialog = (
    event: React.MouseEvent<HTMLButtonElement>,
    setOrderIds: React.Dispatch<number[]>,
    setIsOpen: React.Dispatch<boolean>
) => {
    const eventTarget = event.target as Element;

    const orderIdsString = eventTarget.getAttribute('orderIds');
    const orderIdsStringArray =
        orderIdsString && orderIdsString !== ''
            ? orderIdsString.split(',')
            : [];
    const orderIds = orderIdsStringArray.map((orderId) =>
        Number.parseInt(orderId)
    );

    if (!orderIdsString || orderIdsString === '') {
        return;
    }

    setOrderIds(orderIds);
    setIsOpen(true);
};

export interface InvoicingModalProps {
    forwardURL: string | undefined;
    isOpenByDefault: boolean;
    initialOrderIds: number[];
}

export function InvoicingModal({
    forwardURL,
    isOpenByDefault,
    initialOrderIds,
}: InvoicingModalProps) {
    const [isOpen, setIsOpen] = useState(isOpenByDefault);
    const [orderIds, setOrderIds] = useState<number[]>(initialOrderIds);
    // To allow invoice number to be 1
    const [haveChosenInvoiceNumberNow, setHaveChosenInvoiceNumberNow] =
        useState(false);

    const { data: company, refresh: refreshCompany } =
        useFetchCompany(contextId);
    const { data: invoiceSettings, refresh: refreshInvoiceSettings } =
        useFetchInvoiceSettings();

    function refresh() {
        void refreshCompany(contextId);
        void refreshInvoiceSettings();
        setHaveChosenInvoiceNumberNow(true);
    }

    const isLoading = invoiceSettings == undefined || company == undefined;

    const isReadyToInvoice =
        !isLoading &&
        invoiceSettings.bankAccountReady &&
        (invoiceSettings.hasFirstInvoiceNumber || haveChosenInvoiceNumberNow) &&
        company?.organizationNumber;

    function getContent() {
        if (isLoading) {
            return (
                <div className="kb-invoicing-modal__single-invoicing atl-flex atl-justify-center atl-items-center">
                    <LoadingSpinner />
                </div>
            );
        }
        if (!isReadyToInvoice) {
            return (
                <ConnectedSetup
                    closeModal={() => setIsOpen(false)}
                    invoiceSettings={invoiceSettings}
                    company={company}
                    refresh={refresh}
                />
            );
        }
        return (
            <InvoicingDialogContent
                orderIds={orderIds}
                invoiceSettings={invoiceSettings}
                closeModal={() => setIsOpen(false)}
                forwardURL={forwardURL}
            />
        );
    }

    return (
        <>
            <button
                id="open-invoicing-dialog-trigger"
                data-testid={'open-invoicing-dialog-trigger'}
                onClick={
                    (e) => setOrderIdsAndOpenDialog(e, setOrderIds, setIsOpen) // We do a 'hack' here to set the order ids. See method.
                }
                className="atl-hidden"
            >
                Invoicing
            </button>
            {isOpen && (
                <Modal
                    className="atl-p-0"
                    open={isOpen}
                    onClose={() => {
                        setIsOpen(false);
                        const dialogTriggerButton = $(
                            '#open-invoicing-dialog-trigger'
                        );
                        dialogTriggerButton.attr('orderids', '');
                    }}
                >
                    <div>{getContent()}</div>
                </Modal>
            )}
        </>
    );
}

type InvoicingDialogContentProps = {
    orderIds: number[];
    invoiceSettings: InvoiceSettings;
    closeModal: () => void;
    forwardURL: string | undefined;
};

const InvoicingDialogContent = ({
    orderIds,
    invoiceSettings,
    closeModal,
    forwardURL,
}: InvoicingDialogContentProps) => {
    const paymentTypes = useFetchPaymentTypes();
    const { data: company } = useFetchCompany(contextId);

    const { orders, isLoadingOrders } = useFetchOrders(orderIds);

    if (paymentTypes == undefined) {
        return null;
    }

    let invoicingView;
    if (orderIds.length === 1) {
        if (isLoadingOrders) {
            // Loading could be moved to ConnectedInvoicingForm, -
            // but we agreed to keep it simple for now is loading is almost instant
            invoicingView = (
                <div className="kb-invoicing-modal__loading-single-invoicing">
                    <LoadingSpinner />
                </div>
            );
        } else {
            invoicingView = (
                <ConnectedInvoicingForm
                    className="kb-invoicing-modal__single-invoicing"
                    invoiceSettings={invoiceSettings}
                    paymentTypes={paymentTypes}
                    closeModal={closeModal}
                    order={orders[0]}
                    forwardURL={forwardURL}
                />
            );
        }
    } else if (orderIds.length > 1) {
        invoicingView = (
            <BatchInvoicing
                isLoading={isLoadingOrders}
                ordersLoadingCount={orderIds.length}
                className="kb-invoicing-modal__batch-invoicing"
                company={company}
                invoiceSettings={invoiceSettings}
                orders={orders}
                onSubmit={doBatchInvoice}
            />
        );
    }

    return <>{orders && <div>{invoicingView}</div>}</>;
};

function useFetchInvoiceSettings() {
    const [data, setData] = useState<InvoiceSettings | undefined>();
    const [error, setError] = useState();
    const [isLoading, setIsLoading] = useState(false);

    async function refresh() {
        setError(undefined);
        setIsLoading(true);

        try {
            const response = await window.fetch(
                createAPIRequest('/v2/invoice/settings?fields=*')
            );
            const data = await response.json();
            setData(data.value);
        } catch (error) {
            setError(error);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        void refresh();
    }, []);

    return { data, error, isLoading, refresh };
}

function useFetchPaymentTypes() {
    const [data, setData] = useState<{ values: PaymentType[] | undefined }>();

    useEffect(() => {
        window
            .fetch(
                createAPIRequest(
                    '/v2/invoice/paymentType/query?showOrderInvoice=true&fields=id, description, debitAccount(currency(code))&sorting=sequence'
                )
            )
            .then((response) => response.json())
            .then((data) => setData(data));
    }, []);

    return data?.values;
}
