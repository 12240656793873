import * as React from 'react';

import { renderComponent } from '@General/renderComponent';
import { PageLoader } from '@Component/PageLoader';

const loader: React.FC = () => (
    <PageLoader<{}>
        loader={async () => import('./SupplierProducts').then((m) => m.page)}
        adjustWrapperDiv
    />
);

export function renderSupplierProducts(domElementId: string) {
    renderComponent(loader, domElementId);
}
