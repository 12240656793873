import * as React from 'react';

import { RouteData } from '@General/Router';
import { PageLoader } from '@Component/PageLoader';
import { Team } from '@General/Team';
import { PageUrl } from '@Page/LogisticsV2/utils/enums';

const Page: React.FC = () => (
    <PageLoader<{}>
        loader={async () => import('./GoodsReceipt').then((m) => m.page)}
        adjustWrapperDiv
    />
);

export const route: RouteData = {
    component: Page,
    path: PageUrl.GOODS_RECEIPT,
    title: () => {
        const url = new URL(window.location.href);
        const isNew = !url.searchParams.get('orderOutId');
        return `${getMessage(
            isNew ? 'text_new_goods_receipt' : 'text_goods_receipt'
        )}`;
    },
    documentationComponent: 341,
    team: Team.GOLDSHARK,
    // TODO Add module test when active module data are available in API
    auth: () => moduleLogistics && hasProductAuthAdminRole,
};
