import './SwiftOrBankCode.css';
import React from 'react';
import { Input } from '@tlx/atlas';
import { CreditorBankIdentificator } from '@Page/PaymentWidget/types/paymentWidgetTypes';

interface SwiftOrBankCodeProps {
    bankIdentification: CreditorBankIdentificator;
    setBankIdentification: (direction: CreditorBankIdentificator) => void;
    atlasDesignSystem: boolean;
}

export const SwiftOrBankCode: React.FC<SwiftOrBankCodeProps> = ({
    bankIdentification,
    setBankIdentification,
    atlasDesignSystem,
}) => {
    return (
        <>
            <div
                className={`swift-bank-code-checkbox__label--${
                    atlasDesignSystem ? 'atlas' : 'classic'
                }`}
            >
                {getMessage('text_autopay_domestic_or_foreign_field')}
            </div>

            <div className="atl-mt-16 atl-mb-24 atl-flex atl-gap-24">
                <label className="atl-flex atl-items-center atl-gap-8">
                    <Input
                        data-testid="swift"
                        id="swift"
                        type="radio"
                        name="paymentWidgetWrapper.autoPayTransaction.creditorBankIdentificator"
                        value={
                            CreditorBankIdentificator.CREDITOR_BANK_IDENTIFICATOR_SWIFT
                        }
                        checked={
                            bankIdentification ===
                            CreditorBankIdentificator.CREDITOR_BANK_IDENTIFICATOR_SWIFT
                        }
                        onChange={() =>
                            setBankIdentification(
                                CreditorBankIdentificator.CREDITOR_BANK_IDENTIFICATOR_SWIFT
                            )
                        }
                    />
                    <span>{getMessage('text_autopay_BIC_short')}</span>
                </label>

                <label className="atl-flex atl-items-center atl-gap-8">
                    <Input
                        data-testid="bankCode"
                        id="bankCode"
                        type="radio"
                        name="paymentWidgetWrapper.autoPayTransaction.creditorBankIdentificator"
                        value={
                            CreditorBankIdentificator.CREDITOR_BANK_IDENTIFICATOR_CLEARING_CODE
                        }
                        checked={
                            bankIdentification ===
                            CreditorBankIdentificator.CREDITOR_BANK_IDENTIFICATOR_CLEARING_CODE
                        }
                        onChange={() =>
                            setBankIdentification(
                                CreditorBankIdentificator.CREDITOR_BANK_IDENTIFICATOR_CLEARING_CODE
                            )
                        }
                    />
                    <span>{getMessage('text_autopay_clearing_code')}</span>
                </label>
            </div>
        </>
    );
};
