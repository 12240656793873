import $ from 'jquery';
import { addContextId, tlxUrl } from './url';
import { tlxAlertWithTrackingId } from './alert';
import { nav } from './navigation';
import { tlxForms } from './forms';

import { isChanged } from './change';

window.creditScoring = (function () {
    function fetchCreditScoring(
        scope,
        action,
        saveBeforeCreditCheckAlertOKButtonTrackingId = ''
    ) {
        if (isChanged()) {
            tlxAlertWithTrackingId(
                getMessage('text_credit_scoring_save_before_action'),
                undefined,
                saveBeforeCreditCheckAlertOKButtonTrackingId
            );
            return;
        }
        action();
    }

    function downloadCreditScoringReport(
        scope,
        reportNotReadyAlertOKButtonTrackingId = ''
    ) {
        const creditScoringId = $('.js-credit-scoring-id', scope).val();
        const creditScoring =
            window.jsonrpc.CreditScoring.fetchCreditScoringReportById(
                creditScoringId
            );
        if (creditScoring != null && creditScoring.reportId != 0) {
            const url = getCreditScoringReportUrl(
                scope,
                'downloadCreditScoringReport',
                creditScoringId
            );
            nav.download(url);
        } else {
            tlxAlertWithTrackingId(
                getMessage('text_credit_scoring_report_not_ready'),
                undefined,
                reportNotReadyAlertOKButtonTrackingId
            );
        }
    }

    function getCreditScoringPopupOptions(
        action,
        scope,
        confirmButtonTrackingId = '',
        cancelButtonTrackingId = '',
        saveBeforeCreditCheckAlertOKButtonTrackingId = ''
    ) {
        return {
            dialogContent: $('.js-credit-scoring-popup-cost', scope),
            buttonText: getMessage('text_credit_scoring_do'),
            buttonTrackingId: confirmButtonTrackingId,
            closeTrackingId: cancelButtonTrackingId,
            title: getMessage('text_credit_scoring'),
            click: function () {
                $('.js-credit-scoring-popup-cost', scope).dialog('close');
                fetchCreditScoring(
                    scope,
                    action,
                    saveBeforeCreditCheckAlertOKButtonTrackingId
                );
            },
        };
    }

    function getCreditScoringReportUrl(scope, act, creditScoringId) {
        let url = addContextId('/execute/creditScoring?act=' + act);
        url = tlxUrl.addUrlParameter(url, 'creditScoringId', creditScoringId);
        return url;
    }

    function updateCreditScoring(scope, customerOrSupplierId) {
        window.jsonrpc.CreditScoring.fetchCreditScoringReportByCustomerOrSupplierIdNonNull(
            function (creditScoring) {
                const isPrivateCustomer = creditScoring.isPrivateCustomer;
                const isNoHistory = creditScoring.noHistory;
                const newRating = creditScoring.creditRatingGui;
                const hasAccessToCreditScore =
                    creditScoring.hasAccessToCreditScore;

                const creditScoringElement = $('.hideIfPrivateCustomer', scope);
                const creditRatingElement = $('.credit-rating', scope);
                const oldRating = creditRatingElement[0].innerText;
                const creditScoreElement = $(':prop("creditScoreGui")', scope);
                const riskDescriptionElement = $(':prop("risk")', scope);
                const paymentRemarksTotalElement = $(
                    ':prop("paymentRemarksTotal")',
                    scope
                );
                const dateElement = $('.js-credit-scoring-date', scope);
                const avoidLossElement = $('.js-credit-scoring-loss', scope);
                const creditScoreButton = $('.js-btn-credit-scoring', scope);
                const reportDownloadLinkElement = $(
                    '.js-credit-scoring-report-download',
                    scope
                );
                const creditScoringId = $('.js-credit-scoring-id', scope);

                creditRatingElement.removeClass(
                    getCreditRatingStyleClass(oldRating)
                );
                creditRatingElement.addClass(
                    getCreditRatingStyleClass(newRating)
                );
                creditRatingElement[0].innerText = newRating;
                tlxForms.change(creditScoringId, creditScoring.id);
                tlxForms.change(
                    creditScoreElement,
                    creditScoring.creditScoreGui
                );
                tlxForms.change(riskDescriptionElement, creditScoring.risk);
                tlxForms.change(
                    paymentRemarksTotalElement,
                    creditScoring.paymentRemarksTotal
                );
                dateElement.text(creditScoring.dateGui);
                avoidLossElement.toggle(isNoHistory);
                reportDownloadLinkElement.toggle(!isNoHistory);
                creditScoreButton.toggle(hasAccessToCreditScore);
                creditScoringElement.toggle(!isPrivateCustomer);
            },
            customerOrSupplierId
        );
    }

    function getCreditRatingStyleClass(creditRating) {
        switch (creditRating) {
            case 'A':
                return 'credit-rating--very-low-risk';
            case 'B':
                return 'credit-rating--low-risk';
            case 'C':
                return 'credit-rating--moderate-risk';
            case 'D':
                return 'credit-rating--high-risk';
            default:
                return 'credit-rating--not-rated';
        }
    }

    return {
        downloadCreditScoringReport: downloadCreditScoringReport,
        getCreditScoringPopupOptions: getCreditScoringPopupOptions,
        updateCreditScoring: updateCreditScoring,
    };
})();
