import jQuery from 'jquery';
const $ = jQuery;

/**
 * jQuery UI disallow elements outside modal dialogs to have focus be default.
 * We need some elements to be outside the dialog since they pop up and thus can't be constrained to be inside the dialog.
 * (not usable if you had to scroll inside the dialog to see the tlxselect results)
 *
 * OBS: Not doing this will prevent elements inside the tlxSelect dialog to be
 * focusable. I have no clue why.
 */
$.widget('ui.dialog', $.ui.dialog, {
    _allowInteraction: function (event) {
        return (
            !!$(event.target).closest(
                '.tlx-validation-popup, .txr-dropdown__search-container, .txr-page-dialog'
            ).length || this._super(event)
        );
    },
});

/**
 * Disable the create event for certain widgets. It's not used at all, and add significant cost for pages
 * with many tlxselects/buttons and deep/complicated DOM. (eg. voucher2.jsp - saves ~800ms on IE9 beefy machine..)
 */
(function () {
    const orgTrigger = $.Widget.prototype._trigger;
    $.Widget.prototype._trigger = function (type, event, data) {
        if (
            type === 'create' &&
            (this.widgetName === 'inputOpener' || this.widgetName === 'button')
        ) {
            return;
        }
        return orgTrigger.call(this, type, event, data);
    };
})();
