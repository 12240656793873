import { useProductSettings } from './useProductSettings';
import { useEffect } from 'react';
import {
    ProductSettingsFunctionality,
    ProductSettingsPreferences,
} from '@Page/LogisticsV2/pages/Settings/ProductSettings/ProductSettingsComponents';

export function ProductSettings() {
    const productSettings = useProductSettings();
    useEffect(() => {
        function handleSave() {
            productSettings.onSave().then();
        }

        window.addEventListener('productSettingsSave', handleSave);
        return () => {
            window.removeEventListener('productSettingsSave', handleSave);
        };
    });

    return (
        <form
            className="atl-border-grey-20 atl-border atl-rounded atl-bg-white atl-p-16"
            autoComplete="off"
            method="post"
        >
            <div className="atl-font-medium atl-text-lg">
                {getMessage('heading_features')}
            </div>
            <ProductSettingsFunctionality productSettings={productSettings} />
            <div className="atl-font-medium atl-text-lg atl-my-24">
                {getMessage('heading_preferences')}
            </div>
            <ProductSettingsPreferences productSettings={productSettings} />
        </form>
    );
}
