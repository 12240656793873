import { SendTypes } from '@Page/ProjectInvoicingDialog/component/InvoicingForm/SendMethod';

export function getSendMethodName(enumValue: string, locale?: string) {
    if (enumValue === SendTypes.AVTALEGIRO) {
        return locale
            ? getLocaleMessage(locale, 'text_invoice_option_avtalegiro')
            : getMessage('text_invoice_option_avtalegiro');
    } else if (enumValue === SendTypes.EFAKTURA) {
        return locale
            ? getLocaleMessage(locale, 'text_invoice_option_efaktura')
            : getMessage('text_invoice_option_efaktura');
    } else if (enumValue === SendTypes.EHF) {
        return locale
            ? getLocaleMessage(locale, 'text_electronic_invoice_short')
            : getMessage('text_electronic_invoice_short');
    } else if (enumValue === SendTypes.EMAIL) {
        return locale
            ? getLocaleMessage(locale, 'text_email_short')
            : getMessage('text_email_short');
    } else if (enumValue === SendTypes.PAPER) {
        return locale
            ? getLocaleMessage(locale, 'text_invoice_option_paper')
            : getMessage('text_invoice_option_paper');
    } else if (enumValue === SendTypes.MANUAL) {
        return locale
            ? getLocaleMessage(locale, 'text_send_type_later')
            : getMessage('text_send_type_later');
    } else if (enumValue === SendTypes.VIPPS) {
        return locale
            ? getLocaleMessage(locale, 'text_invoice_option_efaktura')
            : getMessage('text_invoice_option_efaktura');
    }

    return '';
}
