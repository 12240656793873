import jQuery from 'jquery';
const $ = jQuery;
import { encodeHTML } from '../../modules/encodeHTML';

/**
 *
 * Create self expanding textareas
 *
 **/
$(document).on('keyup', 'textarea.tlx-textfield__input', function () {
    const $this = $(this);
    if (!this.$spanHelper) {
        this.$spanHelper = $(
            '<span class="textarea-helper" aria-hidden="true"/>'
        )
            .css({
                fontSize: $this.css('font-size'),
                fontFamily: $this.css('font-family'),
                lineHeight: $this.css('line-height'),
                fontWeight: $this.css('font-weight'),
            })
            .insertBefore(this);
    }
    this.$spanHelper
        .html(encodeHTML($this.val()) + '&nbsp;')
        .css('max-width', $this.width());
    const height = this.$spanHelper.height();
    $this.height(height);
});
