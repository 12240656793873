import {
    Button,
    Input,
    TableLayout,
    TableLayoutContent,
    TableLayoutTopbar,
    Tooltip,
    TooltipContent,
    TooltipTrigger,
} from '@tlx/atlas';
import { StocktakingDetailsTableHeader } from '@Page/LogisticsV2/pages/Stocktaking/details2/table/StocktakingDetailsTableHeaderComponents';
import { useTableColumnsSetting } from '@tlx/astro-shared';
import { getStocktakingDetailsDisplayOptionsName } from '@Page/LogisticsV2/pages/Stocktaking/details2/StocktakingDetailsDisplayOptions';
import { getDefaultColumnsInStocktakingDetails } from '@Page/LogisticsV2/pages/Stocktaking/details2/utils';
import { EmptyState } from '@Page/LogisticsV2/components/EmptyState/EmptyState';
import React, { useRef, useState } from 'react';
import { ProductLineDTO } from '@Page/LogisticsV2/pages/Stocktaking/utils';
import { doScanLine } from '@Page/LogisticsV2/pages/Stocktaking/details2/StocktakingDetailsActions';
import { AutoSaveStatusType } from '@Page/LogisticsV2/utils/enums';
import { getValidationMessagesFromResponse } from '@Page/LogisticsV2/utils/functions';
import { DEFAULT_COLUMNS_STOCKTAKING_DETAILS } from '@Page/LogisticsV2/pages/Stocktaking/details2/types';
import { StocktakingDetailsTableRow } from '@Page/LogisticsV2/pages/Stocktaking/details2/table/StocktakingDetailsTableRowComponents';
import { useStocktakingDetails } from '@Page/LogisticsV2/pages/Stocktaking/details2/useStocktakingDetails';

export function StocktakingDetailsBarcodeTable({
    onChange,
}: {
    onChange: (status: AutoSaveStatusType) => void;
}) {
    const { mutate } = useStocktakingDetails();
    const enabledColumnsForStocktakingDetails = useTableColumnsSetting(
        getStocktakingDetailsDisplayOptionsName(),
        getDefaultColumnsInStocktakingDetails()
    );
    const [scannedProductLines, setScannedProductLines] = useState<
        ProductLineDTO[]
    >([]);
    const [showTooltip, setShowTooltip] = useState(false);
    const prevValue = useRef('');
    const [scanValue, setScanValue] = useState('');
    const inputRef = useRef<HTMLInputElement>(null);

    async function handleScanProductLine(ean: string) {
        onChange(AutoSaveStatusType.PENDING);
        const { data, error } = await doScanLine(ean);
        if (error) {
            onChange(AutoSaveStatusType.ERROR);
            getValidationMessagesFromResponse(
                error,
                getMessage('validation_cannot_save_count')
            );
        } else if (data) {
            onChange(AutoSaveStatusType.SAVED);
            const responseData = await data.json();
            await mutate();
            setScannedProductLines(responseData.values);
        }
    }

    async function handleOnChange(status: AutoSaveStatusType) {
        onChange(status);
        await handleScanProductLine(scanValue);
    }

    return (
        <TableLayout>
            <TableLayoutTopbar className="atl-m-16 atl-gap-4">
                <Tooltip
                    open={showTooltip}
                    onOpenChange={setShowTooltip}
                    placement={'right'}
                >
                    <TooltipTrigger>
                        <Input
                            type="search"
                            ref={inputRef}
                            data-testid={'scan-barcode-input'}
                            value={scanValue}
                            autoFocus
                            placeholder={getMessage('text_scan')}
                            onChange={(event) => {
                                setShowTooltip(false);
                                if (scanValue && prevValue) {
                                    const currentValue =
                                        event.target.value.substring(
                                            prevValue.current.length,
                                            event.target.value.length
                                        );
                                    prevValue.current = '';
                                    setScanValue(currentValue);
                                } else {
                                    setScanValue(event.target.value);
                                }
                            }}
                            onKeyDown={async (event) => {
                                if (event.key === 'Enter') {
                                    await handleScanProductLine(
                                        event.currentTarget.value
                                    );
                                    prevValue.current =
                                        event.currentTarget.value;
                                }
                            }}
                            style={{ minWidth: '400px' }}
                        />
                    </TooltipTrigger>
                    <TooltipContent>
                        {getMessage('text_click_to_start_scanning')}
                    </TooltipContent>
                </Tooltip>
            </TableLayoutTopbar>
            <TableLayoutContent
                style={{
                    overflowX: 'auto',
                    maxHeight: 'calc(100vh - 450px)',
                }}
            >
                <table className="atl-table">
                    <StocktakingDetailsTableHeader
                        columns={enabledColumnsForStocktakingDetails}
                    />
                    <tbody>
                        {scannedProductLines.map((line) => (
                            <StocktakingDetailsTableRow
                                key={line.id}
                                columns={enabledColumnsForStocktakingDetails}
                                line={line}
                                onChange={handleOnChange}
                            />
                        ))}
                        {scannedProductLines.length === 0 && (
                            <tr>
                                <td
                                    colSpan={
                                        enabledColumnsForStocktakingDetails.length +
                                        DEFAULT_COLUMNS_STOCKTAKING_DETAILS
                                    }
                                >
                                    <EmptyState
                                        title={
                                            scanValue.length === 0
                                                ? 'Barcode scanner'
                                                : getMessage(
                                                      'text_no_products_found'
                                                  )
                                        }
                                        description={getMessage(
                                            'text_barcode_scanner_details'
                                        )}
                                        svgIcon={'barcodeScanner'}
                                    >
                                        <div>
                                            <Button
                                                data-testid={
                                                    'show-me-how-to-scan-button'
                                                }
                                                onClick={() => {
                                                    setShowTooltip(true);
                                                    inputRef.current?.focus();
                                                }}
                                                variant="secondary"
                                            >
                                                {getMessage('text_show_how')}
                                            </Button>
                                        </div>
                                    </EmptyState>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </TableLayoutContent>
        </TableLayout>
    );
}
