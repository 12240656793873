import $ from 'jquery';
import { getContextId } from '@tlx/astro-shared';

window.accountCurrentBookUtil = (function () {
    /**
     * Decides if the user should get a warning before closing the posts.
     * @returns {boolean} true if the user should get a warning, false if not.
     */
    function withWarning() {
        const $numberBox = $('.tabzum .numberBox');
        if ($numberBox.length === 0) {
            window.jsonrpc.Sync.remoteLogException(
                'tabzum is not running, so the sum of the chosen posts is not visible.' +
                    'The functionality will be the same as if the user chose to close the posts from within the popup.',
                'accountCurrentBookCustomer.js toolbarActionOptions withWarning',
                null
            );
            return false;
        }
        const absNumber = Math.abs(
            parseFloat(
                $numberBox
                    .text()
                    .replace(',', '.')
                    .split(/[^0-9,.]*/)
                    .join('')
            )
        );
        const tripletexContextId = '3001';
        return (
            getContextId() !== tripletexContextId &&
            absNumber > 0 &&
            absNumber < 10
        );
    }

    return {
        withWarning: withWarning,
    };
})();
