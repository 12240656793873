import { PageError } from '@Component/PageLoader/PageError';
import {
    PurchaseOrderOverviewContainer,
    PurchaseOrderOverviewHeader,
    PurchaseOrderOverviewTableWithFilters,
} from '@Page/LogisticsV2/pages/PurchaseOrder/overview/PurchaseOrderOverview';
import { usePurchaseOrder } from '@Page/LogisticsV2/pages/PurchaseOrder/overview/usePurchaseOrderOverview';
import {
    PurchaseOrderOverviewActionModel,
    PurchaseOrderOverviewBulkActionModel,
    PurchaseOrderOverviewModel,
} from './types';
import { usePurchaseOrderOverviewAction } from './usePurchaseOrderOverviewAction';
import { usePurchaseOrderOverviewBulkAction } from './usePurchaseOrderOverviewBulkAction';
import { getIdFromUrl } from '@Page/LogisticsV2/utils/functions';
import { GetStartedBannerInfo } from '@Page/Beehive/component/Banner';

export function PurchaseOrderOverviewPage() {
    const purchaseOrders: PurchaseOrderOverviewModel = usePurchaseOrder();
    const actions: PurchaseOrderOverviewActionModel =
        usePurchaseOrderOverviewAction(purchaseOrders.onRefresh);
    const bulkActions: PurchaseOrderOverviewBulkActionModel =
        usePurchaseOrderOverviewBulkAction(
            purchaseOrders.onChange,
            purchaseOrders.onRefresh
        );

    if (purchaseOrders.data === undefined) {
        if (purchaseOrders.error) {
            return <PageError />;
        }
        return null;
    }
    const supplierId = document.querySelector<HTMLInputElement>(
        '#customerIdForProductOverview'
    )?.value;
    const projectId = getIdFromUrl('projectId');
    const showHeader = !supplierId && !projectId;
    return (
        <PurchaseOrderOverviewContainer>
            {supplierId === undefined && (
                <div
                    className={
                        'atl-flex atl-justify-between gs-header atl-mb-32'
                    }
                >
                    <div
                        id="menuHeader"
                        data-testid="header-title"
                        className="useAtlasHeader"
                    >
                        <h1 className="clip">
                            {moduleLogistics
                                ? getMessage(
                                      'text_purchase_orders_and_goods_receipt'
                                  )
                                : getMessage('text_orders_out')}
                        </h1>
                    </div>
                    {showHeader && <PurchaseOrderOverviewHeader />}
                </div>
            )}
            <GetStartedBannerInfo
                bannerId="PurchaseOrdersBanner"
                bannerTitle={getMessage(
                    'get_started_banner_purchase_orders_title'
                )}
                bannerDescription={getMessage(
                    'get_started_banner_purchase_orders_description'
                )}
                bannerUrl="https://hjelp.tripletex.no/hc/no/articles/4408517256465-Slik-bruker-du-innkj%C3%B8psoversikten"
                className="atl-mb-24"
            />
            <PurchaseOrderOverviewTableWithFilters
                purchaseOrders={purchaseOrders}
                bulkActions={bulkActions}
                actions={actions}
            />
        </PurchaseOrderOverviewContainer>
    );
}
