import * as React from 'react';

import { RouteData } from '@General/Router';
import { Team } from '@General/Team';

import { PageLoader } from '@Component/PageLoader';
import { EntitlementLookup } from '@General/constants';
import { isCompany } from '@Page/YearEnd/Pages/YearEndSubmission/utils/yearEndSubmissionUtils';

/**
 * The outside part of the bundle shear line.
 * The bundle split happens because of the <code>import('./SomePage')</code> call.
 * The PageLoader both sets up an error boundary (meaning a crash in the React code inside won't crash the page)
 * and handles showing a loader.
 *
 * @see PageLoader
 * @see renderComponent
 */
export const Page: React.FC = (props) => (
    <PageLoader<{}>
        loader={async () =>
            import('./YearEndAnnualAccounts.module').then((m) => m.page)
        }
        props={props}
        adjustWrapperDiv={true}
    />
);

/**
 * Describes URL -> React page mapping
 *
 * Name of this file must end with `.route.ts` or `.route.tsx` for the mapping to be registered automatically.
 */
export const route: RouteData = {
    component: Page,
    path: '/execute/yearEnd/annualAccounts',
    team: Team.PHOENIX,
    documentationComponent:
        window?.segmentationData?.companyType === '1' ? 372 : 376,
    auth: (context) =>
        isCompany('YEAR_END') &&
        context.entitlementMap[
            (EntitlementLookup.AUTH_COMPANY_ACCOUNTING_REPORTS,
            EntitlementLookup.AUTH_ALL_VOUCHERS)
        ] !== undefined &&
        ((!window?.segmentationData?.modules?.yearEndReport &&
            context.entitlementMap[
                EntitlementLookup.AUTH_ACCOUNT_ADMINISTRATOR
            ] !== undefined) ||
            (!window?.segmentationData?.modules?.yearEndReport &&
                window?.isActiveAccountantProxy) ||
            (window?.segmentationData?.modules?.yearEndReport &&
                context.entitlementMap[
                    EntitlementLookup.YEAR_END_REPORT_ADMINISTRATOR
                ] !== undefined)),
};
