import { getFlattenedTree } from '@Page/LogisticsV2/components/DataTree/tree';
import { DataTreeDTO } from '@Page/LogisticsV2/components/DataTree/types';
import { DropdownInput } from '@Page/LogisticsV2/components/Input/DropdownInput/DropdownInput';
import {
    ProductAndSupplierConnectionFormModel,
    SupplierConnectionFormModel,
} from '@Page/LogisticsV2/components/Modals/ProductSelector/types';
import { ToggleSwitch } from '@Page/LogisticsV2/components/ToggleSwitch/ToggleSwitch';
import {
    ResaleProductDTO,
    SupplierConnectionDTO,
    VatTypeDTO,
} from '@Page/LogisticsV2/types/dtoTypes';
import {
    UseCurrencyPaginatedReturn,
    UseResaleProductsPaginatedReturn,
} from '@Page/LogisticsV2/types/returnTypes';
import { ApiError } from '@Page/LogisticsV2/utils/constants';
import { getFieldErrorFromValidationMessages } from '@Page/LogisticsV2/utils/functions';
import {
    Alert,
    AlertContent,
    Button,
    Group,
    Input,
    Label,
    Option,
    Select,
} from '@tlx/atlas';
import { ReactNode, useEffect, useState } from 'react';
import { ValidatedInput } from '../../Input/ValidatedInput';
import './ProductSelector.css';
import { useSuggestedProductNumber } from '@Page/LogisticsV2/components/Modals/ProductSelector/useProducts';

export function ProductSelectorTable(props: { children: ReactNode }) {
    return (
        <div className="atl-table-container">
            <table className="atl-table atl-table-fixed">
                {props.children}
            </table>
        </div>
    );
}

export function ProductSelectorEmptyState() {
    return (
        <tr className="atl-tr">
            <td colSpan={5} className="atl-td">
                {getMessage('text_no_products_found')}
            </td>
        </tr>
    );
}

export function ProductSelectorErrorState() {
    return (
        <tr className="atl-tr">
            <td colSpan={5} className="atl-td">
                {getMessage('text_no_products_found')}
            </td>
        </tr>
    );
}

export function ProductSelectorActions({
    onCancel,
    onConfirm,
}: {
    onCancel: () => void;
    onConfirm: () => void;
}) {
    return (
        <div className="atl-flex atl-gap-8">
            <Button
                data-testid={'product-selector-confirm-button'}
                onClick={() => onConfirm()}
            >
                {getMessage('text_confirm')}
            </Button>
            <Button
                data-testid={'product-selector-cancel-button'}
                onClick={() => onCancel()}
                variant="secondary"
            >
                {getMessage('text_cancel_reply')}
            </Button>
            <Button
                className="atl-ml-auto"
                variant="tertiary"
                data-testid={'product-selector-feedback-button'}
                onClick={() =>
                    window.open('https://forms.gle/K5eT7JzjLmGZbehH7')
                }
            >
                {getMessage('text_send_feedback')}
            </Button>
        </div>
    );
}

export function ProductAndSupplierConnectionForm({
    nodes,
    onCreateProduct,
    supplierId,
    productGroupId,
    vatTypes,
    error,
}: {
    nodes: DataTreeDTO[];
    onCreateProduct: (
        resaleProduct: Partial<ResaleProductDTO>,
        productGroupId: number | undefined,
        supplierConnection: Partial<SupplierConnectionDTO>
    ) => void;
    supplierId: number;
    productGroupId: number | undefined;
    vatTypes: VatTypeDTO[];
    error: ApiError | undefined;
}) {
    const tree = getFlattenedTree(nodes);
    const suggestedProductNumber = useSuggestedProductNumber();
    const [formState, setFormState] =
        useState<ProductAndSupplierConnectionFormModel>({
            name: '',
            number: suggestedProductNumber,
            costExcludingVatCurrency: 0,
            price: 0,
            vatTypeId: 3,
            groupId: productGroupId,
        });
    const [includeVat, setIncludeVat] = useState(false);
    const handleChange = (name: string) => (e: any) => {
        const value = typeof e === 'string' ? e : e.target.value;
        setFormState((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };
    const handleCreateSupplierProduct = async () => {
        const resaleProduct: Partial<ResaleProductDTO> = {
            name: formState.name,
            number: formState.number,
            costExcludingVatCurrency: formState.costExcludingVatCurrency,
            vatType: { id: Number(formState.vatTypeId) },
            isStockItem: true,
        };
        if (includeVat) {
            resaleProduct.priceIncludingVatCurrency = formState.price;
        } else {
            resaleProduct.priceExcludingVatCurrency = formState.price;
        }
        const supplierConnection: Partial<SupplierConnectionDTO> = {
            name: formState.name,
            number: formState.number,
            costExcludingVatCurrency: formState.costExcludingVatCurrency,
            supplier: { id: supplierId },
        };
        await onCreateProduct(
            resaleProduct,
            formState.groupId,
            supplierConnection
        );
    };
    useEffect(() => {
        setFormState((state) => ({
            ...state,
            groupId: productGroupId?.toString() ? productGroupId : 0,
            number: suggestedProductNumber ?? '',
        }));
    }, [productGroupId, suggestedProductNumber]);
    return (
        <form
            id={'create-product-form'}
            className={'atl-flex atl-py-8 atl-flex-col'}
            onSubmit={(event) => {
                event.preventDefault();
                event.stopPropagation();
                handleCreateSupplierProduct();
            }}
        >
            <div className={'atl-flex atl-gap-16 goldshark-new-product__form'}>
                <Group className={'atl-py-8 atl-flex atl-flex-col'}>
                    <Label>{getMessage('text_name')}*</Label>
                    <ValidatedInput
                        type={'text'}
                        data-testid={'new-product-name'}
                        onChange={handleChange('name')}
                        error={getFieldErrorFromValidationMessages(
                            error?.validationMessages,
                            'name'
                        )}
                    />
                </Group>
                <Group className={'atl-py-8 atl-flex atl-flex-col'}>
                    <Label>{getMessage('text_number')}</Label>
                    <ValidatedInput
                        type={'text'}
                        data-testid={'new-product-number'}
                        onChange={handleChange('number')}
                        error={getFieldErrorFromValidationMessages(
                            error?.validationMessages,
                            'number'
                        )}
                        value={formState.number}
                    />
                </Group>
                {tree.length > 0 && (
                    <Group className={'atl-py-8'}>
                        <Label>{getMessage('text_product_group')}</Label>
                        <Select
                            id="productGroup"
                            aria-labelledby="productGroup"
                            data-testid={'new-product-product-group'}
                            className={'goldshark-new-product__dropdown'}
                            onChange={handleChange('groupId')}
                            value={
                                formState.groupId !== undefined
                                    ? formState.groupId.toString()
                                    : '0'
                            }
                        >
                            <Option key={0} value={'0'}>
                                {getMessage('option_not_chosen')}
                            </Option>
                            {tree.map((node) => (
                                <Option
                                    key={node.id}
                                    value={node.id.toString()}
                                >
                                    {`${node.prefix} ${node.name}`}
                                </Option>
                            ))}
                        </Select>
                    </Group>
                )}
                <Group className={'atl-py-8 atl-flex atl-flex-col'}>
                    <Label>{getMessage('text_vat_code')}*</Label>
                    <Select
                        id="vatType"
                        aria-labelledby="vatType"
                        data-testid={'new-product-vat'}
                        className={'goldshark-new-product__dropdown'}
                        onChange={handleChange('vatTypeId')}
                        defaultValue={'3'}
                    >
                        {vatTypes.map((vat) => (
                            <Option key={vat.id} value={vat.id.toString()}>
                                {vat.displayName}
                            </Option>
                        ))}
                    </Select>
                </Group>
                <Group className={'atl-py-8 atl-flex atl-flex-col'}>
                    <Label>{getMessage('text_price_purchase_excl_vat')}</Label>
                    <Input
                        type={'number'}
                        data-testid={'new-product-purchase-excl-vat'}
                        onChange={handleChange('costExcludingVatCurrency')}
                        value={formState.costExcludingVatCurrency}
                    />
                </Group>
                <Group className={'atl-py-8 atl-flex atl-flex-col'}>
                    <Label>
                        {includeVat
                            ? getMessage('text_sales_price_incl_vat') + '(NOK)'
                            : getMessage('text_sales_price_excl_vat') + '(NOK)'}
                    </Label>
                    <Input
                        type={'number'}
                        data-testid={'new-product-purchase-selling-price'}
                        hidden={includeVat}
                        onChange={handleChange('price')}
                        value={formState.price}
                    />
                    <Input
                        type={'number'}
                        data-testid={'new-product-purchase-selling-price'}
                        hidden={!includeVat}
                        onChange={handleChange('price')}
                        value={formState.price}
                    />
                </Group>
                <Group>
                    <Label style={{ height: '35px' }} />
                    <ToggleSwitch
                        id={'product-selector-toggle-switch-vat'}
                        label={getMessage('text_incl_vat')}
                        onCheckedChange={() => {
                            setIncludeVat(!includeVat);
                        }}
                    />
                </Group>
            </div>
            {error?.validationMessages.length === 0 && (
                <Alert variant="error" className={'atl-my-16'}>
                    <AlertContent>{error.message}</AlertContent>
                </Alert>
            )}
        </form>
    );
}

export function SupplierConnectionForm({
    onCreateSupplierConnection,
    supplierId,
    resaleProductsData,
    currencyType,
    error,
}: {
    onCreateSupplierConnection?: (
        supplierConnection: Partial<SupplierConnectionDTO>
    ) => void;
    supplierId: number;
    resaleProductsData: UseResaleProductsPaginatedReturn;
    currencyType: UseCurrencyPaginatedReturn;
    error: ApiError | undefined;
}) {
    const [formState, setFormState] = useState<SupplierConnectionFormModel>({
        name: '',
        number: '',
        costExcludingVatCurrency: 0,
        resaleProductId: 0,
        currencyId: 1 /*Default currency is NOK */,
    });
    useEffect(() => {
        const foundProduct = resaleProductsData.data.find(
            (p) => p.id.toString() === formState.resaleProductId.toString()
        );
        if (foundProduct !== undefined) {
            setFormState((state) => ({
                ...state,
                name: foundProduct.name,
                number: foundProduct.number,
                costExcludingVatCurrency: foundProduct.costExcludingVatCurrency,
            }));
        }
    }, [formState.resaleProductId]);
    const handleChange = (name: string) => (e: any) => {
        const value = typeof e === 'string' ? e : e.target.value;
        setFormState((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };
    const handleCreateSupplierProduct = async () => {
        const supplierConnection = {
            name: formState.name,
            number: formState.number,
            costExcludingVatCurrency: formState.costExcludingVatCurrency,
            resaleProduct: { id: formState.resaleProductId },
            currency: { id: formState.currencyId },
            supplier: { id: supplierId },
        };
        onCreateSupplierConnection?.(supplierConnection);
    };
    currencyType.data.map((currency) => {
        currency.name = currency.displayName;
    });
    return (
        <form
            id={'create-product-form'}
            className={'atl-flex atl-py-8 atl-flex-col'}
            onSubmit={(event) => {
                event.preventDefault();
                event.stopPropagation();
                handleCreateSupplierProduct();
            }}
        >
            <div className={'atl-flex atl-gap-16 goldshark-new-product__form'}>
                <DropdownInput
                    onChange={handleChange('resaleProductId')}
                    data={resaleProductsData}
                    label={`${getMessage('text_product_for_resale')}*`}
                    error={getFieldErrorFromValidationMessages(
                        error?.validationMessages,
                        'resaleProduct'
                    )}
                />
                <Group className={'atl-py-8 atl-flex atl-flex-col'}>
                    <Label>{getMessage('text_name')}*</Label>
                    <ValidatedInput
                        type={'text'}
                        data-testid={'new-product-name'}
                        onChange={handleChange('name')}
                        error={getFieldErrorFromValidationMessages(
                            error?.validationMessages,
                            'name'
                        )}
                        value={formState.name}
                    />
                </Group>
                <Group className={'atl-py-8 atl-flex atl-flex-col'}>
                    <Label>{getMessage('text_number')}</Label>
                    <ValidatedInput
                        type={'text'}
                        data-testid={'new-product-number'}
                        onChange={handleChange('number')}
                        error={getFieldErrorFromValidationMessages(
                            error?.validationMessages,
                            'number'
                        )}
                        value={formState.number}
                    />
                </Group>
                <div style={{ width: '165px' }}>
                    <Group className={'atl-py-8 atl-flex atl-flex-col'}>
                        <Label>
                            {getMessage('text_price_purchase_excl_vat')}
                        </Label>
                        <Input
                            type={'number'}
                            data-testid={'new-product-purchase-excl-vat'}
                            onChange={handleChange('costExcludingVatCurrency')}
                            value={formState.costExcludingVatCurrency}
                        />
                    </Group>
                </div>
                <div style={{ width: '121px' }}>
                    <DropdownInput
                        onChange={handleChange('currencyId')}
                        data={currencyType}
                        label={getMessage('text_currency')}
                        defaultValue={'1'}
                    />
                </div>
            </div>
            {error?.validationMessages.length === 0 && (
                <Alert variant="error" className={'atl-my-16'}>
                    <AlertContent>{error.message}</AlertContent>
                </Alert>
            )}
        </form>
    );
}
