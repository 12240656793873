import * as React from 'react';

import { PageLoader } from '@Component/PageLoader';

import type { PageNotFoundProps } from './component';
import { renderComponent } from '@General/renderComponent';

export const PageNotFoundPage: React.FC<PageNotFoundProps> = (props) => (
    <PageLoader<PageNotFoundProps>
        loader={async () => import('./PageNotFound.module').then((m) => m.page)}
        adjustWrapperDiv
        props={props}
    />
);

export function renderPageNotFound(domId: string) {
    renderComponent(PageNotFoundPage, domId, {});
}
