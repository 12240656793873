import { CommandResponderRegistry } from '@General/Command';

export const EscapeCommandId = 'EscapeCommand';

const EscapeCommand = {
    id: EscapeCommandId,
};

const EscapeCombination = {
    key: 'Escape',
};

export function registerEscape(registry: CommandResponderRegistry) {
    registry.registerCommand(EscapeCommand);
    registry.registerCombination(EscapeCommandId, EscapeCombination);
}
