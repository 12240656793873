import { Button, Icon, Input } from '@tlx/atlas';
import React, { useEffect, useState } from 'react';
import './DueDate.css';
import { LoadingSpinner } from '@Component/Loading';

enum DueInScale {
    Days = 0,
    Months = 1,
}

type DueDateForJSPProps = {
    isEditMode: boolean;
    setIsEditMode: (isEditMode: boolean) => void;
};

export function DueDateForJSP({
    isEditMode,
    setIsEditMode,
}: DueDateForJSPProps) {
    const [daysOrMonths, setDaysOrMonths] = useState<string | undefined>();
    const [dueInScale, setDueInScale] = useState<DueInScale | undefined>();
    const [isDueInRecurringDayOfMonth, setIsDueInRecurringDayOfMonth] =
        useState<boolean | undefined>();
    const [recurringDueDateOfMonth, setRecurringDueDateOfMonth] = useState<
        number | undefined
    >();

    useEffect(() => {
        // Add event listeners to get changes from JSP
        const dueinInput =
            document.querySelector<HTMLInputElement>('.js-duein');
        if (dueinInput) {
            dueinInput.addEventListener('change', (event: any) => {
                setIsEditMode(false);
                const duein = event.target.value;
                if (duein !== daysOrMonths) {
                    setDaysOrMonths(duein);
                }
            });
        }

        const dueinScaleInput = document.querySelector<HTMLInputElement>(
            '[name="order.dueinscale"]'
        );
        if (dueinScaleInput) {
            dueinScaleInput.addEventListener('change', (event: any) => {
                setIsEditMode(false);
                const scale = event.target.value;
                if (scale !== daysOrMonths) {
                    setDueInScale(scale);
                }
            });
        }

        const isRecurringCheckbox = document.querySelector<HTMLInputElement>(
            '.activeRecurringDueDayCheckbox'
        );
        if (isRecurringCheckbox) {
            isRecurringCheckbox.addEventListener('change', (event: any) => {
                const isDueInRecurring = event.target.checked;
                setIsEditMode(false);
                if (isDueInRecurring !== isDueInRecurringDayOfMonth) {
                    setIsDueInRecurringDayOfMonth(isDueInRecurring);
                }
            });
        }

        const recurringValueElement = document.querySelector<HTMLInputElement>(
            '.js-recurringDueDayOfMonth'
        );
        if (recurringValueElement) {
            recurringValueElement.addEventListener('change', (event: any) => {
                const recurringDayOfMonth = event.target.value;
                if (recurringDayOfMonth !== recurringDueDateOfMonth) {
                    setRecurringDueDateOfMonth(recurringDayOfMonth);
                }
            });
        }
    }, []);

    useEffect(() => {
        // Get initial values from JSP
        const dueinInput =
            document.querySelector<HTMLInputElement>('.js-duein');
        setDaysOrMonths(dueinInput?.value);

        const dueInScaleInput = document.querySelector<HTMLInputElement>(
            '[name="order.dueinscale"]'
        );

        setDueInScale(Number(dueInScaleInput?.value));

        const isRecurringCheckbox = document.querySelector<HTMLInputElement>(
            '.activeRecurringDueDayCheckbox'
        );
        setIsDueInRecurringDayOfMonth(isRecurringCheckbox?.checked ?? false);

        const recurringDueDayOfMontInput =
            document.querySelector<HTMLInputElement>(
                '.js-recurringDueDayOfMonth'
            );
        setRecurringDueDateOfMonth(Number(recurringDueDayOfMontInput?.value));
    }, []);

    function handleChange(numberOfDaysOrMonths: string, scale: number) {
        // Due date jsp field is hidden, so does not trigger change by itself
        const form: any =
            document.getElementsByClassName('js-createOrderForm')[0];
        form.dataset.hasChanged = true;

        if (numberOfDaysOrMonths !== daysOrMonths) {
            if (numberOfDaysOrMonths == '') {
                setDaysOrMonths('');
            } else if (!isNaN(Number(numberOfDaysOrMonths))) {
                const dueinInput =
                    document.querySelector<HTMLInputElement>('.js-duein');
                if (dueinInput) {
                    dueinInput.value = numberOfDaysOrMonths.toString();
                }
                setDaysOrMonths(numberOfDaysOrMonths);
            }
        }

        if (scale !== dueInScale) {
            const dueinScaleInput = document.querySelector<HTMLInputElement>(
                '[name="order.dueinscale"]'
            );
            if (dueinScaleInput) {
                dueinScaleInput.value = scale.toString();
            }
            setDueInScale(scale);
        }
    }

    return (
        <DueDate
            daysOrMonths={daysOrMonths?.toString()}
            scale={dueInScale}
            handleChange={handleChange}
            isRecurring={isDueInRecurringDayOfMonth}
            recurringDueDayOfMonth={recurringDueDateOfMonth}
            isEditMode={isEditMode}
            setIsEditMode={setIsEditMode}
        />
    );
}

type DueDateProps = {
    daysOrMonths: string | undefined;
    scale: DueInScale | undefined;
    handleChange: (daysOrMonths: string, scale: DueInScale) => void;
    isRecurring: boolean | undefined;
    recurringDueDayOfMonth: number | undefined;
    isEditMode: boolean;
    setIsEditMode: (isEditMode: boolean) => void;
};

export function DueDate({
    daysOrMonths,
    scale,
    handleChange,
    isRecurring,
    recurringDueDayOfMonth,
    isEditMode,
    setIsEditMode,
}: DueDateProps) {
    const [validationMessage, setValidationMessage] = useState<string>('');

    useEffect(() => {
        if (isEditMode && scale == DueInScale.Months) {
            handleChange(
                (Number(daysOrMonths) * 30).toString(),
                DueInScale.Days
            );
        }
    }, [isEditMode]);

    if (
        daysOrMonths === undefined ||
        scale === undefined ||
        isRecurring === undefined ||
        recurringDueDayOfMonth === undefined
    ) {
        return <LoadingSpinner />;
    }

    function getDayNumberSuffix(day: number) {
        const language = window.locale.split('_')[0].toLowerCase() as
            | 'en'
            | 'no';
        console.log(language);
        if (language !== 'en') {
            return '.';
        }

        if (day >= 11 && day <= 13) {
            return 'th';
        }
        switch (day % 10) {
            case 1:
                return 'st';
            case 2:
                return 'nd';
            case 3:
                return 'rd';
            default:
                return 'th';
        }
    }

    function getDueDateDescription() {
        if (isRecurring) {
            return getMessage(
                'text_fixed_due_date_time',
                recurringDueDayOfMonth ?? '',
                getDayNumberSuffix(Number(recurringDueDayOfMonth))
            );
        }

        return getMessage(
            'text_time_from_invoice_date',
            daysOrMonths ?? '',
            scale == DueInScale.Months
                ? getMessage('text_months').toLowerCase()
                : getMessage('text_days').toLowerCase()
        );
    }

    function handleBlur(event: React.FocusEvent<HTMLInputElement>) {
        validate(event.target.value);
    }

    function validate(value: string) {
        const duein = Number(value);
        if (duein < 0 || duein > 10000) {
            setValidationMessage(getMessage('validation_invalid_value'));
        } else {
            setValidationMessage('');
        }
    }

    function getInputValue() {
        if (isRecurring) {
            return recurringDueDayOfMonth;
        }
        if (scale == DueInScale.Months) {
            return Number(daysOrMonths) * 30;
        }
        if (scale == DueInScale.Days) {
            return daysOrMonths;
        }
        return '';
    }

    return (
        <>
            <div className="atl-pb-8 atl-flex atl-justify-between atl-text-blue-100 atl-text-base">
                <div className="atl-flex atl-items-center ">
                    <Icon className="atl-mr-8">calendar_today</Icon>
                    {getMessage('text_due_date')}
                </div>
            </div>
            {!isEditMode && (
                <div className="atl-text-base atl-text-left atl-text-grey-120">
                    {getDueDateDescription()}
                </div>
            )}
            {isEditMode && (
                <div>
                    <div className="atl-flex">
                        <Input
                            type="text"
                            data-testid="due-date-duein-input"
                            className="kb-due-date__input"
                            value={getInputValue()}
                            onChange={(event) =>
                                handleChange(
                                    event.target.value,
                                    DueInScale.Days
                                )
                            }
                            onBlur={handleBlur}
                            autoFocus={true}
                            disabled={isRecurring}
                        />
                        <Button
                            className="kb-due-date__check-button atl-ml-8"
                            variant="tertiary"
                            data-testid="due-date-check-button"
                            onClick={(event) => {
                                setIsEditMode(false);
                                event.stopPropagation();
                            }}
                        >
                            <Icon>check</Icon>
                        </Button>
                    </div>
                    <div className="atl-text-red-100">{validationMessage}</div>
                </div>
            )}
        </>
    );
}
