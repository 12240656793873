import { renderComponent } from '@General/renderComponent';
import { PageLoader } from '@Component/PageLoader';

const Page = () => (
    <PageLoader<{}>
        adjustWrapperDiv
        loader={async () => import('./BankOnboarding').then((m) => m.page)}
    />
);

export function renderBankOnboarding(domId: string) {
    return renderComponent(Page, domId);
}
