import React from 'react';
import './SkeletonRectangle.css';

export const SkeletonRectangle = ({
    className,
    width,
    height,
}: {
    width?: number | string;
    height?: number | string;
    className?: string;
}) => {
    return (
        <div
            className={`kb-skeleton ${className}`}
            style={{
                width: width,
                height: height,
            }}
        />
    );
};
