import React, { useState } from 'react';
import { Alert, AlertContent, Group, Input, Label, Option } from '@tlx/atlas';
import { SupplierConnectionDTO } from '@Page/LogisticsV2/types/dtoTypes';
import { SupplierConnectionFormModel } from '@Page/LogisticsV2/components/Modals/ProductSelector/types';
import { ApiUrl, MethodType } from '@Page/LogisticsV2/utils/enums';
import { LoadableDropdown } from '@Component/LoadableDropdown/LoadableDropdown';
import { ApiError, OPTION_NOT_CHOSEN } from '@Page/LogisticsV2/utils/constants';
import {
    getDisplayNumber,
    getFieldErrorFromValidationMessages,
    getIdFromUrl,
} from '@Page/LogisticsV2/utils/functions';
import { ValidatedInput } from '@Page/LogisticsV2/components/Input/ValidatedInput';
import { invokeAPI } from '@Page/LogisticsV2/hooks/api';

export function SupplierConnectionForm({
    onCreateSupplierConnection,
    supplierId,
    error,
}: {
    onCreateSupplierConnection?: (
        supplierConnection: Partial<SupplierConnectionDTO>
    ) => void;
    supplierId: number;
    error: ApiError | undefined;
}) {
    const resaleProductId = getIdFromUrl('resaleProductId');
    const [formState, setFormState] = useState<SupplierConnectionFormModel>({
        name: '',
        number: '',
        costExcludingVatCurrency: 0,
        resaleProductId: resaleProductId ? parseInt(resaleProductId) : -1,
        currencyId: 1 /*Default currency is NOK */,
    });

    const handleChange = (name: string) => (event: any) => {
        const value = typeof event === 'string' ? event : event.target.value;
        setFormState((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };
    const handleCreateSupplierProduct = async () => {
        const supplierConnection = {
            name: formState.name,
            number: formState.number,
            costExcludingVatCurrency: formState.costExcludingVatCurrency,
            resaleProduct: { id: formState.resaleProductId },
            currency: { id: formState.currencyId },
            supplier: { id: supplierId },
        };

        await onCreateSupplierConnection?.(supplierConnection);
    };
    const onChangeResaleProduct = async (id: string) => {
        const { data } = await invokeAPI(
            `${ApiUrl.PRODUCT}/${id}?fields=id,name,number,elNumber,nrfNumber,costExcludingVatCurrency`,
            MethodType.GET
        );
        const dataJson = await data?.json();
        const resaleProduct = dataJson.value;
        setFormState((prevState) => ({
            ...prevState,
            ['resaleProductId']: parseInt(id),
            ['name']: resaleProduct.name,
            ['number']: getDisplayNumber(resaleProduct),
            ['costExcludingVatCurrency']:
                resaleProduct.costExcludingVatCurrency,
        }));
    };
    return (
        <form
            id={'create-product-form'}
            className={'atl-flex atl-py-8 atl-flex-col'}
            onSubmit={(event) => {
                event.preventDefault();
                event.stopPropagation();
                handleCreateSupplierProduct();
            }}
        >
            <div className={'atl-flex atl-gap-16 goldshark-new-product__form'}>
                <Group className={'atl-py-8 atl-flex atl-flex-col'}>
                    <Label>{getMessage('text_product_for_resale')}*</Label>
                    <LoadableDropdown
                        data-testid="new-product-resale-dropdown"
                        url={`${ApiUrl.PRODUCT_QUERY}?isInactive=false&isSupplierProduct=false`}
                        value={formState.resaleProductId}
                        onChange={(event) => {
                            const value =
                                typeof event === 'string'
                                    ? event
                                    : event.target.value;
                            onChangeResaleProduct(value);
                        }}
                    >
                        <Option value={OPTION_NOT_CHOSEN}>
                            {getMessage('option_not_chosen')}
                        </Option>
                    </LoadableDropdown>
                    {error ? (
                        <div className="atl-text-red-120">
                            {getFieldErrorFromValidationMessages(
                                error?.validationMessages,
                                'resaleProduct'
                            )}
                        </div>
                    ) : null}
                </Group>
                <Group className={'atl-py-8 atl-flex atl-flex-col'}>
                    <Label>{getMessage('text_name')}*</Label>
                    <ValidatedInput
                        type={'text'}
                        data-testid={'new-product-name'}
                        onChange={handleChange('name')}
                        error={getFieldErrorFromValidationMessages(
                            error?.validationMessages,
                            'name'
                        )}
                        value={formState.name}
                    />
                </Group>
                <Group className={'atl-py-8 atl-flex atl-flex-col'}>
                    <Label>{getMessage('text_number')}</Label>
                    <ValidatedInput
                        type={'text'}
                        data-testid={'new-product-number'}
                        onChange={handleChange('number')}
                        error={getFieldErrorFromValidationMessages(
                            error?.validationMessages,
                            'number'
                        )}
                        value={formState.number}
                    />
                </Group>
                <Group className={'atl-py-8 atl-flex atl-flex-col'}>
                    <Label>{getMessage('text_price_purchase_excl_vat')}</Label>
                    <Input
                        type={'number'}
                        data-testid={'new-product-purchase-price-input'}
                        onChange={handleChange('costExcludingVatCurrency')}
                        value={formState.costExcludingVatCurrency}
                    />
                </Group>
                <Group className={'atl-py-8 atl-flex atl-flex-col'}>
                    <Label>{getMessage('text_currency')}</Label>
                    <LoadableDropdown
                        data-testid="new-product-currency-dropdown"
                        url={ApiUrl.CURRENCY_QUERY}
                        value={formState.currencyId ?? OPTION_NOT_CHOSEN}
                        onChange={handleChange('currencyId')}
                    >
                        <Option value={OPTION_NOT_CHOSEN}>
                            {getMessage('option_not_chosen')}
                        </Option>
                    </LoadableDropdown>
                </Group>
            </div>
            {error?.validationMessages.length === 0 && (
                <Alert variant="error" className={'atl-my-16'}>
                    <AlertContent>{error.message}</AlertContent>
                </Alert>
            )}
        </form>
    );
}
