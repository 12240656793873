import { ModelAPIV1 } from '@General/types/Api';
import { createCreateNewButton } from '@Component/DropDown/CreateNewButton/CreateNewButton';
import { CreateButtonComponent, Option } from '../../types';
import { JSPDropdownFetcher } from '../JSPDropdownFetcher';
import { ApiResultMapperNoUndefinedResult } from '@General/Provider';
import * as React from 'react';

interface WarehouseEntry extends ModelAPIV1 {
    readonly displayName: string;
}

const warehouseMapper: ApiResultMapperNoUndefinedResult<WarehouseEntry> = (
    item
) => ({
    value: item.id + '',
    displayName: item.displayName,
    item,
});

class WarehouseFetcher extends JSPDropdownFetcher<WarehouseEntry> {
    asyncJsonrpcGetter: Function;

    constructor(scope: HTMLDivElement, queryAttributes: Record<string, any>) {
        super(scope, queryAttributes, warehouseMapper);
        this.asyncJsonrpcGetter = asyncrpc.Warehouse.get;
    }

    getParams(): ObjectIndex {
        return {
            isActive: this.getQueryAttribute('isActive', 1),
        };
    }

    async fetchList(params: ObjectIndex): Promise<Option<WarehouseEntry>[]> {
        return new Promise((resolve, reject) => {
            try {
                jsonrpc.Warehouse.searchForWarehouses(
                    (result: WarehouseEntry[] | null) =>
                        resolve((result ?? []).map(warehouseMapper)),
                    this.getMarshallSpec(),
                    window.loginCompanyId,
                    params.isActive,
                    params.name || ''
                );
            } catch (e) {
                defaultJsonRpcExceptionHandler(e);
                reject(e);
            }
        });
    }

    createCreateNewButton(
        setSelected: (id: number) => void
    ): CreateButtonComponent | undefined {
        return createCreateNewButton(
            setSelected,
            'text_warehouse_create',
            '/execute/warehouse'
        );
    }
}

export const exports = {
    fetcher: WarehouseFetcher,
    type: 'Warehouse',
};
