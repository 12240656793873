export type ApiError = {
    message: string;
    validationMessages: TripletexValidationMessage[];
};

export type DeviceType = 'mobile' | 'tablet' | 'desktop';

export interface TripletexValidationMessage {
    field: string;
    message: string;
}

export const OPTION_NOT_CHOSEN = '-1';

export const INFINITE_SCROLL_PAGE_SIZE = 50;
