import * as React from 'react';

import { renderComponent } from '@General/renderComponent';
import { PageLoader } from '@Component/PageLoader';

const Page = () => (
    <PageLoader<{}>
        loader={async () =>
            import('./EntitlementConfirmation').then((m) => m.page)
        }
    />
);

export function renderEntitlementConfirmation(domId: string) {
    renderComponent(Page, domId);
}
