import { AutoSaveStatus } from '@Page/PaymentOverview/components/AutoSaveStatus';
import { AutoSaveStatusType, PageUrl } from '@Page/LogisticsV2/utils/enums';
import { Button } from '@tlx/atlas';
import { nav } from '../../../../../../../js/modules/navigation';
import * as React from 'react';
import { EmptyState } from '@Page/LogisticsV2/components/EmptyState/EmptyState';
import { addContextId } from '../../../../../../../js/modules/url';

export function WarehouseOverviewLayout({
    children,
}: {
    children: React.ReactNode;
}) {
    return <div>{children}</div>;
}
export function WarehouseOverviewHeader({
    status,
}: {
    status: AutoSaveStatusType;
}) {
    return (
        <div
            className={
                'atl-flex atl-w-full atl-justify-between atl-mb-16 atl-flex-wrap'
            }
        >
            <div
                id="menuHeader"
                data-testid="header-title"
                className="useAtlasHeader atl-flex atl-gap-16"
            >
                <h1 className="clip">
                    {getMessage('text_warehouse_overview')}
                </h1>
                <AutoSaveStatus status={status} />
            </div>
            <Button
                className="tlx-warehouses-list-header__button"
                data-testid="new-warehouse-button"
                onClick={() =>
                    nav.nav(
                        addContextId(
                            `${PageUrl.WAREHOUSE_DETAILS}?warehouseId=0`
                        )
                    )
                }
            >
                {getMessage('text_warehouse_create')}
            </Button>
        </div>
    );
}

export const WarehouseOverviewEmpty = ({ colSpan }: { colSpan: number }) => {
    return (
        <tr>
            <td className="atl-bg-white" colSpan={colSpan}>
                <EmptyState
                    title={getMessage('text_warehouse_overview_empty_title')}
                    description={getMessage(
                        'text_warehouse_overview_empty_description'
                    )}
                    className={'atl-text-xl atl-whitespace-nowrap'}
                    svgIcon={'warehouseOverview'}
                    iconSize={200}
                >
                    <div>
                        {!moduleRackbeat && (
                            <Button
                                data-testid="empty-state-warehouse-overview-new-warehouse"
                                className="atl-text-center"
                                variant="secondary"
                                onClick={() =>
                                    nav.nav(
                                        addContextId(
                                            `${PageUrl.WAREHOUSE_DETAILS}?warehouseId=0`
                                        )
                                    )
                                }
                            >
                                {getMessage('text_warehouse_create')}
                            </Button>
                        )}
                    </div>
                </EmptyState>
            </td>
        </tr>
    );
};
