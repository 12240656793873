export interface NewsData {
    readonly title: string;
    readonly publish_date: string;
    readonly excerpt: string;
    readonly permalink: string;
}

export const enum NewsModule {
    All = '',
    Administration = '413',
    App = '407',
    YearEnd = '490',
    Attestation = '417',
    Bank = '411',
    UserExperience = '415',
    Design = '475',
    Invoice = '401',
    FinancialServices = '481',
    ForAccountants = '412',
    Logistics = '410',
    Salary = '403',
    Project = '405',
    Rackbeat = '584',
    Report = '489',
    Accounting = '402',
    TravelAndExpense = '404',
    Security = '414',
    Hour = '406',
    Video = '448',
}
