export type Action<T extends string, Payload extends {}> = {
    readonly type: T;
} & Payload;

interface ActionCreator<T extends string, Payload extends {}> {
    (payload: Payload): Action<T, Payload>;
    readonly type: T;
}

export function ActionCreator<T extends string, Payload extends {}>(
    type: T
): ActionCreator<T, Payload> {
    const factory = (payload: Payload) => ({
        type,
        ...payload,
    });
    factory.type = type;
    return factory;
}

export interface SimpleAction<T extends string> {
    readonly type: T;
}

interface SimpleActionCreator<T extends string> {
    (): SimpleAction<T>;
    readonly type: T;
}

export function SimpleActionCreator<T extends string = string>(
    type: T
): SimpleActionCreator<T> {
    const factory = () => ({
        type,
    });
    factory.type = type;
    return factory;
}
