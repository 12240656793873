import $ from 'jquery';
import { LazyContentLoader } from '../c-common';
import { nav } from './navigation';
import { tlxUrl } from './url';

export const filter = (function () {
    function handleSearchResult(searchUrl: string): void {
        function urlPathname() {
            // Hack to parse a url
            return $("<a href='" + searchUrl + "'></a>").prop('pathname');
        }
        // - If the search was done from the the same page we want to replace/re-use the current history entry, otherwise create a new one
        // - Want the filter state to be preserved, so we used the saved url (with the searchText parameter appended) (see getUpdatedUrl for more)
        //   BUT only if the page is already using filter saving. (ie. if we came from a direct link, don't enable filter saving)
        //   This has the "sideeffect" that when searching from the top-right search box, the result page is not considered the same as the corresponding overview page accessed through the menu.
        // Don't use filter state saving unless the page is already using it.
        const useFilterSaving = history.state && history.state.pageKey;
        const isOnSamePage = urlPathname() === window.location.pathname;
        if (isOnSamePage && useFilterSaving) {
            searchUrl = filter.getUpdatedUrl(searchUrl);
        }
        nav.nav(searchUrl, { replaceState: isOnSamePage });
    }

    function refreshOffset(offset: number) {
        if (offset < 0) {
            offset = 0;
        }
        const $tabPanel = $('.ui-tabs-panel:visible');
        if ($tabPanel.length == 1) {
            // @ts-expect-error submitGetFormAjax is not typed
            $('#PeriodForm', $tabPanel).submitGetFormAjax({ offset: offset });
            return;
        }
        let url;
        url = getUpdatedUrl(
            document.location.pathname + document.location.search
        );
        url = tlxUrl.removeUrlParameter(url, 'offset');
        url = tlxUrl.addUrlParameter(url, 'offset', offset.toString());

        const $contentContainer = $('.contentContainer');

        //setUpdatedUrl(url);

        // We do currently not support lazy loading when several pages/tabs are loaded into the same DOM
        // at the time. To support this, we would have to know in what scope the "next"/"previous" buttons
        // reside in. This function (refreshOffset) don't have any information about this.
        if ($contentContainer.length === 1) {
            // @ts-expect-error not typed yet
            LazyContentLoader($contentContainer)(url);
            history.pushState({}, '', url);
        } else {
            nav.nav(url);
        }

        $('#scrollContainer').scrollTop(0);
    }

    /**
     * For use when the standard way (via submitGetFormAjax) can't be used,
     * i.e. the form is a POST form.
     */
    function refreshPeriod(
        startDate: string,
        endOfPeriodDate: string,
        periodType: number
    ) {
        let url;
        url = getUpdatedUrl(
            document.location.pathname + document.location.search
        );
        url = tlxUrl.removeUrlParameter(url, 'period.startDate');
        url = tlxUrl.removeUrlParameter(url, 'period.endOfPeriodDate');
        url = tlxUrl.removeUrlParameter(url, 'period.periodType');
        url = tlxUrl.removeUrlParameter(url, 'offset'); // prevents pagination UI glitch. TODO: is this a special case?
        url = tlxUrl.addUrlParameter(url, 'period.startDate', startDate);
        url = tlxUrl.addUrlParameter(
            url,
            'period.endOfPeriodDate',
            endOfPeriodDate
        );
        url = tlxUrl.addUrlParameter(
            url,
            'period.periodType',
            periodType.toString()
        );

        const $contentContainer = $('.contentContainer');

        if ($contentContainer.length === 1) {
            // @ts-expect-error not typed yet
            LazyContentLoader($contentContainer)(url);
            history.pushState({}, '', url);
        } else {
            nav.nav(url);
        }

        $('#scrollContainer').scrollTop(0);
    }

    /**
     * If the page specified by origUrl has a saved filter, returns the url representing that filter.
     * (also contains some special logic for search...)
     * @param origUrl
     * @returns
     */
    function getUpdatedUrl(origUrl: string): string {
        const lookupUrl = tlxUrl.getUrlFilterKey(origUrl);
        // @ts-expect-error sessionStorage is not typed yet
        let savedUrl = $.sessionStorage.getItem(lookupUrl);
        if (savedUrl) {
            let search = tlxUrl.getUrlParameters(origUrl)['searchText'];
            if (search) {
                /* This is an ad-hoc fix for issue SUP-667:
                 *   The search text originates from the server which encodes space as '+'
                 *   The client, on the other hand, encode/decodes it as '%20'. The '+'/spaces is thus
                 *   not decoded at this point so we do it manually.
                 *   Hopefully we'll fix this generally in the future, but it wouldn't be appropiate
                 *   in a hot fix. -- ojb 2015-06-25
                 */
                search = search.replace(/\+/g, ' ');

                // We want to retain the filter state when searching (even though it doesn't affect the result).
                // Otherwise the filter would be reset when doing a search and then using the filter in the resulting page.
                // We do this by adding the saved filter to the search url. All server side forms should ignore the filter values when "searchText" is specified.
                // This isn't really ideal, but I couldn't find a better way. The whole search method (usage of defaultAjaxaction etc.) feels a bit broken though..
                savedUrl = tlxUrl.removeUrlParameter(savedUrl, 'searchText');
                savedUrl = tlxUrl.addUrlParameter(
                    savedUrl,
                    'searchText',
                    search
                );
            }
        }
        return savedUrl || origUrl;
    }

    /**
     * Set the saved filter for the current page to 'url'
     */
    function setUpdatedUrl(url: string): void {
        if (history.state && history.state.pageKey) {
            // @ts-expect-error sessionStorage is not typed yet
            $.sessionStorage.setItem(
                history.state.pageKey,
                tlxUrl.removeUrlParameter(url, 'contextId')
            );
        }
    }

    return {
        handleSearchResult: handleSearchResult,
        refreshOffset: refreshOffset,
        refreshPeriod: refreshPeriod,
        getUpdatedUrl: getUpdatedUrl,
        setUpdatedUrl: setUpdatedUrl,
    };
})();
