import { renderComponent } from '@General/renderComponent';
import * as React from 'react';
import { PageLoader } from '@Component/PageLoader';

const Page = () => (
    <PageLoader<{}>
        loader={async () => import('./ProductUnits').then((m) => m.page)}
        adjustWrapperDiv
    />
);

export function renderProductUnits(domId: string) {
    renderComponent(Page, domId);
}
