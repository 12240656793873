import { invokeAPI } from '@Page/LogisticsV2/hooks/api';
import { ApiUrl, MethodType } from '@Page/LogisticsV2/utils/enums';
import {
    STATUS_ACTIVE_INACTIVE,
    WarehouseOverviewAPIModel,
} from '@Page/LogisticsV2/pages/Warehouse/overview/types';
import { KeyedMutator } from 'swr/_internal';
import { ListResponse } from '@tlx/astro-shared';

export function doUpdateWarehouse(
    id: number,
    isChecked: boolean,
    type: string
) {
    const payload =
        type === 'mainWarehouse'
            ? { isMainInventory: isChecked }
            : { isInactive: !isChecked };
    return invokeAPI(
        `${ApiUrl.INVENTORY}/${id}`,
        MethodType.PUT,
        JSON.stringify(payload)
    );
}

export function doDeleteWarehouse(id: number) {
    return invokeAPI(`${ApiUrl.INVENTORY}/${id}`, MethodType.DELETE);
}

export async function doAnimateRow(
    warehouseId: number,
    refresh: KeyedMutator<ListResponse<WarehouseOverviewAPIModel>[]>
) {
    const rowElement = document.querySelector(
        `.row-${warehouseId}`
    ) as HTMLElement;
    if (rowElement) {
        rowElement.style.animation =
            'goldshark-warehouse-overview-row-animated 1s';
        rowElement.classList.remove('atl-tr--highlight');
        rowElement.addEventListener(
            'animationend',
            async () => {
                await refresh();
                rowElement.style.animation = '';
                rowElement.classList.add('atl-tr--highlight');
            },
            { once: true }
        );
    }
}

export function getStatusFilter(statusFilter: string[]) {
    if (
        statusFilter[0] === '' ||
        statusFilter.length === STATUS_ACTIVE_INACTIVE
    ) {
        return undefined;
    } else {
        return !statusFilter.includes('active');
    }
}
