export function encodeHTML(html: string): string {
    if (!html) {
        return html;
    }
    html = html.replace(/&/g, '&amp;'); // this should be done first
    html = html.replace(/'/g, '&#39;');
    html = html.replace(/</g, '&lt;');
    html = html.replace(/>/g, '&gt;');
    html = html.replace(/'/g, '&quot;');
    html = html.replace(/\r\n/g, '<br/>');
    html = html.replace(/\n/g, '<br/>');
    html = html.replace(/\r/g, '<br/>');
    html = html.replace(/\t/g, '&nbsp&nbsp&nbsp&nbsp ');
    return html;
}

export function decodeHTML(text: string): string {
    if (!text) {
        return text;
    }
    text = text.replace(/&lt;/g, '<');
    text = text.replace(/&gt;/g, '>');
    text = text.replace(/&quot;/g, '"');
    text = text.replace(/&#39;/g, "'");
    text = text.replace(/&amp;/g, '&');
    return text;
}
