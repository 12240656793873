import jQuery from 'jquery';

import { browserInfo } from '../../modules/d-browserInfo';

/**
 * Some HTML5 input types do not play very well with MDL. And to implement HTML5 functionality to the MDL inputs
 * we need some help. By placing a transparent HTML5 input on top of the actual input we can get the correct keyboard
 * but skip the headache of trying to style some inputs that are very device dependent to look like MDL.
 */

export const html5Helper = (function ($) {
    //copying the input for the fake HTML5 helper element to the real input element.
    function copy(e) {
        $(e.target)
            .closest('.inputItem')
            .find('.tlx-textfield__input')
            .val(e.target.value);
    }

    // We need to do some checks that the device actually supports HTML5 type inputs
    // In addition some magic to make it look like it behaves as a real MDL input
    function onFocus(e, type) {
        const i = document.createElement('input');
        $(e.target).closest('.tlx-textfield').addClass('is-focused');

        //check if the browser supports input[type="time"]
        i.setAttribute('type', type);
        if (i.type !== type || !browserInfo.isMobileReg()) {
            $(e.target)
                .closest('.inputItem')
                .find('.tlx-textfield__input')
                .focus();
        }
    }

    // Make it look like it behaves as a real MDL input
    function onBlur(e) {
        $(e.target).closest('.tlx-textfield').removeClass('is-focused');
    }

    function init(e) {
        /**
         * html5 input[type="time"] in material design. see html5-input-type-assistant.js
         */
        $('.tmdl-time-picker-assistant', e.target).on('change', function (e) {
            copy(e);
        });

        $('.tmdl-time-picker-assistant', e.target).on('focus', function (e) {
            onFocus(e, 'time');
        });

        //Remove mdl focus style when the dummy noe longer is in focus
        $('.tmdl-time-picker-assistant', e.target).on('blur', function (e) {
            onBlur(e);
        });
    }

    return {
        init: init,
    };
})(jQuery);
