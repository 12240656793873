import * as React from 'react';
import { MessageObject } from '../../types';
import { MESSAGE_TYPE } from '../../types';
import { ButtonIcon } from '@Component/ButtonIcon';
import { MaterialIcon } from '@Component/MaterialIcon';
import { Button } from '@Component/Button';
import { Markdown } from '@Component/Markdown';
import { Text } from '@Component/Text';

import './Message.scss';

const { setTimeout, clearTimeout } = window;

type Props = {
    message: MessageObject;
    onClose?: (message: MessageObject) => void;
    dispatchAction?: Function;
    extraClassnames?: string;
    closeButtonTrackingId?: string;
};

/**
 * Should only be used by Messages component
 */
export default class Message extends React.PureComponent<Props> {
    timeout?: number;

    constructor(props: Props) {
        super(props);
        this.dismiss = this.dismiss.bind(this);
        this.handleAction = this.handleAction.bind(this);
    }

    dismiss() {
        if (this.props.onClose) {
            this.props.onClose(this.props.message);
        }
    }

    componentDidMount() {
        if (this.props.message.timeout) {
            this.timeout = setTimeout(this.dismiss, this.props.message.timeout);
        }
    }

    componentWillUnmount() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    handleAction() {
        if (this.props.message.action) {
            if (
                this.props.message.action.dispatchOnClick &&
                this.props.dispatchAction
            ) {
                this.props.dispatchAction(
                    this.props.message.action.dispatchOnClick
                );
            }
            if (this.props.message.action.onClick) {
                this.props.message.action.onClick();
            }
            if (this.props.message.action.closeOnClick !== false) {
                this.dismiss();
            }
        }
    }

    render() {
        let iconName = '';

        const messageType = this.props.message.type;

        switch (messageType) {
            case MESSAGE_TYPE.ERROR:
                iconName = 'error_outline';
                break;
            case MESSAGE_TYPE.WARNING:
                iconName = 'warning_amber';
                break;
            case MESSAGE_TYPE.CONFIRMATION:
                iconName = 'check_circle';
                break;
            default:
                iconName = 'info';
                break;
        }

        const actionButton =
            !this.props.message.action ||
            !this.props.message.action.text ? null : (
                <Button
                    type="button"
                    raised={this.props.message.action.raised}
                    onClick={this.handleAction}
                    trackingId={this.props.message.action.trackingId}
                >
                    {this.props.message.action.text}
                </Button>
            );

        const isClosable = this.props.message.isCloseable !== false;
        const closeButton =
            this.props.message.isCloseable === false ? null : (
                <ButtonIcon
                    onClick={this.dismiss}
                    className="txr-message-dismiss"
                    icon="close"
                    trackingId={this.props.closeButtonTrackingId}
                />
            );
        return (
            <span
                className={`txr-message txr-message--${messageType || 'info'}${
                    isClosable ? ' txr-message--closable' : ''
                } ${
                    this.props.extraClassnames ? this.props.extraClassnames : ''
                }`}
            >
                <MaterialIcon className="txr-message-icon">
                    {iconName}
                </MaterialIcon>
                <span className="txr-message-content">
                    <span className="txr-message-text">
                        {this.props.message.isMarkdown ? (
                            <Markdown text={this.props.message.text} />
                        ) : (
                            <Text text={this.props.message.text} />
                        )}
                    </span>
                    {actionButton}
                </span>
                {closeButton}
            </span>
        );
    }
}
