/* eslint-disable eqeqeq, no-redeclare */

import jQuery from 'jquery';
import '../inputOpener/inputOpener';
import { dateUtil } from '../../js/modules/date';

const $ = jQuery;

if (!window.narrowScreen) {
    $.widget('tlx.periodselecter', {
        // These options will be used as defaults
        options: {
            allPeriods: true,
            daySize: 3, //Deprecated. see _calculateDaySize();
            labelYear: '',
            labelVatTerm: '',
            labelWageTerm: '',
            labelMonth: '',
            labelWeek: '',
            buttonLabelAll: '',
            buttonLabelSoFarThisYear: '',
            buttonLabelSoFarThisMonth: '',
            buttonLabelWholeMonth: '',
            buttonLabelThisDay: '',
            buttonLabelThisWeek: '',
            buttonLabelOk: 'OK',
            buttonTrackingIdAll: '',
            buttonTrackingIdSoFarThisYear: '',
            buttonTrackingIdSoFarThisMonth: '',
            buttonTrackingIdWholeMonth: '',
            buttonTrackingIdThisDay: '',
            buttonTrackingIdThisWeek: '',
            buttonTrackingIdOk: '',
            maxDate: null,
            minDate: null,
            typeMonth: true,
            typeWholeMonth: false,
            typeRangeSlider: true,
            typeVatTerm: false,
            typeWageTerm: false,
            typeWeek: true,
            typeYear: true,
            vatTermPrefix: '',
            vatTermSuffix: '',
            vatTermFullYearText: '',
            vatTerms: [],
        },

        // If new types are added, be aware that the min/max is significant
        periodTypes: {
            range: 0,
            year: 1,
            vatTerm: 2,
            wageTerm: 3,
            month: 4,
            week: 5,
        },

        keyCodes: {
            enter: 13,
            escape: 27,
            left: 37,
            up: 38,
            right: 39,
            down: 40,
        },

        /**
         * http://api.jqueryui.com/jQuery.widget/#method-_getCreateOptions (used to let the element data attrs define widget options)
         */
        _getCreateOptions: function () {
            var options = {};

            $.each(this.element.data(), function (key, value) {
                switch (key) {
                    case 'typeRange':
                        options['typeRangeSlider'] = value;
                        break;
                    case 'startDate':
                    case 'endOfPeriodDate':
                    case 'periodType':
                        options[key + 'Field'] = $('#' + value);
                        break;
                    case 'vatTerms':
                        var vatTerms = [];
                        if (value) {
                            for (var i = 0; i < value.length; i++) {
                                var date = new Date(value[i].time);
                                date.setMinutes(120 + date.getTimezoneOffset());
                                vatTerms.push(date);
                            }
                        }
                        options[key] = vatTerms;
                        break;
                    default:
                        options[key] = value;
                }
            });

            return options;
        },

        _createContentIfNeeded: function () {
            if (this.periodSelecter) return;
            var self = this;

            // AM 2012-04-12: Moved here from _create
            this.periodSelecter = $('<div/>', {
                class: 'ui-widget ui-widget-content ui-corner-all tlx-periodselecter',
                style: 'top:-3000px', // Widget flashes in IE (at least 11) => position it outside view. Ideally we wouldn't have to show it at all, but quite som code depends on calculated widths etc.
            }).appendTo('body');

            this._drawYears = 2; //Number of years in each direction to draw/show.
            this._periodType = parseInt(this.options.periodTypeField.val(), 10);

            this._startDateField = $("<input type='text' />")
                .addClass('tlx-periodselecter-startdate')
                .val(this.options.startDateField.val());
            this._endDateField = $("<input type='text' />")
                .addClass('tlx-periodselecter-enddate')
                .val(this.options.endOfPeriodDateField.val());

            this._startDateField
                .add(this._endDateField)
                .addClass('tlx-green ui-state-default')
                .datepicker({
                    buttonImageOnly: false,
                    showOn: 'focus',
                    onClose: function () {
                        self.periodSelecterContent
                            .find('.ui-selected')
                            .removeClass('ui-selected');
                    },
                })
                .bind(
                    'change.periodselecter',
                    $.proxy(this._onChangeDateRange, this)
                )
                .bind('click.periodselecter', function () {
                    $(this).datepicker('show');
                })
                .appendTo(this.periodSelecter);

            this._styleBoxWidth =
                (this.periodSelecter.outerWidth() -
                    this.periodSelecter.innerWidth()) /
                2;

            this._setMinMaxDates();

            this.periodSelecterContent = $('<div/>')
                .addClass('tlx-periodselecter-content')
                .appendTo(this.periodSelecter)
                .scroll(function () {
                    if (self.periodSelecter.is(':hidden')) {
                        return;
                    }
                    if (
                        (self.periodSelecterContent.scrollLeft() == 0 &&
                            (self._minDate == null ||
                                self._minDate < self._minDateDraw)) ||
                        (self.periodSelecterContent.scrollLeft() >=
                            self.periodSelecterContent.get(0).scrollWidth -
                                self.periodSelecterContent.width() &&
                            (self._maxDate == null ||
                                self._maxDate > self._maxDateDraw))
                    ) {
                        self._extendDrawDates();
                        self._setActivePeriod(self._periodType);
                    }
                    self._positionDateFields();
                });

            this.rangeSlider = $('<div/>')
                .addClass('tlx-slider')
                .prependTo(this.periodSelecter);

            this.labelsLeft = $('<div></div>')
                .css('float', 'left')
                .addClass('tlx-periodselecter-labels')
                .addClass('tlx-periodselecter-labels-left')
                .insertAfter(this.rangeSlider);
            this.labelsRight = this.labelsLeft
                .clone()
                .removeClass('tlx-periodselecter-labels-left')
                .addClass('tlx-periodselecter-labels-right')
                .css('float', 'right')
                .insertAfter(this.rangeSlider);

            this.buttonPane = $('<div/>')
                .addClass('tlx-periodselecter-buttonpane')
                .appendTo(this.periodSelecter);

            this._createPeriodSelecterContent();
            this._createRangeSlider();
            this._createLabels();
            this._createButtons();
            this._setPosition();
            this.periodSelecter.hide();
            this._bindHover();
            this._bindClick();

            $(document).bind('mousedown.periodselecter', function (event) {
                if (self.periodSelecter.is(':hidden')) {
                    return;
                }
                var $target = $(event.target);
                if (
                    $target.hasClass('hasPeriodselecter') ||
                    $target.hasClass('tlx-periodselecter') ||
                    $target.hasClass('tlx-input-opener') ||
                    $target.hasClass('tlx-input-opener-icon') ||
                    $target.hasClass('ui-datepicker') ||
                    $target.parents('.ui-datepicker').length == 1 ||
                    $target.parents('.tlx-periodselecter').length == 1
                ) {
                    return;
                }
                self.close();
            });
            $('body').bind(
                'keydown.periodselecter',
                $.proxy(this._onKeyDown, this)
            );

            // AM 2012-04-12: Moved here from init
            //TODO handle "all periods" if no min/max dates?
            if (
                ((!this._startDateField.val() && !this._minDate) ||
                    this.options.minDate === this._startDateField.val()) &&
                ((!this._endDateField.val() && !this._maxDate) ||
                    this.options.maxDate === this._endDateField.val())
            ) {
                //			this._setActivePeriod(this._periodType);
                this.element.val(this.options.buttonLabelAll);
                return;
            }
            //set selected dates to current date if not set.
            if (!this._startDateField.val()) {
                this._startDateField.val(dateUtil.formatDate(new Date()));
            }
            if (!this._endDateField.val()) {
                this._endDateField.val(dateUtil.formatDate(new Date()));
            }
            this._setActivePeriod(this._periodType);
        },

        /**
         * Handles keyboard navigation / selection
         */
        _onKeyDown: function (event) {
            var self = this;
            if (self.periodSelecter.is(':hidden')) {
                return;
            }
            const $selection = $('.ui-selected', self.periodSelecterContent);

            $.each(self.keyCodes, function (i, val) {
                if (val == event.which) {
                    event.stopPropagation();
                    event.preventDefault();
                    return false; //break;
                }
            });
            if (event.which == self.keyCodes.escape) {
                self.close();
                return;
            } else if (event.which == self.keyCodes.enter) {
                /**
                 * FORV-490: Ignore enter if ctrl or meta is pressed.
                 * We want ctrl+enter/meta+enter to have a specific meaning.
                 */
                if (event.ctrlKey || event.metaKey) {
                    event.preventDefault();
                    return false;
                }
                var $selectedElements = $(
                    '.ui-selected',
                    self.periodSelecterContent
                );
                if ($selectedElements.length == 1) {
                    $selectedElements.trigger('click');
                } else {
                    self._setPersistent();
                }
                self.close();
                return;
            } else if (event.which == self.keyCodes.left) {
                if (
                    $selection.first().prev().not('.ui-state-disabled')
                        .length == 0
                ) {
                    return;
                }
                if (event.shiftKey && self.options.typeRangeSlider) {
                    if (1 < $selection.length) {
                        self._lastSelection == self._lastSelection ||
                            $selection.first();
                        if (
                            self._lastSelection.prev().hasClass('ui-selected')
                        ) {
                            self._lastSelection = self._lastSelection
                                .removeClass('ui-selected')
                                .prev();
                        } else {
                            self._lastSelection = self._lastSelection
                                .prev()
                                .addClass('ui-selected');
                        }
                    } else {
                        self._lastSelection = $selection
                            .prev()
                            .first()
                            .addClass('ui-selected');
                    }
                } else {
                    $selection
                        .removeClass('ui-selected')
                        .prev()
                        .addClass('ui-selected');
                    if (self._lastSelection) {
                        self._lastSelection = self._lastSelection.prev();
                    }
                }
                self._refreshSelectedRange();
                self._setScrollToElement($selection.first().prev());
            } else if (event.which == self.keyCodes.right) {
                if (
                    $selection.last().next().not('.ui-state-disabled').length ==
                    0
                ) {
                    return;
                }
                if (event.shiftKey && self.options.typeRangeSlider) {
                    if (1 < $selection.length) {
                        self._lastSelection == self._lastSelection ||
                            $selection.last();
                        if (
                            self._lastSelection.next().hasClass('ui-selected')
                        ) {
                            self._lastSelection = self._lastSelection
                                .removeClass('ui-selected')
                                .next();
                        } else {
                            self._lastSelection = self._lastSelection
                                .next()
                                .addClass('ui-selected');
                        }
                    } else {
                        self._lastSelection = $selection
                            .next()
                            .last()
                            .addClass('ui-selected');
                    }
                } else {
                    $selection
                        .removeClass('ui-selected')
                        .next()
                        .addClass('ui-selected');
                    if (self._lastSelection) {
                        self._lastSelection = self._lastSelection.next();
                    }
                }
                self._refreshSelectedRange();
                self._setScrollToElement($selection.last().next());
            } else if (event.which == self.keyCodes.up) {
                var startDate = self._parseDate($selection.data('startDate'));
                var $parentContainer = $();

                // Find next valid period type:
                var nextPeriodType = self._periodType;
                while (self.periodTypes.range <= --nextPeriodType) {
                    if (nextPeriodType == self.periodTypes.range) {
                        if (!self.options.typeRangeSlider) {
                            continue;
                        }
                        break;
                    } else if (nextPeriodType == self.periodTypes.month) {
                        if (!self.options.typeMonth) {
                            continue;
                        }
                        $parentContainer = self.months;
                        break;
                    } else if (nextPeriodType == self.periodTypes.wageTerm) {
                        if (!self.options.typeWageTerm) {
                            continue;
                        }
                        $parentContainer = self.wageTerms;
                        break;
                    } else if (nextPeriodType == self.periodTypes.vatTerm) {
                        if (!self.options.typeVatTerm) {
                            continue;
                        }
                        $parentContainer = self.vatTerms;
                        break;
                    } else if (nextPeriodType == self.periodTypes.year) {
                        if (!self.options.typeYear) {
                            continue;
                        }
                        $parentContainer = self.years;
                        break;
                    }
                }
                if (nextPeriodType < self.periodTypes.range) return false; // No valid (next) period type found => do nothing

                self._periodType = nextPeriodType;

                var $foundElement = $();
                $parentContainer.children().each(function () {
                    $foundElement = $(this);
                    var endDate = self._parseDate($(this).data('endDate'));
                    if ($foundElement.hasClass('ui-state-disabled')) {
                        return true; //continue;
                    }
                    if (startDate < endDate) {
                        return false; //break
                    }
                });

                $selection.removeClass('ui-selected');
                self._lastSelection = $foundElement.addClass('ui-selected');

                self._refreshSelectedRange();
            } else if (event.which == self.keyCodes.down) {
                var startDate = self._parseDate(self._startDateField.val()); //self._parseDate($selection.data("startDate"));
                var $parentContainer = $();

                // Find previous valid period type:
                var previousPeriodType = self._periodType;
                while (++previousPeriodType <= self.periodTypes.week) {
                    if (previousPeriodType == self.periodTypes.year) {
                        if (!self.options.typeYear) {
                            continue;
                        }
                        if (!startDate)
                            // happens when "all periods" is selected
                            startDate = new Date();
                        startDate.setDate(1);
                        startDate.setMonth(0);
                        startDate.setHours(0, 0, 0, 0);
                        $parentContainer = self.years;
                        break;
                    } else if (previousPeriodType == self.periodTypes.month) {
                        if (!self.options.typeMonth) {
                            continue;
                        }
                        $parentContainer = self.months;
                        break;
                    } else if (
                        previousPeriodType == self.periodTypes.wageTerm
                    ) {
                        if (!self.options.typeWageTerm) {
                            continue;
                        }
                        $parentContainer = self.wageTerms;
                        break;
                    } else if (previousPeriodType == self.periodTypes.vatTerm) {
                        if (!self.options.typeVatTerm) {
                            continue;
                        }
                        $parentContainer = self.vatTerms;
                        break;
                    } else if (previousPeriodType == self.periodTypes.week) {
                        if (!self.options.typeWeek) {
                            continue;
                        }
                        $parentContainer = self.weeks;
                        break;
                    }
                }
                if (previousPeriodType > self.periodTypes.week) return false; //  // No valid (previous) period type found => do nothing

                self._periodType = previousPeriodType;

                // Special case if selected period includes today.
                var adjust = false;
                if (
                    startDate <= new Date() &&
                    new Date() <= self._parseDate(self._endDateField.val())
                ) {
                    startDate = new Date();
                    adjust = true;
                }
                var $foundElement = $();
                $parentContainer.children().each(function () {
                    $foundElement = $(this);
                    var positionDate;
                    positionDate = self._parseDate($(this).data('startDate'));
                    if ($foundElement.hasClass('ui-state-disabled')) {
                        return true; //continue;
                    }
                    if (
                        startDate <= positionDate ||
                        (adjust &&
                            startDate >= positionDate &&
                            startDate <=
                                self._parseDate($(this).data('endDate')))
                    ) {
                        return false; //break
                        //Implementation is outside loop to fallback to last element in case only one is found.
                    }
                });

                $selection.removeClass('ui-selected');
                self._lastSelection = $foundElement.addClass('ui-selected');
                self._refreshSelectedRange();
                self._setScrollToElement($foundElement);
            }
        },

        /**
         * Handles changes to endDateField and startDateField. (eg. reposition the sliders according to the new range)
         */
        _onChangeDateRange: function (event) {
            var $target = $(event.target);
            var startDateChanged = $target.hasClass(
                'tlx-periodselecter-startdate'
            );
            var date = $target.datepicker('getDate');
            if (!this.options.allPeriods) {
                date = date || new Date();
            }

            $('.mdl-button.nextPeriod').toggleClass(
                'ui-state-disabled',
                date === null
            );
            $('.mdl-button.previousPeriod').toggleClass(
                'ui-state-disabled',
                date === null
            );

            if (date != null) {
                //Set date, so that dates on invalid format is set back to the parsed format (usually parsed as "today")
                $target.datepicker('setDate', date);
                var $otherField = startDateChanged
                    ? this._endDateField
                    : this._startDateField;
                var otherDate = $otherField.datepicker('getDate');
                if (otherDate != null) {
                    if (startDateChanged) {
                        if (date > otherDate) {
                            $otherField.datepicker('setDate', date);
                        }
                    } else {
                        if (date < otherDate) {
                            $otherField.datepicker('setDate', date);
                        }
                    }
                }
            }
            this._positionSelectedOverlay();
            this._positionDateFields();
            //TODO is this the correct place to do this?
            this._setPeriodType(this.periodTypes.range);
        },

        _unselectOtherPeriods: function (ui) {
            var others = this.periodSelecterContent
                .children()
                .not($(ui.selected).parent());
            others.find('.ui-selected').removeClass('ui-selected');
        },

        /**
         * Updates the state of the widget (start/end date and type) based on which periods are selected (as marked by class ui-selected)
         */
        _refreshSelectedRange: function () {
            var periods = this.periodSelecterContent.find('.ui-selected');
            if (periods.length == 0) return;
            var start = periods.first();
            var end = periods.last();

            if (start.get(0) != end.get(0)) {
                start.nextUntil(end).addClass('ui-selected');
            }

            this._setDates(start.data('startDate'), end.data('endDate'));
            //Set periodType to the selected row.
            if (periods.hasClass('tlx-period-month')) {
                this._periodType = this.periodTypes.month;
            } else if (periods.hasClass('tlx-period-week')) {
                this._periodType = this.periodTypes.week;
            } else if (periods.hasClass('tlx-period-wageterm')) {
                this._periodType = this.periodTypes.wageTerm;
            } else if (periods.hasClass('tlx-period-year')) {
                this._periodType = this.periodTypes.year;
            } else if (periods.hasClass('tlx-period-vatterm')) {
                this._periodType = this.periodTypes.vatTerm;
            }
        },

        // Set up the widget
        _create: function () {
            var self = this;
            this.element
                .prop('readOnly', false)
                .addClass('hasPeriodselecter')
                .inputOpener({
                    click: function () {
                        self.open();
                    },
                    closeEvent: 'periodselecterclose',
                    closeFunction: function () {
                        self.close();
                    },
                    showIcon: false,
                });
        },

        _init: function () {},

        // Use the destroy method to clean up any modifications your widget has made to the DOM
        _destroy: function () {
            // In jQuery UI 1.8, you must invoke the destroy method from the base widget
            this.element
                .prop('readOnly', false)
                .removeClass('hasPeriodselecter')
                .unbind('click.periodselecter');

            if (this.periodSelecter) this.periodSelecter.remove();
            $(document).unbind('.periodselecter');
        },

        widget: function () {
            //		this._createContentIfNeeded();
            return this.periodSelecter;
        },

        close: function () {
            if (this.periodSelecter) {
                this.periodSelecter.hide('fade');
            }

            var oldStartDate =
                this._startDateAfterOpened &&
                this._startDateAfterOpened.getTime();
            var oldEndDate =
                this._endDateAfterOpened && this._endDateAfterOpened.getTime();

            // datepicker("getDate") might both return null (for instance "Select all periods").
            var newStartDate =
                this._startDateField &&
                this._startDateField.datepicker('getDate') &&
                this._startDateField.datepicker('getDate').getTime();
            var newEndDate =
                this._endDateField &&
                this._endDateField.datepicker('getDate') &&
                this._endDateField.datepicker('getDate').getTime();
            if (oldStartDate !== newStartDate || oldEndDate !== newEndDate) {
                /*
                 * Don't trigger change if element is disabled. This led to a peculiar epilepsy provoking bug where
                 * filter kept refreshing: The change event triggered a refresh, which initialized period, which tried
                 * to close periode, which again triggered refresh, etc.
                 *
                 * Perhaps strange that period selecter is initialized when lazy content is refreshed?
                 */
                if (!this.element.is(':disabled')) {
                    // To trigger to have .refreshButton change color.
                    $(this.element).closest('.inputItem').trigger('change');
                    this._trigger('change');
                }
                // We need to set this before actually closing, because the date can be altered without
                // the datepicker actually opens again (using arrow buttons to navigate periods).
                this._startDateAfterOpened =
                    this._startDateField.datepicker('getDate');
                this._endDateAfterOpened =
                    this._endDateField.datepicker('getDate');
            }
            this._trigger('close');

            return this;
        },

        /**
         * No arguments: returns [start, end] (as Date objects)
         * Arguments: Sets the start and end date
         */
        dates: function (startDate, endDate) {
            if (arguments.length) {
                startDate = this._parseDate(startDate);
                endDate = this._parseDate(endDate);
                if (
                    startDate != null &&
                    endDate != null &&
                    (this._isOutsideDrawDates(startDate) ||
                        this._isOutsideDrawDates(endDate))
                ) {
                    this._extendDrawDates(startDate, endDate);
                    //TODO use _regeneratePeriodsIfNeeded instead?
                }
                this._setDates(startDate, endDate);
                this._setPersistent();
                this.periodSelecterContent
                    .find('.ui-selected')
                    .removeClass('ui-selected');
                return this;
            }

            if (!this._startDateField || !this._startDateField) {
                return [
                    this._parseDate(
                        $('#' + this.element.data('startDate')).val()
                    ),
                    this._parseDate(
                        $('#' + this.element.data('endOfPeriodDate')).val()
                    ),
                ];
            }

            return [
                this._parseDate(this._startDateField.val()),
                this._parseDate(this._endDateField.val()),
            ];
        },

        next: function () {
            if (!this.periodSelecter) {
                this._createContentIfNeeded();
                this._regeneratePeriodsIfNeeded();
            }
            var $period = $('.ui-selected', this.periodSelecterContent);
            if ($period.length > 0 && $period.next().length == 0) {
                this._extendDrawDates(
                    dateUtil.addYears(
                        dateUtil.parseDate($period.last().data('endDate')),
                        1
                    )
                );
                this._setActivePeriod(this._periodType); // _extendDrawDates clears the selection ...
                $period = $('.ui-selected', this.periodSelecterContent);
            }
            if ($period.length == 1) {
                $period = $period.next();
                if (
                    $period.length == 1 &&
                    !$period.hasClass('ui-state-disabled')
                ) {
                    $period.trigger('click');
                    this._trigger('next');
                    return true;
                }
            } else if (this._periodType == this.periodTypes.range) {
                var startDate = this._startDateField.datepicker('getDate');
                var endDate = this._endDateField.datepicker('getDate');
                if (this._getDaysBetween(endDate, this._maxDateDraw) <= 0) {
                    //Already at the end
                    return false;
                }
                var periodLength = this._getDaysBetween(startDate, endDate);
                startDate.setDate(startDate.getDate() + periodLength + 1);
                endDate.setDate(endDate.getDate() + periodLength + 1);
                var daysFromEndDate = this._getDaysBetween(
                    endDate,
                    this._maxDateDraw
                );
                if (daysFromEndDate < 0) {
                    startDate.setDate(startDate.getDate() + daysFromEndDate);
                    endDate.setDate(endDate.getDate() + daysFromEndDate);
                }

                this.dates(startDate, endDate);

                // Provoke a refresh or update of Refresh button ...
                this.element.change();
                return true;
            } else if (1 < $period.length) {
                for (var i = 0; i < $period.length; i++) {
                    if (
                        $period.last().next().not('.ui-state-disabled')
                            .length == 0
                    ) {
                        if (i == 0) {
                            return false;
                        }
                        break;
                    }
                    $period = $period
                        .removeClass('ui-selected')
                        .next()
                        .addClass('ui-selected');
                }
                if (this._lastSelection) {
                    this._lastSelection = this._lastSelection.next();
                }
                this._refreshSelectedRange();
                this._setPersistent();

                // Provoke a refresh or update of Refresh button ...
                this.element.change();
                return true;
            }
            return false;
        },

        open: function () {
            this._createContentIfNeeded();
            //TODO refresh values from original inputs?
            this._regeneratePeriodsIfNeeded();
            var self = this;
            this.periodSelecter.show('fade', function () {
                self._setScroll();
            });
            this._trigger('open');
            this._setPosition()
                ._setScroll()
                ._positionDateFields()
                ._positionSelectedOverlay();

            this._startDateAfterOpened =
                this._startDateField.datepicker('getDate');
            this._endDateAfterOpened = this._endDateField.datepicker('getDate');

            return this;
        },

        periodType: function (newValue) {
            //TODO is this needed when we have an external field?
            if (arguments.length) {
                this._setPeriodType(newValue);
                this.options.periodTypeField.val(newValue);

                return this;
            }
            return this._periodType;
        },

        previous: function () {
            if (!this.periodSelecter) {
                this._createContentIfNeeded();
                this._regeneratePeriodsIfNeeded();
            }
            var $period = $('.ui-selected', this.periodSelecterContent);
            if ($period.length > 0 && $period.prev().length == 0) {
                this._extendDrawDates(
                    dateUtil.addYears(
                        dateUtil.parseDate($period.first().data('startDate')),
                        -1
                    )
                );
                this._setActivePeriod(this._periodType); // _extendDrawDates clears the selection ...
                $period = $('.ui-selected', this.periodSelecterContent);
            }
            if ($period.length == 1) {
                $period = $period.prev();
                if (
                    $period.length == 1 &&
                    !$period.hasClass('ui-state-disabled')
                ) {
                    $period.trigger('click');
                    return true;
                }
            } else if (this._periodType == this.periodTypes.range) {
                var startDate = this._startDateField.datepicker('getDate');
                var endDate = this._endDateField.datepicker('getDate');
                if (this._getDaysBetween(this._minDateDraw, startDate) <= 0) {
                    //Already at the start
                    return false;
                }
                var periodLength = this._getDaysBetween(startDate, endDate);
                startDate.setDate(startDate.getDate() - periodLength - 1);
                endDate.setDate(endDate.getDate() - periodLength - 1);
                var daysFromStartDate = this._getDaysBetween(
                    this._minDateDraw,
                    startDate
                );
                if (daysFromStartDate < 0) {
                    startDate.setDate(startDate.getDate() - daysFromStartDate);
                    endDate.setDate(endDate.getDate() - daysFromStartDate);
                }

                this.dates(startDate, endDate);

                // Provoke a refresh or update of Refresh button ...
                this.element.change();
                return true;
            } else if (1 < $period.length) {
                for (var i = 0; i < $period.length; i++) {
                    if (
                        $period.first().prev().not('.ui-state-disabled')
                            .length == 0
                    ) {
                        if (i == 0) {
                            return false;
                        }
                        break;
                    }
                    $period = $period
                        .removeClass('ui-selected')
                        .prev()
                        .addClass('ui-selected');
                }
                if (this._lastSelection) {
                    this._lastSelection = this._lastSelection.prev();
                }
                this._refreshSelectedRange();
                this._setPersistent();

                // Provoke a refresh or update of Refresh button ...
                this.element.change();
                return true;
            }
            return false;
        },

        _createSelectable: function (selectableRow) {
            if (this.options.typeRangeSlider) {
                var self = this;
                selectableRow.disableSelection();
                selectableRow.bind('mousedown', function (e) {
                    self._unselectOtherPeriods({ selected: e.target });
                    self._dragStartElement = $(e.target);
                });
                selectableRow.bind('mouseup', function () {
                    delete self._dragStartElement;
                    delete self._currentDragElement;
                });

                selectableRow.bind('mousemove', function (e) {
                    if (self._dragStartElement) {
                        if (
                            self._currentDragElement &&
                            self._currentDragElement.get(0) == e.target
                        ) {
                            return;
                        } else if (
                            $(e.target).parent().get(0) !=
                            self._dragStartElement.parent().get(0)
                        ) {
                            return;
                        }
                        self.periodSelecterContent
                            .find('.ui-selected')
                            .removeClass('ui-selected');
                        self._dragStartElement
                            .add(e.target)
                            .addClass('ui-selected');
                        self._refreshSelectedRange();
                        self._currentDragElement = self._lastSelection = $(
                            e.target
                        );
                    }
                });

                //			selectableRow
                //				.selectable({
                //					distance: 7,
                //					autoRefresh: false,
                //					selected: function (event, ui){self._unselectOtherPeriods(ui)},
                //					stop: function (){self._refreshSelectedRange()}
                //				});
            }
        },

        _bindClick: function () {
            var self = this;
            var selector = '.ui-state-default';
            return this.periodSelecterContent.bind('click', function (event) {
                var elem = $(event.target).closest(selector);
                if (!elem.length || elem.hasClass('ui-state-disabled')) {
                    return;
                }
                //if modifier keys; selection should be triggered instead
                if (self.options.typeRangeSlider) {
                    if (event.ctrlKey === true || event.shiftKey === true) {
                        elem.toggleClass('ui-selected');
                        if (elem.hasClass('ui-selected')) {
                            self._unselectOtherPeriods({ selected: elem });
                        }
                        self._refreshSelectedRange();
                        return;
                    }
                    self.periodSelecterContent
                        .find('.ui-selected')
                        .removeClass('ui-selected');
                }

                var periodType = 0;
                if (elem.hasClass('tlx-period-year')) {
                    periodType = self.periodTypes.year;
                } else if (elem.hasClass('tlx-period-vatterm')) {
                    periodType = self.periodTypes.vatTerm;
                } else if (elem.hasClass('tlx-period-wageterm')) {
                    periodType = self.periodTypes.wageTerm;
                } else if (elem.hasClass('tlx-period-month')) {
                    periodType = self.periodTypes.month;
                } else if (elem.hasClass('tlx-period-week')) {
                    periodType = self.periodTypes.week;
                }
                self._setActivePeriod(periodType, elem);
                self.close();
            });
        },

        /*
         * Bind hover events for dateselecter elements.
         * Done via delegate so the binding only occurs once in the lifetime of the parent div.
         */
        _bindHover: function () {
            var selector = '.ui-state-default';
            return this.periodSelecterContent
                .bind('mouseout', function (event) {
                    var elem = $(event.target).closest(selector);
                    if (!elem.length) {
                        return;
                    }
                    elem.removeClass('ui-state-hover');
                })
                .bind('mouseover', function (event) {
                    var elem = $(event.target).closest(selector);
                    if (elem.hasClass('ui-state-disabled')) {
                        return;
                    }
                    elem.addClass('ui-state-hover');
                });
        },

        _calculateDaySize: function () {
            this._daySize = 3;
        },

        _createButtons: function () {
            var self = this;
            this.buttonPane.empty();
            var standardButtons = $('<span />')
                .addClass('tlx-buttons')
                .appendTo(this.buttonPane);
            var importantButtons = $('<span />')
                .addClass('tlx-buttons tlx-important')
                .appendTo(this.buttonPane);

            if (this.options.typeRangeSlider) {
                $('<button/>', {
                    text: this.options.buttonLabelOk,
                    'data-trackingid': this.options.buttonTrackingIdOk,
                })
                    .bind('click', function () {
                        var $selectedElements = $(
                            '.ui-selected',
                            self.periodSelecterContent
                        );
                        if ($selectedElements.length == 1) {
                            $selectedElements.trigger('click');
                        } else {
                            self._setPersistent();
                        }
                        self.close();
                    })
                    .addClass('tlx-green button-ok')
                    .appendTo(importantButtons);

                if (
                    this.options.allPeriods == true &&
                    this.options.buttonLabelAll.length > 0
                ) {
                    $('<button/>', {
                        text: this.options.buttonLabelAll,
                        'data-trackingid': this.options.buttonTrackingIdAll,
                    })
                        .bind('click', function () {
                            self.dates(
                                self.options.minDate,
                                self.options.maxDate
                            );
                            self.element
                                .val(self.options.buttonLabelAll)
                                .change();
                            self.close();
                        })
                        .addClass('button-all')
                        .appendTo(standardButtons);
                }
                if (this.options.buttonLabelSoFarThisYear.length > 0) {
                    //TODO only show if "this year" is inside min/max-range
                    $('<button/>', {
                        text: this.options.buttonLabelSoFarThisYear,
                        'data-trackingid':
                            this.options.buttonTrackingIdSoFarThisYear,
                    })
                        .bind('click', function () {
                            self.dates(
                                dateUtil.getFirstDayOfYear(new Date()),
                                new Date()
                            );
                            self.element
                                .val(self.options.buttonLabelSoFarThisYear)
                                .change();
                            self.close();
                        })
                        .addClass('button-sofar')
                        .appendTo(standardButtons);
                }
                if (this.options.typeRangeSlider) {
                    $('<button/>', {
                        text: this.options.buttonLabelThisDay,
                        'data-trackingid': this.options.buttonTrackingIdThisDay,
                    })
                        .click(function () {
                            var d = new Date();
                            self.dates(d, d);
                            self.element
                                .val(self._startDateField.val())
                                .change();
                            self.close();
                        })
                        .addClass('button-thisDay')
                        .appendTo(standardButtons);
                }

                if (this.options.typeMonth) {
                    $('<button/>', {
                        text: this.options.buttonLabelSoFarThisMonth,
                        'data-trackingid':
                            this.options.buttonTrackingIdSoFarThisMonth,
                    })
                        .click(function () {
                            var from = dateUtil.getFirstDayOfMonth(new Date());
                            self.dates(from, new Date());
                            self.element
                                .val(self.options.buttonLabelSoFarThisMonth)
                                .change();
                            self.close();
                        })
                        .addClass('button-thisMonth')
                        .appendTo(standardButtons);
                }

                if (this.options.typeWholeMonth) {
                    $('<button/>', {
                        text: this.options.buttonLabelWholeMonth,
                        'data-trackingid':
                            this.options.buttonTrackingIdWholeMonth,
                    })
                        .click(function () {
                            const from = dateUtil.getFirstDayOfMonth(
                                new Date()
                            );
                            const to = dateUtil.addDays(
                                dateUtil.addMonth(from),
                                -1
                            );
                            self.dates(from, to);
                            self.close();
                        })
                        .addClass('button-wholeMonth')
                        .appendTo(standardButtons);
                }
            }

            // Moved out of the previous if(this.options.typeRangeSlider) above as this would never be true otherwise...
            if (this.options.typeWeek && !this.options.typeRangeSlider) {
                $('<button/>', {
                    text: this.options.buttonLabelThisWeek,
                    'data-trackingid': this.options.buttonTrackingIdThisWeek,
                })
                    .click(function () {
                        var from = dateUtil.getFirstDayOfWeek(new Date());
                        var to = dateUtil.addDays(from, 6);
                        var week = $.datepicker.iso8601Week(from);
                        var year = dateUtil.getYear(from);
                        self.dates(from, to);
                        self.element
                            .val(
                                self.options.labelWeek + ' ' + week + ' ' + year
                            )
                            .change();
                        self.close();
                    })
                    .addClass('button-thisWeek')
                    .appendTo(standardButtons);
            }

            // Find the buttons and add the MDL classes
            standardButtons
                .add(importantButtons)
                .find('button')
                .addClass('mdl-button mdl-js-button');

            importantButtons
                .find('button')
                .addClass('mdl-button--raised mdl-button--colored');

            $('.ui-button', standardButtons).removeClass(
                'ui-corner-left ui-corner-right'
            );
        },

        _createPeriodSelecterContent: function () {
            this.periodSelecterContent.empty();

            this.selectedOverlay = $('<div/>')
                .addClass('ui-widget-shadow')
                .appendTo(this.periodSelecterContent);

            this.years = $('<ul/>').appendTo(this.periodSelecterContent);
            this.vatTerms = $('<ul/>').appendTo(this.periodSelecterContent);
            this.wageTerms = $('<ul/>').appendTo(this.periodSelecterContent);
            this.months = $('<ul/>').appendTo(this.periodSelecterContent);
            this.weeks = $('<ul/>').appendTo(this.periodSelecterContent);

            this._calculateDaySize();
            this._generateYears();
            this._generateVatTerms();
            this._generateWageTerms();
            this._generateMonths();
            this._generateWeeks();
        },

        _createRangeSlider: function () {
            var self = this;

            var draggableOptions = {
                containment: 'parent',
                axis: 'x',
                grid: [this._daySize, 0],
                handle: '.tlx-sliderhandle',
                drag: function (event, ui) {
                    var date = self._getDateFromSliderValue(
                        (self.periodSelecterContent.scrollLeft() +
                            ui.position.left) /
                            self._daySize
                    );
                    $(ui.helper)
                        .find('input')
                        .datepicker('setDate', self._restrictMinMax(date))
                        .trigger('change');
                    self.periodSelecterContent
                        .find('.ui-selected')
                        .removeClass('ui-selected');
                    self.periodType(self.periodTypes.range);
                },
            };

            if (!self.options.typeRangeSlider) {
                $(this.rangeSlider).addClass('ui-state-disabled');
            }

            var sliderHandle = $('<div></div>')
                .addClass('tlx-sliderhandle ui-state-default tlx-green')
                .append("<span class='period-slider ion-navicon'/>");

            this._sliderStart = $('<span/>')
                .addClass('tlx-sliderstart')
                .append(sliderHandle)
                .prepend(this._startDateField)
                .append("<div class='tlx-sliderline' />")
                .appendTo(this.rangeSlider)
                .draggable(draggableOptions);

            this._sliderEnd = $('<span/>')
                .addClass('tlx-sliderend')
                .append(sliderHandle.clone())
                .append(this._endDateField)
                .append("<div class='tlx-sliderline' />")
                .appendTo(this.rangeSlider)
                .draggable(draggableOptions);

            this._setRangeSliderDisabled(!this.options.typeRangeSlider);
        },

        _createLabels: function () {
            var labels = this.labelsLeft.add(this.labelsRight).empty();

            var props = {};

            //Bugfix of scroll set
            this.periodSelecterContent.scrollLeft(0);

            if (0 < this.years.children().length) {
                props.text = this.options.labelYear;
                labels.append($('<label/>', props));
            }
            if (0 < this.vatTerms.children().length) {
                props.text = this.options.labelVatTerm;
                labels.append($('<label/>', props));
            }
            if (0 < this.wageTerms.children().length) {
                props.text = this.options.labelWageTerm;
                labels.append($('<label/>', props));
            }
            if (0 < this.months.children().length) {
                props.text = this.options.labelMonth;
                labels.append($('<label/>', props));
            }
            if (0 < this.weeks.children().length) {
                props.text = this.options.labelWeek;
                labels.append($('<label/>', props));
            }

            var sliderStartAlign;

            // Extra 16 pixels due to padding on label container
            sliderStartAlign = '18px';

            labels.width(this._sliderStart.outerWidth(true));
            this._sliderStart.css('margin-left', sliderStartAlign); //set margin to get alignement right
            this._sliderEnd
                .css('margin-left', this._daySize - 2 + 'px')
                .css('margin-right', sliderStartAlign);

            return this._setScroll();
        },

        /**
         * extend viewable dates to at least the optional input dates.
         * If no dates are specified, it just adds this._drawYears years to each side
         */
        _extendDrawDates: function () {
            for (var i = 0; i < arguments.length; i++) {
                if (arguments[i] < this._minDateDraw) {
                    this._minDateDraw = arguments[i];
                } else if (arguments[i] > this._maxDateDraw) {
                    this._maxDateDraw = arguments[i];
                }
            }
            var tempDate = new Date(
                this._minDateDraw.getFullYear() - this._drawYears,
                0,
                1
            );
            this._minDateDraw = this._restrictMinMax(tempDate);
            tempDate = new Date(
                this._maxDateDraw.getFullYear() + this._drawYears,
                11,
                31
            );
            this._maxDateDraw = this._restrictMinMax(tempDate);
            this._createPeriodSelecterContent();
        },

        _generateMonths: function () {
            this.months.empty();
            var disabledButVisible =
                !this.options.typeYear &&
                !this.options.typeMonth &&
                !this.options.typeVatTerm &&
                !this.options.typeWageTerm &&
                this.options.typeWeek;
            if (disabledButVisible || this.options.typeMonth) {
                for (
                    var year = this._minDateDraw.getFullYear();
                    year <= this._maxDateDraw.getFullYear();
                    year++
                ) {
                    for (var i = 0; i < 12; i++) {
                        var numberOfDays = this._getDaysInMonth(year, i);
                        var startDate = new Date(year, i, 1);
                        var endDate = new Date(year, i, numberOfDays);
                        var sdString = dateUtil.formatDate(startDate);
                        var edString = dateUtil.formatDate(endDate);
                        var classes =
                            'tlx-period tlx-period-month ui-state-default';
                        if (
                            disabledButVisible ||
                            (this._minDate &&
                                startDate.getTime() <
                                    this._minDate.getTime()) ||
                            (this._maxDate &&
                                endDate.getTime() > this._maxDate.getTime())
                        ) {
                            //TODO possible improvment by testing if year is disabled outside loop.
                            classes += ' ui-state-disabled';
                        }
                        $('<li/>', {
                            class: classes,
                            text: $.datepicker._defaults.monthNames[i],
                            data: {
                                startDate: sdString,
                                endDate: edString,
                                title:
                                    $.datepicker._defaults.monthNames[i] +
                                    ' ' +
                                    year,
                            },
                        })
                            .width(
                                this._daySize * numberOfDays -
                                    this._styleBoxWidth
                            )
                            .appendTo(this.months);
                    }
                }
            }
            if (this.options.typeMonth) {
                this._createSelectable(this.months);
            }
            return this;
        },

        _generateVatTerms: function () {
            this.vatTerms.empty();
            if (this.options.typeVatTerm) {
                //TODO use viewMIN/MAX directly

                if (this.options.vatTerms) {
                    var vt = new Array();

                    //Remove items outside viewmin/max-date
                    for (var i in this.options.vatTerms) {
                        var time = this.options.vatTerms[i].getTime();
                        if (
                            time >= this._minDateDraw.getTime() &&
                            time <=
                                new Date(
                                    this._maxDateDraw.getFullYear(),
                                    this._maxDateDraw.getMonth(),
                                    this._maxDateDraw.getDate() + 1,
                                    23,
                                    59
                                ).getTime()
                        ) {
                            vt.push(this.options.vatTerms[i]);
                        }
                    }

                    this.options.vatTermPrefix =
                        this.options.vatTermPrefix || '';
                    this.options.vatTermSuffix =
                        this.options.vatTermSuffix || '';
                    var startDate = new Date(this._minDateDraw.getTime());

                    vt = vt.concat(
                        new Date(
                            this._maxDateDraw.getFullYear(),
                            this._maxDateDraw.getMonth(),
                            this._maxDateDraw.getDate() + 1
                        )
                    );
                    var firstTermIsDisabled = true;
                    var lastTermIsDisabled = true;
                    var vatTermThisYear = 1;
                    for (i in vt) {
                        var endDate = new Date(
                            vt[i].getFullYear(),
                            vt[i].getMonth(),
                            vt[i].getDate() - 1
                        );
                        var startDateString = dateUtil.formatDate(startDate);
                        var endDateString = dateUtil.formatDate(endDate);
                        var width =
                            this._daySize *
                                this._getDaysBetween(
                                    startDate,
                                    new Date(vt[i].setHours(0))
                                ) -
                            this._styleBoxWidth;

                        //endDate will be smaller then startDate if first/last term is equal to min/max-dates.
                        if (endDate.getTime() <= startDate.getTime()) {
                            if (i == 0) {
                                firstTermIsDisabled = false;
                            } else {
                                lastTermIsDisabled = false;
                            }
                            //For next iteration
                            startDate = vt[i];
                            continue;
                        } else if (
                            endDate.getTime() < this._minDateDraw.getTime()
                        ) {
                            startDate = vt[i];
                            continue;
                        }

                        var disabledVatTerm =
                            (this._minDate &&
                                startDate.getTime() <
                                    this._minDate.getTime()) ||
                            (this._maxDate &&
                                endDate.getTime() > this._maxDate.getTime());
                        var classes =
                            'tlx-period tlx-period-vatterm ui-state-default';
                        if (disabledVatTerm) {
                            classes += ' ui-state-disabled';
                        }

                        var isLastTermInYear =
                            endDate.getFullYear() < vt[i].getFullYear();

                        var displayText;
                        if (isLastTermInYear && vatTermThisYear == 1) {
                            displayText = this.options.vatTermFullYearText;
                        } else {
                            displayText =
                                this.options.vatTermPrefix +
                                vatTermThisYear +
                                this.options.vatTermSuffix;
                        }
                        $('<li/>', {
                            class: classes,
                            text: displayText,
                            data: {
                                startDate: startDateString,
                                endDate: endDateString,
                                title:
                                    displayText + ' ' + startDate.getFullYear(),
                            },
                        })
                            .width(width)
                            .appendTo(this.vatTerms);

                        //For next iteration
                        startDate = vt[i];
                        vatTermThisYear++;
                        if (isLastTermInYear) {
                            vatTermThisYear = 1;
                        }
                    }
                    if (firstTermIsDisabled) {
                        this.vatTerms
                            .find('.tlx-period-vatterm')
                            .first()
                            .text('-')
                            .addClass('ui-state-disabled')
                            .removeData('title');
                    }
                    if (lastTermIsDisabled) {
                        this.vatTerms
                            .find('.tlx-period-vatterm')
                            .last()
                            .text('-')
                            .addClass('ui-state-disabled')
                            .removeData('title');
                    }
                }

                this._createSelectable(this.vatTerms);
            }
            return this;
        },

        _generateWageTerms: function () {
            this.wageTerms.empty();
            if (this.options.typeWageTerm) {
                for (
                    var year = this._minDateDraw.getFullYear();
                    year <= this._maxDateDraw.getFullYear();
                    year++
                ) {
                    for (var i = 0; i < 12; i = i + 2) {
                        var numberOfDays =
                            this._getDaysInMonth(year, i) +
                            this._getDaysInMonth(year, i + 1);
                        var startDate = new Date(year, i, 1);
                        var endDate = new Date(year, i, numberOfDays);
                        var sdString = dateUtil.formatDate(startDate);
                        var edString = dateUtil.formatDate(endDate);
                        var classes =
                            'tlx-period tlx-period-wageterm ui-state-default';
                        if (
                            (this._minDate &&
                                startDate.getTime() <
                                    this._minDate.getTime()) ||
                            (this._maxDate &&
                                endDate.getTime() > this._maxDate.getTime())
                        ) {
                            //TODO possible improvment by testing if year is disabled outside loop.
                            classes += ' ui-state-disabled';
                        }
                        $('<li/>', {
                            class: classes,
                            text:
                                $.datepicker._defaults.monthNames[i] +
                                ' - ' +
                                $.datepicker._defaults.monthNames[i + 1],
                            data: {
                                startDate: sdString,
                                endDate: edString,
                                title:
                                    $.datepicker._defaults.monthNames[i] +
                                    ' - ' +
                                    $.datepicker._defaults.monthNames[i + 1] +
                                    ' ' +
                                    year,
                            },
                        })
                            .width(
                                this._daySize * numberOfDays -
                                    this._styleBoxWidth
                            )
                            .appendTo(this.wageTerms);
                    }
                }
                this._createSelectable(this.wageTerms);
            }
            return this;
        },

        _generateWeeks: function () {
            this.weeks.empty();
            if (this.options.typeWeek) {
                var dateHelper = new Date(this._minDateDraw.getTime());
                dateHelper.setDate(2 - (dateHelper.getDay() || 7));

                var wks = [];
                var week = $.datepicker.iso8601Week(dateHelper);
                var year = dateHelper.getFullYear();
                while (dateHelper.getTime() <= this._maxDateDraw.getTime()) {
                    var startDate = dateUtil.formatDate(dateHelper);
                    var disabledWeek =
                        this._minDate &&
                        dateHelper.getTime() < this._minDate.getTime();
                    dateHelper.setDate(6 + dateHelper.getDate());

                    //handle week 1 that starts in previous year
                    if (week == 1) {
                        year = dateHelper.getFullYear();
                    }
                    disabledWeek =
                        disabledWeek ||
                        (this._maxDate &&
                            dateHelper.getTime() > this._maxDate.getTime());
                    var endDate = dateUtil.formatDate(dateHelper);
                    var width = this._daySize * 7 - this._styleBoxWidth;

                    var classes = 'tlx-period tlx-period-week ui-state-default';
                    if (disabledWeek) {
                        classes += ' ui-state-disabled';
                    }

                    wks.push("<li class='");
                    wks.push(classes);
                    wks.push("' data-start-date='");
                    wks.push(startDate);
                    wks.push("' data-end-date='");
                    wks.push(endDate);
                    wks.push("' data-title='");
                    wks.push(this.options.labelWeek + ' ' + week + ' ' + year);
                    wks.push("' style='width: ");
                    wks.push(width + "px;' >");
                    wks.push(week);
                    wks.push('</li>');

                    dateHelper = dateUtil.addDays(dateHelper, 1);
                    if (week >= 52) {
                        week = $.datepicker.iso8601Week(dateHelper);
                        year = dateHelper.getFullYear();
                    } else {
                        week++;
                    }
                }
                this.weeks.html(wks.join(''));
                if (this._minDateDraw.getDay() != 1) {
                    //if first day of year is not monday
                    $('li', this.weeks)
                        .first()
                        .width(
                            (8 - (this._minDateDraw.getDay() || 7)) *
                                this._daySize -
                                this._styleBoxWidth
                        )
                        .text('-');
                }
                if (this._maxDateDraw.getDay() != 0) {
                    //if last day of year is not sunday
                    $('li', this.weeks)
                        .last()
                        .width(
                            (this._maxDateDraw.getDay() || 7) * this._daySize -
                                this._styleBoxWidth
                        )
                        .text('-');
                }
                this._createSelectable(this.weeks);
            }
            return this;
        },

        _generateYears: function () {
            this.years.empty();
            for (
                var year = this._minDateDraw.getFullYear();
                year <= this._maxDateDraw.getFullYear();
                year++
            ) {
                var startDate = new Date(
                    year,
                    this._minDateDraw.getMonth(),
                    this._minDateDraw.getDate()
                );
                var endDate = new Date(
                    year,
                    this._maxDateDraw.getMonth(),
                    this._maxDateDraw.getDate()
                );
                var sdString = dateUtil.formatDate(startDate);
                var edString = dateUtil.formatDate(endDate);

                var yearDisabled =
                    !this.options.typeYear ||
                    (this._minDate &&
                        startDate.getTime() < this._minDate.getTime()) ||
                    (this._maxDate &&
                        endDate.getTime() > this._maxDate.getTime());
                var classes = 'tlx-period tlx-period-year ui-state-default';
                if (yearDisabled) {
                    classes += ' ui-state-disabled';
                }
                $('<li/>', {
                    class: classes,
                    text: year,
                    data: {
                        startDate: sdString,
                        endDate: edString,
                        title: year,
                    },
                })
                    .width(
                        this._daySize * (337 + this._getDaysInMonth(year, 1)) -
                            this._styleBoxWidth
                    )
                    .appendTo(this.years);
            }
            if (this.options.typeYear) {
                this._createSelectable(this.years);
            }
            return this;
        },

        _getDateFromSliderValue: function (value) {
            var dateTimestamp = value * 86400000 + this._minDateDraw.getTime();
            return new Date(dateTimestamp);
        },

        /* There is a copy of this in mobile version. */
        _getDaysBetween: function (startDate, endDate) {
            return Math.round(
                (endDate.getTime() - startDate.getTime()) / 86400000
            );
        },

        /* Find the number of days in a given month.
         * NB! month is zero-based */
        _getDaysInMonth: function (year, month) {
            return 32 - new Date(year, month, 32).getDate();
        },

        /* Checks if date is outside draw area (view). */
        _isOutsideDrawDates: function (date) {
            return date < this._minDateDraw || this._maxDateDraw < date;
        },

        /* Parses date from string to Date object */
        _parseDate: function (date) {
            if (typeof date != 'string') {
                return date;
            }
            return $.datepicker.parseDate(
                $.datepicker._defaults.dateFormat,
                date
            );
        },

        _positionDateFields: function () {
            var scroll = this.periodSelecterContent.scrollLeft();
            var periodWidth = this.periodSelecterContent.width();
            //StartDate
            var date = this._startDateField.datepicker('getDate');
            if (!date) {
                this._sliderStart.css('left', 0);
            } else {
                var daysFromStart = this._getDaysBetween(
                    this._minDateDraw,
                    date
                );
                var position = daysFromStart * this._daySize - scroll;
                position = Math.max(position, 0);
                position = Math.min(position, periodWidth);
                this._sliderStart.css('left', position);
            }

            //End Date
            date = this._endDateField.datepicker('getDate');
            if (!date) {
                this._sliderEnd.css('left', periodWidth);
            } else {
                daysFromStart = this._getDaysBetween(this._minDateDraw, date);
                position = daysFromStart * this._daySize - scroll;
                position = Math.max(position, 0);
                position = Math.min(position, periodWidth);
                this._sliderEnd.css('left', position);
            }

            return this;
        },

        _positionSelectedOverlay: function () {
            var startDate =
                this._startDateField.datepicker('getDate') || this._minDateDraw;
            var endDate =
                this._endDateField.datepicker('getDate') || this._maxDateDraw;
            var daysFromStartDate = this._getDaysBetween(
                this._minDateDraw,
                startDate
            );
            var periodLength = this._getDaysBetween(startDate, endDate);
            var position = daysFromStartDate * this._daySize;

            var width = this._daySize - 2 + periodLength * this._daySize;
            this.selectedOverlay.css('left', position).width(width);
            return this;
        },

        _regeneratePeriodsIfNeeded: function () {
            //TODO dates as input? reuse more places?
            var startDate = this._startDateField.datepicker('getDate');
            var endDate = this._endDateField.datepicker('getDate');
            if (startDate == null || endDate == null) {
                return;
            }
            if (
                startDate <= this._minDateDraw ||
                this._maxDateDraw <= endDate
            ) {
                this._minDateDraw = startDate;
                this._maxDateDraw = endDate;
                this._extendDrawDates();
                //reset position;
                this._setActivePeriod(this._periodType);
            }
        },

        /* Ensure a date is within any min/max bounds. */
        _restrictMinMax: function (date) {
            var newDate =
                this._minDate && date != null && date < this._minDate
                    ? this._minDate
                    : date;
            newDate =
                this._maxDate && newDate > this._maxDate
                    ? this._maxDate
                    : newDate;
            return newDate;
        },

        /* Sets correct month/period/vat-term/week. */
        _setActivePeriod: function (periodType, $selectedPeriod) {
            //TODO merge with function dates?
            if (!$selectedPeriod) {
                var $selector = $();
                switch (periodType) {
                    case this.periodTypes.year:
                        $selector = this.years;
                        break;
                    case this.periodTypes.vatTerm:
                        $selector = this.vatTerms;
                        break;
                    case this.periodTypes.wageTerm:
                        $selector = this.wageTerms;
                        break;
                    case this.periodTypes.month:
                        $selector = this.months;
                        break;
                    case this.periodTypes.week:
                        $selector = this.weeks;
                        break;
                }
                var self = this;

                //Select (ie add class to) current period
                var startFound = false;
                var $inBetweenElements = $();
                $selector.find('li').each(function () {
                    if (
                        startFound ||
                        $(this).data('startDate') == self._startDateField.val()
                    ) {
                        if (
                            $(this).data('endDate') != self._endDateField.val()
                        ) {
                            startFound = true;
                            $inBetweenElements = $inBetweenElements.add(
                                $(this)
                            );
                            return true;
                        }

                        $selectedPeriod = $(this);
                        if (startFound) {
                            $selectedPeriod =
                                $selectedPeriod.add($inBetweenElements);
                        }
                        return false;
                    }
                });
            }
            if ($selectedPeriod) {
                this.periodSelecterContent
                    .find('.ui-state-active')
                    .removeClass('ui-state-active ui-selected');
                this.dates(
                    $selectedPeriod.first().data('startDate'),
                    $selectedPeriod.last().data('endDate')
                );
                $selectedPeriod.addClass('ui-selected');
                if ($selectedPeriod.length == 1) {
                    $selectedPeriod.addClass('ui-state-active');
                    this.element.val($selectedPeriod.data('title'));
                }

                //Set periodType back to original, as dates will change it to range/slider
                //TODO check if this is needed or can be rewrited
                this.periodType(periodType);
            } else {
                //If type range OR no match for periodType, set range.
                this.dates(
                    this._startDateField.val(),
                    this._endDateField.val()
                );
            }
        },

        _setEndDate: function (endDate) {
            this._endDateField.datepicker(
                'setDate',
                this._restrictMinMax(endDate)
            );
            //TODO move the trigger to _setDates after both have been altered?
            this._endDateField.trigger('change');
        },

        _setDates: function (startDate, endDate) {
            this._setStartDate(startDate);
            this._setEndDate(endDate);

            this._trigger('change');
            return this;
        },

        _setMinMaxDates: function () {
            this._minDate = this._parseDate(this.options.minDate || '');
            this._maxDate = this._parseDate(this.options.maxDate || '');

            // get startDate or today;
            var date =
                this._parseDate(this.options.startDateField.val() || '') ||
                new Date();
            // set drawdates +/- 5 years from date
            this._minDateDraw = dateUtil.getFirstDayOfYear(
                this._restrictMinMax(
                    new Date(date.getFullYear() - this._drawYears, 0, 1)
                )
            );

            // get endDate or today;
            date =
                this._parseDate(
                    this.options.endOfPeriodDateField.val() || ''
                ) || new Date();
            // set drawdates +/- 5 years from date
            this._maxDateDraw = dateUtil.getLastDayOfYear(
                this._restrictMinMax(
                    new Date(date.getFullYear() + this._drawYears, 11, 31)
                )
            );

            var self = this;
            this._startDateField.add(this._endDateField).datepicker('option', {
                minDate: self._minDate,
                maxDate: self._maxDate,
            });
        },

        // Use the _setOption method to respond to changes to options
        _setOption: function (key, value) {
            this._super(key, value);
            switch (key) {
                case 'typeMonth':
                    this._calculateDaySize();
                    this._generateMonths();
                    this._createLabels();
                    break;
                case 'typeYear':
                    this._generateYears();
                    this._createButtons();
                    this._createLabels();
                    break;
                case 'typeVatTerm':
                case 'vatTermPrefix':
                case 'vatTermSuffix':
                case 'vatTerms':
                    this._generateVatTerms();
                    this._createLabels();
                    break;
                case 'typeWageTerms':
                    this._calculateDaySize();
                    this._generateWageTerms();
                    this._createLabels();
                    break;
                case 'typeWeek':
                    this._calculateDaySize();
                    this._generateWeeks();
                    this._createLabels();
                    break;
                case 'typeRangeSlider':
                    this._setRangeSliderDisabled(!value);
                    break;
                case 'maxDate':
                case 'minDate':
                    this._setMinMaxDates();
                    this._createPeriodSelecterContent();
                    this._startDateField.trigger('change');
                    this._endDateField.trigger('change');
                    this._setScroll();
                    break;
                case 'daySize':
                    this._createPeriodSelecterContent();
                    this._startDateField.trigger('change');
                    this._endDateField.trigger('change');
                    this._setScroll();
                    break;
                case 'labelYear':
                case 'labelVatTerm':
                case 'labelMonth':
                    this._createLabels();
                    break;
                case 'buttonLabelAll':
                case 'buttonLabelSoFarThisYear':
                case 'buttonLabelOk':
                    this._createButtons();
                    break;
            }
        },

        _setPeriodType: function (newPeriod) {
            this._periodType = newPeriod;
            switch (newPeriod) {
                case this.periodTypes.range:
                    $(
                        '.ui-state-active',
                        this.periodSelecterContent
                    ).removeClass('ui-state-active');
                    break;
            }
        },

        /**
         * Persists the widget state to the form input elements. (and update the label... ?)
         */
        _setPersistent: function () {
            this.options.startDateField.val(this._startDateField.val());
            this.options.endOfPeriodDateField.val(this._endDateField.val());
            this.options.periodTypeField.val(this.periodType());
            this.element.val(
                this._startDateField.val() + ' - ' + this._endDateField.val()
            );
            this._trigger('change');
        },

        _setPosition: function () {
            //TODO implement none fit?
            this.periodSelecter.position({
                of: this.element,
                my: 'left top',
                at: 'left bottom',
                collision: 'fit flip',
                offset: '0 1',
            });
            return this;
        },

        _setRangeSliderDisabled: function (disable) {
            this.rangeSlider.tlxSetDisabled(disable);
            if (disable) {
                this._startDateField
                    .add(this._endDateField)
                    .removeClass('tlx-green')
                    .datepicker('disable');
            } else {
                this._startDateField
                    .add(this._endDateField)
                    .addClass('tlx-green')
                    .datepicker('enable');
            }
        },

        _setScroll: function () {
            var startDate = this._startDateField.datepicker('getDate');
            var endDate = this._endDateField.datepicker('getDate');
            var startDays = this._getDaysBetween(
                this._minDateDraw,
                startDate ? startDate : new Date()
            );
            var endDays = this._getDaysBetween(
                this._minDateDraw,
                endDate ? endDate : new Date()
            );
            var outerWidth = ((startDays + endDays) / 2) * this._daySize;
            var scroll = outerWidth - this.periodSelecterContent.width() / 2;
            this.periodSelecterContent.scrollLeft(scroll);
            return this;
        },

        _setScrollToElement: function ($element) {
            var contentWidth = this.periodSelecterContent.width();

            const position = $element.position();
            if (position === undefined) {
                return;
            }

            if (
                $element.width() + position.left < 0 ||
                contentWidth < position.left
            ) {
                this._setScroll();
            }
        },

        _setStartDate: function (startDate) {
            this._startDateField.datepicker(
                'setDate',
                this._restrictMinMax(startDate)
            );
            this._startDateField.trigger('change');
        },
    });
}
