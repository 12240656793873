import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
    DistributionKey,
    HelperMessage,
} from '@Page/DistributionKeys/models/DistributionKeys';
import { getMessage } from '../../../../../js/modules/getMessage';
import { v4 as uuidv4 } from 'uuid';
import { format } from '../../../../../js/modules/format';

export const useDistributionKeyDialog = (
    distributionKeys?: DistributionKey[],
    selectedKey?: DistributionKey
) => {
    const [key, setKey] = useState<DistributionKey>(
        selectedKey || {
            distributionKeyBlade: {
                distributionKeyBits: [
                    { tempId: uuidv4() },
                    { tempId: uuidv4() },
                ],
            },
        }
    );
    const [validationMessage, setValidationMessage] = useState<HelperMessage>(
        {}
    );

    useEffect(() => {
        if (selectedKey) {
            setKey(
                {
                    ...selectedKey,
                    distributionKeyBlade: {
                        distributionKeyBits:
                            selectedKey.distributionKeyBlade.distributionKeyBits.map(
                                (bit) => {
                                    return { ...bit, tempId: uuidv4() };
                                }
                            ),
                    },
                } || {
                    distributionKeyBlade: {
                        distributionKeyBits: [
                            { tempId: uuidv4() },
                            { tempId: uuidv4() },
                        ],
                    },
                }
            );
        }
    }, [selectedKey]);

    useEffect(() => {
        validationMessageBuilder(setValidationMessage, key, distributionKeys);
    }, [distributionKeys, key, selectedKey]);

    return {
        selectedKey: key,
        setSelectedKey: setKey,
        validationMessage,
    };
};

const validationMessageBuilder = (
    setValidationMessage: Dispatch<SetStateAction<HelperMessage>>,
    distributionKey?: DistributionKey,
    distributionKeys?: DistributionKey[]
) => {
    let validationMessages: HelperMessage = {};

    if (!distributionKey || !distributionKey.distributionKeyBlade) {
        setValidationMessage({});
        return;
    }

    if (!distributionKey.name || distributionKey.name.trim().length === 0) {
        validationMessages = {
            ...validationMessages,
            noNameValidation: getMessage(
                'text_validation_distribution_key_no_name'
            ),
        };
    }

    const sameNameKey = distributionKeys?.find(
        (k) => k.name?.trim() === distributionKey.name?.trim()
    );
    if (sameNameKey && !distributionKey.id) {
        validationMessages = {
            ...validationMessages,
            nameExistsValidation: getMessage(
                'text_validation_distribution_key_name_exists',
                distributionKey.name ? distributionKey.name : ''
            ),
        };
    }

    const distributionKeyBits =
        distributionKey.distributionKeyBlade.distributionKeyBits;

    const departments = distributionKeyBits.map((bit) => {
        return {
            departmentId: bit.departmentId,
            departmentName: bit.departmentName,
        };
    });

    const emptyDepartments = departments
        .map((department, index) => {
            if (!department.departmentId || !department.departmentName) {
                return index + 1;
            }
            return 0;
        })
        .filter((index) => index > 0);

    if (emptyDepartments.length > 0) {
        validationMessages = {
            ...validationMessages,
            emptyDepartmentValidation: getMessage(
                'text_validation_distribution_key_null_department',
                emptyDepartments.join(', ')
            ),
        };
    }

    const uniqueDepartments = new Set();
    const nonUniqueDepartments: number[] = [];

    departments.forEach((department, index) => {
        if (
            department.departmentId &&
            uniqueDepartments.has(department.departmentId)
        ) {
            nonUniqueDepartments.push(index + 1);
        }
        uniqueDepartments.add(department.departmentId);
    });

    if (nonUniqueDepartments.length > 0) {
        validationMessages = {
            ...validationMessages,
            sameDepartmentValidation: getMessage(
                'text_validation_distribution_key_same_department',
                nonUniqueDepartments.join(', ')
            ),
        };
    }

    const totalPercentage = distributionKeyBits
        .filter((bit) => bit.percentage && bit.percentage > 0)
        .reduce(
            (accumulator, bit) =>
                accumulator + (bit.percentage ? bit.percentage : 0),
            0
        );

    if (totalPercentage !== 100) {
        const formattedPercentage = format.withKey(
            totalPercentage.toString(),
            '#,###.##'
        );
        validationMessages = {
            ...validationMessages,
            percentageValidation: getMessage(
                'text_validation_distribution_key_hundred_percent',
                formattedPercentage.length === 0
                    ? '0'
                    : format.withKey(totalPercentage.toString(), '#,###.##')
            ),
        };
    }

    setValidationMessage(validationMessages);
};
