import { Button, Icon, Input } from '@tlx/atlas';
import React, { useEffect, useState } from 'react';
import './OrderDate.css';
import { LoadingSpinner } from '@Component/Loading';
import { dateUtil } from '../../../../../js/modules/date';

type OrderDateForJSPProps = {
    isEditMode: boolean;
    setIsEditMode: (isEditMode: boolean) => void;
};

export function OrderDateForJSP({
    isEditMode,
    setIsEditMode,
}: OrderDateForJSPProps) {
    const [orderDate, setOrderDate] = useState<string | undefined>();

    useEffect(() => {
        // Set initial value
        const orderDateInput =
            document.querySelector<HTMLInputElement>('.js-orderDateInput');
        const initialValue = orderDateInput?.value;
        setOrderDate(initialValue ?? '');
    }, []);

    useEffect(() => {
        // Add event listener to catch changes from JSP
        const orderDateInput =
            document.querySelector<HTMLInputElement>('.js-orderDateInput');
        if (orderDateInput) {
            orderDateInput.addEventListener('change', (event: any) => {
                setOrderDate(event.target.value);
            });
        }
    }, []);

    function handleChange(value: string) {
        // Order date jsp field is hidden, so does not trigger change by itself
        const form: any =
            document.getElementsByClassName('js-createOrderForm')[0];
        form.dataset.hasChanged = true;

        if (value !== orderDate) {
            const element =
                document.querySelector<HTMLInputElement>('.js-orderDateInput');
            if (element) {
                element.value = value.toString();
            }
            setOrderDate(value);
        }
    }

    return (
        <OrderDate
            value={orderDate}
            handleChange={handleChange}
            isEditMode={isEditMode}
            setIsEditMode={setIsEditMode}
        />
    );
}

type OrderDateProps = {
    value?: string;
    handleChange: (value: string) => void;
    isEditMode: boolean;
    setIsEditMode: (isEditMode: boolean) => void;
};

export function OrderDate({
    value,
    handleChange,
    isEditMode,
    setIsEditMode,
}: OrderDateProps) {
    const [validationMessage, setValidationMessage] = useState<string>('');

    if (value === undefined) {
        return <LoadingSpinner />;
    }

    function onChange(event: React.ChangeEvent<HTMLInputElement>) {
        handleChange(event.target.value);
        validate(event.target.value);
    }

    function validate(value: string) {
        if (dateUtil.isDate(value ?? '', 'yyyy-MM-dd')) {
            setValidationMessage('');
        } else {
            setValidationMessage(getMessage('validation_date_format'));
        }
    }

    return (
        <div>
            <div className="atl-pb-8 atl-flex atl-justify-between atl-text-blue-100 atl-text-base">
                <div className="atl-flex atl-items-center ">
                    <Icon className="atl-mr-8">calendar_today</Icon>
                    {getMessage('text_order_date')}
                </div>
            </div>
            {!isEditMode && (
                <div className="atl-text-left atl-text-base">{value}</div>
            )}
            {isEditMode && (
                <div>
                    <div className="atl-flex">
                        <Input
                            type="date"
                            data-testid="order-date-input"
                            className="kb-order-date__input"
                            value={value}
                            onChange={onChange}
                            autoFocus={true}
                            onClick={(event) => event.stopPropagation()}
                        />
                        <Button
                            className="kb-order-date__check-button atl-ml-8"
                            variant="tertiary"
                            data-testid="order-date-check-button"
                            onClick={(event) => {
                                setIsEditMode(false);
                                event.stopPropagation();
                            }}
                        >
                            <Icon className="atl-mr-8">check</Icon>
                        </Button>
                    </div>
                    <div className="atl-text-red-100">{validationMessage}</div>
                </div>
            )}
        </div>
    );
}
