import { useAccountHelpDropdownData } from '@Component/AccountHelp/useAccountHelpDropdownData';
import * as React from 'react';
import {
    AccountHelpDropdown,
    AccountHelpDropdownProps,
} from '@Component/AccountHelp/AccountHelpDropdown';
import './AccountJSPDropdown.css';
import {
    DropdownOpenerIcon,
    DropdownOpenerProps,
    Group,
    Label,
    useDropdownOpener,
} from '@tlx/atlas';
import classNames from 'classnames';

type AccountJSPDropdownProps = Omit<AccountHelpDropdownProps, 'useDataState'>;
export function AccountJSPDropdown(props: AccountJSPDropdownProps) {
    return (
        <Group className={'krr-account-help-jsp-dropdown__group inputItem'}>
            <Label className={'krr-account-help-jsp__label'}>
                {props.label}
                {props.required && ' *'}
            </Label>
            <AccountHelpDropdown
                {...props}
                useDataState={useAccountHelpDropdownData}
                className={props.className}
            >
                <JSPStyleDropdownOpener
                    readOnlyStyle={props.readOnly}
                    disabledStyle={props.disabled}
                    data-testid={props.dataTrackingId}
                />
            </AccountHelpDropdown>
        </Group>
    );
}

function JSPStyleDropdownOpener({
    className,
    ...props
}: DropdownOpenerProps & { readOnlyStyle?: boolean; disabledStyle?: boolean }) {
    const { buttonProps, buttonRef, displayName } = useDropdownOpener();

    return (
        <button
            {...buttonProps}
            ref={buttonRef}
            className={classNames(
                'krr-account-help-jsp-dropdown__opener atl-text-base',
                className,
                {
                    'krr-account-help-jsp-dropdown__opener--readonly':
                        props.readOnlyStyle,
                    'krr-account-help-jsp-dropdown__opener--disabled':
                        props.disabledStyle,
                }
            )}
            aria-label={props['aria-label']}
        >
            <span
                className={'krr-account-help-jsp-dropdown__opener-display-name'}
            >
                {displayName}
            </span>
            {!buttonProps.disabled && <DropdownOpenerIcon />}
        </button>
    );
}
