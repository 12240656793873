import { ReactNode } from 'react';
import './EmptySate.css';
import { getSvgIcon } from '@Page/LogisticsV2/utils/svgIcons';
import { Button } from '@tlx/atlas';
import classNames from 'classnames';

export function EmptyState({
    title,
    description,
    svgIcon,
    iconSize,
    children,
    className,
}: {
    title: string;
    description?: string;
    svgIcon?: string;
    iconSize?: number;
    children?: ReactNode;
    className?: string;
}) {
    return (
        <div className="goldshark-empty-state atl-bg-white">
            {svgIcon && getSvgIcon(svgIcon, iconSize)}
            <div className="goldshark-empty-state__content atl-flex-col">
                <div
                    className={classNames(
                        'atl-text-lg atl-font-bold',
                        className
                    )}
                >
                    {title}
                </div>
                {description && (
                    <div className="atl-font-normal atl-text-grey-80">
                        {description}
                    </div>
                )}
                {children}
            </div>
        </div>
    );
}

export function NoResultsEmptyState({
    colSpan,
    onClick,
}: {
    colSpan: number;
    onClick: () => void;
}) {
    return (
        <tr className="atl-tr">
            <td className="atl-td atl-bg-white" colSpan={colSpan}>
                <EmptyState
                    title={getMessage('text_no_match')}
                    description={getMessage('text_nothing_found_description')}
                    svgIcon={'search'}
                    className={'atl-text-xl'}
                >
                    <div>
                        <Button
                            data-testid="resetFilter"
                            variant="secondary"
                            onClick={onClick}
                        >
                            {getMessage('text_reset_filter')}
                        </Button>
                    </div>
                </EmptyState>
            </td>
        </tr>
    );
}
