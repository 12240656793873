import { ModelAPIV1 } from '@General/types/Api';
import { Option } from '../../types';
import { JSPDropdownFetcher } from '../JSPDropdownFetcher';
import { ApiResultMapperNoUndefinedResult } from '@General/Provider';
import * as React from 'react';
import { notUndefined } from '@General/Helpers';

interface AssetEntry extends ModelAPIV1 {
    readonly displayName: string;
}

const assetMapper: ApiResultMapperNoUndefinedResult<AssetEntry> = (item) => ({
    value: item.id + '',
    displayName: item.displayName,
    item,
});

class AssetFetcher extends JSPDropdownFetcher<AssetEntry> {
    asyncJsonrpcGetter: Function;

    constructor(scope: HTMLDivElement, queryAttributes: Record<string, any>) {
        super(scope, queryAttributes, assetMapper);
        this.asyncJsonrpcGetter = asyncrpc.Asset.get;
    }

    getParams(): ObjectIndex {
        return {};
    }

    async fetchList(params: ObjectIndex): Promise<Option<AssetEntry>[]> {
        return new Promise((resolve, reject) => {
            try {
                return jsonrpc.Asset.searchForAssets(
                    (result: AssetEntry[] | null) => {
                        const mapped = (result ?? [])
                            .filter(notUndefined)
                            .map(assetMapper)
                            .filter(notUndefined);
                        resolve(mapped);
                    },
                    this.getMarshallSpec(),
                    window.loginCompanyId,
                    params.name || '',
                    this.tlxSelectMaxRows,
                    0
                );
            } catch (e) {
                defaultJsonRpcExceptionHandler(e);
                reject(e);
            }
        });
    }
}

export const exports = {
    fetcher: AssetFetcher,
    type: 'Asset',
    link: (id: number, contextId: number) =>
        `/execute/accountCurrentBookAsset?assetId=${id}&contextId=${contextId}`,
};
