import * as React from 'react';
import { createPortal } from 'react-dom';
import classNames from 'classnames';

import { Button, ButtonProps, CloseIcon, Spinner } from '@tlx/atlas';

import { tlxUrl } from '../../../../js/modules/url';

import './PageDialog.scss';

interface BaseProps {
    readonly url: string;
    readonly open: boolean;
    readonly lazy?: boolean;
    readonly close: () => void;
    readonly dialogClassname?: string;
    readonly dialogIdentifier: string;
    readonly onSuccess?: (id: number) => void;
    readonly dialogContentRef?: (ref: HTMLDivElement | null) => void;
    readonly titleIcon?: React.ReactNode;
    readonly titleTextKey?: string;
    readonly titleText?: string;
}

export type PageDialogProps = RequireAtLeastOne<
    BaseProps,
    'titleTextKey' | 'titleText'
>;

/**
 * This component can be used for showing JSP pages within a dialog
 * that is on top of react app content (i.e. #wrapperDiv).
 *
 * NB! The content of the dialog is loaded when the component mounts.
 * Lazy loading is the responsibility of the caller.
 *
 * @author Håkon Wågbø
 * @date 30. Oct 2018
 */

type PageDialogState = {
    buttons: ButtonProps[];
    title?: string;
    loading: boolean;
};

export class PageDialog extends React.Component<
    PageDialogProps,
    PageDialogState
> {
    private dialogContent!: HTMLDivElement | null;
    private body: HTMLElement;

    constructor(props: PageDialogProps) {
        super(props);
        this.loadPageContent = this.loadPageContent.bind(this);
        this.unloadPageContent = this.unloadPageContent.bind(this);
        this.contentLoadedCallback = this.contentLoadedCallback.bind(this);
        this.closeDialog = this.closeDialog.bind(this);
        this.body = window.document.body;
        this.state = {
            buttons: [],
            loading: props.lazy === true,
        };
    }

    loadPageContent() {
        const urlWithoutScope = this.props.url;
        const url = tlxUrl.addUrlParameters(
            urlWithoutScope,
            'dialog',
            'true',
            'scope',
            this.props.dialogIdentifier
        );
        if (this.dialogContent) {
            // To allow nesting / lazy loading of content like filtered lists.
            $(this.dialogContent).data('scope-base-url', urlWithoutScope);
            $(this.dialogContent).load(
                addContextId(url),
                this.contentLoadedCallback
            );
        }
    }

    contentLoadedCallback() {
        const { onSuccess, close } = this.props;
        const $scope = $(this.dialogContent!);

        bindEventsInsideScope($scope);

        app.loadPageScript($scope, () => {
            $scope.trigger('txrPageDialogLoadDone', {
                $container: $scope,
            });

            const title = $scope.get(0)?.dataset.tlxTitle ?? '';
            const buttons: ButtonProps[] = [];

            const $button = $scope.find('.toolbarAction');
            // What if there are more than 1 button 🤔
            if ($button.length == 1) {
                // We have a form
                window.tlxForms.focusFirstText($scope);
                const actionOptions = {
                    infoMessage: $button.data('toolbar-button-text'),
                    disableForward: true,
                    callback: (params: any) => {
                        if (onSuccess) {
                            onSuccess(params);
                        }
                        if (close) {
                            close();
                        }
                    },
                };

                buttons.push({
                    type: 'button',
                    onClick: function onClick(this: HTMLElement) {
                        window.defaultAjaxAction(
                            $scope.find('form'),
                            $button.data('action'),
                            null,
                            actionOptions
                        );
                    },
                    children: $button.data('dialogButtonText'),
                    variant: 'primary',
                    'data-testid': 'dialog-submit-button',
                });

                buttons.push({
                    type: 'button',
                    onClick: this.closeDialog,
                    children: getMessage('button_cancel'),
                    variant: 'tertiary',
                    'data-testid': 'dialog-cancel-button',
                });
            } else if ($button.length == 0) {
                buttons.push({
                    type: 'button',
                    onClick: this.closeDialog,
                    children: getMessage('button_close'),
                    variant: 'tertiary',
                    'data-testid': 'dialog-close-button',
                });
            }

            this.setState({
                title,
                loading: false,
                buttons,
            });
        });
    }

    unloadPageContent() {
        if (this.dialogContent) {
            this.dialogContent.dispatchEvent(
                new CustomEvent('tlxRemoveUpgradedMdlComponents', {
                    bubbles: true,
                    cancelable: true,
                })
            );
            $(this.dialogContent).empty();
        }
    }

    componentDidUpdate(prevProps: Readonly<PageDialogProps>) {
        let reloadContent = false;

        if (
            this.state.loading &&
            this.props.open &&
            prevProps.open !== this.props.open
        ) {
            this.loadPageContent();
        } else if (this.props.dialogIdentifier !== prevProps.dialogIdentifier) {
            reloadContent = true;
        } else if (this.props.url !== prevProps.url) {
            reloadContent = true;
        }

        if (!this.state.loading && reloadContent) {
            this.unloadPageContent();
            this.setState({
                buttons: [],
                loading: true,
                title: undefined,
            });
            if (this.props.lazy !== true || this.props.open) {
                this.loadPageContent();
            }
        }
    }

    componentWillUnmount() {
        this.unloadPageContent();
        this.body.classList.remove('txr-page-dialog-open');
    }

    componentDidMount() {
        if (!this.props.lazy) {
            this.loadPageContent();
        }
        $(window).on('tlxNavigateAjax', this.closeDialog);
    }

    closeDialog() {
        if (this.props.close) {
            this.props.close();
        }
    }

    render() {
        const titleIcon = this.props.titleIcon ?? null;

        const title = this.props.titleTextKey
            ? getMessage(this.props.titleTextKey)
            : this.props.titleText;

        const loadingIndicator = this.state.loading ? (
            <div className="txr-page-dialog__loading-indicator">
                <Spinner />
            </div>
        ) : null;

        const dialog = (
            <div
                data-no-focus-lock={true}
                className={classNames(
                    'txr-page-dialog__backdrop',
                    'txr-page-dialog__backdrop--main',
                    this.props.dialogClassname,
                    {
                        'txr-page-dialog__backdrop--showing': this.props.open,
                    }
                )}
            >
                <section
                    className={`txr-page-dialog ${
                        this.props.open ? 'txr-page-dialog--open' : ''
                    }`}
                >
                    <div className="txr-page-dialog__header">
                        <span className="txr-page-dialog__header__title">
                            {titleIcon}
                            {title}
                        </span>
                        <Button
                            data-testid="page-dialog-close-button"
                            aria-label={getMessage('button_close')}
                            variant="icon"
                            onClick={this.closeDialog}
                        >
                            <CloseIcon />
                        </Button>
                    </div>
                    {loadingIndicator}
                    <div
                        id={this.props.dialogIdentifier}
                        className="tlxScope txr-page-dialog__content"
                        ref={(el) => {
                            this.dialogContent = el;
                            if (this.props.dialogContentRef) {
                                this.props.dialogContentRef(el);
                            }
                        }}
                    />
                    {this.state.buttons.length > 0 && (
                        <div className="txr-page-dialog__footer">
                            {this.state.buttons.map(
                                (buttonProps: ButtonProps, index) => (
                                    <Button {...buttonProps} key={index} />
                                )
                            )}
                        </div>
                    )}
                </section>
            </div>
        );

        if (this.body) {
            if (this.props.open) {
                this.body.classList.add('txr-page-dialog-open');
            } else if (this.body.classList.contains('txr-page-dialog-open')) {
                this.body.classList.remove('txr-page-dialog-open');
            }
            return createPortal(dialog, this.body);
        }

        return dialog;
    }
}
