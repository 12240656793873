import React, { useEffect, useState } from 'react';
import { Alert, AlertContent, Group, Input, Label, Option } from '@tlx/atlas';
import {
    ResaleProductDTO,
    SupplierConnectionDTO,
} from '@Page/LogisticsV2/types/dtoTypes';
import { ProductAndSupplierConnectionFormModel } from '@Page/LogisticsV2/components/Modals/ProductSelector/types';
import { ToggleSwitch } from '@Page/LogisticsV2/components/ToggleSwitch/ToggleSwitch';
import { ApiUrl } from '@Page/LogisticsV2/utils/enums';
import { ApiError, OPTION_NOT_CHOSEN } from '@Page/LogisticsV2/utils/constants';
import { LoadableDropdown } from '@Component/LoadableDropdown/LoadableDropdown';
import { ValidatedInput } from '@Page/LogisticsV2/components/Input/ValidatedInput';
import { getFieldErrorFromValidationMessages } from '@Page/LogisticsV2/utils/functions';
import { useSuggestedProductNumber } from '@Page/LogisticsV2/components/Modals/ProductSelector/useProducts';

export function ProductAndSupplierConnectionForm({
    onCreateProduct,
    supplierId,
    error,
}: {
    onCreateProduct: (
        resaleProduct: Partial<ResaleProductDTO>,
        productGroupId: number | undefined,
        supplierConnection: Partial<SupplierConnectionDTO>
    ) => void;
    supplierId: number;
    error: ApiError | undefined;
}) {
    const suggestedProductNumber = useSuggestedProductNumber();
    const [formState, setFormState] =
        useState<ProductAndSupplierConnectionFormModel>({
            name: '',
            number: suggestedProductNumber,
            costExcludingVatCurrency: 0,
            price: 0,
            vatTypeId: 3,
            groupId: 0,
        });

    const [includeVat, setIncludeVat] = useState(false);
    const handleChange = (name: string) => (event: any) => {
        const value = typeof event === 'string' ? event : event.target.value;
        setFormState((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };
    const handleCreateSupplierProduct = async () => {
        const resaleProduct: Partial<ResaleProductDTO> = {
            name: formState.name,
            number: formState.number,
            costExcludingVatCurrency: formState.costExcludingVatCurrency,
            vatType: { id: Number(formState.vatTypeId) },
            isStockItem: true,
        };
        if (includeVat) {
            resaleProduct.priceIncludingVatCurrency = formState.price;
        } else {
            resaleProduct.priceExcludingVatCurrency = formState.price;
        }
        const supplierConnection: Partial<SupplierConnectionDTO> = {
            name: formState.name,
            number: formState.number,
            costExcludingVatCurrency: formState.costExcludingVatCurrency,
            supplier: { id: supplierId },
        };
        await onCreateProduct(
            resaleProduct,
            formState.groupId,
            supplierConnection
        );
    };

    useEffect(() => {
        setFormState((state) => ({
            ...state,
            number: suggestedProductNumber ?? '',
        }));
    }, [suggestedProductNumber]);

    return (
        <form
            id={'create-product-form'}
            className={'atl-flex atl-py-8 atl-flex-col'}
            onSubmit={(event) => {
                event.preventDefault();
                event.stopPropagation();
                handleCreateSupplierProduct();
            }}
        >
            <div className={'atl-flex atl-gap-16 goldshark-new-product__form'}>
                <Group className={'atl-py-8 atl-flex atl-flex-col'}>
                    <Label>{getMessage('text_name')}*</Label>
                    <ValidatedInput
                        type={'text'}
                        data-testid={'new-product-name'}
                        onChange={handleChange('name')}
                        error={getFieldErrorFromValidationMessages(
                            error?.validationMessages,
                            'name'
                        )}
                    />
                </Group>
                <Group className={'atl-py-8 atl-flex atl-flex-col'}>
                    <Label>{getMessage('text_number')}</Label>
                    <ValidatedInput
                        type={'text'}
                        data-testid={'new-product-number'}
                        onChange={handleChange('number')}
                        error={getFieldErrorFromValidationMessages(
                            error?.validationMessages,
                            'number'
                        )}
                        value={formState.number}
                    />
                </Group>

                {moduleLogistics && (
                    <Group className={'atl-py-8'}>
                        <Label>{getMessage('text_product_group')}</Label>
                        <LoadableDropdown
                            data-testid="new-product-product-group-dropdown"
                            url={ApiUrl.PRODUCT_GROUP}
                            value={OPTION_NOT_CHOSEN}
                            onChange={handleChange('groupId')}
                        >
                            <Option value={OPTION_NOT_CHOSEN}>
                                {getMessage('option_not_chosen')}
                            </Option>
                        </LoadableDropdown>
                    </Group>
                )}

                <Group className={'atl-py-8 atl-flex atl-flex-col'}>
                    <Label>{getMessage('text_vat_code')}*</Label>
                    <LoadableDropdown
                        data-testid="new-product-vat-dropdown"
                        url={`${ApiUrl.VAT_TYPE}?typeOfVat=OUTGOING`}
                        value={formState.vatTypeId ?? OPTION_NOT_CHOSEN}
                        onChange={handleChange('vatTypeId')}
                    >
                        <Option value={OPTION_NOT_CHOSEN}>
                            {getMessage('option_not_chosen')}
                        </Option>
                    </LoadableDropdown>
                </Group>
                <Group className={'atl-py-8 atl-flex atl-flex-col'}>
                    <Label>{getMessage('text_price_purchase_excl_vat')}</Label>
                    <Input
                        type={'number'}
                        data-testid={'new-product-purchase-excl-vat'}
                        onChange={handleChange('costExcludingVatCurrency')}
                        value={formState.costExcludingVatCurrency}
                    />
                </Group>
                <Group className={'atl-py-8 atl-flex atl-flex-col'}>
                    <Label>
                        {includeVat
                            ? getMessage('text_sales_price_incl_vat') + '(NOK)'
                            : getMessage('text_sales_price_excl_vat') + '(NOK)'}
                    </Label>
                    <Input
                        type={'number'}
                        data-testid={'new-product-purchase-selling-price'}
                        hidden={includeVat}
                        onChange={handleChange('price')}
                        value={formState.price}
                    />
                    <Input
                        type={'number'}
                        data-testid={'new-product-purchase-selling-price'}
                        hidden={!includeVat}
                        onChange={handleChange('price')}
                        value={formState.price}
                    />
                </Group>
                <Group className={'atl-py-8 atl-flex atl-items-end atl-mb-8'}>
                    <ToggleSwitch
                        id={'product-selector-toggle-switch-vat'}
                        label={getMessage('text_incl_vat')}
                        onCheckedChange={() => {
                            setIncludeVat(!includeVat);
                        }}
                    />
                </Group>
            </div>
            {error?.validationMessages.length === 0 && (
                <Alert variant="error" className={'atl-my-16'}>
                    <AlertContent>{error.message}</AlertContent>
                </Alert>
            )}
        </form>
    );
}
