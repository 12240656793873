import { PageData, PageLoader } from '@Component/PageLoader';
import { RouteData } from '@General/Router';
import { Team } from '@General/Team';
import { EntitlementLookup } from '@General/constants';
import { isAutoPay } from '@General/PBC/bank.globals';

const ALLOWED_ROLES = [EntitlementLookup.AUTH_DIRECT_REMIT_ADMIN];

const Page = (): JSX.Element => (
    <PageLoader
        loader={async (): Promise<PageData> =>
            import('./SetupAutoPayUser').then((m) => m.page)
        }
    />
);

export const route: RouteData = {
    component: Page,
    path: '/execute/setupAutoPayUser',
    title: () => getMessage('text_setup_autopay_user'),
    team: Team.PBC,
    documentationComponent: 332,
    auth: (context) =>
        isAutoPay &&
        context.entitlements.some((e) =>
            ALLOWED_ROLES.includes(e.id as EntitlementLookup)
        ),
};
