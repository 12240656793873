import { ensureInitAfterContentLoaded } from '../../../../js/modules/ensureInitAfterContentLoaded';
import { CommandCenter } from './CommandCenter';
import { CommandEventName } from './CommandEvent';
import { registerAllGlobalCommands } from './Global';

export const commandCenter = new CommandCenter();
registerAllGlobalCommands(commandCenter);

window.commandCenter = commandCenter;

function initializeGlobalCommand() {
    document.body.addEventListener(
        'keydown',
        commandCenter.handleKey.bind(commandCenter)
    );
    document.body.addEventListener(
        CommandEventName,
        commandCenter.handleCommandTriggerEvent.bind(commandCenter)
    );
}

ensureInitAfterContentLoaded(initializeGlobalCommand);
