import * as React from 'react';
import { useState } from 'react';
import {
    InvoiceSettings,
    InvoicingBankAccount,
} from '@Page/InvoicingDialog/types/InvoicingDialog.type';
import { LoadingSpinner } from '@Component/Loading';
import {
    SetupFormFieldValues,
    useSetupForm,
} from '@Page/InvoicingDialog/component/SetupForm/SetupFormHook';
import { Alert, AlertContent, Button, Group, Input, Label } from '@tlx/atlas';
import { ValidationError } from '@Page/InvoicingDialog/component/ValidationError';
import './Setup.css';
import { FieldErrors } from 'react-hook-form';
import { Company } from '@Page/InvoicingDialog/component/InvoicingModalDataFetching';
import { APIError } from '../../../../hooks/fetch/createAPIResponse';

export interface SetupProps {
    closeModal: () => void;
    invoiceSettings: InvoiceSettings;
    company: Company;
    isCompanyAdmin: boolean;
    isAuthAccountingSettings: boolean;
    invoicingBankAccount?: InvoicingBankAccount;
    submitSetup: (
        formValues: SetupFormFieldValues,
        invoiceSettings: InvoiceSettings,
        invoicingBankAccount?: InvoicingBankAccount
    ) => Promise<void>;
    refresh: () => void;
}

export function Setup({
    closeModal,
    invoiceSettings,
    company,
    isAuthAccountingSettings,
    isCompanyAdmin,
    invoicingBankAccount,
    submitSetup,
    refresh,
}: SetupProps) {
    const { organizationNumber: companyOrgNr, companyMigration } = company;
    const formProps = useSetupForm(
        invoiceSettings,
        invoicingBankAccount,
        companyOrgNr ?? '',
        companyMigration
    );
    const { fields, errors, isSubmitting, handleSubmit, watch, setError } =
        formProps;
    const [hasSubmitError, setHasSubmitError] = useState(false);

    const isBankAccountReady = invoiceSettings.bankAccountReady;

    const invoiceAbroad = watch('invoiceAbroad');

    async function onSubmit(formValues: SetupFormFieldValues) {
        setHasSubmitError(false);
        try {
            await submitSetup(
                formValues,
                invoiceSettings,
                invoicingBankAccount
            );
            refresh();
        } catch (e) {
            if (e instanceof APIError) {
                console.log('error during submit', e.details);
                if (
                    e.details.validationMessages[0].field ===
                    'bankAccountNumber'
                ) {
                    setError(
                        'invoicingBankAccountNumber',
                        {
                            type: 'focus',
                            message: e.details.validationMessages[0].message,
                        },
                        { shouldFocus: true }
                    );
                } else {
                    setHasSubmitError(true);
                }
            }
        }
    }

    function onError(errors: FieldErrors<SetupFormFieldValues>) {
        // Sometimes validation errors will not show in GUI because the field is missing, so we need to log it so that it is possible to figure out what is wrong when the submit button does not work
        console.log('Validation errors: ', errors);
    }

    if (
        (!errors && isSubmitting) ||
        isCompanyAdmin === undefined ||
        isAuthAccountingSettings === undefined
    ) {
        return <LoadingSpinner />;
    }

    const header = (
        <div className="atl-rounded-t atl-bg-blue-20 atl-text-blue-100 atl-p-32">
            <div className="atl-text-2xl atl-font-bold">
                {getMessage('text_invoicing_onboarding1')}
            </div>
            <div className="atl-text-base atl-mt-16">
                {getMessage('text_invoicing_onboarding2')}
            </div>
        </div>
    );

    if (!invoicingBankAccount && !isBankAccountReady) {
        return (
            <div className="kb-setup">
                {header}
                <div className="atl-pl-32 atl-pr-32 atl-pb-32 atl-pt-16">
                    <Alert variant="info" className="atl-mb-16">
                        <AlertContent>
                            {getMessage(
                                'validation_invoice_can_not_be_created_bank_account_number_missing'
                            )}
                            <div>
                                <a
                                    href={addContextId(
                                        '/execute/updateCompany'
                                    )}
                                    className="linkFunction navigate"
                                >
                                    {getMessage(
                                        'link_register_bank_account_number_here'
                                    )}
                                </a>
                            </div>
                        </AlertContent>
                    </Alert>
                    <Button
                        onClick={closeModal}
                        data-testid="setup-cancel-button"
                    >
                        {getMessage('button_close')}
                    </Button>
                </div>
            </div>
        );
    }

    const isAuthBankAccount = isCompanyAdmin || isAuthAccountingSettings;
    if (
        (!companyOrgNr && !isCompanyAdmin) ||
        (!isBankAccountReady && !isAuthBankAccount)
    ) {
        return (
            <div className="kb-setup">
                {header}
                <div className="atl-pl-32 atl-pr-32 atl-pb-32 atl-pt-16">
                    <div className="atl-pb-16 atl-text-base">
                        {getMessage('text_invoice_setup_missing_access')}
                    </div>
                    <Button
                        onClick={closeModal}
                        data-testid="setup-cancel-button"
                    >
                        {getMessage('button_close')}
                    </Button>
                </div>
            </div>
        );
    }

    return (
        <div className="kb-setup">
            {header}
            <div className="atl-pl-32 atl-pr-32 atl-pb-32 atl-pt-16">
                <form
                    onSubmit={handleSubmit(onSubmit, onError)}
                    autoComplete="off"
                >
                    {(!invoiceSettings.hasFirstInvoiceNumber ||
                        !invoiceSettings.hasSentFirstInvoice) && (
                        <Group className="atl-mb-16">
                            <Label>
                                {getMessage('text_first_invoice_number')}
                            </Label>
                            <Input
                                className="atl-w-full"
                                placeholder={getMessage(
                                    'text_first_invoice_number_recommendation'
                                )}
                                data-testid="setup-first-invoice-number-button"
                                type="text"
                                {...fields.firstInvoiceNumber}
                            />
                            <ValidationError
                                error={errors.firstInvoiceNumber}
                            />
                        </Group>
                    )}

                    {!companyOrgNr && (
                        <Group className="atl-mb-16">
                            <Label>{getMessage('text_org_number')}</Label>
                            <Input
                                className="atl-w-full"
                                placeholder={getMessage(
                                    'text_your_organization_number'
                                )}
                                type="text"
                                data-testid="setup-organization-number-input"
                                {...fields.organizationNumber}
                            />
                            <ValidationError
                                error={errors.organizationNumber}
                            />
                        </Group>
                    )}
                    {!isBankAccountReady && !invoicingBankAccount && (
                        <>
                            <p className="errorMessage">
                                {getMessage(
                                    'validation_invoice_can_not_be_created_bank_account_number_missing'
                                )}
                                <br />
                                <a
                                    href={addContextId(
                                        '/execute/updateCompany'
                                    )}
                                    className="linkFunction navigate"
                                >
                                    {getMessage(
                                        'link_register_bank_account_number_here'
                                    )}
                                </a>
                            </p>
                        </>
                    )}
                    {!isBankAccountReady && invoicingBankAccount && (
                        <>
                            <Group className="atl-mb-16">
                                <Label>
                                    {getMessage('text_your_bank_account')}
                                </Label>
                                <Input
                                    className="atl-w-full"
                                    type="text"
                                    placeholder={getMessage(
                                        'text_what_bank_account'
                                    )}
                                    data-testid="setup-bank-account-input"
                                    {...fields.invoicingBankAccountNumber}
                                />
                                <ValidationError
                                    error={errors.invoicingBankAccountNumber}
                                />
                            </Group>
                            <Group
                                className={`atl-pt-16 atl-pb-4 atl-flex atl-rounded atl-pr-16 atl-bg-blue-10 kb-setup__fit-content ${
                                    invoiceAbroad
                                        ? 'atl-border atl-border-blue-100'
                                        : ''
                                }`}
                            >
                                <Input
                                    className="atl-ml-16"
                                    type="checkbox"
                                    {...fields.invoiceAbroad}
                                    data-testid="will-invoice-abroad-checkbox"
                                />
                                <Label className="atl-ml-16">
                                    {getMessage('text_will_invoice_abroad')}
                                </Label>
                            </Group>
                            {invoiceAbroad && (
                                <>
                                    <div className="atl-font-medium atl-text-base atl-mt-16">
                                        {getMessage('text_need_more_info')}
                                    </div>
                                    <Group className="atl-mt-16">
                                        <Label>
                                            {getMessage('text_iban_number')}
                                        </Label>
                                        <Input
                                            className="atl-w-full"
                                            type="text"
                                            data-testid="setup-iban-number-input"
                                            {...fields.iban}
                                        />
                                        <ValidationError error={errors.iban} />
                                    </Group>
                                    <Group className="atl-mt-16">
                                        <Label>
                                            {getMessage('text_swift_code')}
                                        </Label>

                                        <Input
                                            className="atl-w-full"
                                            type="text"
                                            data-testid="setup-swift-input"
                                            {...fields.swift}
                                        />
                                        <ValidationError error={errors.swift} />
                                    </Group>
                                </>
                            )}
                        </>
                    )}
                    {hasSubmitError && (
                        <Alert className="atl-mt-16" variant="error">
                            <AlertContent>
                                {getMessage('validation_unexpected_error')}
                            </AlertContent>
                        </Alert>
                    )}
                    <div className="atl-mt-16">
                        <Button
                            type="submit"
                            className="atl-mr-16"
                            data-testid="setup-submit-button"
                        >
                            {getMessage('button_save')}
                        </Button>
                        <Button
                            variant="tertiary"
                            onClick={closeModal}
                            data-testid="setup-cancel-button"
                        >
                            {getMessage('button_cancel')}
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    );
}
