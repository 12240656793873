import $ from 'jquery';

/**
 * A jQuery replacement of js:confirm.  Please be aware of differences from native confirm, such as return values.
 * @param okCallbackFunction Function to execute if user presses "ok"
 * @param message The message to display
 * @param titleKey Resource bundle key for the confirmation pop-up header
 */
export function tlxConfirm(
    okCallbackFunction: () => void,
    message: string,
    titleKey?: string
) {
    tlxConfirmWithTrackingId(okCallbackFunction, message, titleKey, '', '');
}

/**
 * A jQuery replacement of js:confirm.  Please be aware of differences from native confirm, such as return values.
 * @param okCallbackFunction Function to execute if user presses "ok"
 * @param message The message to display
 * @param titleKey Resource bundle key for the confirmation pop-up header
 * @param okButtonTrackingId The confirmation dialog OK button's tracking ID
 * @param cancelButtonTrackingId The confirmation dialog Cancel button's tracking ID
 */
export function tlxConfirmWithTrackingId(
    okCallbackFunction: () => void,
    message: string,
    titleKey?: string,
    okButtonTrackingId = '',
    cancelButtonTrackingId = ''
) {
    tlxConfirm2WithTrackingId(
        message,
        okCallbackFunction,
        function () {
            /* noop */
        },
        titleKey,
        okButtonTrackingId,
        cancelButtonTrackingId
    );
}

/**
 * Confirmation pop-up with message title and OK/Cancel buttons
 * @param message Message to display on pop-up
 * @param okCallbackFunction Function to execute on OK
 * @param cancelCallbackFunction Function to execute on Cancel
 * @param titleKey Title of pop-up
 */
export function tlxConfirm2(
    message: string,
    okCallbackFunction: () => void,
    cancelCallbackFunction: () => void,
    titleKey?: string
) {
    tlxConfirm2WithTrackingId(
        message,
        okCallbackFunction,
        cancelCallbackFunction,
        titleKey,
        '',
        ''
    );
}

/**
 * Confirmation pop-up with message title and OK/Cancel buttons
 * @param message Message to display on pop-up
 * @param okButtonTrackingId The confirmation dialog OK button's tracking ID
 * @param cancelButtonTrackingId The confirmation dialog Cancel button's tracking ID
 * @param titleKey Title of pop-up
 * @param okCallbackFunction Function to execute on OK
 * @param cancelCallbackFunction Function to execute on Cancel
 */
export function tlxConfirm2WithTrackingId(
    message: string,
    okCallbackFunction: () => void,
    cancelCallbackFunction: () => void,
    titleKey?: string,
    okButtonTrackingId = '',
    cancelButtonTrackingId = ''
) {
    let $confirmDialog = $('#confirmDialog');
    //If not found, generate a new one. This will only be done once (until new page load).
    if (!$confirmDialog || $confirmDialog.length == 0) {
        $confirmDialog = $(
            "<div id='confirmDialog'><div id='confirmDialogMsg' class='tlxDialogContent'></div></div>"
        ).dialog({
            autoOpen: false,
            position: { my: 'top', at: 'center top+200' },
            minHeight: 40,
            modal: true,
            hide: 'fade',
            title: getMessage(titleKey || 'text_warning'),
        });
    } else {
        $confirmDialog.dialog(
            'option',
            'title',
            getMessage(titleKey || 'text_warning')
        );
    }
    //buttons must be redefined each time, as callback function may have changed
    const buttons = [
        {
            text: getMessage('button_ok'),
            'data-testid': 'tlxConfirm-ok',
            'data-trackingid': okButtonTrackingId,
            click: function () {
                $(this).dialog().data('ok', true);
                okCallbackFunction();
                $(this).dialog('close');
            },
            icons: { primary: 'ui-icon-check' },
        },
        {
            text: getMessage('button_cancel'),
            'data-testid': 'tlxConfirm-cancel',
            'data-trackingid': cancelButtonTrackingId,
            click: function () {
                $(this).dialog().data('ok', false);
                $(this).dialog('close');
            },
            icons: { primary: 'ui-icon-close' },
        },
    ];
    //message may also have changed
    $confirmDialog.dialog({
        close: function () {
            if (!$(this).data('ok')) {
                cancelCallbackFunction();
            }
        },
    });
    $('#confirmDialogMsg').html(message);
    $confirmDialog
        .dialog('widget')
        .find('.ui-dialog-buttonpane button:first')
        .addClass('tlx-green'); // green ok button. hackish
    $confirmDialog.dialog('option', 'buttons', buttons).dialog('open');
}
