import {
    Invoice,
    Order,
} from '@Page/InvoicingDialog/types/InvoicingDialog.type';
import { addContextId } from '../../../../../js/modules/url';
import { SendTypes } from '@Page/InvoicingDialog/component/InvoicingForm/SendMethod';
import { createAPIRequest } from '../../../hooks/fetch/createAPIRequest';
import { OrderInvoicingStateMessage } from '@Page/InvoicingDialog/component/BatchInvoicing/InvoicingListItem';
import { asyncPool } from '@Page/InvoicingDialog/utils/asyncPool';
import { useEffect, useState } from 'react';

export interface Currency {
    code: string;
}

export interface Company {
    currency: Currency;
    organizationNumber?: string;
    companyMigration?: string;
}

export function useFetchCompany(companyId: number) {
    const [data, setData] = useState<Company | undefined>();
    const [error, setError] = useState();
    const [isLoading, setIsLoading] = useState(false);

    async function refresh(companyId: number) {
        // Reset error while fetching
        setError(undefined);
        setIsLoading(true);

        try {
            const url = new URL(
                `/v2/company/${companyId}`,
                window.location.origin
            );
            const params = url.searchParams;
            params.set(
                'fields',
                'currency(code), organizationNumber, companyMigration'
            );

            const response = await window.fetch(
                createAPIRequest(url.toString())
            );
            const data = await response.json();

            setData(data.value);
        } catch (error) {
            setError(error);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        void refresh(companyId);
    }, [companyId]);

    return { data, error, isLoading, refresh };
}

function getOrdersURL(orderIds: number[]): string {
    const url = new URL('/v2/order', window.location.origin);
    const params = url.searchParams;
    params.set('count', '200');
    params.set('id', orderIds.join(','));
    params.set('orderDateFrom', '1990-01-01');
    params.set('orderDateTo', '2200-01-01');
    params.set(
        'fields',
        'id, sendMethodDescription, currency(code), number, isClosed, isSubscription, invoiceSendSMSNotification, invoiceSMSNotificationNumber, ' +
            'customer(id, name, invoiceSendMethod, invoiceEmail, singleCustomerInvoice, isPrivateIndividual, organizationNumber, globalLocationNumber, phoneNumber, phoneNumberMobile, invoiceSendSMSNotification , physicalAddress(country(id, name)), postalAddress(postalCode, city)), ' +
            'attn(id), ' +
            'preliminaryInvoice(id, amount, amountCurrency, amountExcludingVat, amountExcludingVatCurrency, travelReports, isPeriodizationPossible, isCreditNote, isApproved, amountRoundoff, amountRoundoffCurrency), ' +
            'canCreateBackorder, orderLines(id), totalInvoicedOnAccountAmountAbsoluteCurrency, ' +
            'invoiceOnAccountVatHigh, receiverEmail, ' +
            'invoicesDueInType, invoicesDueIn'
    );
    return url.toString();
}

type FetchOrdersResponse = {
    orders: Order[];
    error: boolean;
    isLoadingOrders: boolean;
};

export const useFetchOrders = (orderIds: number[]) => {
    const [response, setResponse] = useState<FetchOrdersResponse>({
        orders: [],
        error: false,
        isLoadingOrders: true,
    });

    useEffect(() => {
        fetch(createAPIRequest(getOrdersURL(orderIds), { method: 'GET' }))
            .then((res) => res.json())
            .then((data) => {
                setResponse({
                    orders: data.values,
                    error: false,
                    isLoadingOrders: false,
                });
            })
            .catch(() =>
                setResponse({
                    ...response,
                    error: true,
                    isLoadingOrders: false,
                })
            );
    }, []);
    return response;
};

// Creates and sends an invoice to a single customer
// If the list contains 1 order - a standard invoice is created and sent
// If it has 2 or more orders - they're merged to a single invoice ('single customer invoice'), and sent
const asyncInvoiceCustomer = async (
    orders: Order[],
    date: string,
    sentManuallyCallBack: (invoiceId: number) => void,
    onError: (message: OrderInvoicingStateMessage) => void,
    onSuccess: (message: OrderInvoicingStateMessage) => void,
    onInfo: (message: OrderInvoicingStateMessage) => void,
    createBackOrders: boolean
) => {
    if (orders.length === 0) {
        return;
    }
    const orderIds = orders.map((order) => order.id);

    const isSingleOrder = orderIds.length === 1;

    let invoice: Invoice | undefined;
    if (isSingleOrder) {
        invoice = await createOrderInvoice(
            orderIds[0],
            date,
            createBackOrders,
            (message) => onError(message)
        );
    } else {
        invoice = await createSingleCustomerInvoice(
            orderIds,
            date,
            createBackOrders,
            (message) => onError(message)
        );
    }

    if (!invoice) {
        return;
    }

    // Find out if we can change this flow - so if email is set - we validate if an email is present and give error if not
    // instead of just sending it out manually
    const sendMethod = orders[0].customer.invoiceSendMethod;
    const isSentManually =
        (sendMethod === 'EMAIL' && !orders[0].receiverEmail) ||
        sendMethod === 'MANUAL';

    if (isSentManually) {
        sentManuallyCallBack(invoice.id);
    }

    onSuccess('');
};

const getMissingSendMethodModule = (
    order: Order,
    companySendTypes: string[]
): string | undefined => {
    const isPrivateCustomer = order.customer.isPrivateIndividual;
    const companyHasModulesForLookup = isPrivateCustomer
        ? companySendTypes.includes(SendTypes.EFAKTURA) ||
          companySendTypes.includes(SendTypes.VIPPS)
        : companySendTypes.includes(SendTypes.EHF);

    if (companyHasModulesForLookup) {
        return;
    }

    if (isPrivateCustomer) {
        return getMessage(
            'validation_cannot_lookup_receiver_without_efaktura_module'
        ); // We don't care about Vipps here, as eFaktura is what we use for private individuals, going forward
    } else {
        return getMessage(
            'validation_cannot_lookup_receiver_without_ehf_module'
        );
    }
};

export async function doBatchInvoice(
    date: string,
    groupedOrders: Order[][], // grouped by singleCustomerInvoice or not ([[orderForSCI, orderForSCI], [orderToBeInvoicedAlone]...]
    onError: (
        groupIndex: number,
        errorMessage: OrderInvoicingStateMessage
    ) => void,
    onSuccess: (
        groupIndex: number,
        successMessage: OrderInvoicingStateMessage
    ) => void,
    onInfo: (
        groupIndex: number,
        infoMessage: OrderInvoicingStateMessage
    ) => void,
    createBackOrders: boolean
): Promise<URL | undefined> {
    const idsForInvoicesToBeSentManually: Array<number> = [];

    await asyncPool(
        groupedOrders,
        async (groupOfOrders) => {
            const groupIndex = groupedOrders.indexOf(groupOfOrders);

            await asyncInvoiceCustomer(
                groupOfOrders,
                date,
                (invoiceId) => {
                    idsForInvoicesToBeSentManually.push(invoiceId);
                },
                (errorMessage) => {
                    onError(groupIndex, errorMessage);
                },
                (successMessage) => {
                    onSuccess(groupIndex, successMessage);
                },
                (infoMessage) => {
                    onInfo(groupIndex, infoMessage);
                },
                createBackOrders
            );
        },
        5
    );

    if (idsForInvoicesToBeSentManually.length > 0) {
        const pdfURLForInvoicesToBeSentManually = new URL(
            addContextId(
                `/execute/invoiceUtil?act=viewForPrint&ids=${idsForInvoicesToBeSentManually.join(
                    ','
                )}`
            ),
            window.location.origin
        );

        if (pdfURLForInvoicesToBeSentManually) {
            return pdfURLForInvoicesToBeSentManually;
        }
    }

    return undefined;
}

const createOrderInvoice = async (
    orderId: number,
    invoiceDate: string,
    createBackOrder: boolean,
    onError: (errorMessage: string) => void
): Promise<Invoice | undefined> => {
    const url = new URL(
        `/v2/order/${orderId}/:invoice`,
        window.location.origin
    );
    const params = url.searchParams;
    params.set('invoiceDate', invoiceDate);
    params.set('sendToCustomer', 'true');
    params.set('createBackorder', createBackOrder.toString());

    const request = createAPIRequest(url.toString(), { method: 'PUT' });

    try {
        const response = await fetch(request);
        const data = await response.json();

        if (response.status === 200) {
            return data.value;
        } else {
            const errorMessage =
                data?.validationMessages?.[0]?.message ??
                getMessage('error_on_create_invoice');

            onError(errorMessage);
        }
    } catch (e) {
        console.error('Error while creating invoice:', e);
        onError(getMessage('error_on_create_invoice'));
    }
    return undefined;
};

const createSingleCustomerInvoice = async (
    orderIds: number[],
    invoiceDate: string,
    createBackOrders: boolean,
    onError: (message: string) => void
): Promise<Invoice | undefined> => {
    const url = new URL(
        `/v2/order/:invoiceMultipleOrders`,
        window.location.origin
    );
    const params = url.searchParams;
    params.set('invoiceDate', invoiceDate);
    params.set('sendToCustomer', 'true');
    params.set('id', orderIds.join(','));
    params.set('createBackorders', createBackOrders.toString());

    const request = createAPIRequest(url.toString(), { method: 'PUT' });

    try {
        const response = await fetch(request);
        const data = await response.json();

        if (response.status === 200) {
            return data.value;
        } else {
            const errorMessage =
                data?.validationMessages?.[0]?.message ??
                getMessage('error_on_create_single_customer_invoice');

            onError(errorMessage);
        }
    } catch (e) {
        console.error('Error while creating single customer invoice:', e);
        onError(getMessage('error_on_create_single_customer_invoice'));
    }
    return undefined;
};
