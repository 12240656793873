import {
    ModalContent,
    ModalFooter,
    ModalTitle,
    SkeletonText,
} from '@tlx/atlas';
import React from 'react';

export function KycFeedbackBoxLoading() {
    return (
        <>
            <ModalTitle>
                <SkeletonText
                    placeholder={getMessage(
                        'text_secure_user_identification_ty'
                    )}
                />
            </ModalTitle>
            <ModalContent>
                <div className="atl-grid atl-gap-24 atl-py-8">
                    <SkeletonText
                        width={536}
                        placeholder={getMessage('text_kyc_company_registered')}
                    />
                </div>
            </ModalContent>
            <ModalFooter className="atl-flex atl-flex-wrap atl-gap-x-8 atl-pb-32 atl-pt-0">
                <SkeletonText
                    className="atl-button"
                    placeholder={getMessage(
                        'close_account_button_continue_done'
                    )}
                />
                <SkeletonText
                    width={167}
                    className="atl-button"
                    placeholder={getMessage('text_send_feedback')}
                />
            </ModalFooter>
        </>
    );
}
