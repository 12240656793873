import {
    Button,
    ChevronLeftIcon,
    ChevronRightIcon,
    Dropdown,
    DropdownDrawer,
    DropdownOpener,
    DropdownScrollContainer,
    DropdownSearch,
    Input,
    Label,
    OptionProps,
    ToggleGroup,
    useDropdownLoadMoreTarget,
    useDropdownOption,
    useDropdownOptions,
    useDropdownRegisterOptions,
    useDropdownSearchQuery,
    useDropdownSelectedValues,
} from '@tlx/atlas';
import classNames from 'classnames';
import {
    FetcherError,
    LoadableDropdownSkeletonOptions,
} from '@Component/LoadableDropdown/LoadableDropdown';
import * as React from 'react';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { UseFetchPaginatedState } from '../../hooks/fetch/useFetchPaginatedState';
import {
    AccountApiQueryParams,
    AccountWithHelpInfoDTO,
} from '@Component/AccountHelp/useAccountHelpDropdownData';
import useSWR from 'swr';
import { ListResponse } from '../../hooks/fetch/types';
import { defaultFetcher } from '../../hooks/fetch/defaultFetcher';
import './AccountHelpDropdown.css';
import {
    AccountExplanation,
    AccountHelpExplanationPanel,
} from '@Component/AccountHelp/AccountHelpExplanationPanel';
import { useStorage } from '@tlx/astro-shared';
import { renderDialog } from '@Component/DropDown/CreateNewButton/CreateNewButton';
import { useTrackUnmatchedSearchQuery } from '@Component/AccountHelp/useTrackUnmatchedSearchQuery';
import { useDebouncedQuery } from '../../hooks/useDebouncedQuery';

type AccountHelpDefaultOption = {
    value: string;
    displayName: string;
};

export type AccountHelpDropdownProps = {
    label: string;
    selectedValue: string;
    dataTrackingId: string;
    onChange: (id: string) => void;
    defaultOptions?: AccountHelpDefaultOption[];
    defaultDisplayName: string;
    required?: boolean;
    readOnly?: boolean;
    disabled?: boolean;
    useDataState: (
        query: string,
        params: AccountApiQueryParams
    ) => UseFetchPaginatedState<AccountWithHelpInfoDTO>;
    params: AccountApiQueryParams;
    className?: string;
    children?: ReactNode;
};

export function AccountHelpDropdown({
    defaultDisplayName,
    defaultOptions,
    dataTrackingId,
    selectedValue,
    onChange,
    useDataState,
    params,
    className,
    disabled,
    readOnly,
    required,
    children,
}: AccountHelpDropdownProps) {
    const { getItem, setItem } = useStorage();
    const [showAccountHelp, setShowAccountHelp] = useState(
        () => getItem('showAccountHelp', 'true') === 'true'
    );

    const isEnglishLocale = window.locale === 'en_GB';

    const url = getCreateNewButtonURL(
        params.accountType,
        params.accountSubType,
        params.accountRequiresVatType
    );

    useEffect(
        () => setItem('showAccountHelp', String(showAccountHelp)),
        [setItem, showAccountHelp]
    );

    const [accountExplanation, setAccountExplanation] =
        useState<AccountExplanation>({
            title: '',
            description: '',
            userDescription: '',
            tags: [],
        });

    return (
        <Dropdown
            required={required}
            disabled={disabled || readOnly}
            data-testid={dataTrackingId}
            onChange={(event) => onChange(event.target.value)}
            value={selectedValue}
            defaultDisplayName={defaultDisplayName}
        >
            <AccountHelpDropdownOpener className={className}>
                {children}
            </AccountHelpDropdownOpener>

            {isEnglishLocale ? (
                <DropdownDrawer>
                    <DropdownSearch
                        placeholder={getMessage(
                            'text_account_help_placeholder'
                        )}
                    />
                    <DropdownScrollContainer>
                        <AccountHelpDropdownOptions
                            defaultOptions={defaultOptions}
                            useDataState={useDataState}
                            params={params}
                            setDescription={() => {
                                //do nothing
                            }}
                        />
                    </DropdownScrollContainer>
                    <AccountHelpDropdownFooter
                        createNewAccountUrl={url}
                        onChange={onChange}
                        showCreateAccountButton={isAuth.createAccount}
                    />
                </DropdownDrawer>
            ) : (
                <DropdownDrawer
                    className={classNames('krr-account-help__drawer', {
                        'krr-account-help__drawer--help-hidden':
                            !showAccountHelp,
                    })}
                >
                    <div
                        className={
                            'atl-flex-none krr-account-help__drawer-left'
                        }
                    >
                        <DropdownSearch
                            placeholder={getMessage(
                                'text_account_help_placeholder'
                            )}
                        />
                        <DropdownScrollContainer>
                            <AccountHelpDropdownOptions
                                defaultOptions={defaultOptions}
                                useDataState={useDataState}
                                params={params}
                                setDescription={setAccountExplanation}
                            />
                        </DropdownScrollContainer>
                    </div>
                    <div
                        className={classNames(
                            'atl-bg-white krr-account-help__drawer-right',
                            {
                                'krr-account-help__drawer-right--hidden':
                                    !showAccountHelp,
                            }
                        )}
                    >
                        <div
                            className={'atl-overflow-y-auto'}
                            // 342px + 4px + 4px padding = 350px which is the width of drawer-right; needed for animation
                            style={{ flexShrink: 0, maxWidth: 342 }}
                        >
                            <AccountHelpExplanationPanel
                                key={accountExplanation.title}
                                accountExplanation={accountExplanation}
                            />
                        </div>
                    </div>
                    <AccountHelpDropdownFooter
                        onChange={onChange}
                        createNewAccountUrl={url}
                        showCreateAccountButton={isAuth.createAccount}
                        showAccountHelp={showAccountHelp}
                        toggleShowAccountHelp={() =>
                            setShowAccountHelp((prev) => !prev)
                        }
                    />
                </DropdownDrawer>
            )}
        </Dropdown>
    );
}

function AccountHelpDropdownOpener({
    className,
    children,
}: {
    className?: string;
    children?: ReactNode;
}) {
    useRegisterSelectedOptions();

    if (children) {
        return <>{children}</>;
    }

    return <DropdownOpener className={className} />;
}

function AccountHelpDropdownOptions({
    defaultOptions,
    useDataState,
    params,
    setDescription,
}: {
    defaultOptions?: AccountHelpDefaultOption[];
    useDataState: (
        query: string,
        params: AccountApiQueryParams
    ) => UseFetchPaginatedState<AccountWithHelpInfoDTO>;
    params: AccountApiQueryParams;
    setDescription: (explanation: AccountExplanation) => void;
}) {
    const query = useDropdownSearchQuery();
    const debouncedQuery = useDebouncedQuery(query, 250);

    const optimizedQuery = query.match(/^[0-9]{4}$/) ? query : debouncedQuery;
    const { data, isLoading, hasMore, loadMore, isEmpty } = useDataState(
        optimizedQuery,
        params
    );
    const loadMoreRef = useDropdownLoadMoreTarget<HTMLDivElement>(loadMore);

    useEffect(() => {
        setDescription({
            title: '',
            userDescription: '',
            description: '',
            tags: [],
        });
    }, [isEmpty, setDescription]);

    useTrackUnmatchedSearchQuery(optimizedQuery, isEmpty, isLoading);

    return (
        <>
            {query === '' &&
                defaultOptions?.map((option) => (
                    <AccountHelpDropdownOption
                        key={option.value}
                        value={option.value}
                        displayName={option.displayName}
                        onMouseEnter={() => {
                            setDescription({
                                title: '',
                                userDescription: '',
                                description: '',
                                tags: [],
                            });
                        }}
                    >
                        {option.displayName}
                    </AccountHelpDropdownOption>
                ))}
            {data?.map((option) => (
                <AccountHelpDropdownOption
                    key={option.id}
                    value={option.id}
                    displayName={option.displayName}
                    onMouseEnter={() => {
                        setDescription({
                            title: option.displayName ?? '',
                            userDescription: option.description ?? '',
                            description: option.accountHelp?.description ?? '',
                            tags: option.accountHelp?.tags ?? [],
                        });
                    }}
                    onFocus={() => {
                        setDescription({
                            title: option.displayName ?? '',
                            userDescription: option.description ?? '',
                            description: option.accountHelp?.description ?? '',
                            tags: option.accountHelp?.tags ?? [],
                        });
                    }}
                >
                    <div className={'atl-flex atl-gap-8'}>
                        <span style={{ minWidth: 36 }}>
                            {option.numberPretty}
                        </span>
                        <span>{option.account.name}</span>
                    </div>
                </AccountHelpDropdownOption>
            ))}
            {isEmpty ? <AccountHelpDropdownEmpty /> : null}
            {isLoading ? <LoadableDropdownSkeletonOptions /> : null}
            {hasMore ? <div ref={loadMoreRef} /> : null}
        </>
    );
}

function AccountHelpDropdownOption({
    value,
    displayName,
    children,
    onMouseEnter,
    className,
    onFocus,
}: Omit<OptionProps, 'children'> & {
    onMouseEnter?: React.MouseEventHandler<HTMLDivElement>;
    onFocus?: (value: string) => void;
    displayName: string;
    children: string | React.ReactNode;
}) {
    const { attributes, isSelected, hasFocus, isMultiple } = useDropdownOption({
        value: String(value),
        displayName,
    });
    const onFocusRef = useRef(onFocus);
    const previousFocus = usePrevious(hasFocus);

    useEffect(() => {
        if (hasFocus && !previousFocus) {
            onFocusRef.current?.(String(value));
        }
    }, [hasFocus, previousFocus, value]);

    return (
        <div
            className={classNames(
                'atl-dropdown__option',
                'krr-account-help__option',
                hasFocus && 'atl-dropdown__option--has-focus',
                isSelected && 'atl-font-medium',
                isSelected && !isMultiple && 'atl-bg-blue-10',

                className
            )}
            {...attributes}
            onMouseEnter={onMouseEnter}
        >
            {children}
            {isMultiple ? (
                isSelected ? (
                    <span className="atl-dropdown__checkbox atl-dropdown__checkbox-checked"></span>
                ) : (
                    <span className="atl-dropdown__checkbox"></span>
                )
            ) : null}
        </div>
    );
}

function AccountHelpDropdownEmpty() {
    return (
        <div
            className={
                'atl-flex atl-flex-col atl-items-center atl-justify-center krr-account-help__empty-search'
            }
        >
            <img
                alt="Search"
                className="atl-mb-24"
                src="/resources/imgs/Search_illustration.svg"
            />

            <div className={'atl-mb-16 atl-text-xl atl-font-medium atl-mb-16'}>
                {getMessage('text_no_accounts_found')}
            </div>
            <div style={{ width: 340 }} className={'atl-text-center'}>
                {getMessage('text_account_help_no_accounts_found_explanation')}
            </div>
        </div>
    );
}

export function AccountHelpDropdownFooter({
    createNewAccountUrl,
    onChange,
    showCreateAccountButton,
    showAccountHelp,
    toggleShowAccountHelp,
}: {
    createNewAccountUrl: string;
    onChange: (id: string) => void;
    showCreateAccountButton: boolean;
    showAccountHelp?: boolean;
    toggleShowAccountHelp?: () => void;
}) {
    const isAccountHelpAvailable =
        showAccountHelp !== undefined && toggleShowAccountHelp;
    if (!showCreateAccountButton && !isAccountHelpAvailable) {
        return null;
    }

    const canSeeHiddenAccounts = false;
    return (
        <div className={'atl-p-8 krr-account-help__footer'}>
            {canSeeHiddenAccounts && (
                <ToggleGroup>
                    <Input
                        id="showHiddenAccounts"
                        type="toggle"
                        data-testid={'show-hidden-accounts-toggle'}
                    />
                    <Label htmlFor="showHiddenAccounts">
                        {getMessage('text_account_help_show_hidden_accounts')}
                    </Label>
                </ToggleGroup>
            )}

            {showCreateAccountButton && (
                <CreateNewAccountButton
                    url={createNewAccountUrl}
                    onSuccess={(id: number) => {
                        onChange(String(id));
                    }}
                />
            )}

            {isAccountHelpAvailable && (
                <Button
                    style={{ marginLeft: 'auto' }}
                    onClick={toggleShowAccountHelp}
                    data-testid={'toggle-accounting-help-button'}
                    variant={'tertiary'}
                    aria-label={
                        showAccountHelp
                            ? getMessage('text_hide_description')
                            : getMessage('text_show_description')
                    }
                    className={'krr-account-help__show-account-help-button'}
                >
                    <span>
                        {showAccountHelp
                            ? getMessage('text_hide_description')
                            : getMessage('text_show_description')}
                    </span>
                    {showAccountHelp ? (
                        <ChevronLeftIcon />
                    ) : (
                        <ChevronRightIcon />
                    )}
                </Button>
            )}
        </div>
    );
}

function CreateNewAccountButton({
    url,
    onSuccess,
}: {
    url: string;
    onSuccess: (id: number) => void;
}) {
    return (
        <Button
            data-testid={'create-new-account-button'}
            variant={'tertiary'}
            aria-label={getMessage('text_create_new_account')}
            onClick={() =>
                renderDialog({
                    tooltipText: getMessage('text_create_new_account'),
                    url: url ?? '',
                    onSuccess: onSuccess,
                })
            }
        >
            {getMessage('text_create_new_account')}
        </Button>
    );
}

function getSelectedApiQueryUrl(selectedValues: string[]) {
    const ids = selectedValues.filter((value) => Number(value) > 0);

    if (ids.length === 0) {
        return null;
    }
    const url = new URL(`/v2/ledger/account/accountHelpQuery`, window.origin);
    url.searchParams.set(
        'fields',
        'id,displayName,numberPretty,accountHelp,account(number, name)'
    );

    // Remove duplicate slashes. Copied from LoadableDropdown [Tom Kong 2023-11-07]
    url.pathname = url.pathname.replace(/\/{2,}/, '/');
    url.searchParams.set('ids', ids.join(','));
    return url.toString();
}

function useRegisterSelectedOptions() {
    const registerOptions = useDropdownRegisterOptions();
    const unregisteredSelectedValues = useUnregisteredSelectedValues();
    const response = useSWR<ListResponse<AccountWithHelpInfoDTO>, FetcherError>(
        getSelectedApiQueryUrl(unregisteredSelectedValues),
        defaultFetcher,
        {
            revalidateOnFocus: false,
        }
    );
    const selectedOptions = response.data?.values;

    useEffect(() => {
        if (selectedOptions === undefined || selectedOptions.length === 0) {
            return;
        }

        const options = selectedOptions.map((item) => ({
            value: String(item.id),
            displayName: item.displayName,
        }));

        registerOptions(options);
    }, [registerOptions, selectedOptions]);
}

function useUnregisteredSelectedValues() {
    const options = useDropdownOptions();
    const [selectedValues] = useDropdownSelectedValues();

    return selectedValues.filter((value) => {
        return !options.some((option) => option.value === value);
    });
}

function usePrevious<T>(value: T) {
    const ref = useRef<T>(value);

    useEffect(() => {
        ref.current = value;
    }, [value]);

    return ref.current;
}
function getCreateNewButtonURL(
    accountType?: string,
    accountSubType?: string,
    accountRequiresVatType?: string
) {
    const accountTypeNumber = accountType ? parseInt(accountType) : -1;
    const accountSubTypeNumber = accountSubType ? parseInt(accountSubType) : -1;
    const requiresVatNumber = accountRequiresVatType
        ? parseInt(accountRequiresVatType)
        : -1;

    const params = new URLSearchParams();
    if (accountTypeNumber !== -1) {
        params.append('accountType', accountTypeNumber.toString());
    }

    if (accountSubTypeNumber !== -1) {
        params.append('accountSubType', accountSubTypeNumber.toString());
        params.append('lockSubType', 'true');
    } else {
        params.append('accountType', '1');
        params.append('accountSubType', '4000');
    }

    if (requiresVatNumber !== -1) {
        params.append('requiresVat', requiresVatNumber.toString());
    }

    return `account?${params.toString()}`;
}
