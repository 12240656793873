import { LogisticsModal } from '@Page/LogisticsV2/components/Modals/Modal';
import { NumberFormat } from '@Page/LogisticsV2/components/NumberFormat/NumberFormat';
import { MultipleValuesWithPopup } from '@Page/LogisticsV2/components/Popup/MultipleValuesPopup';
import { useLoadMoreTarget } from '@Page/LogisticsV2/hooks/fetcher';
import { NoProductsInCompany } from '@Page/LogisticsV2/pages/Product/overview/EmptyStateComponents';
import {
    ProductDTO,
    ProductOverviewFilterModel,
    ProductOverviewModel,
    StockDTO,
} from '@Page/LogisticsV2/pages/Product/overview/types';
import {
    DEFAULT_COLUMNS_NUMBER,
    getLink,
    updateUrl,
} from '@Page/LogisticsV2/pages/Product/overview/utils';
import { PageUrl, SortingType } from '@Page/LogisticsV2/utils/enums';
import {
    getSortDirection,
    getSorting,
    isNumberColumn,
} from '@Page/LogisticsV2/utils/functions';
import {
    Button,
    Input,
    KebabIcon,
    PopupMenu,
    PopupMenuItemButton,
    SkeletonCheckbox,
    SkeletonRow,
    SkeletonText,
    SortableTableHeader,
    usePopupMenu,
} from '@tlx/atlas';
import React, { useEffect, useRef, useState } from 'react';
import { nav } from '../../../../../../../js/modules/navigation';
import { getColumnLabel } from '@Page/LogisticsV2/pages/Product/overview/DisplayOptions/utils';
import { NoResultsEmptyState } from '@Page/LogisticsV2/components/EmptyState/EmptyState';
import { ALL_PRODUCT_GROUPS } from '@Page/LogisticsV2/pages/Stocktaking/utils';

export function ProductOverviewTable({
    products,
    filters,
}: {
    products: ProductOverviewModel;
    filters: ProductOverviewFilterModel;
}) {
    const loadMoreRef = useLoadMoreTarget<HTMLDivElement>(products.loadMore);
    const noFilterAndSearch =
        filters.query === '' &&
        filters.inventoryFilter === '' &&
        filters.supplierFilter.includes('') &&
        filters.incomeAccountFilter === '' &&
        filters.productGroupsFilter.includes(ALL_PRODUCT_GROUPS) &&
        filters.withoutMainSupplier === '' &&
        filters.productUnitFilter === '' &&
        filters.statusFilter.includes('active') &&
        filters.statusFilter.length === 1 &&
        filters.stockItemFilter === '';

    return (
        <table className="atl-table">
            <ProductOverviewTableHeader products={products} />
            <tbody className="atl-tbody">
                {products.data.length > 0 &&
                    products.data.map((product, index) => (
                        <ProductOverviewTableRow
                            key={index}
                            product={product}
                            filters={filters}
                            columns={products.columns}
                            selectedProducts={products.selectedIds}
                            onDelete={products.onDelete}
                            onChangeSelectedProducts={
                                products.onChangeSelectedIds
                            }
                        />
                    ))}
                {products.isLoading && (
                    <SkeletonRow>
                        <td className="atl-td atl-td--checkbox">
                            <SkeletonCheckbox />
                        </td>
                        {[
                            ...Array.from(
                                Array(
                                    products.columns.length +
                                        DEFAULT_COLUMNS_NUMBER
                                ).keys()
                            ),
                        ].map((index) => (
                            <td className="atl-td" key={index}>
                                <SkeletonText className="atl-w-full" />
                            </td>
                        ))}
                        <td className="atl-td">
                            <SkeletonText className="atl-w-full" />
                        </td>
                    </SkeletonRow>
                )}
                {!products.isLoading &&
                    products.data.length === 0 &&
                    noFilterAndSearch && (
                        <tr>
                            <td
                                className="atl-bg-white "
                                colSpan={
                                    products.columns.length +
                                    DEFAULT_COLUMNS_NUMBER
                                }
                            >
                                <NoProductsInCompany />
                            </td>
                        </tr>
                    )}
                {!products.isLoading &&
                    products.data.length === 0 &&
                    !noFilterAndSearch && (
                        <NoResultsEmptyState
                            colSpan={
                                products.columns.length + DEFAULT_COLUMNS_NUMBER
                            }
                            onClick={() => updateUrl()}
                        />
                    )}
                {products.hasMore && (
                    <tr>
                        <td>
                            <div ref={loadMoreRef} />
                        </td>
                    </tr>
                )}
            </tbody>
        </table>
    );
}

export function ProductOverviewTableHeader({
    products,
}: {
    products: ProductOverviewModel;
}) {
    const globalCheckboxRef = useRef<HTMLInputElement | null>(null);
    const [globalCheckboxStatus, setGlobalCheckboxStatus] = useState<
        'off' | 'on' | 'indeterminate'
    >('off');
    const customerId =
        document.querySelector<HTMLInputElement>(
            '#customerIdForProductOverview'
        )?.value ?? null;

    useEffect(() => {
        if (!globalCheckboxRef.current) {
            return;
        }
        switch (globalCheckboxStatus) {
            case 'indeterminate':
                globalCheckboxRef.current.checked = false;
                globalCheckboxRef.current.indeterminate = true;
                break;
            case 'on':
                globalCheckboxRef.current.checked = true;
                globalCheckboxRef.current.indeterminate = false;
                break;
            case 'off':
            default:
                globalCheckboxRef.current.checked = false;
                globalCheckboxRef.current.indeterminate = false;
                break;
        }
    }, [globalCheckboxStatus]);

    const handleCheckboxChange = (status: 'off' | 'on' | 'indeterminate') => {
        switch (status) {
            case 'on':
            case 'indeterminate': {
                products.onChangeSelectedIds([]);
                break;
            }
            case 'off': {
                products.onChangeSelectedIds(
                    products.data.map((data) => {
                        return { id: data.id };
                    })
                );
                break;
            }
        }
    };

    useEffect(() => {
        if (products.totalLines.length === undefined) {
            return;
        }
        if (products.selectedIds.length === products.totalLines.length) {
            setGlobalCheckboxStatus('on');
        }
        if (!products.selectedIds.length) {
            setGlobalCheckboxStatus('off');
        }
        if (
            products.selectedIds.length > 0 &&
            products.selectedIds.length !== products.totalLines.length
        ) {
            setGlobalCheckboxStatus('indeterminate');
        }
    }, [products.selectedIds, products.totalLines]);

    return (
        <thead className="atl-thead atl-thead--sticky">
            <tr className="atl-tr">
                <th className="atl-th atl-th--checkbox atl-align-middle">
                    <Input
                        type="checkbox"
                        data-testid="product-overview-checkbox-header"
                        value={'all'}
                        ref={globalCheckboxRef}
                        onChange={() =>
                            handleCheckboxChange(globalCheckboxStatus)
                        }
                    />
                </th>
                <SortableTableHeader
                    data-testid="product-overview-number-header"
                    className="atl-th atl-align-middle"
                    sortDirection={getSortDirection(
                        products.sorting,
                        SortingType.NUMBER_ASCENDING,
                        SortingType.NUMBER_DESCENDING
                    )}
                    onClick={() =>
                        products.onChangeSorting(
                            getSorting(
                                products.sorting,
                                SortingType.NUMBER_ASCENDING,
                                SortingType.NUMBER_DESCENDING
                            )
                        )
                    }
                >
                    {getMessage('text_number')}
                </SortableTableHeader>
                <SortableTableHeader
                    data-testid="product-overview-name-header"
                    className="atl-th atl-align-middle"
                    sortDirection={getSortDirection(
                        products.sorting,
                        SortingType.NAME_ASCENDING,
                        SortingType.NAME_DESCENDING
                    )}
                    onClick={() =>
                        products.onChangeSorting(
                            getSorting(
                                products.sorting,
                                SortingType.NAME_ASCENDING,
                                SortingType.NAME_DESCENDING
                            )
                        )
                    }
                >
                    {getMessage('text_name')}
                </SortableTableHeader>
                {products.columns.map((column, index) => {
                    let label = getColumnLabel(column);
                    if (
                        (!products.filters.supplierFilter.includes('') ||
                            customerId !== null) &&
                        column === 'costExcludingVatCurrency'
                    ) {
                        label += ' (NOK)';
                    }
                    return (
                        <th
                            className={`atl-th atl-align-middle ${
                                isNumberColumn(column) ? 'atl-text-right' : ''
                            }`}
                            key={`${column}-${index}`}
                        >
                            {label}
                        </th>
                    );
                })}
                {!moduleRackbeat && <th className="atl-th atl-align-middle" />}
            </tr>
        </thead>
    );
}

export function ProductOverviewTableRow({
    product,
    columns,
    filters,
    selectedProducts,
    onChangeSelectedProducts,
    onDelete,
}: {
    product: ProductDTO;
    filters: ProductOverviewFilterModel;
    columns: string[];
    selectedProducts: Array<{ id: number }>;
    onChangeSelectedProducts: (ids: Array<{ id: number }>) => void;
    onDelete: (id: number) => void;
}) {
    const [showDeleteWarning, setShowDeleteWarning] = useState(false);
    const { openerButtonProps, popupMenuProps } = usePopupMenu();
    const supplierId =
        document.querySelector<HTMLInputElement>(
            '#customerIdForProductOverview'
        )?.value ?? null;

    const handleCheckChange = (checked: boolean, value: string): void => {
        let nextSelectedIds: Array<{ id: number }> = new Array(
            ...selectedProducts
        );
        if (checked) {
            nextSelectedIds.push({ id: Number(value) });
        } else {
            nextSelectedIds = nextSelectedIds.filter(
                (product) => product.id !== Number(value)
            );
        }
        onChangeSelectedProducts(nextSelectedIds);
    };
    return (
        <tr
            className="atl-tr atl-tr--highlight"
            style={{ cursor: 'pointer' }}
            onClick={(e) =>
                setTimeout(() => {
                    nav.popup(
                        getLink(product.id),
                        e.ctrlKey || e.metaKey ? '_blank' : '_self'
                    );
                }, 0)
            }
        >
            <td
                className="atl-td atl-td--checkbox atl-align-middle"
                onClick={(e) => e.stopPropagation()}
            >
                <Input
                    type="checkbox"
                    data-testid={'product-overview-checkbox-row'}
                    value={product.id}
                    checked={selectedProducts.some(
                        (value) => value.id === product.id
                    )}
                    onChange={(e) =>
                        handleCheckChange(e.target.checked, e.target.value)
                    }
                />
            </td>
            <td className="atl-td atl-align-middle">
                <a href={getLink(product.id)} className="atl-text-grey-100">
                    {product.displayNumber}
                </a>
            </td>
            <td className="atl-td atl-align-middle">
                <a href={getLink(product.id)} className="atl-text-grey-100">
                    {product.name}
                </a>
            </td>
            {columns.map((column) => (
                <ProductOverviewTableCell
                    key={column}
                    column={column}
                    product={product}
                    filters={filters}
                />
            ))}
            {!moduleRackbeat &&
                (supplierId === null || product.isDeletable ? (
                    <td
                        className="atl-td atl-td--button atl-flex atl-justify-end"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div>
                            <Button
                                data-testid="product-row-actions"
                                variant="icon"
                                {...openerButtonProps}
                            >
                                <KebabIcon />
                            </Button>
                        </div>

                        <PopupMenu {...popupMenuProps}>
                            {supplierId === null && (
                                <PopupMenuItemButton
                                    data-testid="product-overview-copy-action"
                                    onClick={() =>
                                        nav.popup(
                                            addContextId(
                                                `${PageUrl.PRODUCT_MENU}?productId=0&templateId=${product.id}`
                                            )
                                        )
                                    }
                                >
                                    <div style={{ minWidth: '100px' }}>
                                        {getMessage('text_copy')}
                                    </div>
                                </PopupMenuItemButton>
                            )}
                            {product.isDeletable && (
                                <PopupMenuItemButton
                                    data-testid="product-overview-delete-action"
                                    onClick={() => setShowDeleteWarning(true)}
                                >
                                    {getMessage('text_delete')}
                                </PopupMenuItemButton>
                            )}
                        </PopupMenu>
                        {showDeleteWarning && (
                            <LogisticsModal
                                title={getMessage('text_warning')}
                                svgIcon="warning"
                                onConfirm={() => {
                                    setShowDeleteWarning(false);
                                    onDelete(product.id);
                                }}
                                onCancel={() => setShowDeleteWarning(false)}
                            >
                                {getMessage('text_delete_product_message')}
                            </LogisticsModal>
                        )}
                    </td>
                ) : (
                    <td className="atl-td"></td>
                ))}
        </tr>
    );
}

function ProductOverviewTableCell({
    column,
    product,
    filters,
}: {
    column: string;
    product: ProductDTO;
    filters: ProductOverviewFilterModel;
}): JSX.Element {
    const englishLanguage = window.locale.includes('en');
    switch (column) {
        case 'stockOfGoods':
            return <InventoriesCell product={product} filters={filters} />;
        case 'warehouse':
            return <WarehouseCell product={product} filters={filters} />;
        case 'costExcludingVatCurrency':
            return (
                <td className="atl-td atl-align-middle atl-text-right">
                    <a href={getLink(product.id)} className="atl-text-grey-100">
                        <NumberFormat
                            value={product.costExcludingVatCurrency}
                            options={{
                                style: 'decimal',
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2,
                            }}
                        />
                    </a>
                </td>
            );
        case 'priceExcludingVatCurrency':
            return (
                <td className="atl-td atl-align-middle atl-text-right">
                    <a href={getLink(product.id)} className="atl-text-grey-100">
                        <NumberFormat
                            value={product.priceExcludingVatCurrency}
                            options={{
                                style: 'decimal',
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2,
                            }}
                        />
                    </a>
                </td>
            );
        case 'currency(code)':
            return (
                <td className="atl-td atl-align-middle">
                    <a href={getLink(product.id)} className="atl-text-grey-100">
                        {product.currency?.code}
                    </a>
                </td>
            );
        case 'account(displayName)':
            return (
                <td
                    className="atl-td atl-align-middle atl-overflow-ellipsis atl-whitespace-nowrap atl-overflow-hidden"
                    style={{ maxWidth: '290px' }}
                >
                    <a
                        href={getLink(product.id)}
                        className="atl-text-grey-100 "
                    >
                        {product.account !== null
                            ? product.account?.displayName
                            : getMessage('option_use_std_account_order_lines')}
                    </a>
                </td>
            );
        case 'productUnit':
            return (
                <td className="atl-td atl-align-middle">
                    <a href={getLink(product.id)} className="atl-text-grey-100">
                        {product.productUnit
                            ? englishLanguage
                                ? product.productUnit.nameShortEN ||
                                  product.productUnit.nameEN
                                : product.productUnit.nameShort
                            : '-'}
                    </a>
                </td>
            );
        case 'vatType(displayName)':
            return (
                <td
                    className="atl-td atl-align-middle atl-overflow-ellipsis atl-whitespace-nowrap atl-overflow-hidden"
                    style={{ maxWidth: '290px' }}
                >
                    <a
                        href={getLink(product.id)}
                        className="atl-text-grey-100 "
                    >
                        {product.vatType?.displayName}
                    </a>
                </td>
            );
        case 'priceIncludingVatCurrency':
            return (
                <td className="atl-td atl-align-middle atl-text-right">
                    <a href={getLink(product.id)} className="atl-text-grey-100">
                        <NumberFormat
                            value={product.priceIncludingVatCurrency}
                            options={{
                                style: 'decimal',
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2,
                            }}
                        />
                    </a>
                </td>
            );
        case 'ean':
            return (
                <td className="atl-td atl-align-middle">
                    <a href={getLink(product.id)} className="atl-text-grey-100">
                        {product.ean}
                    </a>
                </td>
            );
        case 'availableStock':
            return (
                <td className="atl-td atl-align-middle atl-text-right">
                    {isNaN(product.availableStock) ? (
                        <SkeletonText className="atl-w-full" />
                    ) : (
                        <a
                            href={getLink(product.id)}
                            className="atl-text-grey-100"
                        >
                            <NumberFormat
                                value={product.availableStock}
                                options={{
                                    style: 'decimal',
                                    maximumFractionDigits: 2,
                                }}
                            />
                        </a>
                    )}
                </td>
            );
        case 'incomingStock':
            return (
                <td className="atl-td atl-align-middle atl-text-right">
                    <a href={getLink(product.id)} className="atl-text-grey-100">
                        <NumberFormat
                            value={product.incomingStock}
                            options={{
                                style: 'decimal',
                                maximumFractionDigits: 2,
                            }}
                        />
                    </a>
                </td>
            );
        case 'outgoingStock':
            return (
                <td className="atl-td atl-align-middle atl-text-right">
                    <a href={getLink(product.id)} className="atl-text-grey-100">
                        <NumberFormat
                            value={product.outgoingStock}
                            options={{
                                style: 'decimal',
                                maximumFractionDigits: 2,
                            }}
                        />
                    </a>
                </td>
            );
        case 'costCurrency':
            return (
                <td className="atl-td atl-align-middle atl-text-right">
                    <a href={getLink(product.id)} className="atl-text-grey-100">
                        <NumberFormat
                            value={product.costCurrency}
                            options={{
                                style: 'decimal',
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2,
                            }}
                        />
                    </a>
                </td>
            );
        case 'description':
            return (
                <td className="atl-td atl-align-middle">
                    <a
                        href={getLink(product.id)}
                        className="atl-text-grey-100 "
                    >
                        {product.description}
                    </a>
                </td>
            );
        case 'orderLineDescription':
            return (
                <td className="atl-td atl-align-middle">
                    <a href={getLink(product.id)} className="atl-text-grey-100">
                        {product.orderLineDescription}
                    </a>
                </td>
            );
        default:
            throw new Error(`Unknown column: ${column}`);
    }
}

const SingleInventory = ({ inventories }: { inventories: StockDTO[] }) => {
    return <div>{inventories[0]?.inventory}</div>;
};

const MultipleInventories = ({ inventories }: { inventories: StockDTO[] }) => {
    const [showMultipleInventories, setShowMultipleInventories] =
        useState(false);
    return (
        <MultipleValuesWithPopup
            open={showMultipleInventories}
            setOpen={setShowMultipleInventories}
            text={getMessage('text_warehouses_number', inventories.length)}
        >
            {inventories.map((inventory: StockDTO, index) => (
                <div
                    key={`${inventory?.inventoryId} + ${index}`}
                    className="atl-flex atl-flex-row atl-items-center atl-gap-16 atl-justify-between"
                >
                    <div style={{ width: '200px' }}>{inventory.inventory}</div>
                    <div className="atl-flex atl-flex-row atl-gap-32">
                        <div>{inventory?.inventoryLocation?.displayName}</div>
                        <NumberFormat
                            value={inventory.closingStock}
                            options={{
                                style: 'decimal',
                                maximumFractionDigits: 2,
                            }}
                        />
                    </div>
                </div>
            ))}
        </MultipleValuesWithPopup>
    );
};

const FilteredInventory = ({
    inventories,
    inventoryFilter,
}: {
    inventories: StockDTO[];
    inventoryFilter: string;
}) => {
    const inventory = inventories.find(
        (inventory) => inventory.inventoryId === Number(inventoryFilter)
    );
    return <div>{inventory?.inventory}</div>;
};

const WarehouseCell = ({
    product,
    filters,
}: {
    product: ProductDTO;
    filters: ProductOverviewFilterModel;
}) => {
    return (moduleLogistics || moduleLogisticsLight) && moduleWarehouse ? (
        <td className="atl-td atl-align-middle">
            <a href={getLink(product.id)} className="atl-text-grey-100">
                {!product.inventories && (
                    <SkeletonText className="atl-w-full" />
                )}
                {!product.isStockItem &&
                    getMessage('text_not_warehouse_product')}
                {product.isStockItem && product.inventories && (
                    <>
                        {product.inventories.length === 0 && <div>-</div>}
                        {product.inventories.length === 1 &&
                            (product.inventories[0].inventoryLocation ? (
                                <MultipleInventories
                                    inventories={product.inventories}
                                />
                            ) : (
                                <SingleInventory
                                    inventories={product.inventories}
                                />
                            ))}
                        {product.inventories.length > 1 &&
                            filters.inventoryFilter.length === 0 && (
                                <MultipleInventories
                                    inventories={product.inventories}
                                />
                            )}
                        {product.inventories.length > 1 &&
                            filters.inventoryFilter.length !== 0 && (
                                <FilteredInventory
                                    inventories={product.inventories}
                                    inventoryFilter={filters.inventoryFilter}
                                />
                            )}
                    </>
                )}
            </a>
        </td>
    ) : (
        <></>
    );
};

const InventoriesCell = ({
    product,
    filters,
}: {
    product: ProductDTO;
    filters: ProductOverviewFilterModel;
}) => {
    return moduleLogistics || moduleLogisticsLight ? (
        <td className="atl-td atl-align-middle atl-text-right">
            {isNaN(product.stockOfGoods) && (
                <SkeletonText className="atl-w-full" />
            )}
            {product.isStockItem &&
                !filters.inventoryFilter &&
                !isNaN(product.stockOfGoods) && (
                    <a href={getLink(product.id)} className="atl-text-grey-100">
                        <NumberFormat
                            value={product.stockOfGoods}
                            options={{
                                style: 'decimal',
                                maximumFractionDigits: 2,
                            }}
                        />
                    </a>
                )}
            {product.isStockItem &&
                filters.inventoryFilter &&
                !isNaN(product.stockOfGoods) && (
                    <a href={getLink(product.id)} className="atl-text-grey-100">
                        <NumberFormat
                            value={
                                product.inventories?.find(
                                    (inventory) =>
                                        inventory.inventoryId ===
                                        Number(filters.inventoryFilter)
                                )?.closingStock ?? 0
                            }
                            options={{
                                style: 'decimal',
                                maximumFractionDigits: 2,
                            }}
                        />
                    </a>
                )}
            {!product.isStockItem && <div>-</div>}
        </td>
    ) : (
        <></>
    );
};
