import { UseApiPaginatedReturn } from '@Page/LogisticsV2/types/returnTypes';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import useSWRInfinite from 'swr/infinite';
import { ListResponse } from '../../../hooks/fetch/types';
import { useFetchPaginatedState } from '../../../hooks/fetch/useFetchPaginatedState';
import { MethodType } from '../utils/enums';
import { invokeAPI } from './api';
import { INFINITE_SCROLL_PAGE_SIZE } from '@Page/LogisticsV2/utils/constants';

export function usePaginatedDataFetcher<T>(
    url: string,
    queryParams: { [name: string]: string | boolean | undefined }
): UseApiPaginatedReturn<T[]> {
    const [search, setSearch] = useState('');
    function getKey(pageIndex: number): string | null {
        const pageSize = 50;
        const from = pageSize * pageIndex;
        const searchParams = new URLSearchParams();
        Object.entries(queryParams).forEach(([key, value]) => {
            if (value !== undefined && value !== '') {
                searchParams.set(key, value.toString());
            }
        });
        searchParams.set('count', pageSize.toString());
        searchParams.set('from', from.toString());
        searchParams.set('query', search);
        return `${url}?${searchParams}`;
    }
    const response = useSWRInfinite<ListResponse<T>>(
        getKey,
        async (url: string) => {
            const { data, error } = await invokeAPI(url, MethodType.GET);
            const result = await data?.json();
            return result;
        },
        {
            revalidateOnFocus: false,
            shouldRetryOnError: false,
        }
    );
    const { data, error, isLoading } = useFetchPaginatedState(response);
    const hasMore = getHasMore(response.data);
    function loadMore() {
        if (hasMore && !response.isValidating) {
            response.setSize(response.size + 1);
        }
    }
    return {
        data,
        error,
        isLoading,
        hasMore,
        loadMore,
        refresh: response.mutate,
        query: search,
        search: setSearch,
    };
}

/*Needed because of Authorization Manager to make an extra call to backend*/
export function getHasMore(pages: ListResponse<any>[] | undefined): boolean {
    const lastPage = pages?.[pages?.length - 1];
    return lastPage
        ? lastPage.values.length === INFINITE_SCROLL_PAGE_SIZE
        : false;
}

/*for triggering fetch more data*/
export function useLoadMoreTarget<T extends HTMLElement>(
    loadMore: () => void,
    { root, rootMargin, threshold }: IntersectionObserverInit = {}
): MutableRefObject<T | null> {
    const targetRef = useRef<T | null>(null);
    useEffect(() => {
        const target = targetRef.current;
        if (target === null) {
            return;
        }
        function onIntersection(entries: IntersectionObserverEntry[]) {
            if (entries[0].isIntersecting) {
                loadMore();
            }
        }
        const observer = new IntersectionObserver(onIntersection, {
            root,
            rootMargin,
            threshold,
        });
        observer.observe(target);
        return () => {
            observer.unobserve(target);
        };
    }, [root, rootMargin, threshold, loadMore]);
    return targetRef;
}
