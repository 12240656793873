import $ from 'jquery';
import { tlxAlert } from './alert';
import { getPageTitleWithNewTitle } from './pageTitle';

export const app = (function () {
    let tlxHaveRunCES = false;

    return {
        outermostScope: getOutermostScope,

        setTitle: function (title) {
            document.title = getPageTitleWithNewTitle(document.title, title);
        },

        runCES: function tlxRunCES(scenario) {
            if (!tlxHaveRunCES) {
                tlxHaveRunCES = true;
                if (
                    window.wootricSettings &&
                    window.wootricSettings.properties &&
                    window.tlx_wootric_serve_ces &&
                    window.wootric
                ) {
                    window.wootricSettings.properties.scenario = scenario;
                    window.wootric('run');
                }
            }
        },

        /**
         * Load script with tlxInitializeState function asynchonously, and call this
         * function after the script is loaded.
         *
         * If the script is already loaded synchronously, just run it directly.
         *
         * This handles calling the tlxInitializeState function, and provides it with
         * scope and the dataset of values given to the script tag.
         *
         */
        loadPageScript: function ($scope, doneLoadingScript) {
            function onloadHandler(dataset) {
                try {
                    window.tlxInitializeState($scope.get(0), dataset);
                    $scope.trigger('tlxInitializeStateDone');
                    window.dispatchEvent(
                        new CustomEvent('pageLoad-initializeStateDone')
                    );
                    if (doneLoadingScript) {
                        doneLoadingScript();
                    }
                } catch (e) {
                    window.Sentry.captureException(e);
                    tlxAlert(getMessage('validation_unexpected_error'));

                    throw e;
                } finally {
                    window.tlxInitializeState = null; // should never be called more than once
                }
            }

            const script = $scope.find('script[type="tlxInitializeState"]');

            if (script.length === 0) {
                if (window.tlxInitializeState) {
                    onloadHandler({});
                }

                return;
            }

            const src = script.attr('src');
            const scriptTag = document.createElement('script');
            scriptTag.src = src;
            scriptTag.async = true;

            scriptTag.onload = function () {
                onloadHandler(script[0].dataset);
            };

            script[0].parentElement.insertBefore(scriptTag, null);

            // Make sure this script is only loaded once. A never ending recursion will incure
            // on pages that load page specific scripts for lazy loaded report tables.
            // See console.warn messages in loadContentLazily for more information (this mess
            // should be cleaned up).
            script.remove();
        },
    };
})();

/* Returns either the active scope for current tab, or the ajax content container, or
 * for some special cases, the #bodyContent in error pages. */
export function getOutermostScope() {
    const $scope = $('.tlxScope.ui-tabs-panel:visible');
    if ($scope.length == 1) {
        return $scope;
    }

    // #bodyContent is used on some error pages
    // we should not have both in dom at the same time?
    return $('#bodyContent, #ajaxContent');
}
