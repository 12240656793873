import { useEffect, useState } from 'react';

export type DeviceType = 'mobile' | 'tablet' | 'desktop';

function calculateDeviceType(windowWidth: number): DeviceType {
    if (windowWidth < 480) {
        return 'mobile';
    } else if (windowWidth <= 1024) {
        return 'tablet';
    } else {
        return 'desktop';
    }
}

export function useDeviceType(): DeviceType {
    const [deviceType, setDeviceType] = useState<DeviceType>(
        calculateDeviceType(window.innerWidth)
    );

    useEffect(() => {
        const listener = () => {
            const newDeviceType = calculateDeviceType(window.innerWidth);
            if (newDeviceType !== deviceType) {
                setDeviceType(newDeviceType);
            }
        };
        window.addEventListener('resize', listener);
        return () => {
            window.removeEventListener('resize', listener);
        };
    }, [deviceType]);

    return deviceType;
}
