import { Option } from '../../types';
import { notUndefined } from '@General/Helpers';
import { ModelAPIV1 } from '@General/types/Api';
import { JSPDropdownFetcher } from '../JSPDropdownFetcher';
import { ApiResultMapper } from '@General/Provider';
import * as React from 'react';

interface TravelReportRateCategoryGroupEntry extends ModelAPIV1 {
    readonly displayName: string;
    readonly name: string;
    readonly abroad: string;
}

const TravelReportRateCategoryGroupMapper: ApiResultMapper<TravelReportRateCategoryGroupEntry> =
    (item) => {
        return {
            value: item.id + '',
            displayName: item.name,
            name: item.name,
            item,
        };
    };

class TravelReportRateCategoryGroupFetcher extends JSPDropdownFetcher<TravelReportRateCategoryGroupEntry> {
    asyncJsonrpcGetter: Function;

    constructor(scope: HTMLDivElement, queryAttributes: Record<string, any>) {
        super(scope, queryAttributes, TravelReportRateCategoryGroupMapper);

        this.asyncJsonrpcGetter = asyncrpc.TravelReportRateCategoryGroup.get;
    }

    getMarshallSpec(): { marshallSpec: string[] } {
        return window.marshallSpec('id', 'name', 'abroad');
    }

    getParams(): ObjectIndex {
        return {
            departureDate: this.getQueryAttribute('departureDate', ''),
            arrivalDate: this.getQueryAttribute('arrivalDate', ''),
        };
    }

    async fetchList(
        params: ObjectIndex
    ): Promise<Option<TravelReportRateCategoryGroupEntry>[]> {
        return new Promise((resolve, reject) => {
            if (params.departureDate === '' && params.arrivalDate === '') {
                resolve([]);
                return;
            }
            try {
                jsonrpc.TravelReportRateCategoryGroup.getActiveByArrivalAndDepDate(
                    (result: TravelReportRateCategoryGroupEntry[] | null) => {
                        const mapped = (result ?? [])
                            .filter(notUndefined)
                            .map(this.getMapper(params))
                            .filter(notUndefined);
                        resolve(mapped);
                    },
                    this.getMarshallSpec(),
                    params.departureDate,
                    params.arrivalDate
                );
            } catch (e) {
                defaultJsonRpcExceptionHandler(e);
                reject(e);
            }
        });
    }
}

export const exports = {
    fetcher: TravelReportRateCategoryGroupFetcher,
    type: 'TravelReportRateCategoryGroup',
};
