import { SagaIterator } from 'redux-saga';
import { put, takeEvery } from 'redux-saga/effects';

import { FetchEntitlement, UpdateEntitlement } from '../action/General.action';
import { getEntitlements } from '@General/getEntitlements';

function* sagaFetchEntitlement(): SagaIterator {
    const entitlements = getEntitlements();
    yield put(UpdateEntitlement({ entitlements }));
}

export const generalSaga = [
    takeEvery(FetchEntitlement.type, sagaFetchEntitlement),
];
