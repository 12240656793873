import {
    ALL_WAREHOUSES,
    DEFAULT_COLUMNS_NUMBER,
    INVENTORY_CORRECTION_FIELDS,
    INVENTORY_CORRECTION_FIELDS_WITH_STOCK_CHANGES,
    InventoryCorrectionDTO,
} from '@Page/LogisticsV2/pages/InventoryCorrection/overview/types';
import { useInfiniteScrollData } from '@Page/LogisticsV2/hooks/api';
import {
    SkeletonRow,
    SkeletonText,
    TableLayout,
    TableLayoutContent,
    TableLayoutTopbar,
} from '@tlx/atlas';
import {
    Settings,
    TableColumnSetting,
    TableColumnsSetting,
    useSearchParam,
    useTableColumnsSetting,
} from '@tlx/astro-shared';
import { useLoadMoreTarget } from '@Page/LogisticsV2/hooks/fetcher';
import React from 'react';
import {
    getAvailableColumnsInInventoryCorrection,
    getColumnLabel,
    getDefaultColumnsInInventoryCorrection,
    getInventoryCorrectionsDisplayOptionsName,
} from '@Page/LogisticsV2/pages/InventoryCorrection/overview/utils';
import { InventoryCorrectionTableFilterComponents } from '@Page/LogisticsV2/pages/InventoryCorrection/overview/table/InventoryCorrectionTableFilterComponents';
import { InventoryCorrectionTableHeader } from '@Page/LogisticsV2/pages/InventoryCorrection/overview/table/InventoryCorrectionTableHeaders';
import { InventoryCorrectionTableRow } from '@Page/LogisticsV2/pages/InventoryCorrection/overview/table/InventoryCorrectionTableRows';
import { ApiUrl, PageUrl, SortingType } from '@Page/LogisticsV2/utils/enums';
import useSessionStorage from '@Page/LogisticsV2/hooks/useSessionStorage';
import { NoResultsEmptyState } from '@Page/LogisticsV2/components/EmptyState/EmptyState';
import { InventoryCorrectionOverviewEmptyState } from '@Page/LogisticsV2/pages/InventoryCorrection/overview/InventoryCorrectionComponents';

export function InventoryCorrectionTableWithFilters() {
    const query = useSearchParam('query');
    const warehouse = useSearchParam('warehouse', ALL_WAREHOUSES);
    const [sorting] = useSessionStorage<SortingType>(
        'goldshark_inventory_correction_overview_sorting',
        SortingType.DATE_DESCENDING
    );

    const { data, isLoading, hasMore, loadMore } =
        useInfiniteScrollData<InventoryCorrectionDTO>(
            ApiUrl.INVENTORY_CORRECTION,
            {
                fields: isLocationEnabled
                    ? INVENTORY_CORRECTION_FIELDS_WITH_STOCK_CHANGES
                    : INVENTORY_CORRECTION_FIELDS,
                warehouseIds: warehouse,
                query: query,
                sorting: sorting,
            }
        );

    const enabledColumnsForInventoryCorrectionOverview = useTableColumnsSetting(
        getInventoryCorrectionsDisplayOptionsName(),
        getDefaultColumnsInInventoryCorrection()
    );

    const loadMoreRef = useLoadMoreTarget<HTMLDivElement>(loadMore);
    const noFilters = !query && warehouse === ALL_WAREHOUSES;
    const columnsNumber =
        enabledColumnsForInventoryCorrectionOverview.length +
        DEFAULT_COLUMNS_NUMBER;

    if (data === undefined) {
        return null;
    }

    return (
        <TableLayout>
            <TableLayoutTopbar className="atl-flex atl-p-12 atl-gap-16 atl-justify-between">
                <InventoryCorrectionTableFilterComponents />
                <Settings>
                    <TableColumnsSetting
                        name={getInventoryCorrectionsDisplayOptionsName()}
                        label={getMessage('text_columns')}
                        aria-label="columns-for-inventory-correction-overview"
                        defaultColumns={getDefaultColumnsInInventoryCorrection()}
                    >
                        {getAvailableColumnsInInventoryCorrection().map(
                            (column) => (
                                <TableColumnSetting key={column} value={column}>
                                    {getColumnLabel(column)}
                                </TableColumnSetting>
                            )
                        )}
                    </TableColumnsSetting>
                </Settings>
            </TableLayoutTopbar>
            <TableLayoutContent
                style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 225px)' }}
            >
                <table className="atl-table">
                    <InventoryCorrectionTableHeader
                        columns={enabledColumnsForInventoryCorrectionOverview}
                    />
                    <tbody className="atl-tbody">
                        {data.map((inventoryCorrection) => (
                            <InventoryCorrectionTableRow
                                key={inventoryCorrection.id}
                                inventoryCorrection={inventoryCorrection}
                                columns={
                                    enabledColumnsForInventoryCorrectionOverview
                                }
                            />
                        ))}
                        {isLoading && (
                            <SkeletonRow>
                                {[
                                    ...Array.from(Array(columnsNumber).keys()),
                                ].map((index) => (
                                    <td className="atl-td" key={index}>
                                        <SkeletonText className="atl-w-full" />
                                    </td>
                                ))}
                            </SkeletonRow>
                        )}
                        {!isLoading && data.length === 0 && !noFilters && (
                            <NoResultsEmptyState
                                colSpan={columnsNumber}
                                onClick={() =>
                                    window.history.pushState(
                                        {},
                                        '',
                                        addContextId(
                                            `${PageUrl.INVENTORY_CORRECTION_OVERVIEW}?warehouse=${ALL_WAREHOUSES}`
                                        )
                                    )
                                }
                            />
                        )}
                        {!isLoading && data.length === 0 && noFilters && (
                            <InventoryCorrectionOverviewEmptyState
                                colSpan={columnsNumber}
                            />
                        )}
                        {hasMore && (
                            <tr>
                                <td>
                                    <div ref={loadMoreRef} />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </TableLayoutContent>
        </TableLayout>
    );
}
