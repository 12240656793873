import { LogisticsModal } from '@Page/LogisticsV2/components/Modals/Modal';
import { PurchaseOrderOverviewTable } from '@Page/LogisticsV2/pages/PurchaseOrder/overview/PurchaseOrderOverviewTable';
import { PageUrl } from '@Page/LogisticsV2/utils/enums';
import {
    Alert,
    AlertContent,
    AlertDescription,
    AlertHeader,
    Button,
    DeleteIcon,
    EditIcon,
    KebabIcon,
    PopupMenu,
    PopupMenuItemButton,
    SendIcon,
    TableLayout,
    TableLayoutContent,
    TableLayoutTopbar,
    usePopupMenu,
} from '@tlx/atlas';
import {
    BatchActions,
    BatchActionsButton,
    BatchActionsButtons,
    BatchActionsTitle,
} from '@tlx/astro-shared';
import classNames from 'classnames';
import { ReactNode, useState } from 'react';
import '../style.css';
import { PurchaseOrderOverviewDisplayOptions } from './DisplayOptions/PurchaseOrderOverviewDisplayOptions';
import { PurchaseOrderOverviewFilters } from './PurchaseOrderOverviewFilters';
import { ChangeStatusModal } from './components';
import {
    OLD_PURCHASEORDER,
    PurchaseOrderDTO,
    PurchaseOrderOverviewActionModel,
    PurchaseOrderOverviewBulkActionModel,
    PurchaseOrderOverviewModel,
    PurchaseOrderStatus,
} from './types';

export const PurchaseOrderOverviewContainer = ({
    children,
}: {
    children: ReactNode;
}) => {
    return <form autoComplete="off">{children}</form>;
};

export const PurchaseOrderOverviewHeader = () => {
    return (
        <div className="atl-flex atl-items-center goldshark-purchase-order-overview__top-buttons">
            <PurchaseOrderOverviewTopActions />
        </div>
    );
};

export const PurchaseOrderOverviewTableWithFilters = ({
    purchaseOrders,
    actions,
    bulkActions,
}: {
    purchaseOrders: PurchaseOrderOverviewModel;
    actions: PurchaseOrderOverviewActionModel;
    bulkActions: PurchaseOrderOverviewBulkActionModel;
}) => {
    const validLines = Object.values(purchaseOrders.selectedLines).filter(
        (line) =>
            line.status === PurchaseOrderStatus.OPEN ||
            line.status === PurchaseOrderStatus.CONFIRMED ||
            line.status === PurchaseOrderStatus.SENT ||
            line.status === PurchaseOrderStatus.RECEIVING ||
            line.status === PurchaseOrderStatus.CANCELLED ||
            (line.status === PurchaseOrderStatus.CLOSED &&
                line.receivedDate === null)
    );

    return (
        <TableLayout>
            <PurchaseOrderBulkActionBar
                selectedLines={validLines}
                bulkActions={bulkActions}
            />
            <TableLayoutTopbar
                hidden={Object.keys(purchaseOrders.selectedLines).length !== 0}
            >
                <PurchaseOrderOverviewFilters />
                <div className="atl-mr-8">
                    <PurchaseOrderOverviewDisplayOptions />
                </div>
            </TableLayoutTopbar>
            <TableLayoutContent
                style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 250px)' }}
            >
                <PurchaseOrderOverviewTable
                    purchaseOrders={purchaseOrders}
                    actions={actions}
                />
            </TableLayoutContent>
        </TableLayout>
    );
};

export function PurchaseOrderBulkActionBar({
    selectedLines,
    bulkActions,
}: {
    selectedLines: PurchaseOrderDTO[];
    bulkActions: PurchaseOrderOverviewBulkActionModel;
}) {
    const [showSendModal, setShowSendModal] = useState(false);
    const [showDeleteWarning, setShowDeleteWarning] = useState(false);
    const [showChangeStatusModal, setShowChangeStatusModal] = useState(false);
    const validLinesForChangeStatus = selectedLines.filter(
        (line) =>
            line.status === PurchaseOrderStatus.OPEN ||
            line.status === PurchaseOrderStatus.CONFIRMED ||
            line.status === PurchaseOrderStatus.SENT ||
            line.status === PurchaseOrderStatus.CANCELLED ||
            (line.status === PurchaseOrderStatus.CLOSED &&
                line.receivedDate === null)
    );

    const validLinesForSent = selectedLines.filter(
        (line) => line.status === PurchaseOrderStatus.OPEN
    );

    const validLinesForDelete = selectedLines.filter(
        (line) =>
            line.status === PurchaseOrderStatus.OPEN ||
            line.status === PurchaseOrderStatus.SENT ||
            line.status === PurchaseOrderStatus.CONFIRMED ||
            line.status === PurchaseOrderStatus.RECEIVING
    );
    return (
        <TableLayoutTopbar hidden={selectedLines.length === 0}>
            <BatchActions>
                <BatchActionsTitle>
                    {selectedLines.length} {getMessage('text_selected')}
                </BatchActionsTitle>
                <BatchActionsButtons>
                    {validLinesForDelete.length !== 0 && !moduleRackbeat && (
                        <BatchActionsButton
                            data-testid={'purchase-order-bulk-delete'}
                            variant={'secondary'}
                            onClick={() => {
                                setShowDeleteWarning(true);
                            }}
                        >
                            {getMessage('text_delete')}
                            <DeleteIcon />
                        </BatchActionsButton>
                    )}
                    {validLinesForChangeStatus.length !== 0 && (
                        <BatchActionsButton
                            data-testid="purchase-order-change-status"
                            variant={'secondary'}
                            onClick={() => setShowChangeStatusModal(true)}
                        >
                            {getMessage('text_change_status')}
                            <EditIcon />
                        </BatchActionsButton>
                    )}
                    {validLinesForSent.length !== 0 && !moduleRackbeat && (
                        <BatchActionsButton
                            data-testid={'purchase-order-bulk-send'}
                            variant={'secondary'}
                            onClick={() => {
                                if (
                                    selectedLines.length !==
                                    validLinesForSent.length
                                ) {
                                    setShowSendModal(true);
                                } else {
                                    bulkActions.onBulkSend(selectedLines);
                                }
                            }}
                        >
                            {getMessage('text_send')}
                            <SendIcon />
                        </BatchActionsButton>
                    )}
                </BatchActionsButtons>
                {showSendModal && (
                    <LogisticsModal
                        svgIcon={'send'}
                        title={getMessage('text_purchase_order_send_title')}
                        onConfirm={() => {
                            setShowSendModal(false);
                            bulkActions.onBulkSend(selectedLines);
                        }}
                        onCancel={() => setShowSendModal(false)}
                    >
                        <Alert variant="warning">
                            <AlertContent>
                                <AlertHeader as={'h3'}>
                                    {getMessage(
                                        'text_purchase_order_change_status_warning_title'
                                    )}
                                </AlertHeader>
                                <AlertDescription>
                                    {getMessage(
                                        'text_purchase_order_send_warning_description',
                                        validLinesForSent.length,
                                        selectedLines.length
                                    )}
                                </AlertDescription>
                            </AlertContent>
                        </Alert>
                    </LogisticsModal>
                )}
                {showDeleteWarning && (
                    <LogisticsModal
                        svgIcon={'delete'}
                        title={getMessage('text_purchase_order_delete_title')}
                        onConfirm={() => {
                            setShowDeleteWarning(false);
                            bulkActions.onBulkDelete(selectedLines);
                        }}
                        onCancel={() => setShowDeleteWarning(false)}
                    >
                        <Alert variant="warning" className="atl-w-full">
                            <AlertContent>
                                <AlertHeader as={'h3'}>
                                    {getMessage(
                                        'text_purchase_order_change_status_warning_title'
                                    )}
                                </AlertHeader>
                                <AlertDescription>
                                    {getMessage(
                                        'text_purchase_order_delete_warning_description',
                                        validLinesForDelete.length,
                                        selectedLines.length
                                    )}
                                </AlertDescription>
                            </AlertContent>
                        </Alert>
                    </LogisticsModal>
                )}
                {showChangeStatusModal && (
                    <ChangeStatusModal
                        selectedLines={selectedLines}
                        validLines={validLinesForChangeStatus}
                        onClose={() => setShowChangeStatusModal(false)}
                        onChangeStatus={bulkActions.onBulkChangeStatus}
                    />
                )}
            </BatchActions>
        </TableLayoutTopbar>
    );
}

export function PurchaseOrderOverviewTopActions({
    className,
}: {
    className?: string;
}) {
    const { openerButtonProps, popupMenuProps } = usePopupMenu();

    return (
        <div
            className={classNames(
                'atl-flex atl-gap-8 atl-items-center',
                className
            )}
        >
            {moduleLogistics && !moduleRackbeat && (
                <a href={addContextId(PageUrl.GOODS_RECEIPT)}>
                    <Button
                        data-testid="new-goods-receipt"
                        className="atl-text-center"
                        variant="secondary"
                    >
                        {getMessage('text_new_goods_receipt')}
                    </Button>
                </a>
            )}
            {!moduleRackbeat && (
                <a href={addContextId('purchaseOrderMenu?type=2')}>
                    <Button
                        data-testid="new-purchase-order"
                        className="atl-text-center"
                    >
                        {getMessage('text_new_purchase_order')}
                    </Button>
                </a>
            )}
            <div>
                <Button
                    {...openerButtonProps}
                    data-testid="purchase-order-open-popup"
                    variant="icon"
                    aria-label="purchase-order-open-popup"
                >
                    <KebabIcon />
                </Button>
                <PopupMenu {...popupMenuProps}>
                    <a href={addContextId(OLD_PURCHASEORDER)}>
                        <PopupMenuItemButton
                            data-testid={'purchase-order-old-page'}
                            type={'button'}
                        >
                            {getMessage('text_old_page')}
                        </PopupMenuItemButton>
                    </a>
                </PopupMenu>
            </div>
        </div>
    );
}
