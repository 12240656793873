import * as React from 'react';

import { PageLoader } from '@Component/PageLoader';
import { RouteData } from '@General/Router';
import { Team } from '@General/Team';

/**
 * The outside part of the bundle shear line.
 * The bundle split happens because of the <code>import('./SomePage')</code> call.
 * The PageLoader both sets up an error boundary (meaning a crash in the React code inside won't crash the page)
 * and handles showing a loader.
 *
 * @see PageLoader
 * @see renderComponent
 */
const Page: React.FC = (props) => (
    <PageLoader<{}>
        loader={async () =>
            import('./SalaryTaxCard.module').then((m) => m.page)
        }
        adjustWrapperDiv
        props={props}
    />
);

export const route: RouteData = {
    component: Page,
    path: '/execute/salaryTaxCard',
    title: () => getMessage('text_tax_deduction_card'),
    team: Team.TSK,
    // To fully connect this page to the Tripletex framework, the page needs a documentationComponent id so that we can
    // link help articles to the page
    documentationComponent: 255,

    // We should limit who has access to this page.
    // [It's fine currently, the menu is protected by ETaxDeductionCardForm.isAuth and all AIP's are protected by the same and the pilot program. [Kristoffer Kvello, 2022-06-09]]
    auth: () => {
        // remember that this func can take 'context' as a parameter
        return true;
    },
};
