import {
    SkeletonRow,
    SkeletonText,
    TableLayout,
    TableLayoutContent,
    TableLayoutTopbar,
} from '@tlx/atlas';
import {
    Settings,
    TableColumnSetting,
    TableColumnsSetting,
    useListSearchParam,
    useSearchParam,
    useTableColumnsSetting,
} from '@tlx/astro-shared';
import {
    availableColumnsInWarehouseOverview,
    defaultColumnsInWarehouseOverview,
    getColumnLabel,
    getWarehouseLink,
    shouldAnimate,
} from '@Page/LogisticsV2/pages/Warehouse/overview/utils';
import {
    ApiUrl,
    AutoSaveStatusType,
    PageUrl,
    SortingType,
} from '@Page/LogisticsV2/utils/enums';
import { NoResultsEmptyState } from '@Page/LogisticsV2/components/EmptyState/EmptyState';
import React from 'react';
import { WarehouseOverviewTableHeader } from '@Page/LogisticsV2/pages/Warehouse/overview/table/WarehouseOverviewTableHeaderComponents';
import { useInfiniteScrollData } from '@Page/LogisticsV2/hooks/api';
import { WarehouseOverviewAPIModel } from '@Page/LogisticsV2/pages/Warehouse/overview/types';
import { useLoadMoreTarget } from '@Page/LogisticsV2/hooks/fetcher';
import { WarehouseOverviewEmpty } from '@Page/LogisticsV2/pages/Warehouse/overview/WarehouseOverviewComponents';
import {
    NameRow,
    NumberRow,
    WarehouseOverviewTableRowActions,
    WarehouseOverviewTableRowCell,
} from '@Page/LogisticsV2/pages/Warehouse/overview/table/WarehouseOverviewTableRowComponents';
import useSessionStorage from '@Page/LogisticsV2/hooks/useSessionStorage';
import { WarehouseOverviewTableFilterComponents } from '@Page/LogisticsV2/pages/Warehouse/overview/table/WarehouseOverviewTableFilterComponents';
import {
    doAnimateRow,
    getStatusFilter,
} from '@Page/LogisticsV2/pages/Warehouse/overview/actions';
import { nav } from '../../../../../../../../js/modules/navigation';

export function WarehouseOverviewTableWithFilters({
    onChangeStatus,
}: {
    onChangeStatus?: (newStatus: AutoSaveStatusType) => void;
}) {
    const query = useSearchParam('query');
    const statusFilter = useListSearchParam('status', ['active']);
    const [sortBy] = useSessionStorage<SortingType>(
        'goldshark_warehouse_overview_sorting',
        SortingType.NAME_ASCENDING
    );
    const { data, isLoading, hasMore, loadMore, refresh } =
        useInfiniteScrollData<WarehouseOverviewAPIModel>(ApiUrl.INVENTORY, {
            query: query,
            sorting: sortBy,
            isInactive: getStatusFilter(statusFilter),
        });
    const enabledColumnsForWarehouseOverview = useTableColumnsSetting(
        `columns-warehouse-overview-${window.loginEmployeeId}`,
        defaultColumnsInWarehouseOverview
    );
    const handleChangeStatus = async (
        newStatus: AutoSaveStatusType,
        warehouseId?: number,
        newStatusFilter?: string
    ) => {
        onChangeStatus?.(newStatus);
        if (shouldAnimate(statusFilter, newStatusFilter)) {
            await doAnimateRow(warehouseId ?? 0, refresh);
        } else {
            await refresh();
        }
    };
    const loadMoreRef = useLoadMoreTarget<HTMLDivElement>(loadMore);

    if (data === undefined) {
        return null;
    }

    const isFiltered = query.length > 0 || !statusFilter.includes('');

    return (
        <TableLayout>
            <TableLayoutTopbar className={'atl-justify-between atl-m-8'}>
                <WarehouseOverviewTableFilterComponents />
                <Settings>
                    <TableColumnsSetting
                        name={`columns-warehouse-overview-${window.loginEmployeeId}`}
                        label={getMessage('text_columns')}
                        aria-label="columns-for-warehouse-overview"
                        defaultColumns={defaultColumnsInWarehouseOverview}
                    >
                        {availableColumnsInWarehouseOverview.map((column) => (
                            <TableColumnSetting key={column} value={column}>
                                {getColumnLabel(column)}
                            </TableColumnSetting>
                        ))}
                    </TableColumnsSetting>
                </Settings>
            </TableLayoutTopbar>
            <TableLayoutContent
                style={{
                    overflowY: 'auto',
                    maxHeight: 'calc(100vh - 250px)',
                }}
            >
                <table className="atl-table">
                    <WarehouseOverviewTableHeader
                        columns={enabledColumnsForWarehouseOverview}
                    />
                    <tbody>
                        {data.length > 0 &&
                            data.map((warehouse) => (
                                <tr
                                    className={`atl-tr atl-tr--highlight row-${warehouse.id}`}
                                    style={{ cursor: 'pointer' }}
                                    key={warehouse.id}
                                    onClick={(e) =>
                                        setTimeout(() => {
                                            nav.popup(
                                                getWarehouseLink(warehouse.id),
                                                e.ctrlKey || e.metaKey
                                                    ? '_blank'
                                                    : '_self'
                                            );
                                        }, 0)
                                    }
                                >
                                    <NumberRow
                                        warehouseNumber={warehouse.number}
                                    />
                                    <NameRow warehouseName={warehouse.name} />
                                    {enabledColumnsForWarehouseOverview.map(
                                        (column, index) => (
                                            <WarehouseOverviewTableRowCell
                                                key={index}
                                                column={column}
                                                warehouse={warehouse}
                                                onChangeStatus={
                                                    handleChangeStatus
                                                }
                                            />
                                        )
                                    )}
                                    {!warehouse.isInactive ||
                                    warehouse.deletable ? (
                                        <WarehouseOverviewTableRowActions
                                            warehouse={warehouse}
                                            onChangeStatus={handleChangeStatus}
                                        />
                                    ) : (
                                        <td
                                            className="atl-td atl-font-normal atl-align-middle atl-flex atl-justify-end"
                                            key="warehouseOverviewMenuRow"
                                            style={{ minHeight: '61px' }}
                                        />
                                    )}
                                </tr>
                            ))}
                        {data.length === 0 && !isLoading && isFiltered && (
                            <NoResultsEmptyState
                                colSpan={
                                    enabledColumnsForWarehouseOverview.length +
                                    2
                                }
                                onClick={() =>
                                    window.history.pushState(
                                        {},
                                        '',
                                        addContextId(PageUrl.WAREHOUSE)
                                    )
                                }
                            />
                        )}
                        {data.length === 0 && !isLoading && !isFiltered && (
                            <WarehouseOverviewEmpty
                                colSpan={
                                    enabledColumnsForWarehouseOverview.length +
                                    2
                                }
                            />
                        )}
                        {isLoading && (
                            <SkeletonRow>
                                {[
                                    ...Array.from(
                                        Array(
                                            enabledColumnsForWarehouseOverview.length +
                                                2
                                        ).keys()
                                    ),
                                ].map((index) => (
                                    <td className="atl-td" key={index}>
                                        <SkeletonText className="atl-w-full" />
                                    </td>
                                ))}
                            </SkeletonRow>
                        )}
                        {hasMore && (
                            <tr>
                                <td>
                                    <div ref={loadMoreRef} />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </TableLayoutContent>
        </TableLayout>
    );
}
