import { ModelAPIV1 } from '@General/types/Api';
import { Option } from '../../types';
import { JSPDropdownFetcher } from '../JSPDropdownFetcher';
import { ApiResultMapperNoUndefinedResult } from '@General/Provider';
import * as React from 'react';

interface CountryEntry extends ModelAPIV1 {
    readonly displayName: string;
}

const countryMapper: ApiResultMapperNoUndefinedResult<CountryEntry> = (
    item
) => ({
    value: item.id + '',
    displayName: item.displayName,
    item,
});

class CountryFetcher extends JSPDropdownFetcher<CountryEntry> {
    asyncJsonrpcGetter: Function;

    constructor(scope: HTMLDivElement, queryAttributes: Record<string, any>) {
        super(scope, queryAttributes, countryMapper);
        this.asyncJsonrpcGetter = asyncrpc.Country.get;
    }

    getParams(): ObjectIndex {
        return {
            isActive: this.getQueryAttribute('isActive', 1),
            excludeNorway: this.getQueryAttribute('excludeNorway', false),
        };
    }

    async fetchList(params: ObjectIndex): Promise<Option<CountryEntry>[]> {
        return new Promise((resolve, reject) => {
            try {
                return jsonrpc.Country.searchForCountries(
                    (result: CountryEntry[] | null) =>
                        resolve((result ?? []).map(countryMapper)),
                    this.getMarshallSpec(),
                    params.name || '',
                    params.isActive,
                    params.excludeNorway
                );
            } catch (e) {
                defaultJsonRpcExceptionHandler(e);
                reject(e);
            }
        });
    }
}

export const exports = {
    fetcher: CountryFetcher,
    type: 'Country',
};
