import React from 'react';
import { Markdown } from '@Component/Markdown';

export function UserFeedbackQuestionField() {
    return (
        <div className="feedbackButton-user-question atl-flex atl-flex-row atl-gap-16 atl-bg-blue-10">
            <span className="material-icons atl-text-blue-100">
                tips_and_updates
            </span>
            <div>
                <Markdown
                    textKey={'feedback_box_we_do_not_answer_questions'}
                    textKeyArgs={[
                        '[hjelpesenter](https://hjelp.tripletex.no/hc/no)',
                        '[support](https://hjelp.tripletex.no/hc/no/p/kontakt_oss)',
                    ]}
                />
            </div>
        </div>
    );
}
