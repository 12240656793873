import { RouteData } from '@General/Router';
import { Team } from '@General/Team';

import { TestFixture } from './TestFixture.page';

export const route: RouteData = {
    component: TestFixture,
    path: '/execute/test/beehive/fixture',
    team: Team.BEEHIVE,
    title: () => 'Test Fixture',
    auth: () => !window.productionMode,
};
