import * as React from 'react';
import { useEffect } from 'react';

export function useDragAndDrop(events: {
    onDragEnter?: () => void;
    onDragLeave?: () => void;
    onDrop?: (files: File[]) => void;
}) {
    const drag = React.useRef(null);
    const drop = React.useRef<HTMLDivElement | null>(null);
    const [dragging, setDragging] = React.useState(false);
    useEffect(() => {
        drop.current?.addEventListener('dragover', handleDragOver);
        drop.current?.addEventListener('drop', handleDrop);
        drop.current?.addEventListener('dragenter', handleDragEnter);
        drop.current?.addEventListener('dragleave', handleDragLeave);

        return () => {
            drop.current?.removeEventListener('dragover', handleDragOver);
            drop.current?.removeEventListener('drop', handleDrop);
            drop.current?.removeEventListener('dragenter', handleDragEnter);
            drop.current?.removeEventListener('dragleave', handleDragLeave);
        };
    }, []);
    const handleDragOver = (e: DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
    };
    const handleDrop = (e: DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
        setDragging(false);
        const files = e.dataTransfer ? [...e.dataTransfer.files] : [];
        events.onDrop && events.onDrop(files);
    };
    const handleDragEnter = (e: DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.target !== drag.current) {
            setDragging(true);
            events.onDragEnter && events.onDragEnter();
        }
    };
    const handleDragLeave = (e: DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.target === drag.current) {
            setDragging(false);
            events.onDragLeave && events.onDragLeave();
        }
    };
    return { drag, drop, dragging };
}
