export enum Associativity {
    Left,
    Right,
}

export interface OperatorDescription {
    operator: string;
    precedence: number;
    associativity: Associativity;
    apply: (a: number, b: number) => number;
}

type ExpressionTerminalType = 'number';

export type ExpressionOperatorType =
    | 'power'
    | 'modulo'
    | 'multiplier'
    | 'divider'
    | 'plus'
    | 'minus';

type ExpressionBracketType = 'leftRoundBracket' | 'rightRoundBracket';

type ExpressionTokenType =
    | ExpressionTerminalType
    | ExpressionBracketType
    | ExpressionOperatorType;

interface LexerTokenBase<T> {
    readonly type: T;
    readonly offset: number;
    readonly lineBreaks: number;
    readonly line: number;
    readonly col: number;
}

interface LexerToken<T> extends LexerTokenBase<T> {
    readonly value: string;
    readonly text: string;
}

type ExpressionToken<T extends ExpressionTokenType> = LexerToken<T>;

export type ExpressionOperatorToken = ExpressionToken<ExpressionOperatorType>;

export type ExpressionNode =
    | ExpressionToken<ExpressionTerminalType>
    | BinaryOperation
    | UnaryOperation
    | RoundBrackets;

export interface BinaryOperation extends LexerTokenBase<'binaryOperation'> {
    readonly leftOperand: ExpressionNode;
    readonly operator: ExpressionOperatorToken;
    readonly rightOperand: ExpressionNode;
}

export interface UnaryOperation extends LexerTokenBase<'unaryOperation'> {
    readonly operator: ExpressionToken<'plus' | 'minus'>;
    readonly operand: ExpressionNode;
}

export interface RoundBrackets extends LexerTokenBase<'roundBracketGroup'> {
    readonly content: ExpressionNode;
}
