import { useCallback, useMemo } from 'react';
import { SWRInfiniteResponse } from 'swr/infinite';
import { ListResponse } from './types';
import { KeyedMutator } from 'swr/_internal';

export type UseFetchPaginatedState<TData = any, TError = any> = {
    data: TData[];
    error?: TError;

    /**
     * This is `true` when loading both the first and subsequent pages.
     * If you want to know if a page is being reloaded, use `isValidating` from
     * the return value of `useSWRInfinite`.
     *
     * @see SWRInfiniteResponse
     */
    isLoading: boolean;

    /**
     * This is `true` we have successfully loaded the first page but it is empty.
     * To display an empty state you first have to check `isLoading` and then
     * this property.
     */
    isEmpty: boolean;
    hasMore: boolean;
    loadMore(): void;
    mutate?: KeyedMutator<ListResponse<TData>[]>;
};

export function useFetchPaginatedState<TData = any, TError = any>({
    data: pages,
    error,
    size,
    setSize,
    mutate,
}: SWRInfiniteResponse<ListResponse<TData>, TError>): UseFetchPaginatedState<
    TData,
    TError
> {
    const { isLoading, isEmpty, hasMore } = getListResponsePaginationState(
        pages,
        size,
        error !== undefined
    );

    // Ensure loadMore isn't redefined unecessarily
    const loadMore = useCallback(() => {
        if (hasMore && !isLoading) {
            setSize((size) => size + 1);
        }
    }, [hasMore, isLoading, setSize]);

    // Ensure flattened pages are memoized
    const data = useMemo(() => flattenPages(pages), [pages]);

    return {
        data,
        error,
        isLoading,
        isEmpty,
        hasMore,
        loadMore,
        mutate,
    };
}

type ListResponsePaginatedState = {
    isLoadingFirstPage: boolean;
    isLoadingMore: boolean;
    isLoading: boolean;
    isEmpty: boolean;
    hasMore: boolean;
};

export function getListResponsePaginationState(
    pages: ListResponse<unknown>[] | undefined,
    size: number,
    hasError: boolean
): ListResponsePaginatedState {
    const firstPage = pages?.[0];
    const currentPage = pages?.[size - 1];
    const lastPage = pages?.[pages.length - 1];

    const isLoadingFirstPage: boolean =
        size > 0 && pages === undefined && !hasError;
    const isLoadingMore: boolean =
        size > 0 && pages !== undefined && currentPage === undefined;

    const isLoading = isLoadingFirstPage || isLoadingMore;
    const isEmpty: boolean = firstPage?.values.length === 0;
    const hasMore =
        lastPage === undefined ||
        lastPage.fullResultSize > lastPage.from + lastPage.count;

    return {
        isLoadingFirstPage,
        isLoadingMore,
        isLoading,
        isEmpty,
        hasMore,
    };
}

function flattenPages<T = unknown>(
    pages: (ListResponse<T> | undefined)[] | undefined
): T[] {
    if (pages === undefined) {
        return [];
    }

    return pages.flatMap((page) => (page !== undefined ? page.values : []));
}
