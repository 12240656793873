import { getFormNames, isDirty } from 'redux-form';

import { store } from './createStore';

export const formChanged = () => {
    const state = store.getState();
    const formNames = getFormNames()(state);
    return formNames.some((formName) => {
        return isDirty(formName)(state);
    });
};
