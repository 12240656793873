import { ModelAPIV1 } from '@General/types/Api';
import { notUndefined } from '@General/Helpers';
import { ApiResultMapper } from '@General/Provider';
import { JSPDropdownFetcher } from '../JSPDropdownFetcher';
import { Option } from '../../types';

interface ProductGroupEntry extends ModelAPIV1 {
    readonly name: string;
    readonly parentName: string;
    readonly rootParentName: string;
    readonly displayName: string;
}

const productGroupMapper: ApiResultMapper<ProductGroupEntry> = (item) => ({
    value: `${item.id}`,
    displayName: item.displayName,
    item,
});

class ProductGroupFetcher extends JSPDropdownFetcher<ProductGroupEntry> {
    asyncJsonrpcGetter: Function;

    constructor(scope: HTMLDivElement, queryAttributes: Record<string, any>) {
        super(scope, queryAttributes, productGroupMapper);
        this.asyncJsonrpcGetter = asyncrpc.ProductGroup.get;
    }

    getMarshallSpec() {
        return window.marshallSpec(
            'id',
            'displayName',
            'name',
            'parentGroupId',
            'parentName',
            'rootParentName'
        );
    }
    getParams(): ObjectIndex {
        return {};
    }

    async fetchList(params: ObjectIndex): Promise<Option<ProductGroupEntry>[]> {
        return new Promise((resolve, reject) => {
            try {
                jsonrpc.ProductGroup.searchForProductGroups(
                    (result: ProductGroupEntry[] | null) => {
                        const mapped = (result ?? [])
                            .filter(notUndefined)
                            .map(this.getMapper(params))
                            .filter(notUndefined);
                        mapped.sort((a, b) =>
                            a.displayName.localeCompare(b.displayName)
                        );
                        resolve(mapped);
                    },
                    this.getMarshallSpec(),
                    window.loginCompanyId,
                    params.name || ''
                );
            } catch (e) {
                defaultJsonRpcExceptionHandler(e);
                reject(e);
            }
        });
    }
}

export const exports = {
    fetcher: ProductGroupFetcher,
    type: 'ProductGroup',
};
