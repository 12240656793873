import jQuery from 'jquery';
const $ = jQuery;

import { postnummer } from './postnummer';
import { dateUtil } from './date';

import { encodeHTML } from './encodeHTML';
import { hideAndSubmit } from '../o-common';
import { changeTest } from './change';
import { browserInfo } from './d-browserInfo';
import { utils } from './utils';

export const tlxForms = (function () {
    let idIterator = 0;

    /**
     * Gives focus to the first input element of type text or email, or the first textarea
     * that is visible inside this element.
     */
    function focusFirstText(parentElem) {
        let focusedElem = null;
        $(
            "input[type='text'], textarea, input[type='email'], .tlx-dropdown",
            parentElem
        ).each(function () {
            const $this = $(this);
            if ($this.is(':visible')) {
                $this.focus();

                if ($this.is('.tlx-dropdown')) {
                    $this.find('.tlx-dropdown__input').data('autofocus', true);
                } else if (
                    !$this.is('[type="email"]') &&
                    (browserInfo.isChrome() || browserInfo.isSafari())
                ) {
                    // Chrome/Safari fix - don't select the text (typically of the period selector.
                    $this.get(0).selectionStart = -1;
                    $this.get(0).selectionEnd = -1;
                }
                // eslint-disable-next-line @typescript-eslint/no-this-alias -- this is jQuery legacy code
                focusedElem = this;
                return false;
            }
        });
        return focusedElem;
    }

    /**
     *
     */
    function submitFilterForm(someElementWithinForm) {
        const $form = $(someElementWithinForm).closest('form');
        // eslint-disable-next-line prefer-rest-params
        $form.submitGetFormAjax.call($form, arguments);
    }

    function submitHideContent(form) {
        changeTest(function () {
            hideAndSubmit(form);
        }, form);
    }

    /**
     * Change the value of an input element. Make sure the GUI updates and that
     * change event is triggered. This function only supports ONE element as input
     * (unlike tlxForms.check() and tlxForms.disable()).
     *
     * @param element the DOM Node which should be an <input>
     * @param value the value for the <input>
     */
    function change(element, value) {
        if (element instanceof jQuery && element.length > 0) {
            element = element[0];
        }

        if (element !== undefined) {
            element.value = value;
        }
    }

    /**
     * Set a checkbox or radio button element checked or unchecked. The element could be the DOM
     * Node form element, or a jQuery object with one or more form elements.
     *
     * @param $element
     * @param value true if you want to make the element checked. Default is true.
     */
    function setChecked($element, value) {
        // Default value
        value = value === undefined ? true : value;
        $($element).each(function () {
            // Set correct value on disabled property when it is not a Material Design Lite component
            this.checked = value;
        });
    }

    /**
     * Sets a form element disabled or enabled. The element could be the DOM Node form element,
     * or a jQuery object with one or more form elements.
     *
     * NB! See also tlxUtil.js tlxSetDisabled. These two functions should be merged together.
     * .tlxSetDisabled extends jQuery and is used on jQuery objects, while this is used
     * directly on DOM Node elements.
     *
     * @param $element the element you want to disable/enable
     * @param value true if you want the element to be disabled (default is true)
     */
    function setDisabled($element, value) {
        // Default value
        value = value === undefined ? true : value;

        $($element).each(function () {
            if ($(this).is('button')) {
                if (value) {
                    $(this).attr('disabled', 'disabled');
                } else {
                    $(this).removeAttr('disabled');
                }
                return;
            }

            // Set correct value on disabled property when it is not a Material Design Lite component
            this.disabled = value;
        });
    }

    function setReadonly($element, value) {
        value = value === undefined ? true : value;

        $($element).each(function () {
            const $this = $(this);
            if (
                value &&
                $this.is(':input') &&
                $this.attr('type') === 'hidden'
            ) {
                return;
            }

            if (!value) {
                $this.closest('.is-readonly').removeClass('is-readonly');
            }

            this.readOnly = value;
        });
    }

    /**
     * Internal function, used for createCheckboxTxt and createRadioTxt.
     *
     * See more comments in these methods.
     */
    function createToggleTxt(opts) {
        const b = [];
        const id = opts.id || 'formElementId' + idIterator++;

        const labelClass =
            opts.type === 'checkbox' ? 'tlx-checkbox' : 'tlx-radio';
        const inputClass =
            opts.type === 'checkbox'
                ? 'atl-input--checkbox'
                : 'tlx-radio__button';
        const textClass =
            opts.type === 'checkbox'
                ? 'tlx-checkbox__label'
                : 'tlx-radio__label';

        b.push('<label class="' + labelClass + '" for="' + id + '">');
        b.push(
            '<input type="' +
                opts.type +
                '" id="' +
                id +
                '" class="' +
                inputClass +
                '"'
        );

        if (opts.checked) {
            b.push(' checked');
        }

        if (opts.name) {
            b.push(' name="' + opts.name + '"');
        }

        if (opts.value) {
            b.push(' value="' + opts.value + '"');
        }

        b.push('>');
        b.push('<span class="' + textClass + '">' + opts.label + '</span>');
        b.push('</label>');

        return b.join('');
    }

    /**
     * Create a textual representation of a checkbox.
     *
     * @param opts.id	ID of the input element
     * @param opts.where Where should this checkbox be inserted
     * @param opts.label the label text (not HTML escaped!)
     * @param opts.labelStyle style for label element (deprecated)
     */
    function createCheckboxTxt(opts) {
        opts.type = 'checkbox';
        return createToggleTxt(opts);
    }

    /**
     * Create a textual representation of a radio button.
     *
     * @param opts.id	ID of the input element
     * @param opts.where Where should this checkbox be inserted
     * @param opts.label the label text (not HTML escaped!)
     * @param opts.labelStyle style for label element (deprecated)
     */
    function createRadioTxt(opts) {
        opts.type = 'radio';
        return createToggleTxt(opts);
    }

    /**
     * Create a checkbox and insert it into the DOM in opts.where (appended).
     * @param opts.id ID of the input element
     * @param opts.where Where should this checkbox be inserted
     * @param opts.label the label text (not HTML escaped!)
     * @param opts.labelStyle style for label element (deprecated)
     */
    function createCheckbox(opts) {
        const checkboxText = createCheckboxTxt(opts);
        const checkbox = $(checkboxText)[0];
        opts.where.append(checkbox);

        componentHandler.upgradeElement(checkbox);
    }

    /**
     * Given an input item, produce a textual summary for this item.
     * @param inputItem
     */
    function createSummary($input) {
        $input = $($input);

        let summaryLabel = ''; // Optional manual label tag
        if ($input.attr('summary-label')) {
            summaryLabel = encodeHTML($input.attr('summary-label')) + ' ';
        }

        if ($input.is('[type=checkbox]')) {
            return (
                summaryLabel +
                getMessage($input.is(':checked') ? 'text_yes' : 'text_no')
            );
        }

        if ($input.is('textarea')) {
            return summaryLabel + $input.val().split('\n')[0];
        }

        return summaryLabel + $input.val();
    }

    /**
     * Shortcuts for fields with formatKey="dateT"
     * T = todays date
     * up = previous day
     * down = next day
     * pageup = previous month
     * pagedown = next month
     * @param input
     * @param e
     * @returns {boolean}
     */
    function dateOnKeyDown(input, e) {
        if ($(input).prop('readonly')) {
            return true;
        }

        const keyCode = e.keyCode;
        let date = dateUtil.parseDate(input.value, true);
        if (e.ctrlKey || e.altKey || e.shiftKey) {
            return true;
        } else {
            if (keyCode == 84) {
                // t
                date = new Date();
            } else if (date) {
                if (keyCode == 38) {
                    // Up
                    date = dateUtil.addDays(date, -1);
                } else if (keyCode == 40) {
                    // Down
                    date = dateUtil.addDay(date);
                } else if (keyCode == 33) {
                    // Page Up
                    date = dateUtil.addMonths(date, -1);
                } else if (keyCode == 34) {
                    // Page Down
                    date = dateUtil.addMonth(date);
                } else {
                    date = null;
                }
            } else {
                date = null;
            }
        }

        if (date) {
            if (browserInfo.isFirefox()) {
                setTimeout(function () {
                    tlxForms.change(
                        input,
                        dateUtil.formatDate(date, 'yyyy-MM-dd')
                    );
                    $(input).trigger('change');
                }, 1);
                return false;
            } else {
                tlxForms.change(input, dateUtil.formatDate(date, 'yyyy-MM-dd'));
                $(input).trigger('change');
                utils.stopEvent(e);
                return false;
            }
        } else {
            return true;
        }
    }

    /**
     * Replaces the input passed on formatKey="dateT" fields with empty string if not valid
     * @param e
     * @returns {boolean}
     */
    function dateOnInput(e) {
        e.target.value = e.target.value.replace(/[^0-9.-]/g, '');
    }

    /**
     * Replaces the input passed on formatKey="time" fields with empty string if not valid
     * @param e
     * @returns {boolean}
     */
    function timeOnInput(e) {
        e.target.value = e.target.value.replace(/[^0-9.:]/g, '');
    }

    // From jquery.validate.js (by joern), contributed by Scott
    // Gonzalez:
    // http://projects.scottsplayground.com/email_address_validation/
    // found in jquery ui dialog documentation (modal form)
    /* eslint-disable no-control-regex */
    const validEmailRegexp =
        // eslint-disable-next-line no-useless-escape
        /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?[^\.]$/i;
    /* eslint-enable no-control-regex */
    /**
     * Connects an input field representing the postal number and postal place.
     * When number is changed, the place is automatically updated.
     *
     * Requires that "postnummer" is defined in global scope. Should be included
     * on pages that uses this function.
     *
     * @param $postalNumberField
     * @param $postalPlaceField
     */

    function autofillPostalNumber(
        $postalNumberField,
        $postalPlaceField,
        $postalCountryField
    ) {
        $postalNumberField.change(function () {
            const place = postnummer(this.value);
            if (!place) {
                return;
            }

            tlxForms.change(
                $postalPlaceField,
                utils.capitalizeFirstLetter(place, true)
            );
            if ($postalCountryField) {
                tlxForms.change($postalCountryField, 161); //Norway
            }
        });
    }

    /**
     * Fill predictions to form.
     *
     * @param predictions
     * @param scope
     *
     * @author Håkon Wågbø
     * @date 06. Jul 2018
     */
    function fillPredictions(predictions, scope) {
        for (const property in predictions) {
            const $property = $(':prop(' + property + ')', scope);
            const $parent = $property.parent();
            const prediction = predictions[property];
            if ($property.length > 0 && prediction) {
                if ($parent.is('.tlx-dropdown:visible')) {
                    $property.val(prediction);
                    $property.each(function (i, element) {
                        // Wait until dom is updated with value
                        setTimeout(function () {
                            $(element)
                                .parent()
                                .find('.txr-dropdown__field__input')
                                .addClass(
                                    'tlx-textfield__input--smart-scan-prediction'
                                );
                            try {
                                element.dispatchEvent(new Event('change'));
                            } catch (ignore) {
                                // Because IE
                                const event = document.createEvent('Event');
                                event.initEvent('change', false, false);
                                element.dispatchEvent(event);
                            }
                        }, 10);
                    });
                } else if ($property.is(':visible')) {
                    change($property, prediction);
                    $property.addClass('smartScanPrediction');
                    $property.each(function () {
                        try {
                            this.dispatchEvent(new Event('change'));
                        } catch (ignore) {
                            // Because IE
                            const event = document.createEvent('Event');
                            event.initEvent('change', false, false);
                            this.dispatchEvent(event);
                        }
                    });
                }
            }
        }
    }

    return {
        focusFirstText: focusFirstText,
        submitFilter: submitFilterForm,
        submitHideContent: submitHideContent,
        disable: setDisabled,
        readonly: setReadonly,
        check: setChecked,
        change: change,
        createSummary: createSummary,
        dateOnKeyDown: dateOnKeyDown,
        dateOnInput: dateOnInput,
        timeOnInput: timeOnInput,

        createCheckbox: createCheckbox,
        createCheckboxTxt: createCheckboxTxt,
        createRadioTxt: createRadioTxt,
        validEmailRegexp: validEmailRegexp,
        autofillPostalNumber: autofillPostalNumber,
        fillPredictions: fillPredictions,
    };
})();
