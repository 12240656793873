import { CreateButtonComponent, Option } from '../../types';
import { notUndefined } from '@General/Helpers';
import { JSPDropdownFetcher } from '../JSPDropdownFetcher';
import { ApiResultMapper } from '@General/Provider';
import * as React from 'react';
import { createCreateNewButton } from '@Component/DropDown/CreateNewButton/CreateNewButton';
import { TlxListItem } from '@Component/DropDown/DropDown';
import classNames from 'classnames';
import { ProjectObject } from '@Page/IncomingInvoice/type/IncomingInvoiceTypes';
import { renderProjectWizardModal } from '@Page/ProjectWizard/ProjectWizardModal.route';
import tlxFetch from '../../../../api/tlxFetch';

export const DesktopListItemProjectHierarchy: TlxListItem<any> = (props) => {
    const classes = classNames({
        'txr-dropdown__list-item--has-secondary': props.option.secondary,
        'txr-dropdown__list-item': true,
    });
    return (
        <div className={classes} tabIndex={-1}>
            <div className="txr-dropdown__list-item__text">
                <div>{props.option.displayName}</div>
            </div>
        </div>
    );
};

const ProjectMapper: ApiResultMapper<ProjectObject> = (item) => {
    return {
        value: item.id + '',
        displayName: item.displayName,
        item,
    };
};

// For formatting the project name in the dropdown list when it is a child project - based on hierarchy level
function getHierarchyFormattedProjectName(
    name: string,
    hierarchyLevel: number
): React.ReactNode {
    if (hierarchyLevel > 0) {
        return (
            <span title={name}>
                {`${' . .'.repeat(hierarchyLevel)} ${name}`}
            </span>
        );
    }
    return <span title={name}>{name}</span>;
}

const DesktopListItem: TlxListItem<ProjectObject> = (props) => {
    if (!props.option.item) {
        return (
            <div className="txr-dropdown__row txr-dropdown__list-item">
                <div className="txr-dropdown__item-cell txr-dropdown__item-cell--default">
                    {props.option.displayName}
                </div>
            </div>
        );
    }
    return (
        <div className="txr-dropdown__row txr-dropdown__list-item">
            <div
                className="txr-dropdown__item-cell txr-dropdown__item-cell--description txr-dropdown__item-cell--level-indent"
                title={props.option.item.displayName}
            >
                {getHierarchyFormattedProjectName(
                    props.option.item.displayName,
                    props.option.item.hierarchyLevel
                )}
            </div>
            <div
                className="txr-dropdown__item-cell txr-dropdown__item-cell--name"
                title={props.option.item.customerName}
            >
                {props.option.item.customerName}
            </div>
            <div
                className="txr-dropdown__item-cell txr-dropdown__item-cell--name"
                title={props.option.item.projectManagerNameAndNumber}
            >
                {props.option.item.projectManagerNameAndNumber}
            </div>
        </div>
    );
};

const Headers: React.FC = () => {
    return (
        <div className="txr-dropdown__row">
            <div className="txr-dropdown__item-cell txr-dropdown__item-cell--header  txr-dropdown__item-cell--description">
                {getMessage('text_project')}
            </div>
            <div className="txr-dropdown__item-cell txr-dropdown__item-cell--header txr-dropdown__item-cell--name">
                {getMessage('text_customer')}
            </div>
            <div className="txr-dropdown__item-cell txr-dropdown__item-cell--header txr-dropdown__item-cell--name">
                {getMessage('text_project_manager')}
            </div>
        </div>
    );
};

class ProjectFetcher extends JSPDropdownFetcher<ProjectObject> {
    asyncJsonrpcGetter: Function;

    constructor(scope: HTMLDivElement, queryAttributes: Record<string, any>) {
        super(scope, queryAttributes, ProjectMapper);

        this.asyncJsonrpcGetter = asyncrpc.Project.get;
    }

    getDesktopListItem(): TlxListItem<ProjectObject> {
        return DesktopListItem;
    }

    getMarshallSpec(): { marshallSpec: string[] } {
        return window.marshallSpec(
            'id',
            'number',
            'displayName',
            'hierarchyLevel',
            'projectManagerNameAndNumber',
            'customerName'
        );
    }

    getParams(): ObjectIndex {
        const { startDate, endDate } = this.getDates();
        return {
            projectCategory: this.getQueryAttribute('projectCategory', -1),
            employee: this.getQueryAttribute('employeeElementId', -1),
            department: this.getQueryAttribute('department', -1),
            customer: this.getQueryAttribute('customer', -1),
            accountManager: this.getQueryAttribute('accountManager', -1),
            registerInfoEmployeeElementId: this.getQueryAttribute(
                'registerInfoEmployeeElementId',
                -1
            ),
            isClosed: this.getQueryAttribute('isClosed', 0),
            isProject: this.getQueryAttribute('isProject', 1),
            isOffer: this.getQueryAttribute('isOffer', -1),
            withManagerPrivilegesOnly: this.getQueryAttribute(
                'withManagerPrivilegesOnly',
                false
            ),
            includeAllOpen: this.getQueryAttribute('includeAllOpen', false),
            includeRecentlyClosed: this.getQueryAttribute(
                'includeRecentlyClosed',
                false
            ),
            excludeOffers: this.getQueryAttribute('excludeOffers', false),
            startDate,
            endDate,
        };
    }

    async fetchList(
        params: ObjectIndex,
        pageIndex?: number
    ): Promise<Option<ProjectObject>[]> {
        return new Promise((resolve, reject) => {
            try {
                if (params.isProject === 1 && pageIndex != undefined) {
                    tlxFetch({
                        url: this.getProjectAPIQuery(
                            params,
                            pageIndex >= 0 ? pageIndex : 0
                        ).toString(),
                        method: 'GET',
                    }).then((value) => {
                        resolve(value.response.map(this.getMapper(params)));
                    });
                } else {
                    jsonrpc.Project.searchForProjects(
                        (result: ProjectObject[] | null) => {
                            const mapped = (result ?? [])
                                .filter(notUndefined)
                                .map(this.getMapper(params))
                                .filter(notUndefined);
                            resolve(mapped);
                        },
                        this.getMarshallSpec(),
                        window.loginCompanyId,
                        params.projectCategory,
                        params.employee,
                        params.department,
                        params.customer,
                        params.accountManager,
                        params.registerInfoEmployeeElementId,
                        params.isClosed,
                        params.isProject,
                        params.isOffer,
                        params.withManagerPrivilegesOnly,
                        params.includeAllOpen,
                        params.includeRecentlyClosed,
                        params.startDate,
                        params.endDate,
                        params.excludeOffers,
                        params.name
                    );
                }
            } catch (e) {
                defaultJsonRpcExceptionHandler(e);
                reject(e);
            }
        });
    }

    getProjectAPIQuery(params: ObjectIndex, pageIndex: number): string {
        const url = new URL('/v2/project/query', window.location.origin);
        const urlParams = url.searchParams;
        if (params.projectCategory > -1) {
            urlParams.set('projectCategoryId', params.projectCategory);
        }
        if (params.employee > -1) {
            urlParams.set('projectManagerId', params.employee);
        }
        if (params.department > -1) {
            urlParams.set('departmentId', params.department);
        }
        if (params.customer > -1) {
            urlParams.set('customerId', params.customer);
        }
        if (params.accountManager > -1) {
            urlParams.set('accountManagerId', params.accountManager);
        }
        if (params.registerInfoEmployeeElementId > -1) {
            urlParams.set(
                'registerInfoEmployeeId',
                params.registerInfoEmployeeElementId
            );
        }
        if (params.isClosed > -1) {
            urlParams.set('isClosed', params.isClosed == 1 ? 'true' : 'false');
        }
        if (params.isOffer > -1) {
            urlParams.set('isOffer', params.isOffer == 1 ? 'true' : 'false');
        }
        urlParams.set(
            'managerPrivilegesOnly',
            params.withManagerPrivilegesOnly
        );
        urlParams.set('includeRecentlyClosed', params.includeRecentlyClosed);
        urlParams.set('query', params.name);
        urlParams.set(
            'fields',
            'id, displayName, number, customerName, projectManagerNameAndNumber, hierarchyLevel'
        );
        const countPerPage = 100;
        urlParams.set('count', countPerPage.toString());
        urlParams.set('from', (pageIndex * countPerPage).toString());
        return url.toString();
    }

    createCreateNewButton(
        setSelected: (id: number) => void,
        dropdownTrackingId?: string,
        dropdownIsInJSPModal?: boolean
    ): CreateButtonComponent | undefined {
        if (!isAuth.createProject) {
            return undefined;
        }
        return createCreateNewButton(
            setSelected,
            'text_new_project',
            'createProject',
            dropdownTrackingId
                ? getLocaleMessage('en_GB', 'text_new_project') +
                      ' - ' +
                      dropdownTrackingId
                : '',
            window.narrowScreen || dropdownIsInJSPModal
                ? undefined
                : () =>
                      renderProjectWizardModal('projectWizardModal', {
                          setSelected,
                      })
        );
    }

    getHeaders() {
        return Headers;
    }
}

function link(id: number, contextId: number): string | null {
    if (!loginCompanyModules.project || !isAuth.projectMenu) {
        return null;
    }
    return `/execute/projectMenu?projectId=${id}&contextId=${contextId}`;
}

export const exports = {
    fetcher: ProjectFetcher,
    type: 'Project',
    link: link,
};
