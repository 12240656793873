import { DistributionKeysKey } from '@Page/DistributionKeys/components/key/DistributionKeysKey';
import { Button, Icon } from '@tlx/atlas';
import React, { useState } from 'react';
import { DistributionKey } from '@Page/DistributionKeys/models/DistributionKeys';
import { ApiAction } from '@Page/DistributionKeys/models/Actions';
import { getMessage } from '../../../../../../js/modules/getMessage';
import { DistributionKeysNoKeys } from '@Page/DistributionKeys/components/key/DistributionKeysNoKeys';
import { DistributionKeysDialogModal } from '@Page/DistributionKeys/components/Dialog/DistributionKeysDialogModal';

type DistributionKeysTableProps = {
    distributionKeys?: DistributionKey[];
    dispatch: (apiAction: ApiAction, distributionKey: DistributionKey) => void;
};

export const DistributionKeysTable = ({
    distributionKeys,
    dispatch,
}: DistributionKeysTableProps) => {
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedKey, setSelectedKey] = useState<DistributionKey | undefined>(
        undefined
    );

    return (
        <>
            <table className="atl-table">
                <thead className="atl-thead ants-border-top">
                    <tr className="atl-tr">
                        <th className="atl-th" />
                        <th className="atl-th atl-text-left">
                            {getMessage('text_name')}
                        </th>
                        <th className="atl-th ants-w-300 atl-text-left">
                            {getMessage('heading_department')}
                        </th>
                        <th className="atl-th atl-text-right">
                            {getMessage('text_distribution')}
                        </th>
                        <th className="atl-th" />
                        <th className="atl-th" />
                        <th className="atl-th atl-td--button">
                            <Button
                                variant="tertiary"
                                data-testid="test-add-button"
                                onClick={() => {
                                    setSelectedKey(undefined);
                                    setOpenDialog(true);
                                }}
                            >
                                <Icon>add</Icon>
                            </Button>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {distributionKeys && distributionKeys.length === 0 && (
                        <DistributionKeysNoKeys />
                    )}
                    {distributionKeys &&
                        distributionKeys.length > 0 &&
                        distributionKeys
                            .sort((key1, key2) => {
                                if (key1.name && key2.name) {
                                    return key1.name.localeCompare(
                                        key2.name,
                                        'nb-NO',
                                        {
                                            sensitivity: 'base',
                                        }
                                    );
                                } else {
                                    return 0;
                                }
                            })
                            .map((key) => (
                                <DistributionKeysKey
                                    key={key.id}
                                    distributionKey={key}
                                    setSelectedDistributionKey={setSelectedKey}
                                    setOpenModal={setOpenDialog}
                                    dispatch={dispatch}
                                />
                            ))}
                </tbody>
            </table>
            <DistributionKeysDialogModal
                open={openDialog}
                setOpen={setOpenDialog}
                distributionKey={selectedKey}
                distributionKeys={distributionKeys}
                dispatch={dispatch}
            />
        </>
    );
};
