import { renderComponent } from '@General/renderComponent';
import { createElement } from 'react';
import { SupportReadAccessRequestDialog } from './SupportReadAccessRequestDialog';
import { useSupportReadAccessRequestDialog } from './useSupportReadAccessRequestDialog';

export function renderSupportReadAccessRequestDialog(
    domId: string,
    onAccept: () => void,
    onReject: () => void
) {
    function SupportReadAccessRequestDialogContainer() {
        return createElement(
            SupportReadAccessRequestDialog,
            useSupportReadAccessRequestDialog({ onAccept, onReject })
        );
    }

    renderComponent(SupportReadAccessRequestDialogContainer, domId);
}
