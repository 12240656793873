import { EntitlementLookup } from '@General/constants';
import { RouteData } from '@General/Router';
import { Team } from '@General/Team';

import { DatabaseOverviewPage } from './DatabaseOverview.page';

export const route: RouteData = {
    component: DatabaseOverviewPage,
    title: () => getMessage('text_database_overview'),
    path: '/execute/admin/database/overview',
    team: Team.BEEHIVE,
    auth: (context) =>
        context.entitlementMap[EntitlementLookup.ROLE_SYSTEM_ADMINISTRATOR] !==
            undefined || context.developer !== false,
};
