import { ISagaModule } from 'redux-dynamic-modules-saga';

import { logisticsSettingsDataSagas } from './saga';
import { logisticsSettingsDataReducers } from './reducer';

export const LogisticsSettingsDataModule: ISagaModule<{}> = {
    reducerMap: logisticsSettingsDataReducers,
    sagas: logisticsSettingsDataSagas,
    id: 'LogisticsSettingsData',
    // No reason to unload this data when it has been loaded once.
    retained: true,
};
