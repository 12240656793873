import { CustomerDTO } from '@Page/LogisticsV2/types/dtoTypes';
import { ProductDTO } from '@Page/LogisticsV2/pages/Product/overview/types';

export type DiscountPolicyApiModel = {
    id: number;
    percentage: number;
    fixedAmount: number;
    product: ProductDTO;
    customer: CustomerDTO;
    isPercentage: boolean;
    salesPriceWithDiscount: number;
    discountType: string;
};

export const DISCOUNT_POLICY_FIELDS =
    '*,product(id,name,number,priceExcludingVatCurrency, currency(code))';
export const DEFAULT_COLUMNS_NUMBER_IN_DISCOUNT_OVERVIEW = 4;

export const DISCOUNT_TYPE = {
    PERCENT: 'percent',
    FIXED_AMOUNT: 'fixedAmount',
};
