import { ModelAPIV1 } from '@General/types/Api';
import { Option } from '../../types';
import { notUndefined } from '@General/Helpers';
import { JSPDropdownFetcher } from '../JSPDropdownFetcher';
import { ApiResultMapper } from '@General/Provider';
import * as React from 'react';

interface DocumentEntry extends ModelAPIV1 {
    readonly nameHierarchy: string;
}

const COMPANY = 5;
const PROJECT = 6;
const PRODUCT = 7;
const EMPLOYEE = 8;
const ACCOUNT = 9;

const documentMapper: ApiResultMapper<DocumentEntry> = (item) => ({
    value: item.id + '',
    displayName: item.nameHierarchy,
    item,
});

class DocumentFetcher extends JSPDropdownFetcher<DocumentEntry> {
    asyncJsonrpcGetter: Function;

    constructor(scope: HTMLDivElement, queryAttributes: Record<string, any>) {
        super(scope, queryAttributes, documentMapper);

        this.asyncJsonrpcGetter = asyncrpc.Document.get;
    }

    getMarshallSpec() {
        return window.marshallSpec('id', 'nameHierarchy');
    }

    getParams(): ObjectIndex {
        if (this.scope === null) {
            return {
                archiveObjectType: 0,
            };
        }

        let archiveObjectType = this.getQueryAttribute('archiveObjectType', 0);
        const scope = this.scope;

        function getElementInt(name: string): number {
            const element = scope.querySelector(
                '[name="' + name + '"]'
            ) as HTMLInputElement;
            if (!element) {
                return 0;
            }

            return parseInt(element.value, 10) || 0;
        }

        if (archiveObjectType === 0) {
            /**
             * The comments below are copy/pasted from tlxSelect. What perhaps should have been done instead, is not
             * using this pattern at all, but say what elements we are interested in as attributes insteadm which are
             * done for all other tlxSelects....
             */
            //For historic reasons we support element names of both the form 'selected_XXX_Id' as well as 'SelectedArchive_XXX_Id'
            //New pages should probably use 'SelectedArchive_XXX_Id' as this makes it possible to have a 'selected_XXX_Id' in the page for other uses as well
            if (
                getElementInt('selectedCustomerId') > 0 ||
                getElementInt('selectedArchiveCustomerId') > 0
            ) {
                archiveObjectType = COMPANY;
            } else if (
                getElementInt('selectedEmployeeId') > 0 ||
                getElementInt('selectedArchiveEmployeeId') > 0
            ) {
                archiveObjectType = EMPLOYEE;
            } else if (
                getElementInt('selectedProjectId') > 0 ||
                getElementInt('selectedArchiveProjectId') > 0
            ) {
                archiveObjectType = PROJECT;
            } else if (
                getElementInt('selectedProductId') > 0 ||
                getElementInt('selectedArchiveProductId') > 0
            ) {
                archiveObjectType = PRODUCT;
            } else if (
                getElementInt('selectedAccountId') > 0 ||
                getElementInt('selectedArchiveAccountId') > 0
            ) {
                archiveObjectType = ACCOUNT;
            }
        }

        return {
            archiveObjectType: archiveObjectType,
        };
    }

    async fetchList(params: ObjectIndex): Promise<Option<DocumentEntry>[]> {
        return new Promise((resolve, reject) => {
            try {
                return jsonrpc.Document.getFolderHierarchy(
                    (result: DocumentEntry[] | null) => {
                        const mapped = (result ?? [])
                            .filter(notUndefined)
                            .map(this.getMapper(params))
                            .filter(notUndefined);
                        resolve(mapped);
                    },
                    this.getMarshallSpec(),
                    params.archiveObjectType,
                    params.name || ''
                );
            } catch (e) {
                defaultJsonRpcExceptionHandler(e);
                reject(e);
            }
        });
    }
}

export const exports = {
    fetcher: DocumentFetcher,
    type: 'Document',
    link: (id: number, contextId: number) =>
        `/execute/documentMenu?documentId=${id}&contextId=${contextId}`,
};
