import { put } from 'redux-saga/effects';

import { wrapSagaList } from '@General/Saga.util';

import { logisticsSettingsDataSaga } from './LogisticsSettingsData.saga';
import { FetchData } from '../action/LogisticsSettingsData.action';

export const logisticsSettingsDataSagas = [
    wrapSagaList([...logisticsSettingsDataSaga, put(FetchData())]),
];
