import * as React from 'react';

import { renderComponent } from '@General/renderComponent';
import {
    InvoicingModal,
    InvoicingModalProps,
} from '@Page/ProjectInvoicingDialog/component/InvoicingModal';

export function renderProjectInvoicingFormModal(
    domId: string,
    isOpenByDefault: boolean,
    forwardURL: undefined | string,
    orderIds: number[],
    projectIds: number[],
    invoiceIds: number[],
    doBatchInvoicing: boolean | string,
    isProjectInvoicingModal: boolean,
    periodEndDate?: string,
    readyForBilling?: string,
    trackingIdContext?: string
) {
    // doBatchInvoicing may be a string for some reason
    const doBatchInvoicingBool =
        doBatchInvoicing === true || doBatchInvoicing === 'true';
    renderComponent<InvoicingModalProps>(InvoicingModal, domId, {
        isOpenByDefault,
        forwardURL,
        orderIds,
        projectIds,
        invoiceIds,
        doBatchInvoicing: doBatchInvoicingBool,
        isProjectInvoicingModal,
        periodEndDate,
        readyForBilling,
        trackingIdContext,
    });
}
