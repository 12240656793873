export enum Team {
    ATEAM = 'ATEAM',
    BOND = 'BOND',
    ANGRY_NERDS = 'ANGRY_NERDS',
    TSK = 'TSK',
    KRR = 'KRR',
    GOLDSHARK = 'GOLDSHARK',
    PBC = 'PBC',
    BEEHIVE = 'BEEHIVE',
    SHEET_STORM = 'SHEET_STORM',
    DATA_STREAM = 'DATA_STREAM',
    KILLBILL = 'KILLBILL',
    PHOENIX = 'PHOENIX',
    FINX = 'FINX',
    ANTS = 'ANTS',
    TOOLKITTENS = 'TOOLKITTENS',
}
