import * as React from 'react';

import { renderComponent } from '@General/renderComponent';
import { PageLoader } from '@Component/PageLoader';

/**
 * The outside part of the bundle shear line.
 * The bundle split happens because of the <code>import('./SomePage')</code> call.
 * The PageLoader both sets up an error boundary (meaning a crash in the React code inside won't crash the page)
 * and handles showing a loader.
 *
 **
 * @see PageLoader
 * @see renderComponent
 */
const Page: React.FC = (props) => (
    <PageLoader<{}>
        loader={async () => import('./ProjectWizard').then((m) => m.page)}
        props={props}
    />
);

export function renderProjectWizard(domId: string, title: string) {
    renderComponent(Page, domId, { title });
}
