import * as React from 'react';
import ReactMarkdown from 'react-markdown';
import type { ReactMarkdownProps } from 'react-markdown/lib/complex-types';
import { clsx } from 'clsx';

import { isFilled } from '@General/Helpers';

import './Markdown.css';

interface MarkdownProps {
    readonly text?: string;
    readonly textKey?: string;
    readonly textKeyArgs?: Array<string | number>;
    readonly linksTargetBlank?: boolean;
    readonly children?: string;
}

// Block elements that loads resources, unsafe unless URLs are processed properly (like blocking external resources).
const markdownDisallowedElements: Array<keyof HTMLElementTagNameMap> = [
    // Images
    'img',
];

type TlxMarkdownAnchorProps = React.AnchorHTMLAttributes<HTMLAnchorElement> &
    ReactMarkdownProps;

const TlxMarkdownAnchor = ({
    href,
    rel,
    target,
    className,
    children,
    // These 4 props are specific for ReactMarkdown and should not be propagated
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    node,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    sourcePosition,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    index,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    siblingCount,
    ...anchorProps
}: TlxMarkdownAnchorProps) => {
    let external = false;

    if (href) {
        try {
            const url = new URL(href);
            // Can only be external if it actually goes to a different origin
            external = url.origin !== window.location.origin;
        } catch (ignored) {
            // Ignored
        }
    }

    const relResolved = clsx(
        rel,
        ...(external ? ['nofollow', 'noopener', 'noreferrer'] : [])
    );

    const classNameResolved = clsx(className, {
        navigate: !external && href,
    });

    return (
        <a
            href={href}
            rel={isFilled(relResolved, '') ? relResolved : undefined}
            className={
                isFilled(classNameResolved, '') ? classNameResolved : undefined
            }
            target={external ? '_blank' : target}
            {...anchorProps}
        >
            {children}
        </a>
    );
};

const AnchorRegular = ({
    children,
    ...anchorProps
}: TlxMarkdownAnchorProps) => (
    <TlxMarkdownAnchor {...anchorProps}>{children}</TlxMarkdownAnchor>
);

const AnchorAlwaysBlank = ({
    children,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    target,
    ...anchorProps
}: TlxMarkdownAnchorProps) => (
    <TlxMarkdownAnchor target="_blank" {...anchorProps}>
        {children}
    </TlxMarkdownAnchor>
);

export const Markdown: React.FC<MarkdownProps> = ({
    linksTargetBlank,
    text,
    textKey,
    textKeyArgs,
    children,
}) => {
    let content: string | null = null;

    if (isFilled(textKey, '')) {
        content = getMessage(textKey, ...(textKeyArgs ?? []));
    } else if (text) {
        content = text;
    } else if (children) {
        content = children;
    }

    if (content === null) {
        return content;
    }

    return (
        <ReactMarkdown
            className="tlx-markdown"
            disallowedElements={markdownDisallowedElements}
            components={{
                a: linksTargetBlank ? AnchorAlwaysBlank : AnchorRegular,
            }}
        >
            {content}
        </ReactMarkdown>
    );
};
