import * as React from 'react';
import { useEffect, useState } from 'react';
import './InvoicingForm.css';
import '../../InvoicingDialog.css';
import {
    Invoice,
    InvoiceSettings,
    Order,
    PaymentType,
} from '@Page/InvoicingDialog/types/InvoicingDialog.type';
import {
    FieldErrors,
    UseFormRegisterReturn,
    UseFormReset,
    UseFormSetError,
    UseFormSetValue,
    UseFormTrigger,
    UseFormWatch,
    useWatch,
} from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types/form';
import { format } from '../../../../../../js/modules/format';
import { DoInvoiceFieldValues } from '@Page/InvoicingDialog/component/InvoicingForm/ConnectedInvoicingForm';
import { Amount } from '@Page/InvoicingDialog/component/Amount';
import { LoadingSpinner } from '@Component/Loading';
import { useDeviceType } from '@Component/Responsive/useDeviceType';
import { useDoInvoice } from '@Page/InvoicingDialog/component/InvoicingForm/formHook';
import {
    getSendMethodNameForInvoicingButton,
    SendMethod,
    SendTypes,
} from '@Page/InvoicingDialog/component/InvoicingForm/SendMethod';
import { format2 } from '@Page/Assets/AssetOverview/components/TableCell';
import { ValidationError } from '@Page/InvoicingDialog/component/ValidationError';
import {
    Alert,
    AlertContent,
    Button,
    CheckboxGroup,
    Dropdown,
    DropdownDrawer,
    DropdownScrollContainer,
    DropdownSearchOpener,
    Group,
    Input,
    Label,
    ModalCloseButton,
    Option,
    Select,
    Textarea,
} from '@tlx/atlas';
import classNames from 'classnames';
import { MESSAGE_POSITION, MESSAGE_TYPE } from '@Component/Messages/types';
import Message from '@Component/Messages/component/Message/Message';
import { SendingError } from '@Page/InvoicingDialog/component/SendingError';
import { getSendTypeErrors } from '@Page/InvoicingDialog/ValidationUtil';
import { ElectronicReceiveState } from '@Page/InvoicingDialog/component/InvoicingForm/Hooks';
import { getInvoicingValidationErrors } from '@Page/InvoicingDialog/component/BatchInvoicing/BatchInvoicing';
import { defaultFetcher } from '../../../../hooks/fetch/defaultFetcher';
import { ListResponse } from '../../../../hooks/fetch/types';
import { dateUtil } from '../../../../../../js/modules/date';
import { SkeletonRectangle } from '@Page/InvoicingDialog/component/SkeletonRectangle/SkeletonRectangle';
import { TRIPLETEX_ID } from '@General/PBC/util';

export interface InvoicingFormProps {
    className?: string;
    order: Order;
    paymentTypes: PaymentType[];
    invoiceSettings: InvoiceSettings;
    onCancel: () => void;
    createInvoice: (
        formValues: DoInvoiceFieldValues,
        order: Order,
        sendMethods: string[]
    ) => Promise<string[]>;
    electronicReceiveState: ElectronicReceiveState;

    updateCanReceiveElectronic: (phoneNumber: string) => void;
    isLoading: boolean;
    isUserAdmin: boolean | undefined;
    isCompanyAdmin: boolean | undefined;
}

export interface FormProps {
    fields: {
        invoiceAKontoWithVAT: UseFormRegisterReturn;
        aKontoAmount: UseFormRegisterReturn;
        aKontoComment: UseFormRegisterReturn;
        invoiceDate: UseFormRegisterReturn;
        createAKonto: UseFormRegisterReturn;
        createBackorder: UseFormRegisterReturn;
        paidAmount: UseFormRegisterReturn;
        paidAmountAccountCurrency: UseFormRegisterReturn;
        paidAmountRestAccountCurrency: UseFormRegisterReturn;
        paymentTypeId: UseFormRegisterReturn;
        restAmountPaymentTypeId: UseFormRegisterReturn;
        invoiceSendMethod: UseFormRegisterReturn;
        customerEmail: UseFormRegisterReturn;
        customerPhoneNumber: UseFormRegisterReturn;
        customerOrganizationNumber: UseFormRegisterReturn;
        invoiceIdForCreditNote: UseFormRegisterReturn;
    };
    errors: FieldErrors<DoInvoiceFieldValues>;
    onSubmit: React.FormEventHandler<HTMLFormElement>;
    control: Control<DoInvoiceFieldValues>;
    watch: UseFormWatch<DoInvoiceFieldValues>;
    reset: UseFormReset<DoInvoiceFieldValues>;
    trigger: UseFormTrigger<DoInvoiceFieldValues>;
    setError: UseFormSetError<DoInvoiceFieldValues>;
    setValue: UseFormSetValue<DoInvoiceFieldValues>;
}

export function InvoiceTotal({
    isLoading,
    total,
    totalExcludingVAT,
    amountRoundoff,
    currencyCode,
}: {
    isLoading?: boolean;
    total: number;
    totalExcludingVAT: number;
    amountRoundoff?: number;
    currencyCode?: string;
}) {
    const orderAmountRoundOff = amountRoundoff ?? 0;
    const totalVAT = total - totalExcludingVAT - orderAmountRoundOff;

    if (isLoading) {
        return <SkeletonRectangle width={200} height={49} />;
    }

    return (
        <div className="atl-flex atl-flex-col atl-items-end">
            <span className="atl-items-end atl-text-lg atl-font-medium atl-mb-4">
                {format2(total)} {currencyCode}
            </span>

            <div className="atl-flex atl-text-grey-80">
                <span className="atl-text-sm atl-pr-8 invoicing-total--border-right">
                    {getMessage('text_vat').toUpperCase()}: {format2(totalVAT)}
                </span>

                <span className="atl-text-sm atl-pl-8">
                    {getMessage('text_net')}:{' '}
                    {format2(totalExcludingVAT + orderAmountRoundOff)}
                </span>
            </div>
        </div>
    );
}

export function InvoicingForm(props: InvoicingFormProps) {
    const {
        className,
        order,
        invoiceSettings,
        paymentTypes,
        onCancel,
        createInvoice,
        electronicReceiveState,

        isLoading,
    } = { ...props };

    const formProps = useDoInvoice(
        order,
        paymentTypes,
        createInvoice,
        electronicReceiveState,
        invoiceSettings
    );
    const { errors, fields, onSubmit, watch }: FormProps = formProps;

    const createAKonto = watch('createAKonto');
    const invoiceSendMethod = watch('invoiceSendMethod');

    const isMobile = useDeviceType() === 'mobile';

    const isVipps =
        invoiceSendMethod === SendTypes.EFAKTURA &&
        invoiceSettings.sendTypes.includes(SendTypes.VIPPS);
    const isInvalidSendMethod =
        !invoiceSettings.sendTypes.includes(invoiceSendMethod) &&
        !isVipps &&
        invoiceSendMethod !== SendTypes.MANUAL;

    const isEHFOrEfakturaSelected =
        invoiceSendMethod === SendTypes.EFAKTURA ||
        invoiceSendMethod === SendTypes.EHF ||
        invoiceSendMethod === SendTypes.VIPPS;
    const customerCanReceiveSendMethod =
        !isEHFOrEfakturaSelected ||
        electronicReceiveState !== ElectronicReceiveState.NO;

    function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        onSubmit(event);
        // Sometimes validation errors will not show in GUI because the field is missing, so we need to log it so that it is possible to figure out what is wrong when the submit button does not work
        console.log('Validation errors:');
        console.log(errors);
    }

    const invoicingValidationErrors = getInvoicingValidationErrors(order);

    if (invoicingValidationErrors.length > 0) {
        return (
            <div className="atl-p-32">
                {invoicingValidationErrors.map((error, index) => (
                    <div className="atl-text-red-100" key={index}>
                        {error}
                    </div>
                ))}
                <div className="atl-mt-16 atl-mb-8 tlx-invoicingDialog__mb_auto">
                    <Button
                        variant="secondary"
                        onClick={onCancel}
                        data-testid="close-invoicing-dialog-button"
                    >
                        {getMessage('button_cancel')}
                    </Button>
                </div>
            </div>
        );
    }

    if (errors.invoiceSendMethod?.type === 'manual') {
        return <SendingError order={order} />;
    }

    const aKontoOptions = (
        <div className="atl-mt-16 atl-ml-24">
            <div className="atl-pb-16 atl-grid tlx-invoicingDialog__gridCols2">
                <Group className="atl-mr-16">
                    <Label htmlFor="aKontoAmount">
                        {getMessage('text_akonto_amount')}
                    </Label>
                    <Amount className="atl-w-full" {...fields.aKontoAmount} />
                    <ValidationError error={errors.aKontoAmount} />
                </Group>
                <CheckboxGroup className="tlx-invoicingDialog__to_bottom atl-pt-16">
                    <Input
                        data-testid="invoice-a-konto-with-vat-input"
                        type="checkbox"
                        {...fields.invoiceAKontoWithVAT}
                        disabled={
                            order.totalInvoicedOnAccountAmountAbsoluteCurrency >
                            0
                        }
                    />
                    <Label htmlFor="invoiceAKontoWithVAT">
                        {getMessage('text_invoice_akonto_with_vat')}
                    </Label>
                </CheckboxGroup>
            </div>
            <Group>
                <Label htmlFor="aKontoComment">
                    {getMessage('text_comment')}
                </Label>
                <Textarea
                    data-testid="a-konto-comment-textarea"
                    {...fields.aKontoComment}
                    className="atl-w-full"
                />
            </Group>
        </div>
    );

    if (electronicReceiveState === ElectronicReceiveState.LOADING) {
        return (
            <div
                className={classNames('', {
                    'tlx-invoicingDialog-mobile': isMobile,
                    'tlx-invoicingDialog': !isMobile,
                })}
            >
                <div className="tlx-invoicingDialog__spinner">
                    <LoadingSpinner />
                </div>
            </div>
        );
    }

    return (
        <div className={`atl-p-32 ${className ?? ''}`}>
            <ModalCloseButton />
            <div className={!isMobile ? 'atl-justify-between atl-flex' : ''}>
                <div className="atl-flex atl-flex-col">
                    <span className="atl-text-xl atl-mt-auto">
                        {getMessage('text_invoicing')}
                    </span>

                    <div>
                        <span className="atl-text-grey-80 atl-mr-4">
                            {getMessage('text_next_invoice_number')}:
                        </span>

                        <span>#{invoiceSettings.nextInvoiceNumber}</span>
                    </div>
                </div>
            </div>
            {order.customer.singleCustomerInvoice &&
            window.contextId == TRIPLETEX_ID ? (
                <Alert className="atl-mt-8" variant="error">
                    <AlertContent>
                        {getMessage('text_invoice_single_invoice_customer')}
                    </AlertContent>
                </Alert>
            ) : null}
            <div className="tlx-invoicingDialog__divider atl-mt-24" />
            <form onSubmit={handleSubmit} autoComplete="off">
                <div className="atl-mb-16">
                    <div
                        className={classNames('', {
                            'atl-grid tlx-invoicingDialog__gridCols2':
                                !isMobile,
                        })}
                    >
                        <Group className="atl-mr-16">
                            <Label htmlFor="invoiceDate">
                                {getMessage('text_invoice_date')}
                            </Label>
                            <Input
                                data-testid="invoice-date-input"
                                autoFocus
                                type="date"
                                {...fields.invoiceDate}
                                className="atl-w-full"
                            />
                            <ValidationError error={errors.invoiceDate} />
                        </Group>
                        <CheckboxGroup className="tlx-invoicingDialog__to_bottom">
                            <Input
                                data-testid="create-a-konto-input"
                                type="checkbox"
                                {...fields.createAKonto}
                            />
                            <Label htmlFor="createAKonto">
                                {getMessage('text_create_akonto_invoice')}
                            </Label>
                        </CheckboxGroup>
                    </div>
                    {createAKonto && aKontoOptions}
                </div>
                <>
                    <div className="tlx-invoicingDialog__divider" />
                    <SendMethod {...props} {...formProps} />
                    <div className="tlx-invoicingDialog__divider" />
                </>
                {order.preliminaryInvoice.amountCurrency < 0 && (
                    <CreditNoteOptions {...props} {...formProps} />
                )}
                <Payments {...formProps} {...props} />
                {order.canCreateBackorder && (
                    <CheckboxGroup className="atl-pb-16 atl-pt-16">
                        <Input
                            data-testid="create-backorder-input"
                            type="checkbox"
                            {...fields.createBackorder}
                        />
                        <Label htmlFor="createBackorder">
                            {getMessage('text_backorder_will_be_created')}
                        </Label>
                    </CheckboxGroup>
                )}
                <div className="atl-mt-16 atl-mb-8">
                    {!order.preliminaryInvoice.isPeriodizationPossible && (
                        <Message
                            extraClassnames="atl-mb-16"
                            message={{
                                text: getMessage(
                                    'text_period_closed_posting_on_invoice_date'
                                ),
                                type: MESSAGE_TYPE.WARNING,
                                position: MESSAGE_POSITION.INLINE,
                                isCloseable: false,
                            }}
                        />
                    )}
                    {isLoading && Object.keys(errors).length === 0 && (
                        <LoadingSpinner />
                    )}
                    {!(isLoading && Object.keys(errors).length === 0) && (
                        <div className="atl-justify-between atl-flex atl-items-end">
                            <div>
                                <Button
                                    data-testid="order-submit-invoice-button"
                                    className="atl-mr-16"
                                    type="submit"
                                    disabled={
                                        isInvalidSendMethod ||
                                        !customerCanReceiveSendMethod ||
                                        getSendTypeErrors(
                                            order.customer,
                                            invoiceSendMethod
                                        ) != ''
                                    }
                                >
                                    {invoiceSendMethod === SendTypes.MANUAL
                                        ? getMessage('text_create_no_send')
                                        : getMessage('text_send') +
                                          ' ' +
                                          getSendMethodNameForInvoicingButton(
                                              invoiceSendMethod
                                          )}
                                </Button>
                            </div>
                            {!isMobile && (
                                <InvoiceTotal
                                    isLoading={isLoading}
                                    total={
                                        order.preliminaryInvoice.amountCurrency
                                    }
                                    totalExcludingVAT={
                                        order.preliminaryInvoice
                                            .amountExcludingVatCurrency
                                    }
                                    amountRoundoff={
                                        order.preliminaryInvoice.amountRoundoff
                                    }
                                    currencyCode={order.currency.code}
                                />
                            )}
                        </div>
                    )}
                </div>
            </form>
        </div>
    );
}

function Payments({
    order,
    control,
    fields,
    errors,
    paymentTypes,
}: InvoicingFormProps & FormProps) {
    const createAKonto = Number(
        useWatch({
            control,
            name: 'createAKonto',
            defaultValue: false,
        })
    );
    const paymentTypeId = Number(
        useWatch({
            control,
            name: 'paymentTypeId',
            defaultValue: '0',
        })
    );
    const restAmountPaymentTypeId = Number(
        useWatch({
            control,
            name: 'restAmountPaymentTypeId',
            defaultValue: '0',
        })
    );
    const aKontoAmount = format.unFormat(
        useWatch({
            control,
            name: 'aKontoAmount',
            defaultValue: '0,00',
        })
    );
    const paidAmount = format.unFormat(
        useWatch({
            control,
            name: 'paidAmount',
            defaultValue: '0,00',
        })
    );

    const paymentType = paymentTypes.find(
        (type) => type.id === Number(paymentTypeId)
    );
    const paymentTypeCurrencyCode =
        paymentType && paymentType.debitAccount.currency
            ? paymentType.debitAccount.currency.code
            : undefined;

    const restAmountPaymentType = paymentTypes.find(
        (type) => type.id === Number(restAmountPaymentTypeId)
    );

    const restAmountPaymentCurrencyCode =
        restAmountPaymentType && restAmountPaymentType.debitAccount.currency
            ? restAmountPaymentType.debitAccount.currency.code
            : undefined;

    const showPaymentAmount = paymentTypeId > 0;
    const amountCurrency = order.preliminaryInvoice.amountCurrency;

    const showRestPayment =
        paidAmount > 0 &&
        ((createAKonto && paidAmount < aKontoAmount) ||
            (!createAKonto && paidAmount < amountCurrency));

    const isDifferentCurrency =
        paymentType &&
        paymentTypeCurrencyCode &&
        order.currency.code != paymentTypeCurrencyCode;

    return (
        <>
            <Group className="atl-pb-16">
                <Label id="paymentTypeId">
                    {getMessage('text_payment_type')}
                </Label>
                <Select
                    data-testid="payment-type-select"
                    className="atl-w-full"
                    id="paymentTypeId"
                    aria-labelledby="paymentTypeIdLabel"
                    {...fields.paymentTypeId}
                >
                    <Option value="0">{getMessage('text_paid_later')}</Option>
                    {paymentTypes.map((pt) => {
                        return (
                            <Option key={pt.id} value={pt.id.toString()}>
                                {pt.description}
                            </Option>
                        );
                    })}
                </Select>
            </Group>
            {showPaymentAmount && (
                <>
                    <div
                        className={classNames('atl-pb-16', {
                            'atl-grid tlx-invoicingDialog__gridCols2 atl-pb-16':
                                isDifferentCurrency,
                        })}
                    >
                        <Group
                            className={classNames({
                                'atl-mr-16': isDifferentCurrency,
                            })}
                        >
                            <Label htmlFor="paidAmount">
                                {getMessage('text_amount') +
                                    ' ' +
                                    order.currency.code}
                            </Label>
                            <Amount
                                className="atl-w-full"
                                {...fields.paidAmount}
                            />
                            <ValidationError error={errors.paidAmount} />
                        </Group>
                        {isDifferentCurrency && (
                            <Group>
                                <Label htmlFor="paidAmountAccountCurrency">
                                    {getMessage('text_amount') +
                                        ' ' +
                                        paymentTypeCurrencyCode}
                                </Label>
                                <Amount
                                    className="atl-w-full"
                                    {...fields.paidAmountAccountCurrency}
                                />
                                <ValidationError
                                    error={errors.paidAmountAccountCurrency}
                                />
                            </Group>
                        )}
                    </div>
                    {showRestPayment && (
                        <>
                            <Group className="atl-pb-16">
                                <Label id="restAmountPaymentTypeId">
                                    {getMessage('text_rest_amount')}
                                </Label>
                                <Select
                                    className="atl-w-full"
                                    id="restAmountPaymentTypeId"
                                    data-testid="rest-payment-type-select"
                                    aria-labelledby="restAmountPaymentTypeIdLabel"
                                    {...fields.restAmountPaymentTypeId}
                                >
                                    <Option value="0">
                                        {getMessage('text_paid_later')}
                                    </Option>
                                    {paymentTypes.map((pt) => {
                                        return (
                                            <Option
                                                key={pt.id}
                                                value={pt.id.toString()}
                                            >
                                                {pt.description}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </Group>
                            {restAmountPaymentType &&
                                restAmountPaymentCurrencyCode &&
                                order.currency.code !==
                                    restAmountPaymentCurrencyCode &&
                                restAmountPaymentTypeId > 0 && (
                                    <Group className="atl-pb-16">
                                        <Label htmlFor="paidAmountRestAccountCurrency">
                                            {getMessage('text_amount') +
                                                ' ' +
                                                restAmountPaymentCurrencyCode}
                                        </Label>
                                        <Amount
                                            className="atl-w-full"
                                            {...fields.paidAmountRestAccountCurrency}
                                        />
                                        <ValidationError
                                            error={
                                                errors.paidAmountRestAccountCurrency
                                            }
                                        />
                                    </Group>
                                )}
                        </>
                    )}
                </>
            )}
        </>
    );
}

function CreditNoteOptions({
    fields,
    order,
    errors,
}: InvoicingFormProps & FormProps) {
    const customerId = order.customer.id;
    const [query, setQuery] = useState('');
    const invoicesFetchState = useFetchInvoices(customerId, query);
    if (!invoicesFetchState.data) {
        return null;
    }
    const invoices = invoicesFetchState.data
        .filter((invoice) => {
            return invoice.customer.id == customerId && invoice.amount > 0;
        })
        .sort((a, b) => b.invoiceNumber - a.invoiceNumber);

    return (
        <>
            <div className="atl-mb-16">
                <Label>{getMessage('text_invoice_to_credit')}</Label>
                <Dropdown
                    data-testid="invoice-to-credit-dropdown"
                    defaultDisplayName={getMessage('option_not_chosen')}
                    {...fields.invoiceIdForCreditNote}
                >
                    <DropdownSearchOpener
                        className="atl-w-full"
                        onChange={(event) => setQuery(event.target.value)}
                    />
                    <DropdownDrawer>
                        <DropdownScrollContainer>
                            {invoices.length === 0
                                ? getMessage('text_no_invoices_found')
                                : null}
                            <Option key={0} value={'0'}>
                                {getMessage('option_not_chosen')}
                            </Option>
                            {invoices.map((invoice) => (
                                <Option
                                    key={invoice.id}
                                    value={invoice.id.toString()}
                                >
                                    {invoice.invoiceNumber +
                                        '    ' +
                                        invoice.invoiceDate}
                                </Option>
                            ))}
                        </DropdownScrollContainer>
                    </DropdownDrawer>
                </Dropdown>
                <ValidationError error={errors.invoiceIdForCreditNote} />
            </div>
        </>
    );
}

interface InvoiceFetchState {
    data: Invoice[] | undefined;
    error: any;
    isLoading: boolean;
}

function getInvoicesUrl(customerId: number, query: string) {
    const threeMonthsAgo: Date = dateUtil.addMonths(dateUtil.today(), -3);
    const fromDateAsString =
        (query ? 1900 : threeMonthsAgo.getFullYear()) +
        '-' +
        threeMonthsAgo.getMonth() +
        '-' +
        threeMonthsAgo.getDate();
    const fields =
        'id, invoiceNumber, customer(id, name), invoiceDate, amount, amountCurrency, currency(code)';

    return new URL(
        `v2/invoice?customerId=${customerId}&fields=${fields}&count=${200}&invoiceDateFrom=${fromDateAsString}&invoiceDateTo=3000-01-01${
            query ? '&invoiceNumber=' + query : ''
        }`,
        window.location.origin
    ).href;
}

function useFetchInvoices(customerId: number, query: string) {
    const [fetchState, setFetchState] = useState<InvoiceFetchState>({
        data: undefined,
        error: undefined,
        isLoading: true,
    });
    const [initialResult, setInitialResult] = useState<Invoice[] | undefined>();

    useEffect(() => {
        const filteredInvoices = initialResult?.filter((invoice) => {
            return invoice.invoiceNumber.toString().startsWith(query);
        });

        if (filteredInvoices && filteredInvoices.length > 0 && query) {
            setFetchState({
                ...fetchState,
                data: filteredInvoices,
            });
        } else {
            // The initial result has a limit, so if the invoice that is search for is not in the original result, we need to search again
            const url = getInvoicesUrl(customerId, query);
            defaultFetcher<ListResponse<Invoice>>(url)
                .then((data) => {
                    setFetchState({
                        data: data?.values,
                        error: undefined,
                        isLoading: false,
                    });
                })
                .catch((exception) => {
                    setFetchState({
                        data: undefined,
                        error: exception,
                        isLoading: false,
                    });
                });
            if (!initialResult) {
                setInitialResult(fetchState.data);
            }
        }
    }, [query]);

    return fetchState;
}
