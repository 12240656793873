// Returns a list containing lists of orders, grouped by the following logic:
// 1 - The order should be invoiced the standard way - alone - and using the regular API for creation.
// 2 - The customer of a series of orders has the setting "singleCustomerInvoice" = true AND all the orders of the grouping -
//     has the same customer, currency, due date, receiver email and attn., and invoice and invoiceSMSNotificationNumber -
//     and should be "merge invoiced" using the /order/:invoiceMultipleOrders end-point.
// Example: [[order], [order], [singleCustomerInvoiceOrder, singleCustomerInvoiceOrder]]
import { Order } from '@Page/InvoicingDialog/types/InvoicingDialog.type';

function compareLength(
    invoiceOrdersA: Order[],
    invoiceOrdersB: Order[]
): number {
    if (invoiceOrdersA.length > invoiceOrdersB.length) {
        return -1;
    }
    if (invoiceOrdersA.length < invoiceOrdersB.length) {
        return 1;
    }
    return 0;
}

export const groupOrdersByInvoice = (orders: Order[]): Array<Order[]> => {
    const invoiceDictionary: { [index: string]: Order[] } = {};

    orders.forEach((order) => {
        if (!order.customer.singleCustomerInvoice) {
            invoiceDictionary[order.id] = [order];
            return;
        }

        const key =
            order.customer.id.toString() +
            order.currency.code +
            order.invoicesDueIn.toString() +
            order.invoicesDueInType +
            order.receiverEmail +
            order.attn?.id +
            getInvoiceSMSNotificationNumberKey(order); // phone number is only considered if invoiceSendSMSNotification is true on both customer and order

        if (invoiceDictionary[key] !== undefined) {
            invoiceDictionary[key].push(order);
        } else {
            invoiceDictionary[key] = [order];
        }
    });

    const ordersGroupedByInvoice = Object.values(invoiceDictionary);
    ordersGroupedByInvoice.sort(compareLength); // Single customer invoices comes first in list

    return ordersGroupedByInvoice;
};

function getInvoiceSMSNotificationNumberKey(order: Order): string | undefined {
    const isCustomerSmsNotificationOnForInvoicing =
        order.customer.invoiceSendSMSNotification;
    const isOrderSmsNotificationOnForInvoicing =
        order.invoiceSendSMSNotification;

    if (
        isCustomerSmsNotificationOnForInvoicing &&
        isOrderSmsNotificationOnForInvoicing
    ) {
        return order.invoiceSMSNotificationNumber;
    }
    return undefined;
}
