import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';

import { SetDeveloper } from '@General/action/Developer.action';
import { DeveloperMetaData } from '@General/types/Developer.type';

function* sagaLoadData(): SagaIterator {
    const data = document.getElementById(
        'redux-data-preload-Developer'
    )?.textContent;

    const developer: DeveloperMetaData | false =
        JSON.parse(data ?? 'false') ?? false;

    yield put(
        SetDeveloper({
            developer,
        })
    );
}

export const developerSaga = [call(sagaLoadData)];
