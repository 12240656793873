/**
 * Copyright (C) 2013, Daniel Wachsstock
 *
 * Taken from: http://bililite.com/blog/2009/02/04/new-plugin-scrollintoview/
 *
 * Shared and licensed under Attribution-ShareAlike 3.0 Unported: http://creativecommons.org/licenses/by-sa/3.0/
 *
 * (This information is based on the footer of the web-site bililite.com.)
 *
 */
import jQuery from 'jquery';

(function($){
	//lazy evaluate the scrolling element (needs to be evaluated after the body element exists)
	let html;

		// default for this plugin, outerWidth/Height  and offset is to include padding and border but exclude margin.
		// This adjusts the measurements if the options are set to something different
	function propPx(el, opts, what){
		return	Math.round(
			(!opts.padding && -parseFloat(el.css('padding'+what) || 0)) +
			(!opts.border && -parseFloat(el.css('border'+what+'Width') || 0)) +
			(opts.margin && parseFloat(el.css('margin'+what) || 0))
		);
	}

	// find the effective size of the window; the clientHeight/Width less the size of the element. This gives
	// the width/height of the rectangle that the top left corner of the element can go in and still
	// leave room for the entire element
	function constrainPx (el, opts, what){
		const element = opts.element || document.documentElement;

		return element['client'+what] - el['outer'+what]() -
			(what === 'Height' ?
				propPx(el, opts, 'Top')+propPx(el, opts, 'Bottom') :
				propPx(el, opts, 'Left')+propPx(el, opts, 'Right')
			);
	}

	$.fn.scrollIntoView = function(opts, easing, fn){

		opts = opts || {};
		// Bugfix, if
		if(opts.element) {
			$('body').frameless("scrollIntoView", this, opts);
			return this;
		}

		// Bugfix 2014.04.31, Lars-Erik Bruce
		// Try to scroll the element. If scrolling doesn't work, use the other element
		if(!html && !opts.element) {
			html = $('html')[0];
			const top = html.scrollTop;

			html.scrollTop = top + 1;

			if(html.scrollTop === top + 1)
				// Undo scrolling
				{html.scrollTop = top - 1;}
			else
				{html = $('body')[0];}
		}

		if (typeof opts != 'object') {opts = {duration: opts, easing: easing, complete: fn};}
		opts = $.extend({}, $.fn.scrollIntoView.defaults, $.metadata && this.metadata()['scrollIntoView'], opts);
		if (opts.complete){
			// the animate is done on the html element; the callback needs to be done on the target.
			const complete = opts.complete; const self = this[0];
            // eslint-disable-next-line
			opts.complete = function (){complete.apply(self, arguments);};
		}
		if (opts.margin) {opts.border = true;} // make sure the properties are logically consistent
		if (opts.border) {opts.padding = true;}
		const offset = this.offset(); // offset includes padding and border. We need to adjust
		offset.top -= propPx(this, opts, 'Top');
		offset.left -= propPx(this, opts, 'Left');
		const h = Math.max(0, constrainPx(this, opts, 'Height')); // if the size is negative then the element will not fit.  Just scroll so the top left corner is maximally visible
			const w = Math.max(0, constrainPx(this, opts, 'Width'));
			const scrollTop = Math.min(offset.top-opts.offsetTop, Math.max(html.scrollTop, offset.top-h+opts.offsetBottom));
			const scrollLeft = Math.min(offset.left-opts.offsetLeft, Math.max(html.scrollLeft, offset.left-w+opts.offsetRight));

		// Do not scroll screen if it is not necessary. Bugfix: bruce 2013.11.06
		if(scrollTop !== html.scrollTop || scrollLeft !== html.scrollLeft) {
			$(html).animate({
				scrollTop: scrollTop,
				scrollLeft: scrollLeft
			}, opts);
		} else {
			if(opts.complete) {opts.complete();}
		}
		return this;
	};
	$.fn.scrollIntoView.defaults = {
		padding: true,
		border: true,
		margin: false,
		offsetTop: 0,
		offsetBottom: 0,
		offsetLeft: 0,
		offsetRight: 0
	};
})(jQuery);
