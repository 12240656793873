import * as React from 'react';
import { PageData, PageLoader } from '@Component/PageLoader';
import { ProductDiscountPage } from '@Page/LogisticsV2/pages/Product discount/overview/ProductDiscoutPage';
import { renderComponent } from '@General/renderComponent';

const loader: React.FC = () => (
    <PageLoader<{}> loader={async () => page} adjustWrapperDiv />
);

export function renderDiscountPolicyOverview(domElementId: string) {
    renderComponent(loader, domElementId);
}
const PAGE_ID = 'ProductDiscountPage';
const page: PageData<any> = {
    page: ProductDiscountPage,
    modules: [
        {
            id: PAGE_ID,
        },
    ],
};
