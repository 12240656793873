import { useLoadMoreTarget } from '@Page/LogisticsV2/hooks/fetcher';
import { PurchaseOrderOverviewHeaderCell } from '@Page/LogisticsV2/pages/PurchaseOrder/overview/PurchaseOrderOverviewTableHeader';
import { PurchaseOrderOverviewTableRowActions } from '@Page/LogisticsV2/pages/PurchaseOrder/overview/PurchaseOrderOverviewTableRowActions';
import tourNewPOOverview from '@Page/LogisticsV2/pages/PurchaseOrder/overview/TourMe/GOLDSHARK_NEW_PO_OVERVIEW_tour.json';
import {
    ALL_STATUSES,
    PurchaseOrderDTO,
    PurchaseOrderOverviewActionModel,
    PurchaseOrderOverviewModel,
    PurchaseOrderStatus,
} from '@Page/LogisticsV2/pages/PurchaseOrder/overview/types';
import {
    getLink,
    updateUrl,
} from '@Page/LogisticsV2/pages/PurchaseOrder/utils';
import { SortingType } from '@Page/LogisticsV2/utils/enums';
import {
    getSortDirection,
    getSorting,
} from '@Page/LogisticsV2/utils/functions';
import {
    Input,
    SkeletonCheckbox,
    SkeletonRow,
    SkeletonText,
    SortableTableHeader,
} from '@tlx/atlas';
import { Tour } from '@tlx/tourme-core';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { runTour } from '../../../../../../../js/modules/tourme';
import '../style.css';
import { PurchaseOrderOverviewEmpty } from './components';
import { PurchaseOrderOverviewTableCell } from './PurchaseOrderOverviewTableRow';
import { NoResultsEmptyState } from '@Page/LogisticsV2/components/EmptyState/EmptyState';

export function PurchaseOrderOverviewTable({
    purchaseOrders,
    actions,
}: {
    purchaseOrders: PurchaseOrderOverviewModel;
    actions: PurchaseOrderOverviewActionModel;
}) {
    runTour(tourNewPOOverview as Tour);

    const selectedIds = Object.keys(purchaseOrders.selectedLines).map((id) =>
        Number(id)
    );
    const isSelected = (purchaseOrderId: number) => {
        return selectedIds.includes(purchaseOrderId);
    };
    const [isSelectedAll, setIsSelectedAll] = useState(false);
    const isAllSelected = () => {
        const visibleIds = purchaseOrders.data.map((line) => line.id);
        return (
            visibleIds.length > 0 &&
            visibleIds.every((id) => selectedIds.includes(id))
        );
    };

    const noFilterAndSearch =
        purchaseOrders.filters.orderFilter.includes('') &&
        purchaseOrders.filters.supplierFilter.includes('') &&
        purchaseOrders.filters.projectFilter.includes('') &&
        (purchaseOrders.filters.statusFilter.includes(ALL_STATUSES) ||
            purchaseOrders.filters.statusFilter.includes('')) &&
        purchaseOrders.filters.query === '' &&
        purchaseOrders.filters.supplierProductsFilter.includes('');

    useEffect(() => {
        setIsSelectedAll(isAllSelected());
    }, [
        purchaseOrders.selectedLines,
        purchaseOrders.filters.query,
        purchaseOrders.filters.statusFilter,
        purchaseOrders.filters.supplierFilter,
        purchaseOrders.filters.projectFilter,
        purchaseOrders.filters.orderFilter,
        purchaseOrders.filters.supplierProductsFilter,
    ]);

    const loadMoreRef = useLoadMoreTarget<HTMLDivElement>(
        purchaseOrders.loadMore
    );
    return (
        <table className="atl-table">
            <PurchaseOrderOverviewTableHeader
                isSelectedAll={isSelectedAll}
                onToggleSelectAll={() =>
                    purchaseOrders.onToggleSelectAll(isAllSelected())
                }
                sortBy={purchaseOrders.sortBy}
                onChangeSortBy={purchaseOrders.onChangeSortBy}
                columns={purchaseOrders.columns}
            />
            <tbody>
                {purchaseOrders.data.length > 0 &&
                    purchaseOrders.data.map((purchaseOrder) => (
                        <PurchaseOrderOverviewTableRow
                            key={purchaseOrder.id}
                            purchaseOrder={purchaseOrder}
                            isSelected={isSelected}
                            actions={actions}
                            rowAction={purchaseOrders}
                        />
                    ))}
                {purchaseOrders.isLoading && (
                    <SkeletonRow>
                        <td className="atl-td atl-td--checkbox">
                            <SkeletonCheckbox />
                        </td>
                        {[
                            ...Array.from(
                                Array(purchaseOrders.columns.length + 2).keys()
                            ),
                        ].map((index) => (
                            <td className="atl-td" key={index}>
                                <SkeletonText className="atl-w-full" />
                            </td>
                        ))}
                    </SkeletonRow>
                )}
                {!purchaseOrders.isLoading &&
                    purchaseOrders.data.length === 0 &&
                    noFilterAndSearch && (
                        <PurchaseOrderOverviewEmpty
                            colSpan={purchaseOrders.columns.length + 2}
                        />
                    )}
                {!purchaseOrders.isLoading &&
                    purchaseOrders.data.length === 0 &&
                    !noFilterAndSearch && (
                        <NoResultsEmptyState
                            colSpan={purchaseOrders.columns.length + 2}
                            onClick={() => updateUrl()}
                        />
                    )}
                {purchaseOrders.hasMore && (
                    <tr>
                        <td>
                            <div ref={loadMoreRef} />
                        </td>
                    </tr>
                )}
            </tbody>
        </table>
    );
}

function PurchaseOrderOverviewTableHeader({
    isSelectedAll,
    columns,
    onToggleSelectAll,
    sortBy,
    onChangeSortBy,
}: {
    isSelectedAll: boolean;
    columns: string[];
    onToggleSelectAll: () => void;
    sortBy: SortingType;
    onChangeSortBy: Dispatch<SetStateAction<SortingType>>;
}) {
    return (
        <thead className="atl-thead atl-thead--sticky">
            <tr className="atl-tr">
                <th
                    className="atl-th atl-th--checkbox atl-align-middle"
                    key="checkboxHeader"
                >
                    <Input
                        data-testid="purchase-order-table-checkbox"
                        type="checkbox"
                        checked={isSelectedAll}
                        onClick={() => onToggleSelectAll()}
                        readOnly
                    />
                </th>
                <SortableTableHeader
                    className="atl-font-bold atl-align-middle"
                    data-testid="numberHeader"
                    sortDirection={getSortDirection(
                        sortBy,
                        SortingType.NUMBER_ASCENDING,
                        SortingType.NUMBER_DESCENDING
                    )}
                    onClick={() =>
                        onChangeSortBy(
                            getSorting(
                                sortBy,
                                SortingType.NUMBER_ASCENDING,
                                SortingType.NUMBER_DESCENDING
                            )
                        )
                    }
                >
                    {getMessage('text_number')}
                </SortableTableHeader>
                {columns.map((column, index) => (
                    <PurchaseOrderOverviewHeaderCell
                        key={`${column}-${index}`}
                        column={column}
                        sortBy={sortBy}
                        onChangeSortBy={onChangeSortBy}
                    />
                ))}
                <th className="atl-th atl-font-bold" key="menuHeader" />
            </tr>
        </thead>
    );
}

function PurchaseOrderOverviewTableRow({
    purchaseOrder,
    rowAction,
    actions,
    isSelected,
}: {
    purchaseOrder: PurchaseOrderDTO;
    rowAction: PurchaseOrderOverviewModel;
    actions: PurchaseOrderOverviewActionModel;
    isSelected: (purchaseOrderId: number) => boolean;
}) {
    const hasActions =
        purchaseOrder.status === PurchaseOrderStatus.OPEN ||
        purchaseOrder.status === PurchaseOrderStatus.CONFIRMED ||
        purchaseOrder.status === PurchaseOrderStatus.SENT ||
        purchaseOrder.status === PurchaseOrderStatus.RECEIVING ||
        purchaseOrder.status === PurchaseOrderStatus.CANCELLED ||
        (purchaseOrder.status === PurchaseOrderStatus.CLOSED &&
            purchaseOrder.receivedDate === null);

    return (
        <tr className="atl-tr atl-tr--highlight">
            <td
                className="atl-td atl-font-normal atl-align-middle atl-td--checkbox"
                key="checkboxRow"
            >
                {hasActions && (
                    <Input
                        data-testid="purchase-order-checkbox"
                        type="checkbox"
                        checked={isSelected(purchaseOrder.id)}
                        onClick={() => {
                            rowAction.onChangeLineSelection(
                                purchaseOrder,
                                isSelected(purchaseOrder.id)
                            );
                        }}
                        readOnly
                    />
                )}
            </td>
            <td
                className="atl-td atl-font-normal atl-align-middle"
                key="numberRow"
            >
                <a href={getLink(purchaseOrder)} className="atl-text-grey-100">
                    {purchaseOrder.number}
                </a>
            </td>
            {rowAction.columns.map((column, index) => (
                <PurchaseOrderOverviewTableCell
                    key={index}
                    column={column}
                    purchaseOrder={purchaseOrder}
                />
            ))}
            <PurchaseOrderOverviewTableRowActions
                purchaseOrder={purchaseOrder}
                actions={actions}
            />
        </tr>
    );
}
