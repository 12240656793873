import { Option } from '@Component/DropDown/types';

export type ErrorResponse = {
    error: Error | null;
    errorMsg: string | null;
    hasError: true;
};

export type MappedResponse<T> = Option<T>[] | ErrorResponse;

export function isValidResponse<T>(
    response: MappedResponse<T>
): response is Option<T>[] {
    return response && !('hasError' in response);
}

/**
 * Describes a source of data that can be called for getting either one or many entries.
 * Asynchronous by design.
 */
export abstract class Fetcher<T> {
    /**
     * Fetch arbitrary data based on arbitrary parameters.
     * Implementation specific how the parameters will be used.
     *
     * @param {ObjectIndex} params The parameters to use.
     * @param pageIndex Current page index for infinite scroll
     *
     * @return {Promise<MappedResponse<T>>} The data via a Promise.
     */
    abstract fetchList(
        params: ObjectIndex,
        pageIndex?: number
    ): Promise<MappedResponse<T>>;

    /**
     * Fetch arbitrary data based on identifier.
     * Implementation specific how the identifier relates to the data.
     *
     * @param {string | number} id The identifier of the data.
     *
     * @return {Promise<MappedResponse<T>>} The data via a Promise.
     */
    abstract fetchSingle(id: string | number): Promise<MappedResponse<T>>;
}
