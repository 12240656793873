export type WarehouseOverviewAPIModel = {
    id: number;
    name: string;
    number: string;
    description: string;
    lastStocking: string;
    status: string;
    isMainInventory: boolean;
    isInactive: boolean;
    deletable: boolean;
};

export const STATUS_ACTIVE_INACTIVE = 2;
