import React from 'react';
import { PageLoader } from '@Component/PageLoader';
import { RouteData } from '@General/Router';
import { Team } from '@General/Team';

const Page = () => (
    <PageLoader<{}>
        loader={async () => import('./AddonsPartner').then((m) => m.page)}
        adjustWrapperDiv
    />
);

export const route: RouteData = {
    component: Page,
    path: '/execute/addonPartner',
    title: () => getMessage('text_package_integration_partner'),
    team: Team.BEEHIVE,
    documentationComponent: undefined,
    auth: () => addonsPartnerForm,
};
