import * as React from 'react';

import { PageLoader } from '@Component/PageLoader';

import type { ReportSystemAdminProps } from './component';

export const ReportSystemAdmin: React.FC<ReportSystemAdminProps> = (props) => (
    <PageLoader<ReportSystemAdminProps>
        loader={async () =>
            import('./ReportSystemAdmin.module').then((m) => m.page)
        }
        adjustWrapperDiv
        props={props}
    />
);
