import { BareFetcher, useSWRConfig } from 'swr';
import { defaultFetcher } from './defaultFetcher';

/**
 * @deprecated Use <SWRConfig> directly alongside defaultFetcher instead
 * @see https://storybook.tripletex.dev/?path=/story/data-fetching-guide--page
 * @see https://swr.vercel.app/docs/global-configuration
 */
export function useFetcher<T = unknown>() {
    const contextFetcher = useSWRConfig().fetcher as BareFetcher<T>;

    return contextFetcher ?? defaultFetcher;
}
