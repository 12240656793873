// This file is autogenerated by cpcm.js. Do not make any manual changes!
export function postnummer(E) {
    if (E >= 1 && E <= 1295) {
        return 'OSLO';
    }
    if ((E >= 1300 && E <= 1304) || (E >= 1336 && E <= 1338)) {
        return 'SANDVIKA';
    }
    if (E >= 1324 && E <= 1327) {
        return 'LYSAKER';
    }
    if ((E >= 1371 && E <= 1373) || (E >= 1383 && E <= 1387)) {
        return 'ASKER';
    }
    if (E >= 1375 && E <= 1377) {
        return 'BILLINGSTAD';
    }
    if (E >= 1430 && E <= 1435) {
        return 'ÅS';
    }
    if (E >= 1440 && E <= 1449) {
        return 'DRØBAK';
    }
    if (E >= 1450 && E <= 1452) {
        return 'NESODDTANGEN';
    }
    if (E >= 1462 && E <= 1464) {
        return 'FJELLHAMAR';
    }
    if (E >= 1465 && E <= 1467) {
        return 'STRØMMEN';
    }
    if ((E >= 1501 && E <= 1539) || (E >= 1596 && E <= 1599)) {
        return 'MOSS';
    }
    if (E >= 1540 && E <= 1544) {
        return 'VESTBY';
    }
    if (E >= 1580 && E <= 1590) {
        return 'RYGGE';
    }
    if (E >= 1601 && E <= 1619) {
        return 'FREDRIKSTAD';
    }
    if (E >= 1620 && E <= 1624) {
        return 'GRESSVIK';
    }
    if (E >= 1629 && E <= 1639) {
        return 'GAMLE FREDRIKSTAD';
    }
    if (E >= 1650 && E <= 1655) {
        return 'SELLEBAKK';
    }
    if (E >= 1657 && E <= 1659) {
        return 'TORP';
    }
    if (E >= 1661 && E <= 1667) {
        return 'ROLVSØY';
    }
    if (E >= 1670 && E <= 1679) {
        return 'KRÅKERØY';
    }
    if ((E >= 1701 && E <= 1711) || (E >= 1721 && E <= 1727)) {
        return 'SARPSBORG';
    }
    if (E >= 1712 && E <= 1714) {
        return 'GRÅLUM';
    }
    if (E >= 1718 && E <= 1720) {
        return 'GREÅKER';
    }
    if (E >= 1738 && E <= 1740) {
        return 'BORGENHAUGEN';
    }
    if (E >= 1745 && E <= 1747) {
        return 'SKJEBERG';
    }
    if (E >= 1751 && E <= 1788) {
        return 'HALDEN';
    }
    if (E >= 1790 && E <= 1793) {
        return 'TISTEDAL';
    }
    if (
        (E >= 1801 && E <= 1803) ||
        (E >= 1807 && E <= 1815) ||
        (E >= 1830 && E <= 1833)
    ) {
        return 'ASKIM';
    }
    if (E >= 1850 && E <= 1852) {
        return 'MYSEN';
    }
    if (E >= 2000 && E <= 2004) {
        return 'LILLESTRØM';
    }
    if (E >= 2019 && E <= 2021) {
        return 'SKEDSMOKORSET';
    }
    if ((E >= 2050 && E <= 2053) || (E >= 2062 && E <= 2069)) {
        return 'JESSHEIM';
    }
    if ((E >= 2201 && E <= 2209) || (E >= 2211 && E <= 2214)) {
        return 'KONGSVINGER';
    }
    if ((E >= 2301 && E <= 2311) || (E >= 2315 && E <= 2319)) {
        return 'HAMAR';
    }
    if (E >= 2312 && E <= 2314) {
        return 'OTTESTAD';
    }
    if (E >= 2350 && E <= 2351) {
        return 'NES PÅ HEDMARKEN';
    }
    if (E >= 2380 && E <= 2389) {
        return 'BRUMUNDDAL';
    }
    if ((E >= 2401 && E <= 2409) || (E >= 2417 && E <= 2419)) {
        return 'ELVERUM';
    }
    if (E >= 2484 && E <= 2488) {
        return 'RENDALEN';
    }
    if (
        (E >= 2601 && E <= 2606) ||
        (E >= 2613 && E <= 2615) ||
        (E >= 2617 && E <= 2619) ||
        (E >= 2622 && E <= 2624)
    ) {
        return 'LILLEHAMMER';
    }
    if (
        (E >= 2801 && E <= 2806) ||
        (E >= 2808 && E <= 2810) ||
        (E >= 2812 && E <= 2819)
    ) {
        return 'GJØVIK';
    }
    if (E >= 2833 && E <= 2835) {
        return 'RAUFOSS';
    }
    if ((E >= 3001 && E <= 3048) || (E >= 3063 && E <= 3066)) {
        return 'DRAMMEN';
    }
    if ((E >= 3070 && E <= 3074) || (E >= 3076 && E <= 3077)) {
        return 'SANDE I VESTFOLD';
    }
    if (E >= 3080 && E <= 3089) {
        return 'HOLMESTRAND';
    }
    if (
        (E >= 3101 && E <= 3105) ||
        (E >= 3109 && E <= 3119) ||
        (E >= 3122 && E <= 3127)
    ) {
        return 'TØNSBERG';
    }
    if (E >= 3150 && E <= 3154) {
        return 'TOLVSRØD';
    }
    if ((E >= 3181 && E <= 3183) || (E >= 3186 && E <= 3194)) {
        return 'HORTEN';
    }
    if ((E >= 3201 && E <= 3242) || (E >= 3246 && E <= 3249)) {
        return 'SANDEFJORD';
    }
    if (E >= 3251 && E <= 3274) {
        return 'LARVIK';
    }
    if (E >= 3290 && E <= 3294) {
        return 'STAVERN';
    }
    if (E >= 3300 && E <= 3303) {
        return 'HOKKSUND';
    }
    if (E >= 3340 && E <= 3342) {
        return 'ÅMOT';
    }
    if (E >= 3401 && E <= 3405) {
        return 'LIER';
    }
    if (E >= 3406 && E <= 3409) {
        return 'TRANBY';
    }
    if (E >= 3426 && E <= 3428) {
        return 'GULLAUG';
    }
    if (E >= 3501 && E <= 3519) {
        return 'HØNEFOSS';
    }
    if ((E >= 3601 && E <= 3607) || (E >= 3609 && E <= 3617)) {
        return 'KONGSBERG';
    }
    if (E >= 3671 && E <= 3684) {
        return 'NOTODDEN';
    }
    if ((E >= 3701 && E <= 3747) || (E >= 3796 && E <= 3799)) {
        return 'SKIEN';
    }
    if (E >= 3800 && E <= 3805) {
        return 'BØ I TELEMARK';
    }
    if ((E >= 3901 && E <= 3946) || (E >= 3996 && E <= 3998)) {
        return 'PORSGRUNN';
    }
    if (E >= 3960 && E <= 3962) {
        return 'STATHELLE';
    }
    if (
        (E >= 4001 && E <= 4036) ||
        (E >= 4078 && E <= 4082) ||
        (E >= 4092 && E <= 4095)
    ) {
        return 'STAVANGER';
    }
    if ((E >= 4041 && E <= 4049) || (E >= 4089 && E <= 4091)) {
        return 'HAFRSFJORD';
    }
    if (E >= 4056 && E <= 4058) {
        return 'TANANGER';
    }
    if (E >= 4070 && E <= 4073) {
        return 'RANDABERG';
    }
    if (E >= 4103 && E <= 4105) {
        return 'JØRPELAND';
    }
    if (
        (E >= 4301 && E <= 4309) ||
        (E >= 4312 && E <= 4329) ||
        (E >= 4336 && E <= 4338) ||
        (E >= 4391 && E <= 4394) ||
        (E >= 4396 && E <= 4399)
    ) {
        return 'SANDNES';
    }
    if (E >= 4344 && E <= 4346) {
        return 'BRYNE';
    }
    if (E >= 4350 && E <= 4352) {
        return 'KLEPPE';
    }
    if (E >= 4370 && E <= 4374) {
        return 'EGERSUND';
    }
    if (E >= 4400 && E <= 4407) {
        return 'FLEKKEFJORD';
    }
    if (E >= 4490 && E <= 4492) {
        return 'KVINESDAL';
    }
    if (E >= 4501 && E <= 4517) {
        return 'MANDAL';
    }
    if (E >= 4520 && E <= 4524) {
        return 'LINDESNES';
    }
    if (E >= 4550 && E <= 4554) {
        return 'FARSUND';
    }
    if (E >= 4557 && E <= 4560) {
        return 'VANSE';
    }
    if (E >= 4575 && E <= 4580) {
        return 'LYNGDAL';
    }
    if (
        (E >= 4604 && E <= 4608) ||
        (E >= 4610 && E <= 4618) ||
        (E >= 4620 && E <= 4624) ||
        (E >= 4626 && E <= 4639) ||
        (E >= 4661 && E <= 4678) ||
        (E >= 4686 && E <= 4698)
    ) {
        return 'KRISTIANSAND S';
    }
    if ((E >= 4640 && E <= 4644) || (E >= 4681 && E <= 4683)) {
        return 'SØGNE';
    }
    if ((E >= 4700 && E <= 4703) || (E >= 4706 && E <= 4708)) {
        return 'VENNESLA';
    }
    if (E >= 4733 && E <= 4735) {
        return 'EVJE';
    }
    if (E >= 4755 && E <= 4756) {
        return 'HOVDEN I SETESDAL';
    }
    if (E >= 4790 && E <= 4792) {
        return 'LILLESAND';
    }
    if (
        (E >= 4801 && E <= 4809) ||
        (E >= 4836 && E <= 4849) ||
        (E >= 4856 && E <= 4859)
    ) {
        return 'ARENDAL';
    }
    if ((E >= 4876 && E <= 4887) || (E >= 4891 && E <= 4898)) {
        return 'GRIMSTAD';
    }
    if (E >= 4900 && E <= 4905) {
        return 'TVEDESTRAND';
    }
    if (E >= 4950 && E <= 4957) {
        return 'RISØR';
    }
    if ((E >= 5003 && E <= 5099) || (E >= 5802 && E <= 5899)) {
        return 'BERGEN';
    }
    if (E >= 5115 && E <= 5121) {
        return 'ULSET';
    }
    if (E >= 5130 && E <= 5132) {
        return 'NYBORG';
    }
    if (E >= 5141 && E <= 5148) {
        return 'FYLLINGSDALEN';
    }
    if (E >= 5152 && E <= 5155) {
        return 'BØNES';
    }
    if (E >= 5160 && E <= 5165) {
        return 'LAKSEVÅG';
    }
    if (E >= 5171 && E <= 5173) {
        return 'LODDEFJORD';
    }
    if ((E >= 5200 && E <= 5206) || (E >= 5208 && E <= 5211)) {
        return 'OS';
    }
    if (E >= 5221 && E <= 5228) {
        return 'NESTTUN';
    }
    if (E >= 5230 && E <= 5232) {
        return 'PARADIS';
    }
    if (E >= 5235 && E <= 5239) {
        return 'RÅDAL';
    }
    if (E >= 5319 && E <= 5323) {
        return 'KLEPPESTØ';
    }
    if (E >= 5341 && E <= 5343) {
        return 'STRAUME';
    }
    if ((E >= 5401 && E <= 5407) || (E >= 5414 && E <= 5417)) {
        return 'STORD';
    }
    if ((E >= 5501 && E <= 5507) || (E >= 5509 && E <= 5538)) {
        return 'HAUGESUND';
    }
    if (E >= 5555 && E <= 5556) {
        return 'FØRDE I HORDALAND';
    }
    if (E >= 5600 && E <= 5602) {
        return 'NORHEIMSUND';
    }
    if (E >= 5700 && E <= 5706) {
        return 'VOSS';
    }
    if (E >= 5750 && E <= 5752) {
        return 'ODDA';
    }
    if (E >= 5902 && E <= 5904) {
        return 'ISDALSTØ';
    }
    if ((E >= 6001 && E <= 6028) || (E >= 6044 && E <= 6048)) {
        return 'ÅLESUND';
    }
    if (E >= 6090 && E <= 6092) {
        return 'FOSNAVÅG';
    }
    if (E >= 6100 && E <= 6106) {
        return 'VOLDA';
    }
    if (E >= 6150 && E <= 6156) {
        return 'ØRSTA';
    }
    if (
        (E >= 6401 && E <= 6407) ||
        (E >= 6409 && E <= 6416) ||
        (E >= 6419 && E <= 6429) ||
        (E >= 6434 && E <= 6436)
    ) {
        return 'MOLDE';
    }
    if (E >= 6460 && E <= 6461) {
        return 'EIDSVÅG I ROMSDAL';
    }
    if ((E >= 6501 && E <= 6518) || (E >= 6546 && E <= 6549)) {
        return 'KRISTIANSUND N';
    }
    if (E >= 6520 && E <= 6529) {
        return 'FREI';
    }
    if (E >= 6530 && E <= 6539) {
        return 'AVERØY';
    }
    if (E >= 6700 && E <= 6703) {
        return 'MÅLØY';
    }
    if (E >= 6770 && E <= 6774) {
        return 'NORDFJORDEID';
    }
    if (E >= 6781 && E <= 6783) {
        return 'STRYN';
    }
    if ((E >= 6800 && E <= 6805) || (E >= 6807 && E <= 6815)) {
        return 'FØRDE';
    }
    if (E >= 6821 && E <= 6823) {
        return 'SANDANE';
    }
    if (E >= 6851 && E <= 6853) {
        return 'SOGNDAL';
    }
    if (E >= 6900 && E <= 6910) {
        return 'FLORØ';
    }
    if (E >= 6961 && E <= 6963) {
        return 'DALE I SUNNFJORD';
    }
    if (E >= 6971 && E <= 6973) {
        return 'SANDE I SUNNFJORD';
    }
    if (
        (E >= 7003 && E <= 7052) ||
        (E >= 7066 && E <= 7069) ||
        (E >= 7400 && E <= 7498)
    ) {
        return 'TRONDHEIM';
    }
    if (E >= 7053 && E <= 7056) {
        return 'RANHEIM';
    }
    if (E >= 7091 && E <= 7093) {
        return 'TILLER';
    }
    if (E >= 7221 && E <= 7224) {
        return 'MELHUS';
    }
    if (E >= 7300 && E <= 7303) {
        return 'ORKANGER';
    }
    if (E >= 7386 && E <= 7388) {
        return 'SINGSÅS';
    }
    if (E >= 7391 && E <= 7399) {
        return 'RENNEBU';
    }
    if ((E >= 7500 && E <= 7509) || (E >= 7512 && E <= 7514)) {
        return 'STJØRDAL';
    }
    if (E >= 7580 && E <= 7583) {
        return 'SELBU';
    }
    if (E >= 7600 && E <= 7610) {
        return 'LEVANGER';
    }
    if (E >= 7650 && E <= 7658) {
        return 'VERDAL';
    }
    if (E >= 7670 && E <= 7672) {
        return 'INDERØY';
    }
    if (
        (E >= 7701 && E <= 7709) ||
        (E >= 7711 && E <= 7729) ||
        (E >= 7734 && E <= 7738)
    ) {
        return 'STEINKJER';
    }
    if (E >= 7800 && E <= 7810) {
        return 'NAMSOS';
    }
    if (E >= 7900 && E <= 7902) {
        return 'RØRVIK';
    }
    if ((E >= 8001 && E <= 8049) || (E >= 8070 && E <= 8092)) {
        return 'BODØ';
    }
    if (E >= 8200 && E <= 8214) {
        return 'FAUSKE';
    }
    if (E >= 8250 && E <= 8253) {
        return 'ROGNAN';
    }
    if (E >= 8300 && E <= 8305) {
        return 'SVOLVÆR';
    }
    if ((E >= 8400 && E <= 8408) || (E >= 8415 && E <= 8419)) {
        return 'SORTLAND';
    }
    if ((E >= 8501 && E <= 8512) || (E >= 8514 && E <= 8519)) {
        return 'NARVIK';
    }
    if ((E >= 8601 && E <= 8614) || (E >= 8618 && E <= 8626)) {
        return 'MO I RANA';
    }
    if (E >= 8651 && E <= 8666) {
        return 'MOSJØEN';
    }
    if (E >= 8800 && E <= 8809) {
        return 'SANDNESSJØEN';
    }
    if (E >= 8850 && E <= 8852) {
        return 'HERØY';
    }
    if (E >= 8900 && E <= 8910) {
        return 'BRØNNØYSUND';
    }
    if (E >= 8920 && E <= 8922) {
        return 'SØMNA';
    }
    if ((E >= 9006 && E <= 9019) || (E >= 9240 && E <= 9299)) {
        return 'TROMSØ';
    }
    if (E >= 9100 && E <= 9102) {
        return 'KVALØYSLETTA';
    }
    if (E >= 9103 && E <= 9105) {
        return 'KVALØYA';
    }
    if (E >= 9156 && E <= 9158) {
        return 'STORSLETT';
    }
    if (E >= 9305 && E <= 9309) {
        return 'FINNSNES';
    }
    if ((E >= 9402 && E <= 9416) || (E >= 9479 && E <= 9498)) {
        return 'HARSTAD';
    }
    if (E >= 9501 && E <= 9518) {
        return 'ALTA';
    }
    if (E >= 9600 && E <= 9603) {
        return 'HAMMERFEST';
    }
    if (E >= 9802 && E <= 9810) {
        return 'VESTRE JAKOBSELV';
    }
    return {
        1305: 'HASLUM',
        1306: 'SANDVIKA',
        1307: 'FORNEBU',
        1308: 'JAR',
        1309: 'RUD',
        1311: 'HØVIKODDEN',
        1312: 'SLEPENDEN',
        1313: 'VØYENENGA',
        1314: 'VØYENENGA',
        1316: 'EIKSMARKA',
        1317: 'BÆRUMS VERK',
        1318: 'BEKKESTUA',
        1319: 'BEKKESTUA',
        1321: 'STABEKK',
        1322: 'HØVIK',
        1323: 'HØVIK',
        1328: 'HØVIK',
        1329: 'LOMMEDALEN',
        1330: 'FORNEBU',
        1331: 'FORNEBU',
        1332: 'ØSTERÅS',
        1333: 'KOLSÅS',
        1334: 'RYKKINN',
        1335: 'SNARØYA',
        1339: 'VØYENENGA',
        1340: 'SKUI',
        1341: 'SLEPENDEN',
        1342: 'GJETTUM',
        1344: 'HASLUM',
        1346: 'GJETTUM',
        1348: 'RYKKINN',
        1349: 'RYKKINN',
        1350: 'LOMMEDALEN',
        1351: 'RUD',
        1352: 'KOLSÅS',
        1353: 'BÆRUMS VERK',
        1354: 'BÆRUMS VERK',
        1356: 'BEKKESTUA',
        1357: 'BEKKESTUA',
        1358: 'JAR',
        1359: 'EIKSMARKA',
        1360: 'FORNEBU',
        1361: 'ØSTERÅS',
        1362: 'HOSLE',
        1363: 'HØVIK',
        1364: 'FORNEBU',
        1365: 'BLOMMENHOLM',
        1366: 'LYSAKER',
        1367: 'SNARØYA',
        1368: 'STABEKK',
        1369: 'STABEKK',
        1378: 'NESBRU',
        1379: 'NESBRU',
        1380: 'HEGGEDAL',
        1381: 'VETTRE',
        1388: 'BORGEN',
        1389: 'HEGGEDAL',
        1390: 'VOLLEN',
        1391: 'VOLLEN',
        1392: 'VETTRE',
        1393: 'VOLLEN',
        1394: 'NESBRU',
        1395: 'HVALSTAD',
        1396: 'BILLINGSTAD',
        1397: 'NESØYA',
        1399: 'ASKER',
        1400: 'SKI',
        1401: 'SKI',
        1402: 'SKI',
        1403: 'LANGHUS',
        1404: 'SIGGERUD',
        1405: 'LANGHUS',
        1406: 'SKI',
        1407: 'VINTERBRO',
        1408: 'KRÅKSTAD',
        1409: 'SKOTBU',
        1410: 'KOLBOTN',
        1411: 'KOLBOTN',
        1412: 'SOFIEMYR',
        1413: 'TÅRNÅSEN',
        1414: 'TROLLÅSEN',
        1415: 'OPPEGÅRD',
        1416: 'OPPEGÅRD',
        1417: 'SOFIEMYR',
        1418: 'KOLBOTN',
        1419: 'OPPEGÅRD',
        1420: 'SVARTSKOG',
        1421: 'TROLLÅSEN',
        1422: 'SIGGERUD',
        1423: 'SKI',
        1424: 'SKI',
        1425: 'SKI',
        1429: 'VINTERBRO',
        1453: 'BJØRNEMYR',
        1454: 'FAGERSTRAND',
        1455: 'NORDRE FROGN',
        1456: 'NESODDTANGEN',
        1457: 'FAGERSTRAND',
        1458: 'FJELLSTRAND',
        1459: 'NESODDEN',
        1461: 'LØRENSKOG',
        1468: 'FINSTADJORDET',
        1469: 'RASTA',
        1470: 'LØRENSKOG',
        1471: 'LØRENSKOG',
        1472: 'FJELLHAMAR',
        1473: 'LØRENSKOG',
        1474: 'LØRENSKOG',
        1475: 'FINSTADJORDET',
        1476: 'RASTA',
        1477: 'FJELLHAMAR',
        1478: 'LØRENSKOG',
        1479: 'KURLAND',
        1480: 'SLATTUM',
        1481: 'HAGAN',
        1482: 'NITTEDAL',
        1483: 'HAGAN',
        1484: 'HAKADAL',
        1485: 'HAKADAL',
        1486: 'NITTEDAL',
        1487: 'HAKADAL',
        1488: 'HAKADAL',
        1545: 'HVITSTEN',
        1550: 'HØLEN',
        1555: 'SON',
        1556: 'SON',
        1560: 'LARKOLLEN',
        1561: 'LARKOLLEN',
        1570: 'DILLING',
        1591: 'SPERREBOTN',
        1592: 'VÅLER I ØSTFOLD',
        1593: 'SVINNDAL',
        1594: 'VÅLER I ØSTFOLD',
        1625: 'MANSTAD',
        1626: 'MANSTAD',
        1628: 'ENGELSVIKEN',
        1640: 'RÅDE',
        1641: 'RÅDE',
        1642: 'SALTNES',
        1643: 'RÅDE',
        1680: 'SKJÆRHALDEN',
        1682: 'SKJÆRHALDEN',
        1683: 'VESTERØY',
        1684: 'VESTERØY',
        1690: 'HERFØL',
        1692: 'NEDGÅRDEN',
        1715: 'YVEN',
        1730: 'ISE',
        1733: 'HAFSLUNDSØY',
        1734: 'HAFSLUNDSØY',
        1735: 'VARTEIG',
        1742: 'KLAVESTADHAUGEN',
        1743: 'KLAVESTADHAUGEN',
        1789: 'BERG I ØSTFOLD',
        1794: 'SPONVIKA',
        1796: 'KORNSJØ',
        1798: 'AREMARK',
        1799: 'AREMARK',
        1804: 'SPYDEBERG',
        1805: 'TOMTER',
        1806: 'SKIPTVET',
        1816: 'SKIPTVET',
        1820: 'SPYDEBERG',
        1821: 'SPYDEBERG',
        1823: 'KNAPSTAD',
        1825: 'TOMTER',
        1827: 'HOBØL',
        1828: 'HOBØL',
        1859: 'SLITU',
        1860: 'TRØGSTAD',
        1861: 'TRØGSTAD',
        1866: 'BÅSTAD',
        1867: 'BÅSTAD',
        1870: 'ØRJE',
        1871: 'ØRJE',
        1875: 'OTTEID',
        1878: 'HÆRLAND',
        1880: 'EIDSBERG',
        1890: 'RAKKESTAD',
        1891: 'RAKKESTAD',
        1892: 'DEGERNES',
        1893: 'DEGERNES',
        1894: 'RAKKESTAD',
        1900: 'FETSUND',
        1901: 'FETSUND',
        1903: 'GAN',
        1910: 'ENEBAKKNESET',
        1911: 'FLATEBY',
        1912: 'ENEBAKK',
        1914: 'YTRE ENEBAKK',
        1916: 'FLATEBY',
        1917: 'YTRE ENEBAKK',
        1920: 'SØRUMSAND',
        1921: 'SØRUMSAND',
        1923: 'SØRUM',
        1924: 'SØRUM',
        1925: 'BLAKER',
        1926: 'BLAKER',
        1927: 'RÅNÅSFOSS',
        1928: 'AULI',
        1929: 'AULI',
        1930: 'AURSKOG',
        1931: 'AURSKOG',
        1940: 'BJØRKELANGEN',
        1941: 'BJØRKELANGEN',
        1950: 'RØMSKOG',
        1954: 'SETSKOG',
        1960: 'LØKEN',
        1961: 'LØKEN',
        1963: 'FOSSER',
        1970: 'HEMNES',
        1971: 'HEMNES',
        2005: 'RÆLINGEN',
        2006: 'LØVENSTAD',
        2007: 'KJELLER',
        2008: 'FJERDINGBY',
        2009: 'NORDBY',
        2010: 'STRØMMEN',
        2011: 'STRØMMEN',
        2012: 'LILLESTRØM',
        2013: 'SKJETTEN',
        2014: 'BLYSTADLIA',
        2015: 'LEIRSUND',
        2016: 'FROGNER',
        2017: 'FROGNER',
        2018: 'LØVENSTAD',
        2022: 'GJERDRUM',
        2023: 'SKEDSMOKORSET',
        2024: 'GJERDRUM',
        2025: 'FJERDINGBY',
        2026: 'SKJETTEN',
        2027: 'KJELLER',
        2028: 'LILLESTRØM',
        2029: 'RÆLINGEN',
        2030: 'NANNESTAD',
        2031: 'NANNESTAD',
        2032: 'MAURA',
        2033: 'ÅSGREINA',
        2034: 'HOLTER',
        2035: 'HOLTER',
        2036: 'MAURA',
        2040: 'KLØFTA',
        2041: 'KLØFTA',
        2054: 'MOGREINA',
        2055: 'NORDKISA',
        2056: 'ALGARHEIM',
        2057: 'JESSHEIM',
        2058: 'SESSVOLLMOEN',
        2060: 'GARDERMOEN',
        2061: 'GARDERMOEN',
        2070: 'RÅHOLT',
        2071: 'RÅHOLT',
        2072: 'DAL',
        2073: 'BØN',
        2074: 'EIDSVOLL VERK',
        2076: 'DAL',
        2080: 'EIDSVOLL',
        2081: 'EIDSVOLL',
        2090: 'HURDAL',
        2091: 'HURDAL',
        2092: 'MINNESUND',
        2093: 'FEIRING',
        2094: 'MINNESUND',
        2100: 'SKARNES',
        2101: 'SKARNES',
        2110: 'SLÅSTAD',
        2114: 'DISENÅ',
        2116: 'SANDER',
        2120: 'SAGSTUA',
        2121: 'SAGSTUA',
        2123: 'BRUVOLL',
        2130: 'KNAPPER',
        2132: 'GARDVIK',
        2133: 'GARDVIK',
        2134: 'AUSTVATN',
        2150: 'ÅRNES',
        2151: 'ÅRNES',
        2160: 'VORMSUND',
        2161: 'VORMSUND',
        2162: 'BRÅRUD',
        2163: 'SKOGBYGDA',
        2164: 'SKOGBYGDA',
        2165: 'HVAM',
        2166: 'OPPAKER',
        2167: 'HVAM',
        2170: 'FENSTAD',
        2210: 'GRANLI',
        2215: 'ROVERUD',
        2216: 'ROVERUD',
        2217: 'HOKKÅSEN',
        2218: 'LUNDERSÆTER',
        2219: 'BRANDVAL',
        2220: 'ÅBOGEN',
        2223: 'GALTERUD',
        2224: 'AUSTMARKA',
        2225: 'KONGSVINGER',
        2226: 'KONGSVINGER',
        2227: 'AUSTMARKA',
        2230: 'SKOTTERUD',
        2231: 'SKOTTERUD',
        2232: 'TOBØL',
        2233: 'VESTMARKA',
        2235: 'MATRAND',
        2240: 'MAGNOR',
        2241: 'MAGNOR',
        2251: 'GRUE FINNSKOG',
        2256: 'GRUE FINNSKOG',
        2260: 'KIRKENÆR',
        2261: 'KIRKENÆR',
        2264: 'GRINDER',
        2265: 'NAMNÅ',
        2266: 'ARNEBERG',
        2270: 'FLISA',
        2271: 'FLISA',
        2280: 'GJESÅSEN',
        2283: 'ÅSNES FINNSKOG',
        2320: 'FURNES',
        2321: 'HAMAR',
        2322: 'RIDABU',
        2323: 'INGEBERG',
        2324: 'VANG PÅ HEDMARKEN',
        2325: 'HAMAR',
        2326: 'HAMAR',
        2327: 'FURNES',
        2328: 'RIDABU',
        2329: 'VANG PÅ HEDMARKEN',
        2330: 'VALLSET',
        2331: 'VALLSET',
        2332: 'ÅSVANG',
        2333: 'ROMEDAL',
        2334: 'ROMEDAL',
        2335: 'STANGE',
        2336: 'STANGE',
        2337: 'TANGEN',
        2338: 'ESPA',
        2339: 'TANGEN',
        2340: 'LØTEN',
        2341: 'LØTEN',
        2344: 'ILSENG',
        2345: 'ÅDALSBRUK',
        2346: 'ILSENG',
        2353: 'STAVSJØ',
        2355: 'GAUPEN',
        2360: 'RUDSHØGDA',
        2361: 'RUDSHØGDA',
        2364: 'NÆROSET',
        2365: 'ÅSMARKA',
        2372: 'BRØTTUM',
        2373: 'BRØTTUM',
        2390: 'MOELV',
        2391: 'MOELV',
        2410: 'HERNES',
        2411: 'ELVERUM',
        2412: 'SØRSKOGBYGDA',
        2413: 'ELVERUM',
        2414: 'ELVERUM',
        2415: 'HERADSBYGD',
        2416: 'JØMNA',
        2420: 'TRYSIL',
        2421: 'TRYSIL',
        2422: 'NYBERGSUND',
        2423: 'ØSTBY',
        2424: 'ØSTBY',
        2425: 'LJØRDALEN',
        2426: 'LJØRDALEN',
        2427: 'PLASSEN',
        2428: 'SØRE OSEN',
        2429: 'TØRBERGET',
        2430: 'JORDET',
        2432: 'SLETTÅS',
        2434: 'BRASKEREIDFOSS',
        2435: 'BRASKEREIDFOSS',
        2436: 'VÅLER I SOLØR',
        2437: 'HASLEMOEN',
        2438: 'GRAVBERGET',
        2439: 'VÅLER I SOLØR',
        2440: 'ENGERDAL',
        2441: 'ENGERDAL',
        2442: 'HERADSBYGD',
        2443: 'DREVSJØ',
        2444: 'DREVSJØ',
        2446: 'ELGÅ',
        2447: 'SØRE OSEN',
        2448: 'SØMÅDALEN',
        2450: 'RENA',
        2451: 'RENA',
        2460: 'OSEN',
        2461: 'OSEN',
        2476: 'ATNA',
        2477: 'SOLLIA',
        2478: 'HANESTAD',
        2480: 'KOPPANG',
        2481: 'KOPPANG',
        2500: 'TYNSET',
        2501: 'TYNSET',
        2510: 'TYLLDALEN',
        2512: 'KVIKNE',
        2513: 'KVIKNE',
        2540: 'TOLGA',
        2541: 'TOLGA',
        2542: 'VINGELEN',
        2544: 'ØVERSJØDALEN',
        2550: 'OS I ØSTERDALEN',
        2551: 'OS I ØSTERDALEN',
        2552: 'DALSBYGDA',
        2555: 'TUFSINGDALEN',
        2560: 'ALVDAL',
        2561: 'ALVDAL',
        2580: 'FOLLDAL',
        2581: 'FOLLDAL',
        2582: 'GRIMSBU',
        2584: 'DALHOLEN',
        2607: 'VINGROM',
        2608: 'LILLEHAMMER',
        2609: 'LILLEHAMMER',
        2610: 'MESNALI',
        2611: 'LILLEHAMMER',
        2612: 'SJUSJØEN',
        2616: 'LISMARKA',
        2620: 'MESNALI',
        2621: 'VINGROM',
        2625: 'FÅBERG',
        2626: 'LILLEHAMMER',
        2627: 'FÅBERG',
        2628: 'SJUSJØEN',
        2629: 'LILLEHAMMER',
        2630: 'RINGEBU',
        2631: 'RINGEBU',
        2632: 'VENABYGD',
        2633: 'FÅVANG',
        2634: 'FÅVANG',
        2635: 'TRETTEN',
        2636: 'ØYER',
        2637: 'ØYER',
        2638: 'TRETTEN',
        2639: 'VINSTRA',
        2640: 'VINSTRA',
        2641: 'KVAM',
        2642: 'KVAM',
        2643: 'SKÅBU',
        2644: 'SKÅBU',
        2645: 'SØR-FRON',
        2646: 'GÅLÅ',
        2647: 'SØR-FRON',
        2648: 'SØR-FRON',
        2649: 'ØSTRE GAUSDAL',
        2651: 'ØSTRE GAUSDAL',
        2652: 'SVINGVOLL',
        2653: 'VESTRE GAUSDAL',
        2654: 'VESTRE GAUSDAL',
        2656: 'FOLLEBU',
        2657: 'SVATSUM',
        2658: 'ESPEDALEN',
        2659: 'DOMBÅS',
        2660: 'DOMBÅS',
        2661: 'HJERKINN',
        2662: 'DOVRE',
        2663: 'DOVRESKOGEN',
        2664: 'DOVRE',
        2665: 'LESJA',
        2666: 'LORA',
        2667: 'LESJAVERK',
        2668: 'LESJASKOG',
        2669: 'BJORLI',
        2670: 'OTTA',
        2671: 'LESJA',
        2672: 'SEL',
        2673: 'HØVRINGEN',
        2674: 'MYSUSÆTER',
        2675: 'OTTA',
        2676: 'HEIDAL',
        2677: 'NEDRE HEIDAL',
        2678: 'SEL',
        2679: 'HEIDAL',
        2680: 'VÅGÅ',
        2681: 'LALM',
        2682: 'LALM',
        2683: 'TESSANDEN',
        2684: 'VÅGÅ',
        2685: 'GARMO',
        2686: 'LOM',
        2687: 'BØVERDALEN',
        2688: 'LOM',
        2690: 'SKJÅK',
        2693: 'NORDBERG',
        2694: 'SKJÅK',
        2695: 'GROTLI',
        2711: 'GRAN',
        2712: 'BRANDBU',
        2713: 'ROA',
        2714: 'JAREN',
        2715: 'LUNNER',
        2716: 'HARESTUA',
        2717: 'GRUA',
        2718: 'BRANDBU',
        2720: 'GRINDVOLL',
        2730: 'LUNNER',
        2740: 'ROA',
        2742: 'GRUA',
        2743: 'HARESTUA',
        2750: 'GRAN',
        2760: 'BRANDBU',
        2770: 'JAREN',
        2807: 'HUNNDALEN',
        2811: 'HUNNDALEN',
        2820: 'NORDRE TOTEN',
        2821: 'GJØVIK',
        2822: 'BYBRUA',
        2825: 'GJØVIK',
        2827: 'HUNNDALEN',
        2830: 'RAUFOSS',
        2831: 'RAUFOSS',
        2832: 'BIRI',
        2836: 'BIRI',
        2837: 'BIRISTRAND',
        2838: 'SNERTINGDAL',
        2839: 'ØVRE SNERTINGDAL',
        2840: 'REINSVOLL',
        2841: 'SNERTINGDAL',
        2843: 'EINA',
        2844: 'KOLBU',
        2845: 'BØVERBRU',
        2846: 'BØVERBRU',
        2847: 'KOLBU',
        2848: 'SKREIA',
        2849: 'KAPP',
        2850: 'LENA',
        2851: 'LENA',
        2853: 'REINSVOLL',
        2854: 'EINA',
        2857: 'SKREIA',
        2858: 'KAPP',
        2860: 'HOV',
        2861: 'LANDÅSBYGDA',
        2862: 'FLUBERG',
        2863: 'VESTSIDA',
        2864: 'FALL',
        2866: 'ENGER',
        2867: 'HOV',
        2870: 'DOKKA',
        2879: 'ODNES',
        2880: 'NORD-TORPA',
        2881: 'AUST-TORPA',
        2882: 'DOKKA',
        2890: 'ETNEDAL',
        2893: 'ETNEDAL',
        2900: 'FAGERNES',
        2901: 'FAGERNES',
        2907: 'LEIRA I VALDRES',
        2909: 'AURDAL',
        2910: 'AURDAL',
        2917: 'SKRAUTVÅL',
        2918: 'ULNES',
        2920: 'LEIRA I VALDRES',
        2923: 'TISLEIDALEN',
        2929: 'BAGN',
        2930: 'BAGN',
        2933: 'REINLI',
        2936: 'BEGNADALEN',
        2937: 'BEGNA',
        2939: 'HEGGENES',
        2940: 'HEGGENES',
        2943: 'ROGNE',
        2950: 'SKAMMESTEIN',
        2952: 'BEITO',
        2953: 'BEITOSTØLEN',
        2954: 'BEITOSTØLEN',
        2959: 'RØN',
        2960: 'RØN',
        2965: 'SLIDRE',
        2966: 'SLIDRE',
        2967: 'LOMEN',
        2972: 'RYFOSS',
        2973: 'RYFOSS',
        2974: 'VANG I VALDRES',
        2975: 'VANG I VALDRES',
        2977: 'ØYE',
        2985: 'TYINKRYSSET',
        3050: 'MJØNDALEN',
        3051: 'MJØNDALEN',
        3053: 'STEINBERG',
        3054: 'KROKSTADELVA',
        3055: 'KROKSTADELVA',
        3056: 'SOLBERGELVA',
        3057: 'SOLBERGELVA',
        3058: 'SOLBERGMOEN',
        3060: 'SVELVIK',
        3061: 'SVELVIK',
        3075: 'BERGER',
        3090: 'HOF',
        3091: 'HOF',
        3092: 'SUNDBYFOSS',
        3095: 'EIDSFOSS',
        3106: 'NØTTERØY',
        3107: 'SEM',
        3108: 'VEAR',
        3120: 'NØTTERØY',
        3121: 'NØTTERØY',
        3128: 'NØTTERØY',
        3129: 'TØNSBERG',
        3131: 'HUSØYSUND',
        3132: 'HUSØYSUND',
        3133: 'DUKEN',
        3134: 'TØNSBERG',
        3135: 'TORØD',
        3137: 'TORØD',
        3138: 'SKALLESTAD',
        3139: 'SKALLESTAD',
        3140: 'NØTTERØY',
        3141: 'KJØPMANNSKJÆR',
        3142: 'VESTSKOGEN',
        3143: 'KJØPMANNSKJÆR',
        3144: 'VEIERLAND',
        3145: 'TJØME',
        3148: 'HVASSER',
        3156: 'MELSOMVIK',
        3157: 'BARKÅKER',
        3158: 'ANDEBU',
        3159: 'MELSOMVIK',
        3160: 'STOKKE',
        3161: 'STOKKE',
        3162: 'ANDEBU',
        3163: 'NØTTERØY',
        3164: 'REVETAL',
        3165: 'TJØME',
        3166: 'TOLVSRØD',
        3167: 'ÅSGÅRDSTRAND',
        3168: 'MELSOMVIK',
        3169: 'STOKKE',
        3170: 'SEM',
        3171: 'SEM',
        3172: 'VEAR',
        3173: 'VEAR',
        3174: 'REVETAL',
        3175: 'RAMNES',
        3176: 'UNDRUMSDAL',
        3177: 'VÅLE',
        3178: 'VÅLE',
        3179: 'ÅSGÅRDSTRAND',
        3180: 'NYKIRKE',
        3184: 'BORRE',
        3185: 'SKOPPUM',
        3195: 'SKOPPUM',
        3196: 'HORTEN',
        3197: 'NYKIRKE',
        3199: 'BORRE',
        3243: 'KODAL',
        3244: 'SANDEFJORD',
        3245: 'KODAL',
        3275: 'SVARSTAD',
        3276: 'SVARSTAD',
        3277: 'STEINSHOLT',
        3280: 'TJODALYNG',
        3281: 'TJODALYNG',
        3282: 'KVELDE',
        3284: 'KVELDE',
        3285: 'LARVIK',
        3295: 'HELGEROA',
        3296: 'NEVLUNGHAVN',
        3297: 'HELGEROA',
        3320: 'VESTFOSSEN',
        3321: 'VESTFOSSEN',
        3322: 'FISKUM',
        3330: 'SKOTSELV',
        3331: 'SKOTSELV',
        3350: 'PRESTFOSS',
        3351: 'PRESTFOSS',
        3355: 'SOLUMSMOEN',
        3357: 'EGGEDAL',
        3358: 'NEDRE EGGEDAL',
        3359: 'EGGEDAL',
        3360: 'GEITHUS',
        3361: 'GEITHUS',
        3370: 'VIKERSUND',
        3371: 'VIKERSUND',
        3410: 'SYLLING',
        3411: 'SYLLING',
        3412: 'LIERSTRANDA',
        3413: 'LIER',
        3414: 'LIERSTRANDA',
        3420: 'LIERSKOGEN',
        3421: 'LIERSKOGEN',
        3425: 'REISTAD',
        3430: 'SPIKKESTAD',
        3431: 'SPIKKESTAD',
        3440: 'RØYKEN',
        3441: 'RØYKEN',
        3442: 'HYGGEN',
        3470: 'SLEMMESTAD',
        3471: 'SLEMMESTAD',
        3472: 'BØDALEN',
        3474: 'ÅROS',
        3475: 'SÆTRE',
        3476: 'SÆTRE',
        3477: 'BÅTSTØ',
        3478: 'NÆRSNES',
        3479: 'NÆRSNES',
        3480: 'FILTVET',
        3481: 'TOFTE',
        3482: 'TOFTE',
        3483: 'KANA',
        3484: 'HOLMSBU',
        3485: 'FILTVET',
        3490: 'KLOKKARSTUA',
        3520: 'JEVNAKER',
        3521: 'JEVNAKER',
        3522: 'BJONEROA',
        3523: 'NES I ÅDAL',
        3524: 'NES I ÅDAL',
        3525: 'HALLINGBY',
        3526: 'HALLINGBY',
        3527: 'BJONEROA',
        3528: 'HEDALEN',
        3529: 'RØYSE',
        3530: 'RØYSE',
        3531: 'KROKKLEIVA',
        3532: 'TYRISTRAND',
        3533: 'TYRISTRAND',
        3534: 'SOKNA',
        3535: 'KRØDEREN',
        3536: 'NORESUND',
        3537: 'KRØDEREN',
        3538: 'SOLLIHØGDA',
        3539: 'FLÅ',
        3540: 'NESBYEN',
        3541: 'NESBYEN',
        3543: 'NORESUND',
        3544: 'TUNHOVD',
        3545: 'FLÅ',
        3550: 'GOL',
        3551: 'GOL',
        3560: 'HEMSEDAL',
        3561: 'HEMSEDAL',
        3570: 'ÅL',
        3571: 'ÅL',
        3575: 'HOL',
        3576: 'HOL',
        3577: 'HOVET',
        3579: 'TORPO',
        3580: 'GEILO',
        3581: 'GEILO',
        3588: 'DAGALI',
        3593: 'USTAOSET',
        3595: 'HAUGASTØL',
        3608: 'HEISTADMOEN',
        3618: 'SKOLLENBORG',
        3619: 'SKOLLENBORG',
        3620: 'FLESBERG',
        3621: 'LAMPELAND',
        3622: 'SVENE',
        3623: 'LAMPELAND',
        3624: 'LYNGDAL I NUMEDAL',
        3625: 'SKOLLENBORG',
        3626: 'ROLLAG',
        3627: 'VEGGLI',
        3628: 'VEGGLI',
        3629: 'NORE',
        3630: 'RØDBERG',
        3631: 'RØDBERG',
        3632: 'UVDAL',
        3634: 'NORE',
        3646: 'HVITTINGFOSS',
        3647: 'HVITTINGFOSS',
        3648: 'PASSEBEKK',
        3650: 'TINN AUSTBYGD',
        3652: 'HOVIN I TELEMARK',
        3656: 'ATRÅ',
        3658: 'MILAND',
        3660: 'RJUKAN',
        3661: 'RJUKAN',
        3665: 'SAULAND',
        3666: 'ATRÅ',
        3690: 'HJARTDAL',
        3691: 'GRANSHERAD',
        3692: 'SAULAND',
        3697: 'TUDDAL',
        3748: 'SILJAN',
        3749: 'SILJAN',
        3750: 'DRANGEDAL',
        3753: 'TØRDAL',
        3760: 'NESLANDSVATN',
        3766: 'SANNIDAL',
        3770: 'KRAGERØ',
        3772: 'KRAGERØ',
        3780: 'SKÅTØY',
        3781: 'JOMFRULAND',
        3783: 'KRAGERØ SKJÆRGÅRD',
        3785: 'SKIEN',
        3787: 'SKIEN',
        3788: 'STABBESTAD',
        3789: 'KRAGERØ',
        3790: 'HELLE',
        3791: 'KRAGERØ',
        3792: 'SKIEN',
        3793: 'SANNIDAL',
        3794: 'HELLE',
        3795: 'DRANGEDAL',
        3810: 'GVARV',
        3811: 'HØRTE',
        3812: 'AKKERHAUGEN',
        3820: 'NORDAGUTU',
        3825: 'LUNDE',
        3830: 'ULEFOSS',
        3831: 'ULEFOSS',
        3832: 'LUNDE',
        3833: 'BØ I TELEMARK',
        3834: 'GVARV',
        3835: 'SELJORD',
        3836: 'KVITESEID',
        3840: 'SELJORD',
        3841: 'FLATDAL',
        3844: 'ÅMOTSDAL',
        3848: 'MORGEDAL',
        3849: 'VRÅLIOSEN',
        3850: 'KVITESEID',
        3852: 'VRÅDAL',
        3853: 'VRÅDAL',
        3854: 'NISSEDAL',
        3855: 'TREUNGEN',
        3864: 'RAULAND',
        3870: 'FYRESDAL',
        3880: 'DALEN',
        3882: 'ÅMDALS VERK',
        3883: 'TREUNGEN',
        3884: 'RAULAND',
        3885: 'FYRESDAL',
        3886: 'DALEN',
        3887: 'VINJE',
        3888: 'EDLAND',
        3890: 'VINJE',
        3891: 'HØYDALSMO',
        3893: 'VINJESVINGEN',
        3895: 'EDLAND',
        3947: 'LANGANGEN',
        3948: 'PORSGRUNN',
        3949: 'PORSGRUNN',
        3950: 'BREVIK',
        3965: 'HERRE',
        3966: 'STATHELLE',
        3967: 'STATHELLE',
        3970: 'LANGESUND',
        3991: 'BREVIK',
        3993: 'LANGESUND',
        3994: 'LANGESUND',
        3995: 'STATHELLE',
        3999: 'HERRE',
        4050: 'SOLA',
        4051: 'SOLA',
        4052: 'RØYNEBERG',
        4053: 'RÆGE',
        4054: 'TJELTA',
        4055: 'SOLA',
        4059: 'RØYNEBERG',
        4063: 'TJELTA',
        4068: 'STAVANGER',
        4076: 'VASSØY',
        4077: 'HUNDVÅG',
        4083: 'HUNDVÅG',
        4084: 'STAVANGER',
        4085: 'HUNDVÅG',
        4086: 'HUNDVÅG',
        4087: 'STAVANGER',
        4088: 'STAVANGER',
        4096: 'RANDABERG',
        4097: 'SOLA',
        4098: 'TANANGER',
        4099: 'STAVANGER',
        4100: 'JØRPELAND',
        4102: 'IDSE',
        4110: 'FORSAND',
        4119: 'FORSAND',
        4120: 'TAU',
        4121: 'TAU',
        4123: 'SØR-HIDLE',
        4124: 'TAU',
        4126: 'JØRPELAND',
        4127: 'LYSEBOTN',
        4128: 'FLØYRLI',
        4129: 'SONGESAND',
        4130: 'HJELMELAND',
        4134: 'JØSENFJORDEN',
        4137: 'ÅRDAL I RYFYLKE',
        4139: 'FISTER',
        4146: 'SKIFTUN',
        4148: 'HJELMELAND',
        4150: 'RENNESØY',
        4152: 'VESTRE ÅMØY',
        4153: 'BRIMSE',
        4154: 'AUSTRE ÅMØY',
        4156: 'MOSTERØY',
        4158: 'BRU',
        4159: 'RENNESØY',
        4160: 'FINNØY',
        4161: 'FINNØY',
        4163: 'TALGJE',
        4164: 'FOGN',
        4167: 'HELGØY I RYFYLKE',
        4168: 'BYRE',
        4169: 'SØRBOKN',
        4170: 'SJERNARØY',
        4173: 'NORD-HIDLE',
        4174: 'SJERNARØY',
        4180: 'KVITSØY',
        4181: 'KVITSØY',
        4182: 'SKARTVEIT',
        4187: 'OMBO',
        4198: 'FOLDØY',
        4200: 'SAUDA',
        4201: 'SAUDA',
        4208: 'SAUDASJØEN',
        4209: 'VANVIK',
        4230: 'SAND',
        4233: 'ERFJORD',
        4234: 'JELSA',
        4235: 'HEBNES',
        4237: 'SULDALSOSEN',
        4239: 'SAND',
        4240: 'SULDALSOSEN',
        4244: 'NESFLATEN',
        4250: 'KOPERVIK',
        4260: 'TORVASTAD',
        4262: 'AVALDSNES',
        4264: 'KVALAVÅG',
        4265: 'HÅVIK',
        4270: 'ÅKREHAMN',
        4272: 'SANDVE',
        4274: 'STOL',
        4275: 'SÆVELANDSVIK',
        4276: 'VEAVÅGEN',
        4280: 'SKUDENESHAVN',
        4291: 'KOPERVIK',
        4294: 'KOPERVIK',
        4295: 'VEAVÅGEN',
        4296: 'ÅKREHAMN',
        4297: 'SKUDENESHAVN',
        4298: 'TORVASTAD',
        4299: 'AVALDSNES',
        4310: 'HOMMERSÅK',
        4311: 'HOMMERSÅK',
        4330: 'ÅLGÅRD',
        4331: 'ÅLGÅRD',
        4332: 'FIGGJO',
        4333: 'OLTEDAL',
        4334: 'ÅLGÅRD',
        4335: 'DIRDAL',
        4339: 'ÅLGÅRD',
        4340: 'BRYNE',
        4341: 'BRYNE',
        4342: 'UNDHEIM',
        4343: 'ORRE',
        4347: 'LYE',
        4348: 'LYE',
        4349: 'BRYNE',
        4353: 'KLEPP STASJON',
        4354: 'VOLL',
        4355: 'KVERNALAND',
        4356: 'KVERNALAND',
        4357: 'KLEPP STASJON',
        4358: 'KLEPPE',
        4360: 'VARHAUG',
        4361: 'SIREVÅG',
        4362: 'VIGRESTAD',
        4363: 'BRUSAND',
        4364: 'SIREVÅG',
        4365: 'NÆRBØ',
        4367: 'NÆRBØ',
        4368: 'VARHAUG',
        4369: 'VIGRESTAD',
        4375: 'HELLVIK',
        4376: 'HELLELAND',
        4378: 'EGERSUND',
        4379: 'EGERSUND',
        4380: 'HAUGE I DALANE',
        4381: 'HAUGE I DALANE',
        4384: 'VIKESÅ',
        4385: 'HELLELAND',
        4387: 'BJERKREIM',
        4389: 'VIKESÅ',
        4390: 'OLTEDAL',
        4395: 'HOMMERSÅK',
        4420: 'ÅNA-SIRA',
        4432: 'HIDRASUND',
        4434: 'ANDABELØY',
        4436: 'GYLAND',
        4438: 'SIRA',
        4439: 'SIRA',
        4440: 'TONSTAD',
        4441: 'TONSTAD',
        4443: 'TJØRHOM',
        4460: 'MOI',
        4462: 'HOVSHERAD',
        4463: 'UALAND',
        4465: 'MOI',
        4473: 'KVINLOG',
        4480: 'KVINESDAL',
        4484: 'ØYESTRANDA',
        4485: 'FEDA',
        4519: 'HOLUM',
        4525: 'KONSMO',
        4526: 'KONSMO',
        4528: 'KOLLUNGTVEIT',
        4529: 'BYREMO',
        4532: 'ØYSLEBØ',
        4534: 'MARNARDAL',
        4535: 'MARNARDAL',
        4536: 'BJELLAND',
        4540: 'ÅSERAL',
        4541: 'ÅSERAL',
        4544: 'FOSSDAL',
        4563: 'BORHAUG',
        4586: 'KORSHAMN',
        4588: 'KVÅS',
        4590: 'SNARTEMO',
        4595: 'TINGVATN',
        4596: 'EIKEN',
        4597: 'EIKEN',
        4609: 'KARDEMOMME BY',
        4619: 'MOSBY',
        4625: 'FLEKKERØY',
        4645: 'NODELAND',
        4646: 'FINSLAND',
        4647: 'BRENNÅSEN',
        4649: 'FINSLAND',
        4656: 'HAMRESANDEN',
        4657: 'KJEVIK',
        4658: 'TVEIT',
        4679: 'FLEKKERØY',
        4684: 'BRENNÅSEN',
        4685: 'NODELAND',
        4699: 'TVEIT',
        4705: 'ØVREBØ',
        4715: 'ØVREBØ',
        4720: 'HÆGELAND',
        4721: 'HÆGELAND',
        4724: 'IVELAND',
        4725: 'IVELAND',
        4730: 'VATNESTRØM',
        4737: 'HORNNES',
        4741: 'BYGLANDSFJORD',
        4742: 'GRENDI',
        4744: 'BYGLAND',
        4745: 'BYGLAND',
        4746: 'VALLE',
        4747: 'VALLE',
        4748: 'RYSSTAD',
        4749: 'RYSSTAD',
        4754: 'BYKLE',
        4760: 'BIRKELAND',
        4766: 'HEREFOSS',
        4768: 'ENGESLAND',
        4770: 'HØVÅG',
        4780: 'BREKKESTØ',
        4793: 'HØVÅG',
        4794: 'LILLESAND',
        4795: 'BIRKELAND',
        4810: 'EYDEHAVN',
        4812: 'KONGSHAVN',
        4815: 'SALTRØD',
        4816: 'KOLBJØRNSVIK',
        4817: 'HIS',
        4818: 'FÆRVIK',
        4820: 'FROLAND',
        4821: 'RYKENE',
        4822: 'RYKENE',
        4823: 'NEDENES',
        4824: 'BJORBEKK',
        4825: 'ARENDAL',
        4827: 'FROLANDS VERK',
        4828: 'MJÅVATN',
        4830: 'HYNNEKLEIV',
        4832: 'MYKLAND',
        4834: 'RISDAL',
        4851: 'SALTRØD',
        4852: 'FÆRVIK',
        4853: 'HIS',
        4854: 'NEDENES',
        4855: 'FROLAND',
        4862: 'EYDEHAVN',
        4863: 'NELAUG',
        4864: 'ÅMLI',
        4865: 'ÅMLI',
        4868: 'SELÅSVATN',
        4869: 'DØLEMO',
        4870: 'FEVIK',
        4888: 'HOMBORSUND',
        4889: 'FEVIK',
        4909: 'SONGE',
        4910: 'LYNGØR',
        4912: 'GJEVING',
        4915: 'VESTRE SANDØYA',
        4916: 'BORØY',
        4920: 'STAUBØ',
        4921: 'STAUBØ',
        4934: 'NES VERK',
        4971: 'SUNDEBRU',
        4972: 'GJERSTAD',
        4973: 'VEGÅRSHEI',
        4974: 'SØNDELED',
        4980: 'GJERSTAD',
        4985: 'VEGÅRSHEI',
        4990: 'SØNDELED',
        4993: 'SUNDEBRU',
        4994: 'AKLAND',
        5101: 'EIDSVÅGNESET',
        5104: 'EIDSVÅG I ÅSANE',
        5105: 'EIDSVÅG I ÅSANE',
        5106: 'ØVRE ERVIK',
        5107: 'SALHUS',
        5108: 'HORDVIK',
        5109: 'HYLKJE',
        5111: 'BREISTEIN',
        5113: 'TERTNES',
        5114: 'TERTNES',
        5122: 'MORVIK',
        5124: 'MORVIK',
        5134: 'FLAKTVEIT',
        5135: 'FLAKTVEIT',
        5136: 'MJØLKERÅEN',
        5137: 'MJØLKERÅEN',
        5151: 'STRAUMSGREND',
        5170: 'BJØRNDALSTRÆ',
        5174: 'MATHOPEN',
        5176: 'LODDEFJORD',
        5177: 'BJØRØYHAMN',
        5178: 'LODDEFJORD',
        5179: 'GODVIK',
        5183: 'OLSVIK',
        5184: 'OLSVIK',
        5207: 'SØFTELAND',
        5212: 'SØFTELAND',
        5213: 'LEPSØY',
        5214: 'LYSEKLOSTER',
        5215: 'LYSEKLOSTER',
        5216: 'LEPSØY',
        5217: 'HAGAVIK',
        5218: 'NORDSTRØNO',
        5229: 'KALANDSEIDET',
        5243: 'FANA',
        5244: 'FANA',
        5251: 'SØREIDGREND',
        5252: 'SØREIDGREND',
        5253: 'SANDSLI',
        5254: 'SANDSLI',
        5257: 'KOKSTAD',
        5258: 'BLOMSTERDALEN',
        5259: 'HJELLESTAD',
        5260: 'INDRE ARNA',
        5261: 'INDRE ARNA',
        5262: 'ARNATVEIT',
        5263: 'TRENGEREID',
        5264: 'GARNES',
        5265: 'YTRE ARNA',
        5267: 'ESPELAND',
        5268: 'HAUKELAND',
        5281: 'VALESTRANDSFOSSEN',
        5282: 'LONEVÅG',
        5283: 'FOTLANDSVÅG',
        5284: 'TYSSEBOTNEN',
        5285: 'BRUVIK',
        5286: 'HAUS',
        5291: 'VALESTRANDSFOSSEN',
        5293: 'LONEVÅG',
        5299: 'HAUS',
        5300: 'KLEPPESTØ',
        5301: 'KLEPPESTØ',
        5302: 'STRUSSHAMN',
        5303: 'FOLLESE',
        5304: 'HETLEVIK',
        5305: 'FLORVÅG',
        5306: 'ERDAL',
        5307: 'ASK',
        5308: 'KLEPPESTØ',
        5309: 'KLEPPESTØ',
        5310: 'HAUGLANDSHELLA',
        5311: 'KJERRGARDEN',
        5314: 'KJERRGARDEN',
        5315: 'HERDLA',
        5318: 'STRUSSHAMN',
        5325: 'FOLLESE',
        5326: 'ASK',
        5327: 'HAUGLANDSHELLA',
        5329: 'FLORVÅG',
        5331: 'RONG',
        5333: 'TJELDSTØ',
        5334: 'HELLESØY',
        5335: 'HERNAR',
        5336: 'TJELDSTØ',
        5337: 'RONG',
        5345: 'KNARREVIK',
        5346: 'ÅGOTNES',
        5347: 'ÅGOTNES',
        5350: 'BRATTHOLMEN',
        5353: 'STRAUME',
        5354: 'STRAUME',
        5355: 'KNARREVIK',
        5357: 'FJELL',
        5358: 'FJELL',
        5360: 'KOLLTVEIT',
        5363: 'ÅGOTNES',
        5365: 'TURØY',
        5366: 'MISJE',
        5371: 'SKOGSVÅG',
        5374: 'STEINSLAND',
        5378: 'KLOKKARVIK',
        5379: 'STEINSLAND',
        5380: 'TÆLAVÅG',
        5381: 'GLESVÆR',
        5382: 'SKOGSVÅG',
        5384: 'TORANGSVÅG',
        5385: 'BAKKASUND',
        5387: 'MØKSTER',
        5388: 'LITLAKALSØY',
        5392: 'STOREBØ',
        5393: 'STOREBØ',
        5394: 'KOLBEINSVIK',
        5396: 'VESTRE VINNESVÅG',
        5397: 'BEKKJARVIK',
        5398: 'STOLMEN',
        5399: 'BEKKJARVIK',
        5408: 'SAGVÅG',
        5409: 'STORD',
        5410: 'SAGVÅG',
        5411: 'STORD',
        5412: 'STORD',
        5413: 'HUGLO',
        5418: 'FITJAR',
        5419: 'FITJAR',
        5420: 'RUBBESTADNESET',
        5423: 'BRANDASUND',
        5427: 'URANGSVÅG',
        5428: 'FOLDRØYHAMN',
        5430: 'BREMNES',
        5437: 'FINNÅS',
        5440: 'MOSTERHAMN',
        5443: 'BØMLO',
        5444: 'ESPEVÆR',
        5445: 'BREMNES',
        5447: 'MOSTERHAMN',
        5449: 'BØMLO',
        5450: 'SUNDE I SUNNHORDLAND',
        5451: 'VALEN',
        5452: 'SANDVOLL',
        5453: 'UTÅKER',
        5454: 'SÆBØVIK',
        5455: 'HALSNØY KLOSTER',
        5457: 'HØYLANDSBYGD',
        5458: 'ARNAVIK',
        5459: 'FJELBERG',
        5460: 'HUSNES',
        5462: 'HERØYSUNDET',
        5463: 'USKEDALEN',
        5464: 'DIMMELSVIK',
        5465: 'USKEDALEN',
        5470: 'ROSENDAL',
        5472: 'SEIMSFOSS',
        5473: 'SNILSTVEITØY',
        5474: 'LØFALLSTRAND',
        5475: 'ÆNES',
        5476: 'MAURANGER',
        5480: 'HUSNES',
        5484: 'SÆBØVIK',
        5486: 'ROSENDAL',
        5498: 'MATRE',
        5499: 'ÅKRA',
        5508: 'KARMSUND',
        5541: 'KOLNES',
        5542: 'KARMSUND',
        5544: 'VORMEDAL',
        5545: 'VORMEDAL',
        5546: 'RØYKSUND',
        5547: 'UTSIRA',
        5548: 'FEØY',
        5549: 'RØVÆR',
        5550: 'SVEIO',
        5551: 'AUKLANDSHAMN',
        5554: 'VALEVÅG',
        5559: 'SVEIO',
        5560: 'NEDSTRAND',
        5561: 'BOKN',
        5562: 'NEDSTRAND',
        5563: 'FØRRESFJORDEN',
        5565: 'TYSVÆRVÅG',
        5566: 'HERVIK',
        5567: 'SKJOLDASTRAUMEN',
        5568: 'VIKEBYGD',
        5569: 'BOKN',
        5570: 'AKSDAL',
        5574: 'SKJOLD',
        5575: 'AKSDAL',
        5576: 'ØVRE VATS',
        5578: 'NEDRE VATS',
        5580: 'ØLEN',
        5582: 'ØLENSVÅG',
        5583: 'VIKEDAL',
        5584: 'BJOA',
        5585: 'SANDEID',
        5586: 'VIKEDAL',
        5588: 'ØLEN',
        5589: 'SANDEID',
        5590: 'ETNE',
        5591: 'ETNE',
        5593: 'SKÅNEVIK',
        5594: 'SKÅNEVIK',
        5595: 'FØRRESFJORDEN',
        5596: 'MARKHUS',
        5598: 'FJÆRA',
        5604: 'ØYSTESE',
        5605: 'ÅLVIK',
        5610: 'ØYSTESE',
        5612: 'STEINSTØ',
        5614: 'ÅLVIK',
        5620: 'TØRVIKBYGD',
        5626: 'KYSNESSTRAND',
        5627: 'JONDAL',
        5628: 'HERAND',
        5629: 'JONDAL',
        5630: 'STRANDEBARM',
        5631: 'STRANDEBARM',
        5632: 'OMASTRAND',
        5633: 'OMASTRAND',
        5635: 'HATLESTRAND',
        5636: 'VARALDSØY',
        5637: 'ØLVE',
        5640: 'EIKELANDSOSEN',
        5641: 'FUSA',
        5642: 'HOLMEFJORD',
        5643: 'STRANDVIK',
        5644: 'SÆVAREID',
        5645: 'SÆVAREID',
        5646: 'NORDTVEITGREND',
        5647: 'BALDERSHEIM',
        5648: 'FUSA',
        5649: 'EIKELANDSOSEN',
        5650: 'TYSSE',
        5651: 'TYSSE',
        5652: 'ÅRLAND',
        5653: 'ÅRLAND',
        5680: 'TYSNES',
        5683: 'REKSTEREN',
        5685: 'UGGDAL',
        5687: 'FLATRÅKER',
        5690: 'LUNDEGREND',
        5693: 'ÅRBAKKA',
        5694: 'ONARHEIM',
        5695: 'UGGDAL',
        5696: 'TYSNES',
        5707: 'EVANGER',
        5708: 'VOSS',
        5709: 'VOSS',
        5710: 'SKULESTADMO',
        5711: 'SKULESTADMO',
        5712: 'VOSSESTRAND',
        5713: 'VOSSESTRAND',
        5714: 'VOSS',
        5715: 'STALHEIM',
        5718: 'MYRDAL',
        5719: 'FINSE',
        5720: 'STANGHELLE',
        5721: 'DALEKVAM',
        5722: 'DALEKVAM',
        5723: 'BOLSTADØYRI',
        5724: 'STANGHELLE',
        5725: 'VAKSDAL',
        5726: 'VAKSDAL',
        5727: 'STAMNES',
        5728: 'EIDSLANDET',
        5729: 'MODALEN',
        5730: 'ULVIK',
        5731: 'ULVIK',
        5732: 'MODALEN',
        5733: 'GRANVIN',
        5734: 'VALLAVIK',
        5736: 'GRANVIN',
        5741: 'AURLAND',
        5742: 'FLÅM',
        5743: 'FLÅM',
        5745: 'AURLAND',
        5746: 'UNDREDAL',
        5747: 'GUDVANGEN',
        5748: 'STYVI',
        5749: 'GUDVANGEN',
        5760: 'RØLDAL',
        5763: 'SKARE',
        5770: 'TYSSEDAL',
        5773: 'HOVLAND',
        5775: 'NÅ',
        5776: 'NÅ',
        5777: 'GRIMO',
        5778: 'UTNE',
        5779: 'UTNE',
        5780: 'KINSARVIK',
        5781: 'LOFTHUS',
        5782: 'KINSARVIK',
        5783: 'EIDFJORD',
        5784: 'ØVRE EIDFJORD',
        5785: 'VØRINGSFOSS',
        5786: 'EIDFJORD',
        5787: 'LOFTHUS',
        5788: 'KINSARVIK',
        5906: 'FREKHAUG',
        5907: 'ALVERSUND',
        5908: 'ISDALSTØ',
        5911: 'ALVERSUND',
        5912: 'SEIM',
        5913: 'EIKANGERVÅG',
        5914: 'ISDALSTØ',
        5915: 'HJELMÅS',
        5916: 'ISDALSTØ',
        5917: 'ROSSLAND',
        5918: 'FREKHAUG',
        5919: 'FREKHAUG',
        5931: 'MANGER',
        5935: 'BØVÅGEN',
        5936: 'MANGER',
        5937: 'BØVÅGEN',
        5938: 'SÆBØVÅGEN',
        5939: 'SLETTA',
        5941: 'AUSTRHEIM',
        5943: 'AUSTRHEIM',
        5947: 'FEDJE',
        5948: 'FEDJE',
        5951: 'LINDÅS',
        5952: 'FONNES',
        5953: 'FONNES',
        5954: 'MONGSTAD',
        5955: 'LINDÅS',
        5956: 'HUNDVIN',
        5957: 'MYKING',
        5960: 'DALSØYRA',
        5961: 'BREKKE',
        5962: 'BJORDAL',
        5963: 'DALSØYRA',
        5964: 'BREKKE',
        5965: 'BJORDAL',
        5966: 'EIVINDVIK',
        5967: 'EIVINDVIK',
        5970: 'BYRKNESØY',
        5977: 'ÅNNELAND',
        5978: 'MJØMNA',
        5979: 'BYRKNESØY',
        5981: 'MASFJORDNES',
        5982: 'MASFJORDNES',
        5983: 'HAUGSVÆR',
        5984: 'MATREDAL',
        5985: 'HAUGSVÆR',
        5986: 'HOSTELAND',
        5987: 'HOSTELAND',
        5991: 'OSTEREIDET',
        5993: 'OSTEREIDET',
        5994: 'VIKANES',
        6030: 'LANGEVÅG',
        6034: 'EIDSNES',
        6035: 'FISKARSTRAND',
        6036: 'MAUSEIDVÅG',
        6037: 'EIDSNES',
        6038: 'FISKARSTRAND',
        6039: 'LANGEVÅG',
        6040: 'VIGRA',
        6050: 'VALDERØYA',
        6052: 'GISKE',
        6054: 'GODØYA',
        6055: 'GODØYA',
        6057: 'ELLINGSØY',
        6058: 'VALDERØYA',
        6059: 'VIGRA',
        6060: 'HAREID',
        6062: 'BRANDAL',
        6063: 'HJØRUNGAVÅG',
        6064: 'HADDAL',
        6065: 'ULSTEINVIK',
        6067: 'ULSTEINVIK',
        6068: 'EIKSUND',
        6069: 'HAREID',
        6070: 'TJØRVÅG',
        6075: 'MOLTUSTRANDA',
        6076: 'MOLTUSTRANDA',
        6078: 'GJERDSVIKA',
        6079: 'GURSKØY',
        6080: 'GURSKØY',
        6082: 'GURSKEN',
        6083: 'GJERDSVIKA',
        6084: 'LARSNES',
        6085: 'LARSNES',
        6086: 'KVAMSØY',
        6087: 'KVAMSØY',
        6088: 'SANDSHAMN',
        6089: 'SANDSHAMN',
        6094: 'LEINØY',
        6095: 'BØLANDET',
        6096: 'RUNDE',
        6098: 'NERLANDSØY',
        6099: 'FOSNAVÅG',
        6110: 'AUSTEFJORDEN',
        6120: 'FOLKESTAD',
        6133: 'LAUVSTAD',
        6134: 'LAUVSTAD',
        6138: 'SYVDE',
        6139: 'FISKÅ',
        6140: 'SYVDE',
        6141: 'ROVDE',
        6142: 'EIDSÅ',
        6143: 'FISKÅ',
        6144: 'SYLTE',
        6146: 'ÅHEIM',
        6147: 'ÅHEIM',
        6149: 'ÅRAM',
        6160: 'HOVDEBYGDA',
        6161: 'HOVDEBYGDA',
        6165: 'SÆBØ',
        6166: 'SÆBØ',
        6170: 'VARTDAL',
        6171: 'VARTDAL',
        6174: 'BARSTADVIK',
        6183: 'TRANDAL',
        6184: 'STORESTANDAL',
        6190: 'BJØRKE',
        6196: 'NORANGSFJORDEN',
        6200: 'STRANDA',
        6201: 'STRANDA',
        6210: 'VALLDAL',
        6211: 'VALLDAL',
        6212: 'LIABYGDA',
        6213: 'TAFJORD',
        6214: 'NORDDAL',
        6215: 'EIDSDAL',
        6216: 'GEIRANGER',
        6217: 'GEIRANGER',
        6218: 'HELLESYLT',
        6219: 'HELLESYLT',
        6220: 'STRAUMGJERDE',
        6222: 'IKORNNES',
        6223: 'IKORNNES',
        6224: 'HUNDEIDVIK',
        6230: 'SYKKYLVEN',
        6238: 'STRAUMGJERDE',
        6239: 'SYKKYLVEN',
        6240: 'ØRSKOG',
        6249: 'ØRSKOG',
        6250: 'STORDAL',
        6255: 'EIDSDAL',
        6259: 'STORDAL',
        6260: 'SKODJE',
        6263: 'SKODJE',
        6264: 'TENNFJORD',
        6265: 'VATNE',
        6270: 'BRATTVÅG',
        6272: 'HILDRE',
        6280: 'SØVIK',
        6281: 'SØVIK',
        6282: 'BRATTVÅG',
        6283: 'VATNE',
        6285: 'STOREKALVØY',
        6290: 'HARAMSØY',
        6291: 'HARAMSØY',
        6292: 'KJERSTAD',
        6293: 'LONGVA',
        6294: 'FJØRTOFT',
        6300: 'ÅNDALSNES',
        6301: 'ÅNDALSNES',
        6310: 'VEBLUNGSNES',
        6315: 'INNFJORDEN',
        6320: 'ISFJORDEN',
        6330: 'VERMA',
        6331: 'VERMA',
        6339: 'ISFJORDEN',
        6350: 'EIDSBYGDA',
        6360: 'ÅFARNES',
        6361: 'ÅFARNES',
        6363: 'MITTET',
        6364: 'VISTDAL',
        6365: 'VISTDAL',
        6385: 'MÅNDALEN',
        6386: 'MÅNDALEN',
        6387: 'VÅGSTRANDA',
        6388: 'VÅGSTRANDA',
        6389: 'FIKSDAL',
        6390: 'VESTNES',
        6391: 'TRESFJORD',
        6392: 'VIKEBUKT',
        6393: 'TOMREFJORD',
        6394: 'FIKSDAL',
        6395: 'REKDAL',
        6396: 'VIKEBUKT',
        6397: 'TRESFJORD',
        6398: 'TOMREFJORD',
        6399: 'VESTNES',
        6408: 'AUREOSEN',
        6418: 'SEKKEN',
        6430: 'BUD',
        6431: 'BUD',
        6433: 'HUSTAD',
        6440: 'ELNESVÅGEN',
        6443: 'TORNES I ROMSDAL',
        6444: 'FARSTAD',
        6445: 'MALMEFJORDEN',
        6446: 'FARSTAD',
        6447: 'ELNESVÅGEN',
        6450: 'HJELSET',
        6452: 'KLEIVE',
        6453: 'KLEIVE',
        6454: 'HJELSET',
        6455: 'KORTGARDEN',
        6456: 'SKÅLA',
        6457: 'BOLSØYA',
        6458: 'SKÅLA',
        6462: 'RAUDSAND',
        6470: 'ERESFJORD',
        6471: 'ERESFJORD',
        6472: 'EIKESDAL',
        6475: 'MIDSUND',
        6476: 'MIDSUND',
        6480: 'AUKRA',
        6481: 'AUKRA',
        6483: 'ONA',
        6484: 'SANDØY',
        6485: 'HARØY',
        6486: 'ORTEN',
        6487: 'HARØY',
        6488: 'MYKLEBOST',
        6490: 'EIDE',
        6493: 'LYNGSTAD',
        6494: 'VEVANG',
        6499: 'EIDE',
        6570: 'SMØLA',
        6571: 'SMØLA',
        6590: 'TUSTNA',
        6591: 'TUSTNA',
        6600: 'SUNNDALSØRA',
        6601: 'SUNNDALSØRA',
        6610: 'ØKSENDAL',
        6611: 'FURUGRENDA',
        6612: 'GRØA',
        6613: 'GJØRA',
        6614: 'GJØRA',
        6620: 'ÅLVUNDEID',
        6622: 'ÅLVUNDFJORD',
        6623: 'ÅLVUNDFJORD',
        6627: 'TINGVOLL',
        6628: 'MEISINGSET',
        6629: 'TORJULVÅGEN',
        6630: 'TINGVOLL',
        6631: 'BATNFJORDSØRA',
        6632: 'BATNFJORDSØRA',
        6633: 'GJEMNES',
        6636: 'ANGVIK',
        6637: 'FLEMMA',
        6638: 'OSMARKA',
        6639: 'TORVIKBUKT',
        6640: 'KVANNE',
        6641: 'TORVIKBUKT',
        6642: 'STANGVIK',
        6643: 'BØFJORDEN',
        6644: 'BÆVERFJORD',
        6645: 'TODALEN',
        6650: 'SURNADAL',
        6652: 'SURNADAL',
        6653: 'ØVRE SURNADAL',
        6655: 'VINDØLA',
        6656: 'SURNADAL',
        6657: 'RINDAL',
        6658: 'RINDALSSKOGEN',
        6659: 'RINDAL',
        6670: 'ØYDEGARD',
        6671: 'ØYDEGARD',
        6674: 'KVISVIK',
        6680: 'HALSANAUSTAN',
        6683: 'VÅGLAND',
        6686: 'VALSØYBOTN',
        6687: 'VALSØYFJORD',
        6688: 'VÅGLAND',
        6689: 'AURE',
        6690: 'AURE',
        6693: 'MJOSUNDET',
        6694: 'FOLDFJORDEN',
        6697: 'VIHALS',
        6698: 'LESUND',
        6699: 'KJØRSVIKBUGEN',
        6704: 'DEKNEPOLLEN',
        6707: 'RAUDEBERG',
        6708: 'BRYGGJA',
        6710: 'RAUDEBERG',
        6711: 'BRYGGJA',
        6713: 'ALMENNINGEN',
        6714: 'SILDA',
        6715: 'BARMEN',
        6716: 'HUSEVÅG',
        6717: 'FLATRAKET',
        6718: 'DEKNEPOLLEN',
        6719: 'SKATESTRAUMEN',
        6721: 'SVELGEN',
        6723: 'SVELGEN',
        6726: 'BREMANGER',
        6727: 'BREMANGER',
        6728: 'KALVÅG',
        6729: 'KALVÅG',
        6730: 'DAVIK',
        6734: 'RUGSUND',
        6737: 'ÅLFOTEN',
        6740: 'SELJE',
        6741: 'SELJE',
        6750: 'STADLANDET',
        6751: 'STADLANDET',
        6761: 'HORNINDAL',
        6763: 'HORNINDAL',
        6776: 'KJØLSDALEN',
        6777: 'STÅRHEIM',
        6778: 'LOTE',
        6779: 'HOLMØYANE',
        6784: 'OLDEN',
        6788: 'OLDEN',
        6789: 'LOEN',
        6790: 'LOEN',
        6791: 'OLDEDALEN',
        6792: 'BRIKSDALSBRE',
        6793: 'INNVIK',
        6794: 'INNVIK',
        6795: 'BLAKSÆTER',
        6796: 'HOPLAND',
        6797: 'UTVIK',
        6798: 'HJELLEDALEN',
        6799: 'OPPSTRYN',
        6806: 'NAUSTDAL',
        6817: 'NAUSTDAL',
        6818: 'HAUKEDALEN',
        6819: 'FØRDE',
        6820: 'FØRDE',
        6826: 'BYRKJELO',
        6827: 'BREIM',
        6828: 'HESTENESØYRA',
        6829: 'HYEN',
        6830: 'BYRKJELO',
        6831: 'HYEN',
        6841: 'SKEI I JØLSTER',
        6843: 'SKEI I JØLSTER',
        6844: 'VASSENDEN',
        6845: 'FJÆRLAND',
        6847: 'VASSENDEN',
        6848: 'FJÆRLAND',
        6849: 'KAUPANGER',
        6854: 'KAUPANGER',
        6855: 'FRØNNINGEN',
        6856: 'SOGNDAL',
        6857: 'SOGNDAL',
        6858: 'FARDAL',
        6859: 'SLINDE',
        6861: 'LEIKANGER',
        6863: 'LEIKANGER',
        6866: 'GAUPNE',
        6867: 'HAFSLO',
        6868: 'GAUPNE',
        6869: 'HAFSLO',
        6870: 'ORNES',
        6871: 'JOSTEDAL',
        6872: 'LUSTER',
        6873: 'MARIFJØRA',
        6874: 'LUSTER',
        6875: 'HØYHEIMSVIK',
        6876: 'SKJOLDEN',
        6877: 'FORTUN',
        6878: 'VEITASTROND',
        6879: 'SOLVORN',
        6881: 'ÅRDALSTANGEN',
        6882: 'ØVRE ÅRDAL',
        6884: 'ØVRE ÅRDAL',
        6885: 'ÅRDALSTANGEN',
        6886: 'LÆRDAL',
        6887: 'LÆRDAL',
        6888: 'BORGUND',
        6891: 'VIK I SOGN',
        6893: 'VIK I SOGN',
        6894: 'VANGSNES',
        6895: 'FEIOS',
        6896: 'FRESVIK',
        6898: 'BALESTRAND',
        6899: 'BALESTRAND',
        6912: 'KINN',
        6913: 'FLORØ',
        6914: 'SVANØYBUKT',
        6915: 'ROGNALDSVÅG',
        6916: 'BAREKSTAD',
        6917: 'BATALDEN',
        6918: 'SØR-SKORPA',
        6919: 'TANSØY',
        6921: 'HARDBAKKE',
        6924: 'HARDBAKKE',
        6926: 'KRAKHELLA',
        6927: 'YTRØYGREND',
        6928: 'KOLGROV',
        6929: 'HERSVIKBYGDA',
        6940: 'EIKEFJORD',
        6941: 'EIKEFJORD',
        6942: 'SVORTEVIK',
        6944: 'STAVANG',
        6946: 'LAVIK',
        6947: 'LAVIK',
        6951: 'LEIRVIK I SOGN',
        6953: 'LEIRVIK I SOGN',
        6957: 'HYLLESTAD',
        6958: 'SØRBØVÅG',
        6959: 'SØRBØVÅG',
        6964: 'KORSSUND',
        6966: 'GUDDAL',
        6967: 'HELLEVIK I FJALER',
        6968: 'FLEKKE',
        6969: 'STRAUMSNES',
        6975: 'SKILBREI',
        6976: 'BYGSTAD',
        6977: 'BYGSTAD',
        6978: 'VIKSDALEN',
        6980: 'ASKVOLL',
        6982: 'HOLMEDAL',
        6983: 'KVAMMEN',
        6984: 'STONGFJORDEN',
        6985: 'ATLØY',
        6986: 'VÆRLANDET',
        6987: 'BULANDET',
        6988: 'ASKVOLL',
        6991: 'HØYANGER',
        6993: 'HØYANGER',
        6994: 'NESSANE',
        6995: 'KYRKJEBØ',
        6996: 'VADHEIM',
        6997: 'VADHEIM',
        7057: 'JONSVATNET',
        7058: 'JAKOBSLI',
        7059: 'JAKOBSLI',
        7070: 'BOSBERG',
        7071: 'TRONDHEIM',
        7072: 'HEIMDAL',
        7074: 'SPONGDAL',
        7075: 'TILLER',
        7078: 'SAUPSTAD',
        7079: 'FLATÅSEN',
        7080: 'HEIMDAL',
        7081: 'SJETNEMARKA',
        7082: 'KATTEM',
        7083: 'LEINSTRAND',
        7088: 'HEIMDAL',
        7089: 'HEIMDAL',
        7097: 'SAUPSTAD',
        7098: 'SAUPSTAD',
        7099: 'FLATÅSEN',
        7100: 'RISSA',
        7101: 'RISSA',
        7105: 'STADSBYGD',
        7110: 'FEVÅG',
        7111: 'HASSELVIKA',
        7112: 'HASSELVIKA',
        7113: 'HUSBYSJØEN',
        7114: 'RÅKVÅG',
        7115: 'HUSBYSJØEN',
        7116: 'RÅKVÅG',
        7119: 'STADSBYGD',
        7120: 'LEKSVIK',
        7121: 'LEKSVIK',
        7125: 'VANVIKAN',
        7126: 'VANVIKAN',
        7127: 'OPPHAUG',
        7129: 'BREKSTAD',
        7130: 'BREKSTAD',
        7140: 'OPPHAUG',
        7142: 'UTHAUG',
        7150: 'STORFOSNA',
        7151: 'STORFOSNA',
        7152: 'KRÅKVÅG',
        7153: 'GARTEN',
        7156: 'LEKSA',
        7159: 'BJUGN',
        7160: 'BJUGN',
        7164: 'LYSØYSUNDET',
        7165: 'OKSVOLL',
        7166: 'TARVA',
        7167: 'VALLERSUND',
        7168: 'LYSØYSUNDET',
        7169: 'ÅFJORD',
        7170: 'ÅFJORD',
        7174: 'REVSNES',
        7175: 'STOKKØY',
        7176: 'LINESØYA',
        7177: 'REVSNES',
        7178: 'STOKKØY',
        7180: 'ROAN',
        7181: 'ROAN',
        7190: 'BESSAKER',
        7194: 'BRANDSFJORD',
        7200: 'KYRKSÆTERØRA',
        7201: 'KYRKSÆTERØRA',
        7203: 'VINJEØRA',
        7206: 'HELLANDSJØEN',
        7211: 'KORSVEGEN',
        7212: 'KORSVEGEN',
        7213: 'GÅSBAKKEN',
        7227: 'GIMSE',
        7228: 'KVÅL',
        7231: 'LUNDAMO',
        7232: 'LUNDAMO',
        7234: 'LER',
        7235: 'LER',
        7236: 'HOVIN I GAULDAL',
        7238: 'HOVIN I GAULDAL',
        7239: 'HITRA',
        7240: 'HITRA',
        7241: 'ANSNES',
        7242: 'KNARRLAGSUND',
        7243: 'KVENVÆR',
        7244: 'KNARRLAGSUND',
        7245: 'KVENVÆR',
        7246: 'SANDSTAD',
        7247: 'HESTVIKA',
        7250: 'MELANDSJØ',
        7252: 'DOLMØY',
        7255: 'SUNDLANDET',
        7256: 'HEMNSKJELA',
        7257: 'SNILLFJORD',
        7259: 'SNILLFJORD',
        7260: 'SISTRANDA',
        7261: 'SISTRANDA',
        7263: 'HAMARVIK',
        7264: 'HAMARVIK',
        7266: 'KVERVA',
        7267: 'KVERVA',
        7268: 'TITRAN',
        7270: 'DYRVIK',
        7273: 'NORDDYRØY',
        7274: 'NORDDYRØY',
        7280: 'SULA',
        7282: 'BOGØYVÆR',
        7284: 'MAUSUND',
        7285: 'GJÆSINGEN',
        7286: 'SØRBURØY',
        7287: 'SAUØY',
        7288: 'SOKNEDAL',
        7289: 'SOKNEDAL',
        7290: 'STØREN',
        7291: 'STØREN',
        7295: 'ROGNES',
        7298: 'BUDALEN',
        7310: 'GJØLME',
        7315: 'LENSVIK',
        7316: 'LENSVIK',
        7318: 'AGDENES',
        7319: 'AGDENES',
        7320: 'FANNREM',
        7321: 'FANNREM',
        7327: 'SVORKMO',
        7329: 'SVORKMO',
        7331: 'LØKKEN VERK',
        7332: 'LØKKEN VERK',
        7333: 'STORÅS',
        7334: 'STORÅS',
        7335: 'JERPSTAD',
        7336: 'MELDAL',
        7338: 'MELDAL',
        7340: 'OPPDAL',
        7341: 'OPPDAL',
        7342: 'LØNSET',
        7343: 'VOGNILL',
        7345: 'DRIVA',
        7350: 'BUVIKA',
        7351: 'BUVIKA',
        7353: 'BØRSA',
        7354: 'VIGGJA',
        7355: 'EGGKLEIVA',
        7356: 'SKAUN',
        7357: 'SKAUN',
        7358: 'BØRSA',
        7361: 'RØROS',
        7370: 'BREKKEBYGD',
        7372: 'GLÅMOS',
        7374: 'RØROS',
        7375: 'RØROS',
        7380: 'ÅLEN',
        7383: 'HALTDALEN',
        7384: 'ÅLEN',
        7510: 'SKATVAL',
        7511: 'SKATVAL',
        7517: 'HELL',
        7519: 'ELVARLI',
        7520: 'HEGRA',
        7525: 'FLORNES',
        7529: 'HEGRA',
        7530: 'MERÅKER',
        7531: 'MERÅKER',
        7533: 'KOPPERÅ',
        7540: 'KLÆBU',
        7541: 'KLÆBU',
        7549: 'TANEM',
        7550: 'HOMMELVIK',
        7551: 'HOMMELVIK',
        7560: 'VIKHAMMER',
        7562: 'SAKSVIK',
        7563: 'MALVIK',
        7566: 'VIKHAMMER',
        7570: 'HELL',
        7584: 'SELBUSTRAND',
        7590: 'TYDAL',
        7591: 'TYDAL',
        7596: 'FLAKNAN',
        7619: 'SKOGN',
        7620: 'SKOGN',
        7622: 'MARKABYGDA',
        7623: 'RONGLAN',
        7624: 'EKNE',
        7629: 'YTTERØY',
        7630: 'ÅSEN',
        7631: 'ÅSEN',
        7632: 'ÅSENFJORD',
        7633: 'FROSTA',
        7634: 'FROSTA',
        7660: 'VUKU',
        7661: 'VUKU',
        7690: 'MOSVIK',
        7691: 'MOSVIK',
        7710: 'SPARBU',
        7730: 'BEITSTAD',
        7732: 'STEINKJER',
        7733: 'SPARBU',
        7739: 'BEITSTAD',
        7740: 'STEINSDALEN',
        7741: 'STEINSDALEN',
        7742: 'YTTERVÅG',
        7744: 'HEPSØY',
        7745: 'OPPLAND',
        7746: 'HASVÅG',
        7748: 'SETER',
        7750: 'NAMDALSEID',
        7751: 'NAMDALSEID',
        7760: 'SNÅSA',
        7761: 'SNÅSA',
        7770: 'FLATANGER',
        7771: 'FLATANGER',
        7777: 'NORD-STATLAND',
        7790: 'MALM',
        7791: 'MALM',
        7795: 'FOLLAFOSS',
        7796: 'FOLLAFOSS',
        7797: 'VERRABOTN',
        7817: 'SALSNES',
        7818: 'LUND',
        7819: 'FOSSLANDSOSEN',
        7820: 'SPILLUM',
        7821: 'SPILLUM',
        7822: 'BANGSUND',
        7823: 'BANGSUND',
        7856: 'JØA',
        7860: 'SKAGE I NAMDALEN',
        7863: 'OVERHALLA',
        7864: 'OVERHALLA',
        7869: 'SKAGE I NAMDALEN',
        7870: 'GRONG',
        7871: 'GRONG',
        7873: 'HARRAN',
        7874: 'HARRAN',
        7876: 'KONGSMOEN',
        7877: 'HØYLANDET',
        7878: 'HØYLANDET',
        7881: 'NORDLI',
        7882: 'NORDLI',
        7884: 'SØRLI',
        7885: 'SØRLI',
        7890: 'NAMSSKOGAN',
        7891: 'NAMSSKOGAN',
        7892: 'TRONES',
        7893: 'SKOROVATN',
        7896: 'BREKKVASSELV',
        7897: 'LIMINGEN',
        7898: 'LIMINGEN',
        7940: 'OTTERSØY',
        7941: 'OTTERSØY',
        7944: 'INDRE NÆRØY',
        7950: 'ABELVÆR',
        7960: 'SALSBRUKET',
        7970: 'KOLVEREID',
        7971: 'KOLVEREID',
        7973: 'GJERDINGA',
        7979: 'TERRÅK',
        7980: 'TERRÅK',
        7981: 'HARANGSFJORD',
        7982: 'BINDALSEIDET',
        7983: 'BINDALSEIDET',
        7985: 'FOLDEREID',
        7986: 'FOLDEREID',
        7990: 'NAUSTBUKTA',
        7993: 'GUTVIK',
        7994: 'LEKA',
        7995: 'LEKA',
        8050: 'TVERLANDET',
        8056: 'SALTSTRAUMEN',
        8057: 'SALTSTRAUMEN',
        8058: 'TVERLANDET',
        8062: 'VÆRØY',
        8063: 'VÆRØY',
        8064: 'RØST',
        8065: 'RØST',
        8093: 'KJERRINGØY',
        8094: 'FLEINVÆR',
        8095: 'HELLIGVÆR',
        8096: 'BLIKSVÆR',
        8097: 'GIVÆR',
        8098: 'LANDEGODE',
        8099: 'JAN MAYEN',
        8100: 'MISVÆR',
        8102: 'SKJERSTAD',
        8103: 'BREIVIK I SALTEN',
        8108: 'MISVÆR',
        8110: 'MOLDJORD',
        8114: 'TOLLÅ',
        8118: 'MOLDJORD',
        8120: 'NYGÅRDSJØEN',
        8128: 'YTRE BEIARN',
        8130: 'SANDHORNØY',
        8134: 'SØRARNØY',
        8135: 'SØRARNØY',
        8136: 'NORDARNØY',
        8138: 'INNDYR',
        8140: 'INNDYR',
        8145: 'STORVIK',
        8146: 'REIPÅ',
        8149: 'NEVERDAL',
        8150: 'ØRNES',
        8151: 'ØRNES',
        8157: 'MELØY',
        8158: 'BOLGA',
        8159: 'STØTT',
        8160: 'GLOMFJORD',
        8161: 'GLOMFJORD',
        8168: 'ENGAVÅGEN',
        8170: 'ENGAVÅGEN',
        8178: 'HALSA',
        8179: 'HALSA',
        8181: 'MYKEN',
        8182: 'MELFJORDBOTN',
        8183: 'VÅGAHOLMEN',
        8184: 'ÅGSKARDET',
        8185: 'VÅGAHOLMEN',
        8186: 'TJONGSFJORDEN',
        8187: 'JEKTVIK',
        8188: 'NORDVERNES',
        8189: 'GJERSVIKGRENDA',
        8190: 'SØRFJORDEN',
        8193: 'RØDØY',
        8195: 'GJERØY',
        8196: 'SELSØYVIK',
        8197: 'STORSELSØY',
        8198: 'NORDNESØY',
        8215: 'VALNESFJORD',
        8218: 'FAUSKE',
        8219: 'FAUSKE',
        8220: 'RØSVIK',
        8226: 'STRAUMEN',
        8230: 'SULITJELMA',
        8231: 'SULITJELMA',
        8232: 'STRAUMEN',
        8233: 'VALNESFJORD',
        8255: 'RØKLAND',
        8256: 'RØKLAND',
        8260: 'INNHAVET',
        8261: 'INNHAVET',
        8264: 'ENGAN',
        8266: 'MØRSVIKBOTN',
        8270: 'DRAG',
        8271: 'DRAG',
        8273: 'NEVERVIK',
        8274: 'MUSKEN',
        8275: 'STORJORD I TYSFJORD',
        8276: 'ULVSVÅG',
        8278: 'STORÅ',
        8281: 'LEINESFJORD',
        8283: 'LEINESFJORD',
        8285: 'LEINES',
        8286: 'NORDFOLD',
        8287: 'ENGELØYA',
        8288: 'BOGØY',
        8289: 'ENGELØYA',
        8290: 'SKUTVIK',
        8294: 'HAMARØY',
        8297: 'TRANØY',
        8298: 'HAMARØY',
        8309: 'KABELVÅG',
        8310: 'KABELVÅG',
        8311: 'HENNINGSVÆR',
        8312: 'HENNINGSVÆR',
        8313: 'KLEPPSTAD',
        8314: 'GIMSØYSAND',
        8315: 'LAUKVIK',
        8316: 'LAUPSTAD',
        8317: 'STRØNSTAD',
        8320: 'SKROVA',
        8322: 'BRETTESNES',
        8323: 'STORFJELL',
        8324: 'DIGERMULEN',
        8325: 'TENGELFJORD',
        8326: 'MYRLAND',
        8328: 'STORMOLLA',
        8340: 'STAMSUND',
        8352: 'SENNESVIK',
        8357: 'VALBERG',
        8360: 'BØSTAD',
        8361: 'BØSTAD',
        8370: 'LEKNES',
        8372: 'GRAVDAL',
        8373: 'BALLSTAD',
        8374: 'BALLSTAD',
        8376: 'LEKNES',
        8377: 'GRAVDAL',
        8378: 'STAMSUND',
        8380: 'RAMBERG',
        8382: 'NAPP',
        8384: 'SUND I LOFOTEN',
        8387: 'FREDVANG',
        8388: 'RAMBERG',
        8390: 'REINE',
        8392: 'SØRVÅGEN',
        8393: 'SØRVÅGEN',
        8398: 'REINE',
        8409: 'GULLESFJORD',
        8410: 'LØDINGEN',
        8411: 'LØDINGEN',
        8412: 'VESTBYGD',
        8413: 'KVITNES',
        8414: 'HENNES',
        8426: 'BARKESTAD',
        8428: 'TUNSTAD',
        8430: 'MYRE',
        8432: 'ALSVÅG',
        8438: 'STØ',
        8439: 'MYRE',
        8445: 'MELBU',
        8447: 'LONKAN',
        8450: 'STOKMARKNES',
        8455: 'STOKMARKNES',
        8459: 'MELBU',
        8465: 'STRAUMSJØEN',
        8469: 'BØ I VESTERÅLEN',
        8470: 'BØ I VESTERÅLEN',
        8475: 'STRAUMSJØEN',
        8480: 'ANDENES',
        8481: 'BLEIK',
        8483: 'ANDENES',
        8484: 'RISØYHAMN',
        8485: 'DVERBERG',
        8488: 'NØSS',
        8489: 'NORDMELA',
        8493: 'RISØYHAMN',
        8513: 'ANKENES',
        8520: 'ANKENES',
        8521: 'ANKENES',
        8522: 'BEISFJORD',
        8523: 'SKJOMEN',
        8530: 'BJERKVIK',
        8531: 'BJERKVIK',
        8533: 'BOGEN I OFOTEN',
        8534: 'LILAND',
        8535: 'TÅRSTAD',
        8536: 'EVENES',
        8539: 'BOGEN I OFOTEN',
        8540: 'BALLANGEN',
        8543: 'KJELDEBOTN',
        8546: 'BALLANGEN',
        8590: 'KJØPSVIK',
        8591: 'KJØPSVIK',
        8615: 'SKONSENG',
        8616: 'MO I RANA',
        8617: 'DALSGRENDA',
        8630: 'STORFORSHEI',
        8634: 'MO I RANA',
        8638: 'STORFORSHEI',
        8640: 'HEMNESBERGET',
        8641: 'HEMNESBERGET',
        8642: 'FINNEIDFJORD',
        8643: 'BJERKA',
        8644: 'BJERKA',
        8646: 'KORGEN',
        8647: 'BLEIKVASSLIA',
        8648: 'KORGEN',
        8672: 'ELSFJORD',
        8680: 'TROFORS',
        8681: 'TROFORS',
        8690: 'HATTFJELLDAL',
        8691: 'HATTFJELLDAL',
        8700: 'NESNA',
        8701: 'NESNA',
        8720: 'VIKHOLMEN',
        8723: 'HUSBY',
        8724: 'SAURA',
        8725: 'UTSKARPEN',
        8730: 'BRATLAND',
        8732: 'ALDRA',
        8733: 'STUVLAND',
        8735: 'STOKKVÅGEN',
        8740: 'NORD-SOLVÆR',
        8742: 'SELVÆR',
        8743: 'INDRE KVARØY',
        8750: 'TONNES',
        8752: 'KONSVIKOSEN',
        8753: 'KONSVIKOSEN',
        8754: 'ØRESVIK',
        8762: 'SLENESET',
        8764: 'LOVUND',
        8766: 'LURØY',
        8767: 'LURØY',
        8770: 'TRÆNA',
        8813: 'LØKTA',
        8820: 'DØNNA',
        8827: 'DØNNA',
        8830: 'VANDVE',
        8842: 'BRASØY',
        8844: 'SANDVÆR',
        8854: 'AUSTBØ',
        8860: 'TJØTTA',
        8861: 'TJØTTA',
        8865: 'TRO',
        8870: 'VISTHUS',
        8880: 'BÆRØYVÅGEN',
        8890: 'LEIRFJORD',
        8891: 'LEIRFJORD',
        8892: 'SUNDØY',
        8897: 'BARDAL',
        8960: 'VELFJORD',
        8961: 'VELFJORD',
        8976: 'VEVELSTAD',
        8977: 'VEVELSTAD',
        8980: 'VEGA',
        8981: 'VEGA',
        8985: 'YLVINGEN',
        9020: 'TROMSDALEN',
        9021: 'TROMSDALEN',
        9022: 'KROKELVDALEN',
        9023: 'KROKELVDALEN',
        9024: 'TOMASJORD',
        9027: 'RAMFJORDBOTN',
        9029: 'TROMSDALEN',
        9030: 'SJURSNES',
        9034: 'OLDERVIK',
        9037: 'TROMSØ',
        9038: 'TROMSØ',
        9040: 'NORDKJOSBOTN',
        9042: 'LAKSVATN',
        9043: 'JØVIK',
        9046: 'OTEREN',
        9049: 'NORDKJOSBOTN',
        9050: 'STORSTEINNES',
        9055: 'MEISTERVIK',
        9056: 'MORTENHALS',
        9057: 'VIKRAN',
        9059: 'STORSTEINNES',
        9060: 'LYNGSEIDET',
        9062: 'FURUFLATEN',
        9064: 'SVENSBY',
        9068: 'NORD-LENANGEN',
        9069: 'LYNGSEIDET',
        9106: 'STRAUMSBUKTA',
        9107: 'KVALØYA',
        9108: 'KVALØYA',
        9110: 'SOMMARØY',
        9118: 'BRENSHOLMEN',
        9119: 'SOMMARØY',
        9120: 'VENGSØY',
        9128: 'TUSSØY',
        9130: 'HANSNES',
        9131: 'KÅRVIK',
        9132: 'STAKKVIK',
        9134: 'HANSNES',
        9135: 'VANNVÅG',
        9136: 'VANNAREID',
        9137: 'VANNVÅG',
        9138: 'KARLSØY',
        9140: 'REBBENES',
        9141: 'MJØLVIK',
        9142: 'SKIBOTN',
        9143: 'SKIBOTN',
        9144: 'SAMUELSBERG',
        9145: 'SAMUELSBERG',
        9146: 'OLDERDALEN',
        9147: 'BIRTAVARRE',
        9148: 'OLDERDALEN',
        9149: 'BIRTAVARRE',
        9151: 'STORSLETT',
        9152: 'SØRKJOSEN',
        9153: 'ROTSUND',
        9154: 'STORSLETT',
        9155: 'SØRKJOSEN',
        9159: 'HAVNNES',
        9161: 'BURFJORD',
        9162: 'SØRSTRAUMEN',
        9163: 'JØKELFJORD',
        9169: 'BURFJORD',
        9170: 'LONGYEARBYEN',
        9171: 'LONGYEARBYEN',
        9173: 'NY-ÅLESUND',
        9174: 'HOPEN',
        9175: 'SVEAGRUVA',
        9176: 'BJØRNØYA',
        9178: 'BARENTSBURG',
        9180: 'SKJERVØY',
        9181: 'HAMNEIDET',
        9182: 'SEGLVIK',
        9184: 'REINFJORD',
        9185: 'SPILDRA',
        9186: 'ANDSNES',
        9187: 'VALANHAMN',
        9189: 'SKJERVØY',
        9190: 'AKKARVIK',
        9192: 'ARNØYHAMN',
        9193: 'NIKKEBY',
        9194: 'LAUKSLETTA',
        9195: 'ÅRVIKSAND',
        9197: 'ULØYBUKT',
        9300: 'FINNSNES',
        9302: 'ROSSFJORDSTRAUMEN',
        9303: 'SILSAND',
        9304: 'VANGSVIK',
        9310: 'SØRREISA',
        9311: 'BRØSTADBOTN',
        9315: 'SØRREISA',
        9316: 'BRØSTADBOTN',
        9321: 'MOEN',
        9322: 'KARLSTAD',
        9325: 'BARDUFOSS',
        9326: 'BARDUFOSS',
        9329: 'MOEN',
        9334: 'ØVERBYGD',
        9335: 'ØVERBYGD',
        9336: 'RUNDHAUG',
        9350: 'SJØVEGAN',
        9355: 'SJØVEGAN',
        9357: 'TENNEVOLL',
        9358: 'TENNEVOLL',
        9360: 'BARDU',
        9365: 'BARDU',
        9370: 'SILSAND',
        9372: 'GIBOSTAD',
        9373: 'BOTNHAMN',
        9376: 'SKATVIK',
        9379: 'GRYLLEFJORD',
        9380: 'GRYLLEFJORD',
        9381: 'TORSKEN',
        9382: 'GIBOSTAD',
        9384: 'SKALAND',
        9385: 'SKALAND',
        9386: 'SENJAHOPEN',
        9387: 'SENJAHOPEN',
        9388: 'FJORDGARD',
        9389: 'HUSØY I SENJA',
        9391: 'STONGLANDSEIDET',
        9392: 'STONGLANDSEIDET',
        9393: 'FLAKSTADVÅG',
        9395: 'KALDFARNES',
        9419: 'SØRVIK',
        9420: 'LUNDENES',
        9423: 'GRØTAVÆR',
        9424: 'KJØTTA',
        9425: 'SANDSØY',
        9426: 'BJARKØY',
        9427: 'MELØYVÆR',
        9430: 'SANDTORG',
        9436: 'KONGSVIK',
        9439: 'EVENSKJER',
        9440: 'EVENSKJER',
        9441: 'FJELLDAL',
        9442: 'RAMSUND',
        9443: 'MYKLEBOSTAD',
        9444: 'HOL I TJELDSUND',
        9445: 'TOVIK',
        9446: 'GROVFJORD',
        9447: 'GROVFJORD',
        9448: 'RAMSUND',
        9450: 'HAMNVIK',
        9451: 'HAMNVIK',
        9453: 'KRÅKRØHAMN',
        9454: 'ÅNSTAD',
        9455: 'ENGENES',
        9456: 'ENGENES',
        9470: 'GRATANGEN',
        9471: 'GRATANGEN',
        9475: 'BORKENES',
        9476: 'BORKENES',
        9519: 'KVIBY',
        9520: 'KAUTOKEINO',
        9521: 'KAUTOKEINO',
        9525: 'MAZE',
        9531: 'KVALFJORD',
        9532: 'HAKKSTABBEN',
        9533: 'KONGSHUS',
        9536: 'KORSFJORDEN',
        9540: 'TALVIK',
        9545: 'LANGFJORDBOTN',
        9550: 'ØKSFJORD',
        9580: 'BERGSFJORD',
        9582: 'NUVSVÅG',
        9583: 'LANGFJORDHAMN',
        9584: 'SØR-TVERRFJORD',
        9585: 'SANDLAND',
        9586: 'LOPPA',
        9587: 'SKAVNAKK',
        9590: 'HASVIK',
        9591: 'HASVIK',
        9593: 'BREIVIKBOTN',
        9595: 'SØRVÆR',
        9609: 'NORDRE SEILAND',
        9610: 'RYPEFJORD',
        9611: 'RYPEFJORD',
        9612: 'FORSØL',
        9615: 'HAMMERFEST',
        9616: 'HAMMERFEST',
        9620: 'KVALSUND',
        9621: 'KVALSUND',
        9624: 'REVSNESHAMN',
        9650: 'AKKARFJORD',
        9651: 'LANGSTRAND',
        9657: 'KÅRHAMN',
        9664: 'SANDØYBOTN',
        9670: 'TUFJORD',
        9672: 'INGØY',
        9690: 'HAVØYSUND',
        9691: 'HAVØYSUND',
        9692: 'MÅSØY',
        9700: 'LAKSELV',
        9709: 'PORSANGMOEN',
        9710: 'INDRE BILLEFJORD',
        9711: 'LAKSELV',
        9712: 'LAKSELV',
        9713: 'RUSSENES',
        9714: 'SNEFJORD',
        9715: 'KOKELV',
        9716: 'BØRSELV',
        9717: 'VEIDNESKLUBBEN',
        9722: 'SKOGANVARRE',
        9730: 'KARASJOK',
        9735: 'KARASJOK',
        9740: 'LEBESBY',
        9742: 'KUNES',
        9750: 'HONNINGSVÅG',
        9751: 'HONNINGSVÅG',
        9760: 'NORDVÅGEN',
        9763: 'SKARSVÅG',
        9764: 'NORDKAPP',
        9765: 'GJESVÆR',
        9768: 'REPVÅG',
        9770: 'MEHAMN',
        9771: 'SKJÅNES',
        9772: 'LANGFJORDNES',
        9773: 'NERVEI',
        9775: 'GAMVIK',
        9782: 'DYFJORD',
        9790: 'KJØLLEFJORD',
        9800: 'VADSØ',
        9811: 'VADSØ',
        9815: 'VADSØ',
        9820: 'VARANGERBOTN',
        9826: 'SIRMA',
        9840: 'VARANGERBOTN',
        9845: 'TANA',
        9846: 'TANA',
        9900: 'KIRKENES',
        9901: 'KIRKENES',
        9910: 'BJØRNEVATN',
        9911: 'JARFJORD',
        9912: 'HESSENG',
        9914: 'BJØRNEVATN',
        9915: 'KIRKENES',
        9916: 'HESSENG',
        9917: 'KIRKENES',
        9925: 'SVANVIK',
        9930: 'NEIDEN',
        9935: 'BUGØYNES',
        9950: 'VARDØ',
        9951: 'VARDØ',
        9960: 'KIBERG',
        9980: 'BERLEVÅG',
        9981: 'BERLEVÅG',
        9982: 'KONGSFJORD',
        9990: 'BÅTSFJORD',
        9991: 'BÅTSFJORD',
    }[E];
}
