import * as React from 'react';

import { PageLoader } from '@Component/PageLoader';
import { RouteData } from '@General/Router';
import { Team } from '@General/Team';

/**
 * The outside part of the bundle shear line.
 * The bundle split happens because of the <code>import('./SomePage')</code> call.
 * The PageLoader both sets up an error boundary (meaning a crash in the React code inside won't crash the page)
 * and handles showing a loader.
 *
 * @see PageLoader
 * @see renderComponent
 */
const Page: React.FC = (props) => (
    <PageLoader<{}>
        loader={async () => import('./ProjectOverviewPage').then((m) => m.page)}
        adjustWrapperDiv={true}
        props={props}
    />
);

export const route: RouteData = {
    component: Page,
    path: '/execute/projectOverviewPage',
    title: () => getMessage('heading_project_overview'),
    documentationComponent: 162,
    team: Team.SHEET_STORM,

    // We should limit who has access to this page.
    auth: () => true,
};
