import * as React from 'react';

import { Provider } from '@General/Provider';

import { Option } from '../types';

export type DisplayOptionProps<V = any> = {
    id: string | number | undefined | null;
    provider: Provider<V>;
    display?: (option: Option<V>) => React.ReactNode;
};

/**
 *  This class is used to display a value from a provider outside of the dropdown.
 */
export class DisplayOption<V = any> extends React.Component<
    DisplayOptionProps<V>
> {
    constructor(props: DisplayOptionProps<V>) {
        super(props);
        this.updateComponent = this.updateComponent.bind(this);
    }

    componentDidMount(): void {
        this.props.provider.registerListener(this.updateComponent);
    }

    componentWillUnmount(): void {
        this.props.provider.unregisterListener(this.updateComponent);
    }

    componentDidUpdate(prevProps: Readonly<DisplayOptionProps>): void {
        if (this.props.provider !== prevProps.provider) {
            prevProps.provider.unregisterListener(this.updateComponent);
            this.props.provider.registerListener(this.updateComponent);
        }
    }

    updateComponent() {
        this.forceUpdate();
    }

    render() {
        if (this.props.id === undefined || this.props.id === null) {
            return null;
        }
        const option = this.props.provider.getSingle(this.props.id);
        if (option) {
            return this.props.display
                ? this.props.display(option)
                : option.displayName;
        }
        return null;
    }
}
