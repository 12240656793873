import { ModelAPIV1 } from '@General/types/Api';
import { Option } from '../../types';
import { JSPDropdownFetcher } from '../JSPDropdownFetcher';
import { ApiResultMapperNoUndefinedResult } from '@General/Provider';

interface AmeldingTypeEntry extends ModelAPIV1 {
    readonly displayName: string;
}

const ameldingTypeMapper: ApiResultMapperNoUndefinedResult<AmeldingTypeEntry> =
    (item) => ({
        value: item.id + '',
        displayName: item.displayName,
        item,
    });

class AmeldingTypeFetcher extends JSPDropdownFetcher<AmeldingTypeEntry> {
    asyncJsonrpcGetter: Function;

    constructor(scope: HTMLDivElement, queryAttributes: Record<string, any>) {
        super(scope, queryAttributes, ameldingTypeMapper);
        this.asyncJsonrpcGetter = asyncrpc.AmeldingType.get;
    }

    getParams(): ObjectIndex {
        return {
            queryId: this.getQueryAttribute('queryId', -1),
            dateElementId:
                this.getQueryAttribute('dateElementId', false) || null,
        };
    }

    async fetchList(params: ObjectIndex): Promise<Option<AmeldingTypeEntry>[]> {
        return new Promise((resolve, reject) => {
            try {
                jsonrpc.AmeldingType.search(
                    (result: AmeldingTypeEntry[] | null) =>
                        resolve((result ?? []).map(ameldingTypeMapper)),
                    this.getMarshallSpec(),
                    params.queryId,
                    params.dateElementId,
                    params.name || ''
                );
            } catch (e) {
                defaultJsonRpcExceptionHandler(e);
                reject(e);
            }
        });
    }
}

export const exports = {
    fetcher: AmeldingTypeFetcher,
    type: 'AmeldingType',
};
