import React, { ReactNode } from 'react';
import {
    Alert,
    AlertContent,
    Button,
    Icon,
    Modal,
    ModalCloseButton,
    ModalLayout,
} from '@tlx/atlas';
import { CompanyVerificationType } from '@Page/CompanyVerificationFlow/Types';

export type CompanyVerificationFlowModalProps = {
    isOpen: boolean;
    onClose: () => void;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    children: ReactNode;
    companyVerificationStatus: CompanyVerificationType;
    userCanVerifyOrRequest: boolean;
};

export function CompanyVerificationFlowModal({
    children,
    setIsOpen,
    isOpen,
    companyVerificationStatus,
    userCanVerifyOrRequest,
    onClose,
}: CompanyVerificationFlowModalProps) {
    const companyVerified = companyVerificationStatus !== 'UNVERIFIED';

    let companyStatusElement;
    if (!userCanVerifyOrRequest || companyVerified) {
        companyStatusElement = (
            <VerificationStatus
                companyVerificationStatus={companyVerificationStatus}
            />
        );
    } else {
        companyStatusElement = (
            <Button
                id="open-company-verification-dialog-trigger"
                data-testid={'open-company-verification-dialog-trigger'}
                onClick={() => setIsOpen((prevState) => !prevState)}
                variant={'secondary'}
                data-trackingid={'kyc-open-company-verification-dialog'}
            >
                <Icon>report_problem</Icon>
                {getMessage('text_verify_company')}
            </Button>
        );
    }

    return (
        <>
            <div className="atl-mt-16">{companyStatusElement}</div>
            <Modal open={isOpen} onClose={onClose}>
                <ModalLayout>
                    <ModalCloseButton />
                    {children}
                </ModalLayout>
            </Modal>
        </>
    );
}

export function VerificationStatus({
    companyVerificationStatus,
}: {
    companyVerificationStatus: CompanyVerificationType;
}) {
    const textKey = getCompanyVerificationTextKey(companyVerificationStatus);

    return (
        <Alert
            variant={
                companyVerificationStatus === 'UNVERIFIED'
                    ? 'warning'
                    : 'success'
            }
        >
            <AlertContent>{getMessage(textKey)}</AlertContent>
        </Alert>
    );
}

function getCompanyVerificationTextKey(
    companyVerificationStatus: CompanyVerificationType
) {
    switch (companyVerificationStatus) {
        case 'VERIFIED_ACCOUNTANT':
            return 'text_company_verified_accountant';
        case 'VERIFIED_CLIENT_OF_ACCOUNTANT':
            return 'text_company_verified_client_of_accountant';
        case 'VERIFIED_CUSTOMER_BANKID':
            return 'text_company_verified_customer_bankid';
        case 'VERIFIED_BY_ALTINN_MESSAGE':
            return 'text_company_verified_altinn_message';
        case 'VERIFIED_BANK_AGREEMENT':
            return 'text_company_verified_by_autopay_bank_agreement';
        case 'VERIFIED_BY_TRIPLETEX_SUPPORT':
        case 'VERIFIED_TRIPLETEX':
            return 'text_company_verified_by_tripletex_support';
        default:
            return 'text_company_not_verified';
    }
}
