import { Button } from '@tlx/atlas';
import React from 'react';
import { EmptyState } from '@Page/LogisticsV2/components/EmptyState/EmptyState';

export function ProductDiscountPageLayout({
    children,
}: {
    children: React.ReactNode;
}) {
    return <div className="atl-flex atl-flex-col atl-gap-16">{children}</div>;
}

export function ProductDiscountEmptyState({ colSpan }: { colSpan: number }) {
    return (
        <tr>
            <td className="atl-bg-white" colSpan={colSpan}>
                <EmptyState
                    title={getMessage('text_no_discounts_found')}
                    className="atl-text-xl"
                    description={getMessage(
                        'text_no_discounts_found_description'
                    )}
                    svgIcon={'emptyDiscount'}
                >
                    <div>
                        <Button
                            data-testid="new-discount-button"
                            variant="secondary"
                            form={'triggerCreateDiscountModal'}
                            type={'submit'}
                        >
                            {getMessage('text_create_first_discount')}
                        </Button>
                    </div>
                </EmptyState>
            </td>
        </tr>
    );
}
