import { Button, Modal } from '@tlx/atlas';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { DistributionKey } from '@Page/DistributionKeys/models/DistributionKeys';
import { ApiAction } from '@Page/DistributionKeys/models/Actions';
import { useDistributionKeyDialog } from '@Page/DistributionKeys/hooks/useDistributionKeyDialog';
import { getMessage } from '../../../../../../js/modules/getMessage';
import { v4 as uuidv4 } from 'uuid';
import { DistributionKeysDialog } from '@Page/DistributionKeys/components/Dialog/DistributionKeysDialog';

type DistributionKeysDialogModalProps = {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    distributionKey?: DistributionKey;
    distributionKeys?: DistributionKey[];
    dispatch: (apiAction: ApiAction, distributionKey: DistributionKey) => void;
};

export const DistributionKeysDialogModal = ({
    open,
    setOpen,
    distributionKey,
    distributionKeys,
    dispatch,
}: DistributionKeysDialogModalProps) => {
    const [disableSave, setDisableSave] = useState(false);
    const { selectedKey, setSelectedKey, validationMessage } =
        useDistributionKeyDialog(distributionKeys, distributionKey);
    const [showValidation, setShowValidation] = useState(false);

    useEffect(() => {
        if (!validationMessage) {
            return;
        }
        const validationMessages = Object.values(validationMessage);
        if (validationMessages.length === 0 || !showValidation) {
            setDisableSave(false);
        } else {
            setDisableSave(true);
        }
    }, [validationMessage, showValidation]);

    const saveDistributionKey = () => {
        const key = selectedKey;
        const validationMessages = Object.values(validationMessage);
        if (!key || validationMessages.length > 0) {
            setShowValidation(true);
            return;
        }

        if (key.id) {
            dispatch('PUT', key);
        } else {
            dispatch('POST', key);
        }
        setSelectedKey({
            distributionKeyBlade: {
                distributionKeyBits: [
                    { tempId: uuidv4() },
                    { tempId: uuidv4() },
                ],
            },
        });
        setShowValidation(false);
        setOpen(false);
    };

    return (
        <Modal open={open}>
            <DistributionKeysDialog
                distributionKey={selectedKey}
                setDistributionKey={setSelectedKey}
                validationMessage={validationMessage}
                showValidation={showValidation}
            />
            <div className="atl-flex atl-justify-end atl-gap-8">
                <Button
                    data-testid="distribution-keys-create-update-key"
                    disabled={disableSave}
                    onClick={saveDistributionKey}
                >
                    {distributionKey?.id
                        ? getMessage('text_update')
                        : getMessage('button_create')}
                </Button>
                <Button
                    data-testid="distribution-keys-cancel-key"
                    variant="secondary"
                    onClick={() => {
                        setShowValidation(false);
                        setOpen(false);
                    }}
                >
                    {getMessage('button_cancel')}
                </Button>
            </div>
        </Modal>
    );
};
