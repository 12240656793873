import { ModelAPIV1 } from '@General/types/Api';
import { CreateNewButtonClickApi, Option } from '../../types';
import { notUndefined } from '@General/Helpers';
import { JSPDropdownFetcher } from '../JSPDropdownFetcher';
import { ApiResultMapper } from '@General/Provider';
import * as React from 'react';
import { Button } from '@tlx/atlas';
import { getLocaleMessage } from '../../../../../../js/modules/getMessage';

export interface VatTypeEntry extends ModelAPIV1 {
    readonly displayName: string;
}

const vatTypeMapper: ApiResultMapper<VatTypeEntry> = (item) => ({
    value: item.id + '',
    displayName: item.displayName,
    item,
});

export class VatTypeFetcher extends JSPDropdownFetcher<VatTypeEntry> {
    asyncJsonrpcGetter: Function;

    constructor(
        scope: HTMLDivElement,
        queryAttributes: Record<string, any>,
        mapper: ApiResultMapper<any>
    ) {
        super(scope, queryAttributes, mapper ?? vatTypeMapper);
        this.asyncJsonrpcGetter = asyncrpc.VatType.get;
    }

    getParams(): ObjectIndex {
        // We can provide a date as an attribute via _vatDate and use this as a default
        // when trying to fetch the value belonging to property defined in _vatDateElementIdProp
        const vatDatequeryAttributeAsDefault = this.getQueryAttribute(
            'vatDate',
            ''
        );
        return {
            vatDateStr: this.getQueryAttribute(
                'vatDateElementId',
                vatDatequeryAttributeAsDefault
            ),
            queryId: this.getQueryAttribute('queryId', -1),
            accountId: this.getQueryAttribute('accountElementId', -1),
            accountSubType: this.getQueryAttribute(
                'accountSubTypeElementId',
                -1
            ),
            shouldIncludeSpecificationTypes: this.getQueryAttribute(
                'shouldIncludeSpecificationTypes',
                false
            ),
        };
    }

    async fetchList(params: ObjectIndex): Promise<Option<VatTypeEntry>[]> {
        return new Promise((resolve, reject) => {
            try {
                return jsonrpc.VatType.searchForVatTypes(
                    (result: VatTypeEntry[] | null) => {
                        const mapped = (result ?? [])
                            .filter(notUndefined)
                            .map(this.getMapper(params))
                            .filter(notUndefined);
                        resolve(mapped);
                    },
                    this.getMarshallSpec(),
                    window.loginCompanyId,
                    params.queryId,
                    params.accountId,
                    params.accountSubType,
                    params.vatDateStr,
                    params.name || '',
                    params.shouldIncludeSpecificationTypes
                );
            } catch (e) {
                defaultJsonRpcExceptionHandler(e);
                reject(e);
            }
        });
    }

    // We've hooked into the createCreateNewButton to be allowed to display a button at the bottom of the dropdown.
    // Instead of returning a button, that opens an iframe and displays a form, we just link to a page.
    // But since we haven't found a better way into the dropdown to display it, we use this hook.
    createCreateNewButton(
        setSelected?: (id: number) => void,
        dropdownTrackingId?: string
    ):
        | React.ForwardRefExoticComponent<{
              closeDropdown: () => void;
          }>
        | undefined {
        // We don't display the button if user can't access the page
        if (!isAuth.accountingSettings) {
            return undefined;
        }

        // Button linking users to VAT settings
        return React.forwardRef<HTMLButtonElement, CreateNewButtonClickApi>(
            (props, ref) => (
                <Button
                    aria-label="Open VAT-settings in new tab"
                    variant="tertiary"
                    onClick={() => {
                        window.open(
                            `/execute/accountingSettingsMenu?contextId=${contextId}#vatTypes`,
                            '_blank'
                        );
                        props.closeDropdown();
                    }}
                    ref={ref}
                    data-testid={'testing'}
                    data-trackingid={
                        dropdownTrackingId
                            ? getLocaleMessage(
                                  'en_GB',
                                  'text_more_vat_settings'
                              ) +
                              ' - ' +
                              dropdownTrackingId
                            : ''
                    }
                >
                    {getMessage('text_more_vat_settings')}
                </Button>
            )
        );
    }

    /**
     * In all other models, id must be greater than 0, but not for VatType
     */
    validateId(id: string | number): boolean {
        return parseInt(id, 10) >= 0;
    }
}

export const exports = {
    fetcher: VatTypeFetcher,
    type: 'VatType',
};
