import { ModelAPIV1 } from '@General/types/Api';
import { notUndefined } from '@General/Helpers';
import { ApiResultMapper } from '@General/Provider';
import * as React from 'react';
import { createCreateNewButton } from '@Component/DropDown/CreateNewButton/CreateNewButton';
import { LedgerType } from '@Page/IncomingInvoice/type/IncomingInvoiceTypes';
import { JSPDropdownFetcher } from '../JSPDropdownFetcher';
import { CreateButtonComponent, Option } from '../../types';

interface CustomerEntry extends ModelAPIV1 {
    readonly customerNameAndNumber: string;
    readonly vendorNameAndNumber: string;
    readonly nameAndNumber: string;
    readonly address: string;
}

export enum CustomerType {
    CUSTOMER_VENDOR = 0,
    CUSTOMER = 1,
    VENDOR = 2,
}

const customerMapper: ApiResultMapper<CustomerEntry> = (item) => ({
    value: `${item.id}`,
    displayName:
        item.customerNameAndNumber ||
        item.vendorNameAndNumber ||
        item.nameAndNumber,
    secondary: item.address ? item.address.substring(0, 50) : '',
    item,
});

class CustomerFetcher extends JSPDropdownFetcher<CustomerEntry> {
    asyncJsonrpcGetter: Function;

    constructor(scope: HTMLDivElement, queryAttributes: Record<string, any>) {
        super(scope, queryAttributes, customerMapper);

        this.asyncJsonrpcGetter = asyncrpc.Company.get;
    }

    getMarshallSpec() {
        const customerType = parseInt(
            this.getQueryAttribute('customerType', -1),
            10
        );

        if (customerType === 1) {
            return window.marshallSpec(
                'id',
                'customerAccountNumber',
                'name',
                'customerNameAndNumber',
                'address',
                'customerCategoryId1',
                'customerCategoryId2',
                'customerCategoryId3'
            );
        } else if (customerType == 2) {
            return window.marshallSpec(
                'id',
                'vendorAccountNumber',
                'name',
                'vendorNameAndNumber',
                'address',
                'customerCategoryId1',
                'customerCategoryId2',
                'customerCategoryId3'
            );
        }

        return window.marshallSpec(
            'id',
            'customerAccountNumber',
            'vendorAccountNumber',
            'name',
            'nameAndNumber',
            'address',
            'customerCategoryId1',
            'customerCategoryId2',
            'customerCategoryId3'
        );
    }

    getParams(): ObjectIndex {
        let customerType = this.getQueryAttribute('customerType', 0);

        if (customerType == 0) {
            customerType = this.getQueryAttribute('customerTypeElementId', 0);
        }

        return {
            customerType,
            customerCategoryId1: this.getQueryAttribute(
                'customerCategoryId1',
                -1
            ),
            customerCategoryId2: this.getQueryAttribute(
                'customerCategoryId2',
                -1
            ),
            customerCategoryId3: this.getQueryAttribute(
                'customerCategoryId3',
                -1
            ),
            isActive: this.getQueryAttribute('isActive', 1),
            isTripletexAccount: this.getQueryAttribute('isTripletexAccount', 0),
            accountId: this.getQueryAttribute('accountElementId', -1),
            isMerchant: this.getQueryAttribute('isMerchant', -1),
        };
    }

    async fetchList(params: ObjectIndex): Promise<Option<CustomerEntry>[]> {
        return new Promise((resolve, reject) => {
            try {
                jsonrpc.Company.searchForCustomersAndVendors(
                    (result: CustomerEntry[] | null) => {
                        const mapped = (result ?? [])
                            .filter(notUndefined)
                            .map(this.getMapper(params))
                            .filter(notUndefined);
                        resolve(mapped);
                    },
                    this.getMarshallSpec(),
                    window.loginCompanyId,
                    params.customerType,
                    params.customerCategoryId1,
                    params.customerCategoryId2,
                    params.customerCategoryId3,
                    params.isActive,
                    params.name || '',
                    this.tlxSelectMaxRows,
                    0,
                    params.isTripletexAccount,
                    params.accountId,
                    params.isMerchant
                );
            } catch (e) {
                defaultJsonRpcExceptionHandler(e);
                reject(e);
            }
        });
    }

    createCreateNewButton(
        setSelected: (id: number) => void,
        dropdownTrackingId?: string
    ): CreateButtonComponent | undefined {
        if (!isAuth.createCustomer) {
            return undefined;
        }

        const dontShowPlusButton = this.getQueryAttribute(
            'dontShowPlusButton',
            false
        );

        if (dontShowPlusButton === 'true') {
            return undefined;
        }

        let customerType = this.getQueryAttribute('customerType', 0);

        if (customerType === 0) {
            customerType = this.getQueryAttribute('customerTypeElementId', 0);
        }

        let url = 'customer?showCreditScoring=false';

        if (customerType > 0) {
            url += `&customerType=${customerType}&limitCustomerType=true`;
        }

        return createCreateNewButton(
            setSelected,
            'submenu_new_customer_supplier',
            url,
            dropdownTrackingId
                ? getLocaleMessage('en_GB', 'submenu_new_customer_supplier') +
                      ' - ' +
                      dropdownTrackingId
                : ''
        );
    }

    createLink() {
        const ledgerType = this.getQueryAttribute(
            'ledgerType',
            LedgerType.GENERAL
        );
        let customerType = this.getQueryAttribute(
            'customerType',
            CustomerType.CUSTOMER_VENDOR
        );

        if (customerType === CustomerType.CUSTOMER_VENDOR) {
            customerType = this.getQueryAttribute(
                'customerTypeElementId',
                CustomerType.CUSTOMER_VENDOR
            );
        }

        /**
         * If ledgerType is set, and the ledgerType is the same as customerType, and the user has access to accountCurrentBook
         * let the link go to this accountCurrentBook with correct filters set. I.e. wathc the Purchases ledger for the
         * selected customer/vendor instead of the customer/vendor details.
         *
         */
        return (id: number, contextId: number) => {
            if (
                customerType === CustomerType.CUSTOMER &&
                ledgerType === LedgerType.CUSTOMER &&
                isAuth.accountCurrentBookCustomerForm
            ) {
                const accountId = this.getQueryAttribute(
                    'accountElementId',
                    -1
                );
                return `/execute/accountCurrentBookCustomer?customerId=${id}&currentAccountBookId=${accountId}&contextId=${contextId}`;
            }

            if (
                customerType === CustomerType.VENDOR &&
                ledgerType == LedgerType.VENDOR &&
                isAuth.accountCurrentBookVendorForm
            ) {
                const accountId = this.getQueryAttribute(
                    'accountElementId',
                    -1
                );
                return `/execute/accountCurrentBookVendor?vendorId=${id}&currentAccountBookId=${accountId}&contextId=${contextId}`;
            }

            return `/execute/customerMenu?customerId=${id}&contextId=${contextId}`;
        };
    }
}

export const exports = {
    fetcher: CustomerFetcher,
    type: 'Customer',
};
