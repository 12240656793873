import jQuery from 'jquery';

export const dev = (function ($) {
    function debugMessage(text, newLine) {
        let $debug = $('#tlxDebug');
        if ($debug.length == 0) {
            $debug = $("<div id='tlxDebug'></div>").appendTo('body');
        }
        text = document.createTextNode(text);
        if (newLine) {
            $debug.append('<br>');
        }
        $debug.append(text);
    }

    function debugLine(text) {
        debugMessage(text, true);
    }

    function consoleLog() {
        if (
            typeof productionMode === 'undefined' ||
            productionMode ||
            typeof console === 'undefined' ||
            !console ||
            !console.log ||
            !console.log.apply
        ) {
            return;
        }
        // eslint-disable-next-line prefer-spread,prefer-rest-params
        console.log.apply(console, arguments);
    }

    return {
        // debugMessage: debugMessage, Haven't found code that uses this function
        debugLine: debugLine,
        consoleLog: consoleLog,
    };
})(jQuery);
