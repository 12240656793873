import * as React from 'react';

import { Command, CommandHandler, KeyboardCombination } from './Command.type';
import { commandCenter } from './GlobalCommand';

/**
 * Bind a {@link KeyboardCombination} to a {@link Command} as a hook.
 *
 * @param {C} commandId                     The ID of the {@link Command} to bind the {@link KeyboardCombination} to.
 * @param {KeyboardCombination} combination The {@link KeyboardCombination} to bind.
 *
 * @see KeyboardCombination
 */
export function useKeyboardCombination<C extends string, T>(
    commandId: C,
    combination: KeyboardCombination
) {
    React.useEffect(() => {
        commandCenter.registerCombination(commandId, combination);
        return () => {
            commandCenter.deregisterCombination(commandId, combination);
        };
    }, [commandId, combination]);
}

/**
 * Register a {@link Command} as a hook.
 *
 * @param {Command<C, T>} command The {@link Command} to register.
 *
 * @see Command
 */
export function useCommand<C extends string, T>(command: Command<C, T>) {
    React.useEffect(() => {
        commandCenter.registerCommand(command);
        return () => {
            commandCenter.deregisterCommand(command);
        };
    }, [command]);
}

/**
 * Bind a {@link CommandHandler} to a {@link Command} as a hook.
 *
 * @param {C} commandId                  The ID of the {@link Command} to bind the {@link CommandHandler} to.
 * @param {CommandHandler<C, T>} handler The {@link CommandHandler} to bind.
 *
 * @see CommandHandler
 */
export function useCommandHandler<C extends string, T>(
    commandId: C,
    handler: CommandHandler<C, T>
) {
    React.useEffect(() => {
        commandCenter.registerHandler(commandId, handler);
        return () => {
            commandCenter.deregisterHandler(commandId, handler);
        };
    }, [commandId, handler]);
}
