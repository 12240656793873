import jQuery from 'jquery';
const $ = jQuery;

import { tlxUrl } from '../../modules/url';

$(function () {
    // Hide/show animations doesn't work anymore after upgrading jQuery/jQuery UI.
    function show($submenu) {
        // Show without animation first, to get the actual height
        $submenu.css('height', 'auto').show();
        const height = $submenu.outerHeight();

        // We start from 0 and goes to height in pixels, because animating height and width with css
        // requires a specific height or width to bet set. Going via requestAnimationFrame ensures that
        // the box is actually rendered with a height of 0px before change/animation starts.
        $submenu.css('height', '0px');

        requestAnimationFrame(function () {
            $submenu.show().css('height', height + 'px');
        });

        setTimeout(function () {
            // After animation, make room for sub submenus.
            $submenu.css('height', 'auto');
        }, 300);
    }

    function hide($submenu) {
        // Same philosophy applies here as when showing the box.
        $submenu.css('height', $submenu.outerHeight());

        requestAnimationFrame(function () {
            $submenu.css('height', '0px');
        });

        setTimeout(function () {
            $submenu.hide().css('height', 'auto');
        }, 300);
    }

    // Used for click handling open/close menu
    const $menuButtons = $('.tmdl-navigation__main-menu');

    // Used for click handling open/close, but must first check that click is not inside the actual sub-menu
    const $subMenus = $('.menu-entry.sub-menu');

    /**
     * Open and hide main accordions.
     */
    $menuButtons.on('click', function () {
        if ($(this).parent().is('.open')) {
            $(this).parent().removeClass('open');
            hide($(this).next());
        } else {
            //
            $('.navigateMenu.active').removeClass('active');
            hide(
                $('.mdl-navigation__link.open')
                    .removeClass('open')
                    .children('.tmdl-navigation__submenu')
            );
            hide(
                $('.sub-menu--open')
                    .removeClass('sub-menu--open')
                    .find('.menu-items')
            );
            $(this).parent().addClass('open');
            show($(this).next());
        }
    });

    /**
     * Open and hide sub accordions.
     */
    $subMenus.on('click', function (e) {
        if ($(this).is('.sub-menu--open')) {
            if ($(e.target).closest('.menu-items').length === 1) {
                return;
            }
            $(this).removeClass('sub-menu--open');
            hide($(this).find('.menu-items'));
        } else {
            hide(
                $('.sub-menu--open')
                    .removeClass('sub-menu--open')
                    .find('.menu-items')
            );
            $(this).addClass('sub-menu--open');
            show($(this).find('.menu-items'));
        }
    });

    /**
     * Highlight active link.
     */
    $('.tmdl-nav-mainmenu .navigateMenu').on('click', function (e) {
        if (e.ctrlKey || e.metaKey) {
            return;
        }
        $('.navigateMenu.active').removeClass('active');
        $(e.target).addClass('active');
    });

    /**
     * Open correct accordion(s) and highlight active link.
     */
    $(window).on('tlxLoadPageContentDone', function () {
        const path =
            window.location.pathname +
            tlxUrl.removeUrlParameter(window.location.search, 'narrowScreen');
        const $mainMenu = $('.tmdl-navigation__accordion');
        const $activeMenuItem = $mainMenu.find(
            '.navigateMenu[href|="' + path + '"]'
        );

        $activeMenuItem.addClass('active');

        // The menu item can be within in several accordions. If one of them are visible/open, don't bother with the rest.
        if ($activeMenuItem.is(':visible')) {
            return;
        }

        const $menu = $activeMenuItem
            .closest('.mdl-navigation__link')
            .addClass('open')
            .find('.tmdl-navigation__submenu')
            .filter(':hidden');
        if ($menu.length > 0) {
            show($menu);
        }

        const $sumMenu = $activeMenuItem
            .closest('.sub-menu')
            .addClass('sub-menu--open')
            .find('.menu-items')
            .filter(':hidden');
        if ($sumMenu.length > 0) {
            show($sumMenu);
        }
    });

    /**
     * Remove drawer menu when clicking on links (mobile).
     */
    $('body').on('click', '.navigateMenu', function () {
        document.dispatchEvent(new CustomEvent('sidebar:reset'));
    });
});
