import { BetaProgramObject } from '@General/types/BetaProgram.type';

let betaPrograms: BetaProgramObject[] | undefined = undefined;

export function getBetaPrograms(): BetaProgramObject[] {
    if (betaPrograms === undefined) {
        const data = document.getElementById(
            'redux-data-preload-BetaProgram'
        )?.textContent;
        betaPrograms = JSON.parse(data ?? '[]') as BetaProgramObject[];
    }
    return betaPrograms;
}
