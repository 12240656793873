import { ModelAPIV1 } from '@General/types/Api';
import { Option } from '../../types';
import { JSPDropdownFetcher } from '../JSPDropdownFetcher';
import { ApiResultMapperNoUndefinedResult } from '@General/Provider';

interface IbanOrBbanEntry extends ModelAPIV1 {
    readonly iban: string;
    readonly bban: string;
}

const ibanOrBbanMapper: ApiResultMapperNoUndefinedResult<IbanOrBbanEntry> = (
    item
) => {
    const number =
        item.iban != null && item.iban.length > 0 ? item.iban : item.bban;
    return {
        value: number + '',
        displayName: number + '',
        item,
    };
};

class IbanOrBbanFetcher extends JSPDropdownFetcher<IbanOrBbanEntry> {
    asyncJsonrpcGetter: Function;

    constructor(scope: HTMLDivElement, queryAttributes: Record<string, any>) {
        super(scope, queryAttributes, ibanOrBbanMapper);
        this.asyncJsonrpcGetter = this.get;
    }

    get(marshallSpec: any, id: string): Promise<any> {
        const result = { id, iban: id, bban: id };
        return Promise.resolve(result);
    }

    getMarshallSpec() {
        return window.marshallSpec('id', 'iban', 'bban');
    }

    getParams(): ObjectIndex {
        const vendorId = this.getQueryAttribute('vendorElementId', false);
        const customerId = this.getQueryAttribute('customerElementId', false);

        return {
            customerVendorId: vendorId || customerId || 0,
        };
    }

    async fetchList(params: ObjectIndex): Promise<Option<IbanOrBbanEntry>[]> {
        return new Promise((resolve, reject) => {
            try {
                return jsonrpc.CustomerVendorIbanOrBban.searchIbanOrBban(
                    (result: IbanOrBbanEntry[] | null) =>
                        resolve((result ?? []).map(ibanOrBbanMapper)),
                    this.getMarshallSpec(),
                    params.customerVendorId,
                    params.name || ''
                );
            } catch (e) {
                defaultJsonRpcExceptionHandler(e);
                reject(e);
            }
        });
    }

    /**
     * This seems to be used in the composite field where the user can input whatever he wants.
     * The id parameter represents the value that user has entered in the input field. It could be also be an empty string, because the JSP
     * validations seems to come after the validateId method call.
     * Also == was used instead of === because '' == 0 is true.
     *
     * @author Andrei Gavra
     * @date 21/07/2021
     */
    validateId(id: string | number): boolean {
        if (id == 0) {
            return true;
        }
        return !!id;
    }
}

export const exports = {
    fetcher: IbanOrBbanFetcher,
    type: 'IbanOrBban',
};
