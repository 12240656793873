/**
 * See Currency.java statics
 */
export const enum CurrencyEnum {
    NOK = 1,
    SEK = 2,
    DKK = 3,
    USD = 4,
    EUR = 5,
    GBP = 6,
    CHF = 7,
    JPY = 8,
    AUD = 9,
    CAD = 12,
    VND = 69,
}

export const enum CurrencyCode {
    NOK = 'NOK',
    SEK = 'SEK',
    USD = 'USD',
    EUR = 'EUR',
    GBP = 'GBP',
    CHF = 'CHF',
    JPY = 'JPY',
    AUD = 'AUD',
    CAD = 'CAD',
    VND = 'VND',
}
