import * as React from 'react';
import classNames from 'classnames';

import './MaterialIcon.scss';

export type MaterialIconProps = JSX.IntrinsicElements['i'] & {
    children: string;
};

export const MaterialIcon: React.FunctionComponent<MaterialIconProps> = (
    props
) => (
    <i
        {...props}
        className={classNames(
            'material-icons',
            'tlx-material-icon',
            props.className
        )}
    >
        {props.children}
    </i>
);
