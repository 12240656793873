import { ActionCreator } from '../types/Action';
import { Entitlement } from '../types/General';

const FetchEntitlementType = 'GlobalFetchEntitlement';
export const FetchEntitlement = ActionCreator<
    typeof FetchEntitlementType,
    {
        employeeId: number;
    }
>(FetchEntitlementType);
export type FetchEntitlement = ReturnType<typeof FetchEntitlement>;

const UpdateEntitlementType = 'GlobalUpdateEntitlement';
export const UpdateEntitlement = ActionCreator<
    typeof UpdateEntitlementType,
    {
        entitlements: Array<Entitlement>;
    }
>(UpdateEntitlementType);
export type UpdateEntitlement = ReturnType<typeof UpdateEntitlement>;
