import {
    EmployeeDTO,
    InventoryDTO,
    ResaleProductDTO,
    StocktakingDTO,
} from '@Page/LogisticsV2/types/dtoTypes';
import { PageUrl, SortingType } from '@Page/LogisticsV2/utils/enums';
import { getURL } from '@Page/LogisticsV2/utils/functions';
import { mapObjectToURLSearchParams } from '@Page/LogisticsV2/utils/mappers';
import { ChangeEventHandler, Dispatch, RefObject, SetStateAction } from 'react';
import { KeyedMutator } from 'swr';
import { ListResponse } from '@tlx/astro-shared';

export type ProductLineDTO = {
    id: number;
    stocktaking: StocktakingDTO;
    product: ResaleProductDTO;
    count: number;
    unitCostCurrency: number;
    costCurrency: number;
    counted: boolean;
    expectedStock: number;
    location: InventoryLocationDTO | undefined;
    counter: EmployeeDTO | undefined;
    dateCounted: string;
    comment: string;
};

export type InventoryLocationDTO = {
    id: number;
    inventory: InventoryDTO;
    number: number;
    name: string;
    displayName: string;
    isInactive: boolean;
    isDeletable: boolean;
};
export const STOCKTAKING_FIELDS =
    '*,product(id,number,elNumber,nrfNumber,ean,name,costExcludingVatCurrency, productUnit(nameShort,nameShortEN,nameEN)),expectedStock,location(*),counter(firstName,lastName),comment, counted';

export type LogisticsSettingsDTO = {
    id: number;
    hasWarehouseLocation: boolean;
    moduleSuggestedProductNumber?: boolean;
    suggestedProductNumber?: string;
    rackbeatAgreementNumber?: string;
};

export type StocktakingModel = {
    date: string;
    comment: string;
    inventoryId: number | undefined;
    typeOfStocktaking: string;
};

export interface Stocktaking {
    data: StocktakingDTO | undefined;
    error: any | undefined;
    onConfirm: () => Promise<void>;
    onDelete: () => Promise<void>;
    onEmptyWarehouse: () => Promise<void>;
    onChangeDate: (date: string) => Promise<void>;
    onRefresh: KeyedMutator<StocktakingDTO | undefined>;
}

export interface Barcode {
    inputRef: RefObject<HTMLInputElement>;
    isBarcodeMode: boolean;
    isLineCounted: boolean;
    scannedProductLines: ProductLineDTO[];
    scannedValue: string;
    onChangeScannedProductLines: Dispatch<SetStateAction<ProductLineDTO[]>>;
    onChangeScannedValue: Dispatch<SetStateAction<string>>;
    onScanLine: (ean: string) => Promise<void>;
    onToggleBarcodeMode: Dispatch<SetStateAction<boolean>>;
    onToggleLineCounted: Dispatch<SetStateAction<boolean>>;
}

export interface ProductLines {
    columns: string[];
    data: ProductLineDTO[];
    groupsFilter: string;
    inventoryLocations: InventoryLocationDTO[] | undefined;
    isExporting: boolean;
    isLoading: boolean;
    linesToDisplay: ProductLineDTO[];
    locationsFilter: string;
    statusFilter: string;
    sortingBy: SortingType;
    query: string;
    hasMore: boolean;
    loadMore: () => void;
    onChangeLine: (
        productLineId: number,
        count?: number,
        comment?: string
    ) => void;
    onChangeLinesToDisplay: Dispatch<SetStateAction<ProductLineDTO[]>>;
    onChangeLocation: (
        productLineId: number,
        warehouseLocationId: string
    ) => Promise<void>;
    onChangeSortingBy: Dispatch<SetStateAction<SortingType>>;
    onChangeStatusFilter: Dispatch<SetStateAction<string>>;
    onChangeQuery: Dispatch<SetStateAction<string>>;
    onCountLine: (value: number) => Promise<void>;
    onCreateLines: (
        selectedProducts: ResaleProductDTO[],
        stocktakingDetails: StocktakingDTO | undefined
    ) => Promise<void>;
    onExport: (warehouseName: string, warehouseDate: string) => Promise<void>;
    onFilterGroups: ChangeEventHandler<HTMLSelectElement>;
    onFilterLocations: ChangeEventHandler<HTMLSelectElement>;
    onRefresh: KeyedMutator<ListResponse<ProductLineDTO>[]>;
    onResetFilters: () => void;
    onUncountLine: (value: number) => Promise<void>;
}

export function getReportURL(warehouseId: string, date: string) {
    return (
        getURL(
            PageUrl.WAREHOUSE_REPORT,
            mapObjectToURLSearchParams({
                viewMode: '0',
                warehouseId: warehouseId,
                'period.startDate': date,
                'period.endOfPeriodDate': date,
                'period.periodType': 0,
                sourceType: 1,
                contextId: contextId,
            })
        ) ?? ''
    );
}

export function formatTimeStamp(timeStamp: string) {
    if (timeStamp) {
        return timeStamp.replace('T', ' ');
    }
    return;
}

export const LOCATION_NOT_CHOSEN = {
    id: '0',
    displayName: 'option_not_chosen',
};
export const ALL_PRODUCT_GROUPS = '-1';
export const PRODUCTS_WITHOUT_PRODUCT_GROUPS = '-2';

export const ALL_LOCATIONS = '-1';
export const EMPTY_LOCATION = '-2';

export const DEFAULT_COLUMNS_STOCKTAKING_DETAILS = 5;

export enum ProductLineStatus {
    ALL_PRODUCTS = 'ALL_PRODUCTS',
    COUNTED_PRODUCTS = 'COUNTED_PRODUCTS',
    NOT_COUNTED_PRODUCTS = 'NOT_COUNTED_PRODUCTS',
}

export function getStocktakingValue(productLine: ProductLineDTO) {
    if (productLine.counted) {
        return productLine.costCurrency === 0 ? 0 : -productLine.costCurrency;
    } else {
        return productLine.product.costExcludingVatCurrency * productLine.count;
    }
}

export function getDiscrepancySum(productLines: ProductLineDTO[]) {
    return productLines.reduce(
        (sum, productLine) =>
            sum + productLine.count - productLine.expectedStock,
        0
    );
}

export function getWarehouseValue(productLines: ProductLineDTO[]) {
    return productLines.reduce(
        (sum, productLine) => sum + getStocktakingValue(productLine),
        0
    );
}
