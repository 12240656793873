import jQuery from 'jquery';
const $ = jQuery;

/**
 * This widget makes an input field behave like a button. That is, it uses default/hover/active styles.
 * Used in periodselecter.
 */
$.widget('tlx.inputOpener', {
    // These options will be used as defaults
    options: {
        click: null,
        closeEvent: null,
        disabled: false,
        closeFunction: null,
        showIcon: true,
    },
    // Set up the widget
    _create: function () {
        if (this.options.showIcon) {
            if (this.element.is('.tlxCombo')) {
                this.icon = $('<i class="material-icons">more_vert</i>');
            } else {
                this.icon = $('<i class="material-icons">arrow_drop_down</i>');
            }

            this.icon.insertAfter(this.element);
        }

        this.openerElement = this.element;

        var self = this;
        // who has priority?
        if (self.element.prop('disabled') || self.options.disabled) {
            self.disable();
        }
        if (this.options.click) {
            var clickFunction = function (event) {
                if (self.options.disabled) {
                    return;
                }

                self.element.parent().addClass('open');

                self.element.addClass('ui-state-active');
                self.options.click.call(self.element, event);
                $(':tlx-inputOpener.ui-state-active')
                    .not(self.element)
                    .inputOpener('close');
            };

            if (self.options.showIcon) {
                this.icon.bind('click.inputOpener', clickFunction);

                this.icon
                    .bind('mouseenter.inputOpener', function () {
                        self.element.trigger('mouseenter');
                    })
                    .bind('mouseleave.inputOpener', function () {
                        if (self.element.hasClass('ui-state-active')) {
                            self.element.removeClass('ui-state-hover');
                            return false;
                        }
                        self.element.trigger('mouseleave');
                    });
            }

            this.openerElement
                .bind('mouseleave.inputOpener', function (event) {
                    if (self.element.hasClass('ui-state-active')) {
                        self.element.removeClass('ui-state-hover');
                        event.stopImmediatePropagation();
                    }
                })
                .addClass('tlx-input-opener')
                .bind('click.inputOpener', clickFunction);
        }
        this.element
            .bind(this.options.closeEvent, function () {
                self.element.parent().removeClass('open');

                self.element.removeClass('ui-state-active');
            })
            .bind('disableEvent.inputOpener', function (ev, disabled) {
                if (disabled) {
                    self.disable();
                } else {
                    self.enable();
                }
            });
    },

    close: function () {
        if (typeof this.options.closeFunction !== 'function') return;
        this.options.closeFunction();
    },

    _setOption: function (key, value) {
        this._super(key, value);
        switch (key) {
            case 'disabled':
                this.options.showIcon &&
                    this.icon.toggleClass('ui-state-disabled', value);
                break;
        }
    },

    // Use the destroy method to clean up any modifications your widget has made to the DOM
    _destroy: function () {
        this.element.removeClass('tlx-input-opener');
        this.element.unbind('.inputOpener');
        this.options.showIcon && this.icon.remove();
    },

    widget: function () {
        return this.element;
    },
});
