import { postNotificationMessage } from '@General/LegacyNotification';
import { postValidationMessages } from '@General/LegacyValidationPopup';
import { getTree } from '@Page/LogisticsV2/components/DataTree/tree';
import { invokeAPI, useFetchAll } from '@Page/LogisticsV2/hooks/api';
import {
    GroupRelationDTO,
    ProductGroupDTO,
} from '@Page/LogisticsV2/types/dtoTypes';
import { ApiUrl, MethodType } from '@Page/LogisticsV2/utils/enums';
import { getURL } from '@Page/LogisticsV2/utils/functions';
import {
    mapObjectToURLSearchParams,
    mapProductGroupDTOtoTreeData,
} from '@Page/LogisticsV2/utils/mappers';
import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import {
    buildRelationsToCreate,
    findRelationsToDelete,
    getProductDetailsPayload,
} from './utils';

export function useProductOverviewBulkActions(
    isSupplierProduct: boolean,
    selectedIds: Array<{ id: number }>,
    notDeletableIds: Array<{ id: number }>,
    onChange: () => void
) {
    const { data: productGroups } = useFetchAll<ProductGroupDTO[]>(
        moduleLogistics && selectedIds.length > 0
            ? ApiUrl.PRODUCT_GROUP
            : undefined,
        mapObjectToURLSearchParams({ fields: '*,parentGroup(*)' })
    );

    const [loadingBulkGroups, setLoadingBulkGroups] = useState(false);

    const relations = useRef<GroupRelationDTO[]>([]);

    useEffect(() => {
        let isApiSubscribed = true;
        if (moduleLogistics && selectedIds.length > 0) {
            const fetchData = async () => {
                const { data } = await invokeAPI(
                    `${ApiUrl.PRODUCT_GROUP_RELATIONS}/search?fields=*,product(id),productGroup(id)`,
                    MethodType.POST,
                    JSON.stringify({
                        resourceProductIds: selectedIds
                            .map((product) => product.id)
                            .join(','),
                    })
                );
                if (data) {
                    const dataJson = await data.json();
                    if (isApiSubscribed) {
                        relations.current = dataJson.values;
                    }
                }
            };
            fetchData();
        }
        return () => {
            isApiSubscribed = false;
        };
    }, [selectedIds]);

    const nodes = getTree(mapProductGroupDTOtoTreeData(productGroups ?? []));
    const [selectedNodes, setSelectedNodes] = useState<number[]>([]);

    const onChangeSelectedNodes = (nodeId: number) => {
        const newIds = selectedNodes.includes(nodeId)
            ? selectedNodes.filter((id) => id !== nodeId)
            : [...selectedNodes, nodeId];
        setSelectedNodes(newIds);
    };

    const handleBulkDelete = async () => {
        const uniqueValues = _.xorBy(selectedIds, notDeletableIds, 'id');
        if (uniqueValues.length === 0) {
            return;
        }
        const { error } = await invokeAPI(
            `${ApiUrl.PRODUCT_LIST}`,
            MethodType.DELETE,
            JSON.stringify(uniqueValues)
        );
        if (error !== undefined && error?.validationMessages?.length > 0) {
            postValidationMessages(
                getMessage('text_product') +
                    ' : ' +
                    error?.validationMessages[0]?.message
            );
        } else {
            postNotificationMessage(
                getMessage('text_product'),
                [
                    `${getMessage('text_action_completed')} ${getMessage(
                        'text_delete'
                    )}`,
                ],
                new Date()
            );
        }
        onChange();
    };

    const handleBulkInactivate = async () => {
        const inactiveIds: Array<{ id: number; isInactive: boolean }> =
            notDeletableIds.map((product) => {
                return { id: product.id, isInactive: true };
            });
        if (inactiveIds.length === 0) {
            return;
        }
        const { error } = await invokeAPI(
            ApiUrl.PRODUCT_BATCH_UPDATE_LIST,
            MethodType.PUT,
            JSON.stringify(inactiveIds)
        );
        if (error !== undefined) {
            postValidationMessages(
                getMessage('text_product') +
                    ' : ' +
                    error?.validationMessages[0]?.message
            );
        } else {
            postNotificationMessage(
                getMessage('text_product'),
                [
                    `${getMessage('text_action_completed')} ${getMessage(
                        'text_update'
                    )}`,
                ],
                new Date()
            );
        }
        onChange();
    };

    const onAddProductGroups = async () => {
        setLoadingBulkGroups(true);
        const relationsToCreate = buildRelationsToCreate(
            relations.current ?? [],
            selectedIds.map((product) => product.id),
            selectedNodes
        );
        if (relationsToCreate.length > 0) {
            await invokeAPI(
                ApiUrl.PRODUCT_GROUP_RELATIONS_LIST,
                MethodType.POST,
                JSON.stringify(relationsToCreate)
            );
        }
        setTimeout(() => setLoadingBulkGroups(false), 0);
        setSelectedNodes([]);
        onChange();
    };

    const onRemoveProductGroups = async () => {
        setLoadingBulkGroups(true);
        const relationsToDelete = findRelationsToDelete(
            relations.current ?? [],
            selectedIds.map((product) => product.id),
            selectedNodes
        ).map((relation) => relation.id);
        if (relationsToDelete.length > 0) {
            await invokeAPI(
                getURL(ApiUrl.PRODUCT_GROUP_RELATIONS_LIST) ?? '',
                MethodType.DELETE,
                JSON.stringify(relationsToDelete)
            );
        }
        setTimeout(() => setLoadingBulkGroups(false), 0);
        setSelectedNodes([]);
        onChange();
    };

    const onChangeProductDetails = async (
        formElement: HTMLFormElement,
        values: { [k: string]: FormDataEntryValue }
    ) => {
        const payload = getProductDetailsPayload(selectedIds, values);

        const { error } = await invokeAPI(
            isSupplierProduct
                ? `${ApiUrl.SUPPLIER_CONNECTION}/batchUpdateList`
                : `${ApiUrl.PRODUCT}/batchUpdateList`,
            MethodType.PUT,
            JSON.stringify(payload)
        );
        if (error !== undefined) {
            postValidationMessages(
                getMessage('text_product') +
                    ' : ' +
                    error?.validationMessages[0]?.message
            );
        } else {
            postNotificationMessage(
                getMessage('text_product'),
                [
                    `${getMessage('text_action_completed')} ${getMessage(
                        'text_update'
                    )}`,
                ],
                new Date()
            );
        }
        onChange();
    };

    // temporary solution for rackbeat customers
    const onChangeProductDetailsRackbeat = async (
        formElement: HTMLFormElement,
        values: { [k: string]: FormDataEntryValue }
    ) => {
        const payload = selectedIds.map((product) => {
            return {
                id: product.id,
                ...(values['unitValue'] !== undefined
                    ? { productUnit: { id: values['unitValue'] } }
                    : {}),
            };
        });

        const { error } = await invokeAPI(
            `${ApiUrl.PRODUCT}/:updateUnit`,
            MethodType.PUT,
            JSON.stringify(payload)
        );
        if (error !== undefined) {
            postValidationMessages(
                getMessage('text_product') + ' : ' + error?.message
            );
        } else {
            postNotificationMessage(
                getMessage('text_product'),
                [
                    `${getMessage('text_action_completed')} ${getMessage(
                        'text_update'
                    )}`,
                ],
                new Date()
            );
            onChange();
        }
    };

    return {
        nodes: nodes ?? [],
        selectedNodes,
        loadingBulkGroups,
        onAddProductGroups,
        onBulkDelete: handleBulkDelete,
        onBulkInactivate: handleBulkInactivate,
        onChangeSelectedNodes,
        onChangeProductDetails,
        onChangeProductDetailsRackbeat,
        onRemoveProductGroups,
        onResetSelectedNodes: setSelectedNodes,
    };
}
