export type ProductImportType = {
    id: number;
    importFile: { id: number; url: string; fileName: string };
    productImportHeaders: ProductImportHeaderType[];
    separator: string;
    status: string;
    supplier: string | null;
    startDate: string;
    countCreated: number;
    countUpdated: number;
    countIgnored: number;
    employee: { id: number; displayName: string; pictureId: number };
};

export type ProductImportHeaderType = {
    id: number;
    productImport: { id: number; url: string };
    name: string;
    sampleValue: string;
    orderOfColumn: number;
    suggestedColumnField: ProductImportSuggestedMappingType | null;
};

export type ProductImportSuggestedMappingType = {
    id: number;
    format: string;
    isMandatory: boolean;
    displayName: string;
    sequence?: number;
    fieldName: string;
};

export type NameToMappingType = {
    [name: string]: { headerId: number; id: number; mandatory: boolean };
};

export type ProductPotentialType = {
    id: number;
    productImport: ProductImportType;
    rowContent: ProductRowJSONType;
    errorMessage: ProductImportRowErrorMessage[];
    rowStatus: string;
};

export type ProductRowJSONType = {
    name: string;
    number: string;
    vatTypeId: string;
    isStockItem: string;
    productUnit: string;
    isInactive: string;
    eanGtin: string;
    purchasePrice: string;
    sellingPriceExclVat: string;
    sellingPriceInclVat: string;
    rowNumber: number;
    currencyCode: string;
    departmentNumber: string;
    incomeAccountNumber: string;
};

export type ProductImportHeaderFieldsRelationType = {
    field: ProductImportSuggestedMappingType;
};

export type ProductImportRowErrorMessage = {
    fieldId: number;
    fieldName: string;
    errorMessage: string;
};

export const step = {
    FILE: 'UPLOAD_FILE',
    MAPPING: 'FILE_UPLOADED',
    VALIDATE: 'FIELDS_MAPPED',
    RESULT: 'IMPORT_COMPLETED',
};

export const rowStatus = {
    NEW: 'NEW',
    FAIL: 'FAIL',
    UPDATE: 'UPDATE',
};
export const WEIGHT_FIELD = 16;
export const WEIGHT_UNIT_FIELD = 17;
export const VOLUME_FIELD = 18;
export const VOLUME_UNIT_FIELD = 19;
