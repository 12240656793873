import { ModelAPIV1 } from '@General/types/Api';
import { Option } from '../../types';
import { JSPDropdownFetcher } from '../JSPDropdownFetcher';
import { ApiResultMapperNoUndefinedResult } from '@General/Provider';
import * as React from 'react';

interface MunicipalityEntry extends ModelAPIV1 {
    readonly displayName: string;
}

const municipalityMapper: ApiResultMapperNoUndefinedResult<MunicipalityEntry> =
    (item) => ({
        value: item.id + '',
        displayName: item.displayName,
        item,
    });

class MunicipalityFetcher extends JSPDropdownFetcher<MunicipalityEntry> {
    asyncJsonrpcGetter: Function;

    constructor(scope: HTMLDivElement, queryAttributes: Record<string, any>) {
        super(scope, queryAttributes, municipalityMapper);
        this.asyncJsonrpcGetter = asyncrpc.Municipality.get;
    }

    getParams(): ObjectIndex {
        return {};
    }

    async fetchList(params: ObjectIndex): Promise<Option<MunicipalityEntry>[]> {
        return new Promise((resolve, reject) => {
            try {
                return jsonrpc.Municipality.searchForMunicipalities(
                    (result: MunicipalityEntry[] | null) =>
                        resolve((result ?? []).map(municipalityMapper)),
                    this.getMarshallSpec(),
                    params.name || ''
                );
            } catch (e) {
                defaultJsonRpcExceptionHandler(e);
                reject(e);
            }
        });
    }
}

export const exports = {
    fetcher: MunicipalityFetcher,
    type: 'Municipality',
};
