import { FieldError } from 'react-hook-form';
import * as React from 'react';
import { Markdown } from '@Component/Markdown';

export function ValidationError({ error }: { error?: FieldError }) {
    if (error === undefined) {
        return null;
    }

    return (
        <div className="atl-text-red-100 atl-text-sm">
            <Markdown text={error.message || error.type} />
        </div>
    );
}
