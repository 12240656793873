import { compile } from 'moo';

import { Associativity, OperatorDescription } from './Expression.type';

const validOperators: Array<OperatorDescription> = [
    {
        operator: '^',
        precedence: 4,
        associativity: Associativity.Right,
        apply: (a, b) => Math.pow(a, b),
    },
    {
        operator: '%',
        precedence: 3,
        associativity: Associativity.Right,
        apply: (a, b) => a % b,
    },
    {
        operator: '*',
        precedence: 3,
        associativity: Associativity.Left,
        apply: (a, b) => a * b,
    },
    {
        operator: '/',
        precedence: 3,
        associativity: Associativity.Left,
        apply: (a, b) => a / b,
    },
    {
        operator: '+',
        precedence: 2,
        associativity: Associativity.Left,
        apply: (a, b) => a + b,
    },
    {
        operator: '-',
        precedence: 2,
        associativity: Associativity.Left,
        apply: (a, b) => a - b,
    },
    {
        operator: '×',
        precedence: 3,
        associativity: Associativity.Left,
        apply: (a, b) => a * b,
    },
    {
        operator: '÷',
        precedence: 3,
        associativity: Associativity.Left,
        apply: (a, b) => a / b,
    },
    {
        operator: '−',
        precedence: 2,
        associativity: Associativity.Left,
        apply: (a, b) => a - b,
    },
];

const operatorLookup: {
    [key: string]: OperatorDescription;
} = validOperators.reduce(
    (a: { [key: string]: OperatorDescription }, b) => ({
        ...a,
        [b.operator]: b,
    }),
    {}
);

const allOperatorsRegex = `${validOperators.reduce(
    (a: string, b) => a.concat(`\\${b.operator}`),
    '\\(\\)'
)}`;

const singleNegativeNumber = /^-\d+(?:[.,]\d+)?$/;
const mathOperations = new RegExp(`[${allOperatorsRegex}]`);

export function isExpression(text: string): boolean {
    return mathOperations.test(text) && !singleNegativeNumber.test(text);
}

export function getOperator(
    key: string | number
): OperatorDescription | undefined {
    return operatorLookup[key];
}

export function createLexer() {
    // TODO consolidate operator list with `validOperators`?
    return compile({
        number: /(?:0|(?:[1-9][0-9]*))(?:\.[0-9]+)?/u,
        leftRoundBracket: '(',
        rightRoundBracket: ')',
        power: '^',
        modulo: '%',
        multiplier: /[*×]/u,
        divider: /[/÷]/u,
        plus: '+',
        minus: /[-−]/u,
    });
}
