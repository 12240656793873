import { useFetchPaginated } from '../../hooks/fetch/useFetchPaginated';
import { useFetchSingle } from '../../hooks/fetch/useFetchSingle';

export type ParamsType = {
    [key: string]: number | string | boolean | undefined;
};

export type URLBuilderParams = {
    url: string;
    meta?: {
        fields?: string[];
        query?: string;
        from?: number;
        count?: number;
    };
    params?: ParamsType;
};

export function useFetchOne<Model>(
    url: string,
    id: number | string | undefined,
    fields: string[] = ['id']
) {
    const searchParams = new URLSearchParams();
    searchParams.set('fields', [...fields].sort().join(','));
    const urlAndQuery =
        id !== undefined ? `${url}/${id}?${searchParams.toString()}` : null;
    return useFetchSingle<Model>(urlAndQuery);
}

export function useFetchMany<Params extends ParamsType, Model>(
    url: string,
    params?: Params,
    query?: string,
    fields: string[] = ['id'],
    pageSize = 25,
    lazy = true
) {
    function getKey(pageIndex: number): string | null {
        return buildUrl({
            url,
            meta: {
                query,
                fields,
                count: pageSize,
                from: pageSize * pageIndex,
            },
            params,
        });
    }

    return useFetchPaginated(getKey, lazy);
}

function buildUrl({ url, meta, params }: URLBuilderParams): string {
    const searchParams = new URLSearchParams();

    if (meta !== undefined) {
        if (meta.fields !== undefined) {
            searchParams.set('fields', [...meta.fields].sort().join(','));
        }
        if (meta.query !== undefined) {
            searchParams.set('query', meta.query);
        }

        if (meta.count !== undefined) {
            searchParams.set('count', meta.count.toString());
        }

        if (meta.from !== undefined) {
            searchParams.set('from', meta.from.toString());
        }
    }

    if (params !== undefined) {
        for (const key in params) {
            const value = params[key];
            if (value !== undefined) {
                searchParams.set(key, value.toString());
            }
        }
    }

    searchParams.sort();
    return `${url}?${searchParams.toString()}`;
}
