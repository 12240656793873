import * as React from 'react';

import { PageError } from './PageError';
import { captureException } from '@sentry/browser';

export interface ErrorBoundaryProps {
    readonly format?: 'page' | 'inline' | 'invisible';
    readonly children: React.ReactNode;
}

type ErrorBoundaryState = {
    hasErrored: boolean;
    error?: Error;
    errorInfo?: React.ErrorInfo;
};

export class ErrorBoundary extends React.Component<
    ErrorBoundaryProps,
    ErrorBoundaryState
> {
    constructor(props: ErrorBoundaryProps) {
        super(props);

        this.state = {
            hasErrored: false,
        };
    }

    static getDerivedStateFromError(): Pick<
        ErrorBoundaryState,
        keyof ErrorBoundaryState
    > {
        return {
            hasErrored: true,
        };
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
        captureException(error, {
            extra: {
                errorInfo,
                format: this.props.format,
            },
        });
        console.error(
            'ErrorBoundary caught an exception\nError:\n',
            error,
            '\nReact.ErrorInfo:\n',
            errorInfo
        );
        this.setState({
            hasErrored: true,
            error,
            errorInfo,
        });
    }

    render() {
        if (this.state.hasErrored) {
            if ((this.props.format ?? 'page') === 'page') {
                return (
                    <PageError
                        error={this.state.error}
                        errorInfo={this.state.errorInfo}
                    />
                );
            } else {
                return null;
            }
        } else {
            return this.props.children;
        }
    }
}
