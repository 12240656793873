import React from 'react';
import { SortableTableHeader } from '@tlx/atlas';
import useSessionStorage from '@Page/LogisticsV2/hooks/useSessionStorage';
import { SortingType } from '@Page/LogisticsV2/utils/enums';
import {
    getSortDirection,
    getSorting,
} from '@Page/LogisticsV2/utils/functions';

export function InventoryCorrectionTableHeader({
    columns,
}: {
    columns: string[];
}): JSX.Element {
    const [sorting, setSorting] = useSessionStorage<SortingType>(
        'goldshark_inventory_correction_overview_sorting',
        SortingType.DATE_DESCENDING
    );
    return (
        <thead className="atl-thead atl-thead--sticky">
            <tr className="atl-tr">
                <SortableTableHeader
                    data-testid="inventory-correction-ovevriew-number-header"
                    key="numberHeader"
                    className="atl-th"
                    sortDirection={getSortDirection(
                        sorting,
                        SortingType.NUMBER_ASCENDING,
                        SortingType.NUMBER_DESCENDING
                    )}
                    onClick={() =>
                        setSorting(
                            getSorting(
                                sorting,
                                SortingType.NUMBER_ASCENDING,
                                SortingType.NUMBER_DESCENDING
                            )
                        )
                    }
                    style={{ width: '200px' }}
                >
                    {getMessage('text_number')}
                </SortableTableHeader>
                {columns.map((column) => (
                    <InventoryCorrectionHeaderCell
                        key={column}
                        column={column}
                        sorting={sorting}
                        onSortingChange={setSorting}
                    />
                ))}
            </tr>
        </thead>
    );
}

function InventoryCorrectionHeaderCell({
    column,
    sorting,
    onSortingChange,
}: {
    column: string;
    sorting: SortingType;
    onSortingChange: (sorting: SortingType) => void;
}): JSX.Element {
    switch (column) {
        case 'date':
            return (
                <DateHeaderCell
                    sorting={sorting}
                    onSortingChange={onSortingChange}
                />
            );
        case 'fromWarehouse':
            return <FromWarehouseHeaderCell />;
        case 'toWarehouse':
            return <ToWarehouseHeaderCell />;
        case 'comment':
            return <CommentHeaderCell />;
        default:
            throw new Error(`Unknown setting: ${column}`);
    }
}

function DateHeaderCell({
    sorting,
    onSortingChange,
}: {
    sorting: SortingType;
    onSortingChange: (sorting: SortingType) => void;
}) {
    return (
        <SortableTableHeader
            data-testid={'inventory-correction-ovevriew-date-header'}
            key="dateHeader"
            className="atl-th"
            sortDirection={getSortDirection(
                sorting,
                SortingType.DATE_ASCENDING,
                SortingType.DATE_DESCENDING
            )}
            onClick={() =>
                onSortingChange(
                    getSorting(
                        sorting,
                        SortingType.DATE_ASCENDING,
                        SortingType.DATE_DESCENDING
                    )
                )
            }
            style={{ width: '200px' }}
        >
            {getMessage('text_date')}
        </SortableTableHeader>
    );
}

function FromWarehouseHeaderCell() {
    return (
        <th key="fromWarehouseHeader" className="atl-th">
            {getMessage('text_warehouse_from')}
        </th>
    );
}

function ToWarehouseHeaderCell() {
    return (
        <th key="toWarehouseHeader" className="atl-th">
            {getMessage('text_warehouse_to')}
        </th>
    );
}

function CommentHeaderCell() {
    return (
        <th key="commentHeader" className="atl-th">
            {getMessage('text_comment')}
        </th>
    );
}
