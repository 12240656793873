import { SignatureStatus, Signer } from '@Page/CompanyVerificationFlow/Types';
import { SignerDetails } from '@Page/CompanyVerificationFlow/SendSigningRequestForm/SignerCombination/SignerList/SignerDetails';
import {
    ApprovedIcon,
    Button,
    CloseIcon,
    Input,
    ReminderIcon,
    Spinner,
    Tooltip,
    TooltipContent,
    TooltipTrigger,
    EditIcon,
} from '@tlx/atlas';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { EmailContent } from '@Page/CompanyVerificationFlow/SendSigningRequestForm/SignerCombination/SignerList/EmailContent';
import { SendSigningRequestFormValues } from '@Page/CompanyVerificationFlow/SendSigningRequestForm/SendSigningRequestForm';
import { useRequestEmailReminder } from '@Page/CompanyVerificationFlow/companyVerificationAPI';

export type SignerRowProps = {
    signer: Signer;
    thisIsMeToggled: boolean;
    isEditMode: boolean;
    setIsEditMode: React.Dispatch<React.SetStateAction<boolean>>;
    userSignatureStatus: SignatureStatus;
};

export type SendReminderButtonTypes = {
    handleSendReminder: () => Promise<void>;
    isSendingRequest: boolean;
    reminderSent: boolean;
    canReceiveReminder: boolean;
};

export function SendReminderButton({
    handleSendReminder,
    isSendingRequest,
    reminderSent,
    canReceiveReminder,
}: SendReminderButtonTypes) {
    const showPendingIcon = isSendingRequest && !reminderSent;
    const showSendReminderIcon = !showPendingIcon && !reminderSent;
    const isDisabled = reminderSent || !canReceiveReminder;

    return (
        <Tooltip>
            <TooltipTrigger>
                <div
                    role="button"
                    aria-disabled={isDisabled ? 'true' : 'false'}
                >
                    <Button
                        variant="icon"
                        aria-label={getMessage('text_send')}
                        data-testid={'btn-send-reminder'}
                        onClick={handleSendReminder}
                        disabled={isDisabled}
                    >
                        <>
                            {showSendReminderIcon && <ReminderIcon />}
                            {showPendingIcon && <Spinner size="small" />}
                            {!showSendReminderIcon && !showPendingIcon && (
                                <ApprovedIcon />
                            )}
                        </>
                    </Button>
                </div>
            </TooltipTrigger>
            <TooltipContent>
                {!isDisabled && getMessage('text_send_email_reminder')}
                {isDisabled &&
                    getMessage(
                        'text_validation_below_minimum_hours_required_between_reminders',
                        24
                    )}
            </TooltipContent>
        </Tooltip>
    );
}

export function SignerRow({
    signer,
    thisIsMeToggled,
    isEditMode,
    setIsEditMode,
    userSignatureStatus,
}: SignerRowProps) {
    const { setValue, watch } = useFormContext<SendSigningRequestFormValues>();
    const { requestEmailReminder, error, isExecuting, isReminderSent } =
        useRequestEmailReminder(signer.id);

    const thisIsMeSigner = watch('thisIsMeSigner');
    const readonly = window.segmentationData.hackedOrSupportAccess;

    return (
        <tr className="atl-tr">
            <td className="atl-td">
                <SignerDetails
                    name={signer.name}
                    description={signer.description}
                />
            </td>
            <td className="atl-td atl-align-middle">
                <Input
                    type="toggle"
                    data-testid="submit-signing-button-sole-signer"
                    checked={thisIsMeToggled}
                    onChange={(event) =>
                        setValue(
                            `thisIsMeSigner`,
                            event.target.checked ? signer : undefined
                        )
                    }
                    style={{
                        visibility:
                            isEditMode && userSignatureStatus !== 'SIGNED'
                                ? 'visible'
                                : 'hidden',
                    }}
                    disabled={readonly}
                    className={'atl-flex atl-items-center'}
                />
            </td>
            <td className="atl-td atl-align-middle">
                <EmailContent
                    signer={signer}
                    thisIsMeToggled={thisIsMeToggled}
                    isEditMode={isEditMode}
                    disabled={readonly}
                    reminderError={error}
                    reminderSent={isReminderSent}
                />
            </td>
            <td className="atl-td atl-pr-24">
                {signer.signatureStatus === 'PENDING_SIGNATURE' && (
                    <div className="atl-gap-8 atl-flex">
                        <ToggleEditModeButton
                            disabled={readonly}
                            isEditMode={isEditMode}
                            onClickEditMode={() => {
                                //Reset email back to original.
                                if (isEditMode) {
                                    setValue(
                                        `signers.${signer.id}.email`,
                                        signer.email
                                    );

                                    if (signer.employeeId === loginEmployeeId) {
                                        setValue('thisIsMeSigner', signer);
                                    }
                                } else {
                                    if (thisIsMeSigner?.id === signer.id) {
                                        setValue('thisIsMeSigner', undefined);
                                    }
                                }

                                setIsEditMode((prev) => {
                                    return !prev;
                                });
                            }}
                        />
                        {!isEditMode && (
                            <SendReminderButton
                                isSendingRequest={isExecuting}
                                handleSendReminder={requestEmailReminder}
                                reminderSent={isReminderSent}
                                canReceiveReminder={signer.canReceiveReminder}
                            />
                        )}
                    </div>
                )}
            </td>
        </tr>
    );
}

function ToggleEditModeButton({
    isEditMode,
    onClickEditMode,
    disabled,
}: {
    disabled: boolean;
    isEditMode: boolean;
    onClickEditMode: () => void;
}) {
    return (
        <Button
            variant="icon"
            aria-label={getMessage(isEditMode ? 'text_close' : 'text_edit')}
            data-testid={`${
                !isEditMode ? 'btn-edit-email' : 'btn-cancel-edit-email'
            }`}
            onClick={onClickEditMode}
            disabled={disabled}
            className="atl-button atl-flex"
        >
            {!isEditMode ? <EditIcon /> : <CloseIcon />}
        </Button>
    );
}
