import {
    CommandResponderRegistry,
    KeyboardCombination,
} from '@General/Command';

const isMac = navigator.platform.match(/(Mac)/i);

export const SaveCommandId = 'SaveCommand';

const SaveCommand = {
    id: SaveCommandId,
};

const SaveCombination: KeyboardCombination = {
    key: 's',
    ctrl: isMac ? undefined : true,
    meta: isMac ? true : undefined,
};

export function registerSave(registry: CommandResponderRegistry) {
    registry.registerCommand(SaveCommand);
    registry.registerCombination(SaveCommandId, SaveCombination);
}
