import jQuery from 'jquery';
const $ = jQuery;

$(window).on(
    'tlxtabsload tlxLoadPageContentDone tlxtabsactivate',
    function (e) {
        const target = e.target;

        /**
         * Make sure tlx:viewOptionsDialog and primary tlx:sectionToolbarButtons are placed outside dropdown menu
         * in sectionToolbar. If mobile or sectionToolbar is set to be expanded, do not do anything, the custom tags
         * handles the rest (always dropdown on mobile, and always buttons when expanded)
         */
        $('.sectionToolbar', target).each(function () {
            if (window.narrowScreen) {
                return;
            }

            const $this = $(this);
            if ($this.hasClass('sectionToolbar--expanded')) {
                return;
            }

            const $sectionMore = $(
                '.tlxSection-more',
                $this.closest('.section')
            );
            const $toolbarElements = $this.find(
                'ul.mdl-menu .section-toolbar-button-container, ul.mdl-menu .view-options-container'
            );

            $toolbarElements.each(function () {
                ($(this).is('.section-toolbar-button-container.primary') ||
                    $(this).is('.view-options-container')) &&
                    $(this).appendTo($sectionMore);
            });

            if ($this.find('ul.mdl-menu').children().length === 0) {
                $this.hide();
            }
        });
    }
);
