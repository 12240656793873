import * as React from 'react';

import { renderComponent } from '@General/renderComponent';
import { PageLoader } from '@Component/PageLoader';

async function sleep(milliseconds: number) {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
}

const timeoutPageLoader: React.FC = () => (
    // Can't be unknown because of a bug with Typescript generics and JSX
    // See https://github.com/microsoft/TypeScript/issues/37456
    <PageLoader<{}>
        loader={async () =>
            import('./timeout')
                .then(async (data) => {
                    await sleep(20000);
                    return data;
                })
                .then((m) => m.pageData)
        }
        type="page"
    />
);

const timeoutInlineLoader: React.FC = () => (
    // Can't be unknown because of a bug with Typescript generics and JSX
    // See https://github.com/microsoft/TypeScript/issues/37456
    <PageLoader<{}>
        loader={async () =>
            import('./timeout')
                .then(async (data) => {
                    await sleep(20000);
                    return data;
                })
                .then((m) => m.pageData)
        }
        type="inline"
    />
);

const timeoutInvisibleLoader: React.FC = () => (
    // Can't be unknown because of a bug with Typescript generics and JSX
    // See https://github.com/microsoft/TypeScript/issues/37456
    <PageLoader<{}>
        loader={async () =>
            import('./timeout')
                .then(async (data) => {
                    await sleep(20000);
                    return data;
                })
                .then((m) => m.pageData)
        }
        type="invisible"
    />
);

const errorPageLoader: React.FC = () => (
    // Can't be unknown because of a bug with Typescript generics and JSX
    // See https://github.com/microsoft/TypeScript/issues/37456
    <PageLoader<{}>
        loader={async () => import('./error').then((m) => m.pageData)}
        type="page"
    />
);

const errorInlineLoader: React.FC = () => (
    // Can't be unknown because of a bug with Typescript generics and JSX
    // See https://github.com/microsoft/TypeScript/issues/37456
    <PageLoader<{}>
        loader={async () => import('./error').then((m) => m.pageData)}
        type="inline"
    />
);

const errorInvisibleLoader: React.FC = () => (
    // Can't be unknown because of a bug with Typescript generics and JSX
    // See https://github.com/microsoft/TypeScript/issues/37456
    <PageLoader<{}>
        loader={async () => import('./error').then((m) => m.pageData)}
        type="invisible"
    />
);

const examples: { [key: string]: React.ComponentType } = {
    none: () => null,
    timeoutPage: timeoutPageLoader,
    timeoutInline: timeoutInlineLoader,
    timeoutInvisible: timeoutInvisibleLoader,
    errorPage: errorPageLoader,
    errorInline: errorInlineLoader,
    errorInvisible: errorInvisibleLoader,
};

type PossibleExamples = keyof typeof examples;

const Page: React.FC = () => {
    const [currentExample, setCurrentExample] =
        React.useState<PossibleExamples>('none');

    return (
        <div>
            <select
                onChange={(event) => {
                    setCurrentExample(
                        event.target.options[event.target.selectedIndex].value
                    );
                }}
                defaultValue={currentExample as string}
            >
                {Object.keys(examples).map((value) => (
                    <option key={value} value={value}>
                        {value}
                    </option>
                ))}
            </select>
            {React.createElement(examples[currentExample], {})}
        </div>
    );
};

export const renderPageLoaderTestPage = (domId: string) => {
    renderComponent(Page, domId);
};
