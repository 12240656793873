import { PageLoader } from '@Component/PageLoader';
import { RouteData } from '@General/Router';
import { Team } from '@General/Team';
import { PageUrl } from '@Page/LogisticsV2/utils/enums';
import * as React from 'react';

const Page = () => (
    <PageLoader<{}>
        loader={async () => import('./WarehouseDetails').then((m) => m.page)}
        adjustWrapperDiv
    />
);

export const route: RouteData = {
    component: Page,
    path: PageUrl.WAREHOUSE_DETAILS,
    title: () => `${getMessage('text_warehouse_details')}`,
    documentationComponent: 226,
    team: Team.GOLDSHARK,
    auth: () => hasProductAuthAdminRole && !moduleRackbeat,
};
