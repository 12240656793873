import { Option } from '../../types';
import { notUndefined } from '@General/Helpers';
import { ModelAPIV1 } from '@General/types/Api';
import { JSPDropdownFetcher } from '../JSPDropdownFetcher';
import { ApiResultMapper } from '@General/Provider';
import * as React from 'react';

interface TravelReportZoneEntry extends ModelAPIV1 {
    readonly displayName: string;
}

const TravelReportZoneMapper: ApiResultMapper<TravelReportZoneEntry> = (
    item
) => {
    return {
        value: item.id + '',
        displayName: item.displayName,
        item,
    };
};

class TravelReportZoneFetcher extends JSPDropdownFetcher<TravelReportZoneEntry> {
    asyncJsonrpcGetter: Function;

    constructor(scope: HTMLDivElement, queryAttributes: Record<string, any>) {
        super(scope, queryAttributes, TravelReportZoneMapper);

        this.asyncJsonrpcGetter = asyncrpc.TravelReportZone.get;
    }

    getParams(): ObjectIndex {
        return {
            date: this.getQueryAttribute('date', ''),
        };
    }

    async fetchList(
        params: ObjectIndex
    ): Promise<Option<TravelReportZoneEntry>[]> {
        return new Promise((resolve, reject) => {
            try {
                jsonrpc.TravelReportZone.getActive(
                    (result: TravelReportZoneEntry[] | null) => {
                        const mapped = (result ?? [])
                            .filter(notUndefined)
                            .map(this.getMapper(params))
                            .filter(notUndefined);
                        resolve(mapped);
                    },
                    this.getMarshallSpec(),
                    params.name || '',
                    params.date
                );
            } catch (e) {
                defaultJsonRpcExceptionHandler(e);
                reject(e);
            }
        });
    }
}

export const exports = {
    fetcher: TravelReportZoneFetcher,
    type: 'TravelReportZone',
};
