import { Status } from '../components/IncomeStatementType';
import { SubmissionStepFailureCategory } from '@Page/YearEnd/Pages/YearEndSubmission/models/YearEndSubmissionResult';

export function isEmpty(text?: string) {
    return text === null || text === undefined || text.length === 0;
}

// User locale is save in format 'no_NO' with underscore, Intl want in format 'no-nb' with hypen. Fallback to be used in storeybook.
export function getLocale() {
    let locale: string = window.locale ?? 'nb-no';
    locale = locale.replaceAll('_', '-').toLocaleLowerCase();
    locale = locale === 'no-no' ? 'nb-no' : locale;
    return locale.replaceAll('_', '-').toLocaleLowerCase();
}

export function isStatusIn(status: Status | undefined, ...required: Status[]) {
    return status !== undefined && required.includes(status);
}

export function isFailureCategoryIn(
    failureCategory: SubmissionStepFailureCategory | undefined,
    ...required: SubmissionStepFailureCategory[]
) {
    return failureCategory !== undefined && required.includes(failureCategory);
}

/**
 * Accepts a list of async function.
 * Return value is from first function returning a value !== undefined.
 */
export async function getFirstDefinedAsync<T>(
    ...getters: (() => Promise<T | undefined>)[]
): Promise<T | undefined> {
    for (const getter of getters) {
        const result = await getter();
        if (result !== undefined) {
            return result;
        }
    }

    return undefined;
}

export interface AnnualAccountsDocumentType {
    documentName: string;
    date: string;
    postValue: string;
    postType: string;
    isEnabled: boolean;
    size: number;
}

export interface GenericDataType {
    postValue: string;
    postType: string;
}

export const enum CompanyTypes {
    ENK = 1,
    ANS = 2,
    DA = 3,
    AS = 6,
    STI = 18,
    NUF = 26,
    SA = 27,
    FLI = 28,
}

// prettier-ignore
export const COMPANY_GROUP = {
    ENK: [CompanyTypes.ENK],
    ANS: [CompanyTypes.ANS],
    DA: [CompanyTypes.DA],
    AS: [CompanyTypes.AS],
    STI: [CompanyTypes.STI],
    NUF: [CompanyTypes.NUF],
    SA: [CompanyTypes.SA],
    FLI: [CompanyTypes.FLI],
    GROUP_AS: [
        CompanyTypes.AS,
        CompanyTypes.STI,
        CompanyTypes.NUF,
        CompanyTypes.SA,
        CompanyTypes.FLI
    ],
    GROUP_SA_FLI_STI: [
        CompanyTypes.SA,
        CompanyTypes.FLI,
        CompanyTypes.STI],
    GROUP_SDF: [
        CompanyTypes.ANS,
        CompanyTypes.DA],
    YEAR_END: [
        CompanyTypes.ENK,
        CompanyTypes.AS,
        CompanyTypes.NUF,
        CompanyTypes.STI,
        CompanyTypes.SA,
        CompanyTypes.FLI,
        CompanyTypes.ANS,
        CompanyTypes.DA
    ]
};

export const isCompany = (
    companyGroup: keyof typeof COMPANY_GROUP
): boolean => {
    return COMPANY_GROUP[companyGroup].some(
        (companyType) =>
            companyType.toString() === window?.segmentationData?.companyType
    );
};

// export function getOperatorFromNumber(number: number): Sign {
//     return number < 0 ? 'NEGATIVE' : 'POSITIVE';
// }
