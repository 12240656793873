import * as React from 'react';
import classNames from 'classnames';
import { StrictOmit } from 'ts-essentials';
import { toolTip } from '../../../../js/component/tooltip/tooltip';

import { MaterialIcon } from '@Component/MaterialIcon';

import './ButtonIcon.scss';

type InheritedProps = StrictOmit<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    'type' | 'aria-label'
>;

export interface BaseButtonIconProps {
    icon: string;
    type?: 'button' | 'submit' | 'reset';
    toolTip?: string;
    visible?: boolean;
    active?: boolean;
    raised?: boolean;
    inverted?: boolean;
    testId?: string;
    trackingId?: string;
}

export type ButtonIconProps = InheritedProps & BaseButtonIconProps;

export const ButtonIcon = React.forwardRef<HTMLButtonElement, ButtonIconProps>(
    (props, ref) => {
        const {
            className,
            visible,
            active,
            disabled,
            raised,
            inverted,
            toolTip: ariaLabel,
            icon,
            type,
            tabIndex,
            onClick,
            testId,
            trackingId,
            ...buttonProps
        } = props;

        if (visible === false) {
            return null;
        }

        const doOnClick = (mouseEvent: React.MouseEvent<HTMLButtonElement>) => {
            mouseEvent.preventDefault();
            toolTip.remove(document.getElementsByClassName('js-tooltip-open'));
            if (onClick !== undefined && !disabled) {
                onClick(mouseEvent);
            }
        };

        return (
            <button
                className={classNames('txr-simple-button-icon', className, {
                    'txr-simple-button-icon--active': active,
                    'txr-simple-button-icon--disabled': disabled,
                    'txr-simple-button-icon--raised': raised,
                    'txr-simple-button-icon--inline': !raised,
                    'txr-simple-button-icon--inverted': inverted,
                })}
                ref={ref}
                aria-label={ariaLabel}
                onClick={doOnClick}
                type={type || 'button'}
                tabIndex={tabIndex !== undefined ? tabIndex : disabled ? -1 : 0}
                data-testid={testId}
                data-trackingid={trackingId}
                {...buttonProps}
            >
                <MaterialIcon
                    className={classNames('txr-simple-button-icon__icon', {
                        'txr-simple-button-icon__icon--active': active,
                        'txr-simple-button-icon__icon--disabled': disabled,
                        'txr-simple-button-icon__icon--raised': raised,
                        'txr-simple-button-icon__icon--inline': !raised,
                        'txr-simple-button-icon__icon--inverted': inverted,
                    })}
                    tabIndex={-1}
                >
                    {icon}
                </MaterialIcon>
            </button>
        );
    }
);
