import {
    Button,
    SkeletonRow,
    SkeletonText,
    TableLayout,
    TableLayoutContent,
    TableLayoutTopbar,
} from '@tlx/atlas';
import {
    Settings,
    TableColumnSetting,
    TableColumnsSetting,
    useTableColumnsSetting,
} from '@tlx/astro-shared';
import { ProductDiscountTableFilters } from '@Page/LogisticsV2/pages/Product discount/overview/table/ProductDiscountTableFilters';
import React, { useState } from 'react';
import {
    CheckboxHeader,
    ProductDiscountTableHeaders,
} from '@Page/LogisticsV2/pages/Product discount/overview/table/ProductDiscountTableHeaders';
import {
    CheckboxRow,
    ProductDiscountTableRow,
} from '@Page/LogisticsV2/pages/Product discount/overview/table/ProductDiscountTableRows';
import { DEFAULT_COLUMNS_NUMBER_IN_DISCOUNT_OVERVIEW } from '@Page/LogisticsV2/pages/Product discount/overview/types';
import {
    getAvailableColumnsInProductDiscount,
    getColumnLabel,
    getDefaultColumnsInProductDiscount,
    updateUrl,
} from '@Page/LogisticsV2/pages/Product discount/overview/utils';
import { NoResultsEmptyState } from '@Page/LogisticsV2/components/EmptyState/EmptyState';
import { ProductDiscountEmptyState } from '@Page/LogisticsV2/pages/Product discount/overview/ProductDiscountComponents';
import { useLoadMoreTarget } from '@Page/LogisticsV2/hooks/fetcher';
import { ProductDiscountBatchActions } from '@Page/LogisticsV2/pages/Product discount/overview/table/ProductDiscountTableBatchActions';
import { useDiscountPolicyData } from '@Page/LogisticsV2/pages/Product discount/overview/useDiscountPolicyData';
import { getIdFromUrl } from '@Page/LogisticsV2/utils/functions';
import { CreateProductDiscountModal } from '@Page/LogisticsV2/pages/Product discount/modals/CreateDiscountModal';
import { UserFeedback } from '@Page/LogisticsV2/components/UserFeedback/UserFeedback';

export function ProductDiscountTableWithFilters() {
    const { data, isLoading, hasMore, loadMore, refresh } =
        useDiscountPolicyData();
    const noFilters =
        getIdFromUrl('query') === null && getIdFromUrl('productGroup') === null;
    const enabledColumnsForDiscountOverview = useTableColumnsSetting(
        `columns-product-discount-overview-${window.loginEmployeeId}`,
        getDefaultColumnsInProductDiscount()
    );
    const [selectedRows, setSelectedRows] = useState<number[]>([]);
    const handleSelectRow = (id: number): void => {
        setSelectedRows((prevSelectedRows) =>
            prevSelectedRows.includes(id)
                ? prevSelectedRows.filter((rowId) => rowId !== id)
                : [...prevSelectedRows, id]
        );
    };

    const selectAllRows = () => {
        setSelectedRows(data.map((discountPolicy) => discountPolicy.id));
    };

    const deselectAllRows = () => {
        setSelectedRows([]);
    };

    const allRowsSelected =
        data.length > 0 && data.length === selectedRows.length;
    const loadMoreRef = useLoadMoreTarget<HTMLDivElement>(loadMore);
    const [showCreateDiscountModal, setShowCreateDiscountModal] =
        useState(false);

    return (
        <TableLayout>
            <TableLayoutTopbar hidden={selectedRows.length === 0}>
                <ProductDiscountBatchActions
                    selectedRows={selectedRows}
                    onChange={async () => {
                        await refresh();
                        setSelectedRows([]);
                    }}
                />
            </TableLayoutTopbar>
            <TableLayoutTopbar
                className="atl-flex atl-px-12 atl-pt-12 atl-pb-8 atl-gap-16 atl-justify-between"
                hidden={selectedRows.length !== 0}
            >
                <ProductDiscountTableFilters />
                <div className="atl-flex atl-gap-8">
                    <UserFeedback pageTitle="ProductDiscount" />
                    {(data.length > 0 || !noFilters) && (
                        <Button
                            data-testid={'create-discount-button'}
                            type="submit"
                            form={'triggerCreateDiscountModal'}
                            variant={'secondary'}
                        >
                            {getMessage('text_new_discount')}
                        </Button>
                    )}
                    <Settings>
                        <TableColumnsSetting
                            name={`columns-product-discount-overview-${window.loginEmployeeId}`}
                            label={getMessage('text_columns')}
                            aria-label="columns-for-product-discount-overview"
                            defaultColumns={getDefaultColumnsInProductDiscount()}
                        >
                            {getAvailableColumnsInProductDiscount().map(
                                (column) => (
                                    <TableColumnSetting
                                        key={column}
                                        value={column}
                                    >
                                        {getColumnLabel(column)}
                                    </TableColumnSetting>
                                )
                            )}
                        </TableColumnsSetting>
                    </Settings>
                </div>
            </TableLayoutTopbar>

            <TableLayoutContent
                style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 225px)' }}
            >
                <table className="atl-table">
                    <ProductDiscountTableHeaders
                        columns={enabledColumnsForDiscountOverview}
                    >
                        <CheckboxHeader
                            checked={allRowsSelected}
                            indeterminate={
                                selectedRows.length > 0 &&
                                selectedRows.length !== data.length
                            }
                            onChange={
                                allRowsSelected
                                    ? deselectAllRows
                                    : selectAllRows
                            }
                        />
                    </ProductDiscountTableHeaders>
                    <tbody className="atl-tbody">
                        {data.map((discountPolicy) => (
                            <ProductDiscountTableRow
                                key={discountPolicy.id}
                                discountPolicy={discountPolicy}
                                columns={enabledColumnsForDiscountOverview}
                                onChange={async () => await refresh()}
                            >
                                <CheckboxRow
                                    checked={selectedRows.includes(
                                        discountPolicy.id
                                    )}
                                    onChange={() =>
                                        handleSelectRow(discountPolicy.id)
                                    }
                                />
                            </ProductDiscountTableRow>
                        ))}
                        {isLoading && (
                            <SkeletonRow>
                                {[
                                    ...Array.from(
                                        Array(
                                            enabledColumnsForDiscountOverview.length +
                                                DEFAULT_COLUMNS_NUMBER_IN_DISCOUNT_OVERVIEW
                                        ).keys()
                                    ),
                                ].map((index) => (
                                    <td className="atl-td" key={index}>
                                        <SkeletonText className="atl-w-full" />
                                    </td>
                                ))}
                            </SkeletonRow>
                        )}
                        {!isLoading && data.length === 0 && !noFilters && (
                            <NoResultsEmptyState
                                colSpan={
                                    enabledColumnsForDiscountOverview.length +
                                    DEFAULT_COLUMNS_NUMBER_IN_DISCOUNT_OVERVIEW
                                }
                                onClick={() => updateUrl()}
                            />
                        )}
                        {!isLoading && data.length === 0 && noFilters && (
                            <ProductDiscountEmptyState
                                colSpan={
                                    enabledColumnsForDiscountOverview.length +
                                    DEFAULT_COLUMNS_NUMBER_IN_DISCOUNT_OVERVIEW
                                }
                            />
                        )}
                        {hasMore && (
                            <tr>
                                <td>
                                    <div ref={loadMoreRef} />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </TableLayoutContent>
            <form
                id={'triggerCreateDiscountModal'}
                onSubmit={() => setShowCreateDiscountModal(true)}
            >
                {showCreateDiscountModal && (
                    <CreateProductDiscountModal
                        onClose={() => setShowCreateDiscountModal(false)}
                        onChange={async () => await refresh()}
                    />
                )}
            </form>
        </TableLayout>
    );
}
