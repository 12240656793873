import { getURL } from '@Page/LogisticsV2/utils/functions';
import { ApiUrl } from '@Page/LogisticsV2/utils/enums';
import { mapObjectToURLSearchParams } from '@Page/LogisticsV2/utils/mappers';

export function getChangePriceProps(values: {
    [k: string]: FormDataEntryValue;
}) {
    const changePricePurchasePercentInput =
        values['changePricePurchasePercentInput'];
    if (
        changePricePurchasePercentInput &&
        changePricePurchasePercentInput.toString().length > 0
    ) {
        return {
            priceType: 'PURCHASE',
            type: 'PERCENTAGE',
            value: changePricePurchasePercentInput,
        };
    }
    const changePricePurchaseValueInput =
        values['changePricePurchaseValueInput'];
    if (
        changePricePurchaseValueInput &&
        changePricePurchaseValueInput.toString().length > 0
    ) {
        return {
            priceType: 'PURCHASE',
            type: 'FIXED',
            value: changePricePurchaseValueInput,
        };
    }
    const changePriceSalesPercentInput = values['changePriceSalesPercentInput'];
    if (
        changePriceSalesPercentInput &&
        changePriceSalesPercentInput.toString().length > 0
    ) {
        return {
            priceType: 'SELLING',
            type: 'PERCENTAGE',
            value: changePriceSalesPercentInput,
        };
    }
    const changePriceSalesValueInput = values['changePriceSalesValueInput'];
    if (
        changePriceSalesValueInput &&
        changePriceSalesValueInput.toString().length > 0
    ) {
        return {
            priceType: 'SELLING',
            type: 'FIXED',
            value: changePriceSalesValueInput,
        };
    }

    return {
        priceType: '',
        type: 'SUPPLIER',
        value: '',
    };
}

export function getChangePriceInput(
    input: HTMLInputElement | HTMLSelectElement
) {
    if (input.name === 'changePricePurchaseSupplierRadio') {
        return { type: 'mainSupplier', value: '' };
    }
    if (
        input.name === 'changePricePurchasePercentInput' ||
        input.name === 'changePriceSalesPercentInput'
    ) {
        return { type: 'percent', value: input.value };
    }
    if (
        input.name === 'changePricePurchaseValueInput' ||
        input.name === 'changePriceSalesValueInput'
    ) {
        return { type: 'value', value: input.value };
    }
    return null;
}

export function generateURL(field: string | undefined, productIds: number[]) {
    if ((moduleLogistics || moduleLogisticsLight) && !moduleRackbeat) {
        if (field === 'availableStock') {
            return getURL(
                ApiUrl.PRODUCT_QUERY,
                mapObjectToURLSearchParams({
                    fields: 'id,availableStock',
                    id: productIds,
                })
            );
        }
        if (field === 'stockOfGoods') {
            return getURL(
                ApiUrl.PRODUCT_QUERY,
                mapObjectToURLSearchParams({
                    fields: 'id,stockOfGoods',
                    id: productIds,
                })
            );
        }
    }
    return undefined;
}
