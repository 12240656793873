import * as React from 'react';
import { PageLoader } from '@Component/PageLoader';
import { RouteData } from '@General/Router';
import { Team } from '@General/Team';

const Page: React.FC = (props) => (
    <PageLoader<{}>
        loader={async () => import('./TemplateManagerPage').then((m) => m.page)}
        props={props}
    />
);

export const route: RouteData = {
    component: Page,
    path: '/execute/controlFormsControlPanel',
    team: Team.SHEET_STORM,
    title: () => '',
    auth: () => isDynamicControlFormTemplateAdmin,
};
