import { StocktakingDTO } from '@Page/LogisticsV2/types/dtoTypes';
import { useCallback, useEffect } from 'react';
import { doExport } from '@Page/LogisticsV2/pages/Stocktaking/details2/StocktakingDetailsActions';
import { ProductLineStatus } from '@Page/LogisticsV2/pages/Stocktaking/utils';
import { SortingType } from '@Page/LogisticsV2/utils/enums';

export function useStocktakingExport(
    stocktaking: StocktakingDTO,
    statusFilter: ProductLineStatus,
    sorting: SortingType,
    columns: string[],
    setIsExporting: (isExporting: boolean) => void
) {
    const exportInterceptor = useCallback(
        async (event: TlxExportMenuExportEvent) => {
            if (event.detail.type === 'xls') {
                event.stopPropagation();
                if (stocktaking !== undefined) {
                    await doExport(
                        stocktaking,
                        statusFilter,
                        sorting,
                        columns,
                        setIsExporting
                    );
                }
            }
        },
        [stocktaking]
    );

    useEffect(() => {
        window.addEventListener('tlx:export-menu:export', exportInterceptor, {
            capture: true,
        });
        return () => {
            window.removeEventListener(
                'tlx:export-menu:export',
                exportInterceptor,
                {
                    capture: true,
                }
            );
        };
    }, [exportInterceptor]);
}
