import { PageUrl } from '@Page/LogisticsV2/utils/enums';

export function getColumnLabel(column: string): string {
    switch (column) {
        case 'main_warehouse':
            return getMessage('text_main');
        case 'description':
            return getMessage('text_description');
        case 'last_stocking':
            return getMessage('text_listwarehouse_last_inventory');
        case 'status':
            return getMessage('text_active');
        default:
            throw new Error(`Unknown column: ${column}`);
    }
}
export const defaultColumnsInWarehouseOverview = [
    'description',
    'last_stocking',
    'main_warehouse',
    'status',
];

export const availableColumnsInWarehouseOverview = [
    'description',
    'last_stocking',
    'main_warehouse',
    'status',
];

export function getWarehouseLink(id: number) {
    return addContextId(`${PageUrl.WAREHOUSE_DETAILS}?warehouseId=${id}`);
}

export function shouldAnimate(statusFilter: string[], newStatus?: string) {
    return (
        statusFilter.length === 1 &&
        !statusFilter.includes('') &&
        newStatus !== undefined &&
        statusFilter[0] !== newStatus
    );
}
