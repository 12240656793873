import React, { ReactNode, useState } from 'react';
import { ToggleSwitch } from '@Page/LogisticsV2/components/ToggleSwitch/ToggleSwitch';
import { getIdFromUrl } from '@Page/LogisticsV2/utils/functions';

export function NewProductSection(props: {
    children: (isResaleProduct: boolean) => ReactNode;
}) {
    const resaleProductId = getIdFromUrl('resaleProductId');

    const [hasResaleProduct, setHasResaleProduct] = useState(!!resaleProductId);
    return (
        <div className="goldshark-productselector__new-product atl-flex atl-flex-col">
            <ToggleSwitch
                label={getMessage('text_resale_product_toggle')}
                checked={hasResaleProduct}
                onChange={() => setHasResaleProduct(!hasResaleProduct)}
                onCheckedChange={() => setHasResaleProduct(!hasResaleProduct)}
            />
            {props.children(hasResaleProduct)}
        </div>
    );
}
