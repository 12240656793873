import { SavedIcon, SaveFailedIcon, SavingIcon } from '@tlx/atlas';
import { ReactElement } from 'react';

export enum AutoSaveStatusType {
    SAVED = 'SAVED',
    PENDING = 'PENDING',
    ERROR = 'NOT_SAVED',
}
export const AutoSaveStatus = ({
    status,
}: {
    status: AutoSaveStatusType;
}): ReactElement => {
    return (
        <div className={'atl-flex atl-gap-4 atl-items-center'}>
            {status === AutoSaveStatusType.SAVED && <SavedStatus />}
            {status === AutoSaveStatusType.PENDING && <PendingStatus />}
            {status === AutoSaveStatusType.ERROR && <ErrorStatus />}
        </div>
    );
};

const SavedStatus = (): ReactElement => {
    return (
        <>
            <SavedIcon className="atl-text-grey-40" />
            <div className="atl-text-grey-40 ">
                {getMessage('text_autosave_status_saved')}
            </div>
        </>
    );
};

const PendingStatus = (): ReactElement => {
    return (
        <>
            <SavingIcon className="atl-text-grey-40" />
            <div className="atl-text-grey-40">
                {getMessage('text_autosave_status_saving')}
            </div>
        </>
    );
};

const ErrorStatus = (): ReactElement => {
    return (
        <>
            <SaveFailedIcon className="atl-text-grey-40" />
            <div className="atl-text-grey-40">
                {getMessage('text_autosave_status_failed')}
            </div>
        </>
    );
};
