import * as React from 'react';

import classNames from 'classnames';

import './FloatingSurface.scss';
import { createPortal } from 'react-dom';

export type FloatingSurfaceProps = JSX.IntrinsicElements['div'] &
    Pick<React.CSSProperties, 'top' | 'bottom' | 'left' | 'right'> & {
        /**
         * Render target for when in detached mode, defaults to document.body.
         */
        target?: React.RefObject<Element>;
        /**
         * Inline in DOM or detached?
         */
        inline?: boolean;
    };

/**
 * Container for content that should float over other content.
 * Can either be inline in the DOM or detached with the help of React portals.
 * Placement is done via top, bottom, left and right.
 *
 * @author tellef
 * @date 2020-01-14
 */
export const FloatingSurface = React.forwardRef<
    HTMLDivElement,
    FloatingSurfaceProps
>((props, ref) => {
    const {
        top,
        bottom,
        left,
        right,
        target,
        className,
        style,
        inline,
        children,
        ...rest
    } = props;

    const surface = (
        <div
            className={classNames('tlx-floating-surface__content', className)}
            style={{
                top,
                bottom,
                left,
                right,
                ...style,
            }}
            ref={ref}
            {...rest}
        >
            {children}
        </div>
    );

    if (inline) {
        return surface;
    } else {
        return createPortal(
            <div className="tlx-floating-surface__root">{surface}</div>,
            target?.current ?? document.body
        );
    }
});
FloatingSurface.displayName = 'FloatingSurface';
