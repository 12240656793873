import { ModelAPIV1 } from '@General/types/Api';
import { Option } from '../../types';
import { JSPDropdownFetcher } from '../JSPDropdownFetcher';
import { ApiResultMapperNoUndefinedResult } from '@General/Provider';

interface IndustryEntry extends ModelAPIV1 {
    readonly displayName: string;
}

const industryMapper: ApiResultMapperNoUndefinedResult<IndustryEntry> = (
    item
) => ({
    value: item.id + '',
    displayName: item.displayName,
    item,
});

class IndustryFetcher extends JSPDropdownFetcher<IndustryEntry> {
    asyncJsonrpcGetter: Function;

    constructor(scope: HTMLDivElement, queryAttributes: Record<string, any>) {
        super(scope, queryAttributes, industryMapper);
        this.asyncJsonrpcGetter = asyncrpc.Industry.get;
    }

    getParams(): ObjectIndex {
        return {};
    }

    async fetchList(params: ObjectIndex): Promise<Option<IndustryEntry>[]> {
        return new Promise((resolve, reject) => {
            try {
                return jsonrpc.Industry.searchForIndustries(
                    (result: IndustryEntry[] | null) =>
                        resolve((result ?? []).map(industryMapper)),
                    this.getMarshallSpec(),
                    params.name || ''
                );
            } catch (e) {
                defaultJsonRpcExceptionHandler(e);
                reject(e);
            }
        });
    }
}

export const exports = {
    fetcher: IndustryFetcher,
    type: 'Industry',
};
