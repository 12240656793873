import { stringUtils } from './stringUtils';

/**
 * Tripletex specific code (can be modified here and not in the peacock table repo).
 *
 * Compare two values, a and b (for sorting).
 *
 * @param valueA
 * @param valueB
 * @param nullValue a value that will be "left out" when sorting. With ascending sorting,
 *                  these will be considered to be BIGGER than other values.
 * @param ascending true by default. set to false to get descending ordering.
 * @returns {number} -1 if a is considered SMALLER than b,
 *                   0 if they are considered to be EQUAL,
 *                   1 if a is considered to be BIGGER than b.
 * @private
 *
 * @author Haakon
 * @date 29. sep 2016
 */
export function compareValues(
    valueA: string,
    valueB: string,
    nullValue: string,
    ascending?: boolean
): number {
    if (typeof ascending === 'undefined') {
        ascending = true;
    }
    if (typeof nullValue !== 'undefined') {
        nullValue = '' + nullValue;
    }

    const floatA = parseFloat(valueA);
    const floatB = parseFloat(valueB);
    const floatNull = parseFloat(nullValue);

    if (
        !isNaN(floatA) &&
        !isNaN(floatB) &&
        (typeof nullValue === 'undefined' || !isNaN(floatNull))
    ) {
        // comparing numbers
        /* eslint-disable @typescript-eslint/no-explicit-any */
        valueA = floatA as any;
        valueB = floatB as any;
        nullValue = floatNull as any;
        /* eslint-enable @typescript-eslint/no-explicit-any */
    } else if (typeof valueA === 'string' && typeof valueB === 'string') {
        valueA = valueA.toLowerCase();
        valueB = valueB.toLowerCase();
        nullValue = nullValue.toLowerCase();

        if (valueA !== nullValue && valueB === nullValue) {
            return ascending ? -1 : 1;
        } else if (valueA === nullValue && valueB !== nullValue) {
            return ascending ? 1 : -1;
        } else {
            return stringUtils.splitCompare(valueA, valueB, ascending, false);
        }
    }

    if (
        (valueA < valueB && valueA !== nullValue && valueB !== nullValue) ||
        (valueA !== nullValue && valueB === nullValue)
    ) {
        return ascending ? -1 : 1;
    } else if (
        (valueA > valueB && valueA !== nullValue && valueB !== nullValue) ||
        (valueA === nullValue && valueB !== nullValue)
    ) {
        return ascending ? 1 : -1;
    } else {
        return 0;
    }
}
