import {
    AsyncDropdownOptions,
    DropdownFilter,
    SearchFilter,
} from '@tlx/astro-shared';
import React from 'react';
import { ApiUrl } from '@Page/LogisticsV2/utils/enums';
import { Option } from '@tlx/atlas';

export function ProductDiscountTableFilters() {
    return (
        <form className="atl-flex atl-gap-16" autoComplete="off">
            <SearchFilter
                data-testid="product-discount-overview-search-filter"
                name="query"
                label={getMessage('text_search')}
                placeholder={getMessage('text_search')}
                quick
            />
            <DropdownFilter
                name="productGroup"
                label=""
                quick
                defaultDisplayName={getMessage('text_product_group_all')}
                className="goldshark-dropdown-filter"
            >
                <Option value={'-1'}>
                    {getMessage('text_product_group_all')}
                </Option>
                <AsyncDropdownOptions url={ApiUrl.PRODUCT_GROUP_QUERY} />
            </DropdownFilter>
        </form>
    );
}
