import React from 'react';
import { DistributionKeyBit } from '@Page/DistributionKeys/models/DistributionKeys';

type DistributionKeysRuleProps = {
    distributionKeyBit: DistributionKeyBit;
};

export const DistributionKeyItemDetails = ({
    distributionKeyBit,
}: DistributionKeysRuleProps) => {
    return (
        <tr className="atl-tr atl-tr--highlight">
            <td className="atl-td" />
            <td className="atl-td" />
            <td className="atl-td">{distributionKeyBit.departmentName}</td>
            <td className="atl-td atl-text-right">
                {distributionKeyBit?.percentage}%
            </td>
            <td className="atl-td" />
            <td className="atl-td" />
            <td className="atl-td" />
        </tr>
    );
};
