import jQuery from 'jquery';
import { browserInfo } from '../../js/modules/d-browserInfo';
import { addContextId } from '../../js/modules/url';
const $ = jQuery;

/**
 * https://tripletex.atlassian.net/wiki/display/DOC/filterComponent
 */
$.widget('tlx.filterComponent', {
    // These options will be used as defaults
    options: {
        elementsInHeader: null,
        searchButtonLabel: null,
        /** [function] used to load the actual page content  */
        loader: null,
        text_more_options: null,
        text_less_options: null,
        moreOptionsButtonTrackingId: '',
        fewerOptionsButtonTrackingId: '',
        /* With autoRefresh on, screen is refreshed when filter elements are changed. No Refresh button are shown.
         * If undefined, hide refresh button if loader is set:
         *
         * On pages with lazy load, this is "true" by default, on pages without it is "false".
         *
         */
        autoRefresh: undefined,
        /**
         * When set, create an icon link to this page. (cog wheel icon).
         */
        settingsUrl: undefined,

        /**
         * When set, replace the sort order dropdown with this sort button.
         */
        sortProperty: undefined,

        /**
         * If true, turn the "More options" button into am icon button.
         */
        moreOptionsIconButton: false,

        /**
         * When true, show items in a fieldset.tlx-filter__view-options inside a dialog that
         * is opened with a icon button.
         */
        showViewOptions: false,

        /**
         * When set to true, the filter will look like a section (with white background)
         * and with the button group to the left.
         */
        useNewestDesign: false,

        /**
         * Prevent filter to flip/flop when opening/closing it by setting it to a specific width.
         * We should fix this by other means somehow.
         */
        newDesignWidth: 'auto',

        /**
         * Removes the search field above the filter, and inserts it inside the filter.
         */
        createSearchField: false,
    },

    // Maybe it's better that the filter search for a loader (ie. that some DOM ancestor shoud provide a loader) or maybe use a
    // "refresh" event (bu a event might not work that well for loading animation).
    refresh: function () {
        this._submitFilterForm();

        if (this.searchButton) {
            this.searchButton.removeClass(
                'mdl-button--raised mdl-button--colored'
            );

            if (browserInfo.isTouchDevice()) {
                // Hide keyboard on touch devices on search. Also scrolls down to search results.
                this.searchButton.focus();
            }
        }
    },

    // Set up the widget
    _create: function () {
        var self = this;
        var refresh = $.proxy(this.refresh, this);
        var $form = this.element.closest('form').addClass('filterForm');
        var autoRefresh =
            this.options.autoRefresh ||
            (this.options.autoRefresh === undefined && this.options.loader);
        this.element.addClass('tlx-search-filter ui-corner-all');

        if (this.options.useNewestDesign) {
            this.element.addClass('tlx-filter--new-design');
        }

        this.searchFilterTop = $('<div />', { class: 'tlx-top-part' }).appendTo(
            this.element
        );

        $(this.element).find('.hideFromScreen').remove();

        this.searchFilterBottom = $('<div />', {
            class: 'tlx-bottom-part',
        }).appendTo(this.element);

        var $inputItems = $(this.element)
            .find('.inputItem, .tlx-dropdown')
            .addClass('tlx-filter-item')
            .bind('change', function (e) {
                if (autoRefresh) {
                    // .notInForm: Especially hidden field inside tlxSelect, so we do not provoke a refresh two times.
                    // inside dialog: Don't refresh filter before actually closing dialog (only view options dialog)
                    if (
                        !$(e.target).is('.notInForm') &&
                        $(e.target).closest(
                            '.tlx-filter__view-options.ui-dialog-content'
                        ).length === 0
                    ) {
                        setTimeout(refresh);
                    }
                } else if (
                    !self.searchButton.hasClass(
                        'mdl-button--raised mdl-button--colored'
                    )
                ) {
                    self.searchButton.addClass(
                        'mdl-button--raised mdl-button--colored'
                    );
                }
            });
        var $fieldsets = $(this.element).find('fieldset');
        if ($fieldsets.length === 0) {
            $fieldsets = $('<fieldset/>');
            $fieldsets.appendTo(this.searchFilterBottom);
            $inputItems.appendTo($fieldsets);
        } else {
            $fieldsets.appendTo(this.searchFilterBottom);
        }

        this._createSearchField();

        this.buttonGroup = $('<div class="ui-buttonset filter-buttonset" />');
        if (this.options.useNewestDesign) {
            this.searchFilterTop.append(this.buttonGroup);
        } else {
            this.buttonGroup.appendTo(
                $(this.searchFilterTop).closest('.filter')
            );
        }

        this._createSortButton();

        this._createRefreshButton(autoRefresh, $form);

        if (this.options.moreOptionsIconButton) {
            this._createOpenCloseIcon();
        } else {
            this._createOpenCloseButton();
        }

        this._createViewOptions();

        this._createSettingsButton();

        if (this.options.useNewestDesign) {
            this._updateElementsInHeader();
        } else {
            this._updateElementsInHeaderOLD();
        }

        $('.tlx-free-text-search .material-icons')
            .on('click', function (e) {
                $(e.target)
                    .closest('.tlx-free-text-search')
                    .find('input[type="submit"]')
                    .trigger('click');
            })
            .addClass('active');

        if (this.options.useNewestDesign) {
            var width = this.options.newDesignWidth;

            width =
                typeof width === 'number'
                    ? Math.min($(window).width(), width)
                    : width;

            this.element
                .closest('.tlx-filter-container')
                .addClass('tlx-filter-container--new-design')
                .css('width', width);
            this.buttonGroup.addClass('tlx-filter__buttonset--new-design');
        }
    },

    /**
     * We want to make the search field to appear as part of the filter, while in reality it is within its own form.
     * We solve this in the classical tripletex-way, of hiding the search-form, duplicate the search field within
     * the filter, and send events back to the original form.
     *
     * When starting using the new search functionality, we should shy away from this hacky way of doing things.
     *
     * How a search is performed isn't documented anywhere, so perhaps write something about it here:
     *
     * First the form itself calls defaultSearch(event) on submit, which again is calling defaultAjaxAction.
     * The server then performs the search and checks how many hits it gets.
     * If there is more than one hit, a reply is sent to the client, asking to load the report again, filtered on the search.
     * If there is only one hit, a reply is sent to the client, asking it to go to the page representing the hit.
     *
     */
    _createSearchField() {
        if (!this.options.createSearchField) return;

        // This query might seem strange, but it is actually a very precise way of finding the search element.
        // We do want to only find stuff inside the scope. And we are only interested in the framework component
        // that performs search via the global defaultSearch function.
        var $searchForm = this.element
            .closest('.tlxScope')
            .find('[onsubmit="defaultSearch(event)"]')
            .hide();

        if ($searchForm.length === 0) return;

        var $inputItemClone = $searchForm.find('.tlx-free-text-search').clone();

        var $searchField = $inputItemClone.find('.tlx-textfield__input');

        // Prevent duplicate ids. These inputs are hidden from view, so they
        // should not be tracked.
        $searchForm.find('.tlx-textfield__input').removeAttr('data-testid');
        $searchForm.find('.tlx-textfield__input').removeAttr('data-trackingid');

        // Don't submit the whole form if this button is clicked...
        $inputItemClone.find('[type="submit"]').remove();

        // Prevents these from being submittet for the filter
        $inputItemClone.find('input').addClass('notInForm');

        $inputItemClone.insertBefore(
            this.element.find('.inputItem, .tlx-dropdown')[0]
        );

        $inputItemClone.addClass('tlx-filter-item');
        $inputItemClone.removeClass('ui-corner-all');

        this.$inputItemClone = $inputItemClone;
        this.$searchFieldOriginal = $searchField;

        function submit() {
            $searchForm
                .find('.tlx-free-text-search input')
                .val($inputItemClone.find('input').val());
            $searchForm.submit();
        }

        $inputItemClone.on('keydown', function (e) {
            if (e.keyCode === 13) submit();
        });

        $inputItemClone.find('.tmdl-search-icon').on('click', function () {
            submit();
        });
    },

    /**
     * Replace the sort drop down with an icon button.
     *
     * @private
     */
    _createSortButton: function () {
        if (!this.options.sortProperty) return;

        var $oldSortingDropdown = this.element.find(
            '[name="' + this.options.sortProperty + '"]'
        );
        if ($oldSortingDropdown.length !== 1) return;

        var that = this;
        this.sortAscendingState = $oldSortingDropdown.val() === 'true';

        var refresh = $.proxy(this.refresh, this);
        var $sortButton = $(
            '<button type="button" class="mdl-button mdl-button--colored tmdl-actionmenu-button mdl-js-button mdl-button--icon"></button>'
        );
        var $sortIcon = $(
            '<i class="material-icons tlx-filter__sort_button_icon">sort</i>'
        ).appendTo($sortButton);

        var $input = $('<input type="hidden" name="ascending">');
        $oldSortingDropdown.closest('.tlx-filter-item').remove();
        $input.appendTo($sortButton);

        function render() {
            $input.val(that.sortAscendingState);
            $sortIcon.toggleClass(
                'tlx-filter__sort_button_icon--descending',
                !that.sortAscendingState
            );
            $sortButton.attr(
                'aria-label',
                that.sortAscendingState
                    ? getMessage('option_sort_order_ascending')
                    : getMessage('option_sort_order_descending')
            );
        }

        render();

        $sortButton.on('click', function () {
            that.sortAscendingState = !that.sortAscendingState;
            render();
            refresh();
        });

        $sortButton.appendTo(this.buttonGroup);
    },

    /**
     * This does not have a icon variant, and is considered a very old pattern.
     *
     * @param autoRefresh
     * @param $form
     * @private
     */
    _createRefreshButton: function (autoRefresh, $form) {
        if (autoRefresh) return;

        var refresh = $.proxy(this.refresh, this);
        $form.on('submit', refresh);

        // It looks like this comment was totally disregarded by the last dev who did introduce changes...
        /* OBS! The content of the filter top is inserted relative to this buttonGroup,
         * in the method _updateElementsInHeader! Do not move or change this button
         * before consulting further in there. */

        this.searchButton = $('<button/>', {
            type: 'submit',
        })
            .addClass(
                'refreshButton mdl-button mdl-button--primary mdl-js-button'
            )
            .append(this.options.searchButtonLabel)
            .appendTo(this.buttonGroup);
        this.searchButton.removeClass(
            'ui-button ui-widget ui-state-default ui-corner-all ui-button-text-icon-primary'
        );
    },

    /**
     * Creates an icon button that takes you to the settings page.
     *
     * In the future we might want to open settings in a modal, but as of now the settings pages looks awful inside dialogs.
     *
     * @private
     */
    _createSettingsButton: function () {
        if (this.options.settingsUrl === undefined) return;
        $(
            '<a class="mdl-button mdl-button--colored tmdl-actionmenu-button navigate mdl-js-button mdl-button--icon"><i class="material-icons">settings</i></a>'
        )
            .attr('aria-label', getMessage('text_settings'))
            .attr('href', addContextId(this.options.settingsUrl))
            .appendTo(this.buttonGroup);
    },

    /**
     * The "show more" button, as an icon.
     *
     * @private
     */
    _createOpenCloseIcon: function () {
        var hiddenExpandedInput = this.element.find(
            "[name='isExpandedFilter']"
        );
        var isExpanded = hiddenExpandedInput.prop('value') === 'true';
        var self = this;

        function showHideIcon() {
            if (self.searchFilterBottom.is(':visible')) {
                self.searchFilterBottom.hide('blind', 'fast');
                self.openCloseButton.toggleClass(
                    'tlx-filter__open-close-icon--closed',
                    true
                );
                self.openCloseButton.toggleClass(
                    'tlx-filter__open-close-icon--open',
                    false
                );
                hiddenExpandedInput.prop('value', false);
                self.openCloseButton.attr(
                    'aria-label',
                    getMessage('text_more_options')
                );
            } else {
                self.searchFilterBottom.show('blind', 'fast');
                self.openCloseButton.toggleClass(
                    'tlx-filter__open-close-icon--closed',
                    false
                );
                self.openCloseButton.toggleClass(
                    'tlx-filter__open-close-icon--open',
                    true
                );
                hiddenExpandedInput.prop('value', true);
                self.openCloseButton.attr(
                    'aria-label',
                    getMessage('text_less_options')
                );
            }
        }

        this.openCloseButton = $(
            '<button type="button" class="mdl-button mdl-button--colored tmdl-actionmenu-button mdl-js-button mdl-button--icon"><i class="material-icons">filter_list</i> </button>'
        )
            .attr('aria-label', getMessage('text_more_options'))
            .bind('click', showHideIcon)
            .appendTo(this.buttonGroup);

        if (!isExpanded) {
            this.searchFilterBottom.hide();
        }
    },

    /**
     * The "show more" button, as text.
     *
     * @private
     */
    _createOpenCloseButton: function () {
        var hiddenExpandedInput = this.element.find(
            "[name='isExpandedFilter']"
        );
        var isExpanded = hiddenExpandedInput.prop('value') === 'true';
        var self = this;

        function showHideBottom() {
            if (self.searchFilterBottom.is(':visible')) {
                self.searchFilterBottom.hide('blind', 'fast');
                self.openCloseButton.text(getMessage('text_more_options'));
                self.openCloseButton.attr(
                    'data-trackingid',
                    self.options.moreOptionsButtonTrackingId
                );
                hiddenExpandedInput.prop('value', false);
            } else {
                self.searchFilterBottom.show('blind', 'fast');
                self.openCloseButton.text(self.options.text_less_options);
                self.openCloseButton.attr(
                    'data-trackingid',
                    self.options.fewerOptionsButtonTrackingId
                );
                hiddenExpandedInput.prop('value', true);
            }
            self.openCloseButton.find('.ui-icon').removeClass('ui-icon');
        }

        this.openCloseButton = $(
            '<button type="button" class="filter-morelessbutton mdl-button--primary mdl-button mdl-js-button"/>'
        )
            .text(
                isExpanded
                    ? this.options.text_less_options
                    : this.options.text_more_options
            )
            .attr(
                'data-trackingid',
                isExpanded
                    ? this.options.fewerOptionsButtonTrackingId
                    : this.options.moreOptionsButtonTrackingId
            )
            .bind('click', showHideBottom)
            .appendTo(this.buttonGroup);

        if (!isExpanded) {
            this.searchFilterBottom.hide();
        }
    },

    /**
     * Opens a modal for view options. View options are NOT filter, but decides how much information to show for each item.
     *
     * Should be introduced at least in these pages:
     * In listProjectsExt, listOffers, createOrderInvoices, ledger, viewHourlistReport, balanceReport2, projectResultReport.
     *
     * @private
     */
    _createViewOptions: function () {
        if (!this.options.showViewOptions) return;

        var $viewOptions = this.element.find('.tlx-filter__view-options');
        if ($viewOptions.length !== 1) return;

        var refresh = $.proxy(this.refresh, this);
        $(
            '<button type="button" class="mdl-button mdl-button--colored tmdl-actionmenu-button mdl-js-button mdl-button--icon"><i class="material-icons">tune</i></button>'
        )
            .attr('aria-label', getMessage('text_view_options'))
            .on('click', function () {
                // Used to remember state if user clicks cancel! Only bother with checkboxes for now, needs to be
                // extended if we have other stuff in view options dialogs.
                var originalOptions = $viewOptions
                    .find('input[type="checkbox"]')
                    .serializeArray();
                originalOptions = originalOptions.reduce(function (a, b) {
                    // return {[b.name]: b.value, ...a} Unable to do this because of es-lint! :-(

                    a[b.name] = b.value;
                    return a;
                }, {});

                $viewOptions.dialog({
                    show: {},
                    autoOpen: true,
                    modal: true,
                    title: getMessage('text_view_options'),
                    buttons: [
                        {
                            text: getMessage('button_ok'),
                            click: function () {
                                const $this = $(this);
                                if ($this.dialog('instance') !== undefined) {
                                    $this.dialog('close');
                                    $this.dialog('destroy');
                                }
                                refresh();
                            },
                        },
                        {
                            text: getMessage('button_cancel'),
                            click: function () {
                                const $this = $(this);
                                if ($this.dialog('instance') !== undefined) {
                                    $this.dialog('close');
                                    $this.dialog('destroy');
                                }

                                // jQuery serialization of checbox ignores unchecked checkboxes, we therefore need to
                                // run through everyone.
                                $viewOptions
                                    .find('input[type="checkbox"]')
                                    .each(function () {
                                        $(this).prop(
                                            'checked',
                                            originalOptions[this.name] ===
                                                'true'
                                        );
                                    });
                            },
                        },
                    ],
                });
            })
            .appendTo(this.buttonGroup);
    },

    _submitFilterForm: function () {
        var $form = $(this.element).closest('form');

        if ($form.is('[method="get"]')) {
            // NB: submitGetFormAjax handle "changed confirmation" so it might return without any action

            var loader = this.options.loader;

            if (loader) {
                $form.submitGetFormAjax({}, function (url) {
                    let newUrl = JSON.parse(JSON.stringify(url));

                    if (window.location.search.includes('forceRedirect')) {
                        newUrl += '&forceRedirect=true';
                        return loader(newUrl);
                    } else {
                        loader(newUrl);
                    }
                });
            } else {
                $form.submitGetFormAjax();
            }
        }
    },

    /**
     * This is only used when options.useNewestDesign is true. When options.useNewestDesign is removed (all filters have
     * new design), we must remove the function called _updateElementsInHeaderOLD.
     *
     * I duplicate them, because I don't know if the changes in here will create bugs in other filters.
     *
     * @private
     */
    _updateElementsInHeader: function () {
        var self = this;
        //reset filterItems
        var $items = this.searchFilterTop.find('.inputItem, .tlx-dropdown');
        var $fieldset = this.searchFilterBottom
            .find('fieldset')
            .first()
            .prepend($items);

        var $inputItems = $fieldset.find('.inputItem, .tlx-dropdown');

        // If everything should be exposed, put all in the searchFilterTop
        if (
            !this.options.elementsInHeader ||
            this.options.elementsInHeader >= $inputItems.length
        ) {
            this.searchFilterTop.append($inputItems);
            $inputItems.bind('change', function () {
                self.searchButton &&
                    self.searchButton.addClass(
                        'mdl-button--raised mdl-button--colored'
                    );
            });
        } else {
            var count = 0;
            $inputItems.each(function () {
                if (count < self.options.elementsInHeader) {
                    $inputItems.append(this);
                    if ($(this).css('display') !== 'none') {
                        count++;
                    }
                } else {
                    count++;
                }
            });
        }

        if (
            this.searchFilterBottom.find(
                'fieldset .inputItem, fieldset .tlx-dropdown'
            ).length > 0
        ) {
            this.openCloseButton.show();
        } else {
            this.openCloseButton.remove();
        }

        this.searchFilterBottom.find('fieldset').each(function () {
            var $this = $(this);
            if (
                $this.children().length === 0 ||
                (self.options.showViewOptions &&
                    $this.is('.tlx-filter__view-options'))
            ) {
                $this.hide();
            } else {
                $this.show();
            }
        });
    },

    /**
     *
     * This method got kinda borked during the "new design" process in 2017. I don't dare change it for all, because
     * the filter component is used hundreds of places.
     *
     * @private
     * @deprecated
     */
    _updateElementsInHeaderOLD: function () {
        var self = this;
        //reset filterItems
        var $items = this.searchFilterTop.find('.inputItem, .tlx-dropdown');
        var $fieldset = this.searchFilterBottom
            .find('fieldset')
            .first()
            .prepend($items);

        var $inputItems = $fieldset.find('.inputItem, .tlx-dropdown');

        var tlxBottomPart = this.buttonGroup
            .closest('.filter')
            .find('.tlx-bottom-part');
        var $appenderelement =
            tlxBottomPart.length > 0 ? tlxBottomPart : this.buttonGroup;
        if (
            !this.options.elementsInHeader ||
            this.options.elementsInHeader >= $inputItems.length
        ) {
            $inputItems
                .insertBefore($appenderelement)
                .bind('change', function () {
                    self.searchButton &&
                        self.searchButton.addClass(
                            'mdl-button--raised mdl-button--colored'
                        );
                });
        } else {
            var count = 0;
            $inputItems.each(function () {
                if (count < self.options.elementsInHeader) {
                    $(this).insertBefore($appenderelement);
                    if ($(this).css('display') !== 'none') {
                        count++;
                    }
                } else {
                    count++;
                }
            });
        }

        if (
            this.searchFilterBottom.find(
                'fieldset .inputItem, fieldset .tlx-dropdown'
            ).length > 0
        ) {
            this.openCloseButton.show();
        } else {
            this.openCloseButton.remove();
        }

        this.searchFilterBottom.find('fieldset').each(function () {
            var $this = $(this);
            if (
                $this.children().length === 0 ||
                (self.options.showViewOptions &&
                    $this.is('.tlx-filter__view-options'))
            ) {
                $this.hide();
            } else {
                $this.show();
            }
        });
    },

    // Use the _setOption method to respond to changes to options
    _setOption: function (key, value) {
        this._super(key, value);

        switch (key) {
            case 'searchButtonLabel':
                if (this.searchButton) {
                    this.searchButton
                        .button('option', 'label', value)
                        .find('.ui-icon')
                        .removeClass('ui-icon');
                }
                break;
            case 'elementsInHeader':
                this._updateElementsInHeaderOLD();
                break;
        }
    },

    // Use the destroy method to clean up any modifications your widget has made to the DOM
    _destroy: function () {
        var $items = this.searchFilterTop.find('.tlx-filter-item');
        this.searchFilterBottom.find('fieldset').first().prepend($items);
        this.searchFilterBottom.find('fieldset').appendTo(this.element);
        this.searchFilterTop.remove();
        this.searchFilterBottom.remove();
        this.$inputItemClone.remove();
        this.$searchFieldOriginal.show();
        this.element
            .removeClass('tlx-search-filter ui-corner-all')
            .find('.tlx-filter-item')
            .removeClass('tlx-filter-item')
            .css({
                'border-left-color': '',
                'border-left-width': '',
                'border-left-style': '',
            });
    },
});
