import { messagesReducers } from '@Component/Messages/reducer';

import general from './general.reducer';
import error from './error.reducer';

import { historyReducer } from './HistoryShim.reducer';
import { reduceBetaProgram } from './BetaProgram.reducer';
import { reduceDeveloper } from './Developer.reducer';

export const generalReducers = {
    general,
    error,
};

export const historyReducers = {
    history: historyReducer,
};

export const componentReducers = {
    ...messagesReducers,
};

export const betaProgramReducers = {
    betaProgram: reduceBetaProgram,
};

export const developerReducers = {
    developer: reduceDeveloper,
};
