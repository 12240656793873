import { ApiResultMapper } from '@General/Provider';
import {
    VatTypeEntry,
    VatTypeFetcher,
} from '@Component/DropDown/JSPDropdown/Models/VatType';

interface VatTypeShortEntry extends VatTypeEntry {
    readonly number: string;
    readonly name: string;
    readonly shortName: string;
    readonly percentageAsString: string;
}

const vatTypeShortMapper: ApiResultMapper<VatTypeShortEntry> = (item) => ({
    value: item.id + '',
    displayName: `${item.number} ${item.shortName}`,
    secondary: `${item.number} ${item.name} (${item.percentageAsString})`,
    item,
});

export class VatTypeShortFetcher extends VatTypeFetcher {
    constructor(scope: HTMLDivElement, queryAttributes: Record<string, any>) {
        super(scope, queryAttributes, vatTypeShortMapper);
    }

    getMarshallSpec() {
        return window.marshallSpec(
            'id',
            'number',
            'name',
            'shortName',
            'percentageAsString'
        );
    }
}

export const exports = {
    fetcher: VatTypeShortFetcher,
    type: 'VatTypeShort',
};
