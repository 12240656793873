import { ModelAPIV1 } from '@General/types/Api';
import { CreateButtonComponent, Option } from '../../types';
import { JSPDropdownFetcher } from '../JSPDropdownFetcher';
import { ApiResultMapper } from '@General/Provider';
import * as React from 'react';
import { createCreateNewButton } from '@Component/DropDown/CreateNewButton/CreateNewButton';
import { tlxUrl } from '../../../../../../js/modules/url';
import tlxFetch from '../../../../api/tlxFetch';

interface AccountEntry extends ModelAPIV1 {
    readonly displayName: string;
}

type Params = {
    isActive: number;
    accountType: number;
    accountSubType: number;
    accountShowInIncomingInvoice: number;
    accountIncludeAllVatNone: boolean;
    accountExcludeCustomerCurrentBook: boolean;
    accountExcludeVendorCurrentBook: boolean;
    accountExcludeEmployeeCurrentBook: boolean;

    // for unknown - but legacy - reasons, name is used instead of query
    // to represent the search string from the user.
    name: string;
};

const accountMapper: ApiResultMapper<AccountEntry> = (item) => ({
    value: item.id + '',
    displayName: item.displayName,
    item,
});

class AccountFetcher extends JSPDropdownFetcher<AccountEntry> {
    asyncJsonrpcGetter: Function;

    constructor(scope: HTMLDivElement, queryAttributes: Record<string, any>) {
        super(scope, queryAttributes, accountMapper);

        this.asyncJsonrpcGetter = asyncrpc.Account.get;
    }

    getParams(): ObjectIndex {
        return {
            isActive: this.getQueryAttribute('isActive', 1),
            accountType: this.getQueryAttribute('accountType', -1),
            accountSubType: this.getQueryAttribute('accountSubType', -1),
            accountShowInIncomingInvoice: this.getQueryAttribute(
                'accountShowInIncomingInvoice',
                -1
            ),
            accountIncludeAllVatNone: this.getQueryAttribute(
                'accountIncludeAllVatNone',
                false
            ),
            accountExcludeCustomerCurrentBook: this.getQueryAttribute(
                'accountExcludeCustomerCurrentBook',
                false
            ),
            accountExcludeVendorCurrentBook: this.getQueryAttribute(
                'accountExcludeVendorCurrentBook',
                false
            ),
            accountExcludeEmployeeCurrentBook: this.getQueryAttribute(
                'accountExcludeEmployeeCurrentBook',
                false
            ),
        };
    }

    async fetchList(
        params: ObjectIndex,
        pageIndex = 0
    ): Promise<Option<AccountEntry>[]> {
        try {
            const value = await tlxFetch({
                url: this.getAccountAPIQuery(
                    params,
                    pageIndex >= 0 ? pageIndex : 0
                ).toString(),
                method: 'GET',
            });
            return value.response.map(this.getMapper(params));
        } catch (e) {
            defaultJsonRpcExceptionHandler(e);
            throw e;
        }
    }

    getAccountAPIQuery(params: ObjectIndex, pageIndex: number): string {
        const url = new URL('/v2/ledger/account/query', window.location.origin);
        const urlParams = url.searchParams;

        if (params.accountType !== undefined) {
            urlParams.set('accountType', String(params.accountType));
        }
        if (params.accountSubType !== undefined) {
            urlParams.set('accountSubType', String(params.accountSubType));
        }
        if (params.isActive !== undefined) {
            urlParams.set('isActive', String(params.isActive));
        }

        const showInIncomingInvoice = Number(
            params.accountShowInIncomingInvoice
        );
        if (showInIncomingInvoice === 1) {
            urlParams.set('isApplicableForSupplierInvoice', 'true');
        } else if (showInIncomingInvoice === 0) {
            urlParams.set('isApplicableForSupplierInvoice', 'false');
        }
        if (params.accountIncludeAllVatNone !== undefined) {
            urlParams.set(
                'includeAllVatNone',
                String(params.accountIncludeAllVatNone)
            );
        }
        if (params.accountExcludeCustomerCurrentBook !== undefined) {
            urlParams.set(
                'accountExcludeCustomerCurrentBook',
                String(params.accountExcludeCustomerCurrentBook)
            );
        }
        if (params.accountExcludeVendorCurrentBook !== undefined) {
            urlParams.set(
                'accountExcludeVendorCurrentBook',
                String(params.accountExcludeVendorCurrentBook)
            );
        }
        if (params.accountExcludeEmployeeCurrentBook !== undefined) {
            urlParams.set(
                'accountExcludeEmployeeCurrentBook',
                String(params.accountExcludeEmployeeCurrentBook)
            );
        }
        urlParams.set('query', params.name ?? '');
        urlParams.set('fields', 'id,displayName');
        const countPerPage = this.tlxSelectMaxRows;
        urlParams.set('count', countPerPage.toString());
        urlParams.set('from', (pageIndex * countPerPage).toString());
        return url.toString();
    }

    createCreateNewButton(
        setSelected: (id: number) => void
    ): CreateButtonComponent | undefined {
        if (!isAuth.createAccount) {
            return undefined;
        }

        const accountType = this.getQueryAttribute('accountType', -1);
        const accountSubtype = this.getQueryAttribute('accountSubType', -1);
        const requiresVat = this.getQueryAttribute(
            'accountRequiresVatType',
            -1
        );
        let url = 'account';
        if (accountType !== -1) {
            url = tlxUrl.addUrlParameter(url, 'accountType', accountType);
        }
        if (accountSubtype !== -1) {
            url = tlxUrl.addUrlParameters(
                url,
                'accountSubType',
                accountSubtype,
                'lockSubType',
                'true'
            );
        } else {
            url = tlxUrl.addUrlParameters(
                url,
                'accountType',
                '1',
                'accountSubType',
                '4000'
            );
        }
        if (requiresVat !== -1) {
            url = tlxUrl.addUrlParameter(url, 'requiresVat', requiresVat);
        }

        return createCreateNewButton(setSelected, 'text_new_account', url);
    }
}

export const exports = {
    fetcher: AccountFetcher,
    type: 'Account',
    link: (id: number, contextId: number) =>
        `/execute/accountMenu?accountId=${id}&contextId=${contextId}`,
};

export const accountHelpExports = {
    fetcher: AccountFetcher,
    type: 'AccountHelp',
    link: (id: number, contextId: number) =>
        `/execute/accountMenu?accountId=${id}&contextId=${contextId}`,
};
