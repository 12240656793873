import { ProductDiscountPageLayout } from '@Page/LogisticsV2/pages/Product discount/overview/ProductDiscountComponents';
import { ProductDiscountTableWithFilters } from '@Page/LogisticsV2/pages/Product discount/overview/table/ProductDiscountTableWithFilters';
import './ProductDiscount.css';

export function ProductDiscountPage() {
    return (
        <ProductDiscountPageLayout>
            <ProductDiscountTableWithFilters />
        </ProductDiscountPageLayout>
    );
}
