import { Input, TestableProps } from '@tlx/atlas';
import React, { useState } from 'react';
import { evaluateExpression } from '@General/math';
import { usePrevious } from '@Page/VatReturns2/hooks/usePrevious';
import { getLocale } from '@Page/VatReturns2/utils/vatReturnsUtils';
import { useDeviceType } from '@Component/Responsive/useDeviceType';

type Props = {
    disabled?: boolean;
    readonly?: boolean;
    value?: number;
    minDecimals?: number;
    maxDecimals?: number;
    onChange?: (value: number | undefined) => void;
    onBlur?: (value: number | undefined) => void;
    maxLength?: number;
    className?: string;
} & TestableProps;

export const NumberInput = React.forwardRef<HTMLInputElement, Props>(function (
    {
        value,
        onChange,
        onBlur,
        minDecimals = 0,
        maxDecimals = 2,
        maxLength,
        ...props
    }: Props,
    ref
) {
    const [hasFocus, setHasFocus] = useState(false);

    const v = roundN(value, maxDecimals);
    const previous = usePrevious(v);
    const [internalValue, setInternalValue] = useState(v);

    const [text, setText] = useState(formatN(v, 0, maxDecimals, false));

    const device = useDeviceType();

    if (v !== previous && v !== internalValue) {
        setInternalValue(v);
        setText(formatN(v, 0, maxDecimals, false));
    }

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        event.preventDefault();
        const newText = event.target.value
            .replace(/[^\d^.,+*/()]/, '') // Whitelists digits and the symbols: . , + - * / ( ) for decimals and arithmetic
            .replace(/[,.]/g, window.decimalSeparator) // changes all occurences of , or . into the language decimalSeparator of the user.
            .replace(/^(?:0+(?=[1-9])|0+(?=0$))/gm, '');
        if (newText !== text) {
            setText(newText);
            const newValue = parseInput(newText, maxDecimals);
            setInternalValue(newValue);
            if (value !== newValue) {
                onChange?.(newValue);
            }
        }
    }

    function handleFocus(event: React.FocusEvent<HTMLInputElement>) {
        setHasFocus(true);
        if (device !== 'mobile') {
            event?.target?.select();
        }
    }

    function handleBlur() {
        const newValue = parseInput(text, maxDecimals);
        setInternalValue(newValue);
        if (value !== newValue) {
            onChange?.(newValue);
            onBlur?.(newValue);
        }
        setHasFocus(false);
    }

    const formattedText = formatN(
        internalValue,
        minDecimals,
        maxDecimals,
        true
    );

    const displayText = hasFocus ? text : formattedText;
    return (
        <Input
            type="text"
            inputMode="numeric"
            value={displayText}
            ref={ref}
            onChange={handleChange}
            onBlur={handleBlur}
            onFocus={(e) => handleFocus(e)}
            maxLength={maxLength}
            {...props}
        />
    );
});

function parseInput(text: string, decimals: number) {
    if (text.charAt(0) === window.decimalSeparator) {
        text = '0'.concat(text);
    }
    if (text.charAt(text.length - 1) === window.decimalSeparator) {
        text = text.concat('0'.repeat(decimals));
    }
    return roundN(evaluateExpression(text), decimals);
}

function formatN(
    value: number | undefined,
    minimumFractionDigits: number,
    maximumFractionDigits: number,
    useGrouping: boolean
) {
    if (value != undefined && Number.isFinite(value)) {
        return Intl.NumberFormat(getLocale(), {
            minimumFractionDigits,
            maximumFractionDigits,
            useGrouping,
        }).format(value);
    }

    return '';
}

function roundN(value: number | undefined, decimals: number) {
    const factor = Math.pow(10, decimals);
    return value !== undefined
        ? Math.round(value * factor) / factor
        : undefined;
}
