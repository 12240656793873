import { mergeAll } from '@General/Merge';
import { CSRF_HEADER_NAME, getCSRFToken } from '../../../js/modules/csrf';
import { clearChanged } from '../../../js/modules/change';
import { showValidationMessage } from '../../../js/modules/notification';

export type Methods = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH' | 'OPTIONS';

export type Options = RequiredOptions & OptionsWithDefaults & OptionalOptions;

interface RequiredOptions {
    url: string;
}

interface OptionsWithDefaults {
    method?: Methods;
    callback?: (json: ApiResponse, err: Error | null) => any;
}

interface OptionalOptions {
    body?: Record<string, any> | Array<Record<string, any>> | string | FormData;
    readonly validationErrorCallback?: (response: ApiErrorResponse) => any;
    readonly signal?: AbortSignal | null;
    readonly ignoreValidationException?: boolean;
    readonly noDialogForUnexpectedException?: boolean;
}

export type ValidationMessage = {
    field: string;
    message: string;
    path?: string;
    rootId?: number;
};
export type ApiValidResponse = any;

export interface ApiErrorResponse {
    status: number;
    code: number;
    message: string;
    link: string;
    developerMessage: string;
    validationMessages: Array<ValidationMessage>;
    requestId: string;
}

export interface StandardResponse<T> {
    response?: T | ApiErrorResponse;
    err?: any;
}

interface FieldObject {
    [field: string]: AField;
}

type AField = FieldObject | Array<string> | true;

export function fieldsBuilder(fields: AField): string {
    if (typeof fields === 'boolean') {
        return '';
    }

    if (Array.isArray(fields)) {
        if (fields.length === 0) {
            return '';
        }

        return fields.join(',');
    }
    return Object.entries(fields)
        .map(([key, value]) => {
            const processed = fieldsBuilder(value);
            if (processed.length > 0) {
                return `${key}(${processed})`;
            } else {
                return key;
            }
        })
        .join(',');
}

export function isApiErrorResponse(
    response: ApiResponse
): response is ApiErrorResponse {
    return (
        response &&
        typeof response === 'object' &&
        'status' in response &&
        'code' in response &&
        'message' in response
    );
}

export function isValidApiResponse<T>(
    response: T | ApiErrorResponse
): response is T {
    return !isApiErrorResponse(response);
}

export function StandardResponseValueExtractor<TResponse = any>(
    json: ApiResponse,
    err: Error | null | ApiErrorResponse
): StandardResponse<TResponse> {
    let response = json;
    if (json?.values !== undefined) {
        response = json.values;
    } else if (json?.value !== undefined) {
        response = json.value;
    }
    return { response, err };
}

export type ApiResponse = ApiValidResponse | ApiErrorResponse;

const ValidationException = (json: ApiErrorResponse) => {
    for (const { message } of json.validationMessages) {
        showValidationMessage({ message });
    }

    const errorMessageWithCount = getMessage(
        'validation_popup_number_of_errors',
        `${json.validationMessages.length}`
    );

    showValidationMessage({
        message: errorMessageWithCount,
    });
};

export default async function tlxFetch(configuration: Options) {
    const defaultOptions: Required<OptionsWithDefaults> = {
        method: 'GET',
        callback: StandardResponseValueExtractor,
    };

    const options = mergeAll(defaultOptions, configuration);

    if (options.body && !(options.body instanceof FormData)) {
        options.body = JSON.stringify(options.body);
    }

    const headers: { [key: string]: string } = {};
    if (!(options.body instanceof FormData)) {
        headers['content-type'] = 'application/json; charset=utf-8';
    }

    return fetch(options.url, {
        credentials: 'same-origin',
        headers: {
            'x-tlx-context-id': `${contextId}`,
            accept: 'application/json; charset=utf-8',
            ...headers,
            [CSRF_HEADER_NAME]: getCSRFToken(),
        },
        ...(options as RequestInit),
    })
        .then((response) => {
            if (response.ok) {
                if (response.status === 204) {
                    return response;
                }

                // This is related to the validation_content_change message shown to users
                // if they try to navigate to another page while page has unsaved changes.
                // Assume page has no unsaved changes after a PUT or POST request.
                if (options.method === 'PUT' || options.method === 'POST') {
                    clearChanged();
                }

                return response.json();
            }

            if (
                options.noDialogForUnexpectedException !== true &&
                response.status === 500
            ) {
                tlxAlert(
                    getMessage('text_unexpected_error_has_occured', contextId)
                );
            }

            if (response.status === 422) {
                return response.json();
            }

            if (response.status === 401 || response.status === 403) {
                return response.json();
            }

            if (response.status === 409) {
                tlxAlert(getMessage('validation_revision_error'));
            }

            throw new Error(`${response.status}: ${response.statusText}`);
        })
        .then((json: ApiResponse) => {
            // Handel validationExeption
            if (json.status === 422) {
                if (json.code === 18000) {
                    if (!options.ignoreValidationException) {
                        ValidationException(json);
                    }
                    if (options.validationErrorCallback) {
                        return options.validationErrorCallback(json);
                    }
                } else if (json.code === 23000) {
                    // eslint-disable-next-line no-console
                    console.error(
                        `code: ${json.status} ${json.code} \n message: ${json.message}`
                    );
                    return options.callback(json, new Error(json.message));
                } else {
                    tlxAlert(
                        getMessage(
                            'text_unexpected_error_has_occured',
                            contextId
                        )
                    );
                }
                return options.callback(json, new Error(json.message));
            } else if (json.status === 401 || json.status === 403) {
                if (json.code !== 3000) {
                    tlxAlert(getMessage('text_access_denied'));
                }
                throw new Error(`${json.status}: ${json.statusText}`);
            }

            return options.callback(json, null);
        })
        .catch((err) => options.callback(null, err));
}
