import ReactDOM from 'react-dom';
import { Root, createRoot } from 'react-dom/client';

const roots = new WeakMap<HTMLElement, Root>();

// To use for React 18 upgrade because of hard use of ReactDOM.render and ReactDOM.unmountComponentAtNode -> this is to enable us to use React 18's createRoot and unmount methods instead
export function mount(element: HTMLElement, component: React.ReactElement) {
    if (!window.killSwitches.React18Mount) {
        ReactDOM.render(component, element);
        return;
    }

    let root = roots.get(element);

    if (!root) {
        root = createRoot(element);
        roots.set(element, root);
    }

    root.render(component);
}

export function unmount(element: HTMLElement) {
    // Following what storybook did https://github.com/storybookjs/storybook/pull/21214
    // which follows what is written here https://github.com/facebook/react/issues/25675#issuecomment-1363957941
    window.setTimeout(() => {
        if (!window.killSwitches.React18Mount) {
            ReactDOM.unmountComponentAtNode(element);
            return;
        }

        const root = roots.get(element);

        if (root) {
            root.unmount();
            roots.delete(element);
        } else {
            console.warn('Unmounting a non-mounted element', { element });
        }
    }, 0);
}
