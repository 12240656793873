import {
    Status,
    StatusDot,
    StatusMenu,
    StatusMenuDrawer,
    StatusMenuItem,
    StatusMenuItemText,
    StatusMenuOpener,
} from '@tlx/atlas';
import { ProductImportRowErrorMessage } from '@Page/LogisticsV2/pages/Import v2/types';
import './style.css';
import {
    mapStatusColor,
    mapTextToStatus,
} from '@Page/LogisticsV2/pages/Import v2/utils';

export function ValidationStatus({
    status,
    errorMessage,
}: {
    status: string;
    errorMessage: ProductImportRowErrorMessage[];
}) {
    const text =
        errorMessage?.length > 0
            ? errorMessage[0].errorMessage
            : getMessage(mapTextToStatus.get(status) ?? '');

    return (
        <div style={{ maxWidth: '250px' }} className={'atl-truncate'}>
            {errorMessage?.length > 1 ? (
                <StatusMenu>
                    <div className="goldshark-status">
                        <StatusMenuOpener>
                            <Status count={errorMessage.length} variant="error">
                                {text}
                            </Status>
                        </StatusMenuOpener>
                    </div>
                    <StatusMenuDrawer>
                        {errorMessage.map((error, index) => (
                            <StatusMenuItem key={index}>
                                <StatusDot variant="error" />
                                <StatusMenuItemText>
                                    {error.errorMessage}
                                </StatusMenuItemText>
                            </StatusMenuItem>
                        ))}
                    </StatusMenuDrawer>
                </StatusMenu>
            ) : (
                <Status variant={mapStatusColor.get(status) ?? 'error'}>
                    {text}
                </Status>
            )}
        </div>
    );
}
