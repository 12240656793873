import { ModelAPIV1 } from '@General/types/Api';
import { Option } from '../../types';
import { notUndefined } from '@General/Helpers';
import { JSPDropdownFetcher } from '../JSPDropdownFetcher';
import { ApiResultMapper } from '@General/Provider';
import * as React from 'react';

interface HeatfloorProductEntry extends ModelAPIV1 {
    readonly elNumber: string;
    readonly vktype: string;
    readonly manufacturer: string;
}

const heatfloorProductMapper: ApiResultMapper<HeatfloorProductEntry> = (
    item
) => ({
    value: item.id + '',
    displayName: `${item.elNumber} — ${item.vktype}`,
    secondary: item.manufacturer,
    item,
});

class HeatfloorProductFetcher extends JSPDropdownFetcher<HeatfloorProductEntry> {
    asyncJsonrpcGetter: Function;

    constructor(scope: HTMLDivElement, queryAttributes: Record<string, any>) {
        super(scope, queryAttributes, heatfloorProductMapper);

        this.asyncJsonrpcGetter = asyncrpc.HeatfloorProduct.get;
    }

    getMarshallSpec() {
        return window.marshallSpec(
            'id',
            'elNumber',
            'vktype',
            'manufacturer',
            'displayName'
        );
    }

    getParams(): ObjectIndex {
        return {};
    }

    async fetchList(
        params: ObjectIndex
    ): Promise<Option<HeatfloorProductEntry>[]> {
        return new Promise((resolve, reject) => {
            try {
                jsonrpc.HeatfloorProduct.searchForHeatfloorProduct(
                    (result: HeatfloorProductEntry[] | null) => {
                        const mapped = (result ?? [])
                            .filter(notUndefined)
                            .map(this.getMapper(params))
                            .filter(notUndefined);
                        resolve(mapped);
                    },
                    this.getMarshallSpec(),
                    params.name || '',
                    -1,
                    -1
                );
            } catch (e) {
                defaultJsonRpcExceptionHandler(e);
                reject(e);
            }
        });
    }
}

export const exports = {
    fetcher: HeatfloorProductFetcher,
    type: 'HeatfloorProduct',
};
