import { Button } from '@tlx/atlas';

export function ChangeProductGroupsActions({
    disabled,
    onClose,
    onConfirm,
}: {
    disabled: boolean;
    onClose: () => void;
    onConfirm: () => void;
}) {
    return (
        <div className="atl-flex atl-gap-8 atl-mt-24 atl-mx-32">
            <Button
                data-testid={'confirm-product-groups-change'}
                onClick={onConfirm}
                disabled={disabled}
            >
                {getMessage('text_confirm')}
            </Button>
            <Button
                data-testid={'cancel-warning-modal'}
                onClick={onClose}
                variant={'secondary'}
            >
                {getMessage('button_cancel')}
            </Button>
        </div>
    );
}
