import { ApiResponse, ValidationMessage } from '../../../api/tlxFetch';

export const getValidationMessage = (
    { validationMessages }: ApiResponse,
    name: string
): string => {
    const result = validationMessages.find((validation: ValidationMessage) =>
        validation.field.toLowerCase().includes(name.toLowerCase())
    );

    return result?.message || '';
};
