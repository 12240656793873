import { useStocktakingDetailsTableFilters } from '@Page/LogisticsV2/pages/Stocktaking/details2/useStocktakingDetailsTableFilters';
import { useInfiniteScrollData } from '@Page/LogisticsV2/hooks/api';
import {
    ProductLineDTO,
    STOCKTAKING_FIELDS,
} from '@Page/LogisticsV2/pages/Stocktaking/utils';
import { ApiUrl } from '@Page/LogisticsV2/utils/enums';
import { getIdFromUrl } from '@Page/LogisticsV2/utils/functions';

export function useStocktakingDetailsTableData() {
    const stocktakingId = getIdFromUrl('inventoryId');
    const filters = useStocktakingDetailsTableFilters();
    return useInfiniteScrollData<ProductLineDTO>(
        stocktakingId !== null
            ? ApiUrl.INVENTORY_STOCKTAKING_PRODUCTLINE
            : undefined,
        {
            fields: STOCKTAKING_FIELDS,
            query: filters.queryFilter,
            productGroupIds: filters.productGroupsFilter,
            locationIds: filters.locationFilter,
            stocktakingId: stocktakingId,
            productLineStatus: filters.statusFilter,
            sorting: filters.sorting,
        }
    );
}
