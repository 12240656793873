import {
    useContext,
    useEffect,
    useMemo,
    useState,
    useReducer,
    Reducer,
} from 'react';
import {
    CurrencyTypeCodeEnum,
    PAYMENT_TYPE_NOT_PAID,
    PaymentIntegration,
    PaymentType,
    PaymentTypeEnum,
    PaymentWidgetAdvancedData,
    PaymentWidgetValidations,
    Scenario,
} from '@Page/PaymentWidget/types/paymentWidgetTypes';
import { putNotification } from '@Page/Expense/util/helper.functions';
import tlxFetch from '../../../api/tlxFetch';
import { PaymentWidgetContext } from '../../../../../js/component/paymentWidget/paymentWidget';
import {
    CreditNotesDetailsProps,
    OppositeAmountProps,
} from '@Page/PaymentWidget/component/AdvancedPaymentWidgetTemplate';
import { CurrencyEnum } from '@General/PBC/Enums/Currency.enum';
import { getValidationMessage } from '@Page/PaymentWidget/hooks/utils';
import { usePaymentWidgetEventHandlers } from '@Page/PaymentWidget/hooks/usePaymentWidgetEventHandlers';
import { createAPIRequest, createAPIResponse } from '@tlx/astro-shared';

const initialState: Partial<PaymentWidgetAdvancedData> = {
    paymentTypes: [],
    selectedPaymentType: {
        paymentTypeId: 0,
        paymentTypeValue: '',
    },
    amount: 0,
    prepaidAmount: 0,
    date: '',
    kidPropertyName: '',
    accountNumber: '',
    creditorBic: '',
    kid: '',
    smartScanMapping: {},
    oppositeAmount: 0,
    currencyId: 1,
    currencyCode: 'NOK',
    isAutoPay: false,
    isZtl: false,
};

const vendorInitialState: Partial<PaymentWidgetAdvancedData> = {
    accountNumberPropertyName: '',
    isForeignPayment: false,
    creditorBankIdentificator: 0,
    creditorName: '',
    creditorAddress: '',
    creditorBankPostalCode: '',
    creditorBankPostalCity: '',
    comments: '',
    customerVendorIbanOrBban: [],
    creditorBankAddress: '',
    creditorBankName: '',
    creditorClearingCode: '',
    isCreditorAddressOnly: false,
    creditorCountryId: 0,
    creditorBankCountryId: 0,
    creditorPostalCode: '',
    creditorPostalCity: '',
    regulatoryReportingCode: '',
    regulatoryReportingInfo: '',
};

export type UseAdvancedPaymentWidget = {
    paymentWidgetData: PaymentWidgetAdvancedData;
    setPaymentWidgetData: (
        paymentWidgetData: Partial<PaymentWidgetAdvancedData>
    ) => void;
    registerPayment: () => Promise<void>;
    scenario: Scenario;
    validations?: PaymentWidgetValidations;
    setValidations?: (validations: PaymentWidgetValidations) => void;
    oppositeAmount?: OppositeAmountProps;
    setOppositeAmount?: (oppositeAmount: OppositeAmountProps) => void;
    creditNotesDetails?: CreditNotesDetailsProps;
};

export function useAdvancedPaymentWidget(
    scenario: Scenario,
    vendorId?: number,
    invoiceId?: number,
    hasKid?: boolean,
    isOTPValidated?: boolean,
    voucherId = 0
): UseAdvancedPaymentWidget {
    const [paymentWidgetData, setPaymentWidgetData] = useReducer<
        Reducer<PaymentWidgetAdvancedData, Partial<PaymentWidgetAdvancedData>>
    >(
        (state, newState): PaymentWidgetAdvancedData => ({
            ...state,
            ...newState,
        }),
        {
            ...initialState,
            ...vendorInitialState,
        } as PaymentWidgetAdvancedData
    );

    const { creditNotesDetails } = usePaymentWidgetEventHandlers(
        paymentWidgetData,
        setPaymentWidgetData
    );

    const [oppositeAmount, setOppositeAmount] = useState<OppositeAmountProps>();
    const [validations, setValidations] = useState<PaymentWidgetValidations>({
        selectedPaymentType: '',
        date: '',
        amount: '',
        oppositeAmount: '',
        bankAccount: '',
        kid: '',
    });
    const {
        companyCurrencyId = CurrencyEnum.NOK,
        companyCurrencyCode = CurrencyTypeCodeEnum.NOK,
    } = useContext(PaymentWidgetContext);

    const computedOppositeAmount: OppositeAmountProps = useMemo(() => {
        const showOppositeAmount =
            (paymentWidgetData.currencyId !== companyCurrencyId ||
                (paymentWidgetData.selectedPaymentType.currencyId !== 0 &&
                    paymentWidgetData.selectedPaymentType.currencyId !==
                        companyCurrencyId)) &&
            paymentWidgetData.selectedPaymentType.paymentTypeId !==
                PAYMENT_TYPE_NOT_PAID;
        if (showOppositeAmount) {
            const oppositeAmountCurrencyCode =
                paymentWidgetData.currencyId !==
                paymentWidgetData.selectedPaymentType.currencyId
                    ? paymentWidgetData.selectedPaymentType.currencyCode
                    : companyCurrencyCode;

            const oppositeAmountDisabled =
                (paymentWidgetData.selectedPaymentType.paymentIntegration ===
                    PaymentTypeEnum.AUTOPAY ||
                    paymentWidgetData.selectedPaymentType.paymentIntegration ===
                        PaymentTypeEnum.ZTL) &&
                paymentWidgetData.currencyId !== companyCurrencyId;

            const oppositeCurrency =
                paymentWidgetData.currencyId ===
                paymentWidgetData.selectedPaymentType.currencyId
                    ? companyCurrencyId
                    : paymentWidgetData.selectedPaymentType.currencyId;
            const amount = jsonrpc.CurrencyExchangeRate.calcAmount(
                paymentWidgetData.currencyId,
                oppositeCurrency,
                paymentWidgetData.date,
                paymentWidgetData.amount
            );
            return {
                showOppositeAmount: showOppositeAmount,
                currencyId: oppositeCurrency,
                currencyCode: oppositeAmountCurrencyCode,
                disabled: oppositeAmountDisabled,
                amount: amount,
            };
        }
        return { showOppositeAmount: showOppositeAmount, amount: 0 };
    }, [
        companyCurrencyCode,
        companyCurrencyId,
        paymentWidgetData.amount,
        paymentWidgetData.currencyId,
        paymentWidgetData.selectedPaymentType.currencyCode,
        paymentWidgetData.selectedPaymentType.currencyId,
        paymentWidgetData.selectedPaymentType.paymentIntegration,
        paymentWidgetData.selectedPaymentType.paymentTypeId,
        paymentWidgetData.date,
    ]);

    useEffect(() => {
        setOppositeAmount(computedOppositeAmount);
    }, [computedOppositeAmount]);

    function matchKidPropertyName(paymentTypeId: number): string {
        const isReceiverReference =
            paymentWidgetData.isForeignPayment ||
            scenario === Scenario.INVOICE_REIMBURSEMENT_DIALOG;
        if (
            paymentTypeId <= PaymentIntegration.AUTOPAY_RANGE_START &&
            paymentTypeId > PaymentIntegration.AUTOPAY_RANGE_END
        ) {
            return isReceiverReference
                ? 'paymentWidgetWrapper.autoPayTransaction.receiverReference'
                : 'paymentWidgetWrapper.autoPayTransaction.kidOrReceiverReference';
        }

        if (paymentTypeId == -1) {
            return 'remit.kidOrReceiverReference';
        }

        if (paymentTypeId <= PaymentIntegration.ZTL_RANGE_START) {
            return isReceiverReference
                ? 'paymentWidgetWrapper.ztlTransaction.receiverReference'
                : 'paymentWidgetWrapper.ztlTransaction.kidOrReceiverReference';
        }

        return '';
    }

    function matchAccountNumberPropertyName(paymentTypeId: number): string {
        if (
            paymentTypeId <= PaymentIntegration.AUTOPAY_RANGE_START &&
            paymentTypeId > PaymentIntegration.AUTOPAY_RANGE_END
        ) {
            return 'paymentWidgetWrapper.autoPayTransaction.creditorIbanOrBban';
        }

        if (paymentTypeId == -1) {
            return 'remit.receiverBankAccount';
        }

        if (paymentTypeId <= PaymentIntegration.ZTL_RANGE_START) {
            return 'paymentWidgetWrapper.ztlTransaction.creditorIbanOrBban';
        }

        return '';
    }

    useEffect(() => {
        setPaymentWidgetData({
            kidPropertyName: matchKidPropertyName(
                paymentWidgetData.selectedPaymentType.paymentTypeId
            ),
            accountNumberPropertyName: matchAccountNumberPropertyName(
                paymentWidgetData.selectedPaymentType.paymentTypeId
            ),
        });
    }, [
        paymentWidgetData.selectedPaymentType,
        paymentWidgetData.isForeignPayment,
    ]);

    useEffect(() => {
        async function fetchData() {
            const { response } = await tlxFetch({
                url: `/v2/bank/paymentWidget/advanced?scenario=${scenario}&supplierId=${vendorId}&invoiceId=${invoiceId}&voucherId=${voucherId}`,
            });

            if (response) {
                let selectedPaymentType = response.selectedPaymentType;
                if (response.amount < 0) {
                    const notPaidPaymentType = response.paymentTypes.find(
                        (paymentType: PaymentType) =>
                            paymentType.paymentIntegration ===
                            PaymentTypeEnum.NOT_PAID
                    );
                    selectedPaymentType =
                        notPaidPaymentType ?? selectedPaymentType;
                }
                let newData: Partial<PaymentWidgetAdvancedData> = {
                    paymentTypes: response.paymentTypes,
                    selectedPaymentType: selectedPaymentType,
                    ...(!!response.amount && {
                        amount: response.amount,
                    }),
                    ...(!!response.prepaidAmount && {
                        prepaidAmount: response.prepaidAmount,
                    }),
                    ...(!!response.date && {
                        date: response.date,
                    }),
                    ...(response.currencyId > 0 &&
                        response.currencyCode && {
                            currencyId: response.currencyId,
                            currencyCode: response.currencyCode,
                        }),
                    isAutoPay: response.isAutoPay,
                    isZtl: response.isZtl,
                    ...(!!response.accountNumber && {
                        accountNumber: response.accountNumber,
                    }),
                    ...(!!response.creditorBic && {
                        creditorBic: response.creditorBic,
                    }),
                    ...(typeof response.smartScan === 'boolean' && {
                        smartScan: response.smartScan,
                    }),
                    ...(response.smartScanMapping && {
                        smartScanMapping: response.smartScanMapping,
                    }),
                    ...(!!response.kid && {
                        kid: response.kid,
                    }),
                };

                if (vendorId) {
                    newData = {
                        ...newData,
                        customerVendorIbanOrBban:
                            response.customerVendorIbanOrBban || [],
                    };
                    if (response.isAutoPay || response.isZtl) {
                        newData = {
                            ...newData,
                            creditorName: response.creditorName || '',
                            creditorAddress: response.creditorAddress || '',
                            creditorPostalCode:
                                response.creditorPostalCode || '',
                            creditorPostalCity:
                                response.creditorPostalCity || '',
                            creditorCountryId: response.creditorCountryId,
                            creditorBic: response.creditorBic || '',
                            isForeignPayment: response.isForeignPayment,
                            regulatoryReportingCode:
                                response.regulatoryReportingCode,
                            regulatoryReportingInfo:
                                response.regulatoryReportingInfo,
                        };
                        if (!response.isCreditorAddressOnly) {
                            newData = {
                                ...newData,
                                creditorBankIdentificator:
                                    response.creditorBankIdentificator,
                                creditorClearingCode:
                                    response.creditorClearingCode || '',
                                creditorBankName:
                                    response.creditorBankName || '',
                                creditorBankAddress:
                                    response.creditorBankAddress || '',
                                creditorBankPostalCode:
                                    response.creditorBankPostalCode || '',
                                creditorBankPostalCity:
                                    response.creditorBankPostalCity || '',
                                creditorBankCountryId:
                                    response.creditorBankCountryId,
                            };
                        }
                    }
                } else {
                    newData = {
                        ...newData,
                        ...vendorInitialState,
                        regulatoryReportingCode:
                            response.regulatoryReportingCode,
                        regulatoryReportingInfo:
                            response.regulatoryReportingInfo,
                    };
                }
                setPaymentWidgetData(newData);
            }
        }
        fetchData();
    }, [invoiceId, scenario, vendorId, voucherId]);

    const postRegisterPayment = async (
        body: Partial<PaymentWidgetAdvancedData>
    ): Promise<void> => {
        const request = createAPIRequest(
            `/v2/bank/paymentWidget/advanced?scenario=${scenario}&invoiceId=${invoiceId}&hasKid=${hasKid}&isOTPValidated=${isOTPValidated}`,
            {
                headers: {
                    'content-type': 'application/json',
                },
                body: JSON.stringify(body),
                method: 'POST',
            }
        );
        const response = await fetch(request);
        return createAPIResponse(request, response);
    };

    async function registerPayment(): Promise<void> {
        let paymentBody: Partial<PaymentWidgetAdvancedData> = {
            selectedPaymentType: paymentWidgetData.selectedPaymentType,
            isForeignPayment: paymentWidgetData.isForeignPayment,
            creditorBankIdentificator:
                paymentWidgetData.creditorBankIdentificator,
            accountNumber: paymentWidgetData.accountNumber || '',
            kid: paymentWidgetData.kid || '',
            amount: paymentWidgetData.amount || 0,
            oppositeAmount: oppositeAmount?.amount || 0,
            date: paymentWidgetData.date,
            creditorName: paymentWidgetData.creditorName,
        };
        if (paymentWidgetData.isForeignPayment) {
            paymentBody = {
                ...paymentBody,
                creditorAddress: paymentWidgetData.creditorAddress,
                creditorPostalCode: paymentWidgetData.creditorPostalCode,
                creditorPostalCity: paymentWidgetData.creditorPostalCity,
                creditorCountryId: paymentWidgetData.creditorCountryId,
                creditorBankCountryId: paymentWidgetData.creditorBankCountryId,
                creditorBankName: paymentWidgetData.creditorBankName,
                creditorBankAddress: paymentWidgetData.creditorBankAddress,
                creditorBankPostalCode:
                    paymentWidgetData.creditorBankPostalCode,
                creditorBankPostalCity:
                    paymentWidgetData.creditorBankPostalCity,
                creditorBic: paymentWidgetData.creditorBic,
                creditorClearingCode: paymentWidgetData.creditorClearingCode,
                regulatoryReportingCode:
                    paymentWidgetData.regulatoryReportingCode,
                regulatoryReportingInfo:
                    paymentWidgetData.regulatoryReportingInfo,
            };
        }

        return postRegisterPayment(paymentBody)
            .then(() => {
                putNotification(
                    [
                        getMessage('text_action_completed'),
                        getMessage(
                            scenario === Scenario.INVOICE_REIMBURSEMENT_DIALOG
                                ? 'text_reimbursement'
                                : 'text_register_payment'
                        ),
                    ].join(' ')
                );

                window.location.reload();
                return Promise.resolve();
            })
            .catch(({ details: response }) => {
                if (response?.status === 422) {
                    setValidations({
                        selectedPaymentType: getValidationMessage(
                            response,
                            'paymentType'
                        ),
                        date: getValidationMessage(response, 'date'),
                        amount:
                            getValidationMessage(response, 'amount') ||
                            getValidationMessage(response, 'currencyId'),
                        oppositeAmount: getValidationMessage(
                            response,
                            'oppositeAmountCurrency'
                        ),
                        bankAccount:
                            getValidationMessage(
                                response,
                                'receiverBankAccount'
                            ) ||
                            getValidationMessage(
                                response,
                                'creditorIbanOrBban'
                            ),
                        kid: getValidationMessage(response, 'kid'),
                        creditorName: getValidationMessage(
                            response,
                            'creditorName'
                        ),
                        creditorAddress: getValidationMessage(
                            response,
                            'creditorAddress'
                        ),
                        creditorPostalCity: getValidationMessage(
                            response,
                            'creditorPostalCity'
                        ),
                        creditorPostalCode: getValidationMessage(
                            response,
                            'creditorPostalCode'
                        ),
                        creditorCountryId: getValidationMessage(
                            response,
                            'creditorCountryId'
                        ),
                        creditorBankCountryId: getValidationMessage(
                            response,
                            'creditorBankCountryId'
                        ),
                        creditorBic: getValidationMessage(
                            response,
                            'creditorBIC'
                        ),
                        creditorClearingCode: getValidationMessage(
                            response,
                            'creditorClearingCode'
                        ),
                    });
                } else if (response?.status === 403) {
                    tlxAlert(
                        getMessage('text_access_denied', window.contextId)
                    );
                } else {
                    tlxAlert(
                        getMessage(
                            'text_unexpected_error_has_occured',
                            window.contextId
                        )
                    );
                }
                return Promise.reject();
            });
    }

    return {
        paymentWidgetData,
        setPaymentWidgetData,
        registerPayment,
        scenario,
        validations,
        setValidations,
        oppositeAmount,
        setOppositeAmount,
        creditNotesDetails,
    };
}
