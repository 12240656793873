import React from 'react';

type SignerTableProps = {
    children: React.ReactNode;
    hideEmailLabel: boolean;
    hideThisIsMeLabel: boolean;
};

export function SignerTable({
    children,
    hideEmailLabel,
    hideThisIsMeLabel,
}: SignerTableProps) {
    return (
        <div
            className="atl-table-container atl-overflow-auto"
            style={{ maxHeight: '60vh' }}
        >
            <table className="atl-table atl-table-fixed">
                <thead className="atl-thead atl-thead--sticky">
                    <tr className="atl-tr">
                        <th className="atl-th atl-align-middle">
                            {getMessage('text_name')}
                        </th>
                        <th className="atl-th atl-align-middle">
                            <div style={{ minWidth: 120 }}>
                                {`${
                                    hideThisIsMeLabel
                                        ? ''
                                        : getMessage('text_this_is_me')
                                }`}
                            </div>
                        </th>
                        <th className="atl-th atl-align-middle">
                            {`${
                                hideEmailLabel ? '' : getMessage('text_email2')
                            }`}
                        </th>
                        <th className="atl-th atl-pr-8 atl-text-right"></th>
                    </tr>
                </thead>
                <tbody>{children}</tbody>
            </table>
        </div>
    );
}
