import { Button, Icon } from '@tlx/atlas';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { DistributionKeyItemDetails } from '@Page/DistributionKeys/components/rule/DistributionKeyItemDetails';
import { DistributionKey } from '@Page/DistributionKeys/models/DistributionKeys';
import { ApiAction } from '@Page/DistributionKeys/models/Actions';

export type DistributionKeysKeyProps = {
    distributionKey?: DistributionKey;
    setOpenModal: Dispatch<SetStateAction<boolean>>;
    setSelectedDistributionKey: Dispatch<
        SetStateAction<DistributionKey | undefined>
    >;
    dispatch: (apiAction: ApiAction, distributionKey: DistributionKey) => void;
};

export const DistributionKeysKey = ({
    distributionKey,
    setOpenModal,
    setSelectedDistributionKey,
    dispatch,
}: DistributionKeysKeyProps) => {
    const [isExpanded, setExpanded] = useState(false);

    return (
        <>
            {distributionKey && distributionKey.distributionKeyBlade && (
                <tr className="atl-tr atl-tr--highlight">
                    <td className="atl-td atl-td--button">
                        <Button
                            variant="tertiary"
                            data-testid="distribution-keys-expand-key"
                            onClick={() => setExpanded(!isExpanded)}
                        >
                            <Icon
                                style={{
                                    transform: `rotate(${
                                        isExpanded ? 180 : 0
                                    }deg)`,
                                    transition: 'all 0.25s',
                                }}
                            >
                                expand_more
                            </Icon>
                        </Button>
                    </td>
                    <td className="atl-td" colSpan={4}>
                        {distributionKey?.name}
                    </td>
                    <td className="atl-td atl-td--button">
                        <Button
                            variant="tertiary"
                            data-testid="distribution-keys-update-key"
                            onClick={() => {
                                setSelectedDistributionKey({
                                    ...distributionKey,
                                });
                                setOpenModal(true);
                            }}
                        >
                            <Icon>edit</Icon>
                        </Button>
                    </td>
                    <td className="atl-td atl-td--button">
                        <Button
                            variant="tertiary"
                            data-testid="distribution-keys-delete-key"
                            onClick={() => dispatch('DELETE', distributionKey)}
                        >
                            <Icon
                                style={{
                                    color: 'red',
                                }}
                            >
                                delete
                            </Icon>
                        </Button>
                    </td>
                </tr>
            )}
            {isExpanded &&
                distributionKey &&
                distributionKey.distributionKeyBlade?.distributionKeyBits.map(
                    (bit) => (
                        <DistributionKeyItemDetails
                            key={bit.departmentId}
                            distributionKeyBit={bit}
                        />
                    )
                )}
        </>
    );
};
