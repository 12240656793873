import { useCallback, useState } from 'react';
import { DistributionKey } from '@Page/DistributionKeys/models/DistributionKeys';
import { createAPIRequest } from '../../../../hooks/fetch/createAPIRequest';
import { createAPIResponse } from '../../../../hooks/fetch/createAPIResponse';
import { ResponseWrapper } from '../../../../hooks/fetch/types';
import { ApiAction } from '@Page/DistributionKeys/models/Actions';

export const useDistributionKeyApi = () => {
    const [error, setError] = useState<any>(undefined);

    const distributionKeyApiAction = useCallback(
        async (apiAction: ApiAction, distributionKey: DistributionKey) => {
            setError(undefined);
            try {
                return await internalDistributionKeyApiAction(
                    apiAction,
                    distributionKey
                );
            } catch (error) {
                setError(error);
                throw error;
            }
        },
        []
    );

    return { distributionKeyApiAction, distributionKeyApiActionError: error };
};

const internalDistributionKeyApiAction = async (
    apiAction: ApiAction,
    distributionKey: DistributionKey
) => {
    if (!distributionKey.id && apiAction !== 'POST') {
        throw 'PUT or DELETE call needs an id';
    }

    if (!distributionKey || !distributionKey.distributionKeyBlade) {
        throw 'Key blade is undefined';
    }

    const body: {
        id?: number;
        name?: string;
        distributionKeyBlade: {
            distributionKeyBits: {
                departmentId?: number;
                departmentName?: string;
                percentage?: number;
            }[];
        };
    } = {
        ...distributionKey,
        distributionKeyBlade: {
            distributionKeyBits:
                distributionKey.distributionKeyBlade.distributionKeyBits.map(
                    (bit) => {
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        const { tempId, ...cleanBit } = bit;
                        return cleanBit;
                    }
                ),
        },
    };

    const requestBody =
        apiAction !== 'DELETE'
            ? {
                  body: JSON.stringify(body),
                  headers: { 'content-type': 'application/json' },
              }
            : {};

    const request = createAPIRequest(
        `/v2/distributionKey${
            apiAction !== 'POST' ? '/' + distributionKey.id : ''
        }`,
        {
            ...requestBody,
            method: apiAction,
        }
    );

    const response = await fetch(request);
    const data = await createAPIResponse<ResponseWrapper<DistributionKey>>(
        request,
        response
    );
    return data?.value;
};
