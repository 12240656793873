import {
    Alert,
    AlertContent,
    Button,
    Icon,
    Input,
    Label,
    Option,
} from '@tlx/atlas';
import { useDepartmentDropdown } from '@Component/Dropdowns/DepartmentDropdown/useDepartmentDropdown';
import { getMessage } from '../../../../../../js/modules/getMessage';
import React, {
    ChangeEvent,
    Dispatch,
    SetStateAction,
    useEffect,
    useRef,
    useState,
} from 'react';
import {
    DistributionKey,
    DistributionKeyBit,
    HelperMessage,
} from '@Page/DistributionKeys/models/DistributionKeys';
import { MessageType } from '@tlx/atlas';
import { v4 as uuidv4 } from 'uuid';
import '../../DistributionKeys.css';
import { format } from '../../../../../../js/modules/format';
import { DepartmentDropdown } from '@Page/DistributionKeys/components/Dropdowns/DepartmentDropdown';
import { UseLoadableDropdownReturn } from '@Component/Dropdowns/types';
import { Department } from '../../../../hooks/fetch/department/types';
import { useId } from '../../../../util/useId';
import { FormattedNumberField } from '@Page/DistributionKeys/components/Dialog/FormattedNumberField';

export const DistributionKeysDialog = ({
    distributionKey,
    setDistributionKey,
    validationMessage,
    showValidation,
}: {
    distributionKey: DistributionKey;
    setDistributionKey: Dispatch<SetStateAction<DistributionKey>>;
    validationMessage?: HelperMessage;
    showValidation?: boolean;
}) => {
    return (
        <div className="atl-mb-32 atl-mx-auto ants-lock-w-600">
            <h1 className="atl-text-xl atl-font-medium atl-m-0">
                {distributionKey?.id
                    ? getMessage('text_distribution_key_update_key')
                    : getMessage('text_distribution_key_create_key')}
            </h1>
            <NameRow
                distributionKey={distributionKey}
                setDistributionKey={setDistributionKey}
            />
            <KeyBlade
                distributionKey={distributionKey}
                setDistributionKey={setDistributionKey}
            />
            {showValidation && (
                <ValidationAlert
                    distributionKey={distributionKey}
                    validationMessage={validationMessage}
                />
            )}
        </div>
    );
};

type NameRowProps = {
    distributionKey: DistributionKey;
    setDistributionKey: Dispatch<SetStateAction<DistributionKey>>;
};

const NameRow = ({ distributionKey, setDistributionKey }: NameRowProps) => {
    const nameChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.value.length > 255) {
            event.target.value = event.target.value.slice(0, 255);
        }
        setDistributionKey((prevState) => {
            return { ...prevState, name: event.target.value };
        });
    };

    const ref = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (ref.current) {
            ref.current.value =
                distributionKey.name !== undefined ? distributionKey.name : '';
        }
    }, [distributionKey]);

    return (
        <div>
            <h2 className="atl-font-normal atl-m-0">
                {getMessage('text_name')}
            </h2>
            <Input
                className="atl-input--text atl-w-full"
                data-testid="distribution-key-name-input"
                placeholder={getMessage('text_distribution_key_enter_name')}
                ref={ref}
                type="text"
                onChange={nameChange}
            />
        </div>
    );
};

const KeyBlade = ({
    distributionKey,
    setDistributionKey,
}: {
    distributionKey: DistributionKey;
    setDistributionKey: Dispatch<SetStateAction<DistributionKey>>;
}) => {
    const departments = useDepartmentDropdown();

    const addBitRow = () => {
        setDistributionKey((prevState) => {
            return {
                ...prevState,
                distributionKeyBlade: {
                    distributionKeyBits: [
                        ...prevState.distributionKeyBlade.distributionKeyBits,
                        { tempId: uuidv4() },
                    ],
                },
            };
        });
    };

    const id = useId();

    return (
        <table className="atl-table atl-my-32">
            <thead>
                <tr className="atl-tr">
                    <th className="atl-th atl-text-right atl-font-normal atl-text-lg atl-align-middle" />
                    <th className="atl-th atl-text-left atl-font-normal atl-text-lg atl-align-middle">
                        {getMessage('heading_department')}
                    </th>
                    <th className="atl-th atl-text-left atl-font-normal atl-text-lg atl-align-middle">
                        {getMessage('text_distribution')}
                    </th>
                    <th className="atl-th atl-text-left atl-align-middle" />
                </tr>
            </thead>
            <tbody>
                {distributionKey.distributionKeyBlade.distributionKeyBits.map(
                    (bit, index) => (
                        <KeyBitRow
                            departments={departments}
                            key={bit.tempId}
                            rowIndex={index}
                            distributionKey={distributionKey}
                            distributionKeyBit={bit}
                            setDistributionKey={setDistributionKey}
                        />
                    )
                )}
                <tr className="atl-tr">
                    <td className="atl-td">
                        <Button
                            className="atl-p-4"
                            id={id}
                            variant="tertiary"
                            data-testid="distribution-key-add-key-bit"
                            onClick={addBitRow}
                        >
                            <Icon>add</Icon>
                        </Button>
                    </td>
                    <td className="atl-td atl-align-middle" colSpan={10}>
                        <Label htmlFor={id}>
                            {getMessage('button_add_subgroup')}
                        </Label>
                    </td>
                </tr>
            </tbody>
        </table>
    );
};

const KeyBitRow = ({
    distributionKey,
    rowIndex,
    distributionKeyBit,
    setDistributionKey,
    departments,
}: {
    distributionKey?: DistributionKey;
    rowIndex: number;
    distributionKeyBit: DistributionKeyBit;
    setDistributionKey: Dispatch<SetStateAction<DistributionKey>>;
    departments: UseLoadableDropdownReturn<Department>;
}) => {
    useEffect(() => {
        if (distributionKeyBit.departmentId) {
            departments.items.loadMore();
        }
    }, [departments, distributionKey, distributionKeyBit]);

    const departmentDropdownChange = (value: string) => {
        const department = departments.items.data.find(
            (department) => department.id === Number.parseInt(value)
        );

        setDistributionKey((prevState) => {
            return {
                ...prevState,
                distributionKeyBlade: {
                    distributionKeyBits:
                        prevState.distributionKeyBlade.distributionKeyBits.map(
                            (bit) =>
                                bit.tempId === distributionKeyBit.tempId
                                    ? {
                                          ...bit,
                                          departmentId: department?.id,
                                          departmentName:
                                              department?.displayName,
                                      }
                                    : bit
                        ),
                },
            };
        });
    };

    const percentageChange = (event: ChangeEvent<HTMLInputElement>) => {
        setDistributionKey((prevState) => {
            return {
                ...prevState,
                distributionKeyBlade: {
                    distributionKeyBits:
                        prevState.distributionKeyBlade.distributionKeyBits.map(
                            (bit) =>
                                bit.tempId === distributionKeyBit.tempId
                                    ? {
                                          ...bit,
                                          percentage: format.unFormat(
                                              format.withKey(
                                                  event.target.value,
                                                  {
                                                      roundOffDecimal: false, //If this is true, round the number to options.precision fractions
                                                  }
                                              )
                                          ),
                                      }
                                    : bit
                        ),
                },
            };
        });
    };

    const onClickDelete = () => {
        setDistributionKey((prevState) => {
            const keyBits = prevState.distributionKeyBlade.distributionKeyBits;

            if (keyBits.length > 2) {
                return {
                    ...prevState,
                    distributionKeyBlade: {
                        distributionKeyBits: keyBits.filter(
                            (bit) => bit.tempId !== distributionKeyBit.tempId
                        ),
                    },
                };
            }
            return {
                ...prevState,
                distributionKeyBlade: {
                    distributionKeyBits: keyBits.map((bit) => {
                        return bit.tempId === distributionKeyBit.tempId
                            ? { tempId: uuidv4() }
                            : bit;
                    }),
                },
            };
        });
    };

    return (
        <tr>
            <td className="atl-td atl-align-middle atl-text-right">
                {rowIndex + 1}
            </td>
            <td className="atl-td">
                <DepartmentDropdown
                    {...departments}
                    data-testid="distribution-key-department-dropdown"
                    defaultValue={distributionKeyBit.departmentId?.toString()}
                    onChange={departmentDropdownChange}
                >
                    <Option value="">{getMessage('option_not_chosen')}</Option>
                </DepartmentDropdown>
            </td>
            <td className="atl-td">
                <FormattedNumberField
                    placeholder={getMessage('text_percent')}
                    defaultValue={distributionKeyBit.percentage?.toString()}
                    onChange={percentageChange}
                />
            </td>
            <td className="atl-td atl-td--button atl-align-middle">
                <Button
                    variant="tertiary"
                    data-testid="distribution-key-remove-bit-row"
                    onClick={onClickDelete}
                >
                    <Icon style={{ color: 'red' }}>delete</Icon>
                </Button>
            </td>
        </tr>
    );
};

const ValidationAlert = ({
    distributionKey,
    validationMessage,
}: {
    distributionKey?: DistributionKey;
    validationMessage?: HelperMessage;
}) => {
    const [, setSavedKey] = useState<DistributionKey | undefined>(undefined);
    const [alertVariant, setAlertVariant] = useState<MessageType | undefined>();

    useEffect(() => {
        if (!distributionKey || !distributionKey.distributionKeyBlade) {
            setSavedKey(undefined);
            return;
        }

        if (alertVariant !== 'success') {
            setSavedKey(undefined);
            return;
        }

        setSavedKey({
            ...distributionKey,
            distributionKeyBlade: {
                distributionKeyBits:
                    distributionKey.distributionKeyBlade.distributionKeyBits.filter(
                        (bit) =>
                            bit.departmentId &&
                            bit.departmentName &&
                            bit.percentage &&
                            bit.percentage > 0
                    ),
            },
        });
    }, [alertVariant, distributionKey]);

    useEffect(() => {
        if (!validationMessage) {
            setAlertVariant(undefined);
            return;
        }

        const validationMessages = Object.values(validationMessage);
        if (validationMessages.length === 0) {
            setAlertVariant('success');
            return;
        }

        setAlertVariant('warning');
    }, [validationMessage]);

    if (!alertVariant || alertVariant === 'success') {
        return null;
    }

    return (
        <Alert variant={alertVariant}>
            <AlertContent>
                {validationMessage &&
                    Object.values(validationMessage).length > 0 && (
                        <>
                            <ul className="atl-m-0">
                                {Object.values(validationMessage).map(
                                    (message, index) => (
                                        <li key={index}>{message}</li>
                                    )
                                )}
                            </ul>
                        </>
                    )}
            </AlertContent>
        </Alert>
    );
};
