import { MessageObject, MessagesState } from '../types';
import { ACTION_TYPES, getMessageIdentifier } from '../constants';

export const initialState: MessagesState = {
    messages: [],
};

const reducer = (state: any = initialState, action: any): MessagesState => {
    switch (action.type) {
        case ACTION_TYPES.ADD_MESSAGE:
            return {
                ...state,
                messages:
                    action.message &&
                    state.messages.filter(
                        (m: MessageObject) =>
                            getMessageIdentifier(m) ===
                            getMessageIdentifier(action.message)
                    ).length === 0
                        ? state.messages.concat([action.message])
                        : state.messages,
            };

        case ACTION_TYPES.CLOSE_MESSAGE:
            return {
                ...state,
                messages: action.message
                    ? state.messages.filter(
                          (m: MessageObject) => m !== action.message
                      )
                    : state.messages,
            };

        case ACTION_TYPES.CLEAR_MESSAGES:
            return {
                ...state,
                messages: !action.position
                    ? state.messages
                    : state.messages.filter(
                          (m: MessageObject) => m.position !== action.position
                      ),
            };

        case ACTION_TYPES.SET_MESSAGES: {
            const messagesToKeep = state.messages.filter((m: MessageObject) => {
                // keep all messages that is in state and also in action (we don't want to re-render existing messages)
                const id = getMessageIdentifier(m);
                const isInActionMessages =
                    action.messages.filter(
                        (m2: MessageObject) => getMessageIdentifier(m2) === id
                    ).length > 0;
                return isInActionMessages || m.position !== action.position;
            });

            const messagesToAdd = action.messages.filter((m: MessageObject) => {
                // only add message that does not exists from before (we don't want to re-render existing messages)
                const id = getMessageIdentifier(m);
                return (
                    messagesToKeep.filter(
                        (m2: MessageObject) => getMessageIdentifier(m2) === id
                    ).length === 0
                );
            });

            return {
                ...state,
                messages: !action.position
                    ? state.messages
                    : messagesToKeep.concat(messagesToAdd),
            };
        }

        default:
            return state;
    }
};

export default reducer;
