import * as React from 'react';

import { renderComponent } from '@General/renderComponent';
import { PageLoader } from '@Component/PageLoader';

const Page = () => (
    <PageLoader<{}>
        loader={async () => import('./TravelsAndExpenses').then((m) => m.page)}
    />
);

export function renderTravelsAndExpenses(domId: string) {
    renderComponent(Page, domId);
}

const FAB = () => (
    <PageLoader<{}>
        loader={async () =>
            import('./TravelsAndExpensesFABBar').then((m) => m.page)
        }
    />
);

export function renderTravelsAndExpensesFAB(domId: string) {
    renderComponent(FAB, domId);
}
