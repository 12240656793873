import { ActionCreator, SimpleActionCreator } from '@General/types/Action';

import { LogisticsSettingsModel } from '../type/LogisticsSettingsData.type';

const FetchDataType = 'LogisticsSettingsDataFetchData';
export const FetchData = SimpleActionCreator(FetchDataType);
export type FetchData = ReturnType<typeof FetchData>;

const StoreDataType = 'LogisticsSettingsDataStoreData';
export const StoreData = ActionCreator<
    typeof StoreDataType,
    {
        logisticsSettings: LogisticsSettingsModel;
    }
>(StoreDataType);
export type StoreData = ReturnType<typeof StoreData>;
