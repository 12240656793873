/**
 * JSPDropdownFetcher deliver common functionality to the specific
 * fetchers meant for JSPDropdown.
 *
 * It knows about DOM scope (the div container for the page the dropdown is in) and
 * can be used when information elsewhere on the page (and not yet implemented in react)
 * is needed for the dropdown. This is typically tasks such as:
 *
 * - When a specific department is selected in another dropdown, just fetch employees in that department for this dropdown.
 * - When creating a new Contact from a dropdown, tie it to the customer selected in another dropdown.
 *
 * It also knows how to fetch dates from the period selector (for dropdowns in filter).
 */
import { ApiResultMapper, Fetcher } from '@General/Provider';
import { CreateButtonComponent, Option } from '@Component/DropDown/types';
import { dateUtil, JsonDate } from '../../../../../js/modules/date';
import { JSONRpcClient } from '../../../../../js/legacy/jsonrpc';
import * as React from 'react';

export abstract class JSPDropdownFetcher<T> extends Fetcher<T> {
    scope: HTMLDivElement;
    queryAttributes: Record<string, any>;

    tlxSelectMaxRows: number;

    requestId?: number;

    abstract asyncJsonrpcGetter: Function;

    mapper: ApiResultMapper<T>;

    constructor(
        scope: HTMLDivElement,
        queryAttributes: Record<string, any>,
        mapper: ApiResultMapper<T>
    ) {
        super();
        this.queryAttributes = queryAttributes;
        this.scope = scope;
        this.mapper = mapper;
        this.tlxSelectMaxRows = 1000;
    }

    getMarshallSpec(): { marshallSpec: string[] } {
        return window.marshallSpec('id', 'displayName');
    }

    getDesktopListItem(): any {
        // Default return nothing (DesktopPopup uses default ListItem).
    }

    getMobileListItem(): any {
        // Default return nothing (DesktopPopup uses default ListItem).
    }

    /**
     * Provide a filter for the items fetched in search also from clientside.
     *
     * Typically, we want to ensure that the dropdown options are unique in the context of this scope.
     *
     *
     * Example:
     *
     * In hourlist you can select project and activity. But we do not want to enable the end user to
     * select the same project and activity multiple times. So in the activity dropdown, make sure
     * to filter out the same activities belonging to the same project.
     *
     *
     * The filter is defined on an object named "tlxDropdownFilter" placed on the scope DOM element,
     * with a key that is identical to the property of the dropdown the filter is defined for.
     * See example in updateHourlistNew.js (search for tlxDropdownFilter).
     *
     */
    clientFilter(object: Option<T>): boolean {
        return true;
    }

    /**
     * All calls to getQueryAttribute must reside within this method. It is called by the
     * framework before trying to query server (so that we know how the query should look like).
     * It returns an object with named parameters, that is either given to the tld:dropdown as
     * an attribute, or it lies elsewhere in the DOM and must be fetched there.
     */
    abstract getParams(): ObjectIndex;

    private getDomProp(
        name: string,
        defaultValue: number | string | boolean
    ): any {
        if (!this.scope) {
            return defaultValue;
        }

        // Is the name of domProp even provided?
        const domProp = (this.queryAttributes as any)[name.toLowerCase()];
        if (!domProp) {
            return defaultValue;
        }

        const input = this.scope.querySelector(`[name="${domProp}"]`);
        if (!input) {
            return defaultValue;
        }

        if (input instanceof HTMLInputElement && input.type === 'checkbox') {
            return input.checked ? 1 : 0;
        }

        return (input as HTMLInputElement).value || defaultValue;
    }

    getQueryAttribute(
        name: string,
        defaultValue: number | string | boolean
    ): any {
        if (name.toLowerCase() in this.queryAttributes) {
            return (this.queryAttributes as any)[name.toLowerCase()];
        }

        return this.getDomProp(name + 'prop', defaultValue);
    }

    /**
     * This is kinda equivalent to/replaces tlxSelectGetPeriodFormDateObject.
     */
    getDates(): { startDate: JsonDate | null; endDate: JsonDate | null } {
        let startDate = null;
        let endDate = null;
        if (!this.scope) {
            return { startDate, endDate };
        }

        const ignorePeriodForm = this.getQueryAttribute(
            'ignorePeriodForm',
            false
        );

        if (ignorePeriodForm) {
            return { startDate, endDate };
        }

        const period = window.narrowScreen
            ? this.scope.querySelector('.datepickerHack')
            : this.scope.querySelector(
                  '.tlx-textfield__input.hasPeriodselecter'
              );

        if (!period) {
            return { startDate, endDate };
        }

        if (!window.narrowScreen) {
            // @ts-expect-error Dirty hack for now.
            const dates = $(period).periodselecter('dates');

            startDate = dateUtil.jsonDate(dates[0]);
            endDate = dateUtil.jsonDate(
                dates[1] ? dateUtil.addDay(dates[1]) : dates[1]
            );
        } else {
            const startDateElement = $(period).children(
                '[name="period.startDate"]'
            );
            const endDateElement = $(period).children(
                '[name="period.endDate"]'
            );
            if (startDateElement && endDateElement) {
                startDate = dateUtil.jsonDate(
                    new Date(Date.parse(startDateElement.val() as string))
                );
                endDate = dateUtil.jsonDate(
                    new Date(Date.parse(endDateElement.val() as string))
                );
            }
        }

        return { startDate, endDate };
    }

    createCreateNewButton(
        setSelected?: (id: number) => void,
        dropdownTrackingId?: string,
        dropdownIsInJSPModal?: boolean
    ): CreateButtonComponent | undefined {
        /* Won't show button if it returns undefined. */
        return undefined;
    }

    getMapper(params: ObjectIndex) {
        return (item: T) => this.mapper(item, params);
    }

    getHeaders(): undefined | React.ComponentType {
        return undefined;
    }

    /**
     * Abort inflight json-rpc requests.
     */
    abort() {
        if (this.requestId) {
            const success = JSONRpcClient.cancelRequest(this.requestId);
            this.requestId = undefined;
            return success;
        }
        return false;
    }

    async fetchSingle(id: string | number): Promise<Option<T>[]> {
        try {
            const result = await this.asyncJsonrpcGetter(
                this.getMarshallSpec(),
                id
            );
            const mapped = this.mapper(result, { id });
            if (mapped !== undefined) {
                return [mapped];
            }
        } catch (e) {
            // If object is deleted, show this to end users instead, so they can select something else.
            if (
                e.javaClass !==
                'no.tripletex.common.exception.ObjectDeletedException'
            ) {
                defaultJsonRpcExceptionHandler(e);
            }
        }
        return [
            {
                /**
                 * There might be several reasons we end up here. I can think of at least two reasons:
                 *
                 * 1. dropdowns in filters, URL itself might refer to non-existing reference (URL might have been altered,
                 *    or it might be a saved favourite with a no-longer existing reference).
                 * 2. The item referenced to on a page is actually deleted.
                 */
                displayName: getMessage('text_object_deleted_display_name'),
                value: id,
            },
        ];
    }

    createLink(): undefined | ((id: number, contextId: number) => string) {
        return undefined;
    }

    validateId(id: string | number): boolean {
        return parseInt(id, 10) > 0;
    }
}
