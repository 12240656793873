import { ModelAPIV1 } from '@General/types/Api';
import { Option } from '../../types';
import { JSPDropdownFetcher } from '../JSPDropdownFetcher';
import { ApiResultMapperNoUndefinedResult } from '@General/Provider';

interface NumberSeriesEntry extends ModelAPIV1 {
    readonly displayName: string;
}

const numberSeriesMapper: ApiResultMapperNoUndefinedResult<NumberSeriesEntry> =
    (item) => ({
        value: item.id + '',
        displayName: item.displayName,
        item,
    });

class NumberSeriesFetcher extends JSPDropdownFetcher<NumberSeriesEntry> {
    asyncJsonrpcGetter: Function;

    constructor(scope: HTMLDivElement, queryAttributes: Record<string, any>) {
        super(scope, queryAttributes, numberSeriesMapper);
        this.asyncJsonrpcGetter = asyncrpc.NumberSeries.get;
    }

    getParams(): ObjectIndex {
        return {
            isActive: this.getQueryAttribute('isActive', 1),
            excludeNumberSeriesInvoiceOut: this.getQueryAttribute(
                'excludeNumberSeriesInvoiceOut',
                false
            ),
        };
    }

    async fetchList(params: ObjectIndex): Promise<Option<NumberSeriesEntry>[]> {
        return new Promise((resolve, reject) => {
            try {
                return jsonrpc.NumberSeries.searchForNumberSeries(
                    (result: NumberSeriesEntry[] | null) =>
                        resolve((result ?? []).map(numberSeriesMapper)),
                    this.getMarshallSpec(),
                    window.loginCompanyId,
                    params.isActive,
                    params.excludeNumberSeriesInvoiceOut,
                    params.name || ''
                );
            } catch (e) {
                defaultJsonRpcExceptionHandler(e);
                reject(e);
            }
        });
    }
}

export const exports = {
    fetcher: NumberSeriesFetcher,
    type: 'NumberSeries',
};
