import { Option } from '@Component/DropDown/types';
import { Provider } from './Provider';

/**
 * The simplest implementation of a Provider where the data set is static.
 */
export class ImmutableProvider<T> extends Provider<T> {
    private readonly options: Array<Option<T>>;

    constructor(options: Array<Option<T>>) {
        super();
        this.options = options;
    }

    willFetch(): boolean {
        return false;
    }

    getOptions(query?: string): Array<Option<T>> {
        if (query === undefined) {
            return [...this.options];
        }
        const searchString = query.trim().toLowerCase();
        return this.options.filter(
            (option) =>
                option.displayName.toLowerCase().indexOf(searchString) !== -1
        );
    }

    getDefaults(query: string): Array<Option<T>> {
        return this.getOptions(query);
    }

    getOptionsNoDefaults(query: string): Array<Option<T>> {
        // ImmutableProvider only has default values
        return new Array<Option<T>>();
    }

    getSingle(id: string | number): Option<T> | null {
        // eslint-disable-next-line eqeqeq
        const option = this.options.find((o) => o.value == id);
        if (option) {
            return option;
        }
        return null;
    }
}
