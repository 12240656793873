import { Input, InputProps } from '@tlx/atlas';
import { InputValidationError } from '../Errors/InputValidationError';

export function ValidatedInput(props: InputProps & { error?: string }) {
    return (
        <>
            <Input {...props} />
            {props.error && <InputValidationError error={props.error} />}
        </>
    );
}
