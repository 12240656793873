import $ from 'jquery';

import { addContextId } from './url';
import { getActiveDocumentationComponent } from '../o-common';

export const extraFrame = (function () {
    let expanded = false;
    let defaultUrl = '';
    let extra: Element | Window = window;
    let defaultType = '';
    const scrollContainer = '#scrollContainer';
    const extraFrameContainer = '#extraFrameContainer';
    const screenMappings: Record<string, unknown> = {};
    let externalPDFViewerWindow: Window | null | undefined = undefined;

    // Remember width/height on resizing globally. Width for portrait ("vertical") and height for landscape ("horizontal").
    let extraFrameContainerWidth: number | undefined;
    let extraFrameContainerHeight: number | undefined;
    let scrollContainerWidth: number | undefined;
    let scrollContainerHeight: number | undefined;

    /**
     * Added "type". Use when what is currently opened in the extraframe viewer needs to be known.
     * [Tom Kong, 2019-05-22]
     *
     * @param url
     * @param type - Optional. Appears as attribute pdfViewerType on body.
     */
    function expand(url?: string, type?: string): Element | Window | undefined {
        // Don't expand if there isn't anything to show!
        if (!url && !defaultUrl) {
            return;
        }

        if (!expanded) {
            expanded = true;
        }

        // Close menu automatically when previewing a pdf.
        document.dispatchEvent(new CustomEvent('sidebar:reset'));

        setCorrectWidths();

        if (url) {
            navigate(url);
            defaultUrl = url;
        } else if (defaultUrl) {
            // This fixes a bug where menu and pdf got wrong placement
            navigate(defaultUrl);
        }

        $(window).trigger('tlxExpandPdfViewer').trigger('resize');

        const $body = $('body');
        if (isDocked()) {
            $body.addClass('pdfViewerOpen');
        } else {
            /**
             * The external docked browser window might have become hidden below other windows/programs. This creates a
             * user frustration, because seemingly nothing happens when PDF is loaded in this external "non docked"
             * version.
             */
            externalPDFViewerWindow?.focus();
        }

        if (type != null) {
            defaultType = type;
        } else {
            defaultType = '';
        }

        $body.attr('pdfViewerType', defaultType);

        // so react also hears the event.
        window.dispatchEvent(new CustomEvent('resize'));

        return extra;
    }

    /**
     * That the extra frame is docked, means that the PDF viewer is within the Tripletex window (on the right or on
     * the bottom). Undocked means that the PDF is shown in another window.
     *
     * @returns boolean
     */
    function isDocked() {
        return !externalPDFViewerWindow || externalPDFViewerWindow.closed;
    }

    function navigate(url: string) {
        getFrameLocation().replace(url);
    }

    function reload() {
        getFrameLocation().reload();
    }

    function getFrameLocation() {
        if (!isDocked()) {
            return externalPDFViewerWindow?.location;
        }

        return $('iframe[name=extra]').prop('contentWindow').location;
    }

    function collapse(): Element | Window {
        if (expanded) {
            navigate(defaultUrl);
            expanded = false;
        }

        setCorrectWidths();
        $(window).trigger('tlxCollapsePdfViewer').trigger('resize');
        $('body').removeClass('pdfViewerOpen').removeAttr('pdfViewerType');

        // so react also hears the event.
        window.dispatchEvent(new CustomEvent('resize'));
        return extra;
    }

    function setCorrectWidths() {
        if (!expanded || !isDocked()) {
            // Abort after some adjustments
            $(extraFrameContainer).hide().css('width', '0%');
            $(scrollContainer).css({ width: '100%', height: '100%' });

            $(window).trigger('tlxPossibleWidthChange');
            return;
        }

        // @ts-expect-error Not even sure how this  works, since window.frames is array-like, but anyways...
        const extraFrame = window.frames['extra'];
        const menuFrame = extraFrame.document.getElementsByTagName('frame')[0];
        const documentFrame =
            extraFrame.document.getElementsByTagName('frame')[1];

        let frameSet: { rows?: string };
        let $widgetHeader;
        if (!menuFrame) {
            // First time this method is called, frames are not initialized. Just speak to "dummy" objects instead.
            frameSet = {};
            $widgetHeader = $();
        } else {
            frameSet = extraFrame.document.getElementsByTagName('frameset')[0];
            $widgetHeader = $(
                '.ui-widget-header, .viewer-menu',
                menuFrame.contentWindow.document
            );
        }

        /*
		 The styles need to be added to the jquery element to overwrite the element style.
		 A css class is preferable.
		 */
        if (getDefaultOrientation() === 'horizontal') {
            $(scrollContainer).css({
                height: scrollContainerHeight || '40%',
                width: '100%',
            });
            $(extraFrameContainer)
                .css({
                    height: extraFrameContainerHeight || '60%',
                    width: 'auto',
                    top: 'auto',
                    left: '24px',
                    right: '0px',
                })
                .show();

            frameSet.rows = '54, *';
            $(extraFrameContainer).find('div').css('padding-left', '');
            $widgetHeader.css('padding-top', '6px');
            $widgetHeader.addClass('viewer-menu--horizontal');
            $widgetHeader.removeClass('viewer-menu--vertical');
            $('.ui-resizable-w').css('display', 'none');
            $('.ui-resizable-n').css('display', 'block');
        } else {
            $(scrollContainer).css({
                height: '100%',
                width: scrollContainerWidth || '60%',
            });
            $(extraFrameContainer)
                .css({
                    height: '100%',
                    width: extraFrameContainerWidth || '40%',
                    top: 'auto',
                    left: scrollContainerWidth || '60%',
                })
                .show();

            frameSet.rows = '48, *';
            $(extraFrameContainer).find('div').css('padding-left', '10px');
            $widgetHeader.css('padding-top', '0');
            $widgetHeader.addClass('viewer-menu--vertical');
            $widgetHeader.removeClass('viewer-menu--horizontal');
            $('.ui-resizable-w').css('display', 'block');
            $('.ui-resizable-n').css('display', 'none');
        }

        // Setting image width to 100% so that images scale
        // with the extraFrame and don't require scrolling
        const documentImage = $(documentFrame).contents().find('img');
        if (documentImage.length > 0) {
            documentImage.css('width', '100%');
        }

        $(window).trigger('resize').trigger('tlxPossibleWidthChange');
    }

    // When user goes to another page, reset defaultUrl, so pdf viewer won't open unless
    // there are some relevant content to see.
    $(window).on('tlxStartInitialize', function () {
        defaultUrl = '';
    });

    function getDefaultOrientation() {
        // @ts-expect-error now quite sure about these types
        const settings = screenMappings[getActiveDocumentationComponent()];
        return (settings && settings['orientation']) || 'vertical';
    }

    function setDefaultOrientation(orientation: unknown) {
        const componentId = getActiveDocumentationComponent();
        // @ts-expect-error now quite sure about these types
        const settings = screenMappings[componentId] || {};
        settings.orientation = orientation;
        // @ts-expect-error now quite sure about these types
        screenMappings[componentId] = settings;
    }

    function setHorizontalView() {
        setDefaultOrientation('horizontal');
        setCorrectWidths();
    }

    function setVerticalView() {
        setDefaultOrientation('vertical');
        setCorrectWidths();
    }

    /**
     * Event handlers, tie them to DOM after DOM ready...
     */
    $(function () {
        const $extraFrameContainer = $(extraFrameContainer);
        const $scrollContainer = $(scrollContainer);
        $extraFrameContainer.resize(function (e) {
            if (getDefaultOrientation() === 'horizontal') {
                extraFrameContainerHeight = $extraFrameContainer.height();
                scrollContainerHeight = $scrollContainer.height();
            } else {
                extraFrameContainerWidth = $extraFrameContainer.width();
                scrollContainerWidth = $scrollContainer.width();
            }
            e.stopPropagation();
        });

        const $window = $(window);
        let windowHeight = $window.height();
        let windowWidth = $window.width();
        $window.resize(function () {
            // Existing width/height on extraFrame doesn't make sense if browser window is resized
            if (
                windowHeight !== $window.height() ||
                windowWidth !== $window.width()
            ) {
                extraFrameContainerHeight = undefined;
                scrollContainerHeight = undefined;
                extraFrameContainerWidth = undefined;
                scrollContainerWidth = undefined;
            } else {
                windowHeight = $window.height();
                windowWidth = $window.width();
            }
        });

        const $iFrame = $extraFrameContainer.find('iframe');

        $iFrame.on('load', function () {
            if (!$iFrame.is(':visible')) {
                return;
            }

            // @ts-expect-error Not even sure how this  works, since window.frames is array-like, but anyways...
            const extraFrame = window.frames['extra'];
            const frame = extraFrame.document.getElementsByTagName('frame')[0];
            if (frame) {
                const document = frame.contentWindow.document;

                $(document.getElementById('viewButton')).addClass('mdl-button');
                $(document.getElementById('downloadButton')).addClass(
                    'mdl-button'
                );

                $extraFrameContainer
                    .find('div')
                    .css('padding-left', '10px')
                    .css('box-sizing', 'border-box');

                setCorrectWidths();

                $(document).on(
                    'click',
                    '.viewer-menu__action--horizontal',
                    setHorizontalView
                );

                $(document).on(
                    'click',
                    '.viewer-menu__action--vertical',
                    setVerticalView
                );
                $(document).on('click', '.viewer-menu__action--undock', undock);
                $(document).on(
                    'click',
                    '.viewer-menu__action--close',
                    collapse
                );
            }
        });
    });

    function viewerDocument(id: string | number): void {
        let url;
        if (window.narrowScreen) {
            url = addContextId('/execute/document?act=view&id=' + id);
            window.open(url, '_blank');
        } else {
            url = addContextId('/execute/viewer?act=viewerDocument&id=' + id);
            expand(url);
        }
    }

    function viewerPdf(pdfUrl: string, type?: string) {
        pdfUrl = pdfUrl.replace(/&amp;/g, '&');

        if (window.narrowScreen) {
            window.open(addContextId(pdfUrl), undefined, 'noopener');
        } else {
            const url =
                '/execute/viewer?act=viewerPdf&url=' +
                encodeURIComponent(pdfUrl);
            expand(addContextId(url), type);
        }
    }

    /**
     * A viewer for PDF documents such as vouchers and invoices.
     *
     * @param id the voucher id
     * @param noPostings if true, then don't show the postings table.
     * @param type The type of document to view, valid values are voucher and invoice.
     * @date 6. jan 2017
     */
    function viewerVoucher(id: number, noPostings: boolean, type?: string) {
        let url;
        if (type == null) {
            type = 'voucher';
        }

        if (window.narrowScreen) {
            url = addContextId(
                '/execute/voucher?act=view&type=' + type + '&id=' + id
            );
        } else {
            url = addContextId(
                '/execute/viewer?act=viewerVoucher&type=' + type + '&id=' + id
            );
        }

        // This looks suspicious, should be looked into. KK, LEB, VB. 2016-03-29.
        if (noPostings) {
            url += '&noPostings=';
        }

        if (window.narrowScreen) {
            window.open(url, '_blank', 'noopener');
        } else {
            expand();
            expand(url);
        }
    }

    /**
     * Show PDF in its own window. Might be we could experiment some more on the windowFeatures string.
     */
    function undock() {
        if (isDocked()) {
            // Take the whole screen height as height, and use A4 aspect ratio for width. This is better than full screen,
            // because PDF size is automatically scaled by window width, and not height. If PDF window is in landscape
            // modus, you'd have to zoom out in the PDF to see the whole PDF properly.
            const outerHeight = screen.height;
            const outerWidth = outerHeight * 0.7;
            externalPDFViewerWindow = window.open(
                defaultUrl,
                'pdfDocker',
                'menubar=no,location=yes,resizable=yes,scrollbar=yes,personalbar=no,status=no' +
                    ',height=' +
                    outerHeight +
                    ',width=' +
                    outerWidth
            );
        }
        expand();
    }

    return {
        expand: expand,
        collapse: collapse,
        isExpanded: function (): boolean {
            return expanded;
        },
        getDefaultType: function (): string {
            return defaultType;
        },
        setDefaultUrl: function (url: string): void {
            defaultUrl = url;
        },
        setExtraWindow: function (extraWindow: Element): void {
            extra = extraWindow;
        },
        viewerDocument: viewerDocument,
        viewerPdf: viewerPdf,
        viewerVoucher: viewerVoucher,
        undock: undock,
        reload: reload,
    };
})();
