import * as React from 'react';
import { ISagaModule } from 'redux-dynamic-modules-saga';
import { DynamicModuleLoader } from 'redux-dynamic-modules-react';

// @docusauros/react-loadable is a fork of react-loadable (unmaintained), which
// solves a problem regarding React's deprecated componentWillMount lifecycle.
// @ts-expect-error there are no types for this library as of now?
import Loadable from '@docusaurus/react-loadable';

import { LoadingSpinner } from '@Component/Loading';
import { LoadingDots } from '@Component/Loading';

import { PageError } from './PageError';
import { ErrorBoundary } from './ErrorBoundary';

import './PageLoader.scss';
import { captureException } from '@sentry/browser';
import { fetchAndCheckClientHash } from '@General/Router/Router';

// From @types/react-loadable
interface LoadableComponent {
    preload(): void;
}

// From @types/react-loadable
interface LoadingComponentProps {
    isLoading: boolean;
    pastDelay: boolean;
    timedOut: boolean;
    error: any;
    retry: () => void;
}

export type LoadingPlaceholders = 'page' | 'inline' | 'invisible';

export type PageLoaderProps<P, E extends object> = {
    loader: () => Promise<PageData<P>>;
    adjustWrapperDiv?: boolean;
    type?: LoadingPlaceholders;
    props?: P;
};

/**
 * Describes a component that has the given redux modules tied to it. Usually a page.
 */
export type PageData<P = Record<string, never>, S = {}> = {
    modules: ISagaModule<S>[];
    page: React.ComponentType<P>;
};

/**
 * LoadingPlaceholder
 */
const PageLoadingPlaceholder: React.FunctionComponent<LoadingComponentProps> = (
    props
) => {
    if (props.error) {
        if (window.reloadingDueToHashChange) {
            return null;
        } else {
            captureException(props.error);
            console.error('Error loading page', props.error);
            return <PageError error={props.error} />;
        }
    } else if (props.timedOut) {
        return null;
    } else if (props.isLoading && props.pastDelay) {
        return (
            <LoadingSpinner className="tlx-page-loader__loading-indicator" />
        );
    } else {
        return null;
    }
};

/**
 * InlineLoadingPlaceholder
 */
const InlineLoadingPlaceholder: React.FunctionComponent<LoadingComponentProps> =
    (props) => {
        if (props.error) {
            if (window.reloadingDueToHashChange) {
                return null;
            } else {
                console.log('Error loading page', props.error);
                return (
                    <span role="img" aria-label="failed to load">
                        ☠️
                    </span>
                );
            }
        } else if (props.timedOut) {
            return null;
        } else if (props.isLoading && props.pastDelay) {
            return (
                <LoadingDots className="tlx-page-loader__inline-loading-indicator" />
            );
        } else {
            return null;
        }
    };

const InvisibleLoadingPlaceholder: React.FunctionComponent<LoadingComponentProps> =
    (props) => {
        if (props.error) {
            console.log('Error loading page', props.error);
            if (window.PRODUCTION_MODE) {
                return null;
            } else {
                return (
                    <span role="img" aria-label="failed to load">
                        ☠️
                    </span>
                );
            }
        } else {
            return null;
        }
    };

// Higher-order component ²
function componentLoaderCreator<P extends object>(
    dataSource: () => Promise<PageData<P>>
): () => Promise<React.FC<P>> {
    return async () => {
        try {
            const data = await dataSource();
            if (data.modules.length === 0) {
                return (props: P) => <data.page {...props} />;
            }

            return (props: P) => (
                <DynamicModuleLoader modules={data.modules}>
                    <data.page {...props} />
                </DynamicModuleLoader>
            );
        } catch (error) {
            const hasHashChangeBeenHandled = await fetchAndCheckClientHash();
            if (!hasHashChangeBeenHandled) {
                throw error;
            } else {
                // We should not really get to this point, because the hash change
                // should have triggered a window.location.reload().
                throw new Error('Could not load page', error);
            }
        }
    };
}

const loadingPlaceholders: {
    [type in LoadingPlaceholders]: React.FunctionComponent<LoadingComponentProps>;
} = {
    page: PageLoadingPlaceholder,
    inline: InlineLoadingPlaceholder,
    invisible: InvisibleLoadingPlaceholder,
};

export class PageLoader<
    P extends object,
    E extends object = React.ComponentType<P>
> extends React.Component<PageLoaderProps<P, E>> {
    private readonly PageComponent: React.ComponentType<P> & LoadableComponent;

    constructor(props: PageLoaderProps<P, E>) {
        super(props);
        this.PageComponent = Loadable<P, E>({
            loader: componentLoaderCreator<P>(this.props.loader),
            loading: loadingPlaceholders[this.props.type ?? 'page'],
        });
    }

    componentDidMount(): void {
        if (this.props.adjustWrapperDiv) {
            const wrapperDiv = document.getElementById('wrapperDiv');
            if (wrapperDiv !== null) {
                wrapperDiv.style.display = 'block';
            }
        }
    }

    render() {
        // Typescript gets confused otherwise
        const page = React.createElement(this.PageComponent, this.props.props);
        return <ErrorBoundary format={this.props.type}>{page}</ErrorBoundary>;
    }
}
