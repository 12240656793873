import { EmptyState } from '@Page/LogisticsV2/components/EmptyState/EmptyState';
import React from 'react';
import { Button } from '@tlx/atlas';
import { nav } from '../../../../../../../js/modules/navigation';
import { PageUrl } from '@Page/LogisticsV2/utils/enums';

export const NoProductsInCompany = () => {
    return (
        <EmptyState
            title={getMessage('text_no_products')}
            description={getMessage('text_creating_new_product')}
            className={'atl-text-xl'}
            svgIcon={'noProductsInCompany'}
        >
            <div className="atl-flex atl-flex-wrap atl-gap-8 atl-items-center">
                {!moduleRackbeat && (
                    <Button
                        data-testid="new-product-button"
                        className="atl-text-center"
                        onClick={() =>
                            nav.nav(
                                addContextId(
                                    `${PageUrl.PRODUCT_MENU}?productId=0`
                                )
                            )
                        }
                        variant={'secondary'}
                    >
                        {getMessage('text_new_product')}
                    </Button>
                )}
            </div>
        </EmptyState>
    );
};
