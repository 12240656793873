import * as React from 'react';

import { isFilled } from '@General/Helpers';

interface TextProps {
    readonly text?: string;
    readonly textKey?: string;
    readonly textKeyArgs?: Array<string | number>;
    readonly uppercase?: boolean;
    readonly lowercase?: boolean;
    readonly children?: never;
}

export const Text: React.FC<TextProps> = ({
    textKey,
    textKeyArgs,
    lowercase,
    uppercase,
    text,
}) => {
    let result: string | null = null;

    if (isFilled(textKey, '')) {
        result = getMessage(textKey, ...(textKeyArgs ?? []));
    } else if (text) {
        result = text;
    }

    if (result !== null) {
        if (uppercase) {
            result = result.toUpperCase();
        } else if (lowercase) {
            result = result.toLowerCase();
        }
    }

    return <>{result}</>;
};
