import { ModelAPIV1 } from '@General/types/Api';
import { Option } from '../../types';
import { notUndefined } from '@General/Helpers';
import { JSPDropdownFetcher } from '../JSPDropdownFetcher';
import { ApiResultMapper } from '@General/Provider';
import * as React from 'react';
import { SearchFilterGenerator } from '@Component/DropDown/types';

interface ProjectControlSchemaTypeEntry extends ModelAPIV1 {
    readonly name: string;
    readonly version: string;
}

const projectControlSchemaTypeMapper: ApiResultMapper<ProjectControlSchemaTypeEntry> =
    (item) => ({
        value: item.id + '',
        displayName: item.name,
        secondary: item.version
            ? getMessage('text_version') + ' ' + item.version
            : '',
        item,
    });

const searchFilterGenerator: SearchFilterGenerator<unknown> = (
    query: string
) => {
    return (option: Option<unknown>) =>
        option.displayName.toLowerCase().indexOf(query.toLowerCase()) > -1;
};

class ProjectControlSchemaTypeFetcher extends JSPDropdownFetcher<ProjectControlSchemaTypeEntry> {
    asyncJsonrpcGetter: Function;

    constructor(scope: HTMLDivElement, queryAttributes: Record<string, any>) {
        super(scope, queryAttributes, projectControlSchemaTypeMapper);
        this.asyncJsonrpcGetter = asyncrpc.ProjectControlSchemaType.get;
    }

    getMarshallSpec() {
        return window.marshallSpec('id', 'name', 'version');
    }

    getParams(): ObjectIndex {
        return {};
    }

    async fetchList(
        params: ObjectIndex
    ): Promise<Option<ProjectControlSchemaTypeEntry>[]> {
        return new Promise((resolve, reject) => {
            try {
                return jsonrpc.ProjectControlSchemaType.search(
                    (result: ProjectControlSchemaTypeEntry[] | null) => {
                        const mapped = (result ?? [])
                            .filter(notUndefined)
                            .map(this.getMapper(params))
                            .filter(notUndefined)
                            .filter((item) => item.value != 19); // We are no longer using this schema.
                        resolve(mapped);
                    },
                    this.getMarshallSpec(),
                    1,
                    params.name || '',
                    this.tlxSelectMaxRows,
                    0
                );
            } catch (e) {
                defaultJsonRpcExceptionHandler(e);
                reject(e);
            }
        });
    }
}

export const exports = {
    fetcher: ProjectControlSchemaTypeFetcher,
    type: 'ProjectControlSchemaType',
    searchFilterGenerator: searchFilterGenerator,
};
