import { Option } from '../../types';
import { ModelAPIV1 } from '@General/types/Api';
import { JSPDropdownFetcher } from '../JSPDropdownFetcher';
import { ApiResultMapperNoUndefinedResult } from '@General/Provider';

interface ProspectFilteredEntry extends ModelAPIV1 {
    readonly displayName: string;
}

/**
 * This differs from Prospect.ts by the method used to fetch Prospects!
 *
 * In the old tlxSelect, the options was fetched manually outside of the tlxSelect, and inserted into
 * it as default options. Why this was the case, I HAVE NO IDEA.
 *
 * The list is fetched in "tlxSelect" format... which is an array of arrays with three values:
 *
 * id, displayName, displaName...
 *
 * We convert this array to an ProspectFilteredEntry specifically (tlxSelectMapper), so as not to confuse which is what.
 *
 * Also, it seems to only be used when an actual customer is selected on same screen, so it won't
 * show customer name in the dropdown.
 *
 * Might be merged with Prospect.ts, and endpoints could be fixed as well (insert filter in the regular endpoint), but
 * I leave that as a task to the correct domain team. :-]
 *
 * @param item
 * @constructor
 */
const ProspectFilteredMapper: ApiResultMapperNoUndefinedResult<ProspectFilteredEntry> =
    (item) => {
        return {
            value: item.id + '',
            displayName: item.displayName,
            item,
        };
    };

function tlxSelectMapper(item: [string, string]): ProspectFilteredEntry {
    return {
        clientId: '',
        deleted: false,
        revision: 0,
        selected: false,
        id: parseInt(item[0], 10),
        displayName: item[1],
    };
}

class ProspectFilteredFetcher extends JSPDropdownFetcher<ProspectFilteredEntry> {
    asyncJsonrpcGetter: Function;
    constructor(scope: HTMLDivElement, queryAttributes: Record<string, any>) {
        super(scope, queryAttributes, ProspectFilteredMapper);
        this.asyncJsonrpcGetter = asyncrpc.Prospect.get;
    }

    getParams(): ObjectIndex {
        return {
            customerId: this.getQueryAttribute('customerId', 0),
            // 1 = PROSPECT_TYPE_ORDER
            orderProspectType: this.getQueryAttribute('orderProspectType', 1),
        };
    }

    async fetchList(
        params: ObjectIndex
    ): Promise<Option<ProspectFilteredEntry>[]> {
        return new Promise((resolve, reject) => {
            try {
                jsonrpc.Prospect.getFilteredProspectListInTlxSelectFormat(
                    (result: [string, string][] | null) =>
                        resolve(
                            (result ?? [])
                                .map(tlxSelectMapper)
                                .filter((item: any) => item.id !== 0)
                                .map(ProspectFilteredMapper)
                        ),
                    params.customerId,
                    params.orderProspectType
                );
            } catch (e) {
                defaultJsonRpcExceptionHandler(e);
                reject(e);
            }
        });
    }
}

export const exports = {
    fetcher: ProspectFilteredFetcher,
    type: 'ProspectFiltered',
    link: (id: number, contextId: number) =>
        `/execute/prospectMenu?prospectId=${id}&contextId=${contextId}`,
};
