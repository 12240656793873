import useSWR from 'swr';
import { MethodType } from '../utils/enums';
import { getHeaders, getURL, getValue, getValues } from '../utils/functions';
import useSWRInfinite from 'swr/infinite';
import { ListResponse } from '../../../hooks/fetch/types';
import { getHasMore } from '@Page/LogisticsV2/hooks/fetcher';
import { INFINITE_SCROLL_PAGE_SIZE } from '@Page/LogisticsV2/utils/constants';
import { mapObjectToURLSearchParams } from '@Page/LogisticsV2/utils/mappers';
import { useMemo } from 'react';

//used for GET ResponseWrapper<T>
export const useFetchSingle = <T>(
    url: string | null | undefined,
    queryParams?: URLSearchParams,
    swrConfig?: {}
) => {
    return useAPI<T>(getURL(url, queryParams), getValue, swrConfig);
};

//used for GET ListResponse<T>
export const useFetchAll = <T>(
    url: null | string | undefined,
    queryParams?: URLSearchParams,
    swrConfig?: {}
) => {
    return useAPI<T>(getURL(url, queryParams), getValues, swrConfig);
};

//used for POST | PUT | DELETE
export const invokeAPI = async (
    url: string,
    methodType: string,
    payload?: string | FormData,
    additionalHeaders: { [name: string]: string } = {
        'Content-Type': 'application/json; charset=utf-8',
    }
) => {
    const options = {
        method: methodType,
        ...(payload && { body: payload }),
        headers: getHeaders(additionalHeaders),
    };
    const response = await fetch(url, options);
    if (!response.ok) {
        return { data: undefined, error: await response.json() };
    }
    return {
        data: response,
        error: undefined,
    };
};

const useAPI = <T>(
    url: string | null | undefined,
    processResponse: (response: Response) => Promise<T>,
    swrConfig?: {}
) => {
    const { data, error, mutate } = useSWR<T | undefined>(
        url,
        async (url: string, methodType: string) => {
            const { data, error } = await invokeAPI(url, methodType);
            if (data === undefined) {
                throw error;
            }
            return await processResponse(data);
        },
        swrConfig
    );
    const isLoading = !error && !data;
    return { data, error, isLoading, mutate };
};

// used for infinite scroll
export function useInfiniteScrollData<T>(
    url: string | null | undefined,
    queryParams?: {},
    pageFetchedCallback?: (data: T[]) => void,
    swrConfig: { [name: string]: string | number | boolean } = {
        revalidateOnFocus: false,
        revalidateAll: true,
        shouldRetryOnError: false,
    }
) {
    const buildUrl = getURL(url, mapObjectToURLSearchParams(queryParams));
    const getKey = (pageIndex: number, previousPageData: T[]) => {
        if (previousPageData && !previousPageData.values.length) {
            return null;
        }
        return `${buildUrl}&count=${INFINITE_SCROLL_PAGE_SIZE}&from=${
            pageIndex * INFINITE_SCROLL_PAGE_SIZE
        }`;
    };
    const response = useSWRInfinite<ListResponse<T>>(
        getKey,
        async (url: string) => {
            const { data, error } = await invokeAPI(url, MethodType.GET);
            if (!data) {
                throw error;
            }
            const result = await data.json();
            pageFetchedCallback?.(result.values);
            return result;
        },
        swrConfig
    );
    const data = useMemo(() => getFlattenData(response.data), [response.data]);

    const hasMore = getHasMore(response.data);
    const isLoading = getIsLoading(
        response.data,
        response.size,
        response.error !== undefined
    );
    function loadMore() {
        if (hasMore && !response.isValidating) {
            response.setSize(response.size + 1);
        }
    }
    return {
        data,
        error: response.error,
        isLoading,
        hasMore,
        loadMore,
        refresh: response.mutate,
    };
}

function getFlattenData<T = unknown>(
    pages: (ListResponse<T> | undefined)[] | undefined
): T[] {
    if (pages === undefined) {
        return [];
    }
    return pages.flatMap((page) => (page !== undefined ? page.values : []));
}

function getIsLoading<T = unknown>(
    pages: (ListResponse<T> | undefined)[] | undefined,
    size: number,
    hasError: boolean
): boolean {
    const currentPage = pages?.[size - 1];
    const isLoadingFirstPage: boolean =
        size > 0 && pages === undefined && !hasError;
    const isLoadingMore: boolean =
        size > 0 && pages !== undefined && currentPage === undefined;
    return isLoadingFirstPage || isLoadingMore;
}
