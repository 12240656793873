import { Button } from '@tlx/atlas';
import React, { useEffect, useState } from 'react';
import { DataTreeNode } from '@Page/LogisticsV2/components/DataTree/types';
import { DataTreeItem } from '@Page/LogisticsV2/components/DataTree/DataTree';
import classNames from 'classnames';
import EmptyState from '@Component/EmptyState/EmptyState';

export function EditableDataTree({
    nodes,
    addNode,
    onUpdate,
    onDelete,
    onCancel,
    className,
}: {
    nodes: DataTreeNode[] | undefined;
    addNode: (parentId: number | null) => void;
    onUpdate: (node: DataTreeNode) => void;
    onDelete: (id: number) => void;
    onCancel: (id: number) => void;
    className?: string;
}) {
    const [isEditMode, setIsEditMode] = useState(false);
    useEffect(() => {
        if (
            nodes?.find((node) => node.name === '') !== undefined ||
            nodes?.find(
                (node) =>
                    node.children.find((children) => children.name === '') !==
                        undefined || isEditMode
            )
        ) {
            setIsEditMode(true);
        } else {
            setIsEditMode(false);
        }
    }, [nodes]);

    return (
        <div className={'atl-p-8'}>
            <div className={'atl-flex atl-gap-8 atl-justify-end atl-mb-16'}>
                <Button
                    data-testid={'add-button'}
                    variant={'primary'}
                    onClick={() => {
                        addNode(null);
                    }}
                    disabled={isEditMode}
                >
                    {getMessage('text_product_group_create')}
                </Button>
            </div>
            {nodes !== undefined && nodes.length === 0 && (
                <EmptyState
                    icon="folder"
                    title={getMessage('text_no_productgroups')}
                />
            )}
            {nodes !== undefined && nodes.length > 0 && (
                <div
                    role="tree"
                    className={classNames(
                        'atl-border atl-border-grey-40 atl-bg-white atl-p-16 atl-overflow-auto goldshark-editable-tree-container',
                        className
                    )}
                >
                    {nodes.map((node) => (
                        <DataTreeItem
                            key={node.id}
                            node={node}
                            isRoot={true}
                            isEditMode={isEditMode}
                            addNode={addNode}
                            onUpdate={onUpdate}
                            onDelete={onDelete}
                            onCancel={onCancel}
                            onEditing={setIsEditMode}
                        />
                    ))}
                </div>
            )}
        </div>
    );
}
