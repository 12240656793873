import * as React from 'react';
import classNames from 'classnames';
import { CreateButtonComponent, Option } from './types';
import { throttle } from 'lodash';
import { LoadingSpinner } from '@Component/Loading';
import { DefaultListItem, TlxListItem } from '@Component/DropDown/DropDown';

type DesktopPopupProps<T> = {
    search?: boolean;
    textField?: React.ReactNode;
    scrollContainer: React.RefObject<HTMLDivElement>;
    selectedValue?: string | number | null;
    listItems: HTMLDivElement[];
    handleClick: (o: Option<T>) => void;
    handleBlur: React.FocusEventHandler;
    handleScroll?: (
        scrollTop: number,
        scrollVisibleHeight: number,
        scrollFullHeight: number
    ) => void;
    show: (show: boolean) => void;
    focus: number;
    options: Option<T>[];
    anchor: React.RefObject<HTMLElement>;
    fetching?: boolean;
    desktopListItem?: TlxListItem<T>;
    onKeyDown?: React.KeyboardEventHandler<HTMLDivElement>;
    headers?: React.ComponentType;
    offsetTop?: number;
    createNewButton?: CreateButtonComponent;
    searchInput?: React.RefObject<HTMLInputElement>;
    createNewButtonRef?: React.RefObject<HTMLButtonElement>;
};

type DesktopPopupState = {
    minWidth: number;
    scrolling: boolean;
};

function getOptionTestId(option: Option<unknown>): string | undefined {
    const value = option.value;

    if (typeof value === 'string') {
        return value;
    }

    return undefined;
}

export default class DesktopPopup<T> extends React.Component<
    DesktopPopupProps<T>,
    DesktopPopupState
> {
    constructor(props: DesktopPopupProps<T>) {
        super(props);
        this.state = {
            minWidth: 200,
            scrolling: false,
        };

        this.fitToAnchor = throttle(this.fitToAnchor.bind(this), 50);
        this.handleScroll = throttle(this.handleScroll.bind(this), 50);
    }

    handleScroll(event: React.UIEvent) {
        if (event.target instanceof HTMLElement) {
            const scrollTop = event.target.scrollTop;
            const scrollVisibleHeight = event.target.clientHeight;
            const scrollFullHeight = event.target.scrollHeight;
            const scrolling = scrollTop > 0;

            // Only update drop shadow when leaving or entering top
            if (scrolling !== this.state.scrolling) {
                this.setState({ scrolling });
            }

            this.props.handleScroll?.(
                scrollTop,
                scrollVisibleHeight,
                scrollFullHeight
            );
        }
    }

    fitToAnchor() {
        if (this.props.anchor.current) {
            const boundingRect =
                this.props.anchor.current.getBoundingClientRect();
            if (boundingRect.width !== this.state.minWidth) {
                this.setState({
                    minWidth: boundingRect.width,
                });
            }
        }
    }

    componentDidMount(): void {
        this.fitToAnchor();
        this.props.searchInput?.current?.focus();
        window.addEventListener('resize', this.fitToAnchor);
    }

    componentDidUpdate() {
        this.fitToAnchor();
    }

    componentWillUnmount(): void {
        window.removeEventListener('resize', this.fitToAnchor);
    }

    render() {
        const {
            search,
            textField,
            scrollContainer,
            selectedValue,
            listItems,
            handleClick,
            handleBlur,
            focus,
            options,
            show,
        } = this.props;

        const ListItem = this.props.desktopListItem || DefaultListItem;

        const Headers: React.ComponentType | undefined = this.props.headers;

        return (
            <div
                onKeyDown={this.props.onKeyDown}
                className="txr-dropdown__search-container"
                style={{
                    minWidth: this.state.minWidth,
                }}
                role="listbox"
            >
                <div
                    className="txr-dropdown__backdrop"
                    onClick={() => show(false)}
                />
                {search && (
                    <div className="txr-dropdown__search-field">
                        {textField}
                    </div>
                )}
                {Headers && <Headers />}
                <div
                    ref={scrollContainer}
                    onScroll={this.handleScroll}
                    className={classNames('txr-dropdown__search-results', {
                        'txr-dropdown__search-results--scrolling':
                            this.state.scrolling,
                    })}
                >
                    <div className="txr-dropdown__list">
                        {options.map((option: Option<T>, idx: number) => {
                            const className = classNames(
                                'txr-dropdown__search-result',
                                {
                                    'txr-dropdown__search-result--active':
                                        idx === focus,
                                }
                            );

                            return (
                                <div
                                    className={className}
                                    key={idx}
                                    ref={(node: HTMLDivElement) => {
                                        listItems[idx] = node;
                                    }}
                                    onClick={() => handleClick(option)}
                                    onBlur={handleBlur}
                                    role="option"
                                    data-testid={getOptionTestId(option)}
                                    aria-selected={
                                        option.value === selectedValue
                                    }
                                >
                                    <ListItem option={option} />
                                </div>
                            );
                        })}
                        {this.props.fetching && (
                            <div className="txr-dropdown__ajax_wheel">
                                <LoadingSpinner />
                            </div>
                        )}
                    </div>
                </div>
                <div className="txr-dropdown__footer">
                    {this.props.createNewButton && (
                        <this.props.createNewButton
                            closeDropdown={() => this.props.show(false)}
                            ref={this.props.createNewButtonRef}
                        />
                    )}
                </div>
            </div>
        );
    }
}
