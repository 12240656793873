import jQuery from 'jquery';
import {
    addSpacesuitBackdrops,
    removeSpacesuitBackdrops,
} from './spacesuit-backdrop';
const $ = jQuery;

(function () {
    /**
     * Toggle on/off styleClass which applies shadow to the 'stickyHeader' of dialogs
     * when scrolling.
     */
    $(window)
        .on('dialogopen', function () {
            const $dialogScope = $('.ui-dialog.ui-widget:visible');
            const $contentContainer = $(
                '.ui-dialog-content.ui-widget-content',
                $dialogScope
            );
            $contentContainer.on('scroll', function () {
                if ($contentContainer.scrollTop() === 0) {
                    $(
                        '.ui-dialog-titlebar.ui-widget-header',
                        $dialogScope
                    ).removeClass('stickyDialogHeaderShadow');
                } else {
                    $(
                        '.ui-dialog-titlebar.ui-widget-header',
                        $dialogScope
                    ).addClass('stickyDialogHeaderShadow');
                }
            });
            document.body.classList.add('jq-dialog-is-open');
        })
        .on('dialogclose', function () {
            if ($('.ui-dialog:visible').length === 0) {
                document.body.classList.remove('jq-dialog-is-open');
            }
        });

    if (window.narrowScreen) {
        return;
    }

    /**
     * Hack which delays replacing the dialog screen. Clicking outside a text field, after text
     * has been added to it, triggers the change event. This hack allows users to click buttons after
     * editing text fields before moving the dialog window. Related to TRIP-1193
     * Listening to the change event is probably to broad for this case.
     *
     * @param e
     */
    function delayDialogPlacementOnChange(e) {
        if (e.type === 'change') {
            setTimeout(function () {
                placeDialogInScreen(e);
            }, 120);
        } else {
            placeDialogInScreen(e);
        }
    }

    /**
     * This is a hack to make jQuery UI dialogs behave
     * more like MDL dialogs, applied when a dialog is
     * opened.
     *
     * It also makes sure the dialog contains itself
     * within the given room we have on the screen.
     */
    function placeDialogInScreen(e) {
        // The widget is the DOM-element which .dialog has been called, and is
        // thus the element applicable for .dialog('options', ...) for further
        // manipulation.
        const $dialog = $(e.target).closest('.ui-dialog');
        if ($dialog.is(':hidden')) {
            return;
        }

        const widgetId = $dialog.attr('aria-describedby');
        const $widget = $('#' + widgetId);

        //$widget.dialog('option', 'height', 'auto');

        /* If the dialog is a model (overlay over rest of screen) and model is inside #scrollContainer,
         * the jQuery UI overlay is not above meny, header nor actionbar. Therefore we add extra
         * overlays on these elements.
         */
        if (
            $widget.dialog('option', 'modal') &&
            $widget.closest('#scrollContainer').length === 1
        ) {
            addSpacesuitBackdrops();
        }

        const options = {
            maxWidth: $('#framelessContent').width(),
        };

        if (!window.narrowScreen) {
            options.position = {
                my: 'center center',
                at: 'center center',
                of: '#scrollContainer',
            };
            options.maxHeight = $('#framelessContent').height();
        }

        $widget.dialog('option', options);
        // Make sure that the dialog height doesn't expand, because it can disappear when inside MDL layout machinery
        // Should be called after the line above, so jQuery UI can calculate the "best" height first.
        // This doesn't work with dialog that have ajax-loaded content...
        //$widget.dialog('option', 'height', $dialog.height());
    }

    $(window)
        .on('dialogopen changeInDialog', function (e) {
            $(e.target)
                .closest('.ui-dialog')
                .off(
                    'change tlxPossibleWidthChange tlxNewRowAdded',
                    delayDialogPlacementOnChange
                )
                .on(
                    'change tlxPossibleWidthChange tlxNewRowAdded',
                    delayDialogPlacementOnChange
                );
            delayDialogPlacementOnChange(e);
        })
        .on(
            'dialogclose tlxNavigateAjax tlxtabsload tlxLoadPageContentDone tlxLazyLoadDone tlxOverlayHidden',
            function () {
                // Ideally we'd like to hide our fake overlay when there are no more dialogs open,
                // but there are currently too many edge-cases to handle.
                // const dialogOpen = $('body').hasClass('jq-dialog-is-open');
                const dialogOpen = false;

                if (!dialogOpen) {
                    removeSpacesuitBackdrops();
                } else {
                    // Overlay for menu, actionbar and header is pretty KISS
                    // No sophistication in cases if several dialogs are opened etc ...
                    $('.tmdl-modal-overlay').remove();
                }
            }
        );
})();
