import React, { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js/auto';
import { Color, ChartArea, ChartOptions, ChartData } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import './plugins';
import { formatAxis } from '@Component/Charts/common';
import { format } from '../../../../js/modules/format';

Chart.unregister(ChartDataLabels);

export type BarChartData = {
    value: number;
    color: Color;
    label: string;
};

export type Dataset = {
    label: string;
    data: Array<BarChartData>;
};

export type BarChartProps = {
    datasets: Array<Dataset>;
    type?: 'bar' | 'horizontalBar';
    stacked?: boolean;
    layout?: {
        padding?: number | Partial<ChartArea>;
    };
    hideLegend?: boolean;
};

function createDatasets(datasets: Array<Dataset>): ChartData<'bar'> {
    return {
        labels: datasets
            .map((dataset) => dataset.data.map((data) => data.label))
            .flat(),
        datasets: datasets.map((dataset) => ({
            data: dataset.data.map((d) => d.value),
            maxBarThickness: 30,
            backgroundColor: dataset.data.map((d) => d.color),
            label: dataset.label,
        })),
    };
}

function createOptions(props: BarChartProps): ChartOptions<'bar'> {
    return {
        responsive: true,
        maintainAspectRatio: false,
        indexAxis: props.type === 'horizontalBar' ? 'y' : 'x',
        scales: {
            yAxes: {
                beginAtZero: true,
                ticks: {
                    callback: formatAxis,
                },
                stacked: props.stacked ?? false,
            },
            xAxes: {
                stacked: props.stacked ?? false,
            },
        },
        layout: {
            padding: props.layout
                ? props.layout.padding
                : {
                      top: 20,
                  },
        },
        plugins: {
            datalabels: {
                formatter: (value: any) => {
                    return format.amount(value);
                },
                anchor: 'end',
                align: 'end',
            },
            legend: {
                position: 'bottom',
                display: props.hideLegend !== true,
            },
            tooltip: {
                callbacks: {
                    label: function formatLabel(tooltipItem) {
                        const label =
                            tooltipItem.dataset.data[tooltipItem.dataIndex];
                        return format.amount(label);
                    },
                },
            },
        },
    };
}

export const BarChart: React.FC<BarChartProps> = (props: BarChartProps) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [chart, setChart] = useState<Chart<'bar'> | null>(null);

    useEffect(() => {
        if (canvasRef.current == null) {
            return;
        }
        if (chart == null) {
            setChart(
                new Chart(canvasRef.current, {
                    plugins: [ChartDataLabels],
                    type: 'bar',
                    data: createDatasets(props.datasets),
                    options: createOptions(props),
                })
            );
        } else {
            chart.data = createDatasets(props.datasets);
            chart.update();
        }
    });

    return (
        <canvas
            ref={canvasRef}
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
            }}
        />
    );
};
