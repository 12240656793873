import { ModelAPIV1 } from '@General/types/Api';
import { notUndefined } from '@General/Helpers';
import { ApiResultMapperNoUndefinedResult } from '@General/Provider';
import { JSPDropdownFetcher } from '../JSPDropdownFetcher';
import { CreateButtonComponent, Option } from '../../types';
import { createCreateNewButton } from '@Component/DropDown/CreateNewButton/CreateNewButton';

interface UnionDetailsEntry extends ModelAPIV1 {
    readonly displayName: string;
}

const unionDetailsMapper: ApiResultMapperNoUndefinedResult<UnionDetailsEntry> =
    (item) => ({
        value: `${item.id}`,
        displayName: item.displayName,
        item,
    });

class UnionDetailsFetcher extends JSPDropdownFetcher<UnionDetailsEntry> {
    asyncJsonrpcGetter: Function;

    constructor(scope: HTMLDivElement, queryAttributes: Record<string, any>) {
        super(scope, queryAttributes, unionDetailsMapper);
        this.asyncJsonrpcGetter = asyncrpc.UnionDetails.get;
    }

    getParams(): ObjectIndex {
        return {};
    }

    async fetchList(params: ObjectIndex): Promise<Option<UnionDetailsEntry>[]> {
        return new Promise((resolve, reject) => {
            try {
                jsonrpc.UnionDetails.searchForUnionDetails(
                    (result: UnionDetailsEntry[] | null) => {
                        resolve(
                            (result ?? [])
                                .map(this.getMapper(params))
                                .filter(notUndefined)
                        );
                    },
                    this.getMarshallSpec(),
                    0,
                    params.name || ''
                );
            } catch (e) {
                defaultJsonRpcExceptionHandler(e);
                reject(e);
            }
        });
    }

    createCreateNewButton(
        setSelected: (id: number) => void
    ): CreateButtonComponent | undefined {
        // If you are authorized to select a union, you are authorized to create one. No auth check.
        return createCreateNewButton(
            setSelected,
            'text_new_union',
            'createUnion'
        );
    }
}

export const exports = {
    fetcher: UnionDetailsFetcher,
    type: 'UnionDetails',
    link: (id: number, contextId: number) =>
        `/execute/wageSettingsMenu?&contextId=${contextId}#unionTab`,
};
