import classNames from 'classnames';
import { mapColorToStatus } from './mappers';

export const StatusIcon = ({ status }: { status: string }) => {
    const color = mapColorToStatus.get(status);
    if (!status) {
        return null;
    }
    return (
        <div
            className={classNames(color, 'atl-rounded-full')}
            style={{
                width: '10px',
                height: '10px',
            }}
        />
    );
};
