import {
    WarehouseOverviewHeader,
    WarehouseOverviewLayout,
} from '@Page/LogisticsV2/pages/Warehouse/overview/WarehouseOverviewComponents';
import { AutoSaveStatusType } from '@Page/LogisticsV2/utils/enums';
import { WarehouseOverviewTableWithFilters } from '@Page/LogisticsV2/pages/Warehouse/overview/table/WarehouseOverviewTableWithFilters';
import { useState } from 'react';
import './WarehouseOverview.css';
export function WarehouseOverviewPage() {
    const [status, setStatus] = useState(AutoSaveStatusType.SAVED);
    const onChangeStatus = (newStatus: AutoSaveStatusType) => {
        setStatus(newStatus);
    };

    return (
        <WarehouseOverviewLayout>
            <WarehouseOverviewHeader status={status} />
            <WarehouseOverviewTableWithFilters
                onChangeStatus={onChangeStatus}
            />
        </WarehouseOverviewLayout>
    );
}
