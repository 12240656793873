import { ContentSwitch, ContentSwitcher } from '@tlx/atlas';
import React, { ReactNode } from 'react';
import useLocalStorage from '../../../../../../util/storage';

export function ChangePriceContentSection({
    children,
    isSupplierProduct,
}: {
    children: (contentValue: string) => ReactNode;
    isSupplierProduct: boolean;
}) {
    const [contentValue, setContentValue] = useLocalStorage<string>(
        isSupplierProduct
            ? `goldshark_product_overview_bulk-change-price-${window.loginEmployeeId}-supplier`
            : `goldshark_product_overview_bulk-change-price-${window.loginEmployeeId}`,
        'purchase'
    );

    return (
        <div className="atl-flex atl-flex-col atl-gap-24">
            <div>
                <ContentSwitcher data-testid="change-price-content-switcher">
                    <ContentSwitch
                        value="purchase"
                        checked={contentValue === 'purchase'}
                        onChange={() => {
                            setContentValue('purchase');
                        }}
                        data-testid={
                            'change-price-content-switcher-purchase-price'
                        }
                    >
                        {getMessage('text_cost_price')}
                    </ContentSwitch>
                    {!isSupplierProduct && (
                        <ContentSwitch
                            value="sales"
                            checked={contentValue === 'sales'}
                            onChange={() => {
                                setContentValue('sales');
                            }}
                            data-testid={
                                'change-price-content-switcher-sales-price'
                            }
                        >
                            {getMessage('text_sales_price')}
                        </ContentSwitch>
                    )}
                </ContentSwitcher>
            </div>
            {children(contentValue)}
        </div>
    );
}
