import { Option } from '@Component/DropDown/types';
import { KeyboardEvent } from 'react';
import { Expense as ExpenseType } from '../types/Expense';
import { extraFrame } from '../../../../../js/modules/extraFrame';
import { showActionLogMessage } from '../../../../../js/modules/notification';

export function getDisplayName(
    options: Array<Option<unknown>>,
    id: number
): string | undefined {
    const selectedDropdownField = options.find((value) => value.value == id);
    if (selectedDropdownField) {
        return selectedDropdownField.displayName;
    }
    return undefined;
}

export function putNotification(message: string) {
    const pageTitle = encodeHTML($.trim($('#menuHeader h1').text()));
    showActionLogMessage({
        pageName: pageTitle,
        date: new Date(),
        message,
    });
}

export function handleEnter(evt: KeyboardEvent) {
    if (evt.key === 'Enter') {
        evt.preventDefault();
    }
}

export function closeExtraFrame() {
    if (extraFrame.isExpanded()) {
        extraFrame.collapse();
    }
}

export function openExtraFrame(expense?: ExpenseType) {
    if (
        expense &&
        expense.attachment &&
        expense.attachment.id &&
        !extraFrame.isExpanded()
    ) {
        extraFrame.viewerDocument(expense.attachment.id);
        window.dispatchEvent(new CustomEvent('resize'));
    }
}

export function toggleExtraFrame(expense?: ExpenseType) {
    if (extraFrame.isExpanded()) {
        closeExtraFrame();
    } else {
        openExtraFrame(expense);
    }
}
