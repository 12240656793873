import { ModelAPIV1 } from '@General/types/Api';
import { Option } from '../../types';
import { notUndefined } from '@General/Helpers';
import { JSPDropdownFetcher } from '../JSPDropdownFetcher';
import { ApiResultMapper } from '@General/Provider';
import * as React from 'react';
import { SearchFilterGenerator } from '@Component/DropDown/types';

interface ActivityEntry extends ModelAPIV1 {
    readonly displayName: string;
    readonly ownerName?: string;
}

const activityMapper: ApiResultMapper<ActivityEntry> = (item) => ({
    value: item.id + '',
    displayName: item.displayName,
    secondary: item.ownerName,
    item,
});

const searchFilterGenerator: SearchFilterGenerator<unknown> = (query) => {
    return (option) =>
        option.displayName.toLowerCase().indexOf(query.toLowerCase()) > -1;
};

class ActivityFetcher extends JSPDropdownFetcher<ActivityEntry> {
    asyncJsonrpcGetter: Function;

    /**
     *
     * @param scope
     * @param queryAttributes
     * @param clientFilter
     */
    constructor(
        scope: HTMLDivElement,
        queryAttributes: Record<string, any>,
        clientFilter: (object: Option<any>) => boolean
    ) {
        super(scope, queryAttributes, activityMapper);
        this.asyncJsonrpcGetter = asyncrpc.Activity.get;
        if (clientFilter && typeof clientFilter === 'function') {
            this.clientFilter = clientFilter;
        }
    }

    getMarshallSpec() {
        // This seems very senseless, default value is -1, which is "true" in javascript. So per default
        // the activity is a task.
        const isTask = this.getQueryAttribute('isTask', -1);
        if (isTask) {
            return window.marshallSpec('id', 'displayName', 'ownerName');
        } else {
            return window.marshallSpec('id', 'displayName');
        }
    }

    getParams(): ObjectIndex {
        const { startDate, endDate } = this.getDates();
        return {
            startDate,
            endDate,
            projectId: this.getQueryAttribute('projectElementId', 0),
            isChargeable: this.getQueryAttribute('isChargeable', -1),
            allowTimeTracking: this.getQueryAttribute('allowTimeTracking', 1),
            isTask: this.getQueryAttribute('isTask', -1),
            isOpen: this.getQueryAttribute('isOpen', -1),
        };
    }

    async fetchList(params: ObjectIndex): Promise<Option<ActivityEntry>[]> {
        return new Promise((resolve, reject) => {
            try {
                return jsonrpc.Activity.searchForActivities(
                    (result: ActivityEntry[] | null) => {
                        const mapped = (result ?? [])
                            .filter(notUndefined)
                            .map(this.getMapper(params))
                            .filter(notUndefined);
                        resolve(mapped);
                    },
                    this.getMarshallSpec(),
                    window.loginCompanyId,
                    params.projectId,
                    params.allowTimeTracking,
                    params.isChargeable,
                    params.startDate,
                    params.endDate,
                    params.name || '',
                    params.isTask,
                    params.isOpen
                );
            } catch (e) {
                defaultJsonRpcExceptionHandler(e);
                reject(e);
            }
        });
    }
}

export const exports = {
    fetcher: ActivityFetcher,
    type: 'Activity',
    searchFilterGenerator: searchFilterGenerator,
};
