import { Signer } from '@Page/CompanyVerificationFlow/Types';
import { useFormContext } from 'react-hook-form';
import { ApprovedIcon, Group, Input, SendIcon } from '@tlx/atlas';
import { isEmpty } from 'lodash';
import React from 'react';
import { SendSigningRequestFormValues } from '@Page/CompanyVerificationFlow/SendSigningRequestForm/SendSigningRequestForm';
import { validateEmailList } from '../../../../../util/validateEmail';

export type EmailContentProps = {
    thisIsMeToggled: boolean;
    signer: Signer;
    isEditMode: boolean;
    disabled: boolean;
    reminderError: string;
    reminderSent: boolean;
};

export function EmailContent({
    signer,
    thisIsMeToggled,
    isEditMode,
    disabled,
    reminderError,
    reminderSent,
}: EmailContentProps) {
    const {
        register,
        formState: { errors },
    } = useFormContext<SendSigningRequestFormValues>();

    if (thisIsMeToggled && isEditMode) {
        return (
            <Group>
                <Input
                    key={`${signer.id}-thisIsMeInputReadOnly`}
                    type="email"
                    data-testid="signerEmailInputReadOnly"
                    className={'atl-w-full'}
                    readOnly
                    disabled
                />
            </Group>
        );
    }

    if (isEditMode || signer.signatureStatus === 'NOT_SIGNED') {
        const errorMessage = errors.signers?.[signer.id]?.email?.message;
        return (
            <Group>
                <Input
                    className={'atl-w-full'}
                    key={`${signer.id}`}
                    type="email"
                    data-testid="signerEmailInput"
                    {...register(`signers.${signer.id}.email`, {
                        validate: (value) => validateEmailList(value, true),
                    })}
                    disabled={disabled}
                />
                <div className="atl-text-red-100">
                    {`${errorMessage ?? ''}`}
                </div>
            </Group>
        );
    }

    if (signer.signatureStatus === 'PENDING_SIGNATURE' && reminderSent) {
        return (
            <div className="atl-text-base atl-text-grey-80 atl-flex atl-items-center email-or-signature-status-group">
                <SendIcon className="atl-mr-8" />
                <div className={'atl-w-full'}>
                    {getMessage('text_reminder_sent_to', signer.email)}
                </div>
                <div className="atl-text-red-100 atl-mt-4">{reminderError}</div>
            </div>
        );
    }

    if (
        signer.signatureStatus === 'PENDING_SIGNATURE' &&
        !isEmpty(signer.email)
    ) {
        return (
            <div className="atl-text-base atl-text-grey-80 atl-flex atl-items-center email-or-signature-status-group">
                <SendIcon className="atl-mr-8" />
                <div className={'atl-w-full'}>
                    {getMessage('invoice_email_sent_to', signer.email)}
                </div>
                <div className="atl-text-red-100 atl-mt-4">{reminderError}</div>
            </div>
        );
    }

    // What does this state mean? You have sent email to them so it's pending,
    // but then the email has been wiped to blank? Should this be allowed/possible?
    if (
        signer.signatureStatus === 'PENDING_SIGNATURE' &&
        isEmpty(signer.email)
    ) {
        return (
            <div className="atl-text-base atl-text-grey-80 atl-flex atl-items-center email-or-signature-status-group">
                <div className={'atl-w-full'}>
                    {getMessage(
                        'text_kyc_pending_signature_from_logged_in_user'
                    )}
                </div>
                <div className="atl-text-red-100 atl-mt-4">{reminderError}</div>
            </div>
        );
    }

    return (
        <div className="atl-text-base atl-text-grey-80 atl-flex atl-items-center email-or-signature-status-group">
            <ApprovedIcon className="atl-mr-8" />
            {getMessage('text_signed')}
        </div>
    );
}
