/**
 * Wrapper around `Number.parseInt` for easier handling when default values for parsing fails are not 0.
 * The problem stems from 0 being falsy and the `parseInt(input, 10) || 0` being a very common shorthand.
 *
 * Example:
 *   `Number.parseInt('0', 10) || 1` will return 1 even tho '0' is a valid integer.
 *
 * @param value        The value to parse as an integer.
 * @param radix        The radix to parse with.
 * @param defaultValue The value to return if parsing fails.
 *
 * @return The parsed integer or the given default.
 *
 * @author tellef
 * @date 2019-01-11
 */
export function parseIntWithDefault<R extends MaybeNumber>(
    value: string | number | undefined | null,
    radix: number,
    defaultValue: R
): number | R {
    if (value === undefined || value === null || value === '') {
        return defaultValue;
    }

    const result = Number.parseInt(value, radix);
    return Number.isNaN(result) ? defaultValue : result;
}

/**
 * Wrapper around `Number.parseFloat` for easier handling when default values for parsing fails are not 0.
 * The problem stems from 0 being falsy and the `parseFloat(input) || 0` being a very common shorthand.
 *
 * Example:
 *   `Number.parseFloat('0') || 1.5` will return 1.5 even tho '0' is a valid float.
 *
 * @param value        The value to parse as a float.
 * @param defaultValue The value to return if parsing fails.
 *
 * @return The parsed float or the given default.
 *
 * @author tellef
 * @date 2019-01-11
 */
export function parseFloatWithDefault<R extends MaybeNumber>(
    value: string | number | undefined | null,
    defaultValue: R
): number | R {
    if (value === undefined || value === null || value === '') {
        return defaultValue;
    }

    const result = Number.parseFloat(value);
    return Number.isNaN(result) ? defaultValue : result;
}

/**
 * Clamps the given number to the given range.
 *
 * @param {number} min   The minimum that the value is allowed to be.
 * @param {number} value The value to clamp.
 * @param {number} max   The maximum that the value is allowed to be.
 *
 * @return A number in the given range.
 *
 * @author tellef
 * @date 2020-08-07
 */
export function clamp(min: number, value: number, max: number) {
    if (value < min) {
        return min;
    }

    if (value > max) {
        return max;
    }

    return value;
}

// Helper constants for the min/max values Java Integers.
export const INT_MIN_VALUE = -2147483648;
export const INT_MAX_VALUE = 2147483647;
