import { ModelAPIV1 } from '@General/types/Api';
import { notUndefined } from '@General/Helpers';
import { ApiResultMapper } from '@General/Provider';
import * as React from 'react';
import { JSPDropdownFetcher } from '../JSPDropdownFetcher';
import { Option } from '../../types';
import { dateUtil } from '../../../../../../js/modules/date';

interface DivisionEntry extends ModelAPIV1 {
    readonly divisionNumberAndName: string;
}

const divisionMapper: ApiResultMapper<DivisionEntry> = (item) => ({
    value: `${item.id}`,
    displayName: item.divisionNumberAndName,
    item,
});

class DivisionFetcher extends JSPDropdownFetcher<DivisionEntry> {
    asyncJsonrpcGetter: Function;

    constructor(scope: HTMLDivElement, queryAttributes: Record<string, any>) {
        super(scope, queryAttributes, divisionMapper);
        this.asyncJsonrpcGetter = asyncrpc.Company.get;
    }

    getMarshallSpec() {
        return window.marshallSpec('id', 'divisionNumberAndName');
    }

    getParams(): ObjectIndex {
        return {
            activeOnly: this.getQueryAttribute('activeOnly', false),
            activeEndDate: this.getQueryAttribute(
                'activeEndDate',
                dateUtil.formatDate(new Date(), 'yyyy-MM-dd')
            ),
        };
    }

    async fetchList(params: ObjectIndex): Promise<Option<DivisionEntry>[]> {
        return new Promise((resolve, reject) => {
            try {
                jsonrpc.Company.searchForDivisions(
                    (result: DivisionEntry[] | null) => {
                        const mapped = (result ?? [])
                            .filter(notUndefined)
                            .map(this.getMapper(params))
                            .filter(notUndefined);
                        resolve(mapped);
                    },
                    this.getMarshallSpec(),
                    window.loginCompanyId,
                    params.activeOnly,
                    params.activeEndDate,
                    params.name,
                    this.tlxSelectMaxRows,
                    0
                );
            } catch (e) {
                defaultJsonRpcExceptionHandler(e);
                reject(e);
            }
        });
    }
}

export const exports = {
    fetcher: DivisionFetcher,
    type: 'Division',
};
