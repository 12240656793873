import { MapModel } from '@Page/LogisticsV2/types/returnTypes';
import {
    NameToMappingType,
    ProductImportSuggestedMappingType,
    ProductRowJSONType,
    step,
} from './types';

export function checkDuplicates(map: NameToMappingType) {
    const idToNameMap: { [id: number]: string[] } = {};
    const result: { [id: number]: string[] } = {};
    Object.entries(map).forEach(([key, value]) => {
        if (idToNameMap[value.id] !== undefined) {
            idToNameMap[value.id].push(key);
        } else {
            idToNameMap[value.id] = [key];
        }
        if (idToNameMap[value.id].length > 1 && value.id !== -1) {
            result[value.id] = idToNameMap[value.id];
        }
    });
    return result;
}

export function checkMandatory(
    map: NameToMappingType,
    requiredFields: ProductImportSuggestedMappingType[]
) {
    const result: { [name: string]: boolean } = {};
    const mappedIds = Object.values(map).map((value) => value.id);
    Object.entries(requiredFields).forEach(([key, value]) => {
        result[value.displayName] = mappedIds.includes(value.id);
    });
    return result;
}

export function checkError(map: NameToMappingType, mappingName: string) {
    const duplicates = checkDuplicates(map);
    let error;
    Object.values(duplicates).forEach((values) =>
        values.map((fieldName) => {
            if (fieldName === mappingName) {
                error = getMessage('text_import_mapping_error_duplicate');
            }
        })
    );
    return error;
}

export function mapRowContent(
    headers: ProductImportSuggestedMappingType[],
    rowContent: ProductRowJSONType
) {
    const result: MapModel<string | number> = {};
    if (rowContent === undefined) {
        return {};
    }
    headers.forEach((header) => {
        Object.entries(rowContent).forEach(([key, value]) => {
            if (key === header.fieldName) {
                result[key] = value;
            }
        });
    });
    return result;
}

export const mapTextToStatus = new Map<string, string>([
    ['NEW', 'text_new'],
    ['UPDATE', 'text_updated'],
]);

export const mapStatusColor = new Map<
    string,
    'success' | 'warning' | 'error' | 'neutral'
>([
    ['NEW', 'success'],
    ['UPDATE', 'warning'],
    ['ERROR', 'error'],
]);

export const stepTitles = {
    [step.FILE]: 'ProductImportFileUploadStep',
    [step.MAPPING]: 'ProductImportMappingStep',
    [step.VALIDATE]: 'ProductImportValidationStep',
    [step.RESULT]: 'ProductImportResultStep',
};
