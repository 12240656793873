import { CheckboxGroup, Input, Label, ToggleGroup } from '@tlx/atlas';

interface UserFeedbackContactRequestUncheckedProps {
    localStorageContactPreference: boolean;
    onCheck: (contactPreference: boolean) => void;
}

export function UserFeedbackContactRequestUnchecked({
    localStorageContactPreference,
    onCheck,
}: UserFeedbackContactRequestUncheckedProps) {
    return (
        <div className={' atl-flex atl-flex-col atl-p-16 atl-gap-16'}>
            {getMessage('text_request_user_contact')}
            <div className={'atl-flex atl-flex-col'}>
                <div className={'atl-flex atl-flex-row atl-gap-8'}>
                    <ToggleGroup>
                        <Input
                            type="toggle"
                            checked={localStorageContactPreference}
                            onChange={() =>
                                onCheck(!localStorageContactPreference)
                            }
                            data-testid="contact-me-checkbox"
                            id="contactMe"
                        />
                        <Label htmlFor="{'contactMe'}">
                            {getMessage('text_request_user_contact_accepted')}
                        </Label>
                    </ToggleGroup>
                </div>
            </div>
        </div>
    );
}
