import { Setup } from '@Page/ProjectInvoicingDialog/component/SetupForm/Setup';
import { useEffect, useState } from 'react';
import tlxFetch, {
    StandardResponseValueExtractor,
} from '../../../../api/tlxFetch';
import { createAPIRequest } from '../../../../hooks/fetch/createAPIRequest';
import {
    InvoiceSettings,
    InvoicingBankAccount,
} from '@Page/ProjectInvoicingDialog/types/InvoicingDialog.type';
import { SetupFormFieldValues } from '@Page/ProjectInvoicingDialog/component/SetupForm/SetupFormHook';
import { createAPIResponse } from '../../../../hooks/fetch/createAPIResponse';
import { ResponseWrapper } from '../../../../hooks/fetch/types';

export function ConnectedSetup({
    closeModal,
    invoiceSettings,
    companyOrgNr,
    refresh,
}: {
    closeModal: () => void;
    invoiceSettings: InvoiceSettings;
    companyOrgNr: string | undefined;
    refresh: () => void;
}) {
    const isCompanyAdmin = useFetchEntitlement(21);
    const isAuthAccountingSettings = useFetchEntitlement(107);

    const invoicingBankAccount = useFetchInvoicingBankAccount();

    return (
        <Setup
            closeModal={closeModal}
            invoiceSettings={invoiceSettings}
            isCompanyAdmin={isCompanyAdmin ?? false}
            isAuthAccountingSettings={isAuthAccountingSettings ?? false}
            invoicingBankAccount={invoicingBankAccount}
            companyOrgNr={companyOrgNr}
            submitSetup={submitSetup}
            refresh={refresh}
        />
    );
}

async function submitSetup(
    formValues: SetupFormFieldValues,
    invoiceSettings: InvoiceSettings,
    invoicingBankAccount?: InvoicingBankAccount
) {
    const hasFirstInvoiceNumber = invoiceSettings.hasFirstInvoiceNumber;
    const bankAccountReady = invoiceSettings.bankAccountReady;
    const invoiceAbroad = formValues.invoiceAbroad;

    if (!bankAccountReady && invoicingBankAccount) {
        const updatedInvoicingBankAccount: InvoicingBankAccount = {
            ...invoicingBankAccount,
            bankAccountNumber: formValues.invoicingBankAccountNumber,
            bankAccountIBAN: invoiceAbroad ? formValues.iban : '',
            bankAccountSWIFT: invoiceAbroad ? formValues.swift : '',
        };
        await putBankAccount(updatedInvoicingBankAccount);
    }

    if (!hasFirstInvoiceNumber) {
        await putInvoiceNr(Number(formValues.firstInvoiceNumber));
    }

    if (formValues.organizationNumber != undefined) {
        const organizationNumber = Number(formValues.organizationNumber);
        await putOrgNr(organizationNumber);
    }
}

async function putBankAccount(account: InvoicingBankAccount) {
    const request = createAPIRequest('/v2/ledger/account/' + account.id, {
        method: 'PUT',
        body: JSON.stringify(account),
        headers: {
            'content-type': 'application/json',
        },
    });

    const response = await window.fetch(request);
    const data = await createAPIResponse<ResponseWrapper<InvoicingBankAccount>>(
        request,
        response
    );

    return data?.value;
}

async function putOrgNr(organizationNumber: number) {
    const url = new URL('/v2/company', window.location.origin);

    return tlxFetch({
        url: url.href,
        method: 'PUT',
        body: { organizationNumber },
        callback: StandardResponseValueExtractor,
    });
}

async function putInvoiceNr(invoiceNumber: number) {
    const url = new URL(
        '/v2/invoice/settings/:setInvoiceNumber',
        window.location.origin
    );
    const params = url.searchParams;
    params.set('invoiceNumber', invoiceNumber.toString());

    return tlxFetch({
        url: url.href,
        method: 'PUT',
        callback: StandardResponseValueExtractor,
    });
}

export function useFetchEntitlement(entitlementId: number) {
    const [isAdmin, setIsAdmin] = useState<boolean | undefined>();

    useEffect(() => {
        const url = new URL('/v2/employee/entitlement', window.location.origin);

        tlxFetch({
            url: url.href,
            method: 'GET',
            callback: StandardResponseValueExtractor,
        }).then((data) => {
            const entitlement = data.response.filter(
                (e: any) => e.entitlementId == entitlementId
            );
            setIsAdmin(entitlement.length == 1);
        });
    }, [entitlementId]);

    return isAdmin;
}

function useFetchInvoicingBankAccount() {
    const { bankAccounts } = useFetchBankAccount();

    if (
        bankAccounts &&
        bankAccounts.length > 0 &&
        bankAccounts[0].name === 'Bankinnskudd'
    ) {
        return bankAccounts[0];
    }

    return undefined;
}

function useFetchBankAccount() {
    const [bankAccounts, setBankAccounts] = useState<InvoicingBankAccount[]>();
    const [isLoadingBankAccounts, setIsLoadingBankAccounts] = useState(true);
    const [bankAccountError, setBankAccountError] = useState<
        string | undefined
    >();

    useEffect(() => {
        fetch(
            createAPIRequest(
                '/v2/ledger/account?isBankAccount=true&isInvoiceAccount=true' +
                    '&fields=id,name,currency,isBankAccount,bankName,bankAccountNumber,bankAccountIBAN,bankAccountSWIFT'
            )
        )
            .then((response) => response.json())
            .then((data) => {
                setBankAccounts(data.values);
                setIsLoadingBankAccounts(false);
            })
            .catch((error) => {
                setBankAccountError(error);
                setIsLoadingBankAccounts(false);
            });
    }, []);

    return { bankAccounts, isLoadingBankAccounts, bankAccountError };
}
