/**
 * All endpoints/models for fetching objects. They are defined within the Models folders, and gets
 * dynamically imported here at runtime.
 *
 * A model must contain the fetcher and a string explaining its type (see the type definition straight above me).
 *
 */
import { Model } from '@Component/DropDown/types';
import { exports as Account } from './Models/Account';
import { accountHelpExports as AccountHelp } from './Models/Account';
import { exports as ActivatedDebtCollector } from './Models/ActivatedDebtCollector';
import { exports as Activity } from './Models/Activity';
import { exports as ActivityCategory } from './Models/ActivityCategory';
import { exports as Address } from './Models/Address';
import { exports as AmeldingType } from './Models/AmeldingType';
import { exports as AmeldingWageCode } from './Models/AmeldingWageCode';
import { exports as Association } from './Models/Association';
import { exports as BankAccountNumber } from './Models/BankAccountNumber';
import { exports as Country } from './Models/Country';
import { exports as Currency } from './Models/Currency';
import { exports as Customer } from './Models/Customer';
import { exports as CustomerCategory } from './Models/CustomerCategory';
import { exports as Department } from './Models/Department';
import { exports as Division } from './Models/Division';
import { exports as Document } from './Models/Document';
import { exports as DynamicControlFormTemplate } from './Models/DynamicControlFormTemplate';
import { exports as Employee } from './Models/Employee';
import { exports as EmployeeCategory } from './Models/EmployeeCategory';
import { exports as HeatfloorProduct } from './Models/HeatfloorProduct';
import { exports as HelpArticle } from './Models/HelpArticle';
import { exports as IbanOrBankAccountNumber } from './Models/IbanOrBankAccountNumber';
import { exports as Letterhead } from './Models/Letterhead';
import { exports as MailAccount } from './Models/MailAccount';
import { exports as Municipality } from './Models/Municipality';
import { exports as NumberSeries } from './Models/NumberSeries';
import { exports as OrderOut } from './Models/OrderOut';
import { exports as PaperVoucher } from './Models/PaperVoucher';
import { exports as Product } from './Models/Product';
import { exports as ProductSimple } from './Models/ProductSimple';
import { exports as ProductUnit } from './Models/ProductUnit';
import { exports as ProductGroup } from './Models/ProductGroup';
import { exports as ProductWarehouse } from './Models/ProductWarehouse';
import { exports as ProductWarehouseLocation } from './Models/ProductWarehouseLocation';
import { exports as Profession } from './Models/Profession';
import { exports as Project } from './Models/Project';
import { exports as ProjectCategory } from './Models/ProjectCategory';
import { exports as ProjectControlSchemaType } from './Models/ProjectControlSchemaType';
import { exports as ProjectSubContract } from './Models/ProjectSubContract';
import { exports as Prospect } from './Models/Prospect';
import { exports as ProspectFiltered } from './Models/ProspectFiltered';
import { exports as RemitsSelectedStatus } from './Models/RemitsSelectedStatus';
import { exports as TransportType } from './Models/TransportType';
import { exports as TravelReportRateCategory } from './Models/TravelReportRateCategory';
import { exports as TravelReportRateCategoryGroup } from './Models/TravelReportRateCategoryGroup';
import { exports as TravelReportZone } from './Models/TravelReportZone';
import { exports as UnionDetails } from './Models/UnionDetails';
import { exports as VatType } from './Models/VatType';
import { exports as VatTypeShort } from './Models/VatTypeShort';
import { exports as Voucher } from './Models/Voucher';
import { exports as VoucherType } from './Models/VoucherType';
import { exports as WageCode } from './Models/WageCode';
import { exports as Warehouse } from './Models/Warehouse';
import { exports as WarehouseAndLocation } from './Models/WarehouseAndLocation';
import { exports as WarehouseLocation } from './Models/WarehouseLocation';
import { exports as TripletexSalesModuleType } from './Models/TripletexSalesModuleType';
import { exports as TaxTransactionType } from './Models/TaxTransactionType';
import { exports as Asset } from './Models/Asset';
import { exports as OvernightStop } from './Models/OvernightStop';
import { exports as Industry } from './Models/Industry';
import { exports as BusinessActivityType } from './Models/BusinessActivityType';

export const Models: Record<string, Model<unknown>> = {};

function addModel(model: any) {
    Models[model.type] = model;
}

addModel(Account);
addModel(AccountHelp);

addModel(ActivatedDebtCollector);

addModel(Activity);

addModel(ActivityCategory);

addModel(Address);

addModel(AmeldingType);

addModel(AmeldingWageCode);

addModel(Association);

addModel(BankAccountNumber);

addModel(Country);

addModel(Currency);

addModel(Customer);

addModel(CustomerCategory);

addModel(Department);

addModel(Division);

addModel(Document);

addModel(DynamicControlFormTemplate);

addModel(Employee);

addModel(EmployeeCategory);

addModel(HeatfloorProduct);

addModel(HelpArticle);

addModel(IbanOrBankAccountNumber);

addModel(Letterhead);

addModel(MailAccount);

addModel(Municipality);

addModel(NumberSeries);

addModel(OrderOut);

addModel(PaperVoucher);

addModel(Product);

addModel(ProductSimple);

addModel(ProductUnit);

addModel(ProductGroup);

addModel(ProductWarehouse);

addModel(ProductWarehouseLocation);

addModel(Profession);

addModel(Project);

addModel(ProjectCategory);

addModel(ProjectControlSchemaType);

addModel(ProjectSubContract);

addModel(Prospect);

addModel(ProspectFiltered);

addModel(RemitsSelectedStatus);

addModel(TransportType);

addModel(TravelReportRateCategory);

addModel(TravelReportRateCategoryGroup);

addModel(TravelReportZone);

addModel(UnionDetails);

addModel(VatType);

addModel(VatTypeShort);

addModel(Voucher);

addModel(VoucherType);

addModel(WageCode);

addModel(Warehouse);

addModel(WarehouseAndLocation);

addModel(WarehouseLocation);

addModel(TripletexSalesModuleType);

addModel(TaxTransactionType);

addModel(Asset);

addModel(OvernightStop);

addModel(Industry);

addModel(BusinessActivityType);
