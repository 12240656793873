import { GeneralState } from '../types/General';
import { ActionReducer } from '../types/Reducer';
import { UpdateEntitlement } from '../action/General.action';

const initialState: GeneralState = {
    entitlements: [],
    entitlementLookup: {},
};

const reducerUpdateEntitlement: ActionReducer<GeneralState, UpdateEntitlement> =
    (state, action) => ({
        ...state,
        entitlements: action.entitlements,
        entitlementLookup: action.entitlements.reduce(
            (p, c) => ({ ...p, [c.id]: c }),
            {}
        ),
    });

type SupportedActions = UpdateEntitlement;

export default (
    state: GeneralState = initialState,
    action: SupportedActions
) => {
    switch (action.type) {
        case UpdateEntitlement.type:
            return reducerUpdateEntitlement(state, action);
        default:
            return state;
    }
};
