import { SWRResponse } from 'swr';
import { ResponseWrapper } from './types';
import { KeyedMutator } from 'swr/_internal';

export type UseFetchState<TData, TError = any> = {
    data?: TData;
    error?: TError;
    isLoading: boolean;
    mutate?: KeyedMutator<ResponseWrapper<TData>>;
};

export function useFetchState<TData = unknown, TError = any>({
    data,
    error,
    isValidating,
    mutate,
}: SWRResponse<ResponseWrapper<TData>, TError>): UseFetchState<TData, TError> {
    const { isLoading } = getResponseWrapperState(
        data !== undefined,
        error !== undefined,
        isValidating
    );

    return {
        data: data?.value,
        error,
        isLoading,
        mutate,
    };
}

export function getResponseWrapperState(
    hasData: boolean,
    hasError: boolean,
    isValidating: boolean
): { isLoading: boolean } {
    const isLoading = isValidating && !hasData && !hasError;

    return {
        isLoading,
    };
}
