import React from 'react';
import { PageLoader } from '@Component/PageLoader';
import { RouteData } from '@General/Router';
import { Team } from '@General/Team';

const Page = () => {
    return (
        <PageLoader<{}>
            loader={async () => import('./MySubscription').then((m) => m.page)}
        />
    );
};

export const route: RouteData = {
    component: Page,
    path: '/execute/mySubscription',
    title: () => getMessage('text_my_subscription'),
    team: Team.BEEHIVE,
    documentationComponent: 336,
    auth: () => mySubscriptionForm,
};
