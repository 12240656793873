import '../style.css';
import { EditableDataTree } from '@Page/LogisticsV2/components/DataTree/EditableDataTree';
import { useEditableProductGroups } from './useEditableProductGroups';

export function ProductGroups() {
    const { nodes, addNode, onUpdate, onDelete, onCancel } =
        useEditableProductGroups();
    return (
        <form style={{ position: 'relative' }}>
            <div style={{ maxWidth: '984px' }}>
                <EditableDataTree
                    nodes={nodes}
                    addNode={addNode}
                    onUpdate={onUpdate}
                    onDelete={onDelete}
                    onCancel={onCancel}
                />
            </div>
        </form>
    );
}
