import jQuery from 'jquery';
import { Chart } from 'chart.js';
import { arrayUtil } from './modules/d-array';
import { app } from './modules/framework';
import { nav } from './modules/navigation';
import { dev } from './modules/dev-util';
import { dom } from './modules/dom';
import { dateUtil } from './modules/date';
import { utils } from './modules/utils';
import { browserInfo } from './modules/d-browserInfo';
import { getRelevantUrl } from './modules/scope';
import {
    changed,
    clearChanged,
    clearGeneralValidation,
    clearOtherTabsChanged,
    clearTabChanged,
    isChanged,
    isOtherTabChanged,
} from './modules/change';
import {
    checkboxFix,
    collapseExtra,
    deepUpdateIndices,
    getElement,
    getTableRowCount,
    indexedName,
    makeSafeCallback,
    setElementValue,
    showElement,
    tlxGetScope,
    toArray,
    updateDeleteButton,
    updateDigits,
} from './c-common';
import { encodeHTML } from './modules/encodeHTML';
import { flattenJson } from './modules/flattenJson';
import { tlxForms } from './modules/forms';
import { addContextId, tlxUrl } from './modules/url';
import { logException } from './legacy/clientLogging';
import { JSONRpcClient, marshallSpec, toJSON } from './legacy/jsonrpc';
import { jsonrpcUtil } from './modules/jsonrpcUtil';
import { extraFrame } from './modules/extraFrame';
import { tlxAlert } from './modules/alert';
import { stringUtils } from './modules/stringUtils';
import { handlePossibleHashChange } from './modules/ClientHash';
import { hasRoute, renderRoute } from '@General/Router';
import { renderUnexpectedError } from '@Page/Error';
import { store } from '@General/createStore';
import { legacyAdapter } from '@General/LegacyAdapter';
import { CSRF_HEADER_NAME, getCSRFToken } from './modules/csrf';
import {
    showActionLogMessage,
    showValidationMessage,
} from './modules/notification';
import { captureException } from '@sentry/browser';
import { mapHighchartToChartJS } from './modules/chart';

const $ = jQuery;

const isString = stringUtils.isString;

window.setLocale = function setLocale(newLocale) {
    window.locale = newLocale;
};

// DOM methods

/**
 * Returns next element as if the user pressed 'tab' (with the extension that hidden elements are omitted)
 * NB: only works for form elements inside a form and does not support equal tabindices properly (TODO minor)
 *
 * Ref: http://www.w3.org/TR/html4/interact/forms.html#tabbing-navigation
 */
window.getNextElement = function getNextElement(element) {
    function findNextValidElement(elements, startIndex, currentTabIndex) {
        const useTabIndex = currentTabIndex !== undefined;
        let nextElement = null;
        for (let i = startIndex; i < elements.length; i++) {
            const e = elements[i];
            if (!e) {
                continue;
            }
            if (e.disabled == undefined || e.disabled) {
                continue;
            }
            if (e.type === 'hidden') {
                continue;
            }
            if (useTabIndex) {
                if (e.tabIndex == undefined || e.tabIndex <= 0) {
                    continue;
                }
                if (e.tabIndex <= currentTabIndex) {
                    continue;
                }
            } else {
                if (e.tabIndex != undefined && e.tabIndex !== 0) {
                    continue;
                }
            }
            if ($(e).is(':hidden')) {
                continue;
            } // Hidden check is relative expensive
            if (useTabIndex) {
                if (e.tabIndex === currentTabIndex + 1) {
                    return e;
                }
                // At this point: e.tabIndex > currentTabIndex+1
                if (
                    nextElement === null ||
                    e.tabIndex <= nextElement.tabIndex
                ) {
                    nextElement = e;
                }
            } else {
                return e;
            }
        }
        // TODO ojb: support "wrapping"
        return nextElement;
    }

    if (element.form) {
        let tabIndex;
        if (element.tabIndex > 0) {
            tabIndex = element.tabIndex + 1;
        }

        const elements = toArray(element.form.elements); // Prevents Opera from freaking out

        let startSearchIndex = 0;
        if (!tabIndex) {
            startSearchIndex = arrayUtil.findIndex(elements, element) + 1;
            if (startSearchIndex === -1) {
                dev.consoleLog(
                    'Error: getNextElement failed. Parameter not found in form',
                    element
                );
            }
        }
        return findNextValidElement(elements, startSearchIndex, tabIndex);
    }
    return null;
};

/**
 * Used if element is outside post-form (isChanged has no effect) and we need to know if user has touched value.
 */
window.markAsChangedByUser = function markAsChangedByUser(element) {
    $(element).data('changedByUser', true);
};
window.isChangedByUser = function isChangedByUser(element) {
    return isChanged(element) || $(element).data('changedByUser');
};

function replaceChildren(node, newChild) {
    $(node).empty().append(newChild);
}

window.replaceChildren = replaceChildren;

window.replaceChildrenWithText = function replaceChildrenWithText(node, text) {
    replaceChildren(node, document.createTextNode(text));
};

// Tripletex

if (!window.getCompanyName) {
    window.getCompanyName = function getCompanyName() {
        return '';
    };
}

//To make behavior customizable without mass updating articles
window.openHelpArticle = function openHelpArticle(articleId) {
    const url = '/execute/docViewer?articleId=' + articleId;
    nav.popup(url);
};

/**
 * Does not update history api, etc. (see navigate[Ajax]).
 */
export async function loadPageContent(urlWithoutScope) {
    await window.ensureESMModulesAreLoaded();

    // FRAMEWORK: Feels a little dirty do to it this way? Why can't the title be rendered by BaseForm.getHTML? [ojb - 8. sep. 2014]
    function addTitle($scope) {
        if (!$scope.get(0).dataset['tlxTitle']) {
            return;
        } // not 100% sure this is the best policy ... some pages (eg. simple pages like error pages doesn't define tlxTitle)

        $scope.prepend(
            "<div id='menuHeader' data-testid='header-title' class='useAtlasHeader'><h1 class='clip'>" +
                encodeHTML($scope.get(0).dataset['tlxTitle']) +
                '</h1></div>'
        );
    }

    const contentUrl = tlxUrl.addUrlParameter(
        urlWithoutScope,
        'scope',
        'ajaxContent'
    );

    $('#bodyContent, #ajaxContent, .submenu')
        .trigger('tlxRemoveUpgradedMdlComponents')
        .remove();

    const $scope = $(
        "<div id='ajaxContent' class='tlxScope bodyContent' class='display:none' />"
    ).insertBefore('#tlxDebug'); // why not replace old div instead?
    window.currentStopWatch = null; // to prevent bug from bottomFrame on ticking clock.
    const $container = $("<div id='wrapperDiv'/>").appendTo($scope); // FRAMEWORK: could we get rid of this? (or - do we really need 1,2,3,4 div levels before we get to the real content?)

    // Should be redefined by requested page - clear to make sure old method can't be called.
    window.tlxInitializeState = null;

    // Abort currently running xhr if any.
    if (loadPageContent.runningXhr) {
        loadPageContent.runningXhr.abort();
        loadPageContent.runningXhr = null;
    }

    const reactPage = hasRoute(contentUrl);
    let title;
    if (reactPage) {
        try {
            renderRoute(contentUrl, 'wrapperDiv', $scope, true, {
                prefix: 'ajaxContent',
            });

            if ($scope.get(0).tlxShowTitle) {
                addTitle($scope);
            }
            title = $scope.get(0).dataset['tlxTitle']
                ? $scope.get(0).dataset['tlxTitle']
                : '';
            app.setTitle(
                window.getCompanyName() +
                    ' - ' +
                    title +
                    ' - ' +
                    window.tripletexSystemName
            );

            $(document).off('.tlxStickyTableInit');
            hideOverlays();
            showContent(true);
            $scope.trigger('tlxLoadPageContentDone', {
                url: urlWithoutScope,
                title: title,
                $container: $container,
            });
        } catch (error) {
            $container.empty();
            captureException(error, {
                extra: {
                    url: contentUrl,
                },
            });
            renderUnexpectedError('wrapperDiv', { error });
            hideOverlays();
            showContent(false);
        }

        return;
    }

    function onDoneHandler(data, textStatus, xhr) {
        const httpStatusOK = xhr.status >= 200 && xhr.status < 300;

        function doneLoadingScript() {
            hideOverlays();
            showContent(httpStatusOK);
            $scope.trigger('tlxLoadPageContentDone', {
                url: urlWithoutScope,
                title: title,
                $container: $container,
            });

            window.dispatchEvent(
                new CustomEvent('pageLoad-loadPageContentDone', {
                    detail: {
                        url: contentUrl,
                    },
                })
            );
        }

        try {
            /// Insert content
            const html = xhr.responseText;

            // Request was probably aborted
            if (html === undefined) {
                return;
            }

            $container.html(html);

            // Non-menu pages (e.g. timeliste) don't render their own title, so we do it here (wrapper.jsp renders the title in framefull)
            if ($scope.get(0).tlxShowTitle) {
                addTitle($scope);
            }

            const newClientHash = $container
                .find('[name=currentClientHash]')
                .val();

            handlePossibleHashChange(newClientHash);

            const title = $scope.get(0).dataset['tlxTitle']
                ? $scope.get(0).dataset['tlxTitle']
                : '';
            app.setTitle(
                window.getCompanyName() +
                    ' - ' +
                    title +
                    ' - ' +
                    window.tripletexSystemName
            );

            bindEventsInsideScope($scope);
            $scope.trigger('tlxStartInitialize');
            app.loadPageScript($scope, doneLoadingScript);
        } catch (e) {
            logException('Error in loadPageContent', e);
            tlxAlert(getMessage('validation_unexpected_error'));
            throw e;
        }
    }

    function onFailHandler(xhr, textStatus) {
        if (textStatus == 'abort') {
            return;
        }

        /**
         *  If our session has expired the response contains login page markup that
         *  cannot be rendered inside $container without resulting in a myriad
         *  of errors.
         *
         *  To prevent this we check for the login failure message code header
         *  prior to injecting markup and then defer to our
         *  global ajaxError handler that correctly redirects to the login page.
         *
         *  @see RequestProcessor#handleLoginException
         *  @see frameless.js#ajaxError
         */
        if (
            xhr.getResponseHeader('Private-Tlx-LoginFailedMessageCode') !== null
        ) {
            return;
        }

        if (window.contextId) {
            onDoneHandler(null, textStatus, xhr);
            return;
        }

        if (xhr.status === 401) {
            return; // See global ajaxError handler too
        } else if (xhr.status === 503 || xhr.status === 0) {
            const event = jQuery.Event('tlxCommunicationError');
            event.xhr = xhr;
            $container.trigger(event);
        } else if (xhr.status === 500) {
            document.write('');
            document.write(xhr.responseText);
        } else if (xhr.status === 404) {
            // If favouriteId header is present it means the deleted page was marked as a favourite and has been removed from the db.
            // Choosing to remove the favourite dom item from favourite menu manually here.
            // In theory, we should only need to reload the page/menu for it to be updated, however
            // Chrome is not consistent when navigating and reloading page at this moment.
            // manually removing it also saves a reload and feels "snappier"
            const favouriteId = xhr.getResponseHeader('FavouriteId');
            if (favouriteId) {
                $("[data-id='" + favouriteId + "']")
                    .closest('li')
                    .remove();
                sessionStorage.setItem('favouriteDeleted', 'true');
            }

            // sending the user back one step seems to work out of the box for most cases
            // may want to include referer/home page in response and navigate to that page for some scenarios as well
            nav.back();
        } else {
            $container.html(xhr.responseText);
            hideOverlays();
            showContent(false);
        }
    }

    loadPageContent.runningXhr = $.ajax(contentUrl, {
        headers: { [CSRF_HEADER_NAME]: getCSRFToken() },
    })
        .done(onDoneHandler)
        .fail(onFailHandler)
        .always(function () {
            loadPageContent.runningXhr = null;
        });
}

/**
 * Hide and remove content in scrollcontainer or body page is changed.
 */
export function hideContent() {
    collapseExtra();

    // $.hide trigger a getComputedStyle (to store the old display value)
    // Seems to be quite expensive occasionally, at least in ie, and we don't really need the functionality here
    const bodyContent = $('.bodyContent');
    if (bodyContent.length > 0) {
        // happens on e.g. backredirect
        bodyContent.get(0).style.display = 'none';
    }
    dom.showClass('.bodyWait');
    $('.js-favourite-button').hide();
}

function showContent(httpStatusOK) {
    dom.hideClass('.bodyWait');
    dom.showClass('.bodyContent');
    if (httpStatusOK) {
        $('.js-favourite-button').show();
    }
}

window.showContent = showContent;

window.onSubmit = function onSubmit(form, bookmark) {
    if (window.validate && !window.validate(form)) {
        return false;
    }
    form.action = form.action + '#' + bookmark;
    hideContent();
    checkboxFix(form);
    return true;
};

export function hideAndSubmit(form) {
    hideContent();
    checkboxFix(form);
    $('#validationIcon', document).removeClass('tlx-active');
    // Safari sometimes fails to submit properly when hideContent and form.submit is run together?!
    // The form seems to be submitted to a new tab.. "ressursplan" fail almost every time.
    // setTimeout seems to fix this issue.
    setTimeout(function () {
        form.submit();
    }, 0);
}

// Should be called something like getRowInfo() instead
function getGroupInfo(groupName, scope) {
    let prefix = '';
    if (scope && scope.tlxIdPrefix) {
        prefix = scope.tlxIdPrefix;
    }
    // Finds all rows where id= prefix + groupName + (index on the format "[i]")
    // Changed to regex instead of "starts with" because when you have two kinds of rows, one row might start with the name of the other
    const regexRow = new RegExp(
        prefix +
            groupName.replace('[', '\\[').replace(']', '\\]') +
            '\\[\\d*\\]$'
    );
    let $rows = $('tr').filter(function () {
        return regexRow.test(this.id);
    });

    if ($rows.length == 0) {
        // time sheet uses tbody
        $rows = $('tbody[id^="' + prefix + groupName + '\\["]');
    }

    // Get the last row, not by position in list but by highest id index
    let $templateRow = $rows.eq(0);
    let lastIndex = 0;

    //use var in loop. Safari 11 crashes on let
    for (let i = 1; i < $rows.length; i++) {
        const $row = $rows.eq(i);
        const templateId = $row.attr('id');
        const index = parseInt(
            templateId.substring(
                templateId.lastIndexOf('[') + 1,
                templateId.lastIndexOf(']')
            )
        );
        if (index > lastIndex) {
            $templateRow = $row;
            lastIndex = index;
        }
    }

    const templateId = $templateRow.attr('id');
    const templateIndex = templateId.substring(
        templateId.lastIndexOf('[') + 1,
        templateId.lastIndexOf(']')
    );

    $templateRow
        .data('rowIndex', templateIndex)
        .attr('data-row-index', templateIndex);

    /// Destroy certain functionality that's un-clonable. Must be reinitialized on the copy (TODO: avoid doing this each time)
    // + buttons:
    $templateRow.find('.tlx-popup-opener').remove();

    // input openers (eg. tlxSelect):
    $templateRow
        .find(':tlx-inputOpener')
        .inputOpener('destroy')
        .unbind('disableEvent');

    return {
        templateRow: $templateRow,
        rows: $rows,
        templateIndex: parseInt(templateIndex),
    };
}

window.getGroupInfo = getGroupInfo;

/**
 * Return a copy of the passed row with all "property indexes" updated.
 * Template indexes are also bumped. (might be relevant if the row doesn't end up in the DOM)
 *
 * jQuery UI widgets doesn't support clone, so they need various workarounds (http://bugs.jqueryui.com/ticket/3803)
 *
 * @param groupName
 * @param source row element to be copied
 * @param scope
 * @param insertAfterSource should the new row be inserted into the dom after the source row?
 * @param triggerInitializationEvent should the event to complete initialization be triggered?  Only relevant if insertAfterSource is true
 */
window.copyRow = function copyRow(
    groupName,
    source,
    scope,
    insertAfterSource,
    triggerInitializationEvent
) {
    const groupInfo = getGroupInfo(groupName, scope);

    const $source = $(source);

    // Must destroy input openers prior to copying :( (reinitialize after)
    $source
        .find(':tlx-inputOpener')
        .inputOpener('destroy')
        .unbind('disableEvent');

    // Hack to copy datepickers properly (related task 9817)
    //   datepicker("destroy") removes the icon, so we keep it and inserts it again after destroying
    //   This is only an issue for already used datepickers since they're lazily initialized
    const sourceDates = $source.find('.tlxDateField');
    const sourceCalendars = sourceDates.next().detach();
    sourceDates.datepicker('destroy');
    $.each(sourceDates, function (i) {
        $(this).after(sourceCalendars.eq(i));
    });

    // Hack to copy textareas properly (current value isn't copied by regular clone - see eg. http://bugs.jquery.com/ticket/3016)
    $source.find('textarea').each(function () {
        const $this = $(this);
        $this.data('tlxTextareaCloneWorkaround', $this.val());
    });

    const $clone = $source.clone(true);

    // Restore values on cloned textareas
    $clone.find('textarea').each(function () {
        const $this = $(this);
        $this.val($this.data('tlxTextareaCloneWorkaround'));
        $this.removeData('tlxTextareaCloneWorkaround');
    });

    // If we clone an element observed by an IntersectionObserver, the cloned element is no longer observed.
    $clone
        .find('.tlx-dropdown__react-container--observing')
        .removeClass('tlx-dropdown__react-container--observing');

    // Reinitialize plus buttons on the clone. (remove and add)
    $clone.find('.tlx-popup-opener').remove();

    deepUpdateIndices($clone, groupName, groupInfo.templateIndex, scope);
    updateDeleteButton($clone, groupName, groupInfo.templateIndex, scope);

    deepUpdateIndices(
        groupInfo.templateRow,
        groupName,
        groupInfo.templateIndex + 1,
        scope
    );
    updateDeleteButton(
        groupInfo.templateRow,
        groupName,
        groupInfo.templateIndex + 1,
        scope
    );

    if (insertAfterSource) {
        $clone.insertAfter(source);

        if (triggerInitializationEvent) {
            $($clone.get(0)).trigger('tlxNewRowAdded');
        }
    }

    updateDigits(groupInfo.templateRow);

    return $clone.get(0);
};

/**
 * Normally 'addNewRow' is what you want. This method does not fire tlxNewRowAdded, focus, etc.
 */
function addNewRowWithoutFocusing(groupName, scope, where) {
    where = where || 'bottom';

    const groupInfo = getGroupInfo(groupName, scope);
    const $rows = groupInfo.rows;
    const $templateRow = groupInfo.templateRow;
    const templateIndex = groupInfo.templateIndex;

    const $newRow = $templateRow.clone(true); // include events. Depends on general eventhandlers...

    deepUpdateIndices($templateRow, groupName, templateIndex + 1, scope);
    updateDeleteButton($templateRow, groupName, templateIndex + 1, scope);

    // If we clone an element observer by a IntersectionObserver, the cloned element is no longer observed.
    $newRow
        .find('.tlx-dropdown__react-container--observing')
        .removeClass('tlx-dropdown__react-container--observing');

    if (where === 'bottom') {
        $newRow.insertBefore($templateRow);
    } else if (where === 'bottom-1') {
        // For when there is two types of template rows
        $newRow.insertBefore($templateRow.prev());
    } else {
        $newRow.insertBefore(groupInfo.rows.first());
    }

    // NB! row must be in the DOM
    setElementValue(
        indexedName(groupName, templateIndex, 'deleted'),
        'false',
        scope
    );

    if (scope && scope.fillRow) {
        scope.fillRow(templateIndex, groupName);
    } else if (window.fillRow) {
        window.fillRow(templateIndex, groupName);
    }

    if (where === 'bottom') {
        $newRow.insertBefore($templateRow);
    } else if (where === 'bottom-1') {
        $newRow.insertBefore($templateRow.prev());
    } else {
        $newRow.insertBefore($rows.first());
    }

    showElement($newRow.get(0), scope);
    changed();

    updateDigits($templateRow);

    return $newRow.get(0);
}

window.addNewRowWithoutFocusing = addNewRowWithoutFocusing;

// "ajax style" using one hidden template row
window.addNewRow = function addNewRow(groupName, scope, where) {
    const newRow = addNewRowWithoutFocusing(groupName, scope, where);
    $(newRow).trigger('tlxNewRowAdded', { where: where });
    return newRow;
};

/// end dynamic rows }}

window.showNextRow = function showNextRow(groupName, i, cancel, scope) {
    if (!cancel) {
        let last = null;
        let lastJ = -1;
        let deleted = null;
        for (let j = getTableRowCount(groupName, scope) - 1; j > i; j--) {
            deleted = getElement(groupName + '[' + j + '].deleted', scope);
            if (deleted.value == 'false') {
                return;
            } else if (!deleted.getAttribute('deleteRow.dirty')) {
                last = deleted;
                lastJ = j;
            }
        }
        if (last) {
            last.value = 'false';
            if (scope && scope.fillRow) {
                scope.fillRow(window.size + 1, groupName);
            } else if (window.fillRow) {
                window.fillRow(window.size + 1, groupName);
            }
            showElement(groupName + '[' + lastJ + ']', scope);
            changed();
        }
    }
};

window.clearSelect = function clearSelect(select, exceptThisIndex) {
    for (let i = select.options.length - 1; i >= 0; i--) {
        if (exceptThisIndex == undefined || i != exceptThisIndex) {
            select.remove(i);
        }
    }
};

/* Used from SelectTag. */
window.fillListSelect = function fillListSelect(idOrElement, sync) {
    const element = isString(idOrElement)
        ? getElement(idOrElement)
        : idOrElement;
    if (element == null) {
        return;
    }

    const listFunctionArgs = element.getAttribute('listFunctionArgs');
    if (!listFunctionArgs) {
        return;
    }

    if (element.getAttribute('isFilled')) {
        return;
    }

    const ev = window.event;

    if (ev) {
        const keyCode = ev.keyCode;

        if (keyCode == 9 || keyCode == 16) {
            // Tab or shift
            return;
        }
    }

    if (
        element.selectedIndex >= 0 &&
        parseInt(element.options[element.selectedIndex].value) > 0
    ) {
        element.remove(element.selectedIndex);
    }

    if (sync) {
        // eslint-disable-next-line prefer-spread
        fillSelectSync.apply(null, listFunctionArgs.split(','));
        if (element.style.display === '') {
            element.focus();
        }
    } else {
        // eslint-disable-next-line prefer-spread
        fillSelectAsync.apply(null, listFunctionArgs.split(','));
    }

    element.setAttribute('isFilled', 'true');
    return true;
};

function fillSelect(select, objects, property, labelProperty, prefix, value) {
    if (!prefix) {
        prefix = '';
    }
    //var options = new Array(objects.length + 1);
    //options[0] = select.innerHTML;
    for (let i = 0; i < objects.length; i++) {
        const optValue = property ? objects[i][property] : '' + objects[i];
        const label = labelProperty
            ? objects[i][labelProperty]
            : '' + objects[i];
        addOption(
            select,
            prefix + label,
            optValue,
            optValue == value,
            null,
            objects[i]
        );
    }
    /*
    select.innerHTML = "";
    var html = select.outerHTML;
    var splitPoint = html.toUpperCase().indexOf("</SELECT>");
    select.outerHTML = html.substring(0, splitPoint) + options.join("") + html.substring(splitPoint);
    */
}

window.fillSelect = fillSelect;

function addOption(select, label, value, selected, defaultSelected, tlxObject) {
    //var opt = new Option(label, value, selected ? true : false);
    const opt = new Option(label, value);
    const index = select.options.length;
    select[index] = opt;
    if (selected) {
        opt.selected = true;
        select.selectedIndex = index;
    }

    opt.defaultSelected = defaultSelected ? true : false;

    if (tlxObject) {
        opt.setAttribute('tlxObject', toJSON(tlxObject));
    }

    return opt;
}

window.addOption = addOption;

// XLS

//typically used for functions using synchronous ajax calls. Not ideal though.. (i.e. better if we had a global error handler, but there might be some benign error we just want to ignore)
window.errorWrap = function errorWrap(fn) {
    return function () {
        try {
            // eslint-disable-next-line prefer-rest-params
            return fn.apply(this, arguments);
        } catch (e) {
            defaultJsonRpcExceptionHandler(e);
        }
    };
};

// typically used for functions using synchronous ajax calls. Not ideal though.. (i.e. better if we had a global error handler, but there might be some benign error we just want to ignore)
function displayJsonRpcError(exception, message) {
    const t = message || getMessage('validation_unexpected_error');
    tlxAlert(t);
}

window.displayJsonRpcError = displayJsonRpcError;

function displayCompanySuspendedError() {
    const message = getMessage(
        'text_action_not_allowed_company_suspended_maintenance'
    );
    const buttons = [
        {
            text: getMessage('button_close'),
            click: function () {
                const $this = $(this);
                if ($this.dialog('instance') !== undefined) {
                    $this.dialog('close');
                    $this.dialog('destroy');
                }
            },
        },
    ];

    $(
        "<div id='alertDialog'><div id='alertDialogMsg' class='tlxDialogContent'><div" +
            '>' +
            message +
            '</div></div></div>'
    )
        .dialog({
            autoOpen: false,
            position: { at: 'top+200' },
            minHeight: 40,
            width: 410,
            buttons: buttons,
            modal: true,
            hide: 'fade',
            closeOnEscape: false,
            title: getMessage('text_information'),
        })
        .dialog('open');
}

window.displayCompanySuspendedError = displayCompanySuspendedError;

function displayUnavailableShardError() {
    const message = getMessage('text_action_not_allowed_shard_unavailable');
    const buttons = [
        {
            text: getMessage('button_close'),
            click: function () {
                const $this = $(this);
                if ($this.dialog('instance') !== undefined) {
                    $this.dialog('close');
                    $this.dialog('destroy');
                }
            },
        },
    ];

    $(
        "<div id='alertDialog'><div id='alertDialogMsg' class='tlxDialogContent'><div" +
            '>' +
            message +
            '</div></div></div>'
    )
        .dialog({
            autoOpen: false,
            position: { at: 'top+200' },
            minHeight: 40,
            width: 410,
            buttons: buttons,
            modal: true,
            hide: 'fade',
            closeOnEscape: false,
            title: getMessage('text_information'),
        })
        .dialog('open');
}

window.displayUnavailableShardError = displayUnavailableShardError;

function displayRevisionError() {
    const message = getMessage('validation_revision_error_with_refresh');
    const buttons = [
        {
            text: getMessage('button_close'),
            click: function () {
                const $this = $(this);
                if ($this.dialog('instance') !== undefined) {
                    $this.dialog('close');
                    $this.dialog('destroy');
                }
            },
        },
        {
            text: getMessage('button_refresh'),
            click: function () {
                const $this = $(this);
                if ($this.dialog('instance') !== undefined) {
                    $this.dialog('close');
                    $this.dialog('destroy');
                }
                nav.nav(location.href, {
                    checkChanges: false,
                });
            },
        },
    ];

    $(
        "<div id='alertDialog'><div id='alertDialogMsg' class='tlxDialogContent'><div" +
            '>' +
            message +
            '</div></div></div>'
    )
        .dialog({
            autoOpen: false,
            position: { at: 'top+200' },
            minHeight: 40,
            width: 410,
            buttons: buttons,
            modal: true,
            hide: 'fade',
            closeOnEscape: false,
            title: getMessage('text_information'),
        })
        .dialog('open');
}

window.displayRevisionError = displayRevisionError;

export function defaultJsonRpcExceptionHandler(exception) {
    if (exception) {
        // If the exception object does not contain information about java class,
        // and the status code is of type 500, assume server error.
        // If status code is 0, assume communication error.
        if (!exception.javaClass) {
            // jsonrpc communication failure, probably us
            if (exception.status <= 500 && exception.status >= 600) {
                tlxAlert(getMessage('text_offline_body_server'));
                return;
                // jsonrpc communication failure, probably client
            } else if (exception.status === 0) {
                tlxAlert(getMessage('text_offline_body_client'));
                return;
            }
        }
        switch (exception.javaClass) {
            case 'no.tripletex.common.exception.TripletexSecurityException':
                displayJsonRpcError(
                    exception,
                    getMessage('text_access_denied')
                );
                break;
            case 'no.tripletex.common.exception.CSRFException':
                displayJsonRpcError(exception, getMessage('text_csrf_denied'));
                break;
            case 'no.tripletex.common.exception.RevisionException':
            case 'no.tripletex.common.exception.ObjectDeletedException':
            case 'no.tripletex.common.exception.DatabaseConstraintException':
            case 'no.tripletex.common.exception.ConcurrencyException':
                displayRevisionError();
                break;
            case 'no.tripletex.tcp.model.CompanySuspendedException':
                displayCompanySuspendedError();
                break;
            case 'no.tripletex.tcp.model.UnavailableShardException':
                displayUnavailableShardError();
                break;
            default:
                displayJsonRpcError(exception);
                dev.consoleLog(
                    'defaultJsonRpcExceptionHandler:',
                    exception.message,
                    exception.stack
                );
        }
    }
}

/**
 * Used when $.load fails. Replaces the whole document with the xhr response.
 *
 * (defaultJsonRpcExceptionHandler used for failed json rpc calls)
 */
window.ajaxFail = function ajaxFail(jqXHR) {
    dev.consoleLog('jqXHR object: ' + jqXHR);
    dev.consoleLog('readyState: ' + jqXHR.readyState);
    dev.consoleLog('status: ' + jqXHR.status);
    dev.consoleLog('statusText: ' + jqXHR.statusText);
    dev.consoleLog('responseXML: ' + jqXHR.responseXML);
    $(document.documentElement).html(jqXHR.responseText);
    dev.consoleLog('getAllResponseHeaders: ' + jqXHR.getAllResponseHeaders());
};

function fillSelectSync(
    selectId,
    value,
    labelProperty,
    property,
    clazz,
    method
) {
    document.body.style.cursor = 'wait';
    const select = isString(selectId) ? getElement(selectId) : selectId;
    const disabled = select.disabled;
    tlxForms.disable(select);
    const selectedIndex = select.selectedIndex;
    addOption(select, getMessage('text_loading') + ' ...', value, true);
    const toBeRemoved = select.selectedIndex;
    const args = [marshallSpec(property, labelProperty)];
    for (let i = 6; i < arguments.length; i++) {
        // eslint-disable-next-line prefer-rest-params
        args.push(arguments[i]);
    }
    const c = window.jsonrpc[clazz];
    // eslint-disable-next-line prefer-spread
    const result = c[method].apply(c, args);
    fillSelect(select, result, property, labelProperty, false, value);
    if (
        select.selectedIndex == toBeRemoved &&
        typeof selectedIndex == 'number' &&
        selectedIndex >= 0
    ) {
        select.options[selectedIndex].selected = true;
    }
    select.remove(toBeRemoved);
    tlxForms.disable(select, disabled);
    document.body.style.cursor = 'default';
}

window.fillSelectSync = fillSelectSync;

function fillSelectAsync(selectId, value) {
    const disabled = fillSelectAsync.prepareSelect(selectId, value);

    const args = [];
    args.push(selectId);
    args.push(value);
    args.push(disabled);
    for (let i = 2; i < arguments.length; i++) {
        // eslint-disable-next-line prefer-rest-params
        args.push(arguments[i]);
    }
    fillSelectAsync.perform1.apply(this, args);
}

window.fillSelectAsync = fillSelectAsync;

fillSelectAsync.prepareSelect = function (selectId, value) {
    const select = getElement(selectId);

    if (select == null) {
        return;
    }

    select.setAttribute('fillSelectAsync.selectedIndex', select.selectedIndex);

    const ret = select.disabled;
    tlxForms.disable(select);
    addOption(select, getMessage('text_loading') + ' ...', value, true);
    return ret;
};

/**
 * Eng: This is only used in the screenshots "advanced assets" and "list resource groups".
 *      This is old legacy code (old tlx select) that is still in some places in the system.
 * Nor: Denne er kun i bruk i skjermbildet avansert bilag og list resource groups.
 *     Dette er gammel legacy-code (gamle tlx select) som henger igjen et par steder i systemet.
 */
fillSelectAsync.perform1 = function (
    selectIds,
    values,
    disableds,
    labelProperty,
    property,
    clazz,
    method
) {
    /*
    Returns a function that can be used as a callback function by fillSelectAsync.
    The created function assumes that an option will be (or has been) added (and selected)
    to display "Select is loading" or similar message. This option will be
    removed by the created function.

    Note: selectIds may be an array of ids or one single id. If selectIds is a single id,
        then values must be a single value, not an array. If selectedIds is an array
        then values may be a single value or an array. If it is a single value, then
        the same value will be used for all selects.
    */
    function makeSelectFillerCallback(
        selectIds,
        values,
        disableds,
        labelProperty,
        property
    ) {
        if (selectIds.constructor.toString().indexOf('Array') == -1) {
            selectIds = [selectIds];
        }
        if (values.constructor.toString().indexOf('Array') == -1) {
            const val = values;
            values = [];
            for (let i = 0; i < selectIds.length; i++) {
                values[i] = val;
            }
        }
        if (disableds.constructor.toString().indexOf('Array') == -1) {
            const val = disableds;
            disableds = [];
            for (let i = 0; i < selectIds.length; i++) {
                disableds[i] = val;
            }
        }
        return makeSafeCallback(function (result) {
            for (let i = 0; i < selectIds.length; i++) {
                const select = getElement(selectIds[i]);

                if (select) {
                    let selectedIndex = select.getAttribute(
                        'fillSelectAsync.selectedIndex'
                    );
                    if (
                        selectedIndex == null ||
                        selectedIndex == undefined ||
                        selectedIndex == ''
                    ) {
                        selectedIndex = -1;
                    }
                    if (typeof selectedIndex == 'string') {
                        selectedIndex = parseInt(selectedIndex);
                    }
                    const toBeRemoved = select.selectedIndex;
                    fillSelect(
                        select,
                        result,
                        property,
                        labelProperty,
                        false,
                        values[i]
                    );
                    if (
                        select.selectedIndex == toBeRemoved &&
                        typeof selectedIndex == 'number' &&
                        selectedIndex >= 0
                    ) {
                        select.options[selectedIndex].selected = true;
                    }
                    select.remove(toBeRemoved);
                    tlxForms.disable(select, disableds[i]);
                }
            }
        });
    }

    const args = [
        makeSelectFillerCallback(
            selectIds,
            values,
            disableds,
            labelProperty,
            property
        ),
    ];
    for (let i = 7; i < arguments.length; i++) {
        // eslint-disable-next-line prefer-rest-params
        args.push(arguments[i]);
    }
    const c = window.jsonrpc[clazz];
    // eslint-disable-next-line prefer-spread
    c[method].apply(c, args);
};

// Date input

// Used from TextTag.java!
window.dateOnChange = function dateOnChange(input) {
    if (input.value.trim() == '') {
        return true;
    }
    const date = dateUtil.parseDate(input.value, true);
    if (date) {
        input.value = dateUtil.formatDate(date, 'yyyy-MM-dd');
        return true;
    }

    return false;
};

// Extra frame

export function getActiveDocumentationComponent() {
    let scope;
    const activeTab = $('.tlxScope.ui-tabs-panel:visible');
    if (activeTab.length > 0) {
        scope = activeTab;
    } else {
        scope = $(document.body);
    }
    const componentElem = scope.find("[name='documentationComponent']");
    return componentElem.val() || -1;
}

window.handleJSONException = function handleJSONException(e) {
    dev.debugLine('Error: ' + toJSON(e));

    if (e.code == 107) {
        if (e.generalMessages && e.generalMessages.length > 0) {
            alert(e.generalMessages);
        }
    } else {
        displayJsonRpcError();
    }
};

//jQuery dependent functions

/**
 * Bind events to a page, must be run if new content is added to the DOM, such as in a pop-up.
 *
 * OBS: This has its own version in m-common.js!
 *
 * @param $newContent The new content that is added to the DOM
 * @param tabPeriodSelectorTrackingIdMap Map of period selector tracking IDs
 * @example
 * tabPeriodSelectorTrackingIdMap format:
 * {
 *     *scope ID*: {
 *         *period selector ID*: {
 *             prevPeriodButton: '*tracking ID*',
 *             nextPeriodButton: '*tracking ID*',
 *             allPeriodsButton: '*tracking ID*',
 *             soFarThisYearButton: '*tracking ID*',
 *             todayButton: '*tracking ID*',
 *             thisWeekButton: '*tracking ID*',
 *             thisMonthButton: '*tracking ID*',
 *             theWholeCurrentMonthButton: '*tracking ID*',
 *             okButton: '*tracking ID*',
 *             narrowScreenPeriodTab: '*tracking ID*',
 *             narrowScreenWeekTab: '*tracking ID*',
 *             narrowScreenMonthTab: '*tracking ID*',
 *             narrowScreenYearTab: '*tracking ID*',
 *             narrowScreenWageTermTab: '*tracking ID*',
 *             narrowScreenVatTermTab: '*tracking ID*'
 *         }
 *     }
 * }
 */
export function bindEventsInsideScope(
    $newContent,
    tabPeriodSelectorTrackingIdMap = {}
) {
    // FRAMEWORK: this method seems to mix global and "local" initialization.

    // clone filter form elements into "post forms" to preserve state:
    const namesToSkip = {
        act: 1,
        contextId: 1,
        javaClass: 1,
        documentationComponent: 1,
    };
    const $filterInputs = $newContent
        .find('#PeriodForm')
        .find('input')
        .not(function () {
            return !!namesToSkip[this.name];
        });
    $newContent
        .find('form[method="post"]')
        .append($filterInputs.clone().removeAttr('id').hide());

    const periodSelector = $(getElement('period', tlxGetScope($newContent)));
    if (periodSelector.closest($newContent).length > 0) {
        const {
            prevPeriodButtonTrackingId,
            nextPeriodButtonTrackingId,
            allPeriodsButtonTrackingId,
            soFarThisYearButtonTrackingId,
            todayButtonTrackingId,
            thisWeekButtonTrackingId,
            thisMonthButtonTrackingId,
            theWholeCurrentMonthButtonTrackingId,
            okButtonTrackingId,
            narrowScreenPeriodTabTrackingId,
            narrowScreenWeekTabTrackingId,
            narrowScreenMonthTabTrackingId,
            narrowScreenYearTabTrackingId,
            narrowScreenWageTermTabTrackingId,
            narrowScreenVatTermTabTrackingId,
        } = getPeriodSelectorButtonTrackingIds(
            tabPeriodSelectorTrackingIdMap,
            $newContent.attr('id'),
            periodSelector.attr('id')
        );
        // Only initialize periodSelector if inside the new content
        defaultPeriodInit(
            periodSelector,
            prevPeriodButtonTrackingId,
            nextPeriodButtonTrackingId,
            allPeriodsButtonTrackingId,
            soFarThisYearButtonTrackingId,
            todayButtonTrackingId,
            thisWeekButtonTrackingId,
            thisMonthButtonTrackingId,
            theWholeCurrentMonthButtonTrackingId,
            okButtonTrackingId,
            narrowScreenPeriodTabTrackingId,
            narrowScreenWeekTabTrackingId,
            narrowScreenMonthTabTrackingId,
            narrowScreenYearTabTrackingId,
            narrowScreenWageTermTabTrackingId,
            narrowScreenVatTermTabTrackingId
        );
    }

    // Prevent build up of one-shot events. (especially in frameless mode this is important, but could happen normally too. (bindEventsInsideScope is called for +dialogs, tabs, etc))
    // (ideally we'd have a mechanism for checking if an onshot is active, but it doesn't seem to be a public api for this(?). Could use a flag, but this is more robust/simpler)
    $(document).unbind('.tlxStickyTableInit');
    $(document).one(
        'scroll.tlxStickyTableInit resize.tlxStickyTableInit',
        function () {
            // Check on upgrade: jQuery UI - datepicker
            const $stickyTableHeader = $('thead').parent(
                ':not(.ui-datepicker-calendar):not(.noStickyHeader)'
            );

            $stickyTableHeader.each(function () {
                const $this = $(this);
                if ($this.closest('.section').length === 1) {
                    return;
                }

                /**
                 * Bugfix: On retina displays, where a jQuery UI tab has a lot of content, scrolling experience
                 * deteriorated + bottom content got invisible when initializing sticky table headers. We did not see
                 * this bug outside of jQuery UI tabs.
                 *
                 * The bug disappears when setting display on .ui-tabs-panel to inline or table, but then the floating
                 * header won't work either way.
                 *
                 * The bug also disappears when hiding the floating table header, but still running all relevant
                 * JavaScript. This indicates that the bug is purely a render issue in Chromium Blink.
                 *
                 * By experimentation the problem only occurred when tab content was above 7000 pixels in height. This
                 * number might need adjustments depending on screen size and computer specs?
                 *
                 **/
                if (browserInfo.isRetina() && browserInfo.isChrome()) {
                    const $tabsPanel = $this.closest('.ui-tabs-panel');
                    if ($tabsPanel.length === 1 && $tabsPanel.height() > 7000) {
                        return;
                    }
                }

                $this.stickyTableHeaders({
                    allHeaders: $this.is('.allHeadersSticky'),
                });
            });
        }
    );

    function getPeriodSelectorButtonTrackingIds(
        tabPeriodSelectorTrackingIdMap,
        scopeId,
        periodSelectorId
    ) {
        let prevPeriodButtonTrackingId = '';
        let nextPeriodButtonTrackingId = '';
        let allPeriodsButtonTrackingId = '';
        let soFarThisYearButtonTrackingId = '';
        let todayButtonTrackingId = '';
        let thisWeekButtonTrackingId = '';
        let thisMonthButtonTrackingId = '';
        let theWholeCurrentMonthButtonTrackingId = '';
        let okButtonTrackingId = '';
        let narrowScreenPeriodTabTrackingId = '';
        let narrowScreenWeekTabTrackingId = '';
        let narrowScreenMonthTabTrackingId = '';
        let narrowScreenYearTabTrackingId = '';
        let narrowScreenWageTermTabTrackingId = '';
        let narrowScreenVatTermTabTrackingId = '';
        if (
            tabPeriodSelectorTrackingIdMap[scopeId] &&
            tabPeriodSelectorTrackingIdMap[scopeId][periodSelectorId]
        ) {
            prevPeriodButtonTrackingId =
                tabPeriodSelectorTrackingIdMap[scopeId][periodSelectorId][
                    'prevPeriodButton'
                ] ?? '';
            nextPeriodButtonTrackingId =
                tabPeriodSelectorTrackingIdMap[scopeId][periodSelectorId][
                    'nextPeriodButton'
                ] ?? '';
            allPeriodsButtonTrackingId =
                tabPeriodSelectorTrackingIdMap[scopeId][periodSelectorId][
                    'allPeriodsButton'
                ] ?? '';
            soFarThisYearButtonTrackingId =
                tabPeriodSelectorTrackingIdMap[scopeId][periodSelectorId][
                    'soFarThisYearButton'
                ] ?? '';
            todayButtonTrackingId =
                tabPeriodSelectorTrackingIdMap[scopeId][periodSelectorId][
                    'todayButton'
                ] ?? '';
            thisWeekButtonTrackingId =
                tabPeriodSelectorTrackingIdMap[scopeId][periodSelectorId][
                    'thisWeekButton'
                ] ?? '';
            thisMonthButtonTrackingId =
                tabPeriodSelectorTrackingIdMap[scopeId][periodSelectorId][
                    'thisMonthButton'
                ] ?? '';
            theWholeCurrentMonthButtonTrackingId =
                tabPeriodSelectorTrackingIdMap[scopeId][periodSelectorId][
                    'theWholeCurrentMonthButton'
                ] ?? '';
            okButtonTrackingId =
                tabPeriodSelectorTrackingIdMap[scopeId][periodSelectorId][
                    'okButton'
                ] ?? '';
            narrowScreenPeriodTabTrackingId =
                tabPeriodSelectorTrackingIdMap[scopeId][periodSelectorId][
                    'narrowScreenPeriodTab'
                ] ?? '';
            narrowScreenWeekTabTrackingId =
                tabPeriodSelectorTrackingIdMap[scopeId][periodSelectorId][
                    'narrowScreenWeekTab'
                ] ?? '';
            narrowScreenMonthTabTrackingId =
                tabPeriodSelectorTrackingIdMap[scopeId][periodSelectorId][
                    'narrowScreenMonthTab'
                ] ?? '';
            narrowScreenYearTabTrackingId =
                tabPeriodSelectorTrackingIdMap[scopeId][periodSelectorId][
                    'narrowScreenYearTab'
                ] ?? '';
            narrowScreenWageTermTabTrackingId =
                tabPeriodSelectorTrackingIdMap[scopeId][periodSelectorId][
                    'narrowScreenWageTermTab'
                ] ?? '';
            narrowScreenVatTermTabTrackingId =
                tabPeriodSelectorTrackingIdMap[scopeId][periodSelectorId][
                    'narrowScreenVatTermTab'
                ] ?? '';
        }
        return {
            prevPeriodButtonTrackingId,
            nextPeriodButtonTrackingId,
            allPeriodsButtonTrackingId,
            soFarThisYearButtonTrackingId,
            todayButtonTrackingId,
            thisWeekButtonTrackingId,
            thisMonthButtonTrackingId,
            theWholeCurrentMonthButtonTrackingId,
            okButtonTrackingId,
            narrowScreenPeriodTabTrackingId,
            narrowScreenWeekTabTrackingId,
            narrowScreenMonthTabTrackingId,
            narrowScreenYearTabTrackingId,
            narrowScreenWageTermTabTrackingId,
            narrowScreenVatTermTabTrackingId,
        };
    }

    $newContent.find('.tlx-chart').each(function () {
        const data = $(this).data('chartJson');
        if (data) {
            const container = document.createElement('div');
            container.style.position = 'relative';
            const canvas = document.createElement('canvas');
            container.append(canvas);
            $(this).append(container);
            const configuration = mapHighchartToChartJS(data);
            new Chart(canvas, configuration);
        }
    });
}

// There is a version of this in m-common
export function hideElement(idOrElement, scope) {
    let $element = isString(idOrElement)
        ? $(getElement(idOrElement, scope))
        : $(idOrElement);
    if ($element.length == 0) {
        return;
    }

    if ($element.is('.tlx-dropdown__input')) {
        $element = $element.closest('.tlx-dropdown');
    }

    $element.hide();

    if ($element.is('.hasHeader')) {
        $element.prev().hide();
    }
    $(scope || tlxGetScope($element[0])).trigger('tlxPossibleWidthChange');
}

// callback used for next/previous arrows on menu/tab pages. (For example employeeMenu)
// Also used in docEditor (standaloneWrapper) TODO ojb: rename to a more generic name?
window.changeTabsCallback = function changeTabsCallback(url) {
    return function () {
        const fragmentId = $('.ui-tabs-active').attr('aria-controls');
        if (fragmentId && fragmentId.indexOf('ui-tabs-') == 0) {
            //keep the same tab index
            url = addContextId(url);
            $.sessionStorage.setItem(
                url,
                $.sessionStorage.getItem(
                    document.location.pathname + document.location.search
                )
            );
            nav.link(url);
        } else {
            nav.nav(url, { fragmentIdentifier: fragmentId }); // func doesn't work properly in tabs (opens with 'opener')
        }
    };
};

window.tlxGetXls = function tlxGetXls() {
    let xlsPage = false;

    const $scope = app.outermostScope();
    xlsPage = $scope.get(0).xlsPage;

    if (xlsPage) {
        const url = tlxUrl.addUrlParameter(getRelevantUrl(), 'xls', 'true');
        nav.download(url);
    } else {
        tlxAlert(getMessage('validation_no_excel_export'));
    }
};

window.tlxPdfExportDialog = function tlxPdfExportDialog() {
    if (isChanged()) {
        tlxAlert(getMessage('text_unsaved_error'));
        return;
    }

    const $scope = app.outermostScope();

    if ($scope.data('disablePdfExport')) {
        tlxAlert(getMessage('validation_no_pdf_export'));
        return;
    }

    let $pdfExportDialog = $('#pdfExportDialog');
    //If not found, generate a new one. This will only be done once (until new pageload).
    if (!$pdfExportDialog || $pdfExportDialog.length == 0) {
        const buttons = [
            {
                text: getMessage('button_ok'),
                class: 'tlx-green',
                click: function (ev) {
                    if (
                        !$(':input', $pdfExportDialog)
                            .filter(':visible')
                            .validate($pdfExportDialog.validationPopup())
                    ) {
                        ev.stopPropagation();
                        return false;
                    }

                    let url = getRelevantUrl();
                    url = tlxUrl.addUrlParameter(url, 'pdf', 'true');
                    const pdfSize =
                        $(
                            '#pageFormat option:selected',
                            $pdfExportDialog
                        ).val() +
                        ' ' +
                        $(
                            '#pageOrientation option:selected',
                            $pdfExportDialog
                        ).val();
                    url = tlxUrl.addUrlParameter(url, 'pdfSize', pdfSize);
                    url = tlxUrl.addUrlParameter(
                        url,
                        'menuHeader',
                        $.trim($('#menuHeader h1').text())
                    );

                    if ($('#pdfEmail', $pdfExportDialog).prop('checked')) {
                        url = tlxUrl.addUrlParameter(url, 'pdfAction', 'email');
                        url = tlxUrl.addUrlParameter(
                            url,
                            'pdfEmailAddress',
                            $('#emailAddress', $pdfExportDialog).val()
                        );
                        url = tlxUrl.addUrlParameter(
                            url,
                            'pdfEmailSubject',
                            $('#emailSubject', $pdfExportDialog).val()
                        );
                        url = tlxUrl.addUrlParameter(
                            url,
                            'pdfEmailBody',
                            $('#emailBody', $pdfExportDialog).val()
                        );
                        url = addContextId(url);
                        $.ajax({
                            url: url,
                            context: $pdfExportDialog.get(0),
                            headers: { [CSRF_HEADER_NAME]: getCSRFToken() },
                            success: function (data) {
                                $pdfExportDialog.dialog('close');
                                if (data == 'true') {
                                    tlxAlert(
                                        getMessage(
                                            'text_email_sent_successfully'
                                        )
                                    );
                                } else {
                                    tlxAlert(
                                        getMessage(
                                            'text_unexpected_error_has_occured',
                                            window.contextId
                                        )
                                    );
                                }
                            },
                            error: function (jqXHR, textStatus, errorThrown) {
                                dev.debugLine('ERROR: jqXHR: ' + jqXHR);
                                dev.debugLine(
                                    'ERROR: textStatus: ' + textStatus
                                );
                                dev.debugLine(
                                    'ERROR: errorThrown: ' + errorThrown
                                );
                            },
                        });
                    } else {
                        nav.download(url);
                    }

                    $(this).dialog('close');
                },
            },
            {
                text: getMessage('button_cancel'),
                click: function () {
                    $(this).dialog('close');
                },
            },
        ];

        const dialogContent =
            '<fieldset>' +
            '<legend>' +
            getMessage('text_pdf_settings') +
            '</legend>' +
            '<label for="pageFormat">' +
            getMessage('text_paper_size') +
            '</label>' +
            '<select id="pageFormat" class="defaultSize">' +
            '<option value="A5">A5</option>' +
            '<option value="A4" selected="selected">A4</option>' +
            '<option value="A3">A3</option>' +
            '<option value="B5">B5</option>' +
            '<option value="B4">B4</option>' +
            '<option value="letter">Letter</option>' +
            '<option value="legal">Legal</option>' +
            '<option value="ledger">Ledger</option>' +
            '</select>' +
            '<label for="pageOrientation">' +
            getMessage('text_page_orientation') +
            '</label>' +
            '<select id="pageOrientation" class="defaultSize"><option value="portrait">' +
            getMessage('text_page_orientation_portrait') +
            '</option><option value="landscape">' +
            getMessage('text_page_orientation_landscape') +
            '</option></select>' +
            '<label>' +
            getMessage('text_select_action') +
            '</label>' +
            '<label for="pdfOpen"><input type="radio" name="pdf-action" value="pdfOpen" id="pdfOpen" checked="checked" /> ' +
            getMessage('text_pdf_get') +
            '</label>' +
            '<label> </label>' +
            '<label><input type="radio" name="pdf-action" value="pdfEmail" id="pdfEmail" /> ' +
            getMessage('text_send_as_attachment') +
            '</label>' +
            '</fieldset>' +
            '<br/>' +
            '<fieldset id="emailFields" class="ui-helper-hidden">' +
            '<legend>' +
            getMessage('text_email_settings') +
            '</legend>' +
            '<label for="emailAddress">' +
            getMessage('text_receiver') +
            '</label>' +
            '<input id="emailAddress" type="email" required="required" multiple="multiple" placeholder="' +
            getMessage('text_email') +
            '" />' +
            '<label for="emailSubject">' +
            getMessage('text_subject') +
            '</label>' +
            '<input id="emailSubject" type="text" />' +
            '<label for="emailBody">' +
            getMessage('text_message') +
            '</label>' +
            '<textarea id="emailBody" rows="6"></textarea>' +
            '</fieldset>';

        $pdfExportDialog = $(
            "<div id='pdfExportDialog' class='shameDialog'></div>"
        )
            .append(dialogContent)
            .dialog({
                autoOpen: false,
                position: { my: 'top', at: 'center top+200' },
                minHeight: 40,
                width: 450,
                modal: true,
                hide: 'fade',
                title: getMessage('text_pdf_export'),
                buttons: buttons,
            });

        $('#pdfEmail', $pdfExportDialog).bindToggleHideShow(
            $('#emailFields', $pdfExportDialog)
        );

        $(':input', $pdfExportDialog).bind('keyup', function (e) {
            if (e.which == 13) {
                /**
                 * FORV-490: Ignore enter if ctrl or meta is pressed.
                 * We want ctrl+enter/meta+enter to have a specific meaning.
                 */
                if (e.ctrlKey || e.metaKey) {
                    e.preventDefault();
                    return false;
                }

                $pdfExportDialog
                    .parents('.ui-dialog-buttonpane button:eq(0)')
                    .focus();
            }
        });
    }

    const orientation = $('#bodyContent, #ajaxContent')
        .get(0)
        .tlxGetOrientation();
    $(
        "#pageOrientation option[value='" + orientation + "']",
        $pdfExportDialog
    ).prop('selected', true);

    const emailSubject = $('#bodyContent, #ajaxContent').get(0).dataset[
        'tlxTitle'
    ];
    $('#emailSubject', $pdfExportDialog).val(emailSubject);
    $('#emailAddress', $pdfExportDialog).val('');
    $('#emailBody', $pdfExportDialog).val('');
    $('#pdfOpen', $pdfExportDialog).prop('checked', true);
    $('#emailFields', $pdfExportDialog).hide();

    $pdfExportDialog.dialog('open');
};

window.tlxCsvExportDialog = function tlxCsvExportDialog(url) {
    let csvPage = false;
    const $scope = app.outermostScope();
    csvPage = $scope.get(0).csvPage;

    if (!csvPage && !url) {
        tlxAlert(getMessage('validation_no_csv_export'));
        return;
    }

    if (isChanged()) {
        tlxAlert(getMessage('text_unsaved_error'));
        return;
    }

    let $csvExportDialog = $('#csvExportDialog');
    if (!$csvExportDialog || $csvExportDialog.length == 0) {
        $csvExportDialog = createCsvExportDialog();
    }

    $csvExportDialog.data('url', url || getRelevantUrl());
    $csvExportDialog.dialog('open');
};

function createCsvExportDialog() {
    const buttons = [
        {
            text: getMessage('button_ok'),
            class: 'tlx-green',
            click: function (ev) {
                if (
                    !$(':input', $csvExportDialog)
                        .filter(':visible')
                        .validate($csvExportDialog.validationPopup())
                ) {
                    ev.stopPropagation();
                    return false;
                }

                let url = $(this).data('url');
                url = tlxUrl.addUrlParameter(url, 'csv', 'true');
                url = tlxUrl.addUrlParameter(
                    url,
                    'csvHeader',
                    $('#csvHeader', $csvExportDialog).is(':checked')
                );

                function getHtmlVal(name) {
                    const value = encodeURIComponent(
                        $('#' + name, $csvExportDialog).val()
                    );
                    return '&' + name + '=' + value;
                }

                function getUriEncodedVal(name) {
                    const value = $('#' + name, $csvExportDialog).val();
                    return '&' + name + '=' + value;
                }

                if ($('#csvDefault', $csvExportDialog).is(':checked')) {
                    // default format, defined by language settings
                    // nb. not using addUrlParameter function to avoid escaping
                    url += '&csvEncoding=ISO-8859-1';
                    url += '&csvQualifier=%22';
                    url += '&csvLineBreak=%0D%0A';
                    if (window.locale == 'en') {
                        url += '&csvSeparator=%2C';
                        url += '&csvDecimal=.';
                    } else {
                        url += '&csvSeparator=%3B';
                        url += '&csvDecimal=%2C';
                    }
                } else {
                    // advanced format, user specified
                    url += getHtmlVal('csvEncoding');
                    url += getHtmlVal('csvQualifier');
                    url += getHtmlVal('csvDecimal');

                    // tab and newlines are ignored in html attributes, so we have to store them as encoded uri components
                    url += getUriEncodedVal('csvSeparator');
                    url += getUriEncodedVal('csvLineBreak');
                }

                nav.download(url);
                $(this).dialog('close');
            },
        },
        {
            text: getMessage('button_cancel'),
            click: function () {
                $(this).dialog('close');
            },
        },
    ];

    const dialogContent =
        '<fieldset>' +
        tlxForms.createCheckboxTxt({
            id: 'csvHeader',
            label: getMessage('text_csv_header'),
            checked: true,
        }) +
        '<br/><br/>' +
        '<h3 >' +
        getMessage('text_csv_export_format') +
        '</h3>' +
        '<div class="inputItem">' +
        tlxForms.createRadioTxt({
            id: 'csvDefault',
            name: 'csvExportFormat',
            label: getMessage('text_csv_export_format_default'),
            value: 'csvDefault',
            checked: true,
        }) +
        '</div><div class="inputItem">' +
        tlxForms.createRadioTxt({
            id: 'csvAdvanced',
            name: 'csvExportFormat',
            label: getMessage('text_csv_export_format_advanced'),
            value: 'csvAdvanced',
        }) +
        '</div>' +
        '<fieldset id="csvAdvancedFields" class="ui-helper-hidden shameDialog">' +
        '<label for="csvEncoding">' +
        getMessage('text_csv_encoding') +
        '</label>' +
        '<select id="csvEncoding" class="defaultSize">' +
        '<option value="ISO-8859-1" selected="selected">ISO-8859-1</option>' +
        '<option value="UTF-8" >UTF-8</option>' +
        '<option value="MacRoman" >Mac Roman</option>' +
        '</select>' +
        '<br/>' +
        '<br/>' +
        '<label for="csvSeparator">' +
        getMessage('text_csv_separator') +
        '</label>' +
        '<select id="csvSeparator" class="defaultSize">' +
        '<option value="%3B" >' +
        getMessage('text_csv_separator_semicolon') +
        '</option>' +
        '<option value="%2C" >' +
        getMessage('text_csv_separator_comma') +
        '</option>' +
        '<option value="%09" >' +
        getMessage('text_csv_separator_tab') +
        '</option>' +
        '</select>' +
        '<br/>' +
        '<br/>' +
        '<label for="csvDecimal">' +
        getMessage('text_csv_decimal') +
        '</label>' +
        '<select id="csvDecimal" class="defaultSize">' +
        '<option value="." >' +
        getMessage('text_csv_decimal_dot') +
        '</option>' +
        '<option value="," >' +
        getMessage('text_csv_decimal_comma') +
        '</option>' +
        '</select>' +
        '<br/>' +
        '<br/>' +
        '<label for="csvQualifier">' +
        getMessage('text_csv_qualifier') +
        '</label>' +
        '<select id="csvQualifier" class="defaultSize">' +
        '<option value="&quot;" selected="selected">' +
        getMessage('text_csv_qualifier_double') +
        '</option>' +
        '<option value="&#39;" >' +
        getMessage('text_csv_qualifier_single') +
        '</option>' +
        '<option value="" >' +
        getMessage('text_csv_qualifier_none') +
        '</option>' +
        '</select>' +
        '<br/>' +
        '<br/>' +
        '<label for="csvLineBreak">' +
        getMessage('text_csv_line_break') +
        '</label>' +
        '<select id="csvLineBreak" class="defaultSize">' +
        '<option value="%0D%0A" selected="selected">Windows</option>' +
        '<option value="%0A" >Mac/Linux</option>' +
        '</select>' +
        '<br/>' +
        '</fieldset>' +
        '</fieldset>';

    const $csvExportDialog = $("<div id='csvExportDialog'></div>")
        .append(dialogContent)
        .dialog({
            autoOpen: false,
            position: { my: 'top', at: 'center top+200' },
            minHeight: 40,
            width: 450,
            modal: true,
            hide: 'fade',
            title: getMessage('text_csv_export'),
            buttons: buttons,
        });

    $('#csvAdvanced').bindToggleHideShow($('#csvAdvancedFields'));

    const uriSemicolon = encodeURIComponent(';');
    const uriComma = encodeURIComponent(',');

    // if separator and decimal are the same, flip the separator
    $('#csvSeparator').change(function (e) {
        const $decimalInput = $('#csvDecimal');
        if ($(e.target).val() == uriComma && $decimalInput.val() == ',') {
            $decimalInput.val('.');
        }
    });

    // if separator and decimal are the same, flip the decimal
    $('#csvDecimal').change(function (e) {
        const $csvSeparator = $('#csvSeparator');
        if ($(e.target).val() == ',' && $csvSeparator.val() == uriComma) {
            $csvSeparator.val(uriSemicolon);
        }
    });

    if (window.locale == 'en') {
        $('#csvSeparator').val(uriComma);
        $('#csvDecimal').val('.');
    } else {
        $('#csvSeparator').val(uriSemicolon);
        $('#csvDecimal').val(',');
    }

    return $csvExportDialog;
}

/**
 *
 * @param licenseUrl
 * @param onAgreeCallBackFunction
 * @param onCancelCallBackFunction
 */
window.tlxLicense = function tlxLicense(
    licenseUrl,
    licenseQuestionText,
    onAgreeCallBackFunction,
    onCancelCallBackFunction,
    title,
    data
) {
    onCancelCallBackFunction = onCancelCallBackFunction || $.noop;
    let height = 600;
    let width = 800;

    /* For the love of god, please don't do these height/width stunts with javascript in the future.
     * We do not know what screen size our clients use. */
    if (browserInfo.isNarrowScreen()) {
        height = undefined;
        width = undefined;
    }
    $.get(licenseUrl, data, function (license) {
        let $licenseDialog = $('#licenseDialog').data('ok', false);
        //If not found, generate a new one. This will only be done once (until new pageload).
        if (!$licenseDialog || $licenseDialog.length == 0) {
            $licenseDialog = $(
                "<div id='licenseDialog'><div id='licenseDialogMsg' class='tlxDialogContent'></div></div>"
            ).dialog({
                autoOpen: false,
                height: height,
                width: width,
                modal: true,
                hide: 'fade',
                title: title || getMessage('text_license'),
            });

            // Needed in order to have this dialog on top when opened from another, earlier popup.
            $licenseDialog.closest('.ui-dialog').css('z-index', 150);

            const buttons = [
                {
                    id: 'licenseAgree',
                    text: getMessage('button_ok'),
                    disabled: true,
                    click: function () {
                        $(this).dialog().data('ok', true);
                        $(this).dialog('close');
                    },
                },
                {
                    text: getMessage('button_cancel'),
                    click: function () {
                        $(this).dialog('close');
                    },
                },
                {
                    text: getMessage('text_print'),
                    click: function () {
                        let url = $(this).dialog().data('url');
                        url +=
                            (url.indexOf('?') >= 0 ? '&' : '?') +
                            (url.indexOf('resource') >= 0
                                ? 'viewAsPdf=true'
                                : 'pdf=true');
                        nav.download(url);
                    },
                },
            ];

            $licenseDialog.dialog('option', 'buttons', buttons);
            const $container = $(
                "<div style='float: left; margin: .5em 2em .5em .6em; cursor: pointer;display:inline-block'></div>"
            );
            $licenseDialog
                .parent()
                .find('.ui-dialog-buttonpane')
                .prepend($container);

            tlxForms.createCheckbox({
                id: 'licenseDialogCheck',
                where: $container,
                label: licenseQuestionText,
                labelStyle:
                    'width:auto;padding-left:0.5em;vertical-align: middle',
            });

            $('#licenseDialogCheck').change(function () {
                if (this.checked) {
                    $('#licenseAgree').button('enable');
                } else {
                    $('#licenseAgree').button('disable');
                }
            });
        }
        //callbacks must be redefined each time, as callback function may have changed
        //message may also have changed
        $licenseDialog.dialog({
            close: function () {
                if ($(this).data('ok')) {
                    onAgreeCallBackFunction();
                } else {
                    onCancelCallBackFunction();
                }
            },
        });
        $licenseDialog.dialog().data('url', licenseUrl);
        $('#licenseDialogMsg').html(license);
        tlxForms.check($('#licenseDialogCheck'), false);
        $('#licenseAgree').button('disable').addClass('tlx-green'); // green ok button. hackish

        $licenseDialog.dialog('open');
    });
};

/**
 * Hide overlays for top and bottom frames. Arguments are passed on to $.hide. (e.g. hide("fade"))
 */
export function hideOverlays(animation) {
    $('.tlx-overlay').hide(animation, () =>
        $(window).trigger('tlxOverlayHidden')
    );
}

export function getContentOverlay() {
    let $overlay = $('.tlx-overlay');
    if ($overlay.length == 0) {
        $overlay = addOverlay(document.body, $(document).height());
    } else {
        $overlay.height($(document).height());
    }

    return $overlay;
}

/**
 * Adds overlay to a frame (or other destination). Used as a workaround for modal dialogs in frames.
 * @param destination body of frame or similar
 * @param height height of overlay, optional. Default destination.height.
 * @returns the overlay
 */
function addOverlay(destination, height) {
    return $('<div></div>')
        .addClass('ui-widget-overlay tlx-overlay')
        .appendTo(destination)
        .css({
            width: '100%', //$(destination).outerWidth(),
            height: height ? height : $(destination).outerHeight(),
            zIndex: 2001,
        });
}

window.addOverlay = addOverlay;

export function getTooltip(clazz, id) {
    const key = clazz + '.' + id;
    let value = getTooltip.cache[key];
    if (value === undefined) {
        value = window.jsonrpc[clazz].getTooltip(id);
        getTooltip.cache[key] = value;
    }
    return value;
}

getTooltip.cache = {};

function defaultPeriodInit(
    $periodField,
    prevPeriodButtonTrackingId = '',
    nextPeriodButtonTrackingId = '',
    allPeriodsButtonTrackingId = '',
    soFarThisYearButtonTrackingId = '',
    todayButtonTrackingId = '',
    thisWeekButtonTrackingId = '',
    thisMonthButtonTrackingId = '',
    theWholeCurrentMonthButtonTrackingId = '',
    okButtonTrackingId = '',
    narrowScreenPeriodTabTrackingId = '',
    narrowScreenWeekTabTrackingId = '',
    narrowScreenMonthTabTrackingId = '',
    narrowScreenYearTabTrackingId = '',
    narrowScreenWageTermTabTrackingId = '',
    narrowScreenVatTermTabTrackingId = ''
) {
    if (!$periodField || $periodField.length === 0) {
        return false;
    }
    $periodField.periodselecter({
        buttonTrackingIdAll: allPeriodsButtonTrackingId,
        buttonTrackingIdSoFarThisYear: soFarThisYearButtonTrackingId,
        buttonTrackingIdSoFarThisMonth: thisMonthButtonTrackingId,
        buttonTrackingIdWholeMonth: theWholeCurrentMonthButtonTrackingId,
        buttonTrackingIdThisDay: todayButtonTrackingId,
        buttonTrackingIdThisWeek: thisWeekButtonTrackingId,
        buttonTrackingIdOk: okButtonTrackingId,
        narrowScreenPeriodTabTrackingId,
        narrowScreenWeekTabTrackingId,
        narrowScreenMonthTabTrackingId,
        narrowScreenYearTabTrackingId,
        narrowScreenWageTermTabTrackingId,
        narrowScreenVatTermTabTrackingId,
    });

    const $prevArrow = $(
        '<a class="mdl-button mdl-js-button mdl-button--icon previousPeriod" role="button" data-trackingid="' +
            prevPeriodButtonTrackingId +
            '"/>'
    ).append('<i class="material-icons">&#xE314;</i>');
    const $nextArrow = $(
        '<a class="mdl-button mdl-js-button mdl-button--icon nextPeriod" role="button" data-trackingid="' +
            nextPeriodButtonTrackingId +
            '"/>'
    ).append('<i class="material-icons">&#xE409;</i>');
    componentHandler.upgradeElement($prevArrow[0]);
    componentHandler.upgradeElement($nextArrow[0]);

    $prevArrow.add($nextArrow).find('.ui-icon').removeClass('ui-icon');

    $prevArrow.toggleClass(
        'ui-state-disabled',
        $periodField.val() === getMessage('text_all_periods')
    );
    $nextArrow.toggleClass(
        'ui-state-disabled',
        $periodField.val() === getMessage('text_all_periods')
    );

    $prevArrow.on('click', function () {
        if ($(this).hasClass('ui-state-disabled')) {
            return;
        }
        if ($periodField.periodselecter('previous')) {
            // Click refresh button manually (when it exists) when changing period with next/prev arrows.
            $('.refreshButton:visible', $(this).closest('form')).trigger(
                'click'
            );
        }
    });
    $nextArrow.on('click', function () {
        if ($(this).hasClass('ui-state-disabled')) {
            return;
        }
        if ($periodField.periodselecter('next')) {
            // Click refresh button manually (when it exists) when changing period with next/prev arrows.
            $('.refreshButton:visible', $(this).closest('form')).trigger(
                'click'
            );
        }
    });
    const $arrows = $nextArrow.add($prevArrow);
    $arrows.find('.ui-button-text').css('display', 'none');

    if ($periodField.closest('.tmdl-period-selecter').length > 0) {
        $periodField.closest('.tmdl-period-selecter').prepend($arrows);
    } else {
        $periodField.closest('.tlx-textfield').prepend($arrows);
    }

    $periodField.bind('disableEvent', function (ev, disabled) {
        $prevArrow.toggleClass(
            'ui-state-disabled',
            disabled || $periodField.val() === getMessage('text_all_periods')
        );
        $nextArrow.toggleClass(
            'ui-state-disabled',
            disabled || $periodField.val() === getMessage('text_all_periods')
        );
        if (disabled) {
            $periodField.periodselecter('close');
        }
    });
}

window.defaultPeriodInit = defaultPeriodInit;

/**
 * @returns the return object of the ajax functions OR the Result object of the call if the ajax calls return is void
 *  if options have a callback, instead of returning, it calls the callback with the above values
 *  options:
 *      exclude           - jquery filter which elements to exclude from the object that is sent to the server
 *      flatten           - if the json object should be flattened
 *          prefix        -
 *          property      -
 *      javaClass         - which form on the serverside to call, if not provided by a hidden input
 *      removeNonSelected - which objectarrays should we look for the selected property and remove those objects where selected==false
 *      callback          - callback function to call after call has been made
 *      afterRetrieve     - callback function to call after retrieve. The retrieved form is sent into the function as argument.
 *      infoMessage       - info message displayed when completed successfully
 *      disableForward    - do not forward to the incoming url in "forward" property
 *      form              - extra parameters to submit. (js object with param:value - i.e. confirming to $.retrieve structure)
 * NB: If the response specifies a forward this function might not return.
 */
export async function defaultAjaxAction($form, methodName, $dialog, options) {
    await window.ensureESMModulesAreLoaded();

    if (!options) {
        options = {};
    }
    if (options.contentLayerHandler) {
        options.contentLayerHandler(true);
    } else {
        getContentOverlay().show();
    }

    const postMethod = options.method != 'get';

    function hideOverlays() {
        // Can't hide frame overlays since we might have nested dialogs. The dialog will handle the hiding, but not fade in sync with content overlay :(
        if (options.contentLayerHandler) {
            options.contentLayerHandler(false);
        } else {
            getContentOverlay().hide('fade');
        }
    }

    let $fields = $form.findFormInput();
    if (options.exclude) {
        $fields = $fields.not(options.exclude);
    }

    let jsonForm;
    if (options.form) {
        jsonForm = $fields.retrieve(options.form);
    } else {
        jsonForm = $fields.retrieve();
    }

    const reduxStoreState = store.getState();

    $('.redux-jsp-form-hybrid', $form).each(function () {
        const $this = $(this);
        const reduxStoreSource = $this.attr('data-redux-jsp-form-source');
        const reduxStoreTarget = $this.attr('data-redux-jsp-form-target');
        const reduxDataMarshall = JSON.parse(
            $this.attr('data-redux-jsp-form-marshall')
        );
        const reduxData = reduxStoreSource
            .split('.')
            .reduce(function (state, part) {
                return state[part];
            }, reduxStoreState);

        if (reduxDataMarshall && reduxData) {
            const reduxDataFiltered = legacyAdapter.marshall(
                reduxData,
                reduxDataMarshall
            );

            if (!reduxDataFiltered) {
                return;
            }

            if (reduxStoreTarget) {
                jsonForm = utils.setValueAtPath(
                    jsonForm,
                    reduxDataFiltered,
                    reduxStoreTarget
                );
            } else {
                Object.assign(jsonForm, reduxDataFiltered);
            }
        }
    });

    $.setClientIds(jsonForm);

    if (options.afterRetrieve) {
        options.afterRetrieve(jsonForm);
    }
    if (options.flatten) {
        flattenJson(
            jsonForm,
            options.flatten.prefix,
            options.flatten.property,
            options.flatten.targetProperty
        );
    }
    if (options.javaClass) {
        jsonForm.javaClass = options.javaClass;
    }
    if (options.removeNonSelected) {
        removeNonSelected(jsonForm, options.removeNonSelected);
    }
    if (options.removeEmpty) {
        utils.removeEmpty(jsonForm, options.removeEmpty);
    }

    const pageTitle = $('#menuHeader h1').text()?.trim();
    const $tabs = $('.ui-tabs');
    let selectedTab;
    if ($tabs.length == 1) {
        selectedTab = $tabs.tlxTabs('option', 'active');
        if (postMethod) {
            const changed = isOtherTabChanged(selectedTab);
            if (changed && !confirm(getMessage('validation_tab_changed'))) {
                hideOverlays();
                return;
            } else {
                clearOtherTabsChanged(selectedTab);
            }
        }
        // should create a tabTitle method instead of relying on internal structure..
        // pageTitle += encodeHTML(" ("+$(".ui-tabs .ui-tabs-active a").text()+")");
        // ----------
        // Removing this for now. When creating something from a page without tabs this line
        // will always add "(Details)" behind the page title. This is because the default "tab"
        // is called details even though no actual is selected in gui. Some users saw this as a defect/bugged link.
        // So instead of using the format "PageTitle (tabName)" - we now use "PageTitle" only.
    }
    dev.debugLine('to Send: ' + toJSON(jsonForm));
    clearGeneralValidation();
    try {
        $form.validationPopup('clear');
    } catch (_) {
        // Do nothing
    }

    window.jsonrpc.BaseForm.invoke(
        function invokeFunction(res, e) {
            try {
                // Must happen on validation error too. Otherwise, we might get strange errors related to the server cache. (The current tab might share objects with the other tabs)
                // The above shouldn't be an issue any more.. [ojb - 26. nov. 2014]
                if (postMethod && $tabs.length == 1) {
                    if (
                        $tabs.length > 0 &&
                        $tabs.tlxTabs('instance') !== undefined
                    ) {
                        $tabs.tlxTabs('clearCachedTabs');
                    }
                }

                if (e) {
                    handleError(e, methodName);
                    return;
                }

                legacyAdapter.dispatch({
                    type: 'JSONRPC_FORM_POST_SUCCSESS',
                });

                $form.first().trigger({
                    type: 'defaultAjaxAction',
                    method: methodName,
                    returnValue: res.returnValue,
                });

                if (postMethod) {
                    if ($tabs.length == 1) {
                        clearTabChanged(selectedTab);
                    } else {
                        //$(":tlx-tableToolbar").tableToolbar("option", "lockButtons", false); //Not currently in use.
                        $(':tlx-mainToolbar').mainToolbar(
                            'option',
                            'lockButtons',
                            false
                        );
                    }
                }

                if (res.popups && res.popups.length) {
                    try {
                        for (let i = 0; i < res.popups.length; i++) {
                            nav.popup(res.popups[i]);
                        }
                    } catch (strangePopupError) {
                        // This error happens sometimes but is (in large) benign. Silent it (and log) until we can figure it out
                        logException('Strange popup error', strangePopupError, {
                            actionName: methodName,
                            popup0: res.popups[0],
                        });
                    }
                }
                const forwardUrl =
                    res.forward && res.forward.indexOf('navigateDirect') > 0;
                if (!forwardUrl) {
                    populateIdAndRevision(res.changes, $form);
                }
                if ($dialog) {
                    $dialog.dialog('close');
                }
                if (postMethod) {
                    clearChanged();
                }

                let infoMessages = [];
                if (res.messages && res.messages.length > 0) {
                    infoMessages = res.messages;
                } else if (postMethod) {
                    if (options.infoMessage) {
                        infoMessages = [
                            getMessage('text_action_completed') +
                                ' ' +
                                options.infoMessage,
                        ]; // not ideal to add text_action_completed here.. (non-toolbarAction tag actions)
                    } else {
                        infoMessages = [
                            getMessage('text_action_was_completed'),
                        ];
                    }
                }

                if (infoMessages.length > 0) {
                    for (const message of infoMessages) {
                        const date = new Date();
                        showActionLogMessage({
                            pageName: pageTitle,
                            date,
                            message,
                        });
                    }
                }

                if (res.forward && !options.disableForward) {
                    nav.handleForward(res.forward);
                } else {
                    if (options.callback) {
                        if (res.returnValue !== undefined) {
                            options.callback(res.returnValue);
                        } else {
                            options.callback(res);
                        }
                    } else {
                        if (res.returnValue !== undefined) {
                            return res.returnValue;
                        } else {
                            return res;
                        }
                    }
                }
            } finally {
                if (
                    (res &&
                        (!res.forward || res.forward.indexOf('refresh') < 0)) ||
                    e ||
                    options.disableForward
                ) {
                    hideOverlays();
                }
                // if we refreshUrl we'll wait until the new content is loaded. (all overlays are hidden(v) when that happens)
            }

            function handleError(e, methodName) {
                dev.debugLine('result: ' + toJSON(e));
                const type = e.javaClass;

                if (type === 'no.tripletex.common.exception.AdviceException') {
                    if (!$.isEmptyObject(e.adviceMessages)) {
                        $form.first().trigger({
                            type: 'defaultAjaxActionAdvice',
                            method: methodName,
                        });
                        jsonrpcUtil.handleJsonAdvice(
                            e,
                            $form,
                            methodName,
                            $dialog,
                            options
                        );
                    }
                } else if (
                    e.code === JSONRpcClient.Exception.CODE_SERVICE_UNAVAILABLE
                ) {
                    /**
                     * When an JSONRpcClient.Exception.CODE_SERVICE_UNAVAILABLE is thrown, jsonrpc should also trigger
                     * a tlxCommunicationError.
                     */
                    return;
                } else {
                    let dialogWithErrors = false;
                    $form.first().trigger({
                        type: 'defaultAjaxActionError',
                        method: methodName,
                    });
                    if ($dialog) {
                        jsonrpcUtil.handleJsonError(
                            e,
                            $dialog,
                            $dialog.validationPopup()
                        );
                        /**
                         * #alertDialog is still in DOM when it is closed. So dialog with error isn't closed
                         * if end user has seen an alert. We changed to see if dialog is :visible instead.
                         * 2019-05-22 KRR, LEB.
                         */
                        if (
                            $dialog.find('.ui-state-error').length == 0 &&
                            !$('#alertDialog').is(':visible')
                        ) {
                            $dialog.dialog('close');
                            dialogWithErrors = false;
                        } else {
                            dialogWithErrors = true;
                        }
                    }
                    if (!dialogWithErrors) {
                        jsonrpcUtil.handleJsonError(e, $form.filter('form'));

                        legacyAdapter.dispatch({
                            type: 'JSONRPC_VALIDATION_MESSAGES',
                            propertyMessages: e.propertyMessages,
                            generalMessages: e.generalMessages,
                        });

                        clearGeneralValidation(); // Shouldn't this already be
                        // cleared? [ojb - 3. sep. 2014]
                        let showErrorPopup = false;
                        if (!$.isEmptyObject(e.propertyMessages)) {
                            showErrorPopup = true;
                        }
                        if (e.generalMessages && e.generalMessages.length > 0) {
                            showErrorPopup = true;
                            for (const message of e.generalMessages) {
                                showValidationMessage({
                                    message,
                                });
                            }
                        }
                        if (showErrorPopup) {
                            if ($tabs.length == 1) {
                                // TODO ojb, design: better handled by triggering an event on the validation controller?
                                $('.ui-tabs-active a').addClass('error');
                            }

                            if (
                                e.propertyMessages &&
                                Object.keys(e.propertyMessages).length > 0
                            ) {
                                Object.keys(e.propertyMessages).forEach(
                                    function (clientId) {
                                        const messages =
                                            e.propertyMessages[clientId];
                                        for (
                                            let i = 0;
                                            i < messages.length;
                                            i++
                                        ) {
                                            if (
                                                !messages.errorElementHaveBeenFound
                                            ) {
                                                const message =
                                                    getMessage(
                                                        'text_error_single'
                                                    ) +
                                                    ': "' +
                                                    messages[i] +
                                                    '"';
                                                showValidationMessage({
                                                    message,
                                                });
                                            }
                                        }
                                    }
                                );
                                showValidationMessage({
                                    message: getMessage(
                                        'validation_popup_number_of_errors',
                                        Object.keys(e.propertyMessages).length
                                    ),
                                });
                            } else {
                                showValidationMessage({
                                    message: getMessage(
                                        'validation_general_error_message'
                                    ),
                                });
                            }
                        }
                    }
                }
            }
        },
        jsonForm,
        methodName
    );
}

/**
 * Refresh active tab or page.
 */
export function refreshUrl() {
    if ($('.ui-tabs').length == 1) {
        const $tabs = $('.ui-tabs');
        const selectedTabIndex = $tabs.tlxTabs('option', 'active');
        const $tab = $tabs
            .children('.ui-tabs-nav')
            .find('li')
            .eq(selectedTabIndex);

        clearTabChanged(selectedTabIndex);

        $tab.removeData('cached');
        $('.ui-tabs').tlxTabs('load', selectedTabIndex);
    } else {
        const url = getRelevantUrl();
        nav.nav(url, { replaceState: true });
    }
    extraFrame.reload();
}

function removeNonSelected(obj, property) {
    if ($.isArray(property)) {
        for (let i = 0; i < property.length; i++) {
            removeNonSelected(obj, property[i]);
        }
    } else {
        if ($.isArray(obj[property])) {
            const oldArray = obj[property];
            const newArray = [];
            for (let i = 0; i < oldArray.length; i++) {
                if (oldArray[i] && oldArray[i].selected) {
                    newArray.push(oldArray[i]);
                }
            }
            obj[property] = newArray;
        }
    }
}

export function populateIdAndRevision(changes, $scope) {
    if (!changes) {
        return;
    }
    $scope = $scope || $('body');
    for (let i = 0; i < changes.length; i++) {
        const change = changes[i];
        let clientId = change[0];
        clientId = clientId.replace(/\./g, '\\.');
        const $changedElementId = $scope.find(
            'input[name="' + clientId + '\\.id"]'
        );
        $changedElementId.val(change[1]);
        const $changedElementRevision = $scope.find(
            'input[name="' + clientId + '\\.revision"]'
        );
        $changedElementRevision.val(change[2]);
    }
}

/**
 * @param preferredNumberOfTabs Preferred number of tabs that are outside the "more tabs" dropdown
 * @param moreTabsButtonTrackingId The "more tabs" button's tracking ID
 * @param tabPeriodSelectorTrackingIdMap Map of period selector tracking IDs
 * @example
 * tabPeriodSelectorTrackingIdMap format:
 * {
 *     *scope ID*: {
 *         *period selector ID*: {
 *             prevPeriodButton: '*tracking ID*',
 *             nextPeriodButton: '*tracking ID*',
 *             allPeriodsButton: '*tracking ID*',
 *             soFarThisYearButton: '*tracking ID*',
 *             todayButton: '*tracking ID*',
 *             thisWeekButton: '*tracking ID*',
 *             thisMonthButton: '*tracking ID*',
 *             theWholeCurrentMonthButton: '*tracking ID*',
 *             okButton: '*tracking ID*',
 *             narrowScreenPeriodTab: '*tracking ID*',
 *             narrowScreenWeekTab: '*tracking ID*',
 *             narrowScreenMonthTab: '*tracking ID*',
 *             narrowScreenYearTab: '*tracking ID*',
 *             narrowScreenWageTermTab: '*tracking ID*',
 *             narrowScreenVatTermTab: '*tracking ID*'
 *         }
 *     }
 * }
 */
window.initTabs = function initTabs(
    preferredNumberOfTabs = 6,
    moreTabsButtonTrackingId = '',
    tabPeriodSelectorTrackingIdMap = {}
) {
    function activeTabStorageKey() {
        return (
            '_tlxSelectedTabFor-' +
            document.location.pathname +
            document.location.search
        );
    }

    const disabled = $('li', '#tabs')
        .map(function (i) {
            if ($(this).data('disabled') == true) {
                return i;
            }
        })
        .get();

    // Note: at this point only the fragments (i.e. the divs that will contain the tab content) created on the server is available
    let fragmentId =
        $.sessionStorage.getItem('fragmentId') ||
        document.location.hash.substring(1); // .hash = #thehash

    if (fragmentId.length > 0) {
        $.sessionStorage.removeItem('fragmentId');
        const liElement = $('#tabs')
            .children('ul')
            .find("[aria-controls='" + fragmentId + "']");
        if (fragmentId.startsWith('ui-tabs')) {
            dev.debugLine('Info: fragment with ui-tabs');
        } else if (liElement.length == 1) {
            dev.debugLine('Info: fragment with custom aria-controls');
            $.sessionStorage.setItem(
                activeTabStorageKey(),
                $('#tabs li').index(liElement)
            );
        } else {
            dev.debugLine('Error: Not a valid fragment ID: ' + fragmentId);
            fragmentId = '';
        }
    }
    const activeTab = $.sessionStorage.getItem(activeTabStorageKey());

    // 'activate' is triggered before 'load' (so on first load the panel content is empty on 'activate') This method is called after the tab is shown. period.
    function afterTabShow(scope) {
        $(scope).trigger('tlxTabsActivate');
        // This is necessary because the toolbar(s) is initialized inside tlxInitializeState which has already run.
        // When the current tab is refreshed, the toolbars are initialized to the old tabWidth (not updated until here), possible making the floating toolbars too wide.
        // This condition is best seen on wageCodes.jsp (part og "Lønnsinnstillinger")
        // tlxInitializeState could affect the width, so it has to run before setTabWidth
        // Another possible solution might be to remove the tab width before refresh. I suspect this could have undesirable visual glitches though.
        // Hopefully this (often redundant) call isn't too heavy. In theory it shouldn't :)
        $(scope).trigger('tlxPossibleWidthChange');

        // Turn off all autofocus features in New Design.
        // TODO: remove all references to autoFocus
        // throughout our codebase.
        // Only autofocus if user have not scrolled down and is not on mobile device.
        const $scrollContainer = $('body').frameless('getScrollContainer');
        if (
            scope.autoFocus !== false &&
            $scrollContainer.scrollTop() === 0 &&
            !browserInfo.isMobileReg()
        ) {
            // autoFocus is on by default, so undefined ==> true
            tlxForms.focusFirstText(scope);
        }
    }

    /**
     * We want to prevent context menu and middle-click on these links, because the links will not work. They
     * lack contextId (easy to fix) and they will not take you to the Menu page, but the specific tab (more
     * difficult to fix).
     */
    $('#tabs a[href^="/execute"]')
        .on('contextmenu', function (e) {
            e.preventDefault();
        })
        .on('auxclick', function (e) {
            e.preventDefault();
        })
        // Safari doesn't support the auxclick event
        .on('mousedown', function (e) {
            // e.which is deprecated, but also kinda not: https://github.com/jquery/jquery/issues/4755#issuecomment-664501730
            if (e.which === 2) {
                e.preventDefault();
            }
        });

    let currentTabUrl;

    $('#tabs').tlxTabs({
        disabled,
        active: activeTab,
        preferredNumberOfTabs,
        moreTabsButtonTrackingId,
        beforeLoad: function (event, ui) {
            // eslint-disable-next-line @typescript-eslint/no-this-alias
            const tabWidgetElement = this;
            const $tab = ui.tab; // this is the <li/> element

            if ($tab.data('cached')) {
                event.preventDefault();
                return;
            }
            const $p = ui.panel
                /**
                 * Remove all existing event handlers. Since a tab can be initialized several times,
                 * but loaded only once, events can have been placed on this DOM Node from before.
                 * Typically, when "Lagre" or "Opprett" has been used.
                 */
                .off();
            if ($p.children().length == 0) {
                ui.panel
                    .removeClass('lazyLoad--done')
                    .addClass('lazyLoad--inProgress');
                const $spinner = $(
                    '<div class="mdl-spinner mdl-js-spinner is-active tmdl-spinner"></div>'
                );
                $('#wrapperDiv').append($spinner);
                componentHandler.upgradeElement($spinner[0]);
            }

            if (!$tab.data('originalUrl')) {
                // First time this tab is loaded: Keep the original url (aka. pagekey) so the filter state is saved for the correct page later on.
                const originalUrl = $tab.find('a').attr('href');
                $tab.data('originalUrl', originalUrl);
                // Use the saved filter if any
                ui.ajaxSettings.url = $(tabWidgetElement).tlxTabs(
                    'getUpdatedTabUrl',
                    originalUrl
                );
                // tab-url should always be the same as content-url (could be different due to getUpdatedTabUrl above)
                $tab.find('a').attr('href', ui.ajaxSettings.url);

                // We need to know what we just loaded in the "load" callback
                currentTabUrl = ui.ajaxSettings.url;

                window.dispatchEvent(
                    new CustomEvent('pageLoad-loadContentLazily', {
                        detail: { url: currentTabUrl },
                    })
                );
            }

            ui.jqXHR.setRequestHeader(CSRF_HEADER_NAME, getCSRFToken());
            ui.ajaxSettings.url = tlxUrl.addUrlParameter(
                ui.ajaxSettings.url,
                'scope',
                $p.attr('id')
            );
            ui.ajaxSettings.url = addContextId(ui.ajaxSettings.url);
            ui.jqXHR.done(function () {
                $tab.data('cached', true);
            });
            ui.jqXHR.fail(function (xhr) {
                if (xhr.status == 401) {
                    // See global ajaxError handler too
                    return;
                }
                ui.panel.html(xhr.responseText);
                // set lazyLoad--done and remove spinner to avoid infinite spinner covering responsetext
                ui.panel
                    .removeClass('lazyLoad--inProgess')
                    .addClass('lazyLoad--done');
                $('#wrapperDiv .mdl-spinner').remove();
            });
        },
        load: function (event, ui) {
            const $scope = ui.panel.addClass('tlxScope').css('cursor', '');

            function doneLoadingScript() {
                try {
                    afterTabShow($scope.get(0));
                } catch (e) {
                    logException('Tab loaded', e, {
                        tabAttemptedToLoad: ui.tab.find('a').attr('href'),
                    });
                    tlxAlert(getMessage('validation_unexpected_error'));
                    throw e;
                } finally {
                    ui.panel
                        .removeClass('lazyLoad--inProgress ')
                        .addClass('lazyLoad--done');
                    $('#wrapperDiv').find('.tmdl-spinner').remove();
                }

                window.dispatchEvent(
                    new CustomEvent('pageLoad-lazyLoadDone', {
                        detail: { url: currentTabUrl },
                    })
                );
            }

            $scope
                .unbind('allValidationsCleared')
                .bind('allValidationsCleared', function () {
                    ui.tab.find('a').removeClass('error');
                });
            hideOverlays('fade');
            bindEventsInsideScope($scope, tabPeriodSelectorTrackingIdMap);

            app.loadPageScript($scope, doneLoadingScript);
        },
        beforeActivate: function (event, ui) {
            // Replace/remember scroll position of tab
            ui.oldPanel.data(
                'scrollTop-position',
                $('#scrollContainer').scrollTop()
            );
        },
        // called after load event (or after beforeLoad if the actual load was canceled)
        activate: function (event, ui) {
            $.sessionStorage.setItem(
                activeTabStorageKey(),
                $(this).find('.ui-tabs-nav > li').index(ui.newTab)
            );
            if (ui.newTab.data('cached')) {
                afterTabShow(ui.newPanel.get(0));
            }

            // Replace/remember scroll position of tab
            const scrollTop = ui.newPanel.data('scrollTop-position');

            if (scrollTop) {
                $('#scrollContainer').scrollTop(scrollTop);
            }
        },
    });

    $(window).unbind('.tlxTab');
};

// export function showTermsAndConditionsYearEndReport() {
//     const $div = $('<div>');
//     $div.load(
//         addContextId(
//             '/execute/termsAndConditionsLogistics?scope=TermsAndConditionsLogisticsForm'
//         ),
//         function (responseText, status, xhr) {
//             if (
//                 xhr.status === 401 ||
//                 xhr.status === 0 ||
//                 (xhr.status >= 500 && xhr.status < 600) ||
//                 status === 'error'
//             ) {
//                 return 'error';
//             } else {
//                 $div.dialog({
//                     dialogClass: 'termsAndConditionsLogisticsDialog',
//                     modal: true,
//                     width: 'auto',
//                     height: 'auto',
//                     position: {
//                         my: 'top',
//                         at: 'top',
//                         of: $('#framelessContent'),
//                     },
//                     title: '',
//                     buttons: [
//                         {
//                             id: 'logisticsAgreeOkButton',
//                             text: getMessage('text_ok'),
//                             click: function () {
//                                 window.jsonrpc.PurchaseLogisticsForm.storePurchaseLogisticsModule(
//                                     true
//                                 );
//                                 $(this).dialog('close');
//                             },
//                             disabled: true,
//                             class: 'tlx-green',
//                         },
//                         {
//                             text: getMessage('button_cancel'),
//                             click: function () {
//                                 showDeactivateLogistics();
//                                 const $this = $(this);
//                                 if ($this.dialog('instance') !== undefined) {
//                                     $this.dialog('close');
//                                     $this.dialog('destroy');
//                                 }
//                             },
//                             class: 'tlx-green',
//                         },
//                     ],
//                 });
//                 $('#doYouAgreeLogistics').change(function (e) {
//                     if (e.target.checked) {
//                         $('#logisticsAgreeOkButton').button('enable');
//                     } else {
//                         $('#logisticsAgreeOkButton').button('disable');
//                     }
//                 });
//             }
//         }
//     );
// }

export function showGetStarted() {
    if (window.loginCompanyModules && window.loginCompanyModules.agro) {
        // AM 2020-01-08
        // Get started dialog is not relevant for Agro. Reconsider when Agro has started using standard Tripletex GUI.
    } else {
        const $div = $('<div>');
        $div.load(
            addContextId('/execute/getStarted?scope=GetStartedForm'),
            function (responseText, status, xhr) {
                if (
                    xhr.status === 401 ||
                    xhr.status === 0 ||
                    (xhr.status >= 500 && xhr.status < 600) ||
                    status === 'error'
                ) {
                    return 'error';
                } else {
                    $div.dialog({
                        dialogClass: 'getStartedDialog',
                        modal: true,
                        width: 'auto',
                        height: 'auto',
                        position: {
                            my: 'top',
                            at: 'top',
                            of: $('#framelessContent'),
                        },
                        title: getMessage('text_welcome_to_tlx'),
                        buttons: [
                            {
                                text: getMessage('text_ok'),
                                click: function () {
                                    window.jsonrpc.GetStartedForm.setShowGetStarted(
                                        $('.js-setShowGetStarted')[0].checked
                                    );
                                    $(this).dialog('close');
                                },
                                class: 'tlx-green',
                            },
                        ],
                    });
                }
            }
        );
    }
}

/**
 * Note: In some browsers, xhr.responseText is "" and other it is undefined if there was no
 * response from server. Therefore, we use !xhr.responseText to check if there was no reply.
 */
$(window).on('tlxCommunicationError', function (e) {
    const xhr = e.xhr;

    console.log('We have a communication error.');

    /* Assumption: If status code is 0 and the responseText is undefined client or server is offline. */
    if (xhr.status === 0 && !xhr.responseText) {
        //		tlxAlert(getMessage('text_offline_body_client'));

        const head = '<h1>' + getMessage('text_offline_header') + '</h1>';
        const body = getMessage('text_offline_body_client');

        $(e.target)
            .html(head + body)
            .show();
        $('.bodyWait').hide();
        $('#ajaxContent').show();
        //*/
        return;
    }

    // SORRYSITE AJAX ERROR.
    if (xhr.status === 503 && xhr.responseText) {
        /* We are assuming that 503 is a response not from our app, but the layer before
         * and that the content in the responsetext is a sorry site. */
        $('html').html(xhr.responseText);
        return;
    }

    // Probably ajax and probably clients fault
    if (!xhr.responseText) {
        tlxAlert(getMessage('text_offline_body_client'));
        return;
    }

    // If we come as long as this, we don't actually know what went wrong. Show generic message.
    tlxAlert(getMessage('validation_unexpected_error'));
});

$(window)
    .on('offline', function () {
        const buttons = [
            {
                text: 'Ok',
                icons: {
                    primary: 'ui-icon-heart',
                },
                click: function () {
                    $(this).dialog('close');
                },
            },
        ];

        $(
            '<div id="offlineDialogBox" title="Tapt forbindelse oppdaget"><div class="messageContent">' +
                getMessage('text_offline_event') +
                '</div></div>'
        )
            .dialog({
                position: { at: 'top+200' },
                width: Math.min($(window).width(), 500),
                modal: true,
                hide: 'fade',
                closeOnEscape: false,
                title: getMessage('text_information'),
                closeText: '',
                buttons: buttons,
            })
            .find('.messageContent')
            .css('padding-left', '');
    })
    .on('online', function () {
        $('#offlineDialogBox').dialog('close').remove();
    });
