import { ModelAPIV1 } from '@General/types/Api';
import { Option } from '../../types';
import { JSPDropdownFetcher } from '../JSPDropdownFetcher';
import { ApiResultMapperNoUndefinedResult } from '@General/Provider';
import * as React from 'react';

export interface BusinessActivityTypeEntry extends ModelAPIV1 {
    readonly displayName: string;
}

const BusinessActivityTypeMapper: ApiResultMapperNoUndefinedResult<BusinessActivityTypeEntry> =
    (item) => ({
        value: item.id + '',
        displayName: item.displayName,
        item,
    });

export class BusinessActivityTypeFetcher extends JSPDropdownFetcher<BusinessActivityTypeEntry> {
    asyncJsonrpcGetter: Function;

    constructor(scope: HTMLDivElement, queryAttributes: Record<string, any>) {
        super(scope, queryAttributes, BusinessActivityTypeMapper);
        this.asyncJsonrpcGetter =
            asyncrpc.YearEndReportBasicData.getBusinessActivityType;
    }

    getParams(): ObjectIndex {
        return {};
    }

    async fetchList(
        params: ObjectIndex
    ): Promise<Option<BusinessActivityTypeEntry>[]> {
        return new Promise((resolve, reject) => {
            try {
                return jsonrpc.YearEndReportBasicData.searchForBusinessActivityType(
                    (result: BusinessActivityTypeEntry[] | null) => {
                        resolve((result ?? []).map(BusinessActivityTypeMapper));
                    },
                    this.getMarshallSpec(),
                    window.loginCompanyId,
                    params.name || ''
                );
            } catch (e) {
                defaultJsonRpcExceptionHandler(e);
                reject(e);
            }
        });
    }
}

export const exports = {
    fetcher: BusinessActivityTypeFetcher,
    type: 'BusinessActivityType',
};
