import React from 'react';

import { NewProductSection } from '@Page/LogisticsV2/components/NewSupplierProduct/NewProductSection';
import { SupplierConnectionForm } from '@Page/LogisticsV2/components/NewSupplierProduct/SupplierConnectionForm';
import { ProductAndSupplierConnectionForm } from '@Page/LogisticsV2/components/NewSupplierProduct/ProductAndSupplierConnectionForm';
import {
    ResaleProductDTO,
    SupplierConnectionDTO,
} from '@Page/LogisticsV2/types/dtoTypes';
import { ApiError } from '@Page/LogisticsV2/utils/constants';

export function NewSupplierProduct({
    onCreateSupplierConnection,
    onCreateProduct,
    supplierId,
    error,
}: {
    onCreateSupplierConnection: (
        supplierConnection: Partial<SupplierConnectionDTO>
    ) => void;
    onCreateProduct: (
        resaleProduct: Partial<ResaleProductDTO>,
        productGroupId: number | undefined,
        supplierConnection: Partial<SupplierConnectionDTO>
    ) => void;
    supplierId: number | string;
    error?: ApiError;
}) {
    return (
        <>
            <NewProductSection>
                {(hasResaleProduct) =>
                    hasResaleProduct ? (
                        <SupplierConnectionForm
                            onCreateSupplierConnection={
                                onCreateSupplierConnection
                            }
                            supplierId={Number(supplierId)}
                            error={error}
                        />
                    ) : (
                        <ProductAndSupplierConnectionForm
                            onCreateProduct={onCreateProduct}
                            supplierId={Number(supplierId)}
                            error={error}
                        />
                    )
                }
            </NewProductSection>
        </>
    );
}
