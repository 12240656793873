import { invokeAPI, useInfiniteScrollData } from '@Page/LogisticsV2/hooks/api';
import { useState } from 'react';
import {
    ProductImportType,
    ProductPotentialType,
    step,
} from '@Page/LogisticsV2/pages/Import v2/types';
import { ApiUrl, MethodType } from '@Page/LogisticsV2/utils/enums';
import { postValidationMessages } from '@General/LegacyValidationPopup';

export function useFileValidation(
    activeStep: string,
    productImportId: number | undefined,
    onChangeStep: () => void
) {
    const [status, setStatus] = useState<string | null>(null);
    const [result, setResult] = useState<ProductImportType>();
    const [isLoadingImport, setIsLoadingImport] = useState<boolean>(false);

    const {
        data,
        isLoading,
        hasMore,
        loadMore,
        refresh: onRefresh,
    } = useInfiniteScrollData<ProductPotentialType>(
        productImportId && activeStep === step.VALIDATE
            ? ApiUrl.PRODUCT_POTENTIAL
            : undefined,
        {
            fields: '*,productImport(*)',
            productImportId: productImportId,
            status: status,
        }
    );

    function handleStatusChange(newStatus: string | null) {
        if (status === newStatus) {
            setStatus(null);
        } else {
            setStatus(newStatus);
        }
    }

    async function onSubmitValidation() {
        setIsLoadingImport(true);
        const { data, error } = await invokeAPI(
            `${ApiUrl.PRODUCT_IMPORT}/${productImportId}/:executeImport`,
            MethodType.PUT
        );
        if (error) {
            postValidationMessages(getMessage('text_import_validation_error'));
        } else {
            const dataJson = await data?.json();
            const result: ProductImportType = dataJson.value;
            setResult(result);
            onChangeStep();
        }
        setIsLoadingImport(false);
    }

    return {
        data,
        status,
        isLoadingImport,
        isLoading,
        hasMore,
        loadMore,
        onRefresh,
        result,
        onChangeStatus: handleStatusChange,
        onSubmitValidation,
    };
}
