import {
    InventoriesDTO,
    PRODUCT_OVERVIEW_NON_LOGISTICS_FIELDS,
    ProductDTO,
    ProductInventoryLocationDTO,
    StockDTO,
} from '@Page/LogisticsV2/pages/Product/overview/types';
import { PurchaseOverviewOption } from '@Page/LogisticsV2/pages/PurchaseOrder/overview/types';
import { GroupRelationDTO } from '@Page/LogisticsV2/types/dtoTypes';
import { PageUrl } from '@Page/LogisticsV2/utils/enums';
import _ from 'lodash';

export const PRODUCTS_WITHOUT_SUPPLIER = '-2';
export const ALL_PRODUCT_GROUPS = '-1';
export const PRODUCTS_WITHOUT_PRODUCT_GROUPS = '-2';
export const PRODUCTS_WITHOUT_STANDARD_SUPPLIER = '-3';

export const DEFAULT_COLUMNS_NUMBER = 2;
export const NOT_CHOSEN = 0;

export function getLink(id: number) {
    return addContextId(`${PageUrl.PRODUCT_MENU}?productId=${id}`);
}

export function mapInventoryDataToProductDTO(
    product: ProductDTO,
    inventoryData: InventoriesDTO[] | undefined
): ProductDTO {
    if (!inventoryData) {
        return product;
    }
    const inventories: StockDTO[] = [];
    inventoryData
        .filter((inventory) => inventory.product.id === product.id)
        .forEach((item) => {
            item.stock
                .filter((stock) => stock.closingStock !== 0)
                .forEach((stock) => {
                    inventories.push({
                        inventoryId: stock.inventoryId ?? 0,
                        inventory: stock.inventory,
                        closingStock: stock.closingStock,
                    });
                });
        });
    return {
        ...product,
        inventories: _.uniqWith(inventories, _.isEqual) ?? [],
    };
}

export function mapInventoryLocationsToProductDTO(
    product: ProductDTO,
    inventoryLocations: ProductInventoryLocationDTO[] | undefined
) {
    if (!inventoryLocations) {
        return product;
    }
    const uniqueInventoryLocations = Array.from(
        inventoryLocations
            .reduce(
                (map, item) => map.set(item.id, item),
                new Map<number, ProductInventoryLocationDTO>()
            )
            .values()
    );
    const inventories = uniqueInventoryLocations
        .filter((item) => item.product.id === product.id)
        .map((item) => {
            return {
                inventoryId: item.inventory.id,
                inventory: item.inventory.displayName,
                closingStock: item.stockOfGoods,
                inventoryLocation: item.inventoryLocation,
            };
        });

    return {
        ...product,
        inventories: inventories ?? [],
    };
}

export function getProductOverviewFields(
    selectedColumns: PurchaseOverviewOption[]
) {
    const fields = selectedColumns?.map((column) => {
        if (column.enabled && column.name.length > 0) {
            return column.name;
        } else {
            return;
        }
    });
    return `${PRODUCT_OVERVIEW_NON_LOGISTICS_FIELDS}, ` + fields.join(',');
}

export function buildRelationsToCreate(
    relations: GroupRelationDTO[],
    productIds: number[],
    productGroupIds: number[]
) {
    const existingCombinations: { [key: string]: boolean } = {};
    relations.forEach((relation) => {
        const key = `${relation.product.id}-${relation.productGroup.id}`;
        existingCombinations[key] = true;
    });
    const relationsToCreate = [];
    for (const productId of productIds) {
        for (const productGroupId of productGroupIds) {
            const key = `${productId}-${productGroupId}`;
            if (!existingCombinations[key]) {
                relationsToCreate.push({
                    product: { id: productId },
                    productGroup: { id: productGroupId },
                });
            }
        }
    }
    return relationsToCreate;
}

export function findRelationsToDelete(
    relations: GroupRelationDTO[],
    productIds: number[],
    productGroupIds: number[]
) {
    return relations.filter(
        (relation) =>
            productIds.includes(relation.product.id) &&
            productGroupIds.includes(relation.productGroup.id)
    );
}

export function getProductDetailsPayload(
    ids: {
        id: number;
    }[],
    values: { [k: string]: FormDataEntryValue }
) {
    const isInactive =
        values['productDetailsStatus'] === 'inactive'
            ? true
            : values['productDetailsStatus'] === 'active'
            ? false
            : undefined;

    const isStockItem =
        values['productDetailsStockItem'] === 'stockItem'
            ? true
            : values['productDetailsStockItem'] === 'notStockItem'
            ? false
            : undefined;

    const payload = ids.map((product) => {
        return {
            id: product.id,
            ...(values['productDetailsIncomeAccount'] !== 'undefined' &&
            values['productDetailsIncomeAccount'] !== undefined
                ? { account: { id: values['productDetailsIncomeAccount'] } }
                : {}),
            ...(values['productDetailsDepartment'] !== 'undefined' &&
            values['productDetailsDepartment'] !== undefined
                ? { department: { id: values['productDetailsDepartment'] } }
                : {}),
            ...(values['productDetailsUnit'] !== 'undefined'
                ? { productUnit: { id: values['productDetailsUnit'] } }
                : {}),
            ...(isInactive !== undefined ? { isInactive: isInactive } : {}),
            ...(isStockItem !== undefined ? { isStockItem: isStockItem } : {}),
            ...(values['productDetailsStandardSupplier'] !== undefined
                ? {
                      isMainSupplierProduct: true,
                  }
                : {}),
        };
    });
    return payload;
}

export function updateUrl(): void {
    const customerId =
        document.querySelector<HTMLInputElement>(
            '#customerIdForProductOverview'
        )?.value ?? null;
    const currentUrl = window.location.href;
    const newUrl = currentUrl.includes(PageUrl.PRODUCT_OVERVIEW)
        ? PageUrl.PRODUCT_OVERVIEW
        : `${PageUrl.CUSTOMER_MENU}?customerId=${customerId}#supplierProducts`;

    window.history.pushState({}, '', addContextId(newUrl));
}
export function getExportFields(columns: string[]): string {
    const fields = columns.filter(
        (item) => item !== 'warehouse' && item !== 'costCurrency'
    );
    if (columns.includes('costCurrency')) {
        fields.push('supplier(id)');
    }
    return `${PRODUCT_OVERVIEW_NON_LOGISTICS_FIELDS}, ${fields.join(',')}`;
}
