import * as React from 'react';
import { PageLoader } from '@Component/PageLoader';
import { RouteData } from '@General/Router';
import { Team } from '@General/Team';
import { renderComponent } from '@General/renderComponent';

const Page: React.FC = (props) => (
    <PageLoader<{}>
        loader={async () => import('./ResourcePlan2Page').then((m) => m.page)}
        props={props}
    />
);

export const route: RouteData = {
    component: Page,
    path: '/execute/resourcePlan2',
    team: Team.SHEET_STORM,
    documentationComponent: 111,
    title: () => getMessage('text_resource_plan'),
    // To fully connect this page to the TripleTex framework, the page needs a documentationComponent id so that we can
    // link help articles to the page
    auth: () => isAuth.resourcePlan,
};

export function renderResourcePlan(domId: string) {
    renderComponent(Page, domId);
}
