import React, { useEffect, useState } from 'react';
import { useFetchSingle } from '@Page/LogisticsV2/hooks/api';
import { ApiUrl } from '@Page/LogisticsV2/utils/enums';
import {
    ContentSwitch,
    ContentSwitcher,
    Dropdown,
    DropdownDrawer,
    DropdownOpener,
    DropdownScrollContainer,
    DropdownSearch,
    Group,
    Input,
    Label,
    Modal,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalIllustration,
    ModalLayout,
    ModalSubtitle,
    ModalTitle,
    MoneyInput,
    Option,
    PercentageInput,
    RadioGroup,
} from '@tlx/atlas';
import {
    ProductDiscountModalActions,
    ProductDiscountModalSummary,
} from '@Page/LogisticsV2/pages/Product discount/modals/ProductDiscountModalsComponents';
import { DISCOUNT_TYPE } from '@Page/LogisticsV2/pages/Product discount/overview/types';
import { ProductDTO } from '@Page/LogisticsV2/pages/Product/overview/types';
import { getPriceAfterDiscount } from '@Page/LogisticsV2/pages/Product discount/overview/utils';
import { doCreateDiscount } from '@Page/LogisticsV2/pages/Product discount/overview/ProductDiscountAction';
import { postValidationMessages } from '@General/LegacyValidationPopup';
import { getSvgIcon } from '@Page/LogisticsV2/utils/svgIcons';
import { Form } from '@Page/LogisticsV2/components/Form/Form';
import { CustomAsyncDropdownOptions } from '@Page/LogisticsV2/pages/Product discount/modals/CustomAsyncDropdownOptions';
import { AsyncDropdownOptions } from '@tlx/astro-shared';

export function CreateProductDiscountModal({
    onClose,
    onChange,
}: {
    onClose: () => void;
    onChange: () => void;
}) {
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [errorMessage, setErrorMessage] = useState({
        percentError: '',
        fixedAmountError: '',
    });
    const [currentPrice, setCurrentPrice] = useState(0);
    const handleOnSubmit = async (
        formElement: HTMLFormElement,
        values: { [k: string]: FormDataEntryValue }
    ) => {
        const { error } = await doCreateDiscount(
            formElement,
            values,
            currentPrice
        );

        if (error) {
            postValidationMessages(error?.validationMessages[0]?.message);
        } else {
            onChange?.();
        }
        onClose();
    };

    const handleOnChange = (
        formElement: HTMLFormElement,
        values: { [k: string]: FormDataEntryValue }
    ) => {
        if (
            values['discountProduct'] !== '-1' &&
            values['discountProductGroup'] !== '-1'
        ) {
            setIsButtonDisabled(false);
        } else {
            setIsButtonDisabled(true);
        }
    };

    useEffect(() => {
        if (errorMessage.percentError || errorMessage.fixedAmountError) {
            setIsButtonDisabled(true);
        }
    }, [errorMessage]);

    return (
        <Modal
            open={true}
            onClose={onClose}
            className="goldshark-discount-modal"
        >
            <ModalLayout>
                <ModalCloseButton />
                <ModalIllustration>
                    {getSvgIcon('editDiscount')}
                </ModalIllustration>
                <ModalTitle>{getMessage('text_new_discount')}</ModalTitle>
                <ModalSubtitle className="atl-mt-16">
                    {getMessage('text_apply_discount_to_products')}
                </ModalSubtitle>
                <ModalContent>
                    <Form
                        formId={'createDiscountForm'}
                        onSubmit={handleOnSubmit}
                        onChangeForm={handleOnChange}
                    >
                        <CreateProductDiscountModalContent
                            errorMessage={errorMessage}
                            onChangeErrorMessage={setErrorMessage}
                            onChangeCurrentPrice={setCurrentPrice}
                        />
                    </Form>
                </ModalContent>
                <ModalFooter>
                    <ProductDiscountModalActions
                        formId={'createDiscountForm'}
                        onCancel={onClose}
                        isEditMode={false}
                        isButtonDisabled={isButtonDisabled}
                    />
                </ModalFooter>
            </ModalLayout>
        </Modal>
    );
}

export function CreateProductDiscountModalContent({
    errorMessage,
    onChangeErrorMessage,
    onChangeCurrentPrice,
}: {
    errorMessage: { percentError: string; fixedAmountError: string };
    onChangeErrorMessage: (errorMessage: {
        percentError: string;
        fixedAmountError: string;
    }) => void;
    onChangeCurrentPrice: (price: number) => void;
}) {
    const [contentValue, setContentValue] = useState({
        productId: '-1',
        productGroupId: '-1',
        type: DISCOUNT_TYPE.PERCENT,
        percentValue: '',
        value: '',
        isProductGroup: 'false',
        keepExistingDiscount: false,
    });

    const { data: product } = useFetchSingle<ProductDTO>(
        contentValue.productId !== '-1'
            ? `${ApiUrl.PRODUCT}/${contentValue.productId}?fields=priceExcludingVatCurrency,currency(code)`
            : undefined
    );

    useEffect(() => {
        onChangeCurrentPrice(product?.priceExcludingVatCurrency ?? 0);
    }, [product?.priceExcludingVatCurrency]);

    function clearErrorMessages() {
        onChangeErrorMessage({
            percentError: '',
            fixedAmountError: '',
        });
    }

    return (
        <>
            <div className="atl-flex atl-flex-col atl-gap-16">
                <div>
                    <ContentSwitcher data-testid="discount-switcher">
                        <ContentSwitch
                            data-testid="product-discount"
                            value={contentValue.isProductGroup}
                            onChange={() =>
                                setContentValue({
                                    ...contentValue,
                                    isProductGroup: 'false',
                                })
                            }
                            checked={contentValue.isProductGroup === 'false'}
                        >
                            {getMessage('text_products')}
                        </ContentSwitch>
                        <ContentSwitch
                            value={contentValue.isProductGroup}
                            data-testid="product-group-discount"
                            onChange={() =>
                                setContentValue({
                                    ...contentValue,
                                    isProductGroup: 'true',
                                })
                            }
                            checked={contentValue.isProductGroup === 'true'}
                        >
                            {getMessage('text_product_groups')}
                        </ContentSwitch>
                    </ContentSwitcher>
                </div>
                <div className="atl-flex">
                    {contentValue.isProductGroup === 'true' ? (
                        <CreateDiscountProductGroupDropdown
                            defaultValue={contentValue.productGroupId}
                            onChange={(e) => {
                                setContentValue({
                                    ...contentValue,
                                    productGroupId: e.target.value,
                                });
                                clearErrorMessages();
                            }}
                        />
                    ) : (
                        <CreateDiscountProductDropdown
                            contentValue={contentValue}
                            onChange={(e) => {
                                setContentValue({
                                    ...contentValue,
                                    productId: e.target.value,
                                });
                                clearErrorMessages();
                            }}
                        />
                    )}
                </div>
                <div className="atl-flex atl-gap-16">
                    <Group className="atl-w-full">
                        <Label>
                            <RadioGroup>
                                <Input
                                    id="percent"
                                    type="radio"
                                    data-testid="create-discount-percent-radio"
                                    name="discountPercent"
                                    checked={
                                        contentValue.type ===
                                        DISCOUNT_TYPE.PERCENT
                                    }
                                    onChange={() => {
                                        setContentValue({
                                            ...contentValue,
                                            type: DISCOUNT_TYPE.PERCENT,
                                            percentValue: '',
                                            value: '',
                                        });
                                        clearErrorMessages();
                                    }}
                                />
                                <Label htmlFor="percent">
                                    {getMessage('text_percent')}
                                </Label>
                            </RadioGroup>
                        </Label>
                        <PercentageInput
                            className={'atl-w-full'}
                            name="discountPercentInput"
                            data-testid="create-discount-percent-input"
                            disabled={
                                contentValue.type === DISCOUNT_TYPE.FIXED_AMOUNT
                            }
                            value={contentValue.percentValue}
                            onChange={(value) => {
                                let error = '';
                                if (
                                    Number(value) > 100 ||
                                    Number(value) === 0
                                ) {
                                    error = getMessage(
                                        'validation_invalid_percentage',
                                        1
                                    );
                                } else if (Number(value) < 0) {
                                    error = getMessage('validation_negative');
                                } else if (!Number(value)) {
                                    error = getMessage(
                                        'text_only_numbers_allowed'
                                    );
                                }

                                if (error) {
                                    onChangeErrorMessage({
                                        ...errorMessage,
                                        percentError: error,
                                    });
                                    setContentValue({
                                        ...contentValue,
                                        percentValue: value,
                                    });
                                } else {
                                    setContentValue({
                                        ...contentValue,
                                        percentValue: value,
                                    });
                                    clearErrorMessages();
                                }
                            }}
                        />
                        {errorMessage.percentError && (
                            <div className="atl-text-red-100 atl-mt-4">
                                {errorMessage.percentError}
                            </div>
                        )}
                    </Group>
                    <Group className="atl-w-full">
                        <Label>
                            <RadioGroup>
                                <Input
                                    id="value"
                                    type="radio"
                                    data-testid="create-discount-value-radio"
                                    name="discountValue"
                                    checked={
                                        contentValue.type ===
                                        DISCOUNT_TYPE.FIXED_AMOUNT
                                    }
                                    onChange={() => {
                                        setContentValue({
                                            ...contentValue,
                                            type: DISCOUNT_TYPE.FIXED_AMOUNT,
                                            percentValue: '',
                                            value: '',
                                        });
                                        clearErrorMessages();
                                    }}
                                />
                                <Label htmlFor="value">
                                    {getMessage(
                                        'project_contract_type_fixed_price'
                                    )}
                                </Label>
                            </RadioGroup>
                        </Label>
                        <MoneyInput
                            className={'atl-w-full'}
                            currency={product?.currency?.code ?? 'NOK'}
                            name="discountValueInput"
                            data-testid="create-discount-value-input"
                            disabled={
                                contentValue.type === DISCOUNT_TYPE.PERCENT
                            }
                            value={contentValue.value}
                            onChange={(value) => {
                                const price =
                                    product?.priceExcludingVatCurrency ?? 0;
                                let error = '';
                                if (Number(value) === 0) {
                                    error = getMessage(
                                        'validation_amount_not_zero'
                                    );
                                } else if (!Number(value)) {
                                    error = getMessage(
                                        'text_only_numbers_allowed'
                                    );
                                } else if (
                                    !contentValue.isProductGroup &&
                                    price - Number(value) < 0
                                ) {
                                    error = getMessage(
                                        'validation_discount_exceeds_current_price'
                                    );
                                } else if (Number(value) < 0) {
                                    error = getMessage('validation_negative');
                                }

                                if (error) {
                                    onChangeErrorMessage({
                                        ...errorMessage,
                                        fixedAmountError: error,
                                    });
                                    setContentValue({
                                        ...contentValue,
                                        value: value,
                                    });
                                } else {
                                    setContentValue({
                                        ...contentValue,
                                        value: value,
                                    });
                                    clearErrorMessages();
                                }
                            }}
                        />
                        {errorMessage.fixedAmountError && (
                            <div className="atl-text-red-100 atl-mt-4">
                                {errorMessage.fixedAmountError}
                            </div>
                        )}
                    </Group>
                </div>
            </div>
            {contentValue.isProductGroup === 'true' ? (
                <div className="atl-flex atl-flex-col atl-gap-8">
                    <div>
                        {getMessage('text_products_already_have_discount')}
                    </div>
                    <div className="atl-flex atl-gap-16">
                        <RadioGroup className="atl-flex-1">
                            <Input
                                type="radio"
                                data-testid={'keep-existing-discount-button'}
                                id={'keep-existing-discount-button'}
                                checked={contentValue.keepExistingDiscount}
                                name={'keepExistingDiscount'}
                                onChange={() =>
                                    setContentValue({
                                        ...contentValue,
                                        keepExistingDiscount: true,
                                    })
                                }
                            />
                            <Label htmlFor={'keep-existing-discount-button'}>
                                {getMessage('text_keep_existing_discount')}
                            </Label>
                        </RadioGroup>
                        <RadioGroup className="atl-flex-1">
                            <Input
                                type="radio"
                                data-testid={'apply-new-discount-button'}
                                id={'apply-new-discount-button'}
                                name={'applyNewDiscount'}
                                checked={!contentValue.keepExistingDiscount}
                                onChange={() =>
                                    setContentValue({
                                        ...contentValue,
                                        keepExistingDiscount: false,
                                    })
                                }
                            />
                            <Label htmlFor={'apply-new-discount-button'}>
                                {getMessage('text_apply_new_discount')}
                            </Label>
                        </RadioGroup>
                    </div>
                </div>
            ) : (
                <ProductDiscountModalSummary
                    priceBeforeDiscount={
                        product?.priceExcludingVatCurrency ?? 0
                    }
                    priceAfterDiscount={getPriceAfterDiscount(
                        product?.priceExcludingVatCurrency ?? 0,
                        contentValue.type === DISCOUNT_TYPE.PERCENT
                            ? contentValue.percentValue
                            : contentValue.value,
                        contentValue.type
                    )}
                />
            )}
        </>
    );
}

function CreateDiscountProductDropdown({
    contentValue,
    onChange,
}: {
    contentValue: { productId: string };
    onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}) {
    const customerId = document.querySelector<HTMLInputElement>(
        '#customerIdForProductOverview'
    )?.value;
    const [shouldRefresh, setShouldRefresh] = useState(true);

    useEffect(() => {
        if (shouldRefresh) {
            setShouldRefresh(false);
        }
    }, [shouldRefresh]);

    return (
        <Dropdown
            name={'discountProduct'}
            data-testid="create-discount-product-dropdown"
            defaultValue={contentValue.productId}
            defaultDisplayName={getMessage('option_select_product')}
            onChange={onChange}
        >
            <DropdownOpener className="atl-w-full" />
            <DropdownDrawer>
                <DropdownSearch />
                <DropdownScrollContainer>
                    <Option value={'-1'}>
                        {getMessage('option_select_product')}
                    </Option>
                    <CustomAsyncDropdownOptions
                        url={`${ApiUrl.PRODUCT_QUERY}?customerId=${customerId}&hasNoDiscountForCustomer=true&isSupplierProduct=false`}
                        shouldRefreshData={shouldRefresh}
                    ></CustomAsyncDropdownOptions>
                </DropdownScrollContainer>
            </DropdownDrawer>
        </Dropdown>
    );
}

function CreateDiscountProductGroupDropdown({
    defaultValue,
    onChange,
}: {
    defaultValue: string;
    onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}) {
    return (
        <Dropdown
            name={'discountProductGroup'}
            data-testid="create-discount-product-group-dropdown"
            defaultValue={defaultValue}
            defaultDisplayName={getMessage('text_select_product_group')}
            onChange={onChange}
        >
            <DropdownOpener className="atl-w-full" />
            <DropdownDrawer>
                <DropdownSearch />
                <DropdownScrollContainer>
                    <Option value={'-1'}>
                        {getMessage('text_select_product_group')}
                    </Option>
                    <AsyncDropdownOptions url={ApiUrl.PRODUCT_GROUP_QUERY} />
                </DropdownScrollContainer>
            </DropdownDrawer>
        </Dropdown>
    );
}
