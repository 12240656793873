import { Input, Label, ToggleGroup } from '@tlx/atlas';

interface UserFeedbackContactRequestCheckedProps {
    contactRequest: (answer: boolean) => void;
    contactAnswer: boolean | undefined;
}

export function UserFeedbackContactRequestChecked({
    contactRequest,
    contactAnswer,
}: UserFeedbackContactRequestCheckedProps) {
    return (
        <ToggleGroup className={'atl-bg-white'}>
            <Input
                type="toggle"
                checked={contactAnswer}
                onChange={() => contactRequest(!contactAnswer)}
                data-testid="contact-me-checkbox"
                id="contactMe"
            />
            <Label htmlFor="{'contactMe'}">
                {getMessage('text_request_user_contact_accepted')}
            </Label>
        </ToggleGroup>
    );
}
