export function ProductGroupsIcon() {
    return (
        <svg
            width={93}
            height={86}
            viewBox="0 0 93 86"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M53.648.362a2 2 0 00-1.2-.361l-38 1A2 2 0 0012.5 3v28a2 2 0 001.05 1.76l4.684 2.528-8.942 3.387A2 2 0 008 40.545v27.69a2 2 0 001.767 1.987l30.604 3.595a2 2 0 001.249-.264l8.404-4.951c.065.205.162.4.29.575l5.44 7.481a2 2 0 001.692.823l25.142-.921a2 2 0 001.927-1.999V50.374a2 2 0 00-.916-1.681l-8.373-5.396a2 2 0 00-1.134-.318l-16.454.414a2.02 2.02 0 00.026-.321V37.5c0-.118-.01-.234-.03-.346.045-.064.086-.13.123-.199l4.35-.279a2 2 0 001.872-1.995V8.642a2 2 0 00-.852-1.637L53.647.362zm-6.569 37.279L25.77 39.008a2 2 0 01-1.077-.236l-1.811-.977a2.034 2.034 0 01-.174.075l-3.407 1.291 20.958 1.398 6.82-2.918zM16.5 29.807V6.513l7.142 4.24V33.66L16.5 29.807zm11.142 5.073V11.56l32.337-.864v22.108L27.642 34.88zM26.166 7.6l29.706-.795-3.98-2.788-30.413.8 4.687 2.782zm23.765 37.937v18.545c-.152.046-.302.11-.446.195l-6.88 4.054V43.906l11.059-4.731v3.897c0 .144.015.284.044.42l-1.827.045a2 2 0 00-1.95 2zm-11.326-1.08v25.125L12 66.457V42.683l26.605 1.774zm15.327 5.749l1.465 1.396-.018 17.738-1.447-1.99V50.206zm5.442 23.201l21.141-.774v-20.23l-21.12.314-.02 20.69zm-1.189-24.673l17.687-.262-2.295-1.479-16.776.422 1.384 1.32z"
                fill="#0A41FA"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M78.786 35.548l-9.056-7.673 5.524-5.823-7.73-6.548L75.591 7l16.786 14.221-13.591 14.327z"
                fill="#FCE6AF"
            />
            <path
                d="M15.727 58.251C13.592 50.381 6.55 45.445 0 47.228l3.867 14.25 11.86-3.227z"
                fill="#5FDAC4"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M73.545 64.474l16.743 14.032-22.955 7.004 6.212-21.036z"
                fill="#F6AED5"
            />
        </svg>
    );
}

export function SearchIcon() {
    return (
        <svg
            width={74}
            height={70}
            viewBox="0 0 74 70"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M47.272 41.716c-1 2.25-1.516 2.703-1.516 2.703-2.874 4.604-8.88 7.188-16.504 6.234a27.235 27.235 0 01-1.069-.15c-11.362-1.821-21.618-10.77-23.24-20.701-.652-3.455-.054-6.704 1.468-9.387l3.36-5.2"
                stroke="#0332FF"
                strokeWidth={4}
                strokeMiterlimit={10}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M27.021 8.798c2.771.386 5.478 1.199 8.014 2.35C43.27 14.88 49.712 22.17 50.758 29.955c1.368 10.185-6.927 16.831-18.489 15.117-6.735-.938-13.088-4.398-17.547-9.095-3.331-3.508-5.605-7.708-6.19-12.063C7.074 13.05 15.328 7.36 27.023 8.798z"
                stroke="#0332FF"
                strokeWidth={4}
                strokeMiterlimit={10}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M48.586 40.467l21.411 17.906a4.891 4.891 0 01.614 6.89v0a4.891 4.891 0 01-6.882.621l-8.396-6.99"
                stroke="#0332FF"
                strokeWidth={4}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M45.756 44.42a12.304 12.304 0 01-3.08 3.33c-3.248 2.434-7.913 3.593-13.425 2.904a27.453 27.453 0 01-1.068-.15c-11.362-1.822-21.618-10.77-23.24-20.702-.653-3.454-.054-6.703 1.468-9.386l3.36-5.2"
                stroke="#587DFF"
                strokeWidth={4}
                strokeMiterlimit={10}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M50.758 29.956c-1.046-7.786-7.489-15.075-15.723-18.809-2.536-1.15-5.243-1.963-8.014-2.349C15.327 7.36 7.073 13.05 8.533 23.915c.585 4.355 2.858 8.555 6.189 12.063"
                stroke="#587DFF"
                strokeWidth={4}
                strokeMiterlimit={10}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M43.185 48.779l6.074 5.057 6.074 5.056 6.074 5.057"
                stroke="#587DFF"
                strokeWidth={4}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M48.877 52.513L54.5 45.5"
                stroke="#587DFF"
                strokeWidth={4}
                strokeLinecap="round"
            />
            <path
                d="M29.252 50.654a27.068 27.068 0 01-1.069-.15c-11.362-1.822-21.618-10.77-23.24-20.702-.653-3.454-.054-6.703 1.468-9.386l3.36-5.2"
                stroke="#F6AED5"
                strokeWidth={4}
                strokeMiterlimit={10}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M32.27 45.072c-6.736-.938-13.09-4.397-17.548-9.094-3.331-3.51-5.605-7.709-6.19-12.064C7.074 13.05 15.328 7.36 27.023 8.797c2.77.387 5.477 1.2 8.013 2.35"
                stroke="#F6AED5"
                strokeWidth={4}
                strokeMiterlimit={10}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M29.252 50.654a27.068 27.068 0 01-1.069-.15c-11.362-1.822-21.618-10.77-23.24-20.702-.653-3.454-.054-6.703 1.468-9.386l3.36-5.2"
                stroke="#58B3CD"
                strokeWidth={4}
                strokeMiterlimit={10}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M27.021 8.798C15.327 7.36 7.073 13.05 8.533 23.915c.585 4.355 2.858 8.555 6.189 12.063"
                stroke="#58B3CD"
                strokeWidth={4}
                strokeMiterlimit={10}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M29.252 50.653a27.261 27.261 0 01-1.069-.15c-11.362-1.82-21.618-10.77-23.24-20.701"
                stroke="#AAE3C4"
                strokeWidth={4}
                strokeMiterlimit={10}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M23.272 16.466l4.08 15.73M33.812 26.706l2.08 8.73"
                stroke="#0332FF"
                strokeWidth={4}
                strokeLinecap="round"
            />
        </svg>
    );
}
