import { SearchIcon } from '../icons';

export function ChangeProductGroupsEmptyState() {
    return (
        <div className="atl-flex atl-items-center atl-justify-center atl-flex-col atl-gap-32 atl-mb-32">
            <SearchIcon />
            <div className="atl-text-center">
                {getMessage('text_no_productgroups')}
            </div>
        </div>
    );
}
