import React, { FC, ReactElement, useEffect, useState } from 'react';
import { CountryType } from '@General/types/Models';
import { PaymentWidgetDropdown } from '@Page/PaymentWidget/component/PaymentWidgetDropdown/PaymentWidgetDropdown';
import tlxFetch from '../../../api/tlxFetch';

/**
 * Wrapper over the payment widget dropdown component for fetching the countries from backend.
 *
 * @author Daniel Bratosin
 * @date 31. Jan 2022
 */

type PaymentWidgetCountryDropdownWrapperProps = {
    id: string;
    dataTestId: string;
    atlasDesignSystem: boolean;
    name: string;
    value?: number;
    setValue: (value: number) => void;
    validationMessage?: string;
    resetValidationMessage?: () => void;
    supportedInZtl?: boolean;
};

export const PaymentWidgetCountryDropdownWrapper: FC<PaymentWidgetCountryDropdownWrapperProps> =
    ({
        id,
        dataTestId,
        atlasDesignSystem,
        name,
        value,
        setValue,
        validationMessage,
        resetValidationMessage,
        supportedInZtl,
    }): ReactElement => {
        const defaultSelectedCountry: CountryType = {
            url: '',
            version: 0,
            id: 0,
            name: '',
        };
        const [countries, setCountries] = useState<CountryType[]>([]);
        const [selectedCountry, setSelectedCountry] = useState(
            defaultSelectedCountry
        );

        useEffect(() => {
            if (countries?.length) {
                const selectedCountry = countries.find(
                    (countryType) => countryType.id === value
                );

                if (selectedCountry) {
                    setSelectedCountry(selectedCountry);
                }
            }
        }, [countries, value]);

        useEffect(() => {
            async function fetchCreditorCountriesProvider() {
                const { response } = await tlxFetch({
                    url: `/v2/country?isDisabled=false${
                        supportedInZtl ? '&supportedInZtl=true' : ''
                    }`,
                });

                if (response) {
                    const mappedCountries = response.map((c: CountryType) => {
                        const newDisplayName = c.displayName
                            ?.split(',')
                            .join(' ');
                        return {
                            ...c,
                            name: newDisplayName,
                            displayName: newDisplayName,
                        };
                    });

                    const sortedCountries = mappedCountries.sort(
                        (country: CountryType, anotherCountry: CountryType) =>
                            country.name &&
                            anotherCountry.name &&
                            country.name < anotherCountry.name
                                ? -1
                                : 1
                    );
                    setCountries(sortedCountries);
                }
            }
            fetchCreditorCountriesProvider();
        }, []);

        return (
            <PaymentWidgetDropdown
                name={name}
                id={id}
                options={countries.map((creditorCountry) => ({
                    value: creditorCountry.id.toString(),
                    displayName: creditorCountry.name || '',
                }))}
                atlasDesignSystem={atlasDesignSystem}
                dropdownOption={selectedCountry.id.toString()}
                setDropdownOption={(countryId) => {
                    const selectedCountry = countries.find(
                        (countryType) => countryType.id === parseInt(countryId)
                    );

                    if (selectedCountry) {
                        setSelectedCountry(selectedCountry);
                        setValue(selectedCountry.id);
                    }
                }}
                validationMessage={validationMessage}
                resetValidationMessage={resetValidationMessage}
                data-testid={dataTestId}
            >
                {getMessage('text_country')}
            </PaymentWidgetDropdown>
        );
    };
