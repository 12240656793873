import jQuery from 'jquery';
const $ = jQuery;

/**
 * Validations.
 *
 * @author bruce
 */
$.widget('tlx.validationPopup', {
    options: {
        messages: [],
        /* If true, places the popup below the element instead of to the right. */
        placePopupBelow: $(window).width() < 1000,
    },
    _create: function () {},
    clear: function (element) {
        var self = this;
        if (!element) {
            for (var i = 0; i < this.options.messages.length; i++) {
                this._clearMessage(this.options.messages[i]);
                this.options.messages[i].element.removeData(
                    'tlxValidationController'
                );
            }
            this.options.messages = [];
            this.close();
        } else {
            $.each(self.options.messages, function () {
                if ($(this.element).get(0) === $(element).get(0)) {
                    self._clearMessage(this);
                    return false;
                }
            });
        }
    },
    _clearMessage: function (message) {
        message.element
            .unbind('.validationPopup')
            .removeClass('ui-state-error')
            .closest('.inputItem')
            .find('.is-invalid')
            .removeClass('is-invalid');
        message.isCleared = true;
        var allCleared = true;
        for (var i = 0; i < this.options.messages.length; i++) {
            if (!this.options.messages[i].isCleared) {
                allCleared = false;
                break;
            }
        }
        if (allCleared && !this.dialog) {
            this.element.trigger('allValidationsCleared');
        }
    },
    sort: function () {
        var self = this;
        if (self.options.messages.length === 0) {
            return self;
        }
        function findMessageByElement(messages, element) {
            for (var i = 0; i < messages.length; i++) {
                var message = messages[i];
                if ($(message.element).get(0) === $(element).get(0)) {
                    return message;
                }
            }
        }
        var elements = $.map(self.options.messages, function (message) {
            return $(message.element).get(0);
        });
        $.unique(elements); // sort by DOM order. Doesn't accept keyfn :(
        var sortedMessages = [];
        $.each(elements, function (i, element) {
            sortedMessages.push(
                findMessageByElement(self.options.messages, element)
            );
        });
        self.options.messages = sortedMessages;
        self.currentIndex = -1;
        return self;
    },
    open: function () {
        if (this.options.messages.length > 0) {
            var message = this.options.messages[0];
            message.injectionPoint[0].scrollIntoView({
                behavior: 'smooth',
            });
        }
    },
    close: function () {},
    addMessages: function (messages) {
        // I'm quite convinced this method is never called (it only used to be from tests).
        messages.forEach(self.addMessages.apply(this, messages));
    },
    addMessage: function (message) {
        var injectionPoint;
        var that = this;
        message.element = $(message.element); // ensure all elements is jquerywrapped

        // If element is hidden, show message on the closest visible ancestor
        // But only on the new caves element. By some reasons we give validation exceptions
        // on hidden fields that are not supposed to be filled out?
        if (
            message.element.is(':hidden') &&
            message.element.closest('a.caves').length > 0
        ) {
            message.element = message.element.closest(':visible');
        }

        message.element
            .data('tlxValidationController', this)
            .addClass('ui-state-error')
            .trigger('validationError')
            .on('change.validationPopup', function () {
                injectionPoint.find('.errorMessage').remove();
                that._clearMessage(message);
                injectionPoint.find('label').removeClass('errorLabel');
                injectionPoint.find('.is-invalid').removeClass('is-invalid');

                if ($helperText.length > 0) $helperText.show();
            });

        this.options.messages.push(message);

        injectionPoint = message.element.closest('.inputItem');
        injectionPoint.children().first().addClass('is-invalid');

        var $helperText = injectionPoint.next('.tmdl-textfield__helper-text');

        if (injectionPoint.length === 0) {
            injectionPoint = message.element.parent();
        }

        injectionPoint.find('.errorMessage').remove();

        injectionPoint.append(
            '<div class="errorMessage errorWidget"><span class="tlx-active"></span>' +
                message.message +
                '</div>'
        );
        $helperText.hide();

        injectionPoint.find('label').addClass('errorLabel');

        message.injectionPoint = injectionPoint;

        return this;
    },

    remove: function remove(text) {
        $(text)
            .closest('.inputItem, .tlxFileinput')
            .find('.errorMessage')
            .remove();
        this.clear(text);
    },
});
