import { postNotificationMessage } from '@General/LegacyNotification';
import { postValidationMessages } from '@General/LegacyValidationPopup';
import { invokeAPI } from '@Page/LogisticsV2/hooks/api';
import { ApiUrl, MethodType } from '@Page/LogisticsV2/utils/enums';
import { PurchaseOrderDTO, PurchaseOrderStatus } from './types';

export function usePurchaseOrderOverviewBulkAction(
    onChange: (resetPage?: boolean) => void,
    onRefresh: () => void
) {
    const handleBulkSend = async (
        selectedPurchaseOrders: PurchaseOrderDTO[]
    ) => {
        const sendedPOs = await Promise.all(
            selectedPurchaseOrders.map(async (line) => {
                if (line.status === 'STATUS_OPEN') {
                    const { error } = await invokeAPI(
                        `${ApiUrl.PURCHASE_ORDER}/${line.id}/:send`,
                        MethodType.PUT
                    );
                    if (error !== undefined) {
                        if (error.validationMessages.length > 0) {
                            postValidationMessages(
                                getMessage('text_purchase_order') +
                                    ' ' +
                                    line.number +
                                    ' : ' +
                                    error.validationMessages[0].message
                            );
                        }
                    } else {
                        return line.number;
                    }
                }
                return undefined;
            })
        );
        const validIds = sendedPOs.filter((item) => item !== undefined);
        if (validIds.length > 0) {
            postNotificationMessage(
                getMessage('text_purchase_order'),
                [
                    `${getMessage('text_action_completed')} ${getMessage(
                        'text_send_order_out'
                    )}`,
                    `${getMessage('text_purchase_order')}: ${validIds.join(
                        ','
                    )}`,
                ],
                new Date()
            );
        }
        onChange();
        onRefresh();
    };

    const handleBulkDelete = async (
        selectedPurchaseOrders: PurchaseOrderDTO[]
    ) => {
        const deletablePOs = await Promise.all(
            selectedPurchaseOrders.map(async (line) => {
                if (
                    line.status === PurchaseOrderStatus.OPEN ||
                    line.status === PurchaseOrderStatus.SENT ||
                    line.status === PurchaseOrderStatus.CONFIRMED ||
                    (line.status === PurchaseOrderStatus.RECEIVING &&
                        line.supplier)
                ) {
                    const { error } = await invokeAPI(
                        `${ApiUrl.PURCHASE_ORDER}/${line.id}`,
                        MethodType.DELETE
                    );
                    if (error !== undefined) {
                        if (error.validationMessages.length > 0) {
                            postValidationMessages(
                                getMessage('text_purchase_order') +
                                    ' ' +
                                    line.number +
                                    ' : ' +
                                    error.validationMessages[0].message
                            );
                        }
                    } else {
                        return line.number;
                    }
                }
                return undefined;
            })
        );
        const deletableGRs = await Promise.all(
            selectedPurchaseOrders.map(async (line) => {
                if (
                    line.status === PurchaseOrderStatus.RECEIVING &&
                    !line.supplier
                ) {
                    const { data } = await invokeAPI(
                        `${ApiUrl.PURCHASE_ORDER_GOODS_RECEIPT}/${line.id}`,
                        MethodType.GET
                    );
                    const dataJson = await data?.json();
                    const { error } = await invokeAPI(
                        `${ApiUrl.PURCHASE_ORDER_GOODS_RECEIPT}/${dataJson.value.id}`,
                        MethodType.DELETE
                    );
                    if (error !== undefined) {
                        if (error.validationMessages.length > 0) {
                            postValidationMessages(
                                getMessage('text_goods_receipt') +
                                    ' ' +
                                    line.number +
                                    ' : ' +
                                    error.validationMessages[0].message
                            );
                        }
                    } else {
                        return line.number;
                    }
                }
                return undefined;
            })
        );
        const validPoIds = deletablePOs.filter((item) => item !== undefined);
        const validGRIds = deletableGRs.filter((item) => item !== undefined);
        if (validPoIds.length > 0) {
            postNotificationMessage(
                getMessage('text_purchase_order'),
                [
                    `${getMessage('text_action_completed')} ${getMessage(
                        'text_delete'
                    )}`,
                    `${getMessage('text_purchase_order')}: ${validPoIds.join(
                        ','
                    )}`,
                ],
                new Date()
            );
        }
        if (validGRIds.length > 0) {
            postNotificationMessage(
                getMessage('text_goods_receipt'),
                [
                    `${getMessage('text_action_completed')} ${getMessage(
                        'text_delete'
                    )}`,
                    `${getMessage('text_goods_receipt')}: ${validGRIds.join(
                        ','
                    )}`,
                ],
                new Date()
            );
        }
        onChange(true);
        onRefresh();
    };

    const handleBulkChangeStatus = async (
        selectedPurchaseOrders: PurchaseOrderDTO[],
        status: string
    ) => {
        const validPOs = await Promise.all(
            Object.values(selectedPurchaseOrders).map(async (line) => {
                const newPO = {
                    ...line,
                    status: status,
                };
                delete newPO.supplierInvoice;
                delete newPO.totalAmount;
                const { error } = await invokeAPI(
                    `${ApiUrl.PURCHASE_ORDER}/${line.id}`,
                    MethodType.PUT,
                    JSON.stringify(newPO)
                );
                if (error !== undefined) {
                    if (error.validationMessages.length > 0) {
                        postValidationMessages(
                            getMessage('text_purchase_order') +
                                ' ' +
                                line.number +
                                ' : ' +
                                error.validationMessages[0].message
                        );
                    }
                } else {
                    return line.number;
                }
                return undefined;
            })
        );
        const validIds = validPOs.filter((item) => item !== undefined);
        if (validIds.length > 0) {
            postNotificationMessage(
                getMessage('text_purchase_order'),
                [
                    `${getMessage('text_action_completed')} ${getMessage(
                        'text_change_status'
                    )}`,
                    `${getMessage('text_purchase_order')}: ${validIds.join(
                        ','
                    )}`,
                ],
                new Date()
            );
        }
        onChange();
        onRefresh();
    };

    return {
        onBulkDelete: handleBulkDelete,
        onBulkSend: handleBulkSend,
        onBulkChangeStatus: handleBulkChangeStatus,
    };
}
