import { JSPDropdownFetcher } from '@Component/DropDown/JSPDropdown/JSPDropdownFetcher';
import { Option, SearchFilterGenerator } from '@Component/DropDown/types';
import { mergeAll } from '@General/Merge';
import { AsyncProvider, MappedResponse } from '@General/Provider';
import type { Span, SpanStatusType } from '@sentry/browser';
import { captureException, startTransaction } from '@sentry/browser';
import shallowequal from 'shallowequal';
import { dateUtil } from '../../../../../js/modules/date';

/**
 * This should be the common provider for all JSPDropdown components that need to fetch
 * data from JSON-RPC (and eventually API 2.0).
 *
 * This provider's main responsibility is to combine dropdown default values with database
 * values fetched from an API.
 *
 * It is a re-implementation of existing providers, because we currently don't want any
 * caching of results for JSPDropdown. This again, because the fetcher itself tries to find
 * out what the parameters are during the fetch.
 */
export class JSPDropdownProvider<T = {}> extends AsyncProvider<T> {
    /**
     * The defaults are known and delivered to the provider before (and if) it talks to server.
     * They should not be removed after talking to server, and be shown as the topmost choices
     * if user has not performed any search.
     **/
    readonly defaults: Option<T>[];

    readonly fetcher: JSPDropdownFetcher<T>;

    options: Option<T>[];

    /**
     * When user creates a new object with the "Create new" button, we want to fetch the list again
     * even if params isn't changed. If not, the new object is not listed when you open the dropdown
     * again, which is counter-intuitive and weird.
     *
     * This is only set to "true" when creating new elements. Could be used as a general "list must be
     * updated" flag?
     *
     * This would really apply on other dropdowns of same type in same screen as well. How to fix?
     */
    dirty: boolean;

    /**
     * Name of the hidden input backing this dropdown
     */
    inputName: string;

    /**
     * We need to check if we have fetched a specific ID when fetching single elements,
     * because if it does not exist the provider might try to fetch it again and again…
     */
    singleFetched: Map<string | number, Option<T>>;

    /**
     * We only need to fetch tlxSelect options from server if one of the params in the
     * request is actually changing. If this is not changed, we can be certain that the
     * response would contain the same result set as in the options-field (provided nothing
     * has changed in the world in between...).
     */
    currentParams: ObjectIndex = {};

    /**
     * Currently running Sentry transaction
     */
    fetchSpan: Span | undefined;

    /**
     * If this is defined, we will only fetch options when OPENING the dropdown. Make sure that
     * all options are actually fetched! (I.e. don't limit the fetch). This is defined within the
     * corresponding "model" (in the Models directory).
     */
    getInMemorySearchFilter: SearchFilterGenerator<unknown> | undefined;

    constructor(
        fetcher: JSPDropdownFetcher<T>,
        defaults: Option<T>[] = [],
        searchFilterGenerator: SearchFilterGenerator<unknown> | undefined,
        initialOption: Option<T>,
        inputName: string
    ) {
        super();
        this.fetcher = fetcher;
        this.defaults = defaults;
        this.options = [];
        this.singleFetched = new Map();
        this.getInMemorySearchFilter = searchFilterGenerator;
        this.dirty = false;
        this.inputName = inputName;

        const hasDisplayName =
            initialOption.displayName && initialOption.displayName.length;

        // Item might have an "[Element is deleted]" text because of faulty class/buggy getDisplayName on server side.
        const isNotDeleted =
            hasDisplayName && !initialOption.displayName.startsWith('[');

        if (hasDisplayName && isNotDeleted) {
            this.singleFetched.set(initialOption.value + '', initialOption);
        }
    }

    setDirty() {
        this.dirty = true;
    }

    willFetch(query: string) {
        if (this.dirty) {
            this.dirty = false;
            return true;
        }

        /**
         * If fetching content from memory, we don't bother comparing parameters when a query is typed, simply because
         * we assume parameters do not change while typing in a query. When/if query.length is back to 0, we will
         * perform the shallowEqual once more.
         */
        if (this.getInMemorySearchFilter !== undefined && query.length > 0) {
            return false;
        }

        const currentParamsCopy = JSON.parse(
            JSON.stringify(this.currentParams)
        );
        const newParams = mergeAll(this.fetcher.getParams(), {
            name: query,
        });

        // The parameter objects may have start date/end date fields that are null if
        // there is no period selector on the page, which messes up the shallowequal
        // comparison. Therefore, dates are set before the objects are compared.
        if (
            Object.prototype.hasOwnProperty.call(
                currentParamsCopy,
                'startDate'
            ) &&
            Object.prototype.hasOwnProperty.call(newParams, 'startDate') &&
            currentParamsCopy.startDate === null &&
            newParams.startDate === null
        ) {
            currentParamsCopy.startDate = dateUtil.jsonDate(new Date(0));
            newParams.startDate = dateUtil.jsonDate(new Date(0));
        }
        if (
            Object.prototype.hasOwnProperty.call(
                currentParamsCopy,
                'endDate'
            ) &&
            Object.prototype.hasOwnProperty.call(newParams, 'endDate') &&
            currentParamsCopy.endDate === null &&
            newParams.endDate === null
        ) {
            currentParamsCopy.endDate = dateUtil.jsonDate(new Date(0));
            newParams.endDate = dateUtil.jsonDate(new Date(0));
        }

        /**
         * This note only applies if this.getInMemorySearch === undefined:
         *
         * If we waited for merging name-param into params until after this check, we could
         * save more server load and do the search in memory. I won't do this now, because
         * I cannot be certain how the string search has been performed on the different
         * endpoints in the JSON-RPC API. Might even be we don't have all the needed information,
         * i.e. using fields for searching that are not actually displayed in GUI. If every search
         * is only using displayName or similar (DOUBT IT VERY MUCH!) we can have a generic search
         * implementation here in fetchOptions. If not, each individual fetcher would need to define
         * a search for its options before we can do this client side.
         */
        return !shallowequal(newParams, currentParamsCopy);
    }

    async doFetchOptions(query: string, pageIndex?: number): Promise<void> {
        this.currentParams = mergeAll(this.fetcher.getParams(), {
            name: query,
        });

        this.abortCurrentFetch();
        this.startTransaction('fetchList', this.currentParams);
        const options: MappedResponse<T> = await this.fetcher.fetchList(
            this.currentParams,
            pageIndex
        );
        if (Array.isArray(options)) {
            this.options = options
                .filter((x) => x.value !== undefined && x.value !== null)
                // Apply filter provided by the client side (in tlxInitializeState)
                .filter(this.fetcher.clientFilter);
        }
        this.finishTransaction('ok');
    }

    private abortCurrentFetch() {
        if (this.fetcher.abort()) {
            this.inflightRequests--;
        }

        this.finishTransaction('aborted');
    }

    async fetchSingle(id: string | number): Promise<void> {
        const tags = {
            'dropdown.value': id,
            'dropdown.name': this.inputName,
        };

        if (!this.fetcher.validateId(id)) {
            window.Sentry.captureException(
                new Error(
                    'fetchSingle was called with an invalid id, likely missing default <tlx:option>'
                ),
                {
                    tags,
                }
            );
        }

        if (this.singleFetched.has(id)) {
            return;
        }

        this.singleFetched.set(id, { displayName: '' });
        this.abortCurrentFetch();
        this.startTransaction('fetchSingle');
        const options: MappedResponse<T> = await this.fetcher.fetchSingle(id);

        if (Array.isArray(options)) {
            const option = options[0];
            this.singleFetched.set(id, option);

            const oldOptionIndex = this.options.findIndex(
                (option) => option.value === id
            );

            if (oldOptionIndex > -1) {
                this.options[oldOptionIndex] = option;
            }

            super.updateListeners();
        }

        this.finishTransaction('ok');
    }

    getOptions(query: string): Array<Option<T>> {
        // Don't show defaults when searching, unless they are an obvious query hit.
        // Don't use provided getInMemorySearchFilter, because the default Option might (is) of different kind.
        const filterDefaults = (option: Option<unknown>) =>
            option.displayName.toLowerCase().indexOf(query.toLowerCase()) > -1;

        // We have been provided with an in memory searcher!
        if (this.getInMemorySearchFilter !== undefined && query.length > 0) {
            return [
                ...this.defaults.filter(filterDefaults),
                ...this.options.filter(this.getInMemorySearchFilter(query)),
            ];
        }

        // Here we are returning a server side search, still filter out the defaults though!
        return [...this.defaults.filter(filterDefaults), ...this.options];
    }

    getDefaults(query: string): Array<Option<T>> {
        const filterDefaults = (option: Option<unknown>) =>
            option.displayName.toLowerCase().indexOf(query.toLowerCase()) > -1;
        return [...this.defaults.filter(filterDefaults)];
    }

    getOptionsNoDefaults(query: string): Array<Option<T>> {
        // We have been provided with an in memory searcher!
        if (this.getInMemorySearchFilter !== undefined && query.length > 0) {
            return [
                ...this.options.filter(this.getInMemorySearchFilter(query)),
            ];
        }
        // Here we are returning a server side search
        return [...this.options];
    }

    getSingle(id: string | number): Option<T> | null {
        const fetchedOption = this.singleFetched.get(id);
        if (fetchedOption && fetchedOption.value !== undefined) {
            // Only return fetched option if value is set. This might be undefined for incomplete options
            // currently being fetched from the backend.
            return fetchedOption;
        }

        const existingOption =
            this.getOptions('').find((option) => option.value === id) || null;

        // Apparently, if getSingle returns null, the machinery that calls getSingle will try
        // again when the promise from fetchSingle resolves (or when fetchSingle calls updateListeners).
        if (!existingOption) {
            void this.fetchSingle(id);
        }

        return existingOption;
    }

    async prefetch(query: string): Promise<void> {
        // Not implemented
    }

    /**
     * Defaults are not reset, since these are provided from a serverside template. I.e. if the defaults
     * are changed, the whole page has been refreshed none-the-less! :-)
     */
    reset(): void {
        this.options = [];
    }

    /**
     * OBS!
     *
     * In JSPDropdown the params are fetched from within the fetcher itself! This breaks with the existing
     * abstraction of how fetchers and providers function.
     *
     * We do want to implement caching of results for JSPDropdown as well, and need to look into this in
     * detail. But for now, the performance is not worse than existing tlxSelect dropdowns, so no worries! :-)
     *
     * @param key
     * @param value
     */
    setParameter(key: string, value: any): void {
        // Not implemented
    }

    private startTransaction(methodName: string, data?: Record<string, any>) {
        try {
            this.fetchSpan = startTransaction({
                op: 'ui.update',
                name: `${this.fetcher.constructor.name}.${methodName}`,
                tags: {
                    'dropdown.name': this.inputName,
                },
                data,
            });
        } catch (error) {
            captureException(error, {
                extra: { context },
            });
        }
    }

    private finishTransaction(status: SpanStatusType) {
        try {
            if (this.fetchSpan) {
                this.fetchSpan.setStatus(status);
                this.fetchSpan.finish();
                this.fetchSpan = undefined;
            }
        } catch (error) {
            captureException(error, {
                extra: { context },
            });
        }
    }
}
