import { ModelAPIV1 } from '@General/types/Api';
import { notUndefined } from '@General/Helpers';
import { ApiResultMapper } from '@General/Provider';
import * as React from 'react';
import { createCreateNewButton } from '@Component/DropDown/CreateNewButton/CreateNewButton';
import { JSPDropdownFetcher } from '../JSPDropdownFetcher';
import { CreateButtonComponent, Option } from '../../types';

interface AddressEntry extends ModelAPIV1 {
    readonly displayNameInklMatrikkel: string;
}

const addressMapper: ApiResultMapper<AddressEntry> = (item) => ({
    value: `${item.id}`,
    displayName: item.displayNameInklMatrikkel,
    item,
});

class AddressFetcher extends JSPDropdownFetcher<AddressEntry> {
    asyncJsonrpcGetter: Function;

    constructor(scope: HTMLDivElement, queryAttributes: Record<string, any>) {
        super(scope, queryAttributes, addressMapper);
        this.asyncJsonrpcGetter = asyncrpc.Address.get;
    }

    getMarshallSpec() {
        return window.marshallSpec('id', 'displayNameInklMatrikkel');
    }

    getParams(): ObjectIndex {
        return {
            queryId: this.getQueryAttribute('queryId', -1),
            matrikkelOnly: this.getQueryAttribute('matrikkelOnly', false),
            customerVendorId: this.getQueryAttribute('customerElementId', -1),
        };
    }

    async fetchList(params: ObjectIndex): Promise<Option<AddressEntry>[]> {
        return new Promise((resolve, reject) => {
            try {
                return jsonrpc.Address.searchForAddresses(
                    (result: AddressEntry[] | null) => {
                        const mapped = (result ?? [])
                            .filter(notUndefined)
                            .map(this.getMapper(params))
                            .filter(notUndefined);
                        resolve(mapped);
                    },
                    this.getMarshallSpec(),
                    params.queryId,
                    params.customerVendorId,
                    params.name || '',
                    this.tlxSelectMaxRows,
                    params.matrikkelOnly
                );
            } catch (e) {
                defaultJsonRpcExceptionHandler(e);
                reject(e);
            }
        });
    }

    createCreateNewButton(
        setSelected: (id: number) => void,
        dropdownTrackingId?: string
    ): CreateButtonComponent | undefined {
        const matrikkelOnly = this.getQueryAttribute('matrikkelOnly', false);

        if (matrikkelOnly) {
            return createCreateNewButton(
                setSelected,
                'text_address_search_boligmappa',
                '/execute/boligmappaSelectAddress',
                dropdownTrackingId
                    ? getLocaleMessage(
                          'en_GB',
                          'text_address_search_boligmappa'
                      ) +
                          ' - ' +
                          dropdownTrackingId
                    : ''
            );
        }

        // There was no checks on these in the original version as well
        const customerVendorId = this.getQueryAttribute(
            'customerElementId',
            '0'
        );
        const projectId = this.getQueryAttribute('projectId', '');
        const url = `address?customerVendorId=${customerVendorId}&projectId=${projectId}`;

        return createCreateNewButton(
            setSelected,
            'text_new_address',
            url,
            dropdownTrackingId
                ? getLocaleMessage('en_GB', 'text_new_address') +
                      ' - ' +
                      dropdownTrackingId
                : ''
        );
    }
}

export const exports = {
    fetcher: AddressFetcher,
    type: 'Address',
    link: (id: number, contextId: number) =>
        `/execute/address?addressId=${id}&contextId=${contextId}`,
};
