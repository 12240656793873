import {
    Command,
    CommandHandler,
    CommandResponderRegistry,
    KeyboardCombination,
} from '@General/Command/Command.type';
import { isEditable } from '@General/Helpers';
import { CommandEvent } from '@General/Command/CommandEvent';

// Mapping of a combination key to a combination.
type CombinationLookup = {
    [key: string]: Array<KeyboardCombination> | undefined;
};

// Mapping of a combination key to a commandId.
type CommandLookup = {
    [key: string]: Array<string> | undefined;
};

type KeyboardCombinationMap = {
    [commandId: string]: Array<KeyboardCombination>;
};

type CommandMap = {
    [commandId: string]: Command<string, unknown>;
};

type CommandHandlerMap = {
    [commandId: string]: Array<CommandHandler<string, unknown>>;
};

/**
 * The core for handling user input -> action outside of input fields.
 * This follows the "Command pattern" with the added indirection of variable keyboard sequences to commands.
 * The description of user input to action is split up in 3 parts:
 * <ol>
 * <li>{@link Command}: The command and its metadata. </li>
 * <li>{@link KeyboardCombination}: The sequence needed to trigger it.</li>
 * <li>{@link CommandHandler}: The handlers that should be called if the action was triggered.</li>
 * </ol>
 *
 * The basic sequence of events are:
 * <ol>
 * <li>User input sequence.</li>
 * <li>Sequence matches one or more {@link KeyboardCombination}s.</li>
 * <li>All {@link Command} bound to those {@link KeyboardCombination} are triggered.</li>
 * <li>All {@link CommandHandler} for the triggered {@link Command}s are called, optionally with a payload.</li>
 * </ol>
 *
 * @see Command
 * @see KeyboardCombination
 * @see CommandHandler
 * @see CommandResponderRegistry
 */
export class CommandCenter implements CommandResponderRegistry {
    private readonly keyboardCombinations: KeyboardCombinationMap = {};

    // combination key -> commandId
    private keyboardCommandLookup: CommandLookup = {};

    // combination key -> combination data
    private keyboardCombinationLookup: CombinationLookup = {};

    private readonly commands: CommandMap = {};
    private readonly commandHandlers: CommandHandlerMap = {};

    constructor() {
        this.handleKey = this.handleKey.bind(this);
        this.handleCommandTriggerEvent =
            this.handleCommandTriggerEvent.bind(this);
    }

    regenCombinationLookup() {
        const commandMap: CommandLookup = {};
        const combinationMap: CombinationLookup = {};

        Object.entries(this.keyboardCombinations).forEach(
            ([command, combinations]) => {
                combinations.forEach((combination) => {
                    commandMap[combination.key] = [
                        ...(commandMap[combination.key] ?? []),
                        command,
                    ];
                    combinationMap[combination.key] = [
                        ...(combinationMap[combination.key] ?? []),
                        combination,
                    ];
                });
            }
        );

        this.keyboardCombinationLookup = combinationMap;
        this.keyboardCommandLookup = commandMap;
    }

    /**
     * Main handler of user input.
     * @param {KeyboardEvent} event
     */
    handleKey(event: KeyboardEvent) {
        // Event already handled, ignore.
        if (event.defaultPrevented) {
            return;
        }

        // Composite key, ignore.
        if (event.isComposing || event.keyCode === 229) {
            return;
        }

        // Hold-in repeating, ignore.
        if (event.repeat) {
            return;
        }

        // Origin from inside a text field or similar, ignore.
        if (isEditable(event.target as HTMLElement)) {
            return;
        }

        // Inside a Web component, ignore.
        // @ts-expect-error event.target is not properly typed to point to a HTMLElement
        if (event.target?.shadowRoot !== null) {
            return;
        }

        const matchingCombinations = (
            this.keyboardCombinationLookup[event.key] ?? []
        ).filter(
            (combination) =>
                ((combination.alt === undefined && !event.altKey) ||
                    combination.alt === event.altKey) &&
                ((combination.ctrl === undefined && !event.ctrlKey) ||
                    combination.ctrl === event.ctrlKey) &&
                ((combination.meta === undefined && !event.metaKey) ||
                    combination.meta === event.metaKey) &&
                ((combination.shift === undefined && !event.shiftKey) ||
                    combination.shift === event.shiftKey)
        );

        // No matching commands found, ignore.
        if (matchingCombinations.length === 0) {
            return;
        }

        const matchingCommands = this.keyboardCommandLookup[event.key] ?? [];

        // No matching commands found, ignore.
        if (matchingCommands.length === 0) {
            return;
        }

        event.preventDefault();

        for (const matchingCommand of matchingCommands) {
            const command = this.commands[matchingCommand];
            this.triggerCommand(command.id, command.payloadProvider?.());
        }
    }

    handleCommandTriggerEvent(event: CommandEvent<string, unknown>) {
        const { commandId } = event.detail;

        const payload =
            event.detail.payload ??
            this.commands[commandId].payloadProvider?.();

        this.triggerCommand(commandId, payload);
    }

    triggerCommand<C extends string>(commandId: C, payload: unknown) {
        const commandHandlers = this.commandHandlers[commandId];
        if (commandHandlers === undefined) {
            return;
        }

        for (const handler of commandHandlers) {
            handler(commandId, payload);
        }
    }

    registerHandler<C extends string, T>(
        commandId: C,
        handler: CommandHandler<C, T>
    ): void {
        if (this.commandHandlers[commandId] === undefined) {
            this.commandHandlers[commandId] = [];
        }
        const list = this.commandHandlers[commandId];
        const index = list.indexOf(handler as CommandHandler<string, unknown>);
        if (index > 0) {
            console.error(
                'Attempted duplicate registration of a handler on the same commandId!'
            );
            return;
        }

        list.push(handler as CommandHandler<string, unknown>);
    }

    deregisterHandler<C extends string, T>(
        commandId: C,
        handler: CommandHandler<C, T>
    ): void {
        if (this.commandHandlers[commandId]) {
            const list = this.commandHandlers[commandId];
            const index = list.indexOf(
                handler as CommandHandler<string, unknown>
            );
            if (index >= 0) {
                list.splice(index, 1);
            } else {
                console.error('Handler not found in registry for commandId!');
            }
        } else {
            console.error(
                "Attempted to deregister a handler for a commandId that wasn't registered!"
            );
        }
    }

    registerCommand<C extends string, T>(command: Command<C, T>): void {
        if (this.commands[command.id] !== undefined) {
            console.error(
                'Attempted to register a command that was already registered!'
            );
            return;
        }

        this.commands[command.id] = command;
    }

    deregisterCommand<C extends string, T>(command: Command<C, T>): void {
        if (this.commands[command.id] === undefined) {
            console.error(
                "Attempted to deregister a command that wasn't registered!"
            );
            return;
        }

        delete this.commands[command.id];
    }

    registerCombination<C extends string, T>(
        commandId: C,
        combination: KeyboardCombination
    ): void {
        if (this.keyboardCombinations[commandId] === undefined) {
            this.keyboardCombinations[commandId] = [];
        }
        const list = this.keyboardCombinations[commandId];
        const index = list.indexOf(combination);
        if (index > 0) {
            console.error(
                'Attempted duplicate registration of a combination on the same commandId!'
            );
            return;
        }

        list.push(combination);
        this.regenCombinationLookup();
    }

    deregisterCombination<C extends string, T>(
        commandId: C,
        combination: KeyboardCombination
    ): void {
        if (this.keyboardCombinations[commandId]) {
            const list = this.keyboardCombinations[commandId];
            const index = list.indexOf(combination);
            if (index >= 0) {
                list.splice(index, 1);
                this.regenCombinationLookup();
            } else {
                console.error(
                    'Combination not found in registry for commandId!'
                );
            }
        } else {
            console.error(
                "Attempted to deregister a combination for a commandId that wasn't registered!"
            );
        }
    }
}
