import React, { useEffect, useState } from 'react';
import { Alert, AlertCloseButton, AlertContent } from '@tlx/atlas';

type UpsaleProps = {
    className?: string;
};

export const Upsale = ({ className }: UpsaleProps) => {
    const KEY = 'payment_module__hide_upsale';

    const hasPaymentIntegration =
        window.segmentationData?.modules?.moduleRemitZtl === true ||
        window.segmentationData?.modules?.moduleRemitAutoPay === true;

    const [hideUpsale, setHideUpsale] = useState<boolean>(
        hasPaymentIntegration
    );

    useEffect(() => {
        if (hideUpsale) {
            return;
        }
        const localStorageValue = localStorage.getItem(KEY) === 'true';
        setHideUpsale(localStorageValue);
    }, [hideUpsale]);

    if (hideUpsale) {
        return <></>;
    }

    return (
        <div className={className}>
            <Alert variant="info" className="atl-w-full">
                <AlertContent>
                    {getMessage('text_upsale_autopay_voucher_benefit')}
                    <a
                        href={`/execute/mySubscription?contextId=${window.contextId}&activateModule=ztl`}
                    >
                        {getMessage('text_activate_bank_integration_upsale')}
                    </a>
                </AlertContent>
                <AlertCloseButton
                    data-testid="close"
                    onClick={() => {
                        setHideUpsale(true);
                        localStorage.setItem(KEY, 'true');
                    }}
                />
            </Alert>
        </div>
    );
};
