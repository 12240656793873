function validateEmail(
    email: string,
    allowEmpty: boolean,
    maxLength: number
): string | undefined {
    if ((allowEmpty && !email) || email.length === 0) {
        return;
    }

    if (!allowEmpty && !email) {
        return getMessage('validation_missing');
    }

    const validNameChars = new RegExp(/^[0-9a-zA-Z.!#$%&'*+-/=?^_`{|}~]+$/);
    const validDomainChars = new RegExp(/^[0-9a-zA-ZøØæÆåÅ-]+$/);

    const isMaxLengthSet = maxLength > 0;
    if (isMaxLengthSet && email.length > maxLength) {
        return getMessage('validation_too_long');
    }
    if (!email.includes('@')) {
        return getMessage('validation_invalid_format_@_missing');
    }
    if (!email.includes('.')) {
        return getMessage('validation_invalid_format_dot_missing');
    }

    const atPos: number = email.indexOf('@');
    const name: string = email.substring(0, atPos);
    const domains: string = email.substring(atPos + 1);
    const domainList: string[] = domains.split('.');
    if (name.includes(' ')) {
        return getMessage('validation_invalid_format_contains_space');
    }
    if (
        !validNameChars.test(name) ||
        name.startsWith('.') ||
        name.endsWith('.') ||
        name.includes('..') ||
        domains.startsWith('.') ||
        domains.endsWith('.') ||
        domainList.length < 2
    ) {
        return getMessage('validation_invalid_format');
    }

    for (const domain of domainList) {
        if (
            domain.length == 0 ||
            !validDomainChars.test(domain) ||
            domain.startsWith('-') ||
            domain.endsWith('-')
        ) {
            return getMessage('validation_invalid_format');
        }
    }

    return undefined;
}
export function validateEmailList(emails: string, allowEmpty: boolean) {
    if (
        !allowEmpty &&
        (emails?.length === 0 || emails.replace(/\s/g, '').length === 0)
    ) {
        return getMessage('validation_missing');
    }

    let strippedValue = emails.replace(/\s/g, '');
    const lastChar = strippedValue.slice(strippedValue.length - 1);
    if (lastChar === ',' || lastChar === ';') {
        // Allow emails to end with , or ;
        strippedValue = strippedValue.slice(0, strippedValue.length - 1);
    }
    const splitStripped = strippedValue.split(/[,;]+/);
    for (const email of splitStripped) {
        const validationMessage = validateEmail(email, allowEmpty, -1);
        if (validationMessage) {
            return validationMessage;
        }
    }

    return undefined;
}
