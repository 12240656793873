import { MessageObject } from './types';
import { MESSAGE_POSITION } from './types';

export const DEFAULT_TIMEOUT = 10000;

export const ACTION_TYPES = {
    ADD_MESSAGE: 'ADD_MESSAGE',
    CLOSE_MESSAGE: 'CLOSE_MESSAGE',
    SET_MESSAGES: 'SET_MESSAGES',
    CLEAR_MESSAGES: 'CLEAR_MESSAGES',
};

export const getMessageIdentifier = (message: MessageObject) =>
    `${message.position}_${message.text}`;

export const ACTIONS = {
    addMessage: (message: MessageObject) => ({
        type: ACTION_TYPES.ADD_MESSAGE,
        message,
    }),

    closeMessage: (message: MessageObject) => ({
        type: ACTION_TYPES.CLOSE_MESSAGE,
        message,
    }),

    setMessages: (position: MESSAGE_POSITION, messages: MessageObject[]) => ({
        type: ACTION_TYPES.SET_MESSAGES,
        position,
        messages,
    }),

    clearMessages: (position: MESSAGE_POSITION) => ({
        type: ACTION_TYPES.CLEAR_MESSAGES,
        position,
    }),
};
