import $ from 'jquery';
import { tlxAlert } from './alert';
import { tlxForms } from './forms';

window.autoComplete1881 = (function () {
    const COUNTRY_NORWAY_ID = 161;
    const MIN_AUTOCOMPLETE_LENGTH = 3;

    // All classes (or properties) eligible for being auto filled by 1881 auto complete.
    const all1881Classes = [
        'js-1881-address1',
        'js-1881-address2',
        'js-1881-latitude',
        'js-1881-longitude',
        'js-1881-name',
        'js-1881-firstname',
        'js-1881-lastname',
        'js-1881-email',
        'js-1881-homePage',
        'js-1881-phoneNumberWork',
        'js-1881-faxNumber',
        'js-1881-number',
        'type',
        'js-1881-faxnumber',
        'js-1881-postalcode',
        'js-1881-city',
        'countryId, js-1881-address-name',
    ];

    /**
     * Setup 1881 autocomplete on all js-1881-* classes within given scopes.
     * Use js-1881-location for location, js-1881-person for person, or js-1881-company for company.
     * Additionally use js-1881-main for main address (to fill out other fields such as
     * postal code/area based on this autocomplete).
     */
    function setupAutoComplete(/**/) {
        for (let i = 0; i < arguments.length; ++i) {
            // eslint-disable-next-line prefer-rest-params
            setupAutoCompleteForScope(arguments[i]);
        }
    }

    function setupAutoCompleteForScope(scope) {
        const $autoComplete1881 = $(
            '.js-1881-location, .js-1881-person, .js-1881-company',
            scope
        );
        $autoComplete1881
            .focus(function (event) {
                const isPerson = $(this).hasClass('js-1881-person');
                const isCompany = $(this).hasClass('js-1881-company');
                const isMain = $(this).hasClass('js-1881-main');
                const includeGeoWarning = $(this).hasClass('js-1881-noWarning');
                $(event.target)
                    .autocomplete({
                        minLength: MIN_AUTOCOMPLETE_LENGTH,
                        appendTo: scope,
                        source: function (request, response) {
                            doAutoComplete(
                                request,
                                response,
                                isPerson,
                                isCompany,
                                true
                            );
                        },
                        select: function (event, ui) {
                            // If no search results, but user clicks on the search list.
                            if (ui.item.noResult) {
                                return;
                            }
                            if (ui.item.lastItem) {
                                return;
                            }
                            const isSimpleMode =
                                $(this).hasClass('js-1881-simple');
                            if (isPerson) {
                                if (isSimpleMode) {
                                    event.preventDefault();
                                    updatePersonInformation(ui.item, scope);
                                } else {
                                    searchPerson(ui.item, scope);
                                }
                            } else if (isCompany) {
                                if (isSimpleMode) {
                                    event.preventDefault();
                                    updateCompanyInformation(ui.item, scope);
                                } else {
                                    searchCompany(ui.item, scope);
                                }
                            } else {
                                event.preventDefault();
                                updateLocation(
                                    ui.item,
                                    scope,
                                    isMain ? null : get1881Class(this)
                                );
                            }
                        },
                    })
                    .data('ui-autocomplete')._renderItem = function (ul, item) {
                    const $suggestion = renderAutoCompleteSuggestion(
                        item,
                        scope,
                        isPerson,
                        isCompany,
                        !includeGeoWarning
                    );
                    $suggestion.appendTo(ul);
                    return $suggestion;
                };
            })
            .blur(function (event) {
                $(event.target).autocomplete();
                $(event.target).autocomplete('destroy');
            });
    }

    function doAutoComplete(
        request,
        response,
        isPerson,
        isCompany,
        includeTripletexResults
    ) {
        if (request.term.length < MIN_AUTOCOMPLETE_LENGTH) {
            return;
        }
        const handler = function (result) {
            if (result) {
                if (result.length == 0) {
                    result.push({ noResult: true });
                }
                response(result);
            }
        };
        const categories = isPerson
            ? 'Person'
            : isCompany
            ? 'Company'
            : 'Person,Company,Street,Place';
        searchCompletion(
            handler,
            request.term,
            categories,
            includeTripletexResults,
            0
        );
    }

    /**
     * Search for a person, using either an auto complete result, or a phone number.
     */
    function searchPerson(item, scope) {
        const params = $.param({
            query: getFullSearchLine(item, item.id),
            id: item.id,
        });
        window.localAPI.GET('lookup/searchPersonV2?' + params).then(
            function (result) {
                if (result == null || result.length === 0) {
                    tlxAlert(getMessage('text_1881_search_name_error'));
                    return;
                }
                result = result[0];
                if (result['reserved'] === true) {
                    const $phoneNumber = $('.js-1881-phoneNumber');
                    if (!$phoneNumber[0].value) {
                        $('.js-1881-phoneNumberMobile').setErrorMessage(
                            getMessage('text_1881_reserved_number'),
                            $(this).validationPopup().validationPopup('clear')
                        );
                    } else {
                        $phoneNumber.setErrorMessage(
                            getMessage('text_1881_reserved_number'),
                            $(this).validationPopup().validationPopup('clear')
                        );
                    }
                } else {
                    updatePersonInformation(result || item, scope);
                }
            },
            getFullSearchLine(item, item.id),
            item.id
        );
    }

    function searchCompany(item, scope) {
        const params = $.param({
            count: 1,
            query: getFullSearchLine(item, item.id),
            id: item.id,
        });
        window.localAPI.GET('lookup/searchCompany?' + params).then(
            function (result) {
                if (result == null || result.length == 0) {
                    tlxAlert(getMessage('text_1881_search_company_error'));
                }
                result = result[0];
                updateCompanyInformation(result || item, scope);
            },
            getFullSearchLine(item, item.id),
            item.id
        );
    }

    function searchCompletion(
        handler,
        query,
        categories,
        includeTripletexResults,
        projectId
    ) {
        if (includeTripletexResults) {
            if (
                !isAlphanumericIncludingSomeNonStandardLettersAndWhitespace(
                    query
                )
            ) {
                // do nothing, avoid bad request from 1881 and unnecessary calls
                return;
            }
            const searchProjects = performSearchCompletion(
                null,
                query,
                categories,
                false,
                false,
                false,
                includeTripletexResults,
                projectId
            );
            const searchEmployees = performSearchCompletion(
                null,
                query,
                categories,
                false,
                includeTripletexResults,
                false,
                false,
                projectId
            );
            const searchCompanies = performSearchCompletion(
                null,
                query,
                categories,
                false,
                false,
                includeTripletexResults,
                false,
                projectId
            );
            const search1881 = performSearchCompletion(
                null,
                query,
                categories,
                true,
                false,
                false,
                false,
                projectId
            );
            $.when(
                searchProjects,
                searchEmployees,
                searchCompanies,
                search1881
            ).then(function (
                resultProjects,
                resultEmployees,
                resultCompanies,
                result1881
            ) {
                const results = [];
                let i;
                for (i = 0; i < resultProjects.length; i++) {
                    results.push(resultProjects[i]);
                }
                for (i = 0; i < resultEmployees.length; i++) {
                    results.push(resultEmployees[i]);
                }
                for (i = 0; i < resultCompanies.length; i++) {
                    results.push(resultCompanies[i]);
                }
                for (i = 0; i < result1881.length; i++) {
                    results.push(result1881[i]);
                }
                handler(results);
            });
        } else {
            performSearchCompletion(
                handler,
                query,
                categories,
                true,
                includeTripletexResults,
                includeTripletexResults,
                includeTripletexResults,
                projectId
            );
        }
    }

    function performSearchCompletion(
        handler,
        query,
        categories,
        include1881,
        includeTripletexEmployees,
        includeTripletexCompanies,
        includeTripletexProjects,
        projectId
    ) {
        const params = $.param({
            query: query,
            include1881: include1881,
            includeTripletexEmployees: includeTripletexEmployees,
            includeTripletexCompanies: includeTripletexCompanies,
            includeTripletexProjects: includeTripletexProjects,
            categories: categories,
            projectId: projectId || '',
        });
        const url = 'lookup/searchAutoCompletionV2?';
        return window.localAPI.GET(url + params).then(handler);
    }

    function searchTrip(
        handler,
        fromLatitude,
        fromLongitude,
        toLatitude,
        toLongitude
    ) {
        const params = $.param({
            fromLatitude: fromLatitude,
            fromLongitude: fromLongitude,
            toLatitude: toLatitude,
            toLongitude: toLongitude,
        });
        window.localAPI.GET('lookup/trip?' + params).then(handler);
    }

    function getFullSearchLine(item, id) {
        if (typeof item !== 'object') {
            const number = Number(
                typeof item === 'string' ? item.replaceAll(/\s/g, '') : item
            );
            if (typeof number === 'number' && !isNaN(number)) {
                return number;
            }
        }

        return [
            (item.name || item.firstname + ' ' + item.lastname).trim() ||
                item.alternateName,
            item.phoneNumber,
            id ? '' : item.address,
            id ? '' : item.city,
        ]
            .join(' ')
            .trim();
    }

    function renderAutoCompleteSuggestion(
        item,
        scope,
        isPerson,
        isCompany,
        includeGeoWarning
    ) {
        const $li = $('<li>');

        if (item.noResult) {
            const errorMessage = window.getMessage(
                isPerson
                    ? 'text_1881_search_name_error'
                    : isCompany
                    ? 'text_1881_search_company_error'
                    : 'text_1881_search_place_error'
            );
            $li.text(errorMessage);
        } else {
            const missingCoordinatesIcon =
                includeGeoWarning && !item.latitude && !item.longitude;
            const $a = $('<a>')
                .append(getLeftSideAutoSuggestIcon(item))
                .append(
                    isPerson
                        ? formatAutoCompleteRenderPerson(item)
                        : formatAutoCompleteRenderLocation(item)
                )
                .append(
                    missingCoordinatesIcon ? getMissingCoordinatesIcon() : ''
                );

            $li.append($a);
        }
        return $li;
    }

    function getMissingCoordinatesIcon() {
        const $i = $('<i>', {
            class: 'autocomplete1881-icon autocomplete1881-warning-icon material-icons',
            'aria-label': getMessage('text_geo_position_missing'),
        });
        $i.text('warning');
        return $i;
    }

    function getLeftSideAutoSuggestIcon(data) {
        const emptyIcon = getIconOfSrc('/resources/logos/empty-logo.svg', '');
        const $container = $('<div>', { class: 'ui-menu--stacked-icons' });
        if (isOfSource(data, 'Project')) {
            $container.append(
                getMaterialsIconElement(
                    '&#xEB3F;',
                    'projects-icon',
                    getMessage('text_project')
                )
            );
        } else if (isOfSource(data, 'Offer')) {
            $container.append(
                getMaterialsIconElement(
                    '&#xE14D;',
                    'invoices-icon',
                    getMessage('text_offer')
                )
            );
        } else if (isOfSource(data, 'Order')) {
            $container.append(
                getMaterialsIconElement(
                    '&#xE14D;',
                    'invoices-icon',
                    getMessage('text_order')
                )
            );
        } else if (isOfSource(data, 'Employee')) {
            $container.append(
                getMaterialsIconElement(
                    '&#xE87C;',
                    'employees-icon',
                    getMessage('text_employee')
                )
            );
        } else if (isOfSource(data, 'Contact')) {
            $container.append(
                getMaterialsIconElement(
                    '&#xE7FB;',
                    'customers-icon',
                    getMessage('text_contact')
                )
            );
        } else if (isOfSource(data, 'Customer')) {
            $container.append(
                getMaterialsIconElement(
                    '&#xE7FB;',
                    'customers-icon',
                    getMessage('text_customer')
                )
            );
        } else if (isOfSource(data, 'Activity')) {
            $container.append(
                getMaterialsIconElement(
                    '&#xE86C;',
                    'task-icon',
                    getMessage('text_activity')
                )
            );
        } else if (isOfSource(data, 'Company')) {
            $container.append(
                getMaterialsIconElement(
                    '&#xE0AF;',
                    'company-icon',
                    getMessage('text_company')
                )
            );
        } else if (isOfSource(data, 'Hour')) {
            $container.append(
                getMaterialsIconElement(
                    '&#xE425;',
                    'hours-icon',
                    getMessage('text_company')
                )
            );
        } else if (isOfSource(data, 'TravelExpense')) {
            $container.append(
                getMaterialsIconElement(
                    '&#xE904;',
                    'travel-icon',
                    getMessage('text_travel_report')
                )
            );
        } else if (isOfSource(data, 'ControlSchema')) {
            $container.append(
                getMaterialsIconElement(
                    '&#xE24D;',
                    'documents-icon',
                    getMessage('text_control_schema')
                )
            );
        } else if (isOfSource(data, 'Tripletex')) {
            const tripletexIcon = getIconOfSrc(
                '/resources/logos/tripletex-logo-notext.svg',
                getMessage('text_found_in_tripletex')
            );
            $container.append(tripletexIcon);
        } else {
            const emptyIcon2 = getIconOfSrc(
                '/resources/logos/empty-logo2.svg',
                ''
            );
            $container.append(emptyIcon2);
        }
        $container.append(emptyIcon);
        return $container;
    }

    function getIconOfSrc(src, tooltip) {
        const aria = tooltip || '';
        const $img = $('<img>', {
            class: 'autocomplete1881-icon ui-menu--stacked-icon',
            src: src,
            'aria-label': aria,
        });
        return $img;
    }

    function getMaterialsIconElement(icon, themeClass, tooltip) {
        const aria = tooltip || '';
        const $i = $('<i>', {
            'aria-label': aria,
            class:
                themeClass +
                ' autocomplete1881-icon ui-menu--stacked-icon material-icons mdl-list__item-icon',
        });
        $i.append(icon);
        return $i;
    }

    function isOfSource(data, source) {
        return data.sources.includes(source.toUpperCase());
    }

    function formatAutoCompleteRenderLocation(entry) {
        let text = entry.alternateName || entry.name;
        let street = entry.address || '';

        if (entry.category === 'Street') {
            text = entry.address;
            street = '';
        }

        street = [street, entry.postalCode, entry.postalArea].join(' ').trim();

        const $span = $('<span>', { class: 'autocomplete1881-top-text' });
        const $small = $('<small>', { class: 'autocomplete1881-bottom-text' });
        if (street) {
            $small.text(street);
            $span.append('&ensp;').text(text).append($('<br>')).append($small);
        } else {
            $span.append('&ensp;').text(text).append($('<br>'));
        }

        return $span;
    }

    function formatAutoCompleteRenderPerson(item) {
        let phoneNumber = item.phoneNumber || '';
        if (item.phoneNumberMobile) {
            phoneNumber += (phoneNumber ? '/' : '') + item.phoneNumberMobile;
        }
        if (phoneNumber) {
            phoneNumber = ' (' + phoneNumber + ')';
        }
        const address = [item.postalCode, item.postalArea, item.address]
            .join(' ')
            .trim();
        const $small = $('<small>', { class: 'autocomplete1881-bottom-text' });
        if (address) {
            $small.text(address);
        } else {
            $small.append('&nbsp;');
        }

        const $span = $('<span>', { class: 'autocomplete1881-top-text' })
            .append('&ensp;')
            .text((item.alternateName || item.name) + phoneNumber)
            .append($('<br>'))
            .append($small);
        return $span;
    }

    function formatAutoCompleteLocationTextboxDisplay(entry) {
        const text = entry.alternateName || entry.name;
        let street = entry.address || text;
        const postalCodeAndArea = [entry.postalCode, entry.postalArea]
            .join(' ')
            .trim();
        street = [street, postalCodeAndArea]
            .filter(function (a) {
                return a;
            })
            .join(', ');

        if (entry.category === 'Person' || entry.category === 'Street') {
            return street;
        }
        return street || text;
    }

    /**
     * Instead of looking for address, try to find the "title" of the place (e.g. "Museum X")
     */
    function formatAutoCompleteLocationTextboxDisplayName(entry) {
        const text = entry.alternateName || entry.name;
        let street = entry.address || text;
        const postalCodeAndArea = [entry.postalCode, entry.postalArea]
            .join(' ')
            .trim();
        street = [street, postalCodeAndArea]
            .filter(function (a) {
                return a;
            })
            .join(', ');

        if (entry.category === 'Person' || entry.category === 'Street') {
            return street;
        }

        return text || street;
    }

    function updatePersonInformation(data, scope, onlyOfThis) {
        if (!data) {
            return;
        }
        const firstname = data.firstname || data.name || '';
        const lastname = data.lastname || '';
        const name =
            data.alternateName ||
            data.name ||
            [firstname, lastname].join(' ').trim();
        const email = data.email || '';
        const url = data.url || '';
        setValue('js-1881-name', name, scope, onlyOfThis);
        setValue('js-1881-firstname', firstname, scope, onlyOfThis);
        setValue('js-1881-lastname', lastname, scope, onlyOfThis);
        setValue('js-1881-email', email, scope, onlyOfThis);
        setValue('js-1881-homePage', url, scope, onlyOfThis);
        updatePhoneNumber(data, scope);
        updateLocation(data, scope);
    }

    function get1881Class(element) {
        return all1881Classes.find(function (c) {
            return element.classList.contains(c);
        });
    }

    function updateCompanyInformation(data, scope, onlyOfThis) {
        if (!data) {
            return;
        }
        const name = data.name || '';
        const email = data.email || '';
        const url = data.url || '';
        const number = data.companyCode || '';
        const companyType = data.companyType || 0;

        setValue('js-1881-name', name, scope, onlyOfThis);
        setValue('js-1881-email', email, scope, onlyOfThis);
        setValue('js-1881-homePage', url, scope, onlyOfThis);
        setValue('js-1881-number', number, scope, onlyOfThis);
        setValue('type', companyType, scope, onlyOfThis, true);
        updateLocation(data, scope);
        updatePhoneNumber(data, scope);
        // Clear those we don't have
        setValue('js-1881-address2', '', scope, onlyOfThis);
        setValue('js-1881-faxnumber', '', scope, onlyOfThis);
        setValue('js-1881-faxNumber', '', scope, onlyOfThis);
    }

    function updateLocation(data, scope, onlyOfThis) {
        const isPostalcode = $('.js-1881-postalcode').length > 0;
        const addressName = formatAddressName(data);
        const postalCode = data.postalCode || '';
        const postalArea = data.postalArea || '';
        const latitude = data.latitude || '';
        const longitude = data.longitude || '';
        const countryId = getCountryId(data);
        const address = data.address || data.name || data.alternateName;
        let fullAddress = address;
        if (!isPostalcode) {
            fullAddress = [
                [address, postalCode]
                    .filter(function (a) {
                        return a;
                    })
                    .join(', '),
                postalArea,
            ]
                .join(' ')
                .trim();
        }
        setValue('js-1881-address-name', addressName, scope, onlyOfThis);
        setValue('js-1881-address1', fullAddress, scope, onlyOfThis);
        setValue('js-1881-latitude', latitude, scope, onlyOfThis);
        setValue('js-1881-longitude', longitude, scope, onlyOfThis);
        setValue('js-1881-postalcode', postalCode, scope, onlyOfThis);
        setValue('js-1881-city', postalArea, scope, onlyOfThis);
        setValue('countryId', countryId, scope, onlyOfThis, true);
        if (onlyOfThis == 'js-1881-address2') {
            setValue(
                'js-1881-address2',
                [
                    [address, postalCode]
                        .filter(function (a) {
                            return a;
                        })
                        .join(', '),
                    postalArea,
                ]
                    .join(' ')
                    .trim(),
                scope,
                onlyOfThis
            );
        }
    }

    function formatAddressName(data) {
        const address = data.address || '';
        const name = data.alternateName || data.name;
        const text = name;
        const postalCodeAndArea = [data.postalCode, data.postalArea]
            .join(' ')
            .trim();
        let street = address;
        street = street || text;
        street = [street, postalCodeAndArea]
            .filter(function (a) {
                return a;
            })
            .join(', ');

        if (data.category === 'Person' || data.category === 'Street') {
            return street;
        } else if (data.category === 'Company' || data.category === 'Place') {
            return name;
        } else {
            return text;
        }
    }

    function updatePhoneNumber(data, scope, onlyOfThis) {
        const phoneNumber = data.phoneNumber || '';
        const phoneNumberMobile = data.phoneNumberMobile || '';
        const $home = $('.js-1881-phoneNumberHome');
        const $mobile = $('.js-1881-phoneNumberMobile');
        const phoneNumberMain =
            $home.length == 0 && phoneNumber.length > 0
                ? phoneNumber
                : $mobile.length == 0
                ? phoneNumberMobile
                : '';
        setValue('js-1881-phoneNumberHome', phoneNumber, scope, onlyOfThis);

        // If a user has entered a mobile phone, assume the user does not want to update it
        // A user may have more mobile phones registered in 1881
        // we currently only send one mobile phone number to frontend
        if (!$('.js-1881-phoneNumber')[0].value) {
            setValue('js-1881-phoneNumber', phoneNumberMain, scope, onlyOfThis);
        }

        if (!$mobile[0].value) {
            setValue(
                'js-1881-phoneNumberMobile',
                phoneNumberMobile,
                scope,
                onlyOfThis
            );
        }
    }

    function setValue(
        jsClassOrPropertyName,
        value,
        scope,
        onlyOfThis,
        isDropdown
    ) {
        if (value === undefined) {
            value = '';
        }
        const $field = $(
            isDropdown
                ? "[name$='" + jsClassOrPropertyName + "']"
                : '.' + jsClassOrPropertyName,
            scope
        );
        if (onlyOfThis) {
            const isAllowed = !isDropdown && $field.hasClass(onlyOfThis);
            if (!isAllowed) {
                return;
            }
        }
        $field.each(function () {
            if ($(this).is(':visible')) {
                // to avoid changing hidden elements like business address for private persons
                const isReadonly =
                    $field.hasClass('is-readonly') ||
                    $field.hasClass('disabled');

                if (isReadonly) {
                    return;
                } else {
                    tlxForms.change(this, value);
                }
            }
        });
    }

    function getCountryId(data) {
        return data.countryId
            ? Math.max(parseInt(data.countryId), 0)
            : COUNTRY_NORWAY_ID;
    }

    function isAlphanumericIncludingSomeNonStandardLettersAndWhitespace(query) {
        return query.match('^[ a-zA-Z0-9æøåöêé]*$');
    }

    return {
        setupAutoComplete: setupAutoComplete,
        renderAutoCompleteSuggestion: renderAutoCompleteSuggestion,
        formatAutoCompleteLocationTextboxDisplay:
            formatAutoCompleteLocationTextboxDisplay,
        formatAutoCompleteLocationTextboxDisplayName:
            formatAutoCompleteLocationTextboxDisplayName,
        updateCompanyInformation: updateCompanyInformation,
        doAutoComplete: doAutoComplete,
        searchPerson: searchPerson,
        searchCompany: searchCompany,
        searchCompletion: searchCompletion,
        searchTrip: searchTrip,
        minAutoCompleteLength: MIN_AUTOCOMPLETE_LENGTH,
    };
})();
