import { EntitlementLookup } from '@General/constants';
import { ApiUrl } from '@Page/LogisticsV2/utils/enums';
import {
    checkEntitlements,
    getIdFromUrl,
} from '@Page/LogisticsV2/utils/functions';
import {
    AsyncDropdownOptions,
    CheckboxGroupFilter,
    DropdownFilter,
    FilterItem,
    Filters,
    SearchFilter,
    ToggleFilter,
} from '@tlx/astro-shared';
import { Option } from '@tlx/atlas';
import { useEffect, useState } from 'react';
import { ProductOverviewFilterModel } from './types';
import {
    ALL_PRODUCT_GROUPS,
    PRODUCTS_WITHOUT_PRODUCT_GROUPS,
    PRODUCTS_WITHOUT_SUPPLIER,
} from './utils';
import { useExclusiveOption } from '@Page/LogisticsV2/hooks/useExclusiveOption';

const isSupplierAuth = checkEntitlements([
    EntitlementLookup.AUTH_CUSTOMER_INFO,
]);

export function ProductOverviewFilters({
    filters,
}: {
    filters: ProductOverviewFilterModel;
}) {
    const customerId =
        document.querySelector<HTMLInputElement>(
            '#customerIdForProductOverview'
        )?.value ?? null;
    const hasSupplierSelected = getIdFromUrl('supplier');
    const [isSupplierSelected, setIsSupplierSelected] =
        useState(hasSupplierSelected);

    useEffect(() => {
        setIsSupplierSelected(hasSupplierSelected);
    }, [hasSupplierSelected]);

    return (
        <Filters>
            <SearchFilter
                data-testid="product-overview-search"
                label={getMessage('text_search')}
                name={'query'}
                placeholder={getMessage('text_search')}
                quick
                className={'product-overview-search-filter'}
            />
            {moduleLogistics && (
                <DropdownFilter
                    name="productGroups"
                    label=""
                    quick
                    multiple
                    defaultValue={filters.productGroupsFilter}
                    defaultDisplayName={getMessage('text_product_group_all')}
                    className="goldshark-dropdown-filter"
                >
                    <></>
                    <AsyncDropdownOptions url={ApiUrl.PRODUCT_GROUP_QUERY}>
                        <AllProductGroupsOption />
                        <ProductsWithoutGroupsOption />
                    </AsyncDropdownOptions>
                </DropdownFilter>
            )}
            <CheckboxGroupFilter
                name="status"
                label=""
                quick
                defaultValues={filters.statusFilter}
            >
                <FilterItem value="active">
                    {getMessage('text_active')}
                </FilterItem>
                <FilterItem value="inactive">
                    {getMessage('text_inactive')}
                </FilterItem>
            </CheckboxGroupFilter>
            {moduleWarehouse &&
                !customerId &&
                (moduleLogistics || moduleLogisticsLight) && (
                    <DropdownFilter
                        name="inventory"
                        label={getMessage('text_warehouse')}
                        defaultDisplayName={getMessage('text_all_warehouses')}
                    >
                        <></>
                        <AsyncDropdownOptions url={ApiUrl.INVENTORY} />
                    </DropdownFilter>
                )}
            {customerId === null && isSupplierAuth && (
                <DropdownFilter
                    name="supplier"
                    label={getMessage('text_supplier')}
                    defaultDisplayName={getMessage('text_all')}
                    multiple
                >
                    <></>
                    <AsyncDropdownOptions url={ApiUrl.SUPPLIER_QUERY}>
                        <ProductsWithoutSupplierOption />
                    </AsyncDropdownOptions>
                </DropdownFilter>
            )}
            {customerId === null && isSupplierSelected === null && (
                <DropdownFilter
                    name="incomeAccount"
                    label={getMessage('text_income_account')}
                    defaultDisplayName={getMessage('text_all')}
                >
                    <></>
                    <AsyncDropdownOptions url={ApiUrl.INCOME_ACCOUNT_QUERY}>
                        <Option value={0}>
                            {getMessage('option_use_std_account_order_lines')}
                        </Option>
                    </AsyncDropdownOptions>
                </DropdownFilter>
            )}
            {customerId === null && isSupplierSelected === null && (
                <DropdownFilter
                    name="vatType"
                    label={getMessage('text_vat_code')}
                    defaultDisplayName={getMessage('text_all')}
                >
                    <></>
                    <AsyncDropdownOptions
                        url={`${ApiUrl.VAT_TYPE}/query?typeOfVat=OUTGOING`}
                    />
                </DropdownFilter>
            )}
            <DropdownFilter
                name="productUnit"
                label={getMessage('text_product_unit')}
                defaultDisplayName={getMessage('text_all')}
            >
                <></>
                <AsyncDropdownOptions url={ApiUrl.PRODUCT_UNIT_QUERY} />
            </DropdownFilter>
            {moduleLogistics && customerId === null && (
                <ToggleFilter
                    name="withoutMainSupplier"
                    label={getMessage('text_products_without_main_supplier')}
                />
            )}
            {customerId === null &&
                isSupplierSelected === null &&
                (moduleLogistics || moduleLogisticsLight) && (
                    <ToggleFilter
                        name="stockItem"
                        label={getMessage('text_show_only_stock_items')}
                    />
                )}
        </Filters>
    );
}

function AllProductGroupsOption() {
    useExclusiveOption(ALL_PRODUCT_GROUPS);
    return (
        <Option
            value={ALL_PRODUCT_GROUPS}
            data-testid="all-product-groups-option"
        >
            {getMessage('text_product_group_all')}
        </Option>
    );
}

function ProductsWithoutGroupsOption() {
    useExclusiveOption(PRODUCTS_WITHOUT_PRODUCT_GROUPS);
    return (
        <Option
            value={PRODUCTS_WITHOUT_PRODUCT_GROUPS}
            data-testid="products-without-groups-option"
        >
            {getMessage('text_product_without_group')}
        </Option>
    );
}

function ProductsWithoutSupplierOption() {
    useExclusiveOption(PRODUCTS_WITHOUT_SUPPLIER);
    return (
        <Option
            value={PRODUCTS_WITHOUT_SUPPLIER}
            data-testid="products-without-suppliers-option"
        >
            {getMessage('text_products_without_supplier')}
        </Option>
    );
}
