import { captureException } from '@sentry/browser';
import { useState } from 'react';
import useSWR from 'swr';
import { createAPIRequest } from '../../hooks/fetch/createAPIRequest';
import { createAPIResponse } from '../../hooks/fetch/createAPIResponse';
import { defaultFetcher } from '../../hooks/fetch/defaultFetcher';
import { ResponseWrapper } from '../../hooks/fetch/types';
import { useFetchState } from '../../hooks/fetch/useFetchState';
import { SupportReadAccessRequestDialogProps } from './SupportReadAccessRequestDialog';

export function useSupportReadAccessRequestDialog({
    onAccept,
    onReject,
}: {
    onAccept: () => void;
    onReject: () => void;
}): SupportReadAccessRequestDialogProps {
    const [isClosed, setIsClosed] = useState(false);
    const pending = usePendingRequests();

    async function _onAcceptRequest() {
        const success = await acceptRequest();

        if (success) {
            onAccept();
            setIsClosed(true);
        }
    }

    async function _onRejectRequest() {
        const success = await rejectRequest();

        if (success) {
            onReject();
            setIsClosed(true);
        }
    }

    return {
        isOpen: pending && !isClosed,
        onAccept: _onAcceptRequest,
        onReject: _onRejectRequest,
    };
}

function usePendingRequests(): boolean {
    const response = useSWR<ResponseWrapper<boolean>>(
        '/v2/supportAccess/request/read/pending',
        defaultFetcher
    );

    const { data } = useFetchState(response);

    return data === true;
}

async function acceptRequest(): Promise<boolean> {
    return respondToRequest('/v2/supportAccess/request/read/accept');
}

async function rejectRequest(): Promise<boolean> {
    return respondToRequest('/v2/supportAccess/request/read/reject');
}

async function respondToRequest(url: string): Promise<boolean> {
    try {
        const request = createAPIRequest(url, {
            method: 'POST',
        });
        const response = await window.fetch(request);

        await createAPIResponse<null>(request, response);

        return true;
    } catch (error) {
        captureException(error);

        return false;
    }
}
