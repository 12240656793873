import { useListSearchParam, useSearchParam } from '@tlx/astro-shared';

export function usePurchaseOrderOverviewFilter() {
    const projectFilter = useListSearchParam('project');
    const statusFilter = useListSearchParam('status');
    const supplierFilter = useListSearchParam('supplier');
    const orderFilter = useListSearchParam('order');
    const supplierProductsFilter = useListSearchParam('supplierProducts');
    const query = useSearchParam('query');

    return {
        orderFilter,
        projectFilter,
        query,
        statusFilter,
        supplierFilter,
        supplierProductsFilter,
    };
}
