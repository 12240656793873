import * as React from 'react';

import { renderComponent } from '@General/renderComponent';
import { PageLoader } from '@Component/PageLoader';

const Page: React.FC = (props) => (
    <PageLoader<{}>
        loader={async () => import('./MarketingConsent').then((m) => m.page)}
        props={props}
    />
);

export function renderMarketingConsent(domId: string) {
    renderComponent(Page, domId);
}
