import jQuery from 'jquery';
const $ = jQuery;
import { browserInfo } from '../modules/d-browserInfo';
/**
 * Created by harry on 11/05/16.
 * belongs to indexTop.jsp
 */

(function () {
    $(window).on('tlxLoadPageContentDone', function () {
        if (location.pathname === '/execute/employeeMenu') {
            $('.mdl-navigation__link.user-settings').addClass('active');
        } else {
            $('.mdl-navigation__link.user-settings').removeClass('active');
        }

        _makeOnlyDrawerMenuScrollable();
    });

    $(function () {
        _toggleHeaderOnScroll();
    });

    //Both the header end the footer gets the class actionBarDialogOpen if a dialog from the action bar is visible.
    //The actionBarDialogOpen class increases the z-index making them both appear underneath the dialog
    //This is not necessary for dialogs that are further up in DOM structure
    if (window.narrowScreen) {
        $(window).on('dialogopen dialogclose', function (e) {
            if (!$(e.target).is('.toolbarAction')) {
                return;
            }

            $('.mdl-layout__header, .tmdl-layout__action-bar-row').toggleClass(
                'actionBarDialogOpen',
                $(e.target).is(':visible')
            );
        });
    }

    // Toggles the class 'header-scroll-away' based on whether you are scrolling down (header gets hidden)
    // or up (header gets displayed) The header stays hidden if you reach the bottom, even with the annoying
    // iOS safari bouncing effect.
    function _toggleHeaderOnScroll() {
        if (!window.narrowScreen) {
            return;
        }
        let lastScrollTop = 0;
        const $header = $('.mdl-layout__header').removeClass(
            'header-scroll-away'
        );

        $(window).scroll(
            $.throttle(function () {
                if ($('body').hasClass('noScroll')) {
                    return;
                }

                const scrollTop = $(this).scrollTop();
                const isScrollingDown =
                    scrollTop >= lastScrollTop && scrollTop > 0;

                // 100 is just a magic number keeping the header from displaying if we "over scroll" at the bottom
                const scrollHeight =
                    document.body.scrollHeight -
                    $(window).height() -
                    (browserInfo.isIOS ? 100 : 0);

                // We do not want to hide the header if the user enters the page in last scroll position
                // (the scroll event fires on iOS devices before any user scroll has occurred)
                const hasScrolled = lastScrollTop > 0;

                // We want to keep hiding the header if the user reach the bottom
                const isAtBottom = scrollTop >= scrollHeight;

                $header.toggleClass(
                    'header-scroll-away',
                    scrollTop !== 0 &&
                        ((isScrollingDown && hasScrolled) ||
                            (isAtBottom && hasScrolled))
                );
                $header.trigger('headerChange');

                lastScrollTop = scrollTop;
            }, 100)
        );

        $header.click(function (e) {
            $(e.currentTarget).removeClass('header-scroll-away');
            $header.trigger('headerChange');
        });
    }

    //Remove any scroll from body when the drawer menu is visible
    //The drawer menu is only visible on smaller devices (mobile and tablet portrait)
    function _makeOnlyDrawerMenuScrollable() {
        const $layoutDrawer = $('.mdl-layout__drawer');

        // This makes the body non scrollable when the menu is open.
        $('body').click(function () {
            $('body').toggleClass(
                'noScroll',
                $layoutDrawer.hasClass('is-visible')
            );
            $('.mdl-layout__container').toggleClass(
                'noScroll',
                $layoutDrawer.hasClass('is-visible')
            );
        });
    }
})();
