import { ReactNode, useEffect, useState } from 'react';
import '@Page/ClientTasks/TodoList/TodoList.css';
import './Tooltip.css';

export type TooltipDirection =
    | 'top'
    | 'left'
    | 'bottom'
    | 'right'
    | 'top-left'
    | 'top-right'
    | 'bottom-left'
    | 'bottom-right';

export const Tooltip = ({
    className,
    children,
    content,
    direction = 'top',
    backgroundColor = '#2e384d',
}: {
    className?: string;
    children: ReactNode;
    content: ReactNode;
    direction?: TooltipDirection;
    backgroundColor?: string;
}) => {
    document.documentElement.style.setProperty(
        '--tooltip-color',
        backgroundColor
    );

    const [active, setActive] = useState(false);
    const [delay, setDelay] = useState(false);

    const showFullName = () => {
        setDelay(true);
    };

    useEffect(() => {
        const delay = active && setTimeout(showFullName, 250);
        return () => {
            clearTimeout(delay as NodeJS.Timeout);
            setDelay(false);
        };
    }, [active]);

    return (
        <div
            className={`ants-position-relative ${className}`}
            onMouseEnter={() => setActive(true)}
            onMouseLeave={() => setActive(false)}
        >
            {children}
            {delay && (
                <TooltipContent
                    direction={direction}
                    backgroundColor={backgroundColor}
                >
                    {content}
                </TooltipContent>
            )}
        </div>
    );
};

const TooltipContent = ({
    children,
    direction,
    backgroundColor,
}: {
    children?: ReactNode;
    direction?: string;
    backgroundColor: string;
}) => {
    if (!children) {
        return null;
    }

    const renderStyle = {
        backgroundColor: '' + backgroundColor + '',
    };

    return (
        <div
            className={`ants-tooltip ants-tooltip-direction-${
                direction || 'top'
            }`}
            style={renderStyle}
        >
            {children}
        </div>
    );
};
