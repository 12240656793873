import React, { useContext } from 'react';
import { Button, Group, Input, Label, RadioGroup } from '@tlx/atlas';
import Message from '@Component/Messages/component/Message/Message';
import { MESSAGE_POSITION, MESSAGE_TYPE } from '@Component/Messages/types';
import {
    FormProps,
    InvoicingFormProps,
} from '@Page/ProjectInvoicingDialog/component/InvoicingForm/InvoicingForm';
import { ValidationError } from '@Page/ProjectInvoicingDialog/component/ValidationError';
import { getSendTypeErrors } from '@Page/ProjectInvoicingDialog/ValidationUtil';
import { Markdown } from '@Component/Markdown';
import { ElectronicReceiveState } from '@Page/ProjectInvoicingDialog/component/InvoicingForm/Hooks';
import { Customer } from '@Page/ProjectInvoicingDialog/types/InvoicingDialog.type';
import { getSendMethodName } from '@Page/ProjectInvoicingDialog/utils/getSendMethodName';
import { InvoicingModalContext } from '@Page/ProjectInvoicingDialog/component/InvoicingModal';

export const SendTypes = {
    EMAIL: 'EMAIL',
    EHF: 'EHF',
    PAPER: 'PAPER',
    EFAKTURA: 'EFAKTURA',
    AVTALEGIRO: 'AVTALEGIRO',
    MANUAL: 'MANUAL',
    VIPPS: 'VIPPS',
};

export function SendMethod(props: InvoicingFormProps & FormProps) {
    const {
        orderOrProject,
        invoiceSettings,
        electronicReceiveState,
        fields,
        errors,
        watch,
        isUserAdmin,
        isCompanyAdmin,
    } = { ...props };

    const customer = orderOrProject.customer;

    const selectedSendType = watch('invoiceSendMethod');

    const sendTypesToShow = getSendTypesToShow(
        orderOrProject.customer,
        invoiceSettings.sendTypes
    );

    const hasVipps = invoiceSettings.sendTypes.includes(SendTypes.VIPPS);
    const hasEfaktura = invoiceSettings.sendTypes.includes(SendTypes.EFAKTURA);
    const canSendEFaktura = hasEfaktura || hasVipps;
    const canSendEHF = invoiceSettings.sendTypes.includes(SendTypes.EHF);

    const canSendElectronic = customer.isPrivateIndividual
        ? canSendEFaktura
        : canSendEHF;

    const suggestSendType =
        electronicReceiveState === ElectronicReceiveState.YES &&
        canSendElectronic &&
        ((orderOrProject.customer.invoiceSendMethod !== selectedSendType &&
            orderOrProject.customer.invoiceSendMethod !==
                SendTypes.AVTALEGIRO &&
            (selectedSendType === SendTypes.EFAKTURA ||
                selectedSendType === SendTypes.EHF)) ||
            selectedSendType === SendTypes.EMAIL ||
            selectedSendType === SendTypes.PAPER);

    const { trackingIdContext } = useContext(InvoicingModalContext);

    const sendMethodsRadioButtons = (
        <>
            {getMessage('text_send_type')}
            <div className="atl-mb-16">
                <div className={sendTypesToShow.length < 5 ? 'atl-flex' : ''}>
                    {sendTypesToShow.map((sendType) => {
                        return (
                            <RadioGroup
                                key={sendType}
                                className="atl-py-4 atl-mr-16"
                            >
                                <Input
                                    data-testid={'send-type-radio-' + sendType}
                                    id={sendType}
                                    type="radio"
                                    value={sendType}
                                    {...fields.invoiceSendMethod}
                                    data-trackingid={
                                        !window.location.href.includes('story')
                                            ? getSendMethodName(
                                                  sendType,
                                                  'en_GB'
                                              ) +
                                              ' (' +
                                              getLocaleMessage(
                                                  'en_GB',
                                                  'text_send_type'
                                              ).toLowerCase() +
                                              ') (single invoicing) (invoicing React modal)' +
                                              trackingIdContext
                                            : ''
                                    }
                                />
                                <Label htmlFor={sendType}>
                                    {getSendMethodName(sendType)}
                                </Label>
                            </RadioGroup>
                        );
                    })}
                </div>
                <ValidationError error={errors.invoiceSendMethod} />
            </div>
        </>
    );

    const sendTypeErrors = getSendTypeErrors(customer, selectedSendType);

    const sendTypeEmail = (
        <>
            <Group>
                <Label htmlFor={fields.customerEmail.name}>
                    {getMessage('text_email')}
                </Label>
                <Input
                    data-testid="customer-email-input"
                    type="text"
                    className="atl-w-full"
                    {...fields.customerEmail}
                    data-trackingid={
                        !window.location.href.includes('story')
                            ? getLocaleMessage('en_GB', 'text_email') +
                              ' field (single invoicing) (invoicing React modal)' +
                              trackingIdContext
                            : ''
                    }
                />
                <ValidationError error={errors.customerEmail} />
            </Group>
        </>
    );

    const sendTypeEHF = (
        <>
            {!canSendEHF && (
                <ActivateEHFMessage
                    isUserAdmin={isUserAdmin}
                    isCompanyAdmin={isCompanyAdmin}
                />
            )}
            {sendTypeErrors === '' &&
                electronicReceiveState === ElectronicReceiveState.NO &&
                canSendEHF && (
                    <>
                        <SimpleMessage
                            text={getMessage(
                                'text_can_not_receive_ehf',
                                customer.name
                            )}
                        />
                    </>
                )}
            {canSendEHF && sendTypeErrors !== '' && (
                <SimpleMessage text={sendTypeErrors} />
            )}
            {sendTypeErrors === '' &&
                electronicReceiveState === ElectronicReceiveState.ERROR &&
                canSendEHF && (
                    <>
                        <SimpleMessage
                            text={getMessage('text_elma_lookup_failed_ehf')}
                        />
                    </>
                )}
        </>
    );

    const sendMethodSuggestion = (
        <>
            {suggestSendType && (
                <>
                    {customer.isPrivateIndividual &&
                        (selectedSendType === SendTypes.EFAKTURA ? (
                            <Markdown
                                textKey="text_customer_switched_to_efaktura"
                                textKeyArgs={[customer.name]}
                            />
                        ) : (
                            <Markdown
                                textKey="text_customer_wants_efaktura"
                                textKeyArgs={[customer.name]}
                            />
                        ))}
                    {!customer.isPrivateIndividual &&
                        (selectedSendType === SendTypes.EHF ? (
                            <p>
                                {getMessage(
                                    'text_customer_switched_to_ehf',
                                    customer.name
                                )}
                            </p>
                        ) : (
                            <p>
                                {getMessage(
                                    'text_customer_wants_ehf',
                                    customer.name
                                )}
                            </p>
                        ))}
                </>
            )}
        </>
    );

    return (
        <div className="atl-mb-16">
            {sendMethodsRadioButtons}
            <div>
                {sendMethodSuggestion}
                {selectedSendType === SendTypes.EMAIL && sendTypeEmail}
                {selectedSendType === SendTypes.EHF && sendTypeEHF}
                {selectedSendType === SendTypes.EFAKTURA && (
                    <SendTypeEfaktura {...props} />
                )}
            </div>
        </div>
    );
}

function SendTypeEfaktura({
    orderOrProject,
    isCompanyAdmin,
    isUserAdmin,
    fields,
    electronicReceiveState,
    updateCanReceiveElectronic,
    watch,
    invoiceSettings,
}: InvoicingFormProps & FormProps) {
    const customerPhoneNumber = watch('customerPhoneNumber');
    const customer = orderOrProject.customer;

    const hasVipps = invoiceSettings.sendTypes.includes(SendTypes.VIPPS);
    const hasEfaktura = invoiceSettings.sendTypes.includes(SendTypes.EFAKTURA);
    const canSendEFaktura = hasEfaktura || hasVipps;

    const { trackingIdContext } = useContext(InvoicingModalContext);

    return (
        <>
            {!canSendEFaktura && (
                <ActivateEfakturaMessage
                    isCompanyAdmin={isCompanyAdmin}
                    isUserAdmin={isUserAdmin}
                />
            )}
            {electronicReceiveState !== ElectronicReceiveState.YES &&
                canSendEFaktura &&
                !customer.phoneNumber &&
                !customer.phoneNumberMobile && (
                    <div>
                        <SimpleMessage
                            text={getMessage(
                                'text_add_phone_number_warning',
                                customer.name
                            )}
                        />
                        <div className="atl-grid tlx-invoicingDialog__gridCols2 atl-mb-16">
                            <Group className="atl-mr-16">
                                <Label
                                    htmlFor={fields.customerPhoneNumber.name}
                                >
                                    {getMessage('text_phone_number')}
                                </Label>
                                <Input
                                    data-testid="customer-phone-number-input"
                                    type="text"
                                    className="atl-w-full"
                                    data-trackingid={
                                        !window.location.href.includes('story')
                                            ? getLocaleMessage(
                                                  'en_GB',
                                                  'text_efaktura'
                                              ) +
                                              ' ' +
                                              getLocaleMessage(
                                                  'en_GB',
                                                  'text_phone_number'
                                              ).toLowerCase() +
                                              ' (single invoicing) (invoicing React modal)' +
                                              trackingIdContext
                                            : ''
                                    }
                                    {...fields.customerPhoneNumber}
                                />
                            </Group>
                            <Button
                                data-testid="update-customer-button"
                                onClick={() =>
                                    updateCanReceiveElectronic(
                                        customerPhoneNumber
                                    )
                                }
                                variant="tertiary"
                                className="atl-mt-auto"
                                data-trackingid={
                                    !window.location.href.includes('story')
                                        ? getLocaleMessage(
                                              'en_GB',
                                              'text_efaktura'
                                          ) +
                                          ' ' +
                                          getLocaleMessage(
                                              'en_GB',
                                              'button_search'
                                          ).toLowerCase() +
                                          ' (single invoicing) (invoicing React modal)' +
                                          trackingIdContext
                                        : ''
                                }
                            >
                                {getMessage('button_search')}
                            </Button>
                        </div>
                    </div>
                )}
            {electronicReceiveState === ElectronicReceiveState.NO &&
                canSendEFaktura &&
                (customer.phoneNumber || customer.phoneNumberMobile) && (
                    <SimpleMessage
                        text={getMessage(
                            'text_can_not_receive_efaktura',
                            customer.name
                        )}
                    />
                )}
            {electronicReceiveState === ElectronicReceiveState.ERROR &&
                canSendEFaktura && (
                    <>
                        <SimpleMessage
                            text={getMessage(
                                'text_elma_lookup_failed_efaktura'
                            )}
                        />
                    </>
                )}
        </>
    );
}

function SimpleMessage({ text }: { text: string }) {
    return (
        <Message
            extraClassnames="atl-mb-16"
            message={{
                text: text,
                type: MESSAGE_TYPE.INFO,
                position: MESSAGE_POSITION.INLINE,
                isCloseable: false,
            }}
        />
    );
}

function ActivateEfakturaMessage({
    isCompanyAdmin,
    isUserAdmin,
}: {
    isCompanyAdmin: boolean | undefined;
    isUserAdmin: boolean | undefined;
}) {
    const { trackingIdContext } = useContext(InvoicingModalContext);
    if (isUserAdmin || isCompanyAdmin) {
        return (
            <Message
                extraClassnames="atl-mb-16"
                message={{
                    text: getMessage('text_efaktura_get_monney_faster'),
                    type: MESSAGE_TYPE.INFO,
                    position: MESSAGE_POSITION.INLINE,
                    isCloseable: false,
                    action: {
                        text: getMessage('text_activate_efaktura'),
                        onClick: () =>
                            window.open(
                                addContextId('/execute/mySubscription')
                            ),
                        trackingId: !window.location.href.includes('story')
                            ? getLocaleMessage(
                                  'en_GB',
                                  'text_activate_efaktura'
                              ) +
                              ' (single invoicing) (invoicing React modal)' +
                              trackingIdContext
                            : '',
                    },
                }}
            />
        );
    } else {
        return (
            <Message
                extraClassnames="atl-mb-16"
                message={{
                    text: getMessage(
                        'text_efaktura_get_monney_faster_no_access'
                    ),
                    type: MESSAGE_TYPE.INFO,
                    position: MESSAGE_POSITION.INLINE,
                    isCloseable: false,
                }}
            />
        );
    }
}

function ActivateEHFMessage({
    isCompanyAdmin,
    isUserAdmin,
}: {
    isCompanyAdmin: boolean | undefined;
    isUserAdmin: boolean | undefined;
}) {
    const { trackingIdContext } = useContext(InvoicingModalContext);
    if (isUserAdmin || isCompanyAdmin) {
        return (
            <Message
                extraClassnames="atl-mb-16"
                message={{
                    text: getMessage('text_ehf_get_money_faster'),
                    type: MESSAGE_TYPE.INFO,
                    position: MESSAGE_POSITION.INLINE,
                    isCloseable: false,
                    action: {
                        text: getMessage('text_activate_ehf'),
                        onClick: () =>
                            window.open(
                                addContextId('/execute/mySubscription')
                            ),
                        trackingId: !window.location.href.includes('story')
                            ? getLocaleMessage('en_GB', 'text_activate_ehf') +
                              ' (single invoicing) (invoicing React modal)' +
                              trackingIdContext
                            : '',
                    },
                }}
            />
        );
    } else {
        return (
            <Message
                extraClassnames="atl-mb-16"
                message={{
                    text: getMessage('text_ehf_get_money_faster_no_access'),
                    type: MESSAGE_TYPE.INFO,
                    position: MESSAGE_POSITION.INLINE,
                    isCloseable: false,
                }}
            />
        );
    }
}

export function getSendMethodNameForInvoicingButton(enumValue: string) {
    if (enumValue === SendTypes.AVTALEGIRO) {
        return getMessage('text_invoice_option_avtalegiro');
    } else if (enumValue === SendTypes.EFAKTURA) {
        return getMessage('text_invoice_option_efaktura');
    } else if (enumValue === SendTypes.EHF) {
        return getMessage('text_invoice_using_accesspoint_short');
    } else if (enumValue === SendTypes.EMAIL) {
        return getMessage('text_invoice').toLowerCase();
    } else if (enumValue === SendTypes.PAPER) {
        return getMessage('text_invoice_option_paper').toLowerCase();
    } else if (enumValue === SendTypes.MANUAL) {
        return getMessage('text_send_type_later');
    } else if (enumValue === SendTypes.VIPPS) {
        return getMessage('text_invoice_option_efaktura');
    }

    return '';
}

function getSendTypesToShow(customer: Customer, sendTypes: string[]) {
    const sendTypesToShow: string[] = [
        customer.isPrivateIndividual ? SendTypes.EFAKTURA : SendTypes.EHF,
        SendTypes.EMAIL,
        SendTypes.MANUAL,
    ];

    if (
        customer.isPrivateIndividual &&
        sendTypes.includes(SendTypes.AVTALEGIRO)
    ) {
        sendTypesToShow.unshift(SendTypes.AVTALEGIRO);
    }

    if (customer.invoiceSendMethod === SendTypes.PAPER) {
        sendTypesToShow.push(SendTypes.PAPER);
    }

    return sendTypesToShow;
}
