import * as React from 'react';
import { PageLoader } from '@Component/PageLoader';
import { renderComponent } from '@General/renderComponent';
import { unmount } from '../../util/React18mount';

export const Page: React.FC = (props) => (
    <PageLoader<{}>
        loader={async () =>
            import('./ProjectWageModal.module').then((m) => m.page)
        }
        props={props}
    />
);

export function renderProjectWageModal(domId: string, props: any) {
    renderComponent(Page, domId, props);
}

export function unmountProjectWageModal() {
    const component = document.getElementById(
        'projectWageModal'
    ) as HTMLElement;
    unmount(component);
}
