import jQuery from 'jquery';
const $ = jQuery;
import { tlxForms } from '../../modules/forms';

/**
 * This is intended as a general "view options" dialog.
 *
 * You use it by simply having a tlx:viewOptionsDialog component on the screen. You can have maximum of one
 * tlx:viewOptionsDialog for each screen.
 *
 * This component makes sure that when user clicks Cancel/Avbryt in the dialog, values on input elements inside the
 * dialog is restored to what they were when dialog is opened.
 *
 * When the dialog is closed, it triggers an event called viewOptionsDialog-close. Listen to this event to perform
 * view changes according to what the user has selected in dialog.
 *
 * For an implementation example, see incomingInvoicePostings.
 * To see it in action, click "Bilag -> Leverandørfaktura".
 *
 */
$(window).on(
    'tlxLoadPageContentDone tlxTabsActivate tlxLazyLoadDone',
    function (e) {
        let $viewOptionsDialog;
        const target = e.target;

        $('table .view-options-container', target).each(function () {
            const $tlxToolbar = $(this).siblings('.tlx-toolbar-table');
            if ($tlxToolbar.length > 0) {
                $(this).prependTo($tlxToolbar);
            }
        });

        $('.view-options-entry', target).on(
            'click',
            _createAndShowViewOptionsDialog
        );

        // Destroy the view options dialog on close. We should only have one option dialog
        // up at the time, and the removal of dialog also removes this event listener.
        $('.view-options-dialog').on('dialogclose', function () {
            if ($(this).dialog('instance')) {
                $(this).dialog('destroy');
            }
        });

        /**
         * Make sure that if the dialog is canceled, the input items in dialog is reset to
         * same values as when opened.
         */
        function _cacheInputValuesOnDialogOpen() {
            const values = [];

            $('input', $viewOptionsDialog).each(function () {
                const $item = $(this);
                let value = $item.val();
                if ($item.is('[type=checkbox], [type=radio]')) {
                    value = $item.is(':checked');
                }
                values.push({ $item: $item, value: value });
            });

            $viewOptionsDialog.data(
                'tlx-viewOptionsDialog-valuesOnOpen',
                values
            );
        }

        function _restoreInputValuesOnDialogCancel() {
            // Return input items in dialog to it's old values.
            const values = $viewOptionsDialog.data(
                'tlx-viewOptionsDialog-valuesOnOpen'
            );

            values.forEach(function (item) {
                if (item.$item.is('[type=checkbox], [type=radio]')) {
                    tlxForms.check(item.$item, item.value);
                } else {
                    tlxForms.change(item.$item, item.value);
                }
            });
        }

        function _createAndShowViewOptionsDialog() {
            //We need to remove the cloned view option dialog in the floating header containing cloned checkboxes.
            //If we do not remove the checkboxes, the checkboxes will not work (we can not have the same properties
            // multiple times in the DOM).
            $('thead.tlxFloatingHeader .view-options-dialog', target).remove();

            $viewOptionsDialog = $('.view-options-dialog', target).first();

            $viewOptionsDialog.dialog({
                minWidth: 600,
                modal: true,
                title: getMessage('text_view_options'),
                buttons: _createDialogButtons(),
            });

            _cacheInputValuesOnDialogOpen();

            $viewOptionsDialog.dialog('open');

            $viewOptionsDialog.find('a.navigate').one('click', function () {
                if ($(this).dialog('instance')) {
                    $viewOptionsDialog.dialog('destroy');
                }
            });
        }

        function _createDialogButtons() {
            const openDialogButtonTrackingId = $(
                '.view-options-entry',
                target
            ).data().trackingid;
            let okButtonTrackingIdParts = [''];
            let cancelButtonTrackingIdParts = [''];
            if (openDialogButtonTrackingId) {
                okButtonTrackingIdParts =
                    openDialogButtonTrackingId.split(' (');
                okButtonTrackingIdParts[0] =
                    okButtonTrackingIdParts[0] +
                    ' (' +
                    window.getLocaleMessage('en_GB', 'button_ok') +
                    ')';
                cancelButtonTrackingIdParts =
                    openDialogButtonTrackingId.split(' (');
                cancelButtonTrackingIdParts[0] =
                    cancelButtonTrackingIdParts[0] +
                    ' (' +
                    window
                        .getLocaleMessage('en_GB', 'button_cancel')
                        .toLowerCase() +
                    ')';
            }

            return [
                {
                    text: getMessage('button_ok'),
                    class: 'tlx-green',
                    click: function () {
                        $(this).dialog('close');
                        $(target).trigger('viewOptionsDialog-close');
                        $(target).trigger('tlxPossibleWidthChange');
                    },
                    trackingId: okButtonTrackingIdParts.join(' ('),
                },
                {
                    text: getMessage('button_cancel'),
                    click: function () {
                        const $this = $(this);
                        $this.dialog('close');

                        window.setTimeout(function () {
                            _restoreInputValuesOnDialogCancel();
                        }, 200);
                    },
                    trackingId: cancelButtonTrackingIdParts.join(' ('),
                },
            ];
        }
    }
);
