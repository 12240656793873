import { renderComponent } from '@General/renderComponent';
import { PageLoader } from '@Component/PageLoader';

const Page = () => {
    return (
        <PageLoader<{}>
            adjustWrapperDiv
            loader={async () =>
                import('./BankReconciliation').then((m) => m.page)
            }
        />
    );
};

export function renderBankReconciliation(domId: string) {
    renderComponent(Page, domId);
}
