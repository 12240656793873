import { Parser, Grammar } from 'nearley';

import mathGrammar from './Expression.nearley';

import {
    BinaryOperation,
    ExpressionNode,
    UnaryOperation,
} from './Expression.type';
import { getOperator } from './Expression.lexer';
import { dateUtil } from '../../../../js/modules/date';

const compiledMathGrammar = Grammar.fromCompiled(mathGrammar);

export function unFormatExpression(expression: string) {
    return expression
        .replace(new RegExp(`\\${window.decimalSeparator}`, 'g'), '.')
        .replace(/ /g, '')
        .replace(new RegExp(`\\${window.groupingSeparator}`, 'g'), '');
}

/**
 * Calculates the result from an expression via Nearley (a EBNF evaluator)
 *
 * @param expression The expression string to calculate
 *
 * @return The final value of the expression
 *
 * @author tellef
 * @date 2021-06-17
 */
export function evaluateExpression(expression: string): number {
    const parsed = parseExpression(unFormatExpression(expression));
    if (parsed === null) {
        return 0;
    }
    return evaluateNode(parsed);
}

/**
 * @param hoursTimeRange Must be a string containing a hyphen/minus with time strings on both sides.
 *                       The time strings must be of the format hh, hh:mm or hh.mm.
 * @return The number of hours between the start and stop times, rounded to two decimal places,
 *         or undefined if hoursTimeRange is invalid.
 */
export function evaluateHoursTimeRange(
    hoursTimeRange: string
): number | undefined {
    if (
        !hoursTimeRange.startsWith('-') &&
        hoursTimeRange.split('-').length === 2
    ) {
        hoursTimeRange = hoursTimeRange.replace(/\s+/g, '');
        const startTime = hoursTimeRange.split('-')[0];
        const stopTime = hoursTimeRange.split('-')[1];
        if (startTime.length === 0 || stopTime.length === 0) {
            return undefined;
        }
        const startTimeSecondsSinceDayStart =
            dateUtil.getTimeStringSeconds(startTime);
        const stopTimeSecondsSinceDayStart =
            dateUtil.getTimeStringSeconds(stopTime);
        const timeRangeTotalSeconds =
            stopTimeSecondsSinceDayStart - startTimeSecondsSinceDayStart;
        let timeRangeTotalHours = timeRangeTotalSeconds / (60 * 60);
        if (timeRangeTotalSeconds < 0) {
            // If the number of seconds is negative, the start time is after the stop time.
            // In this case, the stop time is considered to be on the following day
            timeRangeTotalHours += 24;
        } else if (timeRangeTotalHours == 0) {
            return undefined;
        }
        return Number(timeRangeTotalHours.toFixed(2));
    }
    return undefined;
}

// Roll recursion out to a loop
function evaluateNode(node: ExpressionNode): number {
    switch (node.type) {
        case 'number':
            return parseFloat(node.value);

        case 'binaryOperation':
            return evaluateBinaryOperation(node);

        case 'unaryOperation':
            return evaluateUnaryOperation(node);

        case 'roundBracketGroup':
            return evaluateNode(node.content);

        default:
            console.error('Unhandled node when evaluating expression', node);
            return 0;
    }
}

function evaluateBinaryOperation(node: BinaryOperation): number {
    const left = evaluateNode(node.leftOperand);
    const right = evaluateNode(node.rightOperand);
    const operator = getOperator(node.operator.value);

    return operator?.apply(left, right) ?? 0;
}

function evaluateUnaryOperation(node: UnaryOperation): number {
    const value = evaluateNode(node.operand);
    switch (node.operator.type) {
        case 'plus':
            return +value;
        case 'minus':
            return -value;
        default:
            throw new Error('Should not happen');
    }
}

function parse(expression: string) {
    if (expression.length === 0) {
        return [];
    }

    const expressionParser = new Parser(compiledMathGrammar);
    expressionParser.feed(expression);
    return expressionParser.finish();
}

/**
 *
 * @param expression
 *
 * @author tellef
 * @date 2021-06-17
 */
function parseExpression(expression: string): ExpressionNode | null {
    try {
        const ast: ExpressionNode[] = parse(unFormatExpression(expression));

        if (ast.length > 1) {
            console.error(
                `ambiguity in parsing of expression \`${expression}\`:\n${JSON.stringify(
                    ast,
                    undefined,
                    4
                )}`
            );
        }

        if (ast.length > 0) {
            return ast[0];
        }
    } catch (e) {
        console.log(`Error parsing expression "${expression}"\n`, e);
    }

    return null;
}
