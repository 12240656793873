import { ModelAPIV1 } from '@General/types/Api';
import { notUndefined } from '@General/Helpers';
import { ApiResultMapperNoUndefinedResult } from '@General/Provider';
import { JSPDropdownFetcher } from '../JSPDropdownFetcher';
import { Option } from '../../types';

interface AssociationEntry extends ModelAPIV1 {
    readonly name: string;
    readonly number: string;
}

const associationMapper: ApiResultMapperNoUndefinedResult<AssociationEntry> = (
    item
) => ({
    value: `${item.id}`,
    displayName: item.number + ' ' + item.name,
    item,
});

class AssociationFetcher extends JSPDropdownFetcher<AssociationEntry> {
    asyncJsonrpcGetter: Function;

    constructor(scope: HTMLDivElement, queryAttributes: Record<string, any>) {
        super(scope, queryAttributes, associationMapper);

        this.asyncJsonrpcGetter = asyncrpc.Association.get;
    }

    getMarshallSpec() {
        return window.marshallSpec('id', 'number', 'name');
    }

    getParams(): ObjectIndex {
        return {};
    }

    async fetchList(params: ObjectIndex): Promise<Option<AssociationEntry>[]> {
        return new Promise((resolve, reject) => {
            try {
                jsonrpc.Association.search(
                    (result: AssociationEntry[] | null) => {
                        const mapped = (result ?? [])
                            .map(this.getMapper(params))
                            .filter(notUndefined);
                        resolve(mapped);
                    },
                    this.getMarshallSpec(),
                    params.name || ''
                );
            } catch (e) {
                defaultJsonRpcExceptionHandler(e);
                reject(e);
            }
        });
    }
}

export const exports = {
    fetcher: AssociationFetcher,
    type: 'Association',
};
