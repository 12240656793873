import $ from 'jquery';

export const dialog = (function () {
    /**
     * Remove site specific messages on click.
     * @param e
     */
    function clickHandler(e) {
        e.preventDefault();
        const id = $(e.target).closest('[data-messageid]').data('messageid');
        let dismissed;

        $(e.target).closest('.txr-message').remove();

        if (id) {
            // In case same message is shown in several tabs. Remove message from
            // other tabs as well.
            $('[data-messageid= ' + id + ']').remove();
            try {
                dismissed = localStorage.getItem('dismissedMessages') || '';
                localStorage.setItem('dismissedMessages', dismissed + id + '.');
            } catch (e) {
                // Do nothing
            }
        }

        return false;
    }

    /**
     * Used for both system messages and custom messages. A system message is a
     * message created through the doc editor or through the admin tool. Custom
     * messages are those messages displayed by calling this method somewhere in
     * the code.
     *
     * The message object must have a field .message, a string that contains
     * the message. This string is _NOT_ HTML-escaped! Make sure it is before
     * you call dialog.showNewCallout.
     *
     * The message object can have a .type, this is used mostly for styling.
     * Should be one of {error,info,success}.
     *
     * The message object can have an id. If it does, the user will be able to
     * hide the message (given that 'closeable' is true).
     * If a user has hidden a message with a given ID, a message with the
     * same ID will not be shown again. Make sure IDs are unique.
     *
     * If the message should be displayed in a specific context you can either
     * specify the target, or use the isPageSpecific option. If the
     * isPageSpecific option is true, this function will try to find the target
     * closest to the main content on the page.
     *
     * @param opts.message {String} Required
     * @param opts.type {String} Optional, default info
     * @param opts.id {int} Optional
     * @param opts.closeable {Boolean} Optional, default true
     * @param opts.target {String} Optional
     * @param opts.login {Boolean} Optional
     * @param opts.isSystemMessage {Boolean} Optional
     * @param opts.isPageSpecific {Boolean} Optional
     */
    function showNewCallout(opts) {
        if (!opts || !opts.message || typeof opts.message !== 'string') {
            return;
        }

        const type = opts.type || 'info';
        const callouts = $('.tmdl-callout-' + type);
        const closeable =
            typeof opts.closeable === 'boolean' ? opts.closeable : true;

        // Find out where the message should be placed based on the options
        const $target = _getTarget(opts);

        // If this is a dismissed message (user have clicked 'Close') do not show the message again.
        let dismissed;
        try {
            dismissed = localStorage.getItem('dismissedMessages');
        } catch (e) {
            dismissed = '';
        }
        if (opts.id && dismissed && dismissed.indexOf(opts.id + '.') >= 0) {
            return;
        }

        //There is no point in displaying the exact same messages more than once
        if (callouts.length > 0) {
            $.each(callouts, function (i, callout) {
                if (
                    $(callout).find('.tmdl-callout__content-message').text() ===
                    opts.message
                ) {
                    $(callout).remove();
                }
            });
        }

        const message = _createMessage(opts);

        $target.prepend(message.join(''));

        if (opts.id && closeable) {
            $('.tlx-callout__button')
                .off('click', clickHandler)
                .on('click', clickHandler);
        }
    }

    function hideCallout(options) {
        if (!options) {
            return;
        }

        const $target = _getTarget(options);
        $target
            .closest('[data-messageid=' + options.id + ']')
            .closest('.tmdl-callout')
            .remove();

        // In case same message is shown in several tabs. Remove message from
        // other tabs as well.
        $('[data-messageid= ' + options.id + ']').remove();
        try {
            localStorage.setItem(
                'dismissedMessages',
                localStorage.getItem('dismissedMessages') ||
                    '' + options.id + '.'
            );
        } catch (e) {
            // Do nothing
        }
    }

    function _getTarget(options) {
        let $target;

        if (options.login) {
            $target = $('.innerBox form');
        } else if (options.isSystemMessage && !options.isPageSpecific) {
            $target = $('.topMessages .systemMessages');
        } else if (options.target) {
            $target = $(options.target);
        } else if (options.isPageSpecific) {
            $target = _getPageSpecificTarget();
        } else {
            $target = $('.topMessages .customMessages');
        }

        return $target;
    }

    //Tries to find the closest target to the main content on the page, preferably the form. If the page does not
    //contain any form we look for a contentTable. If that does not exist either we simply use the wrapperDiv.
    function _getPageSpecificTarget() {
        let $target;
        let traversingMethod = 'find'; //we have to use different method based on target
        let insertContentMethod = 'prepend'; //same goes for here
        const $wrapperDiv = $('#wrapperDiv');

        if (
            $wrapperDiv.find('form[id*="PeriodForm"]:visible:first').length > 0
        ) {
            $target = $wrapperDiv.find('form[id*="PeriodForm"]:visible:first');
            //Several pages has multiple forms in the view. In some cases, the word 'DataForm' in the ID is the only thing
            //separating them from all the other forms (e.g listCustomers). We therefor have to look for these first.
        } else if (
            $wrapperDiv.find('form[id*="DataForm"]:visible:first').length > 0
        ) {
            $target = $wrapperDiv.find('form[id*="DataForm"]:visible:first');

            //Most pages has its main content inside a form with a name attribute containing the word 'Form'.
            //We do not want to target the period form or the search form though.
        } else if (
            $wrapperDiv.find(
                'form:not(#PeriodForm):not(#SearchForm)[name*="Form"]:visible:first'
            ).length > 0
        ) {
            $target = $wrapperDiv.find(
                'form:not(#PeriodForm):not(#SearchForm)[name*="Form"]:visible:first'
            );

            //Some pages does not have a form (?!). We therefor look for a contentTable
        } else if (
            $wrapperDiv.find('table.contentTable:visible:first').length > 0
        ) {
            $target = $wrapperDiv.find('table.contentTable:visible:first');

            //since the target in this case is a table, we must use the method before to insert the content
            //(or else the message will be displayed inside the table).
            insertContentMethod = 'before';

            //since we are using the insert method before in this case, we also have to use the traversing method
            //prev when searching for the pageSpecificMessages container (see further down)
            traversingMethod = 'prev';

            //Fallback
        } else {
            $target = $wrapperDiv;
        }

        //Creates a container for all the page specific messages for the sake of DOM tidiness and/or easier styling
        if ($target.find('.pageSpecificMessages').length === 0) {
            $target[insertContentMethod]('<div class="pageSpecificMessages">');
        }

        $target = $target[traversingMethod]('.pageSpecificMessages');

        return $target;
    }

    function _createMessage(options) {
        const message = [];
        const type = options.type || 'info';
        const icon = {
            // Type/icon mapper. Most icons happens to have same name as type…
            info: 'info',
            error: 'error_outline_outlined',
            success: undefined, // Comes from login messages, looks best without an icon?
            warning: 'warning_amber_outlined',
            confirmation: 'check_circle',
        }[type];

        const closeable =
            typeof options.closeable === 'boolean' ? options.closeable : true;

        message.push('<div class="txr-message txr-message--' + type);

        if (options.isSystemMessage) {
            message.push(' callout--system-message');

            if (options.isPageSpecific) {
                message.push(' callout--page-specific');
            }
        }

        if (options.id) {
            message.push('" data-messageId="' + options.id + '"');
        }

        message.push('">');

        if (icon) {
            message.push(
                '<span class="icon material-icons txr-message-icon">' +
                    icon +
                    '</span>'
            );
        }

        message.push('<span class="txr-message-content">');
        message.push('<span class="txr-message-text">');
        message.push(options.message);
        message.push('</span>');
        message.push('</span>');

        if (options.id && closeable) {
            message.push(
                '<button class="mdl-button mdl-button--icon tlx-callout__button">'
            );
            message.push('<i class="material-icons">close</i>');
            message.push('</button>');
        }

        message.push('</div>');
        message.push('</div>');

        return message;
    }

    // Removes the CalloutTag callout message, but only if no callout_close
    // event handler doesn't call e.preventDefault().
    // If you want sophisticated ways of hanlding close, you can do this in
    // an event handler listening on 'callout_close' and, if you don't want
    // the message to close, you can prevent the event (just like regular DOM
    // API).
    $(document).on('click', '.js-txr-message-close', function () {
        const event = new Event('callout_close');
        const callout = $(this).closest('.js-txr-message-tag')[0];
        const cancelled = !callout.dispatchEvent(event);
        if (!cancelled) {
            callout.remove();
        }
    });

    return {
        showNewCallout: showNewCallout,
        hideCallout: hideCallout,
    };
})();
