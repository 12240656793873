import * as React from 'react';
import { useDeviceType } from '@Component/Responsive/useDeviceType';

type ResponsiveProps = {
    mobile?: boolean;
    tablet?: boolean;
    desktop?: boolean;
};

export function Responsive(props: React.PropsWithChildren<ResponsiveProps>) {
    const deviceType = useDeviceType();
    if (deviceType === 'mobile') {
        // Type assertion to work around a bug in the return type for React.FunctionComponent
        return props.mobile ? (props.children as React.ReactElement) : null;
    }
    if (deviceType === 'tablet') {
        // Type assertion to work around a bug in the return type for React.FunctionComponent
        return props.tablet ? (props.children as React.ReactElement) : null;
    }
    // Type assertion to work around a bug in the return type for React.FunctionComponent
    return props.desktop ? (props.children as React.ReactElement) : null;
}
