import {
    Button,
    Input,
    TableLayout,
    TableLayoutContent,
    TableLayoutTopbar,
    Tooltip,
    TooltipContent,
    TooltipTrigger,
} from '@tlx/atlas';
import React, { useRef, useState } from 'react';
import { ProductLineDTO } from '@Page/LogisticsV2/pages/Stocktaking/utils';
import { AutoSaveStatusType } from '@Page/LogisticsV2/utils/enums';
import { doScanLine } from '@Page/LogisticsV2/pages/Stocktaking/details2/StocktakingDetailsActions';
import { getValidationMessagesFromResponse } from '@Page/LogisticsV2/utils/functions';
import { StocktakingDetailsMobileHeader } from '@Page/LogisticsV2/pages/Stocktaking/details2/mobile/StocktakingDetailsMobileHeaderComponents';
import { StocktakingDetailsMobileBody } from '@Page/LogisticsV2/pages/Stocktaking/details2/mobile/StocktakingDetailsMobileRowComponents';
import { useTableColumnsSetting } from '@tlx/astro-shared';
import { getStocktakingDetailsDisplayOptionsName } from '@Page/LogisticsV2/pages/Stocktaking/details2/StocktakingDetailsDisplayOptions';
import { getDefaultColumnsInStocktakingDetails } from '@Page/LogisticsV2/pages/Stocktaking/details2/utils';
import { EmptyState } from '@Page/LogisticsV2/components/EmptyState/EmptyState';

export function StocktakingDetailsMobileBarcodeTable({
    onChange,
}: {
    onChange: (status: AutoSaveStatusType) => void;
}) {
    const [scannedProductLines, setScannedProductLines] = useState<
        ProductLineDTO[]
    >([]);
    const [showTooltip, setShowTooltip] = useState(false);
    const prevValue = useRef('');
    const [scanValue, setScanValue] = useState('');
    const inputRef = useRef<HTMLInputElement>(null);
    const [editLocation, setEditLocation] = useState(false);
    const enabledColumnsForStocktakingDetails = useTableColumnsSetting(
        getStocktakingDetailsDisplayOptionsName(),
        getDefaultColumnsInStocktakingDetails()
    );
    async function handleScanProductLine(ean: string) {
        onChange(AutoSaveStatusType.PENDING);
        const { data, error } = await doScanLine(ean);
        if (error) {
            onChange(AutoSaveStatusType.ERROR);
            getValidationMessagesFromResponse(
                error,
                getMessage('validation_cannot_save_count')
            );
        } else if (data) {
            onChange(AutoSaveStatusType.SAVED);
            const responseData = await data.json();
            setScannedProductLines(responseData.values);
        }
    }

    async function handleOnChange(status: AutoSaveStatusType) {
        onChange(status);
        await handleScanProductLine(scanValue);
    }
    return (
        <TableLayout>
            <TableLayoutTopbar className="atl-m-16 atl-gap-4 goldshark-stocktaking-details-mobile-barcode-input">
                <Tooltip
                    open={showTooltip}
                    onOpenChange={setShowTooltip}
                    placement={'right'}
                >
                    <TooltipTrigger>
                        <Input
                            type="search"
                            ref={inputRef}
                            data-testid={'mobile-scan-barcode-input'}
                            value={scanValue}
                            autoFocus
                            placeholder={getMessage('text_scan')}
                            onChange={(event) => {
                                setShowTooltip(false);
                                if (scanValue && prevValue) {
                                    const currentValue =
                                        event.target.value.substring(
                                            prevValue.current.length,
                                            event.target.value.length
                                        );
                                    prevValue.current = '';
                                    setScanValue(currentValue);
                                } else {
                                    setScanValue(event.target.value);
                                }
                            }}
                            onKeyDown={async (event) => {
                                if (event.key === 'Enter') {
                                    await handleScanProductLine(
                                        event.currentTarget.value
                                    );
                                    prevValue.current =
                                        event.currentTarget.value;
                                }
                            }}
                        />
                    </TooltipTrigger>
                    <TooltipContent>
                        {getMessage('text_click_to_start_scanning')}
                    </TooltipContent>
                </Tooltip>
            </TableLayoutTopbar>
            <TableLayoutContent
                style={{
                    maxHeight: 'calc(100vh - 500px)',
                }}
            >
                {scannedProductLines.length > 0 &&
                    scannedProductLines.map((line) => (
                        <table className="atl-table" key={line.id}>
                            <StocktakingDetailsMobileHeader
                                line={line}
                                onChange={handleOnChange}
                                onEditLocation={setEditLocation}
                            />
                            <StocktakingDetailsMobileBody
                                line={line}
                                editLocation={editLocation}
                                columns={enabledColumnsForStocktakingDetails}
                                onChange={handleOnChange}
                            />
                        </table>
                    ))}
                {scannedProductLines.length === 0 && (
                    <table className="atl-table">
                        <tbody className="atl-body">
                            <tr>
                                <td colSpan={3}>
                                    <EmptyState
                                        title={
                                            scanValue.length === 0
                                                ? 'Barcode scanner'
                                                : getMessage(
                                                      'text_no_products_found'
                                                  )
                                        }
                                        description={getMessage(
                                            'text_barcode_scanner_details'
                                        )}
                                        svgIcon={'barcodeScanner'}
                                        iconSize={100}
                                    >
                                        <div>
                                            <Button
                                                data-testid={
                                                    'show-me-how-to-scan-button'
                                                }
                                                onClick={() => {
                                                    inputRef.current?.focus();
                                                    setShowTooltip(true);
                                                }}
                                                variant="secondary"
                                            >
                                                {getMessage('text_show_how')}
                                            </Button>
                                        </div>
                                    </EmptyState>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                )}
            </TableLayoutContent>
        </TableLayout>
    );
}
