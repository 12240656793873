import * as React from 'react';
import { clsx } from 'clsx';

import { Portal } from '@tlx/atlas';

import './FloatingSurfaceBackdrop.css';

export interface FloatingSurfaceBackdropProps
    extends React.HTMLAttributes<HTMLDivElement> {
    readonly 'data-testid'?: string;
    readonly backdrop?: boolean;
    readonly passive?: boolean;
}

/**
 * Companion component for FloatingSurface for convenient outside click handling.
 */
export const FloatingSurfaceBackdrop: React.FC<FloatingSurfaceBackdropProps> =
    ({ children, className, onClick, backdrop, passive, ...divProps }) => {
        const rootRef = React.useRef<HTMLDivElement | null>(null);

        const onClickHandler: React.MouseEventHandler<HTMLDivElement> =
            React.useCallback(
                (event) => {
                    if (event.target === rootRef.current && onClick) {
                        event.preventDefault();
                        onClick(event);
                    }
                },
                [rootRef, onClick]
            );

        return (
            <Portal>
                <div
                    ref={rootRef}
                    className={clsx(
                        'tlx-ds-floating-surface__root',
                        className,
                        {
                            'tlx-ds-floating-surface__root--with-backdrop':
                                backdrop,
                            'tlx-ds-floating-surface__root--passive': passive,
                        }
                    )}
                    onClick={passive === true ? undefined : onClickHandler}
                    {...divProps}
                >
                    {children}
                </div>
            </Portal>
        );
    };
