import React, { useState } from 'react';
import {
    Banner,
    BannerCloseButton,
    BannerContent,
    BannerDescription,
    BannerHeader,
    Button,
    InfoIcon,
    Input,
    Label,
    Modal,
    Tooltip,
    TooltipButton,
    TooltipContent,
    TooltipTrigger,
} from '@tlx/atlas';
import './InvoicingModeSwitcher.css';
import { isChanged } from '../../../../js/modules/change';
import {
    useFetchModules,
    useViewOptions,
} from '@Page/InvoicingModeSwitcher/dataFetching';
import { usePilotFeatures } from '@Page/VatReturns2/hooks/dataFetching/usePilotFeatures';
import { CustomerCategoryEnum } from '@General/types/customerCategory.enum';
import { Markdown } from '@Component/Markdown';

export interface InvoicingModeSwitcherProps {
    saveAndChangeMode: () => void;
    changeMode: () => void;
}
export function InvoicingModeSwitcher({
    saveAndChangeMode,
    changeMode,
}: InvoicingModeSwitcherProps) {
    const [showSaveWarning, setShowSaveWarning] = useState(false);
    const viewOptions = useViewOptions();
    const isTestAccount =
        window.segmentationData.tripletexCustomerCategoryId3 ===
        CustomerCategoryEnum.TRIPLETEX_TEST;
    const loginEmployeeEmail = window.loginEmployeeEmail;
    const [showTestAccountBanner, setShowTestAccountBanner] =
        useState(isTestAccount);
    const isEffortlessPilot = usePilotFeature(
        'KillBillEffortlessInvoicing'
    ).hasFeature;
    const { data: modules } = useFetchModules();
    const contextId = window.contextId;
    if (isEffortlessPilot === undefined) {
        return null;
    }

    return (
        <div>
            {isEffortlessPilot && (
                <div
                    style={{ marginTop: '-24px' }}
                    className="atl-w-full atl-mb-16 atl-flex atl-justify-end"
                >
                    {!modules?.moduleLogistics && (
                        <Button
                            variant="secondary"
                            data-testid={'effortless-invoicing-button'}
                            onClick={() => {
                                const form: any =
                                    document.getElementsByClassName(
                                        'js-createOrderForm'
                                    )[0];
                                const hasManualTriggeredChange =
                                    form.dataset.hasChanged;
                                if (isChanged() || hasManualTriggeredChange) {
                                    setShowSaveWarning(true);
                                } else {
                                    changeMode();
                                }
                            }}
                        >
                            {getMessage('text_simple_invoicing')}
                        </Button>
                    )}
                </div>
            )}
            {showTestAccountBanner && (
                <Banner variant="info" className="atl-mb-12">
                    <BannerCloseButton
                        onClick={() => setShowTestAccountBanner(false)}
                    />
                    <BannerContent>
                        <BannerHeader>
                            {getMessage('text_test_account_limitation_header')}
                        </BannerHeader>
                        <BannerDescription>
                            <Markdown
                                textKey="text_test_account_limitation_message"
                                linksTargetBlank={true}
                                textKeyArgs={[loginEmployeeEmail, contextId]}
                            />
                        </BannerDescription>
                    </BannerContent>
                </Banner>
            )}
            {!isEffortlessPilot && !modules?.moduleLogistics && (
                <div className="atl-flex atl-justify-between">
                    <div
                        className="atl-flex atl-items-center atl-pb-8"
                        data-testid="toggle-simple-invoicing"
                    >
                        <div className="atl-flex atl-items-center atl-gap-2">
                            <Input
                                type="toggle"
                                data-testid="toggle-simple-invoicing"
                                className="app-toggle atl-m-8"
                                checked={viewOptions?.showAsSimple}
                                onChange={() => {
                                    const form: any =
                                        document.getElementsByClassName(
                                            'js-createOrderForm'
                                        )[0];
                                    const hasManualTriggeredChange =
                                        form.dataset.hasChanged;
                                    if (
                                        isChanged() ||
                                        hasManualTriggeredChange
                                    ) {
                                        setShowSaveWarning(true);
                                    } else {
                                        changeMode();
                                    }
                                }}
                            />
                            <Label>{getMessage('text_simple_invoicing')}</Label>
                            <Tooltip placement={'bottom'}>
                                <TooltipTrigger>
                                    <TooltipButton
                                        data-testid="reverse-multiple-tooltip-button"
                                        className={'atl-p-0 atl-bg-transparent'}
                                    >
                                        <InfoIcon
                                            size={'medium'}
                                            className={'atl-text-blue-100 '}
                                        />
                                    </TooltipButton>
                                </TooltipTrigger>
                                <TooltipContent>
                                    {getMessage('text_simple_invoicing_info')}
                                </TooltipContent>
                            </Tooltip>
                        </div>
                    </div>
                </div>
            )}
            <Modal
                className="kb-invoicing-mode-switcher__modal"
                open={showSaveWarning}
                onClose={() => setShowSaveWarning(false)}
            >
                <div>
                    <div>
                        <p className="atl-text-xl atl-mt-auto">
                            {getMessage('text_change_invoice_mode')}
                        </p>
                    </div>
                    <p>{getMessage('text_change_invoice_mode_warning')}</p>
                    <div>
                        <Button
                            data-testid="save-order-button"
                            className="atl-mr-16"
                            onClick={() => {
                                setShowSaveWarning(false);
                                saveAndChangeMode();
                            }}
                        >
                            {getMessage('text_save_and_change')}
                        </Button>
                        <Button
                            variant="secondary"
                            onClick={() => setShowSaveWarning(false)}
                            data-testid="cancel-save-order-button"
                        >
                            {getMessage('button_cancel')}
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

function usePilotFeature(pilotFeature: string) {
    const { pilotFeatures, isLoading, error } = usePilotFeatures([
        pilotFeature,
    ]);
    return {
        hasFeature: pilotFeatures?.includes(pilotFeature) ?? undefined,
        isLoading,
        error,
    };
}
