import { OrderOrProject } from '@Page/ProjectInvoicingDialog/types/InvoicingDialog.type';
import { format } from '../../../../../../js/modules/format';
import classNames from 'classnames';
import { LoadingSpinner } from '@Component/Loading';
import { Icon } from '@Component/Icon';
import { SendTypes } from '@Page/ProjectInvoicingDialog/component/InvoicingForm/SendMethod';
import { InvoicingStatus } from '@Page/ProjectInvoicingDialog/component/BatchInvoicing/BatchInvoicing';
import { getSendMethodName } from '@Page/ProjectInvoicingDialog/utils/getSendMethodName';
import { Button } from '@Component/Button';
import { Tooltip } from '@Component/AntsComponents/ToolTip/components/Tooltip';
import { SkeletonRectangle } from '@Page/ProjectInvoicingDialog/component/SkeletonRectangle/SkeletonRectangle';
import './InvoicingListItem.css';
import * as React from 'react';
import { useContext } from 'react';
import { InvoicingModalContext } from '@Page/ProjectInvoicingDialog/component/InvoicingModal';

export enum OrderOrProjectInvoicingStatus {
    'SENDING',
    'INFO',
    'ERROR',
    'SUCCESS',
}

export type OrderOrProjectInvoicingStateMessage = string | JSX.Element;

export interface BatchInvoiceOrderOrProjectState {
    orderOrProject: OrderOrProject;
    messages: string[];
}

const getInvoiceSendMethod = (orderOrProject: OrderOrProject): string => {
    const customerInvoiceSendMethod = orderOrProject.customer.invoiceSendMethod;

    if (
        customerInvoiceSendMethod === SendTypes.EMAIL &&
        !orderOrProject.receiverEmail
    ) {
        return getMessage('text_send_type_later');
    }

    return getSendMethodName(customerInvoiceSendMethod);
};

function getSentSuccessIcon(orderOrProject: OrderOrProject) {
    const sendMethod = orderOrProject.customer.invoiceSendMethod;
    const isInvoiceToBeHandledManually =
        sendMethod === 'MANUAL' ||
        (sendMethod === 'EMAIL' && !orderOrProject.receiverEmail);
    if (isInvoiceToBeHandledManually) {
        return <Icon icon="text_snippet" className="atl-text-green-80" />;
    }

    return <Icon icon="check" className="atl-text-green-80" />;
}

export function InvoicingListItemSkeleton({
    className,
}: {
    className?: string;
}) {
    return (
        <li
            className={classNames('atl-px-32', 'atl-py-8', {
                [`${className}`]: className,
            })}
        >
            <SkeletonRectangle height="48px" />
        </li>
    );
}

function EmailList({ emails }: { emails: string }) {
    const listOfEmails = emails
        .split(/\s*[,;]\s*/)
        .filter((email) => email.length > 5);
    const emailsCount = listOfEmails.length;
    const formattedEmails = listOfEmails.join('\n');

    return (
        <Tooltip className="atl-flex" content={formattedEmails} direction="top">
            <span className="atl-truncate">{emails}</span>

            {emailsCount > 1 && (
                <div className="atl-bg-turquoise-10 atl-text-turquoise-120 atl-whitespace-nowrap atl-text-s atl-px-4 atl-py-1 atl-rounded atl-ml-2">
                    {emailsCount}
                </div>
            )}
        </Tooltip>
    );
}

export function InvoicingListItem({
    className,
    ordersAndProjectsWithMessages,
    variant,
    status,
    statusMessages,
    onRemoveItem,
}: {
    className?: string;
    ordersAndProjectsWithMessages: BatchInvoiceOrderOrProjectState[];
    variant: undefined | 'success' | 'info' | 'error';
    status: InvoicingStatus;
    statusMessages: Array<string | JSX.Element>;
    onRemoveItem: () => void;
}) {
    const firstOrderOrProjectInList =
        ordersAndProjectsWithMessages[0].orderOrProject;

    const amountCurrencyExclVat = ordersAndProjectsWithMessages.reduce(
        (acc, orderOrProjectWithMessages) => {
            return (
                acc +
                (orderOrProjectWithMessages.orderOrProject.preliminaryInvoice
                    ?.amountExcludingVatCurrency ?? 0)
            );
        },
        0
    );

    const { trackingIdContext } = useContext(InvoicingModalContext);

    let icon;
    if (status === InvoicingStatus.SENDING) {
        icon = <LoadingSpinner />;
    } else if (status === InvoicingStatus.SENT_INFO) {
        icon = <Icon icon="check" className="atl-text-blue-80" />;
    } else if (status === InvoicingStatus.SENT_SUCCESS) {
        icon = getSentSuccessIcon(
            ordersAndProjectsWithMessages[0].orderOrProject
        );
    } else if (status === InvoicingStatus.SENT_ERROR) {
        icon = <Icon icon="error_outline" className="atl-text-red-100" />;
    } else {
        icon = (
            <Button
                onClick={onRemoveItem}
                className="kb-invoicing-list-item__clear-button"
                type="button"
                testId="kb-invoicing-list-item__clear-button"
                trackingId={
                    'Remove invoice row (batch invoicing) (invoicing React modal)' +
                    trackingIdContext
                }
            >
                <Icon
                    icon="clear"
                    className="kb-invoicing-list__clear-button__icon"
                />
            </Button>
        );
    }

    return (
        <li
            className={classNames(
                'kb-invoicing-list-item',
                'atl-pt-2',
                'atl-pb-8',
                {
                    [`${className}`]: className,
                    'kb-invoicing-list-item--error': variant === 'error',
                    'kb-invoicing-list-item--info': variant === 'info',
                }
            )}
        >
            <div className={classNames('kb-invoicing-list-item--status')}>
                <div className="kb-invoicing-list-item--grid kb-invoicing-list-item__header">
                    <span className="atl-text-base atl-font-normal atl-text-grey-120 atl-truncate">
                        {firstOrderOrProjectInList.customer.name}
                    </span>

                    <span className="atl-whitespace-nowrap">
                        {format.amount2(amountCurrencyExclVat)}{' '}
                        {firstOrderOrProjectInList.currency.code}
                    </span>

                    <span>
                        {getInvoiceSendMethod(firstOrderOrProjectInList)}
                    </span>

                    {icon}
                </div>

                <ul className="atl-p-0 kb-invoicing-list-item__order-list">
                    {ordersAndProjectsWithMessages.map(
                        ({ orderOrProject, messages }, index) => {
                            const orderOrProjectAmountExclVat = format.amount2(
                                orderOrProject.preliminaryInvoice
                                    ?.amountExcludingVatCurrency ?? 0
                            );

                            const tooltipMessages = (
                                <div>
                                    {messages.map((message, index) => (
                                        <div key={index}>{message}</div>
                                    ))}
                                </div>
                            );

                            return (
                                <li
                                    className="kb-invoicing-list-item__order-list__order"
                                    key={orderOrProject.id}
                                >
                                    <div className="atl-flex">
                                        <span
                                            className={
                                                messages.length > 0
                                                    ? 'kb-invoicing-list_item__order-ml-minus28'
                                                    : ''
                                            }
                                        >
                                            {messages.length > 0 && (
                                                <Tooltip
                                                    className="atl-flex"
                                                    content={tooltipMessages}
                                                    direction="right"
                                                >
                                                    <Icon
                                                        className={classNames(
                                                            ' atl-mr-4',
                                                            {
                                                                'atl-text-red-80':
                                                                    variant ===
                                                                    'error',
                                                                'atl-text-blue-80':
                                                                    variant ===
                                                                    'info',
                                                            }
                                                        )}
                                                        icon="error_outline"
                                                    />
                                                </Tooltip>
                                            )}
                                        </span>

                                        <div className="kb-invoicing-list-item--grid atl-text-grey-80">
                                            <span>
                                                {orderOrProject.isProject
                                                    ? getMessage('text_project')
                                                    : getMessage('text_order')}
                                                {': '}
                                                <a
                                                    className="atl-text-blue-100"
                                                    href={addContextId(
                                                        `/execute/projectMenu?projectId=${orderOrProject.id}`
                                                    )}
                                                    target="_blank"
                                                    data-trackingid={getProjectOrOrderLinkTrackingId(
                                                        orderOrProject.isProject,
                                                        trackingIdContext
                                                    )}
                                                >
                                                    {orderOrProject.number}
                                                    {orderOrProject.isProject &&
                                                        ' ' +
                                                            orderOrProject.name}
                                                </a>
                                            </span>
                                            {ordersAndProjectsWithMessages.length >
                                                1 && (
                                                <span>
                                                    {
                                                        orderOrProjectAmountExclVat
                                                    }{' '}
                                                    {
                                                        orderOrProject.currency
                                                            .code
                                                    }
                                                </span>
                                            )}

                                            {ordersAndProjectsWithMessages.length ===
                                                1 && (
                                                <span /> // render empty span for grid to fit
                                            )}

                                            {index == 0 &&
                                                orderOrProject.customer
                                                    .invoiceSendMethod ==
                                                    SendTypes.EMAIL &&
                                                orderOrProject.receiverEmail && (
                                                    <EmailList
                                                        emails={
                                                            orderOrProject.receiverEmail
                                                        }
                                                    />
                                                )}
                                        </div>
                                    </div>
                                </li>
                            );
                        }
                    )}
                </ul>

                <div>
                    {!!statusMessages &&
                        statusMessages.map((message, index) => (
                            <span key={index}>{message}</span>
                        ))}
                </div>
            </div>
        </li>
    );
}

function getProjectOrOrderLinkTrackingId(
    isProject: boolean,
    trackingIdContext: string
) {
    let projectOrOrderLinkTrackingId = '';
    if (!window.location.href.includes('story')) {
        projectOrOrderLinkTrackingId =
            (isProject
                ? getLocaleMessage('en_GB', 'text_project')
                : getLocaleMessage('en_GB', 'text_order')) +
            ' link (invoice row) (batch invoicing) (invoicing React modal)' +
            trackingIdContext;
    }
    return projectOrOrderLinkTrackingId;
}
