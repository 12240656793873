import jQuery from 'jquery';

(function ($) {
    'use strict';

    /**
     * Please see our wiki for more information about this widget.
     *
     * @author Lars-Erik Bruce
     * @date 2013.11.07
     */
    $.widget('tlx.highlight', {
        options: {
            /* This is the group who is going to be highlighted. The CSS selector
             * match the first parent of clicked element. */
            highlightGroup: '.highlightGroup',
            /* Trigging click on this element within highlighted group should trigger action., */
            highlightActionSelector: '.highlightAction',
            /* This is the action that should happen on highlightning. */
            highlightAction: function (widget, $group, event) {
                // Do not trigger the link twice.
                if ($(event.target).is(widget.options.highlightActionSelector))
                    return;
                // table-row--grouped is often used to group e.g postings tied to a voucher in tables - if the posting is clicked
                // the voucher should still active the preview frame
                const href = $group.is('.table-row--grouped')
                    ? $group
                          .prevAll('tr:not(.table-row--grouped)')
                          .first()
                          .find(widget.options.highlightActionSelector)
                          .trigger('click')
                          .attr('href')
                    : $group
                          .find(widget.options.highlightActionSelector)
                          .trigger('click')
                          .attr('href');
                if (href) window.location.href = href;
            },
            /* What happens if user press the space-key on keyboard? */
            spaceAction: function (widget, $group) {
                if ($group)
                    // Can be null when the list is empty
                    $group.find('[name$=\\.selected]').trigger('click');
            },
            /* Defines what elements should ignore click event. */
            ignoreClick: 'a, input, button, label',
            /* If true, only one line is visibly highlighted. */
            onlyOne: true,
            /* Listen on keyboard keys? */
            bindKeys: true,
            /* Should we automatically highlight first line? */
            highlightFirst: false,
            /* The style class which indicates that the group is highlighted. */
            indicateHighlighted: 'highlighted',
            spacePreventDefault: true,
        },

        // Only used when only one can be highlighted.
        currentHighlighted: null,

        // This is fired whenever the element is clicked
        onclick: function (event) {
            const widget = event.currentTarget.highlight,
                options = widget.options,
                $target = $(event.target);
            if (
                $target.closest(options.ignoreClick).length > 0 &&
                $target.closest(options.highlightActionSelector).length === 0
            ) {
                return;
            }

            const $group = $(event.target).closest(options.highlightGroup);

            // Remove highlight from old if only one is allowed
            if (
                options.onlyOne &&
                document.currentHighlighter.currentHighlighted
            ) {
                document.currentHighlighter.currentHighlighted
                    .nextUntil("tr:not('.table-row--grouped')")
                    .removeClass(options.indicateHighlighted);
                document.currentHighlighter.currentHighlighted.removeClass(
                    options.indicateHighlighted
                );
            }

            widget.currentHighlighted = $group;

            // table-row--grouped is often used to group e.g postings tied to a voucher in tables - if the posting is clicked
            // it does not need to be highlighted, the voucher row should be highlighted. Posting should not be highlighted
            // if it is not tied to a voucher without attachment
            if (!$group.hasClass('table-row--grouped')) {
                $group.addClass(options.indicateHighlighted);
                $group
                    .nextUntil("tr:not('.table-row--grouped')")
                    .addClass(options.indicateHighlighted);
            }

            options.highlightAction(widget, $group, event);

            // On keydown we need to know which one of the highlighters is the
            // active one, if there are several on same page.
            document.currentHighlighter = widget;
        },

        onkeydown: function (event) {
            const target = event.originalEvent.composedPath()[0];

            if ($(target).is('textarea, input')) {
                return;
            }

            const widget = document.currentHighlighter,
                up = event.keyCode === 38;
            if (
                !(event.keyCode === 38 || event.keyCode === 40) ||
                !document.currentHighlighter ||
                event.ctrlKey ||
                event.metaKey ||
                event.shiftKey ||
                event.altKey
            ) {
                if (event.keyCode === 32) {
                    widget.options.spaceAction(
                        widget,
                        widget.currentHighlighted,
                        event
                    );
                    if (widget.options.spacePreventDefault)
                        event.preventDefault();
                }
                return;
            }

            let newGroup = undefined;
            if (widget.currentHighlighted) {
                newGroup = up
                    ? widget.currentHighlighted.prev()
                    : widget.currentHighlighted.next();
            }
            if (newGroup && newGroup.is(widget.options.highlightGroup)) {
                const element = newGroup.closest('#scrollContainer')[0];
                newGroup.trigger('click').scrollIntoView({
                    offsetTop: 100,
                    offsetBottom: 50,
                    element: element,
                });
                event.preventDefault();
            }
        },

        _create: function () {
            this.element.on('click', this.onclick);
            // We only need one key listener
            if (this.options.bindKeys && !document.currentHighlighter)
                $(document).on('keydown', this.onkeydown);
            this.element.get(0).highlight = this;
            document.currentHighlighter = this;
            if (this.options.highlightFirst)
                $(this.options.highlightGroup).first().trigger('click');
        },

        _destroy: function () {
            this.element.off('click', this.onclick);
            $(document).off('keydown', this.onkeydown);
            this.element.get(0).highlight = undefined;
            document.currentHighlighter = undefined;
        },
    });
})(jQuery);
