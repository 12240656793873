import React, { KeyboardEvent, useState } from 'react';
import { useSendUserFeedback } from '@Page/Automation2022/hooks/dataFetching/useSendUserFeedback';
import { UserFeedbackConfirmation } from '@Page/Automation2022/components/FeedbackButton/UserFeedbackConfirmation';
import { KycFeedbackInput } from '@Page/CompanyVerificationFlow/KycFeedbackBox/components/KycFeedbackInput';
import { KycFeedbackBoxActionButtons } from '@Page/CompanyVerificationFlow/KycFeedbackBox/components/KycFeedbackBoxActionButtons';
import { Team } from '@General/Team';

import './KycFeedbackBox.css';
import { ModalContent, ModalFooter, ModalTitle } from '@tlx/atlas';
import useLocalStorage from '../../../util/storage';
import { useResizeObserver } from '@Page/DynamicForms/component/feedback/useResizeObserver';

export function KycFeedbackBox({
    isCompanyVerified,
    closeModal,
}: {
    isCompanyVerified: boolean;
    closeModal: () => void;
}) {
    const [userWantsToGiveFeedback, setUserWantsToGiveFeedback] =
        useState(false);
    const [isFeedbackSent, setIsFeedbackSent] = useState(false);
    const { sendToBackend, sendToBackendError } = useSendUserFeedback();
    const [input, setInput] = useState('');
    const [contactRequestLocalStorage, setContactRequestLocalStorage] =
        useLocalStorage<boolean | undefined>('contact', undefined);
    const [contactRequest, setContactRequest] = useState(
        contactRequestLocalStorage
    );
    const { ref, size } = useResizeObserver();

    const resetFeedbackBox = () => {
        setIsFeedbackSent(true);
        setTimeout(() => {
            closeModal();
            setIsFeedbackSent(false);
        }, 2000);
    };

    const sendFeedback = async (input: string) => {
        setContactRequestLocalStorage(contactRequest ?? true);

        try {
            await sendToBackend({
                message: input,
                team: Team.KILLBILL,
                pageTitle: 'KYC',
                contactRequest: contactRequest ?? true,
            });
        } catch (error) {
            console.error(error);
        }
        return {
            error: { sendToBackendError },
        };
    };

    const handleKeyDown = (event: KeyboardEvent) => {
        if (
            (event.metaKey || event.ctrlKey) &&
            event.key == 'Enter' &&
            isSendButtonVisible
        ) {
            resetFeedbackBox();
            void sendFeedback(input);
        }
        if (event.key == 'Escape') {
            closeModal();
        }
    };

    const isSendButtonVisible =
        input.replace(/\s/g, '').length > 0 && userWantsToGiveFeedback;
    const letterCounter = input.split('').length + '/500';

    return (
        <>
            {isFeedbackSent ? (
                <div
                    className="atl-flex atl-justify-center atl-items-center"
                    style={{
                        width: 468,
                        marginLeft: '66px',
                        height: size?.height ? size.height + 178 : 0,
                    }}
                >
                    <UserFeedbackConfirmation />
                </div>
            ) : (
                <>
                    <ModalTitle>
                        <div className="atl-font-medium">
                            {getMessage(
                                isCompanyVerified
                                    ? 'text_successfully_registered'
                                    : 'text_secure_user_identification_ty'
                            )}
                        </div>
                    </ModalTitle>
                    <ModalContent>
                        <div
                            className="atl-grid atl-py-8 atl-gap-24"
                            style={{ width: '536px' }}
                            ref={ref}
                        >
                            <div className="atl-text-lg atl-text-grey-100">
                                {getMessage(
                                    isCompanyVerified
                                        ? 'text_kyc_company_registered'
                                        : 'text_kyc_identifying_yourself_info'
                                )}
                            </div>
                            {userWantsToGiveFeedback && (
                                <KycFeedbackInput
                                    handleKeyDown={handleKeyDown}
                                    setInput={setInput}
                                    letterCounter={letterCounter}
                                    contactRequest={setContactRequest}
                                    contactAnswer={contactRequest}
                                />
                            )}
                        </div>
                    </ModalContent>
                    <ModalFooter className="atl-pb-32 atl-pt-0">
                        <KycFeedbackBoxActionButtons
                            userWantsToGiveFeedback={userWantsToGiveFeedback}
                            setUserWantsToGiveFeedback={
                                setUserWantsToGiveFeedback
                            }
                            isSendButtonVisible={isSendButtonVisible}
                            sendFeedback={sendFeedback}
                            resetFeedbackBox={resetFeedbackBox}
                            closeModal={closeModal}
                            input={input}
                        />
                    </ModalFooter>
                </>
            )}
        </>
    );
}
