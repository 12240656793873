import { useState } from 'react';
import { useDiscountPolicyData } from '@Page/LogisticsV2/pages/Product discount/overview/useDiscountPolicyData';
import { CreateProductDiscountModal } from '@Page/LogisticsV2/pages/Product discount/modals/CreateDiscountModal';

export function ProductDiscountModalPage() {
    const [showModal, setShowModal] = useState(false);
    const { refresh } = useDiscountPolicyData();
    return (
        <form
            id="createProductDiscountForm"
            onSubmit={() => setShowModal(true)}
        >
            {showModal && (
                <CreateProductDiscountModal
                    onClose={() => setShowModal(false)}
                    onChange={async () => {
                        await refresh();
                        setShowModal(false);
                    }}
                />
            )}
        </form>
    );
}
