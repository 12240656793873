import { PageData, PageLoader } from '@Component/PageLoader';
import { renderComponent } from '@General/renderComponent';
import { LogisticsSettingsDataModule } from '@Page/Logistics/Module';
import { ProductSettings } from './ProductSettings';

const loader: React.FC = () => (
    <PageLoader<{}> loader={async () => page} adjustWrapperDiv />
);

export function renderProductSettingsPage(domId: string) {
    renderComponent(loader, domId);
}

export const page: PageData = {
    page: ProductSettings,
    modules: [
        LogisticsSettingsDataModule,
        {
            id: 'ProductSettingsPage',
        },
    ],
};
