import * as React from 'react';
import { PureComponent } from 'react';
import { renderComponent } from '@General/renderComponent';
import {
    CreateButtonComponent,
    InputOption,
    Option,
} from '@Component/DropDown/types';
import { JSPDropdownProvider } from '@Component/DropDown/JSPDropdown/JSPDropdownProvider';

import { ProviderDropdown } from '@Component/ProviderDropdown';
import { JSPDropdownFetcher } from '@Component/DropDown/JSPDropdown/JSPDropdownFetcher';
import { ImmutableProvider, Provider } from '@General/Provider';
import { TlxListItem } from '@Component/DropDown/DropDown';

import './JSPDropdown.scss';

import { Models } from '@Component/DropDown/JSPDropdown/loadModels';
import { AccountHelpDropdown } from '@Component/AccountHelp/AccountHelpDropdown';
import { AccountJSPDropdown } from '@Component/DropDown/JSPDropdown/CustomJSPDropdown/account/AccountJSPDropdown';

type Props<T> = {
    type: string | undefined;
    label: string;
    initial: InputOption<T>;
    defaults?: InputOption<T>[];
    input: HTMLInputElement;
    queryAttributes: Record<string, any>;
    scope: HTMLDivElement;
    required?: boolean;
    dontShowPlusButton?: boolean;
    isCombo?: boolean;
    maxLength?: number;
    clientFilter?: Function;
    autofocus?: boolean;
    resetBy?: string;
    dataTestId?: string;
    dataTrackingId?: string;
};

type JSPDropdownState = {
    selected: string;
    disabled: boolean;
    readonly: boolean;
    createNewButton?: CreateButtonComponent;
};

/**
 * Turning the Dropdown label into a link, if applicable.
 *
 * @param props
 * @constructor
 */
const LabelLink = <T extends unknown>(props: {
    text: string | null;
    type: string | undefined;
    id: string;
    fetcher?: JSPDropdownFetcher<T>;
    dropdownTrackingId?: string;
}) => {
    // For help checking if id is below 1, if NaN just set it as 0.
    const idN: number = parseInt(props.id, 10) || 0;

    // If label (props.text) is not provided don't render anything, even though we
    // could have provided links. Typically, the dropdown is located
    // within a table or somewhere else with a dense view.
    // props.type can be undefined as well, since we force typed and non-typed dropdowns
    // to use the same "Component" on render
    // Also if id is below 1 (or not a number), we know there are no objects that can be linked to.
    if (props.text === null || !props.type || idN < 1) {
        return <React.Fragment>{props.text || ''}</React.Fragment>;
    }

    const link = Models[props.type].link || props.fetcher?.createLink();

    if (!link) {
        return <React.Fragment>{props.text}</React.Fragment>;
    }

    // The link's trackingId is the dropdown's trackingId plus " (link)"
    let dataTrackingId = props.dropdownTrackingId;
    if (!dataTrackingId) {
        dataTrackingId = '';
    } else {
        const trackingIDParts = dataTrackingId.split(' (');
        trackingIDParts[0] =
            trackingIDParts[0] +
            ' (' +
            getLocaleMessage('en_GB', 'text_link').toLowerCase() +
            ')';
        dataTrackingId = trackingIDParts.join(' (');
    }

    return (
        <a
            target="_blank"
            rel="noopener noreferrer"
            href={link(props.id, window.contextId)}
            tabIndex={-1}
            data-trackingid={dataTrackingId}
        >
            {props.text}
        </a>
    );
};

class JSPDropdown<T> extends PureComponent<Props<T>, JSPDropdownState> {
    // This can be an AsyncProvider.
    provider: Provider<T>;
    fetcher?: JSPDropdownFetcher<T>;

    desktopListItem?: TlxListItem<T>;
    mobileListItem?: TlxListItem<T>;

    headers?: React.ComponentType;

    mutationObserver: MutationObserver;

    constructor(props: Props<T>) {
        super(props);

        this.state = {
            selected: this.props.input.value,
            disabled: this.props.input.disabled,
            readonly: this.props.input.readOnly,
        };

        this.onChange = this.onChange.bind(this);
        this.onCreateSuccess = this.onCreateSuccess.bind(this);
        this.onOpen = this.onOpen.bind(this);
        this.syncSelectedWithDomInput =
            this.syncSelectedWithDomInput.bind(this);

        if (props.type) {
            if (!Models[props.type]) {
                throw new Error(
                    `The type "${this.props.type}" is not yet implemented`
                );
            }

            /**
             * This is a JSPDropdownFetcher class. I could not find any type that would
             * fit here without red lines, so had to use 'any'…
             */
            const Fetcher: any = Models[props.type].fetcher;

            this.fetcher = new Fetcher(
                this.props.scope,
                this.props.queryAttributes,
                this.props.clientFilter
            );

            if (!this.fetcher) {
                throw new Error('Dropdown with type must have a fetcher.');
            }

            this.provider = new JSPDropdownProvider(
                this.fetcher,
                props.defaults,
                Models[props.type].searchFilterGenerator,
                this.props.initial,
                this.props.input.name
            );
            this.desktopListItem = this.fetcher.getDesktopListItem();
            this.mobileListItem = this.fetcher.getMobileListItem();
            this.headers = this.fetcher.getHeaders();
        } else if (props.defaults) {
            if (props.isCombo) {
                // This if-statement can be removed when we are done converting
                // and deleting all references to tlxSelect!
                if (props.defaults[0].value === '') {
                    throw new Error(
                        'You forgot to remove the empty option from the old tlxSelect tag!'
                    );
                }
                /**
                 * The first option in an "isCombo" dropdown is whatever the end-user has inserted.
                 * In case this value is submitted by user, we pretend at initialization that the
                 * current selected value is such a custom value.
                 */
                this.provider = new ImmutableProvider([
                    {
                        value: this.props.input.value,
                        displayName: this.props.input.value,
                    },
                    ...props.defaults,
                ]);
            } else {
                this.provider = new ImmutableProvider([...props.defaults]);
            }
        } else {
            throw new Error('Missing type or defaults.');
        }

        /**
         * When some code that is not in react changes this element, make sure to update state!
         *
         * The JSPDropdown acts both with old jQuery code and new React code...
         */
        const mutationCallback: MutationCallback = () => {
            const selected = this.props.input.value;
            const disabled = this.props.input.disabled;
            const readonly = this.props.input.readOnly;

            if (
                selected === this.state.selected &&
                disabled === this.state.disabled &&
                readonly === this.state.readonly
            ) {
                return;
            }

            // Make sure that, when isCombo is updated programmatically in the DOM, we update
            // ImmutableProvider to reflect this new value. This typically happens when auto-
            // formatting account numbers, etc.
            if (
                this.props.isCombo &&
                this.props.defaults &&
                selected !== this.state.selected
            ) {
                this.onChange({ value: selected, displayName: selected });
                // I am pretty sure that you have to mutate value, disabled and readOnly individually,
                // so we can just return now.
                return;
            }

            this.setState({
                selected,
                disabled,
                readonly,
            });
        };

        this.mutationObserver = new MutationObserver(mutationCallback);
        this.mutationObserver.observe(props.input, {
            attributes: true,
            subtree: false,
            childList: false,
        });

        // We add a listener to the provider to make sure that we pick up changes to the displayName
        // of the selected option whenever newly created entities are fetched from the backend.
        this.provider.registerListener(this.syncSelectedWithDomInput);
    }

    componentWillUnmount() {
        this.mutationObserver.disconnect();
        this.provider.unregisterListener(this.syncSelectedWithDomInput);
    }

    syncSelectedWithDomInput() {
        /*
         * This method's responsibility is to sync the selected value
         * with the input element representing the selected value.
         * The selected option can be updated in two ways:
         * - When another option is selected from the dropdown
         * - When another option is created through the 'plus-button'.
         *
         * PS. If an option is created through the 'plus-button', this code will run twice.
         * Once to set the value, then once more when the option with a displayName is
         * fetched from the backend.
         */

        const { selected } = this.state;

        const selectedOption = this.provider.getSingle(selected);
        if (selectedOption === null) {
            return;
        }

        const { input } = this.props;
        const newValue = selectedOption.value + '';

        const valueChanged = input.value !== newValue;

        input.dataset.displayName = selectedOption.displayName;
        input.value = newValue;

        if (valueChanged) {
            // 1. Do not trigger on displayName changes. Not all DatabaseComponents has a display name.
            // Triggering a change might dirty the page. Meaning the user will get an alert about losing
            // unsaved changes when navigating.
            // 2. Change event handlers can be expensive, let's get done with the React rendering first,
            // before invoking them. :-)
            window.setTimeout(function () {
                const event = new Event('change', { bubbles: true });
                input.dispatchEvent(event);
            }, 0);
        }
    }

    onChange(option: Option<T>) {
        /**
         * Update the options (also indirectly updates options within the ImmutableProvider, because
         * it is the same array in memory).
         *
         * If our dropdown has a type this means options have been loaded from server and should not
         * be mutated.
         */
        if (this.props.isCombo && this.props.defaults && !this.props.type) {
            // Check if option already exists
            const defaults = this.props.defaults;
            const exists = defaults.some((o) => o.value === option.value);
            if (!exists) {
                this.provider = new ImmutableProvider([option, ...defaults]);
            }
        }

        this.setState(
            {
                selected: option.value + '',
            },
            this.syncSelectedWithDomInput
        );
    }

    onOpen() {
        if (!this.props.dontShowPlusButton && this.fetcher) {
            const dropdownIsInJSPModal =
                $(this.props.input).parents('.ui-dialog').length > 0;
            this.setState({
                createNewButton: this.fetcher.createCreateNewButton(
                    this.onCreateSuccess,
                    this.props.dataTrackingId,
                    dropdownIsInJSPModal
                ),
            });
        }
    }

    onCreateSuccess(id: number) {
        this.setState(
            {
                selected: id + '',
            },
            () => {
                this.onChange({
                    value: id,
                    displayName: '',
                });
                // Create button should only exist on items with type, i.e. items using JSPDropdownProvider
                // and not ImmutableProvider.
                (this.provider as JSPDropdownProvider<T>).setDirty();
            }
        );
    }

    render() {
        if (
            window.accountDropdownWithHelp &&
            this.props.type === 'AccountHelp'
        ) {
            return (
                <AccountJSPDropdown
                    label={this.props.label}
                    selectedValue={this.state.selected}
                    params={this.fetcher?.getParams() ?? {}}
                    dataTrackingId={this.props.dataTrackingId || ''}
                    onChange={(id: string) => {
                        this.setState(
                            { selected: id },
                            this.syncSelectedWithDomInput
                        );
                    }}
                    defaultOptions={this.props.defaults}
                    defaultDisplayName={this.props.initial.displayName}
                    required={this.props.required}
                    disabled={this.state.disabled}
                    readOnly={this.state.readonly}
                />
            );
        }

        return (
            <ProviderDropdown
                label={
                    <LabelLink
                        fetcher={this.fetcher}
                        text={this.props.label}
                        type={this.props.type}
                        id={this.state.selected}
                        dropdownTrackingId={this.props.dataTrackingId}
                    />
                }
                labelText={this.props.label}
                provider={this.provider}
                selected={this.state.selected}
                onChange={this.onChange}
                dense={!this.props.label}
                required={this.props.required}
                disabled={this.state.disabled}
                readOnly={this.state.readonly}
                // If there are no type, there are no server search, and 7 is supposedly the number
                // of items the average human can keep in our working memory at once, so possible this
                // is a simpler interface when not providing search functionality.
                hideSearch={
                    !this.props.type &&
                    this.props.defaults &&
                    this.props.defaults.length <= 7
                }
                desktopListItem={this.desktopListItem}
                mobileListItem={this.mobileListItem}
                selectOnMobile={true}
                headers={this.headers}
                createButton={this.state.createNewButton}
                isCombo={this.props.isCombo}
                maxLength={this.props.maxLength}
                onOpen={this.onOpen}
                autoFocus={this.props.autofocus}
                resetBy={this.props.resetBy}
                dataTestId={this.props.dataTestId}
            />
        );
    }
}

export function renderJSPDropdown(domId: string, props: any) {
    renderComponent(JSPDropdown, domId, props);
}
