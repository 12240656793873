import {
    ModalContent,
    ModalFooter,
    ModalSubtitle,
    ModalTitle,
} from '@tlx/atlas';
import React, { useState } from 'react';
import {
    SignatureStatus,
    Signer,
    SignerCombination,
} from '@Page/CompanyVerificationFlow/Types';
import { FormProvider, useForm } from 'react-hook-form';
import {
    useUniqueSigners,
    SignerCombinations,
} from '@Page/CompanyVerificationFlow/SendSigningRequestForm/SignerCombination/SignerCombinations';
import { SubmitButton } from '@Page/CompanyVerificationFlow/SendSigningRequestForm/SubmitButton';
import { APIError } from '../../../hooks/fetch/createAPIResponse';
import { CompanyVerificationSteps } from '@Page/CompanyVerificationFlow/CompanyVerificationFlow';
import { SignerList } from '@Page/CompanyVerificationFlow/SendSigningRequestForm/SignerCombination/SignerList';

export type SendSigningRequestFormProps = {
    signerCombinations: SignerCombination[];
    handleSignatureRequest: (
        signers: Signer[],
        thisIsMeSigner: Signer | undefined
    ) => Promise<void>;
    handleNextStep?: (value: CompanyVerificationSteps) => void;
    userSignatureStatus: SignatureStatus;
};

export type SendSigningRequestFormValues = {
    thisIsMeSigner: Signer | undefined;
    signersFromServer: Signer[];
    signers: Record<string, Signer>;
};

export function getSignersForEmailRequest(
    thisIsMeSigner: Signer | undefined,
    signersFromServer: Signer[],
    signers: Signer[] | undefined
) {
    if (!signers) {
        return [];
    }

    return signers.filter((signer) => {
        if (signer.id === thisIsMeSigner?.id) {
            return false;
        }

        if (signer.email === null || signer.email.length === 0) {
            return false;
        }

        return (
            signer.signatureStatus === 'NOT_SIGNED' ||
            signersFromServer.some(
                (signerFromServer) =>
                    signerFromServer.id === signer.id &&
                    signerFromServer.email !== signer.email
            )
        );
    });
}

export function SendSigningRequestForm({
    signerCombinations,
    handleSignatureRequest,
    handleNextStep,
    userSignatureStatus,
}: SendSigningRequestFormProps) {
    const [error, setError] = useState('');
    const uniqueSigners = useUniqueSigners(signerCombinations);

    const methods = useForm<SendSigningRequestFormValues>({
        mode: 'onChange',
        defaultValues: {
            thisIsMeSigner:
                userSignatureStatus === 'PENDING_SIGNATURE'
                    ? uniqueSigners.find(
                          (signer) => signer.employeeId === loginEmployeeId
                      )
                    : undefined,
            signers: uniqueSigners.reduce((signers, signer) => {
                signers[signer.id] = signer;
                return signers;
            }, {} as Record<string, Signer>),
        },
    });
    const onSubmit = async (data: SendSigningRequestFormValues) => {
        // TODO: We might want to handle readonly in general rather than having a check
        // specifically on support access; though for now that doesn't seem to be readily
        // available on window.
        if (window.segmentationData.hackedOrSupportAccess) {
            return;
        }
        setError('');
        const forSubmission = getSignersForEmailRequest(
            data.thisIsMeSigner,
            uniqueSigners,
            Object.values(data.signers)
        );

        let errorFromServer = '';
        if (forSubmission.length > 0 || data.thisIsMeSigner) {
            try {
                await handleSignatureRequest?.(
                    forSubmission,
                    data.thisIsMeSigner
                );
            } catch (err) {
                if (err.name === 'APIError') {
                    const error = err as APIError;
                    setError(error.details.validationMessages[0].message);
                    errorFromServer =
                        error.details.validationMessages[0].message;
                }
            }
        }

        if (errorFromServer.length === 0) {
            if (data.thisIsMeSigner) {
                handleNextStep?.('VERIFY_BANK_ID');
            } else {
                handleNextStep?.('SIGNING_REQUESTS_SENT');
            }
        }
    };

    return (
        <FormProvider {...methods}>
            <ModalTitle>{getMessage('text_verify_company')}</ModalTitle>
            <ModalSubtitle>
                {getMessage('text_kyc_need_emails_to_sign')}
            </ModalSubtitle>
            <ModalContent>
                <form
                    id={'company-verification-form'}
                    onSubmit={methods.handleSubmit(onSubmit)}
                >
                    <SignerCombinations
                        signerCombinations={signerCombinations}
                    />
                    {uniqueSigners.length > 0 ? (
                        <SignerList
                            userSignatureStatus={userSignatureStatus}
                            signers={uniqueSigners}
                        />
                    ) : (
                        <div>{getMessage('text_no_signers')}</div>
                    )}
                </form>
            </ModalContent>
            <ModalFooter>
                <SubmitButton
                    uniqueSigners={uniqueSigners}
                    form="company-verification-form"
                />
                {error.length > 0 && (
                    <div className={'atl-mt-8 atl-text-red-100'}>
                        {error.toString()}
                    </div>
                )}
            </ModalFooter>
        </FormProvider>
    );
}
