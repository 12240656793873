import React, { KeyboardEvent } from 'react';
import { Group, Label, Textarea } from '@tlx/atlas';
import { UserFeedbackContactRequest } from '@Page/Automation2022/components/FeedbackButton/UserFeedbackContactRequest';
import '/react-modules/src/Pages/Automation2022/components/FeedbackButton/UserFeedbackButton.css';

interface KycFeedbackInputProps {
    handleKeyDown: (event: KeyboardEvent) => void;
    setInput: (feedback: string) => void;
    letterCounter: string;
    contactRequest: (answer: boolean) => void;
    contactAnswer: boolean | undefined;
}

export function KycFeedbackInput({
    handleKeyDown,
    setInput,
    letterCounter,
    contactRequest,
    contactAnswer,
}: KycFeedbackInputProps) {
    return (
        <div
            className={
                'feedbackButton-contactRequestBox atl-bg-white atl-flex atl-flex-col atl-gap-24'
            }
        >
            <Group className="atl-w-full atl-relative">
                <Label htmlFor="feedback-label">
                    <h3 className="atl-text-base atl-m-0">
                        {getMessage('feedback_box_your_feedback')}
                    </h3>
                </Label>
                <Textarea
                    maxLength={500}
                    className="feedbackButton-borderDialogBox atl-w-full atl-bg-bg atl-rounded"
                    autoFocus={true}
                    rows={4}
                    data-testid="text_send_feedback"
                    onKeyDown={handleKeyDown}
                    onChange={(input) => setInput(input.target.value)}
                />
                <p
                    className="atl-m-4 atl-absolute atl-text-grey-80"
                    style={{ right: 0 }}
                >
                    {letterCounter}
                </p>
            </Group>
            <div>
                <UserFeedbackContactRequest
                    contactRequest={contactRequest}
                    contactAnswer={contactAnswer}
                />
            </div>
        </div>
    );
}
