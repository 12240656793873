import $ from 'jquery';
import { getElementFloat, round2, tlxGetScope } from '../c-common';
import { clearChanged } from './change';
import { marshallSpec } from '../legacy/jsonrpc';
import { format } from './format';
import { addContextId, tlxUrl } from './url';
import { browserInfo } from './d-browserInfo';

window.creditNotes = (function () {
    function init(scope) {
        $('.js-showCreditNotesButton', scope).on('click', function () {
            const $checkboxes = $('.js-creditNoteSelected', scope);
            if (
                $checkboxes.length === 1 &&
                !$checkboxes.find('input').is(':checked')
            ) {
                updateCreditNotes(scope, 30);
            }

            $('.js-openCreditNotesDialog', scope).dialog('open');
        });

        if (hasCreditNotesButton(scope)) {
            updateCreditNotes(scope, 1);
            clearChanged();
        }

        $(':prop(invoice.amountCurrencyNegative)', scope).on(
            'change',
            function () {
                if (!hasCreditNotesButton(scope)) {
                    return;
                }

                const totalAmount = getElementFloat(
                    'invoice.amountCurrencyNegative',
                    scope
                );

                if (totalAmount < amountToSubtractFromPayment()) {
                    resetOpenCreditNotes(scope);
                }

                showCreditNotesButtonIfApplicable(scope);
                refreshPayment(scope);
                updateDialog(
                    round2(totalAmount - amountToSubtractFromPayment()),
                    scope
                );
            }
        );

        $(':prop(invoice.currencyId)', scope).on('change', function () {
            updateCreditNotes(scope, 1);
        });

        $(':prop(creditPosting.vendorId)', scope).on('change', function () {
            const scope = tlxGetScope(this);
            updateCreditNotes(scope, 1);
        });

        createDialog(scope);
    }

    // This is the global definition.
    // eslint-disable-next-line no-redeclare
    function amountToSubtractFromPayment(scope) {
        let subtractAmount = 0;

        for (let i = 0; ; i++) {
            const $checkBox = $(
                ":prop('creditNotesPostings[" + i + "].selected')",
                scope
            )[0];
            if (!$checkBox) {
                break;
            }
            if ($checkBox.checked) {
                subtractAmount += getElementFloat(
                    $('.js-creditNotesAmount-' + i)[0],
                    0,
                    scope
                );
            }
        }
        return round2(subtractAmount);
    }

    function hasCreditNotesButton(scope) {
        return $('.js-showCreditNotesButton', scope).length !== 0;
    }

    function resetOpenCreditNotes(scope) {
        $('.js-creditNoteSelected', scope).find('input').prop('checked', false);
    }

    function createDialog(scope) {
        if (!hasCreditNotesButton(scope)) {
            return;
        }
        $('.js-openCreditNotesDialog', scope).dialog({
            appendTo: '.js-paymentSection',
            autoOpen: false,
            position: browserInfo.isMobileReg()
                ? { my: 'top', at: 'center top+20' }
                : { my: 'top', at: 'center top+200' },
            minHeight: 40,
            width: Math.min(600, $(window).width()),
            modal: true,
            hide: 'fade',
            title: getMessage('text_open_credit_notes'),
            buttons: _getDialogButtons(scope),
        });
    }

    function _getDialogButtons() {
        return [
            {
                text: getMessage('button_ok'),
                class: 'tlx-green',
                click: function () {
                    $(this).dialog('close');
                    const scope = tlxGetScope(this);
                    refreshPayment(scope);
                },
            },
            {
                text: getMessage('text_reset'),
                class: 'tlx-green',
                click: function () {
                    $(this).dialog('close');
                    const scope = tlxGetScope(this);
                    const totalAmount = getElementFloat(
                        'invoice.amountCurrencyNegative',
                        0,
                        scope
                    );
                    resetOpenCreditNotes(scope);
                    refreshPayment(scope);
                    updateDialog(totalAmount, scope);
                },
            },
        ];
    }

    function refreshPayment(scope) {
        scope.refreshPaymentAmount();

        const $paymentType = $('.js-paymentType', scope);
        const paymentAmount = $(
            ':prop(paymentWidgetWrapper.newPayment.amountBasisCurrency)',
            scope
        ).val();
        const totalAmount = $(
            ':prop(invoice.amountCurrencyNegative)',
            scope
        ).val();

        if (paymentAmount === '' && totalAmount !== '') {
            $(':prop(paymentWidgetWrapper.paymentType)', scope).val('0');
            $paymentType.addClass('hidden');
        } else {
            $paymentType.removeClass('hidden');
        }

        const amountToSubtract = amountToSubtractFromPayment(scope);
        if (amountToSubtract === 0) {
            $('.js-creditNoteSubtractedMessage', scope).addClass('hidden');
            $('.js-creditNoteSubtractedMessage_is0', scope).addClass('hidden');
        } else if (paymentAmount === '') {
            $('.js-creditNoteSubtractedMessage_is0', scope).removeClass(
                'hidden'
            );
            $('.js-creditNoteSubtractedMessage', scope).addClass('hidden');
        } else {
            $('.js-creditNoteSubtractedMessage', scope).removeClass('hidden');
            $('.js-creditNoteSubtractedMessage_is0', scope).addClass('hidden');
        }

        if (amountToSubtract > 0) {
            $('.js-showCreditNotesButton', scope).text(
                getMessage('text_edit_credit_notes')
            );
            $('.js-creditNotesMessage', scope).text(
                getMessage('text_credit_notes_message_closed_section2')
            );
        } else {
            $('.js-showCreditNotesButton', scope).text(
                getMessage('text_subtract_open_credit_notes')
            );
            const hasCreditNotes = !$('.js-creditNotes', scope).is(':empty');
            if (hasCreditNotes) {
                $('.js-creditNotesMessage', scope).text(
                    getMessage('text_you_have_open_credit_notes')
                );
            } else {
                $('.js-creditNotesMessage', scope).text('');
            }
        }
    }

    function getOpenCreditNotesPostings(scope, limit) {
        const selectedCurrency = $(':prop(invoice.currencyId)', scope).val();

        const voucherId = $(':prop(voucher.id)', scope).val();
        const vendorId = $(':prop(creditPosting.vendorId)', scope).val();
        let result;
        try {
            const spec = marshallSpec(
                'postingId',
                'voucherId',
                'voucherNumber',
                'invoiceNumber',
                'invoiceDate',
                'amountCurrency',
                'isSelected',
                'clientId'
            );
            result =
                window.jsonrpc.IncomingInvoiceSuperForm.getOpenCreditNotesPostings(
                    spec,
                    vendorId,
                    selectedCurrency,
                    limit,
                    voucherId
                );
        } catch (e) {
            return [];
        }

        return result;
    }

    function updateCreditNotes(scope, limit) {
        const postings = getOpenCreditNotesPostings(scope, limit);
        const totalAmount = getElementFloat(
            'invoice.amountCurrencyNegative',
            0,
            scope
        );

        createRows(postings, scope);
        showCreditNotesButtonIfApplicable(scope);
        refreshPayment(scope);
        updateDialog(totalAmount, scope);
    }

    function showCreditNotesButtonIfApplicable(scope) {
        const hasCreditNotes = !$('.js-creditNotes', scope).is(':empty');
        const isSomeVendor =
            $(':prop(creditPosting.vendorId)', scope).val() !== 0;
        const totalAmount = getElementFloat(
            'invoice.amountCurrencyNegative',
            0,
            scope
        );
        const isCreditNote = isNaN(totalAmount) || totalAmount < 0;

        if (isSomeVendor && hasCreditNotes && !isCreditNote) {
            $('.js-showCreditNotesButton', scope).removeClass('hidden');
        } else {
            $('.js-showCreditNotesButton', scope).addClass('hidden');
        }
    }

    function updateDialog(amountLeft, scope) {
        const $rows = $('.js-creditNotesCheckbox', scope);
        for (let i = 0; i < $rows.length; i++) {
            const amount = getElementFloat(
                $('.js-creditNotesAmount-' + i, scope)[0],
                0,
                scope
            );
            if (amount > amountLeft && !$rows[i].checked) {
                $rows.eq(i).attr('disabled', true);
            } else {
                $rows.eq(i).attr('disabled', false);
            }
        }
        const $rowsDisabled = $rows.filter(':disabled');
        const totalAmount = $(
            ':prop(invoice.amountCurrencyNegative)',
            scope
        ).val();
        if ($rowsDisabled.length !== $rows.length) {
            $('.js-subtractedAmount', scope).text(
                getMessage('text_subtracted') +
                    ': ' +
                    format.amount2(amountToSubtractFromPayment()) +
                    ' / ' +
                    totalAmount
            );
        } else {
            $('.js-subtractedAmount', scope).text(
                getMessage('text_can_not_subtract_credit_notes')
            );
        }
    }

    function createRows(postings, scope) {
        const prefix = scope.tlxIdPrefix;
        const $rows = $('.js-creditNotes', scope);
        $rows.empty();

        for (let i = 0; i < postings.length; i++) {
            const invoiceDate = postings[i].invoiceDate;
            const amountCurrency = postings[i].amountCurrency;
            const invoiceNumber = postings[i].invoiceNumber;
            const voucherNumber = postings[i].voucherNumber;

            const id = prefix + 'creditNotesPostings[' + i + ']';
            const name = 'creditNotesPostings[' + i + ']';

            let url = 'incomingInvoiceMenu';
            url = tlxUrl.addUrlParameter(
                url,
                'voucherId',
                postings[i].voucherId
            );
            url = addContextId(url);

            $rows.append(
                $('<tr>').append(
                    $('<td>', {
                        class: 'js-creditNoteSelected',
                    }).append(
                        $('<div>', {
                            class: 'inputItem checkbox',
                        }).append(
                            $('<label>', {
                                class: 'tlx-checkbox',
                                for: id + '.selected',
                            }).append(
                                $('<input>', {
                                    type: 'checkbox',
                                    value: 'on',
                                    class: 'atl-input--checkbox js-creditNotesCheckbox',
                                    id: id + '.selected',
                                    name: name + '.selected',
                                    'data-tooltip':
                                        getMessage('text_view_voucher'),
                                    'aria-tooltip':
                                        getMessage('text_view_voucher'),
                                    click: function () {
                                        const totalAmount = getElementFloat(
                                            'invoice.amountCurrencyNegative',
                                            0,
                                            scope
                                        );
                                        updateDialog(
                                            round2(
                                                totalAmount -
                                                    amountToSubtractFromPayment()
                                            ),
                                            scope
                                        );
                                    },
                                }),
                                $('<span>', {
                                    class: ' tlx-checkbox__label',
                                })
                            )
                        )
                    ),
                    $('<td>').append(
                        $('<a>', {
                            class: 'linkFunction highlightAction',
                            href: url,
                            id: id + '.id',
                            name: name + '.id',
                            text: voucherNumber,
                        })
                    ),
                    $('<td>', {
                        class: 'js-invoiceNumber',
                        text: invoiceNumber,
                    }),
                    $('<td>', {
                        class: 'js-date',
                        text: invoiceDate,
                    }),
                    $('<td>', {
                        class:
                            'table-cell--number-small js-amount js-creditNotesAmount-' +
                            i,
                        text: amountCurrency,
                    }),
                    $('<input>', {
                        type: 'hidden',
                        value: postings[i].postingId,
                        id: id + '.postingId',
                        name: name + '.postingId',
                    })
                )
            );
        }
        if (postings.length === 30) {
            $('.js-notAllIsLoaded', scope).removeClass('hidden');
        } else {
            $('.js-notAllIsLoaded', scope).addClass('hidden');
        }

        return $rows;
    }

    return {
        init: init,
        amountToSubtractFromPayment: amountToSubtractFromPayment,
    };
})();
