import * as React from 'react';
import {
    PaymentWidget,
    PaymentWidgetProps,
} from '@Page/PaymentWidget/component/PaymentWidget';
import { Scenario } from '@Page/PaymentWidget/types/paymentWidgetTypes';
import { CurrencyEnum } from '@General/PBC/Enums/Currency.enum';
import { mount, unmount } from '../../../react-modules/src/util/React18mount';

export type PaymentWidgetContextProps = {
    companyCurrencyId?: CurrencyEnum;
    companyCurrencyCode?: string;
    isComboAccountNumber?: boolean;
};

export const PaymentWidgetContext =
    React.createContext<PaymentWidgetContextProps>({});

type PaymentWidgetWithContextProps = PaymentWidgetProps &
    PaymentWidgetContextProps;

/**
 *
 * @param simplePaymentWidget
 * @param scenario
 * @param scenarioId
 * @param label
 * @param vendorId
 * @param invoiceId
 * @param hasKid
 * @param isOTPValidated
 * @param voucherId
 * @param companyCurrencyId
 * @param companyCurrencyCode
 *
 * @param isComboAccountNumber
 * when this is set it is always with the same check
 * Context.getCompany().getModules().getModuleRemit().isZtlOnly() ? loginEmployee.isAuthDirectRemitZtl() : loginEmployee.isAuthManageBankAccountNumbers();
 */
export function PaymentWidgetWithContext({
    simplePaymentWidget,
    scenario,
    scenarioId,
    label,
    vendorId,
    invoiceId,
    hasKid,
    isOTPValidated,
    voucherId,
    companyCurrencyId,
    companyCurrencyCode,
    isComboAccountNumber,
}: PaymentWidgetWithContextProps) {
    return (
        <PaymentWidgetContext.Provider
            value={{
                companyCurrencyId,
                companyCurrencyCode,
                isComboAccountNumber,
            }}
        >
            <PaymentWidget
                simplePaymentWidget={simplePaymentWidget}
                scenario={scenario}
                scenarioId={scenarioId}
                label={label}
                vendorId={vendorId}
                invoiceId={invoiceId}
                hasKid={hasKid}
                isOTPValidated={isOTPValidated}
                voucherId={voucherId}
            />
        </PaymentWidgetContext.Provider>
    );
}

export function openPaymentWidget({
    element,
    simplePaymentWidget,
    scenario,
    scenarioId,
    label,
    vendorId,
    invoiceId,
    hasKid,
    isOTPValidated,
    voucherId,
    companyCurrencyId,
    companyCurrencyCode,
    isComboAccountNumber,
}: {
    element: HTMLElement;
    simplePaymentWidget: boolean;
    scenario: Scenario;
    scenarioId: number;
    label: string;
    vendorId?: number;
    invoiceId?: number;
    hasKid?: boolean;
    isOTPValidated?: boolean;
    voucherId?: number;
    companyCurrencyId?: number;
    companyCurrencyCode?: string;
    isComboAccountNumber?: boolean;
}) {
    if (!element) {
        return;
    }

    mount(
        element,
        <PaymentWidgetWithContext
            simplePaymentWidget={simplePaymentWidget}
            scenario={scenario}
            scenarioId={scenarioId}
            label={label}
            vendorId={vendorId}
            invoiceId={invoiceId}
            hasKid={hasKid}
            isOTPValidated={isOTPValidated}
            voucherId={voucherId}
            companyCurrencyId={companyCurrencyId}
            companyCurrencyCode={companyCurrencyCode}
            isComboAccountNumber={isComboAccountNumber}
        />
    );

    document.addEventListener(
        'paymentWidgetClose',
        () => {
            unmount(element);
        },
        { once: true }
    );
}
