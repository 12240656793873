import * as React from 'react';
import classNames from 'classnames';
import Collapse from '@kunukn/react-collapse';

import { ButtonIcon, ButtonIconProps } from '@Component/ButtonIcon';
import { CommandHandler } from '@Component/Command';
import { SectionCommandId } from '@General/Command/Global/Section';
import { isEditable } from '@General/Helpers';

import { SectionToggle } from './SectionToggle';

import './Section.scss';

export type SectionProps = {
    // Title of the section
    title?: string | React.ReactNode;

    // Whether the section can be collapsed or not.
    // If true, the user can click on the title (or open/close icon)
    // to expand/collapse the section,
    // If false, the section will always be open.
    collapsible?: boolean;

    // Whether the section is currently collapsed or not
    collapsed?: boolean;

    // Shown in the header, below the title.
    summary?: React.ReactNode;

    // Option: you can set up a list of icons in the toolbar of the section.
    toolbar?: Array<ButtonIconProps>;

    // Class name of the child container can be overridden here.
    className?: string;

    // The content inside the section, when it's not collapsed.
    children: React.ReactNode;
};

type SectionState = {
    // The current open state
    open: boolean;
    // The last vaule of props.collapsed
    collapsed: boolean;
};

export class Section extends React.Component<SectionProps, SectionState> {
    constructor(props: SectionProps) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.toggleHead = this.toggleHead.bind(this);
        this.handleToggleCommand = this.handleToggleCommand.bind(this);

        this.state = {
            open: props.collapsed !== true,
            collapsed: props.collapsed === true,
        };
    }

    static getDerivedStateFromProps(
        props: SectionProps,
        state: SectionState
    ): Partial<SectionState> | null {
        if (
            props.collapsed !== state.collapsed &&
            props.collapsed === state.open
        ) {
            return {
                open: !props.collapsed,
                collapsed: props.collapsed,
            };
        } else {
            return null;
        }
    }

    toggle(event: React.MouseEvent<HTMLElement>) {
        if (isEditable(event.target as HTMLElement)) {
            return;
        }

        if (this.props.collapsible) {
            this.setState((state) => ({
                open: !state.open,
            }));
        }
    }

    toggleHead(event: React.MouseEvent<HTMLElement>) {
        if (event.defaultPrevented) {
            return;
        }

        this.toggle(event);
    }

    handleToggleCommand(command: string, open: boolean) {
        if (this.props.collapsible) {
            this.setState({ open });
        }
    }

    render() {
        const { open } = this.state;

        const toggleIcon = this.props.collapsible ? (
            <SectionToggle onClick={this.toggle} isSectionOpen={open} />
        ) : null;

        const title = this.props.title ? (
            <span className="tlx-section__head__title">{this.props.title}</span>
        ) : null;

        const summary = (
            <div className="tlx-section__head__summary">
                {this.props.summary}
            </div>
        );

        const hasHead: boolean =
            toggleIcon !== null ||
            title !== null ||
            (this.props.collapsible === true && summary !== null) ||
            (this.props.toolbar?.length ?? 0) > 0;

        const head = hasHead ? (
            <div
                className="tlx-section__head"
                onClick={this.props.collapsible ? this.toggleHead : undefined}
            >
                <div className="tlx-section__head__info">
                    {title}
                    {open ? null : summary}
                </div>
                <div className="tlx-section__head__toolbar">
                    {this.props.toolbar &&
                        this.props.toolbar.map((toolbarItem, index) => (
                            <ButtonIcon
                                key={toolbarItem.toolTip ?? index}
                                {...toolbarItem}
                            />
                        ))}
                    {toggleIcon}
                </div>
            </div>
        ) : null;

        return (
            <div
                className={classNames('tlx-section', this.props.className, {
                    'tlx-section--closeable': this.props.collapsible === true,
                    'tlx-section--closed': !open,
                    'tlx-section--open': open,
                })}
            >
                <CommandHandler
                    commandId={SectionCommandId}
                    handler={this.handleToggleCommand}
                />
                {head}
                <Collapse
                    className="tlx-section__content"
                    isOpen={open}
                    overflowOnExpanded
                >
                    {this.props.children}
                </Collapse>
            </div>
        );
    }
}
