import jQuery from 'jquery';
const $ = jQuery;
import { tlxForms } from '../../modules/forms';
import { encodeHTML } from '../../modules/encodeHTML';
import { stringUtils } from '../../modules/stringUtils';
import { utils } from '../../modules/utils';
import { dtable } from '../../modules/dtable';

(function () {
    /**
     * Creates a summary for the given section.
     *
     * This is invoked on all collapsible sections during page load. It is also invoked
     * on a section that is being collapsed. If you want to use this function from outside
     * you can trigger the event 'updateSectionSummary' on the section.
     *
     * You can target DOM-elements as candidates for the section summary by using
     * the class .useAsSectionSummary and the attribute [useassectionsummary].
     *
     *
     * Summary for tables
     *
     * Tables can be marked as the target of summaries with the attribute
     * useAsSectionSummary. The summary simply counts the number of rows
     * in the table. To mark a row as "countable" add the attribute
     * sectionSummaryLine on the TR (or TBODY if the table is grouped).
     *
     * If the table is in a section called Ordrelinjer, and it has 3
     * "countable" rows, the summary will be "3 ordrelinjer". The term
     * "ordrelinjer" is collected from the section header.
     *
     *
     * Composing a summary
     *
     * Sometimes we might want to compose a section summary out of several parts/
     * input items. If that is the case, use the class .composedSectionSummary
     * on a (preferably lowest) common ancestor in DOM, and tag the parts with
     * the class sectionSummaryPart.
     *
     * OBS: For composed section summaries, all parts must have a value, if not
     * the summary is not used.
     *
     *
     * Creating a site specific dynamic/complex section summary
     *
     * Sometimes the requirements are so specific/complex that there are no reason to try to
     * implement it here in the framework. If that is the case, you can use site-specific javascript
     * to update a hidden .useAsSectionSummary element.
     *
     * An example (taken from voucherAppoval_list.jsp) could look like this:
     *
     * <span class="useAsSectionSummary sectionSummaryHelper js-voucherApprovalListSummary"></span>
     *
     * That is, use the following classes:
     *
     * * .useAsSectionSummary: to tell this tool that the content should be used as section summary
     * * .sectionSummaryHelper: basically to hide the summary from both screen and print
     * * .js-[customNameHere]: for finding the element from javascript, and insert the dynamically computed content
     *
     *
     * You should use a style class, and NOT ID, because we can have the same screen in DOM multiple times.
     *
     *
     * Allowing HTML in summary
     *
     * If you want HTML in your section summary, you have to add the class dangerouslyAvoidHTMLEncoding. This
     * Item can not be an <input> nor <table>. If you add the class dangerouslyAvoidHTMLEncoding, you are responsible
     * to make sure the content actually is, and will be in the future, XSS safe.
     *
     *
     * @param section the section
     */
    function createSummary(section) {
        if (!$(section).is(':visible')) {
            return;
        }

        const $sectionSummary = $('.section-summary', section);

        const summary = $.map(
            $(
                '[useAsSectionSummary=true], .useAsSectionSummary, .composedSectionSummary',
                section
            ),
            function (item) {
                const $item = $(item);
                const possibleLineBreak = $item.hasClass(
                    'sectionSummary-newline'
                )
                    ? '<br />'
                    : '';

                if ($item.is('.composedSectionSummary')) {
                    const summaries = $.map(
                        $('.sectionSummaryPart', $item),
                        function (item) {
                            const $item = $(item);
                            if ($item.is('input')) {
                                const string = tlxForms.createSummary($item);

                                return encodeHTML(string);
                            }

                            if ($item.is('.tlx-dropdown')) {
                                const input = $item.find('input')[0];
                                return encodeHTML(
                                    input.dataset.displayName || ''
                                );
                            }

                            return $item.text().trim();
                        }
                    );

                    // Make sure all component parts actually have some value
                    if (
                        summaries.some(function (a) {
                            return a === '';
                        })
                    ) {
                        return '';
                    }

                    return possibleLineBreak + summaries.join(' ');
                }

                if ($item.is('.tlx-dropdown')) {
                    return encodeHTML(
                        $item.find('.txr-dropdown__field__input').text()
                    );
                }

                // A textarea field is wanted as section summary - maybe we should reconsider
                // if it should be allowed?
                if ($item.is('input') || $item.is('textarea')) {
                    if (item.type === 'radio') {
                        return possibleLineBreak + item.checked
                            ? $item.next('.tlx-radio__label').text().trim()
                            : '';
                    }
                    const summary = encodeHTML(tlxForms.createSummary($item));
                    return summary ? possibleLineBreak + summary : '';
                }

                if ($item.is('table')) {
                    const lines = $('[sectionSummaryLine]', $item).filter(
                        function () {
                            return !dtable.isRowDeleted(this);
                        }
                    ).length;
                    let header = '';
                    if (lines === 1 && $item.attr('sectionsummarylinename')) {
                        header = $item.attr('sectionsummarylinename');
                    } else if (
                        lines > 1 &&
                        $item.attr('sectionsummarylinenameplural')
                    ) {
                        header = $item.attr('sectionsummarylinenameplural');
                    } else {
                        header = $item
                            .closest('.section')
                            .find('.tlxSection-head h3')
                            .text()
                            .trim()
                            .toLocaleLowerCase();
                    }
                    return lines + ' ' + encodeHTML(header);
                }

                if ($item.is('.dangerouslyAvoidHTMLEncoding')) {
                    return $item.html() ? possibleLineBreak + $item.html() : '';
                }

                // ($item.is('span') && $item.children().hasClass('inputItem'))
                // is peacock-table footer with uselabel=false hack/fix.

                if (
                    $item.is('.inputItem') ||
                    ($item.is('span') && $item.children().hasClass('inputItem'))
                ) {
                    let value = '';
                    let label = '';
                    const $inputItem = $item.is('.inputItem')
                        ? $item
                        : $item.find('.inputItem');
                    const $textfield = $inputItem.find('.tlx-textfield');

                    if ($textfield.find('input').length > 0) {
                        value = $textfield.find('input').val();
                        label = $textfield.find('label').text();
                    } else if ($textfield.find('div').length > 0) {
                        value = $textfield.find('div').text();
                        label = $textfield.find('label').text();
                    } else if ($textfield.find('span').length > 0) {
                        value = $textfield.find('span').text();
                        label = $textfield.find('label').text();
                    } else if ($inputItem.hasClass('checkbox')) {
                        //We can use the boolean nature of checkboxes to save some space in the summary. if it's checked it's displayed,
                        //  else, there is no point in showing the information. This is only true for the labeled version
                        //  See project isClosed (updateProjectDetails.jsp).
                        label = $inputItem.find('input').is(':checked')
                            ? $inputItem.find('.tlx-radio__checkbox').text()
                            : '';
                        return label ? possibleLineBreak + label : '';
                    } else {
                        console.error(
                            'Trying to add invalid section summary type!'
                        );
                    }

                    if (!value) {
                        return '';
                    }

                    if ($item.is('.sectionSummary--reversed-label')) {
                        return (
                            possibleLineBreak + encodeHTML(value) + ' ' + label
                        );
                    } else {
                        const summaryText =
                            possibleLineBreak + label + ' ' + encodeHTML(value);

                        return _createText($textfield, summaryText);
                    }
                }

                if ($item.attr('summary-label') && $item.text()) {
                    return (
                        possibleLineBreak +
                        encodeHTML(
                            $item.attr('summary-label') +
                                ' ' +
                                $.trim($item.text())
                        )
                    );
                }

                const itemText = encodeHTML(
                    stringUtils.trimTabsAndNewLine($item.text())
                );

                return $item.text()
                    ? possibleLineBreak + _createText($item, itemText)
                    : '';
            }
        )
            .filter(function (text) {
                return !!text;
            })
            .join(' \u00A0\u2013\u00A0 '); // non-breaking space + n-dash + non-breaking space

        $sectionSummary.html(summary);
    }

    function _createText($item, text) {
        const isError =
            $item.hasClass('errorMessage') ||
            $item.find('.errorMessage').length > 0;
        const isWarning =
            $item.hasClass('warningMessage') ||
            $item.find('.warningMessage').length > 0;
        const summaryClass = isError
            ? 'errorMessage'
            : isWarning
            ? 'warningMessage'
            : '';

        if (summaryClass.length > 0) {
            return (
                '<span class="' +
                summaryClass +
                '">' +
                utils.capitalizeFirstLetter(text) +
                '</span>'
            );
        } else {
            return text;
        }
    }

    $(window)
        .on(
            'tlxLoadPageContentDone tlxTabsActivate tlxLazyLoadDone txrPageDialogLoadDone',
            function () {
                setTimeout(function () {
                    $('form .section.collapsible').each(function () {
                        createSummary(this);
                        $(this)
                            .find('> .sectionContent')
                            .toggle(!$(this).is('.collapsed'));
                    });

                    _placeSectionToolbar();
                }, 0);
            }
        )
        .on(
            'tlx-sectionExpandCollapse updateSectionSummary',
            function (e, opts) {
                // When triggering updateSectionSummary no opts are given
                if (!opts || opts.collapsed) {
                    createSummary(e.target);
                }
            }
        );

    function _placeSectionToolbar() {
        $('.sectionToolbar').each(function (i, toolbar) {
            const $tlxSectionMore = $(toolbar)
                .closest('.sectionContent')
                .siblings('.tlxSection-head')
                .find('.tlxSection-more');
            if ($tlxSectionMore.length > 0) {
                $(toolbar).detach().appendTo($tlxSectionMore);
            }
        });
    }

    if (window.narrowScreen) {
        // Don't support expandable sections or grid magic on narrow screen
        return;
    }

    $(window).on(
        'tlxLoadPageContentDone tlxTabsActivate tlxLazyLoadDone',
        function () {
            //We use the layout-grid class to set a max-width on those pages containing sections
            //We could probably just contain width of #wrapperDiv instead.
            $('form').each(function () {
                if (
                    $(this).has('.section').length > 0 &&
                    // For some historical reason, all forms with sections would have the .layout-grid CSS class added.
                    // The .no-layout-grid CSS class is an escape hatch to prevent/opt-out of that behavior.
                    !$(this).hasClass('no-layout-grid')
                ) {
                    $(this).addClass('layout-grid');
                }
            });
        }
    );
})();
