import jQuery from 'jquery';

import { filter } from './filter';
import { tlxGetScope } from '../c-common';
import { hideContent, loadPageContent, refreshUrl } from '../o-common';
import {
    changeTest,
    clearChanged,
    clearTabChanged,
    clearGeneralValidation,
} from './change';
import { tlxAlert } from './alert';
import { addContextId, tlxUrl } from './url';
import { extraFrame } from './extraFrame';

export interface NavigateOptions {
    fragmentIdentifier?: string;
    target?: string;
    replaceState?: boolean;
    // History.state is actually of type any...
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    state?: any;
    checkChanges?: boolean;
    filter?: Record<string, string>;
}

function isExternalLink(url: string): boolean {
    const tmp = document.createElement('a');
    tmp.href = url;
    return tmp.host !== window.location.host;
}

export const nav = (function (window, $) {
    function download(url: string): void {
        document.location.href = addContextId(url);
    }

    function submenu(url: string, fragmentIdentifier?: string) {
        navigate(url, { fragmentIdentifier: fragmentIdentifier });
    }

    /**
     * @param url _with_ contextId
     */
    function navigateAjax(url: string) {
        $(window).trigger('tlxNavigateAjax', url);
        window.dispatchEvent(
            new CustomEvent('pageLoad-startNavigation', {
                detail: { url },
            })
        );

        hideContent();

        clearChanged();
        clearTabChanged(); // or do this in setChanged(false)/clearChanged() ?

        clearGeneralValidation(); // FRAMEWORK: should be handled by event handler on tlxNavigateAjax in generalValidationPopup

        loadPageContent(url);
    }

    /**
     * TODO: This function should be replaced with a click listener on a[href].link
     * so we could use a normal URL in href.
     */
    function link(url: string, target?: string, fragmentIdentifier?: string) {
        navigate(url, {
            target: target,
            fragmentIdentifier: fragmentIdentifier,
        });
    }

    /**
     * Used for menu entries. These are different from a regular navigate in that they have filter state saving turned on.
     * @param url without contextId
     * @param options see navigate
     */
    function navigateMenu(url: string, options?: NavigateOptions) {
        let savedUrl;
        if (options && options.filter) {
            savedUrl = tlxUrl.addUrlParametersAsObject(url, options.filter);
        } else {
            savedUrl = filter.getUpdatedUrl(url);

            // TRIP-32480: Do not remember searchText in input fields when navigating from sidebar in a session
            savedUrl = tlxUrl.removeUrlParameter(savedUrl, 'searchText');
        }

        const state = { pageKey: tlxUrl.getUrlFilterKey(url) };

        navigate(savedUrl, $.extend(options, { state: state }));
    }

    function popup(url: string, target?: string) {
        if (!target) {
            target = '_blank';
        }
        return navigate(url, { target: target });
    }

    /**
     * NB, navigate(url, target, fragmentIdentifier, replace) form is deprecated. Use url and option object instead.
     *
     * @param url without contextId
     * @param options see defaultOptions below
     */
    function navigate(
        url: string,
        options?: NavigateOptions,
        fragmentIdentifier?: string,
        replaceState?: boolean
    ) {
        function addFragmentIdentifier(
            url: string,
            fragmentIdentifier: string
        ) {
            try {
                /**
                 * Validate selector before storing it in sessionStorage.
                 *
                 * @see https://jira.visma.com/browse/TRIP-34365
                 */
                $('[name=' + fragmentIdentifier + '], #' + fragmentIdentifier);

                // @ts-expect-error sessionStorage not typed on $
                $.sessionStorage.setItem('fragmentId', fragmentIdentifier);
            } catch (error) {
                window.Sentry.captureException(error, {
                    extra: {
                        fragmentId: fragmentIdentifier,
                        url,
                        replaceState,
                        options,
                    },
                });
            }
        }

        const defaultOptions = {
            /** tab identifier */
            fragmentIdentifier: undefined,
            /** target as in <a target=...></a> */
            target: undefined,
            /** Should the current history entry be replaced? (typical refresh filtercomponent) */
            replaceState: false,
            /** state to associate with the history entry - */
            state: undefined,
            /** If false navigation will occur disregarding changes made by user */
            checkChanges: true,
        };

        // Support deprecated form: navigate(url, target, fragmentIdentifier, replace)
        if (!$.isPlainObject(options)) {
            const target = options;
            options = {
                // @ts-expect-error too much work to add typings for the deprecated form
                target: target,
                fragmentIdentifier: fragmentIdentifier,
                replaceState: replaceState,
            };
        }

        options = $.extend(defaultOptions, options);

        if (window.narrowScreen) {
            url = tlxUrl.addUrlParameter(url, 'narrowScreen', 'true');
        }

        url = addContextId(url);

        if (isExternalLink(url)) {
            // @ts-expect-error This should be allowed, but TypeScript for some reason complains.
            window.location = url;
            return;
        }

        const fragmentPos = url.indexOf('#');
        if (fragmentPos >= 0 && options.fragmentIdentifier) {
            throw 'fragmentIdentifier specified in call to navigate with url containing #';
        }

        if (options.fragmentIdentifier) {
            addFragmentIdentifier(url, options.fragmentIdentifier);
        }
        if (options.target) {
            window.open(url, options.target, 'noopener');
        } else {
            let navigateFunction;

            if (history && history.pushState) {
                navigateFunction = function () {
                    // Micro frontends needs hard page load
                    if (isMicroFrontend(url)) {
                        window.open(url, '_self');
                        return;
                    }

                    navigateAjax(url);

                    // why isn't the history management inside navigateAjax?

                    if (url === location.pathname + location.search) {
                        // Don't add duplicate history entries. (WEAKNESS: what's a better way to check equality? eg. relative vs. absolute)

                        /* This check is needed for those few forms that returns full redirects (refreshUrl explicitly replace state).
						An option would be to use Result.replace for those forms.. Probably better too - depending on eg. whether we want "create pages" in the history.
						Although maaaaybe it would interfere with "originalUrl"/filterstate saving in some cases?
						[ojb - 8. sep. 2014]
						*/

                        if (options != undefined) {
                            options.replaceState = true;
                        }
                    }

                    // The hash of the url is stripped when calling this method (at least from loadActualPage), which
                    // strips the hash from the url. We don't want that…
                    if (options?.fragmentIdentifier) {
                        url = url + '#' + options.fragmentIdentifier;
                    }

                    if (options?.replaceState) {
                        // (Retain original history.state. This is used by the filter state management code)
                        history.replaceState(
                            options.state || history.state,
                            // @ts-expect-error According to TypeScript, passing null should not be allowed - but that's what the code does, apparently
                            null,
                            url
                        );
                    } else {
                        history.pushState(
                            options?.state,
                            // @ts-expect-error According to TypeScript, passing null should not be allowed - but that's what the code does, apparently
                            null,
                            url
                        );
                    }
                };
            } else {
                // Should not happen - these users are blocked at login
                navigateFunction = function () {
                    tlxAlert(
                        getMessage(
                            'text_browser_not_supported',
                            'https://www.google.com/chrome/browser/'
                        )
                    );
                };
            }

            if (options.checkChanges) {
                changeTest(navigateFunction);
            } else {
                navigateFunction();
            }
        }
    }

    /**
     * MicroFrontends need hard page load.
     */
    function isMicroFrontend(url: string) {
        return url.toLowerCase().startsWith('/page/');
    }

    /**
     * Go one back in history. Hide current page in case it doesn't work.
     *
     */
    function back() {
        $('#ajaxContent').hide();
        history.back();
    }

    // eslint-disable-next-line no-undef
    function handleForward(forward: string) {
        type Entry = {
            _action: keyof typeof handlers;
            id: string;
            url: string;
            fragmentIdentifier?: string;
        };

        const handlers = {
            handleSearchResult: function (data: Entry) {
                filter.handleSearchResult(data['url']);
            },
            navigate: function (data: {
                url: string;
                fragmentIdentifier?: string;
                replaceState?: boolean;
            }) {
                const props: NavigateOptions = {};
                if ('fragmentIdentifier' in data) {
                    props['fragmentIdentifier'] = data['fragmentIdentifier'];
                }
                if ('replaceState' in data) {
                    props['replaceState'] = data['replaceState'];
                }
                navigate(data['url'], props);
            },
            navigateDirect: function (data: Entry) {
                navigate(data['url']);
            },
            navDownload: function (data: Entry) {
                download(data['url']);
            },
            navPopup: function (data: Entry) {
                popup(data['url']);
            },
            refreshUrl: function () {
                refreshUrl();
            },
            refreshAndReloadPreview: function () {
                refreshUrl();
                tlxGetScope($('.tlx-menu')).reloadPreview();
            },
            reload: function () {
                location.reload();
            },
            menuRefreshViewOrderOut: function () {
                tlxGetScope($('.tlx-menu')).refreshViewOrderOut();
            },
            menuProjectMenuViewer: function () {
                tlxGetScope($('.tlx-menu')).projectMenuViewer();
            },
            openBlank: function (data: Entry) {
                window.open(data['url'], '_blank', 'noopener');
            },
            openSelf: function (data: Entry) {
                window.open(data['url'], '_self', 'noopener');
            },
            back: function () {
                back();
            },
            backNoScroll: function () {
                // @ts-expect-error frameless is not TypeScript-defined on $
                $('body').frameless('ignoreScroll', !0);
                history.back();
            },
            gotoNextPeriod: function () {
                // @ts-expect-error this is defined inline in some JSP file
                window.gotoNextPeriod();
            },
            gotoPreviousPeriod: function () {
                // @ts-expect-error this is defined inline in some JSP file
                window.gotoPreviousPeriod();
            },
            viewerDocument: function (data: Entry) {
                extraFrame.viewerDocument(data['id']);
            },
            viewerPdf: function (data: Entry) {
                extraFrame.viewerPdf(data['url']);
            },
        };

        if (!forward) {
            return;
        }

        const forwardEntries: Entry[] = JSON.parse(forward);

        forwardEntries.forEach(function (entry: Entry) {
            if (entry && entry['_action'] && entry['_action'] in handlers) {
                handlers[entry['_action']](entry);
            }
        });
    }

    return {
        download: download,
        submenu: submenu,
        ajax: navigateAjax,
        link: link,
        menu: navigateMenu,
        // Perhaps the navigate object could be this very method?
        nav: navigate,
        popup: popup,
        back: back,
        handleForward: handleForward,
    };
})(window, jQuery);
