import { useFetchAll } from '@Page/LogisticsV2/hooks/api';
import { usePaginatedDataFetcher } from '@Page/LogisticsV2/hooks/fetcher';
import {
    ProductGroupDTO,
    ResaleProductDTO,
} from '@Page/LogisticsV2/types/dtoTypes';
import { ApiUrl } from '@Page/LogisticsV2/utils/enums';
import {
    mapObjectToURLSearchParams,
    mapProductGroupDTOtoTreeData,
} from '@Page/LogisticsV2/utils/mappers';
import { useState } from 'react';

export function useStocktakingDetailsProductSelector() {
    const [productGroupId, setProductGroupId] = useState<number | undefined>(
        undefined
    );
    const data = usePaginatedDataFetcher<ResaleProductDTO>(
        ApiUrl.PRODUCT_QUERY,
        {
            fields: 'id,name,number,elNumber,nrfNumber,ean,costExcludingVatCurrency,stockOfGoods',
            isInactive: 'false',
            enabledForWarehouse: 'true',
            isSupplierProduct: 'false',
            productGroupId: productGroupId?.toString(),
        }
    );
    return {
        paginatedData: data,
        selectedGroup: productGroupId,
        onChangeGroupFilter: setProductGroupId,
    };
}

export function useNodes() {
    const { data: productGroupDTOs } = useFetchAll<ProductGroupDTO[]>(
        moduleLogistics ? ApiUrl.PRODUCT_GROUP : undefined,
        moduleLogistics
            ? mapObjectToURLSearchParams({ fields: '*,parentGroup(*)' })
            : undefined,
        { revalidateOnFocus: false, shouldRetryOnError: false }
    );
    return mapProductGroupDTOtoTreeData(productGroupDTOs ?? []);
}
