import { ModelAPIV1 } from '@General/types/Api';
import { CreateButtonComponent, Option } from '../../types';
import { JSPDropdownFetcher } from '../JSPDropdownFetcher';
import { ApiResultMapperNoUndefinedResult } from '@General/Provider';
import * as React from 'react';
import { createCreateNewButtonWithLabelText } from '@Component/DropDown/CreateNewButton/CreateNewButton';

interface EmployeeCategoryEntry extends ModelAPIV1 {
    readonly displayName: string;
}

const employeeCategoryMapper: ApiResultMapperNoUndefinedResult<EmployeeCategoryEntry> =
    (item) => ({
        value: item.id + '',
        displayName: item.displayName,
        item,
    });

class EmployeeCategoryFetcher extends JSPDropdownFetcher<EmployeeCategoryEntry> {
    asyncJsonrpcGetter: Function;

    constructor(scope: HTMLDivElement, queryAttributes: Record<string, any>) {
        super(scope, queryAttributes, employeeCategoryMapper);
        this.asyncJsonrpcGetter = asyncrpc.EmployeeCategory.get;
    }

    getMarshallSpec() {
        return window.marshallSpec('id', 'displayName');
    }

    getParams(): ObjectIndex {
        return {};
    }

    async fetchList(
        params: ObjectIndex
    ): Promise<Option<EmployeeCategoryEntry>[]> {
        return new Promise((resolve, reject) => {
            try {
                jsonrpc.EmployeeCategory.searchForEmployeeCategories(
                    (result: EmployeeCategoryEntry[] | null) =>
                        resolve((result ?? []).map(employeeCategoryMapper)),
                    this.getMarshallSpec(),
                    window.loginCompanyId,
                    params.name || '',
                    this.tlxSelectMaxRows,
                    0
                );
            } catch (e) {
                defaultJsonRpcExceptionHandler(e);
                reject(e);
            }
        });
    }

    createCreateNewButton(
        setSelected: (id: number) => void,
        dropdownTrackingId?: string
    ): CreateButtonComponent | undefined {
        if (!isAuth.createEmployeeCategory) {
            return undefined;
        }
        const textKey = 'text_employee_category';
        const text =
            getMessage('text_new') + ' ' + getMessage(textKey).toLowerCase();
        return createCreateNewButtonWithLabelText(
            setSelected,
            text,
            'updateEmployeeCategory',
            (dropdownTrackingId ? dropdownTrackingId + ' - ' : 'Dropdown - ') +
                getLocaleMessage('en_GB', textKey)
        );
    }
}

export const exports = {
    fetcher: EmployeeCategoryFetcher,
    type: 'EmployeeCategory',
    link: (id: number, contextId: number) =>
        `/execute/updateEmployeeCategory?employeeCategoryId=${id}&contextId=${contextId}`,
};
