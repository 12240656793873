export const stringUtils = (function () {
    const wordSplitter = /(\d+|\D+)/g;
    const numberFinder = /(\d+)/;

    // Math.sign polyfill
    if (!Math.sign) {
        Math.sign = function (x) {
            // If x is NaN, the result is NaN.
            // If x is -0, the result is -0.
            // If x is +0, the result is +0.
            // If x is negative and not -0, the result is -1.
            // If x is positive and not +0, the result is +1.
            x = +x; // convert to a number
            if (x === 0 || isNaN(x)) {
                return Number(x);
            }
            return x > 0 ? 1 : -1;
        };
    }

    /**
     * Comparison function for sorting that is aware of numbers and locale.
     * Made to reflect java/no.tripletex.common.util.StringUtil.splitCompare().
     *
     * @param stringA
     * @param stringB
     * @param ascending true by default. set to false to get descending ordering.
     * @param caseReordering true by default. set to false to turn of case reordering. True: a < A, False: a > A.
     * @returns {number} -1 if a is considered SMALLER than b,
     *                   0 if they are considered to be EQUAL,
     *                   1 if a is considered to be BIGGER than b.
     */
    function splitCompare(
        stringA: string,
        stringB: string,
        ascending?: boolean,
        caseReordering?: boolean
    ): number {
        if (typeof ascending === 'undefined') {
            ascending = true;
        }
        if (typeof caseReordering === 'undefined') {
            caseReordering = true;
        }

        if (stringA === undefined || stringA === null) {
            if (stringB === undefined || stringB === null) {
                if (stringA === stringB) {
                    return 0;
                } else {
                    return stringA === null ? -1 : 1;
                }
            } else {
                return 1;
            }
        }

        if (stringB === undefined || stringB === null) {
            return -1;
        }

        const arrayA = splitOnWordNumbers(stringA) || [];
        const arrayB = splitOnWordNumbers(stringB) || [];

        const numA = getIntegerBoolArray(arrayA);
        const numB = getIntegerBoolArray(arrayB);

        const commonCount = Math.min(arrayA.length, arrayB.length);
        let ret = 0;

        for (let i = 0; i < commonCount; i++) {
            const a = arrayA[i];
            const b = arrayB[i];

            if (!numA[i] && !numB[i]) {
                // TODO access context and use the current user's locale possibly?
                ret = ascending ? a.localeCompare(b) : b.localeCompare(a);

                // The case ordering property is wrong in comparison to the java version.
                // The option for case ordering in localeCompare is currently only partially supported.
                if (
                    caseReordering &&
                    ret !== 0 &&
                    a.toLowerCase() === b.toLowerCase()
                ) {
                    ret = -ret;
                }
            } else if (numA[i] && numB[i]) {
                ret = ascending
                    ? compareNumber(parseFloat(a), parseFloat(b))
                    : compareNumber(parseFloat(b), parseFloat(a));
            } else if (numA[i]) {
                ret = ascending ? -1 : 1;
            } else {
                ret = ascending ? 1 : -1;
            }

            if (ret !== 0) {
                return ret;
            }
        }

        if (arrayA.length < arrayB.length) {
            ret = ascending ? -1 : 1;
        } else if (arrayA.length > arrayB.length) {
            ret = ascending ? 1 : -1;
        }

        return ret;
    }

    /**
     *
     * @param list
     * @returns {Array}
     */
    function getIntegerBoolArray(wordList: string[]): boolean[] {
        const result = [];

        for (let i = 0; i < wordList.length; i++) {
            result.push(numberFinder.test(wordList[i]));
        }

        return result;
    }

    /**
     * Does a 3 way number comparison
     * @param a
     * @param b
     * @returns {number} -1 if a is considered SMALLER than b,
     *                   0 if they are considered to be EQUAL,
     *                   1 if a is considered to be BIGGER than b.
     */
    function compareNumber(a: number, b: number) {
        return Math.sign(a - b);
    }

    /**
     *
     * @param s
     */
    function splitOnWordNumbers(s: string): string[] {
        return s.match(wordSplitter) as string[];
    }

    /**
     * Replaces tabs and new line with space for passed text, and trims front and back
     *
     * @param text String to trim
     * @returns {*|string} Trimed string where tabs and newlines are replaced with regular space
     */
    function trimTabsAndNewLine(text: string): string | void {
        if (!text || typeof text !== 'string') {
            return;
        }
        text = text
            .replace(/\t/g, ' ')
            .replace(/\r\n/g, ' ')
            .replace(/\r/g, ' ')
            .replace(/\n/g, ' ')
            .trim();
        return text;
    }

    // Code copy-pasted from the is-string npm package.
    // https://github.com/inspect-js/is-string/blob/main/index.js
    const strValue = String.prototype.valueOf;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const tryStringObject = function tryStringObject(value: any) {
        try {
            strValue.call(value);
            return true;
        } catch (e) {
            return false;
        }
    };
    const toStr = Object.prototype.toString;
    const strClass = '[object String]';
    const hasToStringTag =
        typeof Symbol === 'function' && typeof Symbol.toStringTag === 'symbol';

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function isString(value: any): value is string {
        if (typeof value === 'string') {
            return true;
        }
        if (typeof value !== 'object') {
            return false;
        }
        return hasToStringTag
            ? tryStringObject(value)
            : toStr.call(value) === strClass;
    }

    return {
        splitCompare: splitCompare,
        splitOnWordNumbers: splitOnWordNumbers,
        trimTabsAndNewLine: trimTabsAndNewLine,
        isString: isString,
    };
})();
