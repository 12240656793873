import * as React from 'react';
import { PageLoader } from '@Component/PageLoader';
import { RouteData } from '@General/Router';
import { Team } from '@General/Team';
import { renderComponent } from '@General/renderComponent';

/**
 * The outside part of the bundle shear line.
 * The bundle split happens because of the <code>import('./SomePage')</code> call.
 * The PageLoader both sets up an error boundary (meaning a crash in the React code inside won't crash the page)
 * and handles showing a loader.
 *
 * @see PageLoader
 * @see renderComponent
 */

const Page: React.FC = (props) => (
    <PageLoader<{}>
        loader={async () => import('./SalaryPage').then((m) => m.page)}
        props={props}
        adjustWrapperDiv
    />
);

export const route: RouteData = {
    component: Page,
    path: '/execute/salary',
    team: Team.TSK,
    documentationComponent: 157,
    auth: () => isAuth.salaryPayment,
    supportsXLS: true,
};
