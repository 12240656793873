import * as React from 'react';

import {
    CommandHandler as CommandHandlerFn,
    useCommandHandler,
} from '@General/Command';

export interface CommandHandlerProps<C extends string, T> {
    commandId: C;
    handler: CommandHandlerFn<C, T>;
}

/**
 * Bind a {@link CommandHandler} to a {@link Command} as a React FunctionalComponent.
 *
 * @see CommandHandler
 */
export function CommandHandler<C extends string, T>(
    props: React.PropsWithChildren<CommandHandlerProps<C, T>>
) {
    useCommandHandler(props.commandId, props.handler);
    return (props.children as React.ReactElement) ?? null;
}
