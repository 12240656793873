import { invokeAPI, useInfiniteScrollData } from '@Page/LogisticsV2/hooks/api';
import useSessionStorage from '@Page/LogisticsV2/hooks/useSessionStorage';
import { MapModel } from '@Page/LogisticsV2/types/returnTypes';
import { ApiUrl, MethodType, SortingType } from '@Page/LogisticsV2/utils/enums';
import { getIdFromUrl, getURL } from '@Page/LogisticsV2/utils/functions';
import {
    mapFieldsToPurchaseOrder,
    mapObjectToURLSearchParams,
} from '@Page/LogisticsV2/utils/mappers';
import { useTableColumnsSetting } from '@tlx/astro-shared';
import { useEffect, useState } from 'react';
import {
    PurchaseOrderDTO,
    PurchaseOrderIncomingInvoiceRelationDTO,
    PurchaseOrderInternalDTO,
} from './types';
import { usePurchaseOrderOverviewFilter } from './usePurchaseOrderOverviewFilter';

import { defaultColumnsInPurchaseOrderOverview } from './DisplayOptions/utils';
import { getPurchaseOrdersFields } from '../utils';

export function usePurchaseOrder() {
    const supplierId =
        document.querySelector<HTMLInputElement>(
            '#customerIdForProductOverview'
        )?.value ?? null;
    const projectId = getIdFromUrl('projectId');
    const [selectedPurchaseOrders, setSelectedPurchaseOrders] = useState<
        MapModel<PurchaseOrderDTO>
    >({});
    const onChange = () => {
        setSelectedPurchaseOrders({});
    };
    const filters = usePurchaseOrderOverviewFilter();
    const [sortBy, setSortBy] = useSessionStorage<SortingType>(
        'goldshark_pooverview_sorting',
        SortingType.NUMBER_DESCENDING
    );

    const enabledColumnsForPurchaseOrderOverview = useTableColumnsSetting(
        `columns-purchase-order-overview-${window.loginEmployeeId}`,
        defaultColumnsInPurchaseOrderOverview
    );

    const [totalAmount, setTotalAmount] = useState<PurchaseOrderInternalDTO[]>(
        []
    );
    const [supplierInvoices, setSupplierInvoices] = useState<
        PurchaseOrderIncomingInvoiceRelationDTO[]
    >([]);

    const getStatusIds = () => {
        const statusIds = [...filters.statusFilter];
        if (statusIds.includes('3')) {
            statusIds.push('4');
            statusIds.push('5');
        }
        return statusIds;
    };

    const projectAndOrder = filters.orderFilter
        .concat(filters.projectFilter)
        .filter((value) => value !== '');

    const { data, error, hasMore, isLoading, loadMore, refresh } =
        useInfiniteScrollData<PurchaseOrderDTO>(
            ApiUrl.PURCHASE_ORDER_QUERY,
            {
                fields: getPurchaseOrdersFields(
                    enabledColumnsForPurchaseOrderOverview
                ),
                statusIds: getStatusIds(),
                supplierIds:
                    supplierId !== null
                        ? supplierId
                        : filters.supplierFilter.filter(
                              (value) => value !== ''
                          ),
                projectIds: projectId ?? projectAndOrder,
                supplierProductIds: filters.supplierProductsFilter.filter(
                    (value) => value !== ''
                ),
                sorting: sortBy,
                query: filters.query,
            },
            async (data) => {
                const orderOutIds = data.map((po) => po.id);
                const supplierInvoiceURL = getURL(
                    ApiUrl.PURCHASE_ORDER_INCOMING_INVOICE,
                    mapObjectToURLSearchParams({
                        fields: 'orderOut(id),voucher(number, tempNumber, year)',
                        orderOutId: orderOutIds,
                    })
                );
                if (supplierInvoiceURL) {
                    const result = await invokeAPI(
                        supplierInvoiceURL,
                        MethodType.GET
                    );
                    if (result.data) {
                        const supplierInvoicesData = await result.data.json();
                        setSupplierInvoices((prev) => [
                            ...prev,
                            ...supplierInvoicesData.values,
                        ]);
                    }
                }
                const totalAmountURL = getURL(
                    ApiUrl.PURCHASE_ORDER_INTERNAL,
                    mapObjectToURLSearchParams({
                        fields: 'id,totalAmount',
                        ids: orderOutIds,
                    })
                );
                if (totalAmountURL) {
                    const result = await invokeAPI(
                        totalAmountURL,
                        MethodType.GET
                    );
                    if (result.data) {
                        const totalAmountData = await result.data.json();
                        if (
                            totalAmountData.values &&
                            totalAmountData.values.length > 0
                        ) {
                            setTotalAmount((prev) => [
                                ...prev,
                                ...totalAmountData.values,
                            ]);
                        }
                    }
                }
            },
            {
                revalidateFirstPage: false,
            }
        );

    const handleLineSelection = (line: PurchaseOrderDTO) => {
        const newSelectedLines = { ...selectedPurchaseOrders };
        if (line.id in newSelectedLines) {
            delete newSelectedLines[line.id];
        } else {
            newSelectedLines[line.id] = line;
        }
        setSelectedPurchaseOrders(newSelectedLines);
    };

    const handleSelectAll = (isChecked: boolean) => {
        const newSelectedLines = { ...selectedPurchaseOrders };
        if (!isChecked) {
            data?.forEach((item) => {
                newSelectedLines[item.id] = item;
            });
        } else {
            data?.forEach((item) => {
                delete newSelectedLines[item.id];
            });
        }
        setSelectedPurchaseOrders(newSelectedLines);
    };

    const onRefresh = async () => {
        await refresh();
    };

    const purchaseOrders = data?.map((purchaseOrder) =>
        mapFieldsToPurchaseOrder(
            purchaseOrder,
            supplierInvoices ?? [],
            totalAmount ?? []
        )
    );

    async function handleExport() {
        const { data } = await invokeAPI(
            getURL(
                ApiUrl.PURCHASE_ORDER_EXPORT,
                mapObjectToURLSearchParams({
                    query: filters.query,
                    statusIds: getStatusIds(),
                    supplierIds:
                        supplierId !== null
                            ? supplierId
                            : filters.supplierFilter.filter(
                                  (value) => value !== ''
                              ),
                    projectIds: projectId ?? projectAndOrder,
                    supplierProductIds: filters.supplierProductsFilter.filter(
                        (value) => value !== ''
                    ),
                    sorting: sortBy,
                    columns: enabledColumnsForPurchaseOrderOverview.join(','),
                    fields: '*',
                })
            ) ?? '',
            MethodType.GET
        );
        if (data) {
            const currentUrl = window.location.href;
            const blob = await data.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            // the filename you want
            a.download = getMessage('text_orders_out') + '.xlsx';
            document.body.appendChild(a);
            a.click();
            window.history.replaceState(null, '', currentUrl);
        }
    }

    const exportInterceptor = async (event: TlxExportMenuExportEvent) => {
        if (event.detail.type === 'xls') {
            event.stopPropagation();
            await handleExport();
        }
    };

    useEffect(() => {
        window.addEventListener('tlx:export-menu:export', exportInterceptor, {
            capture: true,
        });
        return () => {
            window.removeEventListener(
                'tlx:export-menu:export',
                exportInterceptor,
                {
                    capture: true,
                }
            );
        };
    }, [exportInterceptor]);
    window.app.runCES('logistics_purchase_order_overview');

    return {
        columns: enabledColumnsForPurchaseOrderOverview,
        data: purchaseOrders ?? [],
        error,
        filters,
        hasMore,
        isLoading,
        loadMore,
        selectedLines: selectedPurchaseOrders,
        sortBy: sortBy,
        onChangeLineSelection: handleLineSelection,
        onChangeSelectedLines: setSelectedPurchaseOrders,
        onToggleSelectAll: handleSelectAll,
        onChangeSortBy: setSortBy,
        onChange,
        onRefresh,
    };
}
