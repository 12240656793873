import useSWR from 'swr';
import { ListResponse } from '../../../../hooks/fetch/types';
import { DistributionKey } from '@Page/DistributionKeys/models/DistributionKeys';
import { createAPIRequest } from '../../../../hooks/fetch/createAPIRequest';
import { createAPIResponse } from '../../../../hooks/fetch/createAPIResponse';

export function useFetchDistributionKeys() {
    const { data, error, mutate } = useSWR<ListResponse<DistributionKey>>(
        `/v2/distributionKey`,
        fetcher
    );

    return {
        distributionKeys: data?.values,
        mutate,
        error: error as unknown,
    };
}

async function fetcher(url: string): Promise<ListResponse<DistributionKey>> {
    const request = createAPIRequest(url);
    const response = await fetch(request);
    return await createAPIResponse<ListResponse<DistributionKey>>(
        request,
        response
    );
}
