import { invokeAPI } from '@Page/LogisticsV2/hooks/api';
import { ResaleProductDTO } from '@Page/LogisticsV2/types/dtoTypes';
import { ApiUrl, MethodType } from '@Page/LogisticsV2/utils/enums';
import _ from 'lodash';

export async function createNewProductWithGroupRelation(
    productInfo: Partial<ResaleProductDTO>,
    productGroupId: number | undefined
) {
    const { data, error } = await invokeAPI(
        ApiUrl.PRODUCT,
        MethodType.POST,
        JSON.stringify(productInfo)
    );
    if (data) {
        const jsonData = await data.json();
        if (productGroupId !== undefined && productGroupId > 0) {
            await createGroupRelation({
                product: { id: jsonData.value.id },
                productGroup: { id: productGroupId },
            });
        }
        return { data: jsonData, error };
    }
    return { data: undefined, error };
}

const createGroupRelation = async (relation: any) => {
    const response = await invokeAPI(
        ApiUrl.PRODUCT_GROUP_RELATIONS,
        MethodType.POST,
        JSON.stringify(relation)
    );
};

export function getAllProducts(
    products: ResaleProductDTO[],
    newProducts: ResaleProductDTO[]
): ResaleProductDTO[] {
    return _.uniqBy([...products, ...newProducts], 'id');
}
