import { createStore, IModuleStore } from 'redux-dynamic-modules';
import { getSagaExtension } from 'redux-dynamic-modules-saga';
import { composeWithDevTools } from 'redux-devtools-extension';

import { GeneralGlobalState } from './types/General';
import { globalModules } from './GlobalModules';

function createTlxStore() {
    const store: IModuleStore<GeneralGlobalState> = createStore(
        {
            extensions: [getSagaExtension()],
            advancedComposeEnhancers: composeWithDevTools({}),
        },
        ...globalModules
    );

    return store;
}

export const store = createTlxStore();
