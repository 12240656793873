// eslint is not smart enough to understand
/* eslint-disable no-use-before-define */

import { AnyAction } from 'redux';
import { store } from './createStore';

type Specification = {
    [key: string]: SpecificationEntry;
};

export type SpecificationEntry = boolean | Specification;

/*
Ideally this should be a `type` but because of a currently existing limitation these have to be interfaces
See https://github.com/Microsoft/TypeScript/issues/3496#issuecomment-128553540
 */
type DataArray = Array<DataEntry>;

type DataObject = {
    [key: string]: DataEntry;
};

export type DataEntry =
    | string
    | number
    | boolean
    | DataObject
    | DataArray
    | void;

/**
 * Applies the given marshall specification to the given data.
 * The result can then be used with JsonRPC form POST operations.
 *
 * THe intended use of this is only for preparing data for form POST's.
 *
 * See no.tripletex.service.redux.ReduxMarshallService
 *
 * GURU client side structural verification?
 *
 * @param data         The DataEntry to prepare.
 * @param marshallSpec The SpecificationEntry to use.
 *
 * @returns The now prepared DataEntry.
 *
 * @author tellef
 * @date 16.11.2018
 */
function marshall(
    data: DataEntry,
    marshallSpec: SpecificationEntry
): DataEntry {
    if (Array.isArray(data)) {
        return marshallArray(data, marshallSpec);
    } else if (data === Object(data) && marshallSpec === Object(marshallSpec)) {
        // Perfect!
        return marshallObject(
            data as DataObject,
            marshallSpec as Specification
        );
    }
    return marshallProperty(data, marshallSpec);
}

/**
 * Part of marshall. Handles arrays.
 *
 * @param data         The DataEntry to prepare.
 * @param marshallSpec The SpecificationEntry to use.
 *
 * @returns The now prepared DataArray.
 *
 * @author tellef
 * @date 20.11.2018
 */
function marshallArray(
    data: DataArray,
    marshallSpec: SpecificationEntry
): DataArray | void {
    const result: DataArray = data
        .map((entry) => marshall(entry, marshallSpec))
        .filter((entry) => entry !== undefined);
    return result.length === 0 ? undefined : result;
}

/**
 * Part of marshall. Handles objects.
 *
 * @param data         The DataObject to prepare.
 * @param marshallSpec The Specification to use.
 *
 * @returns The now prepared DataObject.
 *
 * @author tellef
 * @date 20.11.2018
 */
function marshallObject(
    data: DataObject,
    marshallSpec: Specification
): DataObject | void {
    const result: DataObject = {};
    Object.keys(data).forEach((key) => {
        const entry: DataEntry = marshall(data[key], marshallSpec[key]);
        if (entry !== undefined) {
            result[key] = entry;
        }
    });
    return Object.keys(result).length === 0 ? undefined : result;
}

/**
 * Part of marshall. Handles the individual properties.
 *
 * @param data         The DataEntry to prepare.
 * @param marshallSpec The SpecificationEntry to use.
 *
 * @returns The now prepared DataEntry.
 *
 * @author tellef
 * @date 20.11.2018
 */
function marshallProperty(
    data: DataEntry,
    marshallSpec: SpecificationEntry
): DataEntry {
    return marshallSpec === true && data !== null ? data : undefined;
}

/**
 * Wrapper for dispatch with simple error handling to be used in legacy code.
 * If the caller needs to be aware of errors then call dispatch directly.
 *
 * @param action The action to dispatch to the Redux store.
 *
 * @author tellef
 * @date 2019-03-15
 */
function dispatch(action: AnyAction) {
    try {
        store.dispatch(action);
    } catch (e) {
        logException('Exception in Redux store dispatch', e);
    }
}

export const legacyAdapter = {
    marshall,
    dispatch,
};
