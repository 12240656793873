export function UploadFileIcon(props: any) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={100}
            height={100}
            viewBox="0 0 100 100"
            fill="none"
            {...props}
        >
            <path
                d="M42.002 53.422l8.594-8.523h1.146l9.74 8.523"
                stroke="#0A41FA"
                strokeWidth={4}
                strokeMiterlimit={16}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M53.461 45.508a2 2 0 00-4 0h4zm-2 29.073h-2a2 2 0 002 2v-2zm22.45 0v2h.037l.036-.001-.073-1.999zm-.523-25.055l-1.991-.192-.21 2.192h2.2v-2zm-44.012-5.187l-.282 1.98 1.972.282.29-1.971-1.98-.29zm-.365 30.242l-.014 2h.014v-2zm20.45-29.073v29.073h4V45.508h-4zm2 31.073h22.45v-4h-22.45v4zm22.523-.001C81.76 76.297 88 69.906 88 62.053h-4c0 5.686-4.522 10.325-10.162 10.53l.145 3.997zM88 62.053c0-7.858-6.493-14.527-14.528-14.527v4c5.796 0 10.528 4.848 10.528 10.527h4zM73.473 47.526h-.085v4h.085v-4zm1.906 2.191c.068-.71.094-1.42.094-2.115h-4c0 .596-.023 1.176-.076 1.732l3.982.383zm.094-2.115c0-13.351-10.83-24.182-24.17-24.182v4c11.13 0 20.17 9.038 20.17 20.182h4zM51.303 23.42c-12.147 0-22.193 8.96-23.905 20.629l3.957.58c1.429-9.734 9.815-17.21 19.948-17.21v-4zM29.659 42.36c-.245-.036-.453-.033-.526-.033v4h.045a.781.781 0 01-.084-.008l.565-3.96zm-.526-.033C19.663 42.327 12 50.005 12 59.46h4c0-7.248 5.875-13.133 13.133-13.133v-4zM12 59.46c0 9.455 7.599 17.052 16.997 17.12l.029-3.999A13.097 13.097 0 0116 59.461h-4zm17.011 17.121h14.817v-4H29.01v4z"
                fill="#0A41FA"
            />
            <path
                d="M18.638 63.236c.47-1.529.485-3.142.029-4.643a7.982 7.982 0 00-2.551-3.838 7.668 7.668 0 00-4.276-1.724 8.045 8.045 0 00-4.56.96 8.257 8.257 0 00-3.307 3.322 8.087 8.087 0 00-.94 4.56 7.906 7.906 0 001.752 4.283 7.757 7.757 0 003.848 2.53 8.017 8.017 0 006.086-.68 8.165 8.165 0 003.92-4.77z"
                fill="#F6AED5"
            />
            <path
                d="M75.857 87.708A20.001 20.001 0 0098.143 70.29l-9.527-1.168a10.4 10.4 0 01-11.591 9.058l-1.168 9.527z"
                fill="#FCE6AF"
            />
            <path
                d="M73.606 11a20 20 0 007.935 27.148l9.607-17.541L73.606 11z"
                fill="#62B7D0"
            />
        </svg>
    );
}
