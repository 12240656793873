import {
    JSONRPC_FORM_POST_SUCCSESS,
    JSONRPC_VALIDATION_MESSAGES,
} from '../constants';
import { ErrorState, PropertyErrorMap } from '../types/Error';

const initialState: ErrorState = {
    property: {},
    general: [],
};

/**
 * JsonRPC validation messages comes in the form { [key: string]: Array<string> }
 *     where the key is `clientId.property` when it is an object.
 *     where the key is `property` when it is a field on the form directly.
 *
 * `clientId` is used to map server side exceptions to fields on the client side for display.
 * It maps directly to {@link no.tripletex.tcp.json.RemoteObject#clientId}
 * and is used by {@link no.tripletex.common.exception.ValidationException#getMessage}
 * to generate the messages the client receives when the JsonRPC operation throws validation exceptions.
 *
 * `clientId` is specified client side, either generated (as in the old system) or set explicitly (as in React / Redux).
 * `$.setClientIds` in `tlxUtil.js` is responsible for generating these values normally.
 *
 * JsonRPC property messages
 *   {
 *     "activityForms[0].activity.number": ["Invalid number"],
 *     "activityForms[0].activityExt.startDate": ["Can't be after end date", "Closed period"],
 *     "activityForms[0].activityExt.endDate": ["Can't be before start date"],
 *     "title": ["Can't be empty"]
 *   }
 * turns in to
 *   {
 *     "activityForms[0].activity": {
 *       "number": ["Invalid number"]
 *     },
 *     "activityForms[0].activityExt": {
 *       "startDate": ["Can't be after end date", "Closed period"],
 *       "endDate": ["Can't be before start date"]
 *     },
 *     "title": {
 *         "title": ["Can't be empty"]
 *     }
 *   }
 *
 * @param propertyMessages
 *
 * @author tellef
 * @date 2019-01-29
 */
function transformPropertyMessages(propertyMessages: {
    [key: string]: Array<string>;
}): PropertyErrorMap {
    const result: PropertyErrorMap = {};

    if (!propertyMessages) {
        return result;
    }

    for (const [key, messages] of Object.entries(propertyMessages)) {
        const split = key.split('.');
        const property = split.pop()!;
        const clientId = split.length === 0 ? property : split.join('.');

        result[clientId] = { ...result[clientId], [property]: messages };
    }

    return result;
}

export default (state: ErrorState = initialState, action: any) => {
    switch (action.type) {
        case JSONRPC_VALIDATION_MESSAGES:
            return {
                property: transformPropertyMessages(action.propertyMessages),
                general: action.generalMessages,
            };
        case JSONRPC_FORM_POST_SUCCSESS:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};
