import React, { ChangeEvent, ReactNode, useState } from 'react';
import { NumberFormat } from '@Page/LogisticsV2/components/NumberFormat/NumberFormat';
import { nav } from '../../../../../../../../js/modules/navigation';
import { getReportURL } from '@Page/LogisticsV2/pages/Stocktaking/utils';
import { StocktakingDTO } from '@Page/LogisticsV2/types/dtoTypes';
import { Group, Input, Label, ToggleGroup } from '@tlx/atlas';
import {
    getValidationMessagesFromResponse,
    validateDate,
} from '@Page/LogisticsV2/utils/functions';
import { doChangeDate } from '@Page/LogisticsV2/pages/Stocktaking/details2/StocktakingDetailsActions';
import { AutoSaveStatusType } from '@Page/LogisticsV2/utils/enums';
import useLocalStorage from '../../../../../../util/storage';
import { OptionalComponent } from '@Page/LogisticsV2/pages/Stocktaking/details2/StocktakingDetailsComponents';
import { useDeviceType } from '@Page/LogisticsV2/hooks/useDeviceType';

export function StocktakingDetailsSummaryLayout({
    children,
}: {
    children: ReactNode;
}) {
    return (
        <div className="atl-flex atl-flex-col atl-gap-16 atl-bg-white atl-border atl-rounded atl-p-16 atl-border-grey-20 atl-my-32">
            {children}
        </div>
    );
}

export function StocktakingDetailsCompletedSummary({
    stocktaking,
}: {
    stocktaking?: StocktakingDTO;
}) {
    const device = useDeviceType();
    return (
        <>
            <OptionalComponent id={'desktop'} deviceType={device}>
                <div className="atl-flex atl-gap-32">
                    <div className="atl-flex atl-flex-col atl-gap-8">
                        <div className="atl-text-base atl-font-medium">
                            {getMessage('text_warehouse')}
                        </div>
                        <div className="atl-text-lg atl-flex atl-items-center">
                            {stocktaking?.inventory.name}
                        </div>
                    </div>
                    <div className="atl-flex atl-flex-col atl-gap-8">
                        <div className="atl-text-base atl-font-medium">
                            {getMessage('text_date')}
                        </div>
                        <div className="atl-text-lg atl-flex atl-items-center">
                            {stocktaking?.date}
                        </div>
                    </div>
                    <div className={'atl-flex atl-flex-col atl-gap-8'}>
                        <div className="atl-text-base atl-font-medium">
                            {getMessage('text_discrepancy_sum')}
                        </div>
                        <NumberFormat
                            className="atl-text-lg"
                            id="stocktaking-details-completed-discrepancy-sum"
                            value={stocktaking?.discrepancySum ?? 0.0}
                            options={{
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            }}
                        />
                    </div>
                    <div className={'atl-flex atl-flex-col atl-gap-8'}>
                        <div className="atl-text-base atl-font-medium">
                            {getMessage('text_warehouse_value')}
                        </div>
                        <NumberFormat
                            className="atl-text-lg"
                            id="stocktaking-details-completed-warehouse-value"
                            value={stocktaking?.warehouseValue ?? 0.0}
                            options={{
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            }}
                        />
                    </div>
                    <div className="atl-flex atl-flex-col atl-gap-8">
                        <div className="atl-text-base atl-font-medium">
                            {getMessage('text_stock')}
                        </div>
                        <div
                            className="atl-text-blue-80 atl-text-lg atl-flex atl-items-center"
                            onClick={() =>
                                nav.popup(
                                    getReportURL(
                                        stocktaking?.inventory.id.toString() ??
                                            '',
                                        stocktaking?.date ?? ''
                                    )
                                )
                            }
                            style={{ cursor: 'pointer' }}
                        >
                            {getMessage('text_see_stock_value')}
                        </div>
                    </div>
                </div>
            </OptionalComponent>
            <OptionalComponent id={'mobile'} deviceType={device}>
                <div className="atl-flex atl-flex-col atl-gap-32">
                    <div className="atl-flex atl-gap-32">
                        <div className="atl-flex atl-flex-col atl-gap-8 atl-flex-1">
                            <div className="atl-text-base atl-font-medium">
                                {getMessage('text_warehouse')}
                            </div>
                            <div className="atl-text-lg atl-flex atl-items-center">
                                {stocktaking?.inventory.name}
                            </div>
                        </div>
                        <div className="atl-flex atl-flex-col atl-gap-8 atl-flex-1">
                            <div className="atl-text-base atl-font-medium">
                                {getMessage('text_date')}
                            </div>
                            <div className="atl-text-lg atl-flex atl-items-center">
                                {stocktaking?.date}
                            </div>
                        </div>
                    </div>
                    <div className="atl-flex atl-gap-32">
                        <div
                            className={
                                'atl-flex atl-flex-col atl-gap-8 atl-flex-1'
                            }
                        >
                            <div className="atl-text-base atl-font-medium">
                                {getMessage('text_warehouse_value')}
                            </div>
                            <NumberFormat
                                className="atl-text-lg"
                                id="stocktaking-details-completed-warehouse-value"
                                value={stocktaking?.warehouseValue ?? 0.0}
                                options={{
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                }}
                            />
                        </div>
                        <div
                            className={
                                'atl-flex atl-flex-col atl-gap-8 atl-flex-1'
                            }
                        >
                            <div className="atl-text-base atl-font-medium">
                                {getMessage('text_discrepancy_sum')}
                            </div>
                            <NumberFormat
                                className="atl-text-lg"
                                id="stocktaking-details-completed-discrepancy-sum"
                                value={stocktaking?.discrepancySum ?? 0.0}
                                options={{
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                }}
                            />
                        </div>
                    </div>
                    <div className="atl-flex atl-flex-col atl-gap-8">
                        <div className="atl-text-base atl-font-medium">
                            {getMessage('text_stock')}
                        </div>
                        <div
                            className="atl-text-blue-80 atl-text-lg atl-flex atl-items-center"
                            onClick={() =>
                                nav.popup(
                                    getReportURL(
                                        stocktaking?.inventory.id.toString() ??
                                            '',
                                        stocktaking?.date ?? ''
                                    )
                                )
                            }
                            style={{ cursor: 'pointer' }}
                        >
                            {getMessage('text_see_stock_value')}
                        </div>
                    </div>
                </div>
            </OptionalComponent>
        </>
    );
}

export function StocktakingDetailsInProgressSummary({
    stocktaking,
    onChange,
}: {
    stocktaking?: StocktakingDTO;
    onChange: (status: AutoSaveStatusType) => void;
}) {
    const [hasDateError, setHasDateError] = useState(false);
    const [internalDate, setInternalDate] = useState(stocktaking?.date ?? '');
    const [isBarcodeMode, setIsBarcodeMode] = useLocalStorage(
        `stocktakingBarcodeMode-${window.loginEmployeeId}`,
        false
    );
    const device = useDeviceType();

    const handleDateChange = async (event: ChangeEvent<HTMLInputElement>) => {
        onChange(AutoSaveStatusType.PENDING);
        const inputDate = event.target.value;
        const isValidDate = validateDate(inputDate);
        setInternalDate(inputDate);
        if (isValidDate) {
            const { error } = await doChangeDate(inputDate, stocktaking?.id);
            if (error !== undefined) {
                onChange(AutoSaveStatusType.ERROR);
                getValidationMessagesFromResponse(error);
            } else {
                onChange(AutoSaveStatusType.SAVED);
            }
            setHasDateError(false);
        } else {
            setHasDateError(true);
        }
    };
    return (
        <>
            <OptionalComponent id={'desktop'} deviceType={device}>
                <div className="atl-flex atl-gap-16">
                    <Group>
                        <Label>{getMessage('text_warehouse')}</Label>
                        <Input
                            type="text"
                            data-testid="warehouse-name-in-stocktaking-details"
                            value={stocktaking?.inventory.name}
                            disabled
                        />
                    </Group>
                    <Group>
                        <Label>{getMessage('text_date')}</Label>
                        <div className="atl-flex atl-flex-col atl-gap-8">
                            <Input
                                type="date"
                                data-testid="date-in-stocktaking-details"
                                value={internalDate}
                                onChange={handleDateChange}
                            />
                            {hasDateError && (
                                <span className="atl-text-red-100">
                                    {getMessage('validation_invalid_date')}
                                </span>
                            )}
                        </div>
                    </Group>
                    <div
                        className={
                            'atl-bg-grey-5 atl-rounded atl-flex atl-items-center atl-px-16 atl-gap-8'
                        }
                        style={{ marginTop: '27px', height: '40px' }}
                    >
                        {getMessage('text_discrepancy_sum')}
                        <NumberFormat
                            className="atl-font-bold"
                            id="stocktaking-details-completed-discrepancy-sum"
                            value={stocktaking?.discrepancySum ?? 0.0}
                            options={{
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            }}
                        />
                    </div>
                    <ToggleGroup className="atl-mt-24 atl-items-center">
                        <Input
                            data-testid="barcode-mode-stocktaking"
                            type="toggle"
                            id="barcode-mode-stocktaking"
                            onChange={() => {
                                setIsBarcodeMode(!isBarcodeMode);
                            }}
                            checked={isBarcodeMode}
                        />
                        <Label htmlFor="barcode-mode-stocktaking">
                            {getMessage('text_use_barcode_scanner')}
                        </Label>
                    </ToggleGroup>
                </div>
            </OptionalComponent>
            <OptionalComponent id={'mobile'} deviceType={device}>
                <div className="atl-flex atl-flex-col atl-gap-16">
                    <div className="atl-flex atl-gap-16">
                        <Group className="atl-flex atl-flex-col atl-min-w-0 atl-flex-1">
                            <Label>{getMessage('text_warehouse')}</Label>
                            <Input
                                type="text"
                                data-testid="warehouse-name-in-stocktaking-details"
                                value={stocktaking?.inventory.name}
                                disabled
                            />
                        </Group>
                        <Group className="atl-flex-1">
                            <Label>{getMessage('text_date')}</Label>
                            <div className="atl-flex atl-flex-col atl-gap-8">
                                <Input
                                    type="date"
                                    className={'atl-min-w-0'}
                                    data-testid="date-in-stocktaking-details"
                                    value={internalDate}
                                    onChange={handleDateChange}
                                />
                                {hasDateError && (
                                    <span className="atl-text-red-100">
                                        {getMessage('validation_invalid_date')}
                                    </span>
                                )}
                            </div>
                        </Group>
                    </div>
                    <div
                        className={
                            'atl-bg-grey-5 atl-rounded atl-flex atl-items-center atl-px-16 atl-gap-8'
                        }
                        style={{ height: '40px' }}
                    >
                        {getMessage('text_discrepancy_sum')}
                        <NumberFormat
                            className="atl-font-bold"
                            id="stocktaking-details-completed-discrepancy-sum"
                            value={stocktaking?.discrepancySum ?? 0.0}
                            options={{
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            }}
                        />
                    </div>
                    <ToggleGroup className="atl-items-center">
                        <Input
                            data-testid="barcode-mode-stocktaking"
                            type="toggle"
                            id="barcode-mode-stocktaking"
                            onChange={() => {
                                setIsBarcodeMode(!isBarcodeMode);
                            }}
                            checked={isBarcodeMode}
                        />
                        <Label htmlFor="barcode-mode-stocktaking">
                            {getMessage('text_use_barcode_scanner')}
                        </Label>
                    </ToggleGroup>
                </div>
            </OptionalComponent>
        </>
    );
}
