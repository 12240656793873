import {
    AsyncDropdownOptions,
    DropdownFilter,
    SearchFilter,
} from '@tlx/astro-shared';
import React from 'react';
import { ApiUrl } from '@Page/LogisticsV2/utils/enums';
import { Option } from '@tlx/atlas';
import { ALL_WAREHOUSES } from '@Page/LogisticsV2/pages/InventoryCorrection/overview/types';
import { useExclusiveOption } from '@Page/LogisticsV2/hooks/useExclusiveOption';

function AllWarehousesOption() {
    useExclusiveOption(ALL_WAREHOUSES);
    return (
        <Option
            value={ALL_WAREHOUSES}
            data-testid="inventory-correction-ovevriew-all-warehouse-filter"
        >
            {getMessage('text_all_warehouses')}
        </Option>
    );
}
export function InventoryCorrectionTableFilterComponents() {
    return (
        <form className="atl-flex atl-gap-16" autoComplete="off">
            <SearchFilter
                data-testid="inventory-correction-ovevriew-search-filter"
                name="query"
                label={getMessage('text_search')}
                placeholder={getMessage('text_search')}
                quick
            />
            {moduleWarehouse && (
                <DropdownFilter
                    data-testid="inventory-correction-ovevriew-warehouse-filter"
                    defaultDisplayName={getMessage('text_all_warehouses')}
                    name="warehouse"
                    label="warehouse"
                    multiple
                    quick
                >
                    <AsyncDropdownOptions url={ApiUrl.INVENTORY}>
                        <AllWarehousesOption />
                    </AsyncDropdownOptions>
                </DropdownFilter>
            )}
        </form>
    );
}
