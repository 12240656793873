import { ModelAPIV1 } from '@General/types/Api';
import { Option } from '../../types';
import { JSPDropdownFetcher } from '../JSPDropdownFetcher';
import { ApiResultMapperNoUndefinedResult } from '@General/Provider';

interface VoucherTypeEntry extends ModelAPIV1 {
    readonly displayName: string;
}

const voucherTypeMapper: ApiResultMapperNoUndefinedResult<VoucherTypeEntry> = (
    item
) => ({
    value: item.id + '',
    displayName: item.displayName,
    item,
});

class VoucherTypeFetcher extends JSPDropdownFetcher<VoucherTypeEntry> {
    asyncJsonrpcGetter: Function;

    constructor(scope: HTMLDivElement, queryAttributes: Record<string, any>) {
        super(scope, queryAttributes, voucherTypeMapper);
        this.asyncJsonrpcGetter = asyncrpc.VoucherType.get;
    }

    getParams(): ObjectIndex {
        return {
            isActive: this.getQueryAttribute('isActive', -1),
            queryId: this.getQueryAttribute('queryId', -1),
        };
    }

    async fetchList(params: ObjectIndex): Promise<Option<VoucherTypeEntry>[]> {
        return new Promise((resolve, reject) => {
            try {
                jsonrpc.VoucherType.searchForVoucherTypes(
                    (result: VoucherTypeEntry[] | null) =>
                        resolve((result ?? []).map(voucherTypeMapper)),
                    this.getMarshallSpec(),
                    window.loginCompanyId,
                    params.queryId,
                    params.isActive,
                    params.name || ''
                );
            } catch (e) {
                defaultJsonRpcExceptionHandler(e);
                reject(e);
            }
        });
    }
}

export const exports = {
    fetcher: VoucherTypeFetcher,
    type: 'VoucherType',
};
