import {
    useFetchPaginatedState,
    UseFetchPaginatedState,
} from '../../hooks/fetch/useFetchPaginatedState';
import { ListResponse } from '../../hooks/fetch/types';
import { useDebouncedQuery } from '../../hooks/useDebouncedQuery';
import useSWRInfinite, { SWRInfiniteKeyLoader } from 'swr/infinite';
import { FetcherError } from '@Component/LoadableDropdown/LoadableDropdown';
import { defaultFetcher } from '../../hooks/fetch/defaultFetcher';

const PAGE_SIZE = 25;

export type AccountWithHelpInfoDTO = {
    id: number;
    displayName: string;
    numberPretty: string;
    description?: string;
    accountHelp: AccountHelp | null;
    account: Account;
};

type AccountHelp = {
    description: string;
    tags: string[] | null;
};

type Account = {
    id?: number;
    number: number;
    name: string;
    url?: string;
};

export function useAccountHelpDropdownData(
    query: string,
    params: AccountApiQueryParams
): UseFetchPaginatedState<AccountWithHelpInfoDTO> {
    const getKey: SWRInfiniteKeyLoader = (pageIndex) =>
        getAccountAPIQueryUrl({ ...params, name: query }, pageIndex, PAGE_SIZE);

    const response = useSWRInfinite<
        ListResponse<AccountWithHelpInfoDTO>,
        FetcherError
    >(getKey, defaultFetcher, {
        fallbackData: [],
        initialSize: 0,
        revalidateFirstPage: false,
        revalidateOnFocus: false,
    });

    const state = useFetchPaginatedState(response);

    /**
     * Because we are using an IntersectionObserver to trigger loading of
     * options, the drawer would be empty immediately after opening
     * unless we assume that we are loading when size is zero.
     */
    return {
        ...state,
        isLoading: state.isLoading || response.size === 0,
    };
}

export type AccountApiQueryParams = {
    accountType?: string;
    accountSubType?: string;
    isActive?: string;
    accountShowInIncomingInvoice?: string;
    accountIncludeAllVatNone?: string;
    accountExcludeCustomerCurrentBook?: string;
    accountExcludeVendorCurrentBook?: string;
    accountExcludeEmployeeCurrentBook?: string;
    accountRequiresVatType?: string;
    name?: string;
};
function getAccountAPIQueryUrl(
    params: AccountApiQueryParams,
    pageIndex: number,
    count: number
): string {
    const url = new URL(
        '/v2/ledger/account/accountHelpQuery',
        window.location.origin
    );
    const urlParams = url.searchParams;

    if (params.accountType !== undefined) {
        urlParams.set('accountType', params.accountType);
    }
    if (params.accountSubType !== undefined) {
        urlParams.set('accountSubType', params.accountSubType);
    }
    if (params.isActive !== undefined) {
        urlParams.set('isActive', params.isActive);
    }
    const showInIncomingInvoice = Number(params.accountShowInIncomingInvoice);
    if (showInIncomingInvoice === 1) {
        urlParams.set('isApplicableForSupplierInvoice', 'true');
    } else if (showInIncomingInvoice === 0) {
        urlParams.set('isApplicableForSupplierInvoice', 'false');
    }
    if (params.accountIncludeAllVatNone !== undefined) {
        urlParams.set(
            'accountIncludeAllVatNone',
            params.accountIncludeAllVatNone
        );
    }
    if (params.accountExcludeCustomerCurrentBook !== undefined) {
        urlParams.set(
            'accountExcludeCustomerCurrentBook',
            params.accountExcludeCustomerCurrentBook
        );
    }
    if (params.accountExcludeVendorCurrentBook !== undefined) {
        urlParams.set(
            'accountExcludeVendorCurrentBook',
            params.accountExcludeVendorCurrentBook
        );
    }
    if (params.accountExcludeEmployeeCurrentBook !== undefined) {
        urlParams.set(
            'accountExcludeEmployeeCurrentBook',
            params.accountExcludeEmployeeCurrentBook
        );
    }
    urlParams.set('query', params.name ?? '');
    urlParams.set(
        'fields',
        'id,displayName,description,numberPretty,accountHelp,account(number, name)'
    );
    urlParams.set('count', count.toString());
    urlParams.set('from', (pageIndex * count).toString());
    return url.toString();
}
