import { DataTreeNode } from '@Page/LogisticsV2/components/DataTree/types';
import React, { useState } from 'react';
import {
    DataTree,
    DataTreeContent,
    DataTreeHeader,
    DataTreeToggleButton,
} from '@Page/LogisticsV2/components/DataTree/DataTree';
import { Input } from '@tlx/atlas';
import classNames from 'classnames';

export function MultipleSelectionProductGroups({
    nodes,
    selectedNodes,
    onChange,
    className,
}: {
    nodes: DataTreeNode[];
    selectedNodes: number[];
    onChange: (nodeId: number) => void;
    className?: string;
}) {
    return (
        <div
            role="tree"
            className={classNames(
                className,
                'tlx-product-group-tree atl-my-8 atl-p-8'
            )}
        >
            {nodes.map((node) => {
                return (
                    <MultipleSelectionProductGroupsItem
                        key={node.id}
                        node={node}
                        selectedNodes={selectedNodes}
                        onChange={onChange}
                        isRoot={true}
                    />
                );
            })}
        </div>
    );
}

export function MultipleSelectionProductGroupsItem({
    node,
    selectedNodes,
    onChange,
    isRoot,
}: {
    node: DataTreeNode;
    selectedNodes: number[];
    onChange: (nodeId: number) => void;
    isRoot: boolean;
}) {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <DataTree
            isRoot={isRoot}
            isOpen={isOpen}
            role={node.children.length > 0 ? 'group' : ''}
        >
            <DataTreeHeader
                role={'treeitem'}
                className={'goldshark-editable-tree-title'}
                aria-expanded={node.children.length > 0 ? isOpen : undefined}
            >
                <Input
                    className={'atl-m-8'}
                    data-testid={'productgroups-select-checkbox'}
                    type="checkbox"
                    checked={isSelected(selectedNodes, node.id)}
                    onChange={() => onChange(node.id)}
                    readOnly
                />
                {node.children.length > 0 && (
                    <DataTreeToggleButton
                        isOpen={isOpen}
                        onClick={() => setIsOpen(!isOpen)}
                        data-testid={'toggle-second'}
                        aria-label={
                            isOpen
                                ? 'toggle button expanded'
                                : 'toggle button collapsed'
                        }
                    />
                )}
                <div
                    style={{ height: '40px' }}
                    className={
                        'atl-flex atl-w-full goldshark-tree-row atl-items-center atl-text-base tlx-product-group-title-multiple-selection atl-py-8'
                    }
                    onClick={() => onChange(node.id)}
                >
                    {node.name}
                </div>
            </DataTreeHeader>
            <DataTreeContent
                isOpen={isOpen}
                role={node.children.length > 0 ? 'group' : ''}
            >
                {node.children.map((n) => (
                    <MultipleSelectionProductGroupsItem
                        key={n.id}
                        node={n}
                        selectedNodes={selectedNodes}
                        onChange={onChange}
                        isRoot={false}
                    />
                ))}
            </DataTreeContent>
        </DataTree>
    );
}

const isSelected = (selectedNodes: number[], nodeId: number) => {
    return selectedNodes.find((item) => item === nodeId) !== undefined;
};
