import no from '../../../src/main/resources/ApplicationResources.properties';
import en from '../../../src/main/resources/ApplicationResources_en_GB.properties';

export type SupportedLocale = 'no' | 'en';

export function normalizeLocale(locale: string | undefined): SupportedLocale {
    if (locale === 'en_GB' || locale === 'en') {
        return 'en';
    }
    return 'no';
}

export function getCurrentLocale(): SupportedLocale {
    return normalizeLocale(window.locale);
}

export function getMessage(key: string, ...args: string[]): string {
    const currentLocale = getCurrentLocale();
    return getLocalizedMessage(currentLocale, key, ...args);
}

export function getLocaleMessage(
    locale: string,
    key: string,
    ...args: string[]
): string {
    const normalizedLocale = normalizeLocale(locale);
    return getLocalizedMessage(normalizedLocale, key, ...args);
}

function getLocalizedMessage(locale: string, key: string, ...args: string[]) {
    let message = key;
    switch (locale) {
        case 'en':
            message = en[key] ?? key;
            break;
        case 'no':
            message = no[key] ?? key;
            break;
    }

    if (args.length > 0) {
        for (let i = 0; i < args.length; i++) {
            message = message.replace('{' + i + '}', args[i]);
        }
    }
    return message;
}
