import { Button, Icon, Modal, ModalTitle } from '@tlx/atlas';
import classNames from 'classnames';
import React from 'react';

export function ConfirmationModal({
    text,
    icon,
    className,
    onConfirm,
    onCancel,
}: {
    text: string;
    icon?: string;
    className?: string;
    onConfirm: () => void;
    onCancel: () => void;
}) {
    return (
        <Modal
            className={classNames(
                'goldshark-confirmation-modal__container',
                className
            )}
            open={true}
        >
            {icon && (
                <Icon className="goldshark-confirmation-modal__icon">
                    {icon}
                </Icon>
            )}
            <ModalTitle className="atl-my-16">
                {getMessage('text_warning')}
            </ModalTitle>
            <div className={'atl-flex atl-gap-8 atl-mb-16'}>{text}</div>
            <div className={'atl-flex atl-gap-8'}>
                <Button
                    onClick={onConfirm}
                    data-testid={'confirm-warning-modal'}
                >
                    {getMessage('text_confirm')}
                </Button>
                <Button
                    data-testid={'cancel-warning-modal'}
                    onClick={onCancel}
                    variant={'secondary'}
                >
                    {getMessage('button_cancel')}
                </Button>
            </div>
        </Modal>
    );
}
