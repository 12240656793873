import { useDeviceType } from '@Page/LogisticsV2/hooks/useDeviceType';
import { StocktakingDTO } from '@Page/LogisticsV2/types/dtoTypes';
import { ApiUrl, PageUrl } from '@Page/LogisticsV2/utils/enums';
import { StatusIcon } from '@Page/LogisticsV2/utils/generalComponents';
import { Skeleton } from '@Page/Report/Component/Skeleton/Skeleton';
import {
    Button,
    Dropdown,
    DropdownDrawer,
    DropdownOpener,
    DropdownScrollContainer,
    DropdownSearch,
    Option,
    SkeletonRow,
    SkeletonText,
} from '@tlx/atlas';
import { nav } from '../../../../../../../js/modules/navigation';
import { getStatus, StocktakingContainer } from '../components';
import { useStocktakingOverview } from './useStocktakingOverview';
import React, { useState } from 'react';
import { AsyncDropdownOptions } from '@tlx/astro-shared';
import { CreateStocktakingModal } from '@Page/LogisticsV2/pages/Stocktaking/create/StocktakingCreate';
import { useStocktakingCreate } from '@Page/LogisticsV2/pages/Stocktaking/create/useStocktakingCreate';
import classnames from 'classnames';
import { EmptyState } from '@Page/LogisticsV2/components/EmptyState/EmptyState';

export function StocktakingOverview() {
    const device = useDeviceType();
    const {
        data,
        isLoading: isLoadingData,
        warehouse,
        onWarehouseChange,
    } = useStocktakingOverview();
    const { defaultInventoryId } = useStocktakingCreate();

    const [showNewStocktakingModal, setShowNewStocktakingModal] =
        useState(false);
    const StocktakingOverviewTable =
        device === 'mobile'
            ? StocktakingOverviewMobileTable
            : StocktakingOverviewDesktopTable;
    return (
        <StocktakingContainer>
            <div className={'atl-flex atl-justify-between gs-header atl-mb-16'}>
                <div
                    id="menuHeader"
                    data-testid="header-title"
                    className="useAtlasHeader"
                >
                    <h1
                        className={classnames('clip', {
                            'atl-text-xl atl-font-bold': device === 'mobile',
                        })}
                    >
                        {getMessage('text_inventories')}
                    </h1>
                </div>
                <div className={device === 'mobile' ? 'atl-mr-16' : ''}>
                    <Button
                        autoFocus={device === 'desktop'}
                        data-testid="new-stocktaking"
                        className="goldshark-new-stocktaking__button"
                        onClick={() => setShowNewStocktakingModal(true)}
                    >
                        {getMessage('text_inventory_create')}
                    </Button>
                </div>
            </div>
            <StocktakingOverviewTable
                data={data ?? []}
                hasMultipleWarehouse={moduleWarehouse}
                isLoading={isLoadingData}
                warehouse={warehouse ?? ''}
                onFilterWarehouse={onWarehouseChange}
            />
            {showNewStocktakingModal && (
                <CreateStocktakingModal
                    inventoryId={defaultInventoryId ?? 0}
                    onClose={() => setShowNewStocktakingModal(false)}
                />
            )}
        </StocktakingContainer>
    );
}

export function StocktakingOverviewDesktopTable({
    data,
    hasMultipleWarehouse,
    isLoading,
    warehouse,
    onFilterWarehouse,
}: {
    data: StocktakingDTO[];
    hasMultipleWarehouse: boolean;
    isLoading: boolean;
    warehouse: string;
    onFilterWarehouse: (value: string) => void;
}) {
    return (
        <div className="atl-table-container goldshark-stocktaking-table">
            {hasMultipleWarehouse && (
                <form
                    autoComplete="off"
                    className="atl-p-16"
                    style={{
                        borderBottom: '1px solid var(--atl-color-grey-20)',
                    }}
                >
                    <Dropdown
                        data-testid="stocktaking-warehouse-dropdown"
                        value={warehouse}
                        onChange={(event) =>
                            onFilterWarehouse(event.target.value)
                        }
                    >
                        <DropdownOpener
                            className={'stockating-warehouse-dropdown'}
                        />
                        <DropdownDrawer>
                            <DropdownSearch />
                            <DropdownScrollContainer>
                                <AsyncDropdownOptions url={ApiUrl.INVENTORY}>
                                    <Option value={''}>
                                        {getMessage('text_all_warehouses')}
                                    </Option>
                                </AsyncDropdownOptions>
                            </DropdownScrollContainer>
                        </DropdownDrawer>
                    </Dropdown>
                </form>
            )}
            <table className="atl-table atl-w-full">
                <StocktakingOverviewDesktopHeader />
                <tbody>
                    {data.length > 0 &&
                        data.map((stocktakingData) => (
                            <StocktakingOverviewDesktopRow
                                stocktaking={stocktakingData}
                                key={stocktakingData.id}
                            />
                        ))}
                    {isLoading && data.length === 0 && (
                        <SkeletonRow>
                            <td className="atl-td">
                                <SkeletonText className="atl-w-full" />
                            </td>
                            <td className="atl-td">
                                <SkeletonText className="atl-w-full" />
                            </td>
                            <td className="atl-td">
                                <SkeletonText className="atl-w-full" />
                            </td>
                            <td className="atl-td">
                                <SkeletonText className="atl-w-full" />
                            </td>
                            <td className="atl-td">
                                <SkeletonText className="atl-w-full" />
                            </td>
                        </SkeletonRow>
                    )}
                    {!isLoading && data.length === 0 && (
                        <tr>
                            <td className="atl-bg-white" colSpan={5}>
                                <EmptyState
                                    title={getMessage('text_inventories_none')}
                                    description={getMessage(
                                        'text_stocktakings_empty_description'
                                    )}
                                    svgIcon={'generalEmptyState'}
                                    className={'atl-text-xl'}
                                />
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
}

export function StocktakingOverviewMobileTable({
    data,
    hasMultipleWarehouse,
    isLoading,
    warehouse,
    onFilterWarehouse,
}: {
    data: StocktakingDTO[];
    hasMultipleWarehouse: boolean;
    isLoading: boolean;
    warehouse: string;
    onFilterWarehouse: (value: string) => void;
}) {
    return (
        <div className="atl-table-container goldshark-stocktaking-table atl-mx-16">
            {hasMultipleWarehouse && (
                <form
                    autoComplete="off"
                    style={{
                        padding: '16px',
                        borderBottom: '1px solid var(--atl-color-grey-20)',
                    }}
                >
                    <Dropdown
                        data-testid="stocktaking-warehouse-dropdown"
                        value={warehouse}
                        onChange={(event) =>
                            onFilterWarehouse(event.target.value)
                        }
                    >
                        <DropdownOpener className={'atl-w-full'} />
                        <DropdownDrawer>
                            <DropdownSearch />
                            <DropdownScrollContainer>
                                <AsyncDropdownOptions url={ApiUrl.INVENTORY}>
                                    <Option value={''}>
                                        {getMessage('text_all_warehouses')}
                                    </Option>
                                </AsyncDropdownOptions>
                            </DropdownScrollContainer>
                        </DropdownDrawer>
                    </Dropdown>
                </form>
            )}
            {data.length > 0 &&
                data.map((stocktaking) => (
                    <StocktakingOverviewMobileTableRow
                        stocktaking={stocktaking}
                    />
                ))}
            {isLoading &&
                data.length === 0 &&
                [...Array.from(Array(4).keys())].map((value) => (
                    <Skeleton
                        key={value}
                        style={{ height: '15px', width: '40%', margin: '8px' }}
                    />
                ))}

            {!isLoading && data.length === 0 && (
                <EmptyState
                    title={getMessage('text_inventories_none')}
                    description={getMessage(
                        'text_stocktakings_empty_description'
                    )}
                    svgIcon={'generalEmptyState'}
                    className={'atl-text-xl'}
                />
            )}
        </div>
    );
}

function StocktakingOverviewMobileTableRow({
    stocktaking,
}: {
    stocktaking: StocktakingDTO;
}) {
    return (
        <table
            className="atl-table goldshark-stocktaking__mobile-table"
            key={stocktaking.id}
            onClick={() => {
                window.location.href = addContextId(
                    `${PageUrl.STOCKTAKING_DETAILS}?inventoryId=${stocktaking.id}`
                );
            }}
        >
            <thead className="atl-thead">
                <StocktakingOverviewMobileHeader stocktaking={stocktaking} />
            </thead>
            <tbody>
                <tr className="atl-tr" style={{ cursor: 'pointer' }}>
                    <td className="atl-td atl-font-normal atl-align-middle ">
                        {stocktaking.inventory.name}
                    </td>
                    <td className="atl-td atl-text-right atl-align-middle atl-font-normal">
                        {stocktaking.date}
                    </td>
                </tr>
                {stocktaking.comment && (
                    <tr
                        className="atl-tr"
                        style={{
                            borderTop: 'hidden',
                            cursor: 'pointer',
                        }}
                    >
                        <td
                            className="atl-td atl-font-normal atl-align-middle"
                            colSpan={3}
                        >
                            {stocktaking.comment}
                        </td>
                    </tr>
                )}
            </tbody>
        </table>
    );
}

const StocktakingOverviewDesktopHeader = () => {
    return (
        <thead className="atl-thead">
            <tr className="atl-tr">
                <th className="atl-th">{getMessage('text_number')}</th>
                <th className="atl-th">{getMessage('text_warehouse')}</th>
                <th className="atl-th">{getMessage('text_comment')}</th>
                <th className="atl-th">{getMessage('text_date')}</th>
                <th className="atl-th">{getMessage('text_status')}</th>
            </tr>
        </thead>
    );
};

const StocktakingOverviewMobileHeader = ({
    stocktaking,
}: {
    stocktaking: StocktakingDTO;
}) => {
    return (
        <tr className="atl-tr" style={{ cursor: 'pointer' }}>
            <th className="atl-th atl-align-middle atl-font-normal goldshark-new-stocktaking__first-column">
                {getMessage('text_inventory')} {stocktaking.number}
            </th>
            <th className="atl-th atl-align-middle">
                <div className="atl-font-normal atl-flex atl-align-middle atl-justify-end atl-items-center atl-gap-8">
                    <StatusIcon
                        status={
                            stocktaking?.isCompleted
                                ? 'STATUS_CONFIRMED'
                                : 'STATUS_OPEN'
                        }
                    />
                    {getStatus(stocktaking?.isCompleted ?? false)}
                </div>
            </th>
        </tr>
    );
};

const StocktakingOverviewDesktopRow = ({
    stocktaking,
}: {
    stocktaking: StocktakingDTO;
}) => {
    return (
        <tr
            className="atl-tr atl-tr--highlight"
            onClick={(event) => {
                if (event.metaKey) {
                    nav.popup(
                        addContextId(
                            `${PageUrl.STOCKTAKING_DETAILS}?inventoryId=${stocktaking.id}`
                        ),
                        '_blank'
                    );
                } else {
                    window.location.href = addContextId(
                        `${PageUrl.STOCKTAKING_DETAILS}?inventoryId=${stocktaking.id}`
                    );
                }
            }}
            tabIndex={0}
            onKeyDown={(event) => {
                if (event.key === 'Enter' || event.key === ' ') {
                    window.location.href = addContextId(
                        `${PageUrl.STOCKTAKING_DETAILS}?inventoryId=${stocktaking.id}`
                    );
                }
            }}
            style={{ cursor: 'pointer' }}
        >
            <td className="atl-td atl-font-normal atl-align-middle">
                {stocktaking.number}
            </td>
            <td className="atl-td atl-font-normal atl-align-middle">
                {stocktaking.inventory.name}
            </td>
            <td className="atl-td atl-font-normal atl-align-middle atl-break-all">
                {stocktaking.comment}
            </td>
            <td className="atl-td atl-font-normal atl-align-middle">
                {stocktaking.date}
            </td>
            <td className="atl-td atl-align-middle">
                <div className="atl-font-normal atl-flex atl-items-center atl-gap-8">
                    <StatusIcon
                        status={
                            stocktaking?.isCompleted
                                ? 'STATUS_CONFIRMED'
                                : 'STATUS_OPEN'
                        }
                    />

                    {getStatus(stocktaking.isCompleted)}
                </div>
            </td>
        </tr>
    );
};
