import { browserInfo } from './d-browserInfo';
import { addContextId } from './url';

/**
 * Finds the relevant url for the given scope. Defaults to document href if no other viable candidate was found.
 *
 * Internally scope-base-url is used to store the url for a specific scope,
 * as otherwise nested scopes like dialogs would be reusing the page url, leading to conflicts.
 *
 * @param scope The scope to find url for.
 *
 * @return The relevant url for the given scope.
 */
export function getRelevantUrl(scope?: Element): string {
    if (scope !== undefined && $(scope).data('scope-base-url')) {
        return $(scope).data('scope-base-url');
    } else {
        const url = $('.ui-tabs-active a').attr('href');
        if (url) {
            return url;
        } else {
            // parent.content === undefined in standalone pages...
            if (!browserInfo.isInIframe()) {
                // eslint-disable-next-line
                // @ts-ignore ancient code
                return (parent.content || window).document.location.href;
            } else {
                return window.document.location.href;
            }
        }
    }
}

/**
 * Updated the url for the given scope. Defaults to document href (via the history API) if no other viable candidate was found.
 *
 * Internally scope-base-url is used to store the url for a specific scope,
 * as otherwise nested scopes like dialogs would be reusing the page url, leading to conflicts.
 *
 * NOTE: currently does not support tabs.
 *
 * @param url The new url value.
 * @param scope The scope to update url for.
 *
 * @author tellef
 * @date 2021-07-02
 */
export function setRelevantUrl(url: string, scope?: Element): void {
    if (scope !== undefined && $(scope).data('scope-base-url')) {
        $(scope).data('scope-base-url', url);
    } else {
        history.replaceState(history.state, document.title, addContextId(url));
    }
}
