import * as React from 'react';

import { RouteData } from '@General/Router';
import { Team } from '@General/Team';

import { PageLoader } from '@Component/PageLoader';

/**
 * The outside part of the bundle shear line.
 * The bundle split happens because of the <code>import('./SomePage')</code> call.
 * The PageLoader both sets up an error boundary (meaning a crash in the React code inside won't crash the page)
 * and handles showing a loader.
 *
 **
 * @see PageLoader
 * @see renderComponent
 */
export const Page: React.FC = (props) => (
    <PageLoader<{}>
        loader={async () =>
            import('./Automation2022.module').then((m) => m.page)
        }
        props={props}
        adjustWrapperDiv
    />
);

/**
 * Describes URL -> React page mapping
 *
 * Name of this file must end with `.route.ts` or `.route.tsx` for the mapping to be registered automatically.
 */
export const route: RouteData = {
    component: Page,
    path: '/execute/automation2022',
    title: () => {
        return getMessage(
            'text_notification_preference_type_incoming_invoice_automation_web_title'
        );
    },
    team: Team.KRR,
    // To fully connect this page to the Tripletex framework, the page needs a documentationComponent id so that we can
    // link help articles to the page
    documentationComponent: 300,

    // We should limit who has access to this page.
    auth: () => isAuth.automation,
};
