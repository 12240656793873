import { Option } from '@Component/DropDown/types';

type Listener = () => void;

/**
 * A Provider is a source of Option entries that will also notify any listening parties for changes.
 * How and where it gets Option entries is implementation specific.
 */
export abstract class Provider<T> {
    /**
     * Increment this value whenever we perform a request that should show the ajax wheel
     * The ajax wheel in the dropdown will show if inflightRequests is above 0
     */
    protected inflightRequests = 0;

    getIsLoadingData() {
        return this.inflightRequests > 0;
    }

    /**
     * This returns true if the query would result in an HTTP request fetching data.
     * @param query
     */
    abstract willFetch(query: string): boolean;

    listeners: Array<Listener>;

    protected constructor() {
        this.listeners = [];
    }

    /**
     * Register a new Listener.
     *
     * @param {Listener} listener The Listener to register.
     *
     * NOTE: The identity (instance in memory) of the listener is used.
     */
    registerListener(listener: Listener) {
        this.listeners.push(listener);
    }

    /**
     * Unregister the given listener if found in the local registry.
     *
     * @param {Listener} listener The Listener to unregister.
     *
     * NOTE: The identity (instance in memory) of the listener is used.
     */
    unregisterListener(listener: Listener) {
        const index = this.listeners.indexOf(listener);
        if (index >= 0) {
            this.listeners.splice(index, 1);
        }
    }

    protected updateListeners() {
        this.listeners.forEach((listener) => {
            try {
                listener();
            } catch (e) {
                // eslint-disable-next-line no-console
                console.log(e);
            }
        });
    }

    /**
     * Get the specific Option, if it exists.
     *
     * @param {string | number} id The identifier of the option to get.
     *
     * @return {Option<T> | null} The Option if it exists; NULL otherwise.
     */
    abstract getSingle(id: string | number): Option<T> | null;

    /**
     * Get a list of Options based on the query string.
     *
     * @param {string} query The query
     *
     * @return {Array<Option<T>>} Matching result set.
     */
    abstract getOptions(query: string): Array<Option<T>>;

    abstract getDefaults(query: string): Array<Option<T>>;

    abstract getOptionsNoDefaults(query: string): Array<Option<T>>;
}

/**
 * The asynchronous version / extension of a regular Provider.
 * Only major difference is that there is no guarantee that there will be
 * usable values on first use, as the common use case is to fetch that data asynchronously.
 *
 * For example:
 * Calling getSingle will most likely return NULL the first time, but doing the call triggers the loading of that data.
 * A proper AsyncProvider implementation will then call updateListeners when it is done loading data.
 */
export abstract class AsyncProvider<T> extends Provider<T> {
    /**
     * Trigger a prefetch of data if applicable.
     * prefetch should typically be called very early in the AsyncProvider lifecycle.
     *
     * @param {string} query The query to prefetch for.
     *
     * @return {Promise<void>} A Promise that resolves when the prefetch is done.
     */
    abstract prefetch(query: string): Promise<void>;

    protected abstract doFetchOptions(
        query: string,
        pageIndex?: number
    ): Promise<void>;

    /**
     * Direct fetch trigger for getOptions.
     *
     * @param {string} query The query to prefetch for.
     * @param pageIndex Current page index for infinite scroll
     *
     * @return {Promise<void>} A Promise that resolves when the fetch is done.
     */
    async fetchOptions(query: string, pageIndex?: number): Promise<void> {
        if (!this.willFetch(query)) {
            return;
        }
        try {
            this.inflightRequests++;
            super.updateListeners();
            await this.doFetchOptions(query, pageIndex);
        } finally {
            this.inflightRequests--;
            super.updateListeners();
        }
    }

    /**
     * Direct fetch trigger for getSingle.
     *
     * @param {string | number} id The identifier of the Option.
     *
     * @return {Promise<void>} A Promise that resolves when the fetch is done.
     */
    abstract fetchSingle(id: string | number): Promise<void>;

    /**
     * Set arbitrary additional parameters to be used when fetching Options.
     * Implementation specific how they are used.
     *
     * @param {string} key The parameter key / name.
     * @param value        The parameter value.
     *
     * TODO refactor away?, it doesn't feel like this belongs here.
     */
    abstract setParameter(key: string, value: any): void;

    /**
     * Reset all caches. Handy for forcing refreshes without needing to reconstruct everything.
     */
    abstract reset(): void;
}

/**
 * Common pattern of providing both an eager and lazy version of an AsyncProvider.
 * Eager means it supports prefetch.
 * Lazy means it starts loading the data on first get / fetch.
 */

export type AsyncProviders<T> = {
    Eager: () => AsyncProvider<T>;
    Lazy: () => AsyncProvider<T>;
};
