import { Team } from '@General/Team';
import { UserFeedbackBox } from '@Page/Automation2022/components/FeedbackButton/UserFeedbackBox';
import { UserFeedbackButton } from '@Page/Automation2022/components/FeedbackButton/UserFeedbackButton';
import { useResizeObserver } from '@Page/DynamicForms/component/feedback/useResizeObserver';
import React, { useRef, useState } from 'react';
import { Button, Icon } from '@tlx/atlas';

export function UserFeedback({ pageTitle }: { pageTitle: string }) {
    const [isFeedbackBoxOpen, setIsFeedbackBoxOpen] = useState(false);
    const { ref, size } = useResizeObserver();
    const feedbackFullView = size === undefined || size.width > 760;
    const feedbackButtonRef = useRef<HTMLButtonElement>(null);
    return (
        <div ref={ref}>
            <UserFeedbackButton
                setIsFeedbackBoxOpen={setIsFeedbackBoxOpen}
                buttonRef={feedbackButtonRef}
            />
            {isFeedbackBoxOpen && (
                <UserFeedbackBox
                    pageTitle={pageTitle}
                    setIsFeedbackBoxOpen={setIsFeedbackBoxOpen}
                    feedbackFullView={feedbackFullView}
                    parent={feedbackButtonRef}
                    team={Team.GOLDSHARK}
                    showThisIsNotSupportInfo={true}
                />
            )}
        </div>
    );
}
export function UserFeedback2({ pageTitle }: { pageTitle: string }) {
    const [isFeedbackBoxOpen, setIsFeedbackBoxOpen] = useState(false);
    const { ref, size } = useResizeObserver();
    const feedbackFullView = size === undefined || size.width > 760;
    const feedbackButtonRef = useRef<HTMLButtonElement>(null);
    return (
        <div ref={ref}>
            <Button
                className="atl-mb-4"
                id="product-selector-send-userFeedback"
                variant={'secondary'}
                data-testid="2022-userFeedback"
                data-trackingid="text_send_feedback"
                onClick={() => {
                    setIsFeedbackBoxOpen((v) => !v);
                }}
                ref={feedbackButtonRef}
                tabIndex={9999}
            >
                {getMessage('text_send_feedback')}
                <Icon>drive_file_rename_outline</Icon>
            </Button>
            {isFeedbackBoxOpen && (
                <UserFeedbackBox
                    pageTitle={pageTitle}
                    setIsFeedbackBoxOpen={setIsFeedbackBoxOpen}
                    feedbackFullView={feedbackFullView}
                    parent={feedbackButtonRef}
                    team={Team.GOLDSHARK}
                    showThisIsNotSupportInfo={true}
                />
            )}
        </div>
    );
}
