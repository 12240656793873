import { useEffect, useState } from 'react';
import { createAPIRequest } from '../../hooks/fetch/createAPIRequest';
import {
    APIError,
    createAPIResponse,
} from '../../hooks/fetch/createAPIResponse';
import { ResponseWrapper } from '../../hooks/fetch/types';
import { PageOption } from '@General/PBC/API/PageOptionsAPI.types';
import useSWR from 'swr';
import { defaultFetcher } from '../../hooks/fetch/defaultFetcher';

interface OrderFormOptions {
    showAsSimple: boolean;
    showSimpleInvoicingMessage: boolean;
}

export function useViewOptions() {
    const [viewOptions, setViewOptions] = useState<
        OrderFormOptions | undefined
    >();
    useEffect(() => {
        getViewOptions().then((options) => {
            setViewOptions(options.value.data);
        });
    }, []);

    return viewOptions;
}

export async function getViewOptions() {
    const request = createAPIRequest(
        '/v2/options/page/byKey?fields=*&type=OrderFormViewOptions&key=OrderFormViewOptions&level=Employee'
    );

    const response = await window.fetch(request);

    return createAPIResponse<ResponseWrapper<PageOption<OrderFormOptions>>>(
        request,
        response
    );
}

export async function postViewOptions(viewOptions: OrderFormOptions) {
    const request = createAPIRequest(
        '/v2/options/page/byKey?fields=*&type=OrderFormViewOptions&key=OrderFormViewOptions&level=Employee',
        {
            method: 'POST',
            body: JSON.stringify(viewOptions),
            headers: {
                'content-type': 'application/json',
            },
        }
    );

    const response = await window.fetch(request);

    return createAPIResponse<ResponseWrapper<PageOption<OrderFormOptions>>>(
        request,
        response
    );
}

export type CompanyModuleDTO = {
    moduleLogistics: boolean;
    modulenrf: boolean;
    moduleelectro: boolean;
};

export function useFetchModules() {
    const url = '/v2/company/modules';
    const { data, error, isLoading } = useSWR<
        ResponseWrapper<CompanyModuleDTO>,
        APIError
    >(url, defaultFetcher);

    return { data: data?.value, error, isLoading };
}
