import { PageLoader } from '@Component/PageLoader';
import { renderComponent } from '@General/renderComponent';
import { FC } from 'react';

const Page: FC = (props) => (
    <PageLoader<any>
        loader={async () => import('./PaymentWidget').then((m) => m.page)}
        props={props}
    />
);

export function renderPaymentWidget(domId: string) {
    renderComponent(Page, domId);
}
