import { Option } from '../../types';
import { notUndefined } from '@General/Helpers';
import { ModelAPIV1 } from '@General/types/Api';
import { JSPDropdownFetcher } from '../JSPDropdownFetcher';
import { ApiResultMapper } from '@General/Provider';
import * as React from 'react';

interface OvernightStopEntry extends ModelAPIV1 {
    readonly displayName: string;
}

const OvernightStopMapper: ApiResultMapper<OvernightStopEntry> = (item) => {
    return {
        value: item.id + '',
        displayName: item.displayName,
        item,
    };
};

class OvernightStopFetcher extends JSPDropdownFetcher<OvernightStopEntry> {
    asyncJsonrpcGetter: Function;

    constructor(scope: HTMLDivElement, queryAttributes: Record<string, any>) {
        super(scope, queryAttributes, OvernightStopMapper);

        this.asyncJsonrpcGetter = asyncrpc.OvernightStop.get;
    }

    getParams(): ObjectIndex {
        return {
            date: this.getQueryAttribute('departureDate', ''),
        };
    }

    async fetchList(
        params: ObjectIndex
    ): Promise<Option<OvernightStopEntry>[]> {
        return new Promise((resolve, reject) => {
            try {
                jsonrpc.OvernightStop.getAllActive(
                    (result: OvernightStopEntry[] | null) => {
                        const mapped = (result ?? [])
                            .filter(notUndefined)
                            .map(this.getMapper(params))
                            .filter(notUndefined);
                        resolve(mapped);
                    },
                    this.getMarshallSpec(),
                    params.date
                );
            } catch (e) {
                defaultJsonRpcExceptionHandler(e);
                reject(e);
            }
        });
    }
}

export const exports = {
    fetcher: OvernightStopFetcher,
    type: 'OvernightStop',
};
