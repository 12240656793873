import { postNotificationMessage } from '@General/LegacyNotification';
import {
    invokeAPI,
    useFetchAll,
    useFetchSingle,
} from '@Page/LogisticsV2/hooks/api';
import {
    ProductSettingsDTO,
    StocktakingDTO,
} from '@Page/LogisticsV2/types/dtoTypes';
import { TripletexValidationMessage } from '@Page/LogisticsV2/utils/constants';
import { ApiUrl, MethodType } from '@Page/LogisticsV2/utils/enums';
import { mapObjectToURLSearchParams } from '@Page/LogisticsV2/utils/mappers';
import { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

export function useProductSettings() {
    const { data: productSettings } = useFetchSingle<ProductSettingsDTO>(
        ApiUrl.PRODUCT_SETTINGS,
        undefined,
        {
            revalidateOnFocus: false,
        }
    );

    const { data: outgoingStocktakings } = useFetchAll<StocktakingDTO[]>(
        ApiUrl.INVENTORY_STOCKTAKING,
        mapObjectToURLSearchParams({
            fields: 'id',
            isCompleted: false,
        }),
        { revalidateOnFocus: false }
    );

    const {
        register,
        reset,
        handleSubmit,
        watch,
        getValues,
        setValue,
        setError,
        formState: { errors },
    } = useForm<ProductSettingsDTO>({ mode: 'onChange' });

    useEffect(() => {
        reset(productSettings);
    }, [productSettings]);

    const onSubmit: SubmitHandler<ProductSettingsDTO> = (data) =>
        handleUpdateSettings(data);

    const handleUpdateSettings = async (data: ProductSettingsDTO) => {
        const { error } = await invokeAPI(
            ApiUrl.PRODUCT_SETTINGS,
            MethodType.PUT,
            JSON.stringify(data)
        );
        if (error === undefined) {
            postNotificationMessage(getMessage('text_product_settings'), [
                getMessage('text_action_was_completed'),
            ]);
            window.location.reload();
        } else {
            error.validationMessages?.map(
                (fieldError: TripletexValidationMessage) =>
                    setError(fieldError.field as keyof ProductSettingsDTO, {
                        message: fieldError.message,
                    })
            );
        }
    };

    const hasOutgoingStocks = () => {
        if (outgoingStocktakings === undefined) {
            return false;
        } else {
            return outgoingStocktakings.length > 0;
        }
    };

    return {
        errors,
        hasLogisticsModule: moduleLogistics,
        hasOutgoingStocks: hasOutgoingStocks(),
        hasPurchaseOrderModule: modulePurchaseOrder,
        isNewCustomer:
            productSettings?.newCustomerAfterLogisticsRelease &&
            !moduleElectro &&
            !moduleNrf,
        onSave: handleSubmit(onSubmit),
        getValues,
        register,
        setValue,
        watch,
    };
}
