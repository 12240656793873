import { ModelAPIV1 } from '@General/types/Api';
import { CreateButtonComponent, Option } from '../../types';
import { notUndefined } from '@General/Helpers';
import { JSPDropdownFetcher } from '../JSPDropdownFetcher';
import { ApiResultMapper } from '@General/Provider';
import * as React from 'react';
import { TlxListItem } from '@Component/DropDown/DropDown';
import { createCreateNewButton } from '@Component/DropDown/CreateNewButton/CreateNewButton';

interface ProductSimpleEntry extends ModelAPIV1 {
    readonly displayNumber: string;
    readonly name: string;
    readonly currencyCodeBlank: string;
    readonly nameAndNumber: string;
    readonly vendorName: string;
}

const productSimpleMapper: ApiResultMapper<ProductSimpleEntry> = (item) => ({
    value: item.id + '',
    displayName: item.nameAndNumber,
    secondary: item.vendorName,
    item,
});

const DesktopListItem: TlxListItem<ProductSimpleEntry> = (props) => {
    if (!props.option.item) {
        return (
            <div className="txr-dropdown__row txr-dropdown__list-item">
                <div className="txr-dropdown__item-cell txr-dropdown__item-cell--default">
                    {props.option.displayName}
                </div>
            </div>
        );
    }
    return (
        <div className="txr-dropdown__row txr-dropdown__list-item">
            <div className="txr-dropdown__item-cell txr-dropdown__item-cell--productDisplayNumber">
                {props.option.item.displayNumber}
            </div>
            <div className="txr-dropdown__item-cell txr-dropdown__item-cell--name">
                {props.option.item.name}
            </div>
            <div className="txr-dropdown__item-cell txr-dropdown__item-cell--name">
                {props.option.item.vendorName}
            </div>
            <div className="txr-dropdown__item-cell txr-dropdown__item-cell--currencyCode">
                {props.option.item.currencyCodeBlank}
            </div>
        </div>
    );
};

const Headers: React.FC = () => {
    return (
        <div className="txr-dropdown__row">
            <div className="txr-dropdown__item-cell txr-dropdown__item-cell--header txr-dropdown__item-cell--productDisplayNumber">
                {getMessage('th_number')}
            </div>
            <div className="txr-dropdown__item-cell txr-dropdown__item-cell--header txr-dropdown__item-cell--name">
                {getMessage('text_name')}
            </div>
            <div className="txr-dropdown__item-cell txr-dropdown__item-cell--header txr-dropdown__item-cell--name">
                {getMessage('text_supplier')}
            </div>
            <div className="txr-dropdown__item-cell txr-dropdown__item-cell--header txr-dropdown__item-cell--currencyCode">
                {getMessage('th_currency')}
            </div>
        </div>
    );
};

class ProductSimpleFetcher extends JSPDropdownFetcher<ProductSimpleEntry> {
    asyncJsonrpcGetter: Function;

    constructor(scope: HTMLDivElement, queryAttributes: Record<string, any>) {
        super(scope, queryAttributes, productSimpleMapper);

        this.asyncJsonrpcGetter = asyncrpc.Product.get;
    }

    getDesktopListItem(): TlxListItem<ProductSimpleEntry> {
        return DesktopListItem;
    }

    getMarshallSpec() {
        return window.marshallSpec(
            'id',
            'nameAndNumber',
            'vendorName',
            'displayNumber',
            'name',
            'currencyCodeBlank',
            'vendorName'
        );
    }
    //dropdownIndex is the index of the ACTIVE dropdown from pages where we have more than one Product dropdown
    //It is needed in order to populate the correct dropdown with the new created product.
    dropdownIndex = this.getQueryAttribute('dropdownIndexProp', -1);
    //dropdownProperty is used to know where we are in Supplier invoice(simple) with orderLines (from EHF)
    property = this.getQueryAttribute('dropdownProperty', 'debitPostings');
    getParams(): ObjectIndex {
        return {
            departmentId: this.getQueryAttribute('departmentElementId', -1),
            vendorId: this.getQueryAttribute('vendorElementId', -1),
            projectId: this.getQueryAttribute('projectElementId', -1),
            isExternal: this.getQueryAttribute('isExternal', -1),
            useProductNetPrice: this.getQueryAttribute(
                'useProductNetPriceElementId',
                -1
            ),
            isEnabledForWarehouse: this.getQueryAttribute(
                'isEnabledForWarehouse',
                -1
            ),
            queryId: this.getQueryAttribute('queryId', 1),
            dropdownIndex: this.getQueryAttribute('indexName', -1),
        };
    }

    async fetchList(
        params: ObjectIndex
    ): Promise<Option<ProductSimpleEntry>[]> {
        return new Promise((resolve, reject) => {
            try {
                return jsonrpc.Product.searchForProductsTlxSelect(
                    (result: ProductSimpleEntry[] | null) => {
                        const mapped = (result ?? [])
                            .filter(notUndefined)
                            .map(this.getMapper(params))
                            .filter(notUndefined);
                        resolve(mapped);
                    },
                    this.getMarshallSpec(),
                    window.loginCompanyId,
                    params.departmentId,
                    params.queryId,
                    1,
                    params.isExternal,
                    params.vendorId,
                    params.projectId,
                    params.useProductNetPrice,
                    params.isEnabledForWarehouse,
                    params.name || '',
                    -1,
                    0
                );
            } catch (e) {
                defaultJsonRpcExceptionHandler(e);
                reject(e);
            }
        });
    }

    createCreateNewButton(
        setSelected: (id: number) => void
    ): CreateButtonComponent | undefined {
        if (!isAuth.createProduct || moduleRackbeat) {
            return undefined;
        }
        // There was no checks on these in the original version as well
        const vendorId = this.getQueryAttribute('vendorElementId', '0');
        const url =
            vendorId === '0'
                ? 'product?canUploadImage=false'
                : `createProduct?vendorId=${vendorId}&dropdownIndex=${this.dropdownIndex}&dropdownProperty=${this.property}`;
        const textKey =
            vendorId === '0' ? 'text_new_product' : 'text_new_vendor_product';
        return createCreateNewButton(
            setSelected,
            textKey,
            url,
            'create-new-product-button',
            undefined,
            'txr-page-dialog__backdrop--product'
        );
    }

    getHeaders() {
        return Headers;
    }
}

export const exports = {
    fetcher: ProductSimpleFetcher,
    type: 'ProductSimple',
    link: (id: number, contextId: number) =>
        `/execute/productMenu?productId=${id}&contextId=${contextId}`,
};
