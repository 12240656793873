import * as React from 'react';

import { PageLoader } from '@Component/PageLoader';

import type { CompanyOverviewDashboardProps } from './component/CompanyOverviewDashboard';

export const CompanyOverviewDashboard = () => {
    return (
        <PageLoader<CompanyOverviewDashboardProps>
            loader={async () =>
                import('./CompanyOverviewDashboard.module').then((m) => m.page)
            }
            adjustWrapperDiv
        />
    );
};
