export const arrayUtil = (function () {
    // Array methods

    // eslint-disable-next-line no-unused-vars
    function arrayVisit<T>(a: T[], func: (item: T) => void): T[] {
        const length = a.length;
        for (let i = 0; i < length; i++) {
            func(a[i]);
        }
        return a;
    }

    // only first value found
    function arrayDestructiveRemove<T>(a: T[], value: T): T | undefined {
        const i = a.indexOf(value);
        if (i > -1) {
            return a.splice(i, 1)[0];
        } else {
            return undefined;
        }
    }

    function arrayFindIndex<T, U>(
        a: T[],
        element: U,
        // eslint-disable-next-line no-unused-vars
        key?: (value: T) => U
    ): number {
        for (let i = 0; i < a.length; i++) {
            const value: T = a[i];
            let keyValue: T | U = value;
            if (key) {
                keyValue = key(value);
            }
            if (keyValue == element) {
                return i;
            }
        }
        return -1;
    }
    function arrayFindElement<T, U>(
        a: T[],
        element: U,
        // eslint-disable-next-line no-unused-vars
        key: (value: T) => U
    ): T | null {
        const i = arrayFindIndex(a, element, key);
        if (i > -1) {
            return a[i];
        }
        return null;
    }

    function arraysEqual<T>(a: T[], b: T[]): boolean {
        if (a === b) {
            return true;
        }
        if (a == null || b == null) {
            return false;
        }
        if (a.length != b.length) {
            return false;
        }

        for (let i = 0; i < a.length; ++i) {
            if (a[i] !== b[i]) {
                return false;
            }
        }
        return true;
    }

    function range(from: number, to: number, by = 1) {
        const arr: number[] = [];
        for (let i = from; i < to; i += by) {
            arr.push(i);
        }
        return arr;
    }

    function iterate<T>(howMany: number, create: (i: number) => T): T[] {
        return range(0, howMany, 1).map(create);
    }

    return {
        forEach: arrayVisit,
        destructiveRemove: arrayDestructiveRemove,
        findIndex: arrayFindIndex,
        findElement: arrayFindElement,
        equal: arraysEqual,
        range,
        iterate,
    };
})();
