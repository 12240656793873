import * as React from 'react';

import { PageLoader } from '@Component/PageLoader';

import type { TestFixtureProps } from './component';

export const TestFixture: React.FC<TestFixtureProps> = (props) => (
    <PageLoader<TestFixtureProps>
        loader={async () => import('./TestFixture.module').then((m) => m.page)}
        adjustWrapperDiv
        props={props}
    />
);
