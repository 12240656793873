import {
    Invoice,
    OrderOrProject,
} from '@Page/ProjectInvoicingDialog/types/InvoicingDialog.type';
import { addContextId } from '../../../../../js/modules/url';
import { SendTypes } from '@Page/ProjectInvoicingDialog/component/InvoicingForm/SendMethod';
import { createAPIRequest } from '@tlx/astro-shared';
import { OrderOrProjectInvoicingStateMessage } from '@Page/ProjectInvoicingDialog/component/BatchInvoicing/InvoicingListItem';
import { asyncPool } from '@Page/ProjectInvoicingDialog/utils/asyncPool';
import { useEffect, useState } from 'react';

export interface Currency {
    code: string;
}

export interface Company {
    currency: Currency;
    invoiceShowDeliveryDate: boolean;
    organizationNumber?: string;
}

export function useFetchCompany(companyId: number) {
    const [data, setData] = useState<Company | undefined>();
    const [error, setError] = useState();
    const [isLoading, setIsLoading] = useState(false);

    async function refresh(companyId: number) {
        // Reset error while fetching
        setError(undefined);
        setIsLoading(true);

        try {
            const url = new URL(
                `/v2/company/${companyId}`,
                window.location.origin
            );
            const params = url.searchParams;
            params.set(
                'fields',
                'currency(code), invoiceShowDeliveryDate, organizationNumber'
            );

            const response = await window.fetch(
                createAPIRequest(url.toString())
            );
            const data = await response.json();

            setData(data.value);
        } catch (error) {
            setError(error);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        void refresh(companyId);
    }, [companyId]);

    return { data, error, isLoading, refresh };
}

function getOrdersURL(orderIds: number[]): string {
    const url = new URL('/v2/order', window.location.origin);
    const params = url.searchParams;
    params.set('count', '200');
    params.set('id', orderIds.join(','));
    params.set('orderDateFrom', '1990-01-01');
    params.set('orderDateTo', '2200-01-01');
    params.set(
        'fields',
        'id, sendMethodDescription, currency(code), number, isClosed, isSubscription, invoiceSendSMSNotification, invoiceSMSNotificationNumber, ' +
            'customer(id, name, invoiceSendMethod, invoiceEmail, singleCustomerInvoice, isPrivateIndividual, organizationNumber, phoneNumber, phoneNumberMobile, invoiceSendSMSNotification , physicalAddress(country(id, name)), postalAddress(postalCode, city)), ' +
            'attn(id), ' +
            'canCreateBackorder, orderLines(id), totalInvoicedOnAccountAmountAbsoluteCurrency, ' +
            'invoiceOnAccountVatHigh, receiverEmail, ' +
            'invoicesDueInType, invoicesDueIn'
    );
    return url.toString();
}

function getProjectsURL(projectIds: number[]): string {
    const url = new URL('/v2/project', window.location.origin);
    const params = url.searchParams;
    params.set('count', '200');
    params.set('id', projectIds.join(','));
    params.set(
        'fields',
        'id, currency(code), number, name, isClosed, ' +
            'customer(id, name, invoiceSendMethod, invoiceEmail, singleCustomerInvoice, isPrivateIndividual, organizationNumber, phoneNumber, phoneNumberMobile, invoiceSendSMSNotification , physicalAddress(country(id, name)), postalAddress(postalCode, city)), ' +
            'attention(id), ' +
            'orderLines(id), totalInvoicedOnAccountAmountAbsoluteCurrency, ' +
            'invoiceOnAccountVatHigh, invoiceReceiverEmail, ' +
            'invoiceDueDateType, invoiceDueDate'
    );
    return url.toString();
}

function getInvoicesURL(invoiceIds: number[]): string {
    const url = new URL('/v2/invoice/internal', window.location.origin);
    const params = url.searchParams;
    params.set('count', '200');
    params.set('id', invoiceIds.join(','));
    params.set(
        'fields',
        'id, invoiceNumber, invoiceDate, amount, amountCurrency, amountExcludingVat, ' +
            'amountExcludingVatCurrency, isPeriodizationPossible, isCreditNote, isApproved, ' +
            'customer(id), currency(code), projectInvoiceDetails(project(id)) travelReports'
    );
    return url.toString();
}

type FetchOrdersOrProjectsResponse = {
    ordersOrProjects: OrderOrProject[];
    error: boolean;
    isLoadingOrdersOrProjects: boolean;
};

export const useFetchOrdersOrProjects = (
    isProjectInvoicingModal: boolean,
    fetchProjects: boolean,
    orderOrProjectIds: number[]
) => {
    const [response, setResponse] = useState<FetchOrdersOrProjectsResponse>({
        ordersOrProjects: [],
        error: false,
        isLoadingOrdersOrProjects: true,
    });

    useEffect(() => {
        if (orderOrProjectIds.length > 0) {
            fetch(
                createAPIRequest(
                    fetchProjects
                        ? getProjectsURL(orderOrProjectIds)
                        : getOrdersURL(orderOrProjectIds),
                    { method: 'GET' }
                )
            )
                .then((res) => res.json())
                .then((data) => {
                    const ordersOrProjects = new Array<OrderOrProject>();
                    for (const value of data.values) {
                        let orderOrProject: OrderOrProject;
                        if (fetchProjects) {
                            orderOrProject = {
                                attn: value.attention,
                                receiverEmail: value.invoiceReceiverEmail,
                                invoicesDueInType: value.invoiceDueDateType,
                                invoicesDueIn: value.invoiceDueDate,
                                isSubscription: false,
                                canCreateBackorder: false,
                                invoiceSendSMSNotification: false,
                                ...value,
                            };
                        } else {
                            orderOrProject = { ...value };
                        }
                        orderOrProject.isProject = fetchProjects;
                        ordersOrProjects.push(orderOrProject);
                    }

                    setResponse({
                        ordersOrProjects,
                        error: false,
                        isLoadingOrdersOrProjects: false,
                    });
                })
                .catch(() =>
                    setResponse({
                        ...response,
                        error: true,
                        isLoadingOrdersOrProjects: false,
                    })
                );
        } else {
            setResponse({
                ...response,
                error: true,
                isLoadingOrdersOrProjects: false,
            });
        }
    }, []);
    return response;
};

type FetchInvoicesResponse = {
    invoices: Invoice[];
    error: boolean;
    isLoadingInvoices: boolean;
};

export const useFetchInvoices = (invoiceIds: number[]) => {
    const [response, setResponse] = useState<FetchInvoicesResponse>({
        invoices: [],
        error: false,
        isLoadingInvoices: true,
    });

    useEffect(() => {
        if (invoiceIds.length > 0) {
            fetch(
                createAPIRequest(getInvoicesURL(invoiceIds), { method: 'GET' })
            )
                .then((res) => res.json())
                .then((data) => {
                    const invoices = new Array<Invoice>();
                    for (const value of data.values) {
                        invoices.push({ ...value });
                    }

                    setResponse({
                        invoices,
                        error: false,
                        isLoadingInvoices: false,
                    });
                })
                .catch(() =>
                    setResponse({
                        ...response,
                        error: true,
                        isLoadingInvoices: false,
                    })
                );
        } else {
            setResponse({
                ...response,
                error: true,
                isLoadingInvoices: false,
            });
        }
    }, []);
    return response;
};

// Creates and sends an invoice to a single customer
// If the list contains 1 order - a standard invoice is created and sent
// If it has 2 or more orders - they're merged to a single invoice ('single customer invoice'), and sent
const asyncInvoiceCustomer = async (
    ordersAndProjects: OrderOrProject[],
    invoicingDate: string,
    deliveryDate: string,
    sentManuallyCallBack: (invoiceIds: number[]) => void,
    onError: (message: OrderOrProjectInvoicingStateMessage) => void,
    onSuccess: (message: OrderOrProjectInvoicingStateMessage) => void,
    onInfo: (message: OrderOrProjectInvoicingStateMessage) => void,
    createBackOrders: boolean,
    isProjectInvoicingModal: boolean,
    sendToCustomer: string,
    periodEndDate?: string,
    readyForBilling?: string
) => {
    if (ordersAndProjects.length === 0) {
        return;
    }
    const orderAndProjectIds = ordersAndProjects.map(
        (orderOrProject) => orderOrProject.id
    );
    const preliminaryInvoiceIds = ordersAndProjects.map(
        (orderOrProject) => orderOrProject.preliminaryInvoice?.id
    );

    const isSingleOrderOrProject = orderAndProjectIds.length === 1;

    let invoice: Invoice | number[] | undefined;
    if (isSingleOrderOrProject) {
        invoice = await createOrderOrProjectInvoice(
            orderAndProjectIds[0],
            invoicingDate,
            deliveryDate,
            createBackOrders,
            (message) => onError(message),
            isProjectInvoicingModal,
            sendToCustomer,
            preliminaryInvoiceIds[0],
            periodEndDate,
            readyForBilling
        );
    } else {
        invoice = await createSingleCustomerInvoice(
            orderAndProjectIds,
            invoicingDate,
            deliveryDate,
            createBackOrders,
            (message) => onError(message),
            isProjectInvoicingModal,
            sendToCustomer,
            preliminaryInvoiceIds,
            periodEndDate,
            readyForBilling
        );
    }

    if (!invoice || (Array.isArray(invoice) && invoice.length == 0)) {
        return;
    }

    // Find out if we can change this flow - so if email is set - we validate if an email is present and give error if not
    // instead of just sending it out manually
    const sendMethod = ordersAndProjects[0].customer.invoiceSendMethod;
    const isSentManually =
        (sendMethod === 'EMAIL' && !ordersAndProjects[0].receiverEmail) ||
        sendMethod === 'MANUAL';

    if (isSentManually) {
        sentManuallyCallBack(Array.isArray(invoice) ? invoice : [invoice.id]);
    }

    onSuccess('');
};

export async function doBatchInvoice(
    invoicingDate: string,
    deliveryDate: string,
    groupedOrdersAndProjects: OrderOrProject[][], // grouped by singleCustomerInvoice or not ([[orderForSCI, orderForSCI], [orderToBeInvoicedAlone]...]
    onError: (
        groupIndex: number,
        errorMessage: OrderOrProjectInvoicingStateMessage
    ) => void,
    onSuccess: (
        groupIndex: number,
        successMessage: OrderOrProjectInvoicingStateMessage
    ) => void,
    onInfo: (
        groupIndex: number,
        infoMessage: OrderOrProjectInvoicingStateMessage
    ) => void,
    createBackOrders: boolean,
    isProjectInvoicingModal: boolean,
    periodEndDate?: string,
    readyForBilling?: string
): Promise<URL | undefined> {
    const idsForInvoicesToBeSentManually: Array<number> = [];

    await asyncPool(
        groupedOrdersAndProjects,
        async (groupOfOrdersAndProjects) => {
            const groupIndex = groupedOrdersAndProjects.indexOf(
                groupOfOrdersAndProjects
            );
            const sendToCustomer =
                groupOfOrdersAndProjects[0].customer.invoiceSendMethod !==
                SendTypes.MANUAL
                    ? 'true'
                    : 'false';
            await asyncInvoiceCustomer(
                groupOfOrdersAndProjects,
                invoicingDate,
                deliveryDate,
                (invoiceIds) => {
                    idsForInvoicesToBeSentManually.push(...invoiceIds);
                },
                (errorMessage) => {
                    onError(groupIndex, errorMessage);
                },
                (successMessage) => {
                    onSuccess(groupIndex, successMessage);
                },
                (infoMessage) => {
                    onInfo(groupIndex, infoMessage);
                },
                createBackOrders,
                isProjectInvoicingModal,
                sendToCustomer,
                periodEndDate,
                readyForBilling
            );
        },
        5
    );

    if (idsForInvoicesToBeSentManually.length > 0) {
        const pdfURLForInvoicesToBeSentManually = new URL(
            addContextId(
                `/execute/invoiceUtil?act=viewForPrint&ids=${idsForInvoicesToBeSentManually.join(
                    ','
                )}`
            ),
            window.location.origin
        );

        if (pdfURLForInvoicesToBeSentManually) {
            return pdfURLForInvoicesToBeSentManually;
        }
    }

    return undefined;
}

const createOrderOrProjectInvoice = async (
    orderOrProjectId: number,
    invoiceDate: string,
    deliveryDate: string,
    createBackOrder: boolean,
    onError: (errorMessage: string) => void,
    isProjectInvoicingModal: boolean,
    sendToCustomer: string,
    preliminaryInvoiceId?: number,
    periodEndDate?: string,
    readyForBilling?: string
): Promise<Invoice | undefined> => {
    const url = new URL(
        isProjectInvoicingModal
            ? `/v2/invoice/:projectInvoicing`
            : `/v2/order/${orderOrProjectId}/:invoice`,
        window.location.origin
    );
    const params = url.searchParams;
    params.set('invoiceDate', invoiceDate);
    if (isProjectInvoicingModal && preliminaryInvoiceId) {
        params.set('preliminaryInvoiceIds', preliminaryInvoiceId.toString());
        params.set('invoiceDate', invoiceDate);
        params.set('deliveryDate', deliveryDate);
        periodEndDate && params.set('periodEndDate', periodEndDate);
        readyForBilling !== undefined &&
            params.set('readyForBilling', readyForBilling);
        params.set('sendToCustomer', sendToCustomer);
    } else {
        params.set('sendToCustomer', 'true');
        params.set('createBackorder', createBackOrder.toString());
    }
    const request = createAPIRequest(url.toString(), { method: 'PUT' });
    try {
        const response = await fetch(request);
        const data = await response.json();

        if (response.status === 200) {
            return data.value;
        } else {
            const errorMessage =
                data?.validationMessages?.[0]?.message ??
                getMessage('error_on_create_invoice');

            onError(errorMessage);
        }
    } catch (e) {
        console.error('Error while creating invoice:', e);
        onError(getMessage('error_on_create_invoice'));
    }
    return undefined;
};

const createSingleCustomerInvoice = async (
    orderAndProjectIds: number[],
    invoiceDate: string,
    deliveryDate: string,
    createBackOrders: boolean,
    onError: (message: string) => void,
    isProjectInvoicingModal: boolean,
    sendToCustomer: string,
    preliminaryInvoiceIds?: (number | undefined)[],
    periodEndDate?: string,
    readyForBilling?: string
): Promise<Invoice | number[] | undefined> => {
    const url = new URL(
        isProjectInvoicingModal && preliminaryInvoiceIds
            ? `/v2/invoice/:projectInvoicing`
            : `/v2/order/:invoiceMultipleOrders`,
        window.location.origin
    );
    const params = url.searchParams;
    if (isProjectInvoicingModal && preliminaryInvoiceIds) {
        params.set('preliminaryInvoiceIds', preliminaryInvoiceIds.join(','));
        params.set('invoiceDate', invoiceDate);
        params.set('deliveryDate', deliveryDate);
        periodEndDate && params.set('periodEndDate', periodEndDate);
        readyForBilling !== undefined &&
            params.set('readyForBilling', readyForBilling);
        params.set('sendToCustomer', sendToCustomer);
    } else {
        params.set('invoiceDate', invoiceDate);
        params.set('sendToCustomer', 'true');
        params.set('id', orderAndProjectIds.join(','));
        params.set('createBackorders', createBackOrders.toString());
    }
    const request = createAPIRequest(url.toString(), { method: 'PUT' });
    try {
        const response = await fetch(request);
        const data = await response.json();

        if (response.status === 200) {
            return data.value;
        } else {
            const errorMessage =
                data?.validationMessages?.[0]?.message ??
                getMessage('error_on_create_single_customer_invoice');

            onError(errorMessage);
        }
    } catch (e) {
        console.error('Error while creating single customer invoice:', e);
        onError(getMessage('error_on_create_single_customer_invoice'));
    }
    return undefined;
};
