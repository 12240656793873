const DIGITS_NUMBER = 2;

export function formatAxis(rawValue: number | string): number | string {
    const value =
        typeof rawValue === 'string' ? parseFloat2(rawValue) : rawValue;
    const absValue = Math.abs(value);
    if (absValue < 1e3) {
        return rawValue;
    } else if (absValue >= 1e3 && absValue < 1e6) {
        return +(value / 1e3).toFixed(1) + 'K';
    } else if (absValue >= 1e6 && absValue < 1e9) {
        return +(value / 1e6).toFixed(1) + 'M';
    } else if (absValue >= 1e9 && absValue < 1e12) {
        return +(value / 1e9).toFixed(1) + 'G';
    } else {
        return +(value / 1e12).toFixed(1) + 'T';
    }
}

export function formatAxisMultipleDigits(
    rawValue: number | string
): number | string {
    const value =
        typeof rawValue === 'string' ? parseFloat2(rawValue) : rawValue;
    const absValue = Math.abs(value);
    if (absValue < 1e3) {
        return value;
    } else if (absValue >= 1e3 && absValue < 1e6) {
        return +(value / 1e3).toFixed(DIGITS_NUMBER) + 'K';
    } else if (absValue >= 1e6 && absValue < 1e9) {
        return +(value / 1e6).toFixed(DIGITS_NUMBER) + 'M';
    } else if (absValue >= 1e9 && absValue < 1e12) {
        return +(value / 1e9).toFixed(DIGITS_NUMBER) + 'G';
    } else {
        return +(value / 1e12).toFixed(DIGITS_NUMBER) + 'T';
    }
}
