import * as React from 'react';

function EmptyProductGroupsIcon(props: { width: number; height: number }) {
    return (
        <svg
            width={props.width}
            height={props.height}
            viewBox="0 0 200 200"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M125.674 46.156a3.008 3.008 0 00-1.035-.155l-69.593 1.861a3 3 0 00-2.92 2.999v51.101c0 1.059.559 2.039 1.468 2.579l2.437 1.447-14.559 5.301a3 3 0 00-1.973 2.819v55.394a3 3 0 002.65 2.979l61.197 7.192a3.003 3.003 0 001.882-.401l16.595-9.858 8.976 12.347a2.998 2.998 0 002.536 1.234l50.276-1.841a3 3 0 002.89-2.998v-48.387a3 3 0 00-1.374-2.522l-16.742-10.794a3.003 3.003 0 00-1.701-.477l-29.876.751V112l9.821-.63a3 3 0 002.807-2.994v-52.09a3 3 0 00-2.045-2.843l-21.717-7.287zm5.134 66.23l-9.385.602-14.727 7.087v51.347l12.65-7.515v-43.816a3 3 0 012.925-2.999l8.537-.214v-4.492zm-25.352 1.627l-9.832.632 7.483.499 2.349-1.131zm-38.018-1.248l-4.743-2.818-5.806 2.114 10.549.704zm-9.311-12.51V55.272l12.65 4.999v47.498l-12.65-7.514zm18.65 9.587v-48.69l66.659-1.784v46.195l-66.659 4.279zM74.31 55.215l55.108-1.474-5.149-1.728-54.382 1.454 4.423 1.748zm26.386 65.781v52.324l-55.197-6.486v-49.519l55.197 3.681zm24.65 6.101l4.929 4.7-.04 38.984-4.889-6.725v-36.959zm10.883 49.788l44.272-1.621v-42.45l-44.228.655-.044 43.416zm-1.771-49.39l39.082-.579-7.629-4.919-36.263.912 4.81 4.586z"
                fill="#0A41FA"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M69.815 24.488c-7.074-3.716-17.496-1.708-24.93 5.276-7.437 6.985-9.428 16.64-5.276 23.104l.013.02a13.012 13.012 0 002.201 2.589c.02.011 20.904-19.623 28.301-26.581a6197.308 6197.308 0 002.56-2.409 13.79 13.79 0 00-2.87-2z"
                fill="#FFD775"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M162.772 98.596l-29.17-21.125 38.23-12.516-9.06 33.64z"
                fill="#6EB1CA"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M35.332 167L16.27 155.932l8.21-12.429-16.27-9.447L20.2 115.907l35.332 20.516L35.332 167z"
                fill="#F5C1DF"
            />
        </svg>
    );
}

export default EmptyProductGroupsIcon;
