import * as React from 'react';

export interface AuthorizationErrorProps {
    reason?: string;
}

export const AuthorizationError: React.FC<AuthorizationErrorProps> = (
    props
) => (
    <div className="empty-page">
        <div className="empty-form">
            <i className="material-icons empty-page-icon">lock</i>
            <h1 className="empty-page-title">
                {getMessage('text_site_login_insufficient_access')}
            </h1>
            <div className="empty-page-text">
                <p>{props.reason ?? getMessage('text_access_denied_object')}</p>
            </div>
        </div>
    </div>
);
