import { SignatureStatus, Signer } from '@Page/CompanyVerificationFlow/Types';
import React, { useState } from 'react';
import { SignerRow } from '@Page/CompanyVerificationFlow/SendSigningRequestForm/SignerCombination/SignerList/SignerRow';
import { useFormContext } from 'react-hook-form';
import { SendSigningRequestFormValues } from '@Page/CompanyVerificationFlow/SendSigningRequestForm/SendSigningRequestForm';
import { SignerTable } from '@Page/CompanyVerificationFlow/SendSigningRequestForm/SignerCombination/SignerList/SignerTable';

export type SignerListProps = {
    signers: Signer[];
    userSignatureStatus: SignatureStatus;
};

export function SignerList({ signers, userSignatureStatus }: SignerListProps) {
    const { watch } = useFormContext<SendSigningRequestFormValues>();
    const thisIsMeSigner = watch(`thisIsMeSigner`);
    const hideEmailLabel = signers.length === 1 && thisIsMeSigner !== undefined;
    const [isEditModeSet, setIsEditModeSet] = useState<Set<number>>(
        new Set(
            signers
                .filter((signer) => signer.signatureStatus === 'NOT_SIGNED')
                .map((signer) => signer.id)
        )
    );

    const hideThisIsMeLabel =
        (!signers.some((signer) => signer.signatureStatus === 'NOT_SIGNED') &&
            isEditModeSet.size === 0) ||
        userSignatureStatus === 'SIGNED';

    return (
        <SignerTable
            hideEmailLabel={hideEmailLabel}
            hideThisIsMeLabel={hideThisIsMeLabel}
        >
            {signers.map((signer) => (
                <SignerRow
                    userSignatureStatus={userSignatureStatus}
                    signer={signer}
                    // Usually when we retrieve the list of signers from brreg for the first time,
                    // we save the signers in our DB, and they get an id that we use as the key.
                    // But in a special case where a readonly user looks at this list before
                    // a regular user does, such a save to the DB will result in a security exception.
                    // This means that for a readonly user, the id for every signer will be 0.
                    // To avoid a react warning about duplicate keys, we use the name as the key instead.
                    // [Tom Kong 2023-07-03]
                    key={signer.id === 0 ? signer.name : signer.id}
                    thisIsMeToggled={thisIsMeSigner?.id === signer.id}
                    isEditMode={
                        isEditModeSet.has(signer.id) ||
                        signer.signatureStatus === 'NOT_SIGNED'
                    }
                    setIsEditMode={(value) => {
                        let calculatedValue = value;
                        if (typeof value === 'function') {
                            calculatedValue = value(
                                isEditModeSet.has(signer.id)
                            );
                        }

                        setIsEditModeSet((prevSet) => {
                            if (calculatedValue) {
                                return new Set(prevSet.add(signer.id));
                            } else {
                                prevSet.delete(signer.id);
                                return new Set(prevSet);
                            }
                        });
                    }}
                />
            ))}
        </SignerTable>
    );
}
