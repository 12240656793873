import React from 'react';
import { UserFeedbackContactRequestUnchecked } from '@Page/Automation2022/components/FeedbackButton/UserFeedbackContactRequestUnchecked';
import { UserFeedbackContactRequestChecked } from '@Page/Automation2022/components/FeedbackButton/UserFeedbackContactRequestChecked';

interface UserFeedbackContactRequestProps {
    contactRequest: (answer: boolean) => void;
    contactAnswer: boolean | undefined;
}

export function UserFeedbackContactRequest({
    contactRequest,
    contactAnswer,
}: UserFeedbackContactRequestProps) {
    const isFirstFeedback = contactAnswer === undefined;
    const localStorageContactPreference = contactAnswer ?? true;

    const onCheck = (contactPreference: boolean) => {
        contactRequest(contactPreference);
    };

    return (
        <div className={'atl-bg-bg feedbackButton-contactRequestBox'}>
            {isFirstFeedback ? (
                <UserFeedbackContactRequestUnchecked
                    localStorageContactPreference={
                        localStorageContactPreference
                    }
                    onCheck={onCheck}
                />
            ) : (
                <UserFeedbackContactRequestChecked
                    contactRequest={contactRequest}
                    contactAnswer={contactAnswer}
                />
            )}
        </div>
    );
}
