import $ from 'jquery';

import { tlxInitJsonrpc } from '../c-common';
import { tlxAlertWithTrackingId } from '../modules/alert';

/* Internationalization and default values for jQuery widgets */
export function setDefaults() {
    tlxInitJsonrpc();

    $.widget('tlx.toolbar', $.tlx.toolbar, {
        options: {
            lockedCallback: function (
                saveBeforeOtherActionsOKButtonTrackingId
            ) {
                tlxAlertWithTrackingId(
                    getMessage('text_save_before_action'),
                    undefined,
                    saveBeforeOtherActionsOKButtonTrackingId
                );
            },
        },
    });

    $.widget('tlx.tableToolbar', $.tlx.tableToolbar, {
        options: {
            noRowsSelectedCallback: function (
                noRowsSelectedOKButtonTrackingId
            ) {
                tlxAlertWithTrackingId(
                    getMessage('validation_no_element_selected'),
                    undefined,
                    noRowsSelectedOKButtonTrackingId
                );
            },
        },
    });

    $.widget('tlx.filterComponent', $.tlx.filterComponent, {
        options: {
            searchButtonLabel: getMessage('button_refresh'),
            text_more_options: getMessage('text_more_options'),
            text_less_options: getMessage('text_less_options'),
            moreOptionsButtonTrackingId: '',
            fewerOptionsButtonTrackingId: '',
        },
    });

    $.widget('tlx.popupOpener', $.tlx.popupOpener, {
        options: {
            closeText: getMessage('button_cancel'),
            extraInfo: getMessage('validation_no_element_selected'),
        },
    });

    $.widget('ui.dialog', $.ui.dialog, {
        options: {
            closeText: getMessage('button_close'),
            position: { at: 'top+200' },
        },
    });

    if (window.narrowScreen) {
        $.widget('ui.dialog', $.ui.dialog, {
            options: {
                width: '100vw',
                draggable: false,
                modal: false,
                resizable: false,
                position: { my: 'center top', at: 'center top', of: window },
            },
        });
    }

    $.widget('tlx.periodselecter', $.tlx.periodselecter, {
        options: {
            vatTermSuffix: '. ' + getMessage('text_term').toLowerCase(),
            vatTermFullYearText: getMessage('text_full_year_term'),
            labelPeriod: getMessage('text_period'),
            labelYear: getMessage('text_year'),
            labelVatTerm: getMessage('text_vat_term'),
            labelWageTerm: getMessage('text_term'),
            labelMonth: getMessage('option_month'),
            labelWeek: getMessage('option_week'),
            buttonLabelAll: getMessage('text_all_periods'),
            buttonLabelSoFarThisYear: getMessage('text_so_far_this_year'),
            buttonLabelSoFarThisMonth: getMessage('text_so_far_this_month'),
            buttonLabelWholeMonth: getMessage('text_whole_month'),
            buttonLabelThisDay: getMessage('text_this_day'),
            buttonLabelThisWeek: getMessage('text_this_week'),
            buttonTrackingIdAll: '',
            buttonTrackingIdSoFarThisYear: '',
            buttonTrackingIdSoFarThisMonth: '',
            buttonTrackingIdWholeMonth: '',
            buttonTrackingIdThisDay: '',
            buttonTrackingIdThisWeek: '',
            buttonTrackingIdOk: '',
            narrowScreenPeriodTabTrackingId: '',
            narrowScreenWeekTabTrackingId: '',
            narrowScreenMonthTabTrackingId: '',
            narrowScreenYearTabTrackingId: '',
            narrowScreenWageTermTabTrackingId: '',
            narrowScreenVatTermTabTrackingId: '',
        },
    });

    $.widget('tlx.caves', $.tlx.caves, {
        options: {
            account_text: getMessage('text_account_short'),
            asset_text: getMessage('text_asset'),
            customer_text: getMessage('menu_customer'),
            employee_text: getMessage('heading_employee'),
            employee_contact_text: getMessage('text_employee_contact'),
            vendor_text: getMessage('heading_vendor'),
            new_text: getMessage('activity_status_new'),
            text_search_in_all: getMessage('text_search_in_all'),
            text_search_in_assets: getMessage('text_search_in_assets'),
            text_search_in_accounts: getMessage('text_search_in_accounts'),
            text_search_in_customers: getMessage('text_search_in_customers'),
            text_search_in_vendors: getMessage('text_search_in_vendors'),
            text_search_in_employee: getMessage('text_search_in_employee'),
            text_type: getMessage('text_type'),
            text_number: getMessage('text_number'),
            text_name: getMessage('text_name'),
            text_caves_no_selection: getMessage('text_caves_no_selection'),
            heading_customer: getMessage('heading_customer'),
            heading_vendor: getMessage('heading_vendor'),
            option_employees: getMessage('option_employees'),
            text_account_short: getMessage('text_account_short'),
            text_search: getMessage('button_search'),
        },
    });

    $.widget('tlx.tabzum', $.tlx.tabzum, {
        options: {
            labelText: getMessage('text_automatic_summation'),
        },
    });

    if (window.narrowScreen) {
        $.widget('tlx.tabzum', $.tlx.tabzum, {
            options: {
                draggable: false,
                criterium: function ($tr) {
                    return $tr
                        .find('.td.select [type=checkbox][name$=\\.selected]')
                        .is(':checked');
                },
            },
        });
    }

    const dateStrings = { monthNames: {}, monthNamesShort: {}, dayNames: {} };
    dateStrings.monthNames.no = [
        'Januar',
        'Februar',
        'Mars',
        'April',
        'Mai',
        'Juni',
        'Juli',
        'August',
        'September',
        'Oktober',
        'November',
        'Desember',
    ];
    dateStrings.monthNamesShort.no = [
        'jan',
        'feb',
        'mar',
        'apr',
        'mai',
        'jun',
        'jul',
        'aug',
        'sep',
        'okt',
        'nov',
        'des',
    ];
    dateStrings.dayNames.no = [
        's\u00F8ndag',
        'mandag',
        'tirsdag',
        'onsdag',
        'torsdag',
        'fredag',
        'l\u00F8rdag',
    ];
    dateStrings.monthNames['en-GB'] = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];
    dateStrings.monthNamesShort['en-GB'] = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
    ];
    dateStrings.dayNames['en-GB'] = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
    ];
    /* Datepicker */
    $.datepicker.regional['no'] = {
        closeText: 'Lukk',
        prevText: '&laquo;Forrige',
        nextText: 'Neste&raquo;',
        currentText: 'I dag',
        monthNames: dateStrings.monthNames.no,
        monthNamesShort: dateStrings.monthNamesShort.no,
        dayNamesShort: [
            's\u00F8n',
            'man',
            'tir',
            'ons',
            'tor',
            'fre',
            'l\u00F8r',
        ],
        dayNames: dateStrings.dayNames.no,
        dayNamesMin: ['S', 'M', 'T', 'O', 'T', 'F', 'L'],
        weekHeader: 'Uke',
        dateFormat: 'yy-mm-dd',
        firstDay: 1,
        isRTL: false,
        showMonthAfterYear: false,
        yearSuffix: '',
        showWeek: true,
        showButtonPanel: false,
        autoSize: true,
        changeMonth: true,
        changeYear: true,
        hideIfNoPrevNext: true,
        showOtherMonths: true,
        selectOtherMonths: true,
        buttonImage: '/resources/images/tlx-datepicker.png',
        showOn: 'button',
        buttonImageOnly: true,
        buttonText: '',
    };
    $.datepicker.regional['en-GB'] = {
        closeText: 'Done',
        prevText: 'Prev',
        nextText: 'Next',
        currentText: 'Today',
        monthNames: dateStrings.monthNames['en-GB'],
        monthNamesShort: dateStrings.monthNamesShort['en-GB'],
        dayNames: dateStrings.dayNames['en-GB'],
        dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        dayNamesMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
        weekHeader: 'Wk',
        dateFormat: 'yy-mm-dd',
        firstDay: 1,
        isRTL: false,
        showMonthAfterYear: false,
        yearSuffix: '',
        showWeek: true,
        showButtonPanel: false,
        autoSize: true,
        changeMonth: true,
        changeYear: true,
        hideIfNoPrevNext: true,
        showOtherMonths: true,
        selectOtherMonths: true,
        buttonImage: '/resources/images/tlx-datepicker.png',
        showOn: 'button',
        buttonImageOnly: true,
        buttonText: '',
    };

    $.datepicker.setDefaults($.datepicker.regional[getMessage('languageCode')]);
}
