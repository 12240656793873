import * as React from 'react';
import classNames from 'classnames';
import { format } from '../../../../../js/modules/format';

export type InputProps = React.InputHTMLAttributes<HTMLInputElement>;

export const Amount = React.forwardRef(
    (
        { className, type, ...props }: InputProps,
        ref: React.Ref<HTMLInputElement>
    ) => {
        return (
            <input
                ref={ref}
                type={'text'}
                {...props}
                defaultValue={
                    props.defaultValue
                        ? format.amount2(props.defaultValue.toString())
                        : format.amount2(0)
                }
                className={classNames('atl-input atl-input--text', className)}
                onBlur={(e) => {
                    e.currentTarget.value = format.amount2(
                        format.unFormat(e.currentTarget.value)
                    );
                    if (props.onBlur) {
                        props.onBlur(e);
                    }
                }}
                onFocus={(e) => {
                    if (format.unFormat(e.currentTarget.value) === 0) {
                        e.currentTarget.value = '';
                    }
                    if (props.onFocus) {
                        props.onFocus(e);
                    }
                }}
            />
        );
    }
);

Amount.displayName = 'Input';
