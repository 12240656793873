import $ from 'jquery';
import { showValidationMessage } from './notification';

export const utils = (function () {
    function makeIdMap(objects) {
        return makeMap(objects, 'id');
    }

    function makeMap(objects, key) {
        const map = {};
        for (let i = 0; i < objects.length; i++) {
            const object = objects[i];
            map[object[key]] = object;
        }
        return map;
    }

    //StringBuffer
    function StringBuffer() {
        this.buffer = [];
    }

    StringBuffer.prototype.append = function append(string) {
        this.buffer.push(string);
        return this;
    };

    StringBuffer.prototype.toString = function toString() {
        return this.buffer.join('');
    };

    function uniqueIdCreator(name) {
        'use strict';
        let counter = 0;
        return function () {
            return name + '' + counter++;
        };
    }

    /**
     * Seems to only be used inside defaultAjaxAction for now.
     * @param obj
     * @param property
     */
    function removeEmpty(obj, property) {
        let oldArray;
        if ($.isArray(property)) {
            for (let i = 0; i < property.length; i++) {
                removeEmpty(obj, property[i]);
            }
        } else {
            if ($.isArray(obj[property])) {
                oldArray = obj[property];
                const newArray = [];
                for (let i = 0; i < oldArray.length; i++) {
                    if (oldArray[i]) {
                        newArray.push(oldArray[i]);
                    }
                }
                obj[property] = newArray;
            }
        }
    }

    // Events

    function stopEvent(e) {
        if (!e) {
            e = window.event;
        }
        if (!e) {
            return;
        }
        if (e.preventDefault) {
            e.preventDefault();
        } else {
            e.returnValue = false;
        }
        if (e.stopPropagation) {
            e.stopPropagation();
        } else {
            e.cancelBubble = true;
        }
    }

    /**
     * Emits scrollStart and scrollStop events on the given scrollContainer (DOM Node Element).
     *
     * @param scrollContainer
     */
    function createStartStopScrollEvent(scrollContainer) {
        // HOTFIX, new Event() doesn't work in IE. This method is deprecated in Edge.
        const scrollStart = document.createEvent('CustomEvent');
        const scrollStop = document.createEvent('CustomEvent');

        scrollStart.initCustomEvent('scrollStart', false, true, {});
        scrollStop.initCustomEvent('scrollStop', false, true, {});

        let timer = null;
        scrollContainer.addEventListener('scroll', function () {
            if (timer !== null) {
                clearTimeout(timer);
            } else {
                // Wait 50 ms before firing first start scroll event, because most scroll events usually are interested
                // if scroll container has some scroll offset or not. If we fire scroll event right away the offset might
                // not be there yet (or be high enough yet).
                setTimeout(function () {
                    scrollContainer.dispatchEvent(scrollStart);
                }, 50);
            }

            timer = setTimeout(function () {
                scrollContainer.dispatchEvent(scrollStop);
                timer = null;
            }, 100);
        });
    }

    function capitalizeFirstLetter(string, afterEverySpace) {
        if (string.length < 1 || typeof string !== 'string') {
            return;
        }
        string = string.toLowerCase();

        if (afterEverySpace) {
            return string
                .split(' ')
                .map(function (word) {
                    return word.charAt(0).toUpperCase() + word.slice(1);
                })
                .join(' ');
        }

        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    function getRandomInt(min, max) {
        return Math.floor(Math.floor(max - min) * Math.random()) + min;
    }

    /**
     *	Tries to convert a timestamp or a time period to decimal.
     *	Can not convert time > 23h and 59min, or if the period passes midnight.
     *
     * @author Harry
     * @date 2018-08-16
     * @param value - a time value in the format of 'tt:mm' or 'tt:mm-tt:mm'
     * @returns time value in decimal or the input value if unable to convert.
     */
    function timeToDecimal(value) {
        let total = -1;
        const regex0 = RegExp('^([0-1]?[0-9]|[2][0-3]):[0-5][0-9]$');
        const regex1 = RegExp(
            '^([0-1]?[0-9]|[2][0-3]):[0-5][0-9]-([0-1]?[0-9]|[2][0-3]):[0-5][0-9]$'
        );

        if (regex0.test(value)) {
            const hours = value.split(':')[0] - 0;
            const min = value.split(':')[1] - 0;
            total = hours + min / 60;
        } else if (regex1.test(value)) {
            const fromHours = value.split('-')[0].split(':')[0] - 0;
            const fromMin = value.split('-')[0].split(':')[1] - 0;

            const toHours = value.split('-')[1].split(':')[0] - 0;
            const toMin = value.split('-')[1].split(':')[1] - 0;

            if (fromHours > toHours) {
                showValidationMessage({
                    message: getMessage(
                        'validation_first_can_not_be_bigger_then_last'
                    ),
                });
                return 0;
            }
            total = toHours + toMin / 60 - (fromHours + fromMin / 60);
        } else {
            return value;
        }

        if (total < 0) {
            return value;
        }
        return total;
    }

    /**
     * Assigns the given value to the given target at the given path.
     *
     * @param target The object to insert the value into.
     * @param value The value to assign at the innermost path segment.
     * @param pathArray Array of path segments in order of the last being the outermost and first being the innermost.
     *
     * @returns The target after assignment of the value.
     *
     * @author tellef
     * @date 2019-03-06
     */
    function setValueAtPathArray(target, value, pathArray) {
        const currentPathSegment = pathArray.pop();

        if (pathArray.length === 0) {
            target[currentPathSegment] = value;
            return target;
        } else {
            if (target[currentPathSegment] === undefined) {
                target[currentPathSegment] = {};
            }

            target[currentPathSegment] = setValueAtPathArray(
                target[currentPathSegment],
                value,
                pathArray
            );
            return target;
        }
    }

    /**
     * Assigns the given value to the given target at the given property path.
     * NOTE: Does not support traversing arrays currently.
     *
     * @param target The object to insert the value into.
     * @param value The value to assign at the innermost path segment.
     * @param path The path in target to assign value to. Format is bean property path.
     *
     * @returns The target after assignment of the value.
     *
     * @author tellef
     * @date 2019-03-06
     */
    function setValueAtPath(target, value, path) {
        return setValueAtPathArray(target, value, path.split('.').reverse());
    }

    return {
        makeMap: makeMap,
        makeIdMap: makeIdMap,
        StringBuffer: StringBuffer,
        uniqueIdCreator: uniqueIdCreator,
        widgetIdCreator: uniqueIdCreator('widget'),
        removeEmpty: removeEmpty,
        stopEvent: stopEvent,
        createStartStopScrollEvent: createStartStopScrollEvent,
        capitalizeFirstLetter: capitalizeFirstLetter,
        getRandomInt: getRandomInt,
        timeToDecimal: timeToDecimal, // Used in updateHourlistNew.js
        setValueAtPath: setValueAtPath,
    };
})();
