import './InvoicingList.css';

export interface InvoicingListProps {
    children: React.ReactNode;
}

export const InvoicingList = ({ children }: InvoicingListProps) => {
    return (
        <ul className="atl-px-0 atl-m-0 atl-flex atl-flex-col kb-batch-invoicing-list">
            <div className="atl-px-32 kb-invoicing-list-item--grid atl-text-sm atl-text-grey-60 kb-invoicing-list__header">
                <span>{getMessage('text_customer')}</span>

                <span className="atl-text-right">
                    {getMessage('text_sum_ex_vat')}
                </span>

                <span>{getMessage('text_send_type')}</span>
            </div>

            {children}
        </ul>
    );
};
