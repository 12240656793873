import { store } from './createStore';

import { HistoryUpdate } from './action/HistoryShim.action';
import { HistoryState } from './types/HistoryShim.type';

const originalReplaceState = window.history.replaceState.bind(window.history);
const shimReplaceState: History['replaceState'] = (data, title, url) => {
    store.dispatch(
        HistoryUpdate(data, title, url instanceof URL ? url.toString() : url)
    );

    return originalReplaceState(data, title, url);
};

// eslint-disable-next-line @typescript-eslint/unbound-method
window.history.replaceState = shimReplaceState;

const originalPushState = window.history.pushState.bind(window.history);
const shimPushState: History['pushState'] = (data, title, url) => {
    store.dispatch(
        HistoryUpdate(data, title, url instanceof URL ? url.toString() : url)
    );

    return originalPushState(data, title, url);
};

// eslint-disable-next-line @typescript-eslint/unbound-method
window.history.pushState = shimPushState;

window.addEventListener('popstate', (ev) => {
    store.dispatch(
        HistoryUpdate(ev.state, window.document.title, window.location.href)
    );
});

export function parseUrl(url: URL): HistoryState {
    const {
        href,
        protocol,
        username,
        password,
        hostname,
        port,
        host,
        origin,
        pathname,
        search,
        searchParams,
    } = url;

    const queryParams: Record<string, string | Array<string>> = {};

    for (const key of searchParams.keys()) {
        const value = searchParams.getAll(key);
        queryParams[key] = value.length === 1 ? value[0] : value;
    }

    return {
        href,
        protocol,
        username,
        password,
        hostName: hostname,
        port,
        host,
        origin,
        pathname,
        query: search,
        queryParams,
    };
}
