import * as React from 'react';
import { useEffect, useState } from 'react';

import './BatchInvoicing.css';
import {
    InvoiceSettings,
    Order,
} from '@Page/InvoicingDialog/types/InvoicingDialog.type';
import { Icon } from '@Component/Icon';
import { Text } from '@Component/Text';
import { Button, Group, Input, Label, ModalCloseButton } from '@tlx/atlas';
import { dateUtil } from '../../../../../../js/modules/date';
import { InvoicingList } from '@Page/InvoicingDialog/component/BatchInvoicing/InvoicingList';
import {
    BatchInvoiceOrderState,
    InvoicingListItem,
    InvoicingListItemSkeleton,
    OrderInvoicingStateMessage,
} from '@Page/InvoicingDialog/component/BatchInvoicing/InvoicingListItem';
import { Company } from '@Page/InvoicingDialog/component/InvoicingModalDataFetching';
import { groupOrdersByInvoice } from '@Page/InvoicingDialog/utils/groupOrdersByInvoice';
import { SkeletonRectangle } from '@Page/InvoicingDialog/component/SkeletonRectangle/SkeletonRectangle';
import { InvoiceTotal } from '@Page/InvoicingDialog/component/InvoicingForm/InvoicingForm';
import { Tooltip } from '@Component/AntsComponents/ToolTip/components/Tooltip';

async function handleInvoicing(
    onSubmit: (
        date: string,
        orders: Order[][],
        onError: (
            batchInvoicingGroupIndex: number,
            errorMessage: OrderInvoicingStateMessage
        ) => void,
        onSuccess: (
            batchInvoicingGroupIndex: number,
            successMessage: OrderInvoicingStateMessage
        ) => void,
        onInfo: (
            batchInvoicingGroupIndex: number,
            infoMessage: OrderInvoicingStateMessage
        ) => void,
        createBackOrders: boolean
    ) => Promise<URL | undefined>,
    invoicingDate: string,
    batchInvoicingGroupStates: BatchInvoice[],
    setOrderStates: React.Dispatch<React.SetStateAction<BatchInvoice[]>>,
    createBackOrders: boolean
): Promise<URL | undefined> {
    const setOrdersToSending = () => {
        const orderStatesCopy = batchInvoicingGroupStates.map((orderState) => {
            orderState.invoicingState = {
                status: InvoicingStatus.SENDING,
                messages: [],
            };
            return orderState;
        });
        setOrderStates(orderStatesCopy);
    };

    const setOrderErrorState = (
        orderStateIndex: number,
        errorMessage: OrderInvoicingStateMessage
    ) => {
        const orderStatesCopy = batchInvoicingGroupStates.slice();
        orderStatesCopy[orderStateIndex].invoicingState = {
            status: InvoicingStatus.SENT_ERROR,
            messages: [errorMessage],
        };

        setOrderStates(orderStatesCopy);
    };

    const setOrderSuccessState = (
        orderStateIndex: number,
        successMessage: OrderInvoicingStateMessage
    ) => {
        const orderStatesCopy = batchInvoicingGroupStates.slice();
        orderStatesCopy[orderStateIndex].invoicingState = {
            status: InvoicingStatus.SENT_SUCCESS,
            messages: [successMessage],
        };

        setOrderStates(orderStatesCopy);
    };

    const setOrderInfoState = (
        orderStateIndex: number,
        infoMessage: OrderInvoicingStateMessage
    ) => {
        const orderStatesCopy = batchInvoicingGroupStates.slice();
        orderStatesCopy[orderStateIndex].invoicingState = {
            status: InvoicingStatus.SENT_INFO,
            messages: [infoMessage],
        };

        setOrderStates(orderStatesCopy);
    };

    setOrdersToSending();

    return onSubmit(
        invoicingDate,
        batchInvoicingGroupStates.map((batchInvoicingGroupState) =>
            batchInvoicingGroupState.orderStates.map(
                (orderState) => orderState.order
            )
        ),
        setOrderErrorState,
        setOrderSuccessState,
        setOrderInfoState,
        createBackOrders
    );
}

export interface BatchInvoicingProps {
    isLoading?: boolean;
    ordersLoadingCount?: number;
    className?: string;
    company: Company | undefined;
    orders: Order[];
    onSubmit: (
        date: string,
        orders: Order[][],
        onError: (
            batchInvoicingGroupIndex: number,
            errorMessage: OrderInvoicingStateMessage
        ) => void,
        onSuccess: (
            batchInvoicingGroupIndex: number,
            successMessage: OrderInvoicingStateMessage
        ) => void,
        onInfo: (
            batchInvoicingGroupIndex: number,
            infoMessage: OrderInvoicingStateMessage
        ) => void,
        createBackOrder: boolean
    ) => Promise<URL | undefined>;
    invoiceSettings: InvoiceSettings;
}

export const getInvoicingValidationErrors = (order: Order): string[] => {
    const messages = [];
    if (order.isClosed) {
        messages.push(getMessage('validation_the_order_is_closed'));
    }

    if (order.isSubscription && !order.preliminaryInvoice?.isApproved) {
        messages.push(
            getMessage(
                'validation_subscription_order_must_be_approved_before_invoicing'
            )
        );
    }

    if (
        order.orderLines.length === 0 &&
        (order.preliminaryInvoice?.travelReports.length === 0 ||
            !order.preliminaryInvoice)
    ) {
        messages.push(getMessage('validation_missing_orderline'));
    }
    return messages;
};

enum BatchInvoicingStatus {
    PRE_SEND,
    SENDING,
    SENT,
}

export enum InvoicingStatus {
    PRE_SEND,
    VALIDATION_ERROR,
    SENDING,
    SENT_SUCCESS,
    SENT_INFO,
    SENT_ERROR,
}

export interface BatchInvoice {
    orderStates: BatchInvoiceOrderState[];
    invoicingState: {
        status: InvoicingStatus;
        messages: Array<string | JSX.Element>;
    };
}

const compareInvoicingStatus = (
    batchInvoicingGroupStateA: BatchInvoice,
    batchInvoicingGroupStateB: BatchInvoice
) => {
    if (
        batchInvoicingGroupStateA.invoicingState.status ===
            InvoicingStatus.VALIDATION_ERROR &&
        batchInvoicingGroupStateB.invoicingState.status !==
            InvoicingStatus.VALIDATION_ERROR
    ) {
        return -1;
    } else if (
        batchInvoicingGroupStateB.invoicingState.status ===
            InvoicingStatus.VALIDATION_ERROR &&
        batchInvoicingGroupStateA.invoicingState.status !==
            InvoicingStatus.VALIDATION_ERROR
    ) {
        return 1;
    }
    return 0;
};

// Collect metrics for all sent invoices and send to JSON-RPC endpoint
function collectMetricsForSentInvoices(batchInvoices: BatchInvoice[]) {
    try {
        const batchInvoicesSentSuccessfully = batchInvoices.filter(
            (batchInvoice) =>
                batchInvoice.invoicingState.status ==
                InvoicingStatus.SENT_SUCCESS
        ).length;

        const batchInvoicesSentWithInfo = batchInvoices.filter(
            (batchInvoice) =>
                batchInvoice.invoicingState.status == InvoicingStatus.SENT_INFO
        ).length;

        const batchInvoicesSentWithError = batchInvoices.filter(
            (batchInvoice) =>
                batchInvoice.invoicingState.status == InvoicingStatus.SENT_ERROR
        ).length;

        window.jsonrpc.KillBillMetrics.observeCountBulkInvoicingCompletedWithStatus(
            batchInvoicesSentSuccessfully,
            batchInvoicesSentWithInfo,
            batchInvoicesSentWithError
        );
        // eslint-disable-next-line no-empty
    } catch (ignore) {}
}

export function BatchInvoicing({
    isLoading = false,
    ordersLoadingCount = 0, // Used as an estimate to generate loading skeleton elements (we can't know if they will belong to single customer invoice)
    className,
    orders: selectedOrders,
    company,
    onSubmit,
    invoiceSettings,
}: BatchInvoicingProps) {
    const [batchInvoices, setBatchInvoices] = useState<BatchInvoice[]>([]);
    const [isBatchInvoicesLoading, setIsBatchInvoicesLoading] = useState(true);
    const [createBackOrders, setCreateBackOrders] = useState(false);
    const isBackOrderAvailable = selectedOrders.some(
        (order) => order.canCreateBackorder
    );

    useEffect(() => {
        const ordersGroupedByInvoice = groupOrdersByInvoice(selectedOrders);

        const mappedBatchInvoices: Array<BatchInvoice> =
            ordersGroupedByInvoice.map((ordersInGroup): BatchInvoice => {
                let isValidationError = false;

                const mappedOrderStates = ordersInGroup.map((order) => {
                    const validationErrorsForOrder =
                        getInvoicingValidationErrors(order);

                    if (validationErrorsForOrder.length > 0) {
                        isValidationError = true;
                    }

                    return {
                        order: order,
                        messages: validationErrorsForOrder,
                    };
                });

                return {
                    orderStates: mappedOrderStates,
                    invoicingState: {
                        status: isValidationError
                            ? InvoicingStatus.VALIDATION_ERROR
                            : InvoicingStatus.PRE_SEND,
                        messages: [],
                    },
                };
            });

        mappedBatchInvoices.sort(compareInvoicingStatus);
        setBatchInvoices(mappedBatchInvoices);
    }, [selectedOrders]);

    useEffect(() => {
        setIsBatchInvoicesLoading(batchInvoices.length === 0 || isLoading); // mapping of data has to be finished before we truly are done loading
    }, [batchInvoices.length, isLoading]);

    const [batchInvoicingState, setBatchInvoicingState] =
        useState<BatchInvoicingStatus>(BatchInvoicingStatus.PRE_SEND); // State of the dialog (should it fx. be disabled)
    const [dateValidationError, setDateValidationError] = useState('');
    const [isOrderValidationError, setIsOrderValidationError] = useState(false);
    const [invoicingDate, setInvoicingDate] = useState(
        dateUtil.formatDate(dateUtil.today(), 'yyyy-MM-dd')
    );
    const [pdfURLForManualInvoices, setPDFURLForManualInvoices] = useState<
        undefined | URL
    >(undefined);

    const [startInvoiceNumberRange, setStartInvoiceNumberRange] = useState(0);
    const [endInvoiceNumberRange, setEndInvoiceNumberRange] = useState(0);

    const companyCurrencyCode = company?.currency.code;

    let ordersTotalAmount = 0;
    let ordersTotalAmountExcludingVAT = 0;
    batchInvoices.forEach((batchInvoicingGroupState) =>
        batchInvoicingGroupState.orderStates.forEach((orderState) => {
            ordersTotalAmount +=
                orderState.order.preliminaryInvoice?.amount ?? 0;

            const amountExcludingVAT =
                orderState.order.preliminaryInvoice?.amountExcludingVat ?? 0;
            const amountRoundoff =
                orderState.order.preliminaryInvoice?.amountRoundoff ?? 0;
            ordersTotalAmountExcludingVAT +=
                amountExcludingVAT + amountRoundoff;
        })
    );

    useEffect(() => {
        if (
            batchInvoicingState == BatchInvoicingStatus.SENT &&
            !isBatchInvoicesLoading
        ) {
            collectMetricsForSentInvoices(batchInvoices);
        }
    }, [batchInvoicingState, batchInvoices, isBatchInvoicesLoading]);

    useEffect(() => {
        let isAnInvoiceSending = false;
        let isAllInvoicesSent = true;
        let newIsOrderValidationError = false;

        batchInvoices.forEach((batchInvoiceGroupState) => {
            if (
                batchInvoiceGroupState.invoicingState.status ===
                InvoicingStatus.PRE_SEND
            ) {
                isAllInvoicesSent = false;
            }

            if (
                batchInvoiceGroupState.invoicingState.status ===
                InvoicingStatus.SENDING
            ) {
                isAnInvoiceSending = true;
                isAllInvoicesSent = false;
            }

            if (
                batchInvoiceGroupState.invoicingState.status ===
                InvoicingStatus.VALIDATION_ERROR
            ) {
                newIsOrderValidationError = true;
            }
        });

        setIsOrderValidationError(newIsOrderValidationError);

        if (isAnInvoiceSending) {
            setBatchInvoicingState(BatchInvoicingStatus.SENDING);
        } else if (isAllInvoicesSent) {
            setBatchInvoicingState(BatchInvoicingStatus.SENT);
        } else {
            setBatchInvoicingState(BatchInvoicingStatus.PRE_SEND);
        }
    }, [batchInvoices]);

    useEffect(() => {
        const newStartInvoiceNumberRange = invoiceSettings.nextInvoiceNumber;
        const newEndInvoiceNumberRange =
            newStartInvoiceNumberRange + batchInvoices.length - 1;

        setStartInvoiceNumberRange(newStartInvoiceNumberRange);
        setEndInvoiceNumberRange(newEndInvoiceNumberRange);
    }, [batchInvoices, invoiceSettings.nextInvoiceNumber]);

    const handleDateChange = (e: { target: { value: string } }) => {
        const userInput = e.target.value;
        const isValidDate = dateUtil.isDate(e.target.value, 'yyyy-MM-dd');
        const parsedDate = dateUtil.parseDate(userInput);
        if (!isValidDate || !parsedDate) {
            setDateValidationError(getMessage('validation_invalid_date'));
            return;
        }

        const maxValidYear = dateUtil.getYear(dateUtil.today()) + 1;
        if (parsedDate.getFullYear() > maxValidYear) {
            setDateValidationError(
                getMessage('validation_invoicing_date_too_late')
            );
            return;
        }

        setDateValidationError('');
        setInvoicingDate(userInput);
    };

    let invoicingListItems;
    if (isBatchInvoicesLoading) {
        const items = [];
        for (let i = 0; i < ordersLoadingCount; i++) {
            items.push(
                <InvoicingListItemSkeleton
                    className="kb-invoicing-list__item"
                    key={i}
                />
            );
        }
        invoicingListItems = items;
    } else {
        invoicingListItems = batchInvoices.map((batchInvoice, index) => {
            let invoiceListItemColor: undefined | 'error' | 'info' | 'success';
            if (
                batchInvoice.invoicingState.status ===
                    InvoicingStatus.VALIDATION_ERROR ||
                batchInvoice.invoicingState.status ===
                    InvoicingStatus.SENT_ERROR
            ) {
                invoiceListItemColor = 'error';
            } else if (
                batchInvoice.invoicingState.status === InvoicingStatus.SENT_INFO
            ) {
                invoiceListItemColor = 'info';
            } else if (
                batchInvoice.invoicingState.status ===
                InvoicingStatus.SENT_SUCCESS
            ) {
                invoiceListItemColor = 'success';
            }

            return (
                <InvoicingListItem
                    className="kb-invoicing-list__item"
                    variant={invoiceListItemColor}
                    ordersWithMessages={batchInvoice.orderStates}
                    status={batchInvoice.invoicingState.status}
                    statusMessages={batchInvoice.invoicingState.messages}
                    onRemoveItem={() => {
                        const ordersCopy = [...batchInvoices];
                        ordersCopy.splice(index, 1);
                        setBatchInvoices(ordersCopy);
                    }}
                    key={batchInvoice.orderStates[0].order.id}
                />
            );
        });
    }

    const sendInvoicesText =
        batchInvoices.length === 1 ? (
            getMessage('text_send_invoice')
        ) : (
            <Text
                textKey="text_send_n_invoices"
                textKeyArgs={[batchInvoices.length]}
            />
        );

    return (
        <form
            data-trackingid="react-batch-invoicing-form"
            onSubmit={async (e) => {
                e.preventDefault();

                const pdfURLForManualInvoicing = await handleInvoicing(
                    onSubmit,
                    invoicingDate,
                    batchInvoices,
                    setBatchInvoices,
                    createBackOrders
                );

                if (pdfURLForManualInvoicing) {
                    window.open(pdfURLForManualInvoicing, '_blank')?.focus();
                    setPDFURLForManualInvoices(pdfURLForManualInvoicing);
                }
            }}
            className={className}
        >
            <header className="atl-p-32 atl-flex kb-batch-invoice-border-b-grey-20 atl-items-end atl-justify-between">
                <ModalCloseButton />

                <div className="atl-flex atl-flex-col">
                    <div className="atl-flex atl-mb-4">
                        <h2 className="atl-text-xl atl-m-0">
                            {getMessage('text_send_invoices')}
                        </h2>

                        <div className="atl-bg-turquoise-10 atl-text-turquoise-120 atl-text-s atl-px-8 atl-py-4 atl-rounded atl-ml-16">
                            {batchInvoices.length} {getMessage('text_piece_2')}
                        </div>
                    </div>

                    <div className="atl-flex atl-text-base">
                        <span className="atl-text-grey-60 atl-mr-4">
                            {getMessage('text_invoice_number')}:
                        </span>
                        <InvoiceNumberRange
                            isLoading={isBatchInvoicesLoading}
                            start={startInvoiceNumberRange}
                            end={endInvoiceNumberRange}
                        />
                    </div>
                </div>
            </header>

            <div className="atl-p-32 atl-flex atl-items-end atl-justify-between kb-batch-invoice-border-b-grey-20">
                <Group className="atl-flex atl-items-end atl-w-full">
                    <div className="atl-flex-1">
                        <Label htmlFor="invoicingDate">
                            {getMessage('text_invoice_date')}
                        </Label>
                        <Input
                            autoFocus
                            data-testid="batch-invoice-date-picker"
                            type="date"
                            id="invoicingDate"
                            defaultValue={invoicingDate}
                            onChange={handleDateChange}
                            disabled={isOrderValidationError}
                        />
                        {dateValidationError && (
                            <div className="atl-text-red-100">
                                {dateValidationError}
                            </div>
                        )}
                    </div>

                    {isBackOrderAvailable && (
                        <div className="atl-flex atl-gap-8 atl-items-center atl-flex-1 atl-mb-8">
                            <Input
                                type="checkbox"
                                checked={createBackOrders}
                                onChange={(_e) =>
                                    setCreateBackOrders(!createBackOrders)
                                }
                                name="createBackOrders"
                                data-testid="batch-invoicing-create-back-order-button"
                            />
                            <Label
                                className="atl-m-0"
                                htmlFor="createBackOrders"
                            >
                                {getMessage('text_restorder')}
                            </Label>

                            <Tooltip
                                className="atl-flex"
                                content={getMessage(
                                    'text_create_back_orders_for_orders'
                                )}
                                direction="right"
                            >
                                <Icon
                                    className="atl-text-blue-80 atl-mr-4"
                                    icon="error_outline"
                                />
                            </Tooltip>
                        </div>
                    )}
                </Group>
            </div>

            <div className="atl-pt-32">
                <InvoicingList>{invoicingListItems}</InvoicingList>
            </div>

            {pdfURLForManualInvoices && (
                <div className="atl-px-32 atl-py-8 atl-flex atl-justify-end">
                    <Button
                        variant="secondary"
                        data-testid={'batch-invoice-open-pdf-button'}
                        onClick={() =>
                            window
                                .open(pdfURLForManualInvoices, '_blank')
                                ?.focus()
                        }
                    >
                        {getMessage('text_open_compiled_pdf')}
                        <Icon icon="open_in_new" />
                    </Button>
                </div>
            )}

            <div className="atl-p-32 atl-flex atl-justify-between kb-batch-invoice-border-t-grey-20">
                <div className="atl-flex atl-items-end">
                    {batchInvoicingState === BatchInvoicingStatus.SENT &&
                    !isBatchInvoicesLoading ? (
                        <Button
                            data-testid="done-batch-invoice-button"
                            onClick={() => {
                                window.location.reload();
                            }}
                        >
                            {getMessage('button_finish_it')}
                        </Button>
                    ) : (
                        <>
                            <Button
                                type="submit"
                                data-testid="batch-invoice-submit-button"
                                disabled={
                                    isBatchInvoicesLoading ||
                                    isOrderValidationError ||
                                    !!dateValidationError ||
                                    batchInvoicingState ===
                                        BatchInvoicingStatus.SENDING
                                }
                            >
                                {sendInvoicesText}
                            </Button>
                        </>
                    )}
                </div>

                <InvoiceTotal
                    isLoading={isLoading}
                    total={ordersTotalAmount}
                    totalExcludingVAT={ordersTotalAmountExcludingVAT}
                    currencyCode={companyCurrencyCode}
                />
            </div>
        </form>
    );
}

function InvoiceNumberRange({
    isLoading,
    start,
    end,
}: {
    isLoading?: boolean;
    start?: number;
    end?: number;
}) {
    return (
        <div className="atl-flex">
            <span className="atl-flex">
                #
                {isLoading ? (
                    <SkeletonRectangle width={40} height={20} />
                ) : (
                    start
                )}
            </span>
            {start !== end && (
                <>
                    <Icon
                        className="kb-invoice-number-range__arrow"
                        icon="east"
                    />
                    <span className="atl-flex">
                        #
                        {isLoading ? (
                            <SkeletonRectangle width={40} height={20} />
                        ) : (
                            end
                        )}
                    </span>
                </>
            )}
        </div>
    );
}
