import { MultipleSelectionProductGroups } from '@Page/LogisticsV2/components/DataTree/MultipleSelectionProductGroups';
import { LogisticsModal } from '@Page/LogisticsV2/components/Modals/Modal';
import {
    ProductOverviewBulkActionsModel,
    ProductOverviewModel,
} from '@Page/LogisticsV2/pages/Product/overview/types';
import { PriceIconSVG } from '@Page/LogisticsV2/utils/svgIcons';
import {
    Alert,
    AlertContent,
    AlertDescription,
    AlertHeader,
    DeleteIcon,
    EditIcon,
    GroupIcon,
    Modal,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalTitle,
    PriceTagIcon,
    Spinner,
    TableLayoutTopbar,
} from '@tlx/atlas';
import {
    BatchActions,
    BatchActionsButton,
    BatchActionsButtons,
    BatchActionsTitle,
} from '@tlx/astro-shared';
import React, { useEffect, useState } from 'react';
import { ChangeProductDetailsModal } from '@Page/LogisticsV2/pages/Product/overview/ChangeProductDetails/ChangeProductDetailsModal';
import { ChangePriceContentSection } from '@Page/LogisticsV2/pages/Product/overview/ChangePriceForm/ChangePriceContentSection';
import { ChangePriceForm } from '@Page/LogisticsV2/pages/Product/overview/ChangePriceForm/ChangePriceForm';
import { ChangePricePurchasePriceSection } from '@Page/LogisticsV2/pages/Product/overview/ChangePriceForm/ChangePricePurchasePriceSection';
import { ChangePriceSellingPriceSection } from '@Page/LogisticsV2/pages/Product/overview/ChangePriceForm/ChangePriceSellingPriceSection';
import { ChangePriceTitleSection } from '@Page/LogisticsV2/pages/Product/overview/ChangePriceForm/ChangePriceTitleSection';
import { ChangeProductGroupsActions } from '@Page/LogisticsV2/pages/Product/overview/ChangeProductGroups/ChangeProductGroupsActions';
import { ChangeProductGroupsContent } from '@Page/LogisticsV2/pages/Product/overview/ChangeProductGroups/ChangeProductGroupsContent';
import { ChangeProductGroupsEmptyState } from '@Page/LogisticsV2/pages/Product/overview/ChangeProductGroups/ChangeProductGroupsEmptyState';
import { ChangeProductGroupsWarning } from '@Page/LogisticsV2/pages/Product/overview/ChangeProductGroups/ChangeProductGroupsWarning';
import { ChangePriceModalActions } from './components';
import { getChangePriceInput } from './functions';
import { ProductGroupsIcon } from './icons';
import './style.css';
import { ChangeProductDetailsRackbeatModal } from './Rackbeat/ChangeProductDetailsRackbeatModal';
import { ChangePriceWarningSection } from '@Page/LogisticsV2/pages/Product/overview/ChangePriceForm/ChangePriceWarningSection';

export function ProductOverviewBatchActions({
    products,
    bulkActions,
}: {
    products: ProductOverviewModel;
    bulkActions: ProductOverviewBulkActionsModel;
}) {
    const [showDeleteWarning, setShowDeleteWarning] = useState(false);
    const [showPriceWarning, setShowPriceWarning] = useState(false);
    const [showPriceModal, setShowPriceModal] = useState(false);
    const [showEditProductRackbeatModal, setShowEditProductRackbeatModal] =
        useState(false);
    const [isDisable, setIsDisable] = useState(true);
    const [affectedProducts, setAffectedProducts] = useState(0);
    const [editPriceChange, setEditPriceChange] = useState({
        type: 'percent',
        value: '',
    });
    const [isPurchasePrice, setIsPurchasePrice] = useState(true);

    const handleOnChange = (input: HTMLInputElement | HTMLSelectElement) => {
        if (
            (input.value.length > 0 &&
                input.value !== 'on' &&
                input.value !== 'sales' &&
                input.value !== 'purchase') ||
            input.name === 'changePricePurchaseSupplierRadio'
        ) {
            const edited = getChangePriceInput(input);
            if (edited) {
                setEditPriceChange(edited);
            }
            setIsDisable(false);
        } else {
            setIsDisable(true);
        }
        if (input.value === 'purchase') {
            setIsPurchasePrice(true);
        }
        if (input.value === 'sales') {
            setIsPurchasePrice(false);
        }
    };

    const handleOnSubmit = (
        formElement: HTMLFormElement,
        values: { [k: string]: FormDataEntryValue }
    ) => {
        products.onChangePrice(formElement, values);
        setShowPriceModal(false);
        setShowPriceWarning(false);
        setIsDisable(true);
        products.onChangeSelectedIds([]);
    };

    const [showProductGroupsModal, setShowProductGroupsModal] = useState(
        bulkActions.loadingBulkGroups
    );
    const [editProductGroups, setEditProductGroups] = useState('add');
    const [showEditProductModal, setShowEditProductModal] = useState(false);
    const showSelectAll = products.totalLines.length !== products.data.length;
    const customerId =
        document.querySelector<HTMLInputElement>(
            '#customerIdForProductOverview'
        )?.value ?? null;
    const isSupplierProduct =
        !products.filters.supplierFilter.includes('') || customerId !== null;

    useEffect(() => {
        if (!bulkActions.loadingBulkGroups) {
            setShowProductGroupsModal(false);
        }
    }, [bulkActions.loadingBulkGroups]);

    return (
        <TableLayoutTopbar hidden={products.selectedIds.length === 0}>
            <BatchActions>
                <BatchActionsTitle>
                    <div className="atl-flex atl-gap-16">
                        {products.selectedIds.length}{' '}
                        {getMessage('text_selected')}
                        {products.isTotalLinesLoading ? (
                            <Spinner size="small" />
                        ) : showSelectAll ? (
                            <div
                                className="atl-text-base atl-text-blue-100 atl-underline"
                                onClick={() => products.onSelectAll()}
                                style={{ cursor: 'pointer' }}
                            >
                                {getMessage('text_select_all')}{' '}
                                {products.totalLines?.length}
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>
                </BatchActionsTitle>
                <BatchActionsButtons>
                    {!moduleRackbeat && (
                        <BatchActionsButton
                            variant="secondary"
                            data-testid="product-overview-batch-delete-button"
                            onClick={() => {
                                products.getNotDeletableProducts();
                                setShowDeleteWarning(true);
                            }}
                        >
                            {getMessage('text_delete')}
                            <DeleteIcon />
                        </BatchActionsButton>
                    )}
                    {moduleLogistics && !isSupplierProduct && (
                        <BatchActionsButton
                            variant="secondary"
                            data-testid="product-overview-change-product-groups"
                            onClick={() => {
                                setShowProductGroupsModal(true);
                            }}
                        >
                            {getMessage('text_product_groups')}
                            <GroupIcon />
                        </BatchActionsButton>
                    )}
                    {!moduleRackbeat && (
                        <>
                            <BatchActionsButton
                                variant="secondary"
                                data-testid="product-overview-batch-change-price-button"
                                onClick={() => {
                                    setShowPriceModal(true);
                                }}
                            >
                                {getMessage('text_prospect_close_lost_price')}
                                <PriceTagIcon />
                            </BatchActionsButton>
                            <BatchActionsButton
                                variant="secondary"
                                data-testid="product-overview-change-product-details"
                                onClick={() => {
                                    setShowEditProductModal(true);
                                }}
                            >
                                {getMessage('text_edit')}
                                <EditIcon />
                            </BatchActionsButton>
                        </>
                    )}
                    {/* Temporarily hide this feature to accommodate potential future requests. We can remove the code after some time if no issues are reported.*/}

                    {/*{moduleRackbeat && (*/}
                    {/*    <BatchActionsButton*/}
                    {/*        variant="secondary"*/}
                    {/*        data-testid="product-overview-change-product-details-rackbeat"*/}
                    {/*        onClick={() => {*/}
                    {/*            setShowEditProductRackbeatModal(true);*/}
                    {/*        }}*/}
                    {/*    >*/}
                    {/*        {getMessage('text_edit')}*/}
                    {/*        <EditIcon />*/}
                    {/*    </BatchActionsButton>*/}
                    {/*)}*/}
                </BatchActionsButtons>
                <Modal
                    open={showPriceModal}
                    onClose={() => {
                        setShowPriceModal(false);
                        setShowPriceWarning(false);
                        setIsDisable(true);
                    }}
                    className="goldshark-change-price-modal"
                >
                    <ModalCloseButton />
                    <ModalContent>
                        <ChangePriceForm
                            onChange={handleOnChange}
                            onSubmit={handleOnSubmit}
                        >
                            <PriceIconSVG />
                            <ChangePriceTitleSection />
                            <ChangePriceContentSection
                                isSupplierProduct={isSupplierProduct}
                            >
                                {(contentValue: string) => (
                                    <>
                                        {contentValue === 'purchase' && (
                                            <ChangePricePurchasePriceSection
                                                showPriceChangeWarning={
                                                    showPriceWarning
                                                }
                                                isSupplierProduct={
                                                    isSupplierProduct
                                                }
                                            />
                                        )}
                                        {contentValue === 'sales' && (
                                            <ChangePriceSellingPriceSection
                                                showPriceChangeWarning={
                                                    showPriceWarning
                                                }
                                            />
                                        )}
                                    </>
                                )}
                            </ChangePriceContentSection>
                            {showPriceWarning && (
                                <ChangePriceWarningSection
                                    affectedProducts={affectedProducts}
                                    editPrice={editPriceChange}
                                />
                            )}
                        </ChangePriceForm>
                    </ModalContent>
                    <ModalFooter>
                        <ChangePriceModalActions
                            isDisable={isDisable}
                            showPriceWarning={showPriceWarning}
                            onBack={() => {
                                setShowPriceWarning(false);
                            }}
                            onCancel={() => {
                                setShowPriceModal(false);
                                setShowPriceWarning(false);
                                setIsDisable(true);
                            }}
                            onNext={async () => {
                                const filtered = await products.onNext(
                                    editPriceChange.type,
                                    isPurchasePrice
                                );
                                setAffectedProducts(filtered);
                                setShowPriceWarning(true);
                            }}
                        />
                    </ModalFooter>
                </Modal>
                {showDeleteWarning && (
                    <LogisticsModal
                        svgIcon={'delete'}
                        title={getMessage(
                            'text_product_bulk_delete_title_plural'
                        )}
                        onConfirm={() => {
                            setShowDeleteWarning(false);
                            bulkActions.onBulkInactivate();
                            bulkActions.onBulkDelete();
                        }}
                        onCancel={() => setShowDeleteWarning(false)}
                    >
                        <Alert variant="warning" className="atl-w-full">
                            <AlertContent>
                                <AlertHeader as={'h3'}>
                                    {getMessage(
                                        'text_purchase_order_change_status_warning_title'
                                    )}
                                </AlertHeader>
                                <AlertDescription>
                                    {getMessage(
                                        'text_product_overview_delete_warning',
                                        products.selectedIds.length -
                                            products.notDeletableIds.length,
                                        products.notDeletableIds.length
                                    )}
                                </AlertDescription>
                            </AlertContent>
                        </Alert>
                    </LogisticsModal>
                )}
                <Modal
                    open={showProductGroupsModal}
                    onClose={() => {
                        bulkActions.onResetSelectedNodes([]);
                        setShowProductGroupsModal(false);
                    }}
                    className={'gs-min-w-600'}
                >
                    <ModalContent>
                        {bulkActions.nodes?.length > 0 && <ProductGroupsIcon />}
                        <ModalTitle className={'atl-my-16'}>
                            {getMessage('text_change_product_groups')}
                        </ModalTitle>
                        <ModalCloseButton />
                        {bulkActions.loadingBulkGroups ? (
                            <div
                                className={
                                    'atl-flex atl-items-center atl-mt-32'
                                }
                            >
                                <Spinner />
                                <div className="atl-text-sm atl-text-grey-100 atl-ml-8">
                                    {getMessage('text_fetching_data')}
                                </div>
                            </div>
                        ) : (
                            <ChangeProductGroupsContent
                                action={editProductGroups}
                                onChangeAction={setEditProductGroups}
                            >
                                <MultipleSelectionProductGroups
                                    nodes={bulkActions.nodes}
                                    selectedNodes={bulkActions.selectedNodes}
                                    onChange={bulkActions.onChangeSelectedNodes}
                                    className={'atl-border atl-border-grey-20'}
                                />
                                {bulkActions.selectedNodes?.length !== 0 && (
                                    <ChangeProductGroupsWarning
                                        totalLines={products.selectedIds.length}
                                    />
                                )}
                            </ChangeProductGroupsContent>
                        )}
                        {bulkActions.nodes?.length === 0 && (
                            <ChangeProductGroupsEmptyState />
                        )}
                    </ModalContent>
                    <ModalFooter>
                        <ChangeProductGroupsActions
                            disabled={
                                bulkActions.selectedNodes?.length === 0 ||
                                bulkActions.loadingBulkGroups
                            }
                            onClose={() => {
                                bulkActions.onResetSelectedNodes([]);
                                setShowProductGroupsModal(false);
                            }}
                            onConfirm={() => {
                                editProductGroups === 'add'
                                    ? bulkActions.onAddProductGroups()
                                    : bulkActions.onRemoveProductGroups();
                                setEditProductGroups('add');
                            }}
                        />
                    </ModalFooter>
                </Modal>
                {showEditProductModal && (
                    <ChangeProductDetailsModal
                        suppliers={products.filters.supplierFilter}
                        totalLines={products.selectedIds.length}
                        onChangeProductDetails={
                            bulkActions.onChangeProductDetails
                        }
                        onClose={() => {
                            setShowEditProductModal(false);
                        }}
                    />
                )}
                {showEditProductRackbeatModal && (
                    <ChangeProductDetailsRackbeatModal
                        lines={products.selectedIds.length}
                        onClose={() => setShowEditProductRackbeatModal(false)}
                        onSubmit={bulkActions.onChangeProductDetailsRackbeat}
                    />
                )}
            </BatchActions>
        </TableLayoutTopbar>
    );
}
