import classNames from 'classnames';
import React from 'react';
import NumberFormatOptions = Intl.NumberFormatOptions;

type TextCellProp = {
    value: string | undefined;
    className?: string;
};

export function TextCell({ value, className }: TextCellProp) {
    return <td className={classNames('atl-td', className)}>{value}</td>;
}

type NumberCellProp = {
    value: number | undefined;
    className?: string;
};

export function NumberCell({ value, className }: NumberCellProp) {
    return (
        <td className={classNames('atl-td atl-text-right', className)}>
            {format2(value)}
        </td>
    );
}

type DateCellProp = {
    value: Date | undefined;
    className?: string;
};

export function DateCell({ value, className }: DateCellProp) {
    return (
        <td className={classNames('atl-td atl-text-right', className)}>
            {formatDate(value)}
        </td>
    );
}

export function format2(value: number | undefined): string {
    return formatNumber(value, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
}

function formatNumber(value: number | undefined, options: NumberFormatOptions) {
    if (value !== undefined && isFinite(value)) {
        return Intl.NumberFormat(getLocale(), options).format(value);
    }
    return '';
}

export function formatDate(date: Date | undefined): string {
    if (date !== undefined) {
        return new Intl.DateTimeFormat(getLocale(), {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        }).format(date);
    }

    return '';
}

// User locale is save in format 'no_NO' with underscore, Intl want in format 'no-nb' with hypen. Fallback to be used in storeybook.
function getLocale() {
    const locale = window.locale ?? 'no-nb';
    return locale.replaceAll('_', '-').toLocaleLowerCase();
}
