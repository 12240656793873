import { StockChangeDTO } from '@Page/LogisticsV2/pages/InventoryCorrection/overview/types';
import _ from 'lodash';

export function getFromWarehousesDisplayNames(stockChanges: StockChangeDTO[]) {
    const fromWarehouses = stockChanges.filter(
        (stockChange) =>
            stockChange.stockChangeWarehouseLocationRelation?.fromWarehouse
    );
    return _.uniqBy(
        fromWarehouses,
        (item) =>
            item.stockChangeWarehouseLocationRelation?.fromWarehouse
                ?.displayName
    ).map(
        (item) =>
            item.stockChangeWarehouseLocationRelation?.fromWarehouse.displayName
    );
}

export function getToWarehousesDisplayNames(stockChanges: StockChangeDTO[]) {
    const toWarehouses = stockChanges.filter(
        (stockChange) =>
            stockChange.stockChangeWarehouseLocationRelation?.toWarehouse
    );
    return _.uniqBy(
        toWarehouses,
        (item) =>
            item.stockChangeWarehouseLocationRelation?.toWarehouse?.displayName
    ).map(
        (item) =>
            item.stockChangeWarehouseLocationRelation?.toWarehouse.displayName
    );
}

export function getColumnLabel(column: string) {
    switch (column) {
        case 'fromWarehouse':
            return getMessage('text_warehouse_from');
        case 'toWarehouse':
            return getMessage('text_warehouse_to');
        case 'date':
            return getMessage('text_date');
        case 'comment':
            return getMessage('text_comment');
        default:
            throw new Error(`Unknown column: ${column}`);
    }
}

export const getAvailableColumnsInInventoryCorrection = () => {
    return [
        'date',
        ...(moduleWarehouse ? ['fromWarehouse'] : []),
        'toWarehouse',
        'comment',
    ];
};
export const getDefaultColumnsInInventoryCorrection = () => {
    return [
        'date',
        ...(moduleWarehouse ? ['fromWarehouse'] : []),
        'toWarehouse',
        'comment',
    ];
};

export function getInventoryCorrectionsDisplayOptionsName() {
    let name = `columns-inventory-correction-overview-${window.loginEmployeeId}`;
    if (moduleWarehouse) {
        name = name + '-warehouse';
    }
    return name;
}
