import jQuery from 'jquery';
import { addContextId, tlxUrl } from '../../js/modules/url.ts';
import { app } from '../../js/modules/framework.js';
import { tlxForms } from '../../js/modules/forms';
import { bindEventsInsideScope, defaultAjaxAction } from '../../js/o-common';
import { marshallSpec } from '../../js/legacy/jsonrpc.js';

const $ = jQuery;

(function () {
    function clearTable(self) {
        return (
            '<tbody>' +
            '<tr class="cavesRow ">' +
            '<input type="hidden" name="id" value="0"/>' +
            '<input type="hidden" name="name" value="' +
            self.options.text_caves_no_selection +
            '"/>' +
            '<input type="hidden" name="account" value=""/>' +
            '<input type="hidden" name="type" value="1"/>' +
            '<input type="hidden" name="currentAccountBookNumber" value=""/>' +
            '<input type="hidden" name="currentAccountBookAccountId" value=""/>' +
            '<td class="cavesTabelColumn" colspan="3">' +
            self.options.text_caves_no_selection +
            '</td></tr></tbody>'
        );
    }

    var searchCounter = 0; // help keep track on the order of callbacks (a "fastsearch" may return before an older search).
    var lastSearchNumberCallback = 0;

    /**
     *
     * Dose a database search and populates CAVES with the results
     *
     * @author Harry
     * @date Sep 7, 2015
     *
     */
    function search(self, searchString, type, limitFrom, searchLength, event) {
        if (!self.options.doSearch) return;
        var $table = self.$dialog.find('.cavesScroll').find('.cavesTable');
        var excludeId; //excludes the already selected from search

        var accountId = self.element.find('.js-caves-accountId').val()
            ? parseInt(self.element.find('.js-caves-accountId').val())
            : 0;
        var customerId = self.element.find('.js-caves-customerId').val()
            ? parseInt(self.element.find('.js-caves-customerId').val())
            : 0;
        var vendorId = self.element.find('.js-caves-vendorId').val()
            ? parseInt(self.element.find('.js-caves-vendorId').val())
            : 0;
        var employeeId = self.element.find('.js-caves-employeeId').val()
            ? parseInt(self.element.find('.js-caves-employeeId').val())
            : 0;
        var assetId = self.element.find('.js-caves-guiAssetId').val()
            ? parseInt(self.element.find('.js-caves-guiAssetId').val())
            : 0;

        type = parseInt(type, 10);

        if (1 === type) {
            excludeId = accountId;
        } else if (2 === type) {
            excludeId = customerId;
        } else if (3 === type) {
            excludeId = vendorId;
        } else if (4 === type) {
            excludeId = employeeId;
        } else if (5 === type) {
            excludeId = assetId;
        } else {
            excludeId =
                employeeId > 0 && accountId === self.options.employee_account.id
                    ? employeeId
                    : vendorId > 0 &&
                      accountId === self.options.vendor_account.id
                    ? vendorId
                    : customerId > 0 &&
                      accountId === self.options.customer_account.id
                    ? customerId
                    : accountId > 0
                    ? accountId
                    : 0;
            if (isCurrentAccountBook(self, accountId)) {
                excludeId = accountId;
            }
        }

        var doFastSearch =
            typeof event !== 'undefined' &&
            event !== null &&
            event.type === 'keydown' &&
            event.which === 13; // enter pressed -> do fast search
        var searchNumber = ++searchCounter;

        jsonrpc.Caves.search(
            function (objects) {
                if (searchNumber > lastSearchNumberCallback) {
                    lastSearchNumberCallback = searchNumber;
                } else {
                    return; // ignore, since we have already gotten an newer search result
                }

                if (objects !== null && objects.length > 0) {
                    var typeString = '';
                    var temp = '';
                    for (let i = 0; i < objects.length; i++) {
                        if (
                            self.$dialog.find('.caves-searchField').val() ===
                                objects[i].accountNumber &&
                            objects[i].type === 1 &&
                            event &&
                            event.type === 'keydown' &&
                            event.which === 13
                        ) {
                            selectAccount2(
                                self,
                                objects[i].id,
                                objects[i].type,
                                objects[i].accountNumber +
                                    ' ' +
                                    objects[i].name,
                                null,
                                objects[i].currentAccountBookNumber,
                                objects[i].currentAccountBookAccountId
                            );
                            focusNextElement(self);
                        }

                        if (1 === objects[i].type) {
                            typeString = self.options.account_text;
                            temp =
                                parseInt(
                                    self.element
                                        .find('.js-caves-accountId')
                                        .val(),
                                    10
                                ) === objects[i].id
                                    ? 'cavesRowSelected'
                                    : '';
                        } else if (2 === objects[i].type) {
                            typeString = self.options.customer_text;
                            temp =
                                parseInt(
                                    self.element
                                        .find('.js-caves-customerId')
                                        .val(),
                                    10
                                ) === objects[i].id
                                    ? 'cavesRowSelected'
                                    : '';
                        } else if (3 === objects[i].type) {
                            typeString = self.options.vendor_text;
                            temp =
                                parseInt(
                                    self.element
                                        .find('.js-caves-vendorId')
                                        .val(),
                                    10
                                ) === objects[i].id
                                    ? 'cavesRowSelected'
                                    : '';
                        } else if (4 === objects[i].type) {
                            typeString = self.options.employee_contact_text;
                            temp =
                                parseInt(
                                    self.element
                                        .find('.js-caves-employeeId')
                                        .val(),
                                    10
                                ) === objects[i].id
                                    ? 'cavesRowSelected'
                                    : '';
                        } else if (5 === objects[i].type) {
                            typeString = self.options.asset_text;
                            temp =
                                parseInt(
                                    self.element
                                        .find('.js-caves-guiAssetId')
                                        .val(),
                                    10
                                ) === objects[i].id
                                    ? 'cavesRowSelected'
                                    : '';
                        }

                        var $row = $('<tr class="cavesRow ' + temp + '"></tr>');

                        var hidden1 = $('<input type="hidden" name="id"/>').val(
                            objects[i].id
                        );
                        var hidden2 = $(
                            '<input type="hidden" name="name" />'
                        ).val(objects[i].name);
                        var hidden3 = $(
                            '<input type="hidden" name="account" />'
                        ).val(objects[i].accountNumber);
                        var hidden4 = $(
                            '<input type="hidden" name="currentAccountBookNumber" />'
                        ).val(objects[i].currentAccountBookNumber);
                        var hidden5 = $(
                            '<input type="hidden" name="currentAccountBookAccountId" />'
                        ).val(objects[i].currentAccountBookAccountId);
                        var hidden6 = $(
                            '<input type="hidden" name="type" />'
                        ).val(objects[i].type);

                        var $type = $(
                            '<td class="cavesTabelColumn"></td>'
                        ).text(typeString);
                        var $num = $('<td class="cavesTabelColumn"></td>').text(
                            objects[i].accountNumber
                        );
                        var $name = $('<td></td>').text(objects[i].name);

                        $row.append(
                            hidden1,
                            hidden2,
                            hidden3,
                            hidden4,
                            hidden5,
                            hidden6,
                            $type,
                            $num,
                            $name
                        );
                        $table.append($row);
                    }
                    if (objects.length === 1) {
                        self.$dialog
                            .find('.cavesRow')
                            .removeClass('cavesRowSelected');
                        $(self.$dialog.find('.cavesRow'))
                            .filter(function () {
                                return (
                                    parseInt(
                                        $('input[name="id"]', this).val()
                                    ) === objects[0].id
                                );
                            })
                            .addClass('cavesRowSelected');
                    } else if (self.$dialog.find('.cavesRow').length === 1) {
                        $(self.$dialog.find('.cavesRow')[0]).addClass(
                            'cavesRowSelected'
                        );
                    }
                } else {
                    self.options.doSearch = false;
                }
                if (searchString.length > 0) {
                    for (
                        let i = 1;
                        i < self.$dialog.find('.cavesRow').find('td').length;
                        i++
                    ) {
                        var cell = self.$dialog.find('.cavesRow').find('td')[i];
                        if (
                            cell.innerHTML === self.options.account_text ||
                            cell.innerHTML === self.options.customer_text ||
                            cell.innerHTML === self.options.vendor_text ||
                            cell.innerHTML ===
                                self.options.employee_contact_text
                        ) {
                            continue;
                        }
                        cell.innerHTML = cell.innerHTML.replace(
                            '<span class="searchMatch">',
                            ''
                        );
                        cell.innerHTML = cell.innerHTML.replace('</span>', '');
                        var text = cell.innerHTML.toLowerCase();
                        searchString = searchString.toLowerCase();
                        var statr = text.indexOf(searchString);
                        text = cell.innerHTML;
                        if (statr !== -1) {
                            $(cell).empty();
                            var first = text.substr(0, statr);
                            var match = $(
                                '<span class="searchMatch"></span>'
                            ).text(text.substr(statr, searchString.length));
                            var last = text.substr(statr + searchString.length);
                            $(cell).append(first, match, last);
                        }
                    }
                }
            },
            window.marshallSpec(
                'id',
                'name',
                'accountNumber',
                'type',
                'currentAccountBookNumber',
                'currentAccountBookAccountId'
            ),
            searchString,
            type,
            excludeId,
            limitFrom,
            searchLength,
            doFastSearch
        );

        self.options.limitFrom =
            self.options.limitFrom + self.options.searchLength;
    }

    /**
     *
     * Adds the selected to list to always show it
     *
     * @author Harry
     * @date Oct 5, 2015
     *
     */
    function addSelected(self, event) {
        var accountId = self.element.find('.js-caves-accountId').val()
            ? self.element.find('.js-caves-accountId').val()
            : 0;
        var customerId = self.element.find('.js-caves-customerId').val()
            ? self.element.find('.js-caves-customerId').val()
            : 0;
        var vendorId = self.element.find('.js-caves-vendorId').val()
            ? self.element.find('.js-caves-vendorId').val()
            : 0;
        var employeeId = self.element.find('.js-caves-employeeId').val()
            ? self.element.find('.js-caves-employeeId').val()
            : 0;
        var assetId = self.element.find('.js-caves-guiAssetId').val()
            ? self.element.find('.js-caves-guiAssetId').val()
            : 0;

        jsonrpc.Caves.selectedCaves(
            function (objects) {
                if (objects !== null && objects.id > 0) {
                    var typeString = '';
                    var temp = '';
                    var $table = self.$dialog
                        .find('.cavesScroll')
                        .find('.cavesTable');
                    if (1 === objects.type) {
                        typeString = self.options.account_text;
                        temp =
                            parseInt(
                                self.element.find('.js-caves-accountId').val(),
                                10
                            ) === objects.id
                                ? 'cavesRowSelected'
                                : '';
                    } else if (2 === objects.type) {
                        typeString = self.options.customer_text;
                        temp =
                            parseInt(
                                self.element.find('.js-caves-customerId').val(),
                                10
                            ) === objects.id
                                ? 'cavesRowSelected'
                                : '';
                    } else if (3 === objects.type) {
                        typeString = self.options.vendor_text;
                        temp =
                            parseInt(
                                self.element.find('.js-caves-vendorId').val(),
                                10
                            ) === objects.id
                                ? 'cavesRowSelected'
                                : '';
                    } else if (4 === objects.type) {
                        typeString = self.options.employee_contact_text;
                        temp =
                            parseInt(
                                self.element.find('.js-caves-employeeId').val(),
                                10
                            ) === objects.id
                                ? 'cavesRowSelected'
                                : '';
                    } else if (5 === objects.type) {
                        typeString = self.options.asset_text;
                        temp =
                            parseInt(
                                self.element.find('.js-caves-guiAssetId').val(),
                                10
                            ) === objects.id
                                ? 'cavesRowSelected'
                                : '';
                    }

                    var $row = $('<tr class="cavesRow ' + temp + '"></tr>');

                    var hidden1 = $('<input type="hidden" name="id"/>').val(
                        objects.id
                    );
                    var hidden2 = $('<input type="hidden" name="name" />').val(
                        objects.name
                    );
                    var hidden3 = $(
                        '<input type="hidden" name="account" />'
                    ).val(objects.accountNumber);
                    var hidden4 = $('<input type="hidden" name="type" />').val(
                        objects.type
                    );

                    var $type = $('<td class="cavesTabelColumn"></td>').text(
                        typeString
                    );
                    var $num = $('<td class="cavesTabelColumn"></td>').text(
                        objects.accountNumber
                    );
                    var $name = $('<td></td>').text(objects.name);

                    $row.append(
                        hidden1,
                        hidden2,
                        hidden3,
                        hidden4,
                        $type,
                        $num,
                        $name
                    );
                    $table.append($row);

                    if (
                        event &&
                        event.type === 'keydown' &&
                        event.which === 13 &&
                        $($table.find('tr')[0]).find(':prop(account)').val() ===
                            self.$dialog.find('.caves-searchField').val() &&
                        $($table.find('tr')[0]).find(':prop(type)').val() ===
                            '1' &&
                        parseInt(
                            self.element
                                .find(
                                    ':prop(postingRows[].creditPosting.accountId)'
                                )
                                .val()
                        ) > 0
                    ) {
                        self.$dialog.dialog('close');
                    }
                }
            },
            window.marshallSpec(
                'id',
                'name',
                'accountNumber',
                'type',
                'currentAccountBookNumber',
                'currentAccountBookAccountId'
            ),
            accountId,
            customerId,
            vendorId,
            employeeId,
            assetId
        );
    }

    /**
     *
     * Uses row list row to select the property
     *
     * @author Harry
     * @date Sep 7, 2015
     *
     * @param self - the dialog
     * @param row - caves target row
     *
     */
    function selectAccount(self, row) {
        selectAccount2(
            self,
            $(row).find('input[name="id"]').val(),
            $(row).find('input[name="type"]').val(),
            $(row).find('input[name="account"]').val() +
                ' ' +
                $(row).find('input[name="name"]').val(),
            row,
            $(row).find('input[name="currentAccountBookNumber"]').val(),
            $(row).find('input[name="currentAccountBookAccountId"]').val()
        );
    }

    /**
     * Is the accountId a current account book account, e.g. customer ledger, supplier ledger, employee ledger or asset ledger?
     *
     * @param self - the dialog
     * @param accountId
     * @returns {boolean}
     */
    function isCurrentAccountBook(self, accountId) {
        for (var i = 0; i < self.options.customer_accounts.length; i++) {
            if (self.options.customer_accounts[i].id === accountId) return true;
        }
        for (var j = 0; j < self.options.vendor_accounts.length; j++) {
            if (self.options.vendor_accounts[j].id === accountId) return true;
        }
        for (var x = 0; x < self.options.employee_accounts.length; x++) {
            if (self.options.employee_accounts[x].id === accountId) return true;
        }
        for (var y = 0; y < self.options.asset_accounts.length; y++) {
            if (self.options.asset_accounts[y].id === accountId) return true;
        }

        return false;
    }

    /**
     * Determines the search type from account number.
     *
     * @param self - the dialog
     * @param accountNumber
     * @returns {string|null}
     */
    function getSearchTypeFromAccountNumber(self, accountNumber) {
        for (var i = 0; i < self.options.customer_accounts.length; i++) {
            if (self.options.customer_accounts[i].number === accountNumber)
                return 'customer';
        }
        for (var j = 0; j < self.options.vendor_accounts.length; j++) {
            if (self.options.vendor_accounts[j].number === accountNumber)
                return 'vendor';
        }
        for (var x = 0; x < self.options.employee_accounts.length; x++) {
            if (self.options.employee_accounts[x].number === accountNumber)
                return 'employee';
        }
        for (var y = 0; y < self.options.asset_accounts.length; y++) {
            if (self.options.asset_accounts[y].number === accountNumber)
                return 'asset';
        }
        return null;
    }

    /**
     * Takes the selected from list and adds it to the property
     *
     * @author Harry
     * @date Sep 7, 2015
     *
     * @param self
     * @param id
     * @param type
     * @param name and number
     * @param row
     * @param ledgerAccountNumber
     * @param ledgerAccountId
     *
     */
    function selectAccount2(
        self,
        id,
        type,
        name,
        row,
        ledgerAccountNumber,
        ledgerAccountId
    ) {
        self.$dialog.find('.cavesRowSelected').removeClass('cavesRowSelected');

        self.element
            .find(
                '.js-caves-accountId, .js-caves-customerId, .js-caves-vendorId, .js-caves-employeeId, .js-caves-guiAssetId'
            )
            .val(0);
        type = parseInt(type);
        var accountNumber = '';
        var tooltipHint = '';

        if (1 === type) {
            accountNumber = '\u00A0';
            self.element.find('.js-caves-accountId').val(id);
            tooltipHint = '';
            if (!self.element.find('.cavesBlackLine').is('.hide')) {
                self.element.find('.cavesBlackLine').addClass('hide');
                self.element.find('.cavesAccountPresentation').addClass('hide');
            }
            tooltipHint = self.options.text_account_short + ': ' + name;
        } else if (2 === type) {
            self.element.find('.js-caves-customerId').val(id);
            self.element.find('.js-caves-accountId').val(ledgerAccountId);
            self.element.find('.cavesBlackLine').removeClass('hide');
            self.element.find('.cavesAccountPresentation').removeClass('hide');
            accountNumber = ledgerAccountNumber;
            tooltipHint =
                self.options.text_account_short +
                ': ' +
                self.options.customer_account.presentation +
                ' ' +
                self.options.heading_customer +
                ': ' +
                name;
        } else if (3 === type) {
            self.element.find('.js-caves-vendorId').val(id);
            self.element.find('.js-caves-accountId').val(ledgerAccountId);
            self.element.find('.cavesBlackLine').removeClass('hide');
            self.element.find('.cavesAccountPresentation').removeClass('hide');
            accountNumber = ledgerAccountNumber;
            tooltipHint =
                self.options.text_account_short +
                ': ' +
                self.options.vendor_account.presentation +
                ' ' +
                self.options.heading_vendor +
                ': ' +
                name;
        } else if (4 === type) {
            self.element.find('.js-caves-employeeId').val(id);
            self.element
                .find('.js-caves-accountId')
                .val(self.options.employee_account.id);
            self.element.find('.cavesBlackLine').removeClass('hide');
            self.element.find('.cavesAccountPresentation').removeClass('hide');
            accountNumber = self.options.employee_account.number;
            tooltipHint =
                self.options.text_account_short +
                ': ' +
                self.options.employee_account.presentation +
                ' ' +
                self.options.option_employees +
                ': ' +
                name;
        } else if (5 === type) {
            self.element.find('.js-caves-guiAssetId').val(id);
            self.element.find('.js-caves-accountId').val(ledgerAccountId);
            self.element.find('.cavesBlackLine').removeClass('hide');
            self.element.find('.cavesAccountPresentation').removeClass('hide');
            accountNumber = ledgerAccountNumber;
            tooltipHint =
                self.options.text_account_short +
                ': ' +
                ledgerAccountNumber +
                ' ' +
                self.options.asset_text +
                ' : ' +
                name;
        }

        self.element.find('.title').text(name);
        self.element.find('.cavesAccountPresentation').text(accountNumber);
        self.element.data('custom-tooltip', tooltipHint);

        if ($(row).length > 0) {
            $(row).addClass('cavesRowSelected');
        }
        self.element.change();

        // Dispatch a regular change event
        self.element
            .find('.js-caves-accountId')
            .get(0)
            .dispatchEvent(new Event('change', { bubbles: true }));

        self.$dialog.dialog('close');
    }

    /**
     *
     * Create (tlx)PopupOpener for new C.A.V.E
     *
     * @author Harry
     * @date Sep 7, 2015
     *
     * @param self the widget
     * @param selector the element
     * @param url
     * @param cavesType
     *
     */
    function createPopupOpener(self, selector, url, cavesType) {
        self.$dialog.find(selector).popupOpener({
            url: function () {
                return tlxUrl.addUrlParameters(
                    addContextId(url),
                    'dialog',
                    'true'
                );
            },
            load: function (event, $ui) {
                var $dialog = $ui;
                var $openerElement = $(event.target);

                bindEventsInsideScope($dialog);

                function doneLoadingScript() {
                    tlxForms.focusFirstText($ui);
                    $dialog.bind('dialogclose', function () {
                        $openerElement.focus();
                        $('.caves-button.ui-state-active').removeClass(
                            'ui-state-active'
                        );
                    });
                    var $button = $dialog.find('.toolbarAction');
                    if ($button.length !== 1) {
                        throw (
                            'Invalid number of buttons. Currently 1 is supported. Found ' +
                            $button.length
                        );
                    }
                    var clickFunc = function () {
                        defaultAjaxAction(
                            $dialog.find('form'),
                            $button.data('action'),
                            $dialog,
                            {
                                callback: function (id) {
                                    self.$dialog
                                        .find('.caves-button.ui-state-active')
                                        .removeClass('ui-state-active');
                                    if (1 === cavesType) {
                                        jsonrpc.Account.get(
                                            function (objects) {
                                                var name =
                                                    objects.number +
                                                    ' ' +
                                                    objects.name;
                                                selectAccount2(
                                                    self,
                                                    id,
                                                    cavesType,
                                                    name,
                                                    null,
                                                    null,
                                                    0
                                                );
                                            },
                                            window.marshallSpec(
                                                'name',
                                                'number'
                                            ),
                                            id
                                        );
                                    }
                                    if (2 === cavesType || 3 === cavesType) {
                                        jsonrpc.Company.get(
                                            function (objects) {
                                                var name =
                                                    objects.customerAccountNumber
                                                        ? objects.customerAccountNumber
                                                        : objects.vendorAccountNumber;
                                                name += ' ' + objects.name;
                                                selectAccount2(
                                                    self,
                                                    id,
                                                    cavesType,
                                                    name,
                                                    null,
                                                    objects.currentAccountBookNumber,
                                                    objects.currentAccountBookAccountId
                                                );
                                            },
                                            marshallSpec(
                                                'name',
                                                'customerAccountNumber',
                                                'vendorAccountNumber',
                                                'currentAccountBookAccountId',
                                                'currentAccountBookNumber'
                                            ),
                                            id
                                        );
                                    } else if (4 === cavesType) {
                                        jsonrpc.Employee.get(
                                            function (objects) {
                                                var name =
                                                    objects.number +
                                                    ' ' +
                                                    objects.name;
                                                selectAccount2(
                                                    self,
                                                    id,
                                                    cavesType,
                                                    name,
                                                    null,
                                                    null,
                                                    0
                                                );
                                            },
                                            marshallSpec('name', 'number'),
                                            id
                                        );
                                    } else if (5 === cavesType) {
                                        jsonrpc.Asset.get(
                                            function (objects) {
                                                selectAccount2(
                                                    self,
                                                    id,
                                                    cavesType,
                                                    objects.name,
                                                    null,
                                                    objects.accountNumber,
                                                    objects.accountId
                                                );
                                            },
                                            marshallSpec(
                                                'name',
                                                'accountId',
                                                'accountNumber'
                                            ),
                                            id
                                        );
                                    }

                                    self.$dialog.dialog('close');
                                },
                                infoMessage: $button.data(
                                    'toolbar-button-text'
                                ),
                                disableForward: true,
                            }
                        );
                    };
                    var newOptions = {
                        buttonText: $button.data('dialogButtonText'),
                        closeText: $button.data('closeText'),
                        click: clickFunc,
                        title: $ui.get(0).dataset['tlxTitle'],
                    };
                    $openerElement.popupOpener('option', newOptions);
                }

                app.loadPageScript($ui, doneLoadingScript);
            },
            storeAction: true,
        });
    }

    /**
     *
     * caves On key down handler
     *
     * @author Harry
     * @date Sep 7, 2015
     *
     * @param event
     * @param self the widget
     *
     */
    function cavesOnkeydown(event, self) {
        var keyCode = event.which;
        var $div = self.$dialog.find('.cavesScroll');
        var $selected = self.$dialog.find('.cavesRowSelected');
        if (keyCode === 40) {
            //ArrowDown
            if ($selected.length === 0) {
                $(self.$dialog.find('.cavesScroll').find('tr')[0]).addClass(
                    'cavesRowSelected'
                );
            } else {
                var $next = $selected.next();
                if ($next.length > 0) {
                    $selected.removeClass('cavesRowSelected');
                    $selected = $next;
                    $selected.addClass('cavesRowSelected');
                    $div.scrollTop(
                        $selected.offset().top -
                            self.$dialog
                                .find('.cavesScroll')
                                .find('.cavesTable')
                                .offset().top
                    );
                }
            }
        } else if (keyCode === 38) {
            //ArrowUp
            if ($selected.length === 0) {
                $(self.$dialog.find('.cavesScroll').find('tr')[0]).addClass(
                    'cavesRowSelected'
                );
            } else {
                var $prev = $selected.prev();
                if ($prev.length > 0) {
                    $selected.removeClass('cavesRowSelected');
                    $selected = $prev;
                    $selected.addClass('cavesRowSelected');
                    $div.scrollTop(
                        $selected.offset().top -
                            self.$dialog
                                .find('.cavesScroll')
                                .find('.cavesTable')
                                .offset().top
                    );
                } else {
                    $div.scrollTop(0);
                }
            }
        } else if (keyCode === 13) {
            //Enter
            var searchValue = parseInt(
                self.$dialog.find('.caves-searchField').val()
            );
            var cavesTypeChange = false;
            if (
                parseInt(self.$dialog.find('.cavesTypeSelect').val()) === 0 ||
                parseInt(self.$dialog.find('.cavesTypeSelect').val()) === 1
            ) {
                if (
                    getSearchTypeFromAccountNumber(self, searchValue) ===
                    'customer'
                ) {
                    self.$dialog.find('.cavesTypeSelect').val(2);
                    self.$dialog.find('.caves-searchField').val('');

                    self.$dialog
                        .find('.cavesSearchTypeDropDown__text')
                        .text(self.options.text_search_in_customers);
                    cavesTypeChange = true;
                } else if (
                    getSearchTypeFromAccountNumber(self, searchValue) ===
                    'vendor'
                ) {
                    self.$dialog.find('.cavesTypeSelect').val(3);
                    self.$dialog.find('.caves-searchField').val('');

                    self.$dialog
                        .find('.cavesSearchTypeDropDown__text')
                        .text(self.options.text_search_in_vendors);
                    cavesTypeChange = true;
                } else if (
                    getSearchTypeFromAccountNumber(self, searchValue) ===
                    'employee'
                ) {
                    self.$dialog.find('.cavesTypeSelect').val(4);
                    self.$dialog.find('.caves-searchField').val('');

                    self.$dialog
                        .find('.cavesSearchTypeDropDown__text')
                        .text(self.options.text_search_in_employee);
                    cavesTypeChange = true;
                } else if (
                    getSearchTypeFromAccountNumber(self, searchValue) ===
                    'asset'
                ) {
                    self.$dialog.find('.cavesTypeSelect').val(5);
                    self.$dialog.find('.caves-searchField').val('');

                    self.$dialog
                        .find('.cavesSearchTypeDropDown__text')
                        .text(self.options.text_search_in_assets);
                    cavesTypeChange = true;
                }
            }

            if (!cavesTypeChange && $selected.length > 0) {
                selectAccount(self, $selected);
                focusNextElement(self);
            } else {
                var $table = self.$dialog
                    .find('.cavesScroll')
                    .find('.cavesTable');
                $table.html(clearTable(self));
                self.options.limitFrom = 0;
                self.options.doSearch = true;
                if (!cavesTypeChange) addSelected(self, event);

                search(
                    self,
                    self.$dialog.find('.caves-searchField').val(),
                    self.$dialog.find('.cavesTypeSelect').val(),
                    self.options.limitFrom,
                    self.options.searchLength,
                    event
                );
            }
        } else {
            $selected.removeClass('cavesRowSelected');
        }
        return true;
    }

    /**
     * Move focus to the next element.
     *
     * @author Are
     * @date Nov 15, 2015
     */
    function focusNextElement(self) {
        var $elements = $('.js-enter-next:visible:not([disabled])');
        var $next;
        for (var i = 0; i < $elements.length - 1; i++) {
            if ($elements[i] === self.element[0]) {
                $next = $($elements[i + 1]);
                break;
            }
        }
        if ($next) {
            $next.focus();
            if ($next.is('input:text')) $next.select();
        }
    }

    /**
     *
     * caves On up down handler (not arrow up/down or enter)
     *
     * @author Harry
     * @date Sep 7, 2015
     *
     * @param event
     * @param self the widget
     *
     */
    function cavesInput(event, self) {
        var searchValue = self.$dialog.find('.caves-searchField').val();
        if (!self.$dialog.is(':visible')) return;
        if (
            searchValue.length >= 2 ||
            searchValue.length === 0 ||
            self.options.previousSearch.length > searchValue.length
        ) {
            if (
                getSearchTypeFromAccountNumber(self, parseInt(searchValue)) ===
                'customer'
            ) {
                self.$dialog.find('.cavesTypeSelect').val(2);
                self.$dialog.find('.caves-searchField').val('');

                self.$dialog
                    .find('.cavesSearchTypeDropDown__text')
                    .text(self.options.text_search_in_customers);
            } else if (
                getSearchTypeFromAccountNumber(self, parseInt(searchValue)) ===
                'vendor'
            ) {
                self.$dialog.find('.cavesTypeSelect').val(3);
                self.$dialog.find('.caves-searchField').val('');

                self.$dialog
                    .find('.cavesSearchTypeDropDown__text')
                    .text(self.options.text_search_in_vendors);
            } else if (
                getSearchTypeFromAccountNumber(self, parseInt(searchValue)) ===
                'employee'
            ) {
                self.$dialog.find('.cavesTypeSelect').val(4);
                self.$dialog.find('.caves-searchField').val('');

                self.$dialog
                    .find('.cavesSearchTypeDropDown__text')
                    .text(self.options.text_search_in_employee);
            } else if (
                getSearchTypeFromAccountNumber(self, parseInt(searchValue)) ===
                'asset'
            ) {
                self.$dialog.find('.cavesTypeSelect').val(5);
                self.$dialog.find('.caves-searchField').val('');

                self.$dialog
                    .find('.cavesSearchTypeDropDown__text')
                    .text(self.options.text_search_in_assets);
            }

            var $selected = self.$dialog.find('.cavesRowSelected');
            var $table = self.$dialog.find('.cavesScroll').find('.cavesTable');
            $selected.removeClass('cavesRowSelected');
            $table.html(clearTable(self));
            addSelected(self, null);
            self.options.limitFrom = 0;
            self.options.doSearch = true;
            search(
                self,
                self.$dialog.find('.caves-searchField').val(),
                self.$dialog.find('.cavesTypeSelect').val(),
                self.options.limitFrom,
                self.options.searchLength,
                null
            );
        } else {
            if (searchValue.length > 0) {
                for (
                    var i = 1;
                    i < self.$dialog.find('.cavesRow').find('td').length;
                    i++
                ) {
                    var cell = self.$dialog.find('.cavesRow').find('td')[i];
                    if (
                        cell.innerHTML === self.options.account_text ||
                        cell.innerHTML === self.options.customer_text ||
                        cell.innerHTML === self.options.vendor_text ||
                        cell.innerHTML === self.options.employee_contact_text
                    ) {
                        continue;
                    }
                    cell.innerHTML = cell.innerHTML.replace(
                        '<span class="searchMatch">',
                        ''
                    );
                    cell.innerHTML = cell.innerHTML.replace('</span>', '');
                    var text = cell.innerHTML.toLowerCase();
                    var statr = text.indexOf(searchValue.toLowerCase());
                    text = cell.innerHTML;
                    if (statr !== -1) {
                        $(cell).empty();
                        var first = text.substr(0, statr);
                        var match = $('<span class="searchMatch"></span>').text(
                            text.substr(statr, searchValue.length)
                        );
                        var last = text.substr(statr + searchValue.length);
                        $(cell).append(first, match, last);
                    }
                }
            }
        }
        self.options.previousSearch = searchValue;
    }

    /**
     *
     * widget
     *
     * @author Harry
     * @date @date Sep 7, 2015
     *
     */
    $.widget('tlx.caves', {
        _$dialog: null,

        options: {
            type: '',
            row: '',
            account_text: '',
            asset_text: '',
            customer_text: '',
            employee_text: '',
            employee_contact_text: '',
            vendor_text: '',
            new_text: '',
            text_search_in_all: '',
            text_search_in_accounts: '',
            text_search_in_assets: '',
            text_search_in_customers: '',
            text_search_in_vendors: '',
            text_search_in_employee: '',
            text_type: '',
            text_number: '',
            text_name: '',
            text_transport_method: '',
            text_caves_no_selection: '',
            customer_account: null,
            vendor_account: null,
            employee_account: null,
            customer_accounts: [],
            vendor_accounts: [],
            employee_accounts: [],
            asset_accounts: [],
            searchLength: 25,
            limitFrom: 0,
            isAuthNewCustomerVendor: false,
            isAuthNewAsset: false,
            isAuthNewEmployee: false,
            isAuthNewAccount: false,
            initialInput: '',
            previousSearch: '',
            doSearch: true,
            CUSTOMER_TYPE_VENDOR: 2,
            CUSTOMER_TYPE_CUSTOMER: 1,
            moduleMultipleLedgers: false,
        },
        _create: function () {
            var self = this;

            var buttonStyle =
                'mdl-button mdl-button--colored mdl-js-button caves-button';
            var seachbox;
            var dropdown;

            var buttons = self.options.isAuthNewAccount
                ? '<button type="button" class="' +
                  buttonStyle +
                  ' caves-new-account">' +
                  self.options.new_text +
                  ' ' +
                  self.options.account_text +
                  '</button>'
                : '';
            buttons += self.options.isAuthNewCustomerVendor
                ? '<button type="button" class="' +
                  buttonStyle +
                  ' caves-new-customer">' +
                  self.options.new_text +
                  ' ' +
                  self.options.customer_text +
                  '</button>'
                : '';
            buttons += self.options.isAuthNewCustomerVendor
                ? '<button type="button" class="' +
                  buttonStyle +
                  ' caves-new-vendor">' +
                  self.options.new_text +
                  ' ' +
                  self.options.vendor_text +
                  '</button>'
                : '';
            buttons += self.options.isAuthNewEmployee
                ? '<button type="button" class="' +
                  buttonStyle +
                  ' caves-new-employee">' +
                  self.options.new_text +
                  ' ' +
                  self.options.employee_text +
                  '</button>'
                : '';
            buttons += self.options.isAuthNewAsset
                ? '<button type="button" class="' +
                  buttonStyle +
                  ' caves-new-asset">' +
                  self.options.new_text +
                  ' ' +
                  self.options.asset_text +
                  '</button>'
                : '';

            seachbox =
                '<div class="tlx-textfield caves-search">' +
                '<form autocomplete="off"><input name="searchfield" class="tlx-textfield__input caves-searchField" autocomplete="off" placeholder="' +
                self.options.text_search +
                '" type="text"></form>' +
                '<i class="material-icons cavesDialogen__search-icon js-caves-seacrh-icon">search</i>' +
                '<label class="tlx-textfield__label"></label>' +
                '<div class="tlx-textfield__border"></div>' +
                '</div>';

            var id = Math.floor(Math.random() * 10000);

            dropdown =
                '<span id="cavesSearchTypeDropDown' +
                id +
                '" class="cavesSearchTypeDropDown">' +
                '<span class="cavesSearchTypeDropDown__text">' +
                self.options.text_search_in_all +
                '</span>' +
                '<div class="moreButton mdl-button--icon">' +
                '<i class="material-icons">&#xE5C5;</i>' +
                '</div>' +
                '</span>' +
                '<input type="hidden" id="cavesSearchType' +
                id +
                '" class="cavesTypeSelect mdl-selectfield__select" value="0">' +
                '<ul class="mdl-menu mdl-menu--bottom-right mdl-js-menu topSearchTypeList" for="cavesSearchTypeDropDown' +
                id +
                '">' +
                '<li class="mdl-menu__item" value="0">' +
                self.options.text_search_in_all +
                '</li>' +
                '<li class="mdl-menu__item" value="1">' +
                self.options.text_search_in_accounts +
                '</li>' +
                '<li class="mdl-menu__item" value="2">' +
                self.options.text_search_in_customers +
                '</li>' +
                '<li class="mdl-menu__item" value="3">' +
                self.options.text_search_in_vendors +
                '</li>' +
                '<li class="mdl-menu__item" value="4">' +
                self.options.text_search_in_employee +
                '</li>' +
                (self.options.moduleMultipleLedgers
                    ? '<li class="mdl-menu__item" value="5">' +
                      self.options.text_search_in_assets +
                      '</li>'
                    : '') +
                '</ul>';

            var dialoghtml =
                '<div class="' +
                self.options.type +
                '" style="display: none;">' +
                '<div id="search">' +
                seachbox +
                dropdown +
                '</div>';
            dialoghtml +=
                '<div class="cavesListDiv">' +
                '<table class="cavesTable">' +
                '<tr >' +
                '<th class="cavesTabelColumn">' +
                self.options.text_type +
                '</th>' +
                '<th class="cavesTabelColumn">' +
                self.options.text_number +
                '</th>' +
                '<th>' +
                self.options.text_name +
                '</th>' +
                '</tr>' +
                '</table>' +
                '<div class="cavesScroll">' +
                '<table class="cavesTable">' +
                clearTable(self) +
                '</table>' +
                '</div>' +
                '</div>';
            dialoghtml +=
                '<div class="new-button-wrapper">' + buttons + '</div>';
            dialoghtml += '</div>';

            self.$dialog = $(dialoghtml);
            self.element.parent().append(self.$dialog);
            addSelected(self, null);

            createPopupOpener(
                self,
                '.caves-new-account',
                '/execute/account?accountId=0',
                1
            );
            createPopupOpener(
                self,
                '.caves-new-customer',
                '/execute/customer?customerId=0&customerType=' +
                    self.options.CUSTOMER_TYPE_CUSTOMER,
                2
            );
            createPopupOpener(
                self,
                '.caves-new-vendor',
                '/execute/customer?customerId=0&customerType=' +
                    self.options.CUSTOMER_TYPE_VENDOR,
                3
            ); //
            createPopupOpener(
                self,
                '.caves-new-employee',
                '/execute/createEmployee?isExternal=false&allowLogin=true&customerId=0',
                4
            );
            createPopupOpener(
                self,
                '.caves-new-asset',
                '/execute/createAssetForCaves?assetId=0',
                5
            );

            function cavesOnScroll() {
                if ($(self).is(':visible')) {
                    $(self).dialog('option', 'position', self._position());
                }
            }

            self.$dialog
                .dialog({
                    width: $(window).width() > 600 ? 600 : $(window).width(),
                    position: self._position(),
                    minWidth: 375,
                    resizable: false,
                    draggable: false,
                    appendTo: '#scrollContainer',
                    dialogClass: 'cavesDialogen',
                })
                .on('click', '.cavesRow', function clickCavesRow() {
                    selectAccount(self, this);
                })
                .on('dialogopen', function () {
                    self.element.addClass('ui-state-active');
                    self.$dialog.find('.cavesTypeSelect').val(0);
                    $('.cavesSearchTypeDropDown__text').text(
                        self.options.text_search_in_all
                    );
                    self.options.limitFrom = 0;
                    $table.html(clearTable(self));
                    addSelected(self, null);
                    search(
                        self,
                        '',
                        0,
                        self.options.limitFrom,
                        self.options.searchLength,
                        null
                    );
                    window.setTimeout(function () {
                        self.$dialog.find('.caves-searchField').focus();
                    }, 50);
                    self.$dialog.dialog({
                        position: self._position(),
                    });

                    if (window.narrowScreen) {
                        $('#scrollContainer').on('scroll.caves', cavesOnScroll);
                    }
                })
                .on('dialogclose', function () {
                    self.$dialog.find('.caves-searchField').val('');
                    window.setTimeout(function () {
                        self.element.removeClass('ui-state-active');
                    }, 50);

                    $('#scrollContainer').off('scroll.caves', cavesOnScroll);
                })
                .on('keydown', '.caves-searchField', function (event) {
                    cavesOnkeydown(event, self);
                })
                .on('click', '.js-caves-seacrh-icon', function () {
                    var $table = self.$dialog
                        .find('.cavesScroll')
                        .find('.cavesTable');
                    $table.html(clearTable(self));
                    addSelected(self, null);
                    self.options.limitFrom = 0;
                    self.options.doSearch = true;
                    search(
                        self,
                        self.$dialog.find('.caves-searchField').val(),
                        self.$dialog.find('.cavesTypeSelect').val(),
                        self.options.limitFrom,
                        self.options.searchLength,
                        null
                    );
                })
                .on('change', '.cavesTypeSelect', function () {
                    var $table = self.$dialog
                        .find('.cavesScroll')
                        .find('.cavesTable');
                    $table.html(clearTable(self));
                    addSelected(self, null);
                    self.options.limitFrom = 0;
                    self.options.doSearch = true;
                    search(
                        self,
                        self.$dialog.find('.caves-searchField').val(),
                        self.$dialog.find('.cavesTypeSelect').val(),
                        self.options.limitFrom,
                        self.options.searchLength,
                        null
                    );
                    self.$dialog.find('.caves-searchField').focus();
                })
                .on(
                    'keyup',
                    '.caves-searchField',
                    $.debounce(function (event) {
                        if (
                            event.which !== 40 &&
                            event.which !== 38 &&
                            event.which !== 13
                        ) {
                            cavesInput(event, self);
                        }
                    })
                );

            $(self.$dialog).on('click', '.mdl-menu__item', function (event) {
                var $target = $(event.target);
                $('.cavesSearchTypeDropDown__text').text($target.text());
                self.$dialog
                    .find('.cavesTypeSelect')
                    .val($target.attr('value'));

                var $table = self.$dialog
                    .find('.cavesScroll')
                    .find('.cavesTable');
                $table.html(clearTable(self));

                self.options.limitFrom = 0;
                self.options.doSearch = true;

                search(
                    self,
                    self.$dialog.find('.caves-searchField').val(),
                    self.$dialog.find('.cavesTypeSelect').val(),
                    self.options.limitFrom,
                    self.options.searchLength,
                    null
                );
            });

            // Quick fix, position of dialog fails. Possible due to size changes after
            // initialization? Positioning it once more fix this.
            self.$dialog.dialog('option', 'position', self._position());

            self.$dialog
                .find('.caves-searchField')
                .val(self.options.initialInput);

            self.$dialog.find('.cavesScroll').on(
                'scroll',
                $.debounce(
                    function () {
                        var div = $(this);
                        var table = div.find('.cavesTable');
                        if (0 < table.find('tr').length) {
                            //prevents extra search whene the the list is empty during another search
                            if (
                                table.height() <=
                                div.scrollTop() +
                                    div.height() +
                                    div.height() / 3
                            ) {
                                //scrollTop is 0 based --- div.height()/3: start scroll early to stop lagging effect
                                search(
                                    self,
                                    self.$dialog
                                        .find('.caves-searchField')
                                        .val(),
                                    self.$dialog.find('.cavesTypeSelect').val(),
                                    self.options.limitFrom,
                                    self.options.searchLength,
                                    null
                                );
                            }
                        }
                    },
                    50,
                    true
                )
            );

            self.$dialog
                .find('span.ui-dialog-titlebar-close')
                .click(function () {
                    self.$dialog.dialog('close');
                });

            //Closes dialog if clicked outside.
            $('body, a').on('click', function (event) {
                const target = $(event.target);
                // Is not inside this dialog or dialogs opened by "New xxx" buttons.
                const isInADialog = target.parents('.ui-dialog').length > 0;

                // Dropdown has the drawer in a portal, so an extra check for dropdowns.
                const isFloatingElement =
                    target.parents('.tlx-floating-surface__root').length > 0;

                // Do not know why we check this one, but was a part of original code.
                const hasParent = target.parent().length !== 0;

                if (!isInADialog && !isFloatingElement && hasParent) {
                    self.$dialog.dialog('close');
                }
            });

            self.element
                .on('click.tlxCaves keydown.tlxCaves', function (event) {
                    if (
                        event.type === 'keydown' &&
                        (event.which === 8 ||
                            event.which === 27 ||
                            event.which === 37 ||
                            event.which === 38 ||
                            event.which === 39)
                    )
                        return false;
                })
                .on('click.tlxCaves keypress.tlxCaves', function (event) {
                    if (
                        !self.element.is('.ui-state-active') &&
                        event.which !== 13 &&
                        !(
                            event.type === 'keypress' &&
                            String.fromCharCode(event.which) === '+'
                        )
                    ) {
                        self.$dialog.dialog('open');
                        self.$dialog
                            .find('.caves-searchField')
                            .val(
                                event.which > 32
                                    ? String.fromCharCode(event.which)
                                    : ''
                            );
                    } else if (
                        self.element.is('.ui-state-active') &&
                        event.type === 'click'
                    ) {
                        self.$dialog.dialog('close');
                    }
                });

            $('.caves-button')
                .hover(
                    function (event) {
                        if (!$(event.target).is('.js-caves-disabled')) {
                            $(event.target).addClass('ui-state-hover');
                        }
                    },
                    function (event) {
                        $(event.target).removeClass('ui-state-hover');
                    }
                )
                .click(function (event) {
                    if (
                        !$(event.target).is('.js-caves-disabled') &&
                        !$(event.target).is('.ui-state-active')
                    )
                        $(event.target).addClass('ui-state-active');
                });

            //add selected if selected existes
            var $table = self.$dialog.find('.cavesScroll').find('.cavesTable');
            self.$dialog.parent().show();
            if (2 > $table.find('tr').length) {
                search(
                    self,
                    '',
                    0,
                    self.options.limitFrom,
                    self.options.searchLength,
                    null
                );
            }
            if (self.$dialog.find('.cavesRowSelected').length > 0) {
                var $div = self.$dialog
                    .find('.cavesScroll')
                    .find('.cavesTable')
                    .find('tbody');
                $div.scrollTop(
                    self.$dialog.find('.cavesRowSelected').offset().top -
                        self.$dialog
                            .find('.cavesScroll')
                            .find('.cavesTable')
                            .offset().top
                );
            }
        },
        _destroy: function () {
            $(this.element).removeClass('js-hasCaves');
            this.element.off('.tlxCaves');
            return false;
        },
        clearCaves: function () {
            this.element
                .find(
                    '.js-caves-vendorId, .js-caves-customerId, .js-caves-employeeId, .js-caves-accountId'
                )
                .val(0);
            this.element.find('.cavesAccountPresentation').text('');
            this.element
                .find('.title')
                .text(this.options.text_caves_no_selection);
            this.options.limitFrom = 0;
            this.options.doSearch = true;
            this.$dialog
                .find('.cavesScroll')
                .find('.cavesTable')
                .html(clearTable(this));
            search(
                this,
                '',
                this.$dialog.find('.cavesTypeSelect').val(),
                this.options.limitFrom,
                this.options.searchLength,
                null
            );
            if (!this.element.find('.cavesBlackLine').is('.hide')) {
                this.element.find('.cavesBlackLine').addClass('hide');
                this.element.find('.cavesAccountPresentation').addClass('hide');
            }
        },
        _position: function () {
            return {
                my: 'left top',
                at: 'left bottom',
                of: this.element,
                collision: 'fit flip',
                using: function (position, feedback) {
                    feedback.element.element.css({
                        top: Math.max(position.top, 10),
                        left: position.left,
                    });
                },
            };
        },
    });
})();
