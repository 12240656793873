import {
    Customer,
    InvoiceSettings,
    Order,
    PaymentType,
} from '@Page/InvoicingDialog/types/InvoicingDialog.type';
import React, { useState } from 'react';
import tlxFetch, {
    isApiErrorResponse,
    StandardResponseValueExtractor,
} from '../../../../api/tlxFetch';
import { format } from '../../../../../../js/modules/format';
import { putNotification } from '@Page/Expense/util/helper.functions';
import { InvoicingForm } from './InvoicingForm';
import {
    ElectronicReceiveState,
    useCanReceiveElectronic,
} from '@Page/InvoicingDialog/component/InvoicingForm/Hooks';
import { SendTypes } from '@Page/InvoicingDialog/component/InvoicingForm/SendMethod';
import { ResponseWrapper } from '../../../../hooks/fetch/types';
import { createAPIRequest } from '../../../../hooks/fetch/createAPIRequest';
import { createAPIResponse } from '../../../../hooks/fetch/createAPIResponse';
import { useFetchEntitlement } from '@Page/InvoicingDialog/component/SetupForm/ConnectedSetup';

export function ConnectedInvoicingForm({
    className,
    order,
    invoiceSettings,
    paymentTypes,
    closeModal,
    forwardURL,
}: {
    className: string;
    order: Order;
    invoiceSettings: InvoiceSettings;
    paymentTypes: PaymentType[];
    closeModal: () => void;
    forwardURL: string | undefined;
}) {
    const [updatedPhoneNumber, setUpdatedPhoneNumber] = useState<
        string | undefined
    >(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const [updatedElectronicReceiveState, setUpdatedElectronicReceiveState] =
        useState<ElectronicReceiveState | undefined>(undefined);

    const AUTH_ACCOUNT_ADMINISTRATOR = 126;
    const ROLE_ADMINISTRATOR = 1;
    const isCompanyAdmin = useFetchEntitlement(AUTH_ACCOUNT_ADMINISTRATOR);
    const isUserAdmin = useFetchEntitlement(ROLE_ADMINISTRATOR);

    const initialElectronicReceiveState = useCanReceiveElectronic(
        order.customer,
        order.preliminaryInvoice
            ? order.preliminaryInvoice.isCreditNote
            : false,
        invoiceSettings.sendTypes
    );

    const electronicReceiveState =
        updatedElectronicReceiveState ?? initialElectronicReceiveState;

    async function updatePhoneNumber(phoneNumberMobile: string) {
        const body = {
            phoneNumberMobile,
        };
        const url = '/v2/customer/' + order.customer.id;
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(body),
        };
        window
            .fetch(createAPIRequest(url, requestOptions))
            .then((response) => {
                const statusCode = response.status;
                if (response.ok) {
                    if ([200, 201, 202, 203, 205].includes(statusCode)) {
                        setUpdatedElectronicReceiveState(
                            ElectronicReceiveState.YES
                        );
                        response.json().then((data) => {
                            setUpdatedPhoneNumber(data.phoneNumberMobile);
                        });
                    }
                } else {
                    setUpdatedElectronicReceiveState(ElectronicReceiveState.NO);
                    setUpdatedPhoneNumber(phoneNumberMobile);
                }
            })
            .catch((error) => {
                if (error.code == 422) {
                    setUpdatedElectronicReceiveState(ElectronicReceiveState.NO);
                } else {
                    setUpdatedElectronicReceiveState(
                        ElectronicReceiveState.ERROR
                    );
                }
            });
    }

    return (
        <InvoicingForm
            className={className}
            paymentTypes={paymentTypes}
            order={{
                ...order,
                customer: {
                    ...order.customer,
                    phoneNumberMobile: updatedPhoneNumber
                        ? updatedPhoneNumber
                        : order.customer.phoneNumberMobile,
                },
            }}
            invoiceSettings={invoiceSettings}
            electronicReceiveState={electronicReceiveState}
            updateCanReceiveElectronic={updatePhoneNumber}
            onCancel={closeModal}
            createInvoice={(formValues, order1, sendMethods) => {
                setIsLoading(true);
                return invoice(
                    formValues,
                    order1,
                    sendMethods,
                    closeModal,
                    forwardURL
                );
            }}
            isLoading={isLoading}
            isUserAdmin={isUserAdmin}
            isCompanyAdmin={isCompanyAdmin}
        />
    );
}

export interface DoInvoiceFieldValues {
    invoiceAKontoWithVAT: boolean;
    aKontoAmount: string;
    aKontoComment: string;
    invoiceDate: string;
    createAKonto: boolean;
    createBackorder: boolean;
    paidAmount: string;
    paidAmountAccountCurrency: string;
    paymentTypeId: string;
    restAmountPaymentTypeId: string;
    paidAmountRestAccountCurrency: string;
    invoiceSendMethod: string;
    customerEmail: string;
    customerPhoneNumber: string;
    customerOrganizationNumber: string;
    invoiceIdForCreditNote: number;
}

export async function putCustomer(
    customer: Customer,
    invoiceSendMethod: string,
    sendMethods: string[],
    customerPhoneNumber: string
) {
    const url = new URL('/v2/customer/' + customer.id, window.location.origin);
    const body: any = {};
    if (invoiceSendMethod !== customer.invoiceSendMethod) {
        if (
            invoiceSendMethod === SendTypes.EFAKTURA &&
            !sendMethods.includes(SendTypes.EFAKTURA)
        ) {
            body.invoiceSendMethod = SendTypes.VIPPS;
        } else if (
            invoiceSendMethod === SendTypes.EMAIL ||
            (invoiceSendMethod === SendTypes.EHF &&
                customer.singleCustomerInvoice)
        ) {
            body.invoiceSendMethod = customer.invoiceSendMethod;
        } else {
            body.invoiceSendMethod = invoiceSendMethod;
        }
    }
    if (customerPhoneNumber !== customer.phoneNumber) {
        body.invoiceSendMethod = invoiceSendMethod;
    }

    return tlxFetch({
        url: url.href,
        method: 'PUT',
        body: body,
        callback: StandardResponseValueExtractor,
    });
}

export async function putOrder(orderId: number, customerEmail: string) {
    const url = new URL('/v2/order/' + orderId, window.location.origin);
    const body: any = {};
    body.receiverEmail = customerEmail;

    return tlxFetch({
        url: url.href,
        method: 'PUT',
        body,
        callback: StandardResponseValueExtractor,
    });
}

async function invoice(
    formValues: DoInvoiceFieldValues,
    order: Order,
    sendMethods: string[],
    closeModal: () => void,
    forwardURL: string | undefined
): Promise<string[]> {
    if (
        formValues.invoiceSendMethod !== order.customer.invoiceSendMethod &&
        formValues.invoiceSendMethod !== SendTypes.MANUAL &&
        formValues.invoiceSendMethod !== SendTypes.EMAIL
    ) {
        await putCustomer(
            order.customer,
            formValues.invoiceSendMethod,
            sendMethods,
            formValues.customerPhoneNumber
        );
    }

    if (
        formValues.customerEmail.length > 1 &&
        formValues.customerEmail !== order.receiverEmail
    ) {
        await putOrder(order.id, formValues.customerEmail);
    }

    const invoiceResponse = await doInvoice(
        createRequestUrl(formValues, order.id).toString()
    ).catch(() => {
        closeModal();
        tlxAlert(
            getMessage('text_unexpected_error_has_occured', window.contextId)
        );
    });

    if (!invoiceResponse || isApiErrorResponse(invoiceResponse.response)) {
        closeModal();
    } else if (formValues.invoiceSendMethod === SendTypes.MANUAL) {
        const pdfUrl =
            'invoiceUtil?act=viewForPrint&ids=' + invoiceResponse.response.id;
        const pdfUrlWithContext = addContextId(pdfUrl);
        window.open(pdfUrlWithContext);
        handleInvoiceResponse(invoiceResponse, forwardURL);
    } else {
        const sendType =
            formValues.invoiceSendMethod === SendTypes.EFAKTURA &&
            !sendMethods.includes(SendTypes.EFAKTURA)
                ? SendTypes.VIPPS
                : formValues.invoiceSendMethod;

        try {
            await send(invoiceResponse.response.id, sendType);
        } catch (e) {
            if (
                e.details !== undefined &&
                e.details.validationMessages !== undefined
            ) {
                return [
                    e.details.validationMessages.map(
                        (message: any) => message.message
                    ),
                ];
            } else {
                throw e;
            }
        }
        handleInvoiceResponse(invoiceResponse, forwardURL);
    }
    return [];
}

async function doInvoice(url: string) {
    return tlxFetch({
        url: url,
        method: 'PUT',
    });
}

async function send(invoiceId: number, sendType: string) {
    const request = createAPIRequest(
        `/v2/invoice/${invoiceId}/:send?sendType=${sendType}`,
        {
            method: 'PUT',
        }
    );

    const response = await fetch(request);

    return createAPIResponse<ResponseWrapper<any>>(request, response);
}

function createRequestUrl(formValues: DoInvoiceFieldValues, orderId: number) {
    const paymentTypeId = Number(formValues.paymentTypeId);
    const paidAmount = format.unFormat(formValues.paidAmount);
    const restAmountPaymentTypeId = Number(formValues.restAmountPaymentTypeId);
    const paidAmountAccountCurrency = format.unFormat(
        formValues.paidAmountAccountCurrency
    );
    const paidAmountRestAccountCurrency = format.unFormat(
        formValues.paidAmountRestAccountCurrency
    );

    const aKontoAmount = format.unFormat(formValues.aKontoAmount);

    const url = new URL(
        `/v2/order/${orderId}/:invoice`,
        window.location.origin
    );
    const params = url.searchParams;
    params.set('sendToCustomer', 'false');
    params.set('invoiceDate', formValues.invoiceDate);
    if (formValues.invoiceIdForCreditNote) {
        params.set(
            'invoiceIdIfIsCreditNote',
            formValues.invoiceIdForCreditNote.toString()
        );
    }
    if (formValues.createAKonto) {
        if (formValues.invoiceAKontoWithVAT) {
            params.set('createOnAccount', 'WITH_VAT');
        } else {
            params.set('createOnAccount', 'WITHOUT_VAT');
        }
        if (aKontoAmount && aKontoAmount !== 0) {
            params.set('amountOnAccount', aKontoAmount.toString());
        }
        if (formValues.aKontoComment) {
            params.set('onAccountComment', formValues.aKontoComment);
        }
    }
    if (formValues.createBackorder) {
        params.set(
            'createBackorder',
            Boolean(formValues.createBackorder).toString()
        );
    }

    if (paymentTypeId > 0) {
        params.set('paymentTypeId', paymentTypeId.toString());
        if (paidAmount && paidAmount !== 0) {
            params.set('paidAmount', paidAmount.toString());
        }
        if (restAmountPaymentTypeId > 0) {
            params.set(
                'paymentTypeIdRestAmount',
                formValues.restAmountPaymentTypeId
            );
        }
        if (
            !Number.isNaN(paidAmountAccountCurrency) &&
            paidAmountAccountCurrency !== 0
        ) {
            params.set(
                'paidAmountAccountCurrency',
                paidAmountAccountCurrency.toString()
            );
        }
        if (
            !Number.isNaN(paidAmountRestAccountCurrency) &&
            paidAmountRestAccountCurrency > 0
        ) {
            params.set(
                'paidAmountAccountCurrencyRest',
                paidAmountRestAccountCurrency.toString()
            );
        }
    }
    return url;
}

function createNotification(invoiceId: number) {
    putNotification(
        `${getMessage('text_action_completed')} ${getMessage(
            'text_invoicing'
        )}` +
            '</br>' +
            "<a href='" +
            addContextId('/execute/invoiceUtil?act=view&id=' + invoiceId) +
            "'>" +
            getMessage('link_view_invoice') +
            '</a>' +
            ' / ' +
            "<a href='" +
            addContextId('/execute/invoiceMenu?invoiceId=' + invoiceId) +
            "'>" +
            getMessage('text_details') +
            '</a>' +
            ' / ' +
            "<a href='" +
            addContextId('/execute/listInvoices') +
            "'>" +
            getMessage('text_invoice_overview') +
            '</a>'
    );
}

function handleInvoiceResponse(
    invoiceResponse: any,
    forwardURL: string | undefined
) {
    if (invoiceResponse.response.invoiceNumber !== null) {
        createNotification(invoiceResponse.response.id);

        // if contextId = 3001, just go back
        if (window.contextId == 3001) {
            // @ts-expect-error frameless is not TypeScript-defined on $
            $('body').frameless('ignoreScroll', !0);
            history.back();
        } else if (forwardURL) {
            window.location.href = forwardURL;
        } else {
            window.location.reload();
        }
    }
}
