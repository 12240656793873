import {
    CurrencyTypeDTO,
    EmployeeDTO,
    OrderLine,
    ProjectDTO,
    SupplierDTO,
} from '@Page/LogisticsV2/types/dtoTypes';
import { MapModel } from '@Page/LogisticsV2/types/returnTypes';
import { SortingType } from '@Page/LogisticsV2/utils/enums';
import { Dispatch, SetStateAction } from 'react';

export interface PurchaseOrderDTO {
    id: number;
    number: number;
    supplier: SupplierDTO;
    orderLines: OrderLine[];
    count: number;
    deliveryDate: string;
    project: ProjectDTO;
    ourContact: EmployeeDTO;
    supplierContact: EmployeeDTO;
    attention: EmployeeDTO;
    currency: CurrencyTypeDTO;
    status: string;
    receivedDate: string;
    lastSentTimestamp: string;
    supplierInvoice?: VoucherDTO[];
    totalAmount?: number;
    internalComment?: string;
}

export interface PurchaseOrderInternalDTO {
    id: number;
    totalAmount: number;
}

export interface PurchaseOrderIncomingInvoiceRelationDTO {
    orderOut: PurchaseOrderDTO;
    voucher: VoucherDTO;
}

export interface VoucherDTO {
    number: number;
    tempNumber: number;
    year: number;
}

export interface PurchaseOrderOverviewModel {
    columns: string[];
    data: PurchaseOrderDTO[];
    error?: any;
    filters: PurchaseOrderOverviewFilterModel;
    hasMore: boolean;
    isLoading: boolean;
    loadMore: () => void;
    selectedLines: MapModel<PurchaseOrderDTO>;
    sortBy: SortingType;
    onChangeLineSelection: (line: PurchaseOrderDTO, isChecked: boolean) => void;
    onChangeSelectedLines: Dispatch<
        SetStateAction<{
            [id: number]: PurchaseOrderDTO;
        }>
    >;
    onChangeSortBy: Dispatch<SetStateAction<SortingType>>;
    onToggleSelectAll: (isChecked: boolean) => void;
    onChange: () => void;
    onRefresh: () => Promise<void>;
}

export interface PurchaseOrderOverviewFilterModel {
    orderFilter: string[];
    projectFilter: string[];
    query: string;
    statusFilter: string[];
    supplierFilter: string[];
    supplierProductsFilter: string[];
}

export interface PurchaseOrderOverviewActionModel {
    onDeletePO: (id: number, number: number) => Promise<void>;
    onDeleteGR: (id: number, number: number) => Promise<void>;
    onSend: (id: number, number: number) => Promise<void>;
    onSimplifyGoodReceipt: (
        id: number,
        number: number,
        simplifiedGoodsReceiptModel: SimplifiedGoodsReceiptModel
    ) => Promise<void>;
}

export interface PurchaseOrderOverviewBulkActionModel {
    onBulkDelete: (selectedPurchaseOrders: PurchaseOrderDTO[]) => Promise<void>;
    onBulkSend: (selectedPurchaseOrders: PurchaseOrderDTO[]) => Promise<void>;
    onBulkChangeStatus: (
        selectedPurchaseOrders: PurchaseOrderDTO[],
        status: string
    ) => Promise<void>;
}

export interface SimplifiedGoodsReceiptModel {
    receivedDate: string;
    inventoryId?: number;
}

export interface PurchaseOverviewOption {
    name: string;
    displayName: string;
    enabled: boolean;
}

export interface DropdownModel {
    id: string;
    displayName: string;
}

export const OLD_PURCHASEORDER = 'listOrdersOut';

export const ALL_STATUSES = '-1';

export enum PurchaseOrderStatus {
    OPEN = 'STATUS_OPEN',
    SENT = 'STATUS_SENT',
    RECEIVING = 'STATUS_RECEIVING',
    DEVIATION_DETECTED = 'STATUS_CONFIRMED_DEVIATION_DETECTED',
    DEVIATION_OPEN = 'STATUS_DEVIATION_OPEN',
    DEVIATION_CONFIRMED = 'STATUS_DEVIATION_CONFIRMED',
    CONFIRMED = 'STATUS_CONFIRMED',
    CLOSED = 'STATUS_CLOSED',
    CANCELLED = 'STATUS_CANCELLED',
}
