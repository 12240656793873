import { ModelAPIV1 } from '@General/types/Api';
import { Option } from '../../types';
import { notUndefined } from '@General/Helpers';
import { JSPDropdownFetcher } from '../JSPDropdownFetcher';
import { ApiResultMapper } from '@General/Provider';
import * as React from 'react';
import { SearchFilterGenerator } from '@Component/DropDown/types';

interface LetterheadEntry extends ModelAPIV1 {
    readonly displayName: string;
}

const letterheadMapper: ApiResultMapper<LetterheadEntry> = (item) => ({
    value: item.id + '',
    displayName: item.displayName,
    item,
});

const searchFilterGenerator: SearchFilterGenerator<unknown> = (query) => {
    return (option) =>
        option.displayName.toLowerCase().indexOf(query.toLowerCase()) > -1;
};

class LetterheadFetcher extends JSPDropdownFetcher<LetterheadEntry> {
    asyncJsonrpcGetter: Function;

    constructor(scope: HTMLDivElement, queryAttributes: Record<string, any>) {
        super(scope, queryAttributes, letterheadMapper);

        this.asyncJsonrpcGetter = asyncrpc.Letterhead.get;
    }

    getParams(): ObjectIndex {
        return {
            isActive: this.getQueryAttribute('isActive', 1),
        };
    }

    async fetchList(params: ObjectIndex): Promise<Option<LetterheadEntry>[]> {
        return new Promise((resolve, reject) => {
            try {
                return jsonrpc.Letterhead.searchForLetterheads(
                    (result: LetterheadEntry[] | null) => {
                        const mapped = (result ?? [])
                            .filter(notUndefined)
                            .map(this.getMapper(params))
                            .filter(notUndefined);
                        resolve(mapped);
                    },
                    this.getMarshallSpec(),
                    window.loginCompanyId,
                    params.isActive,
                    params.name || ''
                );
            } catch (e) {
                defaultJsonRpcExceptionHandler(e);
                reject(e);
            }
        });
    }
}

export const exports = {
    fetcher: LetterheadFetcher,
    type: 'Letterhead',
    searchFilterGenerator: searchFilterGenerator,
};
