import { LogisticsModal } from '@Page/LogisticsV2/components/Modals/Modal';
import {
    PurchaseOrderDTO,
    PurchaseOrderOverviewActionModel,
    PurchaseOrderStatus,
    SimplifiedGoodsReceiptModel,
} from '@Page/LogisticsV2/pages/PurchaseOrder/overview/types';
import { ApiUrl, PageUrl } from '@Page/LogisticsV2/utils/enums';
import { getCurrentDate } from '@Page/LogisticsV2/utils/functions';
import {
    Button,
    Dropdown,
    DropdownDrawer,
    DropdownOpener,
    DropdownScrollContainer,
    DropdownSearch,
    Group,
    Input,
    KebabIcon,
    Label,
    PopupMenu,
    PopupMenuItemButton,
    usePopupMenu,
} from '@tlx/atlas';
import { useState } from 'react';
import { extraFrame } from '../../../../../../../js/modules/extraFrame';
import { nav } from '../../../../../../../js/modules/navigation';
import { AsyncDropdownOptions } from '@tlx/astro-shared';

export function PurchaseOrderOverviewTableRowActions({
    purchaseOrder,
    actions,
}: {
    purchaseOrder: PurchaseOrderDTO;
    actions: PurchaseOrderOverviewActionModel;
}) {
    const { openerButtonProps, popupMenuProps } = usePopupMenu();
    const [showDeleteWarning, setShowDeleteWarning] = useState(false);
    const [simplifiedGoodsReceiptModal, setSimplifiedGoodsReceiptModal] =
        useState(false);
    const currentDate = getCurrentDate();
    const [simplifiedGoodsReceipt, setSimplifiedGoodsReceipt] =
        useState<SimplifiedGoodsReceiptModel>({
            receivedDate: currentDate,
        });

    const onChange = (name: string, e: any) => {
        setSimplifiedGoodsReceipt((prevState) => ({
            ...prevState,
            [name]: e.target.value,
        }));
    };
    return (
        <td
            className="atl-td atl-font-normal atl-align-middle atl-flex atl-justify-end"
            key="menuRow"
        >
            <Button
                {...openerButtonProps}
                data-testid="open-button"
                variant="icon"
            >
                <KebabIcon />
            </Button>
            <PopupMenu {...popupMenuProps}>
                {purchaseOrder.status === PurchaseOrderStatus.OPEN &&
                    !moduleRackbeat && (
                        <PopupMenuItemButton
                            onClick={() =>
                                actions.onSend(
                                    purchaseOrder.id,
                                    purchaseOrder.number
                                )
                            }
                            data-testid={'purchase-order-send-button'}
                        >
                            {getMessage('text_send')}
                        </PopupMenuItemButton>
                    )}
                <PopupMenuItemButton
                    data-testid="purchase-order-copy-action"
                    onClick={() => {
                        if (purchaseOrder.supplier === null) {
                            nav.nav(
                                addContextId(
                                    `${PageUrl.GOODS_RECEIPT}?type=2&templateId=${purchaseOrder.id}`
                                )
                            );
                        } else {
                            nav.nav(
                                addContextId(
                                    `/execute/purchaseOrderMenu?type=2&templateId=${purchaseOrder.id}`
                                )
                            );
                        }
                    }}
                >
                    {getMessage('text_copy')}
                </PopupMenuItemButton>
                {(purchaseOrder.status === PurchaseOrderStatus.RECEIVING ||
                    purchaseOrder.status ===
                        PurchaseOrderStatus.DEVIATION_OPEN ||
                    purchaseOrder.status ===
                        PurchaseOrderStatus.DEVIATION_DETECTED) && (
                    <PopupMenuItemButton
                        data-testid={'purchase-order-go-to-po-button'}
                    >
                        <a
                            href={addContextId(
                                PageUrl.PURCHASE_ORDER_DETAILS +
                                    '?orderOutId=' +
                                    purchaseOrder.id
                            )}
                            className="atl-text-grey-100"
                        >
                            {getMessage('text_go_to_purchase_order')}
                        </a>
                    </PopupMenuItemButton>
                )}
                {(purchaseOrder.status === PurchaseOrderStatus.OPEN ||
                    purchaseOrder.status === PurchaseOrderStatus.SENT ||
                    purchaseOrder.status === PurchaseOrderStatus.CONFIRMED) &&
                    moduleLogistics &&
                    !moduleRackbeat && (
                        <PopupMenuItemButton
                            data-testid={
                                'purchase-order-new-goods-receipt-button'
                            }
                        >
                            <a
                                href={addContextId(
                                    PageUrl.GOODS_RECEIPT +
                                        '?orderOutId=' +
                                        purchaseOrder.id
                                )}
                                className="atl-text-grey-100"
                            >
                                {getMessage('text_new_goods_receipt')}
                            </a>
                        </PopupMenuItemButton>
                    )}
                {(purchaseOrder.status === PurchaseOrderStatus.OPEN ||
                    purchaseOrder.status === PurchaseOrderStatus.SENT ||
                    purchaseOrder.status === PurchaseOrderStatus.CONFIRMED) &&
                    moduleLogistics &&
                    !moduleRackbeat &&
                    !isLocationEnabled && (
                        <PopupMenuItemButton
                            data-testid={
                                'purchase-order-simplified-good-receipt-action'
                            }
                            onClick={() => setSimplifiedGoodsReceiptModal(true)}
                        >
                            {getMessage('text_quick_goods_receipt')}
                        </PopupMenuItemButton>
                    )}
                {purchaseOrder.status === PurchaseOrderStatus.CLOSED &&
                    purchaseOrder.receivedDate !== null && (
                        <PopupMenuItemButton
                            data-testid={'purchase-order-show-goods-receipt'}
                        >
                            <a
                                href={addContextId(
                                    PageUrl.GOODS_RECEIPT +
                                        '?orderOutId=' +
                                        purchaseOrder.id
                                )}
                                className="atl-text-grey-100"
                            >
                                {getMessage('text_go_to_goods_receipt')}
                            </a>
                        </PopupMenuItemButton>
                    )}
                <PopupMenuItemButton
                    data-testid={'purchase-order-view-order-out'}
                    onClick={() => {
                        extraFrame.viewerPdf(
                            `/execute/orderOut?act=viewOrderOut&id=${purchaseOrder.id}`
                        );
                        window.dispatchEvent(new CustomEvent('resize'));
                    }}
                >
                    <div
                        style={{ minWidth: '150px' }}
                        className="atl-text-grey-100"
                    >
                        {getMessage('text_view_order_out')}
                    </div>
                </PopupMenuItemButton>
                {(purchaseOrder.status === PurchaseOrderStatus.OPEN ||
                    purchaseOrder.status === PurchaseOrderStatus.SENT ||
                    purchaseOrder.status === PurchaseOrderStatus.CONFIRMED ||
                    purchaseOrder.status === PurchaseOrderStatus.RECEIVING) &&
                    !moduleRackbeat && (
                        <PopupMenuItemButton
                            data-testid={'purchase-order-delete-action'}
                            onClick={() => setShowDeleteWarning(true)}
                        >
                            {getMessage('text_delete')}
                        </PopupMenuItemButton>
                    )}
            </PopupMenu>
            {showDeleteWarning && (
                <LogisticsModal
                    title={getMessage('text_warning')}
                    svgIcon="warning"
                    onConfirm={async () => {
                        setShowDeleteWarning(false);
                        if (purchaseOrder.supplier) {
                            await actions.onDeletePO(
                                purchaseOrder.id,
                                purchaseOrder.number
                            );
                        } else {
                            await actions.onDeleteGR(
                                purchaseOrder.id,
                                purchaseOrder.number
                            );
                        }
                    }}
                    onCancel={() => setShowDeleteWarning(false)}
                >
                    {getMessage('text_delete_purchase_order')}
                </LogisticsModal>
            )}
            {simplifiedGoodsReceiptModal && (
                <LogisticsModal
                    title={getMessage('text_quick_goods_receipt')}
                    onConfirm={async () => {
                        setSimplifiedGoodsReceiptModal(false);
                        await actions.onSimplifyGoodReceipt(
                            purchaseOrder.id,
                            purchaseOrder.number,
                            simplifiedGoodsReceipt
                        );
                    }}
                    onCancel={() => setSimplifiedGoodsReceiptModal(false)}
                >
                    <form autoComplete="off">
                        {getMessage(
                            'text_purchase_order_all_products_will_be_received'
                        )}
                        <Group className="atl-my-8 atl-mr-4">
                            <Label htmlFor="default-value-input">
                                {getMessage('text_date')}
                            </Label>
                            <Input
                                type="date"
                                data-testid="simplified-goods-receipt-date-input"
                                className="atl-w-full"
                                defaultValue={currentDate}
                                onChange={(e) => onChange('receivedDate', e)}
                            />
                        </Group>
                        <Group className="goldshark-purchase-order-overview_warehouse-dropdown atl-my-8 atl-mr-4">
                            <Label htmlFor="default-value-input">
                                {getMessage('text_warehouse')}
                            </Label>
                            <Dropdown
                                data-testid={
                                    'simplified-goods-receipt-warehouse-dropdown'
                                }
                                value={simplifiedGoodsReceipt.inventoryId}
                                onChange={(e) => onChange('inventoryId', e)}
                            >
                                <DropdownOpener />
                                <DropdownDrawer>
                                    <DropdownSearch />
                                    <DropdownScrollContainer>
                                        <AsyncDropdownOptions
                                            url={ApiUrl.INVENTORY}
                                        />
                                    </DropdownScrollContainer>
                                </DropdownDrawer>
                            </Dropdown>
                        </Group>
                    </form>
                </LogisticsModal>
            )}
        </td>
    );
}
