import { postValidationMessages } from '@General/LegacyValidationPopup';
import {
    getFlattenedTree,
    getTree,
} from '@Page/LogisticsV2/components/DataTree/tree';
import { invokeAPI } from '@Page/LogisticsV2/hooks/api';
import {
    useProductGroup,
    useProductGroupRelations,
} from '@Page/LogisticsV2/hooks/useData';
import { ApiUrl, MethodType } from '@Page/LogisticsV2/utils/enums';
import { getURL, getIdFromUrl } from '@Page/LogisticsV2/utils/functions';
import {
    mapObjectToURLSearchParams,
    mapProductGroupDTOtoTreeData,
} from '@Page/LogisticsV2/utils/mappers';
import { useEffect, useState } from 'react';

export function useProductGroupCart() {
    const productId = getIdFromUrl('productId');
    const { data: relations, mutate: relationMutate } =
        useProductGroupRelations({
            fields: '*,productGroup(*)',
            productId: productId,
        });
    const { data: groups } = useProductGroup({ fields: '*,parentGroup(*)' });
    const [selectedNodes, setSelectedNodes] = useState<number[]>([]);

    useEffect(() => {
        setSelectedNodes(relations?.map((rel) => rel.productGroup.id) ?? []);
    }, [relations]);
    const nodesIds = relations?.map((rel) => rel.productGroup.id) ?? [];
    const groupsNodes =
        groups !== undefined
            ? getTree(mapProductGroupDTOtoTreeData(groups))
            : undefined;
    const nodes = getFlattenedTree(mapProductGroupDTOtoTreeData(groups ?? []));
    const dataTree = nodes.filter((node) => nodesIds.includes(node.id));
    const onDelete = async (productGroupId: number) => {
        if (relations === undefined) {
            return;
        }
        const newValues = relations?.filter(
            (item) => item.productGroup.id !== productGroupId
        );
        const deletedItem = relations?.find(
            (item) => item.productGroup.id === productGroupId
        );
        //update local cache to remove element from ui without hitting API
        await relationMutate(newValues, false);
        const { data, error } = await invokeAPI(
            ApiUrl.PRODUCT_GROUP_RELATIONS + '/' + deletedItem?.id,
            MethodType.DELETE
        );
        if (error) {
            postValidationMessages(
                getMessage('text_product_group_error_delete')
            );
        }
        //update local cache with API data
        await relationMutate();
    };
    const onConfirm = async (selectedNodes: number[]) => {
        if (relations === undefined) {
            return;
        }
        const relationsProductGroups = relations.map(
            (relation) => relation.productGroup.id
        );
        const relationsToDelete = relations.filter(
            (relation) => !selectedNodes.includes(relation.productGroup.id)
        );
        const relationsToCreate = selectedNodes.filter(
            (group) => !relationsProductGroups.includes(group)
        );
        if (relationsToDelete.length > 0) {
            await invokeAPI(
                getURL(ApiUrl.PRODUCT_GROUP_RELATIONS_LIST) ?? '',
                MethodType.DELETE,
                JSON.stringify(relationsToDelete)
            );
        }
        if (relationsToCreate.length > 0) {
            const relationsDTOs = relationsToCreate.map((item) => {
                return {
                    product: { id: productId },
                    productGroup: { id: item },
                };
            });
            await invokeAPI(
                ApiUrl.PRODUCT_GROUP_RELATIONS_LIST,
                MethodType.POST,
                JSON.stringify(relationsDTOs)
            );
        }
        await relationMutate();
    };

    const onChange = (nodeId: number) => {
        if (selectedNodes.includes(nodeId)) {
            const newIds = selectedNodes.filter((id) => id !== nodeId);
            setSelectedNodes(newIds);
        } else {
            const newIds = [...selectedNodes];
            newIds.push(nodeId);
            setSelectedNodes(newIds);
        }
    };

    return {
        selectedNodes,
        groupsNodes,
        dataTree,
        onConfirm,
        onDelete,
        onChange,
    };
}
