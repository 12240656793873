import jQuery from 'jquery';
const $ = jQuery;
import { html5Helper } from '../html5-input-type-assistant/html5-input-type-assistant';
import { toolTip } from '../tooltip/tooltip';

(function () {
    function upgradeElements($elements) {
        const newElements = $.makeArray($elements);
        try {
            componentHandler.upgradeElements(newElements);
        } catch (e) {
            // TODO Remove me
            alert(
                'Error upgrading MDL elements!\n' +
                    +'"' +
                    e.message +
                    '"\n' +
                    +'"' +
                    e.stack +
                    '"'
            );
        }
    }

    /**
     * Add the required field back to the element. Also add
     * the is-invalid style class to the mdl textfield component
     * on blur if it is required and it is empty.
     *
     * We only need to do this once, because after changes in the
     * textfield, mdl will start applying the style class on itself again.
     *
     */
    function fixRequiredFieldsHack($required) {
        $required.attr('required', true).one('blur', function () {
            if (this.value === '') {
                $(this).closest('.tlx-textfield').addClass('is-invalid');
            }
        });
    }

    /**
     * Hitting enter when input element in a form has focus triggers submit. Somehow this was prevented,
     * but we have not been able to find out where and how.
     */
    $(window).on('submit', function (e) {
        e.preventDefault();
        return false;
    });

    $(window).on('click', function (e) {
        const $checkbox = $(e.target).closest('.tlx-checkbox');

        if ($checkbox.length === 0) {
            return;
        }

        // Disable clicks on readonly
        if ($checkbox.find('.atl-input--checkbox[readonly]').length === 1) {
            e.preventDefault();
            return false;
        }
    });

    /**
     * Many of these preparations could be done server-side. Setting up info-icons, setting correct style-classes
     * in input-elements etc. As part of cleaning up after "old GUI" when the new one is launched, we should most
     * def try to do something with this.
     *
     * Event crmCardLoaded is a temporary hack for workbench, making sure cards are mdl initialized on load.
     */
    $(window).on(
        'tlxInitializeStateDone tlxLazyLoadDone tlxPopupIsLoaded dialogopen crmCardLoaded',
        function (e) {
            /**
             * remove any lingering tooltips
             */
            $('.mdl-tooltip.is-active').each(function () {
                const $this = $(this);

                if ($('#' + $this.attr('for')).length > 0) {
                    $this.removeClass('is-active');
                } else {
                    $this.remove();
                }
            });

            /**
             * Replace title attribute with both data-tooltip and aria-label.
             *
             * Aria-label is for screen readers and other third party tools guiding visually disabled people.
             * Data-tooltip is for visually able people who need some more information about an element by hovering the mouse pointer.
             * Title is what the browser use for showing a tooltip. We want to show our own, so we decide how to style it (and how long time it takes before it is shown, etc.).
             */
            $('[title]', e.target).each(function () {
                const $this = $(this);
                const tooltip = $this.attr('title');
                $this.removeAttr('title');
                if (!tooltip) {
                    return;
                }

                $this.attr('data-tooltip', tooltip).attr('aria-label', tooltip);
            });

            $('textarea', e.target).attr('rows', 3);
            $('table textarea', e.target).attr('rows', 2);

            /**
             * Material Design Light highlights all required input elements on load.
             * We want to prevent that, and do so by temporarily remove the
             * required attribute.
             */
            const $required = $('[required]', e.target).removeAttr('required');

            /**
             * We should have put the info text into DOM serverside. It is possible, but difficult
             * while maintaining old GUI.
             */
            $('[data-info]', e.target).each(function () {
                const info = $(this).data('info');
                if (!info) {
                    return;
                }
                const id = 'info' + parseInt(Math.random() * 1000000);
                const $icon = $(
                    '<span id="' +
                        id +
                        '" class="icon material-icons">info</span>'
                );

                const $text = $(
                    '<span class="mdl-tooltip" for="' +
                        id +
                        '">' +
                        info +
                        '</span>'
                );
                const infoPosition = $(this).data('info-position');
                if (infoPosition) {
                    $text.addClass(
                        'mdl-tooltip--' + infoPosition.toLowerCase()
                    );
                }

                if (window.narrowScreen) {
                    $text.addClass('mdl-tooltip--left');
                }

                $text.insertAfter(this);
                if (
                    $('label.tlx-checkbox', this).length === 1 &&
                    !window.narrowScreen
                ) {
                    $icon.appendTo($('label.tlx-checkbox', this));
                } else {
                    $icon.appendTo($(this));
                }
                $icon.on('click', false);

                $(this).data('info', '');
            });
            upgradeElements(
                $('.mdl-tooltip').filter(
                    ':not([data-upgraded=",MaterialTooltip"])'
                ),
                e.target
            );

            function wrapInNoLabelMDL(element) {
                let extraClasses = '';
                const $input = $(element).is('input')
                    ? $(element)
                    : $(element).find('input');

                if ($input.is('.currencyCode')) {
                    extraClasses = 'tmdl-currencySelect';
                } else if ($input.hasClass('tlxCurrencyInput')) {
                    extraClasses = 'tmdl-currency';
                }

                if ($input.is('[readonly]')) {
                    extraClasses += ' is-readonly';
                }

                if ($input.is('[disabled]')) {
                    extraClasses += ' is-disabled';
                }

                return $(
                    '<div class="tlx-textfield tlx-textfield--no-label tmdl-mixed-input__element ' +
                        extraClasses +
                        '">' +
                        '<div class="tlx-textfield__border"></div>' +
                        '</div>'
                ).prepend(element);
            }

            $('.tmdl-mixed-input:not(.tmdl-initiated)', e.target).each(
                function () {
                    // This worked for the mixed input elements in
                    // Ny ordre/faktura > Abonnement where we had
                    // two and three input elements inside one
                    // InputItem tag.
                    $(this).addClass('tmdl-initiated');
                    $(this)
                        .find('input:not([type=hidden]):not([type=submit])')
                        .each(function () {
                            const $placeholder = $('<div/>').insertAfter(this);
                            wrapInNoLabelMDL(this).insertBefore($placeholder);
                            $placeholder.remove();
                        });
                    // A combo might contain a datefield.
                    // The InputItemTag doesn't know that one of it's combo components is a datefield
                    // therefore we append it here if it exists (.find() just returns an empty
                    // jQuery object if the combo doesn't contain a .tlxDateField
                    $(this)
                        .find('input.tlxDateField')
                        .after(
                            '<span class="icon material-icons tmdl-date-icon">date_range</span>'
                        );
                    return;
                }
            );

            // Convert checkbox in checkbox-text combo into a visual appealing one
            $('.tmdl-checkbox-text-combo:not(.tmdl-initiated)', e.target).each(
                function () {
                    const $this = $(this);
                    $this.addClass('tmdl-initiated');
                    const $checkbox = $('[type=checkbox]', this);

                    $checkbox[0].addEventListener(
                        'change',
                        function () {
                            if (!$this.find('[type=text]').is('[readonly]')) {
                                $this.find('[type=text]')[0]?.focus();
                            }
                        },
                        true
                    );

                    $(
                        '<label class="tlx-checkbox tmdl-checkbox-text-combo__checkbox" />'
                    )
                        .prependTo(this)
                        .append($checkbox)
                        .append('<span class="tlx-checkbox__label"></span>');
                }
            );

            /*
             * In use in accountCurrentBook* for instance, where you get to select between posts and period in the filter.
             */
            $('.tmdl-radio-date-combo:not(.tmdl-initiated)', e.target).each(
                function () {
                    $(this).addClass('tmdl-initiated');

                    const $radio = $('[type=radio]', this).addClass(
                        'tlx-radio__input'
                    );

                    $(
                        '<label class="tlx-radio tmdl-checkbox-text-combo__checkbox" />'
                    )
                        .prependTo(this)
                        .append($radio)
                        .append('<span class="tlx-radio__label"></span>');

                    $(this)
                        .find('input.tlxDateField')
                        .after(
                            '<span class="icon material-icons tmdl-date-icon">date_range</span>'
                        );
                }
            );

            const $elements = $('.mdl-menu', e.target).filter(
                ':not([data-upgraded])'
            );
            upgradeElements($elements);

            fixRequiredFieldsHack($required);

            /**
             * We need to know which tables contains any input elements
             * (other than checkboxes and hidden ones) to be able to style
             * these differently than the ones not containing any.
             */
            $('table:not(.table--spreadsheet)', e.target).each(function () {
                if ($(this).has('input[type="text"]').length) {
                    $(this).addClass('withInput');
                }
            });

            /**
             * Adds right and margin-right style properties to the period
             * selector in those cases where you can choose to see open items
             * or items in a period (items being orders etc.).
             * The width of the text describing the "open item" various from
             * page to page pushing the period selector more or less to the
             * right. The DOM structure forces the need for this javascript.
             * If the DOM structure for this components is to be changed
             * please remove this code.
             */
            $('.filter .periodArrowContainer ', e.target).each(function () {
                const $periodArrowContainer = $(this);
                const $tmdlPeriodSelecter = $(this)
                    .parent()
                    .addClass('hasPeriodArrowContainer')
                    .siblings()
                    .children('.tmdl-period-selecter');

                $tmdlPeriodSelecter.each(function () {
                    const width =
                        $periodArrowContainer.width() -
                        $periodArrowContainer
                            .find('label:nth-of-type(1)')
                            .width() -
                        5;
                    $(this)
                        .parent()
                        .css({ right: width, 'margin-right': -width });
                });
            });

            $('.pt-content', e.target).each(function () {
                if ($(this).find('.inputItem:not(.checkbox)').length > 0) {
                    $(this).addClass('hasInputItem');
                }
            });

            /**
             * Finds all buttons inside a section and removes the jquery
             * ui classes and adds the mdl-button class instead.
             *
             * Classes also added on serverside for percieved performance. Should be removed from here eventually.
             */
            $('.section input[type=button].ui-button', e.target).each(
                function () {
                    $(this)
                        .addClass('mdl-button tmdl-input-button')
                        .removeClass(
                            'ui-button ui-widget ui-state-default ui-corner-all tlx-textfield__input'
                        );
                }
            );

            /**
             * This should only apply to pt-labels associated with just one item.
             * Use label-with-multiple-items on th elements associated to a td containing multiple items.
             * (see voucher.jsp)
             *
             * Hack for inputItems without mobileKeyLabel. Iterates through all pt-labels in pt-mobile.
             * If the associated pt-content contains an empty mdl label, the pt-label text gets added to
             * the mdl label and then the pt-label is removed. This is done to make the labels behave as
             * mdl labels.
             */
            $(
                '.pt-mobile .pt-label:not(.table-cell--multiple-items)',
                e.target
            ).each(function () {
                const $mdlTextfield = $(this)
                    .siblings('.pt-content')
                    .find('.tlx-textfield');
                const label = $mdlTextfield.find(
                    '> label.tlx-textfield__label'
                );
                if (label.length > 0 && !label.text()) {
                    label.text($(this).text());
                    $(this).remove();
                }
            });

            html5Helper.init(e);

            // Make sure textarea expands if it has any content.
            if (window.location.pathname !== '/execute/updateHourlist') {
                //Setting timeout 0 to set the expansion of textarea as one of the last task of the thred.
                // elements need to be upgraded and the helper-span is needed before expansion
                setTimeout(function () {
                    const $textfields = $(e.target)
                        .find('textarea')
                        .filter(function () {
                            return this.value;
                        });

                    // This is actually wery time consuming...
                    if ($textfields.length > 30) {
                        return;
                    }
                    $textfields.trigger('keyup');
                }, 0);
            }
        }
    );

    // Trigger this event before removing upgraded MDL components, so that we can downgrade them again safely.
    // Use the DOM element that contains the possible MDL components as target:
    // $container.trigger('tlxRemoveUpgradedMdlComponents')
    $(window).on('tlxRemoveUpgradedMdlComponents', function (e) {
        componentHandler.downgradeElements(
            $('[data-upgraded]', e.target).toArray()
        );
    });

    /**
     * Components in dynamically added rows must be upgraded with mdl.
     * But since these elements are copies of upgraded mdl-elements,
     * they look upgraded from mdls perspective. Hide this (by removing classname
     * and data attribute) and perform upgrading.
     */
    $(window).on('tlxNewRowAdded', function (e) {
        /**
         * Material Design Light highlights all required input elements on load.
         * We want to prevent that, and do so by temporarily remove the
         * required attribute.
         */
        const $required = $('[required]', e.target).removeAttr('required');

        const $elements = $('[data-upgraded]', e.target)
            .removeClass('is-upgraded')
            .attr('data-upgraded', '');

        /**
         * Also remove extra elements inside the input widget. (Someone complained
         * in regression test that a checkbox actually was TWO checkboxes in the
         * code. This didn't happen to be true, but there was an extra set of
         * MDL-helper containers inside the checkbox label. Doesn't hurt to remove
         * them, but doesn't seem to have any effect one way or another.)
         *
         */
        const superfluousElementsInsideMDLWidgets = [
            '.mdl-js-ripple-effect',
        ].join(', ');

        $elements.find(superfluousElementsInsideMDLWidgets).remove();

        upgradeElements($elements);

        fixRequiredFieldsHack($required);
    });

    $(document).on('focus', 'input.number', function (e) {
        const $target = $(e.target);

        if (
            $target.attr('readonly') === 'readonly' ||
            $target.attr('readonly') === 'true' ||
            $target.attr('disabled') === 'disabled' ||
            $target.attr('disabled') === 'true'
        ) {
            return;
        }

        const value = parseFloat(
            $target.val().replace(window.decimalSeparator, '.')
        );
        if (value === 0) {
            $target.val('');
        }
    });

    // Prevent event listeners to fire on disabled menu items.
    // This must be a bug in MDL, disabled items should of course not fire
    // on click events. Check if this solution is necessery when updating MDL.
    window.addEventListener(
        'click',
        function (e) {
            if ($(e.target).is('.mdl-menu__item[disabled]')) {
                e.preventDefault();
                e.stopPropagation();
                return false;
            }
        },
        true
    );

    /**
     * This is our truncated text "tooltip" code.
     *
     * For specific items (.navigateMenu and .usercompany span), it will give a tooltip
     * if the text is truncated because of overflow: hidden. This is done by measuring the width of the element with
     * the width of a cloned element without width constraints.
     *
     * @bruce bugfix 17.09.2019: added :not(mdl-navigation__link) on .navigateMenu, this avoids tooltip on "Home" and
     * "Travels and expenses". These had 1) The style class .navigateMenu 2) The HTML tag LI 3) A DIV tag inside it,
     * which is a block element, and thus take "all possible width". Firefox and Edge interprets this correctly and
     * yields a tooltip.
     *
     */
    $(document)
        .on(
            'mouseenter',
            '.txr-dropdown__field__input, .navigateMenu:not(.usercompany,.mdl-navigation__link), .usercompany span',
            function () {
                const $target = $(this);

                if ($.trim($target.text()).length === 0) {
                    return;
                }

                // This is one of the very few reliable cross-browser detections of whether text has overflown
                // or not in the element: Clone the element, remove width restrictions, see if it is wider!
                const $clone = $target
                    .clone()
                    .css({
                        display: 'inline',
                        width: 'auto',
                        visibility: 'hidden',
                    })
                    .appendTo('body');

                if ($clone.width() > $target.width()) {
                    toolTip.add($target[0], $target.text());
                }

                $clone.remove();

                /**
                 * Tooltips taken from [data-tooltip], and in some cases [aria-label].
                 *
                 * A) Elements with [data-tooltip] shall have this as tooltip!
                 *
                 * B) Icons or icon only buttons with [aria-label], and not [data-tooltip], shall have [aria-label] as tooltip.
                 *
                 * If items have a [title], we let the browser give the end user this as a tooltip. We should convert [title]
                 * to [data-tooltip] (and sometimes [aria-label]). Or perhaps we can do something else somewhere down the line... For now we replace
                 * [title] with both [aria-label] and [data-toolip]
                 *
                 */
            }
        )
        .on(
            'mouseenter',
            '[data-tooltip], .material-icons[aria-label], .mdl-button--icon[aria-label], .txr-simple-button-icon[aria-label], .tlx-topbar__button[aria-label]',
            function () {
                const $target = $(this);

                let tooltip = $target.attr('data-tooltip');

                /**
                 * Icon only buttons: Okay to use [aria-label], but only if they don't have a [data-tooltip].
                 */
                if (
                    !tooltip &&
                    $target.is(
                        '.material-icons[aria-label], .mdl-button--icon[aria-label], .txr-simple-button-icon[aria-label], .tlx-topbar__button[aria-label]'
                    )
                ) {
                    tooltip = $target.attr('aria-label');
                }

                if (tooltip) {
                    toolTip.add($target[0], tooltip);
                }
            }
        )
        .on('mouseleave', '.js-tooltip-open', function () {
            toolTip.remove($('.js-tooltip-open'));
        });
})();

//Attachment

//If we navigate ut a new tab, period, etc. we have to reset extraFrame so the user dose not open a pdf form the previous period.
$(window).on('tlxLazyLoadDone', function () {
    const main = window;
    if (main && main.extraFrame) {
        main.extraFrame.setDefaultUrl('');
    }
});
