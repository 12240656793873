import { ModelAPIV1 } from '@General/types/Api';
import { Option } from '../../types';
import { JSPDropdownFetcher } from '../JSPDropdownFetcher';
import { ApiResultMapperNoUndefinedResult } from '@General/Provider';

interface BankAccountNumberEntry extends ModelAPIV1 {
    readonly number: string;
}

const bankAccountNumberMapper: ApiResultMapperNoUndefinedResult<BankAccountNumberEntry> =
    (item) => ({
        value: item.number,
        displayName: item.number,
        item,
    });

class BankAccountNumberFetcher extends JSPDropdownFetcher<BankAccountNumberEntry> {
    asyncJsonrpcGetter: Function;

    constructor(scope: HTMLDivElement, queryAttributes: Record<string, any>) {
        super(scope, queryAttributes, bankAccountNumberMapper);
        this.asyncJsonrpcGetter = this.get;
    }

    // This is added here so that 'number' would be used instead of 'displayName'
    // Using 'displayName' breaks the dropdown for account numbers
    getMarshallSpec(): { marshallSpec: string[] } {
        return window.marshallSpec('id', 'number');
    }

    get(marshallSpec: any, id: string): Promise<any> {
        const result = { id, number: id };
        return Promise.resolve(result);
    }

    getParams(): ObjectIndex {
        const vendorId = this.getQueryAttribute('vendorElementId', false);
        const customerId = this.getQueryAttribute('customerElementId', false);

        return {
            ownerId: vendorId || customerId || 0,
        };
    }

    async fetchList(
        params: ObjectIndex
    ): Promise<Option<BankAccountNumberEntry>[]> {
        return new Promise((resolve, reject) => {
            try {
                return jsonrpc.CompanyBankAccountNumber.search(
                    (result: BankAccountNumberEntry[] | null) =>
                        resolve((result ?? []).map(bankAccountNumberMapper)),
                    this.getMarshallSpec(),
                    params.ownerId,
                    params.name || ''
                );
            } catch (e) {
                defaultJsonRpcExceptionHandler(e);
                reject(e);
            }
        });
    }
}

export const exports = {
    fetcher: BankAccountNumberFetcher,
    type: 'BankAccountNumber',
};
