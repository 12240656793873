import * as React from 'react';

import { RouteData } from '@General/Router';
import { Team } from '@General/Team';

import { PageLoader } from '@Component/PageLoader';

/**
 * @see PageLoader
 * @see renderComponent
 */
export const Page: React.FC = (props) => (
    <PageLoader<{}>
        loader={async () => import('./Checkout.module').then((m) => m.page)}
        props={props}
    />
);

const ALLOWED_ROLES = [1, 126];

/**
 * Describes URL -> React page mapping
 *
 * Name of this file must end with `.route.ts` or `.route.tsx` for the mapping to be registered automatically.
 */
export const route: RouteData = {
    component: Page,
    path: '/execute/checkout',
    title: () => '',
    team: Team.BEEHIVE,
    documentationComponent: undefined,
    auth: (context) =>
        context.entitlements.some((e) => ALLOWED_ROLES.includes(e.id)),
};
