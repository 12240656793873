import React, {
    CSSProperties,
    forwardRef,
    useLayoutEffect,
    useRef,
} from 'react';
import { autoUpdate, offset, shift, useFloating } from '@floating-ui/react-dom';
import { Portal } from '@tlx/atlas';
import { useComposedRefs } from '../../../../hooks/useComposedRefs';

export function MultipleValuesWithPopup({
    open,
    text,
    setOpen,
    children,
}: {
    open: boolean;
    setOpen: (value: boolean) => void;
    text: string;
    children: React.ReactNode;
}) {
    const linkRef = useRef<HTMLDivElement>(null);
    const popupRef = useRef<HTMLDivElement>(null);

    const floating = useFloating({
        placement: 'bottom-start',
        middleware: [shift({ padding: 16 }), offset(11)],
    });

    const popupPositioningStyle: CSSProperties = {
        position: floating.strategy,
        top: floating.y ?? '',
        left: floating.x ?? '',
    };

    const composedLinkRef = useComposedRefs(
        linkRef,
        floating.refs.setReference
    );
    const composedPopupRef = useComposedRefs(
        popupRef,
        floating.refs.setFloating
    );

    useLayoutEffect(() => {
        if (open) {
            floating.update();
        }
    }, [open, children]);

    useLayoutEffect(() => {
        let cleanup: () => void;
        if (linkRef.current && popupRef.current) {
            cleanup = autoUpdate(
                linkRef.current,
                popupRef.current,
                floating.update
            );
        }
        return () => {
            cleanup?.();
        };
    }, [linkRef.current, popupRef.current]);

    return (
        <>
            <MultipleValuesLink
                ref={composedLinkRef}
                onMouseEnter={() => setOpen(true)}
                onMouseLeave={() => setOpen(false)}
            >
                {text}
            </MultipleValuesLink>
            {open && (
                <MultipleValuesPopup
                    ref={composedPopupRef}
                    style={popupPositioningStyle}
                    tabIndex={-1}
                >
                    {children}
                </MultipleValuesPopup>
            )}
        </>
    );
}

export const MultipleValuesLink = forwardRef<
    HTMLDivElement,
    React.ButtonHTMLAttributes<HTMLDivElement>
>(function MultipleWarehouseLink({ children, ...props }, ref) {
    return (
        <div
            ref={ref}
            data-testid="multiple-values-link"
            className="atl-text-blue-100"
            style={{ width: 'fit-content' }}
            {...props}
        >
            {children}
        </div>
    );
});

export const MultipleValuesPopup = forwardRef<
    HTMLDivElement,
    React.HTMLAttributes<HTMLDivElement>
>(function MultipleWarehousePopup({ children, ...props }, ref) {
    return (
        <Portal>
            <div
                data-testid="multiple-values-popup"
                className="atl-flex atl-flex-col atl-bg-white atl-border atl-border-grey-20 atl-p-16 atl-rounded atl-gap-16"
                ref={ref}
                {...props}
            >
                {children}
            </div>
        </Portal>
    );
});
