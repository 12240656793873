import * as React from 'react';
import { resultMemorizer } from '@General/Helpers';
import {
    evaluateExpression,
    isExpression,
    unFormatExpression,
} from '@General/math';
import { Input } from '@tlx/atlas';
import { TextFieldProps, TextFieldSimpleProps } from '@Component/Form';
import { format } from '../../../../../js/modules/format';

type Value = TextFieldProps['value'];

export type FormattedTextFieldProps = Omit<
    TextFieldSimpleProps,
    'onChange' | 'onFocus' | 'onBlur'
> & {
    format?: string | FormattingOptions;
    onChange?: (
        event: React.ChangeEvent<HTMLInputElement>,
        rawValue: Value
    ) => void;
    onFocus?: (
        event: React.FocusEvent<HTMLInputElement>,
        rawValue: Value
    ) => void;
    onBlur?: (
        event: React.FocusEvent<HTMLInputElement>,
        rawValue: Value
    ) => void;
    blankOnEmpty?: boolean;
    id?: string;
    step?: number | string;
};

type Props = FormattedTextFieldProps;

type State = {
    hasFocus: boolean;
    formulaResult: Value;
    currentValue: Value;
};

type FormattingOptions = {
    formatKey: string;
    precision: number;
    blank: boolean;
    blankFraction: boolean;
    separateThousands: boolean;
    trailingZeros: number;
    decimalSeparator: string;
    roundOffDecimal: boolean;
};

const defaultFormatting = {
    formatKey: '#,###.##',
    precision: 2,
    blank: true,
    blankFraction: false,
    separateThousands: true,
    trailingZeros: 2,
    decimalSeparator: window.decimalSeparator,
    roundOffDecimal: true,
};

/**
 * @Warning - PaymentWidgetFormattedNumberField extends this class component
 *
 * This is the exact same component as in YearEndSubmission page, but with support for
 * decimals numbers.
 *
 * @author Daud Mohamed
 * @date 16. Mar 2022
 */
export class FormattedNumberField extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.onChangeHandler = this.onChangeHandler.bind(this);
        this.onFocusHandler = this.onFocusHandler.bind(this);
        this.onBlurHandler = this.onBlurHandler.bind(this);

        this.state = {
            hasFocus: false,
            formulaResult: undefined,
            currentValue: this.getFormattedValue(props.value, props.format),
        };
    }

    static defaultProps = {
        format: defaultFormatting,
    };

    componentDidUpdate(prevProps: Props): void {
        if (this.props.value !== prevProps.value) {
            const formatted = this.getFormattedValue(
                this.props.value,
                this.props.format
            );

            if (
                this.props.blankOnEmpty &&
                (this.props.value === undefined ||
                    this.props.value.length === 0)
            ) {
                this.setState({
                    currentValue: undefined,
                    formulaResult: undefined,
                });
            } else if (
                (this.state.formulaResult !== undefined &&
                    formatted !== this.state.formulaResult) ||
                (this.state.formulaResult === undefined &&
                    formatted !== this.state.currentValue)
            ) {
                this.setState({
                    currentValue: formatted,
                    formulaResult: undefined,
                });
            }
        } else if (this.props.format !== prevProps.format) {
            this.setState({
                currentValue: this.getFormattedValue(
                    this.props.value,
                    this.props.format
                ),
                formulaResult: undefined,
            });
        }
    }

    getFormattedValue = resultMemorizer<Value, Value | number, Props['format']>(
        (rawValue, formatKey) => {
            if (!rawValue) {
                return undefined;
            }
            if (!formatKey) {
                return `${rawValue}`;
            }
            return format.withKey(`${rawValue}`, formatKey);
        }
    );

    getUnFormattedValue = resultMemorizer<Value, Value, Props['format']>(
        (formattedValue, formatKey) => {
            if (!formattedValue) {
                return undefined;
            }
            if (!formatKey) {
                return formattedValue;
            }
            return String(format.unFormat(formattedValue));
        }
    );

    onChangeHandler(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            currentValue:
                this.props.blankOnEmpty && !event.target.value
                    ? undefined
                    : event.target.value,
        });

        this.props.onChange?.(
            event,
            this.getUnFormattedValue(event.target.value, this.props.format)
        );
    }

    onFocusHandler(event: React.FocusEvent<HTMLInputElement>) {
        event.target.select();
        const unformattedValue = this.getUnFormattedValue(
            event.target.value,
            this.props.format
        );
        if (
            unformattedValue !== undefined &&
            this.state.formulaResult === undefined &&
            parseFloat(unformattedValue) === 0
        ) {
            this.setState({
                hasFocus: true,
                currentValue: '',
            });
        } else {
            this.setState({
                hasFocus: true,
            });
        }

        this.props.onFocus?.(
            event,
            this.getUnFormattedValue(event.target.value, this.props.format)
        );
    }

    onBlurHandler(event: React.FocusEvent<HTMLInputElement>) {
        let value: Value;

        if (
            this.state.currentValue !== undefined &&
            isExpression(unFormatExpression(this.state.currentValue))
        ) {
            value = `${evaluateExpression(this.state.currentValue)}`;
            this.setState({
                hasFocus: false,
                formulaResult: this.getFormattedValue(value, this.props.format),
            });
        } else if (
            this.props.blankOnEmpty &&
            !this.state.currentValue?.length
        ) {
            value = undefined;
            this.setState({
                hasFocus: false,
                currentValue: undefined,
                formulaResult: undefined,
            });
        } else {
            value = this.getUnFormattedValue(
                this.state.currentValue,
                this.props.format
            );
            const reformatedValue = this.getFormattedValue(
                value,
                this.props.format
            );

            if (reformatedValue !== this.state.currentValue) {
                this.setState({
                    hasFocus: false,
                    formulaResult: undefined,
                    currentValue: reformatedValue,
                });
            } else {
                this.setState({
                    hasFocus: false,
                    formulaResult: undefined,
                });
            }
        }

        this.props.onBlur?.(event, value);
    }

    render() {
        return (
            <Input
                {...this.props}
                data-testid={'Formatted-number-field'}
                onChange={this.onChangeHandler}
                onFocus={this.onFocusHandler}
                onBlur={this.onBlurHandler}
                type="text"
                value={
                    !this.state.hasFocus &&
                    this.state.formulaResult !== undefined
                        ? this.state.formulaResult
                        : this.state.currentValue === undefined
                        ? ''
                        : this.state.currentValue
                }
                autoComplete={'off'}
                step={'.01'}
            />
        );
    }
}
