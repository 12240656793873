import * as React from 'react';
import { MouseEvent } from 'react';
import $ from 'jquery';
import '../../../../js/legacy/jquery.scrollIntoView.min';
import '@tlx/jquery-ui-fork';
import { TextFieldProps, TextFieldSimple } from '../Form/TextField';
import { FormGroup } from '@Component/Form';
import { ButtonIcon } from '@Component/ButtonIcon';
import { dateUtil } from '../../../../js/modules/date';

type Props = TextFieldProps;

const headerHtml = $(
    '<div class="ui-datepicker-tmdl-header">' +
        '<div class="ui-datepicker-tmdl-year"></div>' +
        '<div class="ui-datepicker-tmdl-date"></div>' +
        '</div>'
);

const updateTmdlHeader = function (header: JQuery, date: Date): void {
    const dateObject = new Date(date),
        dateString = dateUtil.formatDate(dateObject, 'EEEE d. MMMM');
    $('.ui-datepicker-tmdl-year', header).text(dateObject.getFullYear());
    $('.ui-datepicker-tmdl-date', header).text(dateString);
};

//Copied from Jquery UI (ish)
const calculatePosition = function ($elem: JQuery): void {
    const widget = $elem.datepicker('widget'),
        dpHeight = widget.outerHeight(),
        viewHeight = window.innerHeight,
        inputHeight = $elem.outerHeight();
    let top = $elem.offset()!.top + $elem.outerHeight()!;

    top -= Math.min(
        top,
        top + dpHeight > viewHeight && viewHeight > dpHeight
            ? Math.abs(dpHeight + inputHeight)
            : 0
    );
    widget.css('top', top);
};

const syncWithMinDate = function (
    this: JQuery,
    dateInput: any,
    instance: any
): void {
    const $body = $('body');
    const $endDates = $body.find('.tlxDateField.tlxEndDate');
    const $startDates = $body.find('.tlxDateField.tlxStartDate');
    const $startDate = $startDates.eq($endDates.index(this));
    if ($startDate.length == 0) {
        if (!window.productionMode) {
            alert('tlxStartDate without matching tlxEndDate');
        }
        return;
    }
    const selectedDate = $startDate.val();
    const date = $.datepicker.parseDate(
        instance.settings.dateFormat || $.datepicker._defaults.dateFormat,
        selectedDate,
        instance.settings
    );
    $(dateInput).datepicker('option', 'minDate', date);
};

export default class DatePicker extends React.PureComponent<Props> {
    private readonly inputElement: React.RefObject<HTMLInputElement>;
    private initialized = false;

    constructor(props: Props) {
        super(props);
        this.inputElement = React.createRef();
        this.show = this.show.bind(this);
        this.initDatePicker = this.initDatePicker.bind(this);
        this.handleKeyBoardShortcuts = this.handleKeyBoardShortcuts.bind(this);
        this.parseAndSetDate = this.parseAndSetDate.bind(this);
    }

    handleKeyBoardShortcuts(evt: React.KeyboardEvent<HTMLInputElement>) {
        if (this.props.disabled) {
            return;
        }

        if (!this.inputElement.current) {
            return;
        }

        const $elem: any = $(this.inputElement.current);

        const setDate = (date: Date) => {
            $elem.datepicker('setDate', date);
            // We need to update the headers again.
            updateTmdlHeader(headerHtml, $elem.datepicker('getDate'));
            $('.ui-datepicker').prepend(headerHtml);
        };

        const currentDate = $elem.datepicker('getDate');
        const parsedDate = dateUtil.parseDate(
            this.inputElement.current.value,
            true
        );
        switch (evt.key) {
            case '+':
            case 't':
            case 'T':
                setDate(new Date());
                break;
            case 'Up':
            case 'ArrowUp':
                setDate(dateUtil.addDays(currentDate, -1));
                break;
            case 'Down':
            case 'ArrowDown':
                setDate(dateUtil.addDays(currentDate, 1));
                break;
            case 'Enter':
                if (parsedDate) {
                    setDate(parsedDate);
                }
                break;
            case 'Tab':
                if (parsedDate) {
                    setDate(parsedDate);
                }
                break;
            case 'PageDown':
                setDate(dateUtil.addMonth(currentDate));
                break;
            case 'PageUp':
                setDate(dateUtil.subtractMonth(currentDate));
                break;
        }

        if (this.props.onKeyDown) {
            this.props.onKeyDown(evt);
        }
    }

    show(e: MouseEvent) {
        if (this.props.disabled) {
            return;
        }
        if (!this.inputElement.current) {
            return;
        }

        if (!this.initialized) {
            this.initDatePicker();
        }

        const $elem: JQuery = $(this.inputElement.current);
        $elem.datepicker('show');
        updateTmdlHeader(headerHtml, $elem.datepicker('getDate'));
        $('.ui-datepicker').prepend(headerHtml);
        if (window.narrowScreen) {
            $elem.blur();
        }
    }

    componentWillUnmount(): void {
        $.datepicker._disabledInputs = [];
    }

    initDatePicker() {
        if (!this.inputElement.current || this.initialized) {
            return;
        }
        this.initialized = true;
        const $elem: JQuery = $(this.inputElement.current);
        const htmlInputElement = this.inputElement.current;

        if (!$elem.is('.hasDatepicker')) {
            $elem.datepicker({
                yearRange: 'c-100:c+10',
                defaultDate: $elem.data('defaultDate'),
                showButtonPanel: true,
                changeYear: false,
                changeMonth: false,
                weekHeader: '',
                autoSize: false,
                beforeShow: $elem.hasClass('tlxEndDate')
                    ? syncWithMinDate
                    : null,
                onClose: () => {
                    $('.mdl-layout').removeClass('datepickerVisible');
                    $elem.removeAttr('readonly');

                    // This is needed to actually trigger a change in redux-form.
                    htmlInputElement.focus();
                    htmlInputElement.blur();
                },
            });
        }

        //$elem.datepicker('show');
        //Need to update the header the first time it is displayed with either the preselected date or today
        updateTmdlHeader(headerHtml, $elem.datepicker('getDate') || new Date());

        //We can not use the datepicker's onSelect method because it seems to override the date
        //actually being set which causes the rate field not being updated.
        $elem.on('change', function () {
            updateTmdlHeader(headerHtml, $elem.datepicker('getDate'));
        });

        $('.mdl-layout').addClass('datepickerVisible');

        //Prepending the "mdl header" after the datepicker is added to the DOM
        $('.ui-datepicker').prepend(headerHtml);

        //This event must be triggered after the "mdl header" is added
        $elem.datepicker('widget').trigger('tlxDatepickerVisible');

        //We need to recalculate the position of the datepicker after
        //the "mdl header" has been added
        calculatePosition($elem);

        window.setTimeout(function () {
            $elem.datepicker('widget').scrollIntoView();
        }, 50);

        //Prevent keyboard from popping up
        if (window.narrowScreen) {
            $elem.blur();
        }

        //It appears that the datepicker is "redrawn" every time you click on
        //one of the prev or next buttons. We must therefore add the header
        //each time one of these are clicked.
        $($elem.datepicker).on(
            'click',
            '.ui-datepicker-next, .ui-datepicker-prev ',
            function () {
                const $header = $elem
                    .datepicker('widget')
                    .find('.ui-datepicker-tmdl-header');
                if ($header.length === 0) {
                    $elem.datepicker('widget').prepend(headerHtml);
                } else {
                    updateTmdlHeader(
                        $header,
                        $elem.datepicker('getDate') || new Date()
                    );
                }

                //Prevent keyboard from popping up
                if (window.narrowScreen) {
                    $elem.blur();
                }
            }
        );
    }

    parseAndSetDate(event: React.FocusEvent<HTMLInputElement>) {
        if (this.props.disabled) {
            return;
        }

        if (!this.inputElement.current) {
            return;
        }

        const $elem: any = $(this.inputElement.current);
        const setDate = (date: Date) => {
            $elem.datepicker('setDate', date);
            // We need to update the headers again.
            updateTmdlHeader(headerHtml, $elem.datepicker('getDate'));
            $('.ui-datepicker').prepend(headerHtml);
        };
        const parsedDate = dateUtil.parseDate(
            this.inputElement.current.value,
            true
        );
        if (
            parsedDate &&
            !dateUtil.isDate(this.inputElement.current.value, 'yyyy-MM-dd') &&
            !$('.ui-datepicker-tmdl-header').is(':visible')
        ) {
            setDate(parsedDate);
        }

        if (this.props.onBlur) {
            this.props.onBlur(event);
        }

        if (this.props.onChange) {
            this.props.onChange(event);
        }
    }

    blur() {
        this.inputElement.current?.blur();
    }

    render() {
        return (
            <FormGroup
                invalid={false}
                helpText={this.props.helpText}
                validationMessage={this.props.validationMessage}
                className={this.props.formGroupClassName}
                dense={this.props.dense}
            >
                <TextFieldSimple
                    icon={
                        <ButtonIcon
                            onClick={this.show}
                            icon="date_range"
                            disabled={this.props.disabled}
                            className="tlx-textfield__icon"
                            aria-label={getMessage('text_select_date')}
                        />
                    }
                    {...this.props}
                    onFocus={this.initDatePicker}
                    onKeyDown={this.handleKeyBoardShortcuts}
                    onBlur={this.parseAndSetDate}
                    inputRef={this.inputElement}
                    disabled={this.props.disabled}
                    readOnly={this.props.readOnly}
                    required={this.props.required}
                />
            </FormGroup>
        );
    }
}
