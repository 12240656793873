import { useEffect, useRef, useState } from 'react';

// Warning: This hook belongs to sheetstorm and should not be used for features owned by other teams
export function useResizeObserver<E extends Element = Element>() {
    const [elementRef, setElementRef] = useState<E | null>(null);
    const [size, setSize] = useState<DOMRect | undefined>(undefined);
    const resizeObserver = useRef(new window.ResizeObserver(handleResize));

    useEffect(() => {
        const observer = resizeObserver.current;
        const element = elementRef;

        if (element !== null) {
            observer.observe(element);
        }

        return () => {
            if (element !== null) {
                observer.unobserve(element);
            }
        };
    }, [elementRef]);

    function handleResize(elements: ResizeObserverEntry[]) {
        const element = elements[0];
        setSize(element.contentRect);
    }

    return { ref: setElementRef, size };
}
