import * as React from 'react';

import { stringifyError } from '@General/JsonUtil';

export interface PageLoadingErrorProps {
    readonly error?: unknown;
    readonly errorInfo?: React.ErrorInfo;
    readonly children?: never;
    readonly requestId?: string;
}

/**
 * PageLoadingError
 */
export const PageError: React.FC<PageLoadingErrorProps> = (props) => (
    <div className="empty-page">
        <div className="empty-form">
            <i className="material-icons empty-page-icon">error</i>
            <h1 className="empty-page-title">
                {getMessage('heading_unexpected_error')}
            </h1>
            <div className="empty-page-text">
                <p>
                    {getMessage(
                        props.requestId
                            ? 'validation_unexpected_error_with_reference'
                            : 'validation_unexpected_error'
                    )}
                </p>
            </div>
        </div>
        {props.requestId ? (
            <div className="error-page">
                <div className="error-page-request">
                    <div className="error-page-request__label noselect">
                        {getMessage('text_reference')}
                    </div>
                    <div className="error-page-request__id allselect">
                        {props.requestId}
                    </div>
                </div>
                <a
                    className="atl-button atl-button--primary"
                    href="https://hjelp.tripletex.no/hc/no/p/kontakt_oss"
                    target="_blank"
                >
                    {getMessage('text_tripletex_support_link')}
                </a>
            </div>
        ) : null}
        {!window.PRODUCTION_MODE && props.error ? (
            <pre hidden>
                {stringifyError(props.error)}
                {props.errorInfo?.componentStack ?? null}
            </pre>
        ) : null}
    </div>
);
