import React, { useMemo } from 'react';
import { Signer, SignerCombination } from '@Page/CompanyVerificationFlow/Types';
import { ApprovedIcon, Chip, ChipText, Group, Label } from '@tlx/atlas';
import { useFormContext } from 'react-hook-form';
import { SendSigningRequestFormValues } from '@Page/CompanyVerificationFlow/SendSigningRequestForm/SendSigningRequestForm';
import { validateEmailList } from '../../../../util/validateEmail';

export type SignerCombinationsProps = {
    signerCombinations: SignerCombination[];
};

export type SignerCombinationsChipsProps = {
    signerCombinations: SignerCombination[];
};

export function useUniqueSigners(signerCombinations: SignerCombination[]) {
    return useMemo(() => {
        const signersList = signerCombinations
            .map((combination) => {
                return combination.companyRepresentativeDTOList;
            })
            .flatMap((signer) => signer);
        return removeDuplicates(signersList);
    }, [signerCombinations]);
}

export function SignerCombinationsChips({
    signerCombinations,
}: SignerCombinationsChipsProps) {
    const { watch } = useFormContext<SendSigningRequestFormValues>();
    const signers = watch('signers');
    const thisIsMeSigner = watch('thisIsMeSigner');
    const validSigners = new Set(
        Object.values(signers)
            .filter(
                (signer) =>
                    validateEmailList(signer.email, false) === undefined ||
                    signer.signatureStatus !== 'NOT_SIGNED'
            )
            .map((signer) => signer.id)
    );

    if (thisIsMeSigner !== undefined) {
        validSigners.add(thisIsMeSigner.id);
    }

    const stylingColorCompletedCombination =
        'atl-border-blue-40 atl-bg-blue-10';

    const combinationDescriptionMap =
        useUniqueCombinationsMap(signerCombinations);

    const uniqueCombinations = combinationDescriptionMap.keys();
    return (
        <Group className="atl-flex atl-flex-wrap atl-gap-12 atl-mb-24 atl-mt-12">
            {[...uniqueCombinations].map((combination) => {
                const signerCombinationsForKey =
                    combinationDescriptionMap.get(combination);
                const isCompletedCombination =
                    signerCombinationsForKey?.some((signerCombination) => {
                        return signerCombination.every((signer) => {
                            return validSigners.has(signer.id);
                        });
                    }) ?? false;

                const styling = isCompletedCombination
                    ? stylingColorCompletedCombination
                    : 'atl-border-grey-40';

                return (
                    <Chip
                        className={`atl-border atl-text-sm atl-box-border ${styling}`}
                        key={combination}
                    >
                        {isCompletedCombination && (
                            <ApprovedIcon
                                className={'atl-ml-8 atl-text-blue-100'}
                                size={'small'}
                            />
                        )}
                        <ChipText>{combination}</ChipText>
                    </Chip>
                );
            })}
        </Group>
    );
}

function removeDuplicates(signerList: Signer[]) {
    const uniqueSigners = new Set();
    const filteredSigners = [] as Signer[];

    signerList.forEach((signer) => {
        // Define a unique key based on the properties that determine uniqueness
        const uniqueKey = `${signer.id}`;

        if (!uniqueSigners.has(uniqueKey)) {
            uniqueSigners.add(uniqueKey);
            filteredSigners.push(signer);
        }
    });

    return filteredSigners;
}

function useUniqueCombinationsMap(signerCombinations: SignerCombination[]) {
    return useMemo(() => {
        const combinationDescriptionMap = new Map<string, Signer[][]>();
        signerCombinations.forEach((combination) => {
            if (
                !combinationDescriptionMap.has(
                    combination.signatureCombinationDescription
                )
            ) {
                combinationDescriptionMap.set(
                    combination.signatureCombinationDescription,
                    [combination.companyRepresentativeDTOList]
                );
            } else {
                combinationDescriptionMap
                    .get(combination.signatureCombinationDescription)
                    ?.push(combination.companyRepresentativeDTOList);
            }
        });
        return combinationDescriptionMap;
    }, [signerCombinations]);
}

export function SignerCombinations({
    signerCombinations,
}: SignerCombinationsProps) {
    return (
        <Group className="atl-mb-32">
            <Label id="combinationSelectLabel" htmlFor={'combination'}>
                {getMessage('text_signature_combinations')}
                &nbsp;
                <i>{getMessage('text_signature_combinations_disclaimer')}</i>
            </Label>
            <SignerCombinationsChips signerCombinations={signerCombinations} />
        </Group>
    );
}
