import React, { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js/auto';
import {
    ChartArea,
    ChartData,
    ChartOptions,
    Color,
    LineHoverOptions,
    LineOptions,
    PointStyle,
    TextAlign,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import './plugins';
import { formatAxisMultipleDigits } from '@Component/Charts/common';
import { format } from '../../../../js/modules/format';
import { DeepPartial } from 'chart.js/types/utils';

Chart.unregister(ChartDataLabels);

const DEFAULT_CHART_HEIGHT = 150;

export type LineChartData = {
    value: number;
    color: Color;
    label: string;
    pointTooltip?: string;
    point: Point;
};

export type TooltipConfig = {
    titleMarginBottom: number;
    titleFontStyle?: 'normal' | 'italic' | 'oblique' | 'initial' | 'inherit';
    titleFontColor: string;

    footerText: string;
    footerFontStyle?: 'normal' | 'italic' | 'oblique' | 'initial' | 'inherit';
    footerFontColor: string;
    footerMarginTop: number;

    bodyFontColor: string;
    bodyAlign?: TextAlign;
    displayColors: boolean;
};

export type LineChartDataset = {
    label: string;
    data: Array<LineChartData>;
};

export type LineChartProps = {
    height?: number;
    datasets: Array<LineChartDataset>;
    stacked?: boolean;
    layout?: {
        padding?: number | Partial<ChartArea>;
    };
    hideLegend?: boolean;
    hidePointLabels?: boolean;
    lineStyle?: DeepPartial<LineOptions & LineHoverOptions>;
    steppedLine?: SteppedLineType;
    fillChart?: boolean;
    fillChartColor?: Color;
    tooltipConfig?: TooltipConfig;
    beginAtZero?: boolean;
};

export enum SteppedLineType {
    Before = 'before',
    Middle = 'middle',
    After = 'after',
}

export type Point = {
    pointStyle: PointStyle;
    pointRadius: number;
    pointBackgroundColor?: Color;
};

export enum PointStyleEnum {
    Circle = 'circle',
    Triangle = 'triangle',
    Star = 'star',
    Rect = 'rect',
    Cross = 'cross',
    CrossRot = 'crossRot',
    Line = 'line',
    Dash = 'dash',
}

function createDatasets(props: LineChartProps): ChartData<'line'> {
    return {
        labels: props.datasets
            .map((dataset) => dataset.data.map((data) => data.label))
            .flat(),
        datasets: props.datasets.map((dataset) => ({
            data: dataset.data.map((d) => d.value),
            pointBorderColor: dataset.data.map(
                (d) => d.point.pointBackgroundColor ?? d.color
            ),
            steppedLine: props.steppedLine ?? false,
            pointRadius: dataset.data.map((d) => d.point.pointRadius),
            pointStyle: dataset.data.map((d) => d.point.pointStyle),
            fill: props.fillChart ?? false,
            customTooltips: dataset.data.map((d) => d.pointTooltip),
            backgroundColor: props.fillChartColor ?? undefined,
        })),
    };
}

function createOptions(props: LineChartProps): ChartOptions<'line'> {
    const options: ChartOptions<'line'> = {
        responsive: true,
        maintainAspectRatio: false,
        elements: {
            line: props.lineStyle ?? {},
        },
        scales: {
            yAxes: {
                beginAtZero: props.beginAtZero ?? false,
                ticks: {
                    callback: formatAxisMultipleDigits,
                },
                stacked: props.stacked ?? false,
            },
            xAxes: {
                stacked: props.stacked ?? false,
            },
        },
        layout: {
            padding: props.layout
                ? props.layout.padding
                : {
                      top: 20,
                  },
        },
        plugins: {
            datalabels: {
                formatter: (value: any) => {
                    return format.amount(value);
                },
                anchor: 'end',
                align: 'end',
            },
            legend: {
                position: 'bottom',
                display: props.hideLegend !== true,
            },
            tooltip: {
                titleFont: {
                    style: props.tooltipConfig?.titleFontStyle ?? 'normal',
                },
                footerFont: {
                    style: props.tooltipConfig?.titleFontStyle ?? 'normal',
                },
                bodyAlign: props.tooltipConfig?.bodyAlign ?? 'center',

                displayColors: props.tooltipConfig?.displayColors ?? true,
                bodyColor: props.tooltipConfig?.bodyFontColor ?? 'white',
                titleColor: props.tooltipConfig?.titleFontColor ?? 'white',
                titleMarginBottom: props.tooltipConfig?.titleMarginBottom ?? 0,
                footerColor: props.tooltipConfig?.footerFontColor ?? 'white',
                footerMarginTop: props.tooltipConfig?.footerMarginTop ?? 0,

                callbacks: {
                    title(item): string | string[] {
                        return item[0].label;
                    },
                    footer(item): string | string[] {
                        if (props.tooltipConfig?.footerText) {
                            return props.tooltipConfig.footerText.concat(
                                format.amount(item[0].formattedValue.toString())
                            );
                        } else {
                            return item[0].formattedValue.toString();
                        }
                    },
                    label: function formatLabel(tooltipItem) {
                        const label =
                            // @ts-expect-error FIXME Custom dataset value customTooltips has no type yet
                            tooltipItem.dataset.customTooltips[
                                tooltipItem.dataIndex
                            ] ?? '';
                        const lines = label.split('\n');
                        // as the labels are separated by "\n",
                        // the last element from split will always be an empty string, so just remove it
                        lines.pop();
                        return lines;
                    },
                },
            },
        },
    };

    return options;
}

export const LineChart: React.FC<LineChartProps> = (props: LineChartProps) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [chart, setChart] = useState<Chart<'line'> | null>(null);

    useEffect(() => {
        if (canvasRef.current == null) {
            return;
        }
        if (chart == null) {
            setChart(
                new Chart(canvasRef.current, {
                    plugins: !props.hidePointLabels ? [ChartDataLabels] : [],
                    type: 'line',
                    data: createDatasets(props),
                    options: createOptions(props),
                })
            );
        } else {
            chart.data = createDatasets(props);
            chart.update();
        }
    });

    return (
        <div style={{ height: props.height ?? DEFAULT_CHART_HEIGHT }}>
            <canvas
                ref={canvasRef}
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                }}
            />
        </div>
    );
};
