export function resultMemorizer<T>(source: () => T): {
    (): T;
    clear(): void;
};

export function resultMemorizer<T, A>(
    source: (a: A) => T
): {
    (a: A): T;
    clear(): void;
};

export function resultMemorizer<T, A, B>(
    source: (a: A, b: B) => T
): {
    (a: A, b: B): T;
    clear(): void;
};

export function resultMemorizer<T, A, B, C>(
    source: (a: A, b: B, c: C) => T
): {
    (a: A, b: B, c: C): T;
    clear(): void;
};

export function resultMemorizer<T, A, B, C, D>(
    source: (a: A, b: B, c: C, d: D) => T
): {
    (a: A, b: B, c: C, d: D): T;
    clear(): void;
};

/**
 * Remembers the last result of the call to the method.
 * If the wrapper method is called with different parameters then the source function is called.
 *
 * The method `clear` on the returned function allows for clearing the cache.
 *
 * <pre>
 * function costlyOperation(a: string, b: number): object {
 *     //expensive operation
 * };
 * const cachedOperation = resultMemorizer(costlyOperation);
 * const result1 = cachedOperation('hello', 42);
 * const result2 = cachedOperation('hello', 42);
 * result1 === result2; // true
 * </pre>
 *
 *
 * @param source The source of the result to remember.
 *
 * @return A function wrapping the source function.
 *
 * @author tellef
 * @date 2019-04-02
 */
export function resultMemorizer<T>(
    source: (...args: Array<unknown>) => T
): Memorizer<T> {
    let previousArgs: Array<unknown> | undefined;
    let result: T;

    const memorizer = (...args: Array<unknown>): T => {
        if (
            previousArgs === undefined ||
            !previousArgs.reduce(
                (previousValue, currentValue, currentIndex) =>
                    previousValue && currentValue === args[currentIndex],
                true
            )
        ) {
            previousArgs = args;
            result = source(...args);
        }

        return result;
    };

    memorizer.clear = function clear() {
        previousArgs = undefined;
    };

    return memorizer;
}

interface Memorizer<T> {
    (...args: Array<unknown>): T;
    clear(): void;
}

export function isFilled<Value, Empty extends Value>(
    value: null | undefined | Value | Empty,
    emptyValue: Empty
): value is NonNullable<Exclude<Value, Empty>> {
    return value !== null && value !== undefined && value !== emptyValue;
}

export function notUndefined<T>(x: T | undefined): x is T {
    return x !== undefined;
}

export function notNull<T>(x: T | null): x is T {
    return x !== null;
}

export function isDefined<T>(x: T | null | undefined): x is T {
    return x !== undefined && x !== null;
}

export function isString(x: unknown): x is string {
    return typeof x === 'string';
}

/**
 * Remembers all permutation of the input.
 *
 * NOTE: use with care, misuse can lead to memory leaks.
 *
 * @param source
 *
 * @author tellef
 * @date 2019-10-10
 */
export function resultMapMemorizer<
    T,
    A extends string | number | boolean | null | undefined
>(source: (a: A) => T): MapMemorizer<T, A> {
    let resultMap: { [key: string]: T } = {};

    const factory = (arg: A): T => {
        if (resultMap[`${arg}`] === undefined) {
            resultMap[`${arg}`] = source(arg);
        }

        return resultMap[`${arg}`];
    };

    factory.has = function has(arg: A): boolean {
        return resultMap[`${arg}`] !== undefined;
    };

    factory.clear = function clear() {
        resultMap = {};
    };

    return factory;
}

interface MapMemorizer<
    T,
    A extends string | number | boolean | null | undefined
> {
    (arg: A): T;
    has(arg: A): boolean;
    clear(): void;
}

/**
 * Check if element is editable. Handy for preventing global keydown eventhandlers to act when editable HTMLElement has
 * focus.
 *
 * @auth bruce
 */
export function isEditable(element: HTMLElement): boolean {
    const nodeName = element.nodeName.toLowerCase();
    return (
        nodeName === 'input' ||
        nodeName === 'textarea' ||
        element.getAttribute('contenteditable') === 'true' ||
        element.getAttribute('role') === 'listbox'
    );
}

/**
 * Transforms the given array to a map.
 *
 * @param target       The array to transform
 * @param keyExtractor The key extractor
 *
 * @author tellef
 * @date 2021-09-24
 */
export function toMap<T, K extends number | string>(
    target: Array<T>,
    keyExtractor: (value: T) => K
): Record<K, T> {
    return target.reduce(
        (p, c) => ({ ...p, [keyExtractor(c)]: c }),
        {} as Record<K, T>
    );
}

/**
 * Transforms the given array to a map. Allows for transforming the value at the same time.
 *
 * @param target         The array to transform
 * @param keyExtractor   The key extractor
 * @param valueExtractor The value extractor
 *
 * @author tellef
 * @date 2023-11-06
 */
export function toMapExt<T, R extends T, K extends number | string>(
    target: Array<T>,
    keyExtractor: (value: T) => K,
    valueExtractor: (value: T) => R
): Record<K, R> {
    return target.reduce(
        (p, c) => ({ ...p, [keyExtractor(c)]: valueExtractor(c) }),
        {} as Record<K, R>
    );
}

/**
 * Transforms the given array to a map, merging overlapping values.
 *
 * @param target               The array to transform
 * @param keyExtractor         The key extractor
 * @param merger               The value merger
 * @param initialValueProvider The generator for initial values to be used by merger
 *
 * @author tellef
 * @date 2021-11-03
 */
export function toMapMerge<T, R, K extends number | string>(
    target: Array<T>,
    keyExtractor: (value: T) => K,
    merger: (current: T, previous: R) => R,
    initialValueProvider: () => R
): Record<K, R> {
    return target.reduce((p, c) => {
        const key = keyExtractor(c);
        const previous = p[key] ?? initialValueProvider();
        return { ...p, [key]: merger(c, previous) };
    }, {} as Record<K, R>);
}

/**
 * Transposes the given matrix.
 *
 * @param original The matrix to transpose
 * @return The transposed matrix
 *
 * @author tellef
 * @date 2022-03-08
 */
export function transpose<T>(original: T[][]): T[][] {
    if (original.length === 0) {
        return [];
    }

    const width = original.length;
    const height = original[0].length;

    return Array.from(new Array(height), (_, x) =>
        Array.from(new Array(width), (_, y) => original[y][x])
    );
}
