import {
    Dropdown,
    DropdownChips,
    DropdownDrawer,
    DropdownEmpty,
    DropdownProps,
    DropdownScrollContainer,
    DropdownSearchOpener,
    Option,
    SkeletonOption,
    useDropdownLoadMoreTarget,
    useDropdownOptions,
    useDropdownRegisterOptions,
    useDropdownSearchQuery,
    useDropdownSelectedValues,
} from '@tlx/atlas';
import { forwardRef, ReactNode, useEffect } from 'react';
import useSWR from 'swr';
import useSWRInfinite, { SWRInfiniteKeyLoader } from 'swr/infinite';
import { APIError, HTTPError } from '../../hooks/fetch/createAPIResponse';
import { defaultFetcher } from '../../hooks/fetch/defaultFetcher';
import { ListResponse } from '../../hooks/fetch/types';
import { useFetchPaginatedState } from '../../hooks/fetch/useFetchPaginatedState';
import { useDebouncedQuery } from '../../hooks/useDebouncedQuery';

const pageSize = 25;

// We have to use Omit here because the @deprecated decorator carries over from DropdownProps
export type LoadableDropdownProps = Omit<DropdownProps, 'className'> &
    LoadableDropdownSearchOpenerProps;

export type FetcherError = APIError | HTTPError;

/**
 * @deprecated Use `AsyncDropdownOptions` from `@tlx/astro-shared` instead. See https://github.com/Tripletex-AS/astro-shared#asyncdropdownoptions for documentation.
 */
export const LoadableDropdown = forwardRef<
    HTMLSelectElement,
    LoadableDropdownProps
>(function LoadableDropdown(
    { url, placeholder, children, multiple, className, ...props },
    ref
) {
    return (
        <Dropdown ref={ref} multiple={multiple} {...props}>
            <LoadableDropdownSearchOpener
                url={url}
                placeholder={placeholder}
                className={className}
            />
            <DropdownDrawer>
                <DropdownScrollContainer>
                    <LoadableDropdownOptions url={url}>
                        {children}
                    </LoadableDropdownOptions>
                </DropdownScrollContainer>
            </DropdownDrawer>
            {multiple ? <LoadableDropdownChips /> : null}
        </Dropdown>
    );
});

export type LoadableDropdownSearchOpenerProps = {
    url: string;
    placeholder?: string;
    className?: string;
};

export function LoadableDropdownSearchOpener({
    url,
    placeholder,
    className,
}: LoadableDropdownSearchOpenerProps) {
    useLoadableDropdownSelectedOptions(url);

    return (
        <DropdownSearchOpener placeholder={placeholder} className={className} />
    );
}

export function LoadableDropdownOptions({
    url,
    children,
}: {
    url: string;
    children?: ReactNode;
}) {
    const { data, isEmpty, isLoading, hasMore, loadMore } =
        useLoadableDropdownOptions(url);
    const query = useDropdownSearchQuery();
    const loadMoreRef = useDropdownLoadMoreTarget<HTMLDivElement>(loadMore);
    return (
        <>
            {query === '' ? children : null}
            {data.map((item) => (
                <Option key={item.id} value={item.id}>
                    {item.displayName}
                </Option>
            ))}
            {isEmpty ? <LoadableDropdownEmpty /> : null}
            {isLoading ? <LoadableDropdownSkeletonOptions /> : null}
            {hasMore ? <div ref={loadMoreRef} /> : null}
        </>
    );
}

export function LoadableDropdownChips() {
    return (
        <div className="atl-flex atl-flex-wrap atl-mt-8 atl-gap-4">
            <DropdownChips />
        </div>
    );
}

export function LoadableDropdownSkeletonOptions() {
    return (
        <>
            <SkeletonOption />
            <SkeletonOption />
            <SkeletonOption />
        </>
    );
}

export function LoadableDropdownEmpty() {
    return <DropdownEmpty>{getMessage('text_no_results')}</DropdownEmpty>;
}

export function useLoadableDropdownSelectedOptions<
    T extends { id: number; displayName: string }
>(baseUrl: string) {
    const registerOptions = useDropdownRegisterOptions();
    const unregisteredSelectedValues = useUnregisteredSelectedValues();
    const url = getSelectedOptionsUrl(baseUrl, unregisteredSelectedValues);
    const response = useSWR<ListResponse<T>, FetcherError>(
        url,
        defaultFetcher,
        {
            revalidateOnFocus: false,
        }
    );
    const selectedOptions = response.data?.values;

    useEffect(() => {
        if (selectedOptions === undefined || selectedOptions.length === 0) {
            return;
        }

        const options = selectedOptions.map((item) => ({
            value: String(item.id),
            displayName: item.displayName,
        }));

        registerOptions(options);
    }, [registerOptions, selectedOptions]);
}

/**
 * The first time a dropdown is mounted, it will fetch
 * names of selected values. This should however not
 * happen after opening the dropdown again and selecting
 * different values. This is why we use this hook to
 * prevent the dropdown from fetching names of selected
 * values we already know about.
 */
function useUnregisteredSelectedValues() {
    const options = useDropdownOptions();
    const [selectedValues] = useDropdownSelectedValues();

    return selectedValues.filter((value) => {
        return !options.some((option) => option.value === value);
    });
}

type UseLoadableDropdownOptionsReturn<T> = {
    data: T[];
    isEmpty: boolean;
    isLoading: boolean;
    hasMore: boolean;
    loadMore: () => void;
};

export function useLoadableDropdownOptions<
    T extends { id: number; displayName: string }
>(baseUrl: string): UseLoadableDropdownOptionsReturn<T> {
    const query = useDropdownSearchQuery();
    const debouncedQuery = useDebouncedQuery(query, 250);
    const getKey: SWRInfiniteKeyLoader = (pageIndex) =>
        getOptionsUrl(baseUrl, debouncedQuery, pageIndex);

    const response = useSWRInfinite<ListResponse<T>, FetcherError>(
        getKey,
        defaultFetcher,
        {
            fallbackData: [],
            initialSize: 0,
            revalidateFirstPage: false,
            revalidateOnFocus: false,
        }
    );

    const state = useFetchPaginatedState(response);

    /**
     * Because we are using an IntersectionObserver to trigger loading of
     * options, the drawer would be empty immediately after opening
     * unless we assume that we are loading when size is zero.
     */
    return {
        ...state,
        isLoading: state.isLoading || response.size === 0,
    };
}

export function getSelectedOptionsUrl(
    baseUrl: string,
    values: readonly string[]
): string | null {
    // Only fetch valid IDs
    const ids = values.filter((value) => Number(value) > 0);

    if (ids.length === 0) {
        return null;
    }

    const url = new URL(baseUrl, window.origin);

    // Don't overwrite fields param if it exists
    if (!url.searchParams.has('fields')) {
        url.searchParams.set('fields', 'id,displayName');
    }

    // Remove duplicate slashes
    url.pathname = url.pathname.replace(/\/{2,}/, '/');
    url.searchParams.set('id', ids.join(','));

    return url.toString();
}

export function getOptionsUrl(
    baseUrl: string,
    query: string,
    pageIndex: number
): string {
    const url = new URL(baseUrl, window.origin);

    // Don't overwrite fields param if it exists
    if (!url.searchParams.has('fields')) {
        url.searchParams.set('fields', 'id,displayName');
    }

    if (query.length > 0) {
        url.searchParams.set('query', query);
    }

    url.searchParams.set('count', pageSize.toString());
    url.searchParams.set('from', (pageIndex * pageSize).toString());

    return url.toString();
}
