import { ApiUrl } from '@Page/LogisticsV2/utils/enums';
import { getIdFromUrl, getURL } from '@Page/LogisticsV2/utils/functions';
import { mapObjectToURLSearchParams } from '@Page/LogisticsV2/utils/mappers';
import {
    AsyncDropdownOptions,
    DropdownFilter,
    Filters,
    SearchFilter,
} from '@tlx/astro-shared';
import { Option } from '@tlx/atlas';
import { ALL_STATUSES } from './types';
import { useExclusiveOption } from '@Page/LogisticsV2/hooks/useExclusiveOption';

export function PurchaseOrderOverviewFilters() {
    const supplierId = document.querySelector<HTMLInputElement>(
        '#customerIdForProductOverview'
    )?.value;
    const projectId = getIdFromUrl('projectId');

    const availableStatuses = [
        { id: '0', displayName: 'text_purchase_order_status_open' },
        { id: '1', displayName: 'text_purchase_order_status_sent' },
        { id: '8', displayName: 'text_purchase_order_status_confirmed' },
        moduleLogistics
            ? {
                  id: '2',
                  displayName: 'text_purchase_order_status_receiving',
              }
            : { id: '', displayName: '' },
        { id: '6', displayName: 'text_closed' },
        { id: '7', displayName: 'text_is_cancelled' },
        { id: '3', displayName: 'text_deviation_on_purchase_order' },
    ];

    return (
        <Filters>
            <SearchFilter
                data-testid={'purchase-order-search'}
                label={getMessage('text_search')}
                name={'query'}
                placeholder={getMessage('text_search')}
                quick
            />
            <DropdownFilter
                data-testid="purchase-order-status-filter"
                name="status"
                label=""
                multiple
                quick
                defaultDisplayName={getMessage('text_all_statuses')}
            >
                <AllStatusOption />
                <>
                    {availableStatuses.map((status) => {
                        if (status.id !== '') {
                            return (
                                <Option key={status.id} value={status.id}>
                                    {getMessage(status.displayName)}
                                </Option>
                            );
                        } else {
                            return null;
                        }
                    })}
                </>
            </DropdownFilter>
            {!supplierId && (
                <DropdownFilter
                    data-testid="purchase-order-supplier-filter"
                    defaultDisplayName={getMessage('text_all')}
                    label={getMessage('text_supplier')}
                    name="supplier"
                    multiple
                >
                    <></>
                    <AsyncDropdownOptions
                        url={
                            getURL(
                                ApiUrl.SUPPLIER_QUERY,
                                mapObjectToURLSearchParams({
                                    count: 10000,
                                })
                            ) ?? ''
                        }
                    />
                </DropdownFilter>
            )}
            {moduleProject && !projectId && (
                <DropdownFilter
                    data-testid="purchase-order-project-filter"
                    defaultDisplayName={getMessage('text_all')}
                    label={getMessage('text_project')}
                    name="project"
                    multiple
                >
                    <></>
                    <AsyncDropdownOptions
                        url={
                            getURL(
                                ApiUrl.PROJECT_QUERY,
                                mapObjectToURLSearchParams({
                                    count: 10000,
                                })
                            ) ?? ''
                        }
                    />
                </DropdownFilter>
            )}
            <DropdownFilter
                data-testid="purchase-order-order-filter"
                defaultDisplayName={getMessage('text_all')}
                label={getMessage('text_order')}
                name="order"
                multiple
            >
                <></>
                <AsyncDropdownOptions
                    url={
                        getURL(
                            ApiUrl.ORDER_QUERY,
                            mapObjectToURLSearchParams({
                                count: 10000,
                            })
                        ) ?? ''
                    }
                />
            </DropdownFilter>
            <DropdownFilter
                data-testid="purchase-order-supplier-products-filter"
                defaultDisplayName={getMessage('text_all')}
                label={getMessage('text_products')}
                name="supplierProducts"
                multiple
            >
                <></>
                <AsyncDropdownOptions url={ApiUrl.SUPPLIER_CONNECTION} />
            </DropdownFilter>
        </Filters>
    );
}

function AllStatusOption() {
    useExclusiveOption(ALL_STATUSES);

    return (
        <Option value={ALL_STATUSES} data-testid="purchase-order-all-statuses">
            {getMessage('text_all_statuses')}
        </Option>
    );
}
