import React from 'react';

import { PageLoader } from '@Component/PageLoader';
import { RouteData } from '@General/Router';
import { Team } from '@General/Team';
import { renderComponent } from '@General/renderComponent';

const Page: React.FC = (props) => (
    <PageLoader<{}>
        loader={async () => import('./UserLicense').then((m) => m.page)}
        props={props}
    />
);

export const route: RouteData = {
    component: Page,
    path: '/execute/listUserLicense',
    title: () => getMessage('text_user_licenses'),
    team: Team.BEEHIVE,
    documentationComponent: undefined,
    auth: () => listUserLicenseForm,
};

export function renderUserLicensePage(domId: string) {
    renderComponent(Page, domId, {});
}
