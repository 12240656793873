import * as React from 'react';
import { clsx } from 'clsx';

import './Skeleton.css';

export type SkeletonProps = React.HTMLAttributes<HTMLDivElement>;

/**
 * Skeleton
 */
export const Skeleton: React.FC<SkeletonProps> = ({
    children,
    className,
    ...divProps
}) => (
    <div className={clsx('tlx-ds-skeleton', className)} {...divProps}>
        {children}
    </div>
);
