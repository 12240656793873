import { EmptyState } from '@Page/LogisticsV2/components/EmptyState/EmptyState';
import React, { ChangeEvent, useState } from 'react';
import {
    Alert,
    AlertContent,
    AlertDescription,
    AlertHeader,
    ArrowBackIcon,
    ArrowForwardIcon,
    Button,
    Dropdown,
    DropdownDrawer,
    DropdownOpener,
    DropdownScrollContainer,
    Group,
    Label,
    Modal,
    ModalCloseButton,
    ModalTitle,
    Option,
} from '@tlx/atlas';
import { nav } from '../../../../../../../js/modules/navigation';
import { PurchaseOrderDTO } from '@Page/LogisticsV2/pages/PurchaseOrder/overview/types';
import { getSvgIcon } from '@Page/LogisticsV2/utils/svgIcons';
import '../style.css';

export const PurchaseOrderOverviewEmpty = ({
    colSpan,
}: {
    colSpan: number;
}) => {
    return (
        <tr>
            <td className="atl-bg-white" colSpan={colSpan}>
                <EmptyState
                    title={getMessage('text_purchase_order_empty_title')}
                    description={getMessage(
                        'text_purchase_order_empty_description'
                    )}
                    className={'atl-text-xl atl-whitespace-nowrap'}
                    svgIcon={'generalEmptyState'}
                >
                    <div>
                        {!moduleRackbeat && (
                            <Button
                                data-testid="new-purchase-order"
                                className="atl-text-center"
                                variant="secondary"
                                onClick={() =>
                                    nav.nav(
                                        addContextId('purchaseOrderMenu?type=2')
                                    )
                                }
                            >
                                {getMessage('text_new_purchase_order_long')}
                            </Button>
                        )}
                    </div>
                </EmptyState>
            </td>
        </tr>
    );
};

export function ChangeStatusModal({
    selectedLines,
    validLines,
    onClose,
    onChangeStatus,
}: {
    selectedLines: PurchaseOrderDTO[];
    validLines: PurchaseOrderDTO[];
    onClose: () => void;
    onChangeStatus: (
        selectedPurchaseOrders: PurchaseOrderDTO[],
        status: string
    ) => void;
}) {
    const [showWarning, setShowWarning] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState('');
    const handleStatusChange = (event: ChangeEvent<HTMLSelectElement>) => {
        setSelectedStatus(event.currentTarget.value);
    };

    const availableStatusesForChange = moduleRackbeat
        ? [
              { id: '0', displayName: 'text_purchase_order_status_open' },
              { id: '6', displayName: 'text_closed' },
          ]
        : [
              { id: '0', displayName: 'text_purchase_order_status_open' },
              { id: '1', displayName: 'text_purchase_order_status_sent' },
              { id: '8', displayName: 'text_purchase_order_status_confirmed' },
              { id: '6', displayName: 'text_closed' },
              { id: '7', displayName: 'text_is_cancelled' },
          ];

    return (
        <Modal
            open={true}
            onClose={onClose}
            className={'goldshark-purchase-order-overview__change-status-modal'}
        >
            {getSvgIcon('edit')}
            <ModalTitle>
                {getMessage('text_purchase_order_change_status')}
            </ModalTitle>
            <ModalCloseButton />
            <Group className="goldshark-purchase-order-overview__dropdown">
                <Label>{getMessage('text_status')}</Label>
                <Dropdown
                    data-testid="purchase-order-status-filter"
                    value={selectedStatus}
                    defaultDisplayName={getMessage('option_not_chosen')}
                    onChange={(event) => handleStatusChange(event)}
                >
                    <DropdownOpener />
                    <DropdownDrawer>
                        <DropdownScrollContainer>
                            {availableStatusesForChange.map((status) => {
                                if (status.id !== '') {
                                    return (
                                        <Option
                                            value={status.id}
                                            key={status.id}
                                        >
                                            {getMessage(status.displayName)}
                                        </Option>
                                    );
                                } else {
                                    return;
                                }
                            })}
                        </DropdownScrollContainer>
                    </DropdownDrawer>
                </Dropdown>
            </Group>
            {showWarning && (
                <Alert variant="warning">
                    <AlertContent>
                        <AlertHeader as={'h3'}>
                            {getMessage(
                                'text_purchase_order_change_status_warning_title'
                            )}
                        </AlertHeader>
                        <AlertDescription>
                            {getMessage(
                                'text_purchase_order_change_status_warning_description',
                                validLines.length,
                                selectedLines.length
                            )}
                        </AlertDescription>
                    </AlertContent>
                </Alert>
            )}
            <div className={'atl-flex atl-gap-8 atl-justify-between'}>
                <div>
                    {showWarning && (
                        <Button
                            data-testid={'goldshark-modal-cancel'}
                            variant={'secondary'}
                            onClick={() => setShowWarning(false)}
                        >
                            <ArrowBackIcon />
                            {getMessage('button_back')}
                        </Button>
                    )}
                </div>
                <div className={'atl-flex atl-gap-8'}>
                    <Button
                        data-testid={'goldshark-modal-cancel'}
                        variant="tertiary"
                        onClick={onClose}
                    >
                        {getMessage('button_cancel')}
                    </Button>
                    {!showWarning ? (
                        <Button
                            data-testid={'goldshark-modal-next'}
                            onClick={() => setShowWarning(true)}
                        >
                            {getMessage('text_next')}
                            <ArrowForwardIcon className={'atl-ml-8'} />
                        </Button>
                    ) : (
                        <Button
                            data-testid={'goldshark-modal-confirm'}
                            onClick={() => {
                                onChangeStatus(validLines, selectedStatus);
                                onClose();
                            }}
                        >
                            {getMessage('text_confirm')}
                        </Button>
                    )}
                </div>
            </div>
        </Modal>
    );
}
