import classNames from 'classnames';
import { Button, Icon } from '@tlx/atlas';

export function RackbeatProductNewsActions({
    className,
}: {
    className?: string;
}) {
    return (
        <div
            className={classNames(
                'atl-flex atl-gap-8 atl-items-center',
                className
            )}
        >
            <a href={'https://forms.gle/njJ12BaTgjXEE9r66'} target="_blank">
                <Button
                    data-testid="rackbeat_news_page_give_feedback_id"
                    className="atl-text-center"
                    variant="secondary"
                >
                    {getMessage('text_send_feedback')}
                    <Icon>drive_file_rename_outline</Icon>
                </Button>
            </a>
        </div>
    );
}
