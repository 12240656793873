import {
    DataTreeDTO,
    DataTreeNode,
} from '@Page/LogisticsV2/components/DataTree/types';
import { invokeAPI } from '@Page/LogisticsV2/hooks/api';
import { useEffect, useState } from 'react';
import {
    addTreeNode,
    getTree,
} from '@Page/LogisticsV2/components/DataTree/tree';
import { ApiUrl, MethodType } from '@Page/LogisticsV2/utils/enums';
import { postValidationMessages } from '@General/LegacyValidationPopup';
import {
    mapProductGroupDTOtoTreeData,
    mapTreeDataToProductGroupDTO,
} from '@Page/LogisticsV2/utils/mappers';
import { useProductGroup } from '@Page/LogisticsV2/hooks/useData';

export function useEditableProductGroups() {
    const { data: productGroupList, mutate } = useProductGroup({
        fields: '*, parentGroup(*)',
    });
    const [dataTree, setDataTree] = useState<DataTreeDTO[] | undefined>(
        undefined
    );
    useEffect(() => {
        if (productGroupList !== undefined) {
            setDataTree(mapProductGroupDTOtoTreeData(productGroupList));
        }
    }, [productGroupList]);
    const addNode = async (parentId: number | null) => {
        const newNodeId = -Date.now();
        setDataTree(addTreeNode(dataTree ?? [], parentId, newNodeId));
        setNewNodeId(newNodeId);
    };
    const [newNodeId, setNewNodeId] = useState<number | undefined>(undefined);
    const nodes = dataTree !== undefined ? getTree(dataTree) : undefined;
    const onUpdate = async (dataTreeNode: DataTreeNode) => {
        const productGroup = mapTreeDataToProductGroupDTO(dataTreeNode);
        if (productGroup.id <= 0) {
            productGroup.id = 0;
            setDataTree(
                dataTree?.map((treeItem) => {
                    if (treeItem.id === newNodeId) {
                        return { ...treeItem, name: productGroup.name };
                    }
                    return treeItem;
                })
            );
            const { error } = await invokeAPI(
                ApiUrl.PRODUCT_GROUP,
                MethodType.POST,
                JSON.stringify(productGroup)
            );
            if (error) {
                postValidationMessages(
                    getMessage('text_product_group_error_add')
                );
            }
        } else {
            const newValues = productGroupList?.map((object) => {
                if (object.id === productGroup.id) {
                    return { ...object, name: productGroup.name };
                }
                return object;
            });
            await mutate(newValues, false);
            const { error } = await invokeAPI(
                ApiUrl.PRODUCT_GROUP + '/' + productGroup.id,
                MethodType.PUT,
                JSON.stringify(productGroup)
            );
            if (error) {
                postValidationMessages(
                    getMessage('text_product_group_error_update')
                );
            }
        }
        await mutate();
    };
    const onDelete = async (id: number) => {
        if (productGroupList === undefined) {
            return;
        }
        const newValues = productGroupList?.filter((item) => item.id !== id);
        //update local cache to remove element from ui without hitting API
        await mutate(newValues, false);
        const { error } = await invokeAPI(
            ApiUrl.PRODUCT_GROUP_INTERNAL_DELETE + '/' + id,
            MethodType.DELETE
        );
        if (error) {
            postValidationMessages(
                getMessage('text_product_group_error_delete')
            );
        }
        //update local cache with API data
        await mutate();
    };
    const onCancel = () => {
        if (newNodeId !== undefined) {
            const newValues = dataTree?.filter((item) => item.id !== newNodeId);
            setDataTree(newValues);
            setNewNodeId(undefined);
        }
    };
    return { nodes, addNode, onUpdate, onDelete, onCancel };
}
