import {
    useCompanyOrgNumber,
    useCompanyVerificationStatus,
    useCurrentUserSignatureStatus,
    useIsCompanyVerificationAdmin,
} from '@Page/CompanyVerificationFlow/companyVerificationAPI';
import React, { useState } from 'react';
import { Button, ModalContent, ModalFooter, ModalTitle } from '@tlx/atlas';
import { ConnectedSendSigningRequestForm } from '@Page/CompanyVerificationFlow/SendSigningRequestForm/ConnectedSendSigningRequestForm';
import { CompanyVerificationBankId } from '@Page/CompanyVerificationFlow/VerifyWithBankId/CompanyVerificationBankId';
import { CompanyVerificationFlowModal } from '@Page/CompanyVerificationFlow/CompanyVerificationFlowModal';
import {
    CompanyVerificationType,
    SignatureStatus,
} from '@Page/CompanyVerificationFlow/Types';
import { MissingOrgNumber } from '@Page/CompanyVerificationFlow/MissingOrgNumber';

export type CompanyVerificationSteps =
    | 'MISSING_ORG_NUMBER'
    | 'SEND_SIGNING_REQUESTS'
    | 'VERIFY_BANK_ID'
    | 'SIGNING_REQUESTS_SENT'
    | 'DONE';

export function ConnectedCompanyVerificationFlow() {
    const { userSignatureStatus } = useCurrentUserSignatureStatus();
    const { companyVerificationStatus } = useCompanyVerificationStatus();
    const isAdmin = useIsCompanyVerificationAdmin();
    const { companyOrgNumber } = useCompanyOrgNumber();

    if (
        userSignatureStatus === undefined ||
        isAdmin === undefined ||
        companyVerificationStatus === undefined ||
        companyOrgNumber === undefined
    ) {
        return null; // Rather than loading spinner just don't show anything. Otherwise there is a spinner under the verify button.
    }

    // Allows for auto opening popup if verifyCompany is in url.
    const url = new URL(window.location.href);
    const openVerificationFlow = url.searchParams.has('verifyCompany');

    return (
        <CompanyVerificationFlow
            defaultOpen={openVerificationFlow}
            userSignatureStatus={userSignatureStatus}
            userIsAdmin={isAdmin}
            companyVerificationStatus={companyVerificationStatus}
            companyOrgNumber={companyOrgNumber}
        />
    );
}

function calculateInitialStep(
    userIsAdmin: boolean,
    userSignatureStatus: SignatureStatus,
    companyOrgNumber?: string
) {
    if (!companyOrgNumber || companyOrgNumber === '') {
        return 'MISSING_ORG_NUMBER';
    }

    if (userSignatureStatus === 'SIGNED') {
        if (userIsAdmin) {
            return 'SEND_SIGNING_REQUESTS';
        } else {
            return 'DONE';
        }
    } else if (userSignatureStatus === 'PENDING_SIGNATURE' && !userIsAdmin) {
        return 'VERIFY_BANK_ID';
    } else {
        return 'SEND_SIGNING_REQUESTS';
    }
}

export function CompanyVerificationFlow({
    defaultOpen,
    userSignatureStatus,
    userIsAdmin,
    companyVerificationStatus,
    companyOrgNumber,
}: {
    userIsAdmin: boolean;
    userSignatureStatus: SignatureStatus;
    companyVerificationStatus: CompanyVerificationType;
    defaultOpen?: boolean;
    companyOrgNumber?: string;
}) {
    const companyVerified = companyVerificationStatus !== 'UNVERIFIED';
    const userVerified = userSignatureStatus === 'SIGNED';

    const [currentStep, setCurrentStep] = useState<CompanyVerificationSteps>(
        () =>
            calculateInitialStep(
                userIsAdmin,
                userSignatureStatus,
                companyOrgNumber
            )
    );

    const [isOpen, setIsOpen] = useState<boolean>(
        (defaultOpen && !companyVerified && !userVerified) ?? false
    );

    function handleCloseModal() {
        setIsOpen(false);
        setCurrentStep(
            calculateInitialStep(
                userIsAdmin,
                userSignatureStatus,
                companyOrgNumber
            )
        );
    }

    const userCanVerifyOrRequest =
        userIsAdmin ||
        (userSignatureStatus && userSignatureStatus !== 'NOT_A_SIGNER');

    return (
        <CompanyVerificationFlowModal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            onClose={handleCloseModal}
            companyVerificationStatus={companyVerificationStatus}
            userCanVerifyOrRequest={userCanVerifyOrRequest}
        >
            {currentStep === 'MISSING_ORG_NUMBER' && (
                <MissingOrgNumber handleNextStep={setCurrentStep} />
            )}

            {currentStep === 'SEND_SIGNING_REQUESTS' && (
                <ConnectedSendSigningRequestForm
                    userSignatureStatus={userSignatureStatus}
                    handleNextStep={setCurrentStep}
                />
            )}
            {currentStep === 'VERIFY_BANK_ID' && (
                <CompanyVerificationBankId closeModal={handleCloseModal} />
            )}
            {currentStep === 'SIGNING_REQUESTS_SENT' && (
                <>
                    <ModalTitle>{getMessage('text_verify_company')}</ModalTitle>
                    <CompanyVerificationFlowDone
                        text={getMessage('text_signing_requests_sent')}
                        onClickConfirm={handleCloseModal}
                    />
                    {userIsAdmin && !companyVerified && (
                        <ModalFooter className={'atl-flex atl-gap-8'}>
                            <Button
                                variant={'secondary'}
                                data-testid={'company-verification-back-button'}
                                onClick={() =>
                                    setCurrentStep('SEND_SIGNING_REQUESTS')
                                }
                            >
                                {getMessage('close_account_button_go_back')}
                            </Button>
                        </ModalFooter>
                    )}
                </>
            )}
            {currentStep === 'DONE' && (
                <>
                    <ModalTitle>{getMessage('text_verify_company')}</ModalTitle>
                    <CompanyVerificationFlowDone
                        text={getMessage('text_company_verification_signed')}
                        onClickConfirm={handleCloseModal}
                    />
                    {userIsAdmin && !companyVerified && (
                        <ModalFooter className={'atl-flex atl-gap-8'}>
                            <Button
                                variant={'secondary'}
                                data-testid={'company-verification-back-button'}
                                onClick={() =>
                                    setCurrentStep('SEND_SIGNING_REQUESTS')
                                }
                            >
                                {getMessage('close_account_button_go_back')}
                            </Button>
                        </ModalFooter>
                    )}
                </>
            )}
        </CompanyVerificationFlowModal>
    );
}

function CompanyVerificationFlowDone({
    text,
    onClickConfirm,
}: {
    text: string;
    onClickConfirm: () => void;
}) {
    return (
        <>
            <ModalContent>
                <div className="atl-text-base atl-text-grey-80">{text}</div>
            </ModalContent>
            <ModalFooter>
                <Button
                    data-testid="company-verification-done-button"
                    onClick={onClickConfirm}
                >
                    {getMessage('text_ok')}
                </Button>
            </ModalFooter>
        </>
    );
}
