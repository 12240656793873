import {
    addDays,
    addMonths,
    addYears,
    compareAsc,
    getWeek,
    getYear,
    getDaysInMonth,
    startOfToday,
    startOfWeek,
    startOfMonth,
    startOfYear,
    endOfMonth,
    endOfYear,
    differenceInDays,
    isEqual,
    isAfter,
    isBefore,
    isMatch,
    isWithinInterval,
    format,
    startOfDay,
    parse,
} from 'date-fns';
import { nb } from 'date-fns/locale';

export interface JsonDate {
    javaClass: 'java.util.Date';
    time: number;
}

export const dateUtil = (function () {
    function addDay(date: Date): Date {
        return addDays(date, 1);
    }

    function jsonDate(date: Date | null): JsonDate | null {
        if (date) {
            return { javaClass: 'java.util.Date', time: date.valueOf() };
        }
        return null;
    }

    /**
     * Remember that weekStartDay starts with 0 as sunday.
     *
     * @param date
     * @param weekStartDay
     */
    function getFirstDayOfWeek(
        date: Date,
        weekStartDay: 0 | 1 | 2 | 3 | 4 | 5 | 6 = 1
    ): Date {
        return startOfWeek(date, { weekStartsOn: weekStartDay });
    }

    function addMonth(date: Date): Date {
        return addMonths(date, 1);
    }

    function subtractMonth(date: Date): Date {
        return addMonths(date, -1);
    }

    function formatDate(date: Date, formatStr = 'yyyy-MM-dd'): string {
        const loc = window.locale === 'no_NO' ? nb : undefined;
        return format(date, formatStr, { locale: loc });
    }

    // If the date is more than 3 months into the future, subtract one year
    function adjustDate3Months(date: Date, now: Date): Date {
        if (isAfter(date, addMonths(now, 3))) {
            return addYears(date, -1);
        }
        return date;
    }

    // ------------------------------------------------------------------
    // parseDate( date_string [, prefer_euro_format] )
    //
    // This function takes a date string and tries to match it to a
    // number of possible date formats to get the value. It will try to
    // match against the following international formats, in this order:
    // y-M-d d/M-y
    // M/d/y   M-d-y      M.d.y     MMMM-d     M/d      M-d
    // d/M/y   d-M-y      d.M.y     d-MMMM     d/M      d-M
    // A second argument may be passed to instruct the method to search
    // for formats like d/M/y (european format) before M/d/y (American).
    // Returns a Date object or null if no patterns match.
    // ------------------------------------------------------------------
    //
    // Modified by AM:
    //  - it does *not* include patterns containing MMMM.
    //  - it *does* include the norwegian pattern 'd/M-y'
    function parseDate(
        val: string,
        preferEuro = false,
        now = new Date()
    ): Date | null {
        const fields = {
            generalFormats: ['y-M-d', 'd/M-y'],
            monthFirst: [
                'M/d/y',
                'M-d-y',
                'M.d.y',
                'MMMM-d',
                'M/d',
                'M-d',
                'M.d',
                'MMdd',
                'MMddy',
            ],
            dateFirst: [
                'd/M/y',
                'd-M-y',
                'd.M.y',
                'd-MMMM',
                'd/M',
                'd-M',
                'd.M',
                'ddMM',
                'ddMMy',
            ],
        };

        const checkList = [
            'generalFormats',
            preferEuro ? 'dateFirst' : 'monthFirst',
            preferEuro ? 'monthFirst' : 'dateFirst',
        ] as const;
        for (let i = 0; i < checkList.length; i++) {
            const l = fields[checkList[i]];
            for (let j = 0; j < l.length; j++) {
                if (isMatch(val, l[j])) {
                    const foundYear = l[j].includes('y');
                    let parsed = startOfDay(parse(val, l[j], now));
                    if (!foundYear) {
                        // If a year wasn't specified, we assume the current year,
                        // or we use previous year in some cases.
                        return adjustDate3Months(parsed, now);
                    } else if (parsed.getFullYear() < 50) {
                        parsed = addYears(parsed, 2000);
                    } else if (parsed.getFullYear() < 100) {
                        parsed = addYears(parsed, 1900);
                    }
                    return parsed;
                }
            }
        }
        return null;
    }

    // Same as parseDate except that it does include (optional) patterns for time ('HH:mm:ss' and 'hh:mm:ssa')
    function parseDatetime(
        val: string,
        preferEuro = false,
        now = new Date()
    ): Date | null {
        const fields = {
            generalFormats: ['y-M-d', 'd/M-y'],
            monthFirst: [
                'M/d/y',
                'M-d-y',
                'M.d.y',
                'MMMM-d',
                'M/d',
                'M-d',
                'M.d',
                'MMdd',
                'MMddy',
            ],
            dateFirst: [
                'd/M/y',
                'd-M-y',
                'd.M.y',
                'd-MMMM',
                'd/M',
                'd-M',
                'd.M',
                'ddMM',
                'ddMMy',
            ],
        };
        const timeFormats = ['', ' HH:mm:ss', 'hh:mm:ssa'];

        const checkList = [
            'generalFormats',
            preferEuro ? 'dateFirst' : 'monthFirst',
            preferEuro ? 'monthFirst' : 'dateFirst',
        ] as const;
        for (let i = 0; i < checkList.length; i++) {
            const l = fields[checkList[i]];
            for (let j = 0; j < l.length; j++) {
                for (let k = 0; k < timeFormats.length; k++) {
                    const format = l[j] + timeFormats[k];
                    if (isMatch(val, format)) {
                        const foundYear = l[j].includes('y');
                        let parsed = parse(val, format, now);
                        if (!foundYear) {
                            // If a year wasn't specified, we assume the current year,
                            // or we use previous year in some cases.
                            return adjustDate3Months(parsed, now);
                        } else if (parsed.getFullYear() < 50) {
                            parsed = addYears(parsed, 2000);
                        } else if (parsed.getFullYear() < 100) {
                            parsed = addYears(parsed, 1900);
                        }
                        return parsed;
                    }
                }
            }
        }
        return null;
    }

    //function used to parse time value
    //17.03.2014 - VS
    function parseTimeStamp(timeValue: string, now = new Date()): Date | null {
        const timeFormats = ['HH:mm:ss', 'hh:mm:ssa'];
        for (const format of timeFormats) {
            if (isMatch(timeValue, format)) {
                return parse(timeValue, format, now);
            }
        }
        return null;
    }

    function daysInMonth(year: number, month: number): number {
        return getDaysInMonth(new Date(year, month));
    }

    function getDaysBetween(startDate: Date, endDate: Date) {
        return differenceInDays(endDate, startDate);
    }

    // the number of the week within the year that contains this date
    function iso8601Week(date: Date): number {
        return getWeek(date, { weekStartsOn: 1, firstWeekContainsDate: 4 });
    }

    function isBeforeOrSame(a: Date, b: Date): boolean {
        return isBefore(a, b) || isEqual(a, b);
    }

    function isAfterOrSame(a: Date, b: Date): boolean {
        return isAfter(a, b) || isEqual(a, b);
    }

    /**
     *
     * @param start Start of interval, inclusive.
     * @param date  The date to test.
     * @param end   End of interval, exclusive.
     *
     * @return True if date is inside the interval, false otherwise.
     */
    function isBetween(start: Date, date: Date, end: Date): boolean {
        return (
            !isEqual(date, end) &&
            isWithinInterval(date, {
                start,
                end,
            })
        );
    }

    /**
     * Convert a time string of hours, minutes and seconds to the number of seconds.
     *
     * @param timeString String of format hh, hh:mm or hh:mm:ss.
     *                   Periods (.) can be used instead of colons.
     */
    function getTimeStringSeconds(timeString: string): number {
        let hours = 0;
        let minutes = 0;
        let seconds = 0;
        timeString = timeString.replaceAll('-', '');
        timeString = timeString.replaceAll('.', ':');

        let timeStringSplit = timeString.split(':');
        if (timeStringSplit.length > 3) {
            timeStringSplit = [
                timeStringSplit[0],
                timeStringSplit[1],
                timeStringSplit[2],
            ];
        }
        if (timeStringSplit.length > 0 && !isNaN(Number(timeStringSplit[0]))) {
            hours = Number(timeStringSplit[0]);
        } else if (!isNaN(Number(timeString))) {
            hours = Number(timeString);
        }
        if (timeStringSplit.length > 1 && !isNaN(Number(timeStringSplit[1]))) {
            minutes = Number(timeStringSplit[1]);
        }
        if (
            timeStringSplit.length === 3 &&
            !isNaN(Number(timeStringSplit[2]))
        ) {
            seconds = Number(timeStringSplit[2]);
        }
        return 60 * 60 * hours + 60 * minutes + seconds;
    }

    return {
        getFirstDayOfYear: startOfYear,
        getLastDayOfYear: endOfYear,
        getFirstDayOfMonth: startOfMonth,
        getLastDayOfMonth: endOfMonth,
        addDay: addDay,
        addDays: addDays,
        getYear: getYear,
        getFirstDayOfWeek,
        jsonDate,
        addYears,
        addMonths,
        addMonth,
        subtractMonth,
        formatDate,
        isDate: isMatch,
        parseDate,
        parseDatetime,
        parseTimeStamp,
        daysInMonth,
        getDaysBetween,
        iso8601Week,
        today: startOfToday,
        compareDate: compareAsc,
        eql: isEqual,
        isBefore,
        isBeforeOrSame,
        isAfter,
        isAfterOrSame,
        isBetween,
        startOfDay,
        getTimeStringSeconds,
    };
})();
