import { app } from '../../../../js/modules/framework';
import { tlxUrl } from '../../../../js/modules/url';
import { getRelevantUrl } from '../../../../js/modules/scope';
import { nav } from '../../../../js/modules/navigation';
import { tlxAlert } from '../../../../js/modules/alert';

import { isDefined } from '@General/Helpers';
import { ScopeElement } from '@General/Router/Router.type';
import { createAPIRequest, createAPIResponse } from '@tlx/astro-shared';

export function downloadPageAsXLS(): void {
    const url = tlxUrl.addUrlParameter(getRelevantUrl(), 'xls', 'true');
    nav.download(url);
}

export function canDownloadPageAsXLS(): boolean {
    const scope = app.outermostScope().get(0) as ScopeElement;
    return isDefined(scope) && scope.xlsPage === true;
}

export interface CSVExportConfiguration {
    readonly csvHeader: boolean;
    readonly csvExportFormat: 'csvDefault' | 'csvAdvanced';
    readonly csvEncoding: 'ISO-8859-1' | 'UTF-8' | 'MacRoman';
    readonly csvSeparator: ';' | ',' | '\t';
    readonly csvDecimal: '.' | ',';
    readonly csvQualifier: '"' | "'" | '';
    readonly csvLineBreak: '\r\n' | '\n';
}

export function downloadPageAsCSV({
    csvHeader,
    csvExportFormat,
    csvEncoding,
    csvSeparator,
    csvDecimal,
    csvQualifier,
    csvLineBreak,
}: CSVExportConfiguration): void {
    const url = new URL(getRelevantUrl(), window.location.origin);
    const { searchParams } = url;

    searchParams.set('csv', 'true');
    searchParams.set('csvHeader', `${csvHeader}`);

    if (csvExportFormat === 'csvDefault') {
        searchParams.set('csvEncoding', 'ISO-8859-1');
        searchParams.set('csvQualifier', '"');
        searchParams.set('csvLineBreak', '\r\n');
        if (window.locale === 'en_GB') {
            searchParams.set('csvSeparator', ',');
            searchParams.set('csvDecimal', '.');
        } else {
            searchParams.set('csvSeparator', ';');
            searchParams.set('csvDecimal', ',');
        }
    } else {
        searchParams.set('csvEncoding', csvEncoding);
        searchParams.set('csvQualifier', csvQualifier);
        searchParams.set('csvDecimal', csvDecimal);

        searchParams.set('csvSeparator', csvSeparator);
        searchParams.set('csvLineBreak', csvLineBreak);
    }

    nav.download(url.href);
}

export function canDownloadPageAsCSV(): boolean {
    const scope = app.outermostScope().get(0) as ScopeElement;
    return isDefined(scope) && scope.csvPage === true;
}

export function getDefaultCSVConfiguration(): CSVExportConfiguration {
    return {
        csvHeader: true,
        csvExportFormat: 'csvDefault',
        csvEncoding: 'ISO-8859-1',
        csvSeparator: window.locale === 'en_GB' ? ',' : ';',
        csvDecimal: window.locale === 'en_GB' ? '.' : ',',
        csvQualifier: '"',
        csvLineBreak: '\r\n',
    };
}

export interface PDFExportConfiguration {
    readonly pageFormat:
        | 'A5'
        | 'A4'
        | 'A3'
        | 'B5'
        | 'B4'
        | 'letter'
        | 'legal'
        | 'ledger';
    readonly pageOrientation: 'portrait' | 'landscape';
    readonly pdfAction: 'pdfOpen' | 'pdfEmail';
    readonly emailAddress: string;
    readonly emailBody: string;
}

export function downloadPageAsPDF({
    pageFormat,
    pageOrientation,
    pdfAction,
    emailAddress,
    emailBody,
}: PDFExportConfiguration): void {
    const url = new URL(getRelevantUrl(), window.location.origin);
    const { searchParams } = url;

    searchParams.set('pdf', 'true');
    searchParams.set('pdfSize', `${pageFormat} ${pageOrientation}`);
    searchParams.set(
        'menuHeader',
        document
            .querySelector<HTMLElement>('#menuHeader h1')
            ?.innerText?.trim() ?? ''
    );

    if (pdfAction === 'pdfEmail') {
        searchParams.set('pdfAction', 'email');
        searchParams.set('pdfEmailAddress', emailAddress);
        searchParams.set('pdfEmailBody', emailBody);

        searchParams.set('contextId', `${window.contextId}`);
        const request = createAPIRequest(url.href, {
            method: 'POST',
        });

        fetch(request)
            .then((response) => {
                return createAPIResponse<boolean>(request, response);
            })
            .then((response) => {
                if (response) {
                    tlxAlert(getMessage('text_email_sent_successfully'));
                } else {
                    tlxAlert(
                        getMessage(
                            'text_unexpected_error_has_occured',
                            window.contextId
                        )
                    );
                }
            });
    } else {
        nav.download(url.href);
    }
}

export function canDownloadPageAsPDF(): boolean {
    const $scope = app.outermostScope();
    return $scope.data('disablePdfExport') !== true;
}

export function getDefaultPDFConfiguration(): PDFExportConfiguration {
    const scope = app.outermostScope().get(0) as ScopeElement;

    return {
        pageFormat: 'A4',
        pageOrientation: isDefined(scope)
            ? scope.tlxGetOrientation?.() ?? 'portrait'
            : 'portrait',
        pdfAction: 'pdfOpen',
        emailAddress: window.loginEmployeeEmail,
        emailBody: '',
    };
}
