import { EmptyState } from '@Page/LogisticsV2/components/EmptyState/EmptyState';
import React from 'react';
import {
    Button,
    KebabIcon,
    PopupMenu,
    PopupMenuItemButton,
    usePopupMenu,
} from '@tlx/atlas';
import { nav } from '../../../../../../../js/modules/navigation';
import { PageUrl } from '@Page/LogisticsV2/utils/enums';

export function InventoryCorrectionOverviewLayout({
    children,
}: {
    children: React.ReactNode;
}) {
    return (
        <div
            className="atl-flex atl-flex-col atl-gap-16"
            data-testid="inventory-correction-overview-layout"
        >
            {children}
        </div>
    );
}

export function InventoryCorrectionOverviewHeader() {
    const { openerButtonProps, popupMenuProps } = usePopupMenu();
    return (
        <div className={'atl-flex atl-justify-between atl-flex-wrap'}>
            <div
                id="menuHeader"
                data-testid="inventory-correction-overview-title"
                className="useAtlasHeader"
            >
                <h1 className="clip" data-testid={'header-title'}>
                    {getMessage('text_inventory_correction_menu')}
                </h1>
            </div>
            <div className="atl-flex atl-gap-8 atl-items-center">
                <Button
                    data-testid="new-inventory-correction-button"
                    onClick={() =>
                        nav.nav(
                            addContextId(
                                `${PageUrl.INVENTORY_MENU}?inventoryId=0&isCorrection=true`
                            )
                        )
                    }
                >
                    {getMessage('text_inventory_correction_new')}
                </Button>
                <Button
                    data-testid="inventory-correction-overview-top-actions"
                    variant="icon"
                    {...openerButtonProps}
                >
                    <KebabIcon />
                </Button>
                <PopupMenu {...popupMenuProps}>
                    <PopupMenuItemButton data-testid="inventory-correction-overview-old-page-button">
                        <a
                            href={addContextId(
                                '/execute/listInventoryCorrections'
                            )}
                            className="atl-text-grey-100"
                        >
                            {getMessage('text_old_page')}
                        </a>
                    </PopupMenuItemButton>
                </PopupMenu>
            </div>
        </div>
    );
}

export function InventoryCorrectionOverviewEmptyState({
    colSpan,
}: {
    colSpan: number;
}) {
    return (
        <tr>
            <td className="atl-bg-white" colSpan={colSpan}>
                <EmptyState
                    title={getMessage('text_inventory_correction_empty')}
                    className="atl-text-xl"
                    description={getMessage(
                        'text_inventory_correction_empty_description'
                    )}
                    svgIcon={'inventoryCorrectionOverview'}
                >
                    <div>
                        <Button
                            data-testid="new-inventory-correction-button"
                            variant="secondary"
                            onClick={() =>
                                nav.nav(
                                    addContextId(
                                        `${PageUrl.INVENTORY_MENU}?inventoryId=0&isCorrection=true`
                                    )
                                )
                            }
                        >
                            {getMessage('text_inventory_correction_new')}
                        </Button>
                    </div>
                </EmptyState>
            </td>
        </tr>
    );
}
