import EmptyState from '@Component/EmptyState/EmptyState';
import { getFlattenedTree } from '@Page/LogisticsV2/components/DataTree/tree';
import { DataTreeDTO } from '@Page/LogisticsV2/components/DataTree/types';
import { ValidatedInput } from '@Page/LogisticsV2/components/Input/ValidatedInput';
import { ToggleSwitch } from '@Page/LogisticsV2/components/ToggleSwitch/ToggleSwitch';
import { useLoadMoreTarget } from '@Page/LogisticsV2/hooks/fetcher';
import { ResaleProductDTO, VatTypeDTO } from '@Page/LogisticsV2/types/dtoTypes';
import { UseResaleProductsPaginatedReturn } from '@Page/LogisticsV2/types/returnTypes';
import { ApiError } from '@Page/LogisticsV2/utils/constants';
import {
    getDisplayNumber,
    getFieldErrorFromValidationMessages,
    getIdFromUrl,
} from '@Page/LogisticsV2/utils/functions';
import {
    Alert,
    AlertContent,
    Group,
    Input,
    Label,
    OpenNewIcon,
    Option,
    Select,
    SkeletonCheckbox,
    SkeletonRow,
    SkeletonText,
} from '@tlx/atlas';
import React, { useEffect, useState } from 'react';
import { ProductSelectorTable } from '../ProductSelector';
import { ProductAndSupplierConnectionFormModel } from '../types';
import { NumberInput } from '@Page/LogisticsV2/components/Input/NumberInput';
import { useSuggestedProductNumber } from '@Page/LogisticsV2/components/Modals/ProductSelector/useProducts';

export function NewProductForm({
    nodes,
    onCreateProduct,
    productGroupId,
    vatTypes,
    error,
}: {
    nodes: DataTreeDTO[];
    onCreateProduct: (
        resaleProduct: Partial<ResaleProductDTO>,
        productGroupId: number | undefined
    ) => void;
    productGroupId: number | undefined;
    vatTypes: VatTypeDTO[];
    error: ApiError | undefined;
}) {
    const tree = getFlattenedTree(nodes);
    const suggestedProductNumber = useSuggestedProductNumber();
    const [formState, setFormState] =
        useState<ProductAndSupplierConnectionFormModel>({
            name: '',
            number: suggestedProductNumber,
            costExcludingVatCurrency: 0,
            price: 0,
            vatTypeId: 3,
            groupId: productGroupId,
        });
    const [includeVat, setIncludeVat] = useState(false);
    const handleChange = (name: string) => (e: any) => {
        const value = typeof e === 'string' ? e : e.target.value;
        setFormState((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };
    const handleCreateNewProduct = async () => {
        const resaleProduct: Partial<ResaleProductDTO> = {
            name: formState.name,
            number: formState.number,
            costExcludingVatCurrency: formState.costExcludingVatCurrency,
            vatType: { id: Number(formState.vatTypeId) },
            isStockItem: true,
        };
        if (includeVat) {
            resaleProduct.priceIncludingVatCurrency = formState.price;
        } else {
            resaleProduct.priceExcludingVatCurrency = formState.price;
        }
        onCreateProduct(resaleProduct, formState.groupId);
    };
    useEffect(() => {
        setFormState((state) => ({
            ...state,
            groupId: productGroupId?.toString() ? productGroupId : 0,
            number: suggestedProductNumber ?? '',
        }));
    }, [productGroupId, suggestedProductNumber]);

    return (
        <form
            id={'create-product-form'}
            className={'atl-flex atl-py-8 atl-flex-col'}
            onSubmit={(event) => {
                event.preventDefault();
                event.stopPropagation();
                handleCreateNewProduct();
            }}
        >
            <div className={'atl-flex atl-gap-16 goldshark-new-product__form'}>
                <Group className={'atl-py-8 atl-flex atl-flex-col'}>
                    <Label>{getMessage('text_name')}*</Label>
                    <ValidatedInput
                        type={'text'}
                        data-testid={'new-product-name'}
                        onChange={handleChange('name')}
                        error={getFieldErrorFromValidationMessages(
                            error?.validationMessages,
                            'name'
                        )}
                    />
                </Group>
                <Group className={'atl-py-8 atl-flex atl-flex-col'}>
                    <Label>{getMessage('text_number')}</Label>
                    <ValidatedInput
                        type={'text'}
                        data-testid={'new-product-number'}
                        onChange={handleChange('number')}
                        error={getFieldErrorFromValidationMessages(
                            error?.validationMessages,
                            'number'
                        )}
                        value={formState.number}
                    />
                </Group>
                {tree.length > 0 && moduleLogistics && (
                    <Group className={'atl-py-8'}>
                        <Label>{getMessage('text_product_group')}</Label>
                        <Select
                            id="productGroup"
                            aria-labelledby="productGroup"
                            data-testid={'new-product-product-group'}
                            className={'goldshark-new-product__dropdown'}
                            onChange={handleChange('groupId')}
                            value={
                                formState.groupId !== undefined
                                    ? formState.groupId.toString()
                                    : '0'
                            }
                        >
                            <Option key={0} value={'0'}>
                                {getMessage('option_not_chosen')}
                            </Option>
                            {tree.map((node) => (
                                <Option
                                    key={node.id}
                                    value={node.id.toString()}
                                >
                                    {`${node.prefix} ${node.name}`}
                                </Option>
                            ))}
                        </Select>
                    </Group>
                )}
                <Group className={'atl-py-8 atl-flex atl-flex-col'}>
                    <Label>{getMessage('text_vat_code')}*</Label>
                    <Select
                        id="vatType"
                        aria-labelledby="vatType"
                        data-testid={'new-product-vat'}
                        className={'goldshark-new-product__dropdown'}
                        onChange={handleChange('vatTypeId')}
                        defaultValue={'3'}
                    >
                        {vatTypes.map((vat) => (
                            <Option key={vat.id} value={vat.id.toString()}>
                                {vat.displayName}
                            </Option>
                        ))}
                    </Select>
                </Group>
                <Group className={'atl-py-8 atl-flex atl-flex-col'}>
                    <Label>{getMessage('text_price_purchase_excl_vat')}</Label>
                    <Input
                        type={'number'}
                        data-testid={'new-product-purchase-excl-vat'}
                        onChange={handleChange('costExcludingVatCurrency')}
                        value={formState.costExcludingVatCurrency}
                    />
                </Group>
                <Group className={'atl-py-8 atl-flex atl-flex-col'}>
                    <Label>
                        {includeVat
                            ? getMessage('text_sales_price_incl_vat') + '(NOK)'
                            : getMessage('text_sales_price_excl_vat') + '(NOK)'}
                    </Label>
                    <Input
                        type={'number'}
                        data-testid={'new-product-purchase-selling-price'}
                        hidden={includeVat}
                        onChange={handleChange('price')}
                        value={formState.price}
                    />
                    <Input
                        type={'number'}
                        data-testid={'new-product-purchase-selling-price'}
                        hidden={!includeVat}
                        onChange={handleChange('price')}
                        value={formState.price}
                    />
                </Group>
                <Group>
                    <Label style={{ height: '35px' }} />
                    <ToggleSwitch
                        id={'product-selector-toggle-switch-vat'}
                        label={getMessage('text_incl_vat')}
                        onCheckedChange={() => {
                            setIncludeVat(!includeVat);
                        }}
                    />
                </Group>
            </div>
            {error?.validationMessages.length === 0 && (
                <Alert variant="error" className={'atl-my-16'}>
                    <AlertContent>{error.message}</AlertContent>
                </Alert>
            )}
        </form>
    );
}

export function ProductSelectorModalTable({
    products,
    selectedIds,
    onSelectAll,
    onSelectProduct,
    onChangeCount,
}: {
    products: UseResaleProductsPaginatedReturn;
    selectedIds: number[];
    onSelectAll: () => void;
    onSelectProduct: (product: ResaleProductDTO) => void;
    onChangeCount?: (product: ResaleProductDTO) => void;
}) {
    const loadMoreRef = useLoadMoreTarget<HTMLDivElement>(products.loadMore);
    const areAllVisibleProductsSelected = () => {
        const visibleIds = products.data.map((product) => product.id);
        return (
            visibleIds.length > 0 &&
            visibleIds.every((id) => selectedIds.includes(id))
        );
    };
    const isSelected = (productId: number) => {
        return selectedIds.includes(productId);
    };
    return (
        <>
            <ProductSelectorTable>
                <ProductSelectorHeaders
                    isVisible={!products.hasMore && products.data.length > 0}
                    isCheckAll={areAllVisibleProductsSelected()}
                    onChange={onSelectAll}
                />
                <tbody>
                    {products.data.map((product, index) => (
                        <ProductSelectorRow
                            product={product}
                            isSelected={isSelected(product.id)}
                            onChange={onSelectProduct}
                            onChangeCount={onChangeCount}
                            key={index}
                        />
                    ))}
                    {products.isLoading && (
                        <SkeletonRow>
                            <td className="atl-td atl-td--checkbox">
                                <SkeletonCheckbox />
                            </td>
                            <td className="atl-td">
                                <SkeletonText className="atl-w-full" />
                            </td>
                            <td className="atl-td">
                                <SkeletonText className="atl-w-full" />
                            </td>
                            <td className="atl-td">
                                <SkeletonText className="atl-w-full" />
                            </td>
                        </SkeletonRow>
                    )}
                    {products.hasMore && (
                        <tr>
                            <td>
                                <div ref={loadMoreRef} />
                            </td>
                        </tr>
                    )}
                    {products.data.length === 0 && !products.isLoading && (
                        <tr>
                            <td colSpan={5}>
                                <EmptyState
                                    icon="folder"
                                    title={getMessage('text_no_products_found')}
                                />
                            </td>
                        </tr>
                    )}
                </tbody>
            </ProductSelectorTable>
        </>
    );
}

const PRODUCT_SELECTOR_HEADERS = [
    {
        name: 'number',
        textAlign: 'left',
        messageKey: 'text_number',
        width: '25%',
    },
    {
        name: 'name',
        textAlign: 'left',
        messageKey: 'text_name',
        width: '70%',
    },
];

const PRODUCT_SELECTOR_HEADERS_WITH_COUNT = [
    {
        name: 'number',
        textAlign: 'left',
        messageKey: 'text_number',
        width: '25%',
    },
    {
        name: 'name',
        textAlign: 'left',
        messageKey: 'text_name',
        width: '70%',
    },
    {
        name: 'count',
        textAlign: 'left',
        messageKey: 'text_count',
        width: '70%',
    },
];

function ProductSelectorHeaders({
    isVisible,
    isCheckAll,
    onChange,
}: {
    isVisible: boolean;
    isCheckAll: boolean;
    onChange: () => void;
}) {
    const inventoryId = getIdFromUrl('inventoryId');
    const headers =
        inventoryId === null
            ? PRODUCT_SELECTOR_HEADERS_WITH_COUNT
            : PRODUCT_SELECTOR_HEADERS;
    return (
        <thead className="atl-thead atl-thead--sticky">
            <tr>
                {isVisible ? (
                    <th className={'atl-th atl-align-middle'}>
                        <Input
                            className={'goldshark-cursor--pointer'}
                            data-testid={'product-select-all-checkbox'}
                            type="checkbox"
                            checked={isCheckAll}
                            onChange={onChange}
                            readOnly
                        />
                    </th>
                ) : (
                    <th className={'atl-th atl-align-middle'} />
                )}
                {headers.map((productHeader, index) => (
                    <th
                        className={`atl-th atl-align-middle atl-font-medium atl-text-${productHeader.textAlign}`}
                        style={{ width: productHeader.width }}
                        key={index}
                    >
                        {getMessage(productHeader.messageKey)}
                    </th>
                ))}
                <th style={{ width: '5%' }} />
            </tr>
        </thead>
    );
}

function ProductSelectorRow({
    product,
    isSelected,
    onChange,
    onChangeCount,
}: {
    product: ResaleProductDTO;
    isSelected: boolean;
    onChange: (product: ResaleProductDTO) => void;
    onChangeCount?: (product: ResaleProductDTO) => void;
}) {
    const [internalCount, setInternalCount] = React.useState(
        product.count ?? ''
    );
    const inventoryId = getIdFromUrl('inventoryId');

    useEffect(() => {
        if (isSelected && internalCount.length === 0) {
            setInternalCount('1');
        }
        if (isSelected && internalCount.length > 0) {
            product.count = internalCount;
            onChangeCount?.(product);
        }
    }, [isSelected]);

    return (
        <tr className="atl-tr atl-tr--highlight goldshark-cursor--pointer">
            <td
                className="atl-td atl-align-middle"
                onClick={() => onChange(product)}
            >
                <div className={'atl-flex atl-items-center'}>
                    <Input
                        className={'goldshark-cursor--pointer'}
                        data-testid={'product-select-checkbox'}
                        type="checkbox"
                        checked={isSelected}
                        readOnly
                    />
                </div>
            </td>
            <td
                className="atl-td atl-break-all atl-align-middle"
                onClick={() => onChange(product)}
            >
                {getDisplayNumber(product)}
            </td>
            <td
                className="atl-td atl-break-all atl-align-middle"
                onClick={() => onChange(product)}
            >
                {product.name}
            </td>
            {inventoryId === null && (
                <td className="atl-td atl-text-right atl-align-middle">
                    <NumberInput
                        data-testid="product-selector-amount"
                        className="goldshark-product-selector-count"
                        value={Number(internalCount)}
                        onChange={(value) => {
                            const input = Number(value).toString();
                            setInternalCount(input);
                            product.count = input;
                            onChangeCount?.(product);
                            if (
                                (!isSelected && input.length > 0) ||
                                (isSelected && input.length < 1)
                            ) {
                                onChange(product);
                            }
                        }}
                    />
                </td>
            )}
            <td className="atl-td atl-align-middle">
                <a
                    className={'atl-flex atl-items-center'}
                    href={`/execute/productMenu?productId=${product.id}&contextId=${window.contextId}`}
                    target={'_blank'}
                >
                    <OpenNewIcon />
                </a>
            </td>
        </tr>
    );
}
