import * as React from 'react';

import { PageLoader } from '@Component/PageLoader';

import type { UnexpectedGamesProps } from './component';

export const UnexpectedGamesPage: React.FC<UnexpectedGamesProps> = (props) => (
    <PageLoader<UnexpectedGamesProps>
        loader={async () =>
            import('./UnexpectedGames.module').then((m) => m.page)
        }
        adjustWrapperDiv
        props={props}
    />
);
