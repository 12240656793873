import React from 'react';

import { Button, Icon } from '@tlx/atlas';

import './UserFeedbackButton.css';

interface UserFeedbackButtonProps {
    readonly setIsFeedbackBoxOpen: React.Dispatch<
        React.SetStateAction<boolean>
    >;
    readonly buttonRef?: React.RefObject<HTMLButtonElement>;
    readonly isTertiary?: boolean;
    readonly hideIcon?: boolean;
}

export function UserFeedbackButton({
    setIsFeedbackBoxOpen,
    buttonRef,
    isTertiary,
    hideIcon,
}: UserFeedbackButtonProps) {
    return (
        <Button
            className="atl-mb-4"
            id="2022-send-userFeedback"
            variant={isTertiary ? 'tertiary' : 'secondary'}
            data-testid="2022-userFeedback"
            data-trackingid="text_send_feedback"
            onClick={() => {
                setIsFeedbackBoxOpen((v) => !v);
            }}
            ref={buttonRef}
        >
            {getMessage('text_send_feedback')}
            {!hideIcon && <Icon>drive_file_rename_outline</Icon>}
        </Button>
    );
}
