import { useFetchDistributionKeys } from '@Page/DistributionKeys/hooks/dataFetching/useFetchDistributionKeys';
import { ApiAction } from '../models/Actions';
import { useDistributionKeyApi } from '@Page/DistributionKeys/hooks/dataFetching/useDistributionKeyApi';
import { DistributionKey } from '@Page/DistributionKeys/models/DistributionKeys';

export const useDistributionKeys = () => {
    const { distributionKeys, mutate, error } = useFetchDistributionKeys();
    const { distributionKeyApiAction, distributionKeyApiActionError } =
        useDistributionKeyApi();

    const dispatch = async (
        apiAction: ApiAction,
        distributionKey: DistributionKey
    ) => {
        try {
            await distributionKeyApiAction(apiAction, distributionKey);
        } catch (error) {
            console.error(error);
        } finally {
            await mutate();
        }
    };

    return {
        distributionKeys,
        dispatch,
        errors: {
            error,
            distributionKeyApiActionError,
        },
    };
};
