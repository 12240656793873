import { RouteData } from '@General/Router';
import { Team } from '@General/Team';

import { ReportViewerPage } from './ReportViewer.page';

export const route: RouteData = {
    component: ReportViewerPage,
    path: '/execute/report/view',
    team: Team.DATA_STREAM,
    documentationComponent: 310, // REPORT_RESULT TODO convert to enum

    // Needed otherwise the fallback 401 page title will show up.
    title: () => '',

    // Always authorized as the page content adjusts automatically
    auth: () => true,

    supportsPDF: true,
    supportsXLS: true,
    supportsCSV: true,
};
