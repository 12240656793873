import React, { useState } from 'react';
import '../PaymentWidget.scss';
import { Button, Modal, ModalCloseButton, ModalTitle } from '@tlx/atlas';
import { PaymentWidgetDatePicker } from '@Page/PaymentWidget/component/PaymentWidgetDatePicker';
import { PaymentWidgetInputAmountField } from '@Page/PaymentWidget/component/Amount/PaymentWidgetInputAmountField';
import { TextField } from '@Page/PaymentWidget/component/TextField/TextField';
import { UseSimplePaymentWidget } from '@Page/PaymentWidget/hooks/useSimplePaymentWidget';
import {
    PaymentType,
    PaymentTypeEnum,
    PaymentWidgetValidations,
} from '@Page/PaymentWidget/types/paymentWidgetTypes';
import { PaymentTypeDropdown } from '@Page/PaymentWidget/component/PaymentTypeDropdown/PaymentTypeDropdown';

export type SimplePaymentWidgetProps = UseSimplePaymentWidget & {
    atlasDesignSystem: boolean;
    label: string;
};

export const SimplePaymentWidget: React.FC<SimplePaymentWidgetProps> = ({
    atlasDesignSystem,
    label,
    paymentWidgetData,
    setPaymentWidgetData,
    registerPayment,
    paymentWidgetValidations,
    setPaymentWidgetValidations,
}) => {
    const [isDialogOpen, setIsDialogOpen] = useState(true);

    const closeDialog = () => {
        setIsDialogOpen(false);
        window.setTimeout(() => {
            document.dispatchEvent(new Event('paymentWidgetClose'));
        }, 0);
    };

    const resetValidationMessage = (
        propertyName: keyof PaymentWidgetValidations
    ) => {
        setPaymentWidgetValidations({
            ...paymentWidgetValidations,
            [propertyName]: '',
        });
    };

    return (
        <Modal
            open={isDialogOpen}
            onClose={closeDialog}
            className="pbc-payment__section-modal"
        >
            <ModalCloseButton />
            <ModalTitle>{getMessage(label)}</ModalTitle>
            <div className="pbc-payment__section-column">
                <PaymentTypeDropdown
                    id="paymentWidgetPaymentType"
                    paymentTypes={paymentWidgetData.paymentTypes}
                    selectedPaymentType={paymentWidgetData.selectedPaymentType}
                    atlasDesignSystem={atlasDesignSystem}
                    setDropdownOption={(selectedPaymentType: PaymentType) => {
                        setPaymentWidgetData({
                            ...paymentWidgetData,
                            selectedPaymentType,
                        });
                    }}
                    validationMessage={
                        paymentWidgetValidations.selectedPaymentType
                    }
                    resetValidationMessage={() =>
                        resetValidationMessage('selectedPaymentType')
                    }
                />
                <PaymentWidgetDatePicker
                    id="payment-date"
                    atlasDesignSystem={atlasDesignSystem}
                    date={paymentWidgetData.date}
                    setDate={(date) => {
                        setPaymentWidgetData({
                            ...paymentWidgetData,
                            date: date,
                        });
                    }}
                    validationMessage={paymentWidgetValidations.date}
                    resetValidationMessage={() => {
                        resetValidationMessage('date');
                    }}
                    data-testid="date-field"
                >
                    {getMessage('text_payment_date')}
                </PaymentWidgetDatePicker>
                <PaymentWidgetInputAmountField
                    id="amount"
                    atlasDesignSystem={atlasDesignSystem}
                    amount={paymentWidgetData.amount}
                    setAmount={(amount) => {
                        setPaymentWidgetData({
                            ...paymentWidgetData,
                            amount: amount,
                        });
                    }}
                    validationMessage={paymentWidgetValidations.amount}
                    resetValidationMessage={() =>
                        resetValidationMessage('amount')
                    }
                    data-testid="amount-field"
                >
                    {getMessage('text_amount')}
                </PaymentWidgetInputAmountField>

                {paymentWidgetData.selectedPaymentType.paymentIntegration !==
                    PaymentTypeEnum.POSTING_RULE && (
                    <>
                        <TextField
                            id="bank-account"
                            label="text_bank_account_number_11_digits"
                            atlasDesignSystem={atlasDesignSystem}
                            readOnly={paymentWidgetData.readOnlyBankAccount}
                            value={paymentWidgetData.bankAccount}
                            setValue={(bankAccount) => {
                                setPaymentWidgetData({
                                    ...paymentWidgetData,
                                    bankAccount: bankAccount,
                                });
                            }}
                            validationMessage={
                                paymentWidgetValidations.bankAccount
                            }
                            resetValidationMessage={() =>
                                resetValidationMessage('bankAccount')
                            }
                        />
                        <TextField
                            id="kid"
                            label="text_kid"
                            atlasDesignSystem={atlasDesignSystem}
                            readOnly={paymentWidgetData.readOnlyKid}
                            value={paymentWidgetData.kid}
                            setValue={(kid) => {
                                setPaymentWidgetData({
                                    ...paymentWidgetData,
                                    kid: kid,
                                });
                            }}
                            validationMessage={paymentWidgetValidations.kid}
                            resetValidationMessage={() =>
                                resetValidationMessage('kid')
                            }
                        />
                        <div
                            className={
                                atlasDesignSystem
                                    ? 'pbc-payment__section-tips'
                                    : 'pbc-payment__section-tips-non-atlas'
                            }
                        >
                            {getMessage('text_payment_must_be_approved_in')}
                            <a
                                className="pbc-payment__section-link atl-ml-4"
                                href={`/execute/paymentOverview?contextId=${contextId}`}
                                target="_blank"
                            >
                                {getMessage('text_payment_overview')}
                            </a>
                        </div>
                    </>
                )}

                <div className="atl-flex atl-gap-8">
                    <Button
                        data-testid="register-button"
                        variant="primary"
                        onClick={() => {
                            registerPayment();
                            closeDialog();
                        }}
                    >
                        {getMessage('text_register_payment')}
                    </Button>

                    <Button
                        data-testid="close-button"
                        variant="tertiary"
                        onClick={closeDialog}
                    >
                        {getMessage('button_cancel')}
                    </Button>
                </div>
            </div>
        </Modal>
    );
};
