import { useEffect, useRef } from 'react';
import { useDropdownSelectedValues } from '@tlx/atlas';

export function useExclusiveOption(value: string) {
    const [selectedValues, setSelectedValues] = useDropdownSelectedValues();
    const previousSelectedValuesRef = useRef(selectedValues);
    const previousSelectedValues = previousSelectedValuesRef.current;
    const didSelectAll =
        selectedValues.includes(value) &&
        !previousSelectedValues.includes(value);

    const shouldRemoveSelectAll =
        previousSelectedValues.includes(value) &&
        previousSelectedValues.length === 1 &&
        selectedValues.length > 1;

    useEffect(() => {
        previousSelectedValuesRef.current = selectedValues;
    }, [selectedValues]);

    useEffect(() => {
        if (didSelectAll) {
            setSelectedValues([value]);
        }
    }, [didSelectAll, setSelectedValues, value]);

    useEffect(() => {
        if (shouldRemoveSelectAll) {
            setSelectedValues(
                selectedValues.filter(
                    (selectedValue) => selectedValue !== value
                )
            );
        }
    }, [shouldRemoveSelectAll, selectedValues, setSelectedValues, value]);
}
