import './TransportIntegration.css';
import {
    BringCredentialsModel,
    useBringCredentials,
} from './useBringCredentials';
import { Group, Input, Label } from '@tlx/atlas';
import { Icon } from '@Component/Icon';
import { Avatar } from '@Page/Report/Component/Avatar';
import { Footer } from '@Page/Logistics/Component/Footer';

export function TransportIntegration() {
    const bringIntegration = useBringCredentials();

    return (
        <form autoComplete="off">
            <div className="atl-flex atl-flex-wrap atl-gap-16">
                <div className="atl-flex-auto atl-p-8 atl-bg-white atl-border atl-border-grey-20">
                    <div className="atl-text-lg atl-font-bold atl-p-8 atl-pb-16">
                        {getMessage('text_transport_integration_system_bring')}
                    </div>
                    <BringFormHelpSection />
                    <BringForm bringIntegration={bringIntegration} />
                </div>
            </div>
            <Footer action={bringIntegration.onSave} />
        </form>
    );
}

const BringFormHelpSection = () => {
    return (
        <div id="guideBox" className="atl-p-8">
            <div className="atl-p-4 atl-pl-8 atl-flex atl-items-center">
                <div className={'atl-pr-4'}>
                    <Avatar>
                        <Icon icon="local_shipping" />
                    </Avatar>
                </div>
                <Label className="atl-pl-8">
                    <a
                        href="https://hjelp.tripletex.no/hc/no/articles/15164929110929"
                        target="_blank"
                    >
                        {getMessage('text_transport_integration_bring_guide')}{' '}
                    </a>
                </Label>
            </div>
        </div>
    );
};

const BringForm = ({
    bringIntegration,
}: {
    bringIntegration: BringCredentialsModel;
}) => {
    return (
        <div>
            <div className="atl-flex atl-items-center atl-pt-24 atl-pb-8 atl-gap-8">
                <Input
                    type="toggle"
                    defaultChecked={false}
                    data-testid="activate-integration-toggle"
                    {...bringIntegration.register('moduleBring')}
                />
                <Label>
                    {bringIntegration.watch('moduleBring')
                        ? getMessage(
                              'text_transport_integration_deactivate_toggle'
                          )
                        : getMessage(
                              'text_transport_integration_activate_toggle'
                          )}
                </Label>
            </div>
            <div className="atl-flex atl-p-8 atl-gap-16 atl-flex-wrap">
                <Group>
                    <Label htmlFor="customer_number">
                        {getMessage('text_customer_number')} *
                    </Label>
                    <Input
                        id="customer_number"
                        type="text"
                        disabled={!bringIntegration.watch('moduleBring')}
                        className="atl-min-w-0"
                        data-testid="customer-number-input"
                        {...bringIntegration.register('customerNumber', {
                            required: {
                                value: bringIntegration.watch('moduleBring')
                                    ? true
                                    : false,
                                message: getMessage('validation_missing'),
                            },
                            maxLength: {
                                value: 255,
                                message: getMessage(
                                    'validation_max_length_exceeded',
                                    255
                                ),
                            },
                        })}
                    />
                    {bringIntegration.errors.customerNumber && (
                        <p className="atl-text-red-120 atl-text-left">
                            {!bringIntegration.watch('moduleBring')
                                ? ''
                                : bringIntegration.errors.customerNumber
                                      .message}
                        </p>
                    )}
                </Group>

                <Group>
                    <Label htmlFor="credentials">
                        {getMessage('text_email_or_username')} *
                    </Label>
                    <Input
                        id="credentialsEmailId"
                        type="email"
                        disabled={!bringIntegration.watch('moduleBring')}
                        className="atl-min-w-0"
                        data-testid="credentials-email-input"
                        {...bringIntegration.register('username', {
                            required: {
                                value: bringIntegration.watch('moduleBring')
                                    ? true
                                    : false,
                                message: getMessage('validation_missing'),
                            },
                            onBlur: (event) => {
                                const value = event.target.value;
                                const confirmationEmail =
                                    bringIntegration.getValues(
                                        'confirmationEmail'
                                    );
                                if (!confirmationEmail) {
                                    bringIntegration.setValue(
                                        'confirmationEmail',
                                        value
                                    );
                                }
                            },
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                message: getMessage('validation_invalid_email'),
                            },
                        })}
                    />
                    {bringIntegration.errors.username && (
                        <p className="atl-text-red-120 atl-text-left">
                            {!bringIntegration.watch('moduleBring')
                                ? ''
                                : bringIntegration.errors.username.message}
                        </p>
                    )}
                </Group>
                <Group>
                    <Label htmlFor="API-key">
                        {getMessage('tooltip_robot_user')} *
                    </Label>
                    <Input
                        id="API-keyId"
                        type="password"
                        disabled={!bringIntegration.watch('moduleBring')}
                        className="atl-min-w-0"
                        data-testid="API-key-input"
                        {...bringIntegration.register('apiKey', {
                            required: {
                                value: bringIntegration.watch('moduleBring')
                                    ? true
                                    : false,
                                message: getMessage('validation_missing'),
                            },
                            maxLength: {
                                value: 255,
                                message: getMessage(
                                    'validation_max_length_exceeded',
                                    255
                                ),
                            },
                        })}
                    />
                    {bringIntegration.errors.apiKey && (
                        <p className="atl-text-red-120 atl-text-left">
                            {!bringIntegration.watch('moduleBring')
                                ? ''
                                : bringIntegration.errors.apiKey.message}
                        </p>
                    )}
                </Group>
                <Group>
                    <Label htmlFor="confirmationEmail">
                        {getMessage('text_send_confirmation_to')} *
                    </Label>
                    <Input
                        id="confirmationEmail"
                        type="email"
                        disabled={!bringIntegration.watch('moduleBring')}
                        className="atl-w-full"
                        data-testid="confirmationEmail-input"
                        {...bringIntegration.register('confirmationEmail', {
                            required: {
                                value: bringIntegration.watch('moduleBring')
                                    ? true
                                    : false,
                                message: getMessage('validation_missing'),
                            },
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                message: getMessage('validation_invalid_email'),
                            },
                        })}
                    />
                    {bringIntegration.errors.confirmationEmail && (
                        <p className="atl-text-red-120 atl-text-left">
                            {!bringIntegration.watch('moduleBring')
                                ? ''
                                : bringIntegration.errors.confirmationEmail
                                      .message}
                        </p>
                    )}
                </Group>
            </div>
        </div>
    );
};
