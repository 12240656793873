import { SortingType } from '@Page/LogisticsV2/utils/enums';
import { Dispatch, ReactNode, SetStateAction } from 'react';
import { PurchaseOverviewOption } from '@Page/LogisticsV2/pages/PurchaseOrder/overview/types';
import { SortableTableHeader } from '@tlx/atlas';
import {
    getSortDirection,
    getSorting,
} from '@Page/LogisticsV2/utils/functions';

export function PurchaseOrderOverviewHeaderCell({
    column,
    sortBy,
    onChangeSortBy,
}: {
    column: string;
    sortBy: SortingType;
    onChangeSortBy: Dispatch<SetStateAction<SortingType>>;
}): JSX.Element {
    switch (column) {
        case 'status':
            return (
                <StatusHeader sortBy={sortBy} onChangeSortBy={onChangeSortBy} />
            );
        case 'supplier':
            return (
                <SupplierHeader
                    sortBy={sortBy}
                    onChangeSortBy={onChangeSortBy}
                />
            );
        case 'supplierInvoice':
            return <SupplierInvoice />;
        case 'projectOrder':
            return (
                <ProjectOrderHeader
                    sortBy={sortBy}
                    onChangeSortBy={onChangeSortBy}
                />
            );
        case 'sentDate':
            return <SentDate />;
        case 'deliveryDate':
            return (
                <DeliveryDate sortBy={sortBy} onChangeSortBy={onChangeSortBy} />
            );
        case 'contact':
            return <ContactHeader />;
        case 'totalAmount':
            return <TotalAmountHeader />;
        case 'currency':
            return <CurrencyHeader />;
        case 'internalComment':
            return <InternalCommentHeader />;
        case 'orderLines':
            return <></>;
        default:
            throw new Error(`Unknown setting: ${column}`);
    }
}

export function Optional({
    id,
    columns,
    children,
}: {
    id: string;
    columns: PurchaseOverviewOption[];
    children: ReactNode;
}) {
    const column = columns.find((item) => item.displayName === id);
    return <>{column !== undefined && column.enabled ? children : null}</>;
}

function StatusHeader({
    sortBy,
    onChangeSortBy,
}: {
    sortBy: SortingType;
    onChangeSortBy: Dispatch<SetStateAction<SortingType>>;
}) {
    return (
        <SortableTableHeader
            data-testid="statusHeader"
            className="atl-font-bold atl-align-middle"
            sortDirection={getSortDirection(
                sortBy,
                SortingType.STATUS_ASCENDING,
                SortingType.STATUS_DESCENDING
            )}
            onClick={() =>
                onChangeSortBy(
                    getSorting(
                        sortBy,
                        SortingType.STATUS_ASCENDING,
                        SortingType.STATUS_DESCENDING
                    )
                )
            }
        >
            {getMessage('text_status')}
        </SortableTableHeader>
    );
}

function SupplierHeader({
    sortBy,
    onChangeSortBy,
}: {
    sortBy: SortingType;
    onChangeSortBy: Dispatch<SetStateAction<SortingType>>;
}) {
    return (
        <SortableTableHeader
            className="atl-font-bold atl-align-middle"
            data-testid="supplierHeader"
            sortDirection={getSortDirection(
                sortBy,
                SortingType.SUPPLIER_ASCENDING,
                SortingType.SUPPLIER_DESCENDING
            )}
            onClick={() =>
                onChangeSortBy(
                    getSorting(
                        sortBy,
                        SortingType.SUPPLIER_ASCENDING,
                        SortingType.SUPPLIER_DESCENDING
                    )
                )
            }
        >
            {getMessage('text_supplier')}
        </SortableTableHeader>
    );
}

function SupplierInvoice() {
    return (
        <th
            className="atl-th atl-font-bold atl-align-middle"
            key="supplierInvoiceHeader"
        >
            {getMessage('text_supplier_invoice')}
        </th>
    );
}

function ProjectOrderHeader({
    sortBy,
    onChangeSortBy,
}: {
    sortBy: SortingType;
    onChangeSortBy: Dispatch<SetStateAction<SortingType>>;
}) {
    return (
        <SortableTableHeader
            className="atl-font-bold atl-align-middle"
            data-testid="projectHeader"
            sortDirection={getSortDirection(
                sortBy,
                SortingType.PROJECT_ORDER_ASCENDING,
                SortingType.PROJECT_ORDER_DESCENDING
            )}
            onClick={() =>
                onChangeSortBy(
                    getSorting(
                        sortBy,
                        SortingType.PROJECT_ORDER_ASCENDING,
                        SortingType.PROJECT_ORDER_DESCENDING
                    )
                )
            }
        >
            {moduleProject
                ? getMessage('text_project_or_order')
                : getMessage('text_order')}
        </SortableTableHeader>
    );
}

function SentDate() {
    return (
        <th
            className="atl-th atl-font-bold atl-align-middle"
            key="sentDateHeader"
        >
            <div className="atl-flex atl-items-center atl-gap-8">
                {getMessage('text_sent_date')}
            </div>
        </th>
    );
}

function DeliveryDate({
    sortBy,
    onChangeSortBy,
}: {
    sortBy: SortingType;
    onChangeSortBy: Dispatch<SetStateAction<SortingType>>;
}) {
    return (
        <SortableTableHeader
            className="atl-font-bold atl-align-middle"
            data-testid="deliveryDateHeader"
            sortDirection={getSortDirection(
                sortBy,
                SortingType.DELIVERY_DATE_ASCENDING,
                SortingType.DELIVERY_DATE_DESCENDING
            )}
            onClick={() =>
                onChangeSortBy(
                    getSorting(
                        sortBy,
                        SortingType.DELIVERY_DATE_ASCENDING,
                        SortingType.DELIVERY_DATE_DESCENDING
                    )
                )
            }
        >
            {getMessage('text_delivery_date')}
        </SortableTableHeader>
    );
}

function ContactHeader() {
    return (
        <th
            className="atl-th atl-font-bold atl-align-middle"
            key="contactHeader"
        >
            {getMessage('text_contact')}
        </th>
    );
}

function TotalAmountHeader() {
    return (
        <th
            className="atl-th atl-font-bold atl-align-middle atl-text-right"
            key="totalPriceHeader"
        >
            {getMessage('text_total_amount')}
        </th>
    );
}

function CurrencyHeader() {
    return (
        <th
            className="atl-th atl-font-bold atl-align-middle"
            key="currencyHeader"
        >
            {getMessage('text_currency')}
        </th>
    );
}

function InternalCommentHeader() {
    return (
        <th
            className="atl-th atl-font-bold atl-align-middle"
            key="internalCommentHeader"
        >
            {getMessage('text_remark_description_label')}
        </th>
    );
}
