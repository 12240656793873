import { useListSearchParam, useSearchParam } from '@tlx/astro-shared';
import {
    ALL_LOCATIONS,
    ALL_PRODUCT_GROUPS,
    ProductLineStatus,
} from '@Page/LogisticsV2/pages/Stocktaking/utils';
import { SortingType } from '@Page/LogisticsV2/utils/enums';
import useSessionStorage from '@Page/LogisticsV2/hooks/useSessionStorage';

export function useStocktakingDetailsTableFilters() {
    const queryFilter = useSearchParam('query');
    const productGroupsFilter = useListSearchParam('productGroups', [
        ALL_PRODUCT_GROUPS,
    ]);
    const locationFilter = useListSearchParam('location', [ALL_LOCATIONS]);
    const [statusFilter] = useSessionStorage(
        `stocktakingDetailsProductLineStatus-${window.loginEmployeeId}`,
        ProductLineStatus.ALL_PRODUCTS
    );
    const [sorting] = useSessionStorage(
        `stocktakingDetailsSortingBy-${window.loginEmployeeId}`,
        SortingType.NAME_ASCENDING
    );

    return {
        queryFilter,
        productGroupsFilter,
        locationFilter,
        sorting,
        statusFilter,
    };
}
