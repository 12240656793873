import jQuery from 'jquery';

export const dom = (function (window, $) {
    function hideClass(className, scope) {
        $(className, scope).hide();
        $(scope || document).trigger('tlxPossibleWidthChange');
    }

    function showClass(className, scope) {
        // Some elements should have other display: values than block
        // jQuery have no means of knowing this if the element is
        // hidden with .hide, therefore we remove
        // this class first.
        $(className, scope).removeClass('hide').show();
        $(scope || document).trigger('tlxPossibleWidthChange');
    }

    return {
        hideClass: hideClass,
        showClass: showClass,
    };
})(window, jQuery);
