import { ModelAPIV1 } from '@General/types/Api';
import { ApiResultMapperNoUndefinedResult } from '@General/Provider';
import { notUndefined } from '@General/Helpers';
import { TlxListItem } from '@Component/DropDown/DropDown';
import * as React from 'react';
import { JSPDropdownFetcher } from '../JSPDropdownFetcher';
import { Option } from '../../types';

interface ProductWarehouseEntry extends ModelAPIV1 {
    readonly displayName: string;
    readonly isConnectedToProduct: boolean;
}

const productWarehouseMapper: ApiResultMapperNoUndefinedResult<ProductWarehouseEntry> =
    (item) => ({
        value: `${item.id}`,
        displayName: item.displayName,
        isConnectedToProduct: item.isConnectedToProduct,
        item,
    });

const DesktopListItem: TlxListItem<ProductWarehouseEntry> = (props) => {
    if (!props.option.item) {
        return (
            <div className="txr-dropdown__row txr-dropdown__list-item">
                <div className="txr-dropdown__item-cell txr-dropdown__item-cell--default">
                    {props.option.displayName}
                </div>
            </div>
        );
    }
    return (
        <div className="txr-dropdown__row txr-dropdown__list-item">
            <div
                className="txr-dropdown__item-cell txr-dropdown__item-cell--description"
                title={props.option.item.displayName}
            >
                {props.option.item.displayName}
            </div>
            <div className="txr-dropdown__item-cell txr-dropdown__item-cell--checkbox">
                {props.option.item.isConnectedToProduct && (
                    <span className="checkboxOn">done</span>
                )}
            </div>
        </div>
    );
};

const Headers: React.FC = () => (
    <div className="txr-dropdown__row">
        <div className="txr-dropdown__item-cell txr-dropdown__item-cell--header  txr-dropdown__item-cell--description">
            {getMessage('text_description')}
        </div>
        <div
            className="txr-dropdown__item-cell txr-dropdown__item-cell--header txr-dropdown__item-cell--checkbox txr-dropdown__item-cell--allow-wrap"
            title={getMessage('text_product_warehouse_location')}
        >
            {getMessage('text_product_warehouse_location')}
        </div>
    </div>
);

class ProductWarehouseFetcher extends JSPDropdownFetcher<ProductWarehouseEntry> {
    asyncJsonrpcGetter: Function;

    constructor(scope: HTMLDivElement, queryAttributes: Record<string, any>) {
        super(scope, queryAttributes, productWarehouseMapper);
        this.asyncJsonrpcGetter = asyncrpc.ProductWarehouse.get;
    }

    getDesktopListItem(): TlxListItem<ProductWarehouseEntry> {
        return DesktopListItem;
    }

    getMarshallSpec() {
        return window.marshallSpec('id', 'displayName', 'isConnectedToProduct');
    }

    getParams(): ObjectIndex {
        return {
            isActive: this.getQueryAttribute('isActive', 1),
            productId: this.getQueryAttribute('productElementId', -1),
        };
    }

    async fetchList(
        params: ObjectIndex
    ): Promise<Option<ProductWarehouseEntry>[]> {
        return new Promise((resolve, reject) => {
            try {
                jsonrpc.ProductWarehouse.searchForWarehouseTlxSelect(
                    (result: ProductWarehouseEntry[] | null) => {
                        const mapped = (result ?? [])
                            .filter(notUndefined)
                            .map(productWarehouseMapper)
                            .filter(notUndefined);
                        resolve(mapped);
                    },
                    this.getMarshallSpec(),
                    window.loginCompanyId,
                    params.isActive,
                    params.productId,
                    params.name || ''
                );
            } catch (e) {
                defaultJsonRpcExceptionHandler(e);
                reject(e);
            }
        });
    }
    getHeaders() {
        return Headers;
    }
}

export const exports = {
    fetcher: ProductWarehouseFetcher,
    type: 'ProductWarehouse',
};
