import { captureMessage } from '@sentry/browser';

import { renderComponent } from '@General/renderComponent';

import { ExportMenu } from './component/ExportMenu';

export function renderExportMenu(target: HTMLElement | null) {
    if (target) {
        renderComponent(ExportMenu, target, undefined, true);
    } else {
        captureMessage('Rendering target for ExportMenu not found');
    }
}
