import jQuery from 'jquery';
import { hasRoute, renderRoute } from '@General/Router';
import { tlxUrl } from '../../js/modules/url';
import { browserInfo } from '../../js/modules/d-browserInfo.ts';
import { tlxForms } from '../../js/modules/forms';
import { tlxGetScope } from '../../js/c-common';

const $ = jQuery;

/**
 * https://tripletex.atlassian.net/wiki/display/DOC/popupOpener
 *
 * Dialog is constructed lazily when the opener is first clicked. (TODO: add option to override?)
 *
 * 'dialogContent' should initially be hidden.
 */
(function () {
    /* Used for getting to original scroll position
     * when dialogs are closed. */
    var scrollPosition = [];

    $.widget('tlx.popupOpener', {
        // default options
        options: {
            click: null, // function() where 'this' == dialog element
            clickValidation: null,
            closeText: null,
            buttonText: null,
            buttonTestId: 'dialog-main-button',
            buttonTrackingId: null,
            closeTestId: 'dialog-close-button',
            closeTrackingId: null,
            formTestId: 'dialog-form',
            formTrackingId: null,
            disabled: false,
            dialogContent: null,
            extraInfo: '',
            showExtraInfo: $.noop, //If true, "extraInfo" will be displayed in the dialog. If any string, this string will be displayed //TODO should support booleans as well as functions
            hideClickButton: $.noop, //If true, click button will be removed. //TODO should support booleans as well as functions
            loadingMessage: '', //TODO remove after Nytt Design beta
            title: null,
            url: null,
            storeAction: false,
            appendDialogToScope: true, // should the dialog be appended to the openers scope? This is probably what you want unless the dialog is completely self-contained (eg. +dialogs).
            autoOpen: false,
            clientsideValidation: true,
            mainButtonClasses: '',
            disableMainButton: false,
            hideButtons: false,
            noPadding: false,
        },
        _validation: function (dialogElement) {
            var isValid = this.options.clientsideValidation
                ? $(dialogElement)
                      .findFormInput()
                      .not(':hidden')
                      .not(':disabled')
                      .validate($(dialogElement).validationPopup())
                : true;
            var clickValidation = this.options.clickValidation;
            if (clickValidation) {
                if (!clickValidation.call(dialogElement)) {
                    isValid = false;
                }
            }
            return isValid;
        },
        // the constructor
        _create: function () {
            this._dialogConstructed = false;
            this.element.addClass(
                'tlx-popup-opener' +
                    (this.options.disabled ? ' ui-state-disabled' : '')
            );
            //		if(this.options.disabled){
            //			this.element.addClass("ui-state-disabled");
            //		}
            this._originalContent = this.element.val() || this.element.text();
            if (this.element.val().length > 0) {
                this.element
                    .val(this._originalContent + '\u2026')
                    .addClass('tlx-popup-valappend');
            }

            var $button = this.element;
            var self = this;
            this._extraInfo = $(
                "<span class='warningMessage tlx-popup-extrainfo'></span>"
            );
            $button.bind('click.tlxPopupOpener', function () {
                if (self.options.disabled) {
                    return;
                }
                self._dialogSetupAndOpening(self);
            });
            if (this.options.autoOpen) {
                self._dialogSetupAndOpening(self);
            }
            this.element.bind(
                'disableEvent.tlxPopupOpener',
                function (ev, disabled) {
                    if (disabled) {
                        self.disable();
                    } else {
                        self.enable();
                    }
                }
            );
            this._refresh();
        },
        _dialogSetupAndOpening: function (self) {
            var $dialog = self._getDialog();
            var $buttonPane = $dialog
                .dialog('widget')
                .find('.ui-dialog-buttonpane');
            if (self.options.hideButtons) {
                $buttonPane.addClass('hidden');
            }
            if (
                self.options.showExtraInfo &&
                self.options.showExtraInfo(self)
            ) {
                var showExtraInfo = self.options.showExtraInfo(self);
                var displayMsg = self.options.extraInfo;
                if (typeof showExtraInfo === 'string') {
                    displayMsg = showExtraInfo;
                }

                self._extraInfo
                    .empty()
                    .addClass('txr-message')
                    .prependTo($dialog)
                    .append(
                        '<i class="material-icons txr-message-icon">&#xE002;</i>'
                    )
                    .append(
                        '<span class="txr-message-text">' +
                            displayMsg +
                            '</span>'
                    )
                    .show();

                $dialog
                    .find('input:not([disabled])')
                    .addClass('disabledByPopupOpener');
                tlxForms.disable($dialog.find('input'));
            } else {
                self._extraInfo.hide();
                tlxForms.disable(
                    $dialog.find('input.disabledByPopupOpener'),
                    false
                );
            }
            if (
                self.options.hideClickButton &&
                self.options.hideClickButton(self)
            ) {
                $buttonPane.find('.tlx-green').hide();
            } else {
                $buttonPane.find('.tlx-green').show();
            }
            if (self.options.appendDialogToScope && !window.narrowScreen) {
                // Make sure that the dialog can be moved outdside of scrollcontainer.
                // But not on narrow screen, we do want to be able to scroll up/down in large dialogs in small screens
                $dialog.dialog('widget').css('position', 'fixed');
            }
            if (self.options.formTrackingId) {
                $dialog
                    .dialog('widget')
                    .attr('data-testid', self.options.formTestId)
                    .attr('data-formid', self.options.formTrackingId);
            }
            $dialog.dialog('widget').addClass('override-position-fixed');

            $dialog.dialog('open');
        },
        _constructDialog: function () {
            var self = this;
            var $popupContent;
            var maxHeight = $(window).height() - 30;

            if (!window.narrowScreen) {
                // We had a bug where dialogs became larger than the window.
                // Restrict dialog height even more with the magic number 100.
                maxHeight = $('.mdl-layout__content').height() - 100;
            }
            if (self.options.url) {
                const style = self.options.noPadding
                    ? ' style="padding: 0;" '
                    : '';
                //generate unique ID
                var dialogId = '';
                var i = 0;
                do {
                    dialogId = 'tlxAjaxDialog' + i;
                    $popupContent = $('#' + dialogId);
                    if ($popupContent.length === 0) {
                        $popupContent = $(
                            "<div id='" +
                                dialogId +
                                "' class='ui-helper-hidden tlxScope'" +
                                style +
                                '></div>'
                        ).appendTo('body');
                    }
                } while ($popupContent.is(':visible') && ++i < 5);
                if (i >= 5) {
                    throw 'Too many dialogs open. Maximum is set to ' + i;
                }
            } else {
                $popupContent = $(self.options.dialogContent);
            }

            var dialogPosition;
            if (self.options.appendDialogToScope) {
                if (window.isNarrowScreen) {
                    dialogPosition = {
                        my: 'center top',
                        at: 'center top+60',
                        of: window,
                    };
                } else {
                    dialogPosition = {
                        my: 'center center',
                        at: 'center center',
                        of: window,
                    };
                }
            }

            var dialogOptions = {
                // Do not use modal modus on chrome with ios. Sometimes the widget overlay gets above the dialog.
                modal: !(browserInfo.isChrome() && browserInfo.isIphone()),
                autoOpen: false,
                buttons: self._createButtons(),
                width: 'none',
                title: self.options.title || self.options.buttonText,
                appendTo: self.options.appendDialogToScope
                    ? tlxGetScope(self.element)
                    : undefined,
                position: dialogPosition,
                beforeClose: function () {
                    if ($(this).is(':tlx-validationPopup'))
                        $(this)
                            .validationPopup('clear')
                            .validationPopup('close');
                },
                maxHeight: maxHeight,
            };

            if (window.narrowScreen) {
                dialogOptions.maxHeight = 'initial';
                dialogOptions.width = '100vw';
                dialogOptions.open = function () {
                    scrollPosition.push($(document).scrollTop());
                };
                dialogOptions.close = function () {
                    $(document).scrollTop(scrollPosition.pop());
                };
            }

            $popupContent
                .data('popupOpener', self.element)
                .data('ajax', !!self.options.url)
                .data('scope-base-url', self.options.url)
                .dialog(dialogOptions);

            if (self.options.disableMainButton) {
                $('.dialogMainButton').button('disable');
            }

            $popupContent.wrap('<div class="popup-content-wrapper" />');

            self._dialogElement = $popupContent;
            if (self.options.url) {
                // If the dialog isn't destroyed, the next opened "url dialog" will get wrong size (and possible other issues)
                // (since the "url/+ dialogs" shares container)
                self._dialogElement.bind('dialogclose', function () {
                    self._destroyDialog();
                });
            }

            // Loading should happen after the dialog exists, otherwise it is a race condition.
            if (self.options.url) {
                var urlString = $.isFunction(self.options.url)
                    ? self.options.url.call(null, self.element)
                    : self.options.url;

                if (hasRoute(urlString)) {
                    self._loadReact(
                        $popupContent,
                        urlString,
                        dialogId,
                        maxHeight
                    );
                } else {
                    self._loadUrl(
                        $popupContent,
                        urlString,
                        dialogId,
                        maxHeight
                    );
                }
            }

            self._dialogConstructed = true;
        },
        _loadReact: function ($popupContent, urlString, dialogId, maxHeight) {
            const reactContainerId = dialogId + 'react-container';
            const $reactContainer = $('<div>', {
                id: reactContainerId,
            });
            $popupContent.append($reactContainer);
            renderRoute(urlString, reactContainerId, $popupContent, false, {
                prefix: dialogId,
            });

            this._onPageLoaded($popupContent, maxHeight);
        },
        _loadUrl: function ($popupContent, urlString, dialogId, maxHeight) {
            var $spinner = $(
                '<div class="mdl-spinner mdl-js-spinner is-active tmdl-dialog-spinner"></div>'
            );
            $popupContent.append($spinner);
            componentHandler.upgradeElement($spinner[0]);
            const self = this;

            $popupContent.load(
                tlxUrl.addUrlParameter(urlString, 'scope', dialogId),
                function (responseText, status, xhr) {
                    var dialog = this;
                    if (xhr.status === 401) {
                        // See global ajaxError handler too
                        $popupContent.dialog('close');
                        return;
                    }

                    if (
                        xhr.status === 0 ||
                        (xhr.status >= 500 && xhr.status < 600)
                    ) {
                        var event = jQuery.Event('tlxCommunicationError');
                        event.xhr = xhr;
                        $popupContent.trigger(event);
                        // TODO: Close popup? If not necessary, delete this TODO.
                        return;
                    }

                    if (status === 'error') {
                        // In 90%+ of the cases the content will be a error report page that are supposed to be shown
                        // The dialog might look a bit strange though, but since this isn't something that should happen often
                        // I don't put any more work into it now.
                        $(dialog)
                            .html(responseText)
                            .dialog('option', 'title', 'Error'); // must set title, otherwise the dialog decoration doesn't look right.
                        return; // but bailing early is probably best..
                    }

                    self._onPageLoaded($popupContent, maxHeight);
                }
            );
        },
        _onPageLoaded: function ($popupContent, maxHeight) {
            const self = this;
            if (
                !window.narrowScreen &&
                $popupContent.parent().height() > maxHeight
            ) {
                $popupContent.dialog('option', {
                    height: maxHeight,
                    width: 'auto',
                });
            } else {
                // fix for centering some dialogs.
                var buttons = self._createButtons();
                self._dialogElement.dialog('option', 'buttons', buttons);
            }

            $popupContent.show();
            self._trigger('load', null, $popupContent);
            $popupContent.trigger('tlxPopupIsLoaded');

            //Check if dialog is too fare to the left (behind the menu) pop to the right.
            var dialogOuter = $(this._dialogElement).closest('.ui-dialog');
            var layout = $('.mdl-layout');
            if (
                layout.hasClass('insetOpen') &&
                !layout.hasClass('is-small-screen') &&
                !window.narrowScreen
            ) {
                //240 is menu width + padding (_nav.scss)
                if (parseInt($(dialogOuter).css('left')) < 240) {
                    //24 is the padding of #wrapperDiv (_layout.scss)
                    var layoutInset = $('.tmdl-layout__inset'); // agro24 does not have this.
                    if (layoutInset.length > 0) {
                        self._dialogElement.dialog('option', 'position', {
                            my: 'left',
                            at: 'right+24',
                            of: layoutInset,
                        });
                    }
                }
            }
        },
        _createButtons: function () {
            var dialogButtons = [];
            var mB = this._createMainButton(
                this.options.buttonText,
                this.options.click,
                this.options.buttonTestId,
                this.options.buttonTrackingId
            );
            var cB = this._createCloseButton(
                this.options.closeText,
                this.options.closeTestId,
                this.options.closeTrackingId
            );
            if (cB) {
                dialogButtons.push(cB);
            }
            if (mB) {
                dialogButtons.push(mB);
            }
            return dialogButtons;
        },
        _createCloseButton: function (closeText, testId, trackingId) {
            if (!closeText) {
                return;
            }
            return {
                class: 'js-closeDialogButton',
                text: closeText,
                click: function () {
                    var dialogElem = this;
                    $(dialogElem).dialog('close');
                },
                icons: { primary: 'ui-icon-close' },
                'data-testid': testId,
                'data-trackingid': trackingId,
            };
        },
        _createMainButton: function (text, click, testId, trackingId) {
            if (!text || !click) {
                return null;
            }
            var self = this;
            return {
                text: text,
                click: function (ev) {
                    var dialogElem = this; // ref: http://api.jqueryui.com/dialog/#option-buttons
                    if (self._validation(dialogElem)) {
                        click.call(dialogElem);
                    }
                    // Was added (I think) to prevent the click event (bound on document) to close the possible opened infoPopup/validationPopup...
                    // That is now handled by the infoPopup/validationPopup widget, so it should be safe to remove this (2012-06-08)
                    ev.stopPropagation();
                },
                class:
                    'tlx-green dialogMainButton' +
                    (self.options.storeAction ? ' storeAction' : '') +
                    ' ' +
                    self.options.mainButtonClasses,
                'data-testid': testId,
                'data-trackingid': trackingId,
                style: 'background-color: rgb(10,65,250); color: white; margin-right:8px;',
            };
        },
        // called when created, and later when changing options
        _refresh: function () {
            var self = this;
            self.options.buttonText =
                self.options.buttonText || self._originalContent || null;
            if (
                self.options.dialogContent === null &&
                self.options.url === null
            ) {
                self.destroy();
                throw 'Content must be specified with either dialogContent or url.';
            }
            if (
                self.options.click !== null &&
                self.options.buttonText === null
            ) {
                self.destroy();
                throw 'buttonText are required if click is defined.';
            }

            // trigger a callback/event
            self._trigger('change');
        },
        //Closes the dialog
        close: function () {
            //TODO check if open?
            if (this._dialogConstructed) {
                this._dialogElement.dialog('close');
            }
        },
        // events bound via _bind are removed automatically
        // revert other modifications here
        destroy: function () {
            // remove generated elements
            // this.toolbar.remove();

            this.element
                .removeClass('tlx-popup-opener')
                .unbind('.tlxPopupOpener');

            this._destroyDialog();

            if (this.element.hasClass('tlx-popup-valappend')) {
                //TODO check if actual character is elipsis;
                this.element
                    .removeClass('tlx-popup-valappend')
                    .val(this._originalContent);
            } else if (this.element.hasClass('tlx-popup-textappend')) {
                //TODO check if actual character is elipsis;
                this.element
                    .removeClass('tlx-popup-textappend')
                    .text(this._originalContent);
            } else if (this.element.hasClass('tlx-popup-uitextappend')) {
                this.element
                    .removeClass('tlx-popup-textappend')
                    .children('.ui-button-text')
                    .text(this._originalContent);
            }

            this._super();
        },
        _destroyDialog: function () {
            if (this._dialogConstructed) {
                const $dialogElement = this._dialogElement;
                $dialogElement.removeData('popupOpener');

                if ($dialogElement.dialog('instance') !== undefined) {
                    $dialogElement.dialog('close');
                    $dialogElement.dialog('destroy');
                }

                if (this.options.url) {
                    this._dialogElement
                        .trigger('tlxRemoveUpgradedMdlComponents')
                        .empty()
                        .remove();
                }
                this._dialogConstructed = false;
            }
        },
        //Gets the dialog, constructs if needed.
        _getDialog: function () {
            if (!this._dialogConstructed || this.options.url) {
                if (this._dialogConstructed) this._dialogElement.empty();
                this._constructDialog();
            }
            return this._dialogElement;
        },

        // _setOptions is called with a hash of all options that are changing
        // always refresh when changing options
        _setOptions: function () {
            this._superApply(arguments);
            this._refresh();
        },

        // _setOption is called for each individual option that is changing
        _setOption: function (key, value) {
            this._super(key, value);
            if (!this._dialogConstructed) {
                return;
            }
            switch (key) {
                case 'buttonText':
                    this._dialogElement.dialog(
                        'option',
                        'title',
                        this.options.title || value
                    );
                    var buttons = this._createButtons(
                        this.options.buttonText,
                        this.options.click,
                        this.options.closeText
                    );
                    this._dialogElement.dialog('option', 'buttons', buttons);
                    break;
                case 'closeText':
                case 'click':
                    buttons = this._createButtons(
                        this.options.buttonText,
                        this.options.click,
                        this.options.closeText
                    );
                    this._dialogElement.dialog('option', 'buttons', buttons);
                    break;
                case 'title':
                    this._dialogElement.dialog(
                        'option',
                        'title',
                        value || this.options.buttonText
                    );
                    break;
                case 'dialogContent':
                case 'url':
                    this._destroyDialog();
                    break;
                case 'disabled':
                case 'clickValidation':
                case 'loadingMessage':
                //No action required
            }
        },
    });
})();
