import React from 'react';
import {
    Button,
    ChevronDownIcon,
    ChevronUpIcon,
    DeleteIcon,
    Modal,
    ModalTitle,
    SettingsIcon,
} from '@tlx/atlas';
import { DataTreeNode } from '@Page/LogisticsV2/components/DataTree/types';
import { MultipleSelectionProductGroups } from '@Page/LogisticsV2/components/DataTree/MultipleSelectionProductGroups';
import EmptyState from '@Component/EmptyState/EmptyState';

export const ProductGroupCartHeader = ({
    onClick,
    isExpanded,
}: {
    onClick: () => void;
    isExpanded: boolean;
}) => {
    return (
        <div
            className={
                'atl-flex atl-p-16 atl-justify-between goldshark-border-bottom-grey-20'
            }
        >
            <div className={'atl-flex atl-items-center atl-text-xl'}>
                {getMessage('text_product_groups')}
            </div>
            <div>
                <a
                    data-testid={'product-groups-settings'}
                    href={`/execute/productSettingsMenu?contextId=${contextId}#productGroupSettings`}
                >
                    <SettingsIcon />
                </a>
                <Button
                    data-testid={'expand-product-group-cart-button'}
                    variant="tertiary"
                    onClick={() => onClick()}
                >
                    {isExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
                </Button>
            </div>
        </div>
    );
};

export const ProductGroupCartRelationRow = ({
    data,
    handleDelete,
}: {
    data: DataTreeNode;
    handleDelete: (id: number) => void;
}) => {
    return (
        <div
            style={{ height: '41px' }}
            className={
                'goldshark-border-bottom-grey-20 goldshark-relation-title atl-flex atl-px-16 atl-items-center'
            }
        >
            {data.prefix} {data.name}
            <Button
                data-testid={'delete-productgroup-button'}
                variant="icon"
                onClick={async () => {
                    await handleDelete(data.id);
                }}
                aria-label={'delete'}
                className={'atl-ml-auto goldshark-hidden'}
            >
                <DeleteIcon />
            </Button>
        </div>
    );
};

export const ProductGroupCartSelectionModal = ({
    treeNodes,
    selectedNodes,
    onConfirm,
    onCancel,
    onChange,
}: {
    treeNodes: DataTreeNode[] | undefined;
    selectedNodes: number[];
    onConfirm: () => void;
    onCancel: () => void;
    onChange: (nodeId: number) => void;
}) => {
    return (
        <Modal open={true} className={'goldshark-product-cart-modal'}>
            <ModalTitle>{getMessage('text_product_groups')}</ModalTitle>
            {treeNodes !== undefined && treeNodes.length > 0 && (
                <MultipleSelectionProductGroups
                    nodes={treeNodes ?? []}
                    selectedNodes={selectedNodes}
                    onChange={onChange}
                    className={'atl-border atl-border-grey-20'}
                />
            )}
            {treeNodes !== undefined && treeNodes.length === 0 && (
                <EmptyState
                    icon="folder"
                    title={getMessage('text_no_productgroups')}
                />
            )}
            <div className={'atl-flex atl-gap-8 atl-my-8'}>
                <Button
                    onClick={onConfirm}
                    data-testid={'confirm-productgroup'}
                >
                    {getMessage('text_confirm')}
                </Button>
                <Button
                    data-testid={'cancel-productgroup'}
                    onClick={onCancel}
                    variant={'secondary'}
                >
                    {getMessage('button_cancel')}
                </Button>
            </div>
        </Modal>
    );
};
