import { postNotificationMessage } from '@General/LegacyNotification';
import { postValidationMessages } from '@General/LegacyValidationPopup';
import { invokeAPI } from '@Page/LogisticsV2/hooks/api';
import { ApiUrl, MethodType } from '@Page/LogisticsV2/utils/enums';
import {
    getURL,
    getValidationMessagesFromResponse,
} from '@Page/LogisticsV2/utils/functions';
import { mapObjectToURLSearchParams } from '@Page/LogisticsV2/utils/mappers';
import { SimplifiedGoodsReceiptModel } from './types';

export function usePurchaseOrderOverviewAction(onRefresh: () => void) {
    const handleDeletePurchaseOrder = async (id: number, number: number) => {
        const { error } = await invokeAPI(
            `${ApiUrl.PURCHASE_ORDER}/${id}`,
            MethodType.DELETE
        );
        if (error === undefined) {
            onRefresh();
            postNotificationMessage(
                getMessage('text_purchase_order'),
                [
                    `${getMessage('text_action_completed')} ${getMessage(
                        'text_delete'
                    )}`,
                    `${getMessage('text_purchase_order')}: ${getMessage(
                        'th_number_short'
                    )} ${number}`,
                ],
                new Date()
            );
        } else {
            getValidationMessagesFromResponse(error);
        }
    };

    const handleDeleteGoodsReceipt = async (id: number, number: number) => {
        const { data } = await invokeAPI(
            `${ApiUrl.PURCHASE_ORDER_GOODS_RECEIPT}/${id}`,
            MethodType.GET
        );
        const dataJson = await data?.json();
        const { error } = await invokeAPI(
            `${ApiUrl.PURCHASE_ORDER_GOODS_RECEIPT}/${dataJson.value.id}`,
            MethodType.DELETE
        );
        if (error === undefined) {
            onRefresh();
            postNotificationMessage(
                getMessage('text_goods_receipt'),
                [
                    `${getMessage('text_action_completed')} ${getMessage(
                        'text_delete'
                    )}`,
                    `${getMessage('text_goods_receipt')}: ${getMessage(
                        'th_number_short'
                    )} ${number}`,
                ],
                new Date()
            );
        } else {
            getValidationMessagesFromResponse(error);
        }
    };

    const handleSendPurchaseOrder = async (id: number, number: number) => {
        const { error } = await invokeAPI(
            `${ApiUrl.PURCHASE_ORDER}/${id}/:send`,
            MethodType.PUT
        );
        if (error !== undefined) {
            if (error.validationMessages.length > 0) {
                postValidationMessages(
                    getMessage('text_purchase_order') +
                        ' ' +
                        number +
                        ' : ' +
                        error.validationMessages[0].message
                );
            }
        } else {
            postNotificationMessage(
                getMessage('text_purchase_order'),
                [
                    `${getMessage('text_action_completed')} ${getMessage(
                        'text_send_order_out'
                    )}`,
                ],
                new Date()
            );
            onRefresh();
        }
    };

    const handleSimplifiedGoodsReceipt = async (
        id: number,
        number: number,
        simplifiedGoodsReceiptModel: SimplifiedGoodsReceiptModel
    ) => {
        const { error } = await invokeAPI(
            getURL(
                `${ApiUrl.PURCHASE_ORDER_GOODS_RECEIPT}/${id}/:receiveAndConfirm`,
                mapObjectToURLSearchParams({
                    receivedDate: simplifiedGoodsReceiptModel.receivedDate,
                    inventoryId: simplifiedGoodsReceiptModel.inventoryId,
                })
            ) ?? '',
            MethodType.PUT
        );
        if (error !== undefined) {
            getValidationMessagesFromResponse(error);
        } else {
            window.app.runCES('logistics_goods_receipt_simple');
            postNotificationMessage(
                getMessage('text_purchase_order'),
                [
                    `${getMessage('text_action_completed')} ${getMessage(
                        'text_quick_goods_receipt'
                    )}`,
                    `${getMessage('text_purchase_order')}: ${getMessage(
                        'th_number_short'
                    )} ${number}`,
                ],
                new Date()
            );
            onRefresh();
        }
    };

    return {
        onDeletePO: handleDeletePurchaseOrder,
        onDeleteGR: handleDeleteGoodsReceipt,
        onSend: handleSendPurchaseOrder,
        onSimplifyGoodReceipt: handleSimplifiedGoodsReceipt,
    };
}
