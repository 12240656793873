import { invokeAPI, useFetchSingle } from '@Page/LogisticsV2/hooks/api';
import {
    ResaleProductDTO,
    SupplierConnectionDTO,
} from '@Page/LogisticsV2/types/dtoTypes';
import { ApiUrl, MethodType } from '@Page/LogisticsV2/utils/enums';
import _ from 'lodash';
import { LogisticsSettingsDTO } from '@Page/LogisticsV2/pages/Stocktaking/utils';

export async function createProductAndSupplierConnection(
    productInfo: Partial<ResaleProductDTO>,
    productGroupId: number | undefined,
    supplierConnection: Partial<SupplierConnectionDTO>
) {
    const { data: resaleData, error: resaleError } = await createResaleProduct(
        productInfo
    );
    if (resaleData) {
        const jsonResaleData = await resaleData.json();
        if (productGroupId !== undefined && productGroupId > 0) {
            await createGroupRelation({
                product: { id: jsonResaleData.value.id },
                productGroup: { id: productGroupId },
            });
        }
        return await createSupplierConnection({
            ...supplierConnection,
            resaleProduct: { id: jsonResaleData.value.id },
        });
    } else {
        return { data: undefined, error: resaleError };
    }
}

export function getAllProducts(
    products: SupplierConnectionDTO[],
    newProducts: SupplierConnectionDTO[]
): SupplierConnectionDTO[] {
    return _.uniqBy([...products, ...newProducts], 'id');
}

export function useSuggestedProductNumber(): string | undefined {
    const { data: logisticsSettings } = useFetchSingle<LogisticsSettingsDTO>(
        ApiUrl.PRODUCT_LOGISTICS_SETTINGS
    );

    return logisticsSettings?.moduleSuggestedProductNumber
        ? logisticsSettings?.suggestedProductNumber
        : '';
}

async function createResaleProduct(payload: Partial<ResaleProductDTO>) {
    return await invokeAPI(
        ApiUrl.PRODUCT,
        MethodType.POST,
        JSON.stringify(payload)
    );
}

export async function createSupplierConnection(
    payload: Partial<SupplierConnectionDTO>
) {
    const { data, error } = await invokeAPI(
        ApiUrl.SUPPLIER_CONNECTION,
        MethodType.POST,
        JSON.stringify(payload)
    );
    if (data) {
        return { data: await data.json(), error: undefined };
    } else {
        return { data: undefined, error: error };
    }
}

async function createGroupRelation(payload: {}) {
    return await invokeAPI(
        ApiUrl.PRODUCT_GROUP_RELATIONS,
        MethodType.POST,
        JSON.stringify(payload)
    );
}
