import { SagaIterator } from 'redux-saga';
import { put, takeEvery, select } from 'redux-saga/effects';

import { HistoryUpdate, HistoryChanged } from '../action/HistoryShim.action';
import { GeneralGlobalState } from '../types/General';
import { HistoryState } from '../types/HistoryShim.type';
import { parseUrl } from '../HistoryShim';

function* sagaHistoryChange(action: HistoryUpdate): SagaIterator {
    if (!action.url) {
        return;
    }

    const before: HistoryState = yield select(
        (state: GeneralGlobalState) => state.history
    );
    const after: HistoryState = parseUrl(
        new URL(action.url, window.location.origin)
    );

    if (before.href === after.href) {
        return;
    }

    yield put(HistoryChanged(before, after));
}

export const HistorySagas = [takeEvery(HistoryUpdate.type, sagaHistoryChange)];
