/* eslint-disable prefer-destructuring */
import * as React from 'react';
import classNames from 'classnames';
import { debounce } from 'lodash';

import { ButtonIcon } from '@Component/ButtonIcon';
import { MaterialIcon } from '@Component/MaterialIcon';
import {
    FullscreenBody,
    FullscreenDialog,
    FullscreenHeader,
    FullScreenHeaderTitle,
} from '@Component/Dialog';
import { Responsive } from '../Responsive';

import { CreateButtonComponent, Option } from './types';
import { FormGroup, TextField } from '@Component/Form';

import './Dropdown.scss';

import DesktopPopup from './DesktopPopup';
import { LoadingSpinner } from '@Component/Loading';
import { FormEvent } from 'react';
import { AnchoredSurface } from '@Component/AnchoredSurface';
import { findDOMNode } from 'react-dom';

const HEIGHT_OF_SEARCH_FIELD = 56;
const IS_CHARACTER = /^\S$/;

export type Props<T> = {
    onChange: (option: Option<T>) => void;
    onFocus?: React.FocusEventHandler<HTMLInputElement>;
    onBlur?: React.FocusEventHandler<HTMLInputElement>;
    name?: string;
    label?: NonNullable<React.ReactNode> | string;
    labelText?: string;
    selected?: Option<T> | null;
    options: Array<Option<T>>;
    className?: string;
    removeSelectedText?: string | null;
    error?: string;
    onOpen?: Function;
    autoFocus?: boolean;
    search?: boolean;
    dense?: boolean;
    searchLabel?: string;
    fullScreen?: boolean;
    apiSearch?: boolean;
    onSearch?: (search: string) => void;
    doFetchMore?: () => void;
    flushSearch?: () => void;
    selectOnMobile?: boolean;
    disabled?: boolean;
    readOnly?: boolean;
    required?: boolean;
    fetching?: boolean; // When true: display an ajax loader (or something that indicates loading)
    desktopListItem?: TlxListItem<T>;
    mobileListItem?: TlxListItem<T>;
    createButton?: CreateButtonComponent;
    headers?: React.ComponentType;
    offsetTop?: number;
    isCombo?: boolean;
    maxLength?: number;
    dataTestId?: string;
};

type State = {
    open: boolean;
    focus: number; // Index of the element that has (keyboard) focus.
    query: string;
};

// No need to import this, use TlxListItem type
type ListItemProps<ItemT> = {
    option: Option<ItemT>;
};

export type TlxListItem<T> = React.ComponentType<ListItemProps<T>>;

class DropdownList<T> extends React.Component<Props<T>, State> {
    wrapperRef: React.RefObject<HTMLDivElement>;
    scrollContainer: React.RefObject<HTMLDivElement>;
    scrollContainerMobile: React.RefObject<HTMLDivElement>;
    searchInput: React.RefObject<HTMLInputElement>;
    selectElement: React.RefObject<HTMLDivElement>;
    editableElement: React.RefObject<HTMLDivElement>;
    createNewButton: React.RefObject<HTMLButtonElement>;
    desktop: boolean;
    listItems: HTMLDivElement[];

    /**
     * If user completes search with enter AND only one item is found, we use this to select that item automatically.
     */
    completedSearchWithEnter: boolean;

    constructor(props: Props<T>, desktop: boolean) {
        super(props);
        this.desktop = desktop;
        this.state = {
            open: false,
            focus: 0,
            query: '',
        };

        this.listItems = [];

        this.handleClick = this.handleClick.bind(this);
        this.getOptions = this.getOptions.bind(this);
        this.show = this.show.bind(this);
        this.editableOnChange = this.editableOnChange.bind(this);
        this.editableOnInput = this.editableOnInput.bind(this);
        this.editableOnPaste = this.editableOnPaste.bind(this);
        this.handleChange = this.handleChange.bind(this);

        this.wrapperRef = React.createRef();
        this.scrollContainer = React.createRef();
        this.scrollContainerMobile = React.createRef();
        this.searchInput = React.createRef();
        this.selectElement = React.createRef();
        this.editableElement = React.createRef();
        this.createNewButton = React.createRef();

        this.completedSearchWithEnter = false;
    }

    static focusElement(
        container?: HTMLElement | null,
        selector?: string,
        value?: string
    ) {
        if (container && selector) {
            const element = container.querySelector<HTMLInputElement>(selector);
            if (element && element.focus) {
                element.focus();
                if (value && element.value) {
                    element.value = value;
                }
            }
        }
    }

    getIndexOfOption(option: Option<T>) {
        return this.getOptions().findIndex((el) => el.value === option.value);
    }

    setFocus(
        focus: number,
        block: ScrollLogicalPosition = 'nearest',
        scrollIndex = focus
    ) {
        if (focus < -1) {
            return;
        }

        this.setState({ focus }, () => {
            const component = this.listItems[scrollIndex];
            if (component) {
                const element = findDOMNode(component) as Element;
                if (element) {
                    element.scrollIntoView({ block });
                }
            }
        });
    }

    waitForListItemsToBePopulated(): Promise<void> {
        if (this.listItems.length > 0) {
            return Promise.resolve();
        } else {
            return new Promise((resolve) => {
                let attempts = 0;
                const poll = setInterval(() => {
                    attempts++;
                    if (this.listItems.length > 0 || attempts > 50) {
                        clearInterval(poll);
                        resolve();
                    }
                }, 10);
            });
        }
    }

    scrollToSelectedOption(selected: Option<T>) {
        this.waitForListItemsToBePopulated()
            .then(() => {
                if (this.props.fetching) {
                    // Avoid focusing the element if we are fetching
                    return;
                }
                const focus = this.getIndexOfOption(selected);

                let scrollIndex = focus;
                if (this.desktop && !this.props.search) {
                    scrollIndex = focus - 1;
                }

                let offsetToSelectedOption =
                    !this.desktop && this.props.search
                        ? HEIGHT_OF_SEARCH_FIELD
                        : 0;

                for (let i = 0; i < scrollIndex; i++) {
                    const element = findDOMNode(
                        this.listItems[i]
                    ) as HTMLElement | null;
                    if (element) {
                        offsetToSelectedOption += element.offsetHeight;
                    }
                }

                if (this.desktop && this.scrollContainer.current !== null) {
                    this.scrollContainer.current.scrollTop =
                        offsetToSelectedOption;
                } else if (
                    !this.desktop &&
                    this.scrollContainerMobile.current !== null
                ) {
                    this.scrollContainerMobile.current.scrollTop =
                        offsetToSelectedOption;
                }
            })
            .catch(() => {
                /* this should never happen, but it makes eslint happy */
            });
    }

    componentDidUpdate(prevProps: Props<T>): void {
        /**
         * When user press Enter during search, we flush the search debouncer
         * (start searching right away). We also want to automatically select
         * the found option, if it is only one option found.
         *
         * Wait until ongoing search is actually done, to be sure we are not
         * selecting 1 result from the last search
         */
        if (this.completedSearchWithEnter && !this.props.fetching) {
            this.completedSearchWithEnter = false;
            if (this.props.options.length === 1) {
                this.handleClick(this.props.options[0]);
            }
        }

        /**
         * If list is pre-populated with some default fields and the selected field, but
         * collects even more options (from the server), automatically scroll to the
         * selected option again when the new options are rendered inside the dropdown.
         *
         * Also remember to update the index of the focus element, since it is now most likely moved!
         */
        if (
            this.state.open &&
            this.props.selected &&
            prevProps.selected &&
            this.props.selected.value !== prevProps.selected.value &&
            prevProps.options.length !== this.props.options.length
        ) {
            this.scrollToSelectedOption(this.props.selected);
            this.setState({
                focus: this.getIndexOfOption(this.props.selected),
            });
        }
    }

    show(open: boolean) {
        if (open && (this.props.disabled || this.props.readOnly)) {
            return;
        }

        const prevOpen = this.state.open;
        if (!this.props.disabled && open !== prevOpen) {
            this.setState(
                {
                    open,
                    /**
                     * When dropdown open/closes, we want the selected element to be in focus, so that when opening
                     * it and using keyboard arrows to navigate, we navigate relative to the selected item.
                     */
                    focus: this.props.selected
                        ? this.getIndexOfOption(this.props.selected)
                        : 0,
                },
                () => {
                    if (open) {
                        const { selected, search, onOpen } = this.props;

                        if (selected) {
                            this.scrollToSelectedOption(selected);
                        }

                        if (this.searchInput.current) {
                            this.setState({
                                query: this.searchInput.current.value,
                            });
                        }
                        /**
                         * Customer feedback: They didn't like they had to delete existing query string to start a new
                         * search. Hopefully, by selecting it for them, they understand that they now can just type a
                         * new query straight away.
                         *
                         * In the old dropdown, when you closed and opened it, it started fresh, with blank query and
                         * new search. We might consider returning to this old behaviour.
                         */
                        this.searchInput?.current?.select();

                        if (
                            this.desktop &&
                            !search &&
                            this.scrollContainer.current
                        ) {
                            DropdownList.focusElement(
                                this.scrollContainer.current,
                                '*[tabindex]'
                            );
                        }
                        if (onOpen) {
                            onOpen();
                        }
                    } else if (this.selectElement.current) {
                        this.selectElement.current.focus();
                    }
                }
            );
        }
    }

    handleChange(option: Option<T>) {
        this.selectElement.current?.dispatchEvent(
            new CustomEvent<Option<T>>('txr-dropdown:change', {
                bubbles: true,
                detail: option,
            })
        );
        this.props.onChange(option);
    }
    handleClick(option: Option<T>) {
        this.show(false);
        this.handleChange(option);
    }

    isOutside(element: Element) {
        return !(
            (this.wrapperRef.current &&
                this.wrapperRef.current.contains(element)) ||
            (this.searchInput.current &&
                this.searchInput.current.contains(element)) ||
            (this.scrollContainer.current &&
                this.scrollContainer.current.contains(element)) ||
            (this.createNewButton.current &&
                this.createNewButton.current.contains(element))
        );
    }

    onSearch(query: string) {
        if (this.props.apiSearch && this.props.onSearch) {
            this.props.onSearch(query);
        }
        this.setState({
            query,
            focus: 0,
        });
    }

    getOptions() {
        const { options, removeSelectedText } = this.props;
        let actualOptions = [...options];
        const query = this.state.query.trim().toLowerCase();

        if (
            removeSelectedText &&
            removeSelectedText.toLowerCase().indexOf(query) > -1
        ) {
            actualOptions.unshift({
                displayName: removeSelectedText,
                value: undefined,
            });
        }

        if (removeSelectedText === null && query.length === 0) {
            actualOptions.unshift({ displayName: '', value: undefined });
        }

        // We add an empty option as the first option in the provider, but this should
        // be invisible for the end user. If you are debugging a missing option in an
        // isCombo dropdown and ended up here, you must probably add an empty option
        // ({value: '', displayName: ''}) when initializing the provider.
        if (this.props.isCombo) {
            actualOptions.shift();
        }

        // API Search
        if (this.props.apiSearch) {
            return actualOptions;
        }

        // Normal search
        if (query.length > 0) {
            actualOptions = actualOptions.filter(
                (option) =>
                    [option.displayName]
                        .concat(option.secondary ? option.secondary : [])
                        .join(' ')
                        .toLowerCase()
                        .indexOf(query) > -1
            );
        }

        return actualOptions;
    }

    /**
     * Only for isCombo variant: When span element is blurred, this function is run.
     *
     * @param e
     */
    editableOnChange(e: FormEvent<HTMLSpanElement>) {
        if (!this.props.isCombo) {
            return;
        }

        const target = e.target as HTMLSpanElement;

        this.handleChange({
            value: target.innerText,
            displayName: target.innerText,
        });
    }

    /**
     * We have to handle pasting ourselves, to avoid getting formatting from the clipboard
     * into our contentEditable field.
     *
     * @param e The paste event
     */
    editableOnPaste(e: React.ClipboardEvent<HTMLSpanElement>): void {
        if (!e.clipboardData) {
            return;
        }

        e.preventDefault();

        const text = e.clipboardData.getData('text/plain');

        // This method is deprecated, but it is the most straightforward way of
        // inserting text at the given selection/caret.
        if (document.queryCommandSupported('insertText')) {
            document.execCommand('insertText', false, text);
        } else {
            // A more cumbersome way of doing the same, but using non-deprecated browser features.
            let selection = window.getSelection();
            if (!selection) {
                return;
            }
            const range = selection.getRangeAt(0);
            range.deleteContents();

            const textNode = document.createTextNode(text);
            range.insertNode(textNode);
            range.selectNodeContents(textNode);
            range.collapse(false);

            selection = window.getSelection();
            if (!selection) {
                return;
            }

            selection.removeAllRanges();
            selection.addRange(range);
        }

        this.handleChange({
            value: (e.target as HTMLSpanElement).innerText,
            displayName: (e.target as HTMLSpanElement).innerText,
        });
    }

    /**
     * Only for isCombo variant: On input, make sure content
     * isn't longer than the submitted maxLength property.
     *
     * @param e
     */
    editableOnInput(e: FormEvent<HTMLSpanElement>) {
        if (this.props.maxLength === undefined) {
            return;
        }
        const target = e.target as HTMLSpanElement;

        if (target.innerText.length > this.props.maxLength) {
            target.innerText = target.innerText.substr(0, this.props.maxLength);
        }
    }

    render(): React.ReactNode {
        return null;
    }
}

export function DefaultListItem<T>(props: ListItemProps<T>) {
    const classes = classNames({
        'txr-dropdown__list-item--has-secondary': props.option.secondary,
        'txr-dropdown__list-item': true,
    });
    return (
        <div className={classes} tabIndex={-1}>
            <div className="txr-dropdown__list-item__text">
                {props.option.displayName}
                {props.option.secondary &&
                    ([] as string[])
                        .concat(props.option.secondary)
                        .map((text, index) => (
                            <div
                                key={text + index}
                                className="txr-dropdown__list-item__secondary-text"
                            >
                                {text}
                            </div>
                        ))}
            </div>
        </div>
    );
}

class MobileDropdownList<T> extends DropdownList<T> {
    constructor(props: Props<T>) {
        super(props, false);
        this.handleScroll = debounce(this.handleScroll.bind(this), 500);
    }

    handleScroll(event: React.UIEvent) {
        if (event.target instanceof HTMLElement) {
            const scrollTop = event.target.scrollTop;
            const scrollVisibleHeight = event.target.clientHeight;
            const scrollFullHeight = event.target.scrollHeight;

            if (scrollTop > 0) {
                document.body.dispatchEvent(
                    new CustomEvent('DROPDOWN_SCROLL', {
                        detail: {
                            name: this.props.name || this.props.labelText,
                            scrollTop,
                        },
                    })
                );
            }
            // 150 pixels are added so that doFetchMore will be called before
            // the user has scrolled all the way to the bottom
            if (
                scrollTop + scrollVisibleHeight + 150 >= scrollFullHeight &&
                this.props.doFetchMore
            ) {
                this.props.doFetchMore();
            }
        }
    }

    render() {
        const {
            label,
            search,
            searchLabel,
            selected,
            className,
            selectOnMobile,
            readOnly,
            disabled,
            dense,
            required,
            dataTestId,
        } = this.props;

        super.listItems = [];
        const options = this.getOptions();

        const selectedValue = selected ? selected.value : undefined;
        const selectedObject =
            options.find((option) => option.value === selectedValue) ||
            selected;
        const hasSelected =
            selectedObject && selectedObject.value !== undefined;
        const selectedText =
            hasSelected && selectedObject ? selectedObject.displayName : '';
        let innerComponent;
        if (!this.state.open) {
            if (!selectOnMobile) {
                innerComponent = (
                    <div
                        className="txr-dropdown__list-item"
                        onClick={() => this.show(true)}
                    >
                        <div className="txr-dropdown__list-item__text">
                            {label}
                            <div className="txr-dropdown__list-item__secondary-text">
                                {selectedObject && selectedObject.displayName}
                            </div>
                        </div>
                        <i className="material-icons txr-dropdown__list-item__icon">
                            keyboard_arrow_right
                        </i>
                    </div>
                );
            } else {
                innerComponent = (
                    <FormGroup
                        invalid={!!this.props.error}
                        validationMessage={this.props.error}
                        className={classNames('txr-dropdown', className, {
                            'txr-dropdown--disabled': disabled,
                            'txr-dropdown--readonly': readOnly,
                        })}
                    >
                        <div
                            className={classNames('tlx-textfield', {
                                'tlx-textfield--dense': dense,
                                'txr-dropdown__field': !readOnly && !disabled,
                            })}
                            onClick={() => {
                                if (!this.props.isCombo) {
                                    this.show(true);
                                }
                            }}
                            onBlur={this.props.onBlur}
                            role="listbox"
                            data-testid={dataTestId}
                            tabIndex={
                                this.props.readOnly === true ||
                                this.props.disabled === true ||
                                this.props.isCombo
                                    ? undefined
                                    : 0
                            }
                            ref={this.selectElement}
                        >
                            <span
                                className={classNames(
                                    'txr-dropdown__field__input',
                                    'tlx-textfield__input',
                                    {
                                        'tlx-textfield__input--dense': dense,
                                        'tlx-textfield__input--disabled':
                                            disabled,
                                        'tlx-textfield__input--readonly':
                                            readOnly,
                                        'tlx-textfield__input--icon-right':
                                            !readOnly,
                                        'tlx-textfield__input--editable':
                                            this.props.isCombo,
                                    }
                                )}
                                suppressContentEditableWarning={
                                    this.props.isCombo
                                }
                                contentEditable={this.props.isCombo}
                                // When span is editable, make it the tab-able element (if not,
                                // user is not able to tab into it and type).
                                tabIndex={this.props.isCombo ? 0 : undefined}
                                onPaste={this.editableOnPaste}
                                // onChange isn't triggering, and onInput is triggering to often
                                // (making it impossible to change location of cursor, etc.)
                                onBlur={this.editableOnChange}
                                onInput={this.editableOnInput}
                            >
                                {hasSelected && selectedText}
                            </span>
                            <span
                                className={classNames(
                                    'txr-dropdown__field__label',
                                    'tlx-textfield__label',
                                    {
                                        'tlx-textfield__label--dense': dense,
                                        'tlx-textfield__label--required':
                                            required,
                                    }
                                )}
                            >
                                {label}
                            </span>
                            {!(
                                this.props.readOnly === true ||
                                this.props.disabled === true
                            ) && (
                                <>
                                    <div className="tlx-textfield__border" />
                                    <span
                                        className="tlx-textfield__icon tlx-dropdown__icon"
                                        onClick={() => {
                                            if (this.props.isCombo) {
                                                this.show(true);
                                            }
                                        }}
                                    >
                                        <MaterialIcon>
                                            {this.props.isCombo
                                                ? 'more_vert'
                                                : 'arrow_drop_down'}
                                        </MaterialIcon>
                                    </span>
                                </>
                            )}
                        </div>
                    </FormGroup>
                );
            }
        } else {
            const ListItem = this.props.mobileListItem || DefaultListItem;
            innerComponent = (
                <FullscreenDialog>
                    <FullscreenHeader>
                        <ButtonIcon
                            onClick={() => this.show(false)}
                            icon="arrow_back"
                            className="react-modal-fullscreen__header__button"
                        />
                        <FullScreenHeaderTitle>{label}</FullScreenHeaderTitle>
                        {this.props.createButton && (
                            <this.props.createButton
                                ref={this.createNewButton}
                                closeDropdown={() => this.show(false)}
                            />
                        )}
                    </FullscreenHeader>
                    <FullscreenBody
                        ref={this.scrollContainerMobile}
                        handleScroll={this.handleScroll}
                    >
                        {search && (
                            <div className="text-field-container">
                                <TextField
                                    placeholder={searchLabel}
                                    onChange={(
                                        evt: React.ChangeEvent<HTMLInputElement>
                                    ) => this.onSearch(evt.target.value)}
                                    value={this.state.query}
                                    inputRef={this.searchInput}
                                    icon="search"
                                    readOnly={readOnly}
                                    disabled={disabled}
                                    dense
                                />
                            </div>
                        )}
                        <div
                            className="dropdown-search-container"
                            ref={this.scrollContainer}
                        >
                            <div className="txr-dropdown__list">
                                {options.map((option, idx) => {
                                    return (
                                        <div
                                            className="txr-dropdown__mobile-list-item-container"
                                            key={idx}
                                            ref={(node: HTMLDivElement) => {
                                                this.listItems[idx] = node;
                                            }}
                                            onClick={() =>
                                                this.handleClick(option)
                                            }
                                            role="option"
                                            aria-selected={
                                                option.value === selectedValue
                                            }
                                        >
                                            <ListItem option={option} />
                                            <div className="txr-dropdown__mobile-selected">
                                                {option.value ===
                                                    selectedValue && (
                                                    <i className="material-icons">
                                                        check
                                                    </i>
                                                )}
                                            </div>
                                        </div>
                                    );
                                })}
                                {this.props.fetching && (
                                    <div className="txr-dropdown__ajax_wheel">
                                        <LoadingSpinner />
                                    </div>
                                )}
                            </div>
                        </div>
                    </FullscreenBody>
                </FullscreenDialog>
            );
        }

        return (
            <div className="large-dropdown-list" ref={this.wrapperRef}>
                {innerComponent}
            </div>
        );
    }
}

export class DesktopDropdownList<T> extends DropdownList<T> {
    constructor(props: Props<T>) {
        super(props, true);
        this.handleBlur = this.handleBlur.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleScroll = debounce(this.handleScroll.bind(this), 500);
    }
    componentDidMount() {
        if (this.props.autoFocus && this.selectElement.current != null) {
            this.selectElement.current.focus();
        }
    }

    handleBlur(event: React.FocusEvent) {
        // Will not close the dropdown on blur if the new target receiving
        // focus is a "close modal" button. Prevents dropdowns in modals
        // from being immediately closed when clicked.
        if (
            (!event.relatedTarget ||
                event.relatedTarget.getAttribute('data-testid') !=
                    'modal-close-button') &&
            ((event.currentTarget && this.isOutside(event.currentTarget)) ||
                (event.relatedTarget && this.isOutside(event.relatedTarget)))
        ) {
            this.show(false);
        }
    }

    focusNext() {
        const focus = this.state.focus + 1;
        if (focus < this.getOptions().length) {
            this.setFocus(focus);
        }
    }

    focusPrev() {
        const focus = this.state.focus - 1;
        if (focus >= 0) {
            this.setFocus(focus);
        }
    }

    handleKeyDown(event: React.KeyboardEvent) {
        if (this.props.disabled || this.props.readOnly) {
            return;
        }

        const { key } = event;
        const { open } = this.state;

        const isCharacter = IS_CHARACTER.test(key);

        if ((isCharacter || key === ' ') && this.props.isCombo) {
            return;
        }

        // Only open the dropdown, if nothing else is supposed to happen (like saving)
        if (!open && isCharacter && !event.metaKey && !event.ctrlKey) {
            event.stopPropagation();
            event.nativeEvent.stopImmediatePropagation();
            this.show(true);
            this.setState({
                query: '',
            });
        }
        if (!open) {
            switch (key) {
                case ' ':
                case 'ArrowUp':
                case 'ArrowDown':
                    this.show(true);
                    // This prevention is necessary so the keyboard event won't
                    // cancel the selection of text inside search input.
                    event.preventDefault();
                    break;
                default:
                    break;
            }
        } else {
            const options = this.getOptions();
            // Make sure this is returned to false for slow searches. Scenario:
            // User press enter in search, searching is slow and user press more keys.
            // Then we shouldn't auto-select on search result after all.
            this.completedSearchWithEnter = false;
            switch (key) {
                case 'Enter':
                    if (this.props.fetching && this.props.flushSearch) {
                        this.completedSearchWithEnter = true;
                        this.props.flushSearch();
                    } else if (options.length > 0) {
                        // If nothing has focus (value is -1), assume user meant "top one" when hitting
                        // enter. Usually it is only one option left (after a search). Using Math.max
                        // fixed a bug where end user would hit enter and nothing happens or dropdown
                        // just closes with an error.
                        this.handleChange(
                            options[Math.max(this.state.focus, 0)]
                        );
                        this.show(false);
                        if (
                            this.props.isCombo &&
                            this.editableElement.current
                        ) {
                            this.editableElement.current.focus();
                        }
                    }
                    event.preventDefault();
                    event.nativeEvent.stopImmediatePropagation();
                    break;
                case 'ArrowUp':
                    this.focusPrev();
                    event.preventDefault();
                    break;
                case 'ArrowDown':
                    this.focusNext();
                    event.preventDefault();
                    break;
                case 'Escape':
                case 'Esc':
                    this.show(false);
                    if (this.props.isCombo && this.editableElement.current) {
                        this.editableElement.current.focus();
                    } else if (this.selectElement.current) {
                        this.selectElement.current.focus();
                    }
                    event.preventDefault();
                    event.nativeEvent.stopImmediatePropagation();
                    break;
                default:
                    break;
            }
        }
    }

    handleScroll(
        scrollTop: number,
        scrollVisibleHeight: number,
        scrollFullHeight: number
    ) {
        // 150 pixels are added so that doFetchMore will be called before
        // the user has scrolled all the way to the bottom
        if (
            scrollTop + scrollVisibleHeight + 150 >= scrollFullHeight &&
            this.props.doFetchMore
        ) {
            this.props.doFetchMore();
        }
    }

    render() {
        const {
            name,
            label,
            search,
            searchLabel,
            selected,
            className,
            readOnly,
            disabled,
            dense,
            required,
            dataTestId,
        } = this.props;

        super.listItems = [];

        const options = this.getOptions();
        const selectedValue = selected ? selected.value : undefined;
        const selectedObject =
            options.find((option) => option.value === selectedValue) ||
            selected;
        const hasSelected =
            selectedObject && selectedObject.value !== undefined;
        const selectedText =
            hasSelected && selectedObject ? selectedObject.displayName : '';

        const selectElement = (
            <div
                className={classNames('tlx-textfield', {
                    'tlx-textfield--dense': dense,
                    'txr-dropdown__field': !readOnly && !disabled,
                })}
                onClick={(event) => {
                    /**
                     * The label of the dropdown can be a link. If user clicks this link,
                     * we want the browser to open the link, and not open the dropdown.
                     */
                    const isLink =
                        event.target instanceof HTMLAnchorElement &&
                        event.target.hasAttribute('href');

                    if (!this.props.isCombo && !isLink) {
                        this.show(true);
                    }
                }}
                onBlur={this.props.onBlur}
                role="listbox"
                tabIndex={
                    readOnly === true || disabled === true || this.props.isCombo
                        ? undefined
                        : 0
                }
                onKeyDown={this.handleKeyDown}
                ref={this.selectElement}
                aria-label={`${label} ${selectedText}`}
                data-testid={dataTestId}
            >
                <span
                    // When span is editable, make it the tab-able element (if not,
                    // user is not able to tab into it and type).
                    tabIndex={
                        readOnly === true || disabled === true
                            ? undefined
                            : this.props.isCombo
                            ? 0
                            : -1
                    }
                    className={classNames(
                        'txr-dropdown__field__input',
                        'tlx-textfield__input',
                        {
                            'tlx-textfield__input--dense': dense,
                            'tlx-textfield__input--disabled': disabled,
                            'tlx-textfield__input--readonly': readOnly,
                            'tlx-textfield__input--icon-right': !readOnly,
                            'tlx-textfield__input--editable':
                                this.props.isCombo,
                        }
                    )}
                    data-name={name}
                    aria-label={readOnly || disabled ? undefined : selectedText}
                    suppressContentEditableWarning={this.props.isCombo}
                    contentEditable={this.props.isCombo}
                    onPaste={this.editableOnPaste}
                    ref={this.editableElement}
                    // onChange isn't triggering, and onInput is triggering to often (making it
                    // impossible to change location of cursor, etc.)
                    onBlur={this.editableOnChange}
                    onInput={this.editableOnInput}
                >
                    {hasSelected && selectedText}
                </span>
                <span
                    className={classNames(
                        'txr-dropdown__field__label',
                        'tlx-textfield__label',
                        {
                            'tlx-textfield__label--dense': dense,
                            'tlx-textfield__label--required': required,
                        }
                    )}
                >
                    {label}
                </span>
                {!(readOnly === true || disabled === true) && (
                    <>
                        <div className="tlx-textfield__border" />
                        <span
                            className="tlx-textfield__icon tlx-dropdown__icon"
                            onClick={() => {
                                if (this.props.isCombo) {
                                    this.show(true);
                                }
                            }}
                        >
                            <MaterialIcon>
                                {this.props.isCombo
                                    ? 'more_vert'
                                    : 'arrow_drop_down'}
                            </MaterialIcon>
                        </span>
                    </>
                )}
            </div>
        );

        return (
            <FormGroup
                invalid={!!this.props.error}
                validationMessage={this.props.error}
                dense={dense}
                className={classNames('txr-dropdown', className, {
                    'txr-dropdown--disabled': disabled,
                    'txr-dropdown--readonly': readOnly,
                })}
            >
                <AnchoredSurface
                    direction={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    origin={{
                        horizontal: 'left',
                        vertical: 'bottom',
                    }}
                    anchorContent={selectElement}
                    visible={this.state.open}
                    constrainedByViewport
                    container={document.body}
                >
                    <DesktopPopup
                        search={search}
                        offsetTop={this.props.offsetTop}
                        textField={
                            <div className="txr-dropdown__search-container__header">
                                <TextField
                                    placeholder={searchLabel}
                                    inputRef={this.searchInput}
                                    onChange={(
                                        evt: React.ChangeEvent<HTMLInputElement>
                                    ) => this.onSearch(evt.target.value)}
                                    onFocus={this.props.onFocus}
                                    onBlur={this.handleBlur}
                                    value={this.state.query}
                                    invalid={!!this.props.error}
                                    validationMessage={this.props.error}
                                    icon="search"
                                    readOnly={readOnly}
                                    disabled={disabled}
                                    dense
                                    inputClassName="txr-dropdown__search-input"
                                />
                            </div>
                        }
                        onKeyDown={this.handleKeyDown}
                        scrollContainer={this.scrollContainer}
                        selectedValue={selectedValue}
                        listItems={this.listItems}
                        handleClick={this.handleClick}
                        handleBlur={this.handleBlur}
                        handleScroll={this.handleScroll}
                        show={this.show}
                        searchInput={this.searchInput}
                        focus={this.state.focus}
                        options={options}
                        anchor={this.selectElement}
                        fetching={this.props.fetching}
                        desktopListItem={this.props.desktopListItem}
                        headers={this.props.headers}
                        createNewButton={this.props.createButton}
                        createNewButtonRef={this.createNewButton}
                    />
                </AnchoredSurface>
            </FormGroup>
        );
    }
}

export function ResponsiveDropDown<T>(props: Props<T>) {
    return (
        <React.Fragment>
            <Responsive desktop tablet>
                <DesktopDropdownList {...props} />
            </Responsive>
            <Responsive mobile>
                <MobileDropdownList {...props} />
            </Responsive>
        </React.Fragment>
    );
}
