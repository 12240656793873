import { showActionLogMessage } from '../../../js/modules/notification';
import { encodeHTML } from '../../../js/modules/encodeHTML';

export interface LegacyNotification {
    pageName: string;
    date: Date;
    messages: Array<string>;
}

/**
 * Adds and shows the given messages in the action log tray.
 *
 * @param {LegacyNotification} notifications
 *
 * @author tellef
 * @date 2020-04-14
 */
export function postNotifications(...notifications: Array<LegacyNotification>) {
    for (const notification of notifications) {
        for (const message of notification.messages) {
            showActionLogMessage({
                pageName: encodeHTML(notification.pageName),
                date: notification.date,
                message: encodeHTML(message),
            });
        }
    }
}

/**
 * Adds and shows a message in the action log tray.
 *
 * @param {string}        pageName Name of page / title for message.
 * @param {Array<string>} messages List of text for message.
 * @param {Date}          date     (Optional) DateTime for message. Defaults to now.
 *
 * @author tellef
 * @date 2020-04-14
 */
export function postNotificationMessage(
    pageName: string,
    messages: Array<string>,
    date?: Date
) {
    postNotifications({
        pageName,
        date: date ?? new Date(),
        messages,
    });
}
