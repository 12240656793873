import jQuery from 'jquery';

import { dev } from './dev-util';
import { toJSON } from '../legacy/jsonrpc';
import {
    defaultAjaxAction,
    defaultJsonRpcExceptionHandler,
    populateIdAndRevision,
} from '../o-common';
import { clearChanged } from './change';
import { getElement, getElementValue, tlxGetScope } from '../c-common';
import { tlxConfirm } from './confirm';

// jsonrpc not defined at when module is created, so can't be passed as a dependency
export const jsonrpcUtil = (function ($) {
    //
    //	function updateObject($scope, javaClass, clientId, id, revision, props) {
    //		var obj = $.extend({ id: id, revision: revision, javaClass: javaClass, clientId: clientId}, props);
    //		var changes = jsonrpc.Util.updateObject(data[objectPath]);
    //		populateIdAndRevision(changes, $scope);
    //	}

    function objectPathOf(property) {
        const lastDot = property.lastIndexOf('.');
        if (lastDot == -1) {
            throw new Error('invalid property string ' + property);
        }
        return property.substr(0, lastDot);
    }

    function jsonrpcCallbackDeferredAdapter(deferred) {
        return function (r, e) {
            if (e) {
                deferred.reject(e);
            } else {
                deferred.resolve(r);
            }
        };
    }

    /**
     * ONLY USED IN crmWorkbench, when editing fields within there.
     *
     *  Example:
     *  object = { foo: [ { bar: 1 } ] }
     *  path = "foo[0]]
     *  returns { bar: 1 }
     */
    function evalObjectPath(object, path) {
        const rx = /([a-zA-Z]+)\[([0-9]+)\]/;
        const p = rx.exec(path);

        return object[p[1]][parseInt(p[2])];
    }

    /**
     * Persists the input element to the server. Asynchronously by default.
     *
     * The elements name is expected to be a property string. eg. 'activity.description' and the object
     * must also have associated identity information. (ie. hidden inputs with id, revision and javaClass)
     *
     * If the save is successful the revision information will be updated and the change status of the field will be cleared.
     * The element is disabled during the save.
     *
     * @returns a promise
     */
    function saveElement(elem, sync) {
        const $elem = $(elem);

        const objectPath = objectPathOf(elem.name);
        const properties = $.map(
            ['id', 'revision', 'javaClass'],
            function (prop) {
                return objectPath + '.' + prop;
            }
        );
        properties.push(elem.name);

        const $scope = $(tlxGetScope(elem));
        const data = $(
            $.map(properties, function (name) {
                return elem.form[name];
            })
        ).retrieve();

        $.setClientIds(data);

        const objectData = evalObjectPath(data, objectPath); // (objectPath could be eg. "myarray[1].foo.bar")

        const newValue = getElementValue(elem);
        const deferred = $.Deferred();
        deferred.done(function (changes) {
            // Check if page has changed?
            populateIdAndRevision(changes, $scope);
            // Need to be in a callback since this method typically is called from a 'change'
            // handler, and thus could be run before the change-tracking handler.
            if (getElementValue(elem) === newValue) {
                // value has not changed during save -> element no longer dirty
                clearChanged(elem);
            }
        });
        deferred.always(function () {
            $elem.tlxSetDisabled(false);
        });

        $elem.tlxSetDisabled(true);

        if (sync) {
            try {
                deferred.resolve(window.jsonrpc.Util.updateObject(objectData));
            } catch (e) {
                deferred.reject(e);
            }
        } else {
            window.jsonrpc.Util.updateObject(
                jsonrpcCallbackDeferredAdapter(deferred),
                objectData
            );
        }

        return deferred.promise();
    }

    function handleJsonAdvice(exception, $form, methodName, $dialog, options) {
        const level1Messages = [];
        level1Messages.push('<ul>');

        let bottomMessage = '';

        exception.adviceMessages.forEach(function (advice) {
            if (advice.level == 1) {
                level1Messages.push('<li>' + advice.message + '</li>');
                bottomMessage = advice.bottomMessage;
            }
            // todo: implement support for other warning levels
        });
        if (exception.adviceMessages.length > 1) {
            bottomMessage = getMessage('text_confirm_warning');
        } // Only 1 bottomMessage possible

        level1Messages.push('</ul>');

        level1Messages.push('<br><br>' + bottomMessage);

        tlxConfirm(okSelectedCallBack, level1Messages.join(''));

        function okSelectedCallBack() {
            $form.append(
                '<input name="ignoreAdvice" type="hidden" value="true"></input>'
            );

            defaultAjaxAction($form, methodName, $dialog, options);
        }
    }

    function handleJsonError(exception, $findIn, controller, dontClear) {
        $findIn = $findIn || $('body');
        if (exception.code != 107) {
            defaultJsonRpcExceptionHandler(exception);
        }

        let scopeHadErrors = false;
        //validations
        if (exception.propertyMessages) {
            if (!controller) {
                controller = $findIn.filter('form').validationPopup();
            }
            const scope = tlxGetScope(controller);

            if (!dontClear) {
                controller.validationPopup('clear');
            }

            $.each(exception.propertyMessages, function (clientId, message) {
                const clientIdEscaped = clientId.replace(/\./g, '\\.');
                let $errorElement = $findIn.find(
                    'input[name="' + clientIdEscaped + '"]'
                );
                if ($errorElement.length == 0) {
                    $errorElement = $findIn.find(
                        'textarea[name="' + clientIdEscaped + '"]'
                    );
                }
                if ($errorElement.length == 0) {
                    $errorElement = $findIn.find(
                        'select[name="' + clientIdEscaped + '"]'
                    );
                }
                if ($errorElement.length == 0) {
                    $errorElement = $findIn.find(
                        'button[name="' + clientIdEscaped + '"]'
                    );
                }
                if ($errorElement.is("[type='hidden']")) {
                    dev.debugLine(
                        'Error in hidden field: ' + toJSON(exception)
                    );
                }
                //checkboxes in tables should highligh td (parent) instead.
                if ($errorElement.is('[name$="selected"]')) {
                    $errorElement = $errorElement.parent();
                }

                // radio buttons? show warning on the rightmost visible
                if ($($errorElement).is('[type=radio]')) {
                    $errorElement.each(function (i, el) {
                        if ($(el).is(':visible')) {
                            $errorElement = $(el);
                        }
                    });
                }

                if ($errorElement.length == 0) {
                    // need to search in $findIn, since matches outside should not be used
                    $errorElement = $findIn.find(getElement(clientId, scope));
                }

                if ($errorElement.length > 0) {
                    scopeHadErrors = true;
                    this.errorElementHaveBeenFound = true;
                }
                //check if array with more messages
                if (typeof message == 'object') {
                    let tempMsg;
                    $.each(message, function (i, val) {
                        tempMsg = $.appendValueWithDelimiter(
                            tempMsg,
                            val,
                            '<br>'
                        );
                    });
                    message = tempMsg;
                }
                // Maybe a more elgant mechanism would be to fire a event. This way the controller won't need to be specified*
                // (the event can bobble up), and custom actions could easily be specified. (eg. fileupload need special care)
                // * I guess we could done somthing like that anyway though
                $errorElement.setErrorMessage(message, controller);
            });

            controller.validationPopup('sort').validationPopup('open');
        }
        return scopeHadErrors;
    }

    return {
        saveElement: saveElement,
        handleJsonAdvice: handleJsonAdvice,
        handleJsonError: handleJsonError,
    };
})(jQuery);
