import { AttachmentIcon, Chip, UploadIcon } from '@tlx/atlas';
import * as React from 'react';
import {
    ChangeEventHandler,
    forwardRef,
    HTMLAttributes,
    useState,
} from 'react';
import { useDragAndDrop } from '../../hooks/useDragAndDrop';
import './FileUpload.css';
import { UploadFileIcon } from './components';

type FileUploadState = 'dragging' | 'error' | 'empty' | 'hasFile';

interface FilesDragAndDropProps {
    onFileSelected: (file: File | null) => void;
    formats?: string[];
    onDragEnter?: () => void;
    onDragLeave?: () => void;
    onDrop?: (files: File[]) => void;
}

export function FileDragAndDrop({
    onFileSelected,
    formats,
    onDragEnter,
    onDragLeave,
    onDrop,
}: FilesDragAndDropProps): React.ReactElement {
    const [errorMessage, setErrorMessage] = useState<string>();
    const [currentFileName, setCurrentFileName] = useState<string>();
    const { drag, drop, dragging } = useDragAndDrop({
        onDragEnter,
        onDragLeave,
        onDrop: (files) => {
            onDrop && onDrop(files);
            handleUpload(files);
        },
    });

    const input = React.useRef<HTMLInputElement | null>(null);

    const handleUpload = (files: File[]) => {
        setErrorMessage('');
        if (files.length > 1) {
            setErrorMessage(getMessage('text_upload_error_multiple'));
            return;
        }

        if (
            formats &&
            files.some(
                (file: File) =>
                    !formats.some((format) =>
                        file.name.toLowerCase().endsWith(format.toLowerCase())
                    )
            )
        ) {
            setErrorMessage(getMessage('validation_invalid_file_format'));
            return;
        }

        if (files && files.length === 1) {
            const fileName = files[0].name;
            setCurrentFileName(fileName);
            onFileSelected(files[0]);
        }
    };

    const openFileDialog = () => {
        input && input.current?.click();
    };

    const handleFileSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = [...(e.target.files || [])];
        handleUpload(files);
    };

    const clearFileSelection = () => {
        setCurrentFileName(undefined);
        if (input.current) {
            input.current.value = '';
        }
        onFileSelected(null);
    };

    const renderState = (state: FileUploadState) => {
        switch (state) {
            case 'error':
                return (
                    <ErrorState
                        errorMessage={errorMessage ?? ''}
                        onOpenDialog={openFileDialog}
                    />
                );
            case 'empty':
                return <EmptyState onOpenDialog={openFileDialog} />;
            case 'hasFile':
                return (
                    <HasFileState onClear={clearFileSelection}>
                        {currentFileName}
                    </HasFileState>
                );
            case 'dragging':
                return <DragOverState ref={drag} />;
            default:
                return undefined;
        }
    };

    return (
        <div className={'atl-flex-1 atl-h-full'} ref={drop}>
            <HiddenFileUploadInput
                ref={input}
                formats={formats}
                onChange={handleFileSelection}
            />
            {renderState(
                determineState(dragging, currentFileName, errorMessage)
            )}
        </div>
    );
}

const EmptyState = ({ onOpenDialog }: { onOpenDialog: () => void }) => {
    return (
        <div
            className={
                'atl-flex atl-flex-col atl-justify-center atl-items-center atl-relative atl-rounded atl-border-blue-100 atl-bg-blue-5 atl-h-full atl-relative'
            }
            onClick={onOpenDialog}
            style={{ cursor: 'pointer', border: '1px dashed' }}
        >
            <UploadFileIcon />
            <div>
                {getMessage('text_import_supported_files', 'csv', 'xlsx')}
            </div>
        </div>
    );
};

const ErrorState = ({
    errorMessage,
    onOpenDialog,
}: {
    errorMessage: string;
    onOpenDialog: () => void;
}) => {
    return (
        <div
            className={
                'atl-flex atl-flex-col atl-justify-center atl-items-center atl-relative atl-rounded atl-border-red-100 atl-bg-red-5 atl-h-full atl-relative'
            }
            onClick={onOpenDialog}
            style={{ cursor: 'pointer', border: '1px dashed' }}
        >
            <UploadFileIcon />
            <div className="atl-text-red-100">{errorMessage}</div>
        </div>
    );
};

const HiddenFileUploadInput = forwardRef<
    HTMLInputElement,
    {
        formats: string[] | undefined;
        onChange?: ChangeEventHandler<HTMLInputElement>;
    }
>(function ({ formats, onChange }, ref) {
    return (
        <input
            className={'tlx-file-upload__hidden-input'}
            ref={ref}
            type="file"
            accept={
                formats
                    ? formats.map((format) => `.${format}`).join(', ')
                    : undefined
            }
            multiple={false}
            onChange={onChange}
            tabIndex={-1}
        />
    );
});

const DragOverState = forwardRef<
    HTMLDivElement,
    HTMLAttributes<HTMLDivElement>
>(function (_, ref) {
    return (
        <div
            className={
                'atl-flex atl-flex-col atl-justify-center atl-items-center atl-relative atl-rounded atl-border-blue-100 atl-bg-blue-5 atl-h-full atl-relative'
            }
            ref={ref}
            style={{ cursor: 'pointer', border: '1px dashed' }}
        >
            {getMessage('text_upload_release_files')}
        </div>
    );
});

const HasFileState = ({
    children,
    onClear,
}: {
    children: React.ReactNode;
    onClear: () => void;
}) => {
    return (
        <div
            className={
                'atl-flex atl-flex-col atl-gap-8 atl-justify-center atl-items-center atl-relative atl-rounded atl-border-blue-100 atl-bg-blue-5 atl-h-full atl-relative goldshark-overrides'
            }
            style={{ cursor: 'pointer', border: '1px dashed' }}
        >
            {getMessage('text_import_file')}
            <Chip
                value={'selectedFile'}
                onRemove={onClear}
                className="atl-border atl-border-grey-40 atl-p-4"
            >
                <AttachmentIcon className="atl-pl-4" />
                {children}
            </Chip>
        </div>
    );
};

function determineState(
    dragging: boolean,
    currentFile?: string,
    errorMessage?: string
): FileUploadState {
    if (dragging) {
        return 'dragging';
    } else if (errorMessage) {
        return 'error';
    } else if (currentFile) {
        return 'hasFile';
    }
    return 'empty';
}
