import { Group, Input, Label, RadioGroup } from '@tlx/atlas';
import { useState } from 'react';

export function ChangePriceSellingPriceSection({
    showPriceChangeWarning,
}: {
    showPriceChangeWarning: boolean;
}) {
    const [contentValue, setContentValue] = useState({
        type: 'percent',
        percentValue: '',
        value: '',
    });

    return (
        <div className="atl-flex atl-gap-24 atl-items-center atl-flex-col">
            <div className="atl-flex atl-gap-16 atl-w-full">
                <Group className="atl-w-full">
                    <Label>
                        <RadioGroup>
                            <Input
                                id="percent"
                                type="radio"
                                data-testid="change-price-sales-percent-radio"
                                checked={contentValue.type === 'percent'}
                                onChange={() => {
                                    setContentValue({
                                        type: 'percent',
                                        percentValue: '',
                                        value: '',
                                    });
                                }}
                                disabled={showPriceChangeWarning}
                                name="changePriceSalesPercentRadio"
                            />
                            <Label htmlFor="percent">
                                {getMessage('text_percent')}
                            </Label>
                        </RadioGroup>
                    </Label>
                    <Input
                        type="number"
                        name="changePriceSalesPercentInput"
                        data-testid="change-price-sales-percent-input"
                        disabled={
                            contentValue.type !== 'percent' ||
                            showPriceChangeWarning
                        }
                        value={contentValue.percentValue}
                        onChange={(e) =>
                            setContentValue({
                                ...contentValue,
                                percentValue: e.target.value,
                            })
                        }
                    />
                </Group>
                <Group className="atl-w-full">
                    <Label>
                        <RadioGroup>
                            <Input
                                id="value"
                                type="radio"
                                data-testid="change-price-sales-value-radio"
                                checked={contentValue.type === 'value'}
                                onChange={() => {
                                    setContentValue({
                                        type: 'value',
                                        percentValue: '',
                                        value: '',
                                    });
                                }}
                                name="changePriceSalesValueRadio"
                                disabled={showPriceChangeWarning}
                            />
                            <Label htmlFor="value">
                                {getMessage('text_value')} (NOK)
                            </Label>
                        </RadioGroup>
                    </Label>
                    <Input
                        type="money"
                        name="changePriceSalesValueInput"
                        data-testid="change-price-sales-value-input"
                        disabled={
                            contentValue.type !== 'value' ||
                            showPriceChangeWarning
                        }
                        value={contentValue.value}
                        onChange={(e) =>
                            setContentValue({
                                ...contentValue,
                                value: e.target.value,
                            })
                        }
                    />
                </Group>
            </div>
        </div>
    );
}
