/**
 * Run the processing function for every argument provided concurrently with a limit
 * on how many can run concurrently.
 *
 * @param inputDataList The arguments that will be passed into the processing function
 * @param processingFunction The function that will do the work for each argument
 * @param concurrencyLimit Limit on how many can run concurrently at once. Default is 8.
 */

export const asyncPool = <InputData, OutputData>(
    inputDataList: InputData[],
    processingFunction: (
        argument: InputData
    ) => OutputData | Promise<OutputData>,
    concurrencyLimit = 8
): Promise<OutputData[]> => {
    return new Promise((resolve) => {
        // Copy arguments to avoid side effect, reverse queue as
        // pop is faster than shift
        const argumentQueue: InputData[] = [...inputDataList].reverse();
        let concurrencyCount = 0;
        const completedResults: OutputData[] = [];

        function processOut(outputData: OutputData | undefined, index: number) {
            if (outputData) {
                completedResults[index] = outputData;
            }
            concurrencyCount -= 1;
            pollNext();
        }

        function pollNext() {
            if (argumentQueue.length === 0 && concurrencyCount === 0) {
                resolve(completedResults);
            } else {
                while (
                    concurrencyCount < concurrencyLimit &&
                    argumentQueue.length
                ) {
                    const nextIndex =
                        inputDataList.length - argumentQueue.length;
                    const nextInputData: InputData | undefined =
                        argumentQueue.pop();
                    concurrencyCount += 1;
                    let returnedValue:
                        | OutputData
                        | Promise<OutputData>
                        | undefined = undefined;

                    if (nextInputData) {
                        returnedValue = processingFunction(nextInputData);
                    }
                    if (!returnedValue) {
                        processOut(undefined, nextIndex);
                        continue;
                    }

                    Promise.resolve(returnedValue).then(
                        (outputData: OutputData) =>
                            processOut(outputData, nextIndex)
                    );
                }
            }
        }

        pollNext();
    });
};
