import { HistoryChanged } from '../action/HistoryShim.action';
import { ActionReducer } from '../types/Reducer';
import { HistoryState } from '../types/HistoryShim.type';
import { parseUrl } from '../HistoryShim';

const initialState: HistoryState = parseUrl(new URL(window.location.href));

const reducerHistoryChanged: ActionReducer<HistoryState, HistoryChanged> = (
    state,
    action
) => ({
    ...action.after,
});

type SupportedActions = HistoryChanged;

export function historyReducer(
    state: HistoryState = initialState,
    action: SupportedActions
) {
    switch (action.type) {
        case HistoryChanged.type:
            return reducerHistoryChanged(state, action);
        default:
            return state;
    }
}
