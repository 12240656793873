export function getColumnLabel(column: string) {
    switch (column) {
        case 'discount':
            return getMessage('text_discount');
        case 'priceExcludingVatCurrency':
            return getMessage('text_sales_price_excl_vat');
        case 'priceWithDiscount':
            return getMessage('text_sales_price_excl_vat_with_discount');
        case 'currency':
            return getMessage('text_currency');
        default:
            throw new Error(`Unknown column: ${column}`);
    }
}

export const getAvailableColumnsInProductDiscount = () => {
    return [
        'priceExcludingVatCurrency',
        'discount',
        'priceWithDiscount',
        'currency',
    ];
};
export const getDefaultColumnsInProductDiscount = () => {
    return [
        'priceExcludingVatCurrency',
        'discount',
        'priceWithDiscount',
        'currency',
    ];
};

export function getPriceAfterDiscount(
    price: number,
    discount: string,
    discountType: string
) {
    if (!Number(discount) || discount === '' || Number(discount) < 0) {
        return price;
    }
    const discountValue = Number(discount);
    const isPercentDiscount = discountType === 'percent';
    return isPercentDiscount
        ? price * (1 - discountValue / 100)
        : discountValue;
}

export function updateUrl() {
    const currentUrl = new URL(window.location.href);
    currentUrl.searchParams.delete('query');
    currentUrl.searchParams.delete('productGroup');

    window.history.pushState({}, '', currentUrl);
}
