import $ from 'jquery';

/**
 * A jQuery replacement of js:alert. Please be aware of differences from native alert, such as halting events while alert is open.
 * @param message The message to display
 * @param callback (optional) Called when the dialog is closed (regardless of method)
 */
export function tlxAlert(message: string, callback?: () => void) {
    tlxAlertWithTrackingId(message, callback, '');
}

/**
 * A jQuery replacement of js:alert. Please be aware of differences from native alert, such as halting events while alert is open.
 * @param message The message to display
 * @param callback (optional) Called when the dialog is closed (regardless of method)
 * @param okButtonTrackingId (optional) The alert dialog OK button's tracking ID
 */
export function tlxAlertWithTrackingId(
    message: string,
    callback?: () => void,
    okButtonTrackingId = ''
) {
    let $alertDialog = $('#alertDialog');
    //If not found, generate a new one. This will only be done once (until new page load).
    if (!$alertDialog || $alertDialog.length == 0) {
        const buttons = [
            {
                text: getMessage('button_ok'),
                click: function () {
                    $(this).dialog('close');
                },
            },
        ];

        $alertDialog = $(
            "<div id='alertDialog'><div id='alertDialogMsg' class='tlxDialogContent'></div></div>"
        ).dialog({
            autoOpen: false,
            minHeight: 40,
            buttons: buttons,
            modal: true,
            hide: 'fade',
            closeOnEscape: false,
            title: getMessage('text_information'),
        });
    }
    // Message or tracking ID may have changed
    $alertDialog
        .next()
        .children()
        .first()
        .children()
        .first()
        .attr('data-trackingid', okButtonTrackingId);
    $('#alertDialogMsg').html(message);
    $alertDialog.unbind('dialogclose.callback');
    if (callback) {
        $alertDialog.bind('dialogclose.callback', callback);
    }
    $alertDialog.dialog('open');
}
