import { Button } from '@tlx/atlas';
import React from 'react';
import { SendMessage, Signer } from '@Page/CompanyVerificationFlow/Types';
import {
    getSignersForEmailRequest,
    SendSigningRequestFormValues,
} from '@Page/CompanyVerificationFlow/SendSigningRequestForm/SendSigningRequestForm';
import { useFormContext } from 'react-hook-form';

function getSubmitMessage(
    thisIsMeSigner: Signer | undefined,
    signersForSubmission: Signer[]
): string {
    /*
     * Possible states:
     *
     * NOT FILLED
     * 1 SIGNER
     * 1+ SIGNERS
     * 1 SIGNER + THISISME
     * 1+ SIGNERS + THISISME
     */
    const numberOfSigners = signersForSubmission.length;

    if (numberOfSigners > 1 && thisIsMeSigner) {
        return getMessage(
            SendMessage.SEND_MANY_REQUESTS_AND_SIGN_NOW,
            numberOfSigners
        );
    } else if (numberOfSigners === 1 && thisIsMeSigner) {
        return getMessage(SendMessage.SEND_ONE_REQUEST_AND_SIGN_NOW);
    } else if (numberOfSigners > 1) {
        return getMessage(SendMessage.SEND_MANY_REQUESTS, numberOfSigners);
    } else if (numberOfSigners === 1) {
        return getMessage(SendMessage.SEND_ONE_REQUEST);
    } else if (thisIsMeSigner) {
        return getMessage(SendMessage.SIGN_NOW);
    } else {
        return getMessage(SendMessage.NO_DETAILS_PROVIDED);
    }
}

export function SubmitButton({
    form,
    uniqueSigners,
}: {
    uniqueSigners: Signer[];
    form: string;
}) {
    const {
        watch,
        formState: { isValid },
    } = useFormContext<SendSigningRequestFormValues>();

    // TODO: Maybe not quite the ideal way to deal with this?
    const thisIsMeSigner = watch('thisIsMeSigner');
    const signers = Object.values(watch('signers'));
    const signersForEmailRequest = getSignersForEmailRequest(
        thisIsMeSigner,
        uniqueSigners,
        signers
    );

    const submitMessage = getSubmitMessage(
        thisIsMeSigner,
        signersForEmailRequest
    );

    return (
        <Button
            variant="primary"
            data-testid="btn-submit-verification"
            type={'submit'}
            disabled={
                (!thisIsMeSigner && signersForEmailRequest.length === 0) ||
                !isValid
            }
            form={form}
        >
            {submitMessage}
        </Button>
    );
}
