import React, { useState } from 'react';
import {
    DataTree,
    DataTreeContent,
    DataTreeHeader,
    DataTreeTitle,
    DataTreeToggleButton,
} from '@Page/LogisticsV2/components/DataTree/DataTree';
import { DataTreeNode } from '@Page/LogisticsV2/components/DataTree/types';

export function SelectableDataTree({
    nodes,
    selectedNodeId,
    onChange,
}: {
    nodes: DataTreeNode[];
    selectedNodeId: number | undefined;
    onChange: (nodeId: number) => void;
}) {
    return (
        <div role="tree" className="tlx-product-group-tree">
            {nodes.map((node) => (
                <SelectableDataTreeItem
                    key={node.id}
                    node={node}
                    selectedNodeId={selectedNodeId}
                    isRoot={true}
                    onChange={onChange}
                />
            ))}
        </div>
    );
}

export function SelectableDataTreeItem({
    node,
    selectedNodeId,
    isRoot,
    onChange,
}: {
    node: DataTreeNode;
    selectedNodeId: number | undefined;
    isRoot: boolean;
    onChange: (nodeId: number) => void;
}) {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <DataTree isRoot={isRoot} isOpen={isOpen}>
            <DataTreeHeader>
                {node.children.length > 0 && (
                    <DataTreeToggleButton
                        isOpen={isOpen}
                        onClick={() => setIsOpen(!isOpen)}
                        data-testid={'toggle-second'}
                    />
                )}
                <DataTreeTitle
                    isSelected={node.id === selectedNodeId}
                    onChange={() => onChange(node.id)}
                >
                    {node.name}
                </DataTreeTitle>
            </DataTreeHeader>
            <DataTreeContent isOpen={isOpen}>
                {node.children.map((node) => (
                    <SelectableDataTreeItem
                        key={node.id}
                        node={node}
                        selectedNodeId={selectedNodeId}
                        isRoot={false}
                        onChange={onChange}
                    />
                ))}
            </DataTreeContent>
        </DataTree>
    );
}
