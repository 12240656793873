import * as React from 'react';

import { renderComponent } from '@General/renderComponent';
import { PageLoader } from '@Component/PageLoader';
import { RouteData } from '@General/Router';
import { Team } from '@General/Team';
import { EntitlementLookup } from '@General/constants';
import { isCompany } from '@Page/YearEnd/Pages/YearEndSubmission/utils/yearEndSubmissionUtils';

const Page: React.FC = (props) => (
    <PageLoader<{}>
        loader={async () => import('./YearEndSubmission').then((m) => m.page)}
        props={props}
        adjustWrapperDiv={true}
    />
);

export function renderYearEndDashboard(domId: string) {
    renderComponent(Page, domId);
}

export const route: RouteData = {
    component: Page,
    path: '/execute/yearEnd/submission',
    documentationComponent:
        window?.segmentationData?.companyType === '1' ? 358 : 375,
    team: Team.PHOENIX,
    // To fully connect this page to the Tripletex framework, the page needs a documentationComponent id so that we can
    // link help articles to the page
    // documentationComponent: ,

    // We should limit who has access to this page.
    // companyType == 1 is ENK
    auth: (context) =>
        isCompany('YEAR_END') &&
        context.entitlementMap[
            (EntitlementLookup.AUTH_COMPANY_ACCOUNTING_REPORTS,
            EntitlementLookup.AUTH_ALL_VOUCHERS)
        ] !== undefined &&
        ((!window?.segmentationData?.modules?.yearEndReport &&
            context.entitlementMap[
                EntitlementLookup.AUTH_ACCOUNT_ADMINISTRATOR
            ] !== undefined) ||
            (!window?.segmentationData?.modules?.yearEndReport &&
                window?.isActiveAccountantProxy) ||
            (window?.segmentationData?.modules?.yearEndReport &&
                context.entitlementMap[
                    EntitlementLookup.YEAR_END_REPORT_ADMINISTRATOR
                ] !== undefined)),
};
