import { useLoadMoreTarget } from '@Page/LogisticsV2/hooks/fetcher';
import {
    IconSection,
    ResultSection,
    ResultStep,
} from '@Page/LogisticsV2/pages/Import v2/steps/ResultStep';
import {
    ImportSummarySkeleton,
    ImportTableSkeleton,
    ValidationFilterBar,
    ValidationRow,
    ValidationStep,
    ValidationSummary,
    ValidationTable,
    ValidationTableSkeleton,
} from '@Page/LogisticsV2/pages/Import v2/steps/ValidationStep';
import { useFileValidation } from '@Page/LogisticsV2/pages/Import v2/useFileValidation';
import { useState } from 'react';
import {
    ActionBar,
    AutomationDescription,
    DeleteFileWarning,
    ImportPageLayout,
    ImportTitleSection,
    StepContainer,
    StepDescriptionContainer,
    StepOneDescription,
    StepTwoDescription,
} from './components';
import {
    DescriptionSection,
    FileUploadStep,
    IncompleteImportsSection,
    IncompleteImportTable,
    IncompleteImportTableRow,
    UploadFileSection,
} from './steps/FileUploadStep';
import {
    MappingSection,
    MappingStep,
    MappingTable,
    MappingTableRow,
    RequiredFieldsComponent,
} from './steps/MappingStep';
import {
    step,
    VOLUME_FIELD,
    VOLUME_UNIT_FIELD,
    WEIGHT_FIELD,
    WEIGHT_UNIT_FIELD,
} from './types';
import { useFieldMapping } from './useFieldMapping';
import { useFileUpload } from './useFileUpload';
import { checkDuplicates, checkError, checkMandatory } from './utils';
import {
    Modal,
    Spinner,
    TableLayout,
    TableLayoutContent,
    TableLayoutTopbar,
} from '@tlx/atlas';
import { NoResultsEmptyState } from '@Page/LogisticsV2/components/EmptyState/EmptyState';

export function ImportPage() {
    const [activeStep, setActiveStep] = useState(step.FILE);
    const [showDeleteWarning, setShowDeleteWarning] = useState(false);

    const {
        incompleteImports,
        isLoading: isLoadingFileUpload,
        uploadedFile,
        onUploadFile,
        onDeleteFile,
        onChangeImportId,
    } = useFileUpload(setActiveStep);

    const {
        fileFields,
        isLoading: isLoadingMapping,
        headers,
        mappedFields,
        requiredFields,
        status: mappingStatus,
        onChangeMapping,
        onContinueMapping,
        onSubmitMapping,
    } = useFieldMapping(uploadedFile, setActiveStep);

    const [displayWarningModal, setDisplayWarningModal] = useState(false);

    const mandatoryFields = checkMandatory(mappedFields, requiredFields ?? []);
    const duplicateFields = checkDuplicates(mappedFields);
    const hasAutomatedFields = uploadedFile?.productImportHeaders.some(
        (item) => item.suggestedColumnField !== null
    );

    const {
        data,
        status,
        isLoading,
        isLoadingImport,
        hasMore,
        loadMore,
        onRefresh,
        result,
        onChangeStatus,
        onSubmitValidation,
    } = useFileValidation(activeStep, uploadedFile?.id, () =>
        setActiveStep(step.RESULT)
    );

    const loadMoreRef = useLoadMoreTarget<HTMLDivElement>(loadMore);

    const handleSubmitMapping = async () => {
        if (
            Object.keys(mandatoryFields).filter(
                (field) => !mandatoryFields[field]
            ).length > 0 ||
            Object.keys(duplicateFields).length > 0
        ) {
            setDisplayWarningModal(true);
        } else {
            await onSubmitMapping();
            await onRefresh();
        }
    };

    const handleContinueImport = (id: number, status: string) => {
        onChangeImportId(id);
        if (status === step.MAPPING) {
            setActiveStep(step.MAPPING);
        } else {
            onContinueMapping(id);
            setActiveStep(step.VALIDATE);
        }
    };

    const handleDeleteFile = (id: number | undefined) => {
        onDeleteFile(id);
        setShowDeleteWarning(false);
        setActiveStep(step.FILE);
    };

    const shouldHideFormat = (fieldId: number) => {
        if (
            fieldId === WEIGHT_FIELD &&
            Object.values(mappedFields)
                .map((field) => field.id)
                .includes(WEIGHT_UNIT_FIELD)
        ) {
            return true;
        }
        if (
            fieldId === VOLUME_FIELD &&
            Object.values(mappedFields)
                .map((field) => field.id)
                .includes(VOLUME_UNIT_FIELD)
        ) {
            return true;
        }
        return false;
    };

    return (
        <ImportPageLayout>
            <ImportTitleSection step={activeStep} />
            <StepContainer id={step.FILE} activeStep={activeStep}>
                <FileUploadStep>
                    <div className="atl-flex atl-w-full atl-gap-24 atl-flex-1">
                        <UploadFileSection onFileUpload={onUploadFile} />
                        <DescriptionSection />
                    </div>
                    {incompleteImports?.length > 0 && (
                        <IncompleteImportsSection
                            numberOfIncompleteImports={incompleteImports.length}
                        >
                            <IncompleteImportTable>
                                {incompleteImports?.map((data) => {
                                    return (
                                        <IncompleteImportTableRow
                                            key={data.id}
                                            data={data}
                                            onContinue={handleContinueImport}
                                            onDelete={() => {
                                                setShowDeleteWarning(true);
                                                onChangeImportId(data.id);
                                            }}
                                        />
                                    );
                                })}
                            </IncompleteImportTable>
                        </IncompleteImportsSection>
                    )}
                </FileUploadStep>
            </StepContainer>
            <StepContainer id={step.MAPPING} activeStep={activeStep}>
                <MappingStep>
                    <MappingSection>
                        <StepDescriptionContainer>
                            <StepOneDescription />
                            {hasAutomatedFields && <AutomationDescription />}
                            <ActionBar
                                onCancel={() => setShowDeleteWarning(true)}
                                onNext={() => handleSubmitMapping()}
                            />
                        </StepDescriptionContainer>
                        <MappingTable>
                            {isLoadingFileUpload ? (
                                <ImportTableSkeleton rows={3} />
                            ) : (
                                fileFields?.map((item) => {
                                    return (
                                        <MappingTableRow
                                            key={item.id}
                                            item={item}
                                            selectedMapping={
                                                mappedFields[item.name]?.id
                                            }
                                            error={checkError(
                                                mappedFields,
                                                item.name
                                            )}
                                            hideFormat={shouldHideFormat(
                                                mappedFields[item.name]?.id
                                            )}
                                            onChange={onChangeMapping}
                                        />
                                    );
                                })
                            )}
                        </MappingTable>
                    </MappingSection>
                    <RequiredFieldsComponent
                        mandatoryFields={mandatoryFields}
                        duplicateFields={duplicateFields}
                        displayWarningModal={displayWarningModal}
                        toggleWarningModal={setDisplayWarningModal}
                    />
                </MappingStep>
            </StepContainer>
            <StepContainer id={step.VALIDATE} activeStep={activeStep}>
                <ValidationStep>
                    <StepDescriptionContainer>
                        <StepTwoDescription />
                        {isLoadingMapping ? (
                            <ImportSummarySkeleton />
                        ) : (
                            <ValidationSummary status={mappingStatus} />
                        )}
                        <ActionBar
                            onBack={() => {
                                setActiveStep(step.MAPPING);
                            }}
                            onCancel={() => setShowDeleteWarning(true)}
                            onSubmit={() => onSubmitValidation()}
                        />
                    </StepDescriptionContainer>
                    <TableLayout>
                        <TableLayoutTopbar>
                            <ValidationFilterBar
                                status={status}
                                onChangeStatus={onChangeStatus}
                            />
                        </TableLayoutTopbar>
                        <TableLayoutContent
                            style={{
                                maxHeight: 'calc(100vh - 370px)',
                                overflowY: 'auto',
                            }}
                        >
                            <ValidationTable headers={headers}>
                                {isLoadingMapping ? (
                                    <ImportTableSkeleton
                                        rows={headers.length + 2}
                                    />
                                ) : (
                                    data?.map((item) => {
                                        return (
                                            <ValidationRow
                                                key={item.id}
                                                item={item}
                                                headers={headers}
                                            />
                                        );
                                    })
                                )}
                                {isLoading && !isLoadingMapping && (
                                    <ValidationTableSkeleton
                                        length={headers.length + 2}
                                    />
                                )}
                                {data?.length === 0 &&
                                    !isLoading &&
                                    !isLoadingMapping && (
                                        <NoResultsEmptyState
                                            colSpan={headers.length + 2}
                                            onClick={() => onChangeStatus(null)}
                                        />
                                    )}
                                {hasMore && (
                                    <tr>
                                        <td>
                                            <div ref={loadMoreRef} />
                                        </td>
                                    </tr>
                                )}
                            </ValidationTable>
                        </TableLayoutContent>
                    </TableLayout>
                </ValidationStep>
                <Modal
                    className="goldshark-import-loading"
                    open={isLoadingImport}
                >
                    <div className="atl-flex atl-flex-col atl-gap-8">
                        <Spinner size="large" />
                        <div>{getMessage('text_saft_importlog_importing')}</div>
                        <div>{getMessage('text_wait')}</div>
                    </div>
                </Modal>
            </StepContainer>
            <StepContainer id={step.RESULT} activeStep={activeStep}>
                <ResultStep>
                    <IconSection />
                    <ResultSection data={result} />
                </ResultStep>
            </StepContainer>
            {showDeleteWarning && (
                <DeleteFileWarning
                    onConfirm={() => handleDeleteFile(uploadedFile?.id)}
                    onCancel={() => setShowDeleteWarning(false)}
                />
            )}
        </ImportPageLayout>
    );
}
