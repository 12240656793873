import './PaymentWidgetComboDropdown.css';
import './PaymentWidgetDropdown.scss';
import React from 'react';
import { useRef, useEffect } from 'react';
import type { TestableProps } from '@tlx/atlas';
import { DesktopDropdownList } from '@Component/DropDown/DropDown';
import classNames from 'classnames';

interface PaymentWidgetDropdownProps extends TestableProps {
    readonly dropdownOption: string;
    readonly setDropdownOption: (value: string) => void;
    readonly options: string[];
    readonly atlasDesignSystem: boolean;
    readonly name?: string;
    readonly validationMessage?: string;
    readonly className?: string;
    readonly resetValidationMessage?: () => void;
    readonly children?: React.ReactNode;
}

export const PaymentWidgetComboDropdown: React.FC<PaymentWidgetDropdownProps> =
    ({
        dropdownOption,
        setDropdownOption,
        options = [],
        name,
        children,
        validationMessage,
        resetValidationMessage,
        className,
        'data-testid': dataTestId,
    }) => {
        const hiddenInput = useRef<HTMLInputElement | null>(null);

        useEffect(() => {
            if (dropdownOption !== undefined && hiddenInput?.current) {
                const event = new Event('change', { bubbles: false });
                hiddenInput.current.dispatchEvent(event);
            }
        }, [dropdownOption]);

        const mappedOptions = options.map((item) => {
            const values = item.split(',');
            let name = '';
            if (values.length == 1) {
                return {
                    value: item,
                    displayName: item,
                };
            } else if (values.length == 2) {
                name = values[1];
            } else if (values.length >= 3) {
                const firstIndex = item.indexOf(',');
                const lastIndex = item.lastIndexOf(',');
                name = item.substring(firstIndex + 1, lastIndex);
            }
            return {
                value: item,
                displayName: name,
            };
        });

        mappedOptions.splice(0, 0, {
            value: '',
            displayName: '',
        });

        return (
            <div className="account-number-field-icon">
                <input
                    ref={hiddenInput}
                    type="hidden"
                    name={name}
                    value={dropdownOption}
                />

                <DesktopDropdownList
                    dataTestId={dataTestId}
                    className={classNames(
                        'inputItem pbc-payment__section-validation-non-atlas ',
                        className
                    )}
                    label={children?.toString()}
                    search={true}
                    isCombo={true}
                    selected={{
                        value: dropdownOption,
                        displayName: dropdownOption,
                    }}
                    options={mappedOptions}
                    onChange={(e) => {
                        setDropdownOption(e.value ? e.value.toString() : '');

                        resetValidationMessage?.();
                    }}
                    required
                    error={validationMessage}
                />
            </div>
        );
    };
