// This file imports all the legacy code.

// Sentry
import * as Sentry from '@sentry/browser';

window.Sentry = Sentry;

import { getMessage, getLocaleMessage } from './js/modules/getMessage';

window.getMessage = getMessage;
window.getLocaleMessage = getLocaleMessage;

// jQuery
import jQuery from 'jquery';

window.$ = window.jQuery = jQuery;

import '@tlx/jquery-ui-fork';
import './js/legacy/libs/jquery.ui.touch-punch.min.js';
import './js/legacy/jquery.scrollIntoView.min.js';

// This one seems like it's only used in messagesEmail.jsp
import './js/legacy/jquery/layout/jquery.layout-latest.js';

import './js/legacy/jquery/fileupload/jquery.fileupload.js';
import tmpl from './js/legacy/jquery/fileupload/tmpl.min.js';

// Only used in tlxFileupload.js / jsp
window.tmpl = tmpl;

// some libs
import 'material-design-lite';

// more libs
// used for imageUpload
import EXIF from 'exif-js';
// Croppie expects exif-js to exist on window - otherwise, image rotation will not work.
window.EXIF = EXIF;
const originalEXIF = window.EXIF;
window.EXIF = {
    getData(image, callback) {
        try {
            originalEXIF.getData(image, callback);
        } catch (error) {
            window.Sentry.captureException(error);
            callback();
        }
    },
};
import Croppie from 'croppie';
window.Croppie = Croppie;

// jsonrpc
import { JSONRpcClient, marshallSpec, toJSON } from './js/legacy/jsonrpc.js';

window.JSONRpcClient = JSONRpcClient;
window.marshallSpec = marshallSpec;
window.toJOSN = toJSON;

// modules
import './js/modules/accountCurrentBookUtil.js';
import './js/modules/autoComplete1881.js';
import './js/modules/comments.js';
import './js/modules/controlSchema.js';
import './js/modules/creditNotes.js';
import './js/modules/creditScoring.js';
import { arrayUtil } from './js/modules/d-array.ts';

window.arrayUtil = arrayUtil;

import { selectUtil } from './js/modules/selectUtil.ts';
window.selectUtil = selectUtil;

import { browserInfo } from './js/modules/d-browserInfo.ts';

window.browserInfo = browserInfo;
import './js/modules/dashboardWidget.js';
import { dateUtil } from './js/modules/date.ts';

window.dateUtil = dateUtil;
import { dev } from './js/modules/dev-util.js';

window.dev = dev;
import { dialog } from './js/modules/dialog.js';

window.dialog = dialog;

import { dom } from './js/modules/dom.js';

window.dom = dom;
import { dtable } from './js/modules/dtable.js';

window.dtable = dtable;
import { extraFrame } from './js/modules/extraFrame.ts';

window.extraFrame = extraFrame;

import { filter } from './js/modules/filter.ts';

window.filter = filter;

import { format } from './js/modules/format.ts';

window.format = format;

import { tlxForms } from './js/modules/forms';

window.tlxForms = tlxForms;

import { app } from './js/modules/framework.js';

window.app = app;
import './js/modules/httpUtils.js';
import './js/modules/invoicing.js';
import { jsonrpcUtil } from './js/modules/jsonrpcUtil.js';

window.jsonrpcUtil = jsonrpcUtil;
import { localAPI } from './js/modules/localAPI.js';

window.localAPI = localAPI;

import { nav } from './js/modules/navigation.ts';

window.nav = nav;

import {
    showActionLogMessage,
    showValidationMessage,
} from './js/modules/notification.ts';
window.showValidationMessage = showValidationMessage;
window.showActionLogMessage = showActionLogMessage;

import './js/modules/pt-reorder.js';
import { sendEmail } from './js/modules/sendEmail';
window.sendEmail = sendEmail;

import './js/modules/service-promotion.js';
import './js/modules/smoothScroll.js';
import { stringUtils } from './js/modules/stringUtils.ts';

import { encodeJavaScript } from './js/modules/encodeJavaScript';
window.encodeJavaScript = encodeJavaScript;

window.stringUtils = stringUtils;
window.isString = stringUtils.isString;

import './js/modules/templateSelector.js';

import { addContextId, tlxUrl } from './js/modules/url.ts';
window.addContextId = addContextId;
window.tlxUrl = tlxUrl;
import { utils } from './js/modules/utils.js';

window.utils = utils;

import { distributionKeyWidget } from '@Page/DistributionKeys/distributionKeyWidget';
window.distributionKeyWidget = distributionKeyWidget;

// components
import './js/component/accordion/accordion.js';
import './js/component/dialog/dialog.js';
import './js/component/dropdown/dropdown.js';
import './js/component/form/form.js';
import { openPaymentWidget } from './js/component/paymentWidget/paymentWidget.tsx';
window.openPaymentWidget = openPaymentWidget;
import { html5Helper } from './js/component/html5-input-type-assistant/html5-input-type-assistant.js';

window.html5Helper = html5Helper;
import './js/component/indexTop.js';
import './js/component/iosScroll.js';
import './js/component/mainMenuSimple/mainMenuSimple.js';
import './js/component/section/section.js';
import './js/component/sectionToolbar/sectionToolbar.js';
import './js/component/textarea/textarea.js';
import { toolTip } from './js/component/tooltip/tooltip.ts';

window.toolTip = toolTip;
import './js/component/viewOptions/viewOptionsDialog.js';

// common
import {
    checkboxFix,
    collapseExtra,
    deepUpdateIndices,
    getElement,
    getElementFloat,
    getElementInt,
    getElementCheckboxInt,
    getElementValue,
    getTableRowCount,
    tlxInitJsonrpc,
    setElementValue,
    indexedName,
    getScopeId,
    getScopedId,
    makeSafeCallback,
    logout,
    LazyContentLoader,
    round2,
    showElement,
    tlxGetScope,
    toArray,
    updateDigits,
    updateDeleteButton,
} from './js/c-common.js';
window.checkboxFix = checkboxFix;
window.collapseExtra = collapseExtra;
window.deepUpdateIndices = deepUpdateIndices;
window.getElement = getElement;
window.getElementFloat = getElementFloat;
window.getElementInt = getElementInt;
window.getElementCheckboxInt = getElementCheckboxInt;
window.getElementValue = getElementValue;
window.getTableRowCount = getTableRowCount;
window.tlxInitJsonrpc = tlxInitJsonrpc;
window.setElementValue = setElementValue;
window.indexedName = indexedName;
window.getScopeId = getScopeId;
window.getScopedId = getScopedId;
window.makeSafeCallback = makeSafeCallback;
window.logout = logout;
window.LazyContentLoader = LazyContentLoader;
window.round2 = round2;
window.showElement = showElement;
window.tlxGetScope = tlxGetScope;
window.toArray = toArray;
window.updateDigits = updateDigits;
window.updateDeleteButton = updateDeleteButton;

import { decodeHTML, encodeHTML } from './js/modules/encodeHTML';
window.decodeHTML = decodeHTML;
window.encodeHTML = encodeHTML;

import {
    sourceLessChangedKey,
    changed,
    changeTest,
    clearChanged,
    clearTabChanged,
    isChanged,
    clearGeneralValidation,
} from './js/modules/change';
window.sourceLessChangedKey = sourceLessChangedKey;
window.changed = changed;
window.changeTest = changeTest;
window.clearChanged = clearChanged;
window.clearTabChanged = clearTabChanged;
window.isChanged = isChanged;
window.clearGeneralValidation = clearGeneralValidation;

import {
    bindEventsInsideScope,
    defaultAjaxAction,
    defaultJsonRpcExceptionHandler,
    getActiveDocumentationComponent,
    getContentOverlay,
    hideContent,
    hideElement,
    hideOverlays,
    getTooltip,
    hideAndSubmit,
    loadPageContent,
    populateIdAndRevision,
    showGetStarted,
    refreshUrl,
} from './js/o-common.js';
import { getRelevantUrl } from './js/modules/scope';

window.bindEventsInsideScope = bindEventsInsideScope;
window.clearChanged = clearChanged;
window.defaultAjaxAction = defaultAjaxAction;
window.defaultJsonRpcExceptionHandler = defaultJsonRpcExceptionHandler;
window.getActiveDocumentationComponent = getActiveDocumentationComponent;
window.getContentOverlay = getContentOverlay;
window.getRelevantUrl = getRelevantUrl;
window.hideContent = hideContent;
window.hideElement = hideElement;
window.hideElement = hideElement;
window.hideOverlays = hideOverlays;
window.getTooltip = getTooltip;
window.hideAndSubmit = hideAndSubmit;
window.loadPageContent = loadPageContent;
window.populateIdAndRevision = populateIdAndRevision;
window.showGetStarted = showGetStarted;
window.refreshUrl = refreshUrl;

import { tlxAlert, tlxAlertWithTrackingId } from './js/modules/alert';
window.tlxAlert = tlxAlert;
window.tlxAlertWithTrackingId = tlxAlertWithTrackingId;

import {
    tlxConfirm,
    tlxConfirmWithTrackingId,
    tlxConfirm2,
    tlxConfirm2WithTrackingId,
} from './js/modules/confirm';
window.tlxConfirm = tlxConfirm;
window.tlxConfirmWithTrackingId = tlxConfirmWithTrackingId;
window.tlxConfirm2 = tlxConfirm2;
window.tlxConfirm2WithTrackingId = tlxConfirm2WithTrackingId;

import './js/legacy/tlxUtil.js';
import './js/legacy/jquery.stickytableheaders.js';
import { getBrowserInfo } from './js/legacy/browserInfo';

window.getBrowserInfo = getBrowserInfo;
import { logException } from './js/legacy/clientLogging.js';

window.logException = logException;

import {
    initTaskTableDragAndDrop,
    initTaskTableReorderButtons,
} from './js/legacy/task_table_reorder';

window.initTaskTableDragAndDrop = initTaskTableDragAndDrop;
window.initTaskTableReorderButtons = initTaskTableReorderButtons;

// mobiscroll
import './js/mobiscroll/mobiscroll.core.js';
import './js/mobiscroll/mobiscroll.datetime.js';
import './js/mobiscroll/mobiscroll.select.js';

// widgets
import './widgets/aprila/aprila.js';
import './widgets/caves/caves.js';
import './widgets/filterComponent/filterComponent.js';
import './widgets/frameless/frameless.js';
import './widgets/highlight/highlight.js';
import './widgets/inputOpener/inputOpener.js';
import './widgets/loader/loader.js';
import './widgets/popupOpener/popupOpener.js';
import './widgets/queue/queue.js';
import './widgets/tabs/tabs.js';
import './widgets/tabzum/tabzum.js';
import './widgets/toolbar/toolbar.js';
import './widgets/validationPopup/validationPopupNarrow.js';
import './widgets/zelect/zelect.js';
import './widgets/widgets.js';
import './widgets/periodselecter/periodselecter.js';

// Must be called after importing widgets
import { setDefaults } from './js/legacy/defaults.js';
setDefaults();

// frameless
import './js/legacy/frameless.js';
