import * as React from 'react';

import { renderComponent } from '@General/renderComponent';
import { PageLoader } from '@Component/PageLoader';

const loader: React.FC = () => (
    <PageLoader<{}>
        loader={async () => import('./ProductPrice').then((m) => m.page)}
        adjustWrapperDiv
    />
);

export function renderProductPrices(domId: string) {
    renderComponent(loader, domId);
}
