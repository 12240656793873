/**
 * Adds and shows the given messages in the validation message tray.
 *
 * @param {string} messages
 *
 * @author tellef
 * @date 2020-10-30
 */
import { showValidationMessage } from '../../../js/modules/notification';

export function postValidationMessages(...messages: string[]) {
    if (messages.length === 0) {
        return;
    }

    for (const message of messages) {
        showValidationMessage({ message });
    }
}
