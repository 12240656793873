import { AsyncDropdownOptions } from '@tlx/astro-shared';
import {
    CheckboxGroup,
    Dropdown,
    DropdownDrawer,
    DropdownOpener,
    DropdownScrollContainer,
    DropdownSearch,
    Group,
    Input,
    Label,
    Option,
    RadioGroup,
} from '@tlx/atlas';
import { ReactNode, useState } from 'react';
import '../style.css';
import { NOT_CHOSEN } from '../utils';
import { ApiUrl } from '@Page/LogisticsV2/utils/enums';

export function ChangeProductDetailsContent({
    children,
}: {
    children: ReactNode;
}) {
    return (
        <div
            className="atl-flex atl-gap-32 atl-flex-col atl-mt-32"
            style={{ width: '500px' }}
        >
            {children}
        </div>
    );
}

export function ChangeProductDetailsDropdowns({
    isSupplierProduct,
}: {
    isSupplierProduct: boolean;
}) {
    return (
        <div className="atl-flex atl-flex-col atl-gap-32">
            {window.loginCompanyModules.department && !isSupplierProduct && (
                <Group>
                    <Label>{getMessage('text_department')}</Label>
                    <Dropdown
                        data-testid={'department-dropdown'}
                        id="change-product-details-department"
                        name="productDetailsDepartment"
                        defaultValue={undefined}
                    >
                        <DropdownOpener className="atl-w-full" />
                        <DropdownDrawer>
                            <DropdownSearch />
                            <DropdownScrollContainer>
                                <Option value={undefined}>
                                    {getMessage('text_no_change')}
                                </Option>
                                <Option value={NOT_CHOSEN}>
                                    {getMessage('option_not_chosen')}
                                </Option>
                                <AsyncDropdownOptions
                                    url={
                                        '/v2/department/query?isInactive=false'
                                    }
                                />
                            </DropdownScrollContainer>
                        </DropdownDrawer>
                    </Dropdown>
                </Group>
            )}
            <div className="atl-flex atl-gap-16">
                <Group className="atl-flex-1 goldshark-min-width-0">
                    <Label>{getMessage('text_product_unit')}</Label>
                    <Dropdown
                        data-testid={'product-unit-dropdown'}
                        id="change-product-details-unit"
                        name="productDetailsUnit"
                        defaultValue={undefined}
                    >
                        <DropdownOpener className="atl-w-full" />
                        <DropdownDrawer>
                            <DropdownSearch />
                            <DropdownScrollContainer>
                                <Option value={undefined}>
                                    {getMessage('text_no_change')}
                                </Option>
                                <Option value={NOT_CHOSEN}>
                                    {getMessage('option_not_chosen')}
                                </Option>
                                <AsyncDropdownOptions
                                    url={'/v2/product/unit/query'}
                                />
                            </DropdownScrollContainer>
                        </DropdownDrawer>
                    </Dropdown>
                </Group>
                {!isSupplierProduct && (
                    <Group className="atl-flex-1 goldshark-min-width-0">
                        <Label>{getMessage('text_income_account')}</Label>
                        <Dropdown
                            data-testid={'income-account-dropdown'}
                            id="change-product-details-income-account"
                            defaultValue={undefined}
                            name="productDetailsIncomeAccount"
                        >
                            <DropdownOpener className="atl-w-full" />
                            <DropdownDrawer>
                                <DropdownSearch />
                                <DropdownScrollContainer>
                                    <Option value={undefined}>
                                        {getMessage('text_no_change')}
                                    </Option>
                                    <Option value={NOT_CHOSEN}>
                                        {getMessage(
                                            'option_use_std_account_order_lines'
                                        )}
                                    </Option>
                                    <AsyncDropdownOptions
                                        url={ApiUrl.INCOME_ACCOUNT_QUERY}
                                    />
                                </DropdownScrollContainer>
                            </DropdownDrawer>
                        </Dropdown>
                    </Group>
                )}
            </div>
        </div>
    );
}

export function ChangeProductDetailsStatus() {
    const [contentValue, setContentValue] = useState<string>('undefined');

    return (
        <div className="atl-flex atl-gap-8 atl-flex-col">
            <Label>{getMessage('text_status')}</Label>
            <div className="atl-flex atl-gap-16">
                <RadioGroup>
                    <Input
                        data-testid="unchanged-status"
                        type="radio"
                        id="change-product-details-status-unchanged"
                        name="productDetailsStatus"
                        onChange={() => {
                            setContentValue('undefined');
                        }}
                        checked={contentValue === 'undefined'}
                        value={'undefined'}
                    />
                    <Label htmlFor="change-product-details-status-unchanged">
                        {getMessage('text_unchanged')}
                    </Label>
                </RadioGroup>
                <RadioGroup>
                    <Input
                        data-testid="active-status"
                        type="radio"
                        id="change-product-details-status-active"
                        name="productDetailsStatus"
                        onChange={() => {
                            setContentValue('active');
                        }}
                        checked={contentValue === 'active'}
                        value={'active'}
                    />
                    <Label htmlFor="change-product-details-status-active">
                        {getMessage('text_active')}
                    </Label>
                </RadioGroup>
                <RadioGroup>
                    <Input
                        data-testid="inactive-status"
                        type="radio"
                        id="change-product-details-status-inactive"
                        name="productDetailsStatus"
                        checked={contentValue === 'inactive'}
                        onChange={() => {
                            setContentValue('inactive');
                        }}
                        value={'inactive'}
                    />
                    <Label htmlFor="change-product-details-status-inactive">
                        {getMessage('text_inactive')}
                    </Label>
                </RadioGroup>
            </div>
        </div>
    );
}

export function ChangeProductDetailsStockItem() {
    const [contentValue, setContentValue] = useState<string>('undefined');
    return (
        <div className="atl-flex atl-gap-8 atl-flex-col">
            <Label>{getMessage('text_warehouse_product')}</Label>
            <div className="atl-flex atl-gap-16">
                <RadioGroup>
                    <Input
                        data-testid="unchanged-stock-item"
                        id="change-product-details-stock-item-unchanged"
                        type="radio"
                        name="productDetailsStockItem"
                        checked={contentValue === 'undefined'}
                        onChange={() => setContentValue('undefined')}
                        value={'undefined'}
                    />
                    <Label htmlFor="change-product-details-stock-item-unchanged">
                        {getMessage('text_unchanged')}
                    </Label>
                </RadioGroup>
                <RadioGroup>
                    <Input
                        data-testid="stock-item"
                        id="change-product-details-stock-item"
                        type="radio"
                        name="productDetailsStockItem"
                        checked={contentValue === 'true'}
                        onChange={() => setContentValue('true')}
                        value={'stockItem'}
                    />
                    <Label htmlFor="change-product-details-stock-item">
                        {getMessage('text_set')}
                    </Label>
                </RadioGroup>
                <RadioGroup>
                    <Input
                        data-testid="not-stock-item"
                        id="change-product-details-not-stock-item"
                        type="radio"
                        name="productDetailsStockItem"
                        checked={contentValue === 'false'}
                        onChange={() => setContentValue('false')}
                        value={'notStockItem'}
                    />
                    <Label htmlFor="change-product-details-not-stock-item">
                        {getMessage('text_not_warehouse_product')}
                    </Label>
                </RadioGroup>
            </div>
        </div>
    );
}

export function ChangeProductDetailsStandardSupplier() {
    const [contentValue, setContentValue] = useState<boolean>(false);
    return (
        <CheckboxGroup>
            <Input
                data-testid="standard-supplier"
                type="checkbox"
                id="change-product-details-standard-supplier"
                name="productDetailsStandardSupplier"
                onChange={() => {
                    setContentValue(!contentValue);
                }}
                checked={contentValue}
                value={contentValue.toString()}
            />
            <Label htmlFor="change-product-details-standard-supplier">
                {getMessage('text_set_as_standard_supplier')}
            </Label>
        </CheckboxGroup>
    );
}
