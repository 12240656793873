import * as React from 'react';

import { ButtonIcon, ButtonIconProps } from '../ButtonIcon/ButtonIcon';
import {
    DisplayOption,
    DisplayOptionProps,
} from '../DropDown/DisplayOption/DisplayOption';
import { Responsive } from '../Responsive';

export type FilterChipContainerProps = {
    children?: React.ReactNode;
};

export const FilterChipContainer: React.FunctionComponent<FilterChipContainerProps> =
    (props) => (
        <Responsive desktop tablet>
            <div className="filter_chip-container">{props.children}</div>
        </Responsive>
    );

export type FilterChipProps = {
    visible?: boolean;
    label?: string;
    value: string | undefined;
    action: ButtonIconProps['onClick'];
};

export const FilterChip: React.FunctionComponent<FilterChipProps> = (props) => {
    if (props.visible && props.value !== undefined) {
        return (
            <div className="filter_chip" aria-label={props.label}>
                <span>{props.value}</span>
                <ButtonIcon
                    id={'tlx-filter-chip'}
                    icon="cancel"
                    onClick={props.action}
                />
            </div>
        );
    }
    return null;
};

export type ProviderFilterChip = Omit<FilterChipProps, 'value'> &
    DisplayOptionProps;

export const ProviderFilterChip: React.FunctionComponent<ProviderFilterChip> = (
    props
) => {
    if (
        props.visible &&
        props.id !== undefined &&
        props.provider !== undefined
    ) {
        return (
            <div className="filter_chip" aria-label={props.label}>
                <DisplayOption id={props.id} provider={props.provider} />
                <ButtonIcon icon="cancel" onClick={props.action} />
            </div>
        );
    }
    return null;
};
