import { Option } from '../../types';
import { notUndefined } from '@General/Helpers';
import { ModelAPIV1 } from '@General/types/Api';
import { JSPDropdownFetcher } from '../JSPDropdownFetcher';
import { ApiResultMapper } from '@General/Provider';

interface ProspectEntry extends ModelAPIV1 {
    readonly name: string;
    readonly customerName: string;
}

const ProspectMapper: ApiResultMapper<ProspectEntry> = (item) => {
    return {
        value: item.id + '',
        displayName: item.name,
        secondary: item.customerName,
        item,
    };
};

class ProspectFetcher extends JSPDropdownFetcher<ProspectEntry> {
    asyncJsonrpcGetter: Function;
    constructor(scope: HTMLDivElement, queryAttributes: Record<string, any>) {
        super(scope, queryAttributes, ProspectMapper);
        this.asyncJsonrpcGetter = asyncrpc.Prospect.get;
    }

    getMarshallSpec(): { marshallSpec: string[] } {
        return window.marshallSpec('id', 'name', 'customerName');
    }

    getParams(): ObjectIndex {
        return {};
    }

    async fetchList(params: ObjectIndex): Promise<Option<ProspectEntry>[]> {
        return new Promise((resolve, reject) => {
            try {
                jsonrpc.Prospect.searchForProspects(
                    (result: ProspectEntry[] | null) => {
                        const mapped = (result ?? [])
                            .filter(notUndefined)
                            .map(this.getMapper(params))
                            .filter(notUndefined);
                        resolve(mapped);
                    },
                    this.getMarshallSpec(),
                    window.loginCompanyId,
                    0,
                    params.name
                );
            } catch (e) {
                defaultJsonRpcExceptionHandler(e);
                reject(e);
            }
        });
    }
}

export const exports = {
    fetcher: ProspectFetcher,
    type: 'Prospect',
    link: (id: number, contextId: number) =>
        `/execute/prospectMenu?prospectId=${id}&contextId=${contextId}`,
};
