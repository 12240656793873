export enum ApiUrl {
    BRING_INTEGRATION = '/v2/bringCredentials',
    COMPANY = '/v2/company',
    CURRENCY = '/v2/currency',
    CURRENCY_QUERY = '/v2/currency/query',
    DISCOUNT_POLICY = '/v2/discountPolicy',
    INCOME_ACCOUNT_QUERY = '/v2/ledger/account/query',
    INVENTORY = '/v2/inventory',
    INVENTORY_BY_PRODUCTS = '/v2/inventory/inventories/getInventoriesByProductIds',
    INVENTORY_CORRECTION = '/v2/inventoryCorrection',
    INVENTORY_LOCATION = '/v2/inventory/location',
    INVENTORY_LOCATION_INTERNAL_SEARCH = '/v2/inventory/location/internal/search',
    INVENTORY_STOCKTAKING = '/v2/inventory/stocktaking',
    INVENTORY_STOCKTAKING_PRODUCTLINE = '/v2/inventory/stocktaking/productline',
    INVENTORY_STOCKTAKING_PRODUCTLINE_EXPORT = '/v2/inventory/stocktaking/productline/export',
    INVENTORY_STOCKTAKING_PRODUCTLINE_LIST = '/v2/inventory/stocktaking/productline/list',
    ORDER_QUERY = '/v2/order/query',
    PRODUCT = '/v2/product',
    PRODUCT_BATCH_UPDATE_LIST = '/v2/product/batchUpdateList',
    PRODUCT_EXPORT = '/v2/product/export',
    PRODUCT_EXTERNAL_QUERY = '/v2/product/external/query',
    PRODUCT_IMPORT = '/v2/productImport',
    PRODUCT_IMPORT_FIELDS = '/v2/productImportFields',
    PRODUCT_IMPORT_FIELDS_RELATION_LIST = '/v2/productImportFieldsRelation/list',
    PRODUCT_INVENTORY_LOCATION = '/v2/product/inventoryLocation',
    PRODUCT_GROUP = '/v2/product/group',
    PRODUCT_GROUP_INTERNAL_DELETE = '/v2/product/group/deleteParentGroup',
    PRODUCT_GROUP_QUERY = '/v2/product/group/query',
    PRODUCT_GROUP_RELATIONS = '/v2/product/groupRelation',
    PRODUCT_GROUP_RELATIONS_LIST = '/v2/product/groupRelation/list',
    PRODUCT_LIST = '/v2/product/list',
    PRODUCT_LOGISTICS_SETTINGS = '/v2/product/logisticsSettings',
    PRODUCT_POTENTIAL = '/v2/productImport/potentialProduct',
    PRODUCT_PRICE = '/v2/product/productPrice',
    PRODUCT_SETTINGS = '/v2/product/settings',
    PRODUCT_QUERY = '/v2/product/query',
    PRODUCT_QUERY_NOT_DELETABLE_PRODUCTS = '/v2/product/queryNotDeletableProducts',
    PRODUCT_QUERY_OVERVIEW = '/v2/product/queryProductOverview',
    PRODUCT_UNIT_QUERY = '/v2/product/unit/query',
    PROJECT_QUERY = '/v2/project/query',
    PURCHASE_ORDER = '/v2/purchaseOrder',
    PURCHASE_ORDER_EXPORT = '/v2/purchaseOrder/export',
    PURCHASE_ORDER_BY_PARAMS = '/v2/purchaseOrder/getPurchaseOrdersByParams',
    PURCHASE_ORDER_GOODS_RECEIPT = '/v2/purchaseOrder/goodsReceipt',
    PURCHASE_ORDER_INCOMING_INVOICE = '/v2/purchaseOrder/purchaseOrderIncomingInvoiceRelation',
    PURCHASE_ORDER_INTERNAL = '/v2/purchaseOrder/internal',
    PURCHASE_ORDER_QUERY = '/v2/purchaseOrder/query',
    RACKBEAT_PRODUCT_NEWS = '/v2/internal/news',
    STOCKMOVEMENTS_OUTGOING = '/v2/stock/outgoing',
    SUPPLIER = '/v2/supplier',
    SUPPLIER_CONNECTION = '/v2/product/supplierProduct',
    SUPPLIER_QUERY = '/v2/supplier/query',
    VAT_TYPE = '/v2/ledger/vatType',
}

export enum PageUrl {
    CUSTOMER_MENU = '/execute/customerMenu',
    DEVIATION = '/execute/deviation',
    GOODS_RECEIPT = '/execute/goodsReceipt',
    INCOMING_STOCK = '/execute/incomingStock',
    INVENTORY_CORRECTION_OVERVIEW = '/execute/inventoryCorrections',
    INVENTORY_MENU = '/execute/inventoryMenu',
    LOGISTICS_DASHBOARD = '/execute/logisticsDashboard',
    PRODUCT_IMPORT = '/execute/product/import',
    PRODUCT = '/execute/productOverview',
    PRODUCT_CREATE = '/execute/product/create',
    PRODUCT_MENU = '/execute/productMenu',
    PRODUCT_OVERVIEW = '/execute/productOverview2',
    PRODUCT_SETTINGS = '/execute/productSettingsMenu',
    PURCHASE_ORDER = '/execute/purchaseOrder',
    PURCHASE_ORDER_DETAILS = '/execute/purchaseOrderMenu',
    PURCHASE_ORDER_NEW = '/execute/purchaseOrderNew',
    RACKBEAT_PRODUCT_NEWS = '/execute/rackbeatProductNews',
    STOCKTAKING = '/execute/stocktaking',
    STOCKTAKING_DETAILS = '/execute/stocktaking/details',
    WAREHOUSE = '/execute/warehouse',
    WAREHOUSE_OVERVIEW = '/execute/warehouse/overview',
    WAREHOUSE_DETAILS = '/execute/warehouse/details',
    WAREHOUSE_REPORT = '/execute/warehouseReport',
}

export enum MethodType {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
    DELETE = 'DELETE',
}

export enum AutoSaveStatusType {
    SAVED = 'SAVED',
    PENDING = 'PENDING',
    ERROR = 'NOT_SAVED',
}

export enum SortingType {
    DATE_ASCENDING = 'date',
    DATE_DESCENDING = '-date',
    DELIVERY_DATE_ASCENDING = 'deliveryDate',
    DELIVERY_DATE_DESCENDING = '-deliveryDate',
    GROUP_ASCENDING = 'productGroup',
    GROUP_DESCENDING = '-productGroup',
    LAST_STOCKING_ASCENDING = 'lastStocking',
    LAST_STOCKING_DESCENDING = '-lastStocking',
    LOCATION_ASCENDING = 'location',
    LOCATION_DESCENDING = '-location',
    NAME_ASCENDING = 'name',
    NAME_DESCENDING = '-name',
    NO_SORTING = '',
    NUMBER_ASCENDING = 'number',
    NUMBER_DESCENDING = '-number',
    PROJECT_ORDER_ASCENDING = 'projectOrder',
    PROJECT_ORDER_DESCENDING = '-projectOrder',
    STATUS_ASCENDING = 'status',
    STATUS_DESCENDING = '-status',
    STOCK_ASCENDING = 'stockOfGoods',
    STOCK_DESCENDING = '-stockOfGoods',
    SUPPLIER_ASCENDING = 'supplier',
    SUPPLIER_DESCENDING = '-supplier',
    SELLING_PRICE_ASCENDING = 'sellingPrice',
    SELLING_PRICE_DESCENDING = '-sellingPrice',
    SELLING_PRICE_WITH_DISCOUNT_ASCENDING = 'sellingPriceWithDiscount',
    SELLING_PRICE_WITH_DISCOUNT_DESCENDING = '-sellingPriceWithDiscount',
}
