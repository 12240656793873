import useSWR from 'swr';
import { createAPIRequest } from '../../../../hooks/fetch/createAPIRequest';
import { createAPIResponse } from '../../../../hooks/fetch/createAPIResponse';
import { ResponseWrapper } from '../../../../hooks/fetch/types';

export type PilotFeatures = Map<string, boolean>;

export function usePilotFeatures(pilotNames?: string[]) {
    const { data, error, mutate, isLoading } = useSWR<string[]>(
        getPilotFeaturesUrl(pilotNames),
        fetcher,
        {
            revalidateOnFocus: true,
            revalidateOnReconnect: true,
        }
    );

    return {
        pilotFeatures: data,
        error,
        isLoading,
        mutate,
    };
}

function convertToList(data?: ResponseWrapper<PilotFeaturesDto>) {
    if (data?.value === undefined) {
        return [];
    }

    const d = Object.entries(data.value);
    return d.filter(([, value]) => value).map(([key]) => key);
}

export function getPilotFeaturesUrl(pilotNames: string[] | undefined) {
    const url = new URL('/v2/pilotFeature/', window.location.origin);
    if (pilotNames) {
        const params = url.searchParams;
        params.set('pilotFeatures', pilotNames?.join(','));
    }
    return url.href;
}

export type PilotFeaturesDto = { [key: string]: boolean };

async function fetcher(url: string): Promise<string[]> {
    const request = createAPIRequest(url);
    const response = await fetch(request);
    const data = await createAPIResponse<ResponseWrapper<PilotFeaturesDto>>(
        request,
        response
    );
    return convertToList(data);
}
