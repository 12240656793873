import React, { useEffect, useState } from 'react';
import { WarehouseOverviewAPIModel } from '@Page/LogisticsV2/pages/Warehouse/overview/types';
import {
    Button,
    Input,
    KebabIcon,
    PopupMenu,
    PopupMenuItemButton,
    usePopupMenu,
} from '@tlx/atlas';
import { AutoSaveStatusType, PageUrl } from '@Page/LogisticsV2/utils/enums';
import {
    doDeleteWarehouse,
    doUpdateWarehouse,
} from '@Page/LogisticsV2/pages/Warehouse/overview/actions';
import { nav } from '../../../../../../../../js/modules/navigation';
import { LogisticsModal } from '@Page/LogisticsV2/components/Modals/Modal';
import { CreateStocktakingModal } from '@Page/LogisticsV2/pages/Stocktaking/create/StocktakingCreate';

export function WarehouseOverviewTableRowCell({
    column,
    warehouse,
    onChangeStatus,
}: {
    column: string;
    warehouse?: WarehouseOverviewAPIModel;
    onChangeStatus?: (
        newStatus: AutoSaveStatusType,
        warehouseId?: number,
        newStatusFilter?: string
    ) => void;
}): JSX.Element {
    const onUpdate = async (isChecked: boolean, type: string) => {
        onChangeStatus?.(
            AutoSaveStatusType.PENDING,
            warehouse?.id ?? 0,
            type === 'isInactive'
                ? isChecked
                    ? 'active'
                    : 'inactive'
                : undefined
        );
        const { error } = await doUpdateWarehouse(
            warehouse?.id ?? 0,
            isChecked,
            type
        );
        if (error) {
            onChangeStatus?.(AutoSaveStatusType.ERROR);
        } else {
            onChangeStatus?.(AutoSaveStatusType.SAVED);
        }
    };

    switch (column) {
        case 'main_warehouse':
            return !warehouse?.isInactive ? (
                <MainWarehouseRow
                    warehouse={warehouse}
                    onChange={(isChecked) =>
                        onUpdate(isChecked, 'mainWarehouse')
                    }
                />
            ) : (
                <td
                    className="atl-td atl-font-normal atl-align-middle"
                    key="mainWarehouseRow"
                    style={{ height: '61px' }}
                />
            );
        case 'description':
            return <DescriptionRow description={warehouse?.description} />;
        case 'last_stocking':
            return (
                <LastStocktakingRow lastStocktaking={warehouse?.lastStocking} />
            );
        case 'status':
            return !warehouse?.isMainInventory ? (
                <StatusRow
                    warehouse={warehouse}
                    onChange={(isChecked) => onUpdate(isChecked, 'isInactive')}
                />
            ) : (
                <td
                    className="atl-td atl-font-normal atl-align-middle"
                    key="StatusRow"
                />
            );
        default:
            throw new Error(`Unknown setting: ${column}`);
    }
}

export function NumberRow({ warehouseNumber }: { warehouseNumber: string }) {
    return (
        <td className="atl-td atl-font-normal atl-align-middle" key="numberRow">
            {warehouseNumber}
        </td>
    );
}

export function NameRow({ warehouseName }: { warehouseName: string }) {
    return (
        <td className="atl-td atl-font-normal atl-align-middle" key="nameRow">
            {warehouseName}
        </td>
    );
}

function MainWarehouseRow({
    warehouse,
    onChange,
}: {
    warehouse: WarehouseOverviewAPIModel | undefined;
    onChange?: (isActive: boolean) => void;
}) {
    const [
        showChangeMainWarehouseConfirmation,
        setShowChangeMainWarehouseConfirmation,
    ] = useState(false);
    const onChangeMainWarehouse = () => {
        onChange?.(!warehouse?.isMainInventory);
        setShowChangeMainWarehouseConfirmation(false);
    };
    return (
        <td
            className="atl-td atl-font-normal atl-align-middle atl-text-center"
            key="mainWarehouseRow"
            onClick={(e) => e.stopPropagation()}
        >
            <Input
                type="radio"
                className={'atl-mt-4'}
                data-testid={'mainWarehouseRadio'}
                name="warehouseRadioGroup"
                value={warehouse?.isMainInventory ? 'true' : 'false'}
                checked={warehouse?.isMainInventory}
                onChange={() => setShowChangeMainWarehouseConfirmation(true)}
            />
            {showChangeMainWarehouseConfirmation && (
                <LogisticsModal
                    svgIcon={'warehouseOverview'}
                    onConfirm={onChangeMainWarehouse}
                    onCancel={() =>
                        setShowChangeMainWarehouseConfirmation(false)
                    }
                    title={getMessage('text_main')}
                >
                    <div className={'atl-mb-8'}>
                        {getMessage('text_confirm_change_main_warehouse')}
                    </div>
                </LogisticsModal>
            )}
        </td>
    );
}
function DescriptionRow({ description }: { description?: string }) {
    return (
        <td
            className="atl-td atl-font-normal atl-align-middle"
            key="descriptionRow"
        >
            {description && description.length > 0 ? description : '-'}
        </td>
    );
}
function LastStocktakingRow({ lastStocktaking }: { lastStocktaking?: string }) {
    return (
        <td
            className="atl-td atl-font-normal atl-align-middle"
            key="lastStocktakingRow"
        >
            {lastStocktaking && lastStocktaking?.length > 0
                ? lastStocktaking
                : '-'}
        </td>
    );
}

function StatusRow({
    warehouse,
    onChange,
}: {
    warehouse: WarehouseOverviewAPIModel | undefined;
    onChange?: (isActive: boolean) => void;
}) {
    const [isActive, setIsActive] = useState(!warehouse?.isInactive);

    useEffect(() => {
        setIsActive(!warehouse?.isInactive);
    }, [warehouse?.isInactive]);

    return (
        <td
            className="atl-td atl-font-normal atl-align-middle"
            key="statusRow"
            onClick={(e) => e.stopPropagation()}
        >
            <Input
                data-testid={'warehouse-status'}
                className={'atl-mt-4'}
                type="toggle"
                checked={isActive}
                onChange={() => {
                    const newStatus = !isActive;
                    setIsActive(newStatus);
                    onChange?.(!isActive);
                }}
            />
        </td>
    );
}

export function WarehouseOverviewTableRowActions({
    warehouse,
    onChangeStatus,
}: {
    warehouse: WarehouseOverviewAPIModel;
    onChangeStatus?: (newStatus: AutoSaveStatusType) => void;
}) {
    const { openerButtonProps, popupMenuProps } = usePopupMenu();
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [showCreateStocktakingModal, setShowCreateStocktakingModal] =
        useState(false);
    const onDelete = async () => {
        onChangeStatus?.(AutoSaveStatusType.PENDING);
        const { error } = await doDeleteWarehouse(warehouse.id);
        if (error) {
            onChangeStatus?.(AutoSaveStatusType.ERROR);
        } else {
            onChangeStatus?.(AutoSaveStatusType.SAVED);
        }
        setShowDeleteConfirmation(false);
    };

    return (
        <td
            className="atl-td atl-font-normal atl-align-middle atl-flex atl-justify-end"
            key="warehouseOverviewMenuRow"
            onClick={(e) => e.stopPropagation()}
        >
            <Button
                {...openerButtonProps}
                data-testid="warehouse-overview-row-open-button"
                variant="icon"
            >
                <KebabIcon />
            </Button>
            <PopupMenu {...popupMenuProps}>
                {!warehouse.isInactive && (
                    <PopupMenuItemButton
                        onClick={() => setShowCreateStocktakingModal(true)}
                        data-testid={
                            'warehouse-overview-new-stocktaking-button'
                        }
                    >
                        {getMessage('text_inventory_create')}
                    </PopupMenuItemButton>
                )}
                {!warehouse.isInactive && (
                    <PopupMenuItemButton
                        onClick={() =>
                            nav.nav(
                                addContextId(
                                    `${PageUrl.INVENTORY_MENU}?warehouseId=${warehouse.id}&isCorrection=true&inventoryId=0`
                                )
                            )
                        }
                        data-testid={
                            'warehouse-overview-new-inventory-adjustments-button'
                        }
                    >
                        {getMessage('text_inventory_correction_new')}
                    </PopupMenuItemButton>
                )}
                {warehouse.deletable && (
                    <PopupMenuItemButton
                        onClick={() => setShowDeleteConfirmation(true)}
                        data-testid={
                            'warehouse-overview-delete-warehouse-button'
                        }
                    >
                        {getMessage('text_delete')}
                    </PopupMenuItemButton>
                )}
            </PopupMenu>
            {showDeleteConfirmation && (
                <LogisticsModal
                    svgIcon={'delete'}
                    onConfirm={onDelete}
                    onCancel={() => setShowDeleteConfirmation(false)}
                    title={
                        getMessage('text_delete') +
                        ' ' +
                        getMessage('text_warehouse').toLowerCase()
                    }
                >
                    <div className={'atl-mb-8'}>
                        {getMessage('text_confirm_delete_warehouse')}
                    </div>
                </LogisticsModal>
            )}
            {showCreateStocktakingModal && (
                <CreateStocktakingModal
                    inventoryId={warehouse.id}
                    onClose={() => setShowCreateStocktakingModal(false)}
                />
            )}
        </td>
    );
}
