import {
    ProductGroupDTO,
    ResaleProductDTO,
    StocktakingDTO,
} from '@Page/LogisticsV2/types/dtoTypes';
import { DataTreeDTO, DataTreeNode } from '../components/DataTree/types';
import { ProductLineDTO } from '../pages/Stocktaking/utils';
import {
    PurchaseOrderDTO,
    PurchaseOrderIncomingInvoiceRelationDTO,
    PurchaseOrderInternalDTO,
} from '@Page/LogisticsV2/pages/PurchaseOrder/overview/types';
import _ from 'lodash';

export function mapProductGroupDTOtoTreeData(
    nodes: ProductGroupDTO[]
): DataTreeDTO[] {
    return nodes.map((dto) => ({
        id: dto.id,
        name: dto.name,
        parentId: dto.parentGroup?.id ?? null,
        isDeletable: dto.isDeletable,
    }));
}

export function mapTreeDataToProductGroupDTO(node: DataTreeNode) {
    return {
        id: node.id,
        name: node.name,
        parentGroup: node.parent ? { id: node.parent?.id } : null,
    };
}

export function mapObjectToURLSearchParams(object: any) {
    const urlSearchParams = new URLSearchParams();
    for (const property in object) {
        const value = object[property];
        if (value === undefined || value === null || value === '') {
            continue;
        }
        urlSearchParams.append(property, value.toString());
    }
    return urlSearchParams;
}

export function mapProductsToProductLines(
    selectedProducts: ResaleProductDTO[],
    stocktackingDetails: StocktakingDTO | undefined
) {
    const linesToAdd: ProductLineDTO[] = [];
    if (stocktackingDetails === undefined) {
        return [];
    }
    for (const item of selectedProducts) {
        item.count = undefined;
        linesToAdd.push({
            id: 0,
            stocktaking: stocktackingDetails,
            product: item,
            count: 0,
            unitCostCurrency: 0,
            costCurrency: 0,
            counted: false,
            expectedStock: 0,
            location: undefined,
            counter: undefined,
            dateCounted: '',
            comment: '',
        });
    }
    return linesToAdd;
}

export const mapColorToStatus = new Map<string, string>([
    ['STATUS_SENT', 'atl-bg-green-100'],
    ['STATUS_CONFIRMED', 'atl-bg-green-100'],
    ['STATUS_CLOSED', 'atl-bg-green-100'],
    ['STATUS_OPEN', 'atl-bg-yellow-100'],
    ['STATUS_RECEIVING', 'atl-bg-yellow-100'],
    ['STATUS_CANCELLED', 'atl-bg-grey-100'],
    ['STATUS_CONFIRMED_DEVIATION_DETECTED', 'atl-bg-red-100'],
    ['STATUS_DEVIATION_OPEN', 'atl-bg-red-100'],
    ['STATUS_DEVIATION_CONFIRMED', 'atl-bg-red-100'],
]);

export const mapStatusColor = new Map<
    string,
    'error' | 'neutral' | 'success' | 'warning'
>([
    ['STATUS_SENT', 'success'],
    ['STATUS_CONFIRMED', 'success'],
    ['STATUS_CLOSED', 'success'],
    ['STATUS_OPEN', 'warning'],
    ['STATUS_RECEIVING', 'warning'],
    ['STATUS_CANCELLED', 'neutral'],
    ['STATUS_CONFIRMED_DEVIATION_DETECTED', 'error'],
    ['STATUS_DEVIATION_OPEN', 'error'],
    ['STATUS_DEVIATION_CONFIRMED', 'error'],
]);

export const mapTextToStatus = (status: string) => {
    switch (status) {
        case 'STATUS_SENT':
            return getMessage('text_purchase_order_status_sent');
        case 'STATUS_OPEN':
            return getMessage('text_purchase_order_status_open');
        case 'STATUS_CONFIRMED':
            return getMessage('text_purchase_order_status_confirmed');
        case 'STATUS_CLOSED':
            return getMessage('text_closed');
        case 'STATUS_RECEIVING':
            return getMessage('text_purchase_order_status_receiving');
        case 'STATUS_CANCELLED':
            return getMessage('text_is_cancelled');
        case 'STATUS_CONFIRMED_DEVIATION_DETECTED':
            return getMessage('text_deviation_detected');
        case 'STATUS_DEVIATION_OPEN':
            return getMessage('text_deviation_open');
        case 'STATUS_DEVIATION_CONFIRMED':
            return getMessage('text_deviation_confirmed');
    }
    return;
};

export const mapTextToOption = (option: string) => {
    switch (option) {
        case 'status':
            return getMessage('text_status');
        case 'supplier':
            return getMessage('text_supplier');
        case 'supplierInvoice':
            return getMessage('text_supplier_invoice');
        case 'projectOrder':
            return moduleProject
                ? getMessage('text_project_or_order')
                : getMessage('text_order');
        case 'deliveryDate':
            return getMessage('text_delivery_date');
        case 'contact':
            return getMessage('text_contact');
        case 'totalAmount':
            return getMessage('text_total_amount');
        case 'currency':
            return getMessage('text_currency');
        case 'sentDate':
            return getMessage('text_sent_date');
        case 'orderLines':
            return (
                getMessage('text_order_lines') +
                ' ' +
                getMessage('text_export_only')
            );
        case 'costExcludingVatCurrency':
            return getMessage('text_cost_price');
        case 'priceExcludingVatCurrency':
            return getMessage('text_sales_price_excl_vat');
        case 'stockOfGoods':
            return getMessage('text_stock_of_goods');
        case 'warehouse':
            return getMessage('text_warehouse');
    }
    return;
};

export function mapFieldsToPurchaseOrder(
    purchaseOrder: PurchaseOrderDTO,
    supplierInvoices: PurchaseOrderIncomingInvoiceRelationDTO[],
    internalPurchaseOrders: PurchaseOrderInternalDTO[]
) {
    const supplierInvoice = supplierInvoices
        .filter(
            (supplierInvoice) =>
                supplierInvoice.orderOut.id === purchaseOrder.id
        )
        .map((supplierInvoice) => supplierInvoice.voucher);

    const totalAmount = internalPurchaseOrders.filter(
        (totalAmount) => totalAmount.id === purchaseOrder.id
    )[0]?.totalAmount;

    return {
        ...purchaseOrder,
        supplierInvoice: _.uniqWith(supplierInvoice, _.isEqual) ?? [],
        totalAmount: totalAmount ?? 0,
    };
}
