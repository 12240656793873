import { useMemo } from 'react';

// This utility generates a `window`-unique global ID. Whenever
// React components are re-rendered, they will keep the same ID.

// The reason the counter exists on window (instead of a local `let i = 0`)
// is to make sure that we re-use the same counter even if this ESM module
// has somehow been loaded twice (the browser might not recognize as the same
// module after a hot reload, or lazy-load/code splitting.)

// @ts-expect-error We haven't explicitly typed this variable on `window`
if (window.__GLOBAL_ID_COUNTER__ === undefined) {
    // @ts-expect-error We haven't explicitly typed this variable on `window`
    window.__GLOBAL_ID_COUNTER__ = 0;
}

function makeId(): string {
    // @ts-expect-error We haven't explicitly typed this variable on `window`
    return `${window.__GLOBAL_ID_COUNTER__++}`;
}

export function useId(): string {
    return useMemo(makeId, []);
}
