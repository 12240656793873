import useSWRInfinite from 'swr/infinite';
import { FetchPaginatedState, ListResponse } from './types';
import { useFetcher } from './useFetcher';

/**
 * Fetch data (a list of items) from the API backend, on any endpoint that
 * returns a ListResponse<T>. This hook uses pagination by default, and lets you
 * fetch more items by calling the loadMore() function.
 *
 * @deprecated Use useSWRInfinite directly alongside defaultFetcher and useFetchPaginatedState instead
 * @see https://storybook.tripletex.dev/?path=/docs/atlas-data-fetching
 */
export function useFetchPaginated<TData, TError = any>(
    getKey: (
        index: number,
        previousPageData: ListResponse<TData> | null
    ) => string | null,
    lazy = false
): FetchPaginatedState<TData, TError> {
    const fetcher = useFetcher<ListResponse<TData>>();
    const { data, error, size, setSize, mutate } = useSWRInfinite<
        ListResponse<TData>
    >(getKey, {
        fetcher,
        initialSize: lazy ? 0 : 1,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
    });

    const { isLoading, hasMore } = getPaginationState(data, error, size);

    function loadMore() {
        if (hasMore && !isLoading) {
            setSize(size + 1);
        }
    }

    return {
        data: flattenPages(data),
        error,
        isLoading,
        hasMore,
        loadMore,
        mutate,
    };
}

function getPaginationState<T = unknown>(
    pages: ListResponse<T>[] | undefined,
    error: any, // TODO: Implement HttpError or similar
    size: number
): { isLoading: boolean; hasMore: boolean } {
    const firstPage = pages?.[0];
    const currentPage = pages?.[size - 1];
    const lastPage = pages?.[pages.length - 1];

    const isLoadingInitialData: boolean =
        pages === undefined && error === undefined && size > 0;
    const isLoadingMore: boolean =
        isLoadingInitialData ||
        (size > 0 && pages !== undefined && currentPage === undefined);
    const isEmpty: boolean = firstPage?.values.length === 0;
    const isReachingEnd: boolean =
        isEmpty ||
        (lastPage !== undefined &&
            lastPage.fullResultSize <= lastPage.from + lastPage.count);

    return {
        isLoading: isLoadingMore === true,
        hasMore: !isReachingEnd,
    };
}

function flattenPages<T = unknown>(
    pages: (ListResponse<T> | undefined)[] | undefined
): T[] {
    if (pages === undefined) {
        return [];
    }

    return pages.flatMap((page) => (page !== undefined ? page.values : []));
}
