import { ModelAPIV1 } from '@General/types/Api';
import { CreateButtonComponent, Option } from '../../types';
import { notUndefined } from '@General/Helpers';
import { JSPDropdownFetcher } from '../JSPDropdownFetcher';
import { ApiResultMapper } from '@General/Provider';
import * as React from 'react';
import { createCreateNewButton } from '@Component/DropDown/CreateNewButton/CreateNewButton';
import { TlxListItem } from '@Component/DropDown/DropDown';

interface DepartmentEntry extends ModelAPIV1 {
    readonly displayName: string;
    readonly isInactive: boolean;
}

const departmentMapper: ApiResultMapper<DepartmentEntry> = (item) => ({
    value: item.id + '',
    displayName: item.displayName,
    item,
});

const DesktopListItem: TlxListItem<DepartmentEntry> = (props) => {
    if (!props.option.item) {
        return (
            <div className="txr-dropdown__row txr-dropdown__list-item">
                <div className="txr-dropdown__item-cell txr-dropdown__item-cell--default">
                    {props.option.displayName}
                </div>
            </div>
        );
    }
    return (
        <div className="txr-dropdown__row txr-dropdown__list-item">
            <div
                className="txr-dropdown__item-cell txr-dropdown__item-cell--description"
                style={
                    props.option.item.isInactive ? { fontStyle: 'italic' } : {}
                }
            >
                {props.option.item.displayName}
            </div>
        </div>
    );
};

class DepartmentFetcher extends JSPDropdownFetcher<DepartmentEntry> {
    asyncJsonrpcGetter: Function;

    constructor(scope: HTMLDivElement, queryAttributes: Record<string, any>) {
        super(scope, queryAttributes, departmentMapper);
        this.asyncJsonrpcGetter = asyncrpc.Department.get;
    }

    getDesktopListItem(): TlxListItem<DepartmentEntry> {
        return DesktopListItem;
    }

    getParams(): ObjectIndex {
        return {
            showInactive: this.getQueryAttribute('showInactive', false),
        };
    }

    async fetchList(params: ObjectIndex): Promise<Option<DepartmentEntry>[]> {
        return new Promise((resolve, reject) => {
            try {
                return jsonrpc.Department.searchForDepartments(
                    (result: DepartmentEntry[] | null) => {
                        const mapped = (result ?? [])
                            .filter(notUndefined)
                            .map(this.getMapper(params))
                            .filter(notUndefined);
                        resolve(mapped);
                    },
                    this.getMarshallSpec(),
                    window.loginCompanyId,
                    params.name || '',
                    this.tlxSelectMaxRows,
                    0,
                    params.showInactive
                );
            } catch (e) {
                defaultJsonRpcExceptionHandler(e);
                reject(e);
            }
        });
    }

    createCreateNewButton(
        setSelected: (id: number) => void,
        dropdownTrackingId?: string
    ): CreateButtonComponent | undefined {
        if (!isAuth.createDepartment) {
            return undefined;
        }
        return createCreateNewButton(
            setSelected,
            'heading_new_department',
            'updateDepartment',
            dropdownTrackingId
                ? getLocaleMessage('en_GB', 'heading_new_department') +
                      ' - ' +
                      dropdownTrackingId
                : ''
        );
    }
}

export const exports = {
    fetcher: DepartmentFetcher,
    type: 'Department',
    link: (id: number, contextId: number) =>
        `/execute/departmentMenu?departmentId=${id}&contextId=${contextId}`,
};
