import {
    FieldErrors,
    SubmitErrorHandler,
    SubmitHandler,
    useForm,
    UseFormRegister,
    UseFormRegisterReturn,
    UseFormReset,
    UseFormSetError,
    UseFormSetValue,
    UseFormTrigger,
    UseFormWatch,
} from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types/form';
import React from 'react';
import {
    isValidBankAccount,
    validateFirstInvoiceNumber,
    validateIBAN,
    validateOrganizationNumber,
    validateSWIFT,
} from '@Page/InvoicingDialog/ValidationUtil';
import {
    InvoiceSettings,
    InvoicingBankAccount,
} from '@Page/InvoicingDialog/types/InvoicingDialog.type';

export type SetupFormProps = {
    fields: {
        firstInvoiceNumber: UseFormRegisterReturn;
        organizationNumber: UseFormRegisterReturn;
        invoicingBankAccountNumber: UseFormRegisterReturn;
        invoiceAbroad: UseFormRegisterReturn;
        iban: UseFormRegisterReturn;
        swift: UseFormRegisterReturn;
    };
    errors: FieldErrors<SetupFormFieldValues>; // Validation errors
    control: Control<SetupFormFieldValues>; // This object contains methods for registering components into React Hook Form
    watch: UseFormWatch<SetupFormFieldValues>; // Used to peek values from the form
    reset: UseFormReset<SetupFormFieldValues>; // Can be used to reset form with default values, or new values
    trigger: UseFormTrigger<SetupFormFieldValues>; // Trigger validation
    setError: UseFormSetError<SetupFormFieldValues>; // Set validation errors manually
    setValue: UseFormSetValue<SetupFormFieldValues>; // Set values int the form programmatically
    register: UseFormRegister<SetupFormFieldValues>; // Register fields in the form
    handleSubmit: (
        onValid: SubmitHandler<SetupFormFieldValues>,
        onInvalid?: SubmitErrorHandler<SetupFormFieldValues>
    ) => (e?: React.BaseSyntheticEvent) => Promise<void>;
    isSubmitting: boolean;
};

export type SetupFormFieldValues = {
    firstInvoiceNumber: string;
    organizationNumber: string;
    invoicingBankAccountNumber: string;
    invoiceAbroad: boolean;
    iban: string;
    swift: string;
};

export function useSetupForm(
    invoiceSettings: InvoiceSettings,
    invoicingBankAccount: InvoicingBankAccount | undefined,
    organizationNumber: string,
    migratedFrom?: string
): SetupFormProps {
    const externalInvoiceNumber = invoiceSettings.externalInvoiceNumber;
    function nextInvoiceNumber() {
        const nextInvoiceNumberFromSetting = invoiceSettings.nextInvoiceNumber;
        if (
            externalInvoiceNumber &&
            externalInvoiceNumber > nextInvoiceNumberFromSetting &&
            migratedFrom !== 'NONE'
        ) {
            const nextInvoiceNumber = externalInvoiceNumber + 1;
            return nextInvoiceNumber.toString();
        }
        if (nextInvoiceNumberFromSetting !== 1) {
            return nextInvoiceNumberFromSetting.toString();
        }
        return '';
    }

    const defaultValues = {
        firstInvoiceNumber: nextInvoiceNumber(),
        invoicingBankAccountNumber: invoicingBankAccount?.bankAccountNumber,
        organizationNumber: organizationNumber,
    };

    const {
        register,
        control,
        handleSubmit,
        watch,
        setValue,
        reset,
        setError,
        trigger,
        getValues,
        formState: { errors, isSubmitting },
    } = useForm<SetupFormFieldValues>({
        defaultValues: defaultValues,
        mode: 'onBlur', // Trigger validation onBlur
    });

    return {
        handleSubmit: handleSubmit,
        fields: {
            firstInvoiceNumber: register('firstInvoiceNumber', {
                validate: (value: number | string) =>
                    validateFirstInvoiceNumber(
                        value,
                        externalInvoiceNumber,
                        migratedFrom
                    ),
            }),
            organizationNumber: register('organizationNumber', {
                validate: validateOrganizationNumber,
            }),
            invoicingBankAccountNumber: register('invoicingBankAccountNumber', {
                validate: (value) => {
                    if (invoiceSettings.bankAccountReady) {
                        return undefined;
                    }
                    return isValidBankAccount(value);
                },
            }),
            invoiceAbroad: register('invoiceAbroad'),
            iban: register('iban', {
                validate: (value) => {
                    const invoiceAbroad = getValues('invoiceAbroad');
                    if (invoiceAbroad) {
                        return validateIBAN(value);
                    }
                    return undefined;
                },
            }),
            swift: register('swift', {
                validate: (value) => {
                    const invoiceAbroad = getValues('invoiceAbroad');
                    if (invoiceAbroad) {
                        return validateSWIFT(value, false);
                    }
                    return undefined;
                },
            }),
        },
        errors,
        control,
        watch,
        reset,
        trigger,
        setError,
        setValue,
        register,
        isSubmitting,
    };
}
