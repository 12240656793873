import { FileDragAndDrop } from '@Page/LogisticsV2/components/FileUpload/FileUpload';
import {
    ArrowForwardIcon,
    Avatar,
    Button,
    DeleteIcon,
    TableLayoutContent,
    TableLayout,
} from '@tlx/atlas';
import { ReactNode, useState } from 'react';
import {
    StepOneDescription,
    StepThreeDescription,
    StepTwoDescription,
} from '../components';
import { HandWaveIcon } from '../icons';
import { ProductImportType, step } from '../types';

export function FileUploadStep({ children }: { children: ReactNode }) {
    return <div className="atl-flex atl-flex-col atl-gap-24">{children}</div>;
}

export function UploadFileSection({
    onFileUpload,
}: {
    onFileUpload: (file: File) => void;
}) {
    const [file, setFile] = useState<File | null>(null);

    return (
        <div className="atl-w-full atl-rounded atl-border atl-border-grey-20 atl-flex atl-flex-col atl-p-24 atl-bg-white atl-gap-16">
            <div className="atl-font-medium atl-text-lg">
                {getMessage('text_saft_saft_file')}
            </div>
            <FileDragAndDrop
                formats={['csv', 'xlsx']}
                onFileSelected={(file: File | null) => {
                    file ? setFile(file) : setFile(null);
                }}
            />
            <div className="atl-flex atl-items-end atl-justify-end atl-w-full">
                <Button
                    variant={'primary'}
                    data-testid={'product-import-select-file'}
                    disabled={file === null}
                    onClick={() => {
                        if (file) {
                            onFileUpload(file);
                        }
                    }}
                >
                    {getMessage('text_next')}
                    <ArrowForwardIcon />
                </Button>
            </div>
        </div>
    );
}

export function DescriptionSection() {
    return (
        <div className="atl-flex atl-flex-col atl-w-full atl-bg-white atl-p-24 atl-border atl-border-grey-20 atl-gap-24 atl-rounded">
            <div className="atl-text-2xl atl-font-medium atl-text-grey-120 alt-flex atl-items-center">
                {getMessage('text_redirect_title')} <HandWaveIcon />
            </div>
            <div>{getMessage('text_import_subtitle')}</div>
            <StepOneDescription />
            <StepTwoDescription />
            <StepThreeDescription />
        </div>
    );
}

export function IncompleteImportsSection({
    numberOfIncompleteImports,
    children,
}: {
    numberOfIncompleteImports: number;
    children: ReactNode;
}) {
    return (
        <div
            className="atl-flex atl-flex-col atl-gap-24 atl-bg-white atl-border atl-rounded atl-border-grey-20 atl-p-24"
            style={{ maxHeight: '35vh' }}
        >
            <div className="atl-font-medium">
                {getMessage('text_saved_imports')} ({numberOfIncompleteImports})
            </div>
            <TableLayout>
                <TableLayoutContent
                    style={{ overflowY: 'auto', borderTop: 'unset' }}
                >
                    {children}
                </TableLayoutContent>
            </TableLayout>
        </div>
    );
}

export function IncompleteImportTable({ children }: { children: ReactNode }) {
    return (
        <table className="atl-table">
            <IncompleteImportsTableHeader />
            <tbody>{children}</tbody>
        </table>
    );
}

function IncompleteImportsTableHeader() {
    return (
        <thead className="atl-thead atl-thead--sticky">
            <tr className="atl-tr">
                <th
                    className="atl-th atl-font-medium"
                    style={{ maxWidth: '100px' }}
                >
                    {getMessage('text_file_name')}
                </th>
                <th className="atl-th atl-font-medium">
                    {getMessage('text_created')}
                </th>
                <th className="atl-th atl-font-medium">
                    {getMessage('text_created_by')}
                </th>
                <th className="atl-th atl-font-medium">
                    {getMessage('text_bank_onboarding_setup_progress')}
                </th>
                <th className="atl-th atl-font-medium" />
            </tr>
        </thead>
    );
}

export function IncompleteImportTableRow({
    data,
    onContinue,
    onDelete,
}: {
    data: ProductImportType;
    onContinue: (id: number, status: string) => void;
    onDelete: () => void;
}) {
    const stepNumber = Object.values(step).findIndex(
        (step) => step === data.status
    );
    return (
        <tr className="atl-tr">
            <td className="atl-td atl-align-middle atl-truncate">
                {data.importFile.fileName}
            </td>
            <td className="atl-td atl-align-middle">{data.startDate}</td>
            <td className="atl-td atl-align-middle">
                <div className="atl-flex atl-gap-8 atl-items-center">
                    <Avatar
                        name={data.employee.displayName}
                        imageUrl={
                            data.employee.pictureId !== 0
                                ? addContextId(
                                      `/execute/document?act=viewImage&id=${data.employee.pictureId}`
                                  )
                                : ''
                        }
                    />
                    {data.employee.displayName}
                </div>
            </td>
            <td className="atl-td atl-align-middle">
                {getMessage('text_is_completed', stepNumber, 3)}
            </td>
            <td className="atl-td atl-td--button atl-align-middle">
                <div className="atl-flex atl-items-center atl-gap-8 atl-justify-end atl-mr-16">
                    <Button
                        variant="icon"
                        data-testid="delete-incomplete-import"
                        onClick={onDelete}
                        aria-label="delete-incomplete-import"
                    >
                        <DeleteIcon />
                    </Button>
                    <Button
                        variant="tertiary"
                        data-testid="continue-import"
                        onClick={() => onContinue(data.id, data.status)}
                        className="goldshark-text-no-wrap"
                    >
                        {getMessage('text_storebrand_missing_information_next')}
                        <ArrowForwardIcon />
                    </Button>
                </div>
            </td>
        </tr>
    );
}
