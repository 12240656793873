import $ from 'jquery';

export function addSpacesuitBackdrops() {
    // Remove first just in case some of them are stuck
    removeSpacesuitBackdrops();

    const dimensions = getSpacesuitLayoutDimensions();
    const backgroundColor = 'rgba(0 0 0 / 38%)';

    $(document.body).append(
        $('<div class="spacesuit-backdrop spacesuit-backdrop__topbar" />').css({
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            height: dimensions.topbarHeight,
            zIndex: 4,
            backgroundColor,
        }),

        $('<div class="spacesuit-backdrop spacesuit-backdrop__sidebar" />').css(
            {
                position: 'absolute',
                top: dimensions.topbarHeight,
                left: 0,
                bottom: 0,
                width: dimensions.sidebarWidth,
                zIndex: 3,
                backgroundColor,
            }
        ),

        $(
            '<div class="spacesuit-backdrop spacesuit-backdrop__actionbar" />'
        ).css({
            position: 'absolute',
            left: 0,
            right: 0,
            bottom: 0,
            height: dimensions.actionbarHeight,
            zIndex: 4,
            backgroundColor,
        })
    );
}

export function removeSpacesuitBackdrops() {
    $('.spacesuit-backdrop').remove();
}

function getSpacesuitLayoutDimensions() {
    // Fallback dimensions
    const dimensions = {
        sidebarWidth: 240,
        topbarHeight: 50,
        actionbarHeight: 53,
    };

    try {
        const webComponent = document.querySelector('spacesuit-layout');

        if (webComponent && webComponent.shadowRoot) {
            const shadowRoot = webComponent.shadowRoot;

            const topbar = shadowRoot.querySelector('.tlx-layout__topbar');
            const sidebar = shadowRoot.querySelector('.tlx-layout__sidebar');
            const actionbar = shadowRoot.querySelector(
                '.tlx-layout__actionbar'
            );

            if (topbar) {
                dimensions.topbarHeight = topbar.getBoundingClientRect().height;
            }

            if (sidebar) {
                // Use right over width to account for sidebar being hidden
                dimensions.sidebarWidth = sidebar.getBoundingClientRect().right;
            }

            if (actionbar) {
                dimensions.actionbarHeight =
                    actionbar.getBoundingClientRect().height;
            }
        } else {
            // This is probably Agro, which has a 40px topbar and no sidebar
            return {
                sidebarWidth: 0,
                topbarHeight: 40,
                actionbarHeight: 0,
            };
        }
    } catch (error) {
        window.Sentry.captureException(error);
    }

    return dimensions;
}
