export const CommandEventName = 'TriggerCommand';

export interface CommandEventDetails<C extends string, T> {
    commandId: C;
    payload: T | undefined;
}

export class CommandEvent<C extends string, T> extends CustomEvent<
    CommandEventDetails<C, T>
> {
    constructor(commandId: C, payload: T | undefined) {
        super(CommandEventName, {
            detail: {
                commandId,
                payload,
            },
        });
    }
}
