import jQuery from 'jquery';
import { evaluateExpression } from '@General/math';
import { evaluateHoursTimeRange } from '@General/math/Expression';

/**
 * This module defines how we format different numerical values
 * such as hours, currency amount, amount, etc.
 */

export interface FormatOptions {
    // How many numbers of the fraction part should we use
    precision?: number;

    // When true: If value is 0 return ''.
    blank?: boolean;

    // When true: If fraction part is 0 return without fraction or separator symbol
    blankFraction?: boolean;

    // When true, add trailing zeroes up until precision. You can also use a number. See note above
    trailingZeros?: number | boolean;

    // Insert thousand separator
    separateThousands?: boolean;

    // What to insert between thousands if they are separated
    groupingSeparator?: string;

    decimalSeparator?: string;

    // If roundOffDecimal is true, round the number to o.precision fractions
    roundOffDecimal?: boolean;
}

export const format = (function ($) {
    /**
     * The fraction part is either removed if there
     * is nothing there, or it will have two numbers
     * (i.e. 10.5 -> '10.50' but 10.0 -> '10').
     */
    function formatAmount(d: number | string, blank?: boolean): string {
        return format(d, {
            blank: blank === undefined ? false : blank,
            blankFraction: true,
        });
    }

    /**
     * Given an input where time span is represented in decimal format (0.5 is half an hour etc.)
     * this returns a string where time is represented in hours, minutes and seconds.
     *
     * @param hours time span in decimal format as
     * @return time span in the format hh:mm:ss
     */
    function formatSeconds(hours: number): string {
        const h = Math.floor(hours);
        const minutes = (hours - h) * 60;
        const m = Math.floor(minutes);
        const seconds = (minutes - m) * 60;
        const s = Math.floor(seconds);
        return formatInt2(h) + ':' + formatInt2(m) + ':' + formatInt2(s);
    }

    /**
     * Not exposed, this is just used internally.
     */
    function formatInt2(integer: number) {
        if (integer == 0) {
            return '00';
        } else if (integer < 10) {
            return '0' + integer;
        } else {
            return String(integer);
        }
    }

    /**
     * Format currency rate (conversion between currencies).
     * This differs from formatAmount in that decimals are not
     * removed (we keep max 10 decimals though).
     */
    function formatCurrencyAmount(d: number | string, blank?: boolean): string {
        return format(d, {
            blank: blank === undefined ? false : blank,
            precision: 10,
            blankFraction: true,
            separateThousands: false,
            trailingZeros: false,
        });
    }

    /**
     * Adds a decimal separator and a 0 if input is integer.
     * Sets fixed number of decimals to two, but only use
     * one if second decimal is 0.
     */
    function formatHours(
        d: number | string,
        blank?: boolean,
        decimalSeparator?: string
    ): string {
        return format(d, {
            blank: blank === undefined ? false : blank,
            trailingZeros: 1,
            decimalSeparator: decimalSeparator,
        });
    }

    /**
     * Formats a number according to options given.
     *
     * @param o.trailingZeros: 	defaults is true. If true, adding numbers up until 'precision.
     * 							If you want to add zeros, but not until precision, use this
     * 							number instead (counting decimals from decimal separator).
     */
    function format(number: number | string, o: FormatOptions): string {
        o = $.extend(
            {
                precision: 2, // How many numbers of the fraction part should we use
                blank: true, // When true: If value is 0 return ''.
                blankFraction: false, // When true: If fraction part is 0 return without fraction or separator symbol
                trailingZeros: true, // When true, add trailing zeroes up until precision. You can also use a number. See note above
                separateThousands: true, // Insert thousand separator
                decimalSeparator: window.decimalSeparator,
                groupingSeparator: window.groupingSeparator,
                roundOffDecimal: true, //If roundOffDecimal is true, round the number to o.precision fractions
            },
            o
        );
        let d;
        const isNegative = number.toString().startsWith('-');
        if (o.roundOffDecimal) {
            d = decimalPoints(number as number, o.precision as number);
        } else {
            const split = number.toString().split('.');
            if (split.length > 1) {
                //if there are fractional
                split[1] = split[1].substring(0, o.precision);
                d = parseFloat(split.join('.'));
            } else {
                d = number;
            }
        }
        if (o.blank && d === 0) {
            return '';
        }

        const s = String(d);
        const parts = s.split('.');

        // Fixing the integer-part
        if (o.separateThousands) {
            // We ended up with numbers formatted as "-,100", because the regex splits between "-" and "100".
            if (isNegative) {
                parts[0] = parts[0].substring(1);
            }
            parts[0] = parts[0]
                // Split every third character, but starting from back
                .split(/(?=(?:...)+$)/)
                // Join back array to string, but with grouping separator
                .join(o.groupingSeparator);

            if (isNegative) {
                parts[0] = '-' + parts[0];
            }
        }

        // No separator in this number. Either return an integer or add trailing zeros
        if (parts.length === 1) {
            if (o.blankFraction || o.precision === 0) {
                return parts[0];
            } else {
                parts.push('');
            }
        }

        // Fixing the fraction part. Adding trailing zeroes until there are 'precision' number
        // of decimals in the number. Except if 'trailingZeros' is a number, then this is used
        // to count how many decimals there should be in the output number.
        const zeroPrecision: number =
            typeof o.trailingZeros === 'number'
                ? o.trailingZeros
                : (o.precision as number);
        if (o.trailingZeros && parts[1].length < zeroPrecision) {
            parts[1] += new Array(zeroPrecision - parts[1].length + 1).join(
                '0'
            );
        }
        return parts[0] + o.decimalSeparator + parts[1];
    }

    /**
     * Unformats string containing a formatted number.
     * Possible to add options if needed (similar to format), omitting this for until needed
     *
     * @param value Formatted string (e.g., 1 000,00)
     * @return number A unformatted number (1000.00)
     */
    function unFormat(value?: string | number): number {
        // convert to string, just in case if value is valid
        if (!value) {
            return 0;
        }
        value = String(value);
        value = parseFloat(
            value
                .replace(/\s/g, '')
                .replace(new RegExp(window.groupingSeparator, 'g'), '')
                .replace(window.decimalSeparator, '.')
                .replace(/[^\d\-.]/g, '')
        );

        if (isNaN(value)) {
            return 0;
        }

        return value;
    }

    /**
     * Not exposed outside this module.
     */
    function formatDecimal(d: number, precision: number, blank?: boolean) {
        d = decimalPoints(d, precision);

        if (blank && d == 0) {
            return '';
        }

        let s = String(d);
        if (precision > 0) {
            let separatorIndex = s.indexOf('.');
            if (separatorIndex < 0) {
                separatorIndex = s.length;
            }
            const fraction = s.substring(separatorIndex + 1);
            s = s.substring(0, separatorIndex) + window.decimalSeparator;
            s += fraction;
            for (let i = fraction.length; i < precision; i++) {
                s += '0';
            }
        }

        return s;
    }

    /**
     * Format number as an integer.
     */
    function formatDecimal0(d: number | string, blank?: boolean): string {
        return format(d, {
            blank: blank === undefined ? false : blank,
            precision: 0,
        });
    }

    /**
     * Format number with two decimals.
     */
    function formatDecimal2(d: number | string, blank?: boolean): string {
        return format(d, {
            blank: blank === undefined ? false : blank,
            precision: 2,
        });
    }

    /**
     * Seems like this method formats the number by these rules:
     *
     * - max 10 decimals
     * - no trailing zeroes in decimals
     * - if all decimals are zero, don't show decimal separator
     */
    function formatQuantity(d: number | string, blank?: boolean): string {
        return format(d, {
            blank: blank === undefined ? false : blank,
            precision: 10,
            trailingZeros: false,
            blankFraction: true,
        });
    }

    function formatUnitPrice(d: number | string, blank?: boolean): string {
        const temp = formatDecimal(d as number, 10, blank);
        let ret = blank ? '' : '0.00';
        for (let i = temp.length; i > 0; i--) {
            const char = temp.substring(i - 1, i);
            if (char == '.' || char == ',') {
                return temp.substring(0, i - 1);
            }
            if (char != '0') {
                ret = temp.substring(0, i);
                break;
            }
        }
        const ret2 = formatAmount(d, blank);
        if (ret2.length > ret.length) {
            return ret2;
        } else {
            return ret;
        }
    }

    /**
     * Given a number a return a number rounded
     * off with b numbers of decimal points.
     *
     * I put this into the format module because it was most
     * heavily used inside this module, and thus looks connected. (LEB)
     */
    function decimalPoints(a: number, b: number): number {
        return Number.parseFloat(Number.parseFloat(a).toFixed(b));
    }

    /**
     * Makes sure time is always shown as HH:mm regardless of input.
     * This means that excess numbers will be removed in some cases,
     * 0007:0000 	=> 07:00
     * 7 			=> 07:00
     * 070000		0> 07:00
     *
     * @returns XML/string
     */
    function formatTime(time: string): string {
        // Uses HH:mm format - can be expanded to also support HH:mm:ss where we need it
        let i = 0;
        time = time.replace(/\./g, ':').replace(/:/g, function (match) {
            return match === ':' && i++ === 0 ? ':' : '';
        });
        let hour;
        let minutes;
        if (!/:/.test(time)) {
            if (time.length <= 2) {
                time += ':00';
            } else if (time.length === 3) {
                hour = time.substring(0, 1);
                minutes = time.substring(1, 3);
                time = hour + ':' + minutes;
            } else {
                hour = time.substring(0, 2);
                minutes = time.substring(2, 4);
                time = hour + ':' + minutes;
            }
        }
        return time
            .replace(/^\d{1}:/, '0$&')
            .replace(/^\d{0}:/, '00$&')
            .replace(/:\d{0}$/, '$&00')
            .replace(/:\d{1}$/, '$&0')
            .replace(/.*(\d{2}:\d{2}).*/, '$1');
    }

    /**
     * Takes a string containing a number and a format key, and formats that number according to that key
     */
    function formatWithKey(
        d: string,
        formatKeyOrOptions: FormatOptions | string
    ): string {
        let options = {
            formatKey: '#,###.##',
            precision: 2, // How many numbers of the fraction part should we use
            blank: true, // When true: If value is 0 return ''.
            blankFraction: false,
            separateThousands: true, // Insert thousand separator
            trailingZeros: 0, //Number of Zeros to add to the fraction
            decimalSeparator: window.decimalSeparator,
            roundOffDecimal: true, //If this is true, round the number to options.precision fractions
        };
        if (typeof formatKeyOrOptions === 'string') {
            // Special format for time fields
            if (formatKeyOrOptions === 'HH:mm') {
                return formatTime(d);
            }

            options.formatKey = formatKeyOrOptions;
            options.blank =
                '#' ===
                options.formatKey
                    .split('.')[0]
                    .charAt(options.formatKey.split('.')[0].length - 1); //If the last char of formatKeys integer part is # then blank
            options.blankFraction =
                '#' ===
                options.formatKey.charAt(
                    options.formatKey.split('.')[0].length + 1
                );
            options.precision = options.formatKey.split('.')[1]
                ? options.formatKey.split('.')[1].length
                : 0; // length of formatKey decimal is precision
            options.trailingZeros =
                options.precision > 0
                    ? options.formatKey.split('.')[1].lastIndexOf('0') + 1
                    : 0; //if [0] of formatKey decimal part is 0
            options.separateThousands =
                formatKeyOrOptions.split(',').length > 1;
        } else {
            options = $.extend(options, formatKeyOrOptions);
        }
        //Unformat string formatted numbers
        d = d
            .split(' ')
            .join('')
            .split(window.groupingSeparator)
            .join('')
            .split(window.decimalSeparator)
            .join('.');
        d = d === '' ? '0' : d;
        //Format numbers
        if ($.isNumeric(d)) {
            d = format(d, options);
        }
        return d;
    }

    // We do not want to target a single negative number
    const singleNegativeNumber = /^-\d+(?:[.,]\d+)?$/;

    // String must include one of +, -, *, \

    // eslint-disable-next-line no-useless-escape
    const mathOperations = /[\+\-\*\/]/;

    // String must contain something that is not ,, 0-9, +, -, *, /, (, )
    const legalCharacters = new RegExp('[^\\.0-9\\+\\-\\*\\/\\(\\)]');

    /**
     * Given an input element, compute simple math operations (+, -, *, /).
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function computeInput($element: any) {
        const groupingSeparator = window.groupingSeparator || ' ';
        const decimalSeparator = window.decimalSeparator || ',';

        const val = $element
            .val()
            .split(decimalSeparator)
            .join('.')
            .split(groupingSeparator)
            .join('');

        if (
            singleNegativeNumber.exec(val) === null &&
            mathOperations.exec(val) !== null &&
            legalCharacters.exec(val) === null
        ) {
            const result = `${evaluateExpression(val)}`;
            // result and val is equal if val, for instance, is "-1".
            if (!result || result === val) {
                return;
            }

            $element.data('tlxComputeCache', $element.val());
            $element.val(result.split('.').join(decimalSeparator));

            // Make sure the value user puts in, appear again when she
            // tries to edit the input element
            $element.one('focusin', function (e: { target: HTMLElement }) {
                const $target = $(e.target);
                const val = $target.data('tlxComputeCache');

                if (val) {
                    $target.val(val);
                    $target.data('tlxComputeCache', '');
                }
            });
        }
    }

    const hoursTimeRangeIllegalCharacters = /[^0-9\-:.]/;

    /**
     * @param element The element that contains the value
     * @param overrideValue If it is not undefined, it will be used instead of the value
     *                      that the function finds in the element
     */
    function elementValueIsInHoursTimeRangeFormat(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        element?: any,
        overrideValue?: string
    ): boolean {
        const val = getElementVal(element, overrideValue);
        return (
            (!element || element.data('formatkey') === 'hoursTimeRange') &&
            hoursTimeRangeIllegalCharacters.exec(val) === null &&
            evaluateHoursTimeRange(val) !== undefined
        );
    }

    function getTimeRangeEvaluationResult(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        element?: any
    ): string | undefined {
        if (element === undefined) {
            return '';
        }
        const val = getElementVal(element);
        let result: string | undefined = undefined;
        if (
            $(element).data('formatkey') === 'hoursTimeRange' &&
            hoursTimeRangeIllegalCharacters.exec(val) === null
        ) {
            const evaluatedHoursTimeRange = evaluateHoursTimeRange(val);
            if (evaluatedHoursTimeRange !== undefined) {
                result = evaluatedHoursTimeRange.toString();
            }
        }
        return result;
    }

    function getElementVal(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        element?: any,
        overrideValue?: string
    ): string {
        if (element === undefined && overrideValue === undefined) {
            return '';
        }
        const groupingSeparator = window.groupingSeparator || ' ';
        const decimalSeparator = window.decimalSeparator || ',';
        const val =
            overrideValue === undefined ? $(element).val() : overrideValue;
        return (
            val
                ?.toString()
                .split(decimalSeparator)
                .join('.')
                .split(groupingSeparator)
                .join('')
                .trim() ?? ''
        );
    }

    return {
        amount: formatAmount,
        amount0: formatDecimal0,
        amount2: formatDecimal2,
        seconds: formatSeconds,
        //'int2': formatInt2,  // Not used outside of module
        currencyRate: formatCurrencyAmount,
        hours: formatHours,
        //'decimal': formatDecimal, // Not used outside of module
        decimal0: formatDecimal0,
        decimal2: formatDecimal2,
        quantity: formatQuantity,
        unitPrice: formatUnitPrice,
        hours0: formatDecimal0,
        hours2: formatDecimal2,
        decimalPoints: decimalPoints,
        format: format,
        withKey: formatWithKey,
        computeInput: computeInput,
        getTimeRangeEvaluationResult: getTimeRangeEvaluationResult,
        elementValueIsInHoursTimeRangeFormat:
            elementValueIsInHoursTimeRangeFormat,
        mathOperations: mathOperations,
        unFormat: unFormat,
        //is
    };
})(jQuery);
