import { ModelAPIV1 } from '@General/types/Api';
import { notUndefined } from '@General/Helpers';
import { ApiResultMapper } from '@General/Provider';
import { Option } from '../../types';
import { JSPDropdownFetcher } from '../JSPDropdownFetcher';

interface PaperVoucherEntry extends ModelAPIV1 {
    readonly displayName: string;
    readonly description: string;
}

const voucherMapper: ApiResultMapper<PaperVoucherEntry> = (item) => ({
    value: `${item.id}`,
    displayName: item.displayName + ' ' + item.description,
    item,
});

class PaperVoucherFetcher extends JSPDropdownFetcher<PaperVoucherEntry> {
    asyncJsonrpcGetter: Function;

    constructor(scope: HTMLDivElement, queryAttributes: Record<string, any>) {
        super(scope, queryAttributes, voucherMapper);
        this.asyncJsonrpcGetter = asyncrpc.Voucher.get;
    }

    getParams(): ObjectIndex {
        return {
            vendorId: this.getQueryAttribute('vendorElementId', 0),
        };
    }

    async fetchList(params: ObjectIndex): Promise<Option<PaperVoucherEntry>[]> {
        return new Promise((resolve, reject) => {
            try {
                jsonrpc.Voucher.getVouchersWithoutAttachment(
                    (result: PaperVoucherEntry[] | null) => {
                        const mapped = (result ?? [])
                            .filter(notUndefined)
                            .map(this.getMapper(params))
                            .filter(notUndefined);
                        resolve(mapped);
                    },
                    params.name || '',
                    params.vendorId
                );
            } catch (e) {
                defaultJsonRpcExceptionHandler(e);
                reject(e);
            }
        });
    }
}

export const exports = {
    fetcher: PaperVoucherFetcher,
    type: 'PaperVoucher',
    link: (id: number, contextId: number) =>
        `/execute/incomingInvoiceMenu?voucherId=${id}&contextId=${contextId}`,
};
