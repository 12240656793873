import { useEffect } from 'react';

export function useStocktakingDetailsKeyboard() {
    useEffect(() => {
        const getNextSibling = function (
            elem: Element | null,
            selector: string
        ) {
            if (elem === null) {
                return undefined;
            }
            let sibling = elem.nextElementSibling;
            if (!selector) {
                return sibling;
            }
            while (sibling) {
                if (sibling.matches(selector)) {
                    return sibling;
                }
                sibling = sibling.nextElementSibling;
            }
            return undefined;
        };
        const handleKeyDown = (e: KeyboardEvent) => {
            const activeElement = document.activeElement;
            if (activeElement === undefined || activeElement === null) {
                return;
            }
            if (
                activeElement.id === 'stocktaking-details-search' &&
                e.key === 'Enter'
            ) {
                const inputs = document.getElementsByClassName(
                    'goldshark-stocktaking-details__table-input'
                );
                if (inputs && inputs.length > 0) {
                    (inputs[0].firstElementChild as HTMLInputElement).focus();
                }
            }
            if (
                activeElement.className.includes('atl-money-input__input') &&
                e.key === 'Enter'
            ) {
                const button = getNextSibling(
                    activeElement?.parentElement,
                    'button'
                );
                if (button) {
                    const allInputs = document.getElementsByClassName(
                        'goldshark-stocktaking-details__table-input'
                    );
                    const parentElement = activeElement?.parentElement;
                    if (!parentElement) {
                        return;
                    }
                    const indexOfNextElement =
                        Array.from(allInputs).indexOf(parentElement) + 1;
                    if (allInputs && allInputs.length > indexOfNextElement) {
                        (
                            allInputs[indexOfNextElement]
                                ?.firstElementChild as HTMLInputElement
                        )?.focus();
                    } else {
                        (activeElement as HTMLInputElement).blur();
                    }
                    setTimeout(() => {
                        (button as HTMLButtonElement).click();
                    }, 500);
                }
            }
        };
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);
}
