import React from 'react';
import { Icon, Input, Group, Label } from '@tlx/atlas';
import DatePicker from '@Component/DatePicker/DatePicker';
import type { TestableProps } from '@tlx/atlas';
import classNames from 'classnames';

interface PaymentWidgetDatePickerProps extends TestableProps {
    readonly id: string;
    readonly date: string;
    readonly setDate: (date: string) => void;
    readonly atlasDesignSystem: boolean;
    readonly name?: string;
    readonly validationMessage?: string;
    readonly resetValidationMessage?: () => void;
    readonly children?: React.ReactNode;
}

export const PaymentWidgetDatePicker: React.FC<PaymentWidgetDatePickerProps> =
    ({
        id,
        date,
        setDate,
        atlasDesignSystem,
        name,
        children,
        validationMessage,
        resetValidationMessage,
        ...props
    }) => {
        return (
            <>
                {atlasDesignSystem ? (
                    <Group>
                        <Label htmlFor={id}>{children}</Label>
                        <div className="atl-flex atl-items-center atl-justify-center atl-relative">
                            <Input
                                name={name}
                                id={id}
                                type="date"
                                className={classNames(
                                    'atl-w-full atl-mb-24',
                                    validationMessage
                                        ? 'pbc-payment-widget-date-input--error pbc-payment__section-validation-error'
                                        : ''
                                )}
                                autoComplete="off"
                                value={date}
                                onChange={(e) => {
                                    setDate(e.target.value);
                                    resetValidationMessage?.();
                                }}
                                required
                                {...props}
                            />
                            {validationMessage && (
                                <Icon className="material-icons-outlined atl-icon atl-text-red-100 pbc-payment__section-icon-moved">
                                    error_outline
                                </Icon>
                            )}
                        </div>
                        {validationMessage && (
                            <div className="atl-text-red-100 pbc-payment__section-validation">
                                {validationMessage}
                            </div>
                        )}
                    </Group>
                ) : (
                    <DatePicker
                        name={name}
                        formGroupClassName={
                            'inputItem pbc-payment__section-validation-non-atlas'
                        }
                        label={children?.toString()}
                        value={date}
                        onChange={(e) => {
                            setDate(e.target.value);
                            resetValidationMessage?.();
                        }}
                        required
                        validationMessage={validationMessage}
                        testId={props['data-testid']}
                        {...props}
                    />
                )}
            </>
        );
    };
