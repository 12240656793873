export type InventoryCorrectionDTO = {
    id: number;
    number: number;
    date: string;
    fromWarehouse: InventoryDTO;
    warehouse: InventoryDTO;
    comment: string;
    stockChanges: StockChangeDTO[];
};

export type InventoryDTO = {
    displayName: string;
};

export type StockChangeDTO = {
    stockChangeWarehouseLocationRelation: StockChangeWarehouseLocationRelationDTO;
};

export type StockChangeWarehouseLocationRelationDTO = {
    fromWarehouse: InventoryDTO;
    toWarehouse: InventoryDTO;
};

export const INVENTORY_CORRECTION_FIELDS_WITH_STOCK_CHANGES =
    'id,number,date,comment, fromWarehouse(displayName), warehouse(displayName), stockChanges(stockChangeWarehouseLocationRelation( fromWarehouse(displayName), toWarehouse(displayName)))';

export const INVENTORY_CORRECTION_FIELDS =
    'id,number,date,comment, fromWarehouse(displayName), warehouse(displayName)';

export const ALL_WAREHOUSES = '-1';
export const DEFAULT_COLUMNS_NUMBER = 1;
