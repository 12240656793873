import { ModelAPIV1 } from '@General/types/Api';
import { Option } from '../../types';
import { JSPDropdownFetcher } from '../JSPDropdownFetcher';
import { ApiResultMapperNoUndefinedResult } from '@General/Provider';
import { notUndefined } from '@General/Helpers';

interface ProjectSubContractEntry extends ModelAPIV1 {
    readonly displayName: string;
}

const projectSubContractMapper: ApiResultMapperNoUndefinedResult<ProjectSubContractEntry> =
    (item) => ({
        value: item.id + '',
        displayName: item.displayName,
        item,
    });

class ProjectSubContractFetcher extends JSPDropdownFetcher<ProjectSubContractEntry> {
    asyncJsonrpcGetter: Function;

    constructor(scope: HTMLDivElement, queryAttributes: Record<string, any>) {
        super(scope, queryAttributes, projectSubContractMapper);
        this.asyncJsonrpcGetter = asyncrpc.ProjectSubContract.get;
    }

    getParams(): ObjectIndex {
        let projectId = this.getQueryAttribute('projectId', -1);

        if (projectId === -1) {
            projectId = this.getQueryAttribute('projectIdElementId', -1);
        }

        return {
            projectId,
        };
    }

    async fetchList(
        params: ObjectIndex
    ): Promise<Option<ProjectSubContractEntry>[]> {
        return new Promise((resolve, reject) => {
            try {
                return jsonrpc.ProjectSubContract.searchForProjectSubContracts(
                    (result: ProjectSubContractEntry[] | null) => {
                        const mapped = (result ?? [])
                            .filter(notUndefined)
                            .map(this.getMapper(params))
                            .filter(notUndefined);
                        resolve(mapped);
                    },
                    this.getMarshallSpec(),
                    window.loginCompanyId,
                    params.projectId,
                    params.name || ''
                );
            } catch (e) {
                defaultJsonRpcExceptionHandler(e);
                reject(e);
            }
        });
    }
}

export const exports = {
    fetcher: ProjectSubContractFetcher,
    type: 'ProjectSubContract',
};
