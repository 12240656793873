import React from 'react';
import { Button, SendIcon } from '@tlx/atlas';

interface UserFeedbackSendCancelProps {
    isSendButtonClickable: boolean;
    sendFeedback: (input: string) => void;
    resetFeedbackBox: () => void;
    setIsFeedbackBoxOpen: (isFeedbackboxOpen: boolean) => void;
    input: string;
}

export function UserFeedbackSendCancel({
    isSendButtonClickable,
    sendFeedback,
    resetFeedbackBox,
    setIsFeedbackBoxOpen,
    input,
}: UserFeedbackSendCancelProps) {
    return (
        <div className="atl-flex">
            <Button
                className="atl-mr-8"
                id="krr-send-feedback"
                data-testid="krr-feedback"
                data-trackingid="text_send"
                disabled={!isSendButtonClickable}
                onClick={() => {
                    resetFeedbackBox();
                    void sendFeedback(input);
                }}
            >
                {getMessage('text_send')}
                <SendIcon />
            </Button>
            <Button
                className="atl-bg-blue-10"
                data-testid="cancel"
                data-trackingid="text_cancel_reply"
                variant="tertiary"
                onClick={() => setIsFeedbackBoxOpen(false)}
            >
                {getMessage('text_cancel_reply')}
            </Button>
        </div>
    );
}
