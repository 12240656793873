import { ModelAPIV1 } from '@General/types/Api';
import { Option } from '../../types';
import { notUndefined } from '@General/Helpers';
import { JSPDropdownFetcher } from '../JSPDropdownFetcher';
import { ApiResultMapper } from '@General/Provider';
import * as React from 'react';

interface HelpArticleEntry extends ModelAPIV1 {
    readonly displayName: string;
}

const helpArticleMapper: ApiResultMapper<HelpArticleEntry> = (item) => ({
    value: item.id + '',
    displayName: item.displayName,
    item,
});

class HelpArticleFetcher extends JSPDropdownFetcher<HelpArticleEntry> {
    asyncJsonrpcGetter: Function;

    constructor(scope: HTMLDivElement, queryAttributes: Record<string, any>) {
        super(scope, queryAttributes, helpArticleMapper);
        this.asyncJsonrpcGetter = asyncrpc.DocAjax.get;
    }

    getParams(): ObjectIndex {
        return {
            onlyApprovedArticles: this.getQueryAttribute(
                'onlyApprovedArticles',
                false
            ),
            excludeArticleTypeId: this.getQueryAttribute(
                'excludeArticleTypeId',
                -1
            ),
        };
    }

    async fetchList(params: ObjectIndex): Promise<Option<HelpArticleEntry>[]> {
        return new Promise((resolve, reject) => {
            try {
                jsonrpc.DocAjax.search(
                    (result: HelpArticleEntry[] | null) => {
                        const mapped = (result ?? [])
                            .filter(notUndefined)
                            .map(this.getMapper(params))
                            .filter(notUndefined);
                        resolve(mapped);
                    },
                    this.getMarshallSpec(),
                    params.name || '',
                    params.onlyApprovedArticles,
                    params.excludeArticleTypeId
                );
            } catch (e) {
                defaultJsonRpcExceptionHandler(e);
                reject(e);
            }
        });
    }
}

export const exports = {
    fetcher: HelpArticleFetcher,
    type: 'HelpArticle',
    link: (id: number, contextId: number) =>
        `/execute/docEditor?articleId=${id}&language=0&contextId=${contextId}`,
};
