import { EntitlementLookup } from '@General/constants';
import { RouteData } from '@General/Router';
import { Team } from '@General/Team';

import { ReportAuthorization } from './ReportAuthorization.page';

export const route: RouteData = {
    component: ReportAuthorization,
    path: '/execute/report/authorization',
    title: () => getMessage('text_access'),
    team: Team.DATA_STREAM,
    documentationComponent: 311, // REPORT_EDITOR TODO convert to enum
    auth: (context) =>
        context.entitlementMap[EntitlementLookup.REPORT_AUTHOR] !== undefined,
};
