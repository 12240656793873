import { RackbeatProductNewsDTO } from '@Page/LogisticsV2/pages/rackbeatproductnews/types';
import { useFetchAll, useFetchSingle } from '@Page/LogisticsV2/hooks/api';
import { ApiUrl } from '@Page/LogisticsV2/utils/enums';
import { NewsModule } from '@Page/Report/Component/News';
import { LogisticsSettingsDTO } from '@Page/LogisticsV2/pages/Stocktaking/utils';

export function useRackbeatProductNews(isReleased: boolean) {
    const { data } = useFetchAll<RackbeatProductNewsDTO[]>(
        ApiUrl.RACKBEAT_PRODUCT_NEWS +
            `?released=${isReleased}&module=${NewsModule.Rackbeat}`
    );

    const { data: logisticsSettings } = useFetchSingle<LogisticsSettingsDTO>(
        ApiUrl.PRODUCT_LOGISTICS_SETTINGS
    );

    return {
        data: data ?? [],
        agreementNumber: logisticsSettings?.rackbeatAgreementNumber ?? '',
    };
}
