import jQuery from 'jquery';

const $ = jQuery;

import { isFilled } from '@General/Helpers';

import { tlxGetScope } from '../c-common';
import { nav } from '../modules/navigation';
import { logException } from './clientLogging';
import { filter } from '../modules/filter';
import { dateUtil } from '../modules/date';
import { tlxUrl } from '../modules/url';
import { hideOverlays, getContentOverlay } from '../o-common';
import { changeTest, clearChanged, clearTabChanged } from '../modules/change';
import { dev } from '../modules/dev-util';
import { setRelevantUrl } from '../modules/scope';
import { tlxForms } from '../modules/forms';
import { validateModulo10, validateModulo11 } from '../modules/modulo';

//jQuery extensions, plugins, etc.

/**
 * Retrieves values for each element. Typically used within a form, to get a JS-object for ajax sending.
 *
 * This method build a json structure based on the names and values of the input elements.
 * The object structure is derived based on the input names. eg:
 *
 * <name="object[0].foo" value="foo0"/>
 * <name="object[1].foo" value="foo1"/>
 * <name="someId" value="123"/>
 * ->
 * {
 * 	someId: 123,
 * 	object: [
 * 		{ foo: "foo0" },
 * 		{ foo: "foo1" }
 * 	]
 * }
 */
// TODO ojb: set clientIds directly here? Are there any drawbacks?
$.fn.retrieve = function (obj) {
    if (!obj) {
        obj = {};
    }
    this.each(function () {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const elem = this;
        let innerObj;
        let i;

        /// Find the correct value to use. (Some elements need special handling)
        if (elem.type == 'checkbox') {
            innerObj = elem.checked;
        } else if (elem.type == 'radio') {
            if (elem.checked) {
                innerObj = elem.value;
            } else {
                // continue
                return true;
            }
        } else if (elem.type == 'select-multiple') {
            innerObj = [];
            for (i = 0; i < elem.options.length; i++) {
                if (elem.options[i].selected) {
                    innerObj.push(elem.options[i].value);
                }
            }
        } else {
            innerObj = elem.value;
        }

        const elemName = elem.name;

        if (!isFilled(elemName, '')) {
            dev.debugLine(
                'element missing name attribute, ' + $(this).attr('id')
            );
            return true;
        }

        /// Insert the element into the growing json structure

        const parts = elemName.split('.');
        let cur = obj;
        // kinda un-elegant... handle last element after the loop?
        for (i = 0; i < parts.length; i++) {
            const part = parts[i];
            const startArray = part.indexOf('[');
            const endArray = part.lastIndexOf(']');
            let index = -1;
            // if contains [], this is an array
            if (startArray + 1 < endArray) {
                index = parseInt(part.substring(startArray + 1, endArray), 10);
            }
            // If valid array...
            if (index > -1) {
                const name = part.substring(0, startArray);
                let array = cur[name];
                if (!array) {
                    array = [];
                    cur[name] = array;
                }
                if (i == parts.length - 1) {
                    if (
                        !window.productionMode &&
                        array[index] != undefined &&
                        elemName &&
                        array[index] != innerObj
                    ) {
                        alert(
                            "Duplicate name '" +
                                elemName +
                                "' (tlxUtil.js/retrieve)" +
                                '\nfirst value: ' +
                                array[index] +
                                '\nsecond value: ' +
                                innerObj
                        );
                    } // might be cleaner to do a pre-check
                    array[index] = innerObj;
                } else {
                    cur = array[index];
                    if (!cur) {
                        cur = {};
                        array[index] = cur;
                    }
                }
            } else {
                // else it is an object
                if (i == parts.length - 1) {
                    if (
                        !window.productionMode &&
                        cur[part] != undefined &&
                        elemName &&
                        cur[part] != innerObj
                    ) {
                        alert(
                            "Duplicate name '" +
                                elemName +
                                "' (tlxUtil.js/retrieve)" +
                                '\nfirst value: ' +
                                cur[part] +
                                '\nsecond value: ' +
                                innerObj
                        );
                    } // might be cleaner to do a pre-check);
                    cur[part] = innerObj;
                } else {
                    const newCur = cur[part];
                    if (!newCur) {
                        cur = cur[part] = {};
                    } else {
                        cur = newCur;
                    }
                }
            }
        }
    });
    return obj;
};

/**
 * Find all input elements relevant for submit.
 * If you know a element which is not relevant, give it the style class notInForm.
 */
$.fn.findFormInput = function () {
    // find(":input") became a lot slower in jq. 1.8.0-1.8.2-? (especially in chrome)
    const result = this.find(
        "input:not([type='button']),select,textarea"
    ).filter(':not(.notInForm)');
    if (this.find('.redux-jsp-form-hybrid').length > 0) {
        return result.filter(function () {
            return $(this).parents('.redux-jsp-form-hybrid').length === 0;
        });
    }
    return result;
};

/**
 * Disables or enables a field depending on input boolean. And triggers a disabled event.
 * @ Deprecated use DOM API instead
 */
$.fn.tlxSetDisabled = function (disable) {
    this.prop('disabled', disable).each(function () {
        const $this = $(this);
        if ($this.is(':ui-button')) {
            $this.button('option', 'disabled', disable);
        } else if ($this.hasClass('tlxDateField')) {
            // This was moved here (from a eventhandler) due to performance issues with bubbling disableEvent.
            // Probably when disabling alot of elements in clearCachedTabs
            if (disable) {
                $this.next().addClass('ui-datepicker-trigger-disabled');
                $this.datepicker('disable');
            } else {
                $this.next().removeClass('ui-datepicker-trigger-disabled');
                $this.datepicker('enable');
            }
        }
        $this.triggerHandler('disableEvent', disable);
    });
    return this;
};

/**
 * Uses jquery UI tooltip, to highlight and set an error message on a field.
 */
$.fn.setErrorMessage = function (message, validationController) {
    if (this.length > 0) {
        validationController.validationPopup('addMessage', {
            element: this,
            message: message,
        });
    }
    return this;
};

/**
 * Removes the validation message from the specified field.
 *
 * @author Tanet Trimas
 * @date 22.02.2021
 */
$.fn.clearErrorMessage = function (validationController) {
    if ($(validationController).validationPopup('instance') !== undefined) {
        $(validationController).validationPopup('remove', this);
    }
    return this;
};

/**
 * Validate fields. Clears errorMessages before revalidating
 */
$.fn.validate = function (validationController) {
    validationController.validationPopup().validationPopup('clear');
    let required = 'Required';
    let email = 'Invalid email';
    let invalidTimeFormat = 'Invalid timeformat';
    let invalidNumberformat = 'Invalid number format';
    let invalidDateFormat = 'Invalid date format';
    // Negated. If this finds anything, non-numeric characters is present
    const numberExp = new RegExp(
        '[^0-9' + groupingSeparator + decimalSeparator + '-]'
    );
    if (window.getMessage) {
        required = window.getMessage('text_required_field');
        email = window.getMessage('text_valid_email_required');
        invalidTimeFormat = window.getMessage('validation_invalid_timestamp');
        invalidNumberformat = window.getMessage('validation_invalid_number');
        invalidDateFormat = window.getMessage('validation_invalid_date');
    }
    this.each(function () {
        // Number input fields keeps themself valid.
        if (this.type && this.type == 'number') {
            // Fix bug in iphone/safari. If user types in text, it is ignored
            // but still visible. Let us show the user the input is missing.
            if (this.value === '') {
                this.value = 0;
            }
            return;
        }
        const $currentElement = $(this);
        const format = $currentElement.data('vformat');
        const inputValue = $.trim($currentElement.val());
        // should use prop, but must use attr. See bug #11398
        if ($currentElement.attr('required')) {
            if (inputValue == '') {
                $currentElement.setErrorMessage(required, validationController);
            }
        }
        if ($currentElement.is("[type='email']")) {
            if (!$currentElement.attr('required') && inputValue == '') {
                return true; // continue
            }

            const regExp = tlxForms.validEmailRegexp;

            if ($currentElement.attr('multiple')) {
                let emailList;
                if (inputValue.indexOf(',') > 0) {
                    emailList = inputValue.split(',');
                } else if (inputValue.indexOf(';') > 0) {
                    emailList = inputValue.split(';');
                } else if (inputValue.indexOf(' ') > 0) {
                    emailList = inputValue.split(' ');
                } else {
                    emailList = [inputValue];
                }
                $.each(emailList, function () {
                    const inputValue = $.trim(this);
                    if (!regExp.test(inputValue)) {
                        $currentElement.setErrorMessage(
                            email,
                            validationController
                        );
                        return false; // break
                    }
                });
            } else {
                if (!regExp.test(inputValue)) {
                    $currentElement.setErrorMessage(
                        email,
                        validationController
                    );
                }
            }
        }
        if ($currentElement.hasClass('tlxTimeField')) {
            if (!dateUtil.parseTimeStamp($currentElement.val())) {
                $currentElement.setErrorMessage(
                    invalidTimeFormat,
                    validationController
                );
            }
        }

        // Only validate inputValue if it is filled in. We have already checked for required.
        if (inputValue && format) {
            if (format.indexOf('#') >= 0) {
                if (inputValue.match(numberExp)) {
                    $currentElement.setErrorMessage(
                        invalidNumberformat,
                        validationController
                    );
                }
            } else if ($(this).is('.tlxDateField')) {
                if (!dateUtil.isDate(inputValue, format)) {
                    $currentElement.setErrorMessage(
                        invalidDateFormat,
                        validationController
                    );
                }
            }
        }

        // validate kid number for fields with kidNumber class
        if ($(this).hasClass('kidNumber')) {
            if (inputValue.length > 25) {
                $currentElement.setErrorMessage(
                    invalidNumberformat,
                    validationController
                );
            } else if (inputValue.length > 0) {
                const numbersDashRegex = /^\d+-$/;
                const numbersOptionalDashRegex = /^\d+[0-9-]$/;
                const numbersRegex = /^\d+$/;

                /*
                 * With the combined kid and receiverReference field we generally interpret the inputValue as a kid number
                 * if it only contains numbers with an optional dash at the end. IncomingInvoice.jsp is the only occurrence for now.
                 * There are some site specific corner cases where we need to see the inputValue as a receiverReference
                 * even though it "looks like a kid number" - and vice versa. However, these cases are tied to the specific page,
                 * so instead of trying to tackle that in a generic way that would work for all cases here, we do it for the page
                 * it self and pass sessionStorage items with the necessary data we need to avoid validating something as a kid
                 * when it should not be handled as a kid later.
                 *
                 * - interpretKidRefFieldAsKid-elementId: the relevant kidOrReceiverReference element id (some pages have multiple such fields)
                 * - interpretKidRefFieldAsKid: boolean, when true (or null) interpret the inputValue as a kid - if false interpret as receiverReference
                 */
                let interpretKidRefFieldAsKid = null;
                let isKid;
                let isValidNumbersOnly;
                const isNumbersOnly = inputValue.match(numbersRegex) !== null;

                if (
                    $.sessionStorage.getItem(
                        'interpretKidRefFieldAsKid-elementId'
                    ) === this.id
                ) {
                    interpretKidRefFieldAsKid = JSON.parse(
                        $.sessionStorage.getItem('interpretKidRefFieldAsKid')
                    );
                    $.sessionStorage.removeItem(
                        'interpretKidRefFieldAsKid-elementId'
                    );
                    $.sessionStorage.removeItem('interpretKidRefFieldAsKid');
                    isKid =
                        (interpretKidRefFieldAsKid === null ||
                            interpretKidRefFieldAsKid) &&
                        (isNumbersOnly ||
                            inputValue.match(numbersDashRegex) !== null);
                } else {
                    const closestForm =
                        $currentElement.closest('form')[0] ||
                        $currentElement
                            .closest('.ui-dialog')
                            .prevAll('form')
                            .first()[0];
                    const closestInvoiceNumber = $(closestForm)
                        .find('.invoiceNumber')
                        .val();
                    isValidNumbersOnly =
                        inputValue.match(numbersRegex) &&
                        (closestInvoiceNumber === '' ||
                            inputValue !== closestInvoiceNumber);
                }
                if (isKid || isValidNumbersOnly) {
                    // kid validations
                    const isModulo10 = validateModulo10(inputValue);
                    const isModulo11 = validateModulo11(inputValue);

                    if (!isModulo10 && !isModulo11) {
                        $currentElement.setErrorMessage(
                            invalidNumberformat,
                            validationController
                        );
                    } else if (
                        (isModulo10 && !isNumbersOnly) ||
                        (isModulo11 &&
                            !inputValue.match(numbersOptionalDashRegex))
                    ) {
                        $currentElement.setErrorMessage(
                            invalidNumberformat,
                            validationController
                        );
                    }
                }
            }
        }
    });
    $.sessionStorage.removeItem('interpretKidRefFieldAsKid-elementId');
    $.sessionStorage.removeItem('interpretKidRefFieldAsKid');

    if (this.filter('.ui-state-error').length > 0) {
        validationController.validationPopup('sort').validationPopup('open');
        return false;
    }
    return true;
};

/**
 * Toggles content hide/show depending on value. Usually used on a checkbox,
 * where checked values will show content. If reverse is true, check will
 * instead hide content.
 */
$.fn.bindToggleHideShow = function ($toggleContent, reverse) {
    if (this.is('input:checkbox')) {
        this.bind('click.tlxToggle', function () {
            if (reverse) {
                $toggleContent.toggleHideShow(!$(this).is(':checked'));
            } else {
                $toggleContent.toggleHideShow($(this).is(':checked'));
            }

            // Embarrassing hack. When something is shown or hidden in dialogs
            // replace dialogs so they fit the screen again.
            $(this).trigger('changeInDialog');
        });
        this.triggerHandler('click.tlxToggle');
    } else if (this.is('input:radio')) {
        const inputName = this.attr('name');
        const $radioGroup = $("input[name='" + inputName + "']");
        const assignValue = this.val();
        // when other radio button is clicked
        $radioGroup.bind('click.tlxToggle', function () {
            const clickedValue = $(this).val();
            if (reverse) {
                $toggleContent.toggleHideShow(clickedValue != assignValue);
            } else {
                $toggleContent.toggleHideShow(clickedValue == assignValue);
            }

            // Embarrassing hack. When something is shown or hidden in dialogs
            // replace dialogs so they fit the screen again.
            $(this).trigger('changeInDialog');
        });
        $radioGroup.filter(':checked').triggerHandler('click.tlxToggle');
    }
    return this;
};

/**
 * Shows or hides content depending on the boolean show.
 */
$.fn.toggleHideShow = function (show) {
    if (show) {
        this.show();
    } else {
        this.hide();
    }
    return this;
};

/**
 * Serializes form inputs to a query string.
 * Differences from $.serialize:
 * - Specific elements is ignored
 * - disabled elements are serialized
 * - checkboxes are serialized as name=true/false instead name=value. (done here instead of checkboxFix since the dummy checkboxes accumulate unless submitted form is reloaded)
 * - only shallow (ie. this expected to be a flat collection of input elements)
 */
$.fn.tlxSerialize = function () {
    /**
     * These elements are ignored when creating the URL, they are not interesting for the request:
     *
     * - documentationComponent, used by the help system to show relevant documentation on the client side
     * - javaClass, not sure why we need to send this to the client. it is not needed when sending requests to server.
     *
     */
    const ignore = ['documentationComponent', 'javaClass'];

    // (Based on jQuery.serialize)
    // FRAMEWORK: should this maybe handle mobile checkboxes too? (see logic in submitGetFormAjax)
    return jQuery.param(
        this.map(function (i, elem) {
            const val =
                elem.type == 'checkbox' ? '' + elem.checked : $(elem).val();
            if (elem.type == 'radio' && !elem.checked) {
                return null;
            }
            const rCRLF = /\r?\n/g;
            return val == null || elem.name === ''
                ? null
                : jQuery.isArray(val) // $(<select>).val() returns an array
                ? jQuery.map(val, function (val) {
                      return {
                          name: elem.name,
                          value: val.replace(rCRLF, '\r\n'),
                      };
                  })
                : { name: elem.name, value: val.replace(rCRLF, '\r\n') };
        }).filter((i, elem) => elem != null && !ignore.includes(elem.name))
    );
};

/**
 * An AJAX-based get submit. Selection should be a form of method get.
 * Summary of action:
 * - Dirty check
 * - Understands both tabs and non-tabs pages
 * - Loading UI (currently dimmed overlay)
 * - Browser history entry is replaced with the new page
 * - Understands both desktop and mobile
 *
 * The difference from a regular navigate is that this method use a loading UI and that the history entry is replaced, not pushed.
 *
 * If loadFn is specified it overrides the default load mechanism. Change check etc. is still done, but overlay is disabled.
 *
 * FRAMEWORK: whould be nice if this method returned a promise (fulfilled when the changeTest succeed)
 */
$.fn.submitGetFormAjax = function (parameters, loadFn) {
    if (this.length != 1) {
        throw (
            'submitGetFormAjax only accepts exactly one form. (got ' +
            this.length +
            ')'
        );
    }
    const form = this.get(0);
    if (form.method.toLowerCase() != 'get') {
        throw (
            'submitGetFormAjax only accepts get forms. (got ' +
            form.method +
            ')'
        );
    }

    const $filterForm = this.findFormInput();
    const callbackFunction = function () {
        try {
            if (!loadFn) {
                getContentOverlay().show();
            }

            let url = form.action;

            const separator = url.indexOf('?') == -1 ? '?' : '&';

            url += separator + $filterForm.tlxSerialize().replace(/\+/g, '%20');

            if (parameters) {
                $.each(parameters, function (key, value) {
                    url = tlxUrl.removeUrlParameter(url, key);
                    url = tlxUrl.addUrlParameter(url, key, value);
                });
            }
            if (window.location.search.includes('forceRedirect')) {
                url = tlxUrl.addUrlParameter(url, 'forceRedirect', 'true');
            }
            const $tabs = $(form).closest('.ui-tabs');
            if ($tabs.length == 1) {
                url = tlxUrl.removeUrlParameter(url, 'contextId');
                const selectedTabIndex = $tabs.tlxTabs('option', 'active');

                const dontClearTabCache = !!loadFn;
                $tabs.tlxTabs(
                    'updateTabUrl',
                    selectedTabIndex,
                    url,
                    dontClearTabCache
                );

                clearTabChanged(selectedTabIndex);

                if (!loadFn) {
                    $tabs
                        .tlxTabs('load', selectedTabIndex)
                        .one('tabsload', function () {
                            hideOverlays('fade');
                        });
                } else {
                    loadFn(url);
                }
            } else {
                clearChanged();
                filter.setUpdatedUrl(url);
                if (!loadFn) {
                    nav.nav(url, { replaceState: true });
                    hideOverlays('fade');
                } else {
                    loadFn(url);
                    setRelevantUrl(url, tlxGetScope(form));
                }
            }
        } catch (e) {
            if (window.logException) {
                logException('In submitGetFormAjax..', e, {
                    filterSerialized: $filterForm.serialize(),
                });
            }
            throw e;
        }
        return this;
    };
    changeTest(
        callbackFunction,
        this.get(0),
        $('.ui-tabs').tlxTabs('option', 'active')
    );
};

$.debounce = function (method, delayMs) {
    delayMs = delayMs || 500;
    let timer = null;
    return function () {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const context = this;
        // eslint-disable-next-line prefer-rest-params
        const args = arguments;
        clearTimeout(timer);
        timer = setTimeout(function () {
            method.apply(context, args);
        }, delayMs);
    };
};

/**
 * Throttle function, will only run provided function fn if it is 250ms since last time.
 *
 * @param fn
 * @param threshold
 * @returns {Function}
 */
$.throttle = function throttle(fn, threshold) {
    threshold = threshold || 250;
    let last;
    let deferTimer;

    return function () {
        const now = +new Date();
        // eslint-disable-next-line prefer-rest-params
        const args = arguments;
        if (last && now < last + threshold) {
            clearTimeout(deferTimer);
            deferTimer = setTimeout(function () {
                last = now;
                fn.apply(this, args);
            }, threshold);
        } else {
            last = now;
            fn.apply(this, args);
        }
    };
};

/**
 * Insert clientIds into the collection.
 *
 * The collection is a json representation of a input element collection. (typically obtained from $.retrieve)
 *
 * The purpose of the clientId is to allow the server to send input-element specific information back (typically validation messages)
 */
$.setClientIds = function (collection, currentPath) {
    $.each(collection, function (index, value) {
        if ($.type(value) === 'object' || $.type(value) === 'array') {
            if ($.isArray(collection)) {
                // If array; path should be array[index]
                $.setClientIds(value, currentPath + '[' + index + ']');
            } else {
                // else; path should be array.index
                $.setClientIds(
                    value,
                    $.appendValueWithDelimiter(currentPath, index, '.')
                );
            }
        } else {
            // Check that currentPath is defined, so no clientId will be added
            // to top element.
            if (currentPath && collection['clientId'] === undefined) {
                collection['clientId'] = currentPath;
            }
        }
    });
    return collection;
};

/**
 * Simply returns an object combined with another object with a optional
 * delimiter.
 */
$.appendValueWithDelimiter = function (stringObject, newValue, delimiter) {
    if (stringObject) {
        return stringObject + delimiter + newValue;
    }
    return newValue;
};

/**
 * When triggering lots of events, it might take some time before all events have climbed DOM-tree and
 * event handlers have finished their job. In this case, we can use async triggering. Helpful when one
 * ore more of these cases occur:
 *
 * 1. Trigger a lot of events at the same time.
 * 2. Expensive event-handlers.
 * 3. A lot of event handlers on same event.
 *
 * http://jsfiddle.net/7dhpnm5w/
 *
 */
$.fn.asyncTrigger = function () {
    // eslint-disable-next-line prefer-rest-params
    window.setTimeout(Function.apply.bind(this.trigger, this, arguments), 0);
    return this;
};

/**
 * A poor mans template engine.
 */
$.fn.templ = function (vals) {
    if (this.length !== 1) {
        throw 'The template must be a single DOM Element.';
    }
    let template = this.html();
    for (const prop in vals) {
        template = template.replace('%' + prop + '%', vals[prop]);
    }
    return $(template);
};

/**
 * Find elements based on their property.
 * This is run on ALL elements so please restrict
 * the selector with scope and element type, eg:
 *
 * $('input:prop("orderLines[]description"), scope)...
 *
 * and NEVER
 *
 * $(':prop("orderLines[]description")')..
 *
 *
 * DEPRECATED:
 * Sizzle is soon gone, and jQuery will use querySelectorAll instead of it's own query engine (jQUery 4.0).
 * Sizzle was awesome, and actually pawed the way for querySelectorAll, BUT the world moves on.
 * We therefore need to rethink the :prop pseudo selector ...
 *
 */
$.extend($.expr.pseudos, {
    prop: function (elem, index, meta) {
        const prop = meta[3];
        if (/[0-9]/.exec(prop)) {
            return elem.name === prop;
        }
        return (
            elem.name &&
            elem.name.split(/\[[0-9]+\]/).join('') === prop.split('[]').join('')
        );
    },
});

// Removed aliases we still use. Especially $.bind is difficult to refactor,
// because javascript functions also have a .bind() method.
$.fn.bind = $.fn.bind || $.fn.on;
$.fn.unbind = $.fn.unbind || $.fn.off;
