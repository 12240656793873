import * as React from 'react';
import { PageLoader } from '@Component/PageLoader';
import { RouteData } from '@General/Router';
import { Team } from '@General/Team';

const ALLOWED_ROLES = [150];

const Page: React.FC = (props) => (
    <PageLoader<{}>
        loader={async () => import('./ZtlOnboardingPage').then((m) => m.page)}
        adjustWrapperDiv
        props={props}
    />
);

export const route: RouteData = {
    component: Page,
    path: '/execute/ztlOnboarding',
    title: () => getMessage('text_bank_integration_mini'),
    team: Team.PBC,
    // To fully connect this page to the Tripletex framework, the page needs a documentationComponent id so that we can
    // link help articles to the page

    // We should limit who has access to this page.
    auth: (context) =>
        mySubscriptionForm ||
        context.entitlements.some((e) => ALLOWED_ROLES.includes(e.id)),
};
