import {
    Company,
    GroupRelationDTO,
    ProductGroupDTO,
    ProductPriceDTO,
    VatTypeDTO,
} from '@Page/LogisticsV2/types/dtoTypes';
import { useFetchAll, useFetchSingle } from '@Page/LogisticsV2/hooks/api';
import { mapObjectToURLSearchParams } from '@Page/LogisticsV2/utils/mappers';
import { ApiUrl } from '../utils/enums';

export function useProductPrices(queryParams?: {}) {
    const { data, error, mutate } = useFetchAll<ProductPriceDTO[]>(
        ApiUrl.PRODUCT_PRICE,
        queryParams ? mapObjectToURLSearchParams(queryParams) : undefined
    );
    const isLoading = !data && !error;
    return { data, error, isLoading, mutate };
}

export function useGetVAT(queryParams?: {}) {
    const { data, error, mutate } = useFetchAll<VatTypeDTO[]>(
        ApiUrl.VAT_TYPE,
        queryParams ? mapObjectToURLSearchParams(queryParams) : undefined
    );
    data?.forEach((vat) => {
        vat.displayName = `${vat.number}: (${vat.percentage}%) ${vat.name}`;
    });
    const isLoading = !data && !error;
    return { data, error, isLoading, mutate };
}

export function useProductGroupRelations(queryParams?: {}) {
    const { data, error, mutate } = useFetchAll<GroupRelationDTO[]>(
        ApiUrl.PRODUCT_GROUP_RELATIONS,
        queryParams ? mapObjectToURLSearchParams(queryParams) : undefined
    );
    const isLoading = !data && !error;
    return { data, error, isLoading, mutate };
}

export function useProductGroup(queryParams?: {}, swrConfig?: {}) {
    const { data, error, mutate } = useFetchAll<ProductGroupDTO[]>(
        moduleLogistics ? ApiUrl.PRODUCT_GROUP : undefined,
        queryParams ? mapObjectToURLSearchParams(queryParams) : undefined,
        swrConfig
    );
    const isLoading = !data && !error;
    return { data, error, isLoading, mutate };
}

export function useCompany(companyId: number) {
    const { data, error, mutate } = useFetchSingle<Company>(
        `${ApiUrl.COMPANY}/${companyId}`,
        mapObjectToURLSearchParams({ fields: 'currency' })
    );
    const isLoading = !data && !error;
    return { data, error, isLoading, mutate };
}
