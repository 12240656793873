import { FilterChip } from '@Component/Filter';
import { LoadingSpinner } from '@Component/Loading';
import { DataTreeTitle } from '@Page/LogisticsV2/components/DataTree/DataTree';
import { SelectableDataTree } from '@Page/LogisticsV2/components/DataTree/SelectableDataTree';
import { useSelectableTree } from '@Page/LogisticsV2/components/DataTree/tree.hooks';
import { DataTreeDTO } from '@Page/LogisticsV2/components/DataTree/types';
import { ResaleProductDTO } from '@Page/LogisticsV2/types/dtoTypes';
import { UseResaleProductsPaginatedReturn } from '@Page/LogisticsV2/types/returnTypes';
import { ApiError } from '@Page/LogisticsV2/utils/constants';
import {
    Button,
    ChevronDownIcon,
    ChevronUpIcon,
    Input,
    Modal,
} from '@tlx/atlas';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { ProductGroupsEmptyState } from '../ProductGroupsEmptyState';
import { ProductSelectorActions } from '../ProductSelector';

import { useGetVAT } from '@Page/LogisticsV2/hooks/useData';
import '../ProductSelector.css';
import {
    ButtonsSection,
    Container,
    GroupsCollapsibleArrow,
    GroupsSection,
    GroupsTitleSection,
    ProductsHeaderSection,
    ProductsTableSection,
    ProductTitleSection,
} from '../ProductSelectorLayout';
import {
    NewProductForm,
    ProductSelectorModalTable,
} from './ProductSelectorModalComponents';
import { NewProductSection } from './ProductSelectorModalLayout';
import {
    createNewProductWithGroupRelation,
    getAllProducts,
} from './useProductSelectorModal';

type ProductSelectorModalProps = {
    paginatedData: UseResaleProductsPaginatedReturn;
    selectedGroup: number | undefined;
    onChangeGroupFilter: (productGroupId: number | undefined) => void;
};

export function ProductSelectorModalOld({
    productsData,
    nodes,
    modalProps,
    onConfirm,
    onCancel,
}: {
    productsData: ProductSelectorModalProps;
    nodes: DataTreeDTO[];
    modalProps: { open: boolean; onClose: () => void };
    onConfirm: (selectedProducts: ResaleProductDTO[]) => void;
    onCancel?: (selectedProducts: ResaleProductDTO[]) => void;
}) {
    const [selectedProducts, setSelectedProducts] = useState<{
        [id: number]: ResaleProductDTO;
    }>({});
    const [isCheckAll, setIsCheckAll] = useState(false);
    const getSelectedProductIds = () => {
        return Object.keys(selectedProducts).map((id) => Number(id));
    };
    useEffect(() => {
        const newSelectedProducts = { ...selectedProducts };
        if (isCheckAll) {
            productsData.paginatedData.data.forEach((product) => {
                newSelectedProducts[product.id] = product;
            });
        } else {
            productsData.paginatedData.data.forEach((product) => {
                delete newSelectedProducts[product.id];
            });
        }
        setSelectedProducts(newSelectedProducts);
    }, [isCheckAll]);

    const handleSelectAll = () => {
        setIsCheckAll(!isCheckAll);
    };

    const handleChangeCount = (product: ResaleProductDTO) => {
        const newSelectedProducts = { ...selectedProducts };
        newSelectedProducts[product.id] = product;
        setSelectedProducts(newSelectedProducts);
    };

    const [newProducts, setNewProducts] = useState<ResaleProductDTO[]>([]);
    const handleProductSelection = (product: ResaleProductDTO) => {
        const newSelectedProducts = { ...selectedProducts };
        if (product.id in newSelectedProducts) {
            delete newSelectedProducts[product.id];
        } else {
            newSelectedProducts[product.id] = product;
        }
        setSelectedProducts(newSelectedProducts);
    };
    useEffect(() => {
        productsData.paginatedData.data = getAllProducts(
            productsData.paginatedData.data,
            newProducts
        );
    }, [productsData.paginatedData.data, newProducts]);
    const [newProduct, setNewProduct] = useState(false);
    const [error, setError] = useState<ApiError | undefined>(undefined);
    const handleCreateProduct = async (
        resaleProduct: Partial<ResaleProductDTO>,
        productGroupId: number | undefined
    ) => {
        setError(undefined);
        const { data, error } = await createNewProductWithGroupRelation(
            resaleProduct,
            productGroupId
        );
        if (error) {
            setError(error);
        } else {
            setNewProducts([...newProducts, data.value]);
            handleProductSelection(data.value);
            setNewProduct(!newProduct);
            productsData.paginatedData.refresh();
        }
    };
    const { data: vatTypes } = useGetVAT({ typeOfVat: 'OUTGOING' });
    const [visibleGroups, setVisibleGroups] = useState(nodes.length > 0);
    const [isLoading, setIsloading] = useState(false);
    return (
        <Modal className={'goldshark-productelector'} {...modalProps}>
            <div
                className={classNames('goldshark-productselector__overlay', {
                    'goldshark-productselector__overlay--loading': isLoading,
                })}
            >
                <LoadingSpinner />
            </div>
            <Container hasProductGroups={true}>
                {moduleLogistics && (
                    <ProductGroupsSection
                        onProductGroupSelected={(productGroupId) =>
                            productsData.onChangeGroupFilter(productGroupId)
                        }
                        productGroupDTOs={nodes}
                        selectedProductGroupId={productsData.selectedGroup}
                        visibleGroups={visibleGroups}
                        setVisibleGroups={setVisibleGroups}
                    />
                )}
                <ProductTitleSection>
                    {getMessage('text_products')}
                </ProductTitleSection>
                <ProductsHeaderSection>
                    <Input
                        data-testid={'product-selector-search'}
                        type={'search'}
                        value={productsData.paginatedData.query}
                        onChange={(e) =>
                            productsData.paginatedData.search(
                                e.currentTarget.value
                            )
                        }
                        className={'goldshark-product-selector-search'}
                        placeholder={getMessage('text_search')}
                        disabled={newProduct}
                    />
                    {!moduleRackbeat && (
                        <NewProductButton
                            isExpanded={newProduct}
                            onClick={() => setNewProduct(!newProduct)}
                        />
                    )}
                    <FilterChip
                        visible={Object.keys(selectedProducts).length > 0}
                        value={`${
                            Object.keys(selectedProducts).length
                        } ${getMessage('text_selected')}`}
                        action={() => {
                            setSelectedProducts({});
                            setIsCheckAll(false);
                        }}
                    />
                </ProductsHeaderSection>
                {newProduct && (
                    <>
                        <NewProductSection>
                            <NewProductForm
                                nodes={nodes}
                                onCreateProduct={handleCreateProduct}
                                productGroupId={productsData.selectedGroup}
                                vatTypes={vatTypes ?? []}
                                error={error}
                            />
                        </NewProductSection>
                        <ButtonsSection>
                            <div className={'atl-flex atl-gap-8'}>
                                <Button
                                    data-testid={'new-product-create-buttonn'}
                                    variant="primary"
                                    type={'submit'}
                                    form={'create-product-form'}
                                >
                                    {getMessage('button_create')}
                                </Button>
                                <Button
                                    data-testid={'new-product-cancel-button'}
                                    variant="secondary"
                                    onClick={() => setNewProduct(false)}
                                >
                                    {getMessage('text_cancel_reply')}
                                </Button>
                            </div>
                        </ButtonsSection>
                    </>
                )}
                {!newProduct && (
                    <>
                        <ProductsTableSection>
                            <ProductSelectorModalTable
                                products={productsData.paginatedData}
                                selectedIds={getSelectedProductIds()}
                                onSelectProduct={handleProductSelection}
                                onSelectAll={handleSelectAll}
                                onChangeCount={handleChangeCount}
                            />
                        </ProductsTableSection>
                        <ButtonsSection>
                            <ProductSelectorActions
                                onCancel={() => {
                                    onCancel?.(Object.values(selectedProducts));
                                    setSelectedProducts({});
                                    setIsloading(false);
                                    modalProps.onClose();
                                }}
                                onConfirm={() => {
                                    setIsloading(true);
                                    onConfirm(Object.values(selectedProducts));
                                    setIsloading(false);
                                    setSelectedProducts({});
                                    modalProps.onClose();
                                }}
                            />
                        </ButtonsSection>
                    </>
                )}
            </Container>
        </Modal>
    );
}

function NewProductButton({
    isExpanded,
    onClick,
}: {
    isExpanded: boolean;
    onClick: () => void;
}) {
    return (
        <Button
            data-testid={'new-product-button'}
            variant="tertiary"
            onClick={onClick}
            className={'atl-mr-auto'}
        >
            {getMessage('text_new_product')}
            {isExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
        </Button>
    );
}

function ProductGroupsSection({
    onProductGroupSelected,
    productGroupDTOs,
    selectedProductGroupId,
    visibleGroups,
    setVisibleGroups,
}: {
    onProductGroupSelected: (productGroupId: number | undefined) => void;
    productGroupDTOs: DataTreeDTO[];
    selectedProductGroupId?: number;
    visibleGroups: boolean;
    setVisibleGroups: (visible: boolean) => void;
}) {
    const productGroups = useSelectableTree(productGroupDTOs);
    const visibleGroupsLocalStorage = localStorage.getItem(
        'isProductGroupsVisible'
    );
    const isProductGroupVisible = visibleGroupsLocalStorage === 'true';
    useEffect(() => {
        if (visibleGroupsLocalStorage === null) {
            localStorage.setItem(
                'isProductGroupsVisible',
                String(visibleGroups)
            );
        }
    }, []);

    const showProductGroups: boolean =
        visibleGroupsLocalStorage === null
            ? visibleGroups
            : isProductGroupVisible;
    const showEmptyState = productGroups.nodes.length === 0;
    return (
        <>
            {showProductGroups && (
                <>
                    {showEmptyState ? (
                        <ProductGroupsEmptyState
                            className={
                                'goldshark-productselector__emptystate atl-bg-blue-5 atl-flex atl-flex-col'
                            }
                        />
                    ) : (
                        <>
                            <GroupsTitleSection>
                                {getMessage('text_product_groups')}
                            </GroupsTitleSection>
                            <GroupsSection>
                                <DataTreeTitle
                                    onChange={() =>
                                        onProductGroupSelected(undefined)
                                    }
                                    isSelected={
                                        selectedProductGroupId === undefined
                                    }
                                    className={
                                        'goldshark_productselector-all-groups'
                                    }
                                >
                                    {getMessage('text_all')}
                                </DataTreeTitle>
                                <SelectableDataTree
                                    nodes={productGroups.nodes}
                                    selectedNodeId={selectedProductGroupId}
                                    onChange={onProductGroupSelected}
                                />
                            </GroupsSection>
                        </>
                    )}
                </>
            )}
            <GroupsCollapsibleArrow
                onClick={() => {
                    setVisibleGroups(!visibleGroups);
                    localStorage.setItem(
                        'isProductGroupsVisible',
                        String(!isProductGroupVisible)
                    );
                }}
                visibleGroups={showProductGroups}
            />
        </>
    );
}
