import { SimplePaymentWidget } from '@Page/PaymentWidget/component/SimplePaymentWidget';
import { useSimplePaymentWidget } from '@Page/PaymentWidget/hooks/useSimplePaymentWidget';
import React from 'react';
import { Scenario } from '@Page/PaymentWidget/types/paymentWidgetTypes';

export type SimplePaymentWidgetWrapperProps = {
    scenario: Scenario;
    scenarioId: number;
    label: string;
};
export function SimplePaymentWidgetWrapper({
    scenario,
    scenarioId,
    label,
}: SimplePaymentWidgetWrapperProps) {
    return (
        <SimplePaymentWidget
            {...useSimplePaymentWidget(scenario, scenarioId)}
            atlasDesignSystem={true}
            label={label}
        />
    );
}
