import jQuery from 'jquery';
const $ = jQuery;

import { hashCode } from '../legacy/jsonrpc';

window.servicePromotion = (function () {
    const storeTag =
        'tlx.service-promotion_' +
        window.contextId +
        '_' +
        window.loginEmployeeId;

    function loadData() {
        const rawData = $.localStorage.getItem(storeTag);
        return rawData ? JSON.parse(rawData) : {};
    }

    function saveData(data) {
        $.localStorage.setItem(storeTag, JSON.stringify(data));
    }

    function onPageLoad() {
        const storedData = loadData();

        $('.service-promotion')
            .filter(function () {
                return !storedData['' + hashCode($(this).attr('data-type'))];
            })
            .removeClass('hidden')
            .find('.service-promotion__close')
            .one('click', hideHandler);
    }

    function hideHandler() {
        const storedData = loadData();
        const container = $(this).closest('.service-promotion');
        container.slideUp();
        storedData['' + hashCode(container.attr('data-type'))] = true;
        saveData(storedData);
    }

    return {
        onPageLoad: onPageLoad,
        hideHandler: hideHandler,
    };
})();

$(window).on(
    'tlxLoadPageContentDone tlxtabsload tlxLazyLoadDone',
    window.servicePromotion.onPageLoad
);
