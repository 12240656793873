import { SortableTableHeader } from '@tlx/atlas';
import { SortingType } from '@Page/LogisticsV2/utils/enums';
import React, { Dispatch, SetStateAction } from 'react';
import {
    getSortDirection,
    getSorting,
} from '@Page/LogisticsV2/utils/functions';
import useSessionStorage from '@Page/LogisticsV2/hooks/useSessionStorage';

export function WarehouseOverviewTableHeader({
    columns,
}: {
    columns: string[];
}) {
    const [sortBy, setSortBy] = useSessionStorage<SortingType>(
        'goldshark_warehouse_overview_sorting',
        SortingType.NAME_ASCENDING
    );

    return (
        <thead className="atl-thead atl-thead--sticky">
            <tr className="atl-tr">
                <SortableTableHeader
                    className="atl-font-bold atl-align-middle"
                    data-testid="warehouse-overview-number-header"
                    sortDirection={getSortDirection(
                        sortBy,
                        SortingType.NUMBER_ASCENDING,
                        SortingType.NUMBER_DESCENDING
                    )}
                    onClick={() =>
                        setSortBy(
                            getSorting(
                                sortBy,
                                SortingType.NUMBER_ASCENDING,
                                SortingType.NUMBER_DESCENDING
                            )
                        )
                    }
                >
                    {getMessage('text_number')}
                </SortableTableHeader>
                <SortableTableHeader
                    className="atl-font-bold atl-align-middle"
                    data-testid="warehouse-overview-name-header"
                    sortDirection={getSortDirection(
                        sortBy,
                        SortingType.NAME_ASCENDING,
                        SortingType.NAME_DESCENDING
                    )}
                    onClick={() =>
                        setSortBy(
                            getSorting(
                                sortBy,
                                SortingType.NAME_ASCENDING,
                                SortingType.NAME_DESCENDING
                            )
                        )
                    }
                >
                    {getMessage('text_name')}
                </SortableTableHeader>
                {columns.map((column, index) => (
                    <WarehouseOverviewHeaderCell
                        key={`${column}-${index}`}
                        column={column}
                        sortBy={sortBy}
                        onChangeSortBy={setSortBy}
                    />
                ))}
                <th
                    className="atl-th atl-font-bold"
                    key="warehouseOverviewMenuHeader"
                />
            </tr>
        </thead>
    );
}
export function WarehouseOverviewHeaderCell({
    column,
    sortBy,
    onChangeSortBy,
}: {
    column: string;
    sortBy?: SortingType;
    onChangeSortBy?: Dispatch<SetStateAction<SortingType>>;
}): JSX.Element {
    switch (column) {
        case 'main_warehouse':
            return <MainWarehouseHeader />;
        case 'description':
            return <DescriptionHeader />;
        case 'last_stocking':
            return (
                <LastStocktakingHeader
                    sortBy={sortBy}
                    onChangeSortBy={onChangeSortBy}
                />
            );
        case 'status':
            return <StatusHeader />;
        default:
            throw new Error(`Unknown setting: ${column}`);
    }
}

function MainWarehouseHeader() {
    return (
        <th
            className="atl-th atl-font-bold atl-align-middle atl-text-center"
            key="main_warehouse"
        >
            {getMessage('text_main')}
        </th>
    );
}

function DescriptionHeader() {
    return (
        <th className="atl-th atl-font-bold atl-align-middle" key="description">
            {getMessage('text_description')}
        </th>
    );
}

function LastStocktakingHeader({
    sortBy,
    onChangeSortBy,
}: {
    sortBy?: SortingType;
    onChangeSortBy?: Dispatch<SetStateAction<SortingType>>;
}) {
    return (
        <SortableTableHeader
            className="atl-font-bold atl-align-middle"
            data-testid="last_stocking"
            sortDirection={getSortDirection(
                sortBy ?? SortingType.NUMBER_ASCENDING,
                SortingType.LAST_STOCKING_ASCENDING,
                SortingType.LAST_STOCKING_DESCENDING
            )}
            onClick={() =>
                onChangeSortBy?.(
                    getSorting(
                        sortBy ?? SortingType.NUMBER_ASCENDING,
                        SortingType.LAST_STOCKING_ASCENDING,
                        SortingType.LAST_STOCKING_DESCENDING
                    )
                )
            }
        >
            {getMessage('text_listwarehouse_last_inventory')}
        </SortableTableHeader>
    );
}

function StatusHeader() {
    return (
        <th className="atl-th atl-font-bold atl-align-middle" key="status">
            {getMessage('text_active')}
        </th>
    );
}
