import { CommandResponderRegistry } from '@General/Command';
import { extraFrame } from '../../../../../js/modules/extraFrame';

export const DocumentDrawerCommandId = 'DocumentDrawerCommand';

const DocumentDrawerCommand = {
    id: DocumentDrawerCommandId,
};

const DocumentDrawerCombination = {
    key: 'v',
};

function documentDrawerHandler() {
    if ($('#extraFrameContainer').is(':visible')) {
        extraFrame.collapse();
    } else {
        const extra = extraFrame.expand(undefined, extraFrame.getDefaultType());

        if (extra === undefined) {
            const $showAttachment = $('#showAttachment');
            if ($showAttachment.length > 0) {
                $showAttachment[0].click();
            }
        }
    }
}

export function registerDocumentDrawer(registry: CommandResponderRegistry) {
    registry.registerCommand(DocumentDrawerCommand);
    registry.registerHandler(DocumentDrawerCommandId, documentDrawerHandler);
    registry.registerCombination(
        DocumentDrawerCommandId,
        DocumentDrawerCombination
    );
}
