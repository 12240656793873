import { PageData, PageLoader } from '@Component/PageLoader';
import { LogisticsSettingsDataModule } from '@Page/Logistics/Module';
import * as React from 'react';
import { renderComponent } from '@General/renderComponent';
import { TransportIntegration } from '@Page/Logistics/TransportIntegration/TransportIntegration';

const loader: React.FC = () => (
    <PageLoader<{}> loader={async () => page} adjustWrapperDiv />
);

export function renderTransportIntegrationPage(domId: string) {
    renderComponent(loader, domId);
}

export const page: PageData = {
    page: TransportIntegration,
    modules: [
        LogisticsSettingsDataModule,
        {
            id: 'transportIntegration',
        },
    ],
};
