import { useEffect, useState } from 'react';
import { SalesModule } from '@Page/MySubscription/types/MySubscriptionStates';
import { Customer } from '@Page/InvoicingDialog/types/InvoicingDialog.type';
import { SendTypes } from '@Page/InvoicingDialog/component/InvoicingForm/SendMethod';
import { createAPIRequest } from '../../../../hooks/fetch/createAPIRequest';
import { createAPIResponse } from '../../../../hooks/fetch/createAPIResponse';
import { ResponseWrapper } from '../../../../hooks/fetch/types';

export enum ElectronicReceiveState {
    LOADING,
    YES,
    NO,
    ERROR,
    MISSING_MODULES,
}

export function useCanReceiveElectronic(
    customer: Customer,
    isCreditNote: boolean,
    sendTypes: string[]
): ElectronicReceiveState {
    const [canReceiverElectronicInvoices, setCanReceiverElectronicInvoices] =
        useState(ElectronicReceiveState.LOADING);

    const hasModulesForLookup = customer.isPrivateIndividual
        ? sendTypes.some(
              (value) =>
                  value === SendTypes.EFAKTURA || value === SendTypes.VIPPS
          )
        : sendTypes.some((value) => value === SendTypes.EHF);

    useEffect(() => {
        if (customer.id !== 0) {
            const url = new URL(
                '/v2/customer/invoiceSendType/query',
                window.location.origin
            );
            const params = url.searchParams;
            params.set('customerId', customer.id.toString());
            params.set('isCreditNote', String(isCreditNote));

            if (hasModulesForLookup) {
                doLookup(customer, isCreditNote)
                    .then((data) => {
                        const response = data.value;
                        if (response !== null) {
                            const isEHF = response.canReceiveEHF;
                            const isEFaktura = response.canReceiveEFaktura;
                            const isElmaLookupAlive = response.elmaLookupAlive;
                            const isEhfOrEfaktura =
                                isEHF || isEFaktura
                                    ? ElectronicReceiveState.YES
                                    : ElectronicReceiveState.NO;
                            setCanReceiverElectronicInvoices(
                                isElmaLookupAlive
                                    ? isEhfOrEfaktura
                                    : ElectronicReceiveState.ERROR
                            );
                        }
                    })
                    .catch((e) => {
                        const missingEfakturaData =
                            !customer.phoneNumber && !customer.invoiceEmail;

                        if (
                            customer.isPrivateIndividual &&
                            missingEfakturaData
                        ) {
                            setCanReceiverElectronicInvoices(
                                ElectronicReceiveState.NO
                            );
                        } else {
                            setCanReceiverElectronicInvoices(
                                ElectronicReceiveState.ERROR
                            );
                        }
                    });
            } else {
                setCanReceiverElectronicInvoices(
                    ElectronicReceiveState.MISSING_MODULES
                );
            }
        }
    }, [customer.id]);

    return canReceiverElectronicInvoices;
}

async function doLookup(customer: Customer, isCreditNote: boolean) {
    const request = createAPIRequest(
        `/v2/customer/invoiceSendType/query?customerId=${customer.id}&isCreditNote=${isCreditNote}`,
        {
            method: 'GET',
        }
    );

    const response = await fetch(request);

    return createAPIResponse<ResponseWrapper<any>>(request, response);
}

export function useServices() {
    const [services, setServices] = useState<SalesModule[] | undefined>();

    async function fetchServices(): Promise<Array<SalesModule>> {
        return new Promise((resolve, reject) => {
            jsonrpc.MySubscriptionForm.getServices(
                (result: SalesModule[], error: any) => {
                    if (error) {
                        defaultJsonRpcExceptionHandler(error);
                        reject(error);
                    } else {
                        resolve(result);
                    }
                }
            );
        });
    }

    useEffect(() => {
        fetchServices()
            .then((result: SalesModule[]) => {
                const salesModules = result.filter(
                    (salesModule) => salesModule.category === 'Faktura'
                );

                setServices(salesModules);
            })
            .catch((error) => {
                console.log('error' + error);
                console.log(error);
            });
    }, []);

    return services;
}
