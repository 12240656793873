import {
    CreditorBankIdentificator,
    PaymentType,
    PaymentTypeEnum,
    PaymentWidgetAdvancedData,
    PaymentWidgetValidations,
    Scenario,
} from '@Page/PaymentWidget/types/paymentWidgetTypes';
import { PaymentWidgetDropdown } from '@Page/PaymentWidget/component/PaymentWidgetDropdown/PaymentWidgetDropdown';
import { PaymentWidgetInputAmountField } from '@Page/PaymentWidget/component/Amount/PaymentWidgetInputAmountField';
import { DomesticOrForeignPayment } from '@Page/PaymentWidget/component/DomesticOrForeignPayment/DomesticOrForeignPayment';
import { PaymentWidgetDatePicker } from '@Page/PaymentWidget/component/PaymentWidgetDatePicker';
import { TextField } from '@Page/PaymentWidget/component/TextField/TextField';
import { PaymentWidgetCountryDropdownWrapper } from '@Page/PaymentWidget/component/PaymentWidgetCountryDropdownWrapper';
import {
    PaymentWidgetRegulatoryReportingCodeDropdownWrapper,
    RegulatoryReportingCodePair,
} from '@Page/PaymentWidget/component/PaymentWidgetRegulatoryReportingCodeDropdownWrapper';
import { SwiftOrBankCode } from '@Page/PaymentWidget/component/SwiftOrBankCode/SwiftOrBankCode';
import React, { useContext, useEffect } from 'react';
import { KidNumberOrReceiverReference } from '@Page/PaymentWidget/component/KidNumber/KidNumberOrReceiverReference';
import { Button, Label } from '@tlx/atlas';
import { PaymentWidgetContext } from '../../../../../js/component/paymentWidget/paymentWidget';
import { PaymentWidgetComboDropdown } from '@Page/PaymentWidget/component/PaymentWidgetDropdown/PaymentWidgetComboDropdown';
import { Upsale } from '@Page/PaymentWidget/component/Upsale/Upsale';
import { PaymentTypeDropdown } from '@Page/PaymentWidget/component/PaymentTypeDropdown/PaymentTypeDropdown';
import classNames from 'classnames';
import { isZtl } from '@General/PBC/bank.globals';

export const enum BankFieldsEnum {
    CreditorName = 'creditorName',
    CreditorAddress = 'creditorAddress',
    CreditorPostalCode = 'creditorPostalCode',
    CreditorPostalCity = 'creditorPostalCity',
    CreditorCountryId = 'creditorCountryId',
    CreditorBic = 'creditorBic',
    CreditorBankCountryId = 'creditorBankCountryId',
    RegulatoryReportingCode = 'regulatoryReportingCode',
    RegulatoryReportingInfo = 'regulatoryReportingInfo',
}
export const getName = (propertyName: BankFieldsEnum): string => {
    switch (propertyName) {
        case BankFieldsEnum.CreditorName:
            return isZtl
                ? 'paymentWidgetWrapper.ztlTransaction.creditorName'
                : 'paymentWidgetWrapper.autoPayTransaction.creditorName';

        case BankFieldsEnum.CreditorAddress:
            return isZtl
                ? 'paymentWidgetWrapper.ztlTransaction.creditorAddress'
                : 'paymentWidgetWrapper.autoPayTransaction.creditorAddress';
        case BankFieldsEnum.CreditorPostalCode:
            return isZtl
                ? 'paymentWidgetWrapper.ztlTransaction.creditorPostalCode'
                : 'paymentWidgetWrapper.autoPayTransaction.creditorPostalCode';
        case BankFieldsEnum.CreditorPostalCity:
            return isZtl
                ? 'paymentWidgetWrapper.ztlTransaction.creditorPostalCity'
                : 'paymentWidgetWrapper.autoPayTransaction.creditorPostalCity';
        case BankFieldsEnum.CreditorCountryId:
            return isZtl
                ? 'paymentWidgetWrapper.ztlTransaction.creditorCountryId'
                : 'paymentWidgetWrapper.autoPayTransaction.creditorCountryId';
        case BankFieldsEnum.CreditorBankCountryId:
            return isZtl
                ? 'paymentWidgetWrapper.ztlTransaction.creditorBankCountryId'
                : 'paymentWidgetWrapper.autoPayTransaction.creditorBankCountryId';
        case BankFieldsEnum.CreditorBic:
            return isZtl
                ? 'paymentWidgetWrapper.ztlTransaction.creditorBIC'
                : 'paymentWidgetWrapper.autoPayTransaction.creditorBIC';
        case BankFieldsEnum.RegulatoryReportingCode:
            return isZtl
                ? 'paymentWidgetWrapper.ztlTransaction.regulatoryReportingCode'
                : 'paymentWidgetWrapper.autoPayTransaction.regulatoryReportingCode';
        case BankFieldsEnum.RegulatoryReportingInfo:
            return isZtl
                ? 'paymentWidgetWrapper.ztlTransaction.regulatoryReportingInfo'
                : 'paymentWidgetWrapper.autoPayTransaction.regulatoryReportingInfo';
        default:
            return '';
    }
};

export type OppositeAmountProps = {
    showOppositeAmount: boolean;
    currencyCode?: string;
    currencyId?: number;
    amount: number;
    disabled?: boolean;
};
export type CreditNotesDetailsProps = {
    amountSubstracted: number;
    totalRemaining: number;
};
type AdvancedPaymentWidgetTemplateProps = {
    paymentWidgetData: PaymentWidgetAdvancedData;
    setPaymentWidgetData: (
        paymentWidgetData: Partial<PaymentWidgetAdvancedData>
    ) => void;
    atlasDesignSystem: boolean;
    scenario: Scenario;
    closeDialog: () => void;
    registerPayment: () => void;
    validations?: PaymentWidgetValidations;
    setValidations?: (validations: PaymentWidgetValidations) => void;
    oppositeAmount?: OppositeAmountProps;
    setOppositeAmount?: (oppositeAmount: OppositeAmountProps) => void;
    creditNotesDetails?: CreditNotesDetailsProps;
};

export const AdvancedPaymentWidgetTemplate = ({
    paymentWidgetData,
    setPaymentWidgetData,
    atlasDesignSystem,
    scenario,
    closeDialog,
    registerPayment,
    validations,
    setValidations,
    oppositeAmount,
    setOppositeAmount,
    creditNotesDetails,
}: AdvancedPaymentWidgetTemplateProps) => {
    const { isComboAccountNumber } = useContext(PaymentWidgetContext);
    const transactionName = isZtl ? 'ztlTransaction' : 'autoPayTransaction';

    const hidePayment =
        paymentWidgetData.selectedPaymentType.paymentIntegration ===
        PaymentTypeEnum.NOT_PAID;

    const removeSpacesAndDots = (value: string) => {
        return value?.replace(/[. ]+/g, '').trim().toUpperCase();
    };
    useEffect(() => {
        //This needs to be done in useEffect because the inside component will not rerender if the value is the same
        //Ex: enter iban and then add a space or dot, the space or dot will not be removed because the value is the same after replace
        if (paymentWidgetData.accountNumber) {
            const newAccountNumber = removeSpacesAndDots(
                paymentWidgetData.accountNumber
            );
            if (newAccountNumber !== paymentWidgetData.accountNumber) {
                setPaymentWidgetData({
                    accountNumber: newAccountNumber,
                });
            }
        }
    }, [paymentWidgetData.accountNumber]);

    const setAmount = (amount: number) => {
        setPaymentWidgetData({
            amount: amount,
        });
    };
    const isDialog =
        scenario === Scenario.INCOMING_INVOICE_DIALOG ||
        scenario === Scenario.INVOICE_REIMBURSEMENT_DIALOG;

    const resetValidationMessage = (propertyName: string) => {
        if (setValidations && validations) {
            setValidations({
                ...validations,
                [propertyName]: '',
            });
        }
    };

    const getValidationMessage = (
        propertyName: keyof PaymentWidgetValidations
    ): string => {
        return (isDialog && validations ? validations[propertyName] : '') || '';
    };

    const setOppositeAmountObject = (amount: number) => {
        if (setOppositeAmount && oppositeAmount) {
            setOppositeAmount({
                ...oppositeAmount,
                amount: amount,
            });
        }
    };

    const isBankIntegration =
        paymentWidgetData.selectedPaymentType.paymentIntegration ===
            PaymentTypeEnum.AUTOPAY ||
        paymentWidgetData.selectedPaymentType.paymentIntegration ===
            PaymentTypeEnum.ZTL;

    if (creditNotesDetails?.totalRemaining === 0) {
        return (
            <div className="atl-w-full atl-mb-16 atl-text-grey-80 atl-font-bold">
                <input
                    type="hidden"
                    name="paymentWidgetWrapper.paymentType"
                    value={paymentWidgetData.selectedPaymentType.paymentTypeId}
                />
                {getMessage('text_subtracted_credit_notes_is_0')}
            </div>
        );
    }

    return (
        <div className="atl-flex atl-flex-col atl-gap-16">
            <div className="pbc-payment__section-row">
                <div className="atl-w-full atl-flex atl-flex-col atl-gap-4">
                    <PaymentTypeDropdown
                        id="paymentType"
                        paymentTypes={paymentWidgetData.paymentTypes}
                        atlasDesignSystem={atlasDesignSystem}
                        selectedPaymentType={
                            paymentWidgetData.selectedPaymentType
                        }
                        setDropdownOption={(
                            selectedPaymentType: PaymentType
                        ) => {
                            setPaymentWidgetData({
                                ...paymentWidgetData,
                                selectedPaymentType,
                            });
                        }}
                        validationMessage={getValidationMessage(
                            'selectedPaymentType'
                        )}
                        resetValidationMessage={() =>
                            resetValidationMessage('selectedPaymentType')
                        }
                    />
                    {!hidePayment && !!paymentWidgetData.prepaidAmount && (
                        <div className="atl-text-sm atl-mb-16 atl-flex atl-flex-col">
                            <Label>
                                {getMessage('text_prepaid_invoice_amount')}
                            </Label>
                            <Label>{paymentWidgetData.prepaidAmount}</Label>
                        </div>
                    )}
                    {!hidePayment && (
                        <PaymentWidgetInputAmountField
                            name="paymentWidgetWrapper.newPayment.amountBasisCurrency"
                            id="amount"
                            atlasDesignSystem={atlasDesignSystem}
                            amount={paymentWidgetData.amount}
                            setAmount={setAmount}
                            data-testid="amount-field"
                            validationMessage={getValidationMessage('amount')}
                            resetValidationMessage={() =>
                                resetValidationMessage('amount')
                            }
                        >
                            {`${getMessage('text_amount')} ${
                                paymentWidgetData.currencyCode
                            }`}
                        </PaymentWidgetInputAmountField>
                    )}
                    {creditNotesDetails &&
                        creditNotesDetails.amountSubstracted > 0 && (
                            <div className="atl-mb-16 atl-text-grey-80 atl-font-bold">
                                {getMessage(
                                    'text_credit_notes_message_closed_section2'
                                )}
                            </div>
                        )}
                    {oppositeAmount?.showOppositeAmount && (
                        <PaymentWidgetInputAmountField
                            name="oppositeAmountCurrency"
                            id="oppositeAmount"
                            atlasDesignSystem={atlasDesignSystem}
                            amount={oppositeAmount.amount}
                            disabled={oppositeAmount.disabled}
                            setAmount={setOppositeAmountObject}
                            data-testid="opposite-amount-field"
                        >
                            {`${getMessage('text_amount')} ${
                                oppositeAmount.currencyCode
                            }`}
                        </PaymentWidgetInputAmountField>
                    )}
                    {(paymentWidgetData.selectedPaymentType
                        .paymentIntegration === PaymentTypeEnum.AUTOPAY ||
                        paymentWidgetData.selectedPaymentType
                            .paymentIntegration === PaymentTypeEnum.ZTL) && (
                        <DomesticOrForeignPayment
                            name={`paymentWidgetWrapper.${transactionName}.foreignPayment`}
                            isForeignPayment={
                                paymentWidgetData.isForeignPayment
                            }
                            setForeignPayment={(isForeignPayment: boolean) => {
                                setPaymentWidgetData({
                                    isForeignPayment: isForeignPayment,
                                });
                            }}
                        />
                    )}
                </div>

                <div className="atl-w-full atl-flex atl-flex-col atl-gap-4">
                    {!hidePayment && (
                        <PaymentWidgetDatePicker
                            name="paymentWidgetWrapper.newPayment.date"
                            id="paymentDate"
                            date={paymentWidgetData.date}
                            setDate={(date: string) => {
                                setPaymentWidgetData({
                                    date: date,
                                });
                            }}
                            atlasDesignSystem={atlasDesignSystem}
                            validationMessage={getValidationMessage('date')}
                            resetValidationMessage={() =>
                                resetValidationMessage('date')
                            }
                            data-testid="date-field"
                        >
                            {getMessage('text_payment_date')}
                        </PaymentWidgetDatePicker>
                    )}

                    {isBankIntegration && (
                        <>
                            {isComboAccountNumber ? (
                                <PaymentWidgetComboDropdown
                                    name={
                                        paymentWidgetData.accountNumberPropertyName
                                    }
                                    options={
                                        paymentWidgetData.customerVendorIbanOrBban
                                    }
                                    atlasDesignSystem={atlasDesignSystem}
                                    dropdownOption={
                                        paymentWidgetData.accountNumber
                                    }
                                    setDropdownOption={(accountNumber) =>
                                        setPaymentWidgetData({
                                            accountNumber: accountNumber,
                                        })
                                    }
                                    // data-testid is used to show chevron instead of kebab menu
                                    data-testid="account-number-field"
                                    validationMessage={getValidationMessage(
                                        'bankAccount'
                                    )}
                                    resetValidationMessage={() =>
                                        resetValidationMessage('bankAccount')
                                    }
                                    className={classNames({
                                        'pbc-smart-scan-prediction':
                                            paymentWidgetData
                                                .smartScanMapping?.[
                                                'BANK_ACCOUNT_NUMBER'
                                            ],
                                    })}
                                >
                                    {getMessage(
                                        'text_autopay_iban_or_account_number'
                                    )}
                                </PaymentWidgetComboDropdown>
                            ) : (
                                <PaymentWidgetDropdown
                                    name={
                                        paymentWidgetData.accountNumberPropertyName
                                    }
                                    id="ibanAccountNumber"
                                    options={paymentWidgetData.customerVendorIbanOrBban.map(
                                        (value) => ({
                                            value,
                                            displayName: value,
                                        })
                                    )}
                                    atlasDesignSystem={atlasDesignSystem}
                                    dropdownOption={
                                        paymentWidgetData.accountNumber
                                    }
                                    setDropdownOption={(accountNumber) =>
                                        setPaymentWidgetData({
                                            accountNumber: accountNumber,
                                        })
                                    }
                                    data-testid="account-number-field"
                                    validationMessage={getValidationMessage(
                                        'bankAccount'
                                    )}
                                    resetValidationMessage={() =>
                                        resetValidationMessage('bankAccount')
                                    }
                                    className={classNames({
                                        'pbc-smart-scan-prediction':
                                            paymentWidgetData
                                                .smartScanMapping?.[
                                                'BANK_ACCOUNT_NUMBER'
                                            ],
                                    })}
                                >
                                    {getMessage(
                                        'text_autopay_iban_or_account_number'
                                    )}
                                </PaymentWidgetDropdown>
                            )}

                            <KidNumberOrReceiverReference
                                atlasDesignSystem={atlasDesignSystem}
                                value={paymentWidgetData.kid}
                                setValue={(kid: string) => {
                                    setPaymentWidgetData({
                                        kid: kid,
                                    });
                                }}
                                name={paymentWidgetData.kidPropertyName}
                                validationMessage={getValidationMessage('kid')}
                                resetValidationMessage={() =>
                                    resetValidationMessage('kid')
                                }
                                className={classNames({
                                    kidNumber:
                                        !paymentWidgetData.isForeignPayment &&
                                        scenario !==
                                            Scenario.INVOICE_REIMBURSEMENT_DIALOG,
                                })}
                            />
                            {scenario ===
                                Scenario.INVOICE_REIMBURSEMENT_DIALOG && (
                                <div className="atl-text-sm atl-mb-16">
                                    {getMessage(
                                        'text_payment_must_be_approved_in_overview'
                                    )}
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>

            {paymentWidgetData.isForeignPayment && isBankIntegration && (
                <>
                    <div className="pbc-payment__section-row pbc-payment__section-row-separator-line" />
                    <div className="pbc-payment__section-row pbc-payment__header">
                        {getMessage('text_autopay_foreign_payment_heading')}
                    </div>
                    <div className="pbc-payment__section-row">
                        <div className="atl-w-full atl-flex atl-flex-col atl-gap-4">
                            <div className="atl-text-sm atl-mb-16">
                                {getMessage(
                                    'text_autopay_foreign_creditor_info'
                                )}
                            </div>

                            <TextField
                                name={getName(BankFieldsEnum.CreditorName)}
                                id="creditor-name"
                                label="text_name"
                                atlasDesignSystem={atlasDesignSystem}
                                value={paymentWidgetData.creditorName}
                                setValue={(creditorName: string) => {
                                    setPaymentWidgetData({
                                        creditorName: creditorName,
                                    });
                                }}
                                validationMessage={getValidationMessage(
                                    'creditorName'
                                )}
                                resetValidationMessage={() =>
                                    resetValidationMessage('creditorName')
                                }
                            />
                            <TextField
                                name={getName(BankFieldsEnum.CreditorAddress)}
                                id="creditor-address"
                                label="text_address"
                                atlasDesignSystem={atlasDesignSystem}
                                value={paymentWidgetData.creditorAddress}
                                setValue={(creditorAddress: string) => {
                                    setPaymentWidgetData({
                                        creditorAddress: creditorAddress,
                                    });
                                }}
                                validationMessage={getValidationMessage(
                                    'creditorAddress'
                                )}
                                resetValidationMessage={() =>
                                    resetValidationMessage('creditorAddress')
                                }
                            />
                            <div className="atl-flex atl-gap-16">
                                <TextField
                                    name={getName(
                                        BankFieldsEnum.CreditorPostalCode
                                    )}
                                    id="creditor-post-code"
                                    label="text_postal_number"
                                    atlasDesignSystem={atlasDesignSystem}
                                    value={paymentWidgetData.creditorPostalCode}
                                    setValue={(creditorPostCode: string) => {
                                        setPaymentWidgetData({
                                            creditorPostalCode:
                                                creditorPostCode,
                                        });
                                    }}
                                    validationMessage={getValidationMessage(
                                        'creditorPostalCode'
                                    )}
                                    resetValidationMessage={() =>
                                        resetValidationMessage(
                                            'creditorPostalCode'
                                        )
                                    }
                                />
                                <TextField
                                    name={getName(
                                        BankFieldsEnum.CreditorPostalCity
                                    )}
                                    id="creditor-city"
                                    label="text_city"
                                    atlasDesignSystem={atlasDesignSystem}
                                    value={paymentWidgetData.creditorPostalCity}
                                    setValue={(creditorCity: string) => {
                                        setPaymentWidgetData({
                                            creditorPostalCity: creditorCity,
                                        });
                                    }}
                                    validationMessage={getValidationMessage(
                                        'creditorPostalCity'
                                    )}
                                    resetValidationMessage={() =>
                                        resetValidationMessage(
                                            'creditorPostalCity'
                                        )
                                    }
                                />
                            </div>
                            <PaymentWidgetCountryDropdownWrapper
                                id="creditorCountry"
                                dataTestId="creditor-country-dropdown"
                                atlasDesignSystem={atlasDesignSystem}
                                name={getName(BankFieldsEnum.CreditorCountryId)}
                                value={paymentWidgetData.creditorCountryId}
                                setValue={(countryId: number) => {
                                    setPaymentWidgetData({
                                        creditorCountryId: countryId,
                                    });
                                }}
                                validationMessage={getValidationMessage(
                                    'creditorCountryId'
                                )}
                                resetValidationMessage={() =>
                                    resetValidationMessage('creditorCountryId')
                                }
                            />
                            <PaymentWidgetRegulatoryReportingCodeDropdownWrapper
                                id="regulatoryReportingCode"
                                dataTestId="regulatory-reporting-code-dropdown"
                                atlasDesignSystem={atlasDesignSystem}
                                name={getName(
                                    BankFieldsEnum.RegulatoryReportingCode
                                )}
                                regulatoryReportingCodePair={
                                    paymentWidgetData as RegulatoryReportingCodePair
                                }
                                setRegulatoryReportingCodePair={({
                                    regulatoryReportingCode,
                                    regulatoryReportingInfo,
                                }: RegulatoryReportingCodePair) => {
                                    setPaymentWidgetData({
                                        regulatoryReportingCode,
                                        regulatoryReportingInfo,
                                    });
                                }}
                                regulatoryReportingInfoValidationMessage={getValidationMessage(
                                    'regulatoryReportingInfo'
                                )}
                                resetRegulatoryReportingInfoValidationMessage={() =>
                                    resetValidationMessage(
                                        'regulatoryReportingInfo'
                                    )
                                }
                            />
                        </div>

                        <div className="atl-w-full atl-flex atl-flex-col atl-gap-4">
                            <div className="atl-text-sm atl-mb-16">
                                {getMessage(
                                    'text_autopay_foreign_creditor_bank_info'
                                )}
                            </div>
                            {paymentWidgetData.selectedPaymentType
                                .paymentIntegration ===
                                PaymentTypeEnum.AUTOPAY && (
                                <SwiftOrBankCode
                                    atlasDesignSystem={atlasDesignSystem}
                                    bankIdentification={
                                        paymentWidgetData.creditorBankIdentificator
                                    }
                                    setBankIdentification={(
                                        creditorBankIdentificator: number
                                    ) => {
                                        setPaymentWidgetData({
                                            creditorBankIdentificator,
                                        });
                                    }}
                                />
                            )}
                            {paymentWidgetData.creditorBankIdentificator ===
                                CreditorBankIdentificator.CREDITOR_BANK_IDENTIFICATOR_SWIFT && (
                                <TextField
                                    name={getName(BankFieldsEnum.CreditorBic)}
                                    id="swift-number"
                                    className={classNames({
                                        'pbc-smart-scan-prediction':
                                            paymentWidgetData
                                                .smartScanMapping?.[
                                                'BANK_ACCOUNT_SWIFT'
                                            ],
                                    })}
                                    label="text_autopay_BIC"
                                    atlasDesignSystem={atlasDesignSystem}
                                    value={paymentWidgetData.creditorBic}
                                    setValue={(creditorBic: string) => {
                                        const newBic =
                                            removeSpacesAndDots(creditorBic);
                                        setPaymentWidgetData({
                                            creditorBic: newBic,
                                        });
                                    }}
                                    validationMessage={getValidationMessage(
                                        'creditorBic'
                                    )}
                                    resetValidationMessage={() =>
                                        resetValidationMessage('creditorBic')
                                    }
                                />
                            )}

                            {paymentWidgetData.creditorBankIdentificator ===
                                CreditorBankIdentificator.CREDITOR_BANK_IDENTIFICATOR_CLEARING_CODE && (
                                <>
                                    <TextField
                                        name="paymentWidgetWrapper.autoPayTransaction.creditorClearingCode"
                                        id="bank-code"
                                        atlasDesignSystem={atlasDesignSystem}
                                        value={
                                            paymentWidgetData.creditorClearingCode
                                        }
                                        setValue={(
                                            creditorClearingCode: string
                                        ) => {
                                            setPaymentWidgetData({
                                                creditorClearingCode:
                                                    creditorClearingCode,
                                            });
                                        }}
                                        validationMessage={getValidationMessage(
                                            'creditorClearingCode'
                                        )}
                                        resetValidationMessage={() =>
                                            resetValidationMessage(
                                                'creditorClearingCode'
                                            )
                                        }
                                        label="text_autopay_clearing_code"
                                    />
                                    <TextField
                                        name="paymentWidgetWrapper.autoPayTransaction.creditorBankName"
                                        id="bank-name"
                                        label="text_bank_name"
                                        atlasDesignSystem={atlasDesignSystem}
                                        value={
                                            paymentWidgetData.creditorBankName
                                        }
                                        setValue={(
                                            creditorBankName: string
                                        ) => {
                                            setPaymentWidgetData({
                                                creditorBankName:
                                                    creditorBankName,
                                            });
                                        }}
                                    />
                                    <TextField
                                        name="paymentWidgetWrapper.autoPayTransaction.creditorBankAddress"
                                        id="bank-address"
                                        label="text_bank_address"
                                        atlasDesignSystem={atlasDesignSystem}
                                        value={
                                            paymentWidgetData.creditorBankAddress
                                        }
                                        setValue={(
                                            creditorBankAddress: string
                                        ) => {
                                            setPaymentWidgetData({
                                                creditorBankAddress:
                                                    creditorBankAddress,
                                            });
                                        }}
                                    />
                                    <div className="atl-flex atl-gap-16">
                                        <TextField
                                            name="paymentWidgetWrapper.autoPayTransaction.creditorBankPostalCode"
                                            id="bank-post-code"
                                            label="text_bank_postal_number"
                                            atlasDesignSystem={
                                                atlasDesignSystem
                                            }
                                            value={
                                                paymentWidgetData.creditorBankPostalCode
                                            }
                                            setValue={(
                                                creditorBankPostalCode: string
                                            ) => {
                                                setPaymentWidgetData({
                                                    creditorBankPostalCode:
                                                        creditorBankPostalCode,
                                                });
                                            }}
                                        />
                                        <TextField
                                            name="paymentWidgetWrapper.autoPayTransaction.creditorBankPostalCity"
                                            id="bank-city"
                                            label="text_bank_city"
                                            atlasDesignSystem={
                                                atlasDesignSystem
                                            }
                                            value={
                                                paymentWidgetData.creditorBankPostalCity
                                            }
                                            setValue={(
                                                creditorBankPostalCity: string
                                            ) => {
                                                setPaymentWidgetData({
                                                    creditorBankPostalCity:
                                                        creditorBankPostalCity,
                                                });
                                            }}
                                        />
                                    </div>
                                </>
                            )}
                            <PaymentWidgetCountryDropdownWrapper
                                id="creditorBankCountry"
                                dataTestId="creditor-bank-country-dropdown"
                                atlasDesignSystem={atlasDesignSystem}
                                name={getName(
                                    BankFieldsEnum.CreditorBankCountryId
                                )}
                                value={paymentWidgetData.creditorBankCountryId}
                                setValue={(countryId: number) => {
                                    setPaymentWidgetData({
                                        creditorBankCountryId: countryId,
                                    });
                                }}
                                supportedInZtl={isZtl}
                                validationMessage={getValidationMessage(
                                    'creditorBankCountryId'
                                )}
                                resetValidationMessage={() =>
                                    resetValidationMessage(
                                        'creditorBankCountryId'
                                    )
                                }
                            />
                        </div>
                    </div>
                </>
            )}

            <Upsale className="pbc-payment__section-row" />

            {isDialog && (
                <div className="pbc-payment__section-row atl-flex atl-justify-end atl-gap-8">
                    <Button
                        data-testid="register-button"
                        variant="primary"
                        onClick={registerPayment}
                    >
                        {getMessage(
                            scenario === Scenario.INVOICE_REIMBURSEMENT_DIALOG
                                ? 'text_reimburse'
                                : 'text_register_payment'
                        )}
                    </Button>

                    <Button
                        data-testid="close-button"
                        variant="tertiary"
                        onClick={closeDialog}
                    >
                        {getMessage('button_cancel')}
                    </Button>
                </div>
            )}
        </div>
    );
};
