import * as React from 'react';

/**
 * Checks if the target has ancestor as an ancestor.
 *
 * @param ancestor
 * @param target
 *
 * @author tellef
 * @date 2020-11-02
 */
export function isAncestorOfTarget(
    ancestor: Element | null,
    target: Element | null
): boolean {
    if (
        ancestor === null ||
        target === null ||
        ancestor.ownerDocument === null ||
        target.ownerDocument !== ancestor.ownerDocument
    ) {
        return false;
    }

    // Safeguard against infinite loops.
    // If there is more than a 1000 elements between then you got other problems that needs fixing first.
    let rounds = 0;
    let current: Element | null = target;
    while (
        current &&
        current.parentElement !== ancestor.ownerDocument.body &&
        rounds < 1000
    ) {
        ++rounds;
        if (current.parentElement === ancestor) {
            return true;
        } else {
            current = current.parentElement;
        }
    }
    return false;
}

/**
 * Checks if the given MouseEvent is within the given bounds.
 *
 * @param bounds
 * @param event
 *
 * @author tellef
 * @date 2020-11-02
 */
export function withinBounds(
    bounds: ClientRect,
    event: MouseEvent | React.MouseEvent
): boolean {
    return (
        bounds.left <= event.clientX &&
        bounds.right >= event.clientX &&
        bounds.top <= event.clientY &&
        bounds.bottom >= event.clientY
    );
}

/**
 * Checks if the given element is scrollable.
 *
 * @param element
 *
 * @author tellef
 * @date 2020-08-07
 */
export function isScrollable(element: HTMLElement): boolean {
    if (!(element instanceof HTMLElement)) {
        return false;
    }

    const { overflowX, overflowY } = window.getComputedStyle(element);
    return (
        (overflowX !== 'visible' && overflowX !== 'hidden') ||
        (overflowY !== 'visible' && overflowY !== 'hidden')
    );
}

/**
 * Get the containing scroll element for the given element.
 *
 * @param target
 *
 * @author tellef
 * @date 2020-08-07
 */
export function getScrollParent(
    target: HTMLElement | Maybe
): HTMLElement | Maybe {
    let current = target;

    while (
        current &&
        (!isScrollable(current) || current.scrollHeight < current.clientHeight)
    ) {
        current = current.parentElement;
    }

    return current;
}
