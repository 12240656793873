import { SagaIterator } from 'redux-saga';
import { put, takeEvery } from 'redux-saga/effects';

import { FetchAll, StoreAll } from '../action/BetaProgram.action';
import { getBetaPrograms } from '@General/getBetaPrograms';

function* sagaFetchAll(): SagaIterator {
    yield put(StoreAll(getBetaPrograms()));
}

export const betaProgramSaga = [takeEvery(FetchAll.type, sagaFetchAll)];
