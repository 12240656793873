import * as React from 'react';

import { PopupMenu, PopupMenuItemButton, usePopupMenu } from '@tlx/atlas';

import { useComposedRefs } from '../../../hooks/useComposedRefs';

import {
    canDownloadPageAsCSV,
    canDownloadPageAsPDF,
    canDownloadPageAsXLS,
    downloadPageAsXLS,
} from '../ExportMenu.util';
import { ExportCsv } from './ExportCSV';
import { ExportPdf } from './ExportPDF';

/**
 * ExportMenu
 */
export const ExportMenu: React.FC = () => {
    const {
        openerButtonProps: { ref, ...openerButtonProps },
        popupMenuProps,
    } = usePopupMenu();

    const pdfEnabled = React.useMemo(canDownloadPageAsPDF, [
        popupMenuProps.hidden,
    ]);

    const [pdfModal, setPdfModal] = React.useState(false);
    const onOpenPdfModal = React.useCallback(
        () => setPdfModal(true),
        [setPdfModal]
    );
    const onClosePdfModal = React.useCallback(
        () => setPdfModal(false),
        [setPdfModal]
    );

    const xlsEnabled = React.useMemo(canDownloadPageAsXLS, [
        popupMenuProps.hidden,
    ]);

    const csvEnabled = React.useMemo(canDownloadPageAsCSV, [
        popupMenuProps.hidden,
    ]);

    const [csvModal, setCsvModal] = React.useState(false);
    const onOpenCsvModal = React.useCallback(
        () => setCsvModal(true),
        [setCsvModal]
    );
    const onCloseCsvModal = React.useCallback(
        () => setCsvModal(false),
        [setCsvModal]
    );

    const handleExport = React.useCallback(
        (event: TlxExportMenuExportEvent) => {
            if (event.defaultPrevented) {
                return;
            }

            event.preventDefault();

            switch (event.detail.type) {
                case 'csv':
                    onOpenCsvModal();
                    break;
                case 'pdf':
                    onOpenPdfModal();
                    break;
                case 'xls':
                    downloadPageAsXLS();
                    break;
            }
        },
        [onOpenCsvModal, onOpenPdfModal]
    );

    const localRef = React.useRef<HTMLButtonElement | null>(null);
    const handleRef = useComposedRefs(ref, localRef);

    React.useEffect(() => {
        const target = localRef.current;
        target?.addEventListener('tlx:export-menu:export', handleExport);

        return () => {
            target?.removeEventListener('tlx:export-menu:export', handleExport);
        };
    }, [handleExport]);

    const onTriggerCsv = React.useCallback(() => {
        const event: TlxExportMenuExportEvent = new CustomEvent(
            'tlx:export-menu:export',
            { detail: { type: 'csv' }, cancelable: true }
        );
        localRef.current?.dispatchEvent(event);
    }, []);

    const onTriggerPdf = React.useCallback(() => {
        const event: TlxExportMenuExportEvent = new CustomEvent(
            'tlx:export-menu:export',
            { detail: { type: 'pdf' }, cancelable: true }
        );
        localRef.current?.dispatchEvent(event);
    }, []);

    const onTriggerXls = React.useCallback(() => {
        const event: TlxExportMenuExportEvent = new CustomEvent(
            'tlx:export-menu:export',
            { detail: { type: 'xls' }, cancelable: true }
        );
        localRef.current?.dispatchEvent(event);
    }, []);

    return (
        <>
            <button
                {...openerButtonProps}
                ref={handleRef}
                data-testid="export-menu-button"
                aria-label={getMessage('text_download')}
                className="tlx-topbar__button"
                type="button"
            >
                <i className="tlx-topbar__button__icon">file_download</i>
            </button>
            <PopupMenu {...popupMenuProps}>
                <PopupMenuItemButton
                    id="export-menu-pdf-button"
                    data-testid="export-menu-pdf-button"
                    disabled={!pdfEnabled}
                    onClick={onTriggerPdf}
                >
                    {getMessage('text_download_pdf')}
                </PopupMenuItemButton>
                <PopupMenuItemButton
                    id="export-menu-excel-button"
                    data-testid="export-menu-excel-button"
                    disabled={!xlsEnabled}
                    onClick={onTriggerXls}
                >
                    {getMessage('text_download_excel')}
                </PopupMenuItemButton>
                <PopupMenuItemButton
                    id="export-menu-csv-button"
                    data-testid="export-menu-csv-button"
                    disabled={!csvEnabled}
                    onClick={onTriggerCsv}
                >
                    {getMessage('text_download_csv')}
                </PopupMenuItemButton>
            </PopupMenu>
            {pdfModal ? <ExportPdf onClose={onClosePdfModal} /> : null}
            {csvModal ? <ExportCsv onClose={onCloseCsvModal} /> : null}
        </>
    );
};
