import React from 'react';
import '../PaymentWidget.scss';
import { Scenario } from '@Page/PaymentWidget/types/paymentWidgetTypes';
import { SimplePaymentWidgetWrapper } from '@Page/PaymentWidget/component/SimplePaymentWidgetWrapper';
import { AdvancedPaymentWidgetWrapper } from '@Page/PaymentWidget/component/AdvancedPaymentWidgetWrapper';

/**
 * Payment Widget root
 *
 * @author Melisa Damian
 * @date 11. Oct 2021
 */

export type PaymentWidgetProps = {
    simplePaymentWidget: boolean;
    scenario: Scenario;
    scenarioId: number;
    label: string;
    vendorId?: number;
    invoiceId?: number;
    hasKid?: boolean;
    isOTPValidated?: boolean;
    voucherId?: number;
};

export function PaymentWidget({
    simplePaymentWidget,
    scenario,
    scenarioId,
    label,
    vendorId,
    invoiceId,
    hasKid,
    isOTPValidated,
    voucherId,
}: PaymentWidgetProps) {
    return simplePaymentWidget ? (
        <SimplePaymentWidgetWrapper
            scenario={scenario}
            scenarioId={scenarioId}
            label={label}
        />
    ) : (
        <AdvancedPaymentWidgetWrapper
            scenario={scenario}
            label={label}
            vendorId={vendorId}
            invoiceId={invoiceId}
            hasKid={hasKid}
            isOTPValidated={isOTPValidated}
            voucherId={voucherId}
        />
    );
}
