import { ActionReducer } from '@General/types/Reducer';

import { StoreData } from '../action/LogisticsSettingsData.action';
import { LogisticsSettingsDataState } from '../type/LogisticsSettingsData.type';

const initialState: LogisticsSettingsDataState = {
    hasWarehouseLocation: false,
    showOnboardingWizard: false,
};

const reducerStoreData: ActionReducer<LogisticsSettingsDataState, StoreData> = (
    state,
    action
) => ({
    ...state,
    hasWarehouseLocation: action.logisticsSettings.hasWarehouseLocation,
    showOnboardingWizard: action.logisticsSettings.showOnboardingWizard,
});

type SupportedActions = StoreData;

export function logisticsSettingsDataReducer(
    state = initialState,
    action: SupportedActions
) {
    switch (action.type) {
        case StoreData.type:
            return reducerStoreData(state, action);
        default:
            return state;
    }
}
