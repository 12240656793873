import { useFetchAll } from '@Page/LogisticsV2/hooks/api';
import { StocktakingDTO } from '@Page/LogisticsV2/types/dtoTypes';
import { ApiUrl } from '@Page/LogisticsV2/utils/enums';
import { getURL } from '@Page/LogisticsV2/utils/functions';
import { mapObjectToURLSearchParams } from '@Page/LogisticsV2/utils/mappers';
import { useState } from 'react';

export function useStocktakingOverview() {
    const [inventoryId, setInventoryId] = useState<string | undefined>(
        undefined
    );
    const { data, error, isLoading, mutate } = useFetchAll<StocktakingDTO[]>(
        getURL(
            ApiUrl.INVENTORY_STOCKTAKING,
            mapObjectToURLSearchParams({
                fields: 'id,number,date,comment,isCompleted,inventory(id,name)',
                inventoryId: inventoryId,
            })
        ) ?? undefined
    );
    return {
        data,
        error,
        isLoading,
        warehouse: inventoryId,
        onWarehouseChange: setInventoryId,
        mutate,
    };
}
