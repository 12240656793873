import jQuery from 'jquery';
const $ = jQuery;

/**
 * Generic widget for queue handling. Please keep it generic.
 *
 * @author Lars-Erik Bruce
 *
 */
$.widget('tlx.queueWidget', {
    options: {
        open: function (/* element, index */) {
            return true;
        },
        // What is going to happen when the queue gets empy?
        whenEmptied: function () {},
        // Gets called _after_ the current element is removed
        removeCurrent: function (/* element, index */) {},
        // Event function called on keyDown on document if set
        onkeydown: undefined,
        $currentQueueReport: $(),
        $sizeQueueReport: $(),
        $firstButton: $(),
        $previousButton: $(),
        $nextButton: $(),
        $lastButton: $(),
        useButtonsForNavigation: true,
    },

    _checkButtonStatus: function () {
        this.options.$firstButton.toggleClass(
            'ui-state-disabled',
            this.element._current <= 0
        );
        this.options.$previousButton.toggleClass(
            'ui-state-disabled',
            this.element._current <= 0
        );
        this.options.$nextButton.toggleClass(
            'ui-state-disabled',
            this.element._current === this.element._queue.length - 1 ||
                this.element._queue.length === 0
        );
        this.options.$lastButton.toggleClass(
            'ui-state-disabled',
            this.element._current === this.element._queue.length - 1 ||
                this.element._queue.length === 0
        );
    },

    open: function (index) {
        if (!this.options.open(this.element._queue[index], index)) return false;
        this.element._current = index;
        this._checkButtonStatus();
        this.options.$currentQueueReport.text(index + 1);
        return true;
    },

    getCurrent: function () {
        return this.element._queue[this.element._current] || false;
    },

    currentIndex: function () {
        return this.element._current;
    },

    get: function (index) {
        return this.element._queue[index] || false;
    },

    reduce: function (fn) {
        return fn(this.element._queue);
    },

    // Converts every element in the queue to the result
    // of using the element as argument to the given function.
    // More a transform than a map ....
    map: function (fn) {
        for (var i = 0; i < this.element._queue.length; i++)
            this.element._queue[i] = fn(this.element._queue[i]);
    },

    /**
     * Adds an element to the queue. Can be an object of any kind.
     */
    add: function (element) {
        this.element._queue.push(element);

        if (this.element._queue.length === 1) this.open(0);
        else this._checkButtonStatus();
        this.options.$sizeQueueReport.text(this.element._queue.length);
    },

    set: function (element, index) {
        if (typeof index === 'undefined') index = this.element._current;
        if (index >= 0 && index < this.element._queue.length)
            this.element._queue[index] = element;
    },

    first: function () {
        if (this.element._current <= 0) return;
        this.open(0);
    },

    previous: function () {
        if (this.element._current > 0) this.open(this.element._current - 1);
    },

    next: function () {
        if (this.element._current < this.element._queue.length - 1) {
            return this.open(this.element._current + 1);
        }

        return false;
    },

    last: function () {
        if (this.element._current === this.element._queue.length - 1) return;
        this.open(this.element._queue.length - 1);
    },

    openCurrent: function () {
        if (this.element._current === -1) return;
        this.open(this.element._current);
    },

    /**
     * Remove the element in the given index. This method returns
     * the removed object.
     */
    removeIndex: function (index) {
        // If no elements, do nothing.
        if (this.element._queue.length === 0) return false;
        var weRemoveCurrent = index === this.element._current;

        // If index is out of bounds
        if (index < 0 || index >= this.element._queue.length) {
            return false;
        }

        var result = this.element._queue[index];

        // If index is before current, decrement current (because this one is now deleted)
        if (index <= this.element._current) {
            --this.element._current;
        }

        if (index < this.element._queue.length - 1) {
            // If current is not last item, set current as next item (i.e. dont change the counter)
            // Bubble and pop!
            var i = index;

            while (i + 1 < this.element._queue.length) {
                this.element._queue[i] = this.element._queue[i + 1];
                i++;
            }
        }

        this.element._queue.pop();

        if (weRemoveCurrent) {
            this.open(index);
        }

        if (this.element._queue.length === 0) {
            this.options.$currentQueueReport.text(0);
            this.options.whenEmptied();
        } else {
            this.options.$currentQueueReport.text(this.element._current + 1);
        }

        this.options.$sizeQueueReport.text(this.element._queue.length);
        this._checkButtonStatus();
        return result;
    },

    removeCurrent: function () {
        // If no elements, do nothing.
        if (this.element._queue.length === 0) return false;
        var result = this.getCurrent();

        // If currrent is last item, the new current must be the previous one
        if (this.element._current === this.element._queue.length - 1) {
            --this.element._current;
        } else {
            // If current is not last item, set current as next item (i.e. dont change the counter)
            // Bubble and pop!
            var i = this.element._current;
            while (i + 1 < this.element._queue.length && i >= 0) {
                this.element._queue[i] = this.element._queue[i + 1];
                i++;
            }
        }

        this.element._queue.pop();
        if (this.element._current >= 0) this.open(this.element._current);
        else {
            this.options.$currentQueueReport.text(0);
            this.options.whenEmptied();
        }

        this.options.$sizeQueueReport.text(this.element._queue.length);
        this._checkButtonStatus();
        this.options.removeCurrent(result, this.element._current);
        return result;
    },

    _create: function () {
        this.element._queue = [];
        this.element._current = 0;
        if (this.options.useButtonsForNavigation) this._setupButtons();
        this._checkButtonStatus();
        this.options.$currentQueueReport.text(0);
        this.options.$sizeQueueReport.text(0);
        if (this.options.onkeydown)
            $(document).on('keydown', this.options.onkeydown);
    },

    _setupButtons: function () {
        var o = this.options;
        o.$nextButton.click($.proxy(this.next, this));
        o.$lastButton.click($.proxy(this.last, this));
        o.$previousButton.click($.proxy(this.previous, this));
        o.$firstButton.click($.proxy(this.first, this));
    },

    _destroy: function () {
        var o = this.options;
        o.$nextButton.off('click');
        o.$lastButton.off('click');
        o.$previousButton.off('click');
        o.$firstButton.off('click');
        if (this.options.onkeydown)
            $(document).off('keydown', this.options.onkeydown);
    },
});
