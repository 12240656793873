import { PageData, PageLoader } from '@Component/PageLoader';
import { RouteData } from '@General/Router';
import { Team } from '@General/Team';

const ALLOWED_ROLES = [35, 126];

const Page = (): JSX.Element => (
    <PageLoader
        loader={async (): Promise<PageData> =>
            import('./CashCreditWrapper').then((m) => m.page)
        }
    />
);

export const route: RouteData = {
    component: Page,
    path: '/execute/CashCredit',
    title: () => getMessage('text_cashcredit_aprila'),
    team: Team.PBC,
    // To fully connect this page to the Tripletex framework, the page needs a documentationComponent id so that we can
    // link help articles to the page
    documentationComponent: 347,

    auth: (context) =>
        context.entitlements.some((e) => ALLOWED_ROLES.includes(e.id)),
};
