import * as React from 'react';

import { PageLoader } from '@Component/PageLoader';

import type { ReportAuthorizationProps } from './component';

export const ReportAuthorization: React.FC<ReportAuthorizationProps> = (
    props
) => (
    <PageLoader<ReportAuthorizationProps>
        loader={async () =>
            import('./ReportAuthorization.module').then((m) => m.page)
        }
        adjustWrapperDiv
        props={props}
    />
);
