import { notUndefined } from '@General/Helpers';
import { ModelAPIV1 } from '@General/types/Api';
import { ApiResultMapper } from '@General/Provider';
import * as React from 'react';
import { TlxListItem } from '@Component/DropDown/DropDown';
import { JSPDropdownFetcher } from '../JSPDropdownFetcher';
import { Option } from '../../types';

interface WarehouseAndLocationEntry extends ModelAPIV1 {
    readonly displayName: string;
    readonly warehouseLocation: string;
    readonly stockCount: string;
}

const WarehouseAndLocationMapper: ApiResultMapper<WarehouseAndLocationEntry> = (
    item
) => ({
    value: `${item.id}`,
    displayName: item.displayName,
    item,
});

const DesktopListItem: TlxListItem<WarehouseAndLocationEntry> = (props) => {
    if (!props.option.item) {
        return (
            <div className="txr-dropdown__row txr-dropdown__list-item">
                <div className="txr-dropdown__item-cell txr-dropdown__item-cell--default">
                    {props.option.displayName}
                </div>
            </div>
        );
    }
    return (
        <div className="txr-dropdown__row txr-dropdown__list-item">
            <div
                className="txr-dropdown__item-cell txr-dropdown__item-cell--description"
                title={props.option.item.displayName}
            >
                {props.option.item.displayName}
            </div>
            <div
                className="txr-dropdown__item-cell txr-dropdown__item-cell--name"
                title={props.option.item.warehouseLocation}
            >
                {props.option.item.warehouseLocation}
            </div>
            <div
                className="txr-dropdown__item-cell txr-dropdown__item-cell--name"
                title={props.option.item.stockCount}
            >
                {props.option.item.stockCount}
            </div>
        </div>
    );
};

const Headers: React.FC = () => (
    <div className="txr-dropdown__row">
        <div className="txr-dropdown__item-cell txr-dropdown__item-cell--header  txr-dropdown__item-cell--description">
            {getMessage('text_description')}
        </div>
        <div className="txr-dropdown__item-cell txr-dropdown__item-cell--header txr-dropdown__item-cell--name">
            {getMessage('text_location')}
        </div>
        <div className="txr-dropdown__item-cell txr-dropdown__item-cell--header txr-dropdown__item-cell--name">
            {getMessage('text_inventory2')}
        </div>
    </div>
);

/**
 * OBS! This is not the same as WarehouseLocation!
 */
class WarehouseAndLocationFetcher extends JSPDropdownFetcher<WarehouseAndLocationEntry> {
    asyncJsonrpcGetter: Function;

    constructor(scope: HTMLDivElement, queryAttributes: Record<string, any>) {
        super(scope, queryAttributes, WarehouseAndLocationMapper);

        this.asyncJsonrpcGetter = asyncrpc.WarehouseAndLocation.get;
    }

    getDesktopListItem(): TlxListItem<WarehouseAndLocationEntry> {
        return DesktopListItem;
    }

    getMarshallSpec(): { marshallSpec: string[] } {
        return window.marshallSpec(
            'id',
            'displayName',
            'warehouseLocation',
            'stockCount'
        );
    }

    getParams(): ObjectIndex {
        return {
            warehouseId: this.getQueryAttribute('warehouseElementId', -1),
            isActive: this.getQueryAttribute('isActive', 1),
            productId: this.getQueryAttribute('productElementId', -1),
        };
    }

    async fetchList(
        params: ObjectIndex
    ): Promise<Option<WarehouseAndLocationEntry>[]> {
        return new Promise((resolve, reject) => {
            try {
                jsonrpc.WarehouseAndLocation.searchForProductWarehouseTlxSelect(
                    (result: WarehouseAndLocationEntry[] | null) => {
                        const mapped = (result ?? [])
                            .filter(notUndefined)
                            .map(this.getMapper(params))
                            .filter(notUndefined);
                        resolve(mapped);
                    },
                    this.getMarshallSpec(),
                    window.loginCompanyId,
                    params.isActive,
                    parseInt(params.productId, 10),
                    parseInt(params.warehouseId, 10),
                    params.name
                );
            } catch (e) {
                defaultJsonRpcExceptionHandler(e);
                reject(e);
            }
        });
    }

    getHeaders() {
        return Headers;
    }
}

export const exports = {
    fetcher: WarehouseAndLocationFetcher,
    type: 'WarehouseAndLocation',
};
