import * as React from 'react';
import { PageLoader } from '@Component/PageLoader';
import { renderComponent } from '@General/renderComponent';
import { ProjectWizardModalExternalProps } from '@Page/ProjectWizard/types/types';
import { unmount } from '../../util/React18mount';

/**
 * The outside part of the bundle shear line.
 * The bundle split happens because of the <code>import('./SomePage')</code> call.
 * The PageLoader both sets up an error boundary (meaning a crash in the React code inside won't crash the page)
 * and handles showing a loader.
 *
 **
 * @see PageLoader
 * @see renderComponent
 */
const Page: React.FC = (props) => (
    <PageLoader<{}>
        loader={async () =>
            import('./ProjectWizardModal.module').then((m) => m.page)
        }
        props={props}
    />
);

export function renderProjectWizardModal(
    domId: string,
    props: ProjectWizardModalExternalProps
) {
    renderComponent(Page, domId, props);
}

export function unmountProjectWizardModal() {
    const component = document.getElementById(
        'projectWizardModal'
    ) as HTMLElement;
    unmount(component);
}
