import { ModelAPIV1 } from '@General/types/Api';
import { CreateButtonComponent, Option } from '../../types';
import { notUndefined } from '@General/Helpers';
import { JSPDropdownFetcher } from '../JSPDropdownFetcher';
import { ApiResultMapper } from '@General/Provider';
import * as React from 'react';
import { TlxListItem } from '@Component/DropDown/DropDown';
import { createCreateNewButton } from '@Component/DropDown/CreateNewButton/CreateNewButton';

interface ProductEntry extends ModelAPIV1 {
    readonly displayNumber: string;
    readonly name: string;
    readonly currencyCodeBlank: string;
    readonly nameAndNumber: string;
    readonly vendorName: string;
    readonly guiNetPriceCurrency: string;
    readonly guiMarkupNetPrice: string;
    readonly guiListPriceCurrency: string;
    readonly guiMarkupListPrice: string;
    readonly guiSalesPriceCurrency: string;
}

const productMapper: ApiResultMapper<ProductEntry> = (item) => ({
    value: item.id + '',
    displayName: item.nameAndNumber,
    secondary: item.vendorName,
    item,
});

class ProductFetcher extends JSPDropdownFetcher<ProductEntry> {
    asyncJsonrpcGetter: Function;

    constructor(scope: HTMLDivElement, queryAttributes: Record<string, any>) {
        super(scope, queryAttributes, productMapper);
        this.tlxSelectMaxRows = 200;
        this.asyncJsonrpcGetter = asyncrpc.Product.get;
    }

    getMarshallSpec() {
        const hideProductNetPrice = this.getQueryAttribute(
            'HideProductNetPrice',
            true
        );
        if (!hideProductNetPrice) {
            return window.marshallSpec(
                'id',
                'displayNumber',
                'name',
                'nameAndNumber',
                'vendorName',
                'currencyCodeBlank',
                'guiNetPriceCurrency',
                'guiMarkupNetPrice',
                'guiListPriceCurrency',
                'guiMarkupListPrice',
                'guiSalesPriceCurrency'
            );
        } else {
            return window.marshallSpec(
                'id',
                'displayNumber',
                'name',
                'nameAndNumber',
                'vendorName',
                'currencyCodeBlank',
                'guiListPriceCurrency',
                'guiMarkupListPrice',
                'guiSalesPriceCurrency'
            );
        }
    }

    getParams(): ObjectIndex {
        const vendorId = this.getQueryAttribute('vendorId', -1);
        let vendorElementId;
        if (vendorId === -1) {
            vendorElementId = this.getQueryAttribute('vendorElementId', -1);
        }
        return {
            departmentId: this.getQueryAttribute('departmentElementId', -1),
            vendorId: vendorElementId != undefined ? vendorElementId : vendorId,
            projectId: this.getQueryAttribute('projectElementId', -1),
            isExternal: this.getQueryAttribute('isExternal', -1),
            useProductNetPrice: this.getQueryAttribute(
                'useProductNetPriceElementId',
                -1
            ),
            isEnabledForWarehouse: this.getQueryAttribute(
                'isEnabledForWarehouse',
                -1
            ),
            queryId: this.getQueryAttribute('queryId', 1),
        };
    }

    async fetchList(params: ObjectIndex): Promise<Option<ProductEntry>[]> {
        return new Promise((resolve, reject) => {
            try {
                this.requestId = jsonrpc.Product.searchForProductsTlxSelect(
                    (result: ProductEntry[] | null) => {
                        const mapped = (result ?? [])
                            .filter(notUndefined)
                            .map(this.getMapper(params))
                            .filter(notUndefined);
                        resolve(mapped);
                    },
                    this.getMarshallSpec(),
                    window.loginCompanyId,
                    params.departmentId,
                    params.queryId,
                    1,
                    params.isExternal,
                    params.vendorId,
                    params.projectId,
                    params.useProductNetPrice,
                    params.isEnabledForWarehouse,
                    params.name || '',
                    this.tlxSelectMaxRows,
                    0
                );
            } catch (e) {
                defaultJsonRpcExceptionHandler(e);
                reject(e);
            }
        });
    }

    createCreateNewButton(
        setSelected: (id: number) => void,
        dropdownTrackingId?: string
    ): CreateButtonComponent | undefined {
        if (!isAuth.createProduct || moduleRackbeat) {
            return undefined;
        }
        // There was no checks on these in the original version as well
        const vendorId = this.getQueryAttribute('vendorElementId', '0');
        const url = `product?vendorId=${vendorId}&canUploadImage=false`;
        const textKey =
            vendorId === '0' ? 'text_new_product' : 'text_new_vendor_product';
        return createCreateNewButton(
            setSelected,
            textKey,
            url,
            dropdownTrackingId
                ? getLocaleMessage('en_GB', textKey) +
                      ' - ' +
                      dropdownTrackingId
                : '',
            undefined,
            'txr-page-dialog__backdrop--product'
        );
    }

    getHeaders() {
        const vatOn = this.getQueryAttribute('isVatOn', false);
        const queryId = this.getQueryAttribute('queryId', 1);
        const hideProductNetPrice = this.getQueryAttribute(
            'hideProductNetPrice',
            true
        );

        const Headers: React.FC = () => {
            return (
                <div className="txr-dropdown__row">
                    <div className="txr-dropdown__item-cell txr-dropdown__item-cell--header txr-dropdown__item-cell--productDisplayNumber">
                        {getMessage('text_number')}
                    </div>
                    <div className="txr-dropdown__item-cell txr-dropdown__item-cell--header txr-dropdown__item-cell--name">
                        {getMessage('text_name')}
                    </div>
                    <div className="txr-dropdown__item-cell txr-dropdown__item-cell--header txr-dropdown__item-cell--name">
                        {getMessage('text_supplier')}
                    </div>
                    <div className="txr-dropdown__item-cell txr-dropdown__item-cell--header txr-dropdown__item-cell--currencyCode">
                        {getMessage('th_currency')}
                    </div>

                    {queryId == 2 ? (
                        !hideProductNetPrice && (
                            <div className="txr-dropdown__item-cell txr-dropdown__item-cell--header txr-dropdown__item-cell--number">
                                {vatOn
                                    ? getMessage(
                                          'text_price_purchase_excl_vat_br'
                                      )
                                    : getMessage('text_price_purchase')}
                            </div>
                        )
                    ) : moduleNrf || moduleElectro ? (
                        <>
                            {!hideProductNetPrice && (
                                <>
                                    <div className="txr-dropdown__item-cell txr-dropdown__item-cell--header txr-dropdown__item-cell--number">
                                        {vatOn
                                            ? getMessage(
                                                  'text_net_price_excl_vat'
                                              )
                                            : getMessage('text_net_price')}
                                    </div>
                                    <div className="txr-dropdown__item-cell txr-dropdown__item-cell--header txr-dropdown__item-cell--number">
                                        {getMessage('text_markup_net_price')}
                                    </div>
                                </>
                            )}

                            <div className="txr-dropdown__item-cell txr-dropdown__item-cell--header txr-dropdown__item-cell--number">
                                {vatOn
                                    ? getMessage('text_list_price_excl_vat')
                                    : getMessage('text_list_price')}
                            </div>

                            <div className="txr-dropdown__item-cell txr-dropdown__item-cell--header txr-dropdown__item-cell--number">
                                {getMessage('text_markup_list_price')}
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="txr-dropdown__item-cell txr-dropdown__item-cell--header txr-dropdown__item-cell--number">
                                {getMessage('text_sales_price_excl_vat')}
                            </div>
                        </>
                    )}
                </div>
            );
        };
        return Headers;
    }

    getDesktopListItem(): TlxListItem<ProductEntry> {
        const vatOn = this.getQueryAttribute('isVatOn', false);
        const queryId = this.getQueryAttribute('queryId', 1);
        const hideProductNetPrice = this.getQueryAttribute(
            'hideProductNetPrice',
            true
        );

        const DesktopListItem: TlxListItem<ProductEntry> = (props) => {
            if (!props.option.item) {
                return (
                    <div className="txr-dropdown__row txr-dropdown__list-item">
                        <div className="txr-dropdown__item-cell txr-dropdown__item-cell--default">
                            {props.option.displayName}
                        </div>
                    </div>
                );
            }
            return (
                <div className="txr-dropdown__row txr-dropdown__list-item">
                    <div className="txr-dropdown__item-cell txr-dropdown__item-cell--productDisplayNumber">
                        {props.option.item.displayNumber}
                    </div>
                    <div className="txr-dropdown__item-cell txr-dropdown__item-cell--name">
                        {props.option.item.name}
                    </div>
                    <div className="txr-dropdown__item-cell txr-dropdown__item-cell--name">
                        {props.option.item.vendorName}
                    </div>
                    <div className="txr-dropdown__item-cell txr-dropdown__item-cell--currencyCode">
                        {props.option.item.currencyCodeBlank}
                    </div>

                    {queryId == 2 ? (
                        !hideProductNetPrice && (
                            <div className="txr-dropdown__item-cell txr-dropdown__item-cell--number">
                                {props.option.item.guiNetPriceCurrency}
                            </div>
                        )
                    ) : moduleNrf || moduleElectro ? (
                        <>
                            {!hideProductNetPrice && (
                                <>
                                    <div className="txr-dropdown__item-cell txr-dropdown__item-cell--number">
                                        {props.option.item.guiNetPriceCurrency}
                                    </div>
                                    <div className="txr-dropdown__item-cell txr-dropdown__item-cell--number">
                                        {props.option.item.guiMarkupNetPrice}
                                    </div>
                                </>
                            )}

                            <div className="txr-dropdown__item-cell txr-dropdown__item-cell--number">
                                {props.option.item.guiListPriceCurrency}
                            </div>

                            <div className="txr-dropdown__item-cell txr-dropdown__item-cell--number">
                                {props.option.item.guiMarkupListPrice}
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="txr-dropdown__item-cell txr-dropdown__item-cell--number">
                                {props.option.item.guiSalesPriceCurrency}
                            </div>
                        </>
                    )}
                </div>
            );
        };
        return DesktopListItem;
    }
}

export const exports = {
    fetcher: ProductFetcher,
    type: 'Product',
    link: (id: number, contextId: number) =>
        `/execute/productMenu?productId=${id}&contextId=${contextId}`,
};
