import { Entitlement } from '@General/types/General';
import { EntitlementLookup } from '@General/constants';

let entitlements: Entitlement[] | undefined = undefined;

export function getEntitlements(): Entitlement[] {
    if (entitlements === undefined) {
        const data = document.getElementById(
            'redux-data-preload-Entitlement'
        )?.textContent;
        const json: any[] = JSON.parse(data ?? '[]');
        entitlements = json.map((item: any) => ({
            id: item.entitlementId,
            name: EntitlementLookup[item.entitlementId],
        })) as Entitlement[];
    }
    return entitlements;
}
