import { renderComponent } from '@General/renderComponent';

import { UnexpectedError, UnexpectedErrorProps } from './UnexpectedError';

export function renderUnexpectedError(
    domId: string,
    props?: UnexpectedErrorProps
) {
    renderComponent(UnexpectedError, domId, props);
}
