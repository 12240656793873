import * as React from 'react';

import {
    PageError,
    PageLoadingErrorProps,
} from '@Component/PageLoader/PageError';

export type UnexpectedErrorProps = PageLoadingErrorProps;

export const UnexpectedError: React.FunctionComponent<UnexpectedErrorProps> = (
    props
) => <PageError {...props} />;
