import { Customer } from '@Page/ProjectInvoicingDialog/types/InvoicingDialog.type';
import { SendTypes } from '@Page/ProjectInvoicingDialog/component/InvoicingForm/SendMethod';

export const requiredPositiveNotZeroInt = (value: string | number) => {
    value = Number.parseInt(value);

    return value && value > 0 ? undefined : getMessage('text_required_field');
};

export const isValidBankAccount = (value: string | number) => {
    if (value == undefined) {
        return undefined;
    }

    if (value.toString().length != 11) {
        return getMessage('validation_bank_account_number_digits', '11');
    }
    if (!isValidModula11(value, 11)) {
        return getMessage('validation_bank_account_number_invalid');
    }

    return undefined;
};

export const validateOrganizationNumber = (value: string | number) => {
    if (value == undefined) {
        return undefined;
    }
    value = value.toString();
    if (
        value.substr(value.length - 3, value.length).toLocaleLowerCase() ===
        'mva'
    ) {
        value = value.substr(0, value.length - 3);
    }

    if (value.length != 9 && value.length !== 10) {
        return getMessage('validation_org_number_digits', '9');
    }

    if (value.length === 9) {
        if (!isValidModula11(value, 9)) {
            return getMessage('validation_illegal_org_number');
        }
    } else if (value.length === 10) {
        if (!isValidModula10(value, 10)) {
            return getMessage('validation_illegal_org_number');
        }
    }

    return undefined;
};

export const validateIBAN = (value: string | number) => {
    if (value == undefined || value === '') {
        return undefined;
    }
    value = value.toString();

    const country = value.substring(0, 2);
    const controlDigits = value.substr(2, 2);
    const accountNumber = value.substring(4);

    if (
        country.match(/^[a-zA-Z]+$/) === null ||
        controlDigits.match(/^\d+$/) === null ||
        !hasValidIbanChecksum(accountNumber + country + controlDigits)
    ) {
        return getMessage('validation_invalid_iban_with_faq_markdown');
    }

    if (country == 'NO') {
        const bankAccountValidationMessages = isValidBankAccount(accountNumber);
        if (bankAccountValidationMessages != undefined) {
            return getMessage('validation_invalid_iban_with_faq_markdown');
        }
    }

    return undefined;
};

function hasValidIbanChecksum(value: string) {
    const numericValue = value
        // Make it into an array of chars
        .split('')
        // Convert letters to numbers so that we chan create checksum
        .map((char) => {
            const isLetter = char.match(/[a-z]/i);
            if (isLetter) {
                const aAsASCII = 'A'.charCodeAt(0);
                const charAsASCII: number = char.toUpperCase().charCodeAt(0);
                return (charAsASCII - aAsASCII + 10).toString();
            }

            return char;
        })
        // Back to normal string
        .reduce((result, value) => result + value);

    return modulo(numericValue, 97) === 1;
}
function modulo(divident: string, divisor: number) {
    const partLength = 10;

    while (divident.length > partLength) {
        const part = divident.substring(0, partLength);
        divident = (Number(part) % divisor) + divident.substring(partLength);
    }

    return Number(divident) % divisor;
}

export const validateSWIFT = (value: string | number, allowEmpty: boolean) => {
    if (value == undefined || value === '') {
        if (!allowEmpty) {
            return getMessage('validation_missing');
        }
        return undefined;
    }
    value = value.toString();

    if (value.length != 8 && value.length != 11) {
        return getMessage('validation_invalid_swift_with_faq_markdown');
    }

    if (value.substr(0, 6).match(/^[a-zA-Z]+$/) === null) {
        return getMessage('validation_invalid_swift_with_faq_markdown');
    }

    return undefined;
};

export function modulo11(number: string) {
    let sum = 0;
    let weighting = 2;

    for (let i = number.length - 1; i >= 0; i--) {
        const digit: number = Number.parseInt(number.charAt(i));
        const product = weighting * digit;
        sum += product;
        if (weighting == 7) {
            weighting = 2;
        } else {
            weighting++;
        }
    }

    const rest = sum % 11;

    if (rest == 0) {
        return 0;
    } else {
        return 11 - rest;
    }
}

export function isValidModula11(value: string | number, length: number) {
    const number = value + '';

    const digit = modulo11(number.substring(0, number.length - 1));
    const lastChar = number.charAt(number.length - 1);
    const controlDigit =
        lastChar == '-' ? length - 1 : Number.parseInt(lastChar);

    return digit == controlDigit && number.length == length;
}

export function isValidModula10(value: string | number, length: number) {
    const number = value + '';

    return mod10(number.substring(0, number.length - 1), length) === number;
}

export const mod10 = (base: string, length: number): string => {
    const step1: string = luhn_step1(base, length);
    const step2: number[] = luhn_step2(step1);
    const step3: number[] = luhn_step3_mod10(step2);
    const step4: number = luhn_step4(step3);
    const step5: string = luhn_step5_mod10(step4);
    return step1.concat(step5);
};

export const luhn_step1 = (base: string, length: number): string => {
    let ret = base;
    const iterations = length - ret.length - 1;
    for (let i = 0; i < iterations; i++) {
        ret = ret.concat(Math.floor(Math.random() * 10).toString());
    }
    return ret.replace(/[^0-9]+/g, '');
};

export const luhn_step2 = (base: string): number[] => {
    return base
        .split('')
        .map((i) => +i)
        .reverse();
};

export const luhn_step3_mod10 = (digits: number[]): number[] => {
    const step3: number[] = [];
    let shouldDouble = true;
    for (let i = 0; i < digits.length; i++) {
        if (shouldDouble) {
            const doubled = digits[i] * 2;
            if (doubled > 9) {
                // We convert the number to a string to get the digits, because JS :)
                const doubledString = doubled.toString();
                const summed =
                    parseInt(doubledString[0]) + parseInt(doubledString[1]);
                step3.push(summed);
            } else {
                step3.push(doubled);
            }
        } else {
            step3.push(digits[i]);
        }
        shouldDouble = !shouldDouble;
    }
    return step3;
};

export const luhn_step4 = (digits: number[]): number => {
    return digits.reduce((a, b) => a + b, 0);
};

export const luhn_step5_mod10 = (n: number): string => {
    const overflow = n % 10;
    const fix = 10 - overflow;
    if (fix == 10) {
        return '0';
    }
    return fix.toString();
};

export function getSendTypeErrors(customer: Customer, sendType: string) {
    if (sendType === SendTypes.EHF) {
        if (!customer.organizationNumber) {
            return getMessage('validation_ehf_missing_orgnumber');
        }
        return '';
    }
    return '';
}
