import {
    DropdownModel,
    PurchaseOrderDTO,
    PurchaseOrderStatus,
} from '@Page/LogisticsV2/pages/PurchaseOrder/overview/types';
import { MapModel } from '@Page/LogisticsV2/types/returnTypes';
import { getLocale, SupportedLocale } from '@tlx/atlas';
import { ChangeEvent } from 'react';
import { columnToField } from './overview/DisplayOptions/utils';
import { PageUrl } from '@Page/LogisticsV2/utils/enums';

export function getLink(purchaseOrder: PurchaseOrderDTO) {
    if (
        purchaseOrder.status === PurchaseOrderStatus.DEVIATION_CONFIRMED ||
        purchaseOrder.status === PurchaseOrderStatus.DEVIATION_OPEN ||
        purchaseOrder.status === PurchaseOrderStatus.DEVIATION_DETECTED
    ) {
        return addContextId(
            `/execute/deviation?orderOutId=${purchaseOrder.id}`
        );
    } else {
        if (
            purchaseOrder.status === PurchaseOrderStatus.RECEIVING ||
            purchaseOrder.supplier === null
        ) {
            return addContextId(
                `/execute/goodsReceipt?orderOutId=${purchaseOrder.id}`
            );
        } else {
            return addContextId(
                `/execute/purchaseOrderMenu?orderOutId=${purchaseOrder.id}`
            );
        }
    }
}

export function getPurchaseOrdersFields(columns: string[]) {
    const fields = columns
        .filter((column) => column in columnToField)
        .map((column) => columnToField[column]);

    return 'id, number, receivedDate, status, ' + fields.join(', ');
}

export function mapDropdownSelectedOptionsToArray(
    event: ChangeEvent<HTMLSelectElement>
) {
    const values: MapModel<DropdownModel> = {};
    Array.from(event.target.selectedOptions).forEach(
        (option) =>
            (values[option.value] = {
                id: option.value,
                displayName: option.label,
            })
    );
    return values;
}

export const defaultFormatOptions: Intl.NumberFormatOptions = {
    maximumFractionDigits: 2,
};
export function formatNumber(
    value: number,
    locale: SupportedLocale = getLocale(),
    formatOptions = defaultFormatOptions
): string {
    return new Intl.NumberFormat(locale, formatOptions).format(value);
}

export function updateUrl(): void {
    const customerId =
        document.querySelector<HTMLInputElement>(
            '#customerIdForProductOverview'
        )?.value ?? null;
    const currentUrl = window.location.href;
    const newUrl = currentUrl.includes(PageUrl.PURCHASE_ORDER)
        ? PageUrl.PURCHASE_ORDER
        : `${PageUrl.CUSTOMER_MENU}?customerId=${customerId}#purchaseOrders`;

    window.history.pushState({}, '', addContextId(newUrl));
}
