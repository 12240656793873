import useSWR from 'swr';
import { FetchState, ResponseWrapper } from './types';
import { useFetcher } from './useFetcher';

/**
 * Fetches an item from any API endpoint that returns a ResponseWrapper<T>.
 *
 * @deprecated Use useSWR directly alongside defaultFetcher and useFetchState instead
 * @see https://storybook.tripletex.dev/?path=/docs/atlas-data-fetching
 */
export function useFetchSingle<TData, TError = any>(
    url: string | null
): FetchState<TData, TError> {
    const fetcher = useFetcher<ResponseWrapper<TData>>();
    const { data, error } = useSWR<ResponseWrapper<TData>, TError>(url, {
        fetcher,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
    });

    return {
        data: data?.value,
        error,
        isLoading: url !== null && !data && !error,
    };
}
