import * as React from 'react';
import { ButtonIcon } from '@Component/ButtonIcon';
import { PageDialog } from '@Component/PageDialog';
import classNames from 'classnames';
import { renderComponent } from '@General/renderComponent';
import { CreateNewButtonClickApi } from '@Component/DropDown/types';
import { Responsive } from '@Component/Responsive';
import { Button } from '@Component/Button';
import { unmount } from '../../../util/React18mount';

type CommonProps = {
    tooltipText: string;
    dialogClassName?: string;
    url: string;
    onSuccess: (id: number) => void;
};

export type CreateNewButtonProps = {
    className?: string;
    dialogClassName?: string;
    onClick: React.MouseEventHandler<HTMLButtonElement>;
    trackingId?: string;
    customEvent?: () => void;
} & CommonProps;

/**
 * renderDialog loads the jsp-form on the corresponding url.
 * The url must be pointing to the form in create state: in most cases without id
 * The dialogName must be unique for all dialogs.
 */

let counter = 1;
export const renderDialog = (dialogProps: CommonProps): void => {
    const body = document.body;
    const container = document.createElement('div');
    body.append(container);
    const Dialog = () => (
        <PageDialog
            titleText={dialogProps.tooltipText}
            dialogClassname={dialogProps.dialogClassName}
            dialogIdentifier={`createDialog-${counter++}-page-dialog`}
            url={dialogProps.url}
            open={true}
            onSuccess={dialogProps.onSuccess}
            close={() => {
                if (body.contains(container)) {
                    unmount(container);
                    container.remove();
                }
            }}
        />
    );
    renderComponent(Dialog, container);
};

/**
 * This button renders an icon, when clicked it renders the dialog. It will also trigger the onClick function passed as props.
 */
export const CreateNewButton = React.forwardRef(
    (props: CreateNewButtonProps, ref: React.Ref<HTMLButtonElement>) => {
        const { tooltipText, className, onClick, trackingId, customEvent } =
            props;
        return (
            <>
                <Responsive mobile>
                    <ButtonIcon
                        toolTip={tooltipText}
                        className={classNames(
                            'trx-create-new-dialog-button',
                            className
                        )}
                        icon="add"
                        onClick={(e) => {
                            if (customEvent != undefined) {
                                customEvent();
                            } else {
                                renderDialog(props);
                            }
                            onClick(e);
                        }}
                        ref={ref}
                    />
                </Responsive>
                <Responsive tablet desktop>
                    <Button
                        className={classNames(
                            'trx-create-new-dialog-button',
                            className
                        )}
                        onClick={(e) => {
                            if (customEvent != undefined) {
                                customEvent();
                            } else {
                                renderDialog(props);
                            }
                            onClick(e);
                        }}
                        ref={ref}
                        trackingId={trackingId}
                    >
                        {tooltipText}
                    </Button>
                </Responsive>
            </>
        );
    }
);

/**
 * This defines the button users can click in the dropdown to create a new object of the same kind
 * of what you can select in the dropdown. This new object is also automatically selected.
 *
 * @param setSelected is called when the creation of the new object is done, with the id of the new object
 * @param labelTextKey is both used as header for dialog and tooltip for the new-button
 * @param url the page loaded in the dialog for creating the new object
 * @param trackingId will be the button's data-trackingid
 * @param customEvent optional click event function that will be called instead of the default call to renderDialog
 * @param dialogClassName will be the dialog's className
 */
export function createCreateNewButton(
    setSelected: (id: number) => void,
    labelTextKey: string,
    url: string,
    trackingId?: string,
    customEvent?: () => void,
    dialogClassName?: string
): React.ForwardRefExoticComponent<{ closeDropdown: () => void }> {
    return createCreateNewButtonWithLabelText(
        setSelected,
        getMessage(labelTextKey),
        url,
        trackingId,
        customEvent,
        dialogClassName
    );
}

export function createCreateNewButtonWithLabelText(
    setSelected: (id: number) => void,
    labelText: string,
    url: string,
    trackingId?: string,
    customEvent?: () => void,
    dialogClassName?: string
): React.ForwardRefExoticComponent<{ closeDropdown: () => void }> {
    return React.forwardRef<HTMLButtonElement, CreateNewButtonClickApi>(
        (props, ref) => (
            <CreateNewButton
                tooltipText={labelText}
                onClick={() => {
                    props.closeDropdown();
                }}
                url={url}
                onSuccess={setSelected}
                ref={ref}
                trackingId={trackingId}
                customEvent={customEvent}
                dialogClassName={dialogClassName}
            />
        )
    );
}
