import { ApprovedIcon } from '@tlx/atlas';
import { RackbeatProductNewsDTO } from '@Page/LogisticsV2/pages/rackbeatproductnews/types';

export const RackbeatNewsRow = ({
    newsObject,
}: {
    newsObject: RackbeatProductNewsDTO;
}) => {
    return (
        <div className="atl-my-16">
            <div className="atl-flex atl-py-8 atl-items-center">
                <div className="atl-rounded-full rackbeat-news-row-icon atl-flex atl-justify-center atl-items-center">
                    <ApprovedIcon className="atl-rounded-full" />
                </div>
                <div className="atl-font-bold atl-pl-8">{newsObject.title}</div>
            </div>
            <div className="atl-mx-32">
                <div className="atl-flex atl-py-8 atl-mx-8">
                    <div className="">{newsObject.publish_date}</div>
                    <div className="rackbeat-row-items atl-mx-8"></div>
                    <div className="">{newsObject.excerpt}</div>
                </div>
            </div>
        </div>
    );
};
