import React from 'react';

export function UserFeedbackConfirmation() {
    return (
        <div>
            <div className="feedbackButton-feedbackFinished atl-bg-white atl-border-grey-40 atl-justify-center atl-items-center atl-flex">
                <div className="atl-flex atl-flex-col atl-items-center ">
                    <img src="/resources/imgs/plane-sent.svg" />
                    <div className="atl-font-bold atl-text-xl">
                        {getMessage('text_invoice_status_sent')}
                    </div>
                    <div className="atl-text-xl">
                        {getMessage('text_wootric_thankyou_header')}
                    </div>
                </div>
            </div>
        </div>
    );
}
