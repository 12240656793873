export const JSONRPC_VALIDATION_MESSAGES = 'JSONRPC_VALIDATION_MESSAGES';
export const JSONRPC_FORM_POST_SUCCSESS = 'JSONRPC_FORM_POST_SUCCSESS';

export enum EntitlementLookup {
    ROLE_ADMINISTRATOR = 1,
    ROLE_SYSTEM_ADMINISTRATOR = 2,
    AUTH_HOURS_DEPARTMENT = 5,
    AUTH_HOURS_COMPANY = 6,
    AUTH_TRAVELREPORTS_DEPARTMENT = 7,
    AUTH_PROJECT_MANAGER_DEPARTMENT = 8,
    AUTH_PROJECT_MANAGER = 10,
    AUTH_PROJECT_MANAGER_COMPANY = 11,
    AUTH_INVOICING = 14,
    AUTH_CUSTOMER_ADMIN = 15,
    AUTH_HOUR_STATISTICS_COMPANY = 17,
    AUTH_DEPARTMENT_REPORT = 20,
    AUTH_TRAVELREPORTS_COMPANY = 25,
    AUTH_COMPANY_ADMIN = 21,
    AUTH_INBOX_ARCHIVE_ALL_EMPLOYEES = 26,
    AUTH_PRODUCT_ADMIN = 27,
    AUTH_BUDGET = 29,
    AUTH_VOUCHER_EXPORT = 34,
    AUTH_ALL_VOUCHERS = 35,
    AUTH_OWN_HOURS_AMOUNT = 37,
    AUTH_HOURS_SAME_CUSTOMER = 40,
    AUTH_INBOX_VOUCHER = 42,
    AUTH_CUSTOMER_INFO = 43,
    AUTH_DIRECT_REMIT_ADMIN = 44,
    AUTH_CREATE_PROJECT = 45,
    AUTH_HOURLIST = 46,
    AUTH_TRAVELREPORT = 47,
    AUTH_HOURLIST_SETTINGS = 48,
    AUTH_DIRECT_REMIT_LIGHT = 49,
    AUTH_ACCOUNTING_SUPPORT = 50,
    AUTH_RESELLER_REPORT = 51,
    AUTH_PROJECT_CONTROL_SCHEMAS = 52,
    AUTH_HOUR_STATISTICS_DEPARTMENT = 53,
    AUTH_HOUR_STATISTICS_EMPLOYEE = 54,
    AUTH_PROJECT_EXTRACOSTS = 55,
    AUTH_PRODUCT_NET_PRICE = 56,
    AUTH_ARCHIVE_READ = 57,
    AUTH_ARCHIVE_WRITE = 58,
    AUTH_ARCHIVE_ADMIN = 59,
    AUTH_HOLYDAY_PLAN = 60,
    AUTH_EMPLOYEE_INFO = 61,
    AUTH_CREATE_TASK = 62,
    AUTH_CREATE_NOTE = 63,
    AUTH_CREATE_NOTE_TEMPLATE = 64,
    AUTH_OFFER_ADMIN = 65,
    AUTH_READ_ONLY = 66,
    AUTH_TASK_ADMIN = 67,
    AUTH_ORDER_ADMIN = 68,
    AUTH_CREATE_OFFER = 69,
    AUTH_CREATE_ORDER = 70,
    AUTH_CLIENT_ACCOUNT_ADMIN = 71,
    AUTH_EMAIL_ACCOUNT_ADMIN = 72,
    AUTH_INCOMPLETE_VOUCHERS = 76,
    AUTH_INCOMING_INVOICE = 77,
    AUTH_BANK_RECONCILIATION = 78,
    AUTH_VAT_REPORT = 79,
    AUTH_SICKNESS_REIMBURSEMENT = 81,
    AUTH_WITHDRAWAL_MARKETING = 82,
    AUTH_REGISTER_INCOME = 83,
    AUTH_ADVANCED_VOUCHER = 84,
    AUTH_VOUCHER_IMPORT = 85,
    AUTH_PRODUCT_INVOICE = 86,
    AUTH_TRIPLETEX_SUPPORT = 87,
    AUTH_ALL_TASKS = 88,
    AUTH_TRIPLETEX_HELP_EDIT = 91,
    AUTH_PROJECT_INFO = 92,
    AUTH_CREATE_CUSTOMER = 93,
    AUTH_COMPANY_ATTESTOR = 94,
    AUTH_FACTORING_EXPORT = 96,
    AUTH_CRM_ADMIN = 97,
    AUTH_TRIPLETEX_SUPORT_CUSTOMER_LOGIN = 98,
    AUTH_LOGIN = 99,
    AUTH_VIEW_CLIENT_IN_MENU = 100,
    AUTH_MANAGE_BANK_ACCOUNT_NUMBERS = 101,
    AUTH_CRM = 103,
    AUTH_CRM_COORDINATOR = 104,
    AUTH_COMPANY_ACCOUNTING_REPORTS = 105,
    AUTH_DEPARTMENT_ACCOUNTING_REPORTS = 106,
    AUTH_ACCOUNTING_SETTINGS = 107,
    AUTH_COMPANY_RESULT_BUDGET = 108,
    AUTH_DEPARTMENT_RESULT_BUDGET = 109,
    AUTH_COMPANY_CUSTOMER_ACCOUNTING_REPORTS = 110,
    AUTH_DEPARTMENT_CUSTOMER_ACCOUNTING_REPORTS = 111,
    AUTH_COMPANY_VENDOR_ACCOUNTING_REPORTS = 112,
    AUTH_DEPARTMENT_VENDOR_ACCOUNTING_REPORTS = 113,
    AUTH_COMPANY_EMPLOYEE_ACCOUNTING_REPORTS = 114,
    AUTH_DEPARTMENT_EMPLOYEE_ACCOUNTING_REPORTS = 115,
    AUTH_WAGE_ADMIN_SETTINGS = 116,
    AUTH_COMPANY_WAGE_ADMIN = 117,
    AUTH_DEPARTMENT_WAGE_ADMIN = 118,
    AUTH_COMPANY_EMPLOYEE_ADMIN = 119,
    AUTH_DEPARTMENT_EMPLOYEE_ADMIN = 120,
    AUTH_TRAVEL_EXPENCE_ADMIN_SETTINGS = 121,
    AUTH_TRIPLETEX_SUPPORT_DIVERGENT_ACCOUNTING_YEAR = 122,
    AUTH_CUSTOMS_DECLARATION = 123,
    AUTH_TRIPLETEX_TECHNICAL_SUPPORT = 124,
    AUTH_TRIPLETEX_API_CONSUMER_ADMIN = 125,
    AUTH_ACCOUNT_ADMINISTRATOR = 126,
    AUTH_CREATE_TRUSTEE = 128,
    AUTH_PROJECT_ADMIN_SETTINGS = 133,
    AUTH_PURCHASING_MANAGER = 142,
    AUTH_DEPARTMENT_ASSET_ACCOUNTING_REPORTS = 145,
    AUTH_COMPANY_ASSET_ACCOUNTING_REPORTS = 146,
    REPORT_ADMINISTRATOR = 148,
    REPORT_AUTHOR = 149,
    AUTH_DIRECT_REMIT_ADMIN_ZTL = 150,
    YEAR_END_REPORT_ADMINISTRATOR = 151,
    AUTH_COMPANY_REPRESENTATIVE_ONLY = 154,
    GLOBAL_REPORT_ADMINISTRATOR = 155,
    AUTH_TRIPLETEX_SUPPORT_INVOICE_MANAGER = 156,
}

/**
 * From VatType.java
 */
export enum VAT_QUERY {
    OUTGOING = 1,
    OUTGOING_INC_NONE = 2,
    PROJECT = 3,
    VOUCHER_INC_NONE = 4,
    INCOMING = 5,
    VOUCHER = 6,
    RELATIVE = 7,
    LEDGER = 8,
    INCOMING_OUTGOING_NONE = 9,
    CUSTOM_DECLARATION = 10,
    GLOBAL_BY_COUNTRY = 11,
    INCOMING_INVOICE = 12,
    PRODUCT_INVOICE = 13,
}

/**
 * From Account.java
 */
export enum ACCOUNT_SUBTYPE {
    // Driftsinntekt
    RUNNING_INCOME = 3000,
    // Driftskostnad
    RUNNING_EXPENSE = 4000,
    // Kapitalinntekt
    CAPITAL_INCOME = 8000,
    // Kapitalkostnad
    CAPITAL_COST = 8100,
    // Skattekostnad ordinært resultat
    TAX_COST_NET_INCOME = 8300,
    // Ekstraordinær inntekt
    EXTRAORDINARY_INCOME = 8400,
    // Ekstraordinær inntekt
    EXTRAORDINARY_COST = 8500,
    // Ekstraordinær inntekt
    TAX_EXTRAORDINARY_RESULT = 8600,
    // Årsresultat
    RESULT_YEAR = 8700,
    // Overføringer og disp.
    FORWARD = 8800,
}
