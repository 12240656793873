import { ModelAPIV1 } from '@General/types/Api';
import { Option } from '../../types';
import { JSPDropdownFetcher } from '../JSPDropdownFetcher';
import { ApiResultMapperNoUndefinedResult } from '@General/Provider';

interface WageCodeEntry extends ModelAPIV1 {
    readonly payStatementCodeCode: string;
    readonly nameAndNumber: string;
}

const wageCodeMapper: ApiResultMapperNoUndefinedResult<WageCodeEntry> = (
    item
) => ({
    value: item.id + '',
    displayName: item.nameAndNumber,
    secondary: item.payStatementCodeCode,
    item,
});

class WageCodeFetcher extends JSPDropdownFetcher<WageCodeEntry> {
    asyncJsonrpcGetter: Function;

    constructor(scope: HTMLDivElement, queryAttributes: Record<string, any>) {
        super(scope, queryAttributes, wageCodeMapper);
        this.asyncJsonrpcGetter = asyncrpc.WageCode.get;
    }

    getMarshallSpec() {
        return window.marshallSpec(
            'id',
            'nameAndNumber',
            'payStatementCodeCode'
        );
    }

    getParams(): ObjectIndex {
        return {
            queryId: this.getQueryAttribute('queryId', -1),
        };
    }

    async fetchList(params: ObjectIndex): Promise<Option<WageCodeEntry>[]> {
        return new Promise((resolve, reject) => {
            try {
                jsonrpc.WageCode.searchForWageCodes(
                    (result: WageCodeEntry[] | null) =>
                        resolve((result ?? []).map(wageCodeMapper)),
                    this.getMarshallSpec(),
                    window.loginCompanyId,
                    params.queryId,
                    params.name || '',
                    this.tlxSelectMaxRows,
                    0
                );
            } catch (e) {
                defaultJsonRpcExceptionHandler(e);
                reject(e);
            }
        });
    }
}

export const exports = {
    fetcher: WageCodeFetcher,
    type: 'WageCode',
};
