import jQuery from 'jquery';
const $ = jQuery;

import { tlxUrl } from './url';
import { utils } from './utils';
import { bindEventsInsideScope } from '../o-common';

/**
 * Place initialization code for ajax loaded widgets inside window.widget.init[widgetname]
 * where widgetname is identical to what the java Widget.widgetname() returns.
 *
 * Use window.widget.loadWidget to ajax-load a widget.
 *
 *
 * @author bruce
 * @date Sep 16, 2015
 */
(function () {
    const widget = (window.widget = window.widget || {});
    widget.init = widget.init || {};

    widget.loadWidget = function loadWidget($widget, options) {
        let url = (options && options.url) || $widget.data('url');

        const scopeId = utils.widgetIdCreator();
        const $ajaxContent = $widget.find('.widgetAjaxContent');
        $widget.find('h1 .insertInHeader').remove();
        $ajaxContent
            .empty()
            .attr('id', scopeId)
            .tlxLoader({
                showOverlay: false,
                styleClass: 'blue',
                fader: true,
            })
            .tlxLoader('start');

        url = tlxUrl.addUrlParameter(url, 'contextId', window.contextId);
        url = tlxUrl.addUrlParameter(url, 'scope', scopeId);

        url = tlxUrl.addUrlParameters(
            url,
            $('.filter').findFormInput().tlxSerialize()
        );

        $ajaxContent.load(url, function (response, status, jqXHR) {
            if ($ajaxContent.closest('body').length === 0) {
                // If widget is detached from DOM, don't do anything on load.
                return;
            }
            $ajaxContent.tlxLoader('destroy');
            if (jqXHR.status === 200) {
                bindEventsInsideScope($ajaxContent);
            }

            const widgetname = $widget.data('widgetname');

            if (widgetname && window.widget.init[widgetname]) {
                window.widget.init[widgetname]($widget);
            }

            // If you want some fix stuff inside the header,
            // insert it in a div with class name "insertInHeader"
            $ajaxContent.find('.insertInHeader').appendTo($widget.find('h1'));
            $ajaxContent
                .removeClass('lazyLoad--inProgress ')
                .addClass('lazyLoad--done');
        });
    };
})();
