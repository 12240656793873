import { JSPDropdownFetcher } from '@Component/DropDown/JSPDropdown/JSPDropdownFetcher';
import { ModelAPIV1 } from '@General/types/Api';
import { MappedResponse } from '@General/Provider';
import { notUndefined } from '@General/Helpers';

interface SelectedStatusEntry extends ModelAPIV1 {
    readonly code: number;
    readonly displayName: string;
}

function mapper(input: SelectedStatusEntry) {
    return {
        ...input,
        value: input.code,
        displayName: input.displayName,
        item: input,
    };
}

class RemitsSelectedStatusFetcher extends JSPDropdownFetcher<SelectedStatusEntry> {
    asyncJsonrpcGetter: Function;

    constructor(scope: HTMLDivElement, queryAttributes: Record<string, any>) {
        super(scope, queryAttributes, mapper);

        this.asyncJsonrpcGetter = asyncrpc.ListRemitsForm.getRemitStatus;
    }

    fetchList(
        params: ObjectIndex
    ): Promise<MappedResponse<SelectedStatusEntry>> {
        return new Promise((resolve, reject) => {
            try {
                jsonrpc.ListRemitsForm.getPossibleStatuses(
                    (result: SelectedStatusEntry[] | null) => {
                        const mapped = (result ?? [])
                            .filter(notUndefined)
                            .map(this.getMapper(params))
                            .filter(notUndefined);
                        resolve(mapped);
                    },
                    this.getMarshallSpec()
                );
            } catch (e) {
                defaultJsonRpcExceptionHandler(e);
                reject(e);
            }
        });
    }

    getMarshallSpec(): { marshallSpec: string[] } {
        return window.marshallSpec('code', 'displayName');
    }

    getParams(): ObjectIndex {
        return {
            useNets: this.getQueryAttribute('useNets', false),
        };
    }

    /**
     * There are some cases where the customers added to favourite the payments page and the selectedStatus of 0 was valid then.
     * Now the default value is -1 and 0 will cause issues. Because of this, the value 0 is allowed, nothing changes in UI.
     * It will be set to -1 in URL after the validations are done.
     *
     * @author Andrei Gavra
     * @date 26/07/2021
     */
    validateId(id: string | number): boolean {
        return id === '0' || super.validateId(id);
    }
}

export const exports = {
    fetcher: RemitsSelectedStatusFetcher,
    type: 'RemitsSelectedStatus',
};
