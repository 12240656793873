import * as React from 'react';

import { RouteData } from '@General/Router';
import { PageLoader } from '@Component/PageLoader';
import { Team } from '@General/Team';
import { PageUrl } from '@Page/LogisticsV2/utils/enums';

const loader: React.FC = () => (
    <PageLoader<{}>
        loader={async () => import('./PurchaseOrder').then((m) => m.page)}
        adjustWrapperDiv
    />
);

export const route: RouteData = {
    component: loader,
    path: PageUrl.PURCHASE_ORDER,
    title: () => `${getMessage('text_order_out')}`,
    documentationComponent: 215,
    team: Team.GOLDSHARK,
    auth: () =>
        moduleLogistics || (modulePurchaseOrder && hasProductAuthAdminRole),
};
