import jQuery from 'jquery';

import { browserInfo } from '../../modules/d-browserInfo';

export const toolTip = (function ($) {
    function _getElement(element: DOMElementRef) {
        const $target = $(element);
        //The "Home"(perhaps more links?) button in the menu dosen't have a id so the "[for=]" selector returns an error.
        // This prevents these js errors.
        if ($target[0].id === '') {
            return;
        }
        return $target;
    }

    /**
     * showing and placing
     *
     * @param element
     */
    function showTooltip(element: DOMElementRef): void {
        const $target = _getElement(element);
        if ($target) {
            const $tooltip = $('[for="' + $target[0].id + '"].mdl-tooltip');
            if ($tooltip) {
                $tooltip.addClass('is-active');
                // @ts-expect-error missing null checks
                $tooltip.css('left', $target.offset().left);

                const viewPortHeight = window.innerHeight;
                if (
                    // @ts-expect-error missing null checks
                    viewPortHeight - ($target.offset().top + $target.height()) <
                    // @ts-expect-error missing null checks
                    $tooltip.height()
                ) {
                    $tooltip.addClass('mdl-tooltip--top');
                } else {
                    $tooltip.css(
                        'top',
                        // @ts-expect-error missing null checks
                        $target.offset().top + $target.height()
                    );
                }
            }
        }
    }

    /**
     * Adds tooltip to $element with the given value or text of $element.
     * @param $element Accepts both HTML elements and jquery objects.
     * @param [value] - Text added to tooltip will be encoded using _encode
     * @return {$|undefined} returns tooltip or undefined if unable to create tooltip
     */
    function addToolTips($element: DOMElementRef, value: string) {
        //Can't hover on a touch device.
        if (browserInfo.isTouchDevice() && !browserInfo.isDesktopBrowser()) {
            return;
        }

        $element = $($element);
        if (!$element[0]) {
            return;
        }

        let $toolTip;
        let id = $element[0].id;
        if (id) {
            $toolTip = $('[for="' + id + '"].mdl-tooltip');
        }

        $element.addClass('js-tooltip-open');

        if (!value) {
            //Try to add text from $element if value is not given
            if (
                /[a-zA-Z0-9]/.test($element.text()) &&
                $element.text().length > 0
            ) {
                value = $element.text();
            } else {
                //Value not given and no text in $element to add to tooltip.
                return;
            }
        }
        //try to update tooltip if it already exists, this should not be the case, but you never know, you know, before you know, you know
        if ($toolTip) {
            if ($toolTip.length !== 0) {
                $toolTip.text(value);
                return $toolTip;
            }
        }
        //If $element is without an id generate one.
        if (id === undefined || id === '') {
            id =
                $element.attr('id') ||
                'tooltip' + parseInt(Math.random() * 99999);
            $element.attr('id', id);
        }
        //add tooltip to DOM and upgrade it.
        $toolTip = $(
            '<div class="tmdl-tooltip mdl-tooltip" for="' + id + '" ></div>'
        );
        $toolTip.text(value);
        $('body').append($toolTip);
        // @ts-expect-error componentHandler has not (yet) been typed on Window
        window.componentHandler.upgradeElement($toolTip[0]);
        showTooltip($element);
        return $toolTip;
    }

    /**
     *
     *
     * @param $element with tooltip
     * @return {boolean} true if tooltip is removed, false if removal fails
     */
    function removeToolTip($element: DOMElementRef) {
        $element = $($element);

        $element.removeClass('js-tooltip-open');

        const $toolTip = $('[for="' + $element.attr('id') + '"].mdl-tooltip');
        //If element has no tooltip
        if (!$toolTip[0]) {
            return false;
        }
        //If the tooltip was not made by this module
        // @ts-expect-error Failing checks here
        if (!$($toolTip[0].parentElement).is('body')) {
            return false;
        }

        // @ts-expect-error componentHandler has not (yet) been typed on Window
        window.componentHandler.downgradeElements($toolTip[0]);
        $($toolTip[0]).remove();
        return true;
    }

    return {
        show: showTooltip,
        add: addToolTips,
        remove: removeToolTip,
    };
})(jQuery);
