import * as React from 'react';

import { renderComponent } from '@General/renderComponent';
import { PageLoader } from '@Component/PageLoader';
import { RouteData } from '@General/Router';
import { Team } from '@General/Team';
import { EntitlementLookup } from '@General/constants';

const Page: React.FC = (props) => (
    <PageLoader<{}>
        loader={async () => import('./YearEndRateOverview').then((m) => m.page)}
        props={props}
    />
);

export function renderYearEndDashboard(domId: string) {
    renderComponent(Page, domId);
}

export const route: RouteData = {
    component: Page,
    path: '/execute/yearEnd/yearEndRateOverview',
    title: () => {
        return getMessage('text_rates');
    },
    documentationComponent: undefined,
    team: Team.PHOENIX,
    // only available to companies with the year-end developer mode pilot
    auth: (context) =>
        window.segmentationData.pilotFeatures.YearEndDevMode &&
        (window?.segmentationData?.companyType === '1' ||
            window?.segmentationData?.companyType === '6') &&
        context.entitlementMap[
            (EntitlementLookup.AUTH_COMPANY_ACCOUNTING_REPORTS,
            EntitlementLookup.AUTH_ALL_VOUCHERS)
        ] !== undefined &&
        ((!window?.segmentationData?.modules?.yearEndReport &&
            context.entitlementMap[
                EntitlementLookup.AUTH_ACCOUNT_ADMINISTRATOR
            ] !== undefined) ||
            (!window?.segmentationData?.modules?.yearEndReport &&
                window?.isActiveAccountantProxy) ||
            (window?.segmentationData?.modules?.yearEndReport &&
                context.entitlementMap[
                    EntitlementLookup.YEAR_END_REPORT_ADMINISTRATOR
                ] !== undefined)),
};
