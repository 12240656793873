type ValidationNotification = {
    message: string;
};

type ActionLogMessage = {
    pageName: string;
    date: Date;
    message: string;
};

export function showValidationMessage(notification: ValidationNotification) {
    // There are some race conditions in the monolith - sometimes a modal dialog makes a form POST with validation errors.
    // The validation errors would be shown immediately (before the modal dialog is closed), which - because of
    // focus locking inside the modal - quickly restores focus from the validation popup back to the modal dialog.
    // This would hide the validation error panel, so users would not see what's wrong.
    // We add a few ms of waiting here, to allow the modal dialog to close first.
    window.setTimeout(() => {
        window.dispatchEvent(
            new CustomEvent<ValidationNotification>('tlx:error-messages:add', {
                detail: notification,
            })
        );
    }, 50);
}

export function clearAllValidations() {
    window.dispatchEvent(new CustomEvent<void>('tlx:error-messages:clear'));
}

export function showActionLogMessage(message: ActionLogMessage) {
    window.dispatchEvent(
        new CustomEvent<ActionLogMessage>('tlx:action-log:add', {
            detail: message,
        })
    );
}
