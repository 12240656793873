import { ActionReducer } from '../types/Reducer';
import { BetaProgramState } from '../types/BetaProgram.type';
import { StoreAll } from '../action/BetaProgram.action';

export const initialState: BetaProgramState = {
    programs: [],
    programLookup: {},
    doneLoading: false,
};

const reducerStoreAll: ActionReducer<BetaProgramState, StoreAll> = (
    state,
    action
) => ({
    ...state,
    programs: action.programs,
    programLookup: action.programs.reduce(
        (p, c) => ({ ...p, [c.name]: c }),
        {}
    ),
    doneLoading: true,
});

type SupportedActions = StoreAll;

export function reduceBetaProgram(
    state: BetaProgramState = initialState,
    action: SupportedActions
): BetaProgramState {
    switch (action.type) {
        case StoreAll.type:
            return reducerStoreAll(state, action as StoreAll);
        default:
            return state;
    }
}
