import { CommandResponderRegistry } from '@General/Command';

export const MenuDrawerCommandId = 'MenuDrawerCommand';

const MenuDrawerCommand = {
    id: MenuDrawerCommandId,
};

const MenuDrawerCombination = {
    key: 'm',
};

function menuDrawerHandler() {
    document.dispatchEvent(new Event('sidebar:toggle'));
}

export function registerMenuDrawer(registry: CommandResponderRegistry) {
    registry.registerCommand(MenuDrawerCommand);
    registry.registerHandler(MenuDrawerCommandId, menuDrawerHandler);
    registry.registerCombination(MenuDrawerCommandId, MenuDrawerCombination);
}
