import $ from 'jquery';

/**
 * Mutates the json parameter (result of $.retrieve) such that all instances of "property" with the specified prefix path
 * is collected into "targetProperty" ("property" as default). The clientId is preserved.
 * <p>
 * The example is a bit easier to understand :) (also see the testcase in test-common.js)
 * <p>
 * Other properties (!= "property") of the prefix path is preserved. (ie. the prefix path structure will remain if it contains other properties)
 * <p>
 * Example: (inputs listed as property paths instead of json structure since this is a bit easier to understand)
 * <pre>
 * flattenJson(json, ["groups"], "postings", "postingsForSubmit")
 *   json is the $.retrieve result of the following inputs:
 *    groups[1].postings[0].id=1, groups[2].postings[0].id=2, groups[2].postings[1].id=3
 *   ->
 *    postingsForSubmit[0].id=1, postingsForSubmit[1].id=2, postingsForSubmit[2].id=3
 * </pre>
 */
/* eslint-disable @typescript-eslint/no-explicit-any */
export function flattenJson(
    json: Record<string, any>,
    prefixPath: string[],
    property: string,
    targetProperty: string
) {
    function isEmptyMap(obj: Record<any, any>) {
        for (const prop in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, prop)) {
                return false;
            }
        }
        return true;
    }

    if (!targetProperty) {
        targetProperty = property;
    }
    let collected: any[] = [];

    function rec(obj: Record<any, any>, pathLeft: string[]) {
        let i;
        if (pathLeft.length == 0) {
            const x = obj[property];
            if ($.isArray(x)) {
                collected = collected.concat(x);
            } else {
                collected.push(x);
            }
            delete obj[property];
            return;
        }
        const headPath = pathLeft[0];
        if (!obj[headPath]) {
            return;
        }

        if ($.isArray(obj[headPath])) {
            const x = obj[headPath];
            let deleteArray = false;
            for (i = 0; i < x.length; i++) {
                if (x[i]) {
                    rec(x[i], pathLeft.slice(1));
                    if (deleteArray || isEmptyMap(x[i])) {
                        deleteArray = true;
                    } // assume uniform arrays
                }
            }
            if (deleteArray) {
                delete obj[headPath];
            }
        } else {
            rec(obj[headPath], pathLeft.slice(1));
            if (isEmptyMap(obj[headPath])) {
                delete obj[headPath];
            }
        }
    }

    rec(json, prefixPath);
    if (collected.length > 0) {
        json[targetProperty] = collected;
    }
}
