import { EmptyStateIcon } from '@Page/LogisticsV2/pages/Import v2/icons';
import * as React from 'react';

export function getSvgIcon(value: string, size?: number) {
    switch (value) {
        case 'infoIcon':
            return <InfoIcon />;
        case 'outgoingStock':
            return <OutgoingStockIcon />;
        case 'incomingStock':
            return <IncomingStockIcon />;
        case 'barcode':
            return <BarcodeIcon />;
        case 'completeStocktaking':
            return <CompleteStocktakingIcon />;
        case 'warning':
            return <WarningIcon />;
        case 'comment':
            return <CommentIcon />;
        case 'stocktakingCounted':
            return <StocktakingCountedIcon />;
        case 'stocktakingNotCounted':
            return <StocktakingNotCountedIcon />;
        case 'stocktakingAllProducts':
            return <StocktakingAllProductsIcon />;
        case 'searchInTable':
            return <SearchInTableIcon />;
        case 'edit':
            return <EditIcon />;
        case 'send':
            return <SendIcon />;
        case 'delete':
            return <DeleteIcon />;
        case 'productImportEmpty':
            return <EmptyStateIcon />;
        case 'search':
            return <SearchIcon />;
        case 'generalEmptyState':
            return <GeneralEmptyState />;
        case 'warehouseOverview':
            return <WarehouseOverviewIcon size={size} />;
        case 'noProductsInCompany':
            return <NoProductsInCompanyIcon />;
        case 'barcodeScanner':
            return <BarcodeScannerIcon size={size} />;
        case 'inventoryCorrectionOverview':
            return <InventoryCorrectionOverviewIcon />;
        case 'noProductGroupsIcon':
            return <EmptyProductGroupsIcon />;
        case 'emptyDiscount':
            return <EmptyDiscountIcon />;
        case 'editDiscount':
            return <EditDiscountIcon />;
        default:
            return undefined;
    }
}

export function StocktakingCountedIcon() {
    return (
        <svg
            width={100}
            height={100}
            viewBox="0 0 100 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M20 62V50h13v12H20zM20 33V20h13v13H20z"
                stroke="#0A41FA"
                strokeWidth={4}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M44 20h13M44 31h7"
                stroke="#0A41FA"
                strokeWidth={4}
                strokeLinecap="round"
            />
            <path
                d="M11 28v49h32M69 54V10H53M53 10H11v18"
                stroke="#0A41FA"
                strokeWidth={4}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M76.204 53.001a2 2 0 011.17.334l11.266 7.5a2 2 0 01.892 1.665v23.055a2 2 0 01-1.871 1.996l-34.64 2.23a2 2 0 01-1.493-.533l-8.891-8.285A2 2 0 0142 79.5V56a2 2 0 011.938-1.999l32.266-1zM46 60.303V78.63l4.892 4.558V64.43L46 60.303zm8.892 5.14v20.21l30.64-1.973V64.559l-30.64.884zm26.3-4.76l-27.595.796-4.317-3.642 26.41-.818 5.502 3.663z"
                fill="#0A41FA"
            />
            <path
                d="M65.903 64v9.563l9.254-.617V64"
                stroke="#0A41FA"
                strokeWidth={4}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M93.727 91.251C91.592 83.381 84.55 78.445 78 80.228l3.867 14.25 11.86-3.227z"
                fill="#5FDAC4"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M78.545 4.474l16.743 14.032-22.955 7.004 6.212-21.036z"
                fill="#F6AED5"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M28.29 91.56l-6.213 4.35-2.701-4.361-5.304 3.712-3.943-6.368 11.517-8.061L28.29 91.56z"
                fill="#FCE6AF"
            />
        </svg>
    );
}
export function StocktakingNotCountedIcon() {
    return (
        <svg
            width={100}
            height={100}
            viewBox="0 0 100 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_1103_16346)">
                <path fill="#fff" d="M0 0H100V100H0z" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20.017 95.547l-10.26-5.968 4.42-6.7-8.758-5.094L11.872 68l19.017 11.061-10.872 16.486z"
                    fill="#FCE6AF"
                />
                <mask
                    id="a"
                    style={{
                        maskType: 'alpha',
                    }}
                    maskUnits="userSpaceOnUse"
                    x={23}
                    y={21}
                    width={64}
                    height={64}
                >
                    <path fill="#D9D9D9" d="M23 21H87V85H23z" />
                </mask>
                <g mask="url(#a)">
                    <path
                        d="M51.267 65.267l18.8-18.8-3.734-3.734L51.267 57.8l-7.6-7.6-3.734 3.733 11.334 11.334zM55 79.667c-3.689 0-7.156-.7-10.4-2.102-3.244-1.399-6.067-3.298-8.467-5.698-2.4-2.4-4.3-5.223-5.698-8.467-1.401-3.244-2.102-6.711-2.102-10.4s.7-7.156 2.102-10.4c1.399-3.245 3.298-6.067 5.698-8.467 2.4-2.4 5.223-4.3 8.467-5.701 3.244-1.4 6.711-2.099 10.4-2.099s7.156.7 10.4 2.099c3.244 1.4 6.067 3.301 8.467 5.701 2.4 2.4 4.3 5.222 5.698 8.467 1.401 3.244 2.102 6.711 2.102 10.4s-.7 7.156-2.102 10.4c-1.399 3.244-3.298 6.067-5.698 8.467-2.4 2.4-5.223 4.3-8.467 5.698-3.244 1.401-6.711 2.102-10.4 2.102zm0-5.334c5.956 0 11-2.066 15.133-6.2 4.134-4.133 6.2-9.177 6.2-15.133 0-5.956-2.066-11-6.2-15.133-4.133-4.134-9.177-6.2-15.133-6.2-5.956 0-11 2.066-15.133 6.2-4.134 4.133-6.2 9.177-6.2 15.133 0 5.956 2.066 11 6.2 15.133 4.133 4.134 9.178 6.2 15.133 6.2z"
                        fill="#0A41FA"
                    />
                </g>
                <path
                    d="M77.727 21.251C75.592 13.381 68.55 8.445 62 10.228l3.867 14.25 11.86-3.227z"
                    fill="#5FDAC4"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M76.915 90.895L62 68.02 92 68 76.915 90.895z"
                    fill="#F6AED5"
                />
            </g>
            <defs>
                <clipPath id="clip0_1103_16346">
                    <path fill="#fff" d="M0 0H100V100H0z" />
                </clipPath>
            </defs>
        </svg>
    );
}
export function StocktakingAllProductsIcon() {
    return (
        <svg
            width={100}
            height={100}
            viewBox="0 0 100 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_1040_490)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M84.933 20.253l2.97-17.628 14.083 14.064-17.053 3.564z"
                    fill="#6EB1CA"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M41.693 85.525l-9.657 5.115-3.168-6.717-8.242 4.365L16 78.48 33.9 69l7.793 16.525z"
                    fill="#F5C1DF"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M62.437 10.001a2 2 0 01.912.188l18.058 8.472a2 2 0 011.15 1.81v40.363a2 2 0 01-1.871 1.996L24.54 66.445a2 2 0 01-1.152-.278L8.976 57.582A2 2 0 018 55.864V13.663a2 2 0 011.937-2l52.5-1.662zM12 17.125v37.603l10.412 6.201V23.133L12 17.125zm14.412 6.8v38.39l52.145-3.356V22.526l-52.145 1.399zm45.657-5.227l-47.147 1.265-7.84-4.524 45.002-1.425 9.985 4.684z"
                    fill="#0A41FA"
                />
                <path
                    d="M45.5 22v15.5l15-1V22"
                    stroke="#0A41FA"
                    strokeWidth={4}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <circle
                    cx={65.5}
                    cy={62}
                    r={17.5}
                    fill="#fff"
                    stroke="#0A41FA"
                    strokeWidth={5}
                />
                <path
                    d="M78.5 75l14.496 14.496M57.726 66.237a6 6 0 01-.452-8.474"
                    stroke="#0A41FA"
                    strokeWidth={5}
                    strokeLinecap="round"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15.755 23.747c-3.495-1.86-8.645-.855-12.32 2.64C-.238 29.885-1.222 34.717.83 37.95l.006.01c.299.47.66.904 1.088 1.296.01.006 10.33-9.82 13.985-13.303l.64-.608.625-.597a6.812 6.812 0 00-1.417-1z"
                    fill="#FFD775"
                />
            </g>
            <defs>
                <clipPath id="clip0_1040_490">
                    <path fill="#fff" d="M0 0H100V100H0z" />
                </clipPath>
            </defs>
        </svg>
    );
}

export function PriceIconSVG(props: any) {
    return (
        <svg
            width={100}
            height={100}
            viewBox="0 0 100 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g clipPath="url(#clip0_538_7786)">
                <mask
                    id="a"
                    style={{
                        maskType: 'alpha',
                    }}
                    maskUnits="userSpaceOnUse"
                    x={15}
                    y={15}
                    width={70}
                    height={70}
                >
                    <path fill="#D9D9D9" d="M15 15H85V85H15z" />
                </mask>
                <g mask="url(#a)">
                    <path
                        d="M55.76 77.927c-.875.875-1.932 1.313-3.172 1.313-1.24 0-2.297-.438-3.172-1.313L22.073 50.583c-.486-.486-.815-.996-.985-1.53a5.498 5.498 0 01-.255-1.678V25.21c0-1.264.413-2.31 1.24-3.136.826-.826 1.871-1.24 3.135-1.24h22.167c.583 0 1.166.086 1.75.256.583.17 1.118.498 1.604.984l27.198 27.198c.923.924 1.385 2.005 1.385 3.245 0 1.24-.462 2.321-1.385 3.245L55.76 77.927zm-2.99-2.99l22.167-22.166-27.562-27.563H25.208v22.167l27.563 27.563zM32.864 36.585c1.021 0 1.908-.377 2.662-1.13.753-.754 1.13-1.641 1.13-2.662s-.377-1.908-1.13-2.662c-.754-.753-1.64-1.13-2.662-1.13-1.02 0-1.908.377-2.661 1.13-.754.754-1.13 1.641-1.13 2.662 0 1.02.376 1.908 1.13 2.661.753.754 1.64 1.13 2.661 1.13z"
                        fill="#0A41FA"
                    />
                </g>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.017 88.547l-10.26-5.968 4.42-6.7-8.758-5.094L3.872 61l19.017 11.061-10.872 16.486z"
                    fill="#FCE6AF"
                />
                <path
                    d="M69.727 14.251C67.592 6.381 60.55 1.445 54 3.228l3.867 14.25 11.86-3.227z"
                    fill="#5FDAC4"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M68.915 83.895L54 61.02 84 61 68.915 83.895z"
                    fill="#F6AED5"
                />
            </g>
            <defs>
                <clipPath id="clip0_538_7786">
                    <path fill="#fff" d="M0 0H100V100H0z" />
                </clipPath>
            </defs>
        </svg>
    );
}

export function SearchInTableIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100"
            height="100"
            fill="none"
            viewBox="0 0 100 100"
        >
            <path fill="#fff" d="M0 0H100V100H0z" />
            <path
                stroke="#0A41FA"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="5"
                d="M43.618 8H6.31"
            />
            <path
                fill="#F7C7CB"
                d="M7.5 8a2.5 2.5 0 00-5 0h5zM5 65.6H2.5A2.5 2.5 0 005 68.1v-2.5zm19.636 2.5a2.5 2.5 0 000-5v5zM2.5 8v57.6h5V8h-5zM5 68.1h19.636v-5H5v5z"
            />
            <path
                fill="#CCC7FF"
                d="M24.637 63.1a2.5 2.5 0 000 5v-5zM79.5 25.018a2.5 2.5 0 10-5 0h5zM46 68.1a2.5 2.5 0 000-5v5zm28.5-20.6a2.5 2.5 0 005 0h-5zM24.637 68.1H46v-5H24.637v5zM79.5 47.5V25.018h-5V47.5h5z"
            />
            <path
                stroke="#58B3CD"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="5"
                d="M77 25.018V8H44.273"
            />
            <path
                stroke="#58B3CD"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="5"
                d="M50.164 8.655v24.218H29.218"
            />
            <path
                stroke="#0A41FA"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="5"
                d="M28.564 32.873V8.655"
            />
            <circle cx="64" cy="60" r="17.5" stroke="#CCC7FF" strokeWidth="5" />
            <path
                stroke="#CCC7FF"
                strokeLinecap="round"
                strokeWidth="5"
                d="M81 78l14.496 14.496"
            />
            <path
                stroke="#58B3CD"
                strokeLinecap="round"
                strokeWidth="5"
                d="M56.226 64.237a6 6 0 01-.452-8.474"
            />
        </svg>
    );
}

function InfoIcon() {
    return (
        <svg
            width={100}
            height={100}
            viewBox="0 0 100 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.017 37.547l-10.26-5.968 4.42-6.7-8.758-5.094L12.872 10l19.017 11.061-10.872 16.486z"
                fill="#FCE6AF"
            />
            <mask
                id="a"
                style={{
                    maskType: 'alpha',
                }}
                maskUnits="userSpaceOnUse"
                x={23}
                y={21}
                width={64}
                height={64}
            >
                <path fill="#D9D9D9" d="M23 21H87V85H23z" />
            </mask>
            <g mask="url(#a)">
                <path
                    d="M52.333 66.333h5.334v-16h-5.334v16zM55 45c.756 0 1.39-.256 1.901-.768.51-.51.766-1.143.766-1.899 0-.755-.255-1.389-.766-1.901-.512-.51-1.145-.765-1.901-.765-.755 0-1.388.255-1.899.765-.512.512-.768 1.146-.768 1.901 0 .756.256 1.389.768 1.899.51.512 1.144.768 1.899.768zm0 34.667c-3.689 0-7.156-.7-10.4-2.102-3.244-1.399-6.067-3.298-8.467-5.698-2.4-2.4-4.3-5.223-5.698-8.467-1.401-3.244-2.102-6.711-2.102-10.4s.7-7.156 2.102-10.4c1.399-3.244 3.298-6.067 5.698-8.467 2.4-2.4 5.223-4.3 8.467-5.701 3.244-1.4 6.711-2.099 10.4-2.099s7.156.7 10.4 2.099c3.244 1.4 6.067 3.301 8.467 5.701 2.4 2.4 4.3 5.223 5.698 8.467 1.401 3.244 2.102 6.711 2.102 10.4s-.7 7.156-2.102 10.4c-1.399 3.244-3.298 6.067-5.698 8.467-2.4 2.4-5.223 4.3-8.467 5.698-3.244 1.401-6.711 2.102-10.4 2.102zm0-5.334c5.956 0 11-2.066 15.133-6.2 4.134-4.133 6.2-9.177 6.2-15.133 0-5.956-2.066-11-6.2-15.133-4.133-4.134-9.177-6.2-15.133-6.2-5.956 0-11 2.066-15.133 6.2-4.134 4.133-6.2 9.177-6.2 15.133 0 5.956 2.066 11 6.2 15.133 4.133 4.134 9.178 6.2 15.133 6.2z"
                    fill="#0A41FA"
                />
            </g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M72 15.5a7.5 7.5 0 1115 0 7.5 7.5 0 01-15 0z"
                fill="#F6AED5"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M41 60v28H21l20-28z"
                fill="#CBC8FB"
            />
        </svg>
    );
}

export function CommentIcon() {
    return (
        <svg
            width={86}
            height={86}
            viewBox="0 0 86 86"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.288 33.043l-10.26-5.968 4.42-6.7L3.69 15.28l6.453-9.785 19.017 11.06-10.872 16.487z"
                fill="#FCE6AF"
            />
            <mask
                id="a"
                style={{
                    maskType: 'alpha',
                }}
                maskUnits="userSpaceOnUse"
                x={15}
                y={14}
                width={64}
                height={64}
            >
                <path fill="#D9D9D9" d="M15 14H79V78H15z" />
            </mask>
            <g mask="url(#a)">
                <path
                    d="M31 51.333h32V46H31v5.333zm0-8h32V38H31v5.333zm0-8h32V30H31v5.333zm42.667 37.334L63 62H25.667c-1.467 0-2.722-.522-3.766-1.565-1.045-1.046-1.568-2.302-1.568-3.768v-32c0-1.467.523-2.723 1.568-3.768 1.044-1.044 2.299-1.566 3.766-1.566h42.666c1.467 0 2.723.522 3.768 1.566 1.044 1.045 1.566 2.301 1.566 3.768v48zm-48-48v32H65.2l3.133 3.133V24.667H25.667z"
                    fill="#0A41FA"
                />
            </g>
            <path
                d="M85.66 36.584a7.42 7.42 0 00.028-4.348 7.471 7.471 0 00-2.392-3.593 7.195 7.195 0 00-4.008-1.614 7.55 7.55 0 00-4.276.898 7.736 7.736 0 00-3.1 3.111 7.564 7.564 0 00-.882 4.27 7.397 7.397 0 001.644 4.01 7.273 7.273 0 003.607 2.37 7.525 7.525 0 005.706-.639 7.647 7.647 0 003.674-4.465z"
                fill="#F6AED5"
            />
            <path
                d="M31.443 73.24C26.882 66.48 18.625 64.082 13 67.884l8.259 12.24 10.184-6.882z"
                fill="#62B7D0"
            />
        </svg>
    );
}

export function OutgoingStockIcon() {
    return (
        <svg
            width={100}
            height={100}
            viewBox="0 0 100 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_1025_377)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M67.437 37.001a2 2 0 01.912.188l18.058 8.472a2 2 0 011.15 1.81v40.363a2 2 0 01-1.871 1.996L29.54 93.445a2 2 0 01-1.152-.278l-14.412-8.585A2 2 0 0113 82.864V40.663a2 2 0 011.937-2l52.5-1.662zM17 44.126v37.602l10.412 6.202V50.133L17 44.125zm14.412 6.799v38.39l52.145-3.356V49.526l-52.145 1.399zm45.657-5.227l-47.147 1.265-7.84-4.524 45.002-1.425 9.985 4.684z"
                    fill="#0A41FA"
                />
                <path
                    d="M50.5 49v15.5l15-1V49"
                    stroke="#0A41FA"
                    strokeWidth={4}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M92.42 21.836L78.005 11.264 96.897 5 92.42 21.836z"
                    fill="#6EB1CA"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M25.693 73.525l-9.657 5.115-3.168-6.717-8.242 4.365L0 66.48 17.9 57l7.793 16.525z"
                    fill="#F5C1DF"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M96.979 94.559c-.077-3.96-3.46-7.97-8.302-9.478-4.842-1.51-9.542-.017-11.37 3.349l-.005.01a6.492 6.492 0 00-.603 1.581c0 .012 13.606 4.246 18.427 5.745l.843.262.826.256a6.81 6.81 0 00.184-1.725z"
                    fill="#FFD775"
                />
                <path
                    d="M49.845 31.838l-.162-23.77m0 0l-9.782 9.268m9.782-9.269l9.908 9.135"
                    stroke="#0A41FA"
                    strokeWidth={4}
                    strokeLinecap="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_1025_377">
                    <path fill="#fff" d="M0 0H100V100H0z" />
                </clipPath>
            </defs>
        </svg>
    );
}

export function IncomingStockIcon() {
    return (
        <svg
            width={100}
            height={100}
            viewBox="0 0 100 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_1025_123)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M67.437 37.001a2 2 0 01.912.188l18.058 8.472a2 2 0 011.15 1.81v40.363a2 2 0 01-1.871 1.996L29.54 93.445a2 2 0 01-1.152-.278l-14.412-8.585A2 2 0 0113 82.864V40.663a2 2 0 011.937-2l52.5-1.662zM17 44.126v37.602l10.412 6.202V50.133L17 44.125zm14.412 6.799v38.39l52.145-3.356V49.526l-52.145 1.399zm45.657-5.227l-47.147 1.265-7.84-4.524 45.002-1.425 9.985 4.684z"
                    fill="#0A41FA"
                />
                <path
                    d="M50.5 49v15.5l15-1V49"
                    stroke="#0A41FA"
                    strokeWidth={4}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M92.42 21.836L78.005 11.264 96.897 5 92.42 21.836z"
                    fill="#6EB1CA"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M25.693 73.525l-9.657 5.115-3.168-6.717-8.242 4.365L0 66.48 17.9 57l7.793 16.525z"
                    fill="#F5C1DF"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M96.979 94.559c-.077-3.96-3.46-7.97-8.302-9.478-4.842-1.51-9.542-.017-11.37 3.35l-.005.01a6.493 6.493 0 00-.603 1.58c0 .012 13.606 4.246 18.427 5.745l.843.262.826.256a6.81 6.81 0 00.184-1.725z"
                    fill="#FFD775"
                />
                <path
                    d="M50.154 8.067v23.771m0 0L60 22.636m-9.846 9.202l-9.845-9.202"
                    stroke="#0A41FA"
                    strokeWidth={4}
                    strokeLinecap="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_1025_123">
                    <path fill="#fff" d="M0 0H100V100H0z" />
                </clipPath>
            </defs>
        </svg>
    );
}

export function BarcodeIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="none"
            viewBox="0 0 32 32"
        >
            <rect width="32" height="32" fill="#D4EBEB" rx="16" />
            <path
                fill="#1C1B1F"
                d="M8 22.546v-3.637h1.455v2.182h2.181v1.454H8zm12.364 0V21.09h2.181v-2.182H24v3.637h-3.636zm-10.182-2.182v-8.728h1.454v8.728h-1.454zm2.182 0v-8.728h.727v8.728h-.727zm2.181 0v-8.728H16v8.728h-1.454zm2.182 0v-8.728h2.182v8.728h-2.182zm2.91 0v-8.728h.727v8.728h-.728zm1.454 0v-8.728h.727v8.728h-.727zM8 13.09V9.455h3.636v1.454H9.455v2.182H8zm14.546 0v-2.182h-2.182V9.455H24v3.636h-1.454z"
            />
        </svg>
    );
}

export function CompleteStocktakingIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100"
            height="100"
            fill="none"
            viewBox="0 0 100 100"
        >
            <path
                fill="#FCE6AF"
                fillRule="evenodd"
                d="M20.017 95.547l-10.26-5.968 4.42-6.7-8.758-5.094L11.872 68l19.017 11.061-10.872 16.486z"
                clipRule="evenodd"
            />
            <mask
                id="mask0_793_8702"
                style={{ maskType: 'alpha' }}
                width="64"
                height="64"
                x="23"
                y="21"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M23 21H87V85H23z" />
            </mask>
            <g mask="url(#mask0_793_8702)">
                <path
                    fill="#0A41FA"
                    d="M51.267 65.267l18.8-18.8-3.734-3.734L51.267 57.8l-7.6-7.6-3.734 3.733 11.334 11.334zM55 79.667c-3.689 0-7.156-.7-10.4-2.102-3.244-1.399-6.067-3.298-8.467-5.698-2.4-2.4-4.3-5.223-5.698-8.467-1.401-3.244-2.102-6.711-2.102-10.4s.7-7.156 2.102-10.4c1.399-3.245 3.298-6.067 5.698-8.467 2.4-2.4 5.223-4.3 8.467-5.701 3.244-1.4 6.711-2.099 10.4-2.099s7.156.7 10.4 2.099c3.244 1.4 6.067 3.301 8.467 5.701 2.4 2.4 4.3 5.222 5.698 8.467 1.401 3.244 2.102 6.711 2.102 10.4s-.7 7.156-2.102 10.4c-1.399 3.244-3.298 6.067-5.698 8.467-2.4 2.4-5.223 4.3-8.467 5.698-3.244 1.401-6.711 2.102-10.4 2.102zm0-5.334c5.956 0 11-2.066 15.133-6.2 4.134-4.133 6.2-9.177 6.2-15.133 0-5.956-2.066-11-6.2-15.133-4.133-4.134-9.177-6.2-15.133-6.2-5.956 0-11 2.066-15.133 6.2-4.134 4.133-6.2 9.177-6.2 15.133 0 5.956 2.066 11 6.2 15.133 4.133 4.134 9.178 6.2 15.133 6.2z"
                />
            </g>
            <path
                fill="#5FDAC4"
                d="M77.728 21.251C75.592 13.381 68.55 8.445 62 10.228l3.867 14.25 11.86-3.227z"
            />
            <path
                fill="#F6AED5"
                fillRule="evenodd"
                d="M76.915 90.895L62 68.02 92 68 76.915 90.895z"
                clipRule="evenodd"
            />
        </svg>
    );
}

export function WarningIcon() {
    return (
        <svg
            width={84}
            height={85}
            viewBox="0 0 84 85"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <mask
                id="a"
                style={{
                    maskType: 'alpha',
                }}
                maskUnits="userSpaceOnUse"
                x={10}
                y={10}
                width={64}
                height={65}
            >
                <path fill="#D9D9D9" d="M10 10.5H74V74.5H10z" />
            </mask>
            <g mask="url(#a)">
                <path
                    d="M12.666 66.5L42 15.833 71.333 66.5H12.667zm9.2-5.334h40.267L42 26.5 21.866 61.166zM42 58.5c.755 0 1.39-.256 1.901-.768.51-.51.765-1.143.765-1.899 0-.755-.255-1.388-.765-1.899-.512-.512-1.146-.768-1.901-.768-.756 0-1.389.256-1.899.768-.512.51-.768 1.144-.768 1.899 0 .756.256 1.389.768 1.899.51.512 1.143.768 1.899.768zm-2.667-8h5.334V37.166h-5.334V50.5z"
                    fill="#F7C137"
                />
            </g>
        </svg>
    );
}

export function EditIcon() {
    return (
        <svg
            width={100}
            height={100}
            viewBox="0 0 100 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_641_7879)">
                <mask
                    id="a"
                    style={{
                        maskType: 'alpha',
                    }}
                    maskUnits="userSpaceOnUse"
                    x={15}
                    y={15}
                    width={70}
                    height={70}
                >
                    <path fill="#D9D9D9" d="M15 15H85V85H15z" />
                </mask>
                <g mask="url(#a)">
                    <path
                        d="M29.583 70.417h4.084L58.823 45.26l-4.083-4.083-25.157 25.156v4.084zM71.292 41.03l-12.396-12.25 4.083-4.083c1.118-1.118 2.492-1.677 4.12-1.677 1.628 0 3.002.559 4.12 1.677l4.083 4.083c1.118 1.118 1.701 2.467 1.75 4.047.049 1.58-.486 2.929-1.604 4.047l-4.156 4.156zm-4.23 4.302L36.147 76.25H23.75V63.854l30.917-30.917 12.395 12.396z"
                        fill="#0A41FA"
                    />
                </g>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M64.915 75.895L50 53.02 80 53 64.915 75.895z"
                    fill="#F6AED5"
                />
                <path
                    d="M15.627 84.128c-5.252-5.03-5.812-12.244-1.652-16.793-.02-.015-7.017-6.313-7.053-6.332-9.056 9.904-5.525 22.764 2.591 30.052-.001-.009 6.134-6.936 6.114-6.927z"
                    fill="#FCE6AF"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M34.017 42.547l-10.26-5.968 4.42-6.7-8.758-5.094L25.872 15l19.017 11.061-10.872 16.486z"
                    fill="#62B7D0"
                />
            </g>
            <defs>
                <clipPath id="clip0_641_7879">
                    <path fill="#fff" d="M0 0H100V100H0z" />
                </clipPath>
            </defs>
        </svg>
    );
}

export function SendIcon() {
    return (
        <svg
            width={100}
            height={100}
            viewBox="0 0 100 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M17.44 34.03c.167-7.271 5.113-12.552 11.277-12.552-.003-.025-.076-9.438-.086-9.478-13.42 0-20.529 11.284-20.43 22.192.006-.007 9.258-.154 9.238-.163z"
                fill="#FCE6AF"
            />
            <mask
                id="a"
                style={{
                    maskType: 'alpha',
                }}
                maskUnits="userSpaceOnUse"
                x={15}
                y={16}
                width={74}
                height={74}
            >
                <path fill="#D9D9D9" d="M15 16H89V90H15z" />
            </mask>
            <g mask="url(#a)">
                <path
                    d="M24.25 77.667V28.333L82.833 53 24.25 77.667zm4.625-7.169L70.808 53 28.875 35.271v12.95l18.654 4.78-18.654 4.624v12.873z"
                    fill="#0A41FA"
                />
            </g>
            <path
                d="M39 82.46c6.273 5.212 14.872 5.2 19.206-.024L46.848 73 39 82.46z"
                fill="#62B7D0"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M57.915 49.895L43 27.02 73 27 57.915 49.895z"
                fill="#F6AED5"
            />
        </svg>
    );
}

export function DeleteIcon() {
    return (
        <svg
            width={100}
            height={100}
            viewBox="0 0 100 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M17.44 27.03c.167-7.271 5.113-12.552 11.277-12.552-.003-.025-.076-9.438-.086-9.478-13.42 0-20.529 11.284-20.43 22.192.006-.007 9.258-.154 9.238-.163z"
                fill="#FCE6AF"
            />
            <path d="M42 31v40.5M58 31v40.5" stroke="#0A41FA" strokeWidth={5} />
            <path stroke="#0A41FA" strokeWidth={5} d="M19 20.5L81 20.5" />
            <path stroke="#0A41FA" strokeWidth={5} d="M37 15.5L63 15.5" />
            <path
                d="M25 20v55.5a6 6 0 006 6h38a6 6 0 006-6V20"
                stroke="#0A41FA"
                strokeWidth={5}
            />
            <path
                d="M91.588 39.865a9.011 9.011 0 00.032-5.28 9.074 9.074 0 00-2.904-4.364 8.737 8.737 0 00-4.868-1.96 9.17 9.17 0 00-5.192 1.09 9.395 9.395 0 00-3.765 3.779 9.186 9.186 0 00-1.07 5.185 8.982 8.982 0 001.995 4.87 8.832 8.832 0 004.381 2.877 9.139 9.139 0 006.929-.775 9.286 9.286 0 004.462-5.422z"
                fill="#F6AED5"
            />
            <path
                d="M32.094 72c-7.784 2.433-12.448 9.656-10.419 16.134L35.77 83.73 32.094 72z"
                fill="#62B7D0"
            />
        </svg>
    );
}

export function SearchIcon() {
    return (
        <svg
            width={201}
            height={200}
            viewBox="0 0 201 200"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path transform="translate(.5)" fill="#fff" d="M0 0H200V200H0z" />
            <path
                d="M124.522 113.406c-2 4.5-3.032 5.406-3.032 5.406-5.748 9.208-17.758 14.377-33.009 12.469a54.905 54.905 0 01-2.136-.3c-22.725-3.643-43.237-21.539-46.482-41.403-1.304-6.91-.107-13.407 2.937-18.773l6.722-10.399"
                stroke="#0332FF"
                strokeWidth={8}
                strokeMiterlimit={10}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M84.02 47.57c5.543.772 10.956 2.398 16.029 4.698 16.468 7.468 29.354 22.046 31.445 37.618 2.736 20.37-13.855 33.662-36.977 30.234-13.47-1.877-26.178-8.795-35.095-18.189-6.662-7.018-11.209-15.417-12.379-24.127-2.918-21.73 13.59-33.111 36.978-30.234z"
                stroke="#0332FF"
                strokeWidth={8}
                strokeMiterlimit={10}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M114.325 128.626L125.126 112l42.823 35.813c4.145 3.466 4.695 9.635 1.229 13.78v0c-3.461 4.138-9.618 4.694-13.764 1.242l-41.089-34.209z"
                stroke="#0332FF"
                strokeWidth={8}
                strokeLinejoin="round"
            />
            <path
                d="M133.339 121.557l-9.91 15.436"
                stroke="#0332FF"
                strokeWidth={8}
            />
            <path
                d="M76.522 62.906l8.16 31.46M97.602 83.386l4.16 17.459"
                stroke="#0332FF"
                strokeWidth={8}
                strokeLinecap="round"
            />
            <path
                d="M61.828 53.61c.014-.033 6.555-11.202 6.57-11.257-8.4-4.904-16.721-5.704-23.952-3.804-7.987 2.1-14.644 7.494-18.613 14.293.014-.004 12.268 6.916 12.286 6.912 2.64-4.174 6.474-6.88 10.656-7.98 4.288-1.127 8.942-.565 13.053 1.835z"
                fill="#1AB96D"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M54.908 145.812c2.932-12.254 16.216-19.28 29.388-16.128l1.61.385-5.627 23.517-25.757-6.163.386-1.611z"
                fill="#BFE7D6"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M164.104 85.894c4.4 3.66 5.083 10.094 1.525 14.373-3.559 4.278-10.01 4.779-14.411 1.119-4.4-3.66-5.082-10.095-1.524-14.373 3.558-4.278 10.01-4.78 14.41-1.12z"
                fill="#F5C1DF"
            />
        </svg>
    );
}

export function GeneralEmptyState() {
    return (
        <svg
            width={200}
            height={200}
            viewBox="0 0 200 200"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M133.873 43.002a4 4 0 011.826.377l36.114 16.943a4 4 0 012.302 3.62v80.726a4 4 0 01-3.743 3.992L58.08 155.889a3.996 3.996 0 01-2.304-.555l-28.823-17.169A4 4 0 0125 134.728V50.325a4 4 0 013.873-3.998l105-3.325zM33 57.251v75.204l20.823 12.404V69.265L33 57.251zm28.823 13.598v76.783l104.292-6.714V68.052L61.823 70.849zm91.315-10.452l-94.293 2.53-15.682-9.048 90.006-2.85 19.969 9.368z"
                fill="#0A41FA"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M70.511 10.495c-6.992-3.72-17.292-1.71-24.64 5.28-7.349 6.992-9.317 16.656-5.214 23.126l.013.02a12.983 12.983 0 002.176 2.592c.02.011 20.66-19.641 27.97-26.606a8029.28 8029.28 0 002.53-2.41 13.626 13.626 0 00-2.835-2.002z"
                fill="#FFD775"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M161.343 71.302l29.13-20.73.026 39.806-29.156-19.076z"
                fill="#6EB1CA"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M51.533 176.523l-21.79 1.697-.465-14.845-18.598 1.449-.68-21.678L50.388 140l1.145 36.523z"
                fill="#F5C1DF"
            />
            <path
                d="M100 67v31l30-2V67"
                stroke="#0A41FA"
                strokeWidth={8}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

function WarehouseOverviewIcon({ size }: { size?: number }) {
    return (
        <svg
            width={size ?? 100}
            height={size ?? 100}
            viewBox="0 0 100 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_1025_430)">
                <path
                    d="M19 43.957v38.426h12.682V61.412M80.917 45v38.425H68.235V61.412"
                    stroke="#0A41FA"
                    strokeWidth={4}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M81 58.22V29.5L51.824 14M51.824 14L19 29.5v14.077"
                    stroke="#0A41FA"
                    strokeWidth={4}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <rect
                    x={36.5}
                    y={36.0589}
                    width={27.9118}
                    height={9.67649}
                    rx={2}
                    stroke="#0A41FA"
                    strokeWidth={4}
                />
                <path
                    d="M40.882 71.441V86.03h4.974M45.856 86.03h13.262V75.75"
                    stroke="#0A41FA"
                    strokeWidth={4}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M59.118 75.751v-4.31H40.882"
                    stroke="#0A41FA"
                    strokeWidth={4}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M26.294 59.588h46.5"
                    stroke="#0A41FA"
                    strokeWidth={4}
                    strokeLinecap="round"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M89.688 53.5L73.005 41.267l21.864-7.25-5.181 19.485z"
                    fill="#6EB1CA"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.597 27.608l-7.875-7.576 5.391-5.108-6.722-6.466L11.264 1l14.597 14.043-13.264 12.565z"
                    fill="#F5C1DF"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M22.593 69.959c-3.017-2.565-8.263-2.685-12.6-.057-4.337 2.629-6.332 7.138-5.02 10.737l.004.012c.191.521.452 1.023.785 1.498.009.008 12.192-7.383 16.508-10.003l.755-.458.739-.449a6.82 6.82 0 00-1.171-1.28z"
                    fill="#FFD775"
                />
            </g>
            <defs>
                <clipPath id="clip0_1025_430">
                    <path fill="#fff" d="M0 0H100V100H0z" />
                </clipPath>
            </defs>
        </svg>
    );
}

function NoProductsInCompanyIcon() {
    return (
        <svg
            width={100}
            height={100}
            viewBox="0 0 100 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M66.937 21.501a2 2 0 01.912.188l18.058 8.472a2 2 0 011.15 1.81v40.363a2 2 0 01-1.871 1.996L29.04 77.945a2 2 0 01-1.152-.278l-14.412-8.585a2 2 0 01-.976-1.718V25.163a2 2 0 011.937-2l52.5-1.662zM16.5 28.625v37.603l10.412 6.202V34.633L16.5 28.625zm14.412 6.8v38.39l52.145-3.356V34.026l-52.145 1.399zm45.657-5.227l-47.147 1.265-7.84-4.524 45.002-1.425 9.985 4.684z"
                fill="#0A41FA"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M35.256 5.248c-3.496-1.86-8.646-.855-12.32 2.64-3.675 3.496-4.659 8.328-2.607 11.563l.006.01c.299.468.66.903 1.088 1.296.01.005 10.33-9.821 13.985-13.303a2330.177 2330.177 0 001.265-1.206 6.814 6.814 0 00-1.417-1z"
                fill="#FFD775"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M80.672 35.65l14.564-10.364.014 19.903-14.578-9.538z"
                fill="#6EB1CA"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M25.767 88.262l-10.895.848-.233-7.422-9.3.724L5 71.573 25.194 70l.573 18.262z"
                fill="#F5C1DF"
            />
            <path
                d="M50 33.5V49l15-1V33.5"
                stroke="#0A41FA"
                strokeWidth={4}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

function BarcodeScannerIcon({ size }: { size?: number }) {
    return (
        <svg
            width={size ?? 200}
            height={size ?? 200}
            viewBox="0 0 200 200"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M183.462 37.28A4 4 0 10180 30.066l-25 12a4 4 0 003.462 7.212l25-12zm-52.649 3.18c-9.293-.316-27.848-.886-45.389-1.065-8.767-.09-17.314-.082-24.339.107-6.852.183-12.709.543-15.787 1.282-7.009 1.682-11.884 4.868-15.084 9.505-3.098 4.488-4.317 9.905-4.956 15.424-1.46 12.59 4.69 26.64 16.614 30.93-2.257 14.1-4.79 25.914-6.99 35.811-.291 1.311-.578 2.593-.858 3.845v.006c-1.94 8.676-3.566 15.948-4.295 21.812-.819 6.576-.701 12.564 2.436 17.092 3.243 4.681 8.863 6.654 15.933 7.44 6.823.758 12.542.636 17.016-2.163 4.568-2.859 6.864-7.878 8.621-14.101 1.4-4.958 2.637-11.422 4.174-19.455l.721-3.757h10.101a8 8 0 008-8v-17.5a8 8 0 00-8-8h-2.914c.785-3.25 1.632-6.641 2.549-10.177 9.265.126 17.414.162 21.351.173a7.97 7.97 0 007.209-4.532L137.697 51.9c2.466-5.132-1.048-11.242-6.884-11.44zm-42.082 94.713H80.2a680 680 0 013.738-17.5h4.793v17.5zm-41.566-86.61c2.11-.507 7.081-.875 14.135-1.064 6.88-.184 15.316-.193 24.042-.104 17.407.177 35.843.743 45.136 1.058l-20.761 43.216c-5.415-.015-18.739-.078-31.954-.355-13.413-.28-26.21-.775-30.846-1.582-8.682-1.51-15.017-11.84-13.712-23.098.592-5.115 1.623-8.947 3.593-11.801 1.867-2.705 4.875-4.952 10.367-6.27zm2.469 50.199c-2.26 13.959-4.775 25.674-6.942 35.427l-.86 3.857c-1.959 8.768-3.486 15.607-4.165 21.059-.791 6.361-.238 9.654 1.074 11.548 1.206 1.74 3.811 3.33 10.24 4.045 6.678.741 9.92.238 11.889-.994 1.873-1.172 3.5-3.591 5.166-9.493 1.297-4.594 2.446-10.593 3.97-18.552.418-2.178.863-4.503 1.347-6.986 2.026-10.391 4.687-23.279 8.74-39.13l-30.46-.781zM190.96 67.665a4 4 0 01-3.951 4.048l-27.729.341a4 4 0 01-4.048-3.95 3.999 3.999 0 013.95-4.05l27.729-.34a4 4 0 014.049 3.95zm-7.279 38.426a4 4 0 002.87-7.467l-25.885-9.95a4 4 0 00-2.87 7.467l25.885 9.95z"
                fill="#0A41FA"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M48.551 127.168c-6.992-3.719-17.292-1.71-24.64 5.28-7.349 6.992-9.317 16.656-5.214 23.126l.013.02a13 13 0 002.176 2.592c.02.011 20.66-19.642 27.97-26.606l1.28-1.218 1.25-1.193a13.692 13.692 0 00-2.835-2.001z"
                fill="#FFD775"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M73.04 36.404l29.129-20.73.027 39.807L73.04 36.404z"
                fill="#6EB1CA"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M169.297 155.682l-15.454 15.454-10.004-10.978-13.191 13.191-14.608-16.031 28.645-28.645 24.612 27.009z"
                fill="#F5C1DF"
            />
        </svg>
    );
}

function InventoryCorrectionOverviewIcon() {
    return (
        <svg
            width={200}
            height={200}
            viewBox="0 0 100 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_1080_314)">
                <path
                    d="M19 43.957v38.425h12.682v-20.97M81 58.22V29.5L51.824 14M51.824 14L19 29.5v14.077"
                    stroke="#0A41FA"
                    strokeWidth={4}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <rect
                    x={36.5}
                    y={36.0588}
                    width={27.9118}
                    height={9.67649}
                    rx={2}
                    stroke="#0A41FA"
                    strokeWidth={4}
                />
                <path
                    d="M26.294 59.588h46.5"
                    stroke="#0A41FA"
                    strokeWidth={4}
                    strokeLinecap="round"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M89.688 53.5L73.005 41.267l21.864-7.25-5.181 19.485z"
                    fill="#6EB1CA"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.597 27.608l-7.875-7.576 5.391-5.108-6.722-6.466L11.264 1l14.597 14.043-13.264 12.565z"
                    fill="#F5C1DF"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M22.593 69.959c-3.017-2.565-8.263-2.685-12.6-.057-4.337 2.629-6.332 7.138-5.02 10.737l.004.012c.191.521.452 1.023.785 1.498.009.008 12.192-7.383 16.508-10.003l.755-.458.739-.449a6.82 6.82 0 00-1.171-1.28z"
                    fill="#FFD775"
                />
                <path
                    d="M61.122 69v10M56 74.122h10M72 89h10M58 90.296L78 74"
                    stroke="#0A41FA"
                    strokeWidth={4}
                    strokeLinecap="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_1080_314">
                    <path fill="#fff" d="M0 0H100V100H0z" />
                </clipPath>
            </defs>
        </svg>
    );
}
export function EmptyProductGroupsIcon({ size }: { size?: number }) {
    return (
        <svg
            width={size ? size : 100}
            height={size ? size : 100}
            viewBox="0 0 200 200"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M125.674 46.156a3.008 3.008 0 00-1.035-.155l-69.593 1.861a3 3 0 00-2.92 2.999v51.101c0 1.059.559 2.039 1.468 2.579l2.437 1.447-14.559 5.301a3 3 0 00-1.973 2.819v55.394a3 3 0 002.65 2.979l61.197 7.192a3.003 3.003 0 001.882-.401l16.595-9.858 8.976 12.347a2.998 2.998 0 002.536 1.234l50.276-1.841a3 3 0 002.89-2.998v-48.387a3 3 0 00-1.374-2.522l-16.742-10.794a3.003 3.003 0 00-1.701-.477l-29.876.751V112l9.821-.63a3 3 0 002.807-2.994v-52.09a3 3 0 00-2.045-2.843l-21.717-7.287zm5.134 66.23l-9.385.602-14.727 7.087v51.347l12.65-7.515v-43.816a3 3 0 012.925-2.999l8.537-.214v-4.492zm-25.352 1.627l-9.832.632 7.483.499 2.349-1.131zm-38.018-1.248l-4.743-2.818-5.806 2.114 10.549.704zm-9.311-12.51V55.272l12.65 4.999v47.498l-12.65-7.514zm18.65 9.587v-48.69l66.659-1.784v46.195l-66.659 4.279zM74.31 55.215l55.108-1.474-5.149-1.728-54.382 1.454 4.423 1.748zm26.386 65.781v52.324l-55.197-6.486v-49.519l55.197 3.681zm24.65 6.101l4.929 4.7-.04 38.984-4.889-6.725v-36.959zm10.883 49.788l44.272-1.621v-42.45l-44.228.655-.044 43.416zm-1.771-49.39l39.082-.579-7.629-4.919-36.263.912 4.81 4.586z"
                fill="#0A41FA"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M69.815 24.488c-7.074-3.716-17.496-1.708-24.93 5.276-7.437 6.985-9.428 16.64-5.276 23.104l.013.02a13.012 13.012 0 002.201 2.589c.02.011 20.904-19.623 28.301-26.581a6197.308 6197.308 0 002.56-2.409 13.79 13.79 0 00-2.87-2z"
                fill="#FFD775"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M162.772 98.596l-29.17-21.125 38.23-12.516-9.06 33.64z"
                fill="#6EB1CA"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M35.332 167L16.27 155.932l8.21-12.429-16.27-9.447L20.2 115.907l35.332 20.516L35.332 167z"
                fill="#F5C1DF"
            />
        </svg>
    );
}

function EmptyDiscountIcon() {
    return (
        <svg
            width={200}
            height={200}
            viewBox="0 0 200 200"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M154 130l2.823 4.705a6 6 0 01-2.206 8.319l-62.449 35.073a6 6 0 01-8.133-2.229L36.223 93.116a6 6 0 01-.62-4.484l10.99-43.114a6 6 0 016.991-4.401L58 42"
                stroke="#0A41FA"
                strokeWidth={8}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M52.588 74.7l12.503-50.601a6 6 0 017.53-4.313l51.122 15.155a6.002 6.002 0 013.488 2.748l43.741 75.616a6.001 6.001 0 01-2.23 8.222l-62.605 35.56a6 6 0 01-8.13-2.164l-44.76-75.731a6 6 0 01-.659-4.493z"
                stroke="#0A41FA"
                strokeWidth={8}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle cx={82} cy={44} r={8} fill="#0A41FA" />
            <mask
                id="a"
                style={{
                    maskType: 'alpha',
                }}
                maskUnits="userSpaceOnUse"
                x={82}
                y={61}
                width={78}
                height={79}
            >
                <path
                    transform="rotate(-30 83.366 90.562)"
                    fill="#D9D9D9"
                    stroke="#0A41FA"
                    strokeWidth={2}
                    d="M83.366 90.5621H137.7583V144.9544H83.366z"
                />
            </mask>
            <g mask="url(#a)">
                <path
                    d="M104.634 103.129c-2.385 1.377-4.905 1.709-7.564.997-2.658-.713-4.675-2.261-6.052-4.645-1.377-2.384-1.709-4.905-.996-7.564.712-2.658 2.26-4.675 4.644-6.052 2.385-1.376 4.905-1.708 7.564-.996s4.676 2.26 6.052 4.645c1.377 2.384 1.709 4.905.996 7.563-.712 2.659-2.26 4.676-4.644 6.052zm-2.83-4.901c1.036-.598 1.707-1.47 2.014-2.614.306-1.144.161-2.235-.438-3.27-.598-1.036-1.469-1.708-2.613-2.014-1.144-.307-2.235-.161-3.27.437-1.037.598-1.708 1.47-2.015 2.614-.306 1.144-.16 2.234.438 3.27s1.47 1.708 2.613 2.014c1.144.307 2.235.16 3.271-.437zm37.93 14.307c-2.384 1.376-4.905 1.708-7.564.996-2.658-.713-4.675-2.26-6.052-4.645-1.377-2.384-1.709-4.905-.996-7.564.712-2.658 2.26-4.675 4.644-6.052 2.385-1.376 4.905-1.708 7.564-.996s4.676 2.26 6.052 4.645c1.377 2.384 1.709 4.905.997 7.563-.713 2.659-2.261 4.676-4.645 6.053zm-2.83-4.902c1.036-.598 1.708-1.47 2.014-2.614.307-1.144.161-2.234-.437-3.27-.599-1.036-1.47-1.708-2.614-2.014-1.144-.307-2.235-.161-3.271.437-1.035.598-1.707 1.47-2.014 2.614-.306 1.144-.16 2.234.438 3.27s1.469 1.708 2.613 2.014c1.144.307 2.235.161 3.271-.437z"
                    fill="#0A41FA"
                    stroke="#0A41FA"
                    strokeWidth={0.05}
                />
            </g>
            <path
                d="M122 78l-10 44"
                stroke="#0A41FA"
                strokeWidth={8}
                strokeLinecap="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M160.839 45.672l-28.83-21.144L169.793 12l-8.954 33.672z"
                fill="#6EB1CA"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M63.304 137.084l-5.657 21.111-14.152-4.505-4.828 18.019L18 165.13 28.485 126l34.82 11.084z"
                fill="#F5C1DF"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M166.609 177.208c-3.867-6.911-13.615-10.797-23.577-8.896-9.964 1.902-16.85 8.963-16.901 16.624v.024a12.984 12.984 0 00.427 3.357c.01.02 28.003-5.334 37.92-7.233l1.734-.333 1.698-.326a13.673 13.673 0 00-1.301-3.217z"
                fill="#FFD775"
            />
        </svg>
    );
}

function EditDiscountIcon() {
    return (
        <svg
            width={100}
            height={100}
            viewBox="0 0 100 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M30.613 16.521h24.993M30.613 26.863h24.993M30.613 37.205H44.74"
                stroke="#0A41FA"
                strokeWidth={4}
                strokeLinecap="round"
            />
            <path
                d="M18.548 23.695V72.54h20.448M38.088 72.54h13.539M68.533 52.718v-47.4H54.446"
                stroke="#0A41FA"
                strokeWidth={4}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M54.901 5.317H18.548v17.894"
                stroke="#0A41FA"
                strokeWidth={4}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M45.548 64.57V48.392a1 1 0 011.07-.997l16.644 1.189a1 1 0 01.65.305l19.309 20.13a1 1 0 01-.015 1.4L67.8 85.823a1 1 0 01-1.414 0L45.841 65.278a1 1 0 01-.293-.707z"
                stroke="#0A41FA"
                strokeWidth={4}
            />
            <circle cx={52.4423} cy={54.212} r={1.72365} fill="#0A41FA" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M25.914 88.018l-.112-20.688L44.65 80.572l-18.736 7.446z"
                fill="#6EB1CA"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M79.307 6.317l10.33 3.568-2.748 6.9 8.816 3.045-4.012 10.075-19.145-6.613 6.76-16.975z"
                fill="#F5C1DF"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.945 12.22c-3.209-2.32-8.448-2.029-12.565.931-4.118 2.961-5.754 7.613-4.163 11.098l.005.011c.231.505.53.985.9 1.432.01.007 11.576-8.316 15.673-11.266l.716-.516c.448-.322.703-.505.701-.505a6.813 6.813 0 00-1.267-1.185z"
                fill="#FFD775"
            />
        </svg>
    );
}
