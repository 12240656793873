import * as React from 'react';
import clsx from 'clsx';

import { renderComponent } from '@General/renderComponent';

import {
    BannerButtons,
    BannerDescription,
    BannerImage,
    BannerPersistence,
    BannerTitle,
    GetStartedLabel,
    MinimizableBanner,
    useGetStartedBanner,
} from '@tlx/beehive-shared';

import rocket from '@Page/Beehive/resource/rocket.svg';

type GetStartedBannerOverviewProps = {
    bannerId: string;
    bannerTitle: string;
    bannerDescription: string;
    bannerLabel?: React.ReactNode;
    buttonText?: string;
    className?: string;
    onClick: () => void;
};

export const GetStartedBannerSalaryOverview = ({
    bannerId,
    bannerTitle,
    bannerDescription,
    bannerLabel,
    buttonText,
    onClick,
    className,
}: GetStartedBannerOverviewProps) => {
    const bannerInfo = useGetStartedBanner(
        bannerId,
        BannerPersistence.Dismissible
    );

    if (!bannerInfo) {
        return null;
    }

    return (
        <MinimizableBanner use={bannerInfo} className={clsx(className)}>
            <BannerImage src={rocket} alt="rocket" />
            {bannerLabel || <GetStartedLabel />}
            <BannerTitle>{bannerTitle}</BannerTitle>
            <BannerDescription>{bannerDescription}</BannerDescription>
            <BannerButtons>
                <a
                    data-testid={`get-started-banner-link__${bannerId}`}
                    data-trackingid={`get-started-banner-link__${bannerId}`}
                    className="atl-button atl-button--primary"
                    onClick={onClick}
                >
                    {buttonText || getMessage('text_read_more')}
                </a>
            </BannerButtons>
        </MinimizableBanner>
    );
};

export function renderSalaryOverviewGetStartedBanner(
    props: GetStartedBannerOverviewProps,
    domId: string
) {
    renderComponent(GetStartedBannerSalaryOverview, domId, props);
}
