import {
    Settings,
    TableColumnSetting,
    TableColumnsSetting,
} from '@tlx/astro-shared';
import {
    availableColumnsInPurchaseOrderOverview,
    defaultColumnsInPurchaseOrderOverview,
    getColumnLabel,
} from './utils';

export function PurchaseOrderOverviewDisplayOptions() {
    return (
        <Settings>
            <TableColumnsSetting
                name={`columns-purchase-order-overview-${window.loginEmployeeId}`}
                label={getMessage('text_columns')}
                aria-label="columns-for-purchase-order-overview"
                defaultColumns={defaultColumnsInPurchaseOrderOverview}
            >
                {availableColumnsInPurchaseOrderOverview.map((column) => (
                    <TableColumnSetting key={column} value={column}>
                        {getColumnLabel(column)}
                    </TableColumnSetting>
                ))}
            </TableColumnsSetting>
        </Settings>
    );
}
