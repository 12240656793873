import { RackbeatProductNewsActions } from '@Page/LogisticsV2/pages/rackbeatproductnews/components/RackbeatProductNewsActions';
import { Alert, AlertContent } from '@tlx/atlas';
import React from 'react';
import '../RackbeatProductNews.css';
import { RackbeatSections } from '@Page/LogisticsV2/pages/rackbeatproductnews/components/RackbeatSections';
import { Markdown } from '@Component/Markdown';

export function RackbeatProductNewsPage() {
    return (
        <div className="atl-p-16">
            <div
                data-testid={'purchase-order-title'}
                className="atl-flex atl-justify-between atl-my-32 atl-py-8 atl-items-center atl-flex-wrap"
            >
                <div className={'atl-font-normal atl-text-2xl atl-mt-0'}>
                    {getMessage('text_rackbeat_dashboard')}
                </div>
                <RackbeatProductNewsActions />
            </div>
            <Alert variant="info">
                <AlertContent>
                    <Markdown
                        textKey="text_rackbeat_news_page_info_alert"
                        linksTargetBlank
                    />
                </AlertContent>
            </Alert>
            <RackbeatSections />
        </div>
    );
}
