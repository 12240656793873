import { RouteData } from '@General/Router';
import { Team } from '@General/Team';

import { PageNotFoundPage } from './PageNotFound.page';

export const route: RouteData = {
    component: PageNotFoundPage,
    path: '/execute/404',
    team: Team.BEEHIVE,

    // Needed otherwise the fallback 401 page title will show up.
    title: () => '',

    // Always authorized as it is the fallback page
    auth: () => true,
};
