import $ from 'jquery';
import { tlxUrl } from './url';
import { nav } from './navigation';

/**
 * A dialog for selecting which template to base a note on (or no template to start with a blank ntoe)
 * Used from the various 'New' dropdown button on Customer, Employee etc
 * The dialog needs to be in javascript because of how it is linked from the 'new'-button
 *
 * @type {{selectTemplateDialog}}
 *
 */
window.templateSelector = (function () {
    //	ViewMode == 1  : edit & view note
    //	ViewMode == 2  : edit & view a new note created from a preselected template
    const noteViewMode = 1;
    const fromTemplateViewMode = 2;

    function selectTemplateDialog(
        url,
        templateType,
        templateSelectorTrackingId = '',
        confirmButtonTrackingId = '',
        cancelButtonTrackingId = ''
    ) {
        let $selectTemplateDialog = $('#selectTemplateDialog');

        if (!$selectTemplateDialog || $selectTemplateDialog.length === 0) {
            $selectTemplateDialog = createSelectTemplateDialog(
                templateType,
                url,
                templateSelectorTrackingId,
                confirmButtonTrackingId,
                cancelButtonTrackingId
            );
        }

        $selectTemplateDialog.dialog('open');
    }

    function createSelectTemplateDialog(
        templateType,
        url,
        templateSelectorTrackingId = '',
        confirmButtonTrackingId = '',
        cancelButtonTrackingId = ''
    ) {
        const templates =
            window.jsonrpc.Note.getTemplatesByTypeForSelection(templateType);

        const $templateSelect = $('<select>', {
            id: 'templateSelector',
            class: 'defaultSize',
            'data-trackingid': templateSelectorTrackingId,
        });

        $templateSelect.append(
            $('<option>', {
                value: 0,
                text: getMessage('text_note_select_template_no_template'),
            })
        );

        templates.forEach(function (template) {
            $templateSelect.append(
                $('<option>', {
                    value: template[0],
                    text: template[1],
                })
            );
        }, this);

        const buttons = [
            {
                text: getMessage('button_ok'),
                class: 'tlx-green',
                'data-trackingid': confirmButtonTrackingId,
                click: function () {
                    const selectedTemplateId = $(
                        '#templateSelector',
                        $selectTemplateDialog
                    ).val();

                    if (selectedTemplateId != 0) {
                        url = tlxUrl.addUrlParameter(
                            url,
                            'viewMode',
                            fromTemplateViewMode
                        );
                        url = tlxUrl.addUrlParameter(
                            url,
                            'templateId',
                            selectedTemplateId
                        );
                    } else {
                        url = tlxUrl.addUrlParameter(
                            url,
                            'viewMode',
                            noteViewMode
                        );
                    }

                    nav.download(url);
                    $(this).dialog('close');
                },
            },
            {
                text: getMessage('button_cancel'),
                'data-trackingid': cancelButtonTrackingId,
                click: function () {
                    $(this).dialog('close');
                },
            },
        ];

        const $selectTemplateDialog = $('<div>', {
            id: 'selectTemplateDialog',
        })
            .append(
                $('<div>', {
                    style: 'padding: 8px 0px;',
                    text: getMessage('text_note_select_template'),
                })
            )
            .append($templateSelect)
            .dialog({
                autoOpen: false,
                position: { my: 'top', at: 'center top+200' },
                minHeight: 40,
                width: 450,
                modal: true,
                hide: 'fade',
                title: getMessage('text_note_select_template_popup_header'),
                buttons,
            });

        return $selectTemplateDialog;
    }

    return {
        selectTemplateDialog: selectTemplateDialog,
    };
})();
