import { SendTypes } from '@Page/InvoicingDialog/component/InvoicingForm/SendMethod';

export function getSendMethodName(enumValue: string) {
    if (enumValue === SendTypes.AVTALEGIRO) {
        return getMessage('text_invoice_option_avtalegiro');
    } else if (enumValue === SendTypes.EFAKTURA) {
        return getMessage('text_invoice_option_efaktura');
    } else if (enumValue === SendTypes.EHF) {
        return getMessage('text_electronic_invoice_short');
    } else if (enumValue === SendTypes.EMAIL) {
        return getMessage('text_email_short');
    } else if (enumValue === SendTypes.PAPER) {
        return getMessage('text_invoice_option_paper');
    } else if (enumValue === SendTypes.MANUAL) {
        return getMessage('text_send_type_later');
    } else if (enumValue === SendTypes.VIPPS) {
        return getMessage('text_invoice_option_efaktura');
    }

    return '';
}
