import * as React from 'react';

import { renderComponent } from '@General/renderComponent';
import { PageLoader } from '@Component/PageLoader';

const Page: React.FC = (props) => (
    <PageLoader<{}>
        loader={async () =>
            import('./NoProjectsLandingPage').then((m) => m.page)
        }
        props={props}
    />
);

export function renderNoProjectsLandingPage(domId: string) {
    renderComponent(Page, domId);
}
